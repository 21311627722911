import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Select,
  Form,
  InputNumber,
  Popover,
  Input,
  message,
  DatePicker,
  Popconfirm,
} from "antd";
import {
  CheckCircleFilled,
  EllipsisOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import Filter from "../../Common/Filter";
import { TbTruck } from "react-icons/tb";
import { Calendar } from "antd";
import "../../Stylesheet/_dispatchTool.scss";
import BaseModal from "../../Common/BaseModal";
import BaseTable from "../../Common/BaseTable";
import { withAppContext } from "../../Store/AppContext";
import { add } from "../../api/Apis";
import MapDirection from "../Maps/MapDierection";
import Loading from "../../Common/Loading";
import MapComponent from "../Maps/MapComponent";
import { IoFilterSharp } from "react-icons/io5";
import AOS from "aos";
import "aos/dist/aos.css";
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import ErrorView from "../../Common/ErrorView";
import { FaMap } from "react-icons/fa";
import { connect } from "react-redux";
import { downloadExcel } from "react-export-table-to-excel";
import excelIcon from "../../Images/excel.png";
import excelIcon1 from "../../Images/excel1.png";
import {
  getContacts,
  getDispatch,
  getDropDownOptions,
  getTickets,
  getTrucksList,
  updateLead,
  getOrders,
  updateOrder,
  deleteOrders,
  getOwnerOperators,
  getLead,
  updateTrucksList,
} from "../../Redux/Actions/actions";
import moment from "moment";
import { BsArrowLeftShort } from "react-icons/bs";

const { Option } = Select;
const Search = Input.Search;

class DispatchTool extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],
      selectedData: [],
      companyName: [],
      filterCarrierData: [],
      filterTrucksData: [],
      isModalVisible: false,
      isAssignTruckModalVisible: false,
      modalData: "",
      visible: false,
      dispatchFilter: false,
      leadsData: "",
      mapsData: [],
      filteredMapData: [],
      trucksList: [],
      filterData: [],
      companyId: undefined,
      mapLoading: false,
      checkData: undefined,
      filterPropTruckingData: undefined,
      contactDetails: [],
      showRecord: false,
      recordData: {},
      mapStatus: false,
      totalTicketsData: [],
      trucksData: [],
      filterSalesPerson: [],
      filterCompanyData: [],
      showSingleJob: false,
      orderDetails: [],
      filterOrderData: [],
      jobNumberFilter: "",
      truckingPriceType: "Tons",
      orderLeadDetails: [],
      internalLoading: false,
      ShowTable: false,
      showMapComponent: false,
      showTab: "listView",
      totalTrucksData: [],
      selectedStatus: "Uncontacted",
      totalJobs: [],
      enableRowSelection: false,
      calanderVal: moment(),
      showDispatchMap: false,
      singleTruckData: {},
      dispatchMapData: [],
      clickedDate: "",
      showMapFilter: false,
    };
  }

  componentDidMount() {
    let filterStorageData = JSON.parse(
      localStorage.getItem("filterStorageData")
    );
    if (filterStorageData) {
      this.setState({
        filterTrucksData: filterStorageData.result,
        checkData: filterStorageData.filter,
        filterCarrierData: filterStorageData.filterCarrierData,
      });
    } else {
      this.props.actionFetchTrucksList();
    }

    document.title = "Twisted Nail - Dispatch Tool";
    this.props.actionFetchGetOrders();
    this.props?.actionFetchGetDispatch();
    this.props?.actionFetchGetContacts();
    this.props?.actiongetDropDownOptions();
    this.props.actionFetchOwnerOperators();
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
  }

  componentDidUpdate(prevProps) {
    const { clickedDate } = this.state;
    const {
      singleLeadData,
      dispatchLoading,
      leadUpdateInfo,
      totalTrucksListResult,
      contactsData,
      orderResult,
      totalResult,
      actionFetchGetOrders,
      dropDownOptions,
    } = this.props;
    if (prevProps?.singleLeadData.success === false && singleLeadData.success) {
      let orderLeadDetails = this.props.singleLeadData.lead.orderDetails;
      let newOrderData = orderLeadDetails
        ?.filter((e) => e.material[0]?.assignTrucks?.length > 0)
        ?.map((element) => {
          return element.material[0]?.assignTrucksInfo?.map((truck) => {
            truck.dispatchCompletedDate = element.dispatchCompletedDate;
            truck.pickUpLatLng = element.material[0]?.pickUpLatLng;
            return truck;
          });
        });
      if (newOrderData?.flat()?.length) {
        this.getDistanceAndDuration(newOrderData?.flat());
      } else {
        this.setState({
          isAssignTruckModalVisible: true,
          internalLoading: false,
          orderLeadDetails: [],
        });
      }
    }

    if (
      prevProps?.dispatchLoading?.success === false &&
      dispatchLoading?.success === true
    ) {
      actionFetchGetOrders();
    }
    if (
      prevProps?.totalResult?.success === false &&
      totalResult?.success === true
    ) {
      let data = totalResult?.dispatchData?.sort((a, b) =>
        !a.assigned < !b.assigned ? 1 : -1
      );

      let salesData = totalResult?.dispatchData?.filter(
        (value, index, self) =>
          value?.dispatchStatus !== "Completed" &&
          self?.findIndex((s) => s.salesPersonId === value?.salesPersonId) ===
            index
      );

      let companyData = totalResult?.dispatchData?.filter(
        (value, index, self) =>
          value?.dispatchStatus !== "Completed" &&
          self?.findIndex((s) => s.companyId === value?.companyId) === index
      );
      this.setState({
        filterData: data?.filter((d) => d?.dispatchStatus !== "Completed"),
        dispatchData: data?.filter((d) => d?.dispatchStatus !== "Completed"),
        filterSalesPerson: salesData,
        filterCompanyData: companyData,
        loading: false,
        isAssignTruckModalVisible: false,
        mapStatus: false,
      });
    }
    if (
      prevProps?.orderResult?.success === false &&
      orderResult?.success === true
    ) {
      let data = orderResult?.ordersData || [];
      data = data.sort((a, b) => (!a.assigned === !b.assigned ? 1 : -1));
      data = data.filter((d) => d?.dispatchStatus !== "Completed");

      let currentMonth = moment().format("MM");
      let currentYear = moment().format("YYYY");

      let filteredData = [...data];

      if (clickedDate && filteredData?.length > 0) {
        filteredData = filteredData?.filter(
          (e) => e.dispatchDate === clickedDate
        );

        currentMonth = moment(clickedDate).format("MM");
        currentYear = moment(clickedDate).format("YYYY");

        // Sort the filtered data if there are results
        if (filteredData?.length > 0) {
          filteredData = filteredData.sort(this.compareFilters);
        }
      }

      this.setState({
        isAssignTruckModalVisible: false,
        showSingleJob: true,
        filterOrderData: filteredData,
        orderDetails: data,
        totalJobs: data?.filter(
          (e) =>
            moment(e.dispatchDate).format("MM") === currentMonth &&
            moment(e.dispatchDate).format("YYYY") === currentYear
        ),
      });
    }
    if (
      prevProps?.leadUpdateInfo?.success === false &&
      leadUpdateInfo?.success === true
    ) {
      actionFetchGetOrders();
    }
    if (
      prevProps?.totalTrucksListResult?.success === false &&
      totalTrucksListResult?.success === true
    ) {
      let data = totalTrucksListResult?.trucksListData?.filter(
        (e) => e?.carrierDetails?.length > 0
      );
      let carrierData = [];
      if (data?.length > 0) {
        data?.forEach((c) => {
          if (
            c?.carrierDetails?.length > 0 &&
            c.showCarrier !== false &&
            c.banCarrier !== false
          ) {
            c?.carrierDetails?.forEach((t) => {
              const contactInfo =
                c?.contactDetails?.filter(
                  (e) => e?.isPrimaryContact === true
                ) || [];
              carrierData?.push({
                id: c?.id,
                truckingId: t?.id,
                truckId: t?.truckId,
                carrier_name: t?.carrier_name,
                usdot: c?.usdot,
                contact_name:
                  t?.dispatcher ||
                  contactInfo?.map((p) =>
                    p?.contact_name
                      ? p?.contact_name
                      : p?.firstName + " " + p?.lastName
                  )[0] ||
                  t?.carrier_name,
                contact_number:
                  t?.dispatcherPhone ||
                  contactInfo[0]?.phone ||
                  contactInfo[0]?.contact_number,
                truck_code: t?.truck_code,
                truck_type: t?.truck_type,
                parking_location: t?.parking_location,
                insurance_expiry: c?.insurance_expiry,
                payment_terms: c?.payment_terms,
                payment_discount: c?.payment_discount,
                direct_deposit: c?.direct_deposit,
                lat: t?.lat,
                lng: t?.lng,
                showCarrier: c?.showCarrier,
                banCarrier: c?.banCarrier,
                dispatchToolStatus: t?.dispatchToolStatus,
              });
            });
          }
        });

        let removeDuplicatetrucks = carrierData?.filter(
          (record, index, self) =>
            self.findIndex((e) => e.truckId === record.truckId) === index
        );
        let filteredTrucksData = removeDuplicatetrucks.filter(
          (truck) =>
            new Date(truck?.insurance_expiry).getTime() >= new Date().getTime()
        );
        let filterTrucksInfo = data?.filter(
          (truck) =>
            truck?.showCarrier !== false &&
            truck?.banCarrier !== false &&
            new Date(truck?.insurance_expiry).getTime() >= new Date().getTime()
        );
        this.setState({
          totalTrucksData: filteredTrucksData,
          filterTrucksData: filteredTrucksData,
          mapsData: filterTrucksInfo,
          filteredMapData: filterTrucksInfo,
          mapLoading: false,
          internalLoading: false,
          filterCarrierData: filteredTrucksData,
        });
      }
    }
    if (
      prevProps?.contactsData?.success === false &&
      contactsData?.success === true
    ) {
      this.setState({ contactDetails: contactsData?.contacts });
    }
    if (
      prevProps?.dropDownOptions?.success === false &&
      dropDownOptions?.success === true
    ) {
      this.setState({
        trucksData: this.props?.trucksData[0]?.trucksData,
      });
    }
  }

  searchResult = (searchValue) => {
    const { orderDetails } = this.state;
    let searchFilter = [];
    if (typeof searchValue == "number") {
      searchFilter = orderDetails?.filter(
        (e) => e["jobName"] === parseInt(searchValue)
      );
    } else {
      searchFilter = orderDetails?.filter(
        (e) =>
          e["companyName"]?.toLowerCase().indexOf(searchValue.toLowerCase()) >
            -1 ||
          e["jobType"]?.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
      );
    }

    this.setState({
      filterOrderData: searchFilter,
    });

    if (searchValue === undefined || searchValue.length === 0) {
      this.setState({
        filterOrderData: orderDetails,
      });
    }
  };

  onResetData = () => {
    this.setState({
      filterOrderData: this.state.orderDetails,
    });
    this.props?.actionFetchTrucksList();
  };

  onSelectChange = (data, selectedRows) => {
    this.setState({ selectedRowKeys: data, selectedData: selectedRows });

    if (data?.length > 0) {
      this.setState({
        enableRowSelection: true,
      });
    } else {
      this.setState({
        enableRowSelection: false,
      });
    }
  };

  editModal = (record) => {
    this.setState({
      isModalVisible: true,
      leadsData: record,
      editModal: true,
    });
  };

  getDistanceAndDuration = async (data) => {
    const promises = data?.map(async (record) => {
      let url =
        "https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&destinations=" +
        [record?.pickUpLatLng?.lat, record?.pickUpLatLng?.lng] +
        "&origins=" +
        [record?.lat, record?.lng] +
        "&key=AIzaSyB781k9fNf61hnxE3A6cvcascwhAhIVIK4";

      let milesData = {
        entityData: {
          url,
        },
      };

      const result = await add("getMiles", milesData);

      record["distance"] =
        result?.data?.status === "OK"
          ? result?.data?.distance?.text + "les"
          : 0;
      record["duration"] =
        result?.data?.status === "OK" ? result?.data?.duration?.text : 0;

      return record;
    });
    const distanceResults = await Promise.all(promises);
    if (distanceResults?.length === data?.length) {
      this.setState({
        orderLeadDetails: distanceResults,
        isAssignTruckModalVisible: true,
        internalLoading: false,
      });
    } else {
      this.setState({
        internalLoading: false,
        orderLeadDetails: [],
        isAssignTruckModalVisible: true,
      });
    }
  };

  showAssignTruckModal = (record) => {
    const { mapsData, checkData } = this.state;
    let filter = [];
    mapsData.map((m) => {
      let newCarrier = m?.carrierDetails?.filter(
        (t) =>
          record?.truckType?.filter((c) => String(t?.truck_type) === String(c))
            .length > 0
      );
      if (newCarrier.length > 0) {
        let filterObj = m;
        filterObj["carrierDetails"] = newCarrier;
        filter.push(filterObj);
      }
      return true;
    });

    let url =
      "https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&destinations=" +
      [record?.dropOffLatLng?.lat, record?.dropOffLatLng?.lng] +
      "&origins=" +
      [
        record?.material[0]?.pickUpLatLng?.lat,
        record?.material[0]?.pickUpLatLng?.lng,
      ] +
      "&key=AIzaSyB781k9fNf61hnxE3A6cvcascwhAhIVIK4";
    let milesData = {
      entityData: {
        url,
      },
    };
    add("getMiles", milesData).then((result) => {
      if (result.success) {
        record["distance"] =
          result?.data?.status === "OK"
            ? result?.data?.distance?.text + "les"
            : 0;
        record["duration"] =
          result?.data?.status === "OK" ? result?.data?.duration?.text : 0;
      }
    });
    this.setState({
      internalLoading: true,
      modalData: record,
      filteredMapData: filter,
      checkData: {
        ...checkData,
        dispatchCheckData: record.truckType,
      },
      orderLeadDetails: [],
    });
    this.props.actionFetchgetLead(record?.originalJobId);
  };

  onFinish = (values) => {
    const { leadsData, truckingPriceType } = this.state;
    values["trucksRequestedType"] = truckingPriceType;
    let data = {
      entityData: {
        ...values,
      },
    };
    this.props.actionFetchUpdateOrder(leadsData.id, data);
    this.setState({
      isModalVisible: false,
      editModal: false,
    });
  };

  deleteOrdersInfo = (data) => {
    this.props.actionDeleteOrders(data.id);
  };

  closeFilter = () => {
    this.setState({
      visible: false,
      dispatchFilter: false,
    });
  };

  onCancel = (data) => {
    this.setState({ isAssignTruckModalVisible: data });
  };

  filterMapResultData = async (data) => {
    const { mapsData, modalData } = this.state;
    let resultData = mapsData;
    // Filter by ownerOperatorLocation
    if (data.ownerOperatorLocation) {
      const submitData = {
        entityData: {
          origin: data.origin,
          filterData: [],
          radius: data.ownerOperatorRadius,
        },
      };
      const filterAddress = (await this.getFilterAddress(submitData)) || [];
      resultData = this.findAllMapFilterData(filterAddress, data);
    }

    // Filter by dispatchCheckData
    if (data?.dispatchCheckData?.length > 0) {
      resultData = this.filterByDispatchCheckData(
        resultData,
        data.dispatchCheckData
      );
    }
    resultData = this.findAllMapFilterData(resultData, data);

    this.setState({
      checkData: data,
      filteredMapData: resultData,
    });
    this.closeFilter();
    this.showSingleMap(modalData, resultData);
  };

  filterByDispatchCheckData = (data, dispatchCheckData) => {
    return data.reduce((acc, item) => {
      const newCarrier = item?.carrierDetails?.filter((t) =>
        dispatchCheckData.includes(String(t?.truck_type))
      );
      if (newCarrier.length > 0) {
        acc.push({ ...item, carrierDetails: newCarrier });
      }
      return acc;
    }, []);
  };

  findAllMapFilterData = (data, filterCriteria) => {
    return data.filter((item) => {
      if (
        !filterCriteria.ownerOperatorCarrier_name ||
        filterCriteria.ownerOperatorCarrier_name === item.carrierId
      ) {
        return true;
      }
      return false;
    });
  };

  filterResultData = async (data) => {
    const { totalTrucksData, filterCarrierData } = this.state;
    let resultData = [];
    if (
      data.dispatchStatus.includes("Uncontacted") &&
      data.dispatchStatus?.length !== 4
    ) {
      const unContactedStatus =
        totalTrucksData?.filter(
          (e) =>
            e.dispatchToolStatus !== "Contacted" &&
            e.dispatchToolStatus !== "Available" &&
            e.dispatchToolStatus !== "Unavailable"
        ) || [];
      let statusInfo = data.dispatchStatus.filter((e) => e !== "Uncontacted");
      let resultInfo =
        totalTrucksData?.filter((e) => statusInfo.includes(e)) || [];
      resultData = this.findAllFilterData(
        unContactedStatus.concat(resultInfo),
        data
      );
    } else {
      if (data.dispatchStatus?.length !== 4) {
        const statusData = totalTrucksData.filter((item) =>
          data.dispatchStatus.includes(item.dispatchToolStatus)
        );
        resultData = this.findAllFilterData(statusData, data);
      } else {
        resultData = this.findAllFilterData(totalTrucksData, data);
      }
    }
    if (
      data.ownerOperatorLocation !== "" &&
      data.ownerOperatorLocation !== undefined
    ) {
      let submitData = {
        entityData: {
          origin: data.origin,
          filterData: [],
          radius: data.ownerOperatorRadius,
        },
      };
      let filterAddress = (await this.getFilterAddress(submitData)) || [];
      resultData = this.findAllFilterData(filterAddress, data);
    }

    let filterInfo = {};
    const { origin, ...employeeRest } = data;
    filterInfo = employeeRest;

    if (data.dispatchCheckData?.length === 13) {
      const { dispatchCheckData, ...employeeRest } = filterInfo;
      filterInfo = employeeRest;
    }
    if (data.dispatchStatus?.length === 4) {
      const { dispatchStatus, ...employeeRest } = filterInfo;
      filterInfo = employeeRest;
    }
    if (data.ownerOperatorLocation === "") {
      const { ownerOperatorLocation, ownerOperatorRadius, ...employeeRest } =
        filterInfo;
      filterInfo = employeeRest;
    }
    if (data.ownerOperatorRadius === 0 || data.ownerOperatorRadius === null) {
      const { ownerOperatorRadius, ...employeeRest } = filterInfo;
      filterInfo = employeeRest;
    }
    Object.keys(filterInfo)?.forEach((key) => {
      if (filterInfo[key] === undefined) {
        delete filterInfo[key];
      }
    });
    localStorage.setItem(
      "filterStorageData",
      JSON.stringify({
        result: resultData,
        filter: data,
        filterShowData: filterInfo,
        filterCarrierData,
      })
    );

    this.setState({
      checkData: data,
      filterTrucksData: resultData,
    });
    this.closeFilter();
  };

  findAllFilterData = (data, filterCriteria) => {
    return data.filter((item) => {
      if (
        (!filterCriteria.ownerOperatorCarrier_name ||
          filterCriteria.ownerOperatorCarrier_name === item.id) &&
        (!filterCriteria.dispatchCheckData ||
          filterCriteria.dispatchCheckData.includes(item.truck_type))
      ) {
        return true;
      }
      return false;
    });
  };

  handleReset = () => {
    this.setState({
      jobNumberFilter: "",
      selectedCustomer: null,
      companyName: undefined,
      filteredData: null,
    });
  };

  exportData = (selectedData) => {
    this.setState({
      loading: true,
    });
    if (selectedData?.length > 0) {
      let data = [],
        header = [];
      header = [
        "TNB #",
        "CARRIER NAME",
        "DISPATCHER",
        "DISPATCHER PHONE",
        "TRUCK",
        // "PAYLOAD",
        "STATUS",
      ];
      selectedData?.map((e) =>
        data.push({
          truckId: e?.truckId,
          carrier_name: e?.carrier_name,
          contact_name: e?.contact_name,
          contact_number: e?.contact_number,
          truck_code: e?.truck_code,
          // truck_code: e?.truck_code,
          dispatchToolStatus: e?.dispatchToolStatus,
        })
      );
      downloadExcel({
        fileName: "Dispatch Truck Details",
        sheet: "Dispatch Truck Invoices",
        tablePayload: {
          header,
          body: data,
        },
      });
      this.setState({
        loading: false,
      });

      message.success("Record downloaded Successfully!");
    } else {
      message.error("Please filter atleast one customer!");
      this.setState({
        loading: false,
      });
    }
  };

  filterDispatchData = (data) => {
    const { filterOrderData } = this.state;
    let resultData = [];
    let jobType = data.jobType?.length > 0 ? data.jobType : undefined;
    let company =
      data.salesCompanyName !== "" ? data.salesCompanyName : undefined;
    let dispatchDate =
      data.dispatchDate !== "" && data.dispatchDate !== null
        ? data.dispatchDate
        : undefined;

    if (
      jobType !== undefined &&
      company !== undefined &&
      dispatchDate !== undefined
    ) {
      let filterJobType = this.filterJobType(filterOrderData, jobType) || [];
      let filterCompany = this.filterCompany(filterJobType, company) || [];
      resultData = this.filterDate(filterCompany, dispatchDate) || [];
    } else if (jobType !== undefined && dispatchDate !== undefined) {
      let filterJobType = this.filterJobType(filterOrderData, jobType) || [];
      resultData = this.filterDate(filterJobType, dispatchDate) || [];
    } else if (company !== undefined && dispatchDate !== undefined) {
      let filterCompany = this.filterCompany(filterOrderData, company) || [];
      resultData = this.filterDate(filterCompany, dispatchDate) || [];
    } else if (jobType !== undefined && company !== undefined) {
      let filterJobType = this.filterJobType(filterOrderData, jobType) || [];
      resultData = this.filterCompany(filterJobType, company) || [];
    } else if (jobType !== undefined) {
      resultData = this.filterJobType(filterOrderData, jobType) || [];
    } else if (dispatchDate !== undefined) {
      resultData = this.filterDate(filterOrderData, dispatchDate) || [];
    } else {
      resultData = this.filterCompany(filterOrderData, company) || [];
    }
    this.setState({
      orderDetails: resultData,
      filterPropTruckingData: data,
      searchingData: resultData,
    });
    this.closeFilter();
  };

  filterJobType = (filterOrderData, jobType) => {
    return filterOrderData.filter((j) => jobType.includes(j.jobType));
  };

  filterCompany = (filterOrderData, company) => {
    return filterOrderData.filter((j) => j.companyId === company);
  };

  filterDate = (filterOrderData, dispatchDate) => {
    return filterOrderData.filter(
      (j) =>
        new Date(moment(dispatchDate).format("l")) <=
        new Date(moment(j.dispatchDate).format("l"))
    );
  };

  onClickClearFilter = () => {
    localStorage.removeItem("filterStorageData", JSON.stringify({}));
    this.setState({ checkData: undefined, filterPropTruckingData: undefined });
    this.props?.actionFetchGetOrders();
    this.props.actionFetchTrucksList();
    this.props?.actionFetchGetDispatch();
    this.closeFilter();
  };

  showAssignJobModal = (record) => {
    const { filterOrderData, mapsData } = this.state;
    const filterInfo = filterOrderData.filter((e) =>
      e.truckType.includes(record.truck_type)
    );
    let newData = mapsData
      ?.filter((e) => e.id === record.id)
      ?.map((m) => ({
        ...m,
        carrierDetails: m?.carrierDetails?.filter(
          (t) => t?.id === record.truckingId
        ),
      }));
    this.setState({
      dispatchMapData: filterInfo || [],
      showDispatchMap: true,
      singleTruckData: newData,
    });
  };

  handleCancel = () => {
    this.setState({
      showDispatchMap: false,
      ShowTable: false,
      clickedDate: "",
      showTab: "listView",
    });
    this.props.actionFetchGetOrders();
  };

  showDispatchMap = () => {
    const { dispatchMapData, singleTruckData } = this.state;
    return (
      <MapComponent
        lat={17.793293585230707}
        lng={83.17732063382557}
        quarryData={dispatchMapData}
        dispatchTool
        showDispatchMap
        filteredMapData={singleTruckData}
        handleCancel={this.handleCancel}
        dispatchToolMap
      />
    );
  };

  showTrucksMap = (filterData) => {
    const { totalTrucksData, filterOrderData, filteredMapData, clickedDate } =
      this.state;
    const data = filterData?.filter(
      (e) => e.dispatchToolStatus !== "Unavailable"
    );
    const eligibleTruckTypes = [
      ...new Set(filterOrderData?.flatMap((e) => e.truckType)),
    ];
    const filterTrucksInfo = filteredMapData.map((m) => {
      m.carrierDetails = m.carrierDetails?.filter(
        (t) =>
          eligibleTruckTypes.includes(t.truck_type) &&
          t.dispatchToolStatus !== "Unavailable"
      );
      return m;
    });
    return (
      <>
        {clickedDate !== "" ? (
          <MapComponent
            quarryData={filterOrderData || []}
            filteredMapData={filterTrucksInfo}
            truck
            totalTrucksData={totalTrucksData}
            dispatchToolMap
            showDispatchMap
            dispatchTool
            handleCancel={this.handleCancel}
          />
        ) : (
          <MapComponent
            filteredMapData={data}
            truck
            totalTrucksData={totalTrucksData}
            dispatchToolMap
            dispatchTool={false}
          />
        )}
      </>
    );
  };

  showSingleMap = (modalData, filteredMapData) => {
    const { mapsData, orderLeadDetails } = this.state;
    return (
      <MapDirection
        quarryData={modalData}
        mapsData={filteredMapData}
        totalMapData={mapsData}
        singleValues
        handleCancel={this.handleCancel}
        onCancel={this.onCancel}
        mapStatus={this.state.mapStatus}
        updateRecord={this.updateRecord}
        orderLeadDetails={orderLeadDetails}
      />
    );
  };

  updateRecord = (record) => {
    let data = {
      entityData: {
        dispatchStatus: "Completed",
        dispatchCompletedDate: moment().format("l"),
      },
    };
    this.props?.actionUpdateJob(record?.id, data);
  };

  handleTableChage = (props) => {
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    localStorage.setItem(
      "tableSizeRange",
      JSON.stringify({ ...tableSizeRange, dispatchTool: props })
    );
  };

  compareFilters = (a, b) => {
    const dateA = new Date(a.dispatchDate);
    const dateB = new Date(b.dispatchDate);

    // First, compare the dates (past vs. present vs. future)
    if (dateA < dateB) return -1;
    if (dateA > dateB) return 1;

    // If dates are the same, compare by priority in descending order
    if (parseInt(b.dispatchPriority) < parseInt(a.dispatchPriority)) return -1;
    if (parseInt(b.dispatchPriority) > parseInt(a.dispatchPriority)) return 1;

    return 0; // If both conditions are equal, maintain the order
  };

  dateCellRender = (value) => {
    const dateStr = value.format("l");
    const { orderDetails } = this.state;
    let openJobs = orderDetails?.filter((e) => e.dispatchDate === dateStr);
    return (
      <div>
        <div>
          <span
            clasName="jobs"
            style={{
              color: openJobs?.length > 0 ? "red" : "#586370",
            }}
          >
            Open Jobs ({openJobs?.length || 0})
          </span>
        </div>
      </div>
    );
  };

  onPanelChange = (value) => {
    this.setState({
      calanderVal: value,
    });
    const { orderDetails } = this.state;
    const findMonth = value.format("MM");
    const findYear = value.format("YYYY");
    const totalJobs = orderDetails?.filter(
      (e) =>
        moment(e.dispatchDate).format("MM") === findMonth &&
        moment(e.dispatchDate).format("YYYY") === findYear
    );
    this.setState({
      totalJobs,
    });
  };

  onSelectCalender = async (value) => {
    const { orderDetails } = this.state;
    const dateStr = value.format("l");
    const filteredData = orderDetails.filter((e) => e.dispatchDate === dateStr);

    if (filteredData.length > 0) {
      const sortedData = filteredData.sort(this.compareFilters);
      this.setState({
        ShowTable: true,
        filterOrderData: sortedData,
        clickedDate: dateStr,
      });
    } else {
      // message.info("No Jobs & Trucks for the selected date.");
      this.setState({
        ShowTable: false,
        sortedData: [],
      });
    }
  };

  updateInvoiceStatus = (status, recordTruckData) => {
    const { totalTrucksData } = this.state;
    const { filteredMapData } = this.state;
    const updatedTrucksData = totalTrucksData.map((e) => {
      if (e.truckingId === recordTruckData?.truckingId) {
        return { ...e, dispatchToolStatus: status };
      }
      return e;
    });
    this.setState({
      selectedStatus: status,
      totalTrucksData: updatedTrucksData,
      filterTrucksData: updatedTrucksData,
    });
    const equipmentTypeDetails = filteredMapData?.find(
      (e) => e.id === recordTruckData?.id
    );
    const selectedTruckData = equipmentTypeDetails?.carrierDetails?.find(
      (e) => e.id === recordTruckData?.truckingId
    );
    selectedTruckData["dispatchToolStatus"] = status;
    equipmentTypeDetails?.carrierDetails.splice(
      equipmentTypeDetails?.carrierDetails.findIndex(
        (e) => e.id === selectedTruckData?.id
      ),
      1,
      selectedTruckData
    );
    let finalData = {
      entityData: {
        carrierDetails: equipmentTypeDetails?.carrierDetails,
      },
    };

    this.props.actionUpdateTrucksList(recordTruckData?.id, finalData);
  };

  searchResultData = (e) => {
    let searchValue = e.target.value;
    const { totalTrucksData, mapsData } = this.state;
    let searchFilter = [];
    let searchFilterInfo = [];
    if (searchValue === undefined || searchValue === "") {
      this.setState({
        filterTrucksData: totalTrucksData,
        filteredMapData: mapsData,
      });
      return;
    }
    if (typeof searchValue === "number" || !isNaN(searchValue)) {
      searchValue = parseFloat(searchValue);
      searchFilter = totalTrucksData?.filter(
        (e) => parseInt(e?.truckId) === searchValue
      );
      searchFilterInfo = mapsData.filter((item) =>
        item.carrierDetails.some((t) => parseInt(t?.truckId) === searchValue)
      );
    } else {
      searchValue = searchValue.trim().toLowerCase();
      searchFilter = totalTrucksData?.filter(
        (e) =>
          e["carrier_name"]?.toLowerCase().indexOf(searchValue) > -1 ||
          e["contact_name"]?.toLowerCase().indexOf(searchValue) > -1
      );
      searchFilterInfo = mapsData?.filter(
        (e) =>
          e["carrier_name"]?.toLowerCase().indexOf(searchValue) > -1 ||
          e["contact_name"]?.toLowerCase().indexOf(searchValue) > -1
      );
    }

    this.setState({
      filterTrucksData: searchFilter,
      filteredMapData: searchFilterInfo,
    });
  };

  clickTabs = (type) => {
    const { filterOrderData, totalTrucksData, clickedDate, filterTrucksData } =
      this.state;
    if (clickedDate !== "") {
      const required_truckTypes = filterOrderData?.flatMap((e) => e.truckType);
      const filterInfo = filterTrucksData?.filter((e) =>
        required_truckTypes.includes(e.truck_type)
      );
      this.setState({
        filterTrucksData: filterInfo || [],
        showTab: type,
      });
    } else {
      this.setState({
        showTab: type,
        filterTrucksData: totalTrucksData,
      });
    }
  };

  render() {
    const filterInfo =
      JSON.parse(localStorage.getItem("filterStorageData"))?.filterShowData ||
      {};
    const {
      clickedDate,
      filterTrucksData,
      selectedData,
      visible,
      filterCarrierData,
      dispatchFilter,
      filterPropTruckingData,
      filterOrderData,
      selectedRowKeys,
      isModalVisible,
      isAssignTruckModalVisible,
      modalData,
      leadsData,
      selectedStatus,
      checkData,
      showTab,
      filteredMapData,
      ShowTable,
      filterCompanyData,
      trucksData,
      internalLoading,
      totalJobs,
      showDispatchMap,
      calanderVal,
      showMapFilter,
    } = this.state;
    const { errorLoading, loading, dispatchLoading } = this.props;
    const today = moment().add(1, "days");

    const content = ["Uncontacted", "Contacted", "Available", "Unavailable"];
    const searchFields = ["carrierDetails", "carrier_name"];

    const invoiceStatusContent = (record) => (
      <div>
        {content.map((e) => (
          <Row
            onClick={() =>
              e !== selectedStatus && this.updateInvoiceStatus(e, record)
            }
            style={{
              cursor: e === selectedStatus ? "not-allowed" : "pointer",
            }}
          >
            <Col
              style={{
                fontSize: "13px",
                fontFamily: "sans-serif",
              }}
            >
              <Button
                style={{
                  width: "180px",
                  marginBottom: "5px",
                  background: e === selectedStatus ? "#1890ff" : "#FFF",
                  color: e === selectedStatus ? "#FFF" : "#1890ff",
                  pointerEvents: "none",
                  cursor: e === selectedStatus ? "not-allowed" : "pointer",
                }}
                name={e}
                id={e}
                className={e}
              >
                {e}
              </Button>
            </Col>
          </Row>
        ))}
      </div>
    );
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const columns = [
      {
        title: "Date",
        dataIndex: "dispatchDate",
        key: "dispatchDate",
        sorter: false,
        className: "col_styling",
        render: (dispatchDate) => {
          return {
            props: {
              style: {
                background: moment(moment(dispatchDate).format("l")).isBefore(
                  moment().format("l")
                )
                  ? "#ff090924"
                  : moment(moment().format("l")).isSame(
                      moment(dispatchDate).format("l")
                    )
                  ? "#00800417"
                  : moment(moment(dispatchDate).format("l")).isAfter(today) &&
                    "#d9d9d9",
                borderColor: moment(moment(dispatchDate).format("l")).isBefore(
                  moment().format("l")
                )
                  ? "#ff090924"
                  : moment(moment().format("l")).isSame(
                      moment(dispatchDate).format("l")
                    )
                  ? "#00800417"
                  : moment(moment(dispatchDate).format("l")).isAfter(today) &&
                    "#fafafa",
              },
            },
            children: (
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="col_styling table-font-mobile"
              >
                {dispatchDate}
              </span>
            ),
          };
        },
      },
      {
        title: "Dispatch #",
        dataIndex: "orderNumber",
        key: "orderNumber",
        sorter: false,
        // width: "160px",
        className: "col_styling",

        render: (orderNumber, record) => {
          return {
            props: {
              style: {
                background: moment(
                  moment(record.dispatchDate).format("l")
                ).isBefore(moment().format("l"))
                  ? "#ff090924"
                  : moment(moment().format("l")).isSame(
                      moment(record.dispatchDate).format("l")
                    )
                  ? "#00800417"
                  : moment(moment(record.dispatchDate).format("l")).isAfter(
                      today
                    ) && "#d9d9d9",
                borderColor: moment(
                  moment(record.dispatchDate).format("l")
                ).isBefore(moment().format("l"))
                  ? "#ff090924"
                  : moment(moment().format("l")).isSame(
                      moment(record.dispatchDate).format("l")
                    )
                  ? "#00800417"
                  : moment(moment(record.dispatchDate).format("l")).isAfter(
                      today
                    ) && "#fafafa",
              },
            },
            children: (
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="col_styling table-font-mobile"
              >
                {orderNumber}
              </span>
            ),
          };
        },
      },
      {
        title: "JOB #",
        dataIndex: "jobNumber",
        key: "jobNumber",
        sorter: false,
        className: "col_styling",
        // width: "120px",
        onCell: (record) => ({
          onClick: () => {
            this.props.history.push(
              `/app/jobs/job-profile/${record.originalJobId}`
            );
          },
        }),
        render: (jobNumber, record) => {
          return {
            props: {
              style: {
                background: moment(
                  moment(record.dispatchDate).format("l")
                ).isBefore(moment().format("l"))
                  ? "#ff090924"
                  : moment(moment().format("l")).isSame(
                      moment(record.dispatchDate).format("l")
                    )
                  ? "#00800417"
                  : moment(moment(record.dispatchDate).format("l")).isAfter(
                      today
                    ) && "#d9d9d9",
                borderColor: moment(
                  moment(record.dispatchDate).format("l")
                ).isBefore(moment().format("l"))
                  ? "#ff090924"
                  : moment(moment().format("l")).isSame(
                      moment(record.dispatchDate).format("l")
                    )
                  ? "#00800417"
                  : moment(moment(record.dispatchDate).format("l")).isAfter(
                      today
                    ) && "#fafafa",
              },
            },
            children: (
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="col_styling table-font-mobile"
              >
                {jobNumber}
              </span>
            ),
          };
        },
      },
      {
        title: "JOB NAME",
        dataIndex: "jobName",
        key: "jobName",
        sorter: false,
        className: "col_styling",
        // width: "80px",

        render: (jobName, record) => {
          return {
            props: {
              style: {
                background: moment(
                  moment(record.dispatchDate).format("l")
                ).isBefore(moment().format("l"))
                  ? "#ff090924"
                  : moment(moment().format("l")).isSame(
                      moment(record.dispatchDate).format("l")
                    )
                  ? "#00800417"
                  : moment(moment(record.dispatchDate).format("l")).isAfter(
                      today
                    ) && "#d9d9d9",
                borderColor: moment(
                  moment(record.dispatchDate).format("l")
                ).isBefore(moment().format("l"))
                  ? "#ff090924"
                  : moment(moment().format("l")).isSame(
                      moment(record.dispatchDate).format("l")
                    )
                  ? "#00800417"
                  : moment(moment(record.dispatchDate).format("l")).isAfter(
                      today
                    ) && "#fafafa",
              },
            },
            children: (
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="col_styling table-font-mobile"
              >
                {jobName}
              </span>
            ),
          };
        },
      },
      {
        title: "CUSTOMER",
        dataIndex: "companyName",
        key: "companyName",
        sorter: false,
        className: "col_styling space",
        // width: "160px",

        render: (companyName, record) => {
          return {
            props: {
              style: {
                background: moment(
                  moment(record.dispatchDate).format("l")
                ).isBefore(moment().format("l"))
                  ? "#ff090924"
                  : moment(moment().format("l")).isSame(
                      moment(record.dispatchDate).format("l")
                    )
                  ? "#00800417"
                  : moment(moment(record.dispatchDate).format("l")).isAfter(
                      today
                    ) && "#d9d9d9",
                borderColor: moment(
                  moment(record.dispatchDate).format("l")
                ).isBefore(moment().format("l"))
                  ? "#ff090924"
                  : moment(moment().format("l")).isSame(
                      moment(record.dispatchDate).format("l")
                    )
                  ? "#00800417"
                  : moment(moment(record.dispatchDate).format("l")).isAfter(
                      today
                    ) && "#fafafa",
              },
            },
            children: (
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="col_styling table-font-mobile"
              >
                {companyName}
              </span>
            ),
          };
        },
      },
      {
        title: "TYPE",
        dataIndex: "jobType",
        key: "jobType",
        sorter: false,
        className: "col_styling",
        // width: "80px",

        render: (jobType, record) => {
          return {
            props: {
              style: {
                background: moment(
                  moment(record.dispatchDate).format("l")
                ).isBefore(moment().format("l"))
                  ? "#ff090924"
                  : moment(moment().format("l")).isSame(
                      moment(record.dispatchDate).format("l")
                    )
                  ? "#00800417"
                  : moment(moment(record.dispatchDate).format("l")).isAfter(
                      today
                    ) && "#d9d9d9",
                borderColor: moment(
                  moment(record.dispatchDate).format("l")
                ).isBefore(moment().format("l"))
                  ? "#ff090924"
                  : moment(moment().format("l")).isSame(
                      moment(record.dispatchDate).format("l")
                    )
                  ? "#00800417"
                  : moment(moment(record.dispatchDate).format("l")).isAfter(
                      today
                    ) && "#fafafa",
              },
            },
            children: (
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="col_styling table-font-mobile"
              >
                {jobType === "Trucking & Material Job"
                  ? "Material"
                  : jobType?.replace("Job", "")}
              </span>
            ),
          };
        },
      },

      {
        title: "REQUEST",
        dataIndex: "material",
        key: "material",
        sorter: false,
        className: "col_styling",
        width: "15%",

        render: (material, record) => {
          return {
            props: {
              style: {
                background: moment(
                  moment(record.dispatchDate).format("l")
                ).isBefore(moment().format("l"))
                  ? "#ff090924"
                  : moment(moment().format("l")).isSame(
                      moment(record.dispatchDate).format("l")
                    )
                  ? "#00800417"
                  : moment(moment(record.dispatchDate).format("l")).isAfter(
                      today
                    ) && "#d9d9d9",
                borderColor: moment(
                  moment(record.dispatchDate).format("l")
                ).isBefore(moment().format("l"))
                  ? "#ff090924"
                  : moment(moment().format("l")).isSame(
                      moment(record.dispatchDate).format("l")
                    )
                  ? "#00800417"
                  : moment(moment(record.dispatchDate).format("l")).isAfter(
                      today
                    ) && "#fafafa",
              },
            },
            children: (
              <span className="col_styling table-font-mobile">
                {record.jobType === "Trucking Job"
                  ? record.trucksRequested + " " + record.trucksRequestedType
                  : material?.map((m) => (
                      <div>
                        {m?.category +
                          " - " +
                          record?.trucksRequested +
                          " " +
                          record?.trucksRequestedType}
                      </div>
                    ))}
              </span>
            ),
          };
        },
      },

      {
        title: "Priority",
        dataIndex: "dispatchPriority",
        key: "dispatchPriority",
        defaultSortOrder: "ascend",
        className: "col_styling",
        width: "8%",

        render: (dispatchPriority, record) => {
          return {
            props: {
              style: {
                background: moment(
                  moment(record.dispatchDate).format("l")
                ).isBefore(moment().format("l"))
                  ? "#ff090924"
                  : moment(moment().format("l")).isSame(
                      moment(record.dispatchDate).format("l")
                    )
                  ? "#00800417"
                  : moment(moment(record.dispatchDate).format("l")).isAfter(
                      today
                    ) && "#d9d9d9",
                borderColor: moment(
                  moment(record.dispatchDate).format("l")
                ).isBefore(moment().format("l"))
                  ? "#ff090924"
                  : moment(moment().format("l")).isSame(
                      moment(record.dispatchDate).format("l")
                    )
                  ? "#00800417"
                  : moment(moment(record.dispatchDate).format("l")).isAfter(
                      today
                    ) && "#fafafa",
              },
            },
            children: (
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="col_styling table-font-mobile"
              >
                {dispatchPriority}
              </span>
            ),
          };
        },
      },

      {
        title: "Truck Type",
        dataIndex: "truckType",
        key: "truckType",
        sorter: false,
        className: "col_styling",
        width: "150px",
        render: (truckType, record) => {
          const truckCodes = trucksData
            ?.filter((e) => truckType?.includes(e.value))
            .map((e) => e.truck_code);
          const mergedArray = [].concat(...truckCodes);
          return {
            props: {
              style: {
                background: moment(
                  moment(record.dispatchDate).format("l")
                ).isBefore(moment().format("l"))
                  ? "#ff090924"
                  : moment(moment().format("l")).isSame(
                      moment(record.dispatchDate).format("l")
                    )
                  ? "#00800417"
                  : moment(moment(record.dispatchDate).format("l")).isAfter(
                      today
                    ) && "#d9d9d9",
                borderColor: moment(
                  moment(record.dispatchDate).format("l")
                ).isBefore(moment().format("l"))
                  ? "#ff090924"
                  : moment(moment().format("l")).isSame(
                      moment(record.dispatchDate).format("l")
                    )
                  ? "#00800417"
                  : moment(moment(record.dispatchDate).format("l")).isAfter(
                      today
                    ) && "#fafafa",
              },
            },
            children: (
              <span>
                <span className="col_styling table-font-mobile">
                  <div style={{ display: "flex" }}>
                    {mergedArray?.length >= 5 ? (
                      <Popover
                        placement="left"
                        content={<Row>{mergedArray.slice(5)?.join(", ")}</Row>}
                      >
                        {`${mergedArray.slice(0, 5)?.join(", ")}...`}
                      </Popover>
                    ) : (
                      mergedArray?.join(", ")
                    )}
                  </div>
                </span>
              </span>
            ),
          };
        },
      },
      {
        title: "",
        dataIndex: "action",
        key: "action",
        sorter: false,
        className: "col_styling",
        width: "150px",
        render: (tag2, record) => {
          return {
            props: {
              style: {
                background: moment(
                  moment(record.dispatchDate).format("l")
                ).isBefore(moment().format("l"))
                  ? "#ff090924"
                  : moment(moment().format("l")).isSame(
                      moment(record.dispatchDate).format("l")
                    )
                  ? "#00800417"
                  : moment(moment(record.dispatchDate).format("l")).isAfter(
                      today
                    ) && "#d9d9d9",
                borderColor: moment(
                  moment(record.dispatchDate).format("l")
                ).isBefore(moment().format("l"))
                  ? "#ff090924"
                  : moment(moment().format("l")).isSame(
                      moment(record.dispatchDate).format("l")
                    )
                  ? "#00800417"
                  : moment(moment(record.dispatchDate).format("l")).isAfter(
                      today
                    ) && "#fafafa",
              },
            },
            children: (
              <Row>
                {record?.assigned === true &&
                record.dispatchStatus === "Completed" ? (
                  <Button
                    className="btn_checked"
                    style={{ fontSize: "16px", padding: "0px" }}
                  >
                    <CheckCircleFilled
                      style={{ marginRight: "5px", color: "#00b02e" }}
                    />
                    <span style={{ color: "#00b02e" }}>Completed</span>
                  </Button>
                ) : record?.assigned === true ? (
                  // <Popover
                  //   placement="right"
                  //   content={
                  //     <div
                  //       style={{
                  //         fontSize: "13px",
                  //         fontFamily: "sans-serif",
                  //         cursor: "pointer",
                  //       }}
                  //     >
                  //       <Row>
                  //         <Col>
                  //           <Button
                  //             style={{
                  //               width: "100px",
                  //               marginBottom: "5px",
                  //               background: "#1890ff",
                  //               color: "#FFF",
                  //             }}
                  //             onClick={() => this.updateRecord(record)}
                  //           >
                  //             Completed
                  //           </Button>
                  //         </Col>
                  //       </Row>
                  //     </div>
                  //   }
                  //   trigger="hover"
                  // >
                  // </Popover>
                  <Button
                    className="lead_ButtonStatus"
                    style={{
                      background: "transparent",
                      borderRadius: "5px",
                      borderWidth: "1px",
                      borderStyle: "solid",
                      // marginTop: "15px",
                    }}
                  >
                    In Progress
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    onClick={() => {
                      this.showAssignTruckModal(record);
                    }}
                    className="assignTruck_btn"
                  >
                    Assign Truck
                  </Button>
                )}
                {/* <Button
                type="primary"
                style={{
                  backgroundColor: "#586370",
                  borderRadius: "5px",
                  border: "none",
                  color: "#ffffff",
                  cursor: "pointer",
                  width: "120px",
                  margin: "5px 0px",
                }}
              >
                <MdCheckCircle style={{ marginRight: "5px" }} /> Assigned
              </Button> */}
              </Row>
            ),
          };
        },
      },
      {
        title: "Action",
        dataIndex: "",
        key: "",
        sorter: false,
        className: "col_styling",
        width: "25px",
        render: (truckType, record) => {
          return {
            props: {
              style: {
                background: moment(
                  moment(record.dispatchDate).format("l")
                ).isBefore(moment().format("l"))
                  ? "#ff090924"
                  : moment(moment().format("l")).isSame(
                      moment(record.dispatchDate).format("l")
                    )
                  ? "#00800417"
                  : moment(moment(record.dispatchDate).format("l")).isAfter(
                      today
                    ) && "#d9d9d9",
                borderColor: moment(
                  moment(record.dispatchDate).format("l")
                ).isBefore(moment().format("l"))
                  ? "#ff090924"
                  : moment(moment().format("l")).isSame(
                      moment(record.dispatchDate).format("l")
                    )
                  ? "#00800417"
                  : moment(moment(record.dispatchDate).format("l")).isAfter(
                      today
                    ) && "#fafafa",
              },
            },
            children: (
              <div>
                <Popover
                  content={
                    <div className="blineboxgrp">
                      <Row>
                        <Col span={24} onClick={() => this.editModal(record)}>
                          <div className="icon-alignmentit">
                            <MdModeEditOutline /> Edit
                          </div>
                        </Col>
                        <Col span={24}>
                          <Popconfirm
                            title="Are you sure？"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.deleteOrdersInfo(record)}
                          >
                            <span
                              style={{
                                color: "black",
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "left",
                                cursor: "pointer",
                              }}
                            >
                              <MdDelete style={{ marginRight: "5px" }} />
                              Delete
                            </span>
                          </Popconfirm>
                        </Col>
                      </Row>
                    </div>
                  }
                  trigger="hover"
                  placement="left"
                >
                  <Button
                    type="button"
                    style={{
                      background: "transparent",
                      border: "none",
                      height: "40px",
                      boxShadow: "none",
                      outline: "none",
                    }}
                  >
                    <EllipsisOutlined
                      style={{ fontSize: "30px", color: "grey" }}
                    />
                  </Button>
                </Popover>
              </div>
            ),
          };
        },
      },
    ];

    const trucksColumns = [
      {
        title: "TNB #",
        dataIndex: "truckId",
        key: "truckId",
        className: "col_styling",
        sorter: (a, b) => parseInt(a?.truckId) - parseInt(b?.truckId),
        onCell: (record) => ({
          onDoubleClick: () => {
            this.showAssignJobModal(record);
          },
        }),
        render: (truckId) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                color: "#474747",
                fontSize: "16px",
                fontFamily: "sans-serif",
              }}
              className="col_styling table-font-mobile"
            >
              {truckId}
            </span>
          );
        },
      },
      {
        title: "CARRIER NAME",
        dataIndex: "carrier_name",
        key: "carrier_name",
        className: "col_styling",
        sorter: (a, b) => a?.carrier_name?.localeCompare(b?.carrier_name),
        onCell: (record) => ({
          onDoubleClick: () => {
            this.showAssignJobModal(record);
          },
        }),
        render: (carrier_name, record) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                color: "#474747",
                fontSize: "16px",
                fontFamily: "sans-serif",
              }}
              className="col_styling table-font-mobile"
            >
              {carrier_name}
            </span>
          );
        },
      },
      {
        title: "Dispatcher",
        dataIndex: "contact_name",
        key: "contact_name",
        className: "col_styling",
        sorter: (a, b) => a?.contact_name?.localeCompare(b?.contact_name),
        onCell: (record) => ({
          onDoubleClick: () => {
            this.showAssignJobModal(record);
          },
        }),
        render: (contact_name, record) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                color: "#474747",
                fontSize: "16px",
                fontFamily: "sans-serif",
              }}
              className="col_styling table-font-mobile"
            >
              {contact_name}
            </span>
          );
        },
      },
      {
        title: "Dispatcher PHONE",
        dataIndex: "contact_number",
        key: "contact_number",
        className: "col_styling",
        // width: "10%",
        onCell: (record) => ({
          onDoubleClick: () => {
            this.showAssignJobModal(record);
          },
        }),
        render: (contact_number, record) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                color: "#474747",
                fontSize: "16px",
                fontFamily: "sans-serif",
              }}
              className="col_styling table-font-mobile"
            >
              {contact_number}
            </span>
          );
        },
      },
      {
        title: "TRUCK",
        dataIndex: "truck_code",
        key: "truck_code",
        className: "col_styling",
        // width: "10%",
        onCell: (record) => ({
          onDoubleClick: () => {
            this.showAssignJobModal(record);
          },
        }),
        render: (truck_code, record) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                color: "#474747",
                fontSize: "16px",
                fontFamily: "sans-serif",
              }}
              className="col_styling table-font-mobile"
            >
              {truck_code}
            </span>
          );
        },
      },
      {
        title: "PAYLOAD",
        dataIndex: "truck_code",
        key: "truck_code",
        className: "col_styling",
        onCell: (record) => ({
          onDoubleClick: () => {
            this.showAssignJobModal(record);
          },
        }),
        render: (truck_code, record) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                color: "#474747",
                fontSize: "16px",
                fontFamily: "sans-serif",
              }}
            >
              {truck_code}
            </span>
          );
        },
      },
      {
        title: "STATUS",
        dataIndex: "dispatchToolStatus",
        key: "dispatchToolStatus",
        sorter: false,
        className: "col_styling",
        render: (dispatchToolStatus, record) => {
          const backgroundColor =
            dispatchToolStatus === "Available"
              ? "#237804"
              : dispatchToolStatus === "Unavailable"
              ? "#ff0000"
              : dispatchToolStatus === "Contacted"
              ? "#facd2d"
              : "#FFF";

          const color =
            dispatchToolStatus === "Contacted" ||
            dispatchToolStatus === "Available" ||
            dispatchToolStatus === "Unavailable"
              ? "#fff"
              : "#ff0000";

          const border =
            dispatchToolStatus === "Available"
              ? "1px solid #237804"
              : dispatchToolStatus === "Contacted"
              ? "1px solid #facd2d"
              : "1px solid #ff0000";
          return (
            <Popover
              placement="left"
              content={invoiceStatusContent(record)}
              trigger="hover"
            >
              <Button
                className="lead_ButtonStatus"
                style={{
                  backgroundColor,
                  color,
                  borderRadius: "6px",
                  width: "160px",
                  justifyContent: "center",
                  border,
                }}
                onMouseMove={() => {
                  this.setState({
                    selectedStatus: dispatchToolStatus || "Uncontacted",
                  });
                }}
              >
                {dispatchToolStatus || "Uncontacted"}
              </Button>
            </Popover>
          );
        },
      },
      // {
      //   title: "ACTIONS",
      //   dataIndex: "",
      //   key: "",
      //   sorter: false,
      //   className: "col_styling",
      //   fixed: "right",
      //   render: (record) => {
      //     return (
      //       <Row
      //         style={{
      //           display: "flex",
      //           justifyContent: "left",
      //           alignItems: "center",
      //           flexWrap: "nowrap",
      //         }}
      //         className="col_styling table-font-mobile"
      //       >
      //         <Col>
      //           <Popover
      //             placement="left"
      //             content={
      //               <Row
      //                 className="popovergrid"
      //                 style={{ maxWidth: "100px" }}
      //               ></Row>
      //             }
      //           >
      //             <EllipsisOutlined
      //               style={{ fontSize: "30px", color: "grey" }}
      //               className="action-ellipsis-button"
      //             />
      //           </Popover>
      //         </Col>
      //       </Row>
      //     );
      //   },
      // },
    ];

    const selectAfterTruckPrice = (
      <Select
        defaultValue={this.state?.truckingPriceType}
        className="select-after"
        onChange={(e) => {
          this.setState({ truckingPriceType: e });
        }}
      >
        <Option value="Tons">Tons</Option>
        <Option value="Loads">Loads</Option>
        <Option value="Trucks">Trucks</Option>
      </Select>
    );

    return (
      <>
        <Loading
          enableLoading={dispatchLoading.loading || loading || internalLoading}
        />
        <div
          style={{
            background: "#fafafa",
          }}
        >
          {errorLoading ? (
            <Col
              xxl={{ span: 24 }}
              xl={{ span: 24 }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <ErrorView
                refreshLeadDetails={this.onRefresh}
                type="LeadDetails"
              />
            </Col>
          ) : (
            <>
              <Row>
                <Col
                  xxl={{ span: 6 }}
                  xl={{ span: 6 }}
                  lg={{ span: 6 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={12}
                >
                  {showTab !== "listView" && (
                    <Col
                      xxl={{ span: 24 }}
                      xl={{ span: 24 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      className="quarrytoolgrid"
                    >
                      <Search
                        className="products-search"
                        size="large"
                        searchData={filterTrucksData}
                        tableName={"trucksList"}
                        style={{ borderRadius: "5px" }}
                        enterButton
                        onChange={this.searchResultData}
                        allowClear
                        searchFields={searchFields}
                      />
                    </Col>
                  )}
                </Col>
                {showTab !== "listView" && clickedDate !== "" && (
                  <Col
                    xxl={{ span: 6 }}
                    xl={{ span: 6 }}
                    lg={{ span: 6 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        color: "#000",
                        fontSize: "20px",
                        fontWeight: "500",
                      }}
                    >
                      {clickedDate}
                    </span>
                  </Col>
                )}
                <Col
                  xxl={{ span: clickedDate !== "" ? 12 : 18 }}
                  xl={{ span: clickedDate !== "" ? 12 : 18 }}
                  lg={{ span: clickedDate !== "" ? 12 : 18 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={12}
                  style={{ marginLeft: "auto" }}
                >
                  <Row className="trucking-material-layout" gutter={[8, 0]}>
                    {showTab === "boardView" && (
                      <Col className="gridbtntool">
                        <Button
                          style={{
                            textAlign: "left",
                            border: "1px solid gray",
                            cursor: "pointer",
                            borderRadius: "5px",
                            height: "38px",
                          }}
                          onClick={() =>
                            this.exportData(this.state.selectedData)
                          }
                        >
                          <img
                            src={
                              selectedData.length > 0 ? excelIcon : excelIcon1
                            }
                            alt="excelIcon"
                            style={{
                              width: "24px",
                              height: "auto",
                              marginRight: "8px",
                            }}
                          />
                          <span
                            className="fontsettingg"
                            style={{
                              color: selectedData.length > 0 ? "green" : "gray",
                              fontSize: "14px",
                            }}
                          >
                            Export Excel
                          </span>
                        </Button>
                      </Col>
                    )}
                    {showTab !== "listView" && (
                      <Col className="gridbtntool">
                        <Button
                          className="filterButton"
                          style={{
                            borderRadius: "5px",
                            height: "38px",
                          }}
                          onClick={() => {
                            this.setState({
                              visible: true,
                              showMapFilter: false,
                            });
                          }}
                        >
                          <Row>
                            <Col>
                              <span
                                className="show-on-desktop"
                                style={{
                                  color:
                                    Object.keys(filterInfo)?.length > 0
                                      ? "red"
                                      : "#5a6067",
                                  fontWeight: "500",
                                  fontSize: "16px",
                                }}
                              >
                                {Object.keys(filterInfo)?.length > 0
                                  ? `${
                                      "Filter (" +
                                      Object.keys(filterInfo)?.length
                                    })`
                                  : "Filter"}
                              </span>
                            </Col>
                            <Col>
                              <IoFilterSharp
                                className="filterIcon"
                                style={{
                                  fontSize: "22px",
                                  marginLeft: "8px",
                                  color:
                                    Object.keys(filterInfo)?.length > 0
                                      ? "red"
                                      : "#5a6067",
                                }}
                              />
                            </Col>
                          </Row>
                        </Button>
                      </Col>
                    )}
                    <Col className="gridbtntool">
                      <Row
                        style={{
                          background: "#cecece",
                          height: "40px",
                          borderRadius: "6px",
                        }}
                      >
                        <Col style={{ marginTop: "4px" }}>
                          <Button
                            style={{
                              border: "none",
                              borderRadius: "5px",
                              padding: "5px 8px",
                              margin: "0px 5px",
                              background:
                                this.state.showTab === "listView"
                                  ? "#fff"
                                  : "transparent",
                              boxShadow: "none",
                              outline: "none",
                            }}
                            onClick={() => this.clickTabs("listView")}
                          >
                            <UnorderedListOutlined
                              style={{
                                fontSize: "22px",
                                color:
                                  this.state.showTab === "listView"
                                    ? "#586370"
                                    : "#586370",
                              }}
                            />
                          </Button>
                        </Col>
                        <Col style={{ marginTop: "4px" }}>
                          <Button
                            style={{
                              border: "none",
                              borderRadius: "5px",
                              padding: "5px 8px",
                              margin: "0px 5px",
                              background:
                                this.state.showTab === "boardView"
                                  ? "#fff"
                                  : "transparent",
                              boxShadow: "none",
                              outline: "none",
                            }}
                            onClick={() => this.clickTabs("boardView")}
                          >
                            <TbTruck
                              style={{
                                fontSize: "22px",
                                background:
                                  this.state.showTab === "boardView"
                                    ? "#fff"
                                    : "transparent",
                              }}
                            />
                          </Button>
                        </Col>
                        <Col style={{ marginTop: "4px" }}>
                          <Button
                            style={{
                              border: "none",
                              borderRadius: "5px",
                              padding: "5px 8px",
                              margin: "0px 5px",
                              background:
                                this.state.showTab === "showMapComponent"
                                  ? "#fff"
                                  : "transparent",
                              boxShadow: "none",
                              outline: "none",
                            }}
                            onClick={() => this.clickTabs("showMapComponent")}
                          >
                            <FaMap
                              style={{
                                fontSize: "22px",
                                color:
                                  this.state.showTab === "showMapComponent"
                                    ? "#586370"
                                    : "#586370",
                              }}
                            />
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {showTab === "boardView" ? (
                <Row style={{ overflow: "auto" }}>
                  <Col
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    style={{ overflow: "auto" }}
                  >
                    {errorLoading ? (
                      <ErrorView refreshCrm={this.onClickRefresh} type="CRM" />
                    ) : (
                      <Card
                        bodyStyle={{
                          padding: "0px",
                          overflowX: "auto",
                          height: "100%",
                        }}
                        style={{
                          background: "#eff1f3",
                          minHeight: "70vh",
                          // height: "100%",
                          marginTop: "10px",
                        }}
                        className="kanbanscrollCard"
                      >
                        <BaseTable
                          // className="customertablegrp table_laptopscreen"
                          columnsData={trucksColumns}
                          source={filterTrucksData}
                          rowSelection={rowSelection}
                          total={filterTrucksData?.length}
                          rowKey={(record) => record.truckingId}
                          handleTableChage={this.handleTableChage}
                          sizeRange={
                            window.screen.width > 1023
                              ? JSON.parse(
                                  localStorage.getItem("tableSizeRange")
                                )?.TNBSOperator
                              : filterTrucksData?.length
                          }
                          pagination={window.screen.width > 1023 ? true : false}
                        />
                      </Card>
                    )}
                  </Col>
                </Row>
              ) : showTab === "showMapComponent" ? (
                <Row>
                  <Col
                    xxl={{ span: 24, offset: 0 }}
                    xl={{ span: 24, offset: 0 }}
                    lg={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    xs={{ span: 24, offset: 0 }}
                    className="twistednailmap"
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    {this.showTrucksMap(filterTrucksData)}
                  </Col>
                </Row>
              ) : (
                <div>
                  {!ShowTable ? (
                    <>
                      <div className="total_Jobs">
                        <span>{calanderVal?.format("MMMM")}</span>{" "}
                        <span style={{ marginLeft: "10px" }}>{"-"}</span>
                        <span style={{ marginLeft: "10px" }}>
                          Total Jobs ({totalJobs?.length || 0})
                        </span>
                      </div>
                      <Calendar
                        value={calanderVal}
                        dateCellRender={this.dateCellRender}
                        onSelect={this.onSelectCalender}
                        onPanelChange={this.onPanelChange}
                      />
                    </>
                  ) : (
                    <>
                      <Row
                        onClick={() =>
                          this.setState({
                            ShowTable: false,
                            clickedDate: "",
                          })
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <BsArrowLeftShort
                          style={{ color: "#5f6c74", fontSize: "23px" }}
                          className="back-button-return-icon"
                        />
                        <p
                          style={{
                            color: "#808080",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                          className="back-button-return"
                        >
                          Return
                        </p>
                      </Row>

                      <Row>
                        <Col
                          xl={{ span: 24 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <Card
                            className="Dispatch-tool"
                            bodyStyle={{ padding: "0px" }}
                            data-aos="fade-up"
                            // style={{ background: "#eff1f3", minHeight: "80vh" }}
                          >
                            <BaseTable
                              className="dispatchtool_table table_laptopscreen"
                              columnsData={columns}
                              source={filterOrderData?.sort(
                                this.compareFilters
                              )}
                              handleTableChage={this.handleTableChage}
                              sizeRange={
                                window.screen.width > 1023
                                  ? JSON.parse(
                                      localStorage.getItem("tableSizeRange")
                                    )?.dispatchTool
                                  : filterOrderData?.length
                              }
                              pagination={
                                window.screen.width > 1023 ? true : false
                              }
                              total={filterOrderData?.length}
                              rowKey={(record) => record.id}
                            />
                          </Card>
                        </Col>
                      </Row>
                    </>
                  )}
                </div>
              )}
            </>
          )}

          {visible && (
            <Filter
              visible={visible}
              closeFilter={this.closeFilter}
              filterResultData={
                showMapFilter ? this.filterMapResultData : this.filterResultData
              }
              checkData={checkData}
              twistedOperator
              trucksData={this.props?.context?.trucksData}
              onClickClearFilter={this.onClickClearFilter}
              carrierData={filterCarrierData}
              dispatchToolStatus
              dispatchStatus={content}
              showMapFilter={showMapFilter}
            />
          )}

          {isModalVisible && (
            <BaseModal
              width="40%"
              // title="Add Order"
              title={"Update Order"}
              onCancel={() => {
                this.setState({ isModalVisible: false, editModal: false });
              }}
              formId="orderForm"
              className="antmodal_grid headermodal"
            >
              <Form
                onFinish={this.onFinish}
                id="orderForm"
                initialValues={true}
              >
                <Row gutter={[8, 5]}>
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 12 }}
                    sm={{ span: 20 }}
                    xs={{ span: 24 }}
                  >
                    <span
                      className="title_changes"
                      style={{ marginBottom: "10px" }}
                    >
                      Select Date
                    </span>
                    <Form.Item
                      name="specificDate"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Date!",
                        },
                      ]}
                      initialValue={
                        leadsData?.dispatchDate
                          ? moment(leadsData.dispatchDate)
                          : null
                      }
                    >
                      <DatePicker
                        className="form-input"
                        style={{ width: "100%" }}
                        placeholder="Please enter your dateAdded"
                        format={"MM/DD/YYYY"}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 12 }}
                    sm={{ span: 20 }}
                    xs={{ span: 24 }}
                  >
                    <span
                      className="title_changes"
                      style={{ marginBottom: "10px" }}
                    >
                      Select Trucks Type
                    </span>
                    <Form.Item
                      name="truckType"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Your Truck Type!",
                        },
                      ]}
                      initialValue={leadsData?.truckType}
                    >
                      <Select
                        allowClear
                        className="select_multiplequarytol"
                        style={{
                          height: "40px",
                          fontWeight: "300",
                        }}
                        mode="multiple"
                        placeholder="Please Select Your Type"
                      >
                        {leadsData?.truckType?.map((e) => (
                          <Option>{e}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 12 }}
                    sm={{ span: 20 }}
                    xs={{ span: 24 }}
                  >
                    <span
                      className="title_changes"
                      style={{ marginBottom: "10px" }}
                    >
                      Tons/Loads/Trucks Requested
                    </span>
                    <Form.Item
                      name="trucksRequested"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your TrucksRequested!",
                        },
                      ]}
                      initialValue={leadsData?.trucksRequested}
                    >
                      <InputNumber
                        min={1}
                        className="form-input"
                        addonAfter={selectAfterTruckPrice}
                        placeholder="Please Enter Trucking Price To Customer"
                        style={{
                          height: "40px",
                          width: "100%",
                        }}
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 12 }}
                    sm={{ span: 20 }}
                    xs={{ span: 24 }}
                  >
                    <span
                      className="title_changes"
                      style={{ marginBottom: "10px" }}
                    >
                      Dispatch Priority
                    </span>
                    <Form.Item
                      name="dispatchPriority"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Dispatch Priority !",
                        },
                      ]}
                      initialValue={leadsData?.dispatchPriority}
                    >
                      <Select
                        allowClear
                        className="select_Height"
                        style={{
                          height: "40px",
                          fontWeight: "300",
                        }}
                        placeholder="Please Select Dispatch Priority"
                      >
                        <Option value="1">1 (High Priority)</Option>
                        <Option value="2">2</Option>
                        <Option value="3">3</Option>
                        <Option value="4">4</Option>
                        <Option value="5">5 (Low Priority)</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </BaseModal>
          )}

          {isAssignTruckModalVisible && (
            <BaseModal
              title={
                <Row style={{ width: "100%" }}>
                  <Col
                    style={{
                      fontWeight: "500",
                      fontSize: "18px",
                    }}
                    className="dispatch-main-head"
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 12 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    Assign Truck
                  </Col>

                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <Row
                      style={{
                        width: "100%",
                        justifyContent: "right",
                      }}
                    >
                      <Col>
                        <Button
                          style={{
                            borderRadius: "5px",
                            height: "30px",
                          }}
                          className="dispatch-filter-icon"
                          onClick={() => {
                            this.setState({
                              visible: true,
                              showMapFilter: true,
                            });
                          }}
                        >
                          <Row>
                            <Col>
                              <span
                                style={{
                                  color: "#5a6067",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                }}
                                className="show-on-small-desktop"
                              >
                                Filter
                              </span>
                            </Col>
                            <Col>
                              <IoFilterSharp
                                style={{
                                  fontSize: "18px",
                                  marginLeft: "8px",
                                  marginTop: "1px",
                                }}
                              />
                            </Col>
                          </Row>
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              }
              onCancel={() => {
                this.setState({
                  isAssignTruckModalVisible: false,
                });
              }}
              footer={false}
              className="assignmodalbox antmodal_grid headermodal"
            >
              <Form>
                <Row gutter={[24, 0]}>
                  <Col
                    xxl={{ span: 24, offset: 0 }}
                    xl={{ span: 24, offset: 0 }}
                    lg={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    xs={{ span: 24, offset: 0 }}
                  >
                    {this.showSingleMap(modalData, filteredMapData)}
                  </Col>
                </Row>
              </Form>
            </BaseModal>
          )}

          {showDispatchMap && (
            <BaseModal
              title={
                <Row style={{ width: "100%" }}>
                  <Col
                    style={{
                      fontWeight: "500",
                      fontSize: "18px",
                    }}
                    className="dispatch-main-head"
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 12 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    Assign Truck
                  </Col>
                </Row>
              }
              onCancel={() => {
                this.setState({
                  showDispatchMap: false,
                  singleTruckData: {},
                  dispatchMapData: [],
                });
              }}
              footer={false}
              className="assignmodalbox antmodal_grid headermodal"
            >
              <Form>
                <Row gutter={[24, 0]}>
                  <Col
                    xxl={{ span: 24, offset: 0 }}
                    xl={{ span: 24, offset: 0 }}
                    lg={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    xs={{ span: 24, offset: 0 }}
                    style={{ minHeight: "38vw" }}
                  >
                    {this.showDispatchMap()}
                  </Col>
                </Row>
              </Form>
            </BaseModal>
          )}
          {dispatchFilter && (
            <Filter
              visible={dispatchFilter}
              closeFilter={this.closeFilter}
              filterResultData={this.filterDispatchData}
              onClearFilter={this.closeFilter}
              onClickClearFilter={this.onClickClearFilter}
              dispatchToolFilter
              filterData={filterCompanyData}
              checkData={filterPropTruckingData}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  totalResult: state?.reducer?.dispatchResult,
  loading: state?.reducer?.dispatchResult?.loading,
  errorLoading: state?.reducer?.dispatchResult?.error,
  leadUpdateInfo: state?.reducer?.leadUpdateInfo,
  totalTrucksListResult: state.reducer?.trucksListResult,
  // ticketsResult: state?.reducer?.ticketsResult,
  contactsData: state?.redcuer?.contactsData,
  trucksListInfo: state.reducer?.trucksListInfo,
  dropDownOptions: state.reducer.dropDownOptions,
  trucksData: state.reducer?.dropDownOptions?.totalData?.filter(
    (t) => t.typeofDropDown === "trucksType"
  ),
  orderResult: state?.reducer?.ordersResult,
  dispatchLoading: state?.reducer?.ordersResultInfo,
  singleLeadData: state.reducer?.leadData,
});

const mapDispatchToProps = (dispatch) => ({
  actionFetchGetDispatch: () => {
    dispatch(getDispatch());
  },
  actionUpdateJob: (id, data) => {
    dispatch(updateLead(id, data));
  },
  actionFetchTrucksList: () => {
    dispatch(getTrucksList());
  },
  actionFetchGetTickets: () => {
    dispatch(getTickets());
  },
  actionFetchGetContacts: () => {
    dispatch(getContacts());
  },
  actiongetDropDownOptions: () => {
    dispatch(getDropDownOptions());
  },
  actionFetchOwnerOperators: () => {
    dispatch(getOwnerOperators());
  },
  actionFetchGetOrders: (id) => {
    dispatch(getOrders());
  },
  actionFetchUpdateOrder: (id, data) => {
    dispatch(updateOrder(id, data));
  },
  actionDeleteOrders: (id) => {
    dispatch(deleteOrders(id));
  },
  actionFetchgetLead: (id) => {
    dispatch(getLead(id));
  },
  actionUpdateTrucksList: (id, data) => {
    dispatch(updateTrucksList(id, data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAppContext(DispatchTool));
