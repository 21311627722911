export const USAPhoneNumberFormate = (phone) => {
  const cleaned = `${phone}`.replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "" : "";
    const number = [
      intlCode,
      "(",
      match[2],
      ") ",
      match[3],
      "-",
      match[4],
    ].join("");

    return number;
  }
  return phone;
};
