import React, { Component } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import {
  Form,
  Input,
  Button,
  notification,
  Modal,
  Divider,
  Row,
  Col,
  message,
  Popover,
} from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
// import LOGO from "./Images/LOGO.png";
import PrimaryLogo from "./Images/login.png";

import { getDetails } from "./api/Apis";
import { withAppContext } from "./Store/AppContext";
import Amplify, { Auth } from "aws-amplify";
import AwsConfig from "./config/AwsConfigs";
import AppConfig from "./config/AppConfig";
Amplify.configure(AwsConfig);

class Login extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    popupVisible: false,
    loginEmail: "",
    loginPassword: "",
    resendLoader: false,
    showForget: false,
    showConfirm: false,
  };

  componentDidMount = async () => {
    document.title = "Twisted Nail - Login";
  };

  handleSubmit = async (e) => {
    try {
      this.setState({
        loading: true,
      });
      let lowerEmail = e.email.toLowerCase();
      const user = await Auth.signIn(lowerEmail, e.password);
      Cookies.set("jwt_Token", user.signInUserSession.idToken.jwtToken, {
        expires: 1,
      });
      const userDetails = Object.assign(
        {},
        {
          //token: user.signInUserSession.idToken.jwtToken,
          userid: user.username,
        }
      );
      await this.setState({
        loading: false,
      });
      this.getLoginDetails(userDetails);
    } catch (err) {
      if (err.code === "UserNotConfirmedException") {
        this.setState({
          loginEmail: e.email.toLowerCase(),
          loginPassword: e.password,
          popupVisible: true,
        });
        // this.handleConfirmPopUp(e.email.toLowerCase());

        // var code = prompt("Please enter the code which is sent to your mail");
        this.setState({
          loading: false,
          confirmationForm: true,
          errors: err.message,
        });
      } else if (err.code === "PasswordResetRequiredException") {
        this.setState({
          loading: false,
          errors: err.message,
        });
      } else if (err.code === "NotAuthorizedException") {
        this.setState({
          loading: false,
          errors: err.message,
        });
        notification["error"]({
          message: "Wrong email or password. Please try again.",
        });
      } else if (err.code === "UserNotFoundException") {
        this.setState({
          loading: false,
          errors: err.message,
        });
        notification["error"]({
          message: "Wrong email or password. Please try again.",
        });
      } else {
        this.setState({
          loading: false,
          errors: err.message,
        });
      }
    }
  };

  getLoginDetails = (userDetails) => {
    this.props.context.setUserLogin(true);
    getDetails(AppConfig["entityNames"]["users"], userDetails.userid)
      .then((result) => {
        if (result.success) {
          localStorage.setItem("userLogin", JSON.stringify(true));
          localStorage.setItem("userDetails", JSON.stringify(result.data));
          if (
            result?.data?.tableSizeRange &&
            Object.values(result?.data?.tableSizeRange)?.length > 0
          ) {
            localStorage.setItem(
              "tableSizeRange",
              JSON.stringify(result?.data?.tableSizeRange)
            );
          } else {
            localStorage.setItem("tableSizeRange", JSON.stringify({}));
          }
          this.props.context.getUserAccessInfo(result.data.userRoleInfo);
          if (result.data.userProfile === "Admin") {
            this.props.history.replace("/app/dashboard");
            this.setState({
              loading: false,
            });
          } else {
            this.RedirectPath(result.data.userRoleInfo);
          }
        }
      })
      .catch((error) => console.log(error));
  };

  RedirectPath = (roleId) => {
    getDetails(AppConfig["entityNames"]["roles"], roleId)
      .then((result) => {
        let filterInfo = [],
          filterData = [];
        if (result.success) {
          filterData = result.data.otherData?.filter(
            (e) => e.mainPannelSelected && e.title !== "Dashboard"
          );
          filterInfo = filterData[0]?.multiPannel?.filter(
            (e) => e?.selectedOptions?.length > 0
          );
          if (filterInfo?.length > 0) {
            this.props.history.replace(filterInfo[0]?.path);
          } else {
            this.props.history.replace("/app/dashboard");
          }
        }
      })
      .catch((error) => console.log(error));

    this.setState({
      loading: false,
    });
  };

  handleOk = async (e) => {
    try {
      this.setState({
        loading: true,
      });
      await Auth.confirmSignUp(this.state.loginEmail, e.code).then(
        async (result) => {
          this.doLogin();
        }
      );
    } catch (error) {
      this.setState({
        loading: false,
      });
      message.error("Invalid Confirm Code");
    }
  };

  doLogin = async () => {
    try {
      const user = await Auth.signIn(
        this.state.loginEmail,
        this.state.loginPassword
      );
      const userDetails = Object.assign(
        {},
        {
          token: user.signInUserSession.idToken.jwtToken,
          userid: user.username,
        }
      );
      this.setState({
        popupVisible: false,
      });
      this.getLoginDetails(userDetails);
    } catch (err) {
      message.error("Error: unable to login");
      this.setState({
        loading: false,
        errors: err.message,
        popupVisible: false,
      });
    }
  };

  handleResendConfirmCode = async () => {
    this.setState({
      resendLoader: true,
    });
    try {
      await Auth.resendSignUp(this.state.loginEmail);
      notification["success"]({
        message: "code resent successfully",
      });
      this.setState({
        resendLoader: false,
      });
    } catch (err) {
      console.log("error resending code: ", err);
      notification["error"]({
        message: "error resending code.",
      });
      this.setState({
        resendLoader: false,
      });
    }
  };

  sendEmailCode = (values) => {
    this.setState({
      loading: true,
    });
    let email = values.email;

    if (!email.includes("@") || !email.includes(".com")) {
      notification["error"]({
        message: "Please enter valid email.",
      });
      this.setState({
        loading: false,
      });
    } else {
      Auth.forgotPassword(email)
        .then((data) => {
          message.success("Code sent to email successfully");
          this.setState({
            showConfirm: true,
            loading: false,
            passwordError: "",
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            loading: false,
            passwordError: err.message,
          });
          if (err.code === "UserNotFoundException") {
            message.error(
              "This email address is not registered in our system."
            );
            // notification["error"]({
            //   message: "This email address is not registered in our system.",
            // });
          }
        });
    }
  };

  handleOnChange = (element, value, id) => {
    const valid =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(
        value
      );
    if (valid) {
      this.setState({ [id]: true, [element]: value });
    } else if (value === "") {
      this.setState({ [id]: "" });
    } else {
      this.setState({ [id]: false });
    }
    if (element === "confirmNewPassword") {
      if (value === this.state.newPassword) {
        this.setState({ passwordConfirm: true });
      }
    }
  };

  handleConfirmPasswordSubmit = (values) => {
    if (values["newPassword"] !== values["confirmNewPassword"]) {
      this.setState({
        passwordError: "Both passwords should match. Please check your entry.",
      });
      return;
    }
    this.setState({
      loading: true,
    });
    Auth.forgotPasswordSubmit(
      values["email"],
      values["confirmCode"],
      values["newPassword"]
    )
      .then((data) => {
        message.success("Password changed successfully");
        // notification["success"]({
        //   message: "Password Changed",
        //   description: "your password has been changed successfully.",
        // });
        this.setState({
          showConfirm: false,
          loading: false,
          showForget: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
          passwordError: err.message,
        });
      });
  };

  render() {
    let jwtToken = Cookies.get("jwt_Token");
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    if (userLogin && jwtToken !== undefined) {
      return <Redirect to="/app/dashboard" />;
    } else {
      <Redirect to="/login" />;
    }
    const { popupVisible, showForget, showConfirm } = this.state;
    const content = () => {
      return (
        <div>
          <p>-Minimum password length: 8</p>{" "}
          <p>-Required at least one uppercase letter</p>{" "}
          <p>-Required at least one lowercase letter</p>{" "}
          <p>-Required at least one number</p>{" "}
          <p>-Required at least one nonalphanumeric character</p>{" "}
        </div>
      );
    };
    return (
      <div
        // style={{ background: "#eff1f3 0% 0% no-repeat padding-box" }}
        className="admin-login-form"
      >
        <div
          className="login-fields"
          style={{ height: showConfirm ? "525px" : "450px" }}
        >
          <Form
            ref={this.formRef}
            onFinish={
              showForget
                ? showConfirm
                  ? this.handleConfirmPasswordSubmit
                  : this.sendEmailCode
                : this.handleSubmit
            }
          >
            <div style={{ textAlign: "center", marginBottom: "30px" }}>
              <img src={PrimaryLogo} alt="Logo" style={{ width: "100%" }} />
            </div>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input email",
                },
                {
                  type: "email",
                  message: "The input is not valid Corporate Email",
                },
              ]}
              //   initialValue={proConatct.town ? proConatct.town : ""}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder="Email address"
                className="form-input"
              />
            </Form.Item>
            {showConfirm ? (
              <>
                <Form.Item
                  name="confirmCode"
                  rules={[
                    {
                      required: true,
                      message: "Please input password",
                    },
                  ]}
                  style={{ marginTop: "25px" }}
                >
                  <Input
                    style={{
                      height: "40px",
                      width: "100%",
                    }}
                    placeholder="Input confirm Code"
                    className="form-input"
                  />
                </Form.Item>

                <Form.Item
                  name="newPassword"
                  rules={[
                    {
                      required: true,
                      pattern:
                        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])(?=.*[A-Z])(?=.*[a-z])[A-Za-z\d@$!%*#?&]{8,}$/,
                      message: "Password Critieria Doesn't Match",
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="New Password"
                    className="form-input"
                  />
                </Form.Item>

                <Form.Item
                  name="confirmNewPassword"
                  rules={[
                    {
                      required: true,
                      pattern:
                        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])(?=.*[A-Z])(?=.*[a-z])[A-Za-z\d@$!%*#?&]{8,}$/,
                      message: "Password Critieria Doesn't Match",
                    },
                  ]}
                  style={{ marginBottom: "0px" }}
                >
                  <Input.Password
                    placeholder="Confirm New Password"
                    className="form-input"
                  />
                </Form.Item>

                <Form.Item
                  className="password-row"
                  style={{ marginBottom: "0px", marginTop: "0px" }}
                >
                  <Popover
                    content={content}
                    placement="leftTop"
                    title="Password Criteria"
                  >
                    <Button
                      className="criteria"
                      type="text"
                      style={{
                        color: "#39acda",
                        marginLeft: "-15px",
                        background: "transparent",
                        border: "none",
                        float: "left",
                      }}
                    >
                      Password Criteria{" "}
                    </Button>
                  </Popover>
                </Form.Item>
              </>
            ) : (
              <>
                {!showForget && (
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input password",
                      },
                    ]}
                    style={{ marginTop: "25px" }}
                  >
                    <Input.Password
                      prefix={<LockOutlined />}
                      placeholder="Input password"
                      className="form-input"
                    />
                  </Form.Item>
                )}
                <Form.Item
                  style={{
                    marginTop: "-20px",
                  }}
                >
                  <Link
                    className="login-form-forgot"
                    onClick={() => {
                      this.setState({
                        showForget: !showForget,
                      });
                      this.formRef.current.setFieldsValue({
                        confirmNewPassword: "",
                        newPassword: "",
                        confirmCode: "",
                        email: "",
                      });
                    }}
                  >
                    {showForget ? "Back to login" : "Forgot password"}
                  </Link>
                </Form.Item>
              </>
            )}
            <Form.Item className="password-row">
              <Button
                type="primary"
                htmlType="submit"
                loading={this.state.loading}
                className="login-form-button"
                style={{
                  backgroundColor: "#586370",
                  border: "none",
                  height: "40px",
                }}
              >
                <span style={{ fontWeight: "500", color: "#FFF" }}>
                  {showForget ? "Submit" : "Login"}
                </span>
              </Button>
            </Form.Item>
            {/* <Link className="login-form-forgot" to="/register">
              <span
                style={{
                  fontSize: "16px",
                  color: "#868b99",
                  fontWeight: "400",
                }}
              >
                Don't have an account? Create one
              </span>
            </Link> */}
          </Form>
          <p
            style={{
              marginTop: "20px",
              fontSize: "16px",
              color: "#ffffff",
              textAlign: "center",
            }}
          >
            Version 01.09.12.02
          </p>
        </div>

        {popupVisible && (
          <Modal
            visible={popupVisible}
            title={
              <p
                className="font-family-style font-medium-size"
                style={{ color: "blue", margin: "0px" }}
              >
                Confirm SignUp
              </p>
            }
            onCancel={() =>
              this.setState({
                popupVisible: false,
              })
            }
            maskClosable={false}
            className="modal-black-close"
            footer={null}
          >
            <div style={{ padding: "5px 10px" }}>
              <span className="font-family-style text-size padding register-confirm">
                We have sent you a confirmation code to your email. Please enter
                the code below to complete your signup. Thank you.
              </span>
            </div>
            <Form
              className="padding font-family-style text-size"
              onFinish={this.handleOk}
              style={{ padding: "5px 10px" }}
            >
              <Form.Item
                name="code"
                rules={[
                  {
                    required: true,
                    message: "Please input the code.",
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder="Please input the code."
                  className="form-input"
                  maxLength="6"
                />
              </Form.Item>
              <Divider></Divider>
              <Row justify="end">
                <Col>
                  <Form.Item style={{ float: "right" }}>
                    <Button
                      type="danger"
                      onClick={this.handleResendConfirmCode}
                      loading={this.state.resendLoader}
                      style={{
                        height: "40px",
                        width: "100px",
                        borderRadius: "5px",
                        border: "1px solid #ff4d4f",
                        background: "#ff4d4f",
                      }}
                    >
                      <span
                        style={{
                          color: "#FFF",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                      >
                        Resend
                      </span>
                    </Button>
                  </Form.Item>
                </Col>
                <Col offset={1}>
                  <Form.Item style={{ float: "left" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={this.state.loading}
                      style={{
                        height: "40px",
                        width: "100px",
                        borderRadius: "5px",
                      }}
                    >
                      <span
                        style={{
                          color: "#FFF",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                      >
                        Submit
                      </span>
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        )}
      </div>
    );
  }
}
export default withAppContext(withRouter(Login));
