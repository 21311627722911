import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Row, Col } from "antd";
import { MdOutlineFullscreen } from "react-icons/md";

export default class ScrollComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      imageData: [],
      hasMore: true,
    };
  }

  componentDidMount = () => {
    this.fetchMoreData();
  };

  static getDerivedStateFromProps = (props, state) => {
    if (props?.images?.length > 0 && state?.items?.length === 0) {
      let items = [];
      if (props.images?.length > 20) {
        items = props.images?.slice(0, 20);
      } else {
        items = props.images;
      }
      return {
        imagesData: props.images,
        items,
      };
    }
    return null;
  };

  componentDidUpdate = (prevProps) => {
    const { images } = this.props;
    if (JSON.stringify(prevProps.images) !== JSON.stringify(images)) {
      let items = [];
      if (images?.length > 20) {
        items = images?.slice(0, 20);
      } else {
        items = images;
      }
      this.setState({
        imagesData: images,
        items,
      });
    }
  };

  fetchMoreData = () => {
    const { imagesData, items } = this.state;
    if (imagesData?.length === items?.length) {
      this.setState({
        hasMore: false,
      });
    }
    setTimeout(() => {
      this.setState({
        items: items.concat(imagesData.slice(items.length, items?.length + 20)),
      });
    }, 2000);
  };

  render() {
    const { items, hasMore } = this.state;
    return (
      <div>
        <InfiniteScroll
          dataLength={items?.length}
          next={this.fetchMoreData}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
        >
          <Row>
            {items.map((img, index) => (
              <Col
                xxl={{ span: 6 }}
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                md={{ span: 12 }}
                sm={{ span: 12 }}
                xs={{ span: 24 }}
                style={{
                  height: "200px",
                  marginBottom: "1vw",
                  width: "100%",
                }}
                key={img.id}
              >
                <div className="fullScreenImageBtn">
                  <MdOutlineFullscreen
                    style={{
                      fontSize: "40px",
                    }}
                    onClick={() => this.props.showModal(img)}
                  />
                </div>

                <div className="container">
                  <iframe
                    title="showTicket"
                    key={index}
                    className="image"
                    src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/${img.singlePdfTicket}`}
                    alt=""
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                    type="application/pdf"
                  />
                </div>
              </Col>
            ))}
          </Row>
        </InfiniteScroll>
      </div>
    );
  }
}
