import React, { useEffect } from "react";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Tabs,
  Input,
  DatePicker,
  Select,
  InputNumber,
  Checkbox,
  // Pagination,
} from "antd";
import AOS from "aos";
import "aos/dist/aos.css";
import { list, update } from "../../api/Apis";
import { BsArrowLeftShort } from "react-icons/bs";
import { EditOutlined } from "@ant-design/icons";
import BaseTable from "../../Common/BaseTable";
import ErrorView from "../../Common/ErrorView";
import Loading from "../../Common/Loading";
import appConfig from "../../config/AppConfig";
import { withAppContext } from "../../Store/AppContext";
import BaseModal from "../../Common/BaseModal";
import TextArea from "antd/lib/input/TextArea";
import NumberFormat from "react-number-format";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import moment from "moment";
// import Nodata from "./nodata";
const { TabPane } = Tabs;

function SalesPersonDetails(props) {
  const [loading, setLoading] = useState(false);
  const [salesData, setSalesData] = useState([]);
  const [filterJobDetails, setFilterJobDetails] = useState([]);
  const [errorLoading, setErrorLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(false);
  const [latitude, setLatitude] = useState(false);
  const [longitude, setLongitude] = useState(false);
  const [tabKey, setTabKey] = useState("1");
  const [customerData, setCustomerData] = useState([]);
  const [contractMinimum, setContractMinimum] = useState(false);

  useEffect(() => {
    const { match } = props;
    const { params } = match;
    const { id } = params;
    props?.context?.getTrucksType();
    props?.context?.updateSalesId(id);
    getSalesProfile(id);
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSalesProfile = (id, filterDate) => {
    setLoading(true);
    let filterYear = {};
    filterYear = filterDate ? filterDate : moment().format("YYYY");
    list(appConfig["entityNames"]["getSalesPerson"] + `/${id}`, { filterYear })
      .then((res) => {
        let customerData = res.data?.jobDetails?.filter(
          (value, index, self) =>
            index === self.findIndex((r) => r?.companyId === value?.companyId)
        );

        customerData.push({
          companyId: "1",
          companyName: "All",
        });

        if (res.success) {
          setErrorLoading(false);
          setSalesData(res.data);
          setContractMinimum(res.data.contractMinimum);
          setFilterJobDetails(res?.data?.jobDetails);
          setCustomerData(customerData);
          setLoading(false);
          // onChangeYear(moment(), res.data);
        } else {
          setErrorLoading(true);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setErrorLoading(true);
      });
  };

  const onFinish = (values) => {
    setModalVisible(false);
    let data = {
      entityData: {
        firstName: values["firstName"],
        lastName: values["lastName"],
        email: values["email"],
        phone: values["phone"],
        // commission: values["commission"],
        remarks: values["remarks"],
        address: selectedAddress,
        lat: latitude,
        lng: longitude,
        salesCommission: values["salesCommission"],
        contractMinimum,
      },
    };
    update(appConfig["entityNames"]["salesPerson"], salesData?.id, data)
      .then((res) => {
        if (res.success) {
          setLoading(false);
          getSalesProfile(props.match.params.id);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  // const onChangeYear = (e, sales) => {
  //   const invoiceData =
  //     sales !== undefined && sales !== ""
  //       ? sales?.invoiceDetails
  //       : salesData?.invoiceDetails;
  //   let revenueData = [];
  //   let totaData = [];
  //   let current = moment(e).format("YYYY");
  //   invoiceData?.map((e) => {
  //     if (moment(e.createdAt).format("YYYY") === current) {
  //       revenueData[moment(e.createdAt).format("MMMM")] = revenueData[
  //         moment(e.createdAt).format("MMMM")
  //       ]
  //         ? revenueData[moment(e.createdAt).format("MMMM")]
  //         : [];
  //       revenueData[moment(e.createdAt).format("MMMM")].push(e.ticketsTotal);
  //       totaData.push(e.ticketsTotal);
  //     }
  //     return true;
  //   });
  //   setLifeTimeRevenue(totaData);
  //   handleRevenueMerics(revenueData);
  // };

  // const handleRevenueMerics = (revenueData) => {
  //   let data = {};
  //   Object.keys(revenueData)?.map((e) => {
  //     data[e] = data[e] ? data[e] : [];
  //     let revenueValue = Object.values(revenueData[e])?.reduce(
  //       (a, v) => (a = a + parseInt(v)),
  //       0
  //     );
  //     data[e].push({
  //       revenue: revenueValue,
  //       avgRevenue: parseFloat(
  //         revenueValue / Object.values(revenueData[e]).length
  //       ),
  //       commissions: commisionsData?.filter(
  //         (c) =>
  //           revenueValue >=
  //             parseInt(c?.minRevenueTier?.split("$")[1]?.replace(",", "")) &&
  //           revenueValue <=
  //             parseInt(c?.maxRevenueTier?.split("$")[1]?.replace(",", ""))
  //       )[0]?.rate,
  //     });
  //     return e;
  //   });
  //   handleCommissionsEarned(data);
  //   setRevenue(data);
  //   setLoading(false);
  // };

  const onClickReturn = () => {
    props.history.goBack();
  };

  const onRefresh = () => {
    setLoading(true);
    const { match } = props;
    const { params } = match;
    const { id } = params;
    getSalesProfile(id);
    props?.context?.updateSalesId(id);
  };

  const getAddress = (address, latLng, id) => {
    setSelectedAddress(address.label);
  };

  const handleSelect = (address) => {
    geocodeByAddress(address.label)
      .then((results) => {
        getLatLng(results[0]).then(({ lat, lng }) => {
          setLatitude(lat);
          setLongitude(lng);
        });
      })
      .catch((error) => console.error("Error", error));
  };

  const handleSelectCustomer = (e) => {
    let filterSalesData = [];
    if (parseInt(e) === 1) {
      console.log("check");
      filterSalesData = salesData?.jobDetails;
    } else {
      filterSalesData = salesData?.jobDetails?.filter(
        (element) => element?.companyId === e
      );
    }
    setFilterJobDetails(filterSalesData);
  };

  const onClickEdit = () => {
    setModalVisible(true);
    setLatitude(salesData?.lat);
    setLongitude(salesData?.lng);
    setSelectedAddress(salesData?.address);
  };

  const jobsColumns = [
    {
      title: "JOB#",
      dataIndex: "jobNumber",
      key: "jobNumber",
      sorter: false,
      className: "col_styling",
      onCell: (record) => ({
        onClick: () => {
          props.history.push(`/app/jobs/job-profile/${record.id}`);
        },
      }),
      render: (jobNumber, record) => {
        return (
          <>
            <span
              style={{
                textTransform: "capitalize",
                color: "#474747",
                fontSize: "16px",
                fontFamily: "sans-serif",
              }}
              className="col_styling table-font-mobile"
            >
              {jobNumber}
            </span>
          </>
        );
      },
    },
    {
      title: "JOB NAME",
      dataIndex: "",
      key: "",
      sorter: false,
      className: "col_styling",
      onCell: (record) => ({
        onClick: () => {
          props.history.push(`/app/jobs/job-profile/${record.id}`);
        },
      }),
      render: (record) => {
        return (
          <>
            <span
              style={{
                textTransform: "capitalize",
                color: "#474747",
                fontSize: "16px",
                fontFamily: "sans-serif",
              }}
              className="col_styling table-font-mobile"
            >
              {record?.jobName ? record?.jobName : record?.jobNumber}
            </span>
          </>
        );
      },
    },
    {
      title: "CUSTOMER",
      dataIndex: "companyName",
      key: "companyName",
      sorter: false,
      className: "col_styling",
      onCell: (record) => ({
        onClick: () =>
          props.history.push(
            `/app/customer-account-profile/${record.companyId}`
          ),
      }),
      render: (companyName, record) => {
        return (
          <>
            <span
              style={{
                textTransform: "capitalize",
                color: "#474747",
                fontSize: "16px",
                fontFamily: "sans-serif",
              }}
              className="col_styling table-font-mobile"
            >
              {companyName}
            </span>
          </>
        );
      },
    },
    {
      title: "REVENUE",
      dataIndex: "id",
      key: "id",
      sorter: false,
      className: "col_styling",
      onCell: (record) => ({
        onClick: () => {
          props.history.push(`/app/jobs/job-profile/${record.id}`);
        },
      }),
      render: (id) => {
        return (
          <>
            <span
              style={{
                textTransform: "capitalize",
                color: "#474747",
                fontSize: "16px",
                fontFamily: "sans-serif",
              }}
              className="col_styling table-font-mobile"
            >
              {salesData?.invoiceDetails?.filter((e) => e?.jobId === id)
                ?.length > 0
                ? `$ ` +
                  salesData?.invoiceDetails
                    ?.filter((e) => e?.jobId === id)
                    ?.reduce((a, v) => (a = a + parseInt(v?.ticketsTotal)), 0)
                    .toLocaleString("en-US")
                : ""}
            </span>
          </>
        );
      },
    },
  ];
  const revenueColumns = [
    {
      title: "MONTHS",
      dataIndex: "month",
      key: "month",
      sorter: false,
      className: "col_styling",
      // onCell: (record) => ({
      //   onClick: () => {
      //     props.history.push(`/app/jobs/job-profile/${record.id}`);
      //   },
      // }),
      render: (month) => {
        return (
          <>
            <span
              style={{
                textTransform: "capitalize",
                color: "#474747",
                fontSize: "16px",
                fontFamily: "sans-serif",
              }}
              className="col_styling table-font-mobile"
            >
              {month}
            </span>
          </>
        );
      },
    },
    {
      title: "JOB TYPE",
      dataIndex: "",
      key: "",
      sorter: false,
      className: "col_styling",
      // onCell: (record) => ({
      //   onClick: () => {
      //     props.history.push(`/app/jobs/job-profile/${record.id}`);
      //   },
      // }),
      render: () => {
        return (
          <>
            <span
              style={{
                textTransform: "capitalize",
                color: "#474747",
                fontSize: "16px",
                fontFamily: "sans-serif",
              }}
              className="col_styling table-font-mobile"
            >
              <div style={{ marginBottom: "3px" }}>Material</div>
              <div>Trucking</div>
            </span>
          </>
        );
      },
    },
    {
      title: "REVENUE",
      dataIndex: "materialSales",
      key: "materialSales",
      sorter: false,
      className: "col_styling",
      // onCell: (record) => ({
      //   onClick: () => {
      //     props.history.push(`/app/jobs/job-profile/${record.id}`);
      //   },
      // }),
      render: (materialSales, record) => {
        return (
          <>
            <span
              style={{
                textTransform: "capitalize",
                color: "#474747",
                fontSize: "16px",
                fontFamily: "sans-serif",
              }}
              className="col_styling table-font-mobile"
            >
              <div style={{ marginBottom: "3px" }}>
                {`$` + materialSales?.toLocaleString("en-US")}
              </div>
              <div>{`$` + record?.truckingSales?.toLocaleString("en-US")}</div>
            </span>
          </>
        );
      },
    },
    {
      title: "COMMISSION AMOUNT",
      dataIndex: "salesCommissionAmount",
      key: "salesCommissionAmount",
      sorter: false,
      className: "col_styling",
      render: (salesCommissionAmount) => {
        return (
          <>
            <span
              style={{
                textTransform: "capitalize",
                color: "#474747",
                fontSize: "16px",
                fontFamily: "sans-serif",
              }}
              className="col_styling table-font-mobile"
            >
              <div style={{ marginBottom: "3px" }}>
                {`$` + salesCommissionAmount?.toLocaleString("en-US")}
              </div>
              
            </span>
          </>
        );
      },
    },
  ];

  const metricColumns = [
    {
      title: "MONTHS",
      dataIndex: "month",
      key: "month",
      sorter: false,
      className: "col_styling",
      render: (month) => {
        return (
          <>
            <span
              style={{
                textTransform: "capitalize",
                color: "#474747",
                fontSize: "16px",
                fontFamily: "sans-serif",
              }}
              className="col_styling table-font-mobile"
            >
              {month}
            </span>
          </>
        );
      },
    },
    {
      title: "JOB TYPE",
      dataIndex: "",
      key: "",
      sorter: false,
      className: "col_styling",
      // onCell: (record) => ({
      //   onClick: () => {
      //     props.history.push(`/app/jobs/job-profile/${record.id}`);
      //   },
      // }),
      render: () => {
        return (
          <>
            <span
              style={{
                textTransform: "capitalize",
                color: "#474747",
                fontSize: "16px",
                fontFamily: "sans-serif",
              }}
              className="col_styling table-font-mobile"
            >
              <div style={{ marginBottom: "3px" }}>Material</div>
              <div>Trucking</div>
            </span>
          </>
        );
      },
    },
    {
      title: "MONTHLY GROWTH",
      dataIndex: "materialMonthlyGrowth",
      key: "materialMonthlyGrowth",
      sorter: false,
      className: "col_styling",
      render: (materialMonthlyGrowth, record) => {
        return (
          <>
            <span
              style={{
                textTransform: "capitalize",
                color: "#474747",
                fontSize: "16px",
                fontFamily: "sans-serif",
              }}
              className="col_styling table-font-mobile"
            >
              <div style={{ marginBottom: "3px" }}>
                {materialMonthlyGrowth !== undefined
                  ? materialMonthlyGrowth?.toLocaleString("en-US") + " %"
                  : 0}
              </div>
              <div>
                {record?.truckMonthlyGrowth !== undefined
                  ? record?.truckMonthlyGrowth?.toLocaleString("en-US") + " %"
                  : 0}
              </div>
            </span>
          </>
        );
      },
    },
    {
      title: "AVG REVENUE PER MONTH",
      dataIndex: "mAvgRevenue",
      key: "mAvgRevenue",
      sorter: false,
      className: "col_styling",
      render: (mAvgRevenue, record) => {
        return (
          <>
            <span
              style={{
                textTransform: "capitalize",
                color: "#474747",
                fontSize: "16px",
                fontFamily: "sans-serif",
              }}
              className="col_styling table-font-mobile"
            >
              <div style={{ marginBottom: "3px" }}>
                {`$` + parseInt(mAvgRevenue)?.toLocaleString("en-US")}
              </div>
              <div>
                {`$` + parseInt(record?.tAvgRevenue)?.toLocaleString("en-US")}
              </div>

              {/*  */}
            </span>
          </>
        );
      },
    },
  ];

  // const handlePagination = async (page, pageSize, load) => {
  //   if (page <= 1) {
  //     setMinValue(0);
  //     setMaxValue(pageSize);
  //   } else {
  //     setMinValue((page - 1) * pageSize);
  //     setMaxValue(page * pageSize);
  //   }
  //   setCurrentPage(page);
  //   setPerpageSize(pageSize);
  // };

  // const months = [
  //   "January",
  //   "February",
  //   "March",
  //   "April",
  //   "May",
  //   "June",
  //   "July",
  //   "August",
  //   "September",
  //   "October",
  //   "November",
  //   "December",
  // ];
  // const { commisionsData } = props?.context;

  // const revenueData = (i) => {
  //   if (i !== 0) {
  //     return (
  //       parseFloat(
  //         ((revenue[months[i]][0]?.revenue -
  //           revenue[months[i - 1]][0]?.revenue) /
  //           revenue[months[i - 1]][0]?.revenue) *
  //           100
  //       ).toFixed(2) + "%"
  //     );
  //   } else {
  //     return <span style={{ color: "#ddd" }}>Not Applicable</span>;
  //   }
  // };

  // const handleCommissionsEarned = (revenue) => {
  //   let count = 0;
  //   Object.keys(revenue)?.map((e) =>
  //     Object.values(
  //       revenue[e].map((r) => {
  //        v
  //         return r;
  //       })
  //     )
  //   );
  //   return `$` + count?.toLocaleString("en-US");
  // };

  const handleTableChage = (data, type) => {
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    localStorage.setItem(
      "tableSizeRange",
      JSON.stringify({ ...tableSizeRange, [type]: data })
    );
    props.context.updateUserSettings({
      ...tableSizeRange,
      [type]: data,
    });
  };

  return (
    <div>
      <Loading enableLoading={loading} />
      <Row
        style={{
          backgroundColor: "#fafafa",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "60px",
          width: "100%",
        }}
      >
        <Row onClick={onClickReturn} style={{ cursor: "pointer" }}>
          <BsArrowLeftShort
            style={{ color: "#5f6c74", fontSize: "23px" }}
            className="back-button-return-icon"
          />
          <p
            style={{ color: "#808080", fontSize: "16px", fontWeight: "600" }}
            className="back-button-return"
          >
            Return
          </p>
        </Row>
      </Row>
      <div
        style={{
          background: "#fafafa",
          minHeight: "75vh",
        }}
      >
        {errorLoading ? (
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <ErrorView refreshLeadDetails={onRefresh} type="LeadDetails" />
          </Col>
        ) : (
          <Row style={{ marginBottom: "1vw" }} gutter={[12, 0]}>
            <Col
              xxl={{ span: 8 }}
              xl={{ span: 8 }}
              lg={{ span: 8 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              data-aos="fade-right"
            >
              <Card>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "-12px",
                      right: "0px",
                      border: "0.5px solid #aaa8a8",
                      width: "2vw",
                      height: "2vw",
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      alignContent: "center",
                      justifyContent: " center",
                      padding: "8px",
                      borderRadius: "100%",
                    }}
                    className="edit-details-button"
                  >
                    <Button
                      style={{
                        border: "none",
                        cursor: "pointer",
                        // marginTop: "-90px",
                        backgroundColor: "transparent",
                      }}
                      onClick={() => onClickEdit()}
                    >
                      <EditOutlined
                        style={{
                          color: "#586370",
                          fontSize: "100%",
                          fontWeight: "600",
                        }}
                      />
                    </Button>
                  </div>
                  <p
                    className="detail-heading"
                    style={{ fontSize: "16px", color: "#5f6c74" }}
                  >
                    Sales Person Account
                  </p>
                  <div className="linkcompany">
                    <p
                      className="detail-ans"
                      style={{
                        fontSize: "20px",
                        marginTop: "10px",
                        textTransform: "capitalize",
                        marginBottom: "5px",
                        color: "#0d0f10",
                        fontWeight: "800",
                      }}
                    >
                      {salesData?.firstName !== undefined &&
                        salesData?.firstName + " " + salesData?.lastName}
                    </p>
                  </div>
                </div>
              </Card>

              <Card style={{ marginTop: "25px" }}>
                <Row>
                  <Col span={24}>
                    <p
                      span={24}
                      className="detail-ans"
                      style={{
                        fontWeight: "600",
                        fontSize: "18px",
                        borderBottom: "1px solid #afafaf",
                        width: "fit-content",
                      }}
                    >
                      Sales Person Details
                    </p>
                    <Row
                      xxl={{ span: 24 }}
                      xl={{ span: 24 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      style={{ marginBottom: "5px" }}
                    ></Row>
                    <Row
                      xxl={{ span: 24 }}
                      xl={{ span: 24 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      style={{
                        marginBottom: "5px",
                        marginTop: "5px",
                      }}
                    >
                      <Col
                        xxl={{ span: 12 }}
                        xl={{ span: 12 }}
                        lg={{ span: 12 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                        className="detail-heading table-font-mobile"
                        style={{ fontSize: "16px" }}
                      >
                        Address
                      </Col>
                      <Col span={12} className="detail-ans">
                        {salesData?.address && salesData?.address}
                      </Col>
                    </Row>
                    <Row span={24} style={{ marginBottom: "5px" }}>
                      <Col
                        span={12}
                        className="detail-heading table-font-mobile"
                        style={{ fontSize: "16px" }}
                      >
                        Email
                      </Col>
                      <Col span={12} className="detail-ans">
                        {salesData?.email && salesData?.email}
                      </Col>
                    </Row>

                    <Row span={24} style={{ marginBottom: "5px" }}>
                      <Col
                        span={12}
                        className="detail-heading table-font-mobile"
                        style={{ fontSize: "16px" }}
                      >
                        Phone
                      </Col>
                      <Col span={12} className="detail-ans">
                        {salesData?.phone && salesData?.phone}
                      </Col>
                    </Row>

                    <Row span={24} style={{ marginBottom: "5px" }}>
                      <Col
                        span={12}
                        className="detail-heading table-font-mobile"
                        style={{ fontSize: "16px" }}
                      >
                        Sales Commission
                      </Col>
                      <Col span={12} className="detail-ans">
                        {salesData?.salesCommission &&
                          salesData?.salesCommission}
                        %
                      </Col>
                    </Row>


                    <Row span={24} style={{ marginBottom: "5px" }}>
                      <Col
                        span={12}
                        className="detail-heading table-font-mobile"
                        style={{ fontSize: "16px" }}
                      >
                        T Lifetime Revenue
                      </Col>
                      <Col span={12} className="detail-ans">
                        {salesData?.truckLifetimeRevenue?.toLocaleString(
                          "en-US"
                        ) &&
                          `$${salesData?.truckLifetimeRevenue?.toLocaleString(
                            "en-US"
                          )}`}
                      </Col>
                    </Row>

                    <Row span={24} style={{ marginBottom: "5px" }}>
                      <Col
                        span={12}
                        className="detail-heading table-font-mobile"
                        style={{ fontSize: "16px" }}
                      >
                        M Lifetime Revenue
                      </Col>
                      <Col span={12} className="detail-ans">
                        {salesData?.materialLifetimeRevenue?.toLocaleString(
                          "en-US"
                        ) &&
                          `$${salesData?.materialLifetimeRevenue?.toLocaleString(
                            "en-US"
                          )}`}
                      </Col>
                    </Row>

                    <Row span={24} style={{ marginBottom: "5px" }}>
                      <Col
                        span={12}
                        className="detail-heading table-font-mobile"
                        style={{ fontSize: "16px" }}
                      >
                        T Commissions Earned
                      </Col>
                      <Col span={12} className="detail-ans">
                        {salesData?.truckCommisionsEarned?.toLocaleString(
                          "en-US"
                        ) !== undefined &&
                          `$${salesData?.truckCommisionsEarned?.toLocaleString(
                            "en-US"
                          )}`}
                      </Col>
                    </Row>

                    <Row span={24} style={{ marginBottom: "5px" }}>
                      <Col
                        span={12}
                        className="detail-heading table-font-mobile"
                        style={{ fontSize: "16px" }}
                      >
                        M Commissions Earned
                      </Col>
                      <Col span={12} className="detail-ans">
                        {salesData?.materialCommisionsEarned?.toLocaleString(
                          "en-US"
                        ) !== undefined &&
                          `$${salesData?.materialCommisionsEarned?.toLocaleString(
                            "en-US"
                          )}`}
                      </Col>
                    </Row>
                  </Col>

                  <p
                    className="detail-ans"
                    style={{
                      fontWeight: "600",
                      marginBottom: "8px",
                      borderBottom: "1px solid rgb(175, 175, 175)",
                      width: "fit-content",
                    }}
                  >
                    Remarks
                  </p>
                  <div
                    style={{
                      minHeight: "65px",
                      borderRadius: "3px",
                      backgroundColor: "#e3e7ea",
                      padding: "6px",
                      marginTop: "5px",
                      width: "100%",
                    }}
                    className="detail-ans"
                  >
                    {salesData?.remarks && salesData?.remarks}
                  </div>
                </Row>
              </Card>
            </Col>

            <Col
              xxl={{ span: 16, offset: 0 }}
              xl={{ span: 16, offset: 0 }}
              lg={{ span: 16, offset: 0 }}
              md={{ span: 16, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              xs={{ span: 24, offset: 0 }}
              // span={15}
              // offset={1}
              data-aos="fade-left"
            >
              <Card
                className="card_virtual"
                bodyStyle={{ padding: "0px" }}
                style={{ background: "#eff1f3" }}
              >
                <Tabs
                  defaultActiveKey="1"
                  className="ant-card companydetailtab"
                  style={{ borderRadius: "5px", padding: "5px" }}
                  onChange={(e) => {
                    setTabKey(e);
                    e === "1" && setFilterJobDetails(salesData?.jobDetails);
                    // onChangeYear(moment(), undefined);
                  }}
                  tabBarExtraContent={
                    tabKey === "1" ? (
                      <Select
                        size="large"
                        showSearch
                        allowClear
                        className="select_Height sales-person-details"
                        placeholder="Please Select A Customer Name"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onChange={handleSelectCustomer}
                        style={{ width: "250px" }}
                        defaultValue={"1"}
                      >
                        {customerData
                          ?.sort((a, b) => (a.companyId > b.companyId ? 1 : -1))
                          ?.map((d, index) => (
                            <Select.Option key={index} value={d.companyId}>
                              {d.companyName}
                            </Select.Option>
                          ))}
                      </Select>
                    ) : (
                      (tabKey === "2" || tabKey === "3") && (
                        // tabKey === "2" && (
                        <DatePicker
                          defaultValue={moment()}
                          allowClear
                          picker="year"
                          bordered={true}
                          onChange={(e) =>
                            getSalesProfile(
                              props?.match?.params?.id,
                              moment(e).format("YYYY")
                            )
                          }
                          disabledDate={(current) => {
                            let customDate = moment().format("YYYY-MM-DD");
                            return (
                              current &&
                              current > moment(customDate, "YYYY-MM-DD")
                            );
                          }}
                        />
                      )
                    )
                  }
                >
                  <TabPane tab={<span>Jobs</span>} key="1">
                    <BaseTable
                      className="table_system_users table_crm table_laptopscreen"
                      columnsData={jobsColumns}
                      source={filterJobDetails.filter((j) => {
                        return (
                          salesData?.invoiceDetails?.filter(
                            (e) => e.jobId === j.id
                          )?.length > 0
                        );
                      })}
                      rowKey={(record) => record.id}
                      handleTableChage={(e) => handleTableChage(e, "salesJobs")}
                      sizeRange={
                        window.screen.width > 1023
                          ? JSON.parse(localStorage.getItem("tableSizeRange"))
                              ?.salesJobs
                          : filterJobDetails.filter((j) => {
                              return (
                                salesData?.invoiceDetails?.filter(
                                  (e) => e.jobId === j.id
                                )?.length > 0
                              );
                            }).length
                      }
                      pagination={window.screen.width > 1023 ? true : false}
                    />
                  </TabPane>

                  <TabPane tab={<span>Revenue</span>} key="2">
                    <BaseTable
                      className="table_system_users table_crm table_laptopscreen"
                      columnsData={revenueColumns}
                      source={salesData?.revenueReport?.filter(
                        (e) => e.materialSales !== 0
                      )}
                      handleTableChage={(e) =>
                        handleTableChage(e, "salesRevenue")
                      }
                      sizeRange={
                        window.screen.width > 1023
                          ? JSON.parse(localStorage.getItem("tableSizeRange"))
                              ?.salesRevenue
                          : salesData?.revenueReport?.filter(
                              (e) => e.materialSales !== 0
                            ).length
                      }
                      pagination={window.screen.width > 1023 ? true : false}
                      rowKey={(record) => record.id}
                    />
                    {/* {Object.keys(revenue)?.length > 0 ? (
                      <div className="ant-table-wrapper table_system_users table_crm table_laptopscreen">
                        <div className="ant-spin-nested-loading">
                          <div className="ant-spin-container">
                            <div className="ant-table-content ant-table invoicetablennd">
                              <table style={{ tableLayout: "auto" }}>
                                <thead className="ant-table-thead">
                                  <tr>
                                    <th
                                      className="ant-table-cell col_styling"
                                      style={{ width: "80px" }}
                                    >
                                      MONTHS
                                    </th>
                                    <th
                                      className="ant-table-cell col_styling"
                                      style={{ width: "180px" }}
                                    >
                                      REVENUE
                                    </th>
                                    <th
                                      className="ant-table-cell col_styling"
                                      style={{ width: "180px" }}
                                    >
                                      COMMISSIONS
                                    </th>
                                    <th
                                      className="ant-table-cell col_styling"
                                      style={{ width: "180px" }}
                                    >
                                      COMMISSION AMOUNT
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="ant-table-tbody">
                                  {Object.keys(revenue)
                                    ?.slice(minValue, maxValue)
                                    ?.sort(function (a, b) {
                                      return (
                                        months.indexOf(a) - months.indexOf(b)
                                      );
                                    })
                                    ?.map((e) =>
                                      Object.values(
                                        revenue[e].map((r) => (
                                          <tr className="ant-table-row ant-table-row-level-0">
                                            <td className="ant-table-cell col_styling">
                                              <div className="txtinvoiceprt">
                                                {e}
                                              </div>
                                            </td>
                                            <td className="ant-table-cell col_styling">
                                              <div className="txtinvoiceprt">
                                                {`$` +
                                                  r.revenue.toLocaleString(
                                                    "en-US"
                                                  )}
                                              </div>
                                            </td>
                                            <td className="ant-table-cell col_styling">
                                              <div className="txtinvoiceprt">
                                                {r.commissions}
                                              </div>
                                            </td>
                                            <td className="ant-table-cell col_styling">
                                              <div className="txtinvoiceprt">
                                                {`$` +
                                                  (
                                                    parseFloat(
                                                      r?.revenue *
                                                        r?.commissions?.replace(
                                                          "%",
                                                          ""
                                                        )
                                                    ) / 100
                                                  ).toLocaleString("en-US")}
                                              </div>
                                            </td>
                                          </tr>
                                        ))
                                      )
                                    )}
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td colSpan={4} style={{ padding: "0px" }}>
                                      <div className="disp_fixed disp_fixedgrp">
                                        <Pagination
                                          style={{ float: "right" }}
                                          total={Object.keys(revenue)?.length}
                                          showTotal={(total) =>
                                            `${total} items`
                                          }
                                          current={currentPage}
                                          defaultPageSize={perpageSize}
                                          onChange={handlePagination}
                                          defaultCurrent={1}
                                          showSizeChanger
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <Nodata style={{ width: "100%" }} />
                    )} */}
                  </TabPane>

                  <TabPane tab={<span>Metrics</span>} key="3">
                    <BaseTable
                      className="table_system_users table_crm table_laptopscreen"
                      columnsData={metricColumns}
                      source={salesData?.revenueReport?.filter(
                        (e) => e.materialSales !== 0
                      )}
                      handleTableChage={(e) =>
                        handleTableChage(e, "salesMetrics")
                      }
                      sizeRange={
                        window.screen.width > 1023
                          ? JSON.parse(localStorage.getItem("tableSizeRange"))
                              ?.salesMetrics
                          : salesData?.revenueReport?.filter(
                              (e) => e.materialSales !== 0
                            ).length
                      }
                      pagination={window.screen.width > 1023 ? true : false}
                      rowKey={(record) => record.id}
                    />
                    {/* {Object.keys(revenue)?.length > 0 ? (
                      <div className="ant-table-wrapper table_system_users table_crm table_laptopscreen">
                        <div className="ant-spin-nested-loading">
                          <div className="ant-spin-container">
                            <div className="ant-table-content ant-table invoicetablennd">
                              <table style={{ tableLayout: "auto" }}>
                                <thead className="ant-table-thead">
                                  <tr>
                                    <th
                                      className="ant-table-cell col_styling"
                                      style={{ width: "80px" }}
                                    >
                                      MONTHS
                                    </th>
                                    <th
                                      className="ant-table-cell col_styling"
                                      style={{ width: "180px" }}
                                    >
                                      MONTHLY GROWTH %
                                    </th>
                                    <th
                                      className="ant-table-cell col_styling"
                                      style={{ width: "180px" }}
                                    >
                                      AVG REVENUE PER JOB
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="ant-table-tbody">
                                  {Object.keys(revenue)
                                    ?.slice(minValue, maxValue)
                                    ?.sort(function (a, b) {
                                      return (
                                        months.indexOf(a) - months.indexOf(b)
                                      );
                                    })
                                    ?.map((e, i) =>
                                      Object.values(
                                        revenue[e].map((r) => (
                                          <tr className="ant-table-row ant-table-row-level-0">
                                            <td className="ant-table-cell col_styling">
                                              <div className="txtinvoiceprt">
                                                {e}
                                              </div>
                                            </td>
                                            <td className="ant-table-cell col_styling">
                                              <div className="txtinvoiceprt">
                                                {revenueData(i)}
                                              </div>
                                            </td>
                                            <td className="ant-table-cell col_styling">
                                              <div className="txtinvoiceprt">
                                                {`$` +
                                                  r.avgRevenue.toLocaleString(
                                                    "en-US"
                                                  )}
                                              </div>
                                            </td>
                                          </tr>
                                        ))
                                      )
                                    )}
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td colSpan={3} style={{ padding: "0px" }}>
                                      <div className="disp_fixed disp_fixedgrp">
                                        <Pagination
                                          style={{ float: "right" }}
                                          total={Object.keys(revenue)?.length}
                                          showTotal={(total) =>
                                            `${total} items`
                                          }
                                          current={currentPage}
                                          defaultPageSize={perpageSize}
                                          onChange={handlePagination}
                                          defaultCurrent={1}
                                          showSizeChanger
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <Nodata style={{ width: "100%" }} />
                    )} */}
                  </TabPane>
                  {/* <TabPane tab={<span>Materics</span>} key="3">
                    <BaseTable
                      className="financeinvoice_table table_laptopscreen"
                      columnsData={matericColumns}
                      source={matericsData}
                      // rowSelection={rowSelection}
                      total={matericsData?.length}
                      rowKey={(record) => record.id}
                    />
                  </TabPane> */}
                </Tabs>
              </Card>
            </Col>
          </Row>
        )}
      </div>
      {modalVisible && (
        <BaseModal
          title="Add Sales Person"
          onCancel={() => setModalVisible(false)}
          formId="sales"
          className="antmodal_grid headermodal"
          width={
            window.screen.width > "501" && window.screen.width <= "991"
              ? "80%"
              : "35%"
          }
        >
          <Form onFinish={onFinish} id="sales">
            <Row gutter={[32, 0]}>
              <Col
                xxl={{ span: 12, offset: 0 }}
                xl={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
              >
                <span className="title_changes">First Name</span>
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Your First Name!",
                    },
                  ]}
                  initialValue={salesData?.firstName}
                >
                  <Input
                    className="form-input"
                    style={{ width: "100%" }}
                    placeholder="Please Enter Your First Name"
                  />
                </Form.Item>
              </Col>
              <Col
                xxl={{ span: 12, offset: 0 }}
                xl={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
              >
                <span className="title_changes">Last Name</span>
                <Form.Item
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Your Last Name!",
                    },
                  ]}
                  initialValue={salesData?.lastName}
                >
                  <Input
                    className="form-input"
                    style={{ width: "100%" }}
                    placeholder="Please Enter Your Last Name"
                  />
                </Form.Item>
              </Col>
              <Col
                // span={12}
                xxl={{ span: 12, offset: 0 }}
                xl={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
              >
                <span className="title_changes">Email</span>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input email",
                    },
                    {
                      type: "email",
                      message: "The Input Is Not Valid Email",
                    },
                  ]}
                  initialValue={salesData?.email}
                >
                  <Input
                    placeholder="Email address"
                    className="form-input"
                    // readOnly={editData ? true : ""}
                  />
                </Form.Item>
              </Col>
              <Col
                // span={12}
                xxl={{ span: 12, offset: 0 }}
                xl={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
              >
                <span className="title_changes">Phone</span>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your contact phone!",
                    },
                  ]}
                  initialValue={salesData?.phone}
                >
                  <NumberFormat
                    className="ant-input-number numberictest"
                    format="(###) ###-####"
                    style={{ width: "100%" }}
                    placeholder="Please Enter Your Phone"
                  />
                </Form.Item>
              </Col>

              <Col
                xxl={{ span: 12, offset: 0 }}
                xl={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
              >
                <span className="title_changes">Sales Commission</span>
                <Form.Item
                  name="salesCommission"
                  rules={[
                    {
                      required: true,
                      message: "Please input trucking Commission!",
                    },
                  ]}
                  initialValue={salesData?.salesCommission}
                >
                  <InputNumber
                    formatter={(value) => (value !== "" ? `${value}%` : "")}
                    style={{ width: "100%", height: "40px" }}
                    placeholder="Please enter Trucking Commission"
                    className="form-input"
                  />
                </Form.Item>
              </Col>
             
              <Col
                xxl={{ span: 12, offset: 0 }}
                xl={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
              >
                <span className="title_changes">Address</span>
                <Form.Item
                  name="address"
                  rules={[
                    {
                      required: false,
                      message: "Please Enter Address",
                    },
                  ]}
                >
                  <GooglePlacesAutocomplete
                    className="form-input"
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: ["us"],
                      },
                    }}
                    selectProps={{
                      defaultInputValue: `${
                        salesData?.address ? salesData?.address : ""
                      }`,
                      placeholder: "Search Address...",
                      onChange: (address) => {
                        handleSelect(address);
                        getAddress(address);
                      },
                    }}
                  />
                </Form.Item>
              </Col>

              <Col
                xxl={{ span: 24, offset: 0 }}
                xl={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
              >
                <Checkbox
                  checked={contractMinimum}
                  onChange={(e) => setContractMinimum(e.target.checked)}
                  style={{ marginBottom: "18px" }}
                >
                  <span className="checkBox_size" style={{ fontSize: "14px" }}>
                    Override Minimum Hours
                  </span>
                </Checkbox>
              </Col>
              
              <Col
                xxl={{ span: 24, offset: 0 }}
                xl={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
              >
                <span className="title_changes">Remarks</span>
                <Form.Item
                  className="Formitem"
                  name="remarks"
                  rules={[
                    {
                      required: false,
                      message: "Please Enter Remarks!",
                    },
                  ]}
                  initialValue={salesData?.remarks}
                >
                  <TextArea
                    rows={4}
                    placeholder="Remark"
                    className="form-input"
                  />
                </Form.Item>
              </Col>

              <Col
                // span={24} offset={0}
                xxl={{ span: 24, offset: 0 }}
                xl={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
              >
                <div
                  style={{
                    // marginBottom: "20px",
                    marginLeft: "auto",
                    maxWidth: "180px",
                  }}
                ></div>
              </Col>
            </Row>
          </Form>
        </BaseModal>
      )}
    </div>
  );
}

export default withAppContext(withRouter(SalesPersonDetails));
