import React from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import Authenticated from "./Authenticated";
import "./App.css";
// import { connect } from "react-redux";

// import {
//   increaseCounter,
//   decreaseCounter,
// } from "./Redux/Counter/counter.actions";

function App(props) {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/" component={Authenticated} />
        </Switch>
      </Router>
    </div>
  );
}
export default App;

// export default connect(mapStateToProps, mapDispatchToProps)(App);

// import React, { Component } from "react";
// import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
// import Authenticated from "./Authenticated";
// import "antd/dist/antd.min.css";
// import { withAppContext } from "./Store/AppContext";
// class App extends Component {
//   render() {
//     return (
//       <Router>
//         <Switch>
//           <Route path="/" component={Authenticated} />
//         </Switch>
//       </Router>
//     );
//   }
// }
