import React, { Component } from "react";
import { connect } from "react-redux";
import Filter from "../src/Common/Filter";
import { withRouter } from "react-router-dom";
import {
  addCompany,
  addQuarry,
  addTrucksList,
  deleteData,
  getDeleteData,
  getOwnerOperators,
  getTrucksList,
} from "./Redux/Actions/actions";
import { withAppContext } from "./Store/AppContext";
import { Tabs, Row, Popover, Button, Popconfirm, Col } from "antd";
import Loading from "./Common/Loading";
import BaseTable from "./Common/BaseTable";
import { IoFilterSharp } from "react-icons/io5";
import { EllipsisOutlined } from "@ant-design/icons";
import { MdUnarchive } from "react-icons/md";
import moment from "moment";
// const { Option } = Select;

const { TabPane } = Tabs;

class restoreData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deletedResult: [],
      filterData: [],
      checkedData: "",
      activeKey: "0",
      filterVisible: false,
      visible: false,
      masterData: [],
      filterResult: [],
      restoreCustomerName: [],
      totalResult: [],
    };
  }
  componentDidMount = () => {
    const {
      actionFetchTrucksList,
      actionsFetchtDeleteData,
      actionFetchOwnerOperators,
    } = this.props;

    actionsFetchtDeleteData();
    actionFetchOwnerOperators();
    actionFetchTrucksList();
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.deletedResult.success === false &&
      this.props.deletedResult.success === true
    ) {
      this.setState({
        deletedResult: this.props.deletedResult.deletedData,
        filterResult: this.props.deletedResult.deletedData,
        filterData: this.props?.rolesResult?.rolesData,
        totalResult: this.props.deletedResult.deletedData,
      });
    }
    if (
      prevProps.deletedResultInfo.success === false &&
      this.props.deletedResultInfo.success === true
    ) {
      this.props.actionsFetchtDeleteData();
      this.setState({
        deletedResult: this.props.deletedResult.deletedData,
        filterResult: this.props.deletedResult.deletedData,
        totalResult: this.props.deletedResult.deletedData,
      });
    }

    if (
      prevProps?.totalOwnerOperatorsData?.success === false &&
      this.props?.totalOwnerOperatorsData?.success === true
    ) {
      let data = [];
      this.props?.totalOwnerOperatorsData?.ownersData?.forEach((e) => {
        if (e?.isOwnerOperator && e?.carrierDetails?.length > 0) {
          e?.carrierDetails?.forEach((c) => {
            if (c?.truckId !== null && c?.truckId !== undefined) {
              data.push(parseInt(c?.truckId));
            }
          });
        }
      });
      let res = data?.length > 0 ? data.flat() : [0];
      this.setState({
        loading: false,
        localTruckId: res,
      });
    }

    if (
      prevProps?.totalTrucksListResult?.success === false &&
      this.props?.totalTrucksListResult?.success === true
    ) {
      let data = [];
      this.props?.totalTrucksListResult?.trucksListData?.forEach((e) => {
        if (e?.carrierDetails?.length > 0) {
          e?.carrierDetails?.forEach((c) => {
            if (c?.truckId !== null && c?.truckId !== undefined) {
              data.push(parseInt(c?.truckId));
            }
          });
        }
      });
      let res = data?.length > 0 ? data.flat() : [100];
      this.setState({
        assignTruckId: res,
      });
    }
  };

  filterResultData = (values) => {
    const { filterResult, activeKey, deletedResult } = this.state;
    if (
      (values.selectedValue !== "" && values.selectedValue !== undefined) ||
      (values.deletedBy !== "" && values.deletedBy !== undefined)
    ) {
      this.setState({
        checkedData: values,
        visible: false,
        restoreCustomerName: [],
      });
      if (activeKey === "0") {
        let resultData = this.findAllFilterData(
          filterResult[activeKey].deletedData,
          values,
          "quarryName"
        );
        deletedResult[activeKey].deletedData = resultData;

        this.setState({
          deletedResult,
        });
      } else if (activeKey === "1") {
        let resultData = this.findAllFilterData(
          filterResult[activeKey].deletedData,
          values,
          "firstName"
        );

        deletedResult[activeKey].deletedData = resultData;
        this.setState({
          deletedResult,
        });
      } else if (activeKey === "2") {
        let resultData = this.findAllFilterData(
          filterResult[activeKey].deletedData,
          values,
          "carrier_name"
        );
        deletedResult[activeKey].deletedData = resultData;
        this.setState({
          deletedResult,
        });
      }
    } else {
      this.setState({
        visible: false,
      });
    }
  };

  findAllFilterData = (data, filterCriteria, filterName) => {
    return data.filter((item) => {
      if (
        (!filterCriteria.selectedValue ||
          filterCriteria.selectedValue === item[filterName]) &&
        (!filterCriteria.deletedBy ||
          filterCriteria.deletedBy === item.deletedByName)
      ) {
        return true;
      }
      return false;
    });
  };

  onClearFilter = () => {
    this.setState({
      filterVisible: false,
      visible: false,
      checkedData: "",
    });
    this.props.actionsFetchtDeleteData();
  };

  onSelectChange = (data) => {
    this.setState({ selectedRowKeys: data });
  };
  closeFilter = () => {
    this.setState({
      visible: false,
      filterVisible: false,
      restoreCustomerName: [],
    });
  };

  handleTableChage = (props) => {
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    localStorage.setItem(
      "tableSizeRange",
      JSON.stringify({ ...tableSizeRange, restoreTool: props })
    );
    this.onClearFilter();
  };

  handleTab = (e) => {
    this.setState({ activeKey: e, checkedData: undefined });
    this.onClearFilter();
  };

  handleToOpenRolesEdit = (record) => {
    let defaultPannelsOpen = [];
    record["otherData"] &&
      record["otherData"].map((p) => {
        if ("multiPannel" in p === true) {
          p.multiPannel
            .filter(
              (selectedPannel) => selectedPannel.selectedOptions.length > 0
            )
            .map((open) => defaultPannelsOpen.push(open.key));
        }
        return true;
      });

    this.setState({
      isModalVisible: true,
      leadsData: record,
      PanelData: record["otherData"],
      isEditModalVisible: true,
      defaultPannelsOpen,
    });
  };

  restoreData = (record, tableName) => {
    const {
      actionAddContacts,
      actionFetchAddQuarry,
      actionAddTrucksList,
      actionsDeleteDeleteData,
    } = this.props;
    const { localTruckId, assignTruckId } = this.state;
    const combineTruckId = localTruckId.concat(assignTruckId);
    let data = {
      entityData: record,
    };
    actionsDeleteDeleteData(record.deletedId);
    delete data["entityData"]["id"];
    delete data["entityData"]["deletedBy"];
    delete data["entityData"]["deletedByName"];
    delete data["entityData"]["deletedId"];

    if (tableName === "quarryLocations") {
      actionFetchAddQuarry(data);
    } else if (tableName === "drivers") {
      delete data["entityData"]["carrierId"];
      delete data["entityData"]["companyId"];
      data["entityData"]["leadType"] = [
        {
          id: "3",
          value: "Driver",
        },
      ];
      actionAddContacts(data);
    } else {
      let highestTruckID;
      if (combineTruckId.includes(record.truckId)) {
        highestTruckID = Math.max(...combineTruckId) + 1;
      } else {
        highestTruckID = record.truckId;
      }
      data["entityData"]["truckId"] = highestTruckID;
      actionAddTrucksList(data);
    }
  };

  showFilter = () => {
    const { totalResult, activeKey } = this.state;
    this.setState({
      visible: true,
      restoreCustomerName: totalResult[activeKey]?.deletedData,
    });
  };

  render() {
    const tableName = [
      { label: "Quarries", value: "quarryLocations" },
      { label: "Driver Database", value: "drivers" },
      { label: "Owner Operator Database", value: "trucksList" },
    ];

    const { deletedResult, activeKey, restoreCustomerName } = this.state;
    const { loading } = this.props;

    const columns = (value) => [
      {
        title: "Date",
        dataIndex: "updatedAt",
        key: "updatedAt",
        show: true,
        sorter: false,
        width: "12%",
        className: "col_style_quarry",
        render: (updatedAt, record) => {
          return (
            <span
              className="col_style_quarry"
              style={{
                textTransform: "capitalize",
              }}
            >
              <div>{moment(record.updatedAt).format("MM/DD/YYYY")}</div>
            </span>
          );
        },
      },

      {
        title: "Time",
        dataIndex: "updatedAt",
        key: "updatedAt",
        show: true,
        sorter: false,
        width: "12%",
        className: "col_style_quarry",
        render: (updatedAt, record) => {
          return (
            <span
              className="col_style_quarry"
              style={{
                textTransform: "capitalize",
              }}
            >
              <div>{moment(updatedAt).format("hh:mm A")}</div>
            </span>
          );
        },
      },
      {
        title:
          value === "quarryLocations"
            ? "QUARRY NAME"
            : value === "drivers"
            ? "Driver Name"
            : "CARRIER NAME",
        dataIndex:
          value === "quarryLocations"
            ? "quarryName"
            : value === "drivers"
            ? "firstName"
            : "carrier_name",
        key:
          value === "quarryLocations"
            ? "quarryName"
            : value === "drivers"
            ? "firstName"
            : "carrier_name",
        show: true,
        className: "col_style_quarry",
        // sorter: (a, b) => a?.quarryName?.localeCompare(b?.quarryName),
        render: (quarryName, record) => {
          return (
            <span
              className="col_style_quarry"
              style={{
                width: "10px",
              }}
            >
              <Row className="col_style_quarry">
                {value === "drivers"
                  ? quarryName + " " + record?.lastName
                  : quarryName}
              </Row>
            </span>
          );
        },
      },
      {
        title: "LOCATION",
        dataIndex: "address",
        key: "address",
        show: true,
        sorter: false,
        className: "col_style_quarry",
        render: (address, record) => {
          let data = record?.distance === 0 ? 0 : Math.floor(record?.distance);
          return (
            <span
              className="col_style_quarry"
              style={{
                textTransform: "capitalize",
              }}
            >
              {address !== "" && address !== null && (
                <span>
                  {address}
                  <b>{!isNaN(data) && ` - ${data} Miles`}</b>
                </span>
              )}
            </span>
          );
        },
      },
      {
        title: "PHONE NUMBER",
        dataIndex: "phone",
        key: "phone",
        sorter: false,
        className: "col_style_quarry",
        render: (phone, record) => {
          return (
            <span
              className="col_style_quarry"
              style={{
                textTransform: "capitalize",
              }}
            >
              <div>{phone}</div>
            </span>
          );
        },
      },
      {
        title: "Deleted By",
        dataIndex: "deletedByName",
        key: "deletedByName",
        sorter: false,
        className: "col_style_quarry",
        render: (deletedByName) => {
          return (
            <span
              className="col_style_quarry"
              style={{
                width: "10px",
              }}
            >
              <div className="col_quarrytype">{deletedByName}</div>
            </span>
          );
        },
      },
      {
        title: "Deleted Date",
        dataIndex: "deletedDate",
        key: "deletedDate",
        sorter: false,
        className: "col_style_quarry",
        render: (deletedDate) => {
          return (
            <span
              className="col_style_quarry"
              style={{
                width: "10px",
              }}
            >
              <div className="col_quarrytype">{deletedDate}</div>
            </span>
          );
        },
      },
      {
        title: "ACTIONS",
        dataIndex: "id",
        key: "id",
        fixed: "right",
        sorter: false,
        render: (id, record) => {
          return (
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Popover
                placement="left"
                content={
                  <Row
                    className="popovergrid popover-mobile"
                    style={{ maxWidth: "100px" }}
                  >
                    <Col span={24}>
                      <Button className="btn_edit">
                        <Popconfirm
                          title={
                            <span>
                              {`Are you sure you want to restore `}
                              <b>
                                {value === "quarryLocations"
                                  ? record.quarryName
                                  : value === "drivers"
                                  ? record.firstName + " " + record.lastName
                                  : record.carrier_name}
                                ?
                              </b>
                            </span>
                          }
                          okText={
                            <span style={{ width: "60px", height: "30px" }}>
                              Yes
                            </span>
                          }
                          cancelText={
                            <span style={{ width: "60px", height: "30px" }}>
                              No
                            </span>
                          }
                          onConfirm={() => this.restoreData(record, value)}
                        >
                          <span className="span_edit">
                            <MdUnarchive style={{ marginRight: "5px" }} />{" "}
                            Restore
                          </span>
                        </Popconfirm>
                      </Button>
                    </Col>
                  </Row>
                }
              >
                <EllipsisOutlined
                  style={{ fontSize: "30px", color: "grey" }}
                  className="action-ellipsis-button"
                />
              </Popover>
            </Row>
          );
        },
      },
    ];
    return (
      <div>
        <Loading enableLoading={loading} />
        <Tabs
          onChange={this.handleTab}
          activeKey={this.state.activeKey}
          tabBarExtraContent={
            <Button
              className="button_Crm show-on-desktop"
              style={{
                borderRadius: "5px",
                height: "40px",
              }}
              onClick={this.showFilter}
            >
              <Row>
                <Col>
                  <span
                    style={{
                      color: "#5a6067",
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    Filter
                  </span>
                </Col>
                <Col>
                  <IoFilterSharp
                    style={{
                      fontSize: "22px",
                      marginLeft: "8px",
                      marginTop: "2px",
                    }}
                  />
                </Col>
              </Row>
            </Button>
          }
        >
          {tableName?.map((e, i) => {
            return (
              <TabPane tab={e.label} key={i}>
                {deletedResult?.map(
                  (d) =>
                    d.deletedTable === e.value && (
                      <BaseTable
                        className="quarrytooltable table_laptopscreen"
                        columnsData={columns(e.value)}
                        source={d.deletedData}
                        handleTableChage={() => this.handleTableChage()}
                        sizeRange={
                          window.screen.width > 1023
                            ? JSON.parse(localStorage.getItem("tableSizeRange"))
                                ?.restoreTool
                            : d.deletedData?.length
                        }
                        pagination={window.screen.width > 1023 ? true : false}
                        total={d.deletedData?.length}
                        rowKey={(record) => record.id}
                        // total={filterData?.length}
                      />
                    )
                )}
              </TabPane>
            );
          })}
        </Tabs>

        {this.state.visible && (
          <Filter
            visible={this.state.visible}
            closeFilter={this.closeFilter}
            filterResultData={this.filterResultData}
            onClearFilter={this.onClearFilter}
            checkedData={this.state.checkedData}
            restoreData
            restoreCustomerName={restoreCustomerName}
            activeKey={activeKey}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  deletedResult: state?.reducer?.deletedResult,
  deletedResultInfo: state?.reducer?.deletedResultInfo,
  loading: state?.reducer?.deletedResult?.loading,
  totalOwnerOperatorsData: state.reducer?.ownerOperatorsData,
  totalTrucksListResult: state.reducer?.trucksListResult,
});

const mapDispatchToProps = (dispatch) => ({
  actionsFetchtDeleteData: () => {
    dispatch(getDeleteData());
  },

  actionsDeleteDeleteData: (id) => {
    dispatch(deleteData(id));
  },
  actionFetchAddQuarry: (data) => {
    dispatch(addQuarry(data));
  },
  actionAddContacts: (data) => {
    dispatch(addCompany(data));
  },
  actionAddTrucksList: (data) => {
    dispatch(addTrucksList(data));
  },
  actionFetchOwnerOperators: () => {
    dispatch(getOwnerOperators());
  },
  actionFetchTrucksList: () => {
    dispatch(getTrucksList());
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAppContext(withRouter(restoreData)));
