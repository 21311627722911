const appConfig = {
  date_format: "MM/DD/YYYY",
  date_time_format: "MM/DD/YYYY hh:mm A",
  time_format: "hh:mm A",
  dateTimeFormat: "MMM DD, YYYY hh:mm A",
  APP_S3_REGION: process.env.REACT_APP_REGION,
  APP_S3_BUCKET: process.env.REACT_APP_S3_BUCKET,
  API_GATEWAY: process.env.REACT_APP_API_GATEWAY,
  APP_COGNITO_REGION: process.env.REACT_APP_REGION,
  APP_COGNITO_USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  APP_CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  APP_IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,

  fetch_refresh_time: 30000,

  entityNames: {
    addLead: "addLead",
    addContact: "addContact",
    users: "users",
    getLeads: "getLeads",
    singleLead: "getLead",
    updateLead: "leads",
    updateContact: "contacts",
    updateContactPrimary: "updateContact",
    deleteContact: "contacts",
    deleteCompany: "deleteCompany",
    deleteContactandJob: "deleteContact",
    delete: "leads",
    addCompany: "addCompany",
    companyDetails: "getCompany",
    updateCompany: "companies",
    getQuarries: "quarries",
    deleteQuarries: "quarries",
    roles: "roles",
    addQuotes: "quotes",
    updateQuote: "updateQuote",
    addInvoices: "invoices",
    addDocuments: "documents",
    addNotes: "leadNotes",
    ownerDatabase: "ownerOperatorDatabase",
    ownerLocations: "getOwnerLocations",
    getDispatch: "getDispatch",
    updateDirection: "updateLeadDirection",
    addUser: "addUser",
    checkContact: "checkCompanyContact",
    qLocations: "quarryLocations",
    getDist: "getDist/contacts",
    dashBoard: "getDashboard",
    getMilesAndMins: "getMiles",
    getCarrierProfile: "getCarrierDetails",
    search: "searchField",
    getQuarryDetails: "getQuarryDetails",
    updateArchive: "updateArchive",
    salesPerson: "salesPerson",
    getSalesPerson: "getSalesPerson",
    deleteMultipleContacts: "deleteMultipleContacts",
    updateEstimate: "updateEstimate"
  },

  call_status_colors: {
    Connected: "Green",
    "Not Connected": "Red",
  },

  quarry_test_color: {
    "Passes Spec": "green",
    "Does Not Pass Spec": "red",
    Unknown: "#ffc902",
  },

  quote_status_colors: {
    Accepted: "green",
    //#2A2A2C; for dark mode card bg
    //#131417 for main bg
    //#ffffff for text
    "In Review": "#d87a16",
    Rejected: "red",
    Pending: "#fa541c",
  },

  mine_status_colors: {
    Active: "#6f9412",
    "New Mine": "#d87a16",
    Intermittent: "#d87a16",
    Abandoned: "#2b1d11",
    "Abandoned and Sealed": "#a61d24",
    "Temporarily Idled": "#d84a1b",
  },

  status_colors: {
    Active: "#6f9412",
    Inactive: "#2b1d11",
    Overdue: "#d87a16",
  },

  type_colors: {
    "Material Sales": "#8bbb11",
    "Owner Operator": "#15395b",
    Driver: "#144848",
  },

  STAGE_COLORS: {
    "Job Lead": "#ad243e",
    Contacted: "#6a00ff",
    "Generate Estimate": "#237804",
    "Build Quote": "#237804",
    "Quote Delivered": "#9e1068",
    "Quote Accepted": "#0958d9",
    "Dispatch Approved": "#d4380d",
    Paused: "#7d7d7d",
    "Close Job": "#291321",

    // owner operator colors
    "Uncontacted Lead": "#ed8a82",
    "Pending Carrier Packet + Insurance": "#e07c2f",
    "Pending Insurance": "#5b8c00",
    "Pending Carrier Packet": "#e07c2f",
    "Carrier Data Input": "#0d914d",
    "Welcome Call": "#cb2b83",
    "Approved Carrier": "#027313",
    "Archived Carrier": "#d4380d",
    "Stalled Application": "#d8bd14",
  },

  role_colors: {
    Adminstrator: "#177ddc",
    A: "#177ddc",
    Admin: "#177ddc",
  },

  dispatch_colors: {
    COMPLETED: "#0058e0",
    "IN PROGRESS": "#d16a00",
    "UN ASSIGNED": "#c40000",
  },

  dark_mode: {
    color: "#ffffff",
    backgroundColor: "#131417",
    // backgroundColor: "#2A2A2C",
  },
};
export default appConfig;
