import React from "react";
import ErrorView from "../../Common/ErrorView";
import Loading from "../../Common/Loading";
import { connect } from "react-redux";
import { Storage } from "aws-amplify";
import {
  Col,
  Collapse,
  Row,
  Select,
  DatePicker,
  Popover,
  Button,
  Form,
  message,
  Card,
  Pagination,
  Input,
} from "antd";
import {
  EllipsisOutlined,
  DownloadOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import AOS from "aos";
import "aos/dist/aos.css";
import { MdModeEditOutline } from "react-icons/md";
import { add } from "../../api/Apis";
// import SearchFunction from "../Common/SearchFunction";
import BaseModal from "../../Common/BaseModal";
import moment from "moment";
import {
  updateOrder,
  getDispatchSummary,
  getOrders,
  getTrucksList,
} from "../../Redux/Actions/actions";
import BaseTable from "../../Common/BaseTable";
import Nodata from "../nodata";
import { BsArrowLeftShort } from "react-icons/bs";
const { Panel } = Collapse;
const { Option } = Select;

class DispatchArchive extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filterData: [],
      dispatchData: [],
      loading: false,
      errorLoading: false,
      filteredCustomers: [],
      filteredTrucks: [],
      fromDate: "",
      toDate: "",
      truckNumber: undefined,
      companyName: undefined,
      showModal: false,
      truckingPriceType: "",
      filteredMapData: [],
      assignTrucksData: [],
      groupedData: {},
      selectedData: {},
      dayGroupedData: {},

      minValue: 0,
      maxValue: 10,
      currentPage: 1,
      perpageSize: 10,

      dispatchRowData: [],
      loadingDownload: false,
      clickCard: [],
    };
  }

  componentDidMount() {
    document.title = "Twisted Nail - Dispatch Archive";
    this.props?.actionFetchGetOrders();
    this.props?.actionFetchTrucksList();
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.ordersResultInfo?.success === false &&
      this.props?.ordersResultInfo?.success === true
    ) {
      this.props?.actionFetchGetOrders();
      this.setState({
        showModal: false,
      });
    }
    if (
      prevProps?.totalResult?.success === false &&
      this.props?.totalResult?.success === true
    ) {
      let data = this.props?.totalResult?.ordersData?.sort((a, b) =>
        !a.assigned < !b.assigned ? 1 : -1
      );
      let filteredCustomers = data?.filter(
        (value, index, self) =>
          value?.dispatchStatus === "Completed" &&
          self?.findIndex((e) => e?.companyId === value.companyId) === index
      );

      let filteredTrucks = [];
      data?.forEach(
        (value) =>
          value?.dispatchStatus === "Completed" &&
          value?.material[0]?.assignTrucks?.forEach((e) => {
            filteredTrucks.push({ assignTrucks: e });
          })
      );

      filteredTrucks = filteredTrucks?.filter(
        (value, index, self) =>
          self?.findIndex((e) => e?.assignTrucks === value.assignTrucks) ===
          index
      );

      let dispatchData = data?.filter((d) => d?.dispatchStatus === "Completed");
      dispatchData?.sort(
        (a, b) => new Date(b.dispatchDate) - new Date(a?.dispatchDate)
      );

      this.groupData(dispatchData);
      this.setState({
        filterData: dispatchData,
        dispatchData: dispatchData,
        loading: false,
        filteredCustomers,
        filteredTrucks,
      });
    }
    if (
      prevProps?.totalTrucksListResult?.success === false &&
      this.props?.totalTrucksListResult?.success === true
    ) {
      this.setState({
        // mapsData: this.props?.totalTrucksListResult?.trucksListData,
        filteredMapData: this.props?.totalTrucksListResult?.trucksListData,
        // mapLoading: false,
        loading: false,
      });
    }
  }

  groupData = (dispatchData) => {
    const groupedData = {};
    dispatchData.forEach((obj) => {
      const weekStart = new Date(obj.dispatchDate);
      weekStart.setDate(weekStart.getDate() - ((weekStart.getDay() + 6) % 7)); // Monday of the current week
      const weekEnd = new Date(weekStart);
      weekEnd.setDate(weekEnd.getDate() + 6); // Sunday of the current week

      // Format the dates in "MM/DD/YYYY" format
      const formattedWeekStart = `${(weekStart.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${weekStart
        .getDate()
        .toString()
        .padStart(2, "0")}/${weekStart.getFullYear()}`;
      const formattedWeekEnd = `${(weekEnd.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${weekEnd
        .getDate()
        .toString()
        .padStart(2, "0")}/${weekEnd.getFullYear()}`;

      const weekKey = `${formattedWeekStart} - ${formattedWeekEnd}`;
      // add the object to the appropriate week in the grouped data object
      if (!groupedData[weekKey]) {
        groupedData[weekKey] = [];
      }
      groupedData[weekKey].push(obj);
    });
    this.setState({
      groupedData,
    });
  };

  handleFilter = () => {
    const { companyName, dispatchData, truckNumber, fromDate, toDate } =
      this.state;
    let toDateBefore = new Date(toDate);
    toDateBefore.setDate(toDateBefore.getDate() + 1);
    let fromDateBefore = new Date(fromDate);
    fromDateBefore.setDate(fromDateBefore.getDate() - 1);
    let filterData = [];
    if (
      companyName !== "" &&
      companyName !== undefined &&
      truckNumber !== "" &&
      truckNumber !== undefined &&
      toDate !== "" &&
      fromDate !== ""
    ) {
      filterData = dispatchData.filter(
        (e) =>
          e["companyId"]?.indexOf(companyName) > -1 &&
          e["material"][0]["assignTrucks"]?.includes(truckNumber) &&
          moment(moment(e.dispatchDate).format("l")).isBetween(
            moment(fromDateBefore).format("l"),
            moment(toDateBefore).format("l")
          )
      );
    } else if (
      companyName !== "" &&
      truckNumber !== "" &&
      companyName !== undefined &&
      truckNumber !== undefined
    ) {
      filterData = dispatchData.filter(
        (e) =>
          e["companyId"]?.indexOf(companyName) > -1 &&
          e["material"][0]["assignTrucks"]?.includes(truckNumber)
      );
    } else if (
      companyName !== undefined &&
      companyName !== "" &&
      toDate !== "" &&
      fromDate !== ""
    ) {
      filterData = dispatchData.filter(
        (e) =>
          e["companyId"]?.indexOf(companyName) > -1 &&
          moment(moment(e.dispatchDate).format("l")).isBetween(
            moment(fromDateBefore).format("l"),
            moment(toDateBefore).format("l")
          )
      );
    } else if (
      truckNumber !== "" &&
      truckNumber !== undefined &&
      toDate !== "" &&
      fromDate !== ""
    ) {
      filterData = dispatchData.filter(
        (e) =>
          e["material"][0]["assignTrucks"]?.includes(truckNumber) &&
          moment(moment(e.dispatchDate).format("l")).isBetween(
            moment(fromDateBefore).format("l"),
            moment(toDateBefore).format("l")
          )
      );
    } else if (companyName !== undefined && companyName !== "") {
      filterData = dispatchData.filter(
        (e) => e["companyId"]?.indexOf(companyName) > -1
      );
    } else if (truckNumber !== "" && truckNumber !== undefined) {
      filterData = dispatchData.filter((e) =>
        e["material"][0]["assignTrucks"]?.includes(truckNumber)
      );
    } else if (toDate !== "" && fromDate !== "") {
      filterData = dispatchData.filter((e) =>
        moment(moment(e.dispatchDate).format("l")).isBetween(
          moment(fromDateBefore).format("l"),
          moment(toDateBefore).format("l")
        )
      );
    }
    this.groupData(filterData);

    this.setState({
      filterData,
      selectedData: filterData,
    });
  };

  handleReset = () => {
    const { dispatchData } = this.state;
    this.setState({
      truckNumber: undefined,
      toDate: "",
      fromDate: "",
      companyName: undefined,
    });
    dispatchData?.sort(
      (a, b) => new Date(b.dispatchDate) - new Date(a?.dispatchDate)
    );
    this.groupData(dispatchData);
  };

  handleEdit = (record) => {
    // console.log(record, "record");
    this.setState({
      recordData: record,
      showModal: true,
      truckingPriceType: record?.trucksRequestedType
        ? record?.trucksRequestedType
        : "Ton",
    });
  };

  onFinishDispatch = (values) => {
    let truckInfo = [];
    let assignTrucks = [];
    const { filteredMapData, assignTrucksData, recordData } = this.state;
    if (assignTrucksData?.length > 0) {
      filteredMapData?.forEach((f) => {
        assignTrucksData?.forEach((a) => {
          if (f.id === a.label) {
            f?.carrierDetails?.forEach((e) => {
              if (values?.assignTrucks?.includes(e?.truckId)) {
                e["payment_terms"] = f.payment_terms;
                e["carrier_name"] = f.carrier_name;
                truckInfo?.push(e);
                assignTrucks?.push(e?.truckId);
              }
            });
          }
        });
      });
    } else {
      assignTrucks = values["assignTrucks"];
      truckInfo = recordData?.material[0]?.assignTrucksInfo;
    }

    [recordData?.material[0]]?.forEach((e) => {
      e["assignTrucks"] = assignTrucks;
      e["assignTrucksInfo"] = truckInfo;
    });

    let data = {
      entityData: {
        material: [recordData?.material[0]],
        // trucksRequested: values["trucksRequested"],
        // trucksRequestedType: truckingPriceType,
      },
    };
    this.props?.actionUpdateOrder(recordData?.id, data);
  };

  searchResult = (searchValue) => {
    const { dispatchData } = this.state;
    let searchFilter = [];
    if (typeof searchValue === "number") {
      searchFilter = dispatchData.filter(
        (e) => parseInt(e["jobNumber"]) === searchValue
      );
    } else {
      searchFilter = dispatchData.filter(
        (e) =>
          e["companyName"]?.toLowerCase().indexOf(searchValue) > -1 ||
          e["jobType"]?.toLowerCase().indexOf(searchValue) > -1
      );
    }
    this.setState({
      filterData: searchFilter,
    });
  };

  onResetData = () => {
    this.props?.actionFetchGetOrders();
  };

  onSelectChange = (data, record) => {
    this.setState({ selectedRowKeys: data, dispatchRowData: record });
  };

  fileDownload = async (fileName, downloadFileName) => {
    let savedFileName = fileName + ".pdf";
    Storage.get(savedFileName, { download: true }).then((res) =>
      this.downloadBlob(res.Body, savedFileName)
    );
  };

  downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    // a.target = "_blank";
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    this.setState({
      loadingDownload: false,
      loading: false,
    });
    return a;
  }

  customersCount = (value) => {
    let filterCustomers = value?.filter(
      (val, ind, self) =>
        ind === self?.findIndex((e) => e.companyId === val.companyId)
    );
    return filterCustomers;
  };

  showIndividual = (key, value) => {
    const groupedData = {};
    value.forEach((obj) => {
      const weekStart = new Date(obj.dispatchDate);
      let weekKey = `${weekStart.getDay().toString().padStart(1, "0")}`;
      if (Number(weekKey) === 0) {
        weekKey = 7;
      }
      if (!groupedData[weekKey]) {
        groupedData[weekKey] = [];
      }
      groupedData[weekKey].push(obj);
    });
    this.setState({
      showDayData: true,
      dayGroupedData: groupedData,
    });
  };

  handlePagination = async (page, pageSize, load) => {
    if (page <= 1) {
      this.setState({
        minValue: 0,
        maxValue: pageSize,
      });
    } else {
      this.setState({
        minValue: (page - 1) * pageSize,
        maxValue: page * pageSize,
      });
    }
    this.setState({
      currentPage: page,
      perpageSize: pageSize,
    });
  };

  trucksCount = (value) => {
    let trucksData = [];
    value.map((e) => trucksData.push(e.material[0]?.assignTrucks));
    const mergedList = trucksData.flatMap((item) => item);
    const uniqueValues = [...new Set(mergedList.map((item) => item))];
    return uniqueValues;
  };

  handleClickCard = (event, val) => {
    const { clickCard } = this.state;
    if (event) {
      clickCard.push(val);
    } else {
      clickCard.splice(
        clickCard.findIndex((e) => e === val),
        1
      );
    }
    this.setState({
      clickCard,
    });
  };

  downloadAllSummary = () => {
    const { clickCard, dispatchData, groupedData } = this.state;
    if (clickCard?.length > 0) {
      let selectedData = [];
      clickCard.forEach((e) => {
        selectedData.push(groupedData[e]);
      });
      const mergedList = selectedData?.flatMap((item) => item);
      this.downloadSummary(mergedList);
    } else {
      this.downloadSummary(dispatchData);
    }
  };

  downloadSummary = (weekData) => {
    const { dispatchRowData } = this.state;
    let selectedData = dispatchRowData?.length > 0 ? dispatchRowData : weekData;
    const sortedDates = selectedData?.sort(
      (a, b) => new Date(a.dispatchDate) - new Date(b?.dispatchDate)
    );
    let dateRange = "";
    if (
      sortedDates[0].dispatchDate !==
      sortedDates[sortedDates?.length - 1].dispatchDate
    ) {
      dateRange =
        sortedDates[0].dispatchDate +
        " - " +
        sortedDates[sortedDates?.length - 1].dispatchDate;
    } else {
      dateRange = sortedDates[0].dispatchDate;
    }
    let data = {
      entityData: selectedData,
      dateRange: dateRange,
    };
    this.setState({
      loadingDownload: true,
    });
    add("getDispatchSummary", data).then((res) => {
      if (res.success) {
        message.success("Dispatch summary downloaded successfully");
        this.fileDownload("dispatchSummary");
      }
    });
    // this.props.actionGetDispatch(data);
  };

  render() {
    const dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const {
      clickCard,
      showDayData,
      dayGroupedData,
      currentPage,
      perpageSize,
      minValue,
      maxValue,
      showModal,
      filteredCustomers,
      filteredTrucks,
      truckNumber,
      toDate,
      fromDate,
      companyName,
      groupedData,
      // selectedData,
      recordData,
      filteredMapData,
      selectedRowKeys,
      loadingDownload,
    } = this.state;

    const { errorLoading, loading } = this.props;

    // const searchFields = ["jobNumber", "companyName", "jobType"];

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const columns = [
      {
        title: "DATE",
        dataIndex: "dispatchDate",
        key: "dispatchDate",
        sorter: false,
        className: "col_styling",
        width: "120px",
        // onCell: (record) => ({
        //   onClick: () => this.showModal(record),
        // }),
        render: (dispatchDate) => {
          return (
            <span
              className="col_styling"
              style={{
                textTransform: "capitalize",
                width: "10px",
              }}
            >
              <Row
                style={{ color: "#383838", fontSize: "16px" }}
                className="col_styling table-font-mobile"
              >
                {moment(dispatchDate).format("l")}
              </Row>
            </span>
          );
        },
      },
      {
        title: "JOB #",
        dataIndex: "jobNumber",
        key: "jobNumber",
        sorter: false,
        className: "col_styling",
        width: "80px",
        // onCell: (record) => ({
        //   onClick: () => this.showModal(record),
        // }),
        render: (jobNumber) => {
          return (
            <span
              className="col_styling"
              style={{
                textTransform: "capitalize",
                width: "10px",
              }}
            >
              <Row
                style={{ color: "#383838", fontSize: "16px" }}
                className="col_styling table-font-mobile"
              >
                {jobNumber}
              </Row>
            </span>
          );
        },
      },
      {
        title: "JOB NAME",
        dataIndex: "jobName",
        key: "jobName",
        sorter: false,
        className: "col_styling",
        // width: "80px",
        // onCell: (record) => ({
        //   onClick: () => this.showModal(record),
        // }),
        render: (jobName) => {
          return (
            <span
              className="col_styling"
              style={{
                textTransform: "capitalize",
                width: "10px",
              }}
            >
              <Row
                style={{ color: "#383838", fontSize: "16px" }}
                className="col_styling table-font-mobile"
              >
                {jobName}
              </Row>
            </span>
          );
        },
      },
      {
        title: "CUSTOMER",
        dataIndex: "companyName",
        key: "companyName",
        sorter: false,
        className: "col_styling",
        // width: "160px",
        // onCell: (record) => ({
        //   onClick: () => this.showModal(record),
        // }),
        render: (companyName, record) => {
          return (
            <span
              className="col_styling"
              style={{
                textTransform: "capitalize",
                width: "10px",
              }}
            >
              <Row
                style={{ color: "#383838", fontSize: "16px" }}
                className="col_styling table-font-mobile"
              >
                {companyName}
              </Row>
              {/* <Row style={{ color: "#a6a6a6", fontSize: "12px" }}>
                  {record.phone}
                </Row>
                <Row style={{ color: "#a6a6a6", fontSize: "12px" }}>
                  {record.email}
                </Row> */}
              {/* <Row>
                  <Tag
                    style={{
                      borderRadius: "5px",
                      height: "22px",
                      borderWidth: "2px",
                      borderColor: appConfig["dispatch_colors"][record.tag],
                      // record.tag === "COMPLETED"
                      //   ? "#0058e0"
                      //   : record.tag === "IN PROGRESS"
                      //   ? "#d16a00"
                      //   : record.tag === "UN ASSIGNED" && "#c40000",
                      color: appConfig["dispatch_colors"][record.tag],
                      // record.tag === "COMPLETED"
                      //   ? "#0058e0"
                      //   : record.tag === "IN PROGRESS"
                      //   ? "#d16a00"
                      //   : record.tag === "UN ASSIGNED" && "#c40000",
                    }}
                    key={record.tag}
                  >
                    <span
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      {record.tag}
                    </span>
                  </Tag>
                </Row> */}
            </span>
          );
        },
      },
      {
        title: "JOB TYPE",
        dataIndex: "jobType",
        key: "jobType",
        sorter: false,
        className: "col_styling",
        // width: "80px",
        // onCell: (record) => ({
        //   onClick: () => this.showModal(record),
        // }),
        render: (jobType) => {
          return (
            <span
              className="col_styling"
              style={{
                textTransform: "capitalize",
                width: "10px",
              }}
            >
              <Row
                style={{ color: "#383838", fontSize: "16px" }}
                className="col_styling table-font-mobile"
              >
                {jobType}
              </Row>
            </span>
          );
        },
      },
      {
        title: "ASSIGNED TRUCKS",
        dataIndex: "material",
        key: "material",
        sorter: false,
        className: "col_styling",
        // width: "160px",
        // onCell: (record) => ({
        //   onClick: () => this.showModal(record),
        // }),
        render: (material, record) => {
          return (
            <span
              className="col_styling"
              style={{
                textTransform: "capitalize",
                width: "10px",
              }}
            >
              <Row
                style={{ color: "#383838", fontSize: "16px" }}
                className="col_styling table-font-mobile"
              >
                {record?.material[0]?.assignTrucks?.join(", ")}
              </Row>
            </span>
          );
        },
      },
      {
        title: "Dispatch Request",
        dataIndex: "trucksRequested",
        key: "trucksRequested",
        sorter: false,
        className: "col_styling",
        // width: "80px",
        // onCell: (record) => ({
        //   onClick: () => this.showModal(record),
        // }),
        render: (trucksRequested, record) => {
          return (
            <span
              className="col_styling"
              style={{
                textTransform: "capitalize",
                width: "10px",
              }}
            >
              <Row
                style={{ color: "#383838", fontSize: "16px" }}
                className="col_styling table-font-mobile"
              >
                {trucksRequested !== undefined &&
                  trucksRequested + " " + record?.trucksRequestedType}
              </Row>
            </span>
          );
        },
      },
      {
        title: "Material",
        dataIndex: "material",
        key: "Material",
        sorter: (a, b) => (a.jobType > b.jobType ? 1 : -1),
        className: "col_styling",
        show: true,
        // width: 150,

        render: (material, record) => {
          return (
            <>
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="table-font-mobile"
              >
                {record.jobType === "Trucking Job" ? (
                  <div>{record?.material[0]?.materialName}</div>
                ) : (
                  <>
                    {material
                      ?.map((e) => e?.category + " - " + e?.categoryKey)
                      ?.join(", ")}
                  </>
                )}
              </span>
            </>
          );
        },
      },
      // {
      //   title: "STATUS",
      //   dataIndex: "action",
      //   key: "action",
      //   sorter: false,
      //   className: "col_styling",
      //   width: "250px",
      //   onCell: (record) => ({
      //     onClick: () => this.showModal(record),
      //   }),
      //   render: (tag2, record) => {
      //     return (
      //       <Row>
      //         {record?.assigned === true &&
      //         record.dispatchStatus === "Completed" ? (
      //           <Button
      //             className="btn_checked"
      //             style={{ fontSize: "16px", padding: "0px" }}
      //           >
      //             <CheckCircleFilled
      //               style={{ marginRight: "5px", color: "#00b02e" }}
      //             />
      //             <span style={{ color: "#00b02e" }}>Completed</span>
      //           </Button>
      //         ) : record?.assigned === true ? (
      //           // <Popover
      //           //   placement="right"
      //           //   content={
      //           //     <div
      //           //       style={{
      //           //         fontSize: "13px",
      //           //         fontFamily: "sans-serif",
      //           //         cursor: "pointer",
      //           //       }}
      //           //     >
      //           //       <Row>
      //           //         <Col>
      //           //           <Button
      //           //             style={{
      //           //               width: "100px",
      //           //               marginBottom: "5px",
      //           //               background: "#1890ff",
      //           //               color: "#FFF",
      //           //             }}
      //           //             onClick={() => this.updateRecord(record)}
      //           //           >
      //           //             Completed
      //           //           </Button>
      //           //         </Col>
      //           //       </Row>
      //           //     </div>
      //           //   }
      //           //   trigger="hover"
      //           // >
      //           // </Popover>
      //           <Button
      //             className="lead_ButtonStatus"
      //             style={{
      //               background: "transparent",
      //               borderRadius: "5px",
      //               borderWidth: "1px",
      //               borderStyle: "solid",
      //               // marginTop: "15px",
      //             }}
      //           >
      //             In Progress
      //           </Button>
      //         ) : (
      //           <Button
      //             type="primary"
      //             onClick={() => {
      //               this.showAssignTruckModal(record);
      //             }}
      //             className="assignTruck_btn"
      //           >
      //             Assign Truck
      //           </Button>
      //         )}
      //         {/* <Button
      //           type="primary"
      //           style={{
      //             backgroundColor: "#586370",
      //             borderRadius: "5px",
      //             border: "none",
      //             color: "#ffffff",
      //             cursor: "pointer",
      //             width: "120px",
      //             margin: "5px 0px",
      //           }}
      //         >
      //           <MdCheckCircle style={{ marginRight: "5px" }} /> Assigned
      //         </Button> */}
      //       </Row>
      //     );
      //   },
      // },
      // {
      //   title: "DATE COMPLETED",
      //   dataIndex: "dispatchCompletedDate",
      //   key: "dispatchCompletedDate",
      //   sorter: false,
      //   className: "col_styling",
      //   // width: "80px",
      //   onCell: (record) => ({
      //     onClick: () => this.showModal(record),
      //   }),
      //   render: (dispatchCompletedDate) => {
      //     return (
      //       <span className="col_styling">{moment(dispatchCompletedDate).format("L")}</span>
      //     );
      //   },
      // },
      {
        title: "ACTION",
        dataIndex: "id",
        key: "",
        sorter: false,
        // width: "75px",
        fixed: "right",

        render: (action, record) => {
          return (
            <>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  flexWrap: "nowrap",
                }}
              >
                <Col>
                  <Popover
                    placement="left"
                    content={
                      <Row className="popovergrid">
                        <Col span={24}>
                          <Button
                            className="btn_edit"
                            onClick={() => this.handleEdit(record)}
                          >
                            <span className="span_edit">
                              <MdModeEditOutline
                                style={{ marginRight: "5px" }}
                              />{" "}
                              Edit
                            </span>
                          </Button>
                        </Col>
                      </Row>
                    }
                  >
                    <EllipsisOutlined
                      style={{ fontSize: "30px", color: "grey" }}
                      className="action-ellipsis-button"
                    />
                  </Popover>
                </Col>
              </Row>
            </>
          );
        },
      },
    ];

    return (
      <div
        style={{
          background: "#fafafa",
        }}
      >
        <Loading enableLoading={loadingDownload || loading} />
        {errorLoading ? (
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <ErrorView refreshLeadDetails={this.onRefresh} type="LeadDetails" />
          </Col>
        ) : (
          <>
            <div
              style={{
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                margin: "20px 0px",
                justifyContent: "center",
              }}
            >
              <Row
                style={{
                  width: "100%",
                  textAlign: "left",
                }}
              >
                <Col
                  xxl={{ span: 8 }}
                  xl={{ span: 8 }}
                  lg={{ span: 12 }}
                  md={{ span: 12 }}
                  sm={{ span: 8 }}
                  xs={{ span: 24 }}
                >
                  <div
                    style={{
                      margin: "12px 0px",
                      width: "100%",
                      padding: "0px 10px",
                    }}
                  >
                    <Select
                      id="reset"
                      size="large"
                      showSearch
                      allowClear
                      className="select_Height"
                      placeholder="All Customers"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      style={{
                        width: "100%",
                      }}
                      onChange={(e) =>
                        this.setState({
                          companyName: e,
                        })
                      }
                      value={companyName}
                    >
                      {filteredCustomers?.map((d, index) => (
                        <Option key={index} value={d?.companyId}>
                          {d?.companyName}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col>

                <Col
                  xxl={{ span: 4 }}
                  xl={{ span: 4 }}
                  lg={{ span: 6 }}
                  md={{ span: 6 }}
                  sm={{ span: 8 }}
                  xs={{ span: 12 }}
                >
                  <div
                    style={{
                      margin: "12px 0px",
                      width: "100%",
                      padding: "0px 10px",
                    }}
                  >
                    <DatePicker
                      value={fromDate}
                      className="form-input"
                      style={{ width: "100%" }}
                      placeholder="From"
                      format={"MM/DD/YYYY"}
                      onChange={(e) =>
                        this.setState({
                          fromDate: e,
                          toDate: "",
                        })
                      }
                    />
                  </div>
                </Col>

                <Col
                  xxl={{ span: 4 }}
                  xl={{ span: 4 }}
                  lg={{ span: 6 }}
                  md={{ span: 6 }}
                  sm={{ span: 8 }}
                  xs={{ span: 12 }}
                >
                  <div
                    style={{
                      margin: "12px 0px",
                      width: "100%",
                      padding: "0px 10px",
                    }}
                  >
                    <DatePicker
                      value={toDate}
                      className="form-input"
                      style={{ width: "100%" }}
                      placeholder="To"
                      format={"MM/DD/YYYY"}
                      onChange={(e) =>
                        this.setState({
                          toDate: e,
                        })
                      }
                      disabledDate={(current) => {
                        return (
                          current && current.valueOf() < new Date(fromDate)
                        );
                      }}
                    />
                  </div>
                </Col>

                <Col
                  xxl={{ span: 4 }}
                  xl={{ span: 4 }}
                  lg={{ span: 12 }}
                  md={{ span: 12 }}
                  sm={{ span: 8 }}
                  xs={{ span: 12 }}
                >
                  <div
                    style={{
                      margin: "12px 0px",
                      width: "100%",
                      padding: "0px 10px",
                    }}
                  >
                    <Select
                      id="reset"
                      size="large"
                      showSearch
                      allowClear
                      className="select_Height"
                      placeholder="Select Truck Numbers"
                      optionFilterProp="children"
                      style={{
                        width: "100%",
                      }}
                      onChange={(e) =>
                        this.setState({
                          truckNumber: e,
                        })
                      }
                      value={truckNumber}
                    >
                      {filteredTrucks?.map((d, index) => (
                        <Option key={index} value={d?.assignTrucks}>
                          {d?.assignTrucks}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col>

                <Col
                  xxl={{ span: 4 }}
                  xl={{ span: 4 }}
                  lg={{ span: 6 }}
                  md={{ span: 6 }}
                  sm={{ span: 8 }}
                  xs={{ span: 12 }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                      height: "100%",
                      alignItems: "center",
                      padding: "0px 10px",
                    }}
                  >
                    <Button
                      type="primary"
                      style={{
                        height: "35px",
                        width: "100px",
                        borderRadius: "5px",
                        marginRight: "12px",
                      }}
                      disabled={
                        companyName || truckNumber || (fromDate && toDate)
                          ? false
                          : true
                      }
                      onClick={this.handleFilter}
                    >
                      Apply
                    </Button>
                    <Button
                      style={{
                        height: "35px",
                        width: "100px",
                        borderRadius: "5px",
                      }}
                      onClick={this.handleReset}
                    >
                      Reset
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
            {!showDayData && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: "10px",
                }}
              >
                <Button
                  className="show-on-desktop"
                  onClick={() => this.downloadAllSummary()}
                  style={{
                    backgroundColor: "transparent",
                    color: "red",
                    border: "1px solid red",
                  }}
                >
                  Dispatch Summary
                  <DownloadOutlined
                    style={{
                      fontSize: "100%",
                      marginLeft: "8px",
                    }}
                  />
                </Button>
              </div>
            )}

            {showDayData ? (
              <div>
                <Row
                  onClick={() =>
                    this.setState({ showDayData: false, dayGroupedData: {} })
                  }
                  style={{ cursor: "pointer", width: "100%" }}
                >
                  <BsArrowLeftShort
                    style={{ color: "#5f6c74", fontSize: "23px" }}
                    className="back-button-return-icon"
                  />
                  <p
                    style={{
                      color: "#808080",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                    className="back-button-return"
                  >
                    Return
                  </p>
                </Row>
                {Object.keys(dayGroupedData)?.length > 0 ? (
                  <Collapse
                    defaultActiveKey={["0"]}
                    className="dispatch_archive"
                    accordion
                    // collapsible="header"
                  >
                    {Object.keys(dayGroupedData)
                      ?.sort((a, b) => a - b)
                      ?.map((e, i) => (
                        <Panel
                          style={{ minHeight: "3vw" }}
                          header={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "65%",
                              }}
                            >
                              <div style={{ width: "25%" }}>
                                <span className="date-dispatch">
                                  <span>Day</span>
                                  <b
                                    style={{ marginLeft: "5px", color: "gray" }}
                                  >
                                    : {dayNames[e]}
                                  </b>
                                </span>
                              </div>
                              <div style={{ width: "25%" }}>
                                <span className="date-dispatch">
                                  <span>Customers</span>
                                  <b
                                    style={{ marginLeft: "5px", color: "gray" }}
                                  >
                                    :{" "}
                                    {
                                      this.customersCount(dayGroupedData[e])
                                        ?.length
                                    }
                                  </b>
                                </span>
                              </div>
                              <div style={{ width: "25%" }}>
                                <span className="date-dispatch">
                                  <span>Jobs</span>
                                  <b
                                    style={{ marginLeft: "5px", color: "gray" }}
                                  >
                                    : {Object.values(dayGroupedData[e])?.length}
                                  </b>
                                </span>
                              </div>
                              <div style={{ width: "25%" }}>
                                <span className="date-dispatch">
                                  <span>Trucks</span>
                                  <b
                                    style={{ marginLeft: "5px", color: "gray" }}
                                  >
                                    :{" "}
                                    {
                                      this.trucksCount(dayGroupedData[e])
                                        ?.length
                                    }
                                  </b>
                                </span>
                              </div>
                            </div>
                          }
                          key={i}
                          extra={
                            <>
                              <Button
                                className="show-on-desktop"
                                style={{
                                  backgroundColor: "transparent",
                                  color: "red",
                                  border: "1px solid red",
                                }}
                                onClick={() =>
                                  this.downloadSummary(
                                    Object.values(dayGroupedData[e])
                                  )
                                }
                              >
                                Dispatch Summary
                                <DownloadOutlined
                                  style={{
                                    fontSize: "100%",
                                    marginLeft: "8px",
                                  }}
                                />
                              </Button>
                              <Button
                                className="show-on-mobile download-button"
                                style={{
                                  backgroundColor: "transparent",
                                  color: "red",
                                  border: "1px solid red",
                                  fontSize: "14px",
                                }}
                                onClick={() =>
                                  this.downloadSummary(
                                    Object.values(dayGroupedData[e])
                                  )
                                }
                              >
                                <span className="show-on-mobile-download">
                                  Dispatch Summary
                                </span>
                                <DownloadOutlined
                                  style={{
                                    // fontSize: "100%",
                                    marginLeft: "8px",
                                  }}
                                  className="download-button-icon"
                                />
                              </Button>
                            </>
                          }
                        >
                          <BaseTable
                            className="dispatchtool_table table_laptopscreen"
                            columnsData={columns}
                            source={Object.values(dayGroupedData[e])?.sort(
                              (a, b) => (a.updatedAt < b.updatedAt ? 1 : -1)
                            )}
                            total={Object.values(dayGroupedData[e])?.length}
                            rowKey={(record) => record.id}
                            rowSelection={rowSelection}
                            requiredChange
                            pagination={
                              window.screen.width > 1023 ? true : false
                            }
                          />
                        </Panel>
                      ))}
                  </Collapse>
                ) : (
                  <Nodata />
                )}
              </div>
            ) : Object.keys(groupedData)?.length > 0 ? (
              <Col span={24}>
                {Object.keys(groupedData)
                  ?.slice(minValue, maxValue)
                  ?.map((e) => (
                    <>
                      <Row
                        style={{
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Col span={24}>
                          <Input
                            type="checkbox"
                            name="cardRadio"
                            checked={clickCard.includes(e)}
                            className="cardRadio-intbtn checkBox_btn"
                            onChange={(event) => {
                              this.handleClickCard(event.target.checked, e);
                            }}
                            style={{
                              cursor: "pointer",
                              accentColor: "#586370",
                            }}
                          />
                          <Card
                            className="ticket-processing-card show-on-desktop"
                            hoverable
                            style={{
                              marginBottom: "1vw",
                              borderRadius: "5px",
                              border: "1px solid #E8E8E8",
                              cursor: "pointer",
                              backgroundColor: "white",
                            }}
                            onDoubleClick={() => {
                              this.setState(
                                {
                                  jobInfoData: {},
                                  individualCompanyData: {},
                                },
                                () =>
                                  this.showIndividual(
                                    e,
                                    Object.values(groupedData[e])
                                  )
                              );
                            }}
                          >
                            <Row
                              className="ticket_uplaod"
                              style={{
                                padding: "10px",
                                fontSize: "15px",
                              }}
                            >
                              <Col
                                style={{
                                  fontWeight: "bold",
                                  display: "flex",
                                  alignContent: "center",
                                }}
                              >
                                <Col>{e}</Col>
                              </Col>
                              <Col>
                                <Col className="ticket_heading">Customers</Col>
                                <Col
                                  className="ticket_heading"
                                  style={{ fontWeight: "bold" }}
                                >
                                  {
                                    this.customersCount(
                                      Object.values(groupedData[e])
                                    )?.length
                                  }
                                </Col>
                              </Col>

                              <Col>
                                <Col className="ticket_heading">Jobs</Col>
                                <Col
                                  className="ticket_heading"
                                  style={{ fontWeight: "bold" }}
                                >
                                  {Object.values(groupedData[e])?.length}
                                </Col>
                              </Col>

                              <Col
                                style={{
                                  fontWeight: "bold",
                                  color: "#586370",
                                  cursor: "pointer",
                                }}
                              >
                                <Row
                                  onClick={() => {
                                    this.showIndividual(
                                      e,
                                      Object.values(groupedData[e])
                                    );
                                  }}
                                >
                                  <Col>Details</Col>
                                  <Col
                                    style={{
                                      marginLeft: "6px",
                                      color: "#586370",
                                    }}
                                  >
                                    {" "}
                                    <ArrowRightOutlined />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>
                    </>
                  ))}

                {Object.keys(groupedData).length > 0 && (
                  <div
                    className="disp_fixed show-on-desktop"
                    style={{ height: "50px", marginBottom: "20px" }}
                  >
                    <Pagination
                      style={{ float: "right", marginTop: "1vw" }}
                      total={Object.keys(groupedData).length}
                      showTotal={(total) => `${total} items`}
                      pageSizeOptions={[
                        5,
                        10,
                        25,
                        50,
                        100,
                        `${Object.keys(groupedData)?.length}`,
                      ]}
                      current={currentPage}
                      pageSize={
                        JSON.parse(localStorage.getItem("tableSizeRange"))
                          ?.ticketProcessGroupData
                          ? JSON.parse(localStorage.getItem("tableSizeRange"))
                              ?.ticketProcessGroupData
                          : perpageSize
                      }
                      onChange={this.handlePagination}
                      defaultCurrent={1}
                      showSizeChanger={true}
                    />
                  </div>
                )}
              </Col>
            ) : (
              <Nodata />
            )}
          </>
        )}

        {showModal && (
          <BaseModal
            title={"Update Details"}
            onCancel={() => {
              this.setState({
                showModal: false,
              });
              this.formRef?.current?.setFieldsValue({
                assignTrucks: [],
                trucksRequested: "",
                truckingPriceType: "",
                assignTrucksData: [],
              });
            }}
            formId="editDispatchModal"
            className="antmodal_grid headermodal"
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "80%"
                : "640px"
            }
            pagination={window.screen.width > 1023 ? true : false}
          >
            <Form
              id="editDispatchModal"
              ref={this.formRef}
              onFinish={this.onFinishDispatch}
            >
              <Row gutter={[8, 5]}>
                <Col span={24}>
                  <span
                    className="title_changes"
                    style={{ marginBottom: "10px" }}
                  >
                    Assign Trucks
                  </span>
                  <Form.Item
                    name="assignTrucks"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Your Truck Type!",
                      },
                    ]}
                    initialValue={recordData?.material[0]?.assignTrucks}
                  >
                    <Select
                      allowClear
                      className="select_multiplequarytol"
                      style={{
                        fontWeight: "300",
                      }}
                      mode="multiple"
                      placeholder="Please Select Your Type"
                      onChange={(e, option) =>
                        this.setState({
                          assignTrucksData: option,
                        })
                      }
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {filteredMapData?.map((t) =>
                        t?.carrierDetails?.map((e) =>
                          recordData?.truckType?.map(
                            (r) =>
                              e.truck_type === r && (
                                <Select.Option value={e?.truckId} label={t?.id}>
                                  {`TNB${e?.truckId} - ${e?.truck_type} - ${t?.carrier_name}`}
                                </Select.Option>
                              )
                          )
                        )
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                {/* <Col span={24}>
                  <span
                    className="title_changes"
                    style={{ marginBottom: "10px" }}
                  >
                    Tons/Loads/Trucks Requested
                  </span>
                  <Form.Item
                    name="trucksRequested"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Trucks !",
                      },
                    ]}
                    initialValue={recordData?.trucksRequested}
                  >
                    <InputNumber
                      placeholder="Please Enter Trucking Price To Customer"
                      addonAfter={selectAfterTruckPrice}
                      style={{
                        height: "40px",
                        width: "100%",
                      }}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    />
                  </Form.Item>
                </Col> */}
              </Row>
            </Form>
          </BaseModal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) =>
  // console.log(state),
  ({
    totalResult: state?.reducer?.ordersResult,
    loading: state?.reducer?.ordersResult?.loading,
    errorLoading: state?.reducer?.ordersResult?.error,
    totalTrucksListResult: state.reducer?.trucksListResult,
    ordersResultInfo: state?.reducer?.ordersResultInfo,
  });

const mapDispatchToProps = (dispatch) => ({
  actionFetchTrucksList: () => {
    dispatch(getTrucksList());
  },
  actionGetDispatch: (data) => {
    dispatch(getDispatchSummary(data));
  },
  actionFetchGetOrders: (id) => {
    dispatch(getOrders());
  },
  actionUpdateOrder: (id, data) => {
    dispatch(updateOrder(id, data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DispatchArchive);
