import ErrorImage from "../Images/ErrorImage.png";
// import Lottie from "lottie-react";
// import Error400 from "../Json/Error400.json";

const ErrorView = (props) => {
  const {
    type,
    refreshOwner,
    refreshTruck,
    refreshQuarry,
    refreshCrm,
    refreshRoles,
    refreshLeadDetails,
    refreshCompanyDetails,
    refreshUsers,
  } = props;
  const onRefresh = () => {
    if (type === "Owner") {
      refreshOwner();
    } else if (type === "Truck") {
      refreshTruck();
    } else if (type === "QuarryTool") {
      refreshQuarry();
    } else if (type === "CRM") {
      refreshCrm();
    } else if (type === "ManageRoles") {
      refreshRoles();
    } else if (type === "LeadDetails") {
      refreshLeadDetails();
    } else if (type === "CompanyDetails") {
      refreshCompanyDetails();
    } else if (type === "Users") {
      refreshUsers();
    }
  };
  return (
    <div
      style={{
        minHeight: "70vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={ErrorImage}
        alt="error"
        style={{ height: "220px", borderRadius: "12px" }}
      />
      <p
        style={{
          marginTop: "25px",
          fontSize: "22px",
          fontFamily: "poppins",
          fontWeight: "500",
          color: "#323233",
        }}
      >
        Please Try Again...
      </p>
      <button
        type="button"
        onClick={onRefresh}
        style={{
          cursor: "pointer",
          marginTop: "6px",
          borderRadius: "6px",
          border: "none",
          backgroundColor: "#69c0ff",
          color: "#ffffff",
          width: "75px",
          height: "35px",
          fontSize: "14px",
        }}
      >
        Refresh
      </button>
    </div>
  );
};
export default ErrorView;
