import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  DatePicker,
  Select,
  Divider,
  Tabs,
  Badge,
  Button,
  InputNumber,
  Dropdown,
  Menu,
  message,
} from "antd";
import { DollarOutlined, DownloadOutlined } from "@ant-design/icons";
import Filter from "../../Common/Filter";
import FooterData from "../../Common/FooterData";
import { Storage } from "aws-amplify";
import { list, update } from "../../api/Apis";
import BaseModal from "../../Common/BaseModal";
import BaseTable from "../../Common/BaseTable";
import moment from "moment";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import Loading from "../../Common/Loading";
import DownloadLoading from "../../Common/DownloadLoading";
import appConfig from "../../config/AppConfig";
import { IoCaretDownCircleOutline } from "react-icons/io5";
import AOS from "aos";
import "aos/dist/aos.css";
import TextArea from "antd/lib/input/TextArea";
import Invoice from "../Finance/Invoices/Invoice";
import { downloadExcel } from "react-export-table-to-excel";
import excelIcon from "../../Images/excel.png";
import excelIcon1 from "../../Images/excel1.png";
import { withAppContext } from "../../Store/AppContext";
import { withRouter } from "react-router-dom";

// import { FilePdfOutlined } from "@ant-design/icons";

const paymentCategories = [
  { label: "Bank Payment", value: "Bank Payment" },
  { label: "Cash", value: "Cash" },
  { label: "Check", value: "Check" },
  // { label: "Credit card", value: "Credit card" },
  // { label: "Paypal", value: "Paypal" },
  // { label: "Other", value: "Other" },
];
class AccountsReceivable extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: "",
      boardView: true,
      listView: false,
      loading: false,
      selectionType: "",
      selectedRowKeys: [],
      selected: true,
      color: "active",
      visible: false,
      unPaidData: [],
      overDueData: [],
      PaidData: [],
      filterData: [],
      totalData: [],
      filteredCustomers: [],
      fromDate: "",
      toDate: "",
      companyName: undefined,
      invoiceNumber: "",
      tabKey: "1",
      dueNext30Days: 0,
      paidData1: 0,
      overDueAmount: 0,
      payOut: "",
      showPaymentModal: false,
      paymentData: "",
      showPDFview: false,
      paidDaysData: [],
      selectedData: [],
      paymentIndex: 0,
      updateCount: 0,
      // cardDatefiled: "",
      loadingDownload: false,
      downloadProgress: 0,
      modalVisible: false,
    };
  }

  componentDidMount() {
    this.getinvoices();
    document.title = "Twisted Nail - Accounts Receivable";
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
  }

  getinvoices = () => {
    this.setState({
      loading: true,
    });

    list("invoices")
      .then((result) => {
        let filterInvoiceData = result.data?.filter(
          (d) => d.updateInvoice === "Invoiced"
        );
        filterInvoiceData?.map((e) => {
          if (e?.companyTerms !== "Cash" && e["ARinvoiceStatus"] !== "Paid") {
            var current = new Date(e.createdAt);
            current.setDate(
              current.getDate() + parseInt(e?.companyTerms?.split("Net")[1])
            );
            var x = new moment(moment(current).format("l"));
            var y = new moment(moment().format("l"));
            var diff = x - y;
            let duration = Math.ceil(diff / (1000 * 60 * 60 * 24));
            // var duration = moment.duration(x.diff(y));
            e["ARdueDays"] = duration;
            if (duration >= 0) {
              e["ARinvoiceStatus"] = "Unpaid";
            } else {
              e["ARinvoiceStatus"] = "Overdue";
            }
          } else {
            if (e.ARinvoiceStatus === "Paid") {
              let x = new moment(moment(e.ARPaymentDate).format("l"));
              let y = new moment(moment(e.createdAt).format("l"));
              let diff = x - y;
              let duration = Math.ceil(diff / (1000 * 60 * 60 * 24));
              e["paidDays"] = duration;
            }
            e["ARdueDays"] = e["ARdueDays"] ? e["ARdueDays"] : "Cash";
            e["ARinvoiceStatus"] = e["ARinvoiceStatus"]
              ? e["ARinvoiceStatus"]
              : "Paid";
          }
          return true;
        });
        let currentDate = new Date();
        let beforeOneDay = new Date();
        beforeOneDay.setDate(beforeOneDay.getDate() - 1);
        currentDate.setDate(currentDate.getDate() + 30);

        let dueNext30Days = filterInvoiceData?.filter(
          (i) =>
            i.ARinvoiceStatus === "Unpaid" &&
            i.ARdueDays >= 0 &&
            i.ARdueDays <= 29
          // moment(moment(i.createdAt).format("l")).isBetween(
          //   moment(beforeOneDay).format("l"),
          //   moment(currentDate).format("l")
          // )
        );

        if (result.success) {
          let sum = 0;
          let overDueAmount = 0;
          let unPaidData = filterInvoiceData?.filter(
            (i) => i.ARinvoiceStatus === "Unpaid"
          );
          let overDueData = filterInvoiceData?.filter(
            (i) => i.ARinvoiceStatus === "Overdue"
          );
          let PaidData = filterInvoiceData?.filter(
            (i) => i.ARinvoiceStatus === "Paid"
          );

          let filteredCustomers = unPaidData?.filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                (t) => t.ticketData.companyName === value.ticketData.companyName
              )
          );
          let paidData1 = PaidData;
          let total = 0;
          paidData1.map((t) => (total += parseFloat(t.ticketsTotal)));

          dueNext30Days.map(
            (e) =>
              (sum += parseFloat(
                e?.ARpartialPaid ? e?.ARpartialAmount : e.ticketsTotal
              ))
          );
          overDueData.map(
            (e) =>
              (overDueAmount += parseFloat(
                e?.ARpartialPaid ? e?.ARpartialAmount : e.ticketsTotal
              ))
          );
          let payOut = filterInvoiceData
            ?.filter((i) => i.ARinvoiceStatus === "Unpaid")
            .sort((a, b) => (a.ARdueDays > b.ARdueDays ? 1 : -1));

          this.setState({
            totalData: filterInvoiceData,
            filterData: filterInvoiceData,
            PaidData: PaidData,
            unPaidData,
            overDueData,
            overDueAmount,
            filteredCustomers,
            dueNext30Days: sum,
            paidData1: total,
            payOut: parseInt(payOut[0]?.ARdueDays),
            loading: false,
            paidDaysData: filterInvoiceData
              .filter((e) => e.paidDays)
              .reduce((a, v) => (a = a + v.paidDays), 0),
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onSelectChange = (data, record) => {
    this.setState({ selectedRowKeys: data, selectedData: record });
  };

  onFinish = (values) => {
    const { paymentData, selectedData, selectedRowKeys, paymentIndex } =
      this.state;
    if (values["paymentAmount"] === 0) {
      alert("Transaction amount can't be zero.");
      return;
    }
    let balanceAmount = "",
      data = {},
      ARpartialHistory =
        paymentData?.ARpartialHistory?.length > 0
          ? paymentData?.ARpartialHistory
          : [];
    if (paymentData.ARpartialPaid) {
      balanceAmount =
        parseFloat(paymentData.ARpartialAmount) -
        parseFloat(values["paymentAmount"]);
    } else {
      balanceAmount =
        parseFloat(paymentData.ticketsTotal) -
        parseFloat(values["paymentAmount"]);
    }
    ARpartialHistory?.push({
      ARPaymentDate: moment().format("l"),
      ARpartialAmount: balanceAmount,
      ARAmount: values["paymentAmount"],
      ARdueDays: values["paymentMethod"],
    });

    if (balanceAmount === 0) {
      data["entityData"] = {
        ARpartialAmount: balanceAmount,
        ARdueDays: values["paymentMethod"],
        ARinvoiceStatus: "Paid",
        ARPaymentDate: moment().format("l"),
        ARpartialHistory,
        ARpartialPaid: false,
      };
    } else if (balanceAmount < 0) {
      alert("Transaction amount is more than the maximum limit amount.");
      return;
    } else {
      data["entityData"] = {
        ARpartialPaid: true,
        ARpartialAmount: balanceAmount,
        ARpartialHistory,
      };
    }

    update("invoices", paymentData.id, data)
      .then((res) => {
        try {
          if (res.success) {
            message.success("Payment recorded successfully");
            if (
              selectedRowKeys?.length > 0 &&
              selectedRowKeys.length !== paymentIndex + 1
            ) {
              let AmountData = selectedData[paymentIndex + 1];
              this.showModalPayment(selectedData, paymentIndex + 1);
              this.setState({
                paymentIndex: paymentIndex + 1,
              });
              this.formRef.current.setFieldsValue({
                paymentAmount: AmountData.ARpartialPaid
                  ? AmountData.ARpartialAmount
                  : AmountData.ticketsTotal,
                paymentMethod: selectedData.paymentMethod,
              });
            } else {
              this.setState({
                showPaymentModal: false,
                loading: false,
                selectedRowKeys: [],
              });
              this.getinvoices();
              if (selectedRowKeys?.length > 0) {
                message.info("All payments recorded successfully!");
              }
            }
          }
        } catch {
          console.log("err");
        }
      })
      .catch((err) => console.log(err));
    // console.log(data, values);
  };

  getAddress = (address, latLng, id) => {
    this.setState({
      selectedAddress: address,
    });
  };

  showModalPayment = (data, paymentIndex) => {
    let record = data[paymentIndex];
    this.setState({ showPaymentModal: true, paymentData: record });
  };

  closeFilter = () => {
    this.setState({
      visible: false,
    });
  };

  fileDownloadQuote = async (fileName) => {
    this.setState({
      loading: true,
    });
    Storage.get(fileName, { download: true }).then((res) =>
      this.downloadBlobQuote(res.Body, "fileName")
    );
  };
  downloadInvoice = async (fileName) => {
    this.setState({
      loading: true,
    });
    fileName?.map((e) =>
      Storage.get(e.invoicePath, { download: true })
        .then((res) => this.downloadBlobQuote(res.Body, "fileName"))
        .catch((err) => console.log(err))
    );
  };

  downloadBlobQuote(blob, fileName) {
    // console.log(fileName, "fileName");
    //https://twisted-nail-dev-attachments.s3.us-east-1.amazonaws.com/quotes/7d0e0df0-1fb8-11ed-b551-fd2cfd43234b_quote.pdf?x-id=GetObject
    // const url = `https://${process.env.REACT_APP_S3_BUCKET}.s3.us-east-1.amazonaws.com/${fileName}`;
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.download = fileName || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    message.success("Downloaded successfully");
    this.setState({
      loading: false,
    });
    return a;
  }

  updateProgress = (percentage) => {
    this.setState({ downloadProgress: percentage });
  };

  downloadZip = async (images, accountReceivable) => {
    this.setState({
      loadingDownload: true,
      modalVisible: true,
    });

    const zip = new JSZip();
    const folders = {};

    // Fetch and organize images into folders based on companyId
    const promises = images.map(async (image) => {
      const companyId = image.companyName;
      if (!folders[companyId]) {
        folders[companyId] = zip.folder(companyId);
      }
      const folder = folders[companyId];
      const invoicePath = image.invoicePath;

      // Fetch the image URL from Storage
      try {
        const imageUrl = await Storage.get(invoicePath);

        // Add the image to the folder
        folder.file(
          invoicePath,
          await fetch(imageUrl).then((response) => response.blob()),
          { binary: true }
        );

        // Calculate and update the progress
        const completedCount = Object.keys(folders).length;
        const totalCount = images.length;
        const percentage = (completedCount / totalCount) * 100;
        this.updateProgress(percentage);
      } catch (error) {
        console.error(`Error fetching ${invoicePath} from Storage: ${error}`);
      }
    });

    // Wait for all promises to resolve
    await Promise.all(promises);

    // Generate the zip file
    const content = await zip.generateAsync({ type: "blob" });
    // Save the zip file
    saveAs(content, `accountReceivable`);
    window.location.href = "/app/accountReceivable";
    this.setState({
      loadingDownload: false,
      downloadProgress: 0,
      selectedData: [],
    });
  };

  searchResult = (filterData) => {
    this.setState({
      filterData,
    });
  };

  handleInvoice = (e) => {
    let serachValue = e.target.value;
    let data = [];
    if (serachValue !== "") {
      data = this.state.totalData.filter((e) =>
        e.invoiceNumber.includes(serachValue)
      );
    } else {
      data = this.state.totalData;
    }
    this.setState({
      filterData: data,
    });
  };

  handleToDate = (e) => {
    const { fromDate } = this.state;
    let toDateBefore = new Date(e);
    toDateBefore.setDate(toDateBefore.getDate() + 1);
    let fromDateBefore = new Date(fromDate);
    fromDateBefore.setDate(fromDateBefore.getDate() - 1);
    let data = [];
    if (e !== "" && fromDate !== "") {
      data = this.state.totalData.filter((e) =>
        moment(moment(e.createdAt).format("l")).isBetween(
          moment(fromDateBefore).format("l"),
          moment(toDateBefore).format("l")
        )
      );
    } else {
      data = this.state.totalData;
    }
    this.setState({
      filterData: data,
      toDate: e,
    });
  };

  handleSelect = (e) => {
    let serachValue = e;
    let data = [];
    if (serachValue !== "") {
      data = this.state.totalData.filter((e) =>
        e.companyName.includes(serachValue)
      );
    } else {
      data = this.state.totalData;
    }
    this.setState({
      filterData: data,
    });
  };

  handleFilter = () => {
    const { companyName, totalData, invoiceNumber, fromDate, toDate, tabKey } =
      this.state;
    let toDateBefore = new Date(toDate);
    toDateBefore.setDate(toDateBefore.getDate() + 1);
    let fromDateBefore = new Date(fromDate);
    fromDateBefore.setDate(fromDateBefore.getDate() - 1);
    let filterData = [];
    if (
      companyName !== "" &&
      companyName !== undefined &&
      invoiceNumber !== "" &&
      toDate !== "" &&
      fromDate !== ""
    ) {
      filterData = totalData.filter(
        (e) =>
          e["ticketData"]["companyName"]?.indexOf(companyName) > -1 &&
          e["invoiceNumber"]?.indexOf(invoiceNumber) > -1 &&
          moment(moment(e.createdAt).format("l")).isBetween(
            moment(fromDateBefore).format("l"),
            moment(toDateBefore).format("l")
          )
      );
    } else if (
      companyName !== "" &&
      invoiceNumber !== "" &&
      companyName !== undefined
    ) {
      filterData = totalData.filter(
        (e) =>
          e["ticketData"]["companyName"]?.indexOf(companyName) > -1 &&
          e["invoiceNumber"]?.indexOf(invoiceNumber) > -1
      );
    } else if (
      companyName !== undefined &&
      companyName !== "" &&
      toDate !== "" &&
      fromDate !== ""
    ) {
      filterData = totalData.filter(
        (e) =>
          e["ticketData"]["companyName"]?.indexOf(companyName) > -1 &&
          moment(moment(e.createdAt).format("l")).isBetween(
            moment(fromDateBefore).format("l"),
            moment(toDateBefore).format("l")
          )
      );
    } else if (invoiceNumber !== "" && toDate !== "" && fromDate !== "") {
      filterData = totalData.filter(
        (e) =>
          e["invoiceNumber"].indexOf(invoiceNumber) > -1 &&
          moment(moment(e.createdAt).format("l")).isBetween(
            moment(fromDateBefore).format("l"),
            moment(toDateBefore).format("l")
          )
      );
    } else if (companyName !== undefined && companyName !== "") {
      filterData = totalData.filter(
        (e) => e["ticketData"]["companyName"]?.indexOf(companyName) > -1
      );
    } else if (invoiceNumber !== "") {
      filterData = totalData.filter(
        (e) => e["invoiceNumber"]?.indexOf(invoiceNumber) > -1
      );
    } else if (toDate !== "" && fromDate !== "") {
      filterData = totalData.filter((e) =>
        moment(moment(e.createdAt).format("l")).isBetween(
          moment(fromDateBefore).format("l"),
          moment(toDateBefore).format("l")
        )
      );
    }
    if (tabKey === "1") {
      this.setState({
        unPaidData: filterData?.filter((i) => i.ARinvoiceStatus === "Unpaid"),
        selectedData: filterData?.filter((i) => i.ARinvoiceStatus === "Unpaid"),
      });
    } else if (tabKey === "2") {
      this.setState({
        overDueData: filterData?.filter((i) => i.ARinvoiceStatus === "Overdue"),
        selectedData: filterData?.filter(
          (i) => i.ARinvoiceStatus === "Overdue"
        ),
      });
    } else if (tabKey === "3") {
      this.setState({
        PaidData: filterData?.filter((i) => i.ARinvoiceStatus === "Paid"),
        selectedData: filterData?.filter((i) => i.ARinvoiceStatus === "Paid"),
      });
    } else {
      this.setState({
        filterData,
        selectedData: filterData,
      });
    }
  };

  handleReset = () => {
    const { totalData } = this.state;
    this.setState({
      invoiceNumber: "",
      selectedData: [],
      toDate: "",
      fromDate: "",
      companyName: undefined,
      filterData: totalData,
      unPaidData: totalData?.filter((i) => i.ARinvoiceStatus === "Unpaid"),
      overDueData: totalData?.filter((i) => i.ARinvoiceStatus === "Overdue"),
      PaidData: totalData?.filter((i) => i.ARinvoiceStatus === "Paid"),
    });
  };

  handleTabChange = (e) => {
    const { totalData } = this.state;
    let filteredData = [];
    if (Number(e) === 1) {
      filteredData = totalData.filter((e) => e.ARinvoiceStatus === "Unpaid");
    } else if (Number(e) === 2) {
      filteredData = totalData.filter((e) => e.ARinvoiceStatus === "Overdue");
    } else if (Number(e) === 3) {
      filteredData = totalData.filter((e) => e.ARinvoiceStatus === "Paid");
    } else {
      filteredData = totalData;
    }
    let filteredCustomers = filteredData?.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t) => t.ticketData.companyName === value.ticketData.companyName
        )
    );
    this.setState({ tabKey: e, filteredCustomers });
    this.handleReset();
  };

  accountpayitem = (record) => {
    // console.log(record);
    return (
      <Menu>
        {record.ARinvoiceStatus !== "Paid" && (
          <Menu.Item onClick={() => this.showModalPayment([record], 0)}>
            Record payment
          </Menu.Item>
        )}
        {(record?.ARpartialHistory?.length > 0 ||
          record?.ARinvoiceStatus === "Paid") && (
          <Menu.Item
            onClick={() => {
              this.setState({
                showPDFview: true,
                paymentData: record,
              });
            }}
          >
            View
          </Menu.Item>
        )}
        {/* {record?.ARpartialHistory?.length > 0 && (
          <Menu.Item>Export as PDF</Menu.Item>
        )} */}
      </Menu>
    );
  };

  exportData = (selectedData) => {
    this.setState({
      loading: true,
    });
    if (selectedData?.length > 0) {
      let data = [];
      const header = [
        "INVOICE #",
        "INVOICE DATE",
        "CUSTOMER",
        "DUE IN",
        "STATUS",
        "AMOUNT DUE",
      ];
      selectedData?.map((e) =>
        data.push({
          invoiceNumber: e?.invoiceNumber,
          createdAt: moment(e?.createdAt).format("l"),
          companyName: e.ticketData?.companyName,
          paymentMethod:
            e?.ARdueDays !== "Cash" &&
            e?.ARdueDays !== "Bank Payment" &&
            e?.ARdueDays !== "Check"
              ? e?.ARdueDays >= 0
                ? e?.ARdueDays + " days"
                : Math.abs(e?.ARdueDays) === 1
                ? Math.abs(e?.ARdueDays) + " day ago"
                : Math.abs(e?.ARdueDays) + " days ago"
              : e?.ARdueDays,
          status: e?.ARinvoiceStatus,
          amountDue: e?.ARpartialPaid
            ? `$ ` + parseFloat(e?.ARpartialAmount)?.toLocaleString(2)
            : `$ ` + parseFloat(e?.ticketsTotal)?.toLocaleString(2),
        })
      );

      downloadExcel({
        fileName: "Accounts Receivable Details",
        sheet: "Accounts Receivable Invoices",
        tablePayload: {
          header,
          body: data,
        },
      });
      this.setState({
        loading: false,
      });

      message.success("Record downloaded Successfully!");
    } else {
      message.error("Please filter atleast one customer!");
      this.setState({
        loading: false,
      });
    }
  };

  handleTableChage = (props, type) => {
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    localStorage.setItem(
      "tableSizeRange",
      JSON.stringify({ ...tableSizeRange, [type]: props })
    );
  };

  render() {
    const {
      loading,
      companyName,
      selectedRowKeys,
      filterData,
      unPaidData,
      overDueData,
      filteredCustomers,
      fromDate,
      toDate,
      invoiceNumber,
      dueNext30Days,
      // paidData1,
      overDueAmount,
      showPaymentModal,
      paymentData,
      showPDFview,
      paidDaysData,
      PaidData,
      tabKey,
      selectedData,
      loadingDownload,
      // payOut,
    } = this.state;

    const { Option } = Select;

    const columns = [
      {
        title: "INVOICE #",
        dataIndex: "invoiceNumber",
        key: "invoiceNumber",
        sorter: (a, b) => (a?.invoiceNumber < b?.invoiceNumber ? -1 : 1),
        className: "col_styling",
        width: "10%",
        onCell: (record) => ({
          onClick: () => {
            this.setState({
              showPDFview: true,
              paymentData: record,
            });
          },
        }),
        render: (invoiceNumber) => {
          return (
            <span
              className="col_styling table-font-mobile-accounts"
              style={{
                textTransform: "capitalize",
              }}
            >
              {invoiceNumber}
            </span>
          );
        },
      },
      {
        title: "INVOICE DATE",
        dataIndex: "createdAt",
        key: "createdAt",

        sorter: (a, b) => {
          const createdAtA = moment(a.createdAt);
          const createdAtB = moment(b.createdAt);
          return createdAtA - createdAtB;
        },
        className: "col_styling",
        width: "10%",
        onCell: (record) => ({
          onClick: () => {
            this.setState({
              showPDFview: true,
              paymentData: record,
            });
          },
        }),
        render: (createdAt) => {
          return (
            <span
              className="col_styling table-font-mobile-accounts"
              style={{
                textTransform: "capitalize",
              }}
            >
              {moment(createdAt).format("l")}
            </span>
          );
        },
      },
      {
        title: "CUSTOMER",
        dataIndex: "companyName",
        key: "companyName",
        sorter: (a, b) => {
          const nameA = a?.ticketData?.companyName?.toLowerCase();
          const nameB = b?.ticketData?.companyName?.toLowerCase();
          return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
        },
        className: "col_styling",
        width: "25%",
        onCell: (record) => ({
          onClick: () => {
            this.setState({
              showPDFview: true,
              paymentData: record,
            });
          },
        }),
        render: (companyName, record) => {
          return (
            <span
              className="col_styling table-font-mobile-accounts"
              style={{
                textTransform: "capitalize",
                color: "#212121",
              }}
            >
              {record?.ticketData?.companyName}
            </span>
          );
        },
      },
      {
        title: tabKey !== "3" ? "DUE IN" : "PAYMENT METHOD",
        dataIndex: "ARdueDays",
        key: "ARdueDays",
        sorter: false,
        className: "col_styling",
        width: "10%",
        onCell: (record) => ({
          onClick: () => {
            this.setState({
              showPDFview: true,
              paymentData: record,
            });
          },
        }),
        render: (ARdueDays, record) => {
          return (
            <span className="col_styling table-font-mobile-accounts">
              {ARdueDays !== "Cash" &&
              ARdueDays !== "Bank Payment" &&
              ARdueDays !== "Check" ? (
                ARdueDays >= 0 ? (
                  ARdueDays + " days"
                ) : (
                  <span style={{ color: "#d4380d" }}>
                    {Math.abs(ARdueDays) === 1
                      ? Math.abs(ARdueDays) + " day ago"
                      : Math.abs(ARdueDays) + " days ago"}
                  </span>
                )
              ) : (
                ARdueDays
              )}
            </span>
          );
        },
      },
      {
        title: "STATUS",
        dataIndex: "ARinvoiceStatus",
        key: "ARinvoiceStatus",
        sorter: false,
        className: "col_styling",
        width: "10%",
        onCell: (record) => ({
          onClick: () => {
            this.setState({
              showPDFview: true,
              paymentData: record,
            });
          },
        }),
        render: (ARinvoiceStatus) => {
          return (
            <Button
              className="lead_ButtonStatus"
              style={{
                background: "transparent",
                borderRadius: "6px",
                borderColor: appConfig["STAGE_COLORS"][ARinvoiceStatus],
                color: appConfig["STAGE_COLORS"][ARinvoiceStatus],
                borderWidth: "1px",
                borderStyle: "solid",
              }}
            >
              <span
                className="col_styling"
                style={{
                  fontSize: "14px",
                  fontFamily: "sans-serif",
                }}
              >
                {ARinvoiceStatus}
              </span>
            </Button>
          );
        },
      },

      {
        title: tabKey !== "3" ? "AMOUNT DUE" : "AMOUNT PAID",
        dataIndex: "ticketsTotal",
        key: "ticketsTotal",
        sorter: false,
        className: "col_styling",
        width: "15%",
        onCell: (record) => ({
          onClick: () => {
            this.setState({
              showPDFview: true,
              paymentData: record,
            });
          },
        }),
        render: (ticketsTotal, record) => {
          return (
            <span
              className="col_styling table-font-mobile-accounts"
              style={{
                color: "#212121",
              }}
            >
              ${" "}
              {`${parseFloat(
                record?.ARpartialPaid ? record?.ARpartialAmount : ticketsTotal
              ).toLocaleString("us-EN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`}
            </span>
          );
        },
      },
      // {
      //   title: "ACTION",
      //   dataIndex: "action",
      //   key: "action",
      //   sorter: false,
      //   className: "col_styling",
      //   render: () => {
      //     return (
      //       <span
      //         className="col_styling"
      //         style={{
      //           color: "#212121",
      //         }}
      //       >
      //         Record Payment
      //       </span>
      //     );
      //   },
      // },
      {
        title: "ACTIONS",
        dataIndex: "invoiceNumber",
        key: "invoiceNumber",
        sorter: false,
        className: "col_styling",
        width: "15%",
        fixed: "right",
        render: (invoiceNumber, record) => {
          return (
            <div className="actionpayable">
              <span
                className="col_styling table-font-mobile-accounts"
                style={{
                  textTransform: "capitalize",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Payment Actions
              </span>
              <span
                style={{
                  marginLeft: "10px",
                  fontSize: "24px",
                  display: "flex",
                }}
              >
                <Dropdown
                  overlay={() => this.accountpayitem(record)}
                  trigger={["click"]}
                >
                  <IoCaretDownCircleOutline className="col_styling table-font-mobile-accounts" />
                </Dropdown>
              </span>
            </div>
          );
        },
      },
    ];

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          selectedData: selectedRows,
        }); // console.log(selectedFiles,"hgggv");
        console.log(
          `selectedRowKeys: ${selectedRowKeys}`,
          "selectedRows: ",
          selectedRows
        );
      },
    };

    return (
      <div
        style={{
          background: "#fafafa",
        }}
      >
        <DownloadLoading enableDownload={loadingDownload} />
        <Loading enableLoading={loading} />
        {showPDFview ? (
          <Invoice
            paymentData={this.state.paymentData}
            accountReceivable
            onCancel={() => this.setState({ showPDFview: false })}
          />
        ) : (
          <>
            {/* {selectedRowKeys?.length > 0 && (
              <Col className="gridbtntool">
                <Button
                  type="primary"
                  icon={
                    <FilePdfOutlined
                      style={{ fontSize: "22px", color: "#ff0000c2" }}
                    />
                  }
                  onClick={this.exportData}
                  style={{
                    borderRadius: "5px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    background: "#FFF",
                    borderColor: "#ff000063",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                      marginLeft: "5px",
                      color: "#ff0000c2",
                    }}
                  >
                    Export PDF
                  </span>
                </Button>
              </Col>
            )} */}
            <Row
              style={{
                borderRadius: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "20px 0px",
                padding: "0px 20px",
                backgroundColor: "#ffffff",
                border: "1px solid gray",
                textAlign: "left",
              }}
              gutter={[12, 0]}
            >
              <Col
                xxl={{ span: 6 }}
                xl={{ span: 6 }}
                lg={{ span: 6 }}
                md={{ span: 12 }}
                sm={{ span: 12 }}
                xs={{ span: 24 }}
                style={{ margin: "12px 0px" }}
              >
                <p
                  style={{
                    fontSize: "18px",
                    color: "#747474",
                    marginBottom: "-3px",
                    fontWeight: "600",
                  }}
                  className="owner-operator-card-head"
                >
                  Overdue
                </p>
                <p
                  style={{
                    fontSize: "26px",
                    fontWeight: "bold",
                    marginBottom: "0px",
                  }}
                  className="owner-operator-card-body"
                >
                  $
                  {overDueAmount?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}{" "}
                  <span
                    style={{
                      fontSize: "18px",
                      color: "#747474",
                      fontWeight: "500",
                    }}
                    className="owner-operator-card-body"
                  >
                    USD
                  </span>
                </p>
              </Col>
              <Col
                xxl={{ span: 6 }}
                xl={{ span: 6 }}
                lg={{ span: 6 }}
                md={{ span: 12 }}
                sm={{ span: 12 }}
                xs={{ span: 24 }}
                style={{ margin: "12px 0px" }}
              >
                <p
                  style={{
                    fontSize: "18px",
                    color: "#747474",
                    marginBottom: "-3px",
                    fontWeight: "600",
                  }}
                  className="owner-operator-card-head"
                >
                  Due within next 30 days
                </p>
                <p
                  style={{
                    fontSize: "26px",
                    fontWeight: "bold",
                    marginBottom: "0px",
                  }}
                  className="owner-operator-card-body"
                >
                  $
                  {dueNext30Days?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}{" "}
                  <span
                    style={{
                      fontSize: "18px",
                      color: "#747474",
                      fontWeight: "500",
                    }}
                    className="owner-operator-card-body"
                  >
                    USD
                  </span>
                </p>
              </Col>
              <Col
                xxl={{ span: 6 }}
                xl={{ span: 6 }}
                lg={{ span: 6 }}
                md={{ span: 12 }}
                sm={{ span: 12 }}
                xs={{ span: 24 }}
                style={{ margin: "12px 0px" }}
              >
                <p
                  style={{
                    fontSize: "18px",
                    color: "#747474",
                    marginBottom: "-3px",
                    fontWeight: "600",
                  }}
                  className="owner-operator-card-head"
                >
                  Average time to get paid
                </p>
                <p
                  style={{
                    fontSize: "26px",
                    fontWeight: "bold",
                    marginBottom: "0px",
                  }}
                  className="owner-operator-card-body"
                >
                  {parseFloat(
                    paidDaysData / filterData.filter((e) => e.paidDays).length
                  ).toFixed(1) === "NaN"
                    ? 0
                    : parseFloat(
                        paidDaysData /
                          filterData?.filter((e) => e.paidDays)?.length
                      ).toFixed(1)}{" "}
                  <span
                    style={{
                      fontSize: "18px",
                      color: "#747474",
                      fontWeight: "500",
                    }}
                    className="owner-operator-card-body"
                  >
                    Days
                  </span>
                </p>
              </Col>
              {/* <Col
                xxl={{ span: 6 }}
                xl={{ span: 6 }}
                lg={{ span: 6 }}
                md={{ span: 12 }}
                sm={{ span: 12 }}
                xs={{ span: 24 }}
                style={{ margin: "12px 0px" }}
              >
                <p
                  style={{
                    fontSize: "18px",
                    color: "#747474",
                    marginBottom: "-3px",
                    fontWeight: "600",
                  }}
                >
                  Paid
                </p>
                <p
                  style={{
                    fontSize: "26px",
                    fontWeight: "bold",
                    marginBottom: "0px",
                  }}
                >
                  $
                  {paidData1?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}{" "}
                </p>
              </Col> */}
              {/* <Col style={{ margin: "12px 0px" }}>
            <p
              style={{
                fontSize: "18px",
                color: "#b8b8b8",
                marginBottom: "-3px",
                fontWeight: "500",
              }}
            >
              Upcoming Payout
            </p>
            <p
              style={{
                fontSize: "26px",
                fontWeight: "bold",
                marginBottom: "0px",
              }}
            >
              {payOut === 0 ? "Today" : payOut}
              <span
                style={{
                  fontSize: "18px",
                  color: "#b8b8b8",
                  fontWeight: "400",
                }}
              >
                {payOut === 0 ? "" : payOut === 1 ? "Day" : "Days"}
              </span>
            </p>
          </Col> */}
            </Row>

            <div
              style={{
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                margin: "20px 0px",
                justifyContent: "center",
              }}
            >
              <Row
                style={{
                  width: "100%",
                  textAlign: "left",
                }}
              >
                <Col
                  xxl={{ span: 4 }}
                  xl={{ span: 4 }}
                  lg={{ span: 12 }}
                  md={{ span: 12 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <div
                    style={{
                      margin: "12px 0px",
                      width: "100%",
                      padding: "0px 10px",
                    }}
                  >
                    <Select
                      id="reset"
                      size="large"
                      showSearch
                      allowClear
                      className="select_Height"
                      placeholder="All Customers"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      style={{
                        width: "100%",
                      }}
                      onChange={(e) =>
                        this.setState({
                          companyName: e,
                        })
                      }
                      value={companyName}
                    >
                      {filteredCustomers?.map((d, index) => (
                        <Option key={index} value={d?.ticketData?.companyName}>
                          {d?.ticketData?.companyName}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col>
                {/* <Col
                  xxl={{ span: 4 }}
                  xl={{ span: 4 }}
                  lg={{ span: 6 }}
                  md={{ span: 6 }}
                  sm={{ span: 8 }}
                  xs={{ span: 12 }}
                >
                  <div
                    style={{
                      margin: "12px 0px",
                      width: "100%",
                      padding: "0px 10px",
                    }}
                  >
                    <Select
                      size="large"
                      showSearch
                      allowClear
                      className="select_Height"
                      placeholder="All Status"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      style={{ width: "100%" }}
                    >
                      {this.props.carrierData?.map((d, index) => (
                        <Option key={index} value={d.carrierId}>
                          {d.carrier_name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col> */}
                <Col
                  xxl={{ span: 4 }}
                  xl={{ span: 4 }}
                  lg={{ span: 6 }}
                  md={{ span: 6 }}
                  sm={{ span: 8 }}
                  xs={{ span: 12 }}
                >
                  <div
                    style={{
                      margin: "12px 0px",
                      width: "100%",
                      padding: "0px 10px",
                    }}
                  >
                    <DatePicker
                      value={fromDate}
                      className="form-input"
                      style={{ width: "100%" }}
                      placeholder="From"
                      format={"MM/DD/YYYY"}
                      onChange={(e) =>
                        this.setState({
                          fromDate: e,
                          toDate: "",
                        })
                      }
                    />
                  </div>
                </Col>
                <Col
                  xxl={{ span: 4 }}
                  xl={{ span: 4 }}
                  lg={{ span: 6 }}
                  md={{ span: 6 }}
                  sm={{ span: 8 }}
                  xs={{ span: 12 }}
                >
                  <div
                    style={{
                      margin: "12px 0px",
                      width: "100%",
                      padding: "0px 10px",
                    }}
                  >
                    <DatePicker
                      value={toDate}
                      className="form-input"
                      style={{ width: "100%" }}
                      placeholder="To"
                      format={"MM/DD/YYYY"}
                      onChange={(e) =>
                        this.setState({
                          toDate: e,
                        })
                      }
                      // onChange={this.handleToDate}
                      disabledDate={(current) => {
                        return (
                          current && current.valueOf() < new Date(fromDate)
                        );
                      }}
                    />
                  </div>
                </Col>

                <Col
                  xxl={{ span: 4 }}
                  xl={{ span: 4 }}
                  lg={{ span: 6 }}
                  md={{ span: 6 }}
                  sm={{ span: 8 }}
                  xs={{ span: 12 }}
                >
                  <div
                    style={{
                      margin: "12px 0px",
                      width: "100%",
                      padding: "0px 10px",
                    }}
                  >
                    <Input
                      value={invoiceNumber}
                      className="form-input"
                      placeholder="Enter Invoice #"
                      onChange={(e) =>
                        this.setState({
                          invoiceNumber: e.target.value,
                        })
                      }
                      // onChange={this.handleInvoice}
                    />
                  </div>
                </Col>
                <Col
                  xxl={{ span: 4 }}
                  xl={{ span: 4 }}
                  lg={{ span: 4 }}
                  md={{ span: 6 }}
                  sm={{ span: 8 }}
                  xs={{ span: 12 }}
                >
                  <div
                    style={{
                      margin: "12px 0px",
                      width: "max-content",
                      // padding: "0px 10px",
                    }}
                  >
                    <Col
                      className="gridbtntool"
                      style={{
                        borderRadius: "5px",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        fontColor: "white",
                        border:
                          selectedData?.length > 0
                            ? "1px solid #008000"
                            : "1px solid gray",
                        padding: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => this.exportData(this.state.selectedData)}
                    >
                      <span
                        style={{
                          fontWeight: "500",
                          fontSize: "16px",
                          color: "grey",
                          display: "flex",
                          flexWrap: "",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={
                            selectedData?.length > 0 ? excelIcon : excelIcon1
                          }
                          alt="excelIcon"
                          style={{
                            width: "24px",
                            height: "auto",
                            // fontSize: "18px",
                            marginRight: "8px",
                          }}
                        />
                        <div
                          className="fontsettingg"
                          style={{
                            color: selectedData?.length > 0 ? "green" : "gray",
                            fontSize: "14px",
                          }}
                        >
                          Export Excel
                        </div>
                      </span>
                    </Col>
                  </div>
                </Col>
                <Col
                  xxl={{ span: 4 }}
                  xl={{ span: 4 }}
                  lg={{ span: 14 }}
                  md={{ span: 12 }}
                  sm={{ span: 12 }}
                  xs={{ span: 12 }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                      height: "100%",
                      alignItems: "center",
                      padding: "0px 10px",
                    }}
                  >
                    <Button
                      type="primary"
                      style={{
                        height: "35px",
                        width: "100px",
                        borderRadius: "5px",
                        marginRight: "12px",
                      }}
                      disabled={
                        companyName || invoiceNumber || (fromDate && toDate)
                          ? false
                          : true
                      }
                      onClick={this.handleFilter}
                    >
                      Apply
                    </Button>
                    <Button
                      style={{
                        height: "35px",
                        width: "100px",
                        borderRadius: "5px",
                      }}
                      onClick={this.handleReset}
                    >
                      Reset
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>

            <Row>
              <Col
                xxl={{ span: 24 }}
                xl={{ span: 24 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Divider className="linedivider show-on-desktop-portrait" />
                <div className="tabstatus">
                  <Tabs
                    defaultActiveKey="1"
                    className="tabversi"
                    onChange={this.handleTabChange}
                  >
                    <Tabs.TabPane
                      tab={
                        <Row
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Col>Unpaid</Col>
                          <Badge
                            count={unPaidData?.length}
                            dot={false}
                            style={{
                              backgroundColor: "#586370",
                              margin: "1px 5px",
                              display: "flex",
                              alignContent: "center",
                              justifyContent: "center",
                            }}
                            overflowCount={1000}
                          />
                        </Row>
                      }
                      key="1"
                    >
                      <Row>
                        <Col
                          className="show-early-pay-discount"
                          span={24}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            marginBottom: "6px",
                          }}
                        >
                          {selectedData.length > 0 && (
                            <>
                              <Col className="gridbtntool">
                                <Button
                                  style={{
                                    borderRadius: "5px",
                                    height: "40px",
                                  }}
                                  onClick={() => {
                                    this.downloadZip(selectedData);
                                  }}
                                  icon={
                                    <DownloadOutlined
                                      style={{
                                        fontSize: "20px",
                                        marginLeft: "8px",
                                        marginTop: "2px",
                                      }}
                                      className="trucking-filter-icon"
                                    />
                                  }
                                >
                                  <span
                                    style={{
                                      color: "#5a6067",
                                      fontWeight: "500",
                                      fontSize: "16px",
                                    }}
                                    className="col_styling table-font-mobile"
                                  >
                                    Download
                                  </span>
                                </Button>
                              </Col>
                              <Button
                                type="primary"
                                icon={<DollarOutlined />}
                                onClick={() => {
                                  this.showModalPayment(
                                    this.state.selectedData,
                                    0
                                  );
                                }}
                                style={{
                                  borderRadius: "5px",
                                  height: "35px",
                                  margin: "auto 10px",
                                }}
                              >
                                <span>Record Payment</span>
                              </Button>
                            </>
                          )}
                        </Col>
                        <Col
                          xxl={{ span: 24 }}
                          xl={{ span: 24 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <Card
                            bodyStyle={{ padding: "0px" }}
                            // style={{ background: "#eff1f3", minHeight: "80vh" }}
                            data-aos="fade-up"
                          >
                            <BaseTable
                              className="financeinvoice_table table_laptopscreen"
                              columnsData={columns}
                              sortDirections={["ascend", "descend"]}
                              source={unPaidData?.sort((a, b) =>
                                a?.invoiceNumber < b?.invoiceNumber ? 1 : -1
                              )}
                              handleTableChage={(e) =>
                                this.handleTableChage(e, "ARUnpaid")
                              }
                              sizeRange={
                                window.screen.width > 1023
                                  ? JSON.parse(
                                      localStorage.getItem("tableSizeRange")
                                    )?.ARUnpaid
                                  : unPaidData?.length
                              }
                              pagination={
                                window.screen.width > 1023 ? true : false
                              }
                              rowSelection={rowSelection}
                              total={unPaidData?.length}
                              rowKey={(record) => record.id}
                            />
                          </Card>
                        </Col>
                      </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab={
                        <Row
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Col>Overdue</Col>
                          <Badge
                            count={overDueData?.length}
                            dot={false}
                            style={{
                              backgroundColor: "#586370",
                              margin: "1px 5px",
                              display: "flex",
                              alignContent: "center",
                              justifyContent: "center",
                            }}
                            overflowCount={1000}
                          />
                        </Row>
                      }
                      key="2"
                    >
                      <Row>
                        <Col
                          className="show-early-pay-discount"
                          span={24}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            marginBottom: "6px",
                          }}
                        >
                          {selectedData.length > 0 && (
                            <>
                              <Col className="gridbtntool">
                                <Button
                                  style={{
                                    borderRadius: "5px",
                                    height: "40px",
                                  }}
                                  onClick={() => {
                                    this.downloadInvoice(
                                      this.state.selectedData
                                    );
                                  }}
                                  icon={
                                    <DownloadOutlined
                                      style={{
                                        fontSize: "20px",
                                        marginLeft: "8px",
                                        marginTop: "2px",
                                      }}
                                      className="trucking-filter-icon"
                                    />
                                  }
                                >
                                  <span
                                    style={{
                                      color: "#5a6067",
                                      fontWeight: "500",
                                      fontSize: "16px",
                                    }}
                                    className="col_styling table-font-mobile"
                                  >
                                    Download
                                  </span>
                                </Button>
                              </Col>
                              <Button
                                type="primary"
                                icon={<DollarOutlined />}
                                onClick={() => {
                                  this.showModalPayment(
                                    this.state.selectedData,
                                    0
                                  );
                                }}
                                style={{
                                  borderRadius: "5px",
                                  height: "35px",
                                  margin: "auto 10px",
                                }}
                              >
                                <span>Record Payment</span>
                              </Button>
                            </>
                          )}
                        </Col>
                        <Col
                          xxl={{ span: 24 }}
                          xl={{ span: 24 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <Card bodyStyle={{ padding: "0px" }}>
                            <BaseTable
                              className="financeinvoice_table table_laptopscreen"
                              columnsData={columns}
                              sortDirections={["ascend", "descend"]}
                              source={overDueData?.sort((a, b) =>
                                a?.invoiceNumber < b?.invoiceNumber ? 1 : -1
                              )}
                              handleTableChage={(e) =>
                                this.handleTableChage(e, "AROverDue")
                              }
                              sizeRange={
                                window.screen.width > 1023
                                  ? JSON.parse(
                                      localStorage.getItem("tableSizeRange")
                                    )?.AROverDue
                                  : overDueData?.length
                              }
                              pagination={
                                window.screen.width > 1023 ? true : false
                              }
                              rowSelection={rowSelection}
                              total={overDueData?.length}
                              rowKey={(record) => record.id}
                            />
                          </Card>
                          {selectedRowKeys.length > 0 && (
                            <FooterData
                              count={selectedRowKeys.length}
                              invoice
                            />
                          )}
                        </Col>
                      </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab={
                        <Row
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Col>Paid</Col>

                          <Badge
                            count={PaidData?.length}
                            dot={false}
                            style={{
                              backgroundColor: "#586370",
                              margin: "1px 5px",
                              display: "flex",
                              alignContent: "center",
                              justifyContent: "center",
                            }}
                            overflowCount={1000}
                          />
                        </Row>
                      }
                      key="3"
                    >
                      <Col
                        className="show-early-pay-discount"
                        span={24}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          marginBottom: "6px",
                        }}
                      >
                        {selectedData.length > 0 && (
                          <>
                            <Col className="gridbtntool">
                              <Button
                                style={{
                                  borderRadius: "5px",
                                  height: "40px",
                                }}
                                onClick={() => {
                                  this.downloadZip(selectedData);
                                }}
                                icon={
                                  <DownloadOutlined
                                    style={{
                                      fontSize: "20px",
                                      marginLeft: "8px",
                                      marginTop: "2px",
                                    }}
                                    className="trucking-filter-icon"
                                  />
                                }
                              >
                                <span
                                  style={{
                                    color: "#5a6067",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                  }}
                                  className="col_styling table-font-mobile"
                                >
                                  Download
                                </span>
                              </Button>
                            </Col>
                          </>
                        )}
                      </Col>
                      <Col
                        xxl={{ span: 24 }}
                        xl={{ span: 24 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Card bodyStyle={{ padding: "0px" }}>
                          <BaseTable
                            className="financeinvoice_table table_laptopscreen"
                            columnsData={columns}
                            sortDirections={["ascend", "descend"]}
                            source={PaidData?.sort((a, b) =>
                              a?.invoiceNumber < b?.invoiceNumber ? 1 : -1
                            )}
                            handleTableChage={(e) =>
                              this.handleTableChage(e, "ARPaid")
                            }
                            sizeRange={
                              window.screen.width > 1023
                                ? JSON.parse(
                                    localStorage.getItem("tableSizeRange")
                                  )?.ARPaid
                                : PaidData?.length
                            }
                            pagination={
                              window.screen.width > 1023 ? true : false
                            }
                            rowSelection={rowSelection}
                            total={PaidData?.length}
                            rowKey={(record) => record.id}
                          />
                        </Card>
                      </Col>
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab={
                        <Row
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Col>All Invoices</Col>

                          <Badge
                            count={filterData?.length}
                            dot={false}
                            style={{
                              backgroundColor: "#586370",
                              margin: "1px 5px",
                              display: "flex",
                              alignContent: "center",
                              justifyContent: "center",
                            }}
                            overflowCount={1000}
                          />
                        </Row>
                      }
                      key="4"
                    >
                      <Row>
                        <Col
                          className="show-early-pay-discount"
                          span={24}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            marginBottom: "6px",
                          }}
                        >
                          {selectedData.length > 0 && (
                            <>
                              <Col className="gridbtntool">
                                <Button
                                  style={{
                                    borderRadius: "5px",
                                    height: "40px",
                                  }}
                                  onClick={() => {
                                    this.downloadZip(selectedData);
                                  }}
                                  icon={
                                    <DownloadOutlined
                                      style={{
                                        fontSize: "20px",
                                        marginLeft: "8px",
                                        marginTop: "2px",
                                      }}
                                      className="trucking-filter-icon"
                                    />
                                  }
                                >
                                  <span
                                    style={{
                                      color: "#5a6067",
                                      fontWeight: "500",
                                      fontSize: "16px",
                                    }}
                                    className="col_styling table-font-mobile"
                                  >
                                    Download
                                  </span>
                                </Button>
                              </Col>
                            </>
                          )}
                        </Col>
                        <Col
                          xxl={{ span: 24 }}
                          xl={{ span: 24 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <Card bodyStyle={{ padding: "0px" }}>
                            <BaseTable
                              className="financeinvoice_table table_laptopscreen"
                              columnsData={columns}
                              sortDirections={["ascend", "descend"]}
                              source={filterData?.sort((a, b) =>
                                a?.invoiceNumber < b?.invoiceNumber ? 1 : -1
                              )}
                              handleTableChage={(e) =>
                                this.handleTableChage(e, "ARAllInvoices")
                              }
                              sizeRange={
                                window.screen.width > 1023
                                  ? JSON.parse(
                                      localStorage.getItem("tableSizeRange")
                                    )?.ARAllInvoices
                                  : filterData?.length
                              }
                              pagination={
                                window.screen.width > 1023 ? true : false
                              }
                              rowSelection={rowSelection}
                              total={filterData?.length}
                              rowKey={(record) => record.id}
                            />
                          </Card>
                        </Col>
                      </Row>
                    </Tabs.TabPane>
                  </Tabs>
                </div>
              </Col>
            </Row>
          </>
        )}

        {showPaymentModal && paymentData && (
          <BaseModal
            className="tickectfinan_modal antmodal_grid headermodal"
            title={`Record a payment for Invoice #${paymentData.invoiceNumber}`}
            visible={showPaymentModal}
            onCancel={() => {
              this.setState({ showPaymentModal: false });
            }}
            formId="paymentForm"
          >
            {/* <span className="payable-title">Record a Payment you've already received,Suchas checque,cash or Bank payment</span> */}

            <Form
              id="paymentForm"
              onFinish={this.onFinish}
              ref={this.formRef}
              className="formpayablecontent"
            >
              <Row gutter={[8, 0]}>
                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <div className="payflex">
                    <span className="title_changes">Payment Date</span>
                    <Form.Item
                      name="paymentDate"
                      rules={[
                        {
                          required: true,
                          message: "Please input your paymentDate!",
                        },
                      ]}
                      initialValue={moment()}
                    >
                      <DatePicker
                        className="form-input payabledate"
                        style={{ width: "100%" }}
                        format={"MM/DD/YYYY"}
                        placeholder="Payment Date"
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <div className="payflex">
                    <span className="title_changes">Amount</span>
                    <Form.Item
                      name="paymentAmount"
                      rules={[
                        {
                          required: true,
                          message: "Please input your paymentAmount!",
                        },
                      ]}
                      initialValue={
                        paymentData && paymentData.ARpartialPaid
                          ? paymentData.ARpartialAmount
                          : paymentData.ticketsTotal
                      }
                    >
                      <InputNumber
                        formatter={(value) => {
                          const formattedValue = `${parseFloat(value || 0)}`;
                          return formattedValue.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          );
                        }}
                        parser={(value) => {
                          return parseFloat(value.replace(/\$\s?|(,*)/g, ""));
                        }}
                        prefix={"$"}
                        precision={2}
                        decimalSeparator={"."}
                        placeholder="Please Enter Payment Amount"
                        style={{
                          width: "100%",
                        }}
                        className="numberbox"
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <div className="payflex">
                    <span className="title_changes">Payment Method</span>
                    <Form.Item
                      name="paymentMethod"
                      rules={[
                        {
                          required: true,
                          message: "Please input your paymentMethod!",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        showSearch
                        allowClear
                        className="select_Height"
                        placeholder="Please Select A Payment Method"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        style={{ width: "100%" }}
                      >
                        {paymentCategories?.map((d, index) => (
                          <Option key={index} value={d.value}>
                            {d.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <div className="payflex">
                    <span className="title_changes">Notes</span>
                    <Form.Item
                      name="paymentNotes"
                      rules={[
                        {
                          required: false,
                          message: "Please input your paymentNotes!",
                        },
                      ]}
                    >
                      <TextArea
                        rows={4}
                        style={{
                          width: "100%",
                          height: "75px",
                          borderColor: "#d6d6d6",
                        }}
                        className="form-input"
                        placeholder="Please Enter Notes"
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}
        {this.state.visible && (
          <Filter visible={this.state.visible} closeFilter={this.closeFilter} />
        )}
      </div>
    );
  }
}

export default withAppContext(withRouter(AccountsReceivable));
