import React, { Component } from "react";
import { Row, Col, Card, Button } from "antd";
import BaseTable from "../../Common/BaseTable";
import Loading from "../../Common/Loading";
import SearchFunction from "../../Common/SearchFunction";
import { connect } from "react-redux";
import { getInvoices } from "../../Redux/Actions/actions";
import ErrorView from "../../Common/ErrorView";
import { list } from "../../api/Apis";
import { IoFilterSharp } from "react-icons/io5";
import Filter from "../../Common/Filter";
import { withAppContext } from "../../Store/AppContext";
import { withRouter } from "react-router-dom";
import moment from "moment";

class RevenueAnalysis extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceData: [],
      filterData: [],
      customerData: [],
      visible: false,
      checkData: "",
      reportData: {
        jobCount: 0,
        totalRevenue: 0,
        totalCost: 0,
        totalProfit: 0,
        profitPercentage: 0,
      },
    };
  }

  componentDidMount() {
    this.getsalePerson();
    // this.getinvoices();
    this.props?.actionFetchGetInvoices();
    document.title = "Twisted Nail - Revenue Analysis";
  }

  getsalePerson = () => {
    list("salesPerson")
      .then((result) => {
        if (result.success) {
          let totalRevenue = 0,
            jobCount = 0,
            totalCost = 0,
            totalProfit = 0,
            profitPercentage = 0;
          result?.data?.forEach((element) => {
            if (element?.reports?.length > 0) {
              if (element?.reports[0]?.invoicedJobs !== null) {
                jobCount += parseInt(element?.reports[0]?.invoicedJobs);
              }
              if (element?.reports[0]?.lifetimeRevenue !== null) {
                totalRevenue += parseFloat(
                  element?.reports[0]?.mLifetimeRevenue +
                    element?.reports[0]?.tLifetimeRevenue
                );
              }
              if (element?.reports[0]?.materialtotalCost !== null) {
                totalCost += parseFloat(
                  element?.reports[0]?.materialtotalCost +
                    element?.reports[0]?.trucktotalCost
                );
              }
              if (element?.reports[0]?.totalProfit !== null) {
                totalProfit += parseFloat(
                  element?.reports[0]?.materialTotalProfit +
                    element?.reports[0]?.truckTotalProfit
                );
              }
            }
          });
          profitPercentage =
            totalProfit !== 0 && totalRevenue !== 0
              ? parseFloat((totalProfit / totalRevenue) * 100)
              : 0;
          this.setState({
            reportData: {
              jobCount,
              totalRevenue,
              totalCost,
              totalProfit,
              profitPercentage,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps?.totalResult?.success === false &&
      this.props?.totalResult?.success === true
    ) {
      this.props?.totalResult?.invoicesData?.forEach((element) => {
        if (element?.ticketData?.isTruckingJob) {
          element["cost"] = parseFloat(
            element?.ticketData.selectedMaterial[0]?.truckingCost *
              parseFloat(element?.ticketData?.quantity)
          );
          element["profit"] =
            element?.ticketsTotal -
            parseFloat(
              element?.ticketData.selectedMaterial[0]?.truckingCost *
                parseFloat(element?.ticketData?.quantity)
            );
          element["profitPercentage"] = parseFloat(
            (parseFloat(
              element?.ticketsTotal -
                parseFloat(
                  element?.ticketData.selectedMaterial[0]?.truckingCost *
                    parseFloat(element?.ticketData?.quantity)
                )
            ) /
              element?.ticketsTotal) *
              100
          );
        } else {
          element["cost"] = parseFloat(
            parseFloat(
              element?.ticketData?.selectedMaterial[0]?.materialCost *
                parseFloat(element?.ticketData?.quantity)
            ) +
              parseFloat(
                element?.ticketData.selectedMaterial[0]?.truckingCost *
                  parseFloat(element?.ticketData?.quantity)
              )
          );

          element["profit"] = parseFloat(
            element?.ticketsTotal -
              (parseFloat(
                element?.ticketData.selectedMaterial[0]?.materialCost *
                  parseFloat(element?.ticketData?.quantity)
              ) +
                parseFloat(
                  element?.ticketData.selectedMaterial[0]?.truckingCost *
                    parseFloat(element?.ticketData?.quantity)
                ))
          );
          element["profitPercentage"] = parseFloat(
            ((element?.ticketsTotal -
              (parseFloat(
                element?.ticketData.selectedMaterial[0]?.materialCost *
                  parseFloat(element?.ticketData?.quantity)
              ) +
                parseFloat(
                  element?.ticketData.selectedMaterial[0]?.truckingCost *
                    parseFloat(element?.ticketData?.quantity)
                ))) /
              element?.ticketsTotal) *
              100
          );
        }
      });
      let customerData = this.props?.totalResult?.invoicesData?.filter(
        (data, index, self) =>
          self?.findIndex((e) => e.companyId === data?.companyId) === index
      );
      this.setState({
        invoiceData: this.props?.totalResult?.invoicesData,
        filterData: this.props?.totalResult?.invoicesData,
        customerData,
      });
    }
  }

  onClickRefresh = () => {
    this.props?.actionFetchGetInvoices();
  };

  searchResult = (searchValue) => {
    const { invoiceData } = this.state;

    let searchFilter = [];
    if (typeof searchValue === "number") {
      searchFilter = invoiceData?.filter(
        (e) => parseInt(e["jobNumber"]) === parseInt(searchValue)
      );
    } else {
      searchFilter = invoiceData?.filter(
        (e) => e["companyName"]?.toLowerCase().indexOf(searchValue) > -1
        // e["ticketsTotal"]?.toLowerCase().indexOf(searchValue) > -1 ||
        // e["ticketData"]?.toLowerCase().indexOf(searchValue) > -1
      );
    }
    // console.log(invoiceData,'invoiceData');
    this.setState({
      filterData: searchFilter,
    });
  };

  onResetData = () => {
    this.setState({
      filterData: this.state.invoiceData,
    });
    // this.props.actionFetchCompanies();
  };

  closeFilter = () => {
    this.setState({
      visible: false,
      filterVisible: false,
    });
  };

  filterResultData = (data) => {
    const { invoiceData } = this.state;
    let company = data.company !== "" ? data.company : undefined;
    if (company !== undefined) {
      let filterCustomer = invoiceData?.filter((e) => company === e.companyId);
      let totalRevenue = 0,
        totalCost = 0,
        totalProfit = 0,
        profitPercentage = 0;
      filterCustomer?.forEach((element) => {
        totalRevenue += parseFloat(element?.ticketsTotal);
        totalCost += parseFloat(element?.cost);
        totalProfit += parseFloat(element?.profit);
        profitPercentage += parseFloat(element?.profitPercentage);
      });
      this.setState({
        filterData: filterCustomer,
        visible: false,
        checkData: data,
        reportData: {
          jobCount: filterCustomer.filter(
            (val, ind, self) =>
              self.findIndex((e) => e.jobId === val.jobId) === ind
          )?.length,
          totalRevenue,
          totalCost,
          totalProfit,
          profitPercentage,
        },
      });
    }
  };

  onClearFilter = () => {
    const { invoiceData } = this.state;
    this.getsalePerson();
    this.setState({
      filterData: invoiceData,
      visible: false,
      checkData: "",
    });
  };

  handleTableChage = (props) => {
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    localStorage.setItem(
      "tableSizeRange",
      JSON.stringify({ ...tableSizeRange, projectAnalysis: props })
    );
  };

  calculateDuration = (startDate, endDate) => {
    let projectDuration = "";
    if (startDate && endDate) {
      var start = new moment(moment(new Date(startDate)).format("l"));
      var end = new moment(moment(new Date(endDate)).format("l"));
      var diff = end - start;
      let duration = Math.ceil(diff / (1000 * 60 * 60 * 24));
      projectDuration = Math.round(duration * (5 / 7));
    }
    return projectDuration;
  };

  render() {
    const { filterData, reportData, visible, customerData, checkData } =
      this.state;
    const { loading, errorLoading } = this.props;

    const columns = [
      {
        title: "JOB #",
        dataIndex: "jobNumber",
        key: "jobNumber",
        sorter: false,
        // className: "col_styling",
        // onCell: (record) => ({
        //   onClick: () =>
        //     (window.location = `/app/Invoice-Management/${record.id}`),
        // }),
        render: (jobNumber, record) => {
          return (
            <span
              className="col_styling table-font-mobile"
              style={{
                textTransform: "capitalize",
                width: "10px",
                color: "#212121",
              }}
            >
              {record?.ticketData?.jobNumber}
            </span>
          );
        },
      },
      {
        title: "JOB NAME",
        dataIndex: "jobName",
        key: "jobName",
        sorter: false,
        className: "col_styling",
        // onCell: (record) => ({
        //   onClick: () =>
        //     (window.location = `/app/Invoice-Management/${record.id}`),
        // }),
        render: (jobName, record) => {
          return (
            <span
              className="col_styling table-font-mobile"
              style={{
                textTransform: "capitalize",
                width: "10px",
                color: "#212121",
              }}
            >
              {record?.ticketData?.jobName}
            </span>
          );
        },
      },
      {
        title: "CUSTOMER",
        dataIndex: "companyName",
        key: "companyName",
        sorter: false,
        // onCell: (record) => ({
        //   onClick: () =>
        //     (window.location = `/app/Invoice-Management/${record.id}`),
        // }),
        className: "col_styling",
        render: (companyName, record) => {
          return (
            <span
              className="col_styling table-font-mobile"
              style={{
                textTransform: "capitalize",
                color: "#212121",
              }}
            >
              {record?.ticketData?.companyName}
            </span>
          );
        },
      },
      {
        title: "START DATE",
        dataIndex: "",
        key: "",
        sorter: false,
        className: "col_styling",
        render: (record) => {
          return (
            <span
              className="col_styling table-font-mobile"
              style={{
                textTransform: "capitalize",
                color: "#212121",
              }}
            >
              {record?.ticketData?.approvedDate
                ? moment(record?.ticketData?.approvedDate).format("l")
                : ""}
            </span>
          );
        },
      },

      {
        title: "END DATE",
        dataIndex: "closedDate",
        key: "closedDate",
        sorter: false,
        className: "col_styling",
        render: (closedDate) => {
          return (
            <span
              className={`col_styling table-font-mobile`}
              style={{ textTransform: "capitalize", color: "#212121" }}
            >
              {closedDate}
            </span>
          );
        },
      },
      {
        title: "Duration",
        dataIndex: "",
        key: "",
        sorter: false,
        className: "col_styling",
        render: (record) => {
          const duration = this.calculateDuration(
            record?.ticketData?.approvedDate,
            record.closedDate
          );
          return (
            <span
              className="col_styling table-font-mobile"
              style={{
                textTransform: "capitalize",
                color: "#212121",
              }}
            >
              {duration !== "" ? `${duration} Days` : duration}
            </span>
          );
        },
      },

      {
        title: "REVENUE",
        dataIndex: "ticketsTotal",
        key: "ticketsTotal",
        sorter: (a, b) =>
          parseFloat(a.ticketsTotal) > parseFloat(b.ticketsTotal) ? 1 : -1,
        // onCell: (record) => ({
        //   onClick: () =>
        //     (window.location = `/app/Invoice-Management/${record.id}`),
        // }),
        className: "col_styling",
        render: (ticketsTotal, record) => {
          return (
            <span
              className="col_styling table-font-mobile"
              style={{
                color: "#212121",
              }}
            >
              {`$ ${parseFloat(ticketsTotal)?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}`}
            </span>
          );
        },
      },

      {
        title: "COST",
        dataIndex: "cost",
        key: "cost",
        sorter: (a, b) => (parseFloat(a.cost) > parseFloat(b.cost) ? 1 : -1),
        // onCell: (record) => ({
        //   onClick: () =>
        //     (window.location = `/app/Invoice-Management/${record.id}`),
        // }),
        className: "col_styling",
        render: (cost, record) => {
          return (
            <span
              className="col_styling table-font-mobile"
              style={{
                color: "#212121",
              }}
            >
              {`$ ${cost?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}`}
            </span>
          );
        },
      },
      {
        title: "PROFIT",
        dataIndex: "profit",
        key: "profit",
        sorter: (a, b) =>
          parseFloat(a.profit) > parseFloat(b.profit) ? 1 : -1,
        // onCell: (record) => ({
        //   onClick: () =>
        //     (window.location = `/app/Invoice-Management/${record.id}`),
        // }),
        className: "col_styling",
        render: (profit, record) => {
          return (
            <span
              className="col_styling table-font-mobile"
              style={{
                color: "#212121",
              }}
            >
              {`$ ${profit?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}`}
            </span>
          );
        },
      },
      {
        title: "PROFIT %",
        dataIndex: "profitPercentage",
        key: "profitPercentage",
        sorter: (a, b) =>
          parseFloat(a.profitPercentage) > parseFloat(b.profitPercentage)
            ? 1
            : -1,
        // onCell: (record) => ({
        //   onClick: () =>
        //     (window.location = `/app/Invoice-Management/${record.id}`),
        // }),
        className: "col_styling",
        render: (profitPercentage, record) => {
          return (
            <span
              className="col_styling table-font-mobile"
              style={{
                color: "#212121",
              }}
            >
              {`${parseFloat(profitPercentage)?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} %`}
            </span>
          );
        },
      },
    ];

    const searchFields = [
      "jobNumber",
      "jobName",
      "companyName",
      "ticketsTotal",
      "ticketData",
    ];

    return (
      <div>
        <Loading enableLoading={loading} />
        <Row
          style={{
            borderRadius: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "20px 0px",
            padding: "0px 20px",
            backgroundColor: "#ffffff",
            border: "1px solid gray",
            // textAlign: "left",
          }}
          className="project-analysis-layout"
          gutter={[12, 0]}
        >
          <Col style={{ margin: "12px 0px" }}>
            <p
              style={{
                fontSize: "18px",
                color: "#747474",
                marginBottom: "-3px",
                fontWeight: "600",
              }}
              className="owner-operator-card-head"
            >
              Job Count
            </p>
            <p
              style={{
                fontSize: "26px",
                fontWeight: "bold",
                marginBottom: "0px",
              }}
              className="owner-operator-card-body"
            >
              {reportData?.jobCount}
            </p>
          </Col>
          <Col style={{ margin: "12px 0px" }}>
            <p
              style={{
                fontSize: "18px",
                color: "#747474",
                marginBottom: "-3px",
                fontWeight: "600",
              }}
              className="owner-operator-card-head"
            >
              Total Revenue
            </p>
            <p
              style={{
                fontSize: "26px",
                fontWeight: "bold",
                marginBottom: "0px",
              }}
              className="owner-operator-card-body"
            >
              {`$` +
                reportData?.totalRevenue?.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
            </p>
          </Col>
          <Col style={{ margin: "12px 0px" }}>
            <p
              style={{
                fontSize: "18px",
                color: "#747474",
                marginBottom: "-3px",
                fontWeight: "600",
              }}
              className="owner-operator-card-head"
            >
              Total Cost
            </p>
            <p
              style={{
                fontSize: "26px",
                fontWeight: "bold",
                marginBottom: "0px",
              }}
              className="owner-operator-card-body"
            >
              {`$` +
                reportData?.totalCost?.toLocaleString("us-EN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
            </p>
          </Col>
          <Col style={{ margin: "12px 0px" }}>
            <p
              style={{
                fontSize: "18px",
                color: "#747474",
                marginBottom: "-3px",
                fontWeight: "600",
              }}
              className="owner-operator-card-head"
            >
              Total Profit
            </p>
            <p
              style={{
                fontSize: "26px",
                fontWeight: "bold",
                marginBottom: "0px",
              }}
              className="owner-operator-card-body"
            >
              {reportData?.totalProfit?.toString().includes("-")
                ? `-$${parseFloat(
                    reportData?.totalProfit?.toString()?.split("-")[1]
                  )?.toLocaleString("en-us", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`
                : `$${parseFloat(reportData?.totalProfit)?.toLocaleString(
                    "en-us",
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}`}
            </p>
          </Col>
          <Col style={{ margin: "12px 0px" }}>
            <p
              style={{
                fontSize: "18px",
                color: "#747474",
                marginBottom: "-3px",
                fontWeight: "600",
              }}
              className="owner-operator-card-head"
            >
              Profit Percentage %
            </p>
            <p
              style={{
                fontSize: "26px",
                fontWeight: "bold",
                marginBottom: "0px",
              }}
              className="owner-operator-card-body"
            >
              {!isNaN(reportData?.profitPercentage)
                ? reportData?.profitPercentage?.toFixed(2) + " %"
                : "0.00%"}
            </p>
          </Col>
        </Row>
        <Row>
          <Col
            xxl={{ span: 6 }}
            xl={{ span: 6 }}
            lg={{ span: 10 }}
            md={{ span: 10 }}
            sm={{ span: 16 }}
            xs={{ span: 16 }}
            className="quarrytoolgrid search-button-project"
            style={{ marginBottom: "20px" }}
          >
            <SearchFunction
              tableName={"companies"}
              getSearchData={this.searchResult}
              onResetData={this.onResetData}
              searchFields={searchFields}
            />
          </Col>
          <Col
            xxl={{ span: 18 }}
            xl={{ span: 18 }}
            lg={{ span: 14 }}
            md={{ span: 14 }}
            sm={{ span: 8 }}
            xs={{ span: 8 }}
            className="filter-button-project"
            // span={12}
          >
            <Row style={{ justifyContent: "right" }}>
              <Col className="gridbtntool">
                <Button
                  className="button_Crm"
                  style={{
                    borderRadius: "5px",
                    height: "40px",
                  }}
                  onClick={() => {
                    this.setState({ visible: true });
                  }}
                >
                  <Row>
                    <Col>
                      <span
                        style={{
                          color: "#5a6067",
                          fontWeight: "500",
                          fontSize: "16px",
                        }}
                      >
                        Filter
                      </span>
                    </Col>
                    <Col>
                      <IoFilterSharp
                        style={{
                          fontSize: "22px",
                          marginLeft: "8px",
                          marginTop: "2px",
                        }}
                      />
                    </Col>
                  </Row>
                </Button>
              </Col>
            </Row>
          </Col>
          {errorLoading ? (
            <ErrorView refreshUsers={this.onClickRefresh} type="Users" />
          ) : (
            <Col
              xxl={{ span: 24 }}
              xl={{ span: 24 }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Card bodyStyle={{ padding: "0px" }}>
                <BaseTable
                  className="revenuealy financeinvoice_table"
                  columnsData={columns}
                  source={filterData?.sort((a, b) =>
                    a.updatedAt < b.updatedAt ? 1 : -1
                  )}
                  handleTableChage={this.handleTableChage}
                  sizeRange={
                    window.screen.width > 1023
                      ? JSON.parse(localStorage.getItem("tableSizeRange"))
                          ?.projectAnalysis
                      : filterData?.length
                  }
                  pagination={window.screen.width > 1023 ? true : false}
                  // rowSelection={rowSelection}
                  total={filterData?.length}
                  // rowKey={(record) => record.id}
                />
              </Card>
            </Col>
          )}
        </Row>
        {visible && (
          <Filter
            visible={visible}
            closeFilter={this.closeFilter}
            filterResultData={this.filterResultData}
            onClickClearFilter={this.onClearFilter}
            checkData={checkData}
            revenueAnalysis
            company={customerData}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) =>
  // console.log(state),
  ({
    totalResult: state?.reducer?.invoicesResult,
    loading: state?.reducer?.invoicesResult?.loading,
    errorLoading: state?.reducer?.invoicesResult?.error,
  });

const mapDispatchToProps = (dispatch) => ({
  actionFetchGetInvoices: () => {
    dispatch(getInvoices());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAppContext(withRouter(RevenueAnalysis)));
