import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Input } from "antd";

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: "" };
  }

  handleChange = (address) => {
    // this.setState({ address });
    if (this.props.editMode) {
      this.props.setAddress(address);
    } else {
      this.setState({ address });
      if (address === "") {
        this.props.getAddress(address, {});
      }
    }
  };

  handleSelect = (address) => {
    geocodeByAddress(address).then((results) => {
      getLatLng(results[0])
        .then((latLng) => {
          if (this.props.editMode) {
            this.props.setAddress(address);
          } else {
            this.setState({ address });
          }
          this.props.getAddress(address, latLng);
          this.setState({
            address: results[0].formatted_address,
            latLng: latLng,
          });
        })
        .catch((error) => console.error("Error", error));
    });
  };

  handleClick = (address) => {
    console.log(address, "address");
  };

  render() {
    return (
      <div
        style={{
          width: "98%",
          position: "relative",
        }}
      >
        <PlacesAutocomplete
          value={
            this.props.editMode === true
              ? this.props.address
              : this.state.address
          }
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          onClick={this.handleClick}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <Input
                {...getInputProps({
                  placeholder: "Search Places ...",
                  className: "location-search-input form-input",
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.length > 0 && (
                  <div style={{ borderRadius: 5, border: "1px solid #d9d9d9" }}>
                    {suggestions.map((suggestion, index) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? {
                            backgroundColor: "#fafafa",
                            cursor: "pointer",
                            padding: "5px",
                          }
                        : {
                            backgroundColor: "#FFF",
                            border: "none",
                            cursor: "pointer",
                            padding: "5px",
                          };
                      return (
                        <div
                          key={index}
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}

                          // className='add-lead border-form-item'
                        >
                          {suggestion.description?.indexOf(", USA") > -1 ? (
                            <span>{suggestion.description}</span>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
    );
  }
}
export default LocationSearchInput;
