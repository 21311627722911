import { Button } from "antd";
import { Link } from "react-router-dom";
// import NotFoundImage from "../Images/NotFoundImage.png";
import Lottie from "lottie-react";
import missingfileloading from "../Json/missingfileloading.json";

const NotFoundView = () => {
  document.title = "Twisted Nail - Error 404";
  return (
    <div
      style={{
        minHeight: "75vh",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: " #fafafa",
      }}
    >
      <Lottie
        animationData={missingfileloading}
        style={{ height: "400px", mixBlendMode: "multiply" }}
      />

      {/* <img
          src={NotFoundImage}
          alt="notfound"
          style={{ height: "400px", mixBlendMode: "multiply" }}
        /> */}
      <h1
        style={{
          // fontFamily: "Poppins",
          fontSize: "30px",
          marginTop: "-30px"
        }}
      >
        Oh, no! This page does not exist.
      </h1>
      <Link to="/app/dashboard">
        <Button
          type="primary"
          style={{
            borderRadius: "6px",
            height: "40px",
            fontSize: "16px",
            width: "175px",
            marginTop: "25px"
          }}
        >
          Go To DashBoard
        </Button>
      </Link>
    </div>
  );
};

export default NotFoundView;
