import React, { Component } from "react";
import { list, update } from "../api/Apis";
import appConfig from "../config/AppConfig";
export const AppContext = React.createContext("app");

const initialValue = {
  quickLeadData: [],
  dropDownId: "1",
  userLogin: false,
  loading: false,
  getLeadsData: [],
  errorLoading: false,
  systemUsersData: [],
  isDarkMode: false,
  companyId: "",
  leadId: "",
  quarryId: "",
  materialList: [],
  filterInfo: true,
  userRoleAccess: "",
  carrierId: "",
  invoiceId: "",
  collapsed: false,
  trucksData: [],
  filteredTruckTypes: [],
  rolesData: [],
  roleId: "",
  ownerRoles: [],
  masterRoleId: "",
  masterTruckId: "",
  salesPersonId: "",
  salesPersonsData: [],
  commisionsData: [],
  getComapaniesData: []
};

export class AppProvider extends Component {
  state = initialValue;

  getMaterialsList = () => {
    list(appConfig["entityNames"]["getQuarries"]).then((result) => {
      if (result.success) {
        let data = result.data.map((q) => q.primary_sic_cd);
        let filteredData = data.filter((q, idx) => data.indexOf(q) === idx);
        this.setState({
          materialList: filteredData.filter((e) => e !== "" && e !== undefined),
        });
      }
    });
  };

  changeDropDownId = (dropDownId) => {
    this.setState({ dropDownId, filterInfo: true });
  };

  updateFilterInfo = () => {
    this.setState({
      filterInfo: false,
    });
  };

  handleFilterInfo = () => {
    this.setState({
      filterInfo: true,
    });
  };

  updateSideronCollapse = (e) => {
    this.setState({ collapsed: e });
  };

  updateQuickLeadsData = (data) => {
    this.setState({ quickLeadData: data });
  };

  updateSalesId = (id) => {
    this.setState({ salesPersonId: id });
  };

  getUserAccessInfo = (userId) => {
    list(appConfig["entityNames"]["roles"], { id: userId }).then((roleRes) => {
      if (roleRes.success) {
        this.setState({
          userRoleAccess: roleRes.data[0],
        });
      }
    });
  };

  setUserLogin = (value) => {
    this.setState({ userLogin: value });
  };

  setLoading = (value) => {
    this.setState({
      loading: value,
    });
  };

  getUsersData = () => {
    list(appConfig["entityNames"]["users"]).then((result) => {
      if (result.success) {
        this.setState({
          systemUsersData: result.data,
        });
      }
    });
  };

  getLeadDetails = () => {
    this.setState({ getLeadsData: [] });
    list(appConfig["entityNames"]["getLeads"])
      .then((result) => {
        if (result.success) {
          this.setState({
            getLeadsData: result.data.data.filter((f) => !Array.isArray(f)),
            loading: false,
            errorLoading: false,
          });
        } else {
          this.setState({
            loading: false,
            errorLoading: true,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        this.setState({
          loading: false,
          errorLoading: true,
        });
      });
  };

  toggleTheme = () => {
    this.setState((prevState) => ({ isDarkMode: !prevState.isDarkMode }));
  };

  updateCompanyId = (id) => {
    this.setState({ companyId: id });
  };

  updateLeadId = (id) => {
    this.setState({ leadId: id });
  };

  updateQuarryId = (id) => {
    this.setState({ quarryId: id });
  };

  updateCarrierId = (id) => {
    this.setState({ carrierId: id });
  };

  updateInvoiceId = (id) => {
    this.setState({ invoiceId: id });
  };

  getTrucksType = () => {
    // console.log("object");
    list("dropDownOptions").then((result) => {
      if (result.success) {
        let trucksData = [];
        let rolesData = [];
        let roleId = "";
        let ownerRoles = [];
        let masterRoleId = "";
        let masterTruckId = "";
        let material = [];
        let quarryRoleId = "";
        let commisionsId = "";
        let quarryRoles = [];
        let commisionsData = [];
        result.data.map((t) => {
          if (t.typeofDropDown === "trucksType") {
            trucksData = t.trucksData;
            masterTruckId = t.id;
          } else if (t.typeofDropDown === "roles") {
            rolesData = t.rolesData.sort((a, b) =>
              a.value > b.value ? 1 : -1
            );
            roleId = t.id;
          } else if (t.typeofDropDown === "ownerOperatorRoles") {
            ownerRoles = t.ownerRolesData.sort((a, b) =>
              a.value > b.value ? 1 : -1
            );
            masterRoleId = t.id;
          } else if (t.typeofDropDown === "material") {
            material = t.materialsNewList;
          } else if (t.typeofDropDown === "quarryRoles") {
            quarryRoles = t.quarryRolesData.sort((a, b) =>
              a.value > b.value ? 1 : -1
            );
            quarryRoleId = t.id;
          } else if (t.typeofDropDown === "commisions") {
            commisionsData = t.commisionsData;
            commisionsId = t.id;
          }
          return true;
        });
        this.setState({
          trucksData: trucksData?.filter((t) => t !== null),
          filteredTruckTypes: trucksData?.filter((t) => t !== null),
          rolesData: rolesData?.filter((r) => r !== null),
          roleId,
          ownerRoles: ownerRoles?.filter((r) => r !== null),
          masterRoleId,
          masterTruckId,
          material: material?.filter((m) => m !== null),
          quarryRoleId,
          quarryRoles,
          commisionsData,
          commisionsId,
        });
      }
    });
  };

  updateQuarryRolesData = (values) => {
    let quarryRolesData = this.state.quarryRoles;
    quarryRolesData.push({
      label: values,
      value: values.charAt(0).toUpperCase(),
      id: quarryRolesData?.length + 1,
    });
    let data = {
      entityData: {
        quarryRolesData,
        typeofDropDown: "quarryRoles",
      },
    };
    update("dropDownOptions", this.state.quarryRoleId, data).then((result) => {
      if (result.success) {
        this.getTrucksType();
      }
    });
  };

  updateOwnerRolesData = (values) => {
    let ownerRolesData = this.state.ownerRoles;
    ownerRolesData.push({
      label: values,
      value: values.charAt(0).toUpperCase(),
      id: ownerRolesData?.length + 1,
    });
    let data = {
      entityData: {
        ownerRolesData,
        typeofDropDown: "ownerOperatorRoles",
      },
    };
    update("dropDownOptions", this.state.masterRoleId, data).then((result) => {
      if (result.success) {
        this.getTrucksType();
      }
    });
  };

  updateRolesData = (values, type) => {
    let rolesData = this.state.rolesData;
    rolesData.push({
      label: values,
      value: values.charAt(0).toUpperCase(),
      id: rolesData?.length + 1,
    });
    let data = {
      entityData: {
        rolesData,
        typeofDropDown: type,
      },
    };
    try {
      update("dropDownOptions", this.state.roleId, data).then((result) => {
        if (result.success) {
          this.getTrucksType();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  updateTrucksType = (values, type) => {
    let trucksData = this.state.trucksData;
    trucksData.push({ value: values });
    let data = {
      entityData: {
        trucksData,
        typeofDropDown: type,
      },
    };

    update("dropDownOptions", this.state.masterTruckId, data).then((result) => {
      if (result.success) {
        this.getTrucksType();
      }
    });
  };

  getSalesPersons = () => {
    list("salesPerson")
      .then((result) => {
        // console.log(result, "ddd");
        if (result.success) {
          this.setState({ salesPersonsData: result.data });
        }
      })
      .catch((err) => console.log(err, "error"));
  };

  getCompanies = () => {
    list("companies")
      .then((result) => {
        if (result.success) {
          this.setState({ getComapaniesData: result.data.filter((c) => !c.isOwnerOperator) });
        }
      })
      .catch((err) => console.log(err, "error"));
  };

  render() {
    const {
      quickLeadData,
      dropDownId,
      // listViewValue,
      userLogin,
      loading,
      getLeadsData,
      errorLoading,
      systemUsersData,
      isDarkMode,
      companyId,
      leadId,
      quarryId,
      materialList,
      userRoleAccess,
      filterInfo,
      carrierId,
      collapsed,
      trucksData,
      filteredTruckTypes,
      rolesData,
      roleId,
      ownerRoles,
      masterRoleId,
      quarryRoleId,
      quarryRoles,
      commisionsData,
      material,
      invoiceId,
      salesPersonId,
      salesPersonsData,
      getComapaniesData
    } = this.state;

    return (
      <AppContext.Provider
        value={{
          quickLeadData,
          dropDownId,
          userLogin,
          loading,
          getLeadsData,
          errorLoading,
          systemUsersData,
          isDarkMode,
          companyId,
          leadId,
          quarryId,
          materialList,
          userRoleAccess,
          filterInfo,
          carrierId,
          collapsed,
          trucksData,
          filteredTruckTypes,
          rolesData,
          roleId,
          ownerRoles,
          masterRoleId,
          quarryRoleId,
          quarryRoles,
          commisionsData,
          material,
          invoiceId,
          salesPersonId,
          salesPersonsData,
          getComapaniesData,
          changeDropDownId: this.changeDropDownId,
          updateFilterInfo: this.updateFilterInfo,
          handleFilterInfo: this.handleFilterInfo,
          updateQuickLeadsData: this.updateQuickLeadsData,
          updateSideronCollapse: this.updateSideronCollapse,
          setUserLogin: this.setUserLogin,
          updateUserSettings: this.updateUserSettings,
          setLoading: this.setLoading,
          allLeadsData: this.getLeadDetails,
          getUsersData: this.getUsersData,
          toggleTheme: this.toggleTheme,
          updateCompanyId: this.updateCompanyId,
          updateLeadId: this.updateLeadId,
          updateQuarryId: this.updateQuarryId,
          getMaterialsList: this.getMaterialsList,
          getUserAccessInfo: this.getUserAccessInfo,
          updateCarrierId: this.updateCarrierId,
          updateInvoiceId: this.updateInvoiceId,
          getTrucksType: this.getTrucksType,
          updateRolesData: this.updateRolesData,
          updateOwnerRolesData: this.updateOwnerRolesData,
          updateQuarryRolesData: this.updateQuarryRolesData,
          updateTrucksType: this.updateTrucksType,
          updateSalesId: this.updateSalesId,
          getSalesPersons: this.getSalesPersons,
          getCompanyData: this.getCompanies,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export function withAppContext(GivenComponent) {
  return function wrapperComponent(props) {
    return (
      <AppContext.Consumer>
        {(state) => <GivenComponent {...props} context={state} />}
      </AppContext.Consumer>
    );
  };
}
