import { Input } from "antd";
import React, { useState } from "react";
// import { add } from "../api/Apis";
// import appConfig from "../config/AppConfig";
const Search = Input.Search;

const SearchFunction = (props) => {
  const [searchElement, setsearchElement] = useState("");

  const searchData = (event) => {
    let searchValue = "";
    if (event.target.value === "") {
      props.onResetData();
    }
    if (Number(event.target.value) && !props.company) {
      searchValue = parseInt(event.target.value);
    } else {
      searchValue = event.target.value.trim().toLowerCase();
    }
    setsearchElement(searchValue);
    props.getSearchData(searchValue);
    // let some = props.searchFields.map(
    //   (e) =>
    //     props.searchData?.filter((item) => item[e] === searchValue && item)
    //       .length > 0
    // );
    // let data = {
    //   entityData: {
    //     tableName: props?.tableName,
    //     searchValue,
    //     searchFields: props?.searchFields,
    //   },
    // };
    // add(appConfig["entityNames"]["search"], data).then((result) => {
    //   props.getSearchData(result.data);
    // });
  };

  // const handleChange = (event) => {
  //   if (event.target.value === "") {
  //     props.onResetData();
  //   }
  //   setsearchElement(event.target.value);
  // };
  return (
    <div style={{ borderRadius: "5px" }}>
      <Search
        className="products-search"
        size="large"
        placeholder="Search"
        style={{ borderRadius: "5px" }}
        enterButton
        allowClear
        // onSearch={(event) => searchData(event, "text")}
        onChange={(e) => searchData(e)}
        value={searchElement}
      />
    </div>
  );
};

export default SearchFunction;
