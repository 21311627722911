import { useState } from "react";
import { Layout, Menu, Drawer, Button, Row, Col } from "antd";
import { Route, Switch, Link, withRouter } from "react-router-dom";
import AppRoutes from "../config/AppRoutes";
import MainHeader from "../Common/MainHeader";
import { FaCogs, FaTruckMoving } from "react-icons/fa";
import { RiDashboardFill, RiSettings5Fill } from "react-icons/ri";
// import { SettingOutlined } from "@ant-design/icons";
import { HiUserGroup } from "react-icons/hi";
// import { GoGraph } from "react-icons/go";
// import MainLogo from "../Images/MainLogo.png";
// import TwistedNail from "../Images/TwistedNail.png";
// import Twisted from "../Images/Twisted.png";
import PrimaryLogo from "../Images/PrimaryLogo.png";
import MainLogoIcon from "../Images/MainLogoIcon.png";
// import operationsIcon from "../Images/operations.png";
import { withAppContext } from "../Store/AppContext";
// import Operatorsvg from "./operatorsvg";
import { HiOutlineCurrencyDollar } from "react-icons/hi2";

const { Content, Header, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const Application = (props) => {
  const [openKeys, setopenKeys] = useState("");
  const [open, setOpen] = useState(false);
  const [iconType, setIconType] = useState("");
  const [active, setActive] = useState(null);

  const [homeScreenIconType, setHomeScreenIconType] = useState(0);

  const onClose = () => {
    setOpen(false);
  };

  const showOpen = (iconType) => {
    setOpen(true);
    setIconType(iconType);
    // setTimeout(() => {
    //   setOpen(false);
    // }, 3000);
  };
  // const handleChange = () => {
  //   setActive1(true);
  // };

  // const handleReset = () => {
  //   setActive1(false);
  // };

  const onOpenChange = (keys) => {
    const rootSubmenuKeys = ["1", "2", "3", "4", "5", "6"];
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setopenKeys(keys);
    } else {
      setopenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const { userRoleAccess, collapsed, updateSideronCollapse } = props.context;
  const roleData = userRoleAccess?.otherData;
  return (
    <Layout>
      <Layout hasSider className="fullsitelayout">
        <Sider
          collapsed={collapsed}
          onCollapse={(e) => updateSideronCollapse(e)}
          width={258}
          className="side-menu show-on-desktop"
          collapsible
          breakpoint="xxl"
          onBreakpoint={(broken) => {}}
        >
          <Menu
            onClick={() => {
              localStorage.removeItem("filterStorageData", JSON.stringify({}));
              window.screen.width > 1600 &&
                props.context.updateSideronCollapse(false);
            }}
            // inlineCollapsed={collapsed}
            onOpenChange={onOpenChange}
            openKeys={openKeys}
            mode="inline"
            selectedKeys={
              window.location.pathname === "/app/dashboard"
                ? "1"
                : window.location.pathname === "/app/trucking-material"
                ? "21"
                : window.location.pathname === "/app/owner-operator"
                ? "22"
                : window.location.pathname === "/app/customer-database"
                ? "31"
                : window.location.pathname === "/app/twisted-nail"
                ? "32"
                : window.location.pathname === "/app/truck-driver-database"
                ? "33"
                : window.location.pathname === "/app/quarry-finder-tool"
                ? "34"
                : window.location.pathname === "/app/material-database"
                ? "35"
                : window.location.pathname === "/app/job-database"
                ? "36"
                : window.location.pathname === "/app/dispatch-jobs"
                ? "41"
                : window.location.pathname === "/app/dispatch-tool"
                ? "42"
                : window.location.pathname === "/app/dispatch-archive"
                ? "43"
                : // : window.location.pathname === "/app/dispatch-Quote"
                // ? "44"
                window.location.pathname === "/app/ticket-upload"
                ? "51"
                : window.location.pathname === "/app/ticket-processing"
                ? "52"
                : window.location.pathname === "/app/invoiceManagement"
                ? "53"
                : window.location.pathname === "/app/billManagement"
                ? "54"
                : window.location.pathname === "/app/accountsReceivable"
                ? "55"
                : window.location.pathname === "/app/accountsPayable"
                ? "56"
                : window.location.pathname === "/app/projectAnalysis"
                ? "57"
                : window.location.pathname === "/app/manage-roles"
                ? "61"
                : window.location.pathname === "/app/system-users"
                ? "62"
                : window.location.pathname === "/app/salesPerson"
                ? "63"
                : window.location.pathname === "/app/system-settings"
                ? "64"
                : window.location.pathname === "/app/restoreData"
                ? "65"
                : window.location.pathname === "/app/imageDatabase"
                ? "66"
                : window.location.pathname === "/app/manager-review"
                ? "67"
                : window.location.pathname === "/app/changes-in-dispatch"
                ? "45"
                : ["sub1"]
            }
            defaultOpenKeys={["1"]}
          >
            <Link to="/app/dashboard">
              <div
                style={{
                  background: "#FFF",
                  maxWidth: "max-content",
                  margin: "0px auto",
                }}
                className="max_sidebarlogo"
              >
                <img
                  src={
                    PrimaryLogo
                    // Twisted
                    // TwistedNail
                  }
                  alt="logo"
                  className="logo-header"
                />
              </div>
              <div
                style={{
                  background: "#FFF",
                  maxWidth: "max-content",
                  margin: "0px auto",
                }}
                className="min_sidebarlogo"
              >
                <img src={MainLogoIcon} alt="logo" className="logo-header" />
              </div>
            </Link>

            {roleData?.map((role) =>
              role.key === "1" && role.selectedOptions.length > 0 ? (
                <Menu.Item
                  key="1"
                  icon={
                    <RiDashboardFill
                      style={{ fontSize: "25px" }}
                      className="menuitem"
                    />
                  }
                >
                  <Link to="/app/dashboard" title="Dashboard">
                    <span className="menuitem">Dashboard</span>
                  </Link>
                </Menu.Item>
              ) : (
                role.key !== "1" &&
                role.mainPannelSelected === true && (
                  <SubMenu
                    key={role.key}
                    icon={
                      role.key === "2" ? (
                        <HiUserGroup style={{ fontSize: "25px" }} />
                      ) : role.key === "3" ? (
                        <FaTruckMoving style={{ fontSize: "25px" }} />
                      ) : role.key === "4" ? (
                        // <img
                        //   src={operationsIcon}
                        //   alt="operations"
                        //   style={{ width: "25px", height: "auto" }}
                        // />

                        <FaCogs style={{ fontSize: "25px" }} />
                      ) : role.key === "5" ? (
                        // <GoGraph style={{ fontSize: "25px" }} />
                        <HiOutlineCurrencyDollar style={{ fontSize: "25px" }} />
                      ) : (
                        <RiSettings5Fill style={{ fontSize: "25px" }} />
                      )
                    }
                    title={role.title}
                    className="menuitem"
                  >
                    {role.multiPannel
                      ? role.multiPannel.map(
                          (r) =>
                            r.selectedOptions.length > 0 && (
                              <Menu.Item key={r.key}>
                                <Link to={r.path} title={r.title}>
                                  <span className="menuitem_lin">
                                    {r.title}
                                  </span>
                                </Link>
                              </Menu.Item>
                            )
                        )
                      : ""}
                  </SubMenu>
                )
              )
            )}
          </Menu>
        </Sider>
        <Layout
          style={{
            minHeight: "100vh",
            background: "#fafafa",
          }}
          className="sitecontentlayout"
        >
          <Header style={{ padding: 0, height: "auto" }}>
            <MainHeader />
          </Header>

          <Content className="mainlayout">
            <div style={{ minHeight: 360 }}>
              <Layout>
                <Switch>
                  {AppRoutes.map((route) => (
                    <Route
                      exact={route.exact}
                      path={route.path}
                      component={route.component}
                      key={route.key}
                    />
                  ))}
                </Switch>
              </Layout>
            </div>
          </Content>
          <Footer
            style={{ textAlign: "center", padding: "13px 50px" }}
            className="show-on-desktop"
          >
            Twisted Nail {new Date().getFullYear()}
          </Footer>
          <div className="mobile_view_footer show-on-mobile">
            {roleData?.map((role, i) =>
              role.key === "1" && role.selectedOptions.length > 0 ? (
                <div
                  key={i}
                  style={{
                    height: "45px",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor:
                      homeScreenIconType === 0 ? "#FFF" : "transparent",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setHomeScreenIconType(0);
                  }}
                >
                  <Link
                    to="/app/dashboard"
                    title="Dashboard"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <RiDashboardFill
                      style={{
                        minWidth: "15vw",
                        fontSize: "28px",
                        color: homeScreenIconType === 0 ? "grey" : "#FFF",
                      }}
                      className="menuitem"
                    />
                  </Link>
                </div>
              ) : (
                role.key !== "1" &&
                role.mainPannelSelected === true && (
                  <div
                    key={i}
                    style={{
                      height: "45px",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor:
                        homeScreenIconType === parseInt(role?.key)
                          ? "#FFF"
                          : "transparent",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setHomeScreenIconType(parseInt(role?.key));
                      showOpen(role?.title);
                    }}
                  >
                    {role?.title === "CRM" ? (
                      <HiUserGroup
                        style={{
                          minWidth: "15vw",
                          fontSize: "28px",
                          color:
                            homeScreenIconType === parseInt(role?.key)
                              ? "grey"
                              : "#FFF",
                        }}
                        className="menuitem"
                      />
                    ) : role?.title === "Databases" ? (
                      <FaTruckMoving
                        style={{
                          minWidth: "15vw",
                          fontSize: "28px",
                          color:
                            homeScreenIconType === parseInt(role?.key)
                              ? "grey"
                              : "#FFF",
                        }}
                        className="menuitem"
                      />
                    ) : role?.title === "Operations" ? (
                      <FaCogs
                        style={{
                          minWidth: "15vw",
                          fontSize: "28px",
                          color:
                            homeScreenIconType === parseInt(role?.key)
                              ? "grey"
                              : "#FFF",
                        }}
                        className="menuitem"
                      />
                    ) : role?.title === "Finance" ? (
                      <HiOutlineCurrencyDollar
                        style={{
                          minWidth: "15vw",
                          fontSize: "28px",
                          color:
                            homeScreenIconType === parseInt(role?.key)
                              ? "grey"
                              : "#FFF",
                        }}
                        className="menuitem"
                      />
                    ) : (
                      role?.title === "Settings" && (
                        <RiSettings5Fill
                          style={{
                            minWidth: "15vw",
                            fontSize: "28px",
                            color:
                              homeScreenIconType === parseInt(role?.key)
                                ? "grey"
                                : "#FFF",
                          }}
                          className="menuitem"
                        />
                      )
                    )}
                  </div>
                )
              )
            )}
          </div>
        </Layout>
      </Layout>

      {open && (
        <Drawer
          title={iconType}
          onClose={onClose}
          placement={"bottom"}
          visible={open}
          maskClosable={false}
          height={"100px"}
          headerStyle={{ padding: "6px 12px" }}
          bodyStyle={{ padding: "12px" }}
          scrollBottom={false}
          scroll={{ x: "290px", y: "0px" }}
        >
          {roleData?.map(
            (role) =>
              role?.title === iconType && (
                <Row
                  style={{
                    flexWrap: "nowrap",
                    alignItems: "center",
                  }}
                >
                  {role.multiPannel ? (
                    role.multiPannel.map(
                      (r) =>
                        r.selectedOptions.length > 0 && (
                          <Col
                            className="sub-buttons"
                            style={{ textAlign: "center", width: "100%" }}
                          >
                            <Link to={r.path} title={r.title}>
                              <Button
                                onClick={() => {
                                  setActive(parseInt(r?.key));
                                  setOpen(false);
                                }}
                                style={{
                                  color: "white",
                                  width: "100%",
                                  backgroundColor:
                                    active === parseInt(r?.key)
                                      ? "#586370"
                                      : "white",
                                }}
                              >
                                <span
                                  className="menuitem_lin"
                                  style={{
                                    fontSize: "14px",
                                    color:
                                      active === parseInt(r?.key)
                                        ? "white"
                                        : "#586370",
                                  }}
                                >
                                  {r?.title}
                                </span>
                              </Button>
                            </Link>
                          </Col>
                        )
                    )
                  ) : (
                    <></>
                  )}
                </Row>
              )
          )}
        </Drawer>
      )}
    </Layout>
  );
};
export default withAppContext(withRouter(Application));
