import { Card, Col, Divider, Image, Rate, Row } from "antd";
import Title from "antd/lib/typography/Title";
import {
  BsArrowLeftShort,
  BsFacebook,
  BsFillPencilFill,
  BsFillShareFill,
  BsInstagram,
  BsLinkedin,
  BsTwitter,
} from "react-icons/bs";
import { Link } from "react-router-dom";

const MyProfile = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  console.log(userDetails, "userDetails");
  return (
    <div
      style={{ backgroundColor: "rgba(250,250,250,1)" }}
      className="myprofile"
    >
      <Link
        to="/app/Dashboard"
        style={{ display: "flex", maxWidth: "max-content" }}
      >
        <Row>
          <Col
            style={{ color: "#808080", fontSize: "22px", lineHeight: "0px" }}
          >
            <BsArrowLeftShort className="back-button-return-icon" />
          </Col>
          <Col>
            <p
              style={{ color: "#808080", fontSize: "14px", margin: "0px" }}
              className="back-button-return"
            >
              Return
            </p>
          </Col>
        </Row>
      </Link>

      <div style={{ padding: "10px", margin: "0px auto", maxWidth: "1400px" }}>
        <Row gutter={[20, 0]}>
          <Col span={6}>
            <Card>
              <Row>
                <Col span={24}>
                  <div className="iconprifleview">
                    <Link to="/app/profilesetting">
                      <BsFillPencilFill />
                    </Link>
                    <span>
                      <BsFillShareFill />
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <Image
                    src="https://homeslistedby-dev-attachments.s3.amazonaws.com/public/rc-upload-1640607694358-3"
                    style={{
                      maxWidth: "50%",
                      height: "auto",
                      borderRadius: "100%",
                    }}
                  />
                  <Rate
                    allowHalf
                    defaultValue={2.5}
                    style={{ margin: "10px 0px" }}
                    count={5}
                    value={0}
                  />
                </Col>
                <Col span={24} style={{ textAlign: "center" }}>
                  <Title level={2} style={{ margin: "0px" }}>
                    {userDetails.firstName}
                  </Title>
                  <Title level={5} style={{ margin: "0px" }}>
                    {userDetails.lastName}
                  </Title>
                </Col>
                <Col span={24}>
                  <div className="stauscontent">
                    <p>Email:</p>
                    <span>{userDetails.email}</span>
                  </div>
                  <div className="stauscontent">
                    <p>Phone :</p>
                    <span>{userDetails.phone}</span>
                  </div>
                  <div className="stauscontent">
                    <p>Website :</p>
                    <span>{userDetails.website}</span>
                  </div>
                  <div className="stauscontent">
                    <p>Company Address :</p>
                    <span>{userDetails.companyaddress}</span>
                  </div>
                  <div className="stauscontent">
                    <p>I am a local expert in these towns:</p>
                    <span>{userDetails.address}</span>
                  </div>
                  <div className="stauscontent">
                    <p>Languages spoken :</p>
                    <span>{userDetails.language}</span>
                  </div>
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col span={24}>
                  <div className="socialmedia">
                    <Link to={"#"}>
                      <BsFacebook />
                    </Link>
                    <Link to={"#"}>
                      <BsTwitter />
                    </Link>
                    <Link to={"#"}>
                      <BsLinkedin />
                    </Link>
                    <Link to={"#"}>
                      <BsInstagram />
                    </Link>
                  </div>
                </Col>
              </Row>
            </Card>
            <Card style={{ margin: "20px 0px" }}>
              <Title level={3} style={{ margin: "0px" }}>
                Bio
              </Title>
              <p style={{ fontSize: "0.8vw" }}>
                <b>Agents nationwide</b> have spent millions of dollars on
                advertising with the big home search giants only to be placed in
                front of consumers who don’t know who they are and most of the
                time had no interest speaking with them.{" "}
                <b>It’s time for change</b> and{" "}
                <b>
                  <Link to="#">HomesListedBy.com</Link>
                </b>{" "}
                is the vehicle to drive this change to a brighter future for
                real estate.
              </p>
            </Card>
          </Col>
          <Col span={18}>
            <Card></Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MyProfile;
