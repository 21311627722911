import {
  Table,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Popconfirm,
  AutoComplete,
  Popover,
  Row,
  Select,
  Tabs,
  Radio,
  InputNumber,
  message,
  Checkbox,
  Upload,
  Spin,
  Tooltip,
} from "antd";
import React from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import {
  PlusOutlined,
  EditOutlined,
  EllipsisOutlined,
  CameraOutlined,
  ContactsOutlined,
  InboxOutlined,
  PaperClipOutlined,
  ArrowDownOutlined,
  CloseOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { IoFilterSharp } from "react-icons/io5";
import { SlDoc } from "react-icons/sl";
import Filter from "../../Common/Filter";
import { update, add, deleteAll } from "../../api/Apis";
import BaseTable from "../../Common/BaseTable";
import ErrorView from "../../Common/ErrorView";
import Loading from "../../Common/Loading";
import appConfig from "../../config/AppConfig";
import { withAppContext } from "../../Store/AppContext";
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import NumberFormat from "react-number-format";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import TextArea from "antd/lib/input/TextArea";
import BaseModal from "../../Common/BaseModal";
import moment from "moment";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { v4 as uuidv4 } from "uuid";
import AOS from "aos";
import "aos/dist/aos.css";
import { Storage } from "aws-amplify";
import MapComponent from "../../Components/Maps/MapComponent";
import { HiOutlineDocumentArrowDown } from "react-icons/hi2";
// import Mapnotfound from "../Common/mapnotfountview";
import Dragger from "antd/lib/upload/Dragger";
import { connect } from "react-redux";
import {
  deleteDocument,
  getDropDownOptions,
  getOwnerOperators,
  getTruckList,
  getTrucksList,
  updateDropDownOptions,
  updateTrucksList,
  deleteTrucksList,
  getTickets,
} from "../../Redux/Actions/actions";

const { TabPane } = Tabs;

const { Option } = Select;

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
class TwistedNailOwnerOperatorProfile extends React.Component {
  formRef = React.createRef();
  inputRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      isCompanyModalVisible: false,
      selectedAddress: "",
      latitude: "",
      longitude: "",
      keyValue: "1",
      addTruck: false,
      recordTruckData: "",
      equipmentTypeDetails: [],
      isContactModalVisible: false,
      jobContactData: [],
      selectedPaymentType: "",
      selectedRowKeys: [],
      isDocModalVisible: false,
      imageLoading: false,
      quoteAttachment: "",
      quoteAttachmentId: "",
      overWeightPermitState: "",
      notesData: [],
      notesPopup: false,
      recordNotesData: "",
      visible: false,
      invoicesData: [],
      filteredInvoicesData: [],
      checkData: "",
      firstName: "",
      lastName: "",
      fileType: "",
      isCheckcontact: false,
      carrierName: "",
      recordDocData: "",
      isDetailModal: false,
      docData: [],
      docsData: [],
      ownerRoles: [],
      trucksData: [],
      paymentTerms: [],
      payment_discount: "",
      totalTrucksData: [],
      filterTrucksData: [],
      secondTruckNo: [],
      input: "",
      ticketResult: [],
      isContactVisible: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    const {
      match,
      actionFetchGetTickets,
      actionFetchOwnerOperators,
      actiongetTNBSOwner,
      actiongetDropDownOptions,
      actionFetchTrucksList,
    } = this.props;
    const { params } = match;
    const { id } = params;
    actionFetchGetTickets();
    this.props?.context?.updateCarrierId(id);
    actiongetTNBSOwner(id);
    actiongetDropDownOptions();
    actionFetchTrucksList();
    actionFetchOwnerOperators();
    document.title = "Twisted Nail - Carrier Profile";
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
  }

  componentDidUpdate(prevProps) {
    const { ticketResult } = this.props;
    if (prevProps.ticketResult.success === false && ticketResult.success) {
      const ticketResultInfo = ticketResult?.ticketsData?.filter(
        (e) => e.uploadStatus === "Closed" && e.ticketVerification !== "Open"
      );
      this.setState({
        ticketResult: ticketResultInfo,
      });
    }
    if (
      prevProps?.totalResult?.success === false &&
      this.props?.totalResult?.success === true
    ) {
      // console.log("total");
      let selectedRows =
        this.props?.totalResult?.truckListData?.contactDetails?.filter(
          (s) => s?.isPrimaryContact === true
        );
      let companyData = this.props?.totalResult?.truckListData;
      let removeDuplicateTrucks =
        this.props?.totalResult?.truckListData?.carrierDetails?.filter(
          (value, index, self) =>
            self.findIndex((e) => e.id === value.id) === index
        );
      companyData["carrierDetails"] = removeDuplicateTrucks;
      this.setState({
        companyData,
        selectedAddress: this.props?.totalResult?.truckListData.address,
        equipmentTypeDetails: removeDuplicateTrucks,
        contactsData:
          this.props?.totalResult?.truckListData?.contactDetails !== undefined
            ? this.props?.totalResult?.truckListData?.contactDetails
            : [],
        selectedRowKeys: selectedRows && [selectedRows[0]?.id],
        invoicesData: this.props?.totalResult?.truckListData?.invoiceDetails,
        filteredInvoicesData:
          this.props?.totalResult?.truckListData?.invoiceDetails,
        docsData: this.props?.totalResult?.truckListData?.documentsDetails,
        notesData: this.props?.totalResult?.truckListData?.notesDetails,
      });
    }
    if (
      prevProps?.trucksListInfo?.success === false &&
      this.props.trucksListInfo?.success === true
    ) {
      this.setState({
        selectedTruckCode: "",
        latitude: "",
        longitude: "",
        selectedAddress: "",
        selectedPaymentType: "",
        quoteAttachment: "",
        quoteAttachmentId: "",
        overWeightPermitState: "",
        newRole: "",
        isCheckcontact: false,
        carrierName: "",
      });
      this.props.actiongetTNBSOwner(this.props.match.params.id);
      this?.props?.actionFetchOwnerOperators();
      this.props.actionFetchTrucksList();
    }

    if (
      prevProps?.trucksInfo?.success === false &&
      this.props.trucksInfo?.success === true
    ) {
      this.props.history.push("/app/twisted-nail");
    }

    if (
      prevProps?.totalTrucksListResult?.success === false &&
      this.props?.totalTrucksListResult?.success === true
    ) {
      let data = [];
      this.props?.totalTrucksListResult?.trucksListData?.forEach((e) => {
        if (e?.carrierDetails?.length > 0) {
          e?.carrierDetails?.forEach((c) => {
            if (c?.truckId !== null && c?.truckId !== undefined) {
              data.push(parseInt(c?.truckId));
            }
          });
        }
      });
      let res = data?.length > 0 ? data.flat() : [100];
      let truckIdLocal = Math.max(...res);
      // console.log(truckIdLocal);
      this.setState({
        truckId: truckIdLocal,
      });
      let carrierData = [];
      let newData = this.props?.totalTrucksListResult?.trucksListData;
      let carrierCount = [];
      if (newData?.length > 0) {
        newData?.forEach((c) => {
          if (c?.carrierDetails?.length > 0) {
            c?.carrierDetails?.forEach((t) =>
              carrierData?.push({
                id: c?.id,
                truckingId: t?.id,
                truckId: t?.truckId,
                carrier_name: t?.carrier_name,
                usdot: c?.usdot,
                contact_name: c?.contactDetails
                  ?.filter((con) => con?.isPrimaryContact === true)
                  ?.map((p) =>
                    p?.contact_name
                      ? p?.contact_name
                      : p?.firstName + " " + p?.lastName
                  )[0],
                contact_number: c?.contactDetails
                  ?.filter((con) => con?.isPrimaryContact === true)
                  ?.map((p) => (p?.phone ? p?.phone : p?.contact_number))[0],
                truck_code: t?.truck_code,
                truck_type: t?.truck_type,
                parking_location: t?.parking_location,
                insurance_expiry: c?.insurance_expiry,
                payment_terms: c?.payment_terms,
                payment_discount: c?.payment_discount,
                direct_deposit: c?.direct_deposit,
                lat: t?.lat,
                lng: t?.lng,
                // carrier_status: c?.carrier_status,
              })
            );
            carrierCount?.push(c);
          }
        });
        this.setState({
          totalTrucksData: carrierData,
          filterTrucksData: carrierData,
        });
      }
    }
    if (
      prevProps?.companiesResult?.success === false &&
      this.props?.companiesResult?.success === true
    ) {
      let data = [];
      this.props?.companiesResult?.ownersData?.forEach((e) => {
        if (e?.isOwnerOperator && e?.carrierDetails?.length > 0) {
          e?.carrierDetails?.forEach((c) => {
            if (c?.truckId !== null && c?.truckId !== undefined) {
              data.push(parseInt(c?.truckId));
            }
          });
        }
      });

      let res = data?.length > 0 ? data.flat() : [0];
      let truckIdLocal = Math.max(...res);
      this.setState({
        ownerCompanyData: this.props?.companiesResult?.ownersData?.filter(
          (c) => c.isOwnerOperator
        ),
        localTruckId: truckIdLocal,
      });
    }
    if (
      prevProps?.dropDownOptions?.success === false &&
      this.props?.dropDownOptions?.success === true
    ) {
      this.setState({
        ownerRoles: this.props?.ownerRoles[0]?.ownerRolesData?.sort((a, b) =>
          a?.value > b?.value ? 1 : -1
        ),
        trucksData: this.props?.trucksData[0]?.trucksData,
        paymentTerms: this.props?.termsData[0]?.termsData,
      });
    }
    if (
      prevProps?.dropDownOptionsInfo?.success === false &&
      this.props?.dropDownOptionsInfo?.success === true
    ) {
      this.props?.actiongetDropDownOptions();
    }
    if (
      prevProps?.docResult?.success === false &&
      this.props?.docResult?.success === true
    ) {
      this.props.actiongetTNBSOwner(this.props.match.params.id);
    }
  }

  showModal = (key) => {
    const { companyData, localTruckId, truckId } = this.state;
    if (key === "1") {
      this.setState({
        addTruck: true,
        recordTruckData: "",
        equipmentTypeDetails: companyData?.carrierDetails,
        overWeightPermitState: "",
        primaryTruck: localTruckId > truckId ? localTruckId + 1 : truckId + 1,
        input: "",
        secondTruckNo: [],
        dispatcherPhone: this.getDefaultValue(companyData?.contactDetails)
          ?.contact_number,
      });
    } else if (key === "2") {
      this.setState({ isContactModalVisible: true, jobContactData: "" });
    } else if (key === "3") {
      this.setState({ visible: true });
    } else if (key === "4") {
      this.setState({ isDocModalVisible: true, recordDocData: "" });
    } else {
      this.setState({ notesPopup: true });
    }
  };

  getAddress = (address) => {
    // console.log(address);
    this.setState({ selectedAddress: address.label });
  };

  handleSelect = (address) => {
    const citySearch = [];
    citySearch.push(address.label);
    this.setState({
      citySearch,
    });
    let cityValue = "";
    let stateValue = "";
    let zipCodeValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        getLatLng(results[0]).then(({ lat, lng }) => {
          this.setState({ latitude: lat, longitude: lng });
        });
        const geoIdData = results[0];
        this.setState({
          geoIdData,
        });
        let address = results[0].address_components;
        for (let i = 0; i < address?.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
          if (address[i].types[0] === "postal_code") {
            zipCodeValue = address[i].long_name;
          }
        }
        this.setState({
          city: cityValue,
          addressState: stateValue,
          zipCode: zipCodeValue,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  onFinishTruckDetails = (values) => {
    const {
      selectedAddress,
      equipmentTypeDetails,
      recordTruckData,
      latitude,
      longitude,
      selectedTruckCode,
      companyData,
      quoteAttachmentId,
      fileType,
      totalTrucksData,
      secondTruckNo,
      dispatcherPhone,
    } = this.state;
  
    const isValidParkingLocation =
      selectedAddress || recordTruckData?.parking_location;
  
    if (isValidParkingLocation) {
      const truckId = values.truckId;
      const newId = recordTruckData?.id || uuidv4();
      const overWeightPermit = values.overWeightPermit === "Yes";
      const overWeightExpiryDate = overWeightPermit
        ? moment(values.overWeightExpiryDate).format("L")
        : "";
  
      let data = {
        entityData: {
          carrier_name: companyData?.carrier_name,
          id: newId,
          parking_location: selectedAddress,
          lat: latitude || companyData.lat,
          lng: longitude || companyData.lng,
          truck_type: values.truck_type,
          overWeightPermit,
          overWeightExpiryDate,
          empty_weight: values.empty_weight,
          dispatcher: values.dispatcher,
          dispatcherPhone,
          truck_code: selectedTruckCode,
          carrierId: this.props?.match?.params?.id,
          docAttachmentId: overWeightPermit ? quoteAttachmentId : "",
          fileType,
          secondTruckNo,
          docDetails: recordTruckData?.docDetails,
          truckId,
          steelTrailer: values.steelTrailer,
        },
      };
  
      const existingTruck = totalTrucksData?.some(
        (element) => parseInt(element.truckId) === parseInt(truckId)
      );
  
      if (recordTruckData) {
        const isDifferentTruckId = truckId !== recordTruckData?.truckId;
        if (isDifferentTruckId && existingTruck) {
          message.error("Given Truck Number already exists!");
          return;
        }
        const index = equipmentTypeDetails.findIndex((e) => e.id === newId);
        if (index > -1) {
          equipmentTypeDetails[index] = data.entityData;
        }
      } else {
        if (existingTruck) {
          message.error("Given Truck Number already exists!");
          return;
        }
        equipmentTypeDetails.push(data.entityData);
      }
  
      let finalData = {
        entityData: {
          carrierDetails: equipmentTypeDetails,
        },
      };
      this.setState({ loading: true, addTruck: false, primaryTruck: "" });
      this.props.actionUpdateTNBSOwner(companyData?.id, finalData);
    } else {
      message.info("Please Enter Parking Location");
    }
  };

  onCancelTruck = () => {
    const { recordTruckData } = this.state;
    // console.log(recordTruckData)
    if (
      recordTruckData !== "" &&
      recordTruckData !== undefined &&
      recordTruckData?.length !== 0
    ) {
      let truckInfo =
        this.state.equipmentTypeDetails?.length > 0
          ? this.state?.equipmentTypeDetails
          : [];
      truckInfo.push(recordTruckData);
      this.setState({
        equipmentTypeDetails: truckInfo,
        recordTruckData: "",
        addTruck: false,
        selectedTruckCode: "",
        primaryTruck: "",
        input: "",
      });
      // this.formRef.current.setFieldsValue({ material: "", quantity: "" });
    } else {
      this.setState({
        addTruck: false,
        selectedTruckCode: "",
        overWeightPermitState: "",
        quoteAttachmentId: "",
        primaryTruck: "",
        input: "",
      });
    }
  };

  onClickEdit = (data) => {
    this.setState({
      equipmentTypeDetails: this.state.equipmentTypeDetails,
      addTruck: true,
      recordTruckData: data,
      latitude: data?.lat,
      longitude: data?.lng,
      selectedAddress: data?.parking_location,
      selectedTruckCode: data?.truck_code,
      quoteAttachmentId: data?.docAttachmentId,
      overWeightPermitState: data?.overWeightPermit,
      primaryTruck: data?.truckId,
      secondTruckNo: data?.secondTruckNo?.length > 0 ? data?.secondTruckNo : [],
      input: "",
      dispatcherPhone:
        data?.dispatcherPhone ||
        this.getDefaultValue(data?.contactDetails)?.contact_number,
    });
  };

  deleteTruckDetails = (id) => {
    const { companyData } = this.state;
    this.deleteDocOfTruck(id);
    let remainingTrucks = this.state.equipmentTypeDetails?.filter(
      (c) => c.id !== id
    );

    let truckInfo = this.state.equipmentTypeDetails?.filter((c) => c.id === id);

    truckInfo[0]["truckingId"] = truckInfo[0]["id"];
    delete truckInfo[0]["id"];
    truckInfo[0]["id"] = truckInfo[0]["carrierId"];

    let data = {
      entityData: truckInfo,
    };

    deleteAll("bulkDelete/trucksList", data)
      .then((result) => {
        if (result.success) {
          message.success("Truck deleted successfully!");
          this.props.actionFetchTrucksList();
          if (remainingTrucks?.length > 0) {
            this.props.actiongetTNBSOwner(companyData.id);
          } else {
            this.props.history.push("/app/twisted-nail");
          }
          this.props.actionFetchOwnerOperators();
          this.props?.actiongetDropDownOptions();
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  };

  deleteDocOfTruck = (id) => {
    // console.log(id);
    const { docsData } = this.state;

    let docInfo = docsData?.filter((d) => d?.truckId === id);
    // console.log(docInfo);
    if (docInfo?.length === 1) {
      this.deleteDoc(docInfo[0], "deleteDocOnly");
    }
  };

  deleteDoc = (record, type) => {
    this.setState({ loading: true });
    if (type === "deleteDocOnly") {
      this.deleteDocRecord(record?.id);
    } else {
      const { equipmentTypeDetails } = this.state;
      equipmentTypeDetails.forEach((element) => {
        if (element.id === record.truckId) {
          element.docDetails = element.docDetails.filter(
            (d) => d.id !== record.id
          );
        }
      });

      let finalData = {
        entityData: {
          carrierDetails: equipmentTypeDetails,
        },
      };
      this.props.actionUpdateTNBSOwner(this.state?.companyData?.id, finalData);
      this.deleteDocRecord(record?.id);
    }
  };

  deleteDocRecord = (id) => {
    this.props?.actionDeleteDoc(id);
  };

  onFinishCompanyDetails = (values) => {
    this.setState({
      // loading: true,
      isCompanyModalVisible: false,
    });
    const {
      companyData,
      // equipmentTypeDetails,
      selectedAddress,
      // contactInfo,
      latitude,
      longitude,
      carrierName,
      equipmentTypeDetails,
      payment_discount,
    } = this.state;
    const data = {
      entityData: {
        carrier_name: values["carrier_name"],
        address: selectedAddress || companyData?.address,
        usdot: values["usdot"],
        phone: values["phone"],
        email: values["email"],
        payment_terms:
          // values["payment_terms"] === "Custom Input"
          //   ? values["paymentTermsOthers"]
          //   :
          values["payment_terms"],
        payment_discount: payment_discount,
        insurance_expiry:
          values["insurance_expiry"] === null
            ? ""
            : values["insurance_expiry"] === ""
            ? ""
            : moment(values["insurance_expiry"]).format("L"),
        registered_since:
          values["registeredSince"] === null
            ? ""
            : moment(values["registeredSince"]).format("L"),
        lat: latitude || companyData?.["lat"],
        lng: longitude || companyData?.["lng"],
        direct_deposit: values["direct_deposit"],
        notes: values["notes"],
      },
    };
    // console.log(data);
    if (carrierName !== "" && carrierName !== undefined) {
      equipmentTypeDetails?.map((t) => (t["carrier_name"] = carrierName));
      data["entityData"]["carrierDetails"] = equipmentTypeDetails;
    }
    this.props.actionUpdateTNBSOwner(companyData?.id, data);
  };

  onFinishContact = (values) => {
    const { jobContactData, contactsData } = this.state;
    this.setState({ isContactVisible: false });
    let data = {
      entityData: {
        id: jobContactData?.id !== undefined ? jobContactData?.id : uuidv4(),
        carrierId: this.props?.match?.params?.id,
        companyId: this.props?.match?.params?.id,
        contact_name: values?.fName + " " + values?.lName,
        firstName: values.fName,
        lastName: values.lName,
        contact_number: values.contact_number,
        email: values.email,
        contactRole:
          values["role"] === "Add New Role"
            ? values["roleOther"]
            : values["role"],
        notes: values.notes,
        company: "",
        isPrimaryContact: jobContactData?.isPrimaryContact,
      },
    };

    if (contactsData?.length === 0) {
      data["entityData"]["isPrimaryContact"] = true;
    }

    let contactData = this.state.contactsData?.filter(
      (c) =>
        c?.email !== undefined &&
        c?.email === values?.email &&
        c?.phone !== undefined &&
        c?.phone === values?.phone
    );

    // console.log(contactData);
    if (contactData?.length > 0) {
      message.info("Given Email and Phone Number already exists");
      return true;
    } else {
      this.setState({
        loading: true,
        isContactModalVisible: false,
      });
      if (this.state.isCheckcontact) {
        this.updateOwnerRolesData(values["roleOther"]);
      }
      add(appConfig["entityNames"]["checkContact"], data).then((result) => {
        if (result.data.message === "success") {
          message.success("Contact Added Successfully!");
          this.addContact(data);
        }
      });
    }
    // console.log(data, "data");
  };

  updateOwnerRolesData = (values) => {
    let ownerRolesData = this.state.ownerRoles;
    ownerRolesData.push({
      label: values,
      value: values.charAt(0).toUpperCase(),
      id: ownerRolesData?.length + 1,
    });
    let data = {
      entityData: {
        ownerRolesData,
        typeofDropDown: "ownerOperatorRoles",
      },
    };
    this.props.actionupdateDropDownOptions(this.props.ownerRoles[0]?.id, data);
  };

  addContact = (data) => {
    const { contactsData, companyData } = this.state;
    const contactInfo = contactsData?.length > 0 ? contactsData : [];
    contactInfo.unshift(data["entityData"]);
    let finalData = {
      entityData: {
        contactDetails: contactInfo,
      },
    };
    // console.log(finalData);
    this.props.actionUpdateTNBSOwner(companyData?.id, finalData);
  };

  editModal = (data) => {
    // console.log(data);
    const { contactsData } = this.state;
    const filteredData = contactsData?.filter((c) => c?.id !== data?.id);
    // console.log(filteredData, "filtereerd");
    let contactName = data?.contact_name.split(" ");
    // console.log(data?.contact_name);
    this.setState({
      isContactModalVisible: true,
      jobContactData: data,
      contactsData: filteredData,
      firstName: contactName[0],
      lastName: contactName[1],
      secondTruckNo: this.state.secondTruckNo,
    });
  };

  onCancelContact = () => {
    const { jobContactData } = this.state;
    // console.log(jobContactData)
    if (
      jobContactData !== "" &&
      jobContactData !== undefined &&
      jobContactData?.length !== 0
    ) {
      let truckInfo =
        this.state.contactsData?.length > 0 ? this.state?.contactsData : [];
      truckInfo.push(jobContactData);
      this.setState({
        contactsData: truckInfo,
        jobContactData: "",
        isContactModalVisible: false,
      });
    } else {
      this.setState({ isContactModalVisible: false });
    }
  };

  deleteContact = (id) => {
    // console.log(id);
    const { contactsData, companyData } = this.state;
    const deleteData = contactsData?.filter((c) => c?.id !== id);
    // console.log(deleteData);
    let finalData = {
      entityData: {
        contactDetails: deleteData,
      },
    };
    // console.log(finalData);
    this.props.actionUpdateTNBSOwner(companyData?.id, finalData);
    this.setState({ contactsData: deleteData });
  };

  onClickReturn = () => {
    this.props.history.push("/app/twisted-nail");
  };

  onChangeTerms = (e, option) => {
    this.setState({ payment_discount: option.key });
    // this.setState({ selectedPaymentType: e });
    // this.formRef.current.setFieldsValue({
    //   paymentTermsOthers: "",
    // });
  };

  onSelectChange = (data) => {
    const { contactsData, companyData } = this.state;
    contactsData?.map((c) => {
      if (c?.id === data) {
        c["isPrimaryContact"] = true;
      } else {
        c["isPrimaryContact"] = false;
      }
      return true;
    });
    this.setState({ selectedRowKeys: data, contactsData });

    let finalData = {
      entityData: {
        contactDetails: contactsData.sort((a, b) =>
          a.isPrimaryContact < b.isPrimaryContact ? 1 : -1
        ),
      },
    };
    this.props.actionUpdateTNBSOwner(companyData?.id, finalData);
  };

  handleUploadPic = async (info) => {
    this.setState({
      imageLoading: true,
      loading: true,
    });
    switch (info.file.status) {
      case "done":
        if (info.fileList.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              Storage.put(info.file.uid, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                Storage.get(result.key).then(() => {
                  this.setState({
                    imageLoading: false,
                    loading: false,
                    quoteAttachmentId: result.key,
                    fileType: info.file.type,
                  });
                });
              });
            } else {
              this.setState({
                imageLoading: false,
                loading: false,
              });
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;
      default:
    }
  };

  fileDownload = async (fileName, downloadFileName, companyName) => {
    // console.log(fileName);
    let savedFileName = downloadFileName + "_" + companyName.replace(/ /g, "");
    this.setState({
      loadingDownload: true,
    });
    Storage.get(fileName, { download: true }).then((res) =>
      this.downloadBlob(res.Body, savedFileName)
    );
  };

  downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    this.setState({
      loadingDownload: false,
    });
    return a;
  }

  onFinishDoc = (values) => {
    const { quoteAttachment, recordDocData, equipmentTypeDetails } = this.state;
    this.setState({
      loading: true,
      isDocModalVisible: false,
    });
    let data = {
      entityData: {
        carrierId: this.props?.match?.params?.id,
        documentType: values["documentType"],
        description: values["description"],
        docAttachment: quoteAttachment,
        truckId: values["truckId"] !== undefined ? values["truckId"] : "",
      },
    };
    // console.log(data);

    if (recordDocData !== "") {
      if (values["truckId"] !== recordDocData.truckId) {
        equipmentTypeDetails.forEach((element) => {
          if (element.truckId === recordDocData.truckId) {
            element.docDetails = element.docDetails.filter(
              (d) => d.id !== recordDocData.id
            );
          }
        });
        this.setState({
          equipmentTypeDetails,
        });
      }
      this.updateDocument(data);
    } else {
      this.addDocument(data);
    }
  };

  addDocument = (data) => {
    const { equipmentTypeDetails } = this.state;
    add(appConfig["entityNames"]["addDocuments"], data)
      .then((res) => {
        if (res.success) {
          let truckIdData = equipmentTypeDetails?.filter(
            (s) => s?.truckId === res.data.data.truckId
          );
          if (truckIdData?.length > 0) {
            truckIdData[0]["docDetails"] = truckIdData[0]["docDetails"] || [];
            truckIdData[0]["docDetails"].push(res.data.data);

            equipmentTypeDetails?.splice(
              equipmentTypeDetails?.findIndex(
                (e) => e.truckId === data["entityData"]["truckId"]
              ),
              1,
              truckIdData[0]
            );
            let finalData = {
              entityData: {
                carrierDetails: equipmentTypeDetails,
              },
            };
            this.props.actionUpdateTNBSOwner(
              this.state?.companyData?.id,
              finalData
            );
          } else {
            this.props.actiongetTNBSOwner(this.props.match.params.id);
          }
          this.setState({
            loading: false,
            quoteAttachment: "",
            quoteAttachmentId: "",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
          quoteAttachment: "",
          quoteAttachmentId: "",
        });
      });
  };

  updateDocument = (data) => {
    const { equipmentTypeDetails, recordDocData } = this.state;

    update(appConfig["entityNames"]["addDocuments"], recordDocData?.id, data)
      .then((res) => {
        if (res.success) {
          let truckIdData = equipmentTypeDetails?.filter(
            (s) => s?.truckId === res.data.data.truckId
          );
          if (truckIdData?.length > 0) {
            truckIdData[0]["docDetails"] = truckIdData[0]["docDetails"] || [];
            truckIdData[0]["docDetails"].push(res.data.data);
            // console.log(truckIdData, "truckIdData", res.data.data);
            equipmentTypeDetails?.splice(
              equipmentTypeDetails?.findIndex(
                (e) => e.truckId === data["entityData"]["truckId"]
              ),
              1,
              truckIdData[0]
            );
            let finalData = {
              entityData: {
                carrierDetails: equipmentTypeDetails,
              },
            };
            this.props.actionUpdateTNBSOwner(
              this.state?.companyData?.id,
              finalData
            );
          } else {
            this.props.actiongetTNBSOwner(this.props.match.params.id);
          }
          this.setState({
            quoteAttachment: [],
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          // loading: false,
          quoteAttachment: [],
        });
      });
  };

  editDoc = (data) => {
    this.setState({
      isDocModalVisible: true,
      recordDocData: data,
      quoteAttachment: data?.docAttachment,
    });
  };

  DownloadDoc = (data) => {
    data?.docAttachment?.map((e) =>
      this.fileDownload(e.quoteAttachmentId, "Attachment", "docAttachment")
    );
  };

  addNotes = (values) => {
    const { recordNotesData } = this.state;
    this.setState({ loading: true, notesPopup: false });
    if (recordNotesData !== "") {
      let filteredData = this.state.notesData.filter(
        (c) => c.id !== recordNotesData?.id
      );
      this.setState({
        notesData: filteredData,
      });
    }

    let data = {
      entityData: {
        id: recordNotesData?.id !== undefined ? recordNotesData?.id : uuidv4(),
        date:
          recordNotesData?.date !== undefined
            ? recordNotesData?.date
            : moment().format("MM-DD-YYYY LT"),
        typeofNotes: values["type"],
        notes: values["notes"],
      },
    };
    // console.log(data);
    let notesInfo =
      this.state?.notesData?.length > 0 ? this.state?.notesData : [];
    notesInfo.push(data["entityData"]);
    this.setState({ notesData: notesInfo });
    this.updateNotes();
  };

  updateNotes = () => {
    const { notesData } = this.state;
    let data = {
      entityData: {
        notesDetails: notesData,
      },
    };
    this.props.actionUpdateTNBSOwner(this.state?.companyData?.id, data);
  };

  editNotes = (data) => {
    this.setState({
      notesPopup: true,
      recordNotesData: data,
    });
  };

  deleteNotes = (id) => {
    this.setState({ loading: true });
    let notesInfo = this.state.notesData.filter((c) => c?.id !== id);
    this.setState(
      {
        notesData: notesInfo,
      },
      () => this.updateNotes()
    );
    message.info("Notes Deleted Successfully!");
  };

  closeFilter = () => {
    this.setState({
      visible: false,
    });
  };

  onClickClearFilter = () => {
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    this.props?.context?.updateCarrierId(id);
    this.props?.actiongetDropDownOptions();
    this.props.actiongetTNBSOwner(id);
    this.props.actionFetchTrucksList();
    this?.props?.actionFetchOwnerOperators();
    this.closeFilter();
    this.setState({ checkData: "" });
  };

  filterResultData = (data) => {
    const { invoicesData } = this.state;

    if (
      data?.paymentStatus !== undefined &&
      data?.startAndEnddates === undefined &&
      data?.truckNumbers === undefined
    ) {
      const filteredData = invoicesData?.filter(
        (i) => i?.APTinvoiceStatus === data?.paymentStatus
      );
      // console.log(filteredData);
      this.setState({ filteredInvoicesData: filteredData });
    } else if (
      data?.startAndEnddates !== undefined &&
      data?.paymentStatus === undefined &&
      data?.truckNumbers === undefined
    ) {
      const filteredData = invoicesData?.filter((i) =>
        moment(moment(i?.createdAt).format("MM/DD/YYYY")).isBetween(
          data?.startAndEnddates?.startDate,
          data?.startAndEnddates?.endDate,
          undefined,
          "[]"
        )
      );
      this.setState({ filteredInvoicesData: filteredData });
    } else if (
      data?.truckNumbers !== undefined &&
      data?.startAndEnddates === undefined &&
      data?.paymentStatus === undefined
    ) {
      const filteredData = invoicesData?.filter(
        (i) => i?.truckNumber === data?.truckNumbers
      );
      this.setState({ filteredInvoicesData: filteredData });
    } else if (
      data?.startAndEnddates !== undefined &&
      data?.truckNumbers !== undefined &&
      data?.paymentStatus === undefined
    ) {
      const filteredData = invoicesData?.filter(
        (i) =>
          moment(moment(i?.createdAt).format("MM/DD/YYYY")).isBetween(
            data?.startAndEnddates?.startDate,
            data?.startAndEnddates?.endDate,
            undefined,
            "[]"
          ) && i?.truckNumber === data?.truckNumbers
      );
      this.setState({ filteredInvoicesData: filteredData });
    } else if (
      data?.truckNumbers !== undefined &&
      data?.paymentStatus !== undefined &&
      data?.startAndEnddates === undefined
    ) {
      const filteredData = invoicesData?.filter(
        (i) =>
          i?.APTinvoiceStatus === data?.paymentStatus &&
          i?.truckNumber === data?.truckNumbers
      );
      this.setState({ filteredInvoicesData: filteredData });
    } else if (
      data?.paymentStatus !== undefined &&
      data?.startAndEnddates !== undefined &&
      data?.truckNumbers === undefined
    ) {
      const filteredData = invoicesData?.filter(
        (i) =>
          i?.APTinvoiceStatus === data?.paymentStatus &&
          moment(moment(i?.createdAt).format("MM/DD/YYYY")).isBetween(
            data?.startAndEnddates?.startDate,
            data?.startAndEnddates?.endDate,
            undefined,
            "[]"
          )
      );
      this.setState({ filteredInvoicesData: filteredData });
    } else if (
      data?.paymentStatus !== undefined &&
      data?.startAndEnddates !== undefined &&
      data?.truckNumbers !== undefined
    ) {
      const filteredData = invoicesData?.filter(
        (i) =>
          i?.APTinvoiceStatus === data?.paymentStatus &&
          moment(moment(i?.createdAt).format("MM/DD/YYYY")).isBetween(
            data?.startAndEnddates?.startDate,
            data?.startAndEnddates?.endDate,
            undefined,
            "[]"
          ) &&
          i?.truckNumber === data?.truckNumbers
      );
      this.setState({ filteredInvoicesData: filteredData });
    }
    this.closeFilter();
    this.setState({ checkData: data });
  };

  onChangeOverWeight = (e) => {
    this.setState({
      overWeightPermitState: e?.target?.value,
    });
    if (e?.target?.value === "No") {
      this.formRef.current.setFieldsValue({
        overWeightExpiryDate: "",
      });
      this.setState({
        quoteAttachment: "",
        quoteAttachmentId: "",
      });
    }
  };

  uploadTicketPdf = async (info) => {
    const { recordDocData } = this.state;
    this.setState({
      imageLoading: true,
      loading: true,
    });

    switch (info.file.status) {
      case "done":
        this.setState({
          fileList: info.fileList,
        });
        if (info.fileList.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              Storage.put(info.file.uid, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                Storage.get(result.key).then(() => {
                  let quoteAttachmentInfo = this.state.quoteAttachment
                    ? this.state.quoteAttachment
                    : [];
                  quoteAttachmentInfo.push({
                    id: uuidv4(),
                    quoteAttachmentId: result?.key,
                    fileType: info.file.type,
                    fileName: info.file.name,
                  });
                  this.setState({
                    quoteAttachment: quoteAttachmentInfo,
                    imageLoading: false,
                    loading: false,
                  });
                });
              });
            } else {
              this.setState({
                imageLoading: false,
                percentage: 0,
                loading: false,
              });
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;

      case "removed":
        if (recordDocData) {
          let docData = recordDocData.docAttachment.filter(
            (e) => e.id !== info.file.id
          );
          recordDocData["docAttachment"] = docData;
          this.setState({
            quoteAttachment: docData,
            recordDocData,
          });
        }
        this.setState({
          ticketUploaded: "",
          imageLoading: false,
          loading: false,
          percentage: 0,
        });
        break;
      default:
        this.setState(
          {
            imageLoading: false,
            loading: false,

            percentage: 0,
          },
          this.settime()
        );
    }
  };

  settime = () => {
    setTimeout(() => {
      this.setState({ percentage: this.state.percentage + 40 });
    }, 1000);
  };

  showDetailModal = (data) => {
    this.setState({ isDetailModal: true, docData: data?.docDetails });
  };

  handleOnInputChange = (e) => {
    this.setState({
      input: parseInt(e.target.value),
    });
  };

  handleKeyDown = (event) => {
    const { input, secondTruckNo, primaryTruck } = this.state;
    const getIdx = this.state.secondTruckNo.filter(
      (rec) => rec.value === input
    );

    const uniqueSet = new Set(secondTruckNo);
    uniqueSet.add(input);
    const updatedSecondTruckNo = Array.from(uniqueSet);
    if (getIdx.length === 0 && input && event.key === "Enter") {
      if (primaryTruck === input) {
        message.error("Truck Number already exists!");
        event.preventDefault();
        this.setState({ input: "" });
        return;
      }
      if (secondTruckNo.includes(input)) {
        message.error("Truck Number already exists!");
        return;
      } else {
        this.setState({ secondTruckNo: updatedSecondTruckNo, input: "" });
        event.preventDefault();
      }
    }
  };

  handleDelete = (val, index) => {
    const { secondTruckNo } = this.state;
    const currentValues = [...secondTruckNo];
    currentValues.splice(index, 1);
    this.setState({ secondTruckNo: currentValues });
  };

  handleClickWrapper = () => {
    this.inputRef.current.focus();
  };

  onRefresh = () => {
    this.setState({
      loading: true,
    });
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    this.props?.context?.updateCarrierId(id);
    this.props.actiongetTNBSOwner(id);
    this.props?.actiongetDropDownOptions();
    this.props.actionFetchTrucksList();
    this?.props?.actionFetchOwnerOperators();
  };

  handleTableChage = (props, type) => {
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    localStorage.setItem(
      "tableSizeRange",
      JSON.stringify({ ...tableSizeRange, [type]: props })
    );
  };

  onClearResult = () => {
    this.setState({
      secondTruckNo: [],
    });
  };

  calculateAverageTime = (timeStrings) => {
    const totalMinutes = timeStrings
      ?.map((time) => this.timeStringToMinutes(time))
      ?.reduce((acc, cur) => acc + cur, 0);

    const averageMinutes = totalMinutes / timeStrings?.length;
    return this.minutesToTimeString(Math.round(averageMinutes));
  };

  timeStringToMinutes = (timeString) => {
    const [time, period] = timeString?.split(" ");
    let [hours, minutes] = time?.split(":")?.map(Number);

    if (period?.toLowerCase() === "pm" && hours !== 12) {
      hours += 12;
    }
    if (period?.toLowerCase() === "am" && hours === 12) {
      hours = 0;
    }

    return hours * 60 + minutes;
  };

  minutesToTimeString = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const period = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = mins.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes} ${period}`;
  };

  getAverageStartTime = (truckId, type) => {
    const nintyDaysAgo = moment().subtract(90, "days");
    const { ticketResult, DaysSelected } = this.state;
    const matchedTruckNumber = ticketResult?.filter(
      (e) =>
        Number(e.truckNumber) === truckId &&
        e?.pickUpTimeStamp !== "" &&
        (DaysSelected ? moment(e.ticketDate).isAfter(nintyDaysAgo) : true)
    );
    let startTimes = [],
      endTimes = [];
    const groupedByDate = matchedTruckNumber.reduce((acc, curr) => {
      const date = curr?.ticketDate;
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(moment(curr?.pickUpTimeStamp).format("hh:mm a"));
      return acc;
    }, {});
    const sortedDates = Object.keys(groupedByDate).reduce((acc, date) => {
      acc[date] = groupedByDate[date].sort((a, b) => {
        const timeA = moment(a, "hh:mm a");
        const timeB = moment(b, "hh:mm a");
        return timeA.diff(timeB);
      });
      return acc;
    }, {});

    if (sortedDates && Object.keys(sortedDates)?.length > 0) {
      startTimes = Object.keys(sortedDates)?.map((date) => {
        const firstTime = sortedDates[date][0];
        return firstTime;
      });

      endTimes = Object.keys(sortedDates).map((date) => {
        const lastTimeIndex = sortedDates[date].length - 1;
        const lastTime = sortedDates[date][lastTimeIndex];
        return lastTime;
      });
    }
    const result =
      startTimes?.length > 0 &&
      this.calculateAverageTime(type === "start" ? startTimes : endTimes);
    return result || "";
  };

  getDefaultValue = (contactDetails) => {
    const { companyData } = this.state;
    let primaryContacts = contactDetails?.filter((e) => e.isPrimaryContact);
    const dispatchers = contactDetails?.filter(
      (e) => e.contactRole === "Dispatch"
    );
    if (primaryContacts?.length || dispatchers?.length) {
      if (dispatchers.length) return dispatchers[0];

      if (primaryContacts?.length) return primaryContacts[0];
    } else {
      let carrierObj = {};
      carrierObj["contact_name"] = companyData?.carrier_name;
      carrierObj["contact_number"] = {}

      return carrierObj || undefined;
    }
  };

  render() {
    const {
      secondTruckNo,
      primaryTruck,
      paymentTerms,
      companyData,
      isContactVisible,
      // equipmentTypeDetails,
      isCompanyModalVisible,
      citySearch,
      addTruck,
      recordTruckData,
      jobContactData,
      isContactModalVisible,
      //   contactsData,
      // localTruckId,
      // truckId,
      newRole,
      selectedPaymentType,
      // selectedRowKeys,
      isDocModalVisible,
      imageLoading,
      overWeightPermitState,
      notesPopup,
      recordNotesData,
      visible,
      filteredInvoicesData,
      checkData,
      firstName,
      lastName,
      invoicesData,
      recordDocData,
      isDetailModal,
      docData,
      quoteAttachmentId,
      ownerRoles,
      trucksData,
      loadingDownload,
    } = this.state;
    const { loading, errorLoading } = this.props;
    // console.log(equipmentTypeDetails);

    // let today = moment().add(30, "days").calendar();
    const getOptions = (contactDetails) => {
      const { companyData } = this.state;
      const primaryContacts = contactDetails?.filter(
        (e) => e?.isPrimaryContact
      );
      const dispatchers = contactDetails.filter(
        (e) => e.contactRole === "Dispatch"
      );
      const carrierName = companyData?.carrier_name;
      if (dispatchers?.length)
        return dispatchers.map((e, index) => (
          <Option key={index} value={e.contact_name} label={e?.contact_number}>
            {e.contact_name}
          </Option>
        ));

      if (primaryContacts?.length)
        return primaryContacts.map((e, index) => (
          <Option
            key={index}
            value={`${e?.contact_name}`}
            label={e?.contact_number}
          >{`${e.contact_name}`}</Option>
        ));
      if (carrierName)
        return (
          <Option
            key="carrier"
            value={carrierName}
            label={primaryContacts[0]?.contact_number}
          >
            {carrierName}
          </Option>
        );

      return null;
    };

    const allTime = [
      {
        label: "All Time",
        value: "allTime",
      },
      {
        label: "90 Days",
        value: "90Days",
      },
    ];
    let revenue = 0; //lifetime revenue cal.
    invoicesData?.map(
      (i) =>
        i?.isTruckingJob && (revenue += parseFloat(i?.vendor[0]?.truckingPrice))
    );

    let payments = 0; //lifetime payments cal.
    invoicesData?.map(
      (i) =>
        i?.isTruckingJob && (payments += parseFloat(i?.vendor[0]?.truckingCost))
    );

    const equipmentColumns = [
      {
        title: "Primary Truck#",
        dataIndex: "truckId",
        key: "truckId",
        sorter: false,
        width: "5%",
        className: "col_style_quarry",
        fixed: "left",
        render: (truckId) => {
          return <span className="col_style_quarry">{truckId}</span>;
        },
      },
      {
        title: "Secondary Truck#",
        dataIndex: "secondTruckNo",
        key: "secondTruckNo",
        sorter: false,
        width: "5%",
        className: "col_style_quarry",
        render: (secondTruckNo, record) => {
          return (
            <div className="col_style_quarry">
              {record?.secondTruckNo?.length > 0
                ? record?.secondTruckNo.join(", ")
                : secondTruckNo}
            </div>
          );
        },
      },
      {
        title: "Type",
        dataIndex: "truck_type",
        key: "truck_type",
        sorter: false,
        width: "10%",
        className: "col_style_quarry",
        render: (truck_type, record) => {
          return (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "fit-content",
              }}
            >
              <span className="col_style_quarry">{truck_type}</span>
              {record?.docDetails?.length > 0 && (
                <Col>
                  <Button
                    icon={<PaperClipOutlined />}
                    style={{
                      fontSize: "15px",
                      cursor: "pointer",
                      border: "none",
                      backgroundColor: "transparent",
                    }}
                    onClick={() => this.showDetailModal(record)}
                  ></Button>
                </Col>
              )}
            </Row>
          );
        },
      },
      {
        title: "Location",
        dataIndex: "parking_location",
        key: "parking_location",
        sorter: false,
        className: "col_style_quarry",
        width: "20%",
        render: (parking_location) => {
          return <span className="col_style_quarry">{parking_location}</span>;
        },
      },
      {
        title: "Weight",
        dataIndex: "empty_weight",
        key: "empty_weight",
        sorter: false,
        width: "5%",
        className: "col_style_quarry",
        render: (empty_weight) => {
          return (
            <span className="col_style_quarry">
              {empty_weight && empty_weight !== "" && empty_weight !== undefined
                ? `${parseInt(empty_weight)} Ton`
                : ""}
            </span>
          );
        },
      },
      {
        title: "Avg Load",
        dataIndex: "average_loadSize",
        key: "average_loadSize",
        sorter: false,
        width: "8%",
        className: "col_style_quarry",
        render: (average_loadSize) => {
          return (
            <span className="col_style_quarry">
              {average_loadSize && `${average_loadSize} Ton`}
            </span>
          );
        },
      },
      {
        title: "OW",
        dataIndex: "overWeightPermit",
        key: "overWeightPermit",
        sorter: false,
        width: "5%",
        className: "col_style_quarry",
        render: (overWeightPermit, record) => {
          return (
            <span className="col_style_quarry">
              {overWeightPermit && overWeightPermit}
            </span>
          );
        },
      },
      // {
      //   title: "OW Expiry",
      //   dataIndex: "overWeightExpiryDate",
      //   key: "overWeightExpiryDate",
      //   sorter: false,
      //   width: "5%",
      //   className: "col_style_quarry",
      //   render: (overWeightExpiryDate, record) => {
      //     return (
      //       <span
      //         className="col_style_quarry"
      //         style={{
      //           color:
      //             new Date(today).getTime() <=
      //             new Date(overWeightExpiryDate).getTime()
      //               ? "black"
      //               : new Date(today).getTime() >=
      //                 new Date(overWeightExpiryDate).getTime()
      //               ? ""
      //               : "black",
      //         }}
      //       >
      //         {record?.overWeightPermit === "Yes"
      //           ? moment(overWeightExpiryDate).format("L")
      //           : record?.overWeightPermit === "No"
      //           ? "N/A"
      //           : ""}
      //       </span>
      //     );
      //   },
      // },
      {
        title: "Start Time",
        dataIndex: "truckId",
        key: "StartTime",
        sorter: false,
        width: "8%",
        className: "col_style_quarry",
        render: (truckId) => {
          return (
            <span className="col_style_quarry">
              {this.getAverageStartTime(truckId, "start")}
            </span>
          );
        },
      },
      {
        title: "End Time",
        dataIndex: "truckId",
        key: "EndTime",
        sorter: false,
        width: "8%",
        className: "col_style_quarry",
        render: (truckId) => {
          return (
            <span className="col_style_quarry">
              {this.getAverageStartTime(truckId, "end")}
            </span>
          );
        },
      },
      {
        title: "Work Day",
        dataIndex: "truckId",
        key: "WorkDay",
        sorter: false,
        width: "9%",
        className: "col_style_quarry",
        render: (truckId) => {
          const startTime = moment(
            this.getAverageStartTime(truckId, "start"),
            "hh:mm a"
          );
          const endTime = moment(
            this.getAverageStartTime(truckId, "end"),
            "hh:mm a"
          );
          let hours, minutes;
          if (startTime && endTime) {
            const duration = moment.duration(endTime.diff(startTime));
            hours = Math.floor(duration.asHours());
            minutes = duration.minutes();
          }
          return (
            <span className="col_style_quarry">
              {hours || minutes ? `${hours} hrs ${minutes} min` : ""}
            </span>
          );
        },
      },
      // {
      //   title: "Attachment",
      //   dataIndex: "docAttachmentId",
      //   key: "docAttachmentId",
      //   sorter: false,
      //   width: "5%",
      //   className: "col_style_quarry",
      //   render: (docAttachmentId, record) => {
      //     // console.log(docAttachmentId, "docAttachmentId");
      //     return (
      //       <span className="col_style_quarry">
      //         {docAttachmentId !== undefined &&
      //         record?.overWeightPermit === "Yes" &&
      //         docAttachmentId !== "" &&
      //         docAttachmentId !== null ? (
      //           <span
      //             style={{ cursor: "pointer" }}
      //             onClick={() => {
      //               this.fileDownload(
      //                 docAttachmentId,
      //                 "Attachment",
      //                 "docAttachment"
      //               );
      //             }}
      //           >
      //             <Row
      //               style={{
      //                 color: "#7B8190",
      //                 fontSize: "13px",
      //                 display: "flex",
      //                 justifyContent: "center",
      //                 alignContent: "center",
      //                 flexDirection: "coloumn",
      //               }}
      //             >
      //               <HiOutlineDocumentArrowDown
      //                 style={{
      //                   fontSize: "30px",
      //                   height: "30px",
      //                   // marginRight: "5px",
      //                 }}
      //               />
      //               {/* <span style={{ marginTop: "6px" }}>Download</span> */}
      //             </Row>
      //           </span>
      //         ) : record?.overWeightPermit === "No" ? (
      //           "N/A"
      //         ) : (
      //           ""
      //         )}
      //       </span>
      //     );
      //   },
      // },

      {
        title: "Steel Trailer",
        dataIndex: "steelTrailer",
        key: "steelTrailer",
        sorter: false,
        width: "10%",
        className: "col_style_quarry",
        render: (steelTrailer) => {
          return <span className="col_style_quarry">{steelTrailer}</span>;
        },
      },
      {
        title: "Dispatcher",
        dataIndex: "dispatcher",
        key: "dispatcher",
        sorter: false,
        width: "10%",
        className: "col_style_quarry",
        render: (dispatcher) => {
          return <span className="col_style_quarry">{dispatcher}</span>;
        },
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "",
        sorter: false,
        className: "col_style_quarry",
        width: "2%",
        fixed: "right",
        render: (record) => {
          return (
            <Col>
              <Popover
                placement="left"
                content={
                  <Row className="popovergrid" style={{ maxWidth: "90px" }}>
                    <Col span={24}>
                      <Button
                        className="btn_edit"
                        onClick={() => this.onClickEdit(record)}
                      >
                        <span className="span_edit">
                          <MdModeEditOutline style={{ marginRight: "5px" }} />
                          Edit
                        </span>
                      </Button>
                    </Col>
                    <Col span={24}>
                      <Button className="btn_edit">
                        <Popconfirm
                          title="Are you sure？"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => this.deleteTruckDetails(record?.id)}
                        >
                          <span className="span_edit">
                            <MdDelete style={{ marginRight: "5px" }} /> Delete
                          </span>
                        </Popconfirm>
                      </Button>
                    </Col>
                  </Row>
                }
              >
                <EllipsisOutlined
                  style={{ fontSize: "30px", color: "grey" }}
                  className="action-ellipsis-button"
                />
              </Popover>
            </Col>
          );
        },
      },
    ];

    const contactsColumns = [
      {
        title: "Contact Name",
        dataIndex: "contact_name",
        key: "contact_name",
        sorter: false,
        width: "20%",
        className: "col_style_quarry",
        render: (contact_name, record) => {
          return {
            props: {
              style: {
                background: record.isPrimaryContact ? "#dcf4ff" : "white",
              },
            },
            children: (
              <span className={"col_style_quarry"}>
                {record.isPrimaryContact
                  ? contact_name + " (Primary)"
                  : contact_name}
              </span>
            ),
          };
        },
      },
      {
        title: "Role",
        dataIndex: "contactRole",
        key: "contactRole",
        sorter: false,
        width: "20%",
        className: "col_style_quarry",
        render(contactRole, record) {
          return {
            props: {
              style: {
                background: record.isPrimaryContact ? "#dcf4ff" : "white",
              },
            },
            children: <span className="col_style_quarry">{contactRole}</span>,
          };
        },
      },
      {
        title: "Phone",
        dataIndex: "contact_number",
        key: "contact_number",
        sorter: false,
        className: "col_style_quarry",
        width: "15%",
        render: (contact_number, record) => {
          return {
            props: {
              style: {
                background: record.isPrimaryContact ? "#dcf4ff" : "white",
              },
            },
            children: (
              <span className="col_style_quarry">{contact_number}</span>
            ),
          };
        },
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        sorter: false,
        className: "col_style_quarry",
        width: "40%",
        render: (email, record) => {
          return {
            props: {
              style: {
                background: record.isPrimaryContact ? "#dcf4ff" : "white",
              },
            },
            children: <span className="col_style_quarry">{email}</span>,
          };
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        sorter: false,
        className: "col_style_quarry",
        width: "15%",
        render: (record) => {
          return {
            props: {
              style: {
                background: record.isPrimaryContact ? "#dcf4ff" : "white",
              },
            },
            children: (
              <Col>
                <Popover
                  placement="left"
                  content={
                    <Row style={{ width: "136px" }}>
                      <Col span={24}>
                        <Button
                          className="btn_edit"
                          onClick={() => this.editModal(record)}
                        >
                          <span className="span_edit">
                            <MdModeEditOutline style={{ marginRight: "5px" }} />
                            Edit
                          </span>
                        </Button>
                      </Col>
                      <Col span={24}>
                        <Button className="btn_edit">
                          <Popconfirm
                            title="Are you sure？"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.deleteContact(record?.id)}
                          >
                            <span className="span_edit">
                              <MdDelete style={{ marginRight: "5px" }} /> Delete
                            </span>
                          </Popconfirm>
                        </Button>
                      </Col>
                      {!record?.isPrimaryContact && (
                        <Col span={24}>
                          <Button
                            className="btn_edit"
                            onClick={() => this.onSelectChange(record.id)}
                          >
                            <span
                              className="span_edit"
                              style={{
                                color: "black",
                                display: "flex",
                                // flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "left",
                              }}
                            >
                              <ContactsOutlined
                                style={{
                                  marginRight: "5px",
                                }}
                              />
                              Set as Primary
                            </span>
                          </Button>
                        </Col>
                      )}
                    </Row>
                  }
                >
                  <EllipsisOutlined
                    style={{ fontSize: "30px", color: "grey" }}
                    className="action-ellipsis-button"
                  />
                </Popover>
              </Col>
            ),
          };
        },
      },
    ];

    const invoicesColumns = [
      {
        title: "Invoice Date",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: false,
        width: "10%",
        className: "col_style_quarry",
        onCell: (record) => ({
          onDoubleClick: () =>
            (window.location = `/app/Invoice-Management/${record.invoiceId}`),
        }),
        render: (createdAt) => {
          return (
            <span className="col_style_quarry">
              {moment(createdAt).format("L")}
            </span>
          );
        },
      },
      {
        title: "TNB #",
        dataIndex: "truckNumber",
        key: "truckNumber",
        sorter: false,
        width: "10%",
        className: "col_style_quarry",
        onCell: (record) => ({
          onDoubleClick: () =>
            (window.location = `/app/Invoice-Management/${record.invoiceId}`),
        }),
        render: (truckNumber) => {
          return <span className="col_style_quarry">{truckNumber}</span>;
        },
      },
      {
        title: "Invoice #",
        dataIndex: "invoiceNumber",
        key: "invoiceNumber",
        sorter: false,
        width: "10%",
        className: "col_style_quarry",
        onCell: (record) => ({
          onDoubleClick: () =>
            (window.location = `/app/Invoice-Management/${record.invoiceId}`),
        }),
        render: (invoiceNumber) => {
          return <span className="col_style_quarry">{invoiceNumber}</span>;
        },
      },
      {
        title: "Gross",
        dataIndex: "ticketsTotal",
        key: "ticketsTotal",
        sorter: false,
        width: "10%",
        className: "col_style_quarry",
        onCell: (record) => ({
          onDoubleClick: () =>
            (window.location = `/app/Invoice-Management/${record.invoiceId}`),
        }),
        render: (ticketsTotal) => {
          return (
            <span className="col_style_quarry">{"$ " + ticketsTotal}</span>
          );
        },
      },
      {
        title: "Due ",
        dataIndex: "",
        key: "",
        sorter: false,
        width: "10%",
        className: "col_style_quarry",
        onCell: (record) => ({
          onDoubleClick: () =>
            (window.location = `/app/Invoice-Management/${record.invoiceId}`),
        }),
        render: (record) => {
          return (
            <span className="col_style_quarry">
              {"$ " + parseInt(record?.vendor[0]?.truckingCost) + ".00"}
            </span>
          );
        },
      },
      {
        title: "Due Date",
        dataIndex: "APTinvoiceStatus",
        key: "APTinvoiceStatus",
        sorter: false,
        width: "15%",
        className: "col_style_quarry",
        onCell: (record) => ({
          onDoubleClick: () =>
            (window.location = `/app/Invoice-Management/${record.invoiceId}`),
        }),
        render: (APTinvoiceStatus, record) => {
          // APTinvoiceStatus === "Unpaid" ? `Due on: ${moment().add(record?.APdueDays, "days")` : APTinvoiceStatus
          return (
            <span className="col_style_quarry">
              {APTinvoiceStatus === "Unpaid"
                ? `Due on: ${moment(
                    moment().add(parseInt(record?.APdueDays), "days")
                  ).format("L")}`
                : APTinvoiceStatus}
            </span>
          );
        },
      },
      {
        title: "Attachment",
        dataIndex: "invoicePath",
        key: "invoicePath",
        sorter: false,
        width: "4%",
        className: "col_style_quarry",
        render: (invoicePath, record) => {
          return (
            <span className="col_style_quarry">
              {invoicePath !== undefined &&
                invoicePath !== "" &&
                invoicePath !== null && (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.fileDownload(
                        invoicePath,
                        "Attachment",
                        "invoicePath"
                      );
                    }}
                  >
                    <Row
                      style={{
                        color: "#7B8190",
                        fontSize: "13px",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        flexDirection: "coloumn",
                      }}
                    >
                      <HiOutlineDocumentArrowDown
                        style={{
                          fontSize: "30px",
                          height: "30px",
                          // marginRight: "5px",
                        }}
                      />
                      {/* <span style={{ marginTop: "6px" }}>Download</span> */}
                    </Row>
                  </span>
                )}
            </span>
          );
        },
      },
      // {
      //   title: "Attachments",
      //   dataIndex: "invoiceAttachment",
      //   key: "attachment",
      //   sorter: false,
      //   className: "col_style_quarry",
      //   render: (attachment) => {
      //     return <span className="col_style_quarry">{attachment}</span>;
      //   },
      // },
    ];

    const docuColumn = [
      {
        title: "Truck#",
        dataIndex: "truckId",
        key: "truckId",
        sorter: false,
        width: "10%",
        className: "col_style_quarry",
        render: (truckId) => {
          return <span className="col_style_quarry">{truckId}</span>;
        },
      },
      {
        title: "Upload Date",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: false,
        width: "10%",
        className: "col_style_quarry",
        render: (createdAt) => {
          return (
            <span className="col_style_quarry">
              {moment(createdAt).format("L")}
            </span>
          );
        },
      },
      {
        title: "Document Type",
        dataIndex: "documentType",
        key: "documentType",
        sorter: false,
        width: "20%",
        className: "col_style_quarry",
        render: (documentType) => {
          return <span className="col_style_quarry">{documentType}</span>;
        },
      },
      {
        title: "Document Description",
        dataIndex: "description",
        key: "description",
        sorter: false,
        width: "40%",
        className: "col_style_quarry",
        render: (description) => {
          return <span className="col_style_quarry">{description}</span>;
        },
      },
      {
        title: "Attachment",
        dataIndex: "docAttachment",
        key: "docAttachment",
        sorter: false,
        width: "5%",
        className: "col_style_quarry",
        render: (docAttachment) => {
          return (
            <div
              className="col_style_quarry"
              style={{
                marginBottom: "5px",
                // display: "flex",
                // justifyContent: "center",
                // alignContent: "center",
                // flexWrap: "wrap",
              }}
            >
              {docAttachment !== undefined &&
                docAttachment !== "" &&
                docAttachment !== null &&
                docAttachment?.map((d) => (
                  <Popover placement="left" content={d.fileName}>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.fileDownload(
                          d.quoteAttachmentId,
                          "Attachment",
                          "docAttachment"
                        );
                      }}
                    >
                      <Row
                        style={{
                          color: "#7B8190",
                          fontSize: "13px",
                          // display: "flex",
                          // justifyContent: "center",
                          // alignContent: "center",
                          // flexDirection: "coloumn",
                        }}
                      >
                        <HiOutlineDocumentArrowDown
                          style={{
                            fontSize: "30px",
                            height: "30px",
                            // marginRight: "5px",
                          }}
                        />
                        {/* <span style={{ marginTop: "6px" }}>Download</span> */}
                      </Row>
                    </span>
                  </Popover>
                ))}
            </div>
          );
        },
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "",
        sorter: false,
        className: "col_style_quarry",
        width: "2%",
        render: (record) => {
          return (
            <Col>
              <Popover
                placement="left"
                content={
                  <Row className="popovergrid" style={{ maxWidth: "110px" }}>
                    <Col span={24}>
                      <Button
                        className="btn_edit"
                        onClick={() => this.DownloadDoc(record)}
                      >
                        <span className="span_edit">
                          <ArrowDownOutlined
                            style={{
                              marginRight: "5px",
                            }}
                          />{" "}
                          Download
                        </span>
                      </Button>
                    </Col>
                    <Col span={24}>
                      <Button
                        className="btn_edit"
                        onClick={() => this.editDoc(record)}
                      >
                        <span className="span_edit">
                          <MdModeEditOutline
                            style={{
                              marginRight: "5px",
                            }}
                          />{" "}
                          Edit
                        </span>
                      </Button>
                    </Col>
                    <Col span={24}>
                      <Button className="btn_edit">
                        <Popconfirm
                          title="Are you sure？"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => this.deleteDoc(record)}
                        >
                          <span
                            className="span_edit"
                            style={{
                              color: "black",
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                              justifyContent: "left",
                            }}
                          >
                            <MdDelete style={{ marginRight: "5px" }} /> Delete
                          </span>
                        </Popconfirm>
                      </Button>
                    </Col>
                  </Row>
                }
              >
                <EllipsisOutlined
                  style={{ fontSize: "30px", color: "grey" }}
                  className="action-ellipsis-button"
                />
              </Popover>
            </Col>
          );
        },
      },
      // {
      //   title: "Document Attachment",
      //   dataIndex: "docAttachment",
      //   key: "docAttachment",
      //   sorter: false,
      //   className: "col_style_quarry",
      //   render: (docAttachment) => {
      //     return <span className="col_style_quarry">{docAttachment}</span>;
      //   },
      // },
    ];

    const notesColumns = [
      {
        title: "Date Created",
        dataIndex: "date",
        key: "date",
        sorter: false,
        width: "25%",
        className: "col_style_quarry",
        render: (date) => {
          return <span className="col_style_quarry">{date}</span>;
        },
      },
      {
        title: "Note Type",
        dataIndex: "typeofNotes",
        key: "typeofNotes",
        sorter: false,
        className: "col_style_quarry",
        width: "25%",
        render: (typeofNotes) => {
          return <span className="col_style_quarry">{typeofNotes}</span>;
        },
      },
      {
        title: "Note",
        dataIndex: "notes",
        key: "notess",
        sorter: false,
        className: "col_style_quarry",
        render: (notes) => {
          return <span className="col_style_quarry">{notes}</span>;
        },
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "",
        sorter: false,
        className: "col_style_quarry",
        width: "10%",
        render: (record) => {
          return (
            <Col>
              <Popover
                placement="left"
                content={
                  <Row className="popovergrid" style={{ maxWidth: "90px" }}>
                    <Col span={24}>
                      <Button
                        className="btn_edit"
                        onClick={() => this.editNotes(record)}
                      >
                        <span className="span_edit">
                          <MdModeEditOutline
                            style={{
                              marginRight: "5px",
                            }}
                          />
                          Edit
                        </span>
                      </Button>
                    </Col>
                    <Col span={24}>
                      <Button className="btn_edit">
                        <Popconfirm
                          title="Are you sure？"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => this.deleteNotes(record?.id)}
                        >
                          <span className="span_edit">
                            <MdDelete
                              style={{
                                marginRight: "5px",
                              }}
                            />
                            Delete
                          </span>
                        </Popconfirm>
                      </Button>
                    </Col>
                  </Row>
                }
              >
                <EllipsisOutlined
                  style={{
                    fontSize: "30px",
                    color: "grey",
                  }}
                  className="action-ellipsis-button"
                />
              </Popover>
            </Col>
          );
        },
      },
    ];

    const materialDoc = [
      {
        title: "Document Name",
        dataIndex: "documentType",
        key: "documentType",
        sorter: false,
        width: "80%",
        className: "col_style_quarry",
        render: (documentType) => {
          return (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "fit-content",
              }}
            >
              {documentType}
            </Row>
          );
        },
      },
      {
        title: "Attachment",
        dataIndex: "docAttachment",
        key: "docAttachment",
        sorter: false,
        width: "20%",
        className: "col_style_quarry",
        render: (docAttachment) => {
          return (
            <span className="col_style_quarry" style={{ display: "flex" }}>
              {docAttachment !== undefined &&
                docAttachment !== "" &&
                docAttachment !== null &&
                docAttachment.map((e) => (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.fileDownload(
                        e.quoteAttachmentId,
                        "Attachment",
                        "docAttachment"
                      );
                    }}
                  >
                    <Row
                      style={{
                        color: "#7B8190",
                        fontSize: "13px",
                        // display: "flex",
                        // justifyContent: "center",
                        // alignContent: "center",
                        // flexDirection: "coloumn",
                        // width: "75px",
                      }}
                    >
                      <HiOutlineDocumentArrowDown
                        style={{
                          fontSize: "30px",
                          height: "30px",
                          marginRight: "5px",
                        }}
                      />
                    </Row>
                  </span>
                ))}
            </span>
          );
        },
      },
    ];
    return (
      <>
        <Loading enableLoading={loading || loadingDownload} />
        <Row
          style={{
            backgroundColor: "#fafafa",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "60px",
            width: "100%",
          }}
        >
          <Row onClick={this.onClickReturn} style={{ cursor: "pointer" }}>
            <BsArrowLeftShort
              style={{ color: "#5f6c74", fontSize: "23px" }}
              className="back-button-return-icon"
            />
            <p
              style={{ color: "#808080", fontSize: "16px", fontWeight: "600" }}
              className="back-button-return"
            >
              Return
            </p>
          </Row>
        </Row>
        <div
          style={{
            background: "#fafafa",
            minHeight: "75vh",
          }}
        >
          {errorLoading ? (
            <Col
              xxl={{ span: 24 }}
              xl={{ span: 24 }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <ErrorView
                refreshLeadDetails={this.onRefresh}
                type="LeadDetails"
              />
            </Col>
          ) : (
            <Row style={{ marginBottom: "1vw" }} gutter={[12, 0]}>
              <Col
                xxl={{ span: 8 }}
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                data-aos="fade-right"
              >
                <Card>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "-12px",
                        right: "0px",
                        border: "0.5px solid #aaa8a8",
                        width: "2vw",
                        height: "2vw",
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        alignContent: "center",
                        justifyContent: " center",
                        padding: "8px",
                        borderRadius: "100%",
                      }}
                      className="edit-details-button"
                    >
                      <Button
                        style={{
                          border: "none",
                          cursor: "pointer",
                          // marginTop: "-90px",
                          backgroundColor: "transparent",
                        }}
                        onClick={() =>
                          this.setState({
                            isCompanyModalVisible: true,
                            selectedAddress: companyData?.address,
                            latitude: companyData?.lat,
                            longitude: companyData?.lng,
                            payment_discount: companyData?.payment_discount,
                          })
                        }
                      >
                        <EditOutlined
                          style={{
                            color: "#586370",
                            fontSize: "100%",
                            fontWeight: "600",
                          }}
                        />
                      </Button>
                    </div>
                    <p
                      className="detail-heading table-font-mobile"
                      style={{ fontSize: "16px", color: "#5f6c74" }}
                    >
                      Carrier Account
                    </p>
                    <div className="linkcompany">
                      <p
                        className="detail-ans"
                        style={{
                          fontSize: "20px",
                          marginTop: "10px",
                          textTransform: "capitalize",
                          marginBottom: "5px",
                          color: "#0d0f10",
                          fontWeight: "800",
                        }}
                      >
                        {companyData?.carrier_name}
                      </p>
                    </div>
                  </div>
                </Card>

                <Card style={{ marginTop: "25px" }}>
                  <Row>
                    <Col span={24}>
                      <p
                        span={24}
                        className="detail-ans"
                        style={{
                          fontWeight: "600",
                          fontSize: "18px",
                          borderBottom: "1px solid #afafaf",
                          width: "fit-content",
                        }}
                      >
                        Carrier Details
                      </p>
                      <Row
                        xxl={{ span: 24 }}
                        xl={{ span: 24 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                        style={{ marginBottom: "5px" }}
                      >
                        <Col
                          xxl={{ span: 12 }}
                          xl={{ span: 12 }}
                          lg={{ span: 12 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                          className="detail-heading table-font-mobile"
                          style={{ fontSize: "15px", marginBottom: "5px" }}
                        >
                          USDOT
                        </Col>
                        <Col
                          xxl={{ span: 12 }}
                          xl={{ span: 12 }}
                          lg={{ span: 12 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                          className="detail-ans"
                        >
                          {companyData?.usdot && companyData?.usdot}
                        </Col>
                      </Row>
                      <Row
                        xxl={{ span: 24 }}
                        xl={{ span: 24 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                        style={{
                          marginBottom: "5px",
                          marginTop: "5px",
                        }}
                      >
                        <Col
                          xxl={{ span: 12 }}
                          xl={{ span: 12 }}
                          lg={{ span: 12 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                          className="detail-heading table-font-mobile"
                          style={{ fontSize: "16px" }}
                        >
                          Mailing Address
                        </Col>
                        <Col span={12} className="detail-ans">
                          {companyData?.address && companyData?.address}
                        </Col>
                      </Row>
                      <Row span={24} style={{ marginBottom: "5px" }}>
                        <Col
                          span={12}
                          className="detail-heading table-font-mobile"
                          style={{ fontSize: "16px" }}
                        >
                          Email
                        </Col>
                        <Col span={12} className="detail-ans">
                          {companyData?.email
                            ? companyData?.email
                            : companyData?.contactDetails?.filter(
                                (e) => e?.isPrimaryContact
                              )[0]?.email}
                        </Col>
                      </Row>
                      <Row span={24} style={{ marginBottom: "5px" }}>
                        <Col
                          span={12}
                          className="detail-heading table-font-mobile"
                          style={{ fontSize: "16px" }}
                        >
                          Phone
                        </Col>
                        <Col span={12} className="detail-ans">
                          {companyData?.phone
                            ? companyData?.phone
                            : companyData?.contactDetails?.filter(
                                (e) => e?.isPrimaryContact
                              )[0]?.contact_number}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <p
                        span={24}
                        className="detail-ans"
                        style={{
                          fontWeight: "600",
                          fontSize: "18px",
                          borderBottom: "1px solid #afafaf",
                          width: "fit-content",
                        }}
                      >
                        Account Details
                      </p>
                      <Row
                        xxl={{ span: 24 }}
                        xl={{ span: 24 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                        style={{ marginBottom: "5px" }}
                      ></Row>
                      <Row span={24} style={{ marginBottom: "5px" }}>
                        <Col
                          span={12}
                          className="detail-heading table-font-mobile"
                          style={{ fontSize: "16px" }}
                        >
                          Payment Terms
                        </Col>
                        <Col span={12} className="detail-ans">
                          {companyData?.payment_terms +
                            " - " +
                            companyData?.payment_discount}
                        </Col>
                      </Row>
                      <Row span={24} style={{ marginBottom: "5px" }}>
                        <Col
                          span={12}
                          className="detail-heading table-font-mobile"
                          style={{ fontSize: "16px" }}
                        >
                          Paid By
                        </Col>
                        <Col span={12} className="detail-ans">
                          {companyData?.direct_deposit !== undefined &&
                          companyData?.direct_deposit !== "" &&
                          companyData?.direct_deposit === "Direct Deposit"
                            ? "DD"
                            : companyData?.direct_deposit}
                        </Col>
                      </Row>
                      <Row span={24} style={{ marginBottom: "5px" }}>
                        <Col
                          span={12}
                          className="detail-heading table-font-mobile"
                          style={{ fontSize: "16px" }}
                        >
                          Insurance Expiration
                        </Col>
                        <Col span={12} className="detail-ans">
                          {companyData?.insurance_expiry !== "" &&
                            companyData?.insurance_expiry}
                        </Col>
                      </Row>
                      <Row span={24} style={{ marginBottom: "5px" }}>
                        <Col
                          span={12}
                          className="detail-heading table-font-mobile"
                          style={{ fontSize: "16px" }}
                        >
                          Registration Date
                        </Col>
                        <Col span={12} className="detail-ans">
                          {companyData?.registered_since
                            ? companyData?.registered_since
                            : ""}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <p
                        span={24}
                        className="detail-ans"
                        style={{
                          fontWeight: "600",
                          fontSize: "18px",
                          borderBottom: "1px solid #afafaf",
                          width: "fit-content",
                        }}
                      >
                        Carrier statistics
                      </p>
                      <Row
                        xxl={{ span: 24 }}
                        xl={{ span: 24 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                        style={{ marginBottom: "5px" }}
                      ></Row>

                      <Row span={24} style={{ marginBottom: "5px" }}>
                        <Col
                          span={12}
                          className="detail-heading table-font-mobile"
                          style={{ fontSize: "16px" }}
                        >
                          Lifetime Revenue
                        </Col>
                        <Col span={12} className="detail-ans">
                          {`$ ${revenue?.toLocaleString("us-EN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`}
                        </Col>
                      </Row>

                      <Row span={24} style={{ marginBottom: "5px" }}>
                        <Col
                          span={12}
                          className="detail-heading table-font-mobile"
                          style={{ fontSize: "16px" }}
                        >
                          Lifetime Payments
                        </Col>
                        <Col span={12} className="detail-ans">
                          {`$ ${payments?.toLocaleString("us-EN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`}
                        </Col>
                      </Row>

                      <Row span={24} style={{ marginBottom: "5px" }}>
                        <Col
                          span={12}
                          className="detail-heading table-font-mobile"
                          style={{ fontSize: "16px" }}
                        >
                          Load Effciency
                        </Col>
                        <Col span={12} className="detail-ans"></Col>
                      </Row>
                      <Row span={24} style={{ marginBottom: "5px" }}>
                        <Col
                          span={12}
                          className="detail-heading table-font-mobile"
                          style={{ fontSize: "16px" }}
                        >
                          Route Effciency
                        </Col>
                        <Col span={12} className="detail-ans"></Col>
                      </Row>
                      <Row span={24} style={{ marginBottom: "5px" }}>
                        <Col
                          span={12}
                          className="detail-heading table-font-mobile"
                          style={{ fontSize: "16px" }}
                        >
                          Average Start Time
                        </Col>
                        <Col span={12} className="detail-ans"></Col>
                      </Row>
                    </Col>
                  </Row>

                  {/* <div>
                    <p                       className="detail-heading table-font-mobile"
 style={{ fontSize: "16px" }}>
                      Notes
                    </p>
                    <div
                      style={{
                        minHeight: "65px",
                        borderRadius: "3px",
                        backgroundColor: "#e3e7ea",
                        padding: "5px",
                      }}
                      className="detail-ans"
                    >
                      {companyData?.notes && companyData?.notes}
                    </div>
                  </div> */}
                </Card>
              </Col>
              {!loading &&
                (companyData?.address ? (
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    lg={{ span: 16, offset: 0 }}
                    xl={{ span: 16, offset: 0 }}
                    xxl={{ span: 16, offset: 0 }}
                    style={{
                      width: "100%",
                      height: "100%",
                      // marginTop: "25px",
                      // padding: "0px",
                    }}
                  >
                    <div className="companydetail_map">
                      <MapComponent
                        filteredMapData={[companyData]}
                        ownerProfile
                        disableScroll
                      />
                    </div>
                  </Col>
                ) : (
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    lg={{ span: 16, offset: 0 }}
                    xl={{ span: 16, offset: 0 }}
                    xxl={{ span: 16, offset: 0 }}
                    style={{
                      width: "100%",
                      height: "100%",
                      // marginTop: "25px",
                      // padding: "0px",
                    }}
                  >
                    <div className="companydetail_mapempty">
                      {/* {companyData?.carrierDetails[0].parking_location ? (
                        <MapComponent
                          filteredMapData={[companyData]}
                          showTrucksData
                        />
                      ) : ( */}
                      <MapComponent disableScroll />
                      {/* )} */}
                      {/* <Mapnotfound /> */}
                    </div>
                  </Col>
                ))}
              <Col
                xxl={{ span: 24, offset: 0 }}
                xl={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
                // span={15}
                // offset={1}
                data-aos="fade-left"
              >
                <Card
                  className="card_virtual"
                  bodyStyle={{ padding: "0px" }}
                  style={{ background: "#eff1f3", marginTop: "25px" }}
                >
                  <Tabs
                    defaultActiveKey="1"
                    className="ant-card companydetailtab"
                    style={{ borderRadius: "5px" }}
                    onChange={(e) => {
                      this.setState({
                        keyValue: e,
                      });
                    }}
                    tabBarExtraContent={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {this.state?.keyValue === "1" && (
                          <Select
                            defaultValue="All Time"
                            style={{ width: "120px", marginRight: "10px" }}
                            onChange={(e) => {
                              if (e === "90Days") {
                                this.setState({ DaysSelected: true });
                              } else {
                                this.setState({ DaysSelected: false });
                              }
                            }}
                          >
                            {allTime?.map((e) => (
                              <Select.Option key={e.value} value={e.value}>
                                {e.label}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                        <Button
                          type="primary"
                          htmlType="submit"
                          icon={
                            this.state?.keyValue === "3" ? (
                              <IoFilterSharp
                                style={{
                                  fontSize: "15px",
                                  marginRight: "12px",
                                  marginBottom: "-2px",
                                }}
                              />
                            ) : (
                              <PlusOutlined />
                            )
                          }
                          style={{
                            height: "35px",
                            borderRadius: "5px",
                            marginRight: "10px",
                          }}
                          onClick={() => this.showModal(this.state?.keyValue)}
                        >
                          {this.state?.keyValue === "1"
                            ? "Equipment"
                            : this.state?.keyValue === "2"
                            ? "Contact"
                            : this.state?.keyValue === "3"
                            ? "Filter"
                            : this.state.keyValue === "4"
                            ? "Document"
                            : "Note"}
                        </Button>
                      </div>
                    }
                  >
                    <TabPane tab={<span>Equipment</span>} key="1">
                      <BaseTable
                        className="invoices_tableclass table_laptopscreen"
                        columnsData={equipmentColumns}
                        source={companyData?.carrierDetails?.sort(
                          (a, b) => a.truckId - b.truckId
                        )}
                        total={companyData?.carrierDetails?.length}
                        handleTableChage={(e) =>
                          this.handleTableChage(e, "TNBSDetails")
                        }
                        sizeRange={
                          window.screen.width > 1023
                            ? JSON.parse(localStorage.getItem("tableSizeRange"))
                                ?.TNBSDetails
                            : companyData?.carrierDetails?.length
                        }
                        pagination={window.screen.width > 1023 ? true : false}
                      />
                    </TabPane>
                    <TabPane tab={<span>Contacts</span>} key="2">
                      <BaseTable
                        className="contacts_tableclass table_laptopscreen"
                        rowKey={(record) => record?.id}
                        columnsData={contactsColumns}
                        source={companyData?.contactDetails}
                        total={companyData?.contactDetails?.length}
                        handleTableChage={(e) =>
                          this.handleTableChage(e, "TNBSContactDetails")
                        }
                        sizeRange={
                          window.screen.width > 1023
                            ? JSON.parse(localStorage.getItem("tableSizeRange"))
                                ?.TNBSContactDetails
                            : companyData?.contactDetails?.length
                        }
                        pagination={window.screen.width > 1023 ? true : false}
                      />
                    </TabPane>
                    <TabPane tab={<span>Invoices</span>} key="3">
                      <BaseTable
                        className="invoices_tableclass table_laptopscreen"
                        columnsData={invoicesColumns}
                        source={filteredInvoicesData?.filter(
                          (e) => e?.isTruckingJob
                        )}
                        total={
                          filteredInvoicesData?.filter((e) => e?.isTruckingJob)
                            ?.length
                        }
                        handleTableChage={(e) =>
                          this.handleTableChage(e, "TNBSInvoiceDetails")
                        }
                        sizeRange={
                          window.screen.width > 1023
                            ? JSON.parse(localStorage.getItem("tableSizeRange"))
                                ?.TNBSInvoiceDetails
                            : filteredInvoicesData?.filter(
                                (e) => e?.isTruckingJob
                              )?.length
                        }
                        pagination={window.screen.width > 1023 ? true : false}
                      />
                    </TabPane>
                    <TabPane tab={<span>Documents</span>} key="4">
                      <BaseTable
                        className="invoices_tableclass table_laptopscreen"
                        columnsData={docuColumn}
                        source={companyData?.documentsDetails?.sort((a, b) =>
                          a.createdAt < b.createdAt ? 1 : -1
                        )}
                        total={companyData?.documentsDetails?.length}
                        handleTableChage={(e) =>
                          this.handleTableChage(e, "TNBSDocDetails")
                        }
                        sizeRange={
                          window.screen.width > 1023
                            ? JSON.parse(localStorage.getItem("tableSizeRange"))
                                ?.TNBSDocDetails
                            : companyData?.documentsDetails?.length
                        }
                        pagination={window.screen.width > 1023 ? true : false}
                      />
                    </TabPane>
                    <TabPane tab={<span>Notes</span>} key="5">
                      <BaseTable
                        className="invoices_tableclass"
                        columnsData={notesColumns}
                        source={companyData?.notesDetails?.sort((a, b) =>
                          a.createdAt > b.createdAt ? 1 : -1
                        )}
                        total={companyData?.notesDetails?.length}
                        handleTableChage={(e) =>
                          this.handleTableChage(e, "TNBSNotesDetails")
                        }
                        sizeRange={
                          window.screen.width > 1023
                            ? JSON.parse(localStorage.getItem("tableSizeRange"))
                                ?.TNBSNotesDetails
                            : companyData?.notesDetails?.length
                        }
                        pagination={window.screen.width > 1023 ? true : false}
                      />
                    </TabPane>
                  </Tabs>
                </Card>
              </Col>
            </Row>
          )}

          {visible && (
            <Filter
              visible={visible}
              closeFilter={this.closeFilter}
              filterResultData={this.filterResultData}
              ownerOperatorProfile
              onClickClearFilter={this.onClickClearFilter}
              invoicesData={companyData?.carrierDetails}
              checkData={checkData}
            />
          )}

          {isCompanyModalVisible && (
            <BaseModal
              title={"Update Carrier Details"}
              onCancel={() => {
                this.setState({
                  isCompanyModalVisible: false,
                  selectedPaymentType: "",
                });
              }}
              formId="CompanyDetails"
              className="antmodal_grid headermodal"
              width="600px"
              loading={loading}
            >
              <Form
                onFinish={this.onFinishCompanyDetails}
                id="CompanyDetails"
                ref={this.formRef}
              >
                <Row gutter={[32, 0]}>
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={11}
                  >
                    <span className="title_changes">Carrier Name</span>
                    <Form.Item
                      initialValue={companyData?.carrier_name}
                      name="carrier_name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Carrier Name",
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) =>
                          this.setState({ carrierName: e.target.value })
                        }
                        className="form-input"
                        placeholder="Please enter Carrier Name"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">USDOT</span>
                    <Form.Item
                      name="usdot"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter USDOT!",
                        },
                      ]}
                      initialValue={companyData?.usdot}
                    >
                      <NumberFormat
                        className="ant-input-number numberictest"
                        format="##########"
                        style={{ width: "100%" }}
                        placeholder="Please Enter USDOT"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={11}
                  >
                    <span className="title_changes">
                      Insurance Expiration Date
                    </span>
                    <Form.Item
                      name="insurance_expiry"
                      rules={[
                        {
                          required: true,
                          message: "Select Insurance Exipiry Date",
                        },
                      ]}
                      initialValue={
                        companyData?.insurance_expiry &&
                        companyData?.insurance_expiry !== "" &&
                        companyData?.insurance_expiry !== undefined
                          ? moment(companyData?.insurance_expiry)
                          : ""
                      }
                    >
                      <DatePicker
                        size="large"
                        format={"MM/DD/YYYY"}
                        // className="ant-input-number numberictest"
                        style={{ width: "100%", borderRadius: "5px" }}
                        placeholder="Please Select A Date"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={11}
                  >
                    <span className="title_changes">Registered Since</span>
                    <Form.Item
                      name="registeredSince"
                      rules={[
                        {
                          required: true,
                          message: "Select Registered Since",
                        },
                      ]}
                      initialValue={
                        companyData?.registered_since &&
                        moment(companyData?.registered_since)
                      }
                    >
                      <DatePicker
                        size="large"
                        format={"MM/DD/YYYY"}
                        // className="ant-input-number numberictest"
                        style={{ width: "100%", borderRadius: "5px" }}
                        placeholder="Please Select A Date"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Payment Terms</span>
                    <Form.Item
                      name="payment_terms"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Required!",
                        },
                      ]}
                      initialValue={companyData?.payment_terms}
                    >
                      <Select
                        allowClear
                        className="select_Height"
                        style={{ height: "40px", fontWeight: "300" }}
                        placeholder="Please Select Payment Type"
                        onChange={this.onChangeTerms}
                      >
                        {paymentTerms?.map((e) => (
                          <Option
                            key={e.payment_discount}
                            value={e.payment_terms}
                          >
                            {e.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  {selectedPaymentType === "Custom Input" && (
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      // span={12}
                    >
                      <span className="title_changes">
                        Payment Terms (Others)
                      </span>
                      <Form.Item
                        name="paymentTermsOthers"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter New Payment Terms",
                          },
                        ]}
                      >
                        <NumberFormat
                          className="ant-input-number numberictest"
                          style={{ width: "100%" }}
                          prefix={"Net "}
                          placeholder="Please Enter New Payment Terms"
                        />
                      </Form.Item>
                    </Col>
                  )}
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Deposit Method</span>
                    <Form.Item
                      name="direct_deposit"
                      // onChange={this.getparking_location}
                      rules={[
                        {
                          required: true,
                          errorMessage: true,
                          message: "Please Enter Your Direct Deposit!",
                        },
                      ]}
                      initialValue={companyData?.direct_deposit}
                    >
                      <Radio.Group
                        defaultValue={companyData?.direct_deposit}
                        // onChange={(e) =>
                        //   this.setState({ supplier: e?.target?.value })
                        // }
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "left",
                          // flexWrap: "wrap",
                          // maxWidth:"100%",
                          border: "1px solid #e5e5e5",
                          height: "40px",
                          borderRadius: "5px",
                          //  marginTop: "5px",
                          padding: "5px",
                        }}
                      >
                        <Radio
                          style={{ fontSize: "16px" }}
                          value={"Direct Deposit"}
                        >
                          Direct Deposit
                        </Radio>
                        <Radio style={{ fontSize: "16px" }} value={"Check"}>
                          Check
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Office Phone</span>
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Office Phone!",
                        },
                      ]}
                      initialValue={companyData?.phone}
                    >
                      <NumberFormat
                        className="ant-input-number numberictest"
                        format="(###) ###-####"
                        style={{ width: "100%" }}
                        placeholder="Please Enter Office Phone"
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    span={selectedPaymentType === "Custom Input" ? 24 : 12}
                  >
                    <span className="title_changes">Office Email</span>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Office Email!",
                        },
                        {
                          type: "email",
                          message: "The Input Is Not Valid Email",
                        },
                      ]}
                      initialValue={companyData?.email}
                    >
                      <Input
                        className="form-input"
                        placeholder="Please Enter Office Email"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={11}
                  >
                    <span className="title_changes">Office Address</span>
                    <Form.Item
                      name="address"
                      rules={[
                        {
                          required: false,
                          message: "Please enter your address",
                        },
                      ]}
                    >
                      <GooglePlacesAutocomplete
                        className="form-input"
                        // apiKey={process.env.REACT_APP_MAPS_KEY}
                        autocompletionRequest={{
                          componentRestrictions: {
                            country: ["us"],
                          },
                        }}
                        selectProps={{
                          defaultInputValue: `${
                            companyData?.address ? companyData?.address : ""
                          }`,
                          citySearch,
                          placeholder: "Search Address...",
                          onChange: (address) => {
                            this.handleSelect(address);
                            this.getAddress(address);
                          },
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Notes</span>
                    <Form.Item
                      name="notes"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Notes!",
                        },
                      ]}
                      initialValue={companyData?.notes}
                    >
                      <TextArea
                        rows={4}
                        style={{
                          width: "100%",
                          height: "75px",
                          borderColor: "#d6d6d6",
                        }}
                        className="form-input"
                        placeholder="Please Enter Your Notes"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </BaseModal>
          )}

          {addTruck && (
            <BaseModal
              className="crmform_modal antmodal_grid headermodal"
              title={
                recordTruckData !== "" ? "Update Equipment" : "Add Equipment"
              }
              onCancel={this.onCancelTruck}
              loading={loading}
              visible={addTruck}
              destroyOnClose={true}
              formId="truck"
              width={
                window.screen.width > "501" && window.screen.width <= "991"
                  ? "740px"
                  : "740px"
              }
            >
              <Form
                style={{ width: "100%" }}
                onFinish={this.onFinishTruckDetails}
                id="truck"
                ref={this.formRef}
              >
                <Row gutter={[24, 8]}>
                  {/* {recordTruckData !== "" && ( */}
                  <Col
                    xxl={{ span: 8 }}
                    xl={{ span: 8 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Primary Truck#</span>
                    <Form.Item
                      name={"truckId"}
                      rules={[
                        {
                          required: true,
                          message: "Enter Primary Truck#",
                        },
                      ]}
                      initialValue={primaryTruck}
                    >
                      <InputNumber
                        min={1}
                        style={{
                          width: "100%",
                        }}
                        placeholder="Enter Primary Truck#"
                        onChange={(e) => {
                          this.setState({
                            primaryTruck: e,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {/* )} */}
                  <Col
                    xxl={{ span: 8 }}
                    xl={{ span: 8 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Secondary Truck#</span>

                    <div
                      className="tags-wrapper"
                      onClick={this.handleClickWrapper}
                    >
                      <Form.Item>
                        <Input
                          ref={this.inputRef}
                          value={this.state.input}
                          className="form-input"
                          type={"number"}
                          onChange={this.handleOnInputChange}
                          onKeyDown={this.handleKeyDown}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col
                    xxl={{ span: 8 }}
                    xl={{ span: 8 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Truck Type</span>
                    <Form.Item
                      name={"truck_type"}
                      rules={[
                        {
                          required: true,
                          message: "Please Select Truck Type",
                        },
                      ]}
                      style={{
                        marginBottom: "5px",
                      }}
                      initialValue={recordTruckData?.truck_type}
                    >
                      <Select
                        allowClear
                        className="select_Height"
                        style={{
                          height: "40px",
                          fontWeight: "300",
                        }}
                        placeholder="Select Truck Type"
                        onChange={(e, option) => {
                          this.setState({
                            selectedTruckCode: option?.key,
                          });
                        }}
                        defaultValue={recordTruckData?.truck_type}
                      >
                        {trucksData?.map((t) => (
                          <Option value={t.value} key={t.truck_code}>
                            {t.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  {secondTruckNo?.length > 0 && (
                    <Col span={24} style={{ marginTop: "-20px" }}>
                      <span
                        className="title_changes"
                        onClick={this.onClearResult}
                        style={{
                          color: "red",
                          float: "right",
                          cursor: "pointer",
                        }}
                      >
                        Clear All
                      </span>
                      <Card
                        style={{
                          overflowY: "scroll",
                          height: "5vw",
                          width: "100%",
                        }}
                        bodyStyle={{
                          padding: "5px",
                        }}
                      >
                        <div className="colltable-option final-address-card">
                          {secondTruckNo?.map((v, index) => {
                            return (
                              <div
                                title={v}
                                style={{ padding: "3px 8px" }}
                                className="final-address"
                              >
                                <div
                                  style={{
                                    border: "1px solid",
                                    padding: "3px",
                                    borderRadius: "3px",
                                  }}
                                  className="invalid-tag"
                                >
                                  <span>{v}</span>
                                  <CloseOutlined
                                    className="tag-clear"
                                    style={{ color: "red" }}
                                    onClick={() => this.handleDelete(v, index)}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </Card>
                    </Col>
                  )}

                  {/* {recordTruckData === "" && (
                    <Col
                      xxl={{ span: 8 }}
                      xl={{ span: 8 }}
                      lg={{ span: 9 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Empty Weight</span>
                      <Form.Item
                        name={"empty_weight"}
                        rules={[
                          {
                            required: false,
                            message: "Enter Empty Weight",
                          },
                        ]}
                        initialValue={recordTruckData?.empty_weight}
                      >
                        <InputNumber
                          min={1}
                          addonAfter={"Ton"}
                          style={{
                            width: "100%",
                          }}
                          placeholder="Enter Empty Weight"
                        />
                      </Form.Item>
                    </Col>
                  )} */}

                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={11}
                  >
                    <span className="title_changes">Parking Location</span>
                    <Form.Item
                      name="address"
                      rules={[
                        {
                          required: false,
                          message: "Please enter your address",
                        },
                      ]}
                    >
                      <GooglePlacesAutocomplete
                        className="form-input"
                        // apiKey={process.env.REACT_APP_MAPS_KEY}
                        autocompletionRequest={{
                          componentRestrictions: {
                            country: ["us"],
                          },
                        }}
                        selectProps={{
                          defaultInputValue: `${
                            recordTruckData?.parking_location
                              ? recordTruckData?.parking_location
                              : companyData?.address
                              ? companyData?.address
                              : ""
                          }`,
                          citySearch,
                          placeholder: "Search Address...",
                          onChange: (address) => {
                            this.handleSelect(address);
                            this.getAddress(address);
                          },
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {/* {recordTruckData !== "" && ( */}
                  <Col
                    xxl={{ span: 8 }}
                    xl={{ span: 8 }}
                    lg={{ span: 9 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Empty Weight</span>
                    <Form.Item
                      name={"empty_weight"}
                      rules={[
                        {
                          required: false,
                          message: "Enter Empty Weight",
                        },
                      ]}
                      initialValue={recordTruckData?.empty_weight}
                    >
                      <InputNumber
                        min={1}
                        addonAfter={"Ton"}
                        style={{
                          width: "100%",
                        }}
                        placeholder="Enter Empty Weight"
                      />
                    </Form.Item>
                  </Col>
                  {/* )} */}
                  <Col
                    xxl={{ span: 8 }}
                    xl={{ span: 8 }}
                    lg={{ span: 8 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Overweight Permit</span>
                    <Form.Item
                      name="overWeightPermit"
                      rules={[
                        {
                          required: false,
                          message: "Please Select Required!",
                        },
                      ]}
                      initialValue={recordTruckData?.overWeightPermit}
                    >
                      <Radio.Group
                        defaultValue={recordTruckData?.overWeightPermit}
                        onChange={this.onChangeOverWeight}
                        style={{
                          // marginTop: "5px",
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-around",
                          // flexWrap: "wrap",
                          // maxWidth:"100%",
                          padding: "8px",
                          border: "1px solid #e5e5e5",
                          height: "40px",
                          borderRadius: "5px",
                        }}
                      >
                        <Radio
                          style={{ fontSize: "16px", whiteSpace: "nowrap" }}
                          value={"Yes"}
                        >
                          Yes
                        </Radio>
                        <Radio
                          style={{ fontSize: "16px", whiteSpace: "nowrap" }}
                          value={"No"}
                        >
                          No
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 8 }}
                    xl={{ span: 8 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Steel Trailer</span>
                    <Form.Item
                      name={"steelTrailer"}
                      rules={[
                        {
                          required: true,
                          message: "Please Select Steel Trailer",
                        },
                      ]}
                      style={{
                        marginBottom: "5px",
                      }}
                      initialValue={recordTruckData?.steelTrailer || "Unknown"}
                    >
                      <Select
                        allowClear
                        className="select_Height"
                        style={{
                          height: "40px",
                          fontWeight: "300",
                        }}
                        placeholder="Select Steel Trailer"
                      >
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                        <Option value="Unknown">Unknown</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Dispatcher</span>
                    <Form.Item
                      name={"dispatcher"}
                      rules={[
                        {
                          required: true,
                          message: "Please Select Dispatcher",
                        },
                      ]}
                      style={{
                        marginBottom: "5px",
                      }}
                      initialValue={
                        recordTruckData?.dispatcher ||
                        this.getDefaultValue(companyData?.contactDetails)
                          ?.contact_name
                      }
                    >
                      <AutoComplete
                        size="large"
                        allowClear
                        className="select_Height"
                        style={{ height: "auto", width: "100%" }}
                        placeholder="Select a Dispatcher"
                        onChange={(e, option) => {
                          this.setState({
                            dispatcherPhone: option?.label,
                          });
                        }}
                      >
                        {getOptions(companyData?.contactDetails)}
                      </AutoComplete>
                    </Form.Item>
                    {!isContactVisible && (
                      <Tooltip
                        title="Add Contact"
                        overlayStyle={{
                          backGroundColor: "#000",
                          color: "#000",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "20px",
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "auto",
                            padding: "8px",
                            position: "absolute",
                            top: "0px",
                            right: "0px",
                            height: "55%",
                            borderLeft: "1px solid #ddd",
                            zIndex: "+9",
                            cursor: "pointer",
                            marginRight: "12px",
                            marginTop: "27px",
                          }}
                          onClick={() =>
                            this.setState({
                              isContactVisible: true,
                              jobContactData: "",
                            })
                          }
                        >
                          <UsergroupAddOutlined />
                        </div>
                      </Tooltip>
                    )}
                  </Col>
                  {overWeightPermitState === "Yes" && (
                    <>
                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 6 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <span className="title_changes">Date Of Expiry</span>
                        <Form.Item
                          name={"overWeightExpiryDate"}
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Date Of Expiry",
                            },
                          ]}
                          initialValue={
                            recordTruckData?.overWeightExpiryDate
                              ? moment(recordTruckData?.overWeightExpiryDate)
                              : ""
                          }
                        >
                          <DatePicker
                            placeholder="Please Select A Date"
                            style={{
                              width: "100%",
                              height: "40px",
                              borderRadius: "5px",
                            }}
                            format={"MM/DD/YYYY"}
                            // defaultValue={moment()}
                            placement={"bottomRight"}
                            allowClear
                            bordered={true}
                            // onChange={this.onChangeYear}
                            disabledDate={(current) => {
                              let customDate = moment().format("MM-DD-YYYY");
                              return (
                                current &&
                                current < moment(customDate, "MM-DD-YYYY")
                              );
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 6 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                        // span={24}
                      >
                        <span className="title_changes">Attachment</span>
                        <Form.Item
                          name="docAttachment"
                          rules={[
                            {
                              required: true,
                              message: "Please Attach Your Document",
                            },
                          ]}
                          initialValue={recordTruckData?.docAttachmentId}
                        >
                          <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            style={{
                              borderRadius: "50%",
                              width: "100%",
                              height: "100%",
                            }}
                            showUploadList={false}
                            customRequest={dummyRequest}
                            onChange={(e) => this.handleUploadPic(e)}
                          >
                            <Spin spinning={imageLoading}>
                              {quoteAttachmentId ? (
                                <SlDoc
                                  style={{
                                    fontSize: "30px",
                                    height: "30px",
                                    marginRight: "5px",
                                  }}
                                />
                              ) : (
                                <CameraOutlined className="camera-icon" />
                              )}
                            </Spin>
                          </Upload>
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Row>
              </Form>
            </BaseModal>
          )}

          {isContactVisible && (
            <BaseModal
              title={"Add Contact"}
              onCancel={() => {
                this.setState({
                  isContactVisible: false,
                  isCheckBox: false,
                  newRole: "",
                  roleCheckBox: false,
                });
              }}
              loading={loading}
              formId="contact"
              width={
                window.screen.width > "501" && window.screen.width <= "991"
                  ? "80%"
                  : "520px"
              }
              className="antmodal_grid headermodal"
            >
              <Form
                style={{ width: "100%" }}
                onFinish={this.onFinishContact}
                id="contact"
              >
                <Row gutter={[12, 0]}>
                  <Col span={12}>
                    <span className="title_changes">First Name</span>
                    <Form.Item
                      name="fName"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Your First Name!",
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) =>
                          this.setState({ firstName: e.target.value })
                        }
                        className="form-input"
                        placeholder="Please Enter Your First Name"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <span className="title_changes">Last Name</span>
                    <Form.Item
                      name="lName"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter your Last Name!",
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) =>
                          this.setState({ lastName: e.target.value })
                        }
                        className="form-input"
                        placeholder="Please Enter your Last Name"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <span className="title_changes">Phone</span>
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Phone!",
                        },
                      ]}
                    >
                      <NumberFormat
                        onChange={(e) =>
                          this.setState({ phone: e.target.value })
                        }
                        className="ant-input-number numberictest"
                        format="(###) ###-####"
                        style={{ width: "100%", height: "40px" }}
                        placeholder="Please Enter Your Phone"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <span className="title_changes">Email</span>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Email!",
                        },
                        {
                          type: "email",
                          message: "The Input Is Not Valid Email",
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                        className="form-input"
                        placeholder="Please Enter Your Email"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Role</span>
                    <Form.Item
                      name="role"
                      rules={[
                        { required: false, message: "Please Enter Your Role!" },
                      ]}
                      initialValue="Dispatch"
                    >
                      <Input className="form-input" disabled />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <span className="title_changes">Notes</span>
                    <Form.Item
                      name="notes"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Notes!",
                        },
                      ]}
                    >
                      <TextArea
                        rows={4}
                        style={{
                          width: "100%",
                          height: "75px",
                          borderColor: "#d6d6d6",
                        }}
                        className="form-input"
                        placeholder="Please Enter Your Notes"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </BaseModal>
          )}
          {isContactModalVisible && (
            <BaseModal
              title={
                jobContactData !== "" ? "Update Contact" : "Add New Contact"
              }
              onCancel={() =>
                this.setState(
                  {
                    isContactModalVisible: false,
                    newRole: "",
                    roleCheckBox: false,
                    isCheckcontact: false,
                  },
                  () => this.onCancelContact()
                )
              }
              loading={loading}
              formId="contact"
              className="antmodal_grid headermodal"
              width={"25%"}
            >
              <Form onFinish={this.onFinishContact} id="contact">
                <Row gutter={[16, 0]}>
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">First Name</span>
                    <Form.Item
                      name="fName"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Your First Name!",
                        },
                      ]}
                      initialValue={
                        jobContactData?.contact_name
                          ? firstName
                          : jobContactData?.firstName
                      }
                    >
                      <Input
                        className="form-input"
                        placeholder="Please Enter Your First Name"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Last Name</span>
                    <Form.Item
                      name="lName"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter your Last Name!",
                        },
                      ]}
                      initialValue={
                        jobContactData?.contact_name
                          ? lastName
                          : jobContactData?.lastName
                      }
                    >
                      <Input
                        className="form-input"
                        placeholder="Please Enter your Last Name"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Phone</span>
                    <Form.Item
                      name="contact_number"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Phone!",
                        },
                      ]}
                      initialValue={jobContactData?.contact_number}
                    >
                      <NumberFormat
                        className="ant-input-number numberictest"
                        format="(###) ###-####"
                        style={{ width: "100%" }}
                        placeholder="Please Enter Your Phone"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Email</span>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Email!",
                        },
                        {
                          type: "email",
                          message: "The Input Is Not Valid Email",
                        },
                      ]}
                      initialValue={jobContactData?.email}
                    >
                      <Input
                        className="form-input"
                        placeholder="Please Enter Your Email"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Role</span>
                    <Form.Item
                      name="role"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Role!",
                        },
                      ]}
                      initialValue={
                        jobContactData?.contactRole &&
                        jobContactData?.contactRole
                      }
                    >
                      <Select
                        size="large"
                        showSearch
                        allowClear
                        className="select_Height"
                        // style={{ height: "40px" }}
                        placeholder="Please Select A Role"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onChange={(value) => {
                          this.setState({ newRole: value });
                          value === "Others" &&
                            message.info("Please Input Your Role");
                        }}
                      >
                        {ownerRoles?.map((role) => (
                          <Option key={role.id} value={role.label}>
                            {role.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  {newRole === "Add New Role" && (
                    <Col
                      xxl={{ span: 24 }}
                      xl={{ span: 24 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Add New Role</span>
                      <Form.Item name="roleOther">
                        <Input
                          className="form-input"
                          placeholder="Please Enter Role"
                        />
                      </Form.Item>
                      <Checkbox
                        checked={this.state.isCheckcontact}
                        onChange={(e) =>
                          this.setState({
                            isCheckcontact: e.target.checked,
                          })
                        }
                        style={{ marginBottom: "18px" }}
                      >
                        <span
                          className="checkBox_size"
                          style={{ fontSize: "14px" }}
                        >
                          Add this new role to default list
                        </span>
                      </Checkbox>
                    </Col>
                  )}
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Notes</span>
                    <Form.Item
                      name="notes"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Notes!",
                        },
                      ]}
                      initialValue={
                        jobContactData?.notes ? jobContactData?.notes : ""
                      }
                    >
                      <TextArea
                        rows={4}
                        style={{
                          width: "100%",
                          height: "75px",
                          borderColor: "#d6d6d6",
                        }}
                        className="form-input"
                        placeholder="Please Enter Your Notes"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </BaseModal>
          )}

          {isDocModalVisible && (
            <BaseModal
              title="Link document to:"
              visible={this.state.isDocModalVisible}
              onCancel={() => {
                this.setState({
                  isDocModalVisible: false,
                  quoteAttachment: "",
                  selectedAssignTo: "",
                });
              }}
              formId="addDocument"
              className="antmodal_grid headermodal"
              width={
                window.screen.width > "501" && window.screen.width <= "991"
                  ? "80%"
                  : "520px"
              }
              loading={loading}
              disabled={imageLoading ? true : false}
            >
              <Form onFinish={this.onFinishDoc} id="addDocument">
                <Row gutter={[32, 0]}>
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={12}
                  >
                    <span className="title_changes">Document Type</span>
                    <Form.Item
                      name="documentType"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Document Type",
                        },
                      ]}
                      initialValue={recordDocData?.documentType}
                    >
                      <Input
                        type="text"
                        className="form-input"
                        placeholder="Please Enter Document Type"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Trucks</span>
                    <Form.Item
                      name="truckId"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Trucks!",
                        },
                      ]}
                      initialValue={recordDocData?.truckId}
                    >
                      <Select
                        placeholder={"Please Select Any Truck Number"}
                        className="select_Height"
                        size="large"
                        style={{ borderRadius: "6px", border: "#949caa" }}
                        allowClear
                      >
                        {companyData?.carrierDetails?.map((m) => (
                          <Option key={m?.id} value={m?.truckId}>
                            {m?.truckId}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={24}
                  >
                    <span className="title_changes">Description</span>
                    <Form.Item
                      name="description"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Description",
                        },
                      ]}
                      initialValue={recordDocData?.description}
                    >
                      <TextArea
                        rows={4}
                        style={{
                          width: "100%",
                        }}
                        className="form-input"
                        placeholder="Please Enter Your Description"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={24}
                  >
                    <span className="title_changes">Attachment</span>
                    <Form.Item
                      name="docAttachment"
                      rules={[
                        {
                          required: false,
                          message: "Please Attach Your Document",
                        },
                      ]}
                    >
                      <Spin spinning={imageLoading}>
                        <Dragger
                          onChange={this.uploadTicketPdf}
                          customRequest={dummyRequest}
                          listType="picture"
                          // fileList={recordDocData?.docAttachment}
                          multiple={true}
                          className="drag-ticket"
                          // beforeUpload={this.handleUploadType}
                          // maxCount={1}
                          defaultFileList={recordDocData?.docAttachment}
                        >
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                        </Dragger>
                      </Spin>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </BaseModal>
          )}

          {notesPopup && (
            <BaseModal
              title={recordNotesData !== "" ? "Update Notes" : "Add New Notes"}
              onCancel={() => {
                this.setState({
                  notesPopup: false,
                });
              }}
              visible={notesPopup}
              destroyOnClose={true}
              formId="addNotes"
              loading={loading}
              className="antmodal_grid headermodal"
              width={"25%"}
            >
              <Form
                onFinish={this.addNotes}
                initialValues={{ remember: true }}
                id="addNotes"
              >
                <Row gutter={[24, 0]}>
                  <Col span={24}>
                    <span className="title_changes">Note Type</span>
                    <Form.Item
                      name="type"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Your Note!",
                        },
                      ]}
                      initialValue={
                        recordNotesData?.typeofNotes
                          ? recordNotesData?.typeofNotes
                          : ""
                      }
                    >
                      <Input
                        style={{
                          width: "100%",
                          borderColor: "#d6d6d6",
                        }}
                        className="form-input"
                        placeholder="Please Enter Your Type Of Notes"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <span className="title_changes">Note</span>
                    <Form.Item
                      name="notes"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Your Notes!",
                        },
                      ]}
                      initialValue={
                        recordNotesData?.notes ? recordNotesData?.notes : ""
                      }
                    >
                      <TextArea
                        rows={4}
                        style={{
                          width: "100%",
                          height: "75px",
                          borderColor: "#d6d6d6",
                        }}
                        className="form-input"
                        placeholder="Please Enter Your Notes"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </BaseModal>
          )}

          {isDetailModal && (
            <BaseModal
              title={"Truck Attachments"}
              onCancel={() => {
                this.setState({
                  isDetailModal: false,
                });
              }}
              visible={isDetailModal}
              destroyOnClose={true}
              formId="addNotes"
              loading={loading}
              className="antmodal_grid headermodal"
              width={"25%"}
              footer={null}
            >
              <Table dataSource={docData} columns={materialDoc} />
            </BaseModal>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) =>
  // console.log(state),
  ({
    totalResult: state.reducer?.truckListResult,
    trucksListInfo: state?.reducer?.trucksListInfo,
    trucksInfo: state?.reducer?.trucksInfo,
    loading: state?.reducer?.truckListResult?.loading,
    errorLoading: state.reducer?.truckListResult?.error,
    totalTrucksListResult: state.reducer?.trucksListResult,
    companiesResult: state?.reducer?.ownerOperatorsData,
    dropDownOptions: state.reducer?.dropDownOptions,
    ownerRoles: state.reducer?.dropDownOptions?.totalData?.filter(
      (t) => t.typeofDropDown === "ownerOperatorRoles"
    ),
    trucksData: state.reducer?.dropDownOptions?.totalData?.filter(
      (t) => t.typeofDropDown === "trucksType"
    ),
    termsData: state.reducer?.dropDownOptions?.totalData?.filter(
      (t) => t.typeofDropDown === "carrierTerms"
    ),
    dropDownOptionsInfo: state.reducer?.dropDownOptionsInfo,
    docResult: state?.reducer?.docResult,
    ticketResult: state?.reducer?.ticketsResult,
  });

const mapDispatchToProps = (dispatch) => ({
  actiongetTNBSOwner: (id) => {
    dispatch(getTruckList(id));
  },
  actionUpdateTNBSOwner: (id, data) => {
    dispatch(updateTrucksList(id, data));
  },
  actionFetchTrucksList: () => {
    dispatch(getTrucksList());
  },
  actionFetchOwnerOperators: () => {
    dispatch(getOwnerOperators());
  },
  actionDeleteDoc: (id) => {
    dispatch(deleteDocument(id));
  },
  actiongetDropDownOptions: () => {
    dispatch(getDropDownOptions());
  },
  actionupdateDropDownOptions: (id, data) => {
    dispatch(updateDropDownOptions(id, data));
  },
  actionDeleteTrucksList: (id) => {
    dispatch(deleteTrucksList(id));
  },
  actionFetchGetTickets: () => {
    dispatch(getTickets());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAppContext(TwistedNailOwnerOperatorProfile));
