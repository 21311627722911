import { applyMiddleware, createStore, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import apiMiddleware from "../config/middleware";
import reducer from "./Reducers/index";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  combineReducers({ reducer }),
  composeEnhancers(applyMiddleware(apiMiddleware), applyMiddleware(thunk))
);

export default store;
