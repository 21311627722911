import { Row, Col } from "antd";
import {
  MdEmail,
  MdDelete,
  MdPrint,
  MdPhone,
  MdDoDisturb,
  MdAssignmentTurnedIn,
  MdOutlineFileCopy,
} from "react-icons/md";
import { IoChatboxEllipsesSharp } from "react-icons/io5";

const FooterData = (props) => {
  return (
    <div className="countingspan">
      <Row>
        <Col span={9}>
          {props?.invoice || props?.invoiceTicket ? (
            <Row>
              <Col span={6} className="counthashkey">
                {props?.count + " Selected"}
              </Col>
              <Col span={6} className="counthashkey">
                <MdPrint />
                Print
              </Col>
              {props?.invoiceTicket && (
                <Col span={6} className="counthashkey">
                  <MdOutlineFileCopy />
                  Invoice
                </Col>
              )}
            </Row>
          ) : (
            <Row>
              <Col span={6} className="counthashkey">
                {props?.count + " Selected"}
              </Col>
              <Col
                span={6}
                className="counthashkey"
                style={{ cursor: "pointer" }}
              >
                <IoChatboxEllipsesSharp />
                Send sms
              </Col>
              <Col
                span={6}
                className="counthashkey"
                style={{ cursor: "pointer" }}
              >
                <MdEmail />
                Send email
              </Col>
              {props?.call ? (
                <Col span={6} className="counthashkey">
                  <MdPhone />
                  Call
                </Col>
              ) : props?.print ? (
                <Col
                  span={6}
                  className="counthashkey"
                  style={{ cursor: "pointer" }}
                >
                  <MdPrint />
                  Print
                </Col>
              ) : props?.deactive ? (
                <Col span={6} className="counthashkey">
                  <MdDoDisturb />
                  Deactive
                </Col>
              ) : (
                <Col
                  span={6}
                  className="counthashkey"
                  style={{ cursor: "pointer" }}
                >
                  <MdAssignmentTurnedIn />
                  Assign
                </Col>
              )}
            </Row>
          )}
        </Col>
        <Col
          span={2}
          offset={13}
          className="counthashkey"
          style={{ cursor: "pointer" }}
        >
          <MdDelete /> Delete
        </Col>
      </Row>
    </div>
  );
};

export default FooterData;
