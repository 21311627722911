import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getAccountsPayable,
  getInvoices,
  updateInvoices,
} from "../../../Redux/Actions/actions";
import { Storage } from "aws-amplify";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import {
  Row,
  Col,
  Card,
  Input,
  DatePicker,
  Select,
  Popover,
  Button,
  Pagination,
  Divider,
  message,
  Collapse,
} from "antd";
import Filter from "../../../Common/Filter";
import ErrorView from "../../../Common/ErrorView";
import BaseTable from "../../../Common/BaseTable";
import Loading from "../../../Common/Loading";
import moment from "moment";
import {
  ArrowRightOutlined,
  DownloadOutlined,
  CheckOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { FaLayerGroup } from "react-icons/fa";
import { BsArrowLeftShort } from "react-icons/bs";
import { HiOutlineDocumentArrowDown } from "react-icons/hi2";
import Nodata from "../../nodata";
import ExcelExportHelper from "../../../Common/ExcelExportHelper";
class FinanceInvoiceManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: "",
      boardView: true,
      listView: false,
      selectionType: "",
      selectedRowKeys: [],
      selected: true,
      color: "active",
      visible: false,
      invoiceData: [],
      filterInvoiceData: [],
      filteredCustomers: [],
      fromDate: "",
      toDate: "",
      zipCode: "",
      invoiceNumber: "",
      groupedData: {},
      filterGroupedData: {},
      minValue: 0,
      maxValue: 5,
      currentPage: 1,
      perpageSize: 5,
      showFinace: false,
      jobInfoData: [],
      filterJobInfoData: [],
      selectedFiles: [],
      selectedStatus: [],
      // invoicePayment:""
      cardDatefiled: "",
      showInvoiced: true,
      showListView: true,
      apData: [],
      apfilterData: [],
    };
  }

  componentDidMount() {
    this.props?.actionFetchGetInvoices();
    this.props?.getAccountsPayable();
    document.title = "Twisted Nail - Invoice Management";
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.totalResult?.success === false &&
      this.props?.totalResult?.success === true
    ) {
      let filteredCustomers = this.props?.totalResult?.invoicesData?.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) => t.ticketData.companyName === value.ticketData.companyName
          )
      );

      let invoicesData = this.props?.totalResult?.invoicesData?.sort(
        (a, b) => new Date(b?.createdAt) - new Date(a?.createdAt)
      );
      invoicesData?.forEach((record) => {
        let truckingPriceTotal = 0,
          truckingCostTotal = 0,
          materialCostTotal = 0,
          materialPriceTotal = 0;
        record?.ticketDetails?.forEach((i) => {
          if (i?.selectedMaterial?.length > 0) {
            if (record.ticketData?.jobType === "Trucking & Material Job") {
              materialPriceTotal +=
                parseFloat(i?.quantity) *
                parseFloat(i?.selectedMaterial[0]?.totalPrice);
              materialCostTotal +=
                parseFloat(i?.quantity) *
                parseFloat(i?.selectedMaterial[0]?.materialCost);
            }
            truckingPriceTotal +=
              parseFloat(i?.quantity) *
              parseFloat(i?.selectedMaterial[0]?.truckingPrice);
            truckingCostTotal +=
              parseFloat(i?.quantity) *
              parseFloat(i?.selectedMaterial[0]?.truckingCost);
          }
        });
        record["truckingCostTotal"] = truckingCostTotal;
        record["truckingPriceTotal"] = truckingPriceTotal;

        record["materialCostTotal"] = materialCostTotal;
        record["materialPriceTotal"] = materialPriceTotal;
      });

      const groupedData = this.groupedDataInfo(invoicesData);

      this.setState({
        groupedData,
        filterGroupedData: groupedData,
        invoiceData: invoicesData,
        filterInvoiceData: invoicesData,
        loading: false,
        filteredCustomers,
      });
    }

    if (
      prevProps.accountsResult.success === false &&
      this.props.accountsResult.success === true
    ) {
      let data = this.props.accountsResult.data
        ?.filter((e) => e.isTruckingAndMaterialJob)
        ?.sort((a, b) => (a.invoiceNumber > b.invoiceNumber ? -1 : 1));

      data?.forEach((e) =>
        e.ticketDetails.forEach((t) => {
          t["invoiceId"] = e["invoiceId"];
          t["invoiceId"] = e["invoiceId"];
          t["createdAt"] = e["createdAt"];
        })
      );
      let updateData = data?.map((e) => e.ticketDetails);
      let newData = updateData?.flatMap((m) => m);
      this.setState({
        apData: newData,
        apfilterData: newData,
      });
    }
  }

  groupedDataInfo = (invoicesData) => {
    const groupedData = {};
    invoicesData.forEach((obj) => {
      const weekStart = new Date(obj.createdAt);
      weekStart.setDate(weekStart.getDate() - ((weekStart.getDay() + 6) % 7)); // Monday of the current week
      const weekEnd = new Date(weekStart);
      weekEnd.setDate(weekEnd.getDate() + 6); // Sunday of the current week

      // Format the dates in "MM/DD/YYYY" format
      const formattedWeekStart = `${(weekStart.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${weekStart
        .getDate()
        .toString()
        .padStart(2, "0")}/${weekStart.getFullYear()}`;
      const formattedWeekEnd = `${(weekEnd.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${weekEnd
        .getDate()
        .toString()
        .padStart(2, "0")}/${weekEnd.getFullYear()}`;

      const weekKey = `${formattedWeekStart} - ${formattedWeekEnd}`;
      // add the object to the appropriate week in the grouped data object
      if (!groupedData[weekKey]) {
        groupedData[weekKey] = [];
      }
      groupedData[weekKey].push(obj);
    });
    return groupedData;
  };

  // groupedByWeek = (datesInfo) => {
  //   const { apData } = this.state;
  //   const [d1, d2] = datesInfo?.split(" - ");
  //   const filteredData = apData?.filter((i) =>
  //     moment(moment(i?.createdAt).format("MM/DD/YYYY")).isBetween(
  //       d1,
  //       d2,
  //       undefined,
  //       "[]"
  //     )
  //   );
  //   return filteredData;
  // };
  onRefresh = () => {
    this.props?.actionFetchGetInvoices();
  };

  onFinish = (values) => {
    let data = {
      entityData: values,
    };
    console.log(data);
  };
  getAddress = (address, latLng, id) => {
    this.setState({
      selectedAddress: address,
    });
  };

  onChangeNameInput = (event) => {
    console.log(event.target.value);
  };

  closeFilter = () => {
    this.setState({
      visible: false,
    });
  };

  fileDownloadQuote = async (fileName) => {
    console.log(fileName, "lkjhhg");
    this.setState({
      loadingDownload: true,
    });
    Storage.get(fileName, { download: true }).then((res) =>
      this.downloadBlobQuote(res.Body, fileName)
    );
  };

  downloadInvoice = (fileName) => {
    console.log(fileName);

    this.setState({
      loading: true,
    });
    fileName?.map((e) =>
      Storage.get(e.invoicePath, { download: true })
        .then((res) => this.downloadBlobQuote(res.Body, fileName))
        .catch((err) => console.log(err))
    );
  };

  downloadBlobQuote(blob, fileName) {
    console.log(fileName);
    const url = URL.createObjectURL(blob);
    //https://twisted-nail-dev-attachments.s3.us-east-1.amazonaws.com/quotes/7d0e0df0-1fb8-11ed-b551-fd2cfd43234b_quote.pdf?x-id=GetObject
    // const url = `https://${process.env.REACT_APP_S3_BUCKET}.s3.us-east-1.amazonaws.com/public/${fileName}`;
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.download = fileName || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    message.success("Downloaded successfully");

    this.setState({
      loadingDownload: false,
    });
    return a;
  }

  onResetData = () => {
    this.props?.actionFetchGetInvoices();
  };

  handleFilter = () => {
    const {
      companyName,
      invoiceData,
      invoiceNumber,
      fromDate,
      toDate,
      showFinace,
      jobInfoData,
      showListView,
    } = this.state;
    this.setState({
      loadingDownload: true,
    });

    let toDateBefore = "";
    let fromDateBefore = "";
    if (toDate !== "") {
      toDateBefore = new Date(toDate);
      toDateBefore.setDate(toDateBefore.getDate() + 1);
    }
    if (fromDate !== "") {
      fromDateBefore = new Date(fromDate);
      fromDateBefore.setDate(fromDateBefore.getDate() - 1);
    }
    let data = {
      companyName,
      invoiceNumber,
      toDateBefore,
      fromDateBefore,
    };
    let resultData = this.findAllFilterData(
      showFinace ? jobInfoData : invoiceData,
      data
    );
    if (showFinace) {
      this.setState({
        jobInfoData: resultData,
      });
    } else {
      if (showListView) {
        const groupedData = this.groupedDataInfo(resultData);
        this.setState({
          groupedData,
        });
      } else {
        this.setState({
          filterInvoiceData: resultData,
        });
      }
    }
    this.setState({
      loadingDownload: false,
    });
  };

  findAllFilterData = (data, filterCriteria) => {
    return data.filter((item) => {
      if (
        (!filterCriteria.companyName ||
          filterCriteria.companyName === item.ticketData.companyId) &&
        (!filterCriteria.invoiceNumber ||
          filterCriteria.invoiceNumber === item.invoiceNumber) &&
        ((!filterCriteria.toDateBefore && !filterCriteria.fromDateBefore) ||
          moment(moment(item.createdAt).format("l")).isBetween(
            moment(filterCriteria.fromDateBefore).format("l"),
            moment(filterCriteria.toDateBefore).format("l")
          ))
      ) {
        return true;
      }
      return false;
    });
  };

  handlePagination = async (page, pageSize) => {
    if (page <= 1) {
      this.setState({
        minValue: 0,
        maxValue: pageSize,
      });
    } else {
      this.setState({
        minValue: (page - 1) * pageSize,
        maxValue: page * pageSize,
      });
    }
    this.setState({
      currentPage: page,
      perpageSize: pageSize,
    });
  };

  handleReset = () => {
    const { filterGroupedData, filterJobInfoData, invoiceData } = this.state;
    this.setState({
      invoiceNumber: "",
      toDate: "",
      fromDate: "",
      companyName: undefined,
      jobInfoData: filterJobInfoData,
      groupedData: filterGroupedData,
      filterInvoiceData: invoiceData,
    });
  };

  customersCount = (value) => {
    let filterCustomers = value?.filter(
      (val, ind, self) =>
        ind === self?.findIndex((e) => e.companyId === val.companyId)
    );
    return filterCustomers;
  };

  jobsCount = (value) => {
    let filterJobs = value?.filter(
      (val, ind, self) => ind === self?.findIndex((e) => e.jobId === val.jobId)
    );
    return filterJobs;
  };

  topData = (value) => {
    const sortedData = value.sort(
      (a, b) => parseFloat(b.ticketsTotal) - parseFloat(a.ticketsTotal)
    );
    return sortedData[0];
  };

  topTruck = (value, type) => {
    const sortedData = [...value].sort(
      (a, b) =>
        parseFloat(b.truckingPriceTotal) - parseFloat(a.truckingPriceTotal)
    );
    return sortedData[0]?.ticketData?.selectedMaterial[0]?.assignTrucksInfo?.filter(
      (e) => sortedData[0]?.ticketData?.selectedCarrierId
    )[0]?.truckId;
  };

  topTruck1 = (value) => {
    const sortedData = [...value].sort(
      (a, b) =>
        parseFloat(b.truckingPriceTotal) - parseFloat(a.truckingPriceTotal)
    );

    return sortedData[0]?.ticketData?.selectedCarrierId;
  };

  customerRevenue = (record, type) => {
    const sum = record.reduce(
      (accumulator, currentValue) =>
        accumulator + parseFloat(currentValue[type]),
      0
    );
    return sum;
  };

  customerPercentage = (record, type) => {
    const total = record.reduce(
      (accumulator, currentValue) =>
        accumulator + parseFloat(currentValue["ticketsTotal"]),
      0
    );

    const sum = record.reduce(
      (accumulator, currentValue) =>
        accumulator + parseFloat(currentValue[type]),
      0
    );
    return (sum / total) * 100;
  };

  grossPercentage = (record, type) => {
    const total = record.reduce(
      (accumulator, currentValue) =>
        accumulator + parseFloat(currentValue["ticketsTotal"]),
      0
    );
    let sum = 0;
    if (type === "Total") {
      sum = record.reduce(
        (accumulator, currentValue) =>
          accumulator +
          parseFloat(currentValue.truckingCostTotal) +
          parseFloat(currentValue.materialCostTotal),
        0
      );
    } else {
      sum = record.reduce(
        (accumulator, currentValue) =>
          accumulator + parseFloat(currentValue[type]),
        0
      );
    }
    return (sum / total) * 100;
  };

  grossRevenue = (record, type) => {
    let sum = 0;
    if (type === "Total") {
      sum = record.reduce(
        (accumulator, currentValue) =>
          accumulator +
          parseFloat(currentValue.truckingCostTotal) +
          parseFloat(currentValue.materialCostTotal),
        0
      );
    } else {
      sum = record.reduce(
        (accumulator, currentValue) =>
          accumulator + parseFloat(currentValue[type]),
        0
      );
    }

    return sum;
  };

  profitPercentage = (record, type1, type2) => {
    const total = record.reduce(
      (accumulator, currentValue) =>
        accumulator + parseFloat(currentValue["ticketsTotal"]),
      0
    );
    let revenue = 0,
      cost = 0;
    if (type1 === "ticketsTotal") {
      revenue = record.reduce(
        (accumulator, currentValue) =>
          accumulator + parseFloat(currentValue[type1]),
        0
      );
      cost = record.reduce(
        (accumulator, currentValue) =>
          accumulator +
          parseFloat(currentValue.truckingCostTotal) +
          parseFloat(currentValue.materialCostTotal),
        0
      );
    } else {
      revenue = record.reduce(
        (accumulator, currentValue) =>
          accumulator + parseFloat(currentValue[type1]),
        0
      );
      cost = record.reduce(
        (accumulator, currentValue) =>
          accumulator + parseFloat(currentValue[type2]),
        0
      );
    }
    return ((revenue - cost) / total) * 100;
  };

  grossProfit = (record, type1, type2) => {
    let revenue = 0,
      cost = 0;
    if (type1 === "ticketsTotal") {
      revenue = record.reduce(
        (accumulator, currentValue) =>
          accumulator + parseFloat(currentValue[type1]),
        0
      );
      cost = record.reduce(
        (accumulator, currentValue) =>
          accumulator +
          parseFloat(currentValue.truckingCostTotal) +
          parseFloat(currentValue.materialCostTotal),
        0
      );
    } else {
      revenue = record.reduce(
        (accumulator, currentValue) =>
          accumulator + parseFloat(currentValue[type1]),
        0
      );
      cost = record.reduce(
        (accumulator, currentValue) =>
          accumulator + parseFloat(currentValue[type2]),
        0
      );
    }
    return revenue - cost;
  };

  handleClickCard = (jobKey, jobValue) => {
    this.setState({
      showFinace: true,
      cardDatefiled: jobKey,
      jobInfoData: jobValue,
      filterJobInfoData: jobValue,
    });
  };

  updateInvoiceStatus = (status, id) => {
    const { actionUpdateInvoice } = this.props;
    const { jobInfoData } = this.state;
    let data = {
      entityData: {
        updateInvoice: status,
      },
    };

    actionUpdateInvoice(id, data);

    jobInfoData.map((e) => {
      if (e.id === id) {
        e["updateInvoice"] = status;
      }
      return e;
    });
    this.setState({
      jobInfoData,
      selectedStatus: status,
    });
    message.success("Invoice Status Updated Sucessfully");
  };

  downloadZip = async (images, cardDatefiled) => {
    this.setState({
      loadingDownload: true,
    });
    const zip = new JSZip();
    const folders = {};

    // Fetch and organize images into folders based on companyId
    const promises = images.map(async (image) => {
      const companyId = image.companyName;
      if (!folders[companyId]) {
        folders[companyId] = zip.folder(companyId);
      }
      const folder = folders[companyId];
      const invoicePath = image.invoicePath;

      // Fetch the image URL from Storage
      try {
        const imageUrl = await Storage.get(invoicePath);

        // Add the image to the folder
        folder.file(
          invoicePath,
          await fetch(imageUrl).then((response) => response.blob()),
          { binary: true }
        );
      } catch (error) {
        console.error(`Error fetching ${invoicePath} from Storage: ${error}`);
      }
    });
    // Wait for all promises to resolve
    await Promise.all(promises);

    // Generate the zip file
    const content = await zip.generateAsync({ type: "blob" });

    // Save the zip file
    saveAs(content, `${cardDatefiled}.zip`);
    this.setState({
      loadingDownload: false,
    });
  };

  handleTableChage = (props, type) => {
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    localStorage.setItem(
      "tableSizeRange",
      JSON.stringify({ ...tableSizeRange, [type]: props })
    );
  };

  render() {
    const {
      filterInvoiceData,
      showListView,
      showInvoiced,
      selectedStatus,
      cardDatefiled,
      // invoicePayment,
      jobInfoData,
      showFinace,
      minValue,
      maxValue,
      currentPage,
      perpageSize,
      groupedData,
      fromDate,
      toDate,
      invoiceNumber,
      companyName,
      loadingDownload,
      filteredCustomers,
      selectedFiles,
      apData,
      // apData,
    } = this.state;
    // const { FinanceInvoiceManagement } = this.props;
    const content = ["Not Reviewed", "Edits Needed", "Approved", "Invoiced"];

    const invoiceStatusContent = (id) => (
      <div>
        {content.map((e) => (
          <Row
            onClick={() => {
              if (
                (selectedStatus === "Not Reviewed" &&
                  ["Not Reviewed", "Approved", "Invoiced"].includes(e)) ||
                (selectedStatus === "Edits Needed" &&
                  ["Not Reviewed", "Edits Needed", "Invoiced"].includes(e)) ||
                (selectedStatus === "Approved" &&
                  ["Not Reviewed", "Edits Needed", "Approved"].includes(e))
              ) {
              } else {
                this.updateInvoiceStatus(e, id);
              }
            }}
            style={{
              cursor: `${
                selectedStatus === "Not Reviewed"
                  ? ["Not Reviewed", "Approved", "Invoiced"].includes(e)
                    ? "not-allowed"
                    : "pointer"
                  : selectedStatus === "Edits Needed"
                  ? ["Not Reviewed", "Edits Needed", "Invoiced"].includes(e)
                    ? "not-allowed"
                    : "pointer"
                  : selectedStatus === "Approved"
                  ? ["Not Reviewed", "Edits Needed", "Approved"].includes(e)
                    ? "not-allowed"
                    : "pointer"
                  : "pointer"
              }`,
            }}
          >
            <Col
              style={{
                fontSize: "13px",
                fontFamily: "sans-serif",
              }}
            >
              <Button
                style={{
                  width: "180px",
                  marginBottom: "5px",
                  background:
                    selectedStatus === "Not Reviewed"
                      ? ["Not Reviewed", "Approved", "Invoiced"].includes(e)
                        ? ""
                        : e === this.state.selectedStatus
                        ? "#1890ff"
                        : "#FFF"
                      : selectedStatus === "Edits Needed"
                      ? ["Not Reviewed", "Edits Needed", "Invoiced"].includes(e)
                        ? ""
                        : e === this.state.selectedStatus
                        ? "#1890ff"
                        : "#FFF"
                      : selectedStatus === "Approved"
                      ? ["Not Reviewed", "Edits Needed", "Approved"].includes(e)
                        ? ""
                        : e === this.state.selectedStatus
                        ? "#1890ff"
                        : "#FFF"
                      : "#FFF",
                  color:
                    selectedStatus === "Not Reviewed"
                      ? ["Not Reviewed", "Approved", "Invoiced"].includes(e)
                        ? ""
                        : e === this.state.selectedStatus
                        ? "#FFF"
                        : "#1890ff"
                      : selectedStatus === "Edits Needed"
                      ? ["Not Reviewed", "Edits Needed", "Invoiced"].includes(e)
                        ? ""
                        : e === this.state.selectedStatus
                        ? "#FFF"
                        : "#1890ff"
                      : selectedStatus === "Approved"
                      ? ["Not Reviewed", "Edits Needed", "Approved"].includes(e)
                        ? ""
                        : e === this.state.selectedStatus
                        ? "#FFF"
                        : "#1890ff"
                      : "#1890ff",
                  pointerEvents: "none",
                }}
                disabled={
                  (selectedStatus === "Not Reviewed" &&
                    ["Not Reviewed", "Approved", "Invoiced"].includes(e)) ||
                  (selectedStatus === "Edits Needed" &&
                    ["Not Reviewed", "Edits Needed", "Invoiced"].includes(e)) ||
                  (selectedStatus === "Approved" &&
                    ["Not Reviewed", "Edits Needed", "Approved"].includes(e))
                }
                name={e}
                id={e}
                className={e}
              >
                {e}
              </Button>
            </Col>
          </Row>
        ))}
      </div>
    );

    const STATUS_COLORS = {
      "Not Reviewed": "#7d7d7d",
      "Edits Needed": "#e07c2f",
      Approved: "#237804",
      Invoiced: "#d4380d",
    };

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          selectedFiles: selectedRows,
        });
      },
    };

    const { errorLoading, loading } = this.props;

    const { Option } = Select;

    const columns = [
      {
        title: "INVOICE #",
        dataIndex: "invoiceNumber",
        key: "invoiceNumber",
        sorter: false,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => {
            this.props.history.push(`/app/Invoice-Management/${record.id}`);
          },
        }),
        render: (invoiceNumber) => {
          return (
            <span
              className="col_styling table-font-mobile"
              style={{
                textTransform: "capitalize",
                width: "10px",
                color: "#212121",
              }}
            >
              <Row>{invoiceNumber}</Row>
            </span>
          );
        },
      },
      {
        title: "Job #",
        dataIndex: "jobNumber",
        key: "jobNumber",
        sorter: false,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => {
            this.props.history.push(`/app/Invoice-Management/${record.id}`);
          },
        }),
        // onCell: (record) => ({
        //   onClick: () =>
        //     this.setState({
        //       showInvoice: true,
        //       showInvoiceData: record,
        //     }),
        // }),
        render: (jobNumber, record) => {
          return (
            <span
              className="col_styling table-font-mobile"
              style={{
                textTransform: "capitalize",
                width: "10px",
                color: "#212121",
              }}
            >
              {record?.ticketData?.jobNumber}
            </span>
          );
        },
      },
      {
        title: "Job Type",
        dataIndex: "jobType",
        key: "jobType",
        sorter: false,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => {
            this.props.history.push(`/app/Invoice-Management/${record.id}`);
          },
        }),
        // onCell: (record) => ({
        //   onClick: () =>
        //     this.setState({
        //       showInvoice: true,
        //       showInvoiceData: record,
        //     }),
        // }),
        render: (jobType, record) => {
          return (
            <span
              className="col_styling table-font-mobile"
              style={{
                textTransform: "capitalize",
                width: "10px",
                color: "#212121",
              }}
            >
              {record?.ticketData?.jobType}
            </span>
          );
        },
      },
      {
        title: "Date",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: false,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => {
            this.props.history.push(`/app/Invoice-Management/${record.id}`);
          },
        }),
        // onCell: (record) => ({
        //   onClick: () =>
        //     this.setState({
        //       showInvoice: true,
        //       showInvoiceData: record,
        //     }),
        // }),
        render: (createdAt) => {
          return (
            <span
              className="col_styling table-font-mobile"
              style={{
                textTransform: "capitalize",
              }}
            >
              {moment(createdAt).format("l")}
            </span>
          );
        },
      },
      {
        title: "CUSTOMER",
        dataIndex: "companyName",
        key: "companyName",
        sorter: false,
        onCell: (record) => ({
          onClick: () => {
            this.props.history.push(`/app/Invoice-Management/${record.id}`);
          },
        }),
        // onCell: (record) => ({
        //   onClick: () =>
        //     this.setState({
        //       showInvoice: true,
        //       showInvoiceData: record,
        //     }),
        // }),
        className: "col_styling",
        render: (companyName, record) => {
          return (
            <span
              className="col_styling table-font-mobile"
              style={{
                textTransform: "capitalize",
                color: "#212121",
              }}
            >
              {record?.ticketData?.companyName}
            </span>
          );
        },
      },
      {
        title: "AMOUNT",
        dataIndex: "ticketsTotal",
        key: "ticketsTotal",
        sorter: false,
        onCell: (record) => ({
          onClick: () => {
            this.props.history.push(`/app/Invoice-Management/${record.id}`);
          },
        }),
        className: "col_styling",
        // onCell: (record) => ({
        //   onClick: () =>
        //     this.setState({
        //       showInvoice: true,
        //       showInvoiceData: record,
        //     }),
        // }),
        render: (ticketsTotal) => {
          return (
            <span
              className="col_styling table-font-mobile"
              style={{
                color: "#212121",
              }}
            >
              {`$ ${parseFloat(ticketsTotal)?.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`}
            </span>
          );
        },
      },
      {
        title: "Invoice",
        dataIndex: "invoicePath",
        key: "invoicePath",
        sorter: false,
        className: "col_styling",
        // onCell: (record) => ({
        //   onClick: () =>
        //     (window.location = `/app/Invoice-Management/${record.id}`),
        // }),
        // onCell: (record) => ({
        // onClick: () => (window.location = "/app/loads/loaddetails/"),
        // }),
        render: (invoicePath, record) => {
          return (
            <span className="col_style_quarry">
              <span style={{ cursor: "pointer" }}>
                <Row
                  style={{
                    color: "#7B8190",
                    fontSize: "15px",
                  }}
                >
                  <Col
                    span={24}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <HiOutlineDocumentArrowDown
                      style={{
                        fontSize: "30px",
                        height: "30px",
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                      className="invoice-download-icon"
                      onClick={() => {
                        this.fileDownloadQuote(invoicePath);
                      }}
                    />
                    {/* <ExcelExportHelper
                      data={[record]}
                      billData={apData?.filter(
                        (e) => e.invoiceId === record.id
                      )}
                      showIcon
                    /> */}
                  </Col>
                </Row>
              </span>
            </span>
          );
        },
      },
      {
        title: "STATUS",
        dataIndex: "updateInvoice",
        key: "updateInvoice",
        sorter: false,
        className: "col_styling",
        render: (updateInvoice, record) => {
          const statusColor = STATUS_COLORS[updateInvoice] || "#7d7d7d";
          return (
            <Popover
              placement="left"
              content={
                updateInvoice !== "Invoiced" && invoiceStatusContent(record.id)
              }
              trigger="click"
            >
              <Button
                className="lead_ButtonStatus"
                style={{
                  backgroundColor: statusColor,
                  color: "#FFF",
                  borderRadius: "6px",
                  width: "160px",
                  justifyContent: "center",
                  border: `1px solid ${statusColor}`,
                }}
                onClick={() =>
                  this.setState({
                    selectedStatus: updateInvoice
                      ? updateInvoice
                      : "Not Reviewed",
                  })
                }
              >
                {updateInvoice ? updateInvoice : <span>{"Not Reviewed"}</span>}
              </Button>
            </Popover>
          );
        },
      },
    ];

    return (
      <div
        style={{
          background: "#fafafa",
        }}
      >
        <Loading enableLoading={loading || loadingDownload} />
        {errorLoading ? (
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <ErrorView refreshLeadDetails={this.onRefresh} type="LeadDetails" />
          </Col>
        ) : (
          <>
            <div
              style={{
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                margin: "20px 0px 0px",
                justifyContent: "center",
              }}
            >
              <Row
                style={{
                  width: "100%",
                }}
              >
                <Col
                  xxl={{ span: 6 }}
                  xl={{ span: 6 }}
                  lg={{ span: 12 }}
                  md={{ span: 12 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <div
                    style={{
                      margin: "12px 0px",
                      width: "100%",
                      padding: "0px 10px",
                    }}
                  >
                    <Select
                      id="reset"
                      size="large"
                      showSearch
                      allowClear
                      className="select_Height"
                      placeholder="All Customers"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      style={{
                        width: "100%",
                      }}
                      onChange={(e) => {
                        this.setState({
                          companyName: e,
                        });
                      }}
                      value={companyName}
                    >
                      {filteredCustomers?.map((d, index) => (
                        <Option key={index} value={d?.ticketData?.companyId}>
                          {d?.ticketData?.companyName}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col>

                <Col
                  xxl={{ span: 4 }}
                  xl={{ span: 4 }}
                  lg={{ span: 6 }}
                  md={{ span: 6 }}
                  sm={{ span: 8 }}
                  xs={{ span: 12 }}
                >
                  <div
                    style={{
                      margin: "12px 0px",
                      width: "100%",
                      padding: "0px 10px",
                    }}
                  >
                    <DatePicker
                      value={fromDate}
                      className="form-input"
                      style={{ width: "100%" }}
                      placeholder="From"
                      format={"MM/DD/YYYY"}
                      onChange={(e) =>
                        this.setState({
                          fromDate: e,
                          toDate: "",
                        })
                      }
                    />
                  </div>
                </Col>
                <Col
                  xxl={{ span: 4 }}
                  xl={{ span: 4 }}
                  lg={{ span: 6 }}
                  md={{ span: 6 }}
                  sm={{ span: 8 }}
                  xs={{ span: 12 }}
                >
                  <div
                    style={{
                      margin: "12px 0px",
                      width: "100%",
                      padding: "0px 10px",
                    }}
                  >
                    <DatePicker
                      value={toDate}
                      className="form-input"
                      style={{ width: "100%" }}
                      placeholder="To"
                      format={"MM/DD/YYYY"}
                      onChange={(e) =>
                        this.setState({
                          toDate: e,
                        })
                      }
                      // onChange={this.handleToDate}
                      disabledDate={(current) => {
                        return (
                          current && current.valueOf() < new Date(fromDate)
                        );
                      }}
                    />
                  </div>
                </Col>
                <Col
                  xxl={{ span: 4 }}
                  xl={{ span: 4 }}
                  lg={{ span: 12 }}
                  md={{ span: 12 }}
                  sm={{ span: 8 }}
                  xs={{ span: 12 }}
                >
                  <div
                    style={{
                      margin: "12px 0px",
                      width: "100%",
                      padding: "0px 10px",
                    }}
                    className="enter-invoice-input"
                  >
                    <Input
                      value={invoiceNumber}
                      className="form-input"
                      placeholder="Enter Invoice #"
                      onChange={(e) =>
                        this.setState({
                          invoiceNumber: e.target.value,
                        })
                      }
                    />
                  </div>
                </Col>
                <Col
                  xxl={{ span: 4 }}
                  xl={{ span: 4 }}
                  lg={{ span: 6 }}
                  md={{ span: 6 }}
                  sm={{ span: 8 }}
                  xs={{ span: 12 }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                      height: "100%",
                      alignItems: "center",
                      padding: "0px 10px",
                    }}
                  >
                    <Button
                      type="primary"
                      style={{
                        height: "35px",
                        width: "100px",
                        borderRadius: "5px",
                        marginRight: "12px",
                      }}
                      onClick={this.handleFilter}
                      disabled={
                        companyName || invoiceNumber || (fromDate && toDate)
                          ? false
                          : true
                      }
                    >
                      Apply
                    </Button>
                    <Button
                      style={{
                        height: "35px",
                        width: "100px",
                        borderRadius: "5px",
                      }}
                      onClick={this.handleReset}
                    >
                      Reset
                    </Button>
                  </div>
                </Col>
                {!showFinace && (
                  <Col
                    xxl={{ span: 2 }}
                    xl={{ span: 2 }}
                    lg={{ span: 12 }}
                    md={{ span: 12 }}
                    sm={{ span: 8 }}
                    xs={{ span: 12 }}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                      marginLeft: "-5px",
                    }}
                  >
                    <Row
                      style={{
                        background: "#cecece",
                        height: "40px",
                        borderRadius: "6px",
                      }}
                    >
                      <Col style={{ marginTop: "4px" }}>
                        <Button
                          style={{
                            border: "none",
                            borderRadius: "5px",
                            padding: "5px 8px",
                            margin: "0px 5px",
                            background: showListView ? "#FFF" : "transparent",
                            boxShadow: "none",
                            outline: "none",
                          }}
                          onClick={() => this.setState({ showListView: true })}
                        >
                          <FaLayerGroup
                            style={{
                              fontSize: "22px",
                              color: "#586370",
                            }}
                          />
                        </Button>
                      </Col>
                      <Col style={{ marginTop: "4px" }}>
                        <Button
                          style={{
                            border: "none",
                            borderRadius: "5px",
                            padding: "5px 8px",
                            margin: "0px 5px",
                            background: !showListView ? "#FFF" : "transparent",
                            boxShadow: "none",
                            outline: "none",
                          }}
                          onClick={() => this.setState({ showListView: false })}
                        >
                          <UnorderedListOutlined
                            style={{
                              fontSize: "22px",
                              color: "#586370",
                            }}
                          />
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </div>

            {showListView ? (
              <Row>
                {showFinace ? (
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <Row
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        marginBottom: "1vw",
                      }}
                    >
                      <Col
                        span={12}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <BsArrowLeftShort
                          style={{ color: "#5f6c74", fontSize: "23px" }}
                          className="back-button-return-icon"
                          onClick={() =>
                            this.setState({
                              showFinace: false,
                            })
                          }
                        />
                        <p
                          style={{
                            color: "#808080",
                            fontSize: "16px",
                            fontWeight: "600",
                            marginBottom: "0px",
                          }}
                          className="back-button-return"
                          onClick={() =>
                            this.setState({
                              showFinace: false,
                            })
                          }
                        >
                          Return
                        </p>
                      </Col>
                      <Col
                        span={12}
                        style={{ justifyContent: "end", display: "flex" }}
                      >
                        <Col className="gridbtntool">
                          <Button
                            className="hover_style"
                            style={{
                              borderRadius: "5px",
                              height: "40px",
                              border: "none",
                            }}
                            onClick={() =>
                              this.setState({ showInvoiced: !showInvoiced })
                            }
                          >
                            <CheckOutlined
                              style={{
                                color: "#586370",
                                fontSize: "17px",
                                fontWeight: "500",
                              }}
                            />
                            <span
                              style={{
                                color: "#586370",
                                fontSize: "17px",
                                fontWeight: "500",
                              }}
                            >
                              {showInvoiced ? "Show Invoiced" : "Hide Invoiced"}
                            </span>
                          </Button>
                        </Col>

                        {selectedFiles.length > 0 && (
                          <Col className="gridbtntool">
                            <Button
                              style={{
                                height: "40px",
                                backgroundColor: "#586370",
                              }}
                              onClick={() => {
                                this.downloadZip(selectedFiles, cardDatefiled);
                              }}
                              icon={
                                <DownloadOutlined
                                  style={{
                                    fontSize: "20px",
                                    marginLeft: "8px",
                                    marginTop: "2px",
                                    color: "white",
                                  }}
                                  className="trucking-filter-icon"
                                />
                              }
                            >
                              <span
                                style={{
                                  color: "white",
                                  fontWeight: "500",
                                  fontSize: "16px",
                                }}
                                className="col_styling table-font-mobile"
                              >
                                Download
                              </span>
                            </Button>
                          </Col>
                        )}
                      </Col>
                    </Row>
                    <Card bodyStyle={{ padding: "0px" }}>
                      <BaseTable
                        className="financeinvoice_table"
                        columnsData={columns}
                        source={
                          showInvoiced
                            ? jobInfoData
                                .filter((j) => j.updateInvoice !== "Invoiced")
                                ?.sort((a, b) =>
                                  a.invoiceNumber > b.invoiceNumber ? -1 : 1
                                )
                            : jobInfoData
                                ?.filter((j) => j.updateInvoice === "Invoiced")
                                .sort((a, b) =>
                                  a.invoiceNumber > b.invoiceNumber ? -1 : 1
                                )
                        }
                        rowSelection={rowSelection}
                        total={
                          showInvoiced
                            ? jobInfoData.filter(
                                (j) => j.updateInvoice !== "Invoiced"
                              )?.length
                            : jobInfoData?.filter(
                                (j) => j.updateInvoice === "Invoiced"
                              )?.length
                        }
                        rowKey={(record) => record?.id}
                        sizeRange={
                          window.screen.width > 1023
                            ? JSON.parse(localStorage.getItem("tableSizeRange"))
                                ?.FinanceSingleInvoiceManagement
                            : jobInfoData?.length
                        }
                        pagination={window.screen.width > 1023 ? true : false}
                        handleTableChage={(e) =>
                          this.handleTableChage(
                            e,
                            "FinanceSingleInvoiceManagement"
                          )
                        }
                      />
                    </Card>
                  </Col>
                ) : Object.keys(groupedData).length > 0 ? (
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    {Object.keys(groupedData)
                      ?.slice(minValue, maxValue)
                      ?.map((e) => (
                        <>
                          <Card
                            key={e}
                            className="invoice-management-card show-on-small-desktop"
                            hoverable
                            style={{
                              marginBottom: "1vw",
                              borderRadius: "5px",
                              border: "1px solid #E8E8E8",
                              cursor: "pointer",
                              minWidth: "100%",
                            }}
                          >
                            <Col span={24}>
                              <Row
                                // className="ticket_uplaod"
                                style={{ padding: "10px", fontSize: "15px" }}
                              >
                                <Col
                                  style={{
                                    fontWeight: "bold",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  span={12}
                                  onClick={() => {
                                    this.handleClickCard(
                                      e,
                                      Object.values(groupedData[e])
                                    );
                                  }}
                                >
                                  <Col>{e}</Col>
                                </Col>

                                <Col
                                  style={{
                                    fontWeight: "bold",
                                    color: "#586370",
                                    cursor: "pointer",
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                  span={12}
                                >
                                  <Row>
                                    <Col className="gridbtntool">
                                      {/* <Button
                                        style={{
                                          borderRadius: "3px",
                                          height: "40px",
                                          marginRight: "5px",
                                          position: "relative",
                                          bottom: "6px",
                                          backgroundColor: "#586370",
                                        }}
                                        onClick={() => {
                                          this.downloadZip(
                                            Object.values(groupedData[e]),
                                            e
                                          );
                                        }}
                                        icon={
                                          <DownloadOutlined
                                            style={{
                                              fontSize: "20px",
                                              marginLeft: "8px",
                                              marginTop: "2px",
                                              color: "white",
                                            }}
                                            className="trucking-filter-icon"
                                          />
                                        }
                                      >
                                        <span
                                          style={{
                                            color: "white",
                                            fontWeight: "500",
                                            fontSize: "16px",
                                          }}
                                          className="col_styling table-font-mobile"
                                        >
                                          Download
                                        </span>
                                      </Button> */}
                                      <ExcelExportHelper
                                        data={Object.values(groupedData[e])}
                                        billData={apData}
                                        cardDatefiled={e}
                                      />
                                    </Col>

                                    <Col
                                      onClick={() =>
                                        this.handleClickCard(
                                          e,
                                          Object.values(groupedData[e])
                                        )
                                      }
                                    >
                                      Details
                                    </Col>
                                    <Col
                                      style={{
                                        marginLeft: "6px",
                                        color: "#586370",
                                      }}
                                    >
                                      {" "}
                                      <ArrowRightOutlined />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                            <Divider style={{ margin: "5px" }} />
                            <Row
                              onClick={() => {
                                this.handleClickCard(
                                  e,
                                  Object.values(groupedData[e])
                                );
                              }}
                            >
                              <Col span={3}>
                                <div
                                  className="display_inovice"
                                  style={{
                                    flexWrap: "nowrap",
                                  }}
                                >
                                  <Col className="ticket_heading display_head">
                                    Customers
                                  </Col>
                                  <Col className="ticket_heading display_val">
                                    {
                                      this.customersCount(
                                        Object.values(groupedData[e])
                                      )?.length
                                    }
                                  </Col>
                                </div>

                                <div
                                  className="display_inovice"
                                  style={{
                                    flexWrap: "nowrap",
                                  }}
                                >
                                  <Col className="ticket_heading display_head">
                                    Jobs
                                  </Col>
                                  <Col className="ticket_heading display_val">
                                    {
                                      this.jobsCount(
                                        Object.values(groupedData[e])
                                      )?.length
                                    }
                                  </Col>
                                </div>

                                <div
                                  className="display_inovice"
                                  style={{
                                    flexWrap: "nowrap",
                                  }}
                                >
                                  <Col className="ticket_heading display_head">
                                    Invoices
                                  </Col>
                                  <Col className="ticket_heading display_val">
                                    {Object.values(groupedData[e])?.length}
                                  </Col>
                                </div>
                              </Col>

                              <Col
                                span={5}
                                style={{
                                  borderRight: "2px dashed",
                                  color: "#80808047",
                                }}
                              >
                                <div
                                  className="display_inovice"
                                  style={{
                                    color: "#000000d9",
                                    flexWrap: "nowrap",
                                  }}
                                >
                                  <Col className="ticket_heading display_head">
                                    Top Customer
                                  </Col>
                                  <Col className="ticket_heading display_val">
                                    {
                                      this.topData(
                                        Object.values(groupedData[e])
                                      )?.companyName
                                    }
                                  </Col>
                                </div>

                                <div
                                  className="display_inovice"
                                  style={{
                                    color: "#000000d9",
                                    flexWrap: "nowrap",
                                  }}
                                >
                                  <Col className="ticket_heading display_head">
                                    Top Job
                                  </Col>
                                  <Col className="ticket_heading display_val">
                                    {
                                      this.topData(
                                        Object.values(groupedData[e])
                                      )?.jobNumber
                                    }
                                  </Col>
                                </div>

                                <div
                                  className="display_inovice"
                                  style={{
                                    color: "#000000d9",
                                  }}
                                >
                                  <Col className="ticket_heading display_head">
                                    Top Truck
                                  </Col>
                                  <Col className="ticket_heading display_val">
                                    {this.topTruck(
                                      Object.values(groupedData[e])
                                    )}
                                  </Col>
                                </div>
                              </Col>

                              <Col span={5} offset={1}>
                                <div className="display_inovice">
                                  <Col
                                    className="ticket_heading display_head"
                                    style={{ fontWeight: "600" }}
                                  >
                                    Revenue
                                  </Col>
                                  <Col
                                    className="ticket_heading display_val"
                                    style={{
                                      fontWeight: "600",
                                    }}
                                  >
                                    {"$" +
                                      this.customerRevenue(
                                        Object.values(groupedData[e]),
                                        "ticketsTotal"
                                      )?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                  </Col>
                                  <Col className="ticket_heading"></Col>
                                </div>

                                <div className="display_inovice">
                                  <Col className="ticket_heading display_head">
                                    Trucking
                                  </Col>
                                  <Col className="ticket_heading display_val">
                                    {"$" +
                                      this.customerRevenue(
                                        Object.values(groupedData[e]),
                                        "truckingPriceTotal"
                                      )?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                  </Col>
                                  <Col className="ticket_heading">
                                    {this.customerPercentage(
                                      Object.values(groupedData[e]),
                                      "truckingPriceTotal"
                                    )?.toFixed(1) + "%"}
                                  </Col>
                                </div>

                                <div className="display_inovice">
                                  <Col className="ticket_heading display_head">
                                    Material
                                  </Col>
                                  <Col className="ticket_heading display_val">
                                    {"$" +
                                      this.customerRevenue(
                                        Object.values(groupedData[e]),
                                        "materialPriceTotal"
                                      )?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                  </Col>
                                  <Col className="ticket_heading">
                                    {this.customerPercentage(
                                      Object.values(groupedData[e]),
                                      "materialPriceTotal"
                                    )?.toFixed(1) + "%"}
                                  </Col>
                                </div>
                              </Col>

                              <Col span={5}>
                                <div className="display_inovice">
                                  <Col
                                    className="ticket_heading display_head"
                                    style={{ fontWeight: "600" }}
                                  >
                                    COGS
                                  </Col>
                                  <Col
                                    className="ticket_heading display_val"
                                    style={{ fontWeight: "600" }}
                                  >
                                    {"$" +
                                      this.grossRevenue(
                                        Object.values(groupedData[e]),
                                        "Total"
                                      )?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                  </Col>
                                  <Col
                                    className="ticket_heading"
                                    style={{ fontWeight: "600" }}
                                  >
                                    {this.grossPercentage(
                                      Object.values(groupedData[e]),
                                      "Total"
                                    )?.toFixed(1) + "%"}
                                  </Col>
                                </div>

                                <div className="display_inovice">
                                  <Col className="ticket_heading display_head">
                                    Trucking
                                  </Col>
                                  <Col className="ticket_heading display_val">
                                    {"$" +
                                      this.grossRevenue(
                                        Object.values(groupedData[e]),
                                        "truckingCostTotal"
                                      )?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                  </Col>

                                  <Col className="ticket_heading">
                                    {this.grossPercentage(
                                      Object.values(groupedData[e]),
                                      "truckingCostTotal"
                                    )?.toFixed(1) + "%"}
                                  </Col>
                                </div>

                                <div className="display_inovice">
                                  <Col className="ticket_heading display_head">
                                    Material
                                  </Col>
                                  <Col className="ticket_heading display_val">
                                    {"$" +
                                      this.grossRevenue(
                                        Object.values(groupedData[e]),
                                        "materialCostTotal"
                                      )?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                  </Col>
                                  <Col className="ticket_heading">
                                    {this.grossPercentage(
                                      Object.values(groupedData[e]),
                                      "materialCostTotal"
                                    )?.toFixed(1) + "%"}
                                  </Col>
                                </div>
                              </Col>

                              <Col span={5}>
                                <div className="display_inovice">
                                  <Col
                                    className="ticket_heading display_head"
                                    style={{ fontWeight: "600" }}
                                  >
                                    Gross Profit
                                  </Col>
                                  <Col
                                    className="ticket_heading display_val"
                                    style={{ fontWeight: "600" }}
                                  >
                                    {"$" +
                                      this.grossProfit(
                                        Object.values(groupedData[e]),
                                        "ticketsTotal",
                                        ""
                                      )?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                  </Col>
                                  <Col
                                    className="ticket_heading"
                                    style={{ fontWeight: "600" }}
                                  >
                                    {this.profitPercentage(
                                      Object.values(groupedData[e]),
                                      "ticketsTotal"
                                    )?.toFixed(1) + "%"}
                                  </Col>
                                </div>

                                <div className="display_inovice">
                                  <Col className="ticket_heading display_head">
                                    Trucking
                                  </Col>
                                  <Col className="ticket_heading display_val">
                                    {"$" +
                                      this.grossProfit(
                                        Object.values(groupedData[e]),
                                        "truckingPriceTotal",
                                        "truckingCostTotal"
                                      )?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                  </Col>
                                  <Col className="ticket_heading">
                                    {this.profitPercentage(
                                      Object.values(groupedData[e]),
                                      "truckingPriceTotal",
                                      "truckingCostTotal"
                                    )?.toFixed(1) + "%"}
                                  </Col>
                                </div>

                                <div className="display_inovice">
                                  <Col className="ticket_heading display_head">
                                    Material
                                  </Col>
                                  <Col className="ticket_heading display_val">
                                    {"$" +
                                      this.grossProfit(
                                        Object.values(groupedData[e]),
                                        "materialPriceTotal",
                                        "materialCostTotal"
                                      )?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                  </Col>
                                  <Col className="ticket_heading">
                                    {this.profitPercentage(
                                      Object.values(groupedData[e]),
                                      "materialPriceTotal",
                                      "materialCostTotal"
                                    )?.toFixed(1) + "%"}
                                  </Col>
                                </div>
                              </Col>
                            </Row>
                          </Card>

                          {/* Mobile View */}
                          <Collapse
                            style={{ marginBottom: "12px" }}
                            className="show-on-mobile-tab"
                          >
                            <Collapse.Panel
                              showArrow={false}
                              header={
                                <Col span={18}>
                                  <Row justify={"space-between"}>
                                    <Col
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      span={18}
                                      className="collapse-details-size"
                                    >
                                      <Col>{e}</Col>
                                    </Col>
                                  </Row>
                                </Col>
                              }
                              extra={
                                <>
                                  <Col
                                    style={{
                                      fontWeight: "bold",
                                      color: "#586370",
                                      cursor: "pointer",
                                      display: "flex",
                                    }}
                                    span={24}
                                  >
                                    <Row
                                      onClick={() => {
                                        this.handleClickCard(
                                          e,
                                          Object.values(groupedData[e])
                                        );
                                      }}
                                    >
                                      <Col className="details-collapse collapse-details-size">
                                        Details
                                      </Col>
                                      <Col
                                        style={{
                                          marginLeft: "6px",
                                          color: "#586370",
                                        }}
                                        className="details-icon"
                                      >
                                        {" "}
                                        <ArrowRightOutlined />
                                      </Col>
                                    </Row>
                                  </Col>
                                </>
                              }
                            >
                              <Row>
                                <Col
                                  xxl={{ span: 24 }}
                                  xl={{ span: 24 }}
                                  lg={{ span: 24 }}
                                  md={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  xs={{ span: 24 }}
                                >
                                  <div
                                    className="display_inovice"
                                    style={{
                                      flexWrap: "nowrap",
                                    }}
                                  >
                                    <Col className="ticket_heading display_head">
                                      Customers
                                    </Col>
                                    <Col className="ticket_heading display_val">
                                      {
                                        this.customersCount(
                                          Object.values(groupedData[e])
                                        )?.length
                                      }
                                    </Col>
                                  </div>

                                  <div
                                    className="display_inovice"
                                    style={{
                                      flexWrap: "nowrap",
                                    }}
                                  >
                                    <Col className="ticket_heading display_head">
                                      Jobs
                                    </Col>
                                    <Col className="ticket_heading display_val">
                                      {Object.values(groupedData[e])?.length}
                                    </Col>
                                  </div>

                                  <div
                                    className="display_inovice"
                                    style={{
                                      flexWrap: "nowrap",
                                    }}
                                  >
                                    <Col className="ticket_heading display_head">
                                      Invoices
                                    </Col>
                                    <Col className="ticket_heading display_val">
                                      {Object.values(groupedData[e])?.length}
                                    </Col>
                                  </div>
                                </Col>
                                <Divider style={{ margin: "5px" }} />

                                <Col
                                  xxl={{ span: 24 }}
                                  xl={{ span: 24 }}
                                  lg={{ span: 24 }}
                                  md={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  xs={{ span: 24 }}
                                >
                                  <div
                                    className="display_inovice"
                                    style={{
                                      color: "#000000d9",
                                      flexWrap: "nowrap",
                                    }}
                                  >
                                    <Col className="ticket_heading display_head">
                                      Top Customer
                                    </Col>
                                    <Col className="ticket_heading display_val">
                                      {
                                        this.topData(
                                          Object.values(groupedData[e])
                                        )?.companyName
                                      }
                                    </Col>
                                  </div>

                                  <div
                                    className="display_inovice"
                                    style={{
                                      color: "#000000d9",
                                      flexWrap: "nowrap",
                                    }}
                                  >
                                    <Col className="ticket_heading display_head">
                                      Top Job
                                    </Col>
                                    <Col className="ticket_heading display_val">
                                      {
                                        this.topData(
                                          Object.values(groupedData[e])
                                        )?.jobNumber
                                      }
                                    </Col>
                                  </div>

                                  <div
                                    className="display_inovice"
                                    style={{
                                      color: "#000000d9",
                                    }}
                                  >
                                    <Col className="ticket_heading display_head">
                                      Top Truck
                                    </Col>
                                    <Col className="ticket_heading display_val">
                                      {this.topTruck(
                                        Object.values(groupedData[e])
                                      )}
                                    </Col>
                                  </div>
                                </Col>
                                <Divider style={{ margin: "5px" }} />

                                <Col
                                  xxl={{ span: 24 }}
                                  xl={{ span: 24 }}
                                  lg={{ span: 24 }}
                                  md={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  xs={{ span: 24 }}
                                >
                                  <div className="display_inovice">
                                    <Col
                                      className="ticket_heading display_head"
                                      style={{ fontWeight: "600" }}
                                    >
                                      Revenue
                                    </Col>
                                    <Col
                                      className="ticket_heading display_val"
                                      style={{ fontWeight: "600" }}
                                    >
                                      {"$" +
                                        this.customerRevenue(
                                          Object.values(groupedData[e]),
                                          "ticketsTotal"
                                        )?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </Col>
                                  </div>

                                  <div className="display_inovice">
                                    <Col className="ticket_heading display_head">
                                      Trucking
                                    </Col>
                                    <Col className="ticket_heading display_val">
                                      {"$" +
                                        this.customerRevenue(
                                          Object.values(groupedData[e]),
                                          "truckingPriceTotal"
                                        )?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </Col>
                                    <Col className="ticket_heading">
                                      {this.customerPercentage(
                                        Object.values(groupedData[e]),
                                        "truckingPriceTotal"
                                      )?.toFixed(1) + "%"}
                                    </Col>
                                  </div>

                                  <div className="display_inovice">
                                    <Col className="ticket_heading display_head">
                                      Material
                                    </Col>
                                    <Col className="ticket_heading display_val">
                                      {"$" +
                                        this.customerRevenue(
                                          Object.values(groupedData[e]),
                                          "materialPriceTotal"
                                        )?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </Col>
                                    <Col className="ticket_heading">
                                      {this.customerPercentage(
                                        Object.values(groupedData[e]),
                                        "materialPriceTotal"
                                      )?.toFixed(1) + "%"}
                                    </Col>
                                  </div>
                                </Col>
                                <Divider style={{ margin: "5px" }} />

                                <Col
                                  xxl={{ span: 24 }}
                                  xl={{ span: 24 }}
                                  lg={{ span: 24 }}
                                  md={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  xs={{ span: 24 }}
                                >
                                  <div className="display_inovice">
                                    <Col
                                      className="ticket_heading display_head"
                                      style={{ fontWeight: "600" }}
                                    >
                                      COGS
                                    </Col>
                                    <Col
                                      className="ticket_heading display_val"
                                      style={{ fontWeight: "600" }}
                                    >
                                      {"$" +
                                        this.grossRevenue(
                                          Object.values(groupedData[e]),
                                          "Total"
                                        )?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </Col>
                                    <Col
                                      className="ticket_heading"
                                      style={{ fontWeight: "600" }}
                                    >
                                      {this.grossPercentage(
                                        Object.values(groupedData[e]),
                                        "Total"
                                      )?.toFixed(1) + "%"}
                                    </Col>
                                  </div>

                                  <div className="display_inovice">
                                    <Col className="ticket_heading display_head">
                                      Trucking
                                    </Col>
                                    <Col className="ticket_heading display_val">
                                      {"$" +
                                        this.grossRevenue(
                                          Object.values(groupedData[e]),
                                          "truckingCostTotal"
                                        )?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </Col>

                                    <Col className="ticket_heading">
                                      {this.grossPercentage(
                                        Object.values(groupedData[e]),
                                        "truckingCostTotal"
                                      )?.toFixed(1) + "%"}
                                    </Col>
                                  </div>

                                  <div className="display_inovice">
                                    <Col className="ticket_heading display_head">
                                      Material
                                    </Col>
                                    <Col className="ticket_heading display_val">
                                      {"$" +
                                        this.grossRevenue(
                                          Object.values(groupedData[e]),
                                          "materialCostTotal"
                                        )?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </Col>
                                    <Col className="ticket_heading">
                                      {this.grossPercentage(
                                        Object.values(groupedData[e]),
                                        "materialCostTotal"
                                      )?.toFixed(1) + "%"}
                                    </Col>
                                  </div>
                                </Col>
                                <Divider style={{ margin: "5px" }} />

                                <Col
                                  xxl={{ span: 24 }}
                                  xl={{ span: 24 }}
                                  lg={{ span: 24 }}
                                  md={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  xs={{ span: 24 }}
                                >
                                  <div className="display_inovice">
                                    <Col
                                      className="ticket_heading display_head"
                                      style={{ fontWeight: "600" }}
                                    >
                                      Gross Profit
                                    </Col>
                                    <Col
                                      className="ticket_heading display_val"
                                      style={{ fontWeight: "600" }}
                                    >
                                      {"$" +
                                        this.grossProfit(
                                          Object.values(groupedData[e]),
                                          "ticketsTotal",
                                          ""
                                        )?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </Col>
                                    <Col
                                      className="ticket_heading"
                                      style={{ fontWeight: "600" }}
                                    >
                                      {this.profitPercentage(
                                        Object.values(groupedData[e]),
                                        "ticketsTotal"
                                      )?.toFixed(1) + "%"}
                                    </Col>
                                  </div>

                                  <div className="display_inovice">
                                    <Col className="ticket_heading display_head">
                                      Trucking
                                    </Col>
                                    <Col className="ticket_heading display_val">
                                      {"$" +
                                        this.grossProfit(
                                          Object.values(groupedData[e]),
                                          "truckingPriceTotal",
                                          "truckingCostTotal"
                                        )?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </Col>
                                    <Col className="ticket_heading">
                                      {this.profitPercentage(
                                        Object.values(groupedData[e]),
                                        "truckingPriceTotal",
                                        "truckingCostTotal"
                                      )?.toFixed(1) + "%"}
                                    </Col>
                                  </div>

                                  <div className="display_inovice">
                                    <Col className="ticket_heading display_head">
                                      Material
                                    </Col>
                                    <Col className="ticket_heading display_val">
                                      {"$" +
                                        this.grossProfit(
                                          Object.values(groupedData[e]),
                                          "materialPriceTotal",
                                          "materialCostTotal"
                                        )?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </Col>
                                    <Col className="ticket_heading">
                                      {this.profitPercentage(
                                        Object.values(groupedData[e]),
                                        "materialPriceTotal",
                                        "materialCostTotal"
                                      )?.toFixed(1) + "%"}
                                    </Col>
                                  </div>
                                </Col>
                              </Row>
                            </Collapse.Panel>
                          </Collapse>
                        </>
                      ))}
                    {Object.keys(groupedData).length > 0 && (
                      <div
                        className="disp_fixed show-on-desktop"
                        style={{ height: "50px", marginBottom: "20px" }}
                      >
                        <Pagination
                          style={{ float: "right", marginTop: "1vw" }}
                          total={Object.keys(groupedData).length}
                          showTotal={(total) => `${total} items`}
                          current={currentPage}
                          defaultPageSize={perpageSize}
                          onChange={this.handlePagination}
                          defaultCurrent={1}
                        />
                      </div>
                    )}
                  </Col>
                ) : (
                  <Nodata />
                )}
              </Row>
            ) : (
              <Col
                xxl={{ span: 24 }}
                xl={{ span: 24 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Row
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    marginBottom: "1vw",
                  }}
                >
                  <Col
                    span={24}
                    style={{ justifyContent: "end", display: "flex" }}
                  >
                    <Col className="gridbtntool">
                      <Button
                        className="hover_style"
                        style={{
                          borderRadius: "5px",
                          height: "40px",
                          border: "none",
                        }}
                        onClick={() =>
                          this.setState({ showInvoiced: !showInvoiced })
                        }
                      >
                        <CheckOutlined
                          style={{
                            color: "#586370",
                            fontSize: "17px",
                            fontWeight: "500",
                          }}
                        />
                        <span
                          style={{
                            color: "#586370",
                            fontSize: "17px",
                            fontWeight: "500",
                          }}
                        >
                          {showInvoiced ? "Show Invoiced" : "Hide Invoiced"}
                        </span>
                      </Button>
                    </Col>

                    {selectedFiles.length > 0 && (
                      <Col className="gridbtntool">
                        <Button
                          style={{
                            height: "40px",
                            backgroundColor: "#586370",
                          }}
                          onClick={() => {
                            this.downloadZip(selectedFiles, cardDatefiled);
                          }}
                          icon={
                            <DownloadOutlined
                              style={{
                                fontSize: "20px",
                                marginLeft: "8px",
                                marginTop: "2px",
                                color: "white",
                              }}
                              className="trucking-filter-icon"
                            />
                          }
                        >
                          <span
                            style={{
                              color: "white",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                            className="col_styling table-font-mobile"
                          >
                            Download
                          </span>
                        </Button>
                      </Col>
                    )}
                  </Col>
                </Row>
                <Card bodyStyle={{ padding: "0px" }}>
                  <BaseTable
                    className="financeinvoice_table"
                    columnsData={columns}
                    source={
                      showInvoiced
                        ? filterInvoiceData
                            .filter((j) => j.updateInvoice !== "Invoiced")
                            ?.sort((a, b) =>
                              a.invoiceNumber > b.invoiceNumber ? -1 : 1
                            )
                        : filterInvoiceData
                            ?.filter((j) => j.updateInvoice === "Invoiced")
                            .sort((a, b) =>
                              a.invoiceNumber > b.invoiceNumber ? -1 : 1
                            )
                    }
                    rowSelection={rowSelection}
                    total={
                      showInvoiced
                        ? filterInvoiceData.filter(
                            (j) => j.updateInvoice !== "Invoiced"
                          )?.length
                        : filterInvoiceData?.filter(
                            (j) => j.updateInvoice === "Invoiced"
                          )?.length
                    }
                    rowKey={(record) => record?.id}
                    sizeRange={
                      window.screen.width > 1023
                        ? JSON.parse(localStorage.getItem("tableSizeRange"))
                            ?.FinanceInvoiceManagement
                        : filterInvoiceData?.length
                    }
                    pagination={window.screen.width > 1023 ? true : false}
                    handleTableChage={(e) =>
                      this.handleTableChage(e, "FinanceInvoiceManagement")
                    }
                  />
                </Card>
              </Col>
            )}
          </>
        )}

        {this.state.visible && (
          <Filter visible={this.state.visible} closeFilter={this.closeFilter} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) =>
  // console.log(state),
  ({
    totalResult: state?.reducer?.invoicesResult,
    loading: state?.reducer?.invoicesResult?.loading,
    errorLoading: state?.reducer?.invoicesResult?.error,
    accountsResult: state?.reducer?.accountsResult,
  });

const mapDispatchToProps = (dispatch) => ({
  actionUpdateInvoice: (id, data) => {
    dispatch(updateInvoices(id, data));
  },
  actionFetchGetInvoices: () => {
    dispatch(getInvoices());
  },
  getAccountsPayable: () => {
    dispatch(getAccountsPayable());
  },

  // actionFetchGetInvoiceDetails: (id) => {
  //   dispatch(getInvoiceDetails(id));
  // },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinanceInvoiceManagement);
