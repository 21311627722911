import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Popover,
  Form,
  Input,
  Select,
  message,
  notification,
  Popconfirm,
} from "antd";
import { BsArrowLeftShort } from "react-icons/bs";
import { EllipsisOutlined, PlusOutlined } from "@ant-design/icons";
// import NumberFormat from "react-number-format";
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import appConfig from "../../config/AppConfig";
import Loading from "../../Common/Loading";
import ErrorView from "../../Common/ErrorView";
import SearchFunction from "../../Common/SearchFunction";
import BaseTable from "../../Common/BaseTable";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import AOS from "aos";
import "aos/dist/aos.css";
import { USAPhoneNumberFormate } from "../../Utilities";
import { connect } from "react-redux";
import {
  addUser,
  deleteUser,
  getUsers,
  updateUser,
  getRoles,
} from "../../Redux/Actions/actions";
import { withAppContext } from "../../Store/AppContext";
import { withRouter } from "react-router-dom";
import { add } from "../../api/Apis";

class SystemUsers extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: "",
      boardView: true,
      listView: false,
      selectionType: "",
      selected: true,
      color: "active",
      usersData: [],
      isVisible: false,
      isModalVisible: false,
      leadsData: "",
      columnsData: [],
      isEditForm: false,
      filterData: [],
      selectedAddress: "",
      popupVisible: false,
      email: "",
      password: "",
      editData: false,
      totalRolesData: [],
      rolesDetails: [],
      setRoleOfUser: "",
    };
  }

  componentDidMount() {
    this.props?.actionFetchGetUsers();
    this.props?.actionFetchGetRoles();
    document.title = "Twisted Nail - System Users";
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.totalUsersResult?.success === false &&
      this.props?.totalUsersResult?.success === true
    ) {
      this.setState({
        usersData: this.props?.totalUsersResult?.usersData,
        filterData: this.props?.totalUsersResult?.usersData,
      });
    }
    if (
      prevProps?.usersInfo?.success === false &&
      this.props?.usersInfo?.success === true
    ) {
      this.props?.actionFetchGetUsers();
      this.setState({
        selectedAddress: "",
      });
    }
    if (
      prevProps?.rolesResult?.success === false &&
      this.props?.rolesResult?.success === true
    ) {
      let rolesDetails = [{ value: "All", id: "123", label: "All" }];

      this.props?.rolesResult?.rolesData.map((eachRole) => {
        rolesDetails.push({
          value: eachRole.roleName,
          id: eachRole.id,
          label: eachRole.roleName,
        });
        return true;
      });

      this.setState({
        totalRolesData: this.props?.rolesResult?.rolesData,
        rolesDetails,
      });
    }
  }

  onClickRefresh = () => {
    this.props?.actionFetchGetUsers();
    this.props?.actionFetchGetRoles();
  };

  deleteUser = (record) => this.props?.actionFetchDeleteUser(record?.id);

  editForm = (record) => {
    this.setState({ isVisible: true, leadsData: record, editData: true });
  };

  handleSubmit = async (values) => {
    values["address"] = this.state.selectedAddress;
    // console.log(values.phone.length);
    //  / phoneNumber > 10
    if (values.phone.length < 10) {
      console.log("iiiiiii");
      notification["error"]({
        message: "Please enter valid phone number",
      });

      // .message("");
      return;
    }
    this.setState({
      isVisible: false,
    });
    let lowerEmail = values.email.toLowerCase();
    let data = {
      firstName: values.firstName,
      lastName: values.lastName,
      password: values.password,
      phone: values.phone,
      email: lowerEmail,
      userProfile: values.userProfile,
      company: values.company,
      address: values.address,
      recordStatus: true,
      userRoleInfo: this.state.setRoleOfUser,
    };
    this.saveUser(data);
  };

  saveUser = async (registerData) => {
    const data = {
      entityData: registerData,
    };
    this.props?.actionFetchAddUser(data);
  };

  onFinish = (values) => {
    this.setState({ isVisible: false });
    const { leadsData, editData, setRoleOfUser } = this.state;
    // console.log("check", values, editData);
    let data = {
      entityData: {
        firstName: values["firstName"],
        lastName: values["lastName"],
        email: values["email"],
        phone: values["phone"],
        userProfile: values["userProfile"],
        company: values["company"],
        address: this.state.selectedAddress || leadsData.address,
        userRoleInfo: setRoleOfUser || editData?.userRoleInfo,
      },
    };
    if (editData) {
      this.props?.actionFetchUpdateUser(leadsData?.id, data);
    } else {
      console.log(this.state.setRoleOfUser);
    }
  };

  onVisible = () => {
    this.setState({ isVisible: true, leadsData: "", editData: false });
  };

  closeFilter = () => {
    this.setState({
      visible: false,
    });
  };

  searchResult = (searchValue) => {
    const { usersData } = this.state;
    let searchFilter = [];
    if (typeof searchValue === "number") {
      searchFilter = usersData.filter(
        (e) => parseInt(e["phone"]?.replace(/[^+0-9]/g, "")) === searchValue
      );
    } else {
      searchFilter = usersData.filter(
        (e) =>
          e["email"]?.toLowerCase().indexOf(searchValue) > -1 ||
          e["firstName"]?.toLowerCase().indexOf(searchValue) > -1 ||
          e["lastName"]?.toLowerCase().indexOf(searchValue) > -1
      );
    }

    this.setState({
      filterData: searchFilter,
    });
  };

  onResetData = () => {
    this.props?.actionFetchGetUsers();
    this.props?.actionFetchGetRoles();
  };

  onChangeRole = (value) => {
    this.setState({
      selectedValue: value,
    });
  };

  getAddress = (address, latLng, id) => {
    this.setState({
      selectedAddress: address.label,
    });
  };

  handleSelect = (address) => {
    this.setState({
      citySearch: address.label,
    });
    let cityValue = "";
    let stateValue = "";
    let zipCodeValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        const geoIdData = results[0];
        this.setState({
          geoIdData,
        });
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
          if (address[i].types[0] === "postal_code") {
            zipCodeValue = address[i].long_name;
          }
        }
        this.setState({
          city: cityValue,
          addressState: stateValue,
          zipCode: zipCodeValue,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  onClickReturn = () => {
    this.setState({
      isVisible: false,
    });
  };

  handleToSelectRole = (value, option) => {
    this.setState({
      setRoleOfUser: option.key,
    });
  };

  content = () => {
    return (
      <div>
        <p>-Minimum password length: 8</p>{" "}
        <p>-Required at least one uppercase letter</p>{" "}
        <p>-Required at least one lowercase letter</p>{" "}
        <p>-Required at least one number</p>{" "}
        <p>-Required at least one nonalphanumeric character</p>{" "}
      </div>
    );
  };

  handleTableChage = (props) => {
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    localStorage.setItem(
      "tableSizeRange",
      JSON.stringify({ ...tableSizeRange, systemUsers: props })
    );
  };

  updatePassword = (e) => {
    const { leadsData } = this.state;
    let data = {
      entityData: {
        username: leadsData.email,
        newPassword: e.newPassword,
      },
    };
    add("resetPassword", data)
      .then((res) => {
        message.success("Your password updated successfully");
        console.log(res, "res");
      })
      .catch((err) => {
        console.log(err);
      });

    // Auth.currentAuthenticatedUser()
    //   .then((user) => {
    //     console.log(user, "user");
    //     return Auth.changePassword(user, e.oldPassword, e.newPassword);
    //   })
    //   .then((data) => {
    //     if (data === "SUCCESS") {
    //       message.success("Your password updated successfully");
    //       this.formRef.current.setFieldsValue({
    //         oldPassword: "",
    //         newPassword: "",
    //       });
    //       this.setState({
    //         isVisible: false,
    //       });
    //     }
    //   })
    //   .catch((err) => {
    //     message.error("Your Old Password Is Incorrect");
    //   });
  };

  render() {
    const {
      isVisible,
      leadsData,
      usersData,
      filterData,
      selectedValue,
      editData,
      totalRolesData,
      rolesDetails,
    } = this.state;
    const { loading, errorLoading } = this.props;

    const filteredData = usersData.filter(
      (e) => e?.userProfile === selectedValue
    );

    const searchFields = ["firstName", "lastName", "email", "phone"];
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    let filterRolesData = totalRolesData;
    if (userDetails?.userProfile !== "Admin") {
      filterRolesData = totalRolesData?.filter((e) => e.roleName !== "Admin");
    }
    const columns = [
      {
        title: "FIRSTNAME",
        dataIndex: "firstName",
        key: "firstName",
        sorter: false,
        className: "col_styling_system_users",
        onCell: (record) => ({
          onClick: () => console.log(record.id),
        }),
        render: (firstName) => {
          return (
            <Col
              className="col_styling_system_users"
              style={{
                textTransform: "capitalize",
                width: "150px",
              }}
            >
              <Row style={{ color: "#242424", fontSize: "14px" }}>
                {firstName}
              </Row>
            </Col>
          );
        },
      },
      {
        title: "LAST NAME",
        dataIndex: "lastName",
        key: "lastName",
        sorter: false,
        className: "col_styling_system_users",
        onCell: (record) => ({
          onClick: () => console.log(record.id),
        }),
        render: (lastName) => {
          return (
            <span
              className="col_styling"
              style={{
                textTransform: "capitalize",
                width: "10px",
              }}
            >
              <Row style={{ color: "#242424", fontSize: "14px" }}>
                {lastName}
              </Row>
            </span>
          );
        },
      },
      {
        title: "EMAIL",
        dataIndex: "email",
        key: "email",
        sorter: false,
        className: "col_styling_system_users",
        onCell: (record) => ({
          onClick: () => console.log(record.id),
        }),
        render: (email) => {
          return (
            <span style={{ color: "#242424" }} className="col_styling">
              {email}
            </span>
          );
        },
      },
      {
        title: "PHONE",
        dataIndex: "phone",
        key: "phone",
        sorter: false,
        className: "col_styling_system_users",
        onCell: (record) => ({
          onClick: () => console.log(record.id),
        }),
        render: (phone) => {
          return (
            <span
              className="col_styling"
              style={{
                textTransform: "capitalize",
              }}
            >
              {USAPhoneNumberFormate(phone)}
            </span>
          );
        },
      },
      {
        title: "ROLE",
        dataIndex: "userProfile",
        key: "userProfile",
        sorter: false,
        className: "col_styling_system_users",
        onCell: (record) => ({
          onClick: () => console.log(record.id),
        }),
        render: (userProfile) => {
          return (
            <Button
              className="col_styling_system_users"
              style={{
                textTransform: "capitalize",
                color: appConfig["role_colors"][userProfile],
                borderColor: appConfig["role_colors"][userProfile],
                borderRadius: "6px",
              }}
            >
              {userProfile}
            </Button>
          );
        },
      },

      {
        title: "",
        dataIndex: "",
        key: "",
        sorter: false,
        fixed: "right",
        render: (action, record) => {
          return (
            <>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  flexWrap: "nowrap",
                }}
              >
                {userDetails.userProfile === "Admin" && (
                  <Col>
                    <Popover
                      placement="left"
                      content={
                        <Row
                          className="popovergrid"
                          style={{ maxWidth: "90px" }}
                        >
                          <Col span={24}>
                            <Button
                              className="btn_edit"
                              onClick={() => this.editForm(record)}
                            >
                              <span className="span_edit">
                                <MdModeEditOutline
                                  style={{ marginRight: "5px" }}
                                />{" "}
                                Edit
                              </span>
                            </Button>
                          </Col>
                          <Col span={24}>
                            <Button className="btn_edit">
                              <Popconfirm
                                title="Are you sure？"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => this.deleteUser(record)}
                              >
                                <span className="span_edit">
                                  <MdDelete style={{ marginRight: "5px" }} />{" "}
                                  Delete
                                </span>
                              </Popconfirm>
                            </Button>
                          </Col>
                        </Row>
                      }
                    >
                      <EllipsisOutlined
                        style={{ fontSize: "30px", color: "grey" }}
                        className="action-ellipsis-button"
                      />
                    </Popover>
                  </Col>
                )}
              </Row>
            </>
          );
        },
      },
    ];

    return (
      <div
        style={{
          background: "#fafafa",
        }}
      >
        <Loading enableLoading={loading} />

        <Row>
          <Col
            xxl={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xs={{ span: 24, offset: 0 }}
          >
            <Row style={{ marginBottom: "1vw" }}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 24 }}
                xxl={{ span: 24 }}
                // span={12}
              >
                {!isVisible ? (
                  <Row>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 10 }}
                      md={{ span: 10 }}
                      lg={{ span: 10 }}
                      xl={{ span: 6 }}
                      xxl={{ span: 6 }}
                      className="quarrytoolgrid"
                      style={{ paddingLeft: "-24px" }}
                    >
                      <SearchFunction
                        tableName={"users"}
                        getSearchData={this.searchResult}
                        onResetData={this.onResetData}
                        searchFields={searchFields}
                      />
                    </Col>
                    {!isVisible && (
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 14 }}
                        md={{ span: 14 }}
                        lg={{ span: 14 }}
                        xl={{ span: 18 }}
                        xxl={{ span: 18 }}
                        // span={12}
                      >
                        <Row className="system-users-layout">
                          <Col className="gridbtntool">
                            <span
                              style={{
                                fontSize: "18px",
                                color: "#585858",
                                lineHeight: "2",
                              }}
                              className="owner-operator-card-body"
                            >
                              Roles :
                            </span>
                            <Select
                              // value={selectedValue || "Actions"}
                              className="select_truckDriver"
                              onChange={this.onChangeRole}
                              bordered={false}
                              defaultValue="All"
                              style={{ float: "right", height: "35px" }}
                            >
                              {rolesDetails.map((eachRole) => (
                                <Select.Option
                                  value={eachRole.value}
                                  key={eachRole.id}
                                >
                                  {eachRole.value}
                                </Select.Option>
                              ))}
                            </Select>
                          </Col>

                          <Col className="gridbtntool">
                            <Button
                              type="primary"
                              icon={<PlusOutlined />}
                              style={{
                                borderRadius: "5px",
                                height: "40px",
                              }}
                              onClick={this.onVisible}
                              className="owner-operator-card-body"
                            >
                              <span
                                style={{ fontWeight: "500", fontSize: "16px" }}
                                className="owner-operator-card-body"
                              >
                                User
                              </span>
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </Row>
                ) : (
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Row
                      onClick={this.onClickReturn}
                      style={{ cursor: "pointer" }}
                    >
                      <BsArrowLeftShort
                        style={{ color: "#808080", fontSize: "30px" }}
                        className="back-button-return-icon"
                      />
                      <span
                        style={{ color: "#808080", fontSize: "18px" }}
                        className="back-button-return"
                      >
                        Return
                      </span>
                    </Row>
                  </Row>
                )}
              </Col>
            </Row>

            <Card
              bodyStyle={{ textAlign: "left" }}
              className="manage-roles-card"
            >
              {isVisible ? (
                <>
                  <Form
                    id="adduserform"
                    onFinish={editData ? this.onFinish : this.handleSubmit}
                    style={{ padding: "24px" }}
                    destroyOnClose={true}
                  >
                    <Col
                      xxl={{ span: 12, offset: 6 }}
                      xl={{ span: 12, offset: 6 }}
                      lg={{ span: 12, offset: 6 }}
                      md={{ span: 24, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      xs={{ span: 24, offset: 0 }}
                    >
                      <Row gutter={[24, 0]}>
                        <Col
                          xxl={{ span: 12, offset: 0 }}
                          xl={{ span: 12, offset: 0 }}
                          lg={{ span: 12, offset: 0 }}
                          md={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          xs={{ span: 24, offset: 0 }}
                        >
                          <span className="title_changes">First Name</span>
                          <Form.Item
                            name="firstName"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Your First Name!",
                              },
                            ]}
                            initialValue={leadsData?.firstName}
                          >
                            <Input
                              className="form-input"
                              style={{ width: "100%" }}
                              placeholder="Please Enter Your First Name"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xxl={{ span: 12, offset: 0 }}
                          xl={{ span: 12, offset: 0 }}
                          lg={{ span: 12, offset: 0 }}
                          md={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          xs={{ span: 24, offset: 0 }}
                        >
                          <span className="title_changes">Last Name</span>
                          <Form.Item
                            name="lastName"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Your Last Name!",
                              },
                            ]}
                            initialValue={leadsData?.lastName}
                          >
                            <Input
                              className="form-input"
                              style={{ width: "100%" }}
                              placeholder="Please Enter Your Last Name"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          // span={24}
                          xxl={{ span: 24, offset: 0 }}
                          xl={{ span: 24, offset: 0 }}
                          lg={{ span: 24, offset: 0 }}
                          md={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          xs={{ span: 24, offset: 0 }}
                        >
                          <span className="title_changes">Company</span>
                          <Form.Item
                            className="Formitem"
                            name="company"
                            rules={[
                              {
                                required: true,
                                message: "Please input your company name!",
                              },
                            ]}
                            initialValue={"Twisted Nail Broker Services, LLC"}
                          >
                            <Input
                              placeholder="Company name"
                              className="form-input"
                            />
                          </Form.Item>
                        </Col>

                        <Col
                          // span={24}
                          xxl={{ span: 24, offset: 0 }}
                          xl={{ span: 24, offset: 0 }}
                          lg={{ span: 24, offset: 0 }}
                          md={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          xs={{ span: 24, offset: 0 }}
                        >
                          <span className="title_changes">
                            {" "}
                            Mailing Address
                          </span>
                          <Form.Item
                            className="Formitem"
                            name=" Mailing Address"
                            rules={[
                              {
                                errorMessage: true,
                                message: "Please Enter Your  Mailing Address!",
                                // required:
                                //   this.state.selectedAddress.length !== 0
                                //     ? false
                                //     : true,
                                // message: "Please enter your Address!",
                              },
                            ]}
                          >
                            <GooglePlacesAutocomplete
                              className="form-input"
                              autocompletionRequest={{
                                componentRestrictions: {
                                  country: ["us"],
                                },
                              }}
                              selectProps={{
                                //citySearch,
                                defaultInputValue: `${
                                  leadsData &&
                                  (leadsData.address ? leadsData?.address : "")
                                }`,
                                placeholder: "Search Address...",
                                onChange: (address) => {
                                  this.handleSelect(address);
                                  this.getAddress(address);
                                },
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={[24, 0]}>
                        <Col
                          // span={12}
                          xxl={{ span: 12, offset: 0 }}
                          xl={{ span: 12, offset: 0 }}
                          lg={{ span: 12, offset: 0 }}
                          md={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          xs={{ span: 24, offset: 0 }}
                        >
                          <span className="title_changes">Email</span>
                          <Form.Item
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: "Please input email",
                              },
                              {
                                type: "email",
                                message: "The Input Is Not Valid Email",
                              },
                            ]}
                            initialValue={leadsData?.email}
                          >
                            <Input
                              placeholder="Email address"
                              className="form-input"
                              readOnly={editData ? true : ""}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          // span={12}
                          xxl={{ span: 12, offset: 0 }}
                          xl={{ span: 12, offset: 0 }}
                          lg={{ span: 12, offset: 0 }}
                          md={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          xs={{ span: 24, offset: 0 }}
                        >
                          <span className="title_changes">Phone</span>
                          <Form.Item
                            name="phone"
                            rules={[
                              {
                                required: true,
                                message: "Please input your contact phone!",
                              },
                            ]}
                            initialValue={leadsData?.phone}
                          >
                            <ReactPhoneInput
                              inputExtraProps={{
                                name: "contactMainPhone",
                                required: true,
                                // autoFocus: true,
                              }}
                              placeholder=""
                              country="us"
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      {!editData && (
                        <Row gutter={[24, 0]}>
                          <Col
                            // span={12}
                            xxl={{ span: 12, offset: 0 }}
                            xl={{ span: 12, offset: 0 }}
                            lg={{ span: 12, offset: 0 }}
                            md={{ span: 24, offset: 0 }}
                            sm={{ span: 24, offset: 0 }}
                            xs={{ span: 24, offset: 0 }}
                          >
                            <span className="title_changes">Password</span>
                            <Form.Item
                              name="password"
                              rules={[
                                {
                                  required: true,
                                  pattern:
                                    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])(?=.*[A-Z])(?=.*[a-z])[A-Za-z\d@$!%*#?&]{8,}$/,
                                  message: "Please Match The Password Criteria",
                                },
                              ]}
                              hasFeedback
                              style={{ marginBottom: "0px" }}
                            >
                              <Input.Password
                                placeholder="Password"
                                autoComplete="new-password"
                                className="form-input"
                              />
                            </Form.Item>
                            <Popover
                              content={this.content}
                              title="Password Criteria"
                            >
                              <Button
                                className="criteria"
                                type="text"
                                style={{
                                  color: "#39acda",
                                  marginLeft: "-15px",
                                  background: "transparent",
                                  border: "none",
                                }}
                              >
                                Password Criteria{" "}
                              </Button>
                            </Popover>
                          </Col>
                          <Col
                            // span={12}
                            xxl={{ span: 12, offset: 0 }}
                            xl={{ span: 12, offset: 0 }}
                            lg={{ span: 12, offset: 0 }}
                            md={{ span: 24, offset: 0 }}
                            sm={{ span: 24, offset: 0 }}
                            xs={{ span: 24, offset: 0 }}
                          >
                            <span className="title_changes">
                              Confirm Password
                            </span>
                            <Form.Item
                              name="confirm"
                              dependencies={["password"]}
                              hasFeedback
                              rules={[
                                {
                                  required: true,
                                  message: "Please confirm your password!",
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (
                                      !value ||
                                      getFieldValue("password") === value
                                    ) {
                                      return Promise.resolve();
                                    }
                                    return Promise.reject(
                                      new Error(
                                        "The two passwords that you entered do not match!"
                                      )
                                    );
                                  },
                                }),
                              ]}
                            >
                              <Input.Password
                                placeholder="Confirm Password"
                                className="form-input"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      )}

                      <Col
                        // span={24}
                        xxl={{ span: 24, offset: 0 }}
                        xl={{ span: 24, offset: 0 }}
                        lg={{ span: 24, offset: 0 }}
                        md={{ span: 24, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        xs={{ span: 24, offset: 0 }}
                      >
                        <span className="title_changes">Role</span>
                        <Form.Item
                          name="userProfile"
                          rules={[
                            {
                              required: true,
                              message: "Please Select Role!",
                            },
                          ]}
                          initialValue={leadsData?.userProfile}
                        >
                          <Select
                            allowClear
                            className="select_Height"
                            style={{ height: "40px", fontWeight: "300" }}
                            placeholder="Please Select Role"
                            showSearch
                            optionFilterProp="children"
                            onChange={this.handleToSelectRole}
                          >
                            {filterRolesData.map((eachRole) => (
                              <Select.Option
                                value={eachRole.roleName}
                                key={eachRole.id}
                              >
                                {eachRole.roleName}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col
                        // span={24} offset={0}
                        xxl={{ span: 24, offset: 0 }}
                        xl={{ span: 24, offset: 0 }}
                        lg={{ span: 24, offset: 0 }}
                        md={{ span: 24, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        xs={{ span: 24, offset: 0 }}
                      >
                        <div
                          style={{
                            // marginBottom: "20px",
                            marginLeft: "auto",
                            maxWidth: "180px",
                          }}
                        >
                          <Row>
                            <Col
                              xxl={{ span: 12, offset: 0 }}
                              xl={{ span: 12, offset: 0 }}
                              lg={{ span: 12, offset: 0 }}
                              md={{ span: 12, offset: 0 }}
                              sm={{ span: 12, offset: 0 }}
                              xs={{ span: 12, offset: 0 }}
                            >
                              <Button
                                className="btn-styl"
                                style={{
                                  // backgroundColor: "#db1901",
                                  color: "#586370",
                                  border: "1px solid #d9d9d9",
                                }}
                                onClick={() => {
                                  this.setState({
                                    isVisible: false,
                                    editData: false,
                                    leadsData: "",
                                  });
                                }}
                                onReset={true}
                                htmlType="reset"
                                form="adduserform"
                              >
                                Cancel
                              </Button>
                            </Col>
                            <Col
                              xxl={{ span: 11, offset: 1 }}
                              xl={{ span: 11, offset: 1 }}
                              lg={{ span: 11, offset: 1 }}
                              md={{ span: 11, offset: 1 }}
                              sm={{ span: 11, offset: 1 }}
                              xs={{ span: 11, offset: 1 }}
                            >
                              <Button
                                className="btn-styl"
                                style={
                                  {
                                    // backgroundColor: "#248be6",
                                    // marginRight: "15px",
                                  }
                                }
                                htmlType="submit"
                                type="primary"
                                form="adduserform"
                              >
                                {editData ? "Update" : "Save"}
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Col>
                  </Form>
                </>
              ) : errorLoading ? (
                <ErrorView refreshUsers={this.onClickRefresh} type="Users" />
              ) : (
                <div data-aos="fade-up">
                  <BaseTable
                    className="table_system_users table_crm table_laptopscreen"
                    columnsData={columns}
                    source={filteredData.length > 0 ? filteredData : filterData}
                    total={
                      filteredData.length > 0
                        ? filteredData.length
                        : filterData.length
                    }
                    handleTableChage={this.handleTableChage}
                    sizeRange={
                      window.screen.width > 1023
                        ? JSON.parse(localStorage.getItem("tableSizeRange"))
                            ?.systemUsers
                        : filteredData.length > 0
                        ? filteredData.length
                        : filterData.length
                    }
                    pagination={window.screen.width > 1023 ? true : false}
                  />
                </div>
              )}
            </Card>

            {isVisible && editData && userDetails?.userProfile === "Admin" && (
              <Card style={{ margin: "20px 0px" }}>
                <Row gutter={[20, 0]}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                    xl={{ span: 5 }}
                    xxl={{ span: 5 }}
                  >
                    <h1
                      style={{
                        margin: "20px 0px",
                        fontSize: "20px",
                        fontWeight: "600",
                        textTransform: "capitalize",
                        color: "#000",
                      }}
                    >
                      Change Password
                    </h1>
                  </Col>
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    lg={{ span: 24, offset: 0 }}
                    xl={{ span: 12, offset: 1 }}
                    xxl={{ span: 12, offset: 1 }}
                  >
                    <Form
                      id="changepassowrd"
                      layout="vertical"
                      ref={this.formRef}
                      onFinish={this.updatePassword}
                      style={{ margin: "20px 0" }}
                    >
                      <Row gutter={[40, 0]}>
                        {/* <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                          xl={{ span: 12 }}
                          xxl={{ span: 12 }}
                        >
                          <Form.Item
                            name="oldPassword"
                            rules={[
                              {
                                required: true,
                                // pattern:
                                //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])(?=.*[A-Z])(?=.*[a-z])[A-Za-z\d@$!%*#?&]{8,}$/,
                                // message: "Password Doesn't Match",
                              },
                            ]}
                            hasFeedback
                            style={{ marginBottom: "0px" }}
                          >
                            <Input.Password
                              placeholder="Old Password"
                              autoComplete="new-password"
                              className="form-input"
                            />
                          </Form.Item>
                        </Col> */}
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}
                          xl={{ span: 24 }}
                          xxl={{ span: 24 }}
                        >
                          <Form.Item
                            name="newPassword"
                            rules={[
                              {
                                required: true,
                                pattern:
                                  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])(?=.*[A-Z])(?=.*[a-z])[A-Za-z\d@$!%*#?&]{8,}$/,
                                message: "Password Critieria Doesn't Match",
                              },
                            ]}
                            hasFeedback
                            style={{ marginBottom: "0px" }}
                          >
                            <Input.Password
                              placeholder="New Password"
                              autoComplete="new-password"
                              className="form-input"
                            />
                          </Form.Item>
                          <Popover
                            content={this.content}
                            title="Password Criteria"
                          >
                            <Button
                              className="criteria"
                              type="text"
                              style={{
                                color: "#39acda",
                                marginLeft: "-15px",
                                background: "transparent",
                                border: "none",
                                float: "left",
                              }}
                            >
                              Password Criteria{" "}
                            </Button>
                          </Popover>
                        </Col>

                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}
                          xl={{ span: 24 }}
                          xxl={{ span: 24 }}
                          style={{ marginTop: "1vw" }}
                        >
                          <Button
                            htmlType="submit"
                            type="primary"
                            style={{
                              // width: "100%",
                              marginLeft: "auto",
                              display: "flex",
                              justifyContent: "right",
                              // float: "left",
                            }}
                            form="changepassowrd"
                          >
                            Update Password
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  totalUsersResult: state?.reducer?.usersResult,
  loading: state?.reducer?.usersResult?.loading,
  errorLoading: state?.reducer?.error,
  usersInfo: state?.reducer?.usersInfo,
  rolesResult: state?.reducer?.rolesResult,
});

const mapDispatchToProps = (dispatch) => ({
  actionFetchGetUsers: () => {
    dispatch(getUsers());
  },
  actionFetchAddUser: (data) => {
    dispatch(addUser(data));
  },
  actionFetchUpdateUser: (id, data) => {
    dispatch(updateUser(id, data));
  },
  actionFetchDeleteUser: (id) => {
    dispatch(deleteUser(id));
  },
  actionFetchGetRoles: () => {
    dispatch(getRoles());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAppContext(withRouter(SystemUsers)));
