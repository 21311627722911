import axios from "axios/index";
import _ from "lodash";
import AppConfig from "../config/AppConfig";
import { Auth } from "aws-amplify";
export function PageParams(page, perPage) {
  return `page=${page || 1}&per_page=${perPage || AppConfig.perPage}`;
}
export function ApiUrl(path) {
  return `${AppConfig.API_GATEWAY}/api/${path}`;
}

export function noAuthApiUrl(path) {
  return `${AppConfig.API_GATEWAY}/noAuth/${path}`;
}

export function HttpErrorMessage(error) {
  const status = error.response ? error.response.status : "";
  if (_.get(error, "response.data.errors", null)) {
    return {
      message: error.response.data.errors,
      code: status,
    };
  } else if (_.get(error, "response.data", null)) {
    return {
      message: error.response.data,
      code: status,
    };
  }
  return [error.message, status];
}

export function ParseGeneralResponse(success, response, options) {
  const result = { success };
  if (!_.isEmpty(options.return)) {
    result.return = options.return;
  }
  if (success) {
    const dataKeys = options.responseDataKeys || {};
    _.forEach(dataKeys, (value, key) => {
      const valueKey =
        _.isEmpty(value) && key === "data"
          ? "data"
          : _.isEmpty(value) && key === "headers"
          ? "headers"
          : `data.${value}`;
      result[key] = _.get(response, valueKey, null);
    });
  } else {
    result.errors = HttpErrorMessage(response);
  }
  return result;
}

export async function Call(
  method,
  url,
  data,
  parseCallback,
  options = {},
  authorization
) {
  const dataObj = {
    method,
    url,
    data,
  };
  if (authorization) {
    // const { token } = JSON.parse(utils.getCookie('user'));
    let token;
    await Auth.currentSession()
      .then((response) => {
        token = response.idToken.jwtToken;
      })
      .catch((error) => {
        console.log(error);
        window.location.assign("/login");
      });
    dataObj.headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  return new Promise((resolve) => {
    axios(dataObj)
      .then((response) => {
        resolve(parseCallback(true, response, options));
      })
      .catch(async (error) => {
        // addError(error);
        // console.log(error, "console error", error.response);
        if (error.response && error.response.status === 401) {
          // window.location.assign("/login");
          // alert("Your session has expired.Please login!");
        } else if (error.response && error.response.status === 400) {
          // window.location.assign("/pageNotFound/newtWork");
          // alert("Something went wrong... Please try again...");
        } else if (error.response && error.response.status === 502) {
          // window.location.assign("/pageNotFound/newtWork");
          // alert("Something went wrong... Please try again...");
        }

        if (error && !error.response) {
          // window.location.assign("/pageNotFound/newtWork");
          // alert("Please check your network and try again");
          // console.log(error);
        }
        resolve(parseCallback(false, error, options));
      });
  });
}
