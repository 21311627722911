import { Table } from "antd";
import { useEffect } from "react";

const BaseTable = (props) => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      const lastDiv = document.querySelector(
        ".rc-virtual-list-holder-inner > div:last-child"
      );

      if (lastDiv) {
        const title = lastDiv.getAttribute("title");
        if (title === `${props?.total} / page`) {
          lastDiv.style.padding = "5px 16px";
          lastDiv.innerHTML = "All / page";
        }
        clearInterval(intervalId); // Stop the interval once the content is updated
      }
    }, 300);

    return () => {
      clearInterval(intervalId); // Clean up the interval on component unmount
    };
  }, [props]);

  const onChange = (e, range, total) => {
    !props?.requiredChange && props?.handleTableChage(range);
    if (props?.scrollTop) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
    let data = total?.length / range;
    if (data === e) {
      props.getOwnerDetails();
    }
  };

  return (
    <div style={{ overflowX: "auto" }}>
      <Table
        className={props?.className}
        columns={props?.columnsData}
        dataSource={props?.source}
        rowKey={props?.rowKey}
        loading={props?.loading}
        rowSelection={props?.rowSelection}
        pagination={
          props?.pagination === false
            ? false
            : {
                defaultPageSize: props?.sizeRange,
                showSizeChanger: true,
                pageSizeOptions: props.pageSizePagination
                  ? [5, 10, 25, 50, 100, `${props?.total}`]
                  : [10, 25, 50, 100, `${props?.total}`],
                total: props?.total,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                onChange: (e, range) => onChange(e, range, props?.total),
              }
        }
        style={{ cursor: "pointer" }}
        scroll={props.scroll}
        sticky={props.sticky}
        // onRow={(record) => {
        //   return {
        //     onClick: () => {
        //       props.handleRow(record);
        //     },
        //   };
        // }}
      />
    </div>
  );
};

BaseTable.defaultProps = {
  className: "table_crm",
  pagination: true,
  // pageSize: ["10", "25", "50", "100"],
  scrollTop: true,
  sizeRange: 10,
};
export default BaseTable;
