import React, { Component } from "react";
import PanelJsonData from "../../Json/rolesData.json";
import {
  Row,
  Col,
  Card,
  Button,
  Input,
  Popover,
  Collapse,
  Checkbox,
  Form,
  message,
  Popconfirm,
} from "antd";
import { EllipsisOutlined, PlusOutlined } from "@ant-design/icons";
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import Loading from "../../Common/Loading";
import ErrorView from "../../Common/ErrorView";
import SearchFunction from "../../Common/SearchFunction";
import BaseTable from "../../Common/BaseTable";
import { BsArrowLeftShort } from "react-icons/bs";
import { withRouter } from "react-router-dom";
import { withAppContext } from "../../Store/AppContext";
import AOS from "aos";
import "aos/dist/aos.css";
import { connect } from "react-redux";
import {
  addRole,
  deleteRole,
  getRoles,
  updateRole,
} from "../../Redux/Actions/actions";

const { Panel } = Collapse;
class ManageRoles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: "",
      boardView: true,
      listView: false,
      loading: false,
      selectionType: "",
      selected: true,
      color: "active",
      isModalVisible: false,
      role: [],
      PanelInfo: {},
      options: [],
      roleName: "",
      totalRolesData: [],
      isEditModalVisible: false,
      leadsData: "",
      filterData: [],
      errorLoading: false,
      PanelData: [],
      defaultPannelsOpen: [],
      userData: "",
    };
  }

  rolesFormRef = React.createRef();

  componentDidMount() {
    this.setState({
      PanelData: PanelJsonData["PanelData"],
    });
    this.props?.actionsFetchGetRoles();
    document.title = "Twisted Nail - Manage Roles";
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.rolesResult?.success === false &&
      this.props?.rolesResult?.success === true
    ) {
      this.setState({
        totalRolesData: this.props?.rolesResult?.rolesData,
        filterData: this.props?.rolesResult?.rolesData,
        PanelData: [],
        isModalVisible: false,
      });
    }
    if (
      prevProps?.rolesInfo?.success === false &&
      this.props?.rolesInfo?.success === true
    ) {
      this.setState({
        roleLoading: false,
        PanelData: [],
        isModalVisible: false,
      });
      this.props?.actionsFetchGetRoles();
    }
    if (
      prevProps?.rolesUpdateInfo?.success === false &&
      this.props?.rolesUpdateInfo?.success === true
    ) {
      this.setState(
        {
          isEditModalVisible: false,
          roleLoading: false,
          PanelData: [],
          defaultPannelsOpen: [],
          isModalVisible: false,
        },
        () => this.props?.actionsFetchGetRoles(),
        this.props.context.getUserAccessInfo(this.state.userData?.userRoleInfo)
      );
    }
  }

  onRefresh = () => {
    this.props?.actionsFetchGetRoles();
  };

  roleName = (event) => {
    this.setState({ roleName: event.target.value });
  };

  showModal = () => {
    const CheckData = ["Edit", "View", "Delete"];
    const CheckInfo = ["Edit", "Data Input", "Review", "Delete"];
    const PanelData = [
      {
        key: "1",
        title: "Dashboard",
        options: CheckData,
        selectedOptions: [],
      },
      {
        key: "2",
        title: "CRM",
        multiPannel: [
          {
            key: "21",
            title: "Trucking & Material",
            options: CheckData,
            selectedOptions: [],
            path: "/app/trucking-material",
          },
          {
            key: "22",
            title: "Owner Operator",
            options: CheckData,
            selectedOptions: [],
            path: "/app/owner-operator",
          },
        ],
        selectedOptions: false,
        options: CheckData,
      },
      {
        key: "3",
        title: "Databases",
        multiPannel: [
          {
            key: "31",
            title: "Customer Database",
            options: ["Edit", "View", "Delete"],
            selectedOptions: [],
            path: "/app/customer-database",
          },
          {
            key: "32",
            title: "Owner Operator Database",
            options: ["Edit", "View", "Delete"],
            selectedOptions: [],
            path: "/app/twisted-nail",
          },
          {
            key: "33",
            title: "CDL Driver Database",
            options: CheckData,
            selectedOptions: [],
            path: "/app/truck-driver-database",
          },
          {
            key: "34",
            title: "Quarry Database",
            options: CheckData,
            selectedOptions: [],
            path: "/app/quarry-finder-tool",
          },
          {
            key: "35",
            title: "Material Database",
            options: CheckData,
            selectedOptions: [],
            path: "/app/material-database",
          },
          {
            key: "36",
            title: "Job Database",
            options: CheckData,
            selectedOptions: [],
            path: "/app/job-database",
          },
        ],
        selectedOptions: false,
      },
      {
        key: "4",
        title: "Operations",
        options: CheckData,
        multiPannel: [
          {
            key: "41",
            title: "Request Dispatch",
            options: CheckData,
            selectedOptions: [],
            path: "/app/dispatch-jobs",
          },
          {
            key: "42",
            title: "Dispatch Tool",
            options: CheckData,
            selectedOptions: [],
            path: "/app/dispatch-tool",
          },
          {
            key: "43",
            title: "Dispatch Archive",
            options: CheckData,
            selectedOptions: [],
            path: "/app/dispatch-archive",
          },
          // {
          //   key: "44",
          //   title: "Quote Building Tool",
          //   options: CheckData,
          //   selectedOptions: [],
          //   path: "/app/dispatch-Quote",
          // },
        ],
        selectedOptions: false,
      },
      {
        key: "5",
        title: "Finance",
        options: CheckData,
        multiPannel: [
          {
            key: "51",
            title: "Ticket Upload",
            options: CheckInfo,
            selectedOptions: [],
            path: "/app/ticket-upload",
          },
          {
            key: "52",
            title: "Ticket Processing",
            options: CheckInfo,
            selectedOptions: [],
            path: "/app/ticket-processing",
          },
          {
            key: "53",
            title: "Invoice Management",
            options: CheckData,
            selectedOptions: [],
            path: "/app/invoiceManagement",
          },
          {
            key: "54",
            title: "Accounts Receivable",
            options: CheckData,
            selectedOptions: [],
            path: "/app/accountsReceivable",
          },
          {
            key: "55",
            title: "Accounts Payable",
            options: CheckData,
            selectedOptions: [],
            path: "/app/accountsPayable",
          },
          {
            key: "56",
            title: "Project Analysis",
            options: CheckData,
            selectedOptions: [],
            path: "/app/projectAnalysis",
          },
        ],
        selectedOptions: false,
      },
      {
        key: "6",
        title: "Settings",
        options: CheckData,
        multiPannel: [
          {
            key: "61",
            title: "Manage Roles",
            options: CheckData,
            selectedOptions: [],
            path: "/app/manage-roles",
          },
          {
            key: "62",
            title: "System Users",
            options: CheckData,
            selectedOptions: [],
            path: "/app/system-users",
          },
          {
            key: "63",
            title: "Sales People",
            options: CheckData,
            selectedOptions: [],
            path: "/app/salesPerson",
          },
          {
            key: "64",
            title: "System Settings",
            options: CheckData,
            selectedOptions: [],
            path: "/app/system-settings",
          },
          {
            key: "65",
            title: "Restore Data",
            options: ["Edit", "View", "Delete"],
            selectedOptions: [],
            path: "/app/restoreData",
          },
          {
            key: "66",
            title: "Image Database",
            options: ["Edit", "View", "Delete"],
            selectedOptions: [],
            path: "/app/imageDatabase",
          },
          {
            key: "67",
            title: "Manager Review",
            options: CheckData,
            selectedOptions: [],
            path: "/app/manager-review",
          },
        ],
        selectedOptions: false,
      },
    ];

    this.setState({
      isModalVisible: true,
      leadsData: "",
      isEditModalVisible: false,
      PanelData: PanelData,
      defaultPannelsOpen: [],
    });
  };

  onFinish = (values) => {
    const { PanelData } = this.state;
    let userData = JSON.parse(localStorage.getItem("userDetails"));
    this.setState({
      userData: userData,
    });
    let checkData = [];
    PanelData?.forEach((role) => {
      if (
        (role.key === "1" && role.selectedOptions.length > 0) ||
        (role.key !== "1" &&
          role.multiPannel.filter((r) => r.selectedOptions.length > 0)?.length >
            0)
      ) {
        checkData.push(role);
      }
    });

    const { leadsData, isEditModalVisible, isModalVisible } = this.state;
    const data = {
      entityData: {
        roleName: values["roleName"],
        otherData: PanelData,
      },
    };

    if (checkData?.length > 0) {
      this.setState({
        roleLoading: true,
      });
      if (isEditModalVisible && leadsData.id !== "") {
        this.props?.actionsFetchUpdateRole(leadsData?.id, data);
      } else if (isModalVisible && !isEditModalVisible) {
        this.props?.actionsFetchAddRole(data);
      }
    } else {
      message.error("Please add atleast one role");
    }
  };

  handleCheckState = (boxActionType, title, action, subAction) => {
    let PanelData = this.state.PanelData;
    PanelData.map((st) => {
      if (
        st.title === title &&
        st.title !== "Dashboard" &&
        title !== "Dashboard"
      ) {
        let multiPannelCount = 0;
        st.multiPannel.map((mPanel) => {
          if (mPanel.title === subAction) {
            mPanel.title = subAction;
            if (boxActionType.target.checked) {
              if (action === "Edit" && title !== "Finance") {
                mPanel.selectedOptions.push(action, "View");
              } else if (
                action === "Delete" &&
                title !== "Finance" &&
                !mPanel.selectedOptions.includes("View")
              ) {
                mPanel.selectedOptions.push(action, "View");
              } else {
                mPanel.selectedOptions.push(action);
              }
              st["mainPannelSelected"] = true;
            } else {
              let unselectedOptions = mPanel.selectedOptions.filter(
                (o) => o !== action
              );

              mPanel["selectedOptions"] = unselectedOptions;
            }
          }
          if (mPanel.selectedOptions.length === 0) {
            multiPannelCount = multiPannelCount + 1;
          }
          if (multiPannelCount === st.multiPannel.length) {
            st["mainPannelSelected"] = false;
          }
          return true;
        });
      } else if (title === "Dashboard" && st.title === "Dashboard") {
        if (boxActionType.target.checked) {
          // console.log(st.selectedOptions, "st.selectedOptions");
          if (action === "Edit") {
            st.selectedOptions.push(action, "View");
          } else if (
            action === "Delete" &&
            !st.selectedOptions.includes("View")
          ) {
            st.selectedOptions.push(action, "View");
          } else {
            st.selectedOptions.push(action);
          }
          st["mainPannelSelected"] = true;
        } else {
          let unselectedOptions = st.selectedOptions.filter(
            (o) => o !== action
          );

          st["selectedOptions"] = unselectedOptions;
          if (unselectedOptions.length === 0) {
            st["mainPannelSelected"] = false;
          }
        }
      }
      return true;
    });
    this.setState({
      PanelData,
    });
  };

  onResetData = () => {
    this.props?.actionsFetchGetRoles();
  };

  searchResult = (searchValue) => {
    const { totalRolesData } = this.state;

    let searchFilter = [];
    if (typeof searchValue === "number") {
      searchFilter = totalRolesData.filter(
        (e) => parseInt(e["phone"]) === searchValue
      );
    } else {
      searchFilter = totalRolesData.filter(
        (e) => e["roleName"]?.toLowerCase().indexOf(searchValue) > -1
      );
    }

    this.setState({
      filterData: searchFilter,
    });
  };

  handleToOpenRolesEdit = (record) => {
    let defaultPannelsOpen = [];
    record["otherData"] &&
      record["otherData"].map((p) => {
        if ("multiPannel" in p === true) {
          p.multiPannel
            .filter(
              (selectedPannel) => selectedPannel.selectedOptions.length > 0
            )
            .map((open) => defaultPannelsOpen.push(open.key));
        }
        return true;
      });

    this.setState({
      isModalVisible: true,
      leadsData: record,
      PanelData: record["otherData"],
      isEditModalVisible: true,
      defaultPannelsOpen,
    });
  };

  onClickReturn = () => {
    this.setState({
      isModalVisible: !this.state.isModalVisible,
      isEditModalVisible: !this.state.isEditModalVisible,
      leadsData: "",
      PanelData: [],
      defaultPannelsOpen: [],
    });
  };

  deleteUserRole = (id) => {
    this.props?.actionsFetchDeleteRole(id);
  };

  handleTableChage = (props) => {
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    localStorage.setItem(
      "tableSizeRange",
      JSON.stringify({ ...tableSizeRange, manageRoles: props })
    );
  };

  render() {
    const {
      totalRolesData,
      isModalVisible,
      leadsData,
      filterData,
      PanelData,
      roleLoading,
      defaultPannelsOpen,
    } = this.state;

    const { errorLoading, loading } = this.props;

    const columns = [
      {
        title: "ROLE NAME",
        dataIndex: "roleName",
        key: "roleName",
        sorter: false,
        with: "180px",

        render: (roleName) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
              }}
            >
              {roleName}
            </span>
          );
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        sorter: false,
        render: (action, record) => {
          return (
            <>
              <Col
                style={{
                  width: "10px",
                  float: "right",
                  marginRight: "50px",
                }}
              >
                {action.roleName !== "Admin" && (
                  <Popover
                    content={
                      <Row className="popovergrid" style={{ maxWidth: "85px" }}>
                        {" "}
                        <Col
                          onClick={() => this.handleToOpenRolesEdit(record)}
                          span={24}
                          className="btn_edit"
                          style={{ cursor: "pointer" }}
                        >
                          <div className="icon-alignmentit span_edit">
                            <MdModeEditOutline /> Edit
                          </div>
                        </Col>
                        <Col span={24} style={{ cursor: "pointer" }}>
                          <Popconfirm
                            title="Are you sure？"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.deleteUserRole(record.id)}
                            className="btn_edit"
                          >
                            <div className="icon-alignmentit span_edit">
                              <MdDelete /> Delete
                            </div>
                          </Popconfirm>
                        </Col>
                      </Row>
                    }
                    trigger="hover"
                    placement="left"
                  >
                    <Button
                      type="button"
                      style={{ background: "transparent", border: "none" }}
                    >
                      <EllipsisOutlined
                        style={{ fontSize: "35px", color: "grey" }}
                        className="action-ellipsis-button"
                      />
                    </Button>
                  </Popover>
                )}
              </Col>
            </>
          );
        },
      },
    ];
    return (
      <div
        style={{
          background: "#fafafa",
        }}
      >
        <Loading enableLoading={loading} />

        <Row>
          <Col
            xxl={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xs={{ span: 24, offset: 0 }}
          >
            <Row style={{ marginBottom: "1vw" }}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 24 }}
                xxl={{ span: 24 }}
                // span={12}
              >
                <Row justify={"space-between"}>
                  {!isModalVisible ? (
                    <Col
                      xs={{ span: 16 }}
                      sm={{ span: 16 }}
                      md={{ span: 10 }}
                      lg={{ span: 10 }}
                      xl={{ span: 6 }}
                      xxl={{ span: 6 }}
                      // span={10}
                      className="quarrytoolgrid search-button-project"
                    >
                      <SearchFunction
                        data={totalRolesData}
                        getSearchData={this.searchResult}
                        //searchData={"roles"}
                        onResetData={this.onResetData}
                      />
                    </Col>
                  ) : (
                    <Row
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Row
                        onClick={this.onClickReturn}
                        style={{ cursor: "pointer" }}
                      >
                        <BsArrowLeftShort
                          style={{ color: "#5f6c74", fontSize: "25px" }}
                          className="back-button-return-icon"
                        />
                        <p
                          style={{
                            color: "#808080",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                          className="back-button-return"
                        >
                          Return
                        </p>
                      </Row>
                    </Row>
                  )}
                  <Col
                    xs={{ span: 8 }}
                    sm={{ span: 8 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}
                    xl={{ span: 8 }}
                    xxl={{ span: 8 }}
                  >
                    {!isModalVisible ? (
                      <Row style={{ justifyContent: "right" }}>
                        <Col className="gridbtntool">
                          <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={this.showModal}
                            style={{
                              borderRadius: "5px",
                              height: "40px",
                            }}
                          >
                            <span
                              style={{ fontWeight: "500", fontSize: "16px" }}
                            >
                              Role
                            </span>
                          </Button>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            {errorLoading ? (
              <ErrorView refreshRoles={this.onRefresh} type="ManageRoles" />
            ) : (
              <Card
                bodyStyle={{ textAlign: "left" }}
                className="manage-roles-card"
              >
                {isModalVisible ? (
                  <Form
                    id="addroles"
                    name="addrolename"
                    layout="vertical"
                    onFinish={this.onFinish}
                    autoComplete="off"
                  >
                    <Col
                      xxl={{ span: 12, offset: 6 }}
                      xl={{ span: 12, offset: 6 }}
                      lg={{ span: 12, offset: 6 }}
                      md={{ span: 24, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      xs={{ span: 24, offset: 0 }}
                      // span={12}
                      // offset={6}
                    >
                      <Row style={{ marginTop: "1vw" }}>
                        <span className="title_changes">Enter Role Name</span>

                        <Form.Item
                          name="roleName"
                          style={{ width: "100%" }}
                          initialValue={leadsData.roleName}
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Role Name!",
                            },
                          ]}
                        >
                          <Input
                            // onChange={this.roleName}
                            placeholder="Role name"
                            className="form-input"
                          />
                        </Form.Item>
                      </Row>

                      <Collapse
                        expandIconPosition={"right"}
                        className="role_collapse"
                        defaultActiveKey={["1", "2", "3", "4", "5", "6"]}
                        style={{ marginBottom: "1vw" }}
                      >
                        {PanelData.map((p) => (
                          <Panel
                            header={p.title}
                            key={p.key}
                            style={{
                              background: "#d9d9d9",
                            }}
                          >
                            {p.multiPannel ? (
                              <Collapse
                                expandIconPosition={"right"}
                                defaultActiveKey={defaultPannelsOpen}
                              >
                                {p.multiPannel.map((t) => (
                                  <Panel header={t.title} key={t.key}>
                                    <Row>
                                      {t.options.map((e) => (
                                        <Col
                                          xxl={{ span: 8, offset: 0 }}
                                          xl={{ span: 8, offset: 0 }}
                                          lg={{ span: 8, offset: 0 }}
                                          md={{ span: 8, offset: 0 }}
                                          sm={{ span: 8, offset: 0 }}
                                          xs={{ span: 12, offset: 0 }}
                                          // span={8}
                                          key={e}
                                        >
                                          <Form.Item
                                            style={{ marginBottom: "0px" }}
                                          >
                                            <Checkbox
                                              onChange={(checkStatus) =>
                                                this.handleCheckState(
                                                  checkStatus,
                                                  p.title,
                                                  e,
                                                  t.title
                                                )
                                              }
                                              defaultChecked={
                                                "selectedOptions" in t ===
                                                  true &&
                                                t?.selectedOptions?.length >
                                                  0 &&
                                                t.selectedOptions.includes(e)
                                              }
                                              checked={
                                                "selectedOptions" in t ===
                                                  true &&
                                                t?.selectedOptions?.length >
                                                  0 &&
                                                t.selectedOptions.includes(e)
                                              }
                                            />
                                            <span
                                              className="checkBox_data"
                                              // style={{ fontSize: "16px" }}
                                            >
                                              {e}
                                            </span>
                                          </Form.Item>
                                        </Col>
                                      ))}
                                    </Row>
                                  </Panel>
                                ))}
                              </Collapse>
                            ) : (
                              <Row>
                                {p.options.map((e) => (
                                  <Col
                                    xxl={{ span: 8, offset: 0 }}
                                    xl={{ span: 8, offset: 0 }}
                                    lg={{ span: 8, offset: 0 }}
                                    md={{ span: 8, offset: 0 }}
                                    sm={{ span: 8, offset: 0 }}
                                    xs={{ span: 12, offset: 0 }}
                                    // span={8}
                                  >
                                    <Form.Item style={{ marginBottom: "0px" }}>
                                      <Checkbox
                                        onChange={(checkStatus) =>
                                          this.handleCheckState(
                                            checkStatus,
                                            p.title,
                                            e
                                          )
                                        }
                                        defaultChecked={
                                          "selectedOptions" in p === true &&
                                          p?.selectedOptions?.length > 0 &&
                                          p.selectedOptions.includes(e)
                                        }
                                        checked={
                                          "selectedOptions" in p === true &&
                                          p?.selectedOptions?.length > 0 &&
                                          p.selectedOptions.includes(e)
                                        }
                                      />
                                      <span
                                        className="checkBox_data"
                                        // style={{ fontSize: "16px" }}
                                      >
                                        {e}
                                      </span>
                                    </Form.Item>
                                  </Col>
                                ))}
                              </Row>
                            )}
                          </Panel>
                        ))}
                      </Collapse>
                      <div
                        style={{
                          marginBottom: "20px",
                          marginLeft: "auto",
                          maxWidth: "180px",
                        }}
                      >
                        <Row>
                          <Col
                            xxl={{ span: 12, offset: 0 }}
                            xl={{ span: 12, offset: 0 }}
                            lg={{ span: 12, offset: 0 }}
                            md={{ span: 12, offset: 0 }}
                            sm={{ span: 12, offset: 0 }}
                            xs={{ span: 12, offset: 0 }}
                          >
                            <Button
                              className="btn-styl"
                              style={{
                                // backgroundColor: "#db1901",
                                color: "#586370",
                                border: "1px solid #d9d9d9",
                              }}
                              onClick={() => {
                                this.setState({ isModalVisible: false });
                              }}
                              form="addroles"
                              htmlType="reset"
                            >
                              Cancel
                            </Button>
                          </Col>
                          <Col
                            xxl={{ span: 11, offset: 1 }}
                            xl={{ span: 11, offset: 1 }}
                            lg={{ span: 11, offset: 1 }}
                            md={{ span: 11, offset: 1 }}
                            sm={{ span: 11, offset: 1 }}
                            xs={{ span: 11, offset: 1 }}
                          >
                            <Button
                              className="btn-styl"
                              type="primary"
                              form="addroles"
                              htmlType="submit"
                              loading={roleLoading}
                            >
                              Save
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Form>
                ) : (
                  <Col
                    xxl={{ span: 24, offset: 0 }}
                    xl={{ span: 24, offset: 0 }}
                    lg={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    xs={{ span: 24, offset: 0 }}
                    // span={24}
                    // offset={0}
                    data-aos="fade-up"
                  >
                    <BaseTable
                      className="table_manageroles"
                      columnsData={columns}
                      source={filterData}
                      total={filterData.length}
                      handleTableChage={this.handleTableChage}
                      sizeRange={
                        window.screen.width > 1023
                          ? JSON.parse(localStorage.getItem("tableSizeRange"))
                              ?.manageRoles
                          : filterData.length
                      }
                      pagination={window.screen.width > 1023 ? true : false}
                    />
                  </Col>
                )}
              </Card>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) =>
  // console.log(state),
  ({
    rolesResult: state?.reducer?.rolesResult,
    loading: state?.reducer?.rolesResult?.loading,
    errorLoading: state?.reducer?.rolesResult?.error,
    rolesInfo: state?.reducer?.rolesInfo,
    rolesUpdateInfo: state?.reducer?.rolesUpdateInfo,
  });

const mapDispatchToProps = (dispatch) => ({
  actionsFetchGetRoles: () => {
    dispatch(getRoles());
  },
  actionsFetchAddRole: (data) => {
    dispatch(addRole(data));
  },
  actionsFetchUpdateRole: (id, data) => {
    dispatch(updateRole(id, data));
  },
  actionsFetchDeleteRole: (id) => {
    dispatch(deleteRole(id));
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAppContext(withRouter(ManageRoles)));
