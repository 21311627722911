// import * as Sentry from "@sentry/browser";
import axiosInstance from "./axios";
import axios from "axios";

const BACKEND_API = "BACKEND_API";

const apiMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (action.type !== BACKEND_API) {
      return next(action);
    }
    const { method, url, params, data, ...rest } = action.payload;

    dispatch({
      type: action.payload.pending,
      payload: action.payload.extra,
      updateStatus: action.updateStatus,
    });

    axiosInstance({
      method,
      url,
      data,
      params,
    }).then(
      (resData) => {
        return new Promise((resolve) => {
          axios(resData)
            .then((response) => {
              dispatch({
                type: action.payload.success,
                payload: response.data,
                updateStatus: action.updateStatus,
                ...rest,
              });
              resolve(response);
            })
            .catch(async (error) => {
              // addError(error);
              console.log(error, "console error", error.response);
              if (error.response && error.response.status === 401) {
                // window.location.assign("/login");
                // alert("Your session has expired.Please login!");
              } else if (error.response && error.response.status === 400) {
                // window.location.assign("/pageNotFound/newtWork");
                // alert("Something went wrong... Please try again...");
              } else if (error.response && error.response.status === 502) {
                // window.location.assign("/pageNotFound/newtWork");
                // alert("Something went wrong... Please try again...");
              }

              if (error && !error.response) {
                // window.location.assign("/pageNotFound/newtWork");
                // alert("Please check your network and try again");
                console.log(error);
              }
            });
        });
      },

      (error) => {
        console.log(error, "error");
        if (error?.response?.status === 400) {
          // const data = {
          //   response: error?.response?.data,
          //   requestData: action?.payload,
          //   airportCode: localStorage.getItem("airportId"),
          // };
          // Sentry.captureMessage(JSON.stringify(data));
        }
        dispatch({
          type: action.payload.error,
          payload: error,
        });
      }
    );
    return {};
  };

export default apiMiddleware;
