import moment from "moment";
import React, { useState, useEffect } from "react";
import ExcelJS from "exceljs";
import TwistedNail from "../Images/TwistedNail.png";
import { SiMicrosoftexcel } from "react-icons/si";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { Storage } from "aws-amplify";
import Loading from "./Loading";
import { message, Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

const ExcelExportHelper = (props) => {
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch(TwistedNail)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          setImage(reader.result);
          const workbook = new ExcelJS.Workbook();
          workbook.xlsx
            .readFile("yourfile.xlsx")
            .catch((error) => console.error("Error reading file:", error));
        };
      })
      .catch((error) => console.error("Error fetching image:", error));
  }, []);

  const formatRow = (row, startCol, endCol) => {
    for (let col = startCol; col <= endCol; col++) {
      const cell = row.getCell(col);
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFCCCCCC" },
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      cell.alignment = { horizontal: "center" };
    }
  };
  //background White from A1:H7
  const formatCellsAndMerge = (worksheet, startRow, endRow) => {
    for (let i = startRow; i <= endRow; i++) {
      for (let j = 0; j <= 7; j++) {
        const cell = worksheet.getCell(`${String.fromCharCode(65 + j)}${i}`);
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFFFFFF" },
        };
      }
    }
  };

  const mergeCellsData = (
    worksheet,
    startRow,
    endRow,
    startFromChar,
    endFromChar
  ) => {
    for (let i = startRow; i <= endRow; i++) {
      worksheet.mergeCells(`${startFromChar}${i}:${endFromChar}${i}`);
    }
  };

  const addImageToRange = (worksheet, imageId, startRow, endRow) => {
    worksheet.addImage(imageId, {
      tl: { col: 0.3, row: startRow + 1 }, // Adjusted row position
      br: { col: 3, row: endRow },
    });
  };

  const applyBorderCells = (worksheet, i, j) => {
    for (let row = i; row <= j; row++) {
      for (let col = 1; col <= 8; col++) {
        const cell = worksheet.getCell(
          `${String.fromCharCode(64 + col)}${row}`
        );
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        if (row === i) cell.border.top = { style: "medium" };
        if (row === j) cell.border.bottom = { style: "medium" };
        if (col === 1) cell.border.left = { style: "medium" };
        if (col === 8) cell.border.right = { style: "medium" };
      }
    }
  };

  const applyBottomBorder = (ws, row, type) => {
    for (let col = 1; col <= 8; col++) {
      const cell = ws.getCell(`${String.fromCharCode(64 + col)}${row}`);
      cell.border = {
        bottom: { style: type, color: { argb: "#000" } }, // Bottom border style: medium
      };
    }
  };

  const applyBottomValues = (ws, i) => {
    ws.getCell(`A${i}`).value = "266 Pioneer Parkway, Waco, Texas 76708";
    ws.getCell(`D${i}`).value = "AR/AP:";
    ws.getCell(`E${i}`).value = "Paul Cruz-Sanchez";
    ws.getCell(`G${i}`).value = "(254) 651-3597";
  };

  const truckingMaterialTotal = (workData, type, onlyResult) => {
    let result = 0;
    workData?.ticketDetails?.map(
      (a) => (result += a?.quantity * a?.material[0]?.[type])
    );
    const finalResult = onlyResult
      ? result
      : `$ ${result?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;
    return finalResult;
  };

  const tableBottomValues = (worksheet, workbookData) => {
    // Apply bottom border to the ranges A48:H48 and A101:H101
    applyBottomBorder(worksheet, 48, "medium");
    applyBottomBorder(worksheet, 101, "medium");
    applyBottomBorder(worksheet, 154, "medium");
    applyBottomBorder(worksheet, 206, "medium");

    worksheet.mergeCells("A49:E49");
    worksheet.mergeCells("A50:D50");
    worksheet.mergeCells("A102:E102");
    worksheet.mergeCells("A103:D103");
    worksheet.mergeCells("A155:E155");
    worksheet.mergeCells("A156:D156");
    worksheet.mergeCells("A207:E207");
    worksheet.mergeCells("A208:D208");
    const setCellData = (
      worksheet,
      cellData,
      alignment = { horizontal: "center" },
      font = { bold: true }
    ) => {
      cellData.forEach((data) => {
        const cell = worksheet.getCell(data.cell);
        cell.alignment = alignment;
        cell.font = font;
        cell.value = data.value;
      });
    };
    const salesTaxData = [
      { cell: "F49", value: "Sales Tax" },
      {
        cell: "G49",
        value: `$ ${0.0}`,
      },
      {
        cell: "H49",
        value: truckingMaterialTotal(workbookData, "truckingPrice"),
      },
      { cell: "F102", value: "Sales Tax" },
      {
        cell: "G102",
        value: `$ ` + salesTaxValue(workbookData)?.toFixed(2),
      },
      {
        cell: "H102",
        value:
          workbookData?.ticketData?.jobType !== "Trucking Job"
            ? truckingMaterialTotal(workbookData, "totalPrice")
            : `$${0.0}`,
      },
      { cell: "F155", value: "Sales Tax" },
      {
        cell: "G155",
        value: `$ ` + salesTaxValue(workbookData)?.toFixed(2),
      },
      {
        cell: "H155",
        value: `$ ${parseFloat(
          workbookData?.ticketData?.jobType !== "Trucking Job"
            ? truckingMaterialTotal(workbookData, "totalPrice", true) +
                truckingMaterialTotal(workbookData, "truckingPrice", true)
            : truckingMaterialTotal(workbookData, "truckingPrice", true)
        )?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
      },
      { cell: "F207", value: "Sales Tax" },
      {
        cell: "G207",
        value: `$ ${0.0}`,
      },
      {
        cell: "H207",
        value:
          workbookData?.ticketData?.jobType !== "Trucking Job"
            ? truckingMaterialTotal(workbookData, "materialCost")
            : `$ ${0.0}`,
      },
    ];
    const totalData = [
      { cell: "E50", value: "Total" },
      {
        cell: "F50",
        value: workbookData?.ticketTon?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      },
      {
        cell: "G50",
        value: workbookData?.ticketData?.quantityType
          ? workbookData?.ticketData?.quantityType
          : workbookData?.ticketData?.selectedMaterial[0]?.quantityType,
      },
      {
        cell: "H50",
        value: truckingMaterialTotal(workbookData, "truckingPrice"),
      },
      { cell: "E103", value: "Total" },
      {
        cell: "F103",
        value: workbookData?.ticketTon?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      },
      {
        cell: "G103",
        value: workbookData?.ticketData?.quantityType
          ? workbookData?.ticketData?.quantityType
          : workbookData?.ticketData?.selectedMaterial[0]?.quantityType,
      },
      {
        cell: "H103",
        value:
          workbookData?.ticketData?.jobType !== "Trucking Job"
            ? `$ ${parseFloat(
                truckingMaterialTotal(workbookData, "totalPrice", true) +
                  salesTaxValue(workbookData)
              ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : `$ ${0.0}`,
      },
      { cell: "E156", value: "Total" },
      {
        cell: "F156",
        value: workbookData?.ticketTon?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      },
      {
        cell: "G156",
        value: workbookData?.ticketData?.quantityType
          ? workbookData?.ticketData?.quantityType
          : workbookData?.ticketData?.selectedMaterial[0]?.quantityType,
      },
      {
        cell: "H156",
        value: `$ ${parseFloat(
          workbookData?.ticketData?.jobType !== "Trucking Job"
            ? truckingMaterialTotal(workbookData, "totalPrice", true) +
                truckingMaterialTotal(workbookData, "truckingPrice", true) +
                salesTaxValue(workbookData)
            : truckingMaterialTotal(workbookData, "truckingPrice", true)
        )?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
      },
      { cell: "E208", value: "Total" },
      {
        cell: "F208",
        value: workbookData?.ticketTon?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      },
      {
        cell: "G208",
        value: workbookData?.ticketData?.quantityType
          ? workbookData?.ticketData?.quantityType
          : workbookData?.ticketData?.selectedMaterial[0]?.quantityType,
      },
      {
        cell: "H208",
        value:
          workbookData?.ticketData?.jobType !== "Trucking Job"
            ? truckingMaterialTotal(workbookData, "materialCost")
            : `$ ${0.0}`,
      },
    ];
    setCellData(worksheet, salesTaxData);
    setCellData(worksheet, totalData);
  };

  const salesTaxValue = (workbookData) => {
    return workbookData?.ticketDetails?.[0].material[0]?.materialTax ===
      "Natural"
      ? 0
      : workbookData?.ticketDetails &&
        workbookData.ticketDetails?.length > 0 &&
        workbookData.ticketDetails?.[0].material[0]?.additionalTaxRate !==
          undefined
      ? (parseFloat(
          workbookData.ticketDetails?.[0].material[0]?.additionalTaxRate
        ) *
          parseFloat(truckingMaterialTotal(workbookData, "totalPrice", true))) /
        100
      : 0;
  };

  const tableSideInfo = (worksheet, workbookData) => {
    const formatCurrency = (value) =>
      `$ ${parseFloat(value).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
    const formatDate = (date) => moment(date).format("l");
    const cellDataK16 = [
      { cell: "K17", value: "Date 1" },
      { cell: "K18", value: "Date 2" },
      { cell: "K19", value: "Date 3" },
      { cell: "K20", value: "Date 4" },
      { cell: "K21", value: "Date 5" },
      { cell: "K22", value: "Date 6" },
      { cell: "K23", value: "Date 7" },
    ];

    const cellDataL16 = [
      { cell: "L16", value: "Date" },
      { cell: "L17", value: "20/22/2320" },
    ];

    const cellDataM16 = [
      { cell: "M16", value: "Tickets Per Day" },
      { cell: "M17", value: "1" },
    ];

    const cellDataF6 = [
      {
        cell: "E57",
        value: workbookData?.ticketDetails[0]?.taxExempt,
        name: "Calibri",
      },
    ];

    const cellDataM = [
      {
        cell: "M3",
        value: formatCurrency(
          workbookData?.ticketData?.material[0]?.truckingPrice
        ),
      },
      {
        cell: "M4",
        value:
          workbookData?.ticketData?.jobType === "Trucking Job"
            ? "$ 0.00"
            : formatCurrency(workbookData?.ticketData?.material[0]?.totalPrice),
      },
      {
        cell: "M5",
        value:
          workbookData?.ticketData?.jobType === "Trucking Job"
            ? "$ 0.00"
            : formatCurrency(
                workbookData?.ticketData?.material[0]?.materialCost
              ),
      },
      { cell: "M6", value: workbookData?.ticketData?.taxExempt || "" },
      { cell: "M7", value: workbookData?.ticketData?.jobName },
      { cell: "M8", value: workbookData?.ticketData?.companyName },
      {
        cell: "M9",
        value:
          workbookData?.ticketData?.selectedMaterial[0]?.vendor ||
          workbookData?.ticketData?.material[0]?.assignTrucksInfo[0]
            ?.carrier_name,
      },
      {
        cell: "M10",
        value:
          workbookData?.ticketData?.material[0]?.materialName ||
          workbookData?.ticketData?.material[0]?.materialShortValue,
      },
      {
        cell: "M11",
        value: "",
      },
      {
        cell: "M12",
        value: "",
      },
      {
        cell: "M13",
        value: "",
      },
      {
        cell: "M14",
        value: "",
      },

      { cell: "M15", value: formatDate(workbookData?.createdAt) },
    ];

    const cellData = [
      { cell: "D2", value: "" },
      { cell: "D3", value: workbookData?.ticketData?.invoiceNotes?.notes1 },
      { cell: "D4", value: workbookData?.ticketData?.invoiceNotes?.notes2 },
      { cell: "D5", value: workbookData?.ticketData?.invoiceNotes?.notes3 },
      { cell: "D53", value: "" },
      { cell: "D54", value: workbookData?.ticketData?.invoiceNotes?.notes1 },
      { cell: "D55", value: workbookData?.ticketData?.invoiceNotes?.notes2 },
      { cell: "D56", value: workbookData?.ticketData?.invoiceNotes?.notes3 },
      { cell: "D106", value: "" },
      { cell: "D107", value: workbookData?.ticketData?.invoiceNotes?.notes1 },
      { cell: "D108", value: workbookData?.ticketData?.invoiceNotes?.notes2 },
      { cell: "D109", value: workbookData?.ticketData?.invoiceNotes?.notes3 },
      { cell: "D159", value: "" },
      { cell: "D160", value: workbookData?.ticketData?.invoiceNotes?.notes1 },
      { cell: "D161", value: workbookData?.ticketData?.invoiceNotes?.notes2 },
      { cell: "D162", value: workbookData?.ticketData?.invoiceNotes?.notes3 },
    ];

    const cellDataExempt = [
      { cell: "D57", value: "Tax Exempt ?" },
      { cell: "D110", value: "Tax Exempt ?" },
    ];

    const cellDataG = [
      { cell: "G3", value: "Truck #" },
      { cell: "G4", value: "Invoice #" },
      { cell: "G5", value: "Inv Date" },
      { cell: "G55", value: "Truck #" },
      { cell: "G56", value: "Invoice #" },
      { cell: "G57", value: "Inv Date" },
      { cell: "G108", value: "Truck #" },
      { cell: "G109", value: "Invoice #" },
      { cell: "G110", value: "Inv Date" },
      { cell: "G161", value: "Truck #" },
      { cell: "G162", value: "Invoice #" },
      { cell: "G163", value: "Inv Date" },
    ];

    const cellDataH = [
      { cell: "H3", value: workbookData?.ticketData?.truckNumber },
      { cell: "H4", value: workbookData?.invoiceNumber },
      { cell: "H5", value: formatDate(workbookData?.createdAt) },
      { cell: "H55", value: workbookData?.ticketData?.truckNumber },
      { cell: "H56", value: workbookData?.invoiceNumber },
      { cell: "H57", value: formatDate(workbookData?.createdAt) },
      { cell: "H108", value: workbookData?.ticketData?.truckNumber },
      { cell: "H109", value: workbookData?.invoiceNumber },
      { cell: "H110", value: formatDate(workbookData?.createdAt) },
      { cell: "H161", value: workbookData?.ticketData?.truckNumber },
      { cell: "H162", value: workbookData?.invoiceNumber },
      { cell: "H163", value: formatDate(workbookData?.createdAt) },
    ];

    const cellDataKL = [
      { cell: "K3", value: "Trucking Rate" },
      { cell: "K4", value: "Material Rate" },
      { cell: "K5", value: "Material Cost" },
      { cell: "K6", value: "Tax Status" },
      { cell: "K7", value: "Job Name" },
      { cell: "K8", value: "Customer" },
      { cell: "K9", value: "Vendor" },
      { cell: "K10", value: "Material" },
      { cell: "K11", value: "PO Line 1" },
      { cell: "K12", value: "PO Line 2" },
      { cell: "K13", value: "PO Line 3" },
      { cell: "K14", value: "PO Line 4" },
      { cell: "K15", value: "Invoice Date" },
    ];

    const applyCommonStyles = (
      cell,
      value,
      alignment = "center",
      size = 10,
      name = "Calibri",
      bold = false,
      addBorder = false
    ) => {
      cell.alignment = { horizontal: alignment };
      cell.value = value;
      cell.font = { size, name, bold };
      if (!addBorder) {
        cell.border = {
          bottom: { style: "thin", color: { argb: "FF000000" } },
        };
      }
    };

    [cellDataK16, cellDataL16, cellDataM16, cellDataF6, cellDataM].forEach(
      (dataArray) => {
        dataArray.forEach((data) => {
          const cell = worksheet.getCell(data.cell);
          applyCommonStyles(cell, data.value, "center");

          // Apply border only if dataArray is not cellDataF6
          if (dataArray === cellDataF6) {
            cell.border = {
              ...cell.border, // Keep other existing border styles
              bottom: { style: undefined },
            };
            cell.font ={bold: true , name:"Calibri",  color: { argb: "FFFF0000" }, size :10}
          } else {
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          }
        });
      }
    );

    cellDataExempt.forEach((data) => {
      const cell = worksheet.getCell(data.cell);
      applyCommonStyles(cell, data.value, "center", 10, "Calibri", true, false);
      cell.border = {
        ...cell.border, // Keep other existing border styles
        bottom: { style: undefined },
      };
    });

    cellDataG.forEach((data) => {
      const cell = worksheet.getCell(data.cell);
      applyCommonStyles(cell, data.value, "center", 12, "Arial", true, false);

      // Remove the bottom border
      cell.border = {
        ...cell.border, // Keep other existing border styles
        bottom: { style: undefined },
      };
    });

    cellDataH.forEach((data) => {
      const cell = worksheet.getCell(data.cell);
      applyCommonStyles(cell, data.value, "center", 12, "Arial", false, false);
    });

    cellDataKL.forEach((data) => {
      const cell = worksheet.getCell(data.cell);
      applyCommonStyles(cell, data.value, "center", 10, "Calibri", true, false);

      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    cellData.forEach((data) => {
      const cellRange = `${data.cell}:${data.cell.replace("D", "E")}`;
      worksheet.mergeCells(cellRange);
      const cell = worksheet.getCell(data.cell);
      applyCommonStyles(cell, data.value, "center", 12, "Arial", false, false);
    });
  };

  const invoiceTruckSheets = (workbook, workbookData) => {
    const worksheet = workbook.addWorksheet("Invoices");

    //table Side Data
    tableSideInfo(worksheet, workbookData);

    // Add image to worksheet
    const imageId = workbook.addImage({
      base64: image,
      extension: "png",
    });

    for (let i = 3; i <= 15; i++) {
      worksheet.mergeCells(`K${i}:L${i}`);
    }

    // Formatting and merging cells
    formatCellsAndMerge(worksheet, 1, 7);
    formatCellsAndMerge(worksheet, 52, 58);
    formatCellsAndMerge(worksheet, 105, 111);
    formatCellsAndMerge(worksheet, 158, 164);

    mergeCellsData(worksheet, 1, 7, "A", "C");
    mergeCellsData(worksheet, 52, 58, "A", "C");
    mergeCellsData(worksheet, 105, 111, "A", "C");
    mergeCellsData(worksheet, 158, 164, "A", "C");

    // Adding images
    addImageToRange(worksheet, imageId, 1, 7);
    addImageToRange(worksheet, imageId, 52, 58);
    addImageToRange(worksheet, imageId, 105, 111);
    addImageToRange(worksheet, imageId, 158, 164);

    worksheet.getColumn("A").width = 12;
    worksheet.getColumn("B").width = 15;
    worksheet.getColumn("C").width = 15;

    worksheet.getColumn("D").width = 15;
    worksheet.getColumn("E").width = 20;
    worksheet.getColumn("F").width = 10;
    worksheet.getColumn("G").width = 10;
    worksheet.getColumn("H").width = 20;
    worksheet.getColumn("K").width = 20;
    worksheet.getColumn("L").width = 20;
    worksheet.getColumn("M").width = 20;

    worksheet.getCell("H1").value = "TRUCKING";
    worksheet.getCell("H1").font = { color: { argb: "FFFF0000" }, bold: true };
    worksheet.getCell("A10").value = "Date:";
    worksheet.getCell("B10").value = "Vendor";
    worksheet.getCell("C10").value = "Project";
    worksheet.getCell("D10").value = "Ticket No.";
    worksheet.getCell("E10").value = "Material";
    worksheet.getCell("F10").value = "QTY";
    worksheet.getCell("G10").value = "Rate";
    worksheet.getCell("H10").value = "Total";

    worksheet.getCell("H52").value = "MATERIAL";
    worksheet.getCell("H52").font = { color: { argb: "FFFF0000" }, bold: true };
    worksheet.getCell("A62").value = "Date:";
    worksheet.getCell("B62").value = "Vendor";
    worksheet.getCell("C62").value = "Project";
    worksheet.getCell("D62").value = "Ticket No.";
    worksheet.getCell("E62").value = "Material";
    worksheet.getCell("F62").value = "QTY";
    worksheet.getCell("G62").value = "Rate";
    worksheet.getCell("H62").value = "Total";

    worksheet.getCell("H105").value = "LANDED";
    worksheet.getCell("H105").font = {
      color: { argb: "FFFF0000" },
      bold: true,
    };
    worksheet.getCell("A115").value = "Date:";
    worksheet.getCell("B115").value = "Origin";
    worksheet.getCell("C115").value = "Destination";
    worksheet.getCell("D115").value = "Ticket No.";
    worksheet.getCell("E115").value = "Material";
    worksheet.getCell("F115").value = "QTY";
    worksheet.getCell("G115").value = "Rate";
    worksheet.getCell("H115").value = "Total";

    worksheet.getCell("H158").value = "QUARRY AP";
    worksheet.getCell("H158").font = {
      color: { argb: "FFFF0000" },
      bold: true,
    };
    worksheet.getCell("A167").value = "Date:";
    worksheet.getCell("B167").value = "Vendor";
    worksheet.getCell("C167").value = "Project";
    worksheet.getCell("D167").value = "Ticket No.";
    worksheet.getCell("E167").value = "Material";
    worksheet.getCell("F167").value = "QTY";
    worksheet.getCell("G167").value = "Rate";
    worksheet.getCell("H167").value = "Total";

    const truckingData = workbookData?.ticketDetails || [];
    const materialData =
      workbookData?.ticketDetails?.filter(
        (row) => row?.jobType !== "Trucking Job"
      ) || [];
    const landedData = workbookData?.ticketDetails || [];
    const quarryAp =
      workbookData?.ticketDetails?.filter(
        (row) => row?.jobType !== "Trucking Job"
      ) || [];

    if (truckingData?.length > 0) {
      truckingData?.forEach((row, index) => {
        const currentRow = index + 1;
        const cells = {
          A: worksheet.getCell(`A1${currentRow}`),
          B: worksheet.getCell(`B1${currentRow}`),
          C: worksheet.getCell(`C1${currentRow}`),
          D: worksheet.getCell(`D1${currentRow}`),
          E: worksheet.getCell(`E1${currentRow}`),
          F: worksheet.getCell(`F1${currentRow}`),
          G: worksheet.getCell(`G1${currentRow}`),
          H: worksheet.getCell(`H1${currentRow}`),
        };

        cells.A.value = moment(row.createdAt).format("L");

        cells.B.value = row?.material[0]?.assignTrucksInfo[0]?.carrier_name;
        cells.C.value = row?.jobName;
        cells.D.value = row?.ticketNumber;
        cells.E.value = row?.material[0]?.materialName;
        cells.F.value = row?.quantity.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
        cells.G.value = `$ ${parseFloat(
          row?.material[0]?.truckingPrice
        ).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;
        cells.H.value = `$ ${(
          row?.quantity * row?.material[0]?.truckingPrice
        )?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;

        Object.values(cells).forEach((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
        cells.G.alignment = { vertical: "middle", horizontal: "right" };
        cells.H.alignment = { vertical: "middle", horizontal: "right" };
      });
    }

    if (materialData?.length > 0) {
      materialData?.forEach((row, index) => {
        const currentRow = index + 3;
        const cells = {
          A: worksheet.getCell(`A6${currentRow}`),
          B: worksheet.getCell(`B6${currentRow}`),
          C: worksheet.getCell(`C6${currentRow}`),
          D: worksheet.getCell(`D6${currentRow}`),
          E: worksheet.getCell(`E6${currentRow}`),
          F: worksheet.getCell(`F6${currentRow}`),
          G: worksheet.getCell(`G6${currentRow}`),
          H: worksheet.getCell(`H6${currentRow}`),
        };
        cells.A.value = moment(row.createdAt).format("L");
        cells.B.value = row?.selectedMaterial[0]?.vendor;
        cells.C.value = row?.jobName;
        cells.D.value = row?.ticketNumber;
        cells.E.value = row?.material[0]?.value?.split(" - ")[2];
        cells.F.value = row?.quantity.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
        cells.G.value = `$ ${parseFloat(
          row?.material[0]?.totalPrice
        ).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;
        cells.H.value = `$ ${(
          row?.material[0]?.totalPrice * row?.quantity
        )?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;

        Object.values(cells).forEach((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
        cells.G.alignment = { vertical: "middle", horizontal: "right" };
        cells.H.alignment = { vertical: "middle", horizontal: "right" };
      });
    }

    if (landedData?.length > 0) {
      landedData?.forEach((row, index) => {
        const currentRow = index + 6;
        const cells = {
          A: worksheet.getCell(`A11${currentRow}`),
          B: worksheet.getCell(`B11${currentRow}`),
          C: worksheet.getCell(`C11${currentRow}`),
          D: worksheet.getCell(`D11${currentRow}`),
          E: worksheet.getCell(`E11${currentRow}`),
          F: worksheet.getCell(`F11${currentRow}`),
          G: worksheet.getCell(`G11${currentRow}`),
          H: worksheet.getCell(`H11${currentRow}`),
        };

        cells.A.value = moment(row.createdAt).format("L");
        cells.B.value =
          row?.ticketData?.jobType === "Trucking Job"
            ? row?.material[0]?.assignTrucksInfo[0]?.carrier_name
            : row?.selectedMaterial[0]?.vendor;
        cells.C.value = row?.jobName;
        cells.D.value = row?.ticketNumber;
        cells.E.value =
          row?.ticketData?.jobType === "Trucking Job"
            ? row?.material[0]?.materialName
            : row?.material[0].value?.split(" - ")[2];
        cells.F.value = row?.quantity.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
        cells.G.value =
          row?.ticketData?.jobType === "Trucking Job"
            ? `$ ${parseFloat(row?.material[0]?.truckingPrice).toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )}`
            : `$ ${parseFloat(row?.totalPrice).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`;
        cells.H.value =
          row?.ticketData?.jobType === "Trucking Job"
            ? `$ ${(row?.quantity * row?.truckingPrice)?.toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )}`
            : `$ ${(row?.quantity * row.totalPrice)?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`;
        Object.values(cells).forEach((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
        cells.G.alignment = { vertical: "middle", horizontal: "right" };
        cells.H.alignment = { vertical: "middle", horizontal: "right" };
      });
    }

    if (quarryAp?.length > 0) {
      quarryAp?.forEach((record, index) => {
        let materialCost =
          record?.selectedMaterial[0]?.materialCost * record?.quantity;
        const currentRow = index + 8;
        const cells = {
          A: worksheet.getCell(`A16${currentRow}`),
          B: worksheet.getCell(`B16${currentRow}`),
          C: worksheet.getCell(`C16${currentRow}`),
          D: worksheet.getCell(`D16${currentRow}`),
          E: worksheet.getCell(`E16${currentRow}`),
          F: worksheet.getCell(`F16${currentRow}`),
          G: worksheet.getCell(`G16${currentRow}`),
          H: worksheet.getCell(`H16${currentRow}`),
        };

        cells.A.value = moment(record.createdAt).format("L");
        cells.B.value = record?.selectedMaterial[0]?.vendor;
        cells.C.value = record.truckNumber + "#, " + record?.jobName;
        cells.D.value = record?.ticketNumber;
        cells.E.value = record?.material[0].value?.split(" - ")[2];
        cells.F.value = record?.quantity.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
        cells.G.value =
          `$ ` +
          record?.selectedMaterial[0]?.materialCost?.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        cells.H.value = `$ ${materialCost?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;

        Object.values(cells).forEach((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
        cells.G.alignment = { vertical: "middle", horizontal: "right" };
        cells.H.alignment = { vertical: "middle", horizontal: "right" };
      });
    }

    // Set header style
    const truckingRow = worksheet.getRow(10);
    const materialRow = worksheet.getRow(62);
    const landedRow = worksheet.getRow(115);
    const ApRow = worksheet.getRow(167);

    // Apply formatting to each row using the function
    formatRow(truckingRow, 1, 8);
    formatRow(materialRow, 1, 8);
    formatRow(landedRow, 1, 8);
    formatRow(ApRow, 1, 8);

    tableBottomValues(worksheet, workbookData);

    applyBottomBorder(worksheet, 8, "thin");
    applyBottomBorder(worksheet, 60, "thin");
    applyBottomBorder(worksheet, 113, "thin");
    applyBottomBorder(worksheet, 165, "thin");

    applyBorderCells(worksheet, 10, 50);
    applyBorderCells(worksheet, 62, 103);
    applyBorderCells(worksheet, 115, 156);
    applyBorderCells(worksheet, 168, 208);

    const range = worksheet.getCell("A11:J11");
    range.border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };

    worksheet.mergeCells("B8:C8");
    worksheet.mergeCells("G8:H8");

    worksheet.mergeCells("A51:C51");
    worksheet.mergeCells("E51:F51");
    worksheet.mergeCells("G51:H51");

    worksheet.mergeCells("A104:C104");
    worksheet.mergeCells("E104:F104");
    worksheet.mergeCells("G104:H104");

    worksheet.mergeCells("A157:C157");
    worksheet.mergeCells("E157:F157");
    worksheet.mergeCells("G157:H157");

    worksheet.mergeCells("A209:C209");
    worksheet.mergeCells("E209:F209");
    worksheet.mergeCells("G209:H209");

    worksheet.getCell("A8").value = "Customer";
    worksheet.getCell("A8").font = { bold: true };
    worksheet.getCell("B8").value = workbookData?.companyName;
    worksheet.getCell("F8").value = "Dates";
    worksheet.getCell("F8").font = { bold: true };
    worksheet.getCell("D8").value = "Dates";
    worksheet.getCell("D8").font = { bold: true };
    worksheet.getCell("E8").value = workbookData?.startDate;
    worksheet.getCell("F8").value = "To";
    worksheet.getCell("F8").font = { bold: true };
    worksheet.getCell("G8").value = workbookData?.endDate;

    applyBottomValues(worksheet, 51);
    applyBottomValues(worksheet, 104);
    applyBottomValues(worksheet, 157);
    applyBottomValues(worksheet, 209);

    // Formatting and merging cells
    formatCellsAndMerge(worksheet, 1, 7);

    worksheet.mergeCells("B60:C60");
    worksheet.mergeCells("G60:H60");
    worksheet.getCell("A60").value = "Customer";
    worksheet.getCell("A60").font = { bold: true };
    worksheet.getCell("B60").value = workbookData?.companyName;
    worksheet.getCell("D60").value = "Dates";
    worksheet.getCell("D60").font = { bold: true };
    worksheet.getCell("E60").value = workbookData?.startDate;
    worksheet.getCell("F60").value = "To";
    worksheet.getCell("F60").font = { bold: true };
    worksheet.getCell("G60").value = workbookData?.endDate;

    worksheet.mergeCells("B113:C113");
    worksheet.mergeCells("G113:H113");
    worksheet.getCell("A113").value = "Customer";
    worksheet.getCell("A113").font = { bold: true };
    worksheet.getCell("B113").value = workbookData?.companyName;
    worksheet.getCell("D113").value = "Dates";
    worksheet.getCell("D113").font = { bold: true };
    worksheet.getCell("E113").value = workbookData?.startDate;
    worksheet.getCell("F113").value = "To";
    worksheet.getCell("F113").font = { bold: true };
    worksheet.getCell("G113").value = workbookData?.endDate;

    worksheet.mergeCells("B165:C165");
    worksheet.mergeCells("G165:H165");
    worksheet.getCell("A165").value = "Customer";
    worksheet.getCell("A165").font = { bold: true };
    worksheet.getCell("B165").value = workbookData?.companyName;
    worksheet.getCell("D165").value = "Dates";
    worksheet.getCell("D165").font = { bold: true };
    worksheet.getCell("E165").value = workbookData?.startDate;
    worksheet.getCell("F165").value = "To";
    worksheet.getCell("F165").font = { bold: true };
    worksheet.getCell("G165").value = workbookData?.endDate;
  };

  const exportImageExcel = async (data, billData) => {
    const workbook = new ExcelJS.Workbook();
    invoiceTruckSheets(workbook, data);
    const billInfoData = billData?.filter((e) => e.invoiceId === data.id);
    // remaining 4 Sheets Data
    const sheetHeadings = [
      "*InvoiceNo",
      "*Customer",
      "*InvoiceDate",
      "*DueDate",
      "Terms",
      "Memo",
      "Item(Product/Service)",
      "Product/Service Quantity",
      "Product/Service Rate",
      "Product/Service Amount",
      "Product/Service Taxable",
    ];
    const apTableHeadings = [
      "Bill #",
      "Vendor",
      "Bill Date",
      "DueDate",
      "Terms",
      "Memo",
      "Expense Account",
      "Expense Description",
      "Expense Line Amount",
    ];

    const sheetNames = [
      "Trucking",
      "Materials",
      "Sales Tax",
      "Invoices Combined",
      "AP",
    ];
    let truckingSheetData = [];
    let materialSheetData = [];
    let apTruckData = [];
    let apMaterialData = [];

    data?.ticketDetails?.forEach((row) => {
      let terms =
        row?.selectedMaterial[0]?.assignTrucksInfo[0]?.payment_terms?.split(
          "Net "
        )[1];
      let dueDate = moment(moment(row?.createdAt).format("l")).add(
        terms,
        "days"
      );

      const truckRowData = [
        data.invoiceNumber,
        row.companyName,
        moment(row?.createdAt).format("L"),
        moment(dueDate).format("L"),
        row?.selectedMaterial[0]?.assignTrucksInfo[0]?.payment_terms,
        `TNB ${row?.selectedMaterial[0]?.assignTrucks[0]}`,
        row?.ticketData?.jobType,
        row?.quantity.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }),
        `$ ${parseFloat(row?.material[0]?.truckingPrice).toLocaleString(
          undefined,
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        )}`,
        `$ ${parseFloat(
          row?.quantity * row?.material[0]?.truckingPrice
        ).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
        "False",
      ];

      const materialRowData = [
        data.invoiceNumber,
        row.companyName,
        moment(row?.createdAt).format("L"),
        moment(dueDate).format("L"),
        row?.selectedMaterial[0]?.assignTrucksInfo[0]?.payment_terms,
        `TNB ${row?.selectedMaterial[0]?.assignTrucks[0]}`,
        row?.ticketData?.jobType,
        row?.quantity.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }),
        `$ ${parseFloat(row?.material[0]?.totalPrice).toLocaleString(
          undefined,
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        )}`,
        `$ ${parseFloat(
          row?.quantity * row?.material[0]?.totalPrice
        ).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
        row?.selectedMaterial[0]?.materialTax === "Manufactured"
          ? "True"
          : "False",
      ];

      if (row?.jobType === "Trucking Job") {
        truckingSheetData.push(truckRowData);
      } else {
        truckingSheetData.push(truckRowData);
        materialSheetData.push(materialRowData);
      }
    });

    billInfoData?.forEach((record) => {
      let terms = record?.selectedMaterial[0]?.vendorTerms?.split("Net ")[1];
      let dueDate = moment(record?.createdAt).add(terms, "days").calendar();
      let truckingCost =
        record?.selectedMaterial[0]?.truckingCost * record?.totalTons;
      let materialCost =
        record?.selectedMaterial[0]?.materialCost * record?.totalTons;

      let apTruckRowData = [
        record?.jobNumber + "AP - " + record?.ticketNumber,
        record?.material[0]?.assignTrucksInfo[0]?.carrier_name,
        moment(record.createdAt).format("L"),
        moment(dueDate).format("L"),
        record?.selectedMaterial[0]?.vendorTerms,
        record.truckNumber + "#, " + record?.jobName,
        `$ ` +
          record?.selectedMaterial[0]?.truckingCost?.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        record?.selectedMaterial[0]?.materialShortValue,
        `$ ` +
          truckingCost?.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
      ];

      let apMaterialRowData = [
        record?.jobNumber + "AP - " + record?.ticketNumber,
        record?.selectedMaterial[0]?.vendor,
        moment(record.createdAt).format("L"),
        moment(dueDate).format("L"),
        record?.selectedMaterial[0]?.vendorTerms,
        record.truckNumber + "#, " + record?.jobName,
        `$ ` +
          record?.selectedMaterial[0].materialCost?.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        record?.selectedMaterial[0]?.materialShortValue,
        `$ ` +
          materialCost?.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
      ];

      if (record?.isTruckingJob) {
        apTruckData.push(apTruckRowData);
      } else {
        apMaterialData.push(apMaterialRowData);
      }
    });
    // Create the worksheets and add the headings
    sheetNames.forEach((sheetName) => {
      const sheet = workbook.addWorksheet(sheetName);
      const headingRow = sheet.addRow(
        sheetName === "AP" ? apTableHeadings : sheetHeadings
      );
      const APcolumnWidths = [15, 30, 15, 15, 15, 20, 25, 35, 25];
      const columnWidths =
        sheetName === "AP"
          ? APcolumnWidths
          : [15, 30, 15, 15, 15, 15, 25, 25, 25, 25, 25];

      // Set the column widths
      columnWidths.forEach((width, index) => {
        sheet.getColumn(index + 1).width = width;
      });

      // Set the row height for the first row (headings)
      headingRow.height = 25;

      // Center align and bold the headings
      headingRow.eachCell((cell, colNumber) => {
        cell.alignment = { vertical: "middle", horizontal: "center" };
        cell.font = { bold: true };
      });

      let dataToUse;

      if (sheetName === "Trucking") {
        dataToUse = truckingSheetData;
      } else if (sheetName === "Materials") {
        dataToUse = materialSheetData;
      } else if (
        sheetName === "Sales Tax" ||
        sheetName === "Invoices Combined"
      ) {
        dataToUse = [...truckingSheetData, ...materialSheetData];
      } else if (sheetName === "AP") {
        dataToUse = [...apTruckData, ...apMaterialData];
      }

      // Add dataToUse rows to the sheet
      dataToUse.forEach((row) => {
        const rowRef = sheet.addRow(row);
        // Center align all cells by default
        rowRef.alignment = { vertical: "middle", horizontal: "center" };

        if (sheetName === "AP") {
          rowRef.getCell(7).alignment = { horizontal: "right" };
          rowRef.getCell(9).alignment = { horizontal: "right" };
        } else {
          // Right align specific columns H, I, J
          rowRef.getCell(8).alignment = { horizontal: "right" };
          rowRef.getCell(9).alignment = { horizontal: "right" };
          rowRef.getCell(10).alignment = { horizontal: "right" };
        }
      });
    });
    // Save the workbook
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    // const fileName = "InvoiceManagement.xlsx";
    // const fileURL = URL.createObjectURL(blob);

    // Trigger download
    // const link = document.createElement("a");
    // link.href = fileURL;
    return blob;
    // link.download = fileName;
    // link.click();
  };

  const downloadZip = async (data, billData, cardDatefiled) => {
    setLoading(true);
    const zip = new JSZip();
    const folders = {};

    const promises = data.map(async (record) => {
      const companyId = record.companyName;
      if (!folders[companyId]) {
        folders[companyId] = zip.folder(companyId);
      }
      const folder = folders[companyId];

      try {
        // Fetch and add the Excel file
        const newData = await exportImageExcel(record, billData);
        if (newData) {
          const excelFileName = `#${record?.invoiceNumber}-${record?.companyName}-${record?.ticketData?.jobName}-${record?.ticketData?.truckNumber}.xlsx`;
          folder.file(excelFileName, newData, { binary: true });
        } else {
          console.log("first");
        }

        // Fetch and add the image file
        const imageUrl = await Storage.get(record?.invoicePath);
        const imageBlob = await fetch(imageUrl).then((response) =>
          response.blob()
        );
        if (imageBlob) {
          const imageFileName = record?.invoicePath; // Or any other appropriate name
          folder.file(imageFileName, imageBlob, { binary: true });
        } else {
          console.log("second");
        }
      } catch (error) {
        console.error(
          `Error processing record ${record?.invoiceNumber}: ${error}`
        );
      }
    });

    // Wait for all promises to resolve
    await Promise.all(promises);

    try {
      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, `${cardDatefiled}.zip`);
      message.success(`${cardDatefiled} downloaded successfully`);
      setLoading(false);
    } catch (zipError) {
      setLoading(false);
      console.error("Error generating zip file:", zipError);
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Loading enableLoading={loading} />
      {props?.showIcon ? (
        <SiMicrosoftexcel
          style={{
            fontSize: "30px",
            height: "30px",
            width: "60px",
          }}
          onClick={() => {
            exportImageExcel();
          }}
        />
      ) : (
        <Button
          style={{
            borderRadius: "3px",
            height: "40px",
            marginRight: "5px",
            position: "relative",
            bottom: "6px",
            backgroundColor: "#586370",
          }}
          onClick={() => {
            downloadZip(props?.data, props?.billData, props?.cardDatefiled);
          }}
          icon={
            <DownloadOutlined
              style={{
                fontSize: "20px",
                marginLeft: "8px",
                marginTop: "2px",
                color: "white",
              }}
              className="trucking-filter-icon"
            />
          }
        >
          <span
            style={{
              color: "white",
              fontWeight: "500",
              fontSize: "16px",
            }}
            className="col_styling table-font-mobile"
          >
            Download
          </span>
        </Button>
      )}
    </div>
  );
};

export default ExcelExportHelper;
