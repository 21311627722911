import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Popconfirm,
  Form,
  Input,
  message,
  Popover,
  Switch,
  Upload,
  Spin,
  InputNumber,
} from "antd";
import { v4 as uuidv4 } from "uuid";
import Loading from "../../Common/Loading";
import { BsArrowLeftShort } from "react-icons/bs";
import {
  PlusOutlined,
  EllipsisOutlined,
  CameraOutlined,
} from "@ant-design/icons";
import ErrorView from "../../Common/ErrorView";
import { MdModeEditOutline, MdDelete } from "react-icons/md";
// import { IoMdAdd } from "react-icons/io";
import { Storage } from "aws-amplify";
import BaseTable from "../../Common/BaseTable";
import SearchFunction from "../../Common/SearchFunction";
import AOS from "aos";
import "aos/dist/aos.css";
import { connect } from "react-redux";
import {
  addDocument,
  getDropDownOptions,
  getLeads,
  updateDropDownOptions,
  updateLead,
  updateQuarryStatus,
} from "../../Redux/Actions/actions";
import BaseModal from "../../Common/BaseModal";
import NumberFormat from "react-number-format";
import appConfig from "../../config/AppConfig";
import moment from "moment";
import { SlDoc } from "react-icons/sl";
import { withAppContext } from "../../Store/AppContext";
import { withRouter } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (
  isDragging,
  draggableStyle,
  mainClickMode,
  index,
  mainEditMode
) => ({
  display: "flex",
  // marginBottom: "10px",
  position: "relative",
  width: "100%",
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: mainEditMode && mainClickMode === index ? "0px" : "16px",
  margin: `0 0 8px 0`,
  cursor: "move",
  // change background colour if dragging
  background: mainClickMode === index || isDragging ? "#586370" : "#FFF",
  color: isDragging ? "#FFF" : "#000",
  height: "40px",
  borderRadius: "5px",
  border: mainEditMode && mainClickMode === index ? "0px" : "1px solid #d9d9d9",
  alignItems: "center",
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? "lightblue" : "lightgrey",
  // padding: grid,
  width: "100%",
});
class SystemSettings extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      items: "",
      loading: false,
      errorLoading: false,
      materialList: {},
      selectedKey: 1,
      category: "",
      clickMode: 0,
      clickMode2: 0,
      clickMode3: 0,
      mainEditMode: false,
      editMode: false,
      editMode3: false,
      addCategory1: false,
      addCategory2: false,
      addCategory3: false,

      mainKey: "",
      mainCatVal: "",
      addSubValue: "",
      addSubValue2: "",
      addSubValue3: "",
      subCatVal: "",
      subCatVal2: "",
      subCatVal3: "",
      materialUpdateId: "",
      commisionUpdateId: "",
      trucksUpdateId: "",
      deleteCateIds: [],
      totalData: [
        { name: "Materials" },
        { name: "Truck Types" },
        // { name: "Commissions" },
        { name: "Payment Terms" },
        { name: "Tax Exempt" },
        { name: "Ticket Verified Users" },
        { name: "Sales Tax" },
      ],
      showMaterials: false,
      showCommisions: false,
      commissionForm: false,
      commisionsData: [],
      truckingCommisionsData: [],
      recordData: {},
      resultData: [],
      showLeads: false,
      leadDetails: [],
      taxExemptState: false,
      imageLoading: false,
      quoteAttachment: "",
      quoteAttachmentId: "",
      leadId: "",
      showMainMaterial: false,
      mainClickMode: 0,
      addMainValue: "",
      deleteMainCatId: [],
      showTrucks: false,
      truckForm: false,
      recordTrucksData: {},
      showTerms: false,
      termsForm: false,
      showTicket: false,
      recordTermsData: {},
      termsData: [],
      trucksData: [],
      commissionType: "",
      termsUpdateId: "",
      addMainCategory: false,
      originalMaterialList: [],
      categoryKeyValue: "",
      salesTaxRate: "",
      salesUpdateId: "",
      showSales: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.resultData &&
      props.resultData.totalData &&
      props.resultData.totalData.length
    ) {
      return { resultData: props.resultData };
    }
    return state;
  }

  componentDidMount() {
    this.props.actionFetchDropDown();
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
  }

  componentDidUpdate = (prevProps) => {
    if (
      prevProps?.resultData?.success === false &&
      this?.props?.resultData?.success === true
    ) {
      this.getMaterials();
    }
    if (
      prevProps?.updateData?.success === false &&
      this?.props?.updateData?.success === true
    ) {
      this.props.actionFetchDropDown();
    }
    if (
      prevProps?.leadsData?.success === false &&
      this.props?.leadsData?.success === true
    ) {
      // console.log(this.props?.leadsData?.leads);
      let data = this.props?.leadsData?.leads?.filter(
        (l) =>
          l?.stageId !== "Contacted" &&
          l?.stageId !== "Job Lead" &&
          l?.stageId !== "Paused" &&
          l?.stageId !== "Close Job" &&
          l?.jobType === "Trucking & Material Job" &&
          l?.invoiceResult?.length === 0
      );
      this.setState({ leadDetails: data, showLeads: true, leadId: "" });
    }
    if (
      prevProps?.docResult?.success === false &&
      this.props?.docResult?.success === true
    ) {
      const { leadData, leadId } = this.state;
      delete leadData?.id;
      delete leadData?.updatedAt;
      leadData["reQuote"] = true;
      // console.log(leadData);
      let data = {
        entityData: {
          ...leadData,
        },
      };
      this.props?.actionUpdateJob(leadId, data);
      this.setState({
        quoteAttachment: "",
        quoteAttachmentId: "",
      });
    }
    if (
      prevProps?.leadUpdateInfo?.success === false &&
      this.props?.leadUpdateInfo?.success === true
    ) {
      this.props?.actionFetchGetLeads();
    }
  };

  onDragEnd = (result) => {
    const { materialList } = this.state;
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );
    let groups = {};
    items?.map((e, i) => {
      groups[e] = Object.values(materialList[e]).map((val) => ({
        ...val,
        indexVal: i,
        dragable: true,
      }));
      return e;
    });
    this.setState({
      items,
      materialList: groups,
      categoryKeyValue: materialList[result.draggableId][0]?.categoryKey,
      category: result.draggableId,
      mainClickMode: result.destination.index,
      addMainCategory: false,
      clickMode: 0,
      clickMode3: 0,
    });
  };

  handleUploadPic = async (info) => {
    this.setState({
      imageLoading: true,
      loading: true,
    });
    switch (info.file.status) {
      case "done":
        if (info.fileList?.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              Storage.put(info.file.uid, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                Storage.get(result.key).then((urlResult) => {
                  this.setState({
                    quoteAttachment: urlResult,
                    imageLoading: false,
                    loading: false,
                    quoteAttachmentId: result.key,
                    fileType: info.file.type,
                  });
                });
              });
            } else {
              this.setState({
                imageLoading: false,
                loading: false,
              });
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;
      default:
    }
  };

  getMaterials = () => {
    const { resultData } = this.state;
    let materialsNewList = [];

    if (resultData?.totalData?.length > 0) {
      resultData?.totalData?.map((t) => {
        if (t.typeofDropDown === "material") {
          materialsNewList = t?.materialsNewList;
          this.setState({
            originalMaterialList: t?.materialsNewList,
            materialUpdateId: t.id,
          });
        } else if (t.typeofDropDown === "commisions") {
          this.setState({
            commisionsData: t?.commisionsData,
            truckingCommisionsData: t?.truckingCommisionsData,
            commisionUpdateId: t?.id,
          });
        } else if (t.typeofDropDown === "trucksType") {
          this.setState({
            trucksData: t?.trucksData,
            trucksUpdateId: t?.id,
          });
        } else if (t.typeofDropDown === "carrierTerms") {
          this.setState({
            termsData: t?.termsData,
            termsUpdateId: t?.id,
          });
        } else if (t.typeofDropDown === "ticketVerification") {
          this.setState({
            usersCount: t?.usersCount,
            ticketUpdateId: t?.id,
          });
        } else if (t.typeofDropDown === "salesTaxRate") {
          this.setState({
            salesTaxRate: t?.taxRate,
            salesUpdateId: t?.id,
          });
        }
        return true;
      });

      let materialData = {};
      let index = 0;
      let sortedMaterialList = materialsNewList
        ?.filter((v) => v.statusCode)
        .sort((a, b) => a?.indexVal - b?.indexVal);

      sortedMaterialList?.map((eachMat) => {
        let subTypes = [];
        if (eachMat?.statusCode) {
          index += 1;
          materialData[eachMat.category] = !Object.keys(materialData).includes(
            eachMat.category
          )
            ? []
            : materialData[eachMat.category];
          let typeCheck = materialData[eachMat.category].filter(
            (o) => o.subcat === eachMat.materialValue.split(" - ")[1]
          );

          if (typeCheck.length > 0) {
            subTypes = typeCheck[0]["subTypes"];
            subTypes.push({
              subType: eachMat.materialValue.split(" - ")[2],
              id: eachMat.id,
              editMode: false,
            });
          } else {
            subTypes.push({
              subType: eachMat.materialValue.split(" - ")[2],
              id: eachMat.id,
              editMode: false,
            });
            materialData[eachMat.category].push({
              subcat: eachMat.materialValue.split(" - ")[1],
              cate: eachMat.materialValue.split(" - ")[0],
              categoryKey: eachMat.categoryKey,
              subShortName: eachMat.subShortName,
              editSubCat: false,
              editMainCat: false,
              editCatKey: false,
              dragable: false,
              subTypes,
              indexVal: eachMat.indexVal,
              // index,
            });
          }
          if (
            sortedMaterialList?.filter((e) => e.statusCode)?.length === index
          ) {
            this.setState({
              items: Object.keys(materialData),
              materialList: materialData,
              loading: false,
              category: Object.keys(materialData)[0],
              categoryKeyValue: Object.values(
                materialData[Object.keys(materialData)[0]]
              )[0].categoryKey,
              clickMode: 0,
              clickMode2: 0,
              mainClickMode: 0,
            });
          } else {
            this.setState({
              loading: false,
            });
          }
        }
        return true;
      });
    }
  };

  onClickReturn = () => {
    this.props.history.goBack();
  };

  handleEditMainSub = (index, eachSub, key) => {
    this.setState({
      mainEditMode: true,
      mainClickMode: index,
      addMainCategory: false,
      clickMode: 0,
      clickMode3: 0,
      category: eachSub,
      mainCatVal: eachSub,
      mainKey: key,
    });
    setTimeout(() => {
      document.getElementById("myText").focus();
    }, 10);
  };

  handleEditSub1 = (index, eachSub) => {
    // console.log("index", index);
    this.setState({
      editMode: true,
      clickMode: index,
      clickMode3: index,
      subCatVal: eachSub.subcat,
    });
    setTimeout(() => {
      document.getElementById("myText").focus();
    }, 10);
  };

  handleEditSub2 = (index) => {
    this.setState({
      editMode: true,
      clickMode2: index,
    });
    setTimeout(() => {
      document.getElementById("myText2").focus();
    }, 10);
  };

  handleEditSub3 = (index, eachSub) => {
    this.setState({
      editMode3: true,
      clickMode3: index,
      clickMode: index,
      subCatVal3: eachSub.subShortName,
    });
    setTimeout(() => {
      document.getElementById("myText3").focus();
    }, 10);
  };

  updateMainVal = (val, index, key) => {
    const { materialList, mainCatVal, mainKey } = this.state;
    let newData = Object.keys(materialList);
    const newValue = mainCatVal !== "" ? mainCatVal : val;
    const newKeyValue = mainKey !== "" ? mainKey : key;
    let groups = materialList;

    if (mainCatVal !== val || mainKey !== key) {
      groups = {};
      const updatedData = materialList[val].map((obj) => ({
        ...obj,
        cate: newValue,
        editMainCat: true,
        categoryKey: newKeyValue,
        editCatKey: true,
      }));
      // replace old Category to new Category
      newData.splice(index, 1, newValue);

      // add Object Values to Categories
      newData?.map((e) => {
        groups[e] = materialList[e];
        return e;
      });

      // update new Category value to SubCategory
      groups[newValue] = updatedData;
    }
    this.setState({
      materialList: groups,
      mainEditMode: false,
      mainCatVal: "",
      clickMode: 0,
      clickMode3: 0,
      addMainCategory: false,
      category: newValue,
      mainClickMode: index,
    });
  };

  updateSubcatVal = (index, val) => {
    const { materialList, category, subCatVal } = this.state;
    if (subCatVal !== "") {
      materialList[category][index].subcat = subCatVal;
      materialList[category][index].editSubCat = true;
    } else {
      materialList[category][index].subcat = val;
      materialList[category][index].editSubCat = true;
    }
    if (
      index === 0 &&
      (materialList[category][index].subShortName === "" ||
        materialList[category][index].subShortName === undefined)
    ) {
      setTimeout(() => {
        document.getElementById("myText3").focus();
      }, 1);
      this.setState({
        editMode3: true,
        clickMode3: index,
      });
    }

    this.setState({
      materialList,
      editMode: false,
      subCatVal: "",
    });
  };

  updateSubcatVal2 = (subIndex, valIndex, val) => {
    const { materialList, category, subCatVal2 } = this.state;
    materialList[category][subIndex]["subTypes"].map((e, i) => {
      if (i === valIndex) {
        if (subCatVal2 !== "") {
          materialList[category][subIndex]["subTypes"][valIndex]["subType"] =
            subCatVal2;
          materialList[category][subIndex]["subTypes"][valIndex][
            "editMode"
          ] = true;
        } else {
          materialList[category][subIndex]["subTypes"][valIndex]["subType"] =
            val;
          materialList[category][subIndex]["subTypes"][valIndex][
            "editMode"
          ] = true;
        }
      }
      return true;
    });

    this.setState({
      materialList,
      editMode: false,
      subCatVal2: "",
    });
  };

  updateSubcatVal3 = (index, val) => {
    const { materialList, category, subCatVal3 } = this.state;
    if (subCatVal3 !== "") {
      materialList[category][index].subShortName = subCatVal3;
      materialList[category][index]["editShort"] = true;
    } else {
      materialList[category][index].subShortName = val;
      materialList[category][index]["editShort"] = true;
    }
    this.setState({
      materialList,
      editMode3: false,
      subCatVal3: "",
    });
  };

  deleteRecord = (index, record) => {
    const { materialList, category, deleteCateIds } = this.state;
    let updateData = materialList[category].filter(
      (eachItem, i) => i !== index
    );
    // console.log(updateData, "updateData");

    // DELETE ALL SUB CATEGORY TYPES LIST
    let deleteCateIdsList = deleteCateIds.length > 0 ? deleteCateIds : [];
    record.subTypes.map((eachCat) => {
      return deleteCateIdsList.push(eachCat.id);
    });
    // console.log(deleteCateIdsList, "deleteCateIdsList");
    materialList[category] = updateData;
    this.setState({
      materialList,
      editMode: false,
      deleteCateIds: deleteCateIdsList,
      clickMode: 0,
    });
  };

  deleteRecordMain = (record, index) => {
    const { deleteMainCatId, materialList, items } = this.state;
    delete materialList[record];
    let deleteCateIdsList = deleteMainCatId.length > 0 ? deleteMainCatId : [];
    deleteCateIdsList.push(record);
    setTimeout(() => {
      this.setState({
        category:
          items?.length === index + 1
            ? items[0]
            : items.find((e, i) => i === index + 1),
        mainClickMode: items?.length === index + 1 ? 0 : index,
        materialList,
        items: items.filter((i) => i !== record),
        deleteMainCatId: deleteCateIdsList,
        clickMode: 0,
      });
    }, 100);
  };

  deleteSub2 = (subIndex, valIndex, subTypeRecord) => {
    const { materialList, category, deleteCateIds } = this.state;

    // DELETING ID LIST DECLARATION
    let deleteCateIdsList = deleteCateIds.length > 0 ? deleteCateIds : [];

    let cateTypeList = materialList[category][subIndex]["subTypes"].filter(
      (deleteId) => deleteId.id === subTypeRecord.id
    );

    // ID'S PUSH TO LIST
    cateTypeList.map((eachType) => {
      return deleteCateIdsList.push(eachType.id);
    });

    let updateData = materialList[category][subIndex]["subTypes"].filter(
      (e, i) => i !== valIndex
    );
    materialList[category][subIndex]["subTypes"] = updateData;
    this.setState({
      materialList,
      editMode: false,
      deleteCateIds: deleteCateIdsList,
    });
  };

  addCategory = (type) => {
    const {
      addSubValue,
      materialList,
      category,
      categoryKeyValue,
      addSubValue3,
      clickMode,
      items,
    } = this.state;
    if (categoryKeyValue === "") {
      message.error("Please add category key!");
      return;
    } else if (categoryKeyValue !== "") {
      if (addSubValue !== "") {
        materialList[category].push({
          subcat: addSubValue,
          cate: category,
          categoryKey: categoryKeyValue,
          subTypes: [
            {
              subType: "",
              id: uuidv4(),
              editMode: false,
            },
          ],
          editSubCat: true,
          subShortName: "",
          editShort: true,
          indexVal: items?.findIndex((e) => e === category),
        });
        this.setState({
          materialList,
        });
      }
      if (addSubValue3 !== "") {
        materialList[category][clickMode]["subShortName"] = addSubValue3;
        materialList[category][clickMode]["editShort"] = true;
        this.setState({
          materialList,
        });
      }

      if (type === "add") {
        let errorShortName = materialList[category]?.filter(
          (e) => e.subShortName === "" || e.subShortName === undefined
        );
        if (
          errorShortName?.length === 0 &&
          materialList[category][0]?.subcat !== ""
        ) {
          this.setState({
            materialList,
            clickMode: materialList[category]?.length,
            addCategory1: true,
            clickMode3: materialList[category]?.length,
            addCategory3: true,
          });
          setTimeout(() => {
            document.getElementById("myCat").focus();
          }, 1);
        } else {
          if (materialList[category][0]?.subcat === "") {
            this.setState({
              clickMode: 0,
              editMode: true,
            });
            setTimeout(() => {
              document.getElementById("myText").focus();
            }, 1);
          } else {
            message.error("Please add Short Name!");
          }
        }
      } else {
        if (addSubValue) {
          setTimeout(() => {
            document.getElementById("myText3").focus();
          }, 1);
        }
        this.setState({
          editMode3: addSubValue !== "" ? true : false,
          clickMode3:
            addSubValue !== "" ? materialList[category]?.length - 1 : "",
          addCategory1: false,
          addSubValue: "",
          addCategory3: false,
          addSubValue3: "",
        });
      }
    }
  };

  addCategory2 = (type) => {
    const { addSubValue2, materialList, category, clickMode } = this.state;
    if (addSubValue2 !== "") {
      materialList[category][clickMode]["subTypes"].push({
        subType: addSubValue2,
        id: uuidv4(),
        editMode: true,
      });
      this.setState({
        materialList,
      });
    }
    if (type === "add") {
      this.setState({
        materialList,
        clickMode2: materialList[category]?.length,
        addCategory2: true,
      });
      setTimeout(() => {
        document.getElementById("myCat2").focus();
      }, 1);
    } else {
      this.setState({
        addCategory2: false,
        addSubValue2: "",
      });
    }
  };

  addCategory3 = (type) => {
    const { addSubValue3, materialList, category, clickMode } = this.state;
    if (addSubValue3 !== "") {
      materialList[category][clickMode]["subShortName"] = addSubValue3;
      materialList[category][clickMode]["editShort"] = true;
      this.setState({
        materialList,
      });
    }
    if (type === "add") {
      this.setState({
        materialList,
        clickMode3: clickMode,
        addCategory3: true,
      });
      setTimeout(() => {
        document.getElementById("myCat3").focus();
      }, 1);
    } else {
      this.setState({
        addCategory3: false,
        addSubValue3: "",
      });
    }
  };

  searchResult = (searchValue) => {
    const { totalData } = this.state;
    let searchFilter = [];
    if (typeof searchValue !== "number") {
      searchFilter = totalData?.filter(
        (e) => e["name"]?.toLowerCase().indexOf(searchValue) > -1
      );
    }
    this.setState({
      totalData: searchFilter,
    });
  };

  onCancelModal = () => {
    const { materialList } = this.state;
    this.setState({
      addMainCategory: false,
      showMaterials: false,
      category: Object.keys(materialList)[0],
      categoryKeyValue: Object.values(
        materialList[Object.keys(materialList)[0]]
      )[0].categoryKey,
      clickMode: 0,
      clickMode2: 0,
    });
    this.getMaterials();
  };

  onResetData = () => {
    this.setState({
      totalData: [{ name: "Materials" }],
    });
  };

  deleteCommission = (id) => {
    const { commisionUpdateId, commisionsData, truckingCommisionsData } =
      this.state;
    let commisionsInfo = commisionsData?.filter((e) => e.id !== id);
    let data = {
      entityData: {
        commisionsData: commisionsInfo,
        truckingCommisionsData,
      },
    };
    this.props?.actionupdateDropDownOptions(commisionUpdateId, data);
  };

  deleteCommissionTrucking = (id) => {
    const { commisionUpdateId, truckingCommisionsData, commisionsData } =
      this.state;
    let commisionsInfo = truckingCommisionsData?.filter((e) => e.id !== id);
    let data = {
      entityData: {
        truckingCommisionsData: commisionsInfo,
        commisionsData,
      },
    };
    this.props?.actionupdateDropDownOptions(commisionUpdateId, data);
  };

  deleteTruckTypes = (id) => {
    const { trucksUpdateId, trucksData } = this.state;
    let trucksInfo = trucksData?.filter((e) => e.id !== id);
    let data = {
      entityData: {
        trucksData: trucksInfo,
      },
    };
    this.props?.actionupdateDropDownOptions(trucksUpdateId, data);
  };

  deleteTerms = (id) => {
    const { termsUpdateId, termsData } = this.state;
    let termsInfo = termsData?.filter((e) => e.id !== id);
    let data = {
      entityData: {
        termsData: termsInfo,
      },
    };
    this.props?.actionupdateDropDownOptions(termsUpdateId, data);
  };

  onFinishCommissions = (values) => {
    const {
      commisionUpdateId,
      commisionsData,
      recordData,
      truckingCommisionsData,
    } = this.state;
    let data = {};
    if (Object.values(recordData)?.length > 0) {
      commisionsData?.map((e) => {
        if (e?.id === recordData?.id) {
          e["maxRevenueTier"] = values["maxRevenueTier"];
          e["minRevenueTier"] = values["minRevenueTier"];
          e["rate"] = values["rate"];
        }
        return e;
      });
      data = {
        entityData: {
          commisionsData,
          truckingCommisionsData,
        },
      };
    } else {
      let commisionsInfo = commisionsData ? commisionsData : [];
      values["id"] = uuidv4();
      commisionsInfo.push(values);
      data = {
        entityData: {
          commisionsData: commisionsInfo,
          truckingCommisionsData,
        },
      };
    }
    this.props?.actionupdateDropDownOptions(commisionUpdateId, data);
    this.setState({
      commissionForm: false,
    });
  };

  onFinishCommissionsTrucking = (values) => {
    const {
      commisionUpdateId,
      truckingCommisionsData,
      commisionsData,
      recordData,
    } = this.state;
    let data = {};
    if (Object.values(recordData)?.length > 0) {
      truckingCommisionsData?.map((e) => {
        if (e?.id === recordData?.id) {
          e["maxRevenueTier"] = values["maxRevenueTier"];
          e["minRevenueTier"] = values["minRevenueTier"];
          e["rate"] = values["rate"];
        }
        return e;
      });
      data = {
        entityData: {
          truckingCommisionsData,
          commisionsData,
        },
      };
    } else {
      let commisionsInfo = truckingCommisionsData ? truckingCommisionsData : [];
      values["id"] = uuidv4();
      commisionsInfo.push(values);
      data = {
        entityData: {
          truckingCommisionsData: commisionsInfo,
          commisionsData,
        },
      };
    }
    // console.log(data, "data");
    this.props?.actionupdateDropDownOptions(commisionUpdateId, data);
    this.setState({
      commissionForm: false,
    });
  };

  updateApi = () => {
    const {
      materialList,
      originalMaterialList,
      materialUpdateId,
      deleteCateIds,
      deleteMainCatId,
      category,
      categoryKeyValue,
    } = this.state;

    const { actionupdateDropDownOptions, actionUpdateQuarryStatus } =
      this.props;
    let updatedIds = [];
    if (category !== "" && categoryKeyValue === "") {
      message.error("Please add category key!");
      return true;
    }
    this.setState({
      loading: true,
    });
    Object.keys(materialList).map((cat) => {
      let subList = materialList[cat].filter(
        (sub) =>
          sub.editSubCat === true ||
          sub.editMainCat === true ||
          sub.editCatKey === true ||
          sub.dragable === true
      );
      let subShortList = materialList[cat].filter(
        (sub) => sub.editShort === true
      );

      materialList[cat].map((sub) => {
        let typesCheck = sub.subTypes.filter(
          (innerType) => innerType.editMode === true
        );

        if (typesCheck.length > 0) {
          typesCheck.map((e) => {
            e["subCat"] = sub["subcat"];
            e["mainCat"] = sub["cate"];
            e["categoryKey"] = sub["categoryKey"];
            return e;
          });
          // console.log(typesCheck, "typesCheck");
          updatedIds = updatedIds.concat(typesCheck);
        }
        return sub;
      });

      if (subList.length > 0) {
        subList.map((eachSub) => {
          if (eachSub.subcat === "") {
            message.error("Please add a minimum of 1 sub-category to save!");
            this.setState({
              loading: false,
            });
            return true;
          }

          if (eachSub.subTypes.length === 0) {
            message.error("Please add Sub-Category 2 Materials!");
            this.setState({
              loading: false,
            });
            return true;
          }

          if (
            eachSub.subShortName === "" ||
            eachSub.subShortName === undefined
          ) {
            message.error("Please add Short Name!");
            this.setState({
              loading: false,
            });
            return true;
          }
          eachSub.subTypes.map((e) => {
            e["indexVal"] = eachSub.indexVal;
            e["subCat"] = eachSub.subcat;
            e["mainCat"] = eachSub.cate;
            e["categoryKey"] = eachSub.categoryKey;
            e["subShortName"] = eachSub["subShortName"];
            return e;
          });
          updatedIds = updatedIds.concat(eachSub.subTypes);
          return eachSub;
        });
      }

      if (subShortList.length > 0) {
        subShortList.map((eachSub) => {
          if (
            eachSub.subShortName !== "" &&
            eachSub.subShortName !== undefined
          ) {
            eachSub.subTypes.map((e) => {
              e["indexVal"] = eachSub.indexVal;
              e["subCat"] = eachSub.subcat;
              e["mainCat"] = eachSub.cate;
              e["categoryKey"] = eachSub.categoryKey;
              e["subShortName"] = eachSub["subShortName"];
              return e;
            });
            updatedIds = updatedIds.concat(eachSub.subTypes);
            this.setState({
              loading: false,
            });
          }
          return eachSub;
        });
      }
      return cat;
    });
    // let checkError = Object.entries(materialList).map(([cat, subCat]) =>
    //   subCat.filter(
    //     (e) =>
    //       e.mainCat === "" ||
    //       e.subcat === "" ||
    //       e.subShortName === "" ||
    //       e.subShortName === undefined
    //   )
    // );
    let errorData = materialList[category]?.filter(
      (e) =>
        e.mainCat === "" ||
        e.subcat === "" ||
        e.subShortName === "" ||
        e.subShortName === undefined
    );
    if (updatedIds.length > 0 && errorData.length === 0) {
      updatedIds.map((eachPayLoad, index) => {
        let oldObj = [];
        if (originalMaterialList?.length > 0) {
          oldObj = originalMaterialList?.filter(
            (obj) => obj.id === eachPayLoad.id
          );
        }

        // UPDATE OLD SUB CATEGORY TEXT
        if (oldObj?.length > 0) {
          oldObj = oldObj[0];
          oldObj["indexVal"] = eachPayLoad.indexVal;
          oldObj["category"] = eachPayLoad.mainCat;
          oldObj["categoryKey"] = eachPayLoad.categoryKey;
          oldObj[
            "materialValue"
          ] = `${eachPayLoad.mainCat} - ${eachPayLoad.subCat} - ${eachPayLoad.subType}`;
          oldObj[
            "subCategory"
          ] = `${eachPayLoad.mainCat} - ${eachPayLoad.subCat}`;
          oldObj["label"] = `${eachPayLoad.subCat} - ${eachPayLoad.subType}`;
          oldObj["subShortName"] = eachPayLoad.subShortName;

          originalMaterialList.splice(
            originalMaterialList.findIndex((e) => e.id === eachPayLoad.id),
            1,
            oldObj
          );
        }
        // console.log(oldObj, "oldObj");

        // NEW SUB CATEGORY ADDING
        if (oldObj?.length === 0) {
          oldObj = {};
          oldObj["indexVal"] = eachPayLoad.indexVal;
          oldObj["category"] = eachPayLoad.mainCat;
          oldObj["categoryKey"] = eachPayLoad.categoryKey;
          oldObj["subShortName"] = eachPayLoad.subShortName;
          oldObj[
            "materialValue"
          ] = `${eachPayLoad.mainCat} - ${eachPayLoad.subCat} - ${eachPayLoad.subType}`;
          oldObj[
            "subCategory"
          ] = `${eachPayLoad.mainCat} - ${eachPayLoad.subCat}`;
          oldObj["label"] = `${eachPayLoad.subCat} - ${eachPayLoad.subType}`;
          oldObj["id"] = eachPayLoad.id;
          oldObj["statusCode"] = true;
          originalMaterialList.push(oldObj);
        }
        let data = {
          entityData: {
            materialsNewList: originalMaterialList?.sort(
              (a, b) => a.indexVal < b.indexVal
            ),
          },
        };
        if (updatedIds.length === index + 1) {
          // console.log(data, "data");
          actionupdateDropDownOptions(materialUpdateId, data);
        }
        return eachPayLoad;
      });
    }

    // DELETE SUB CATEGORIES LIST TO API
    if (deleteCateIds.length > 0) {
      // FILTER NEW LIST FROM OLD LIST
      let materialId = [];
      originalMaterialList?.forEach((element) => {
        if (deleteCateIds?.includes(element.id)) {
          element["statusCode"] = false;
          materialId?.push(element.id);
        }
      });
      // let newMatList = originalMaterialList.filter(
      //   (oldItem) => !deleteCateIds.includes(oldItem.id) === true
      // );
      // UpdateQuarryStatusData
      let updateData = {
        entityData: {
          materialId: materialId,
        },
      };

      // UPDATE NEW LIST TO API
      let data = {
        entityData: {
          materialsNewList: originalMaterialList,
        },
      };
      actionupdateDropDownOptions(materialUpdateId, data);
      actionUpdateQuarryStatus(updateData);
    }

    // DELETE MAIN CATEGORY LIST TO API
    if (deleteMainCatId?.length > 0) {
      let materialId = [];
      originalMaterialList?.forEach((element) => {
        if (deleteMainCatId?.includes(element.category)) {
          element["statusCode"] = false;
          materialId?.push(element.id);
        }
      });
      let data = {
        entityData: {
          materialsNewList: originalMaterialList,
        },
      };
      let updateData = {
        entityData: {
          materialId: materialId,
        },
      };
      actionupdateDropDownOptions(materialUpdateId, data);
      actionUpdateQuarryStatus(updateData);
    }
    this.setState({
      loading: false,
    });
  };

  handleTableChage = (props, type) => {
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    localStorage.setItem(
      "tableSizeRange",
      JSON.stringify({ ...tableSizeRange, [type]: props })
    );
  };

  showMaterials = () => {
    const {
      editMode,
      subCatVal,
      // subCatVal2,
      clickMode,
      // clickMode2,
      materialList,
      category,
      addCategory1,
      // addCategory2,
      addSubValue,
      // addSubValue2,
      mainClickMode,
      addMainCategory,
      addMainValue,
      categoryKeyValue,
      subCatVal3,
      clickMode3,
      addCategory3,
      addSubValue3,
      editMode3,
      mainEditMode,
      mainCatVal,
      mainKey,
    } = this.state;
    return (
      // <div style={{ padding: "0px 15vw", minHeight: "100vh" }}>
      <div
        style={{
          margin: "0px auto",
          minHeight: "100vh",
          maxWidth: "980px",
          textAlign: "left",
        }}
      >
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "1vw",
          }}
        >
          <Row
            onClick={
              this.onCancelModal
              // this.setState({
              //   showMaterials: false,
              // })
            }
            style={{ cursor: "pointer" }}
          >
            <BsArrowLeftShort
              style={{ color: "#808080", fontSize: "30px" }}
              className="back-button-return-icon"
            />
            <span
              style={{ color: "#808080", fontSize: "18px" }}
              className="back-button-return"
            >
              Return
            </span>
          </Row>
        </Row>
        <Card
          title="Materials List"
          style={{ minHeight: "60vh" }}
          className="manage-roles-card"
        >
          <Form>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <span
                className="title_changes"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Material Categories
              </span>
              <Button
                type="primary"
                icon={<PlusOutlined style={{ color: "#000" }} />}
                style={{
                  borderRadius: "5px",
                  height: "35px",
                  margin: "auto 0px",
                  background: "#fff",
                }}
                onClick={() => {
                  this.setState({
                    addMainCategory: true,
                    mainClickMode: Object.keys(materialList).length,
                    clickMode: 0,
                    clickMode2: 0,
                    clickMode3: 0,
                    categoryKeyValue: "",
                    addMainValue: "",
                  });
                }}
              >
                <span style={{ color: "#000" }}>Material Category</span>
              </Button>
            </div>

            <Form.Item
              name="category"
              rules={[
                {
                  required: true,
                  message: "Please Select Your Category!",
                },
              ]}
              initialValue={category}
            >
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {this.state.items.map((eachSub, index) => (
                        <Draggable
                          key={eachSub}
                          draggableId={eachSub}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                                index,
                                mainClickMode,
                                mainEditMode
                              )}
                              onClick={() =>
                                this.setState({
                                  categoryKeyValue:
                                    materialList[eachSub]?.length > 0 &&
                                    materialList[eachSub][0]?.categoryKey,
                                  category: eachSub,
                                  mainClickMode: index,
                                  addMainCategory: false,
                                  clickMode: 0,
                                  clickMode3: 0,
                                })
                              }
                            >
                              {mainEditMode && mainClickMode === index ? (
                                <div style={{ width: "100%" }}>
                                  <Input
                                    id="myText"
                                    value={mainCatVal}
                                    placeholder="Category"
                                    className="input_subCategory"
                                    style={{
                                      background:
                                        mainClickMode === index
                                          ? "#586370"
                                          : "transparent",
                                      color: "#FFF",
                                      width: "80%",
                                    }}
                                    onClick={() => {
                                      this.setState({
                                        mainClickMode: index,
                                      });
                                    }}
                                    onChange={(e) =>
                                      this.setState({
                                        mainCatVal: e.target.value,
                                      })
                                    }
                                    onBlur={() => {
                                      setTimeout(() => {
                                        document
                                          .getElementById("mainCatKey1")
                                          .focus();
                                      }, 1);
                                    }}
                                  />
                                  <Input
                                    value={mainKey}
                                    id="mainCatKey1"
                                    className="input_subCategory"
                                    placeholder="Category Key"
                                    ref={this.formRef}
                                    style={{
                                      background: "#586370",
                                      color: "#FFF",
                                      width: "20%",
                                    }}
                                    onChange={(e) =>
                                      this.setState({
                                        mainKey: e.target.value,
                                      })
                                    }
                                    onBlur={() =>
                                      this.updateMainVal(
                                        eachSub,
                                        index,
                                        materialList[eachSub][0]?.categoryKey
                                      )
                                    }
                                  />
                                </div>
                              ) : (
                                <div>
                                  <span
                                    style={{
                                      float: "left",
                                      color:
                                        mainClickMode === index
                                          ? "#FFF"
                                          : "#000",
                                    }}
                                    className="span_text"
                                  >
                                    {materialList[eachSub]?.length > 0 &&
                                    Object.values(materialList[eachSub])[0]
                                      ?.categoryKey !== undefined
                                      ? `${eachSub} - ${
                                          Object.values(
                                            materialList[eachSub]
                                          )[0]?.categoryKey
                                        }`
                                      : eachSub}
                                  </span>
                                </div>
                              )}
                              <Row className="popovergrid popover_row">
                                <Button
                                  className="btn_PopconfirmEdit"
                                  onClick={() =>
                                    this.handleEditMainSub(
                                      index,
                                      eachSub,
                                      Object.values(materialList[eachSub])[0]
                                        ?.categoryKey
                                    )
                                  }
                                >
                                  <span
                                    className="btn_deleteIcon"
                                    style={{
                                      color:
                                        mainClickMode === index && !mainEditMode
                                          ? "#FFF"
                                          : "#c3c3c3",
                                    }}
                                  >
                                    <MdModeEditOutline
                                      style={{ marginRight: "5px" }}
                                    />
                                  </span>
                                </Button>
                                <Button className="btn_PopconfirmDelete">
                                  <Popconfirm
                                    title="Are you sure？"
                                    okText="Yes"
                                    cancelText="No"
                                    onConfirm={() =>
                                      this.deleteRecordMain(eachSub, index)
                                    }
                                    style={{
                                      backgroundColor:
                                        mainClickMode === index
                                          ? "#586370"
                                          : "transparent",
                                    }}
                                  >
                                    <span
                                      className="btn_deleteIcon"
                                      style={{
                                        color:
                                          mainClickMode === index //&& !mainEditMode
                                            ? "#FFF"
                                            : "#c3c3c3",
                                      }}
                                    >
                                      <MdDelete
                                        style={{
                                          marginRight: "5px",
                                        }}
                                      />{" "}
                                    </span>
                                  </Popconfirm>
                                </Button>
                              </Row>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              {addMainCategory && (
                <div
                  style={{
                    display: "flex",
                    marginBottom: "10px",
                    position: "relative",
                  }}
                >
                  <Input
                    autoFocus
                    id="mainCat"
                    className="input_subCategory"
                    placeholder="Category"
                    ref={this.formRef}
                    onChange={(e) =>
                      this.setState({
                        addMainValue: e.target.value,
                        category: e.target.value,
                      })
                    }
                    style={{
                      background: "#586370",
                      color: "#FFF",
                      width: "80%",
                    }}
                    onBlur={this.mainCategory}
                    value={
                      addMainValue && categoryKeyValue
                        ? `${addMainValue} - ${categoryKeyValue}`
                        : addMainValue
                    }
                  />
                  <Input
                    // autoFocus
                    id="mainCatKey"
                    className="input_subCategory"
                    placeholder="Category Key"
                    ref={this.formRef}
                    style={{
                      background: "#586370",
                      color: "#FFF",
                      width: "20%",
                    }}
                    onChange={(e) =>
                      this.setState({
                        categoryKeyValue: e.target.value,
                      })
                    }
                    onBlur={this.addMainCategory}
                  />
                </div>
              )}
            </Form.Item>

            <Row gutter={[30, 0]}>
              <Col
                xxl={{ span: 16 }}
                xl={{ span: 16 }}
                lg={{ span: 16 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                data-aos={window.screen.width > 1023 ? "fade-right" : ""}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <span
                    className="title_changes"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Sub-Category 1
                  </span>
                  <Button
                    type="primary"
                    icon={<PlusOutlined style={{ color: "#000" }} />}
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      margin: "auto 0px",
                      background: "#fff",
                    }}
                    onClick={() => this.addCategory("add")}
                  >
                    <span style={{ color: "#000" }}>Sub Category 1</span>
                  </Button>
                </div>
                {this.state.addMainCategory === false &&
                  materialList[category]?.map((eachSub, index) => (
                    <div
                      style={{
                        display: "flex",
                        marginBottom: "10px",
                        position: "relative",
                      }}
                    >
                      {editMode && clickMode === index ? (
                        <Input
                          id="myText"
                          value={subCatVal}
                          placeholder="Sub Category 1"
                          className="input_subCategory"
                          style={{
                            background:
                              clickMode === index ? "#586370" : "transparent",
                            color: "#FFF",
                          }}
                          onClick={() => {
                            this.setState({
                              clickMode: index,
                            });
                          }}
                          onChange={(e) =>
                            this.setState({ subCatVal: e.target.value })
                          }
                          onBlur={() =>
                            this.updateSubcatVal(index, eachSub.subcat)
                          }
                        />
                      ) : (
                        <>
                          <Button
                            placeholder="Sub Category 1"
                            className="input_subCategory"
                            style={{
                              background:
                                clickMode === index ? "#586370" : "transparent",
                            }}
                            onClick={() =>
                              this.setState({
                                clickMode: index,
                                clickMode3: index,
                                editMode: false,
                              })
                            }
                          >
                            <span
                              style={{
                                float: "left",
                                color: clickMode === index ? "#FFF" : "#000",
                              }}
                              className="span_text"
                            >
                              {eachSub.subcat}
                            </span>
                          </Button>
                        </>
                      )}

                      <Row className="popovergrid popover_row">
                        <Button
                          className="btn_PopconfirmEdit"
                          onClick={() => this.handleEditSub1(index, eachSub)}
                        >
                          <span
                            className="btn_deleteIcon"
                            style={{
                              color:
                                clickMode === index && !editMode
                                  ? "#FFF"
                                  : "#c3c3c3",
                            }}
                          >
                            <MdModeEditOutline style={{ marginRight: "5px" }} />
                          </span>
                        </Button>
                        <Button className="btn_PopconfirmDelete">
                          <Popconfirm
                            title="Are you sure？"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.deleteRecord(index, eachSub)}
                          >
                            <span
                              className="btn_deleteIcon"
                              style={{
                                color:
                                  clickMode === index && !editMode
                                    ? "#FFF"
                                    : "#c3c3c3",
                              }}
                            >
                              <MdDelete
                                style={{
                                  marginRight: "5px",
                                }}
                              />{" "}
                            </span>
                          </Popconfirm>
                        </Button>
                      </Row>
                    </div>
                  ))}
                {addCategory1 && (
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "10px",
                      position: "relative",
                    }}
                  >
                    <Input
                      id="myCat"
                      className="input_subCategory"
                      placeholder="Sub-Category"
                      style={{
                        background: editMode ? "#FFF" : "#586370",
                        color: !editMode ? "#FFF" : "#000",
                        // width: "80%",
                      }}
                      ref={this.formRef}
                      onChange={(e) =>
                        this.setState({
                          addSubValue: e.target.value,
                        })
                      }
                      onBlur={() => this.addCategory("blur")}
                      value={addSubValue}
                    />
                  </div>
                )}
              </Col>
              <Col
                xxl={{ span: 8 }}
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                data-aos={window.screen.width > 1023 ? "fade-left" : ""}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <span
                    className="title_changes"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "35px",
                    }}
                  >
                    Short Name
                  </span>
                  {/* {materialList[category]?.map(
                    (eachSub, index) =>
                      index === clickMode &&
                      !eachSub.subShortName && (
                        <Button
                          type="primary"
                          icon={<PlusOutlined style={{ color: "#000" }} />}
                          style={{
                            borderRadius: "5px",
                            height: "35px",
                            margin: "auto 0px",
                            background: "#fff",
                          }}
                          onClick={() => this.addCategory3("add")}
                        >
                          <span style={{ color: "#000" }}>Short Name</span>
                        </Button>
                      )
                  )} */}
                </div>
                {this.state.addMainCategory === false &&
                  materialList[category]?.map(
                    (eachSub, index) => (
                      // index === clickMode && (
                      <div
                        style={{
                          display: "flex",
                          marginBottom: "10px",
                          position: "relative",
                        }}
                      >
                        {editMode3 && clickMode3 === index ? (
                          <Input
                            id="myText3"
                            value={subCatVal3}
                            placeholder="Short Name"
                            className="input_subCategory"
                            onClick={() => {
                              this.setState({
                                clickMode3: index,
                              });
                            }}
                            style={{
                              background:
                                clickMode3 === index
                                  ? "#586370"
                                  : "transparent",
                              color: "#FFF",
                            }}
                            onChange={(e) =>
                              this.setState({
                                subCatVal3: e.target.value,
                              })
                            }
                            onBlur={() =>
                              this.updateSubcatVal3(index, eachSub.subShortName)
                            }
                          />
                        ) : (
                          <>
                            <Button
                              placeholder="Short Name"
                              className="input_subCategory"
                              onClick={() => {
                                this.setState({
                                  clickMode3: index,
                                  clickMode: index,
                                  editMode3: false,
                                });
                              }}
                              style={{
                                background:
                                  clickMode3 === index
                                    ? "#586370"
                                    : "transparent",
                                color: clickMode3 === index ? "#FFF" : "#000",
                              }}
                            >
                              <span
                                style={{
                                  float: "left",
                                }}
                                className="span_text"
                              >
                                {eachSub.subShortName}
                              </span>
                            </Button>
                          </>
                        )}
                        {/* eachSub.subShortName &&
                          eachSub.subShortName !== "" && */}
                        {
                          <Row className="popovergrid popover_row">
                            <Button
                              className="btn_PopconfirmDelete"
                              onClick={() =>
                                this.handleEditSub3(index, eachSub)
                              }
                            >
                              <span
                                className="btn_deleteIcon"
                                style={{
                                  width: "80%",
                                  justifyContent: "end",
                                  display: "flex",
                                  color:
                                    clickMode3 === index && !editMode
                                      ? "#FFF"
                                      : "#c3c3c3",
                                }}
                              >
                                <MdModeEditOutline
                                  style={{ marginRight: "5px" }}
                                />
                              </span>
                            </Button>
                            {/* <Button className="btn_PopconfirmDelete">
                                  <Popconfirm
                                    title="Are you sure？"
                                    okText="Yes"
                                    cancelText="No"
                                    onConfirm={() =>
                                      this.deleteSub3(index, eachSub)
                                    }
                                  >
                                    <span className="btn_deleteIcon">
                                      <MdDelete
                                        style={{
                                          marginRight: "5px",
                                        }}
                                      />{" "}
                                    </span>
                                  </Popconfirm>
                                </Button> */}
                          </Row>
                        }
                      </div>
                    )
                    // )
                  )}

                {addCategory3 && (
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "10px",
                      position: "relative",
                    }}
                  >
                    <Input
                      id="myCat3"
                      placeholder="Short Name"
                      className="input_subCategory"
                      style={{
                        background: editMode3 ? "#FFF" : "#586370",
                        color: !editMode3 ? "#FFF" : "#000",
                      }}
                      onChange={(e) =>
                        this.setState({
                          addSubValue3: e.target.value,
                        })
                      }
                      onBlur={() => this.addCategory3("blur")}
                      value={addSubValue3}
                    />
                  </div>
                )}
              </Col>
              {/* <Col
                xxl={{ span: 12 }}
                xl={{ span: 12 }}
                lg={{ span: 12 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                data-aos="fade-left"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <span
                    className="title_changes"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Sub-Category 2
                  </span>
                  <Button
                    type="primary"
                    icon={<PlusOutlined style={{ color: "#000" }} />}
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      margin: "auto 0px",
                      background: "#fff",
                    }}
                    onClick={() => this.addCategory2("add")}
                  >
                    <span style={{ color: "#000" }}>Sub Category 2</span>
                  </Button>
                </div>
                {this.state.addMainCategory === false &&
                  materialList[category]?.map(
                    (eachSub, index) =>
                      index === clickMode &&
                      eachSub?.subTypes.map((e, i) => (
                        <div
                          style={{
                            display: "flex",
                            marginBottom: "10px",
                            position: "relative",
                          }}
                        >
                          {editMode && clickMode2 === i ? (
                            <Input
                              id="myText2"
                              value={subCatVal2 !== "" ? subCatVal2 : e.subType}
                              placeholder="Sub Category 2"
                              className="input_subCategory"
                              onClick={() => {
                                this.setState({
                                  clickMode2: i,
                                });
                              }}
                              onChange={(e) =>
                                this.setState({
                                  subCatVal2: e.target.value,
                                })
                              }
                              onBlur={() =>
                                this.updateSubcatVal2(index, i, e.subType)
                              }
                            />
                          ) : (
                            <>
                              <Button
                                placeholder="Sub Category 2"
                                className="input_subCategory"
                                onClick={() =>
                                  this.setState({
                                    clickMode2: index,
                                    editMode: false,
                                  })
                                }
                              >
                                <span
                                  style={{
                                    float: "left",
                                  }}
                                  className="span_text"
                                >
                                  {e.subType}
                                </span>
                              </Button>
                            </>
                          )}

                          <Row className="popovergrid popover_row">
                            <Button
                              className="btn_PopconfirmEdit"
                              onClick={() => this.handleEditSub2(i)}
                            >
                              <span className="btn_deleteIcon">
                                <MdModeEditOutline
                                  style={{ marginRight: "5px" }}
                                />
                              </span>
                            </Button>
                            <Button className="btn_PopconfirmDelete">
                              <Popconfirm
                                title="Are you sure？"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => this.deleteSub2(index, i, e)}
                              >
                                <span className="btn_deleteIcon">
                                  <MdDelete
                                    style={{
                                      marginRight: "5px",
                                    }}
                                  />{" "}
                                </span>
                              </Popconfirm>
                            </Button>
                          </Row>
                        </div>
                      ))
                  )}
                {addCategory2 && (
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "10px",
                      position: "relative",
                    }}
                  >
                    <Input
                      id="myCat2"
                      placeholder="Sub-Category"
                      className="input_subCategory"
                      style={{
                        background: editMode ? "#FFF" : "#586370",
                        color: !editMode ? "#FFF" : "#000",
                      }}
                      onChange={(e) =>
                        this.setState({
                          addSubValue2: e.target.value,
                        })
                      }
                      onBlur={() => this.addCategory2("blur")}
                      value={addSubValue2}
                    />
                  </div>
                )}
              </Col> */}
            </Row>
          </Form>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "2vw 0px 1vw 0px",
            }}
          >
            <Button
              className="btn-styl"
              style={{
                color: "#586370",
                border: "1px solid #d9d9d9",
                width: "120px",
                marginRight: "15px",
                height: "40px",
              }}
              onClick={this.onCancelModal}
            >
              <span
                style={{
                  color: "#000",
                  fontWeight: "400",
                  fontSize: "18px",
                  fontFamily: "sans-serif",
                }}
              >
                Cancel
              </span>
            </Button>
            <Button
              type="primary"
              className="btn-styl"
              style={{
                width: "120px",
                height: "40px",
              }}
              onClick={this.updateApi}
            >
              <span
                style={{
                  color: "#FFF",
                  fontWeight: "400",
                  fontSize: "18px",
                  fontFamily: "sans-serif",
                }}
              >
                Save
              </span>
            </Button>
          </div>
        </Card>
      </div>
    );
  };

  showCommisions = () => {
    const { commisionsData, truckingCommisionsData } = this.state;
    const commisionsColumns = [
      {
        title: "Revenue Tier",
        dataIndex: "minRevenueTier",
        key: "minRevenueTier",
        sorter: false,
        with: "180px",
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => {
            this.setState({
              commissionForm: true,
              recordData: record,
            });
          },
        }),
        render: (minRevenueTier, record) => {
          return (
            <span
              className="col_styling table-font-mobile"
              style={{
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {minRevenueTier + " - " + record.maxRevenueTier}
            </span>
          );
        },
      },
      {
        title: "Commissions",
        dataIndex: "rate",
        key: "rate",
        sorter: false,
        with: "180px",
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => {
            this.setState({
              commissionForm: true,
              recordData: record,
            });
          },
        }),
        render: (rate) => {
          return (
            <span
              className="col_styling table-font-mobile"
              style={{
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {rate}
            </span>
          );
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        sorter: false,
        render: (action, record) => {
          return (
            <>
              <Col
                style={{
                  width: "10px",
                  float: "right",
                  marginRight: "50px",
                }}
              >
                <Popover
                  placement="left"
                  content={
                    <Row className="popovergrid">
                      <Col
                        onClick={() =>
                          this.setState({
                            commissionForm: true,
                            recordData: record,
                            commissionType: "Material",
                          })
                        }
                        span={24}
                        style={{ cursor: "pointer" }}
                      >
                        <Button
                          style={{
                            fontSize: "15px",
                            cursor: "pointer",
                            border: "none",
                            padding: "0px",
                            textAlign: "left",
                            width: "100%",
                            height: "auto",
                          }}
                        >
                          <span
                            style={{
                              color: "black",
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                              justifyContent: "left",
                            }}
                          >
                            <MdModeEditOutline style={{ marginRight: "5px" }} />
                            Edit
                          </span>
                        </Button>
                      </Col>
                      <Col span={24}>
                        <Button
                          style={{
                            fontSize: "15px",
                            cursor: "pointer",
                            border: "none",
                            padding: "0px",
                            textAlign: "left",
                            width: "100%",
                            height: "auto",
                          }}
                        >
                          <Popconfirm
                            title="Are you sure？"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.deleteCommission(record?.id)}
                          >
                            <span
                              style={{
                                color: "black",
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "left",
                              }}
                            >
                              <MdDelete style={{ marginRight: "5px" }} />
                              Delete
                            </span>
                          </Popconfirm>
                        </Button>
                      </Col>
                    </Row>
                  }
                >
                  <EllipsisOutlined
                    style={{
                      fontSize: "30px",
                      color: "grey",
                      marginTop: "3px",
                    }}
                    className="action-ellipsis-button"
                  />
                </Popover>
              </Col>
            </>
          );
        },
      },
    ];
    const truckingCommisionsColumns = [
      {
        title: "Revenue Tier",
        dataIndex: "minRevenueTier",
        key: "minRevenueTier",
        sorter: false,
        with: "180px",
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => {
            this.setState({
              commissionForm: true,
              recordData: record,
            });
          },
        }),
        render: (minRevenueTier, record) => {
          return (
            <span
              className="col_styling table-font-mobile"
              style={{
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {minRevenueTier + " - " + record.maxRevenueTier}
            </span>
          );
        },
      },
      {
        title: "Commissions",
        dataIndex: "rate",
        key: "rate",
        sorter: false,
        with: "180px",
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => {
            this.setState({
              commissionForm: true,
              recordData: record,
            });
          },
        }),
        render: (rate) => {
          return (
            <span
              className="col_styling table-font-mobile"
              style={{
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {rate}
            </span>
          );
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        sorter: false,
        render: (action, record) => {
          return (
            <>
              <Col
                style={{
                  width: "10px",
                  float: "right",
                  marginRight: "50px",
                }}
              >
                <Popover
                  placement="left"
                  content={
                    <Row className="popovergrid">
                      <Col
                        onClick={() =>
                          this.setState({
                            commissionForm: true,
                            recordData: record,
                            commissionType: "Trucking",
                          })
                        }
                        span={24}
                        style={{ cursor: "pointer" }}
                      >
                        <Button
                          style={{
                            fontSize: "15px",
                            cursor: "pointer",
                            border: "none",
                            padding: "0px",
                            textAlign: "left",
                            width: "100%",
                            height: "auto",
                          }}
                        >
                          <span
                            style={{
                              color: "black",
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                              justifyContent: "left",
                            }}
                          >
                            <MdModeEditOutline style={{ marginRight: "5px" }} />
                            Edit
                          </span>
                        </Button>
                      </Col>
                      <Col span={24}>
                        <Button
                          style={{
                            fontSize: "15px",
                            cursor: "pointer",
                            border: "none",
                            padding: "0px",
                            textAlign: "left",
                            width: "100%",
                            height: "auto",
                          }}
                        >
                          <Popconfirm
                            title="Are you sure？"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() =>
                              this.deleteCommissionTrucking(record?.id)
                            }
                          >
                            <span
                              style={{
                                color: "black",
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "left",
                              }}
                            >
                              <MdDelete style={{ marginRight: "5px" }} />
                              Delete
                            </span>
                          </Popconfirm>
                        </Button>
                      </Col>
                    </Row>
                  }
                >
                  <EllipsisOutlined
                    style={{
                      fontSize: "30px",
                      color: "grey",
                      marginTop: "3px",
                    }}
                    className="action-ellipsis-button"
                  />
                </Popover>
              </Col>
            </>
          );
        },
      },
    ];
    return (
      <div>
        <Row
          style={{
            display: "flex",
            marginBottom: "1vw",
          }}
        >
          <Row
            onClick={() =>
              this.setState({
                showCommisions: false,
              })
            }
            style={{ cursor: "pointer" }}
          >
            <BsArrowLeftShort
              style={{ color: "#808080", fontSize: "30px" }}
              className="back-button-return-icon"
            />
            <span
              style={{ color: "#808080", fontSize: "18px" }}
              className="back-button-return"
            >
              Return
            </span>
          </Row>
        </Row>
        <Row gutter={[36, 0]}>
          <Col
            xxl={{ span: 12 }}
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Card
              className="system-settings-table-head"
              title={
                <span
                  style={{ display: "flex" }}
                  className="system-settings-table-head"
                >
                  Trucking Commision Structure
                </span>
              }
              extra={
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() =>
                    this.setState({
                      commissionForm: true,
                      recordData: {},
                      commissionType: "Trucking",
                    })
                  }
                  style={{
                    borderRadius: "5px",
                    height: "35px",
                    margin: "auto 0px",
                  }}
                >
                  <span>Add</span>
                </Button>
              }
            >
              <BaseTable
                className="financeinvoice_table table_laptopscreen"
                columnsData={truckingCommisionsColumns}
                source={truckingCommisionsData?.sort(
                  (a, b) =>
                    (a.rate.match(/\d+/) || 0) - (b.rate.match(/\d+/) || 0)
                )}
                rowKey={(record) => record.id}
                handleTableChage={(e) =>
                  this.handleTableChage(e, "truckingCommisions")
                }
                sizeRange={
                  window.screen.width > 1023
                    ? JSON.parse(localStorage.getItem("tableSizeRange"))
                        ?.truckingCommisions
                    : truckingCommisionsData.length
                }
                pagination={window.screen.width > 1023 ? true : false}
              />
            </Card>
            <br />
          </Col>
          <Col
            xxl={{ span: 12 }}
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Card
              className="system-settings-table-head"
              title={
                <span
                  style={{ display: "flex" }}
                  className="system-settings-table-head"
                >
                  Material Commision Structure
                </span>
              }
              extra={
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() =>
                    this.setState({
                      commissionForm: true,
                      recordData: {},
                      commissionType: "Material",
                    })
                  }
                  style={{
                    borderRadius: "5px",
                    height: "35px",
                    margin: "auto 0px",
                  }}
                >
                  <span>Add</span>
                </Button>
              }
            >
              <BaseTable
                className="financeinvoice_table table_laptopscreen"
                columnsData={commisionsColumns}
                source={commisionsData?.sort(
                  (a, b) =>
                    (a.rate.match(/\d+/) || 0) - (b.rate.match(/\d+/) || 0)
                )}
                // rowSelection={rowSelection}
                // total={unPaidData?.length}
                rowKey={(record) => record.id}
                handleTableChage={(e) =>
                  this.handleTableChage(e, "materialCommisions")
                }
                sizeRange={
                  window.screen.width > 1023
                    ? JSON.parse(localStorage.getItem("tableSizeRange"))
                        ?.materialCommisions
                    : commisionsData.length
                }
                pagination={window.screen.width > 1023 ? true : false}
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
  };

  showTrucksData = () => {
    const { trucksData } = this.state;
    const trucksColumns = [
      {
        title: "Truck Types",
        dataIndex: "value",
        key: "value",
        sorter: false,
        with: "180px",
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => {
            this.setState({
              truckForm: true,
              recordTrucksData: record,
            });
          },
        }),
        render: (value) => {
          return (
            <span
              className="col_styling table-font-mobile"
              style={{
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {value}
            </span>
          );
        },
      },
      {
        title: "Short Name",
        dataIndex: "truck_code",
        key: "truck_code",
        sorter: false,
        with: "180px",
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => {
            this.setState({
              truckForm: true,
              recordTrucksData: record,
            });
          },
        }),
        render: (truck_code) => {
          return (
            <span
              className="col_styling table-font-mobile"
              style={{
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {truck_code}
            </span>
          );
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        sorter: false,
        render: (action, record) => {
          return (
            <>
              <Col
                style={{
                  width: "10px",
                  float: "right",
                  marginRight: "50px",
                }}
              >
                <Popover
                  placement="left"
                  content={
                    <Row className="popovergrid">
                      <Col
                        onClick={() =>
                          this.setState({
                            truckForm: true,
                            recordTrucksData: record,
                          })
                        }
                        span={24}
                        style={{ cursor: "pointer" }}
                      >
                        <Button
                          style={{
                            fontSize: "15px",
                            cursor: "pointer",
                            border: "none",
                            padding: "0px",
                            textAlign: "left",
                            width: "100%",
                            height: "auto",
                          }}
                        >
                          <span
                            style={{
                              color: "black",
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                              justifyContent: "left",
                            }}
                          >
                            <MdModeEditOutline style={{ marginRight: "5px" }} />
                            Edit
                          </span>
                        </Button>
                      </Col>
                      <Col span={24}>
                        <Button
                          style={{
                            fontSize: "15px",
                            cursor: "pointer",
                            border: "none",
                            padding: "0px",
                            textAlign: "left",
                            width: "100%",
                            height: "auto",
                          }}
                        >
                          <Popconfirm
                            title="Are you sure？"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.deleteTruckTypes(record?.id)}
                          >
                            <span
                              style={{
                                color: "black",
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "left",
                              }}
                            >
                              <MdDelete style={{ marginRight: "5px" }} />
                              Delete
                            </span>
                          </Popconfirm>
                        </Button>
                      </Col>
                    </Row>
                  }
                >
                  <EllipsisOutlined
                    style={{
                      fontSize: "30px",
                      color: "grey",
                      marginTop: "3px",
                    }}
                    className="action-ellipsis-button"
                  />
                </Popover>
              </Col>
            </>
          );
        },
      },
    ];
    return (
      <div
        style={{
          margin: "0px auto",
          minHeight: "100vh",
          maxWidth: "980px",
          textAlign: "left",
        }}
      >
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "1vw",
          }}
        >
          <Row
            onClick={() =>
              this.setState({
                showTrucks: false,
              })
            }
            style={{ cursor: "pointer" }}
          >
            <BsArrowLeftShort
              className="back-button-return-icon"
              style={{ color: "#808080", fontSize: "30px" }}
            />
            <span
              style={{ color: "#808080", fontSize: "18px" }}
              className="back-button-return"
            >
              Return
            </span>
          </Row>
        </Row>
        <Card
          title={
            <span
              style={{ fontSize: "18px" }}
              className="system-settings-table-head"
            >
              Truck Types
            </span>
          }
          extra={
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() =>
                this.setState({
                  truckForm: true,
                  recordTrucksData: {},
                })
              }
              style={{
                borderRadius: "5px",
                height: "35px",
                margin: "auto 0px",
              }}
            >
              <span>Add</span>
            </Button>
          }
        >
          <BaseTable
            className="financeinvoice_table table_laptopscreen"
            columnsData={trucksColumns}
            source={trucksData}
            // rowSelection={rowSelection}
            // total={unPaidData?.length}
            rowKey={(record) => record.id}
            sizeRange={
              window.screen.width > 1023
                ? JSON.parse(localStorage.getItem("tableSizeRange"))
                    ?.systemTruckTypes
                : trucksData.length
            }
            pagination={window.screen.width > 1023 ? true : false}
            handleTableChage={(e) =>
              this.handleTableChage(e, "systemTruckTypes")
            }
          />
        </Card>
      </div>
    );
  };

  showTermsData = () => {
    const { termsData } = this.state;

    const termsColumns = [
      {
        title: "Payment Terms",
        dataIndex: "payment_terms",
        key: "payment_terms",
        sorter: false,
        with: "180px",
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => {
            this.setState({
              truckForm: true,
              recordTrucksData: record,
            });
          },
        }),
        render: (payment_terms) => {
          return (
            <span
              className="col_styling table-font-mobile"
              style={{
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {payment_terms}
            </span>
          );
        },
      },
      {
        title: "Discount",
        dataIndex: "payment_discount",
        key: "payment_discount",
        sorter: false,
        with: "180px",
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => {
            this.setState({
              truckForm: true,
              recordTrucksData: record,
            });
          },
        }),
        render: (payment_discount) => {
          return (
            <span
              className="col_styling table-font-mobile"
              style={{
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {payment_discount}
            </span>
          );
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        sorter: false,
        render: (action, record) => {
          return (
            <>
              <Col
                style={{
                  width: "10px",
                  float: "right",
                  marginRight: "50px",
                }}
              >
                <Popover
                  placement="left"
                  content={
                    <Row className="popovergrid">
                      <Col
                        onClick={() =>
                          this.setState({
                            termsForm: true,
                            recordTermsData: record,
                          })
                        }
                        span={24}
                        style={{ cursor: "pointer" }}
                      >
                        <Button
                          style={{
                            fontSize: "15px",
                            cursor: "pointer",
                            border: "none",
                            padding: "0px",
                            textAlign: "left",
                            width: "100%",
                            height: "auto",
                          }}
                        >
                          <span
                            style={{
                              color: "black",
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                              justifyContent: "left",
                            }}
                          >
                            <MdModeEditOutline style={{ marginRight: "5px" }} />
                            Edit
                          </span>
                        </Button>
                      </Col>
                      <Col span={24}>
                        <Button
                          style={{
                            fontSize: "15px",
                            cursor: "pointer",
                            border: "none",
                            padding: "0px",
                            textAlign: "left",
                            width: "100%",
                            height: "auto",
                          }}
                        >
                          <Popconfirm
                            title="Are you sure？"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.deleteTerms(record?.id)}
                          >
                            <span
                              style={{
                                color: "black",
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "left",
                              }}
                            >
                              <MdDelete style={{ marginRight: "5px" }} />
                              Delete
                            </span>
                          </Popconfirm>
                        </Button>
                      </Col>
                    </Row>
                  }
                >
                  <EllipsisOutlined
                    style={{
                      fontSize: "30px",
                      color: "grey",
                      marginTop: "3px",
                    }}
                    className="action-ellipsis-button"
                  />
                </Popover>
              </Col>
            </>
          );
        },
      },
    ];
    return (
      <div
        style={{
          margin: "0px auto",
          minHeight: "100vh",
          maxWidth: "980px",
          textAlign: "left",
        }}
      >
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "1vw",
          }}
        >
          <Row
            onClick={() =>
              this.setState({
                showTerms: false,
              })
            }
            style={{ cursor: "pointer" }}
          >
            <BsArrowLeftShort
              className="back-button-return-icon"
              style={{ color: "#808080", fontSize: "30px" }}
            />
            <span
              style={{ color: "#808080", fontSize: "18px" }}
              className="back-button-return"
            >
              Return
            </span>
          </Row>
        </Row>
        <Card
          title={
            <span
              style={{ fontSize: "18px" }}
              className="system-settings-table-head"
            >
              Payment Terms
            </span>
          }
          extra={
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() =>
                this.setState({
                  termsForm: true,
                  recordTermsData: {},
                })
              }
              style={{
                borderRadius: "5px",
                height: "35px",
                margin: "auto 0px",
              }}
            >
              <span>Add</span>
            </Button>
          }
        >
          <BaseTable
            className="financeinvoice_table table_laptopscreen"
            columnsData={termsColumns}
            source={termsData}
            // rowSelection={rowSelection}
            // total={unPaidData?.length}
            rowKey={(record) => record.id}
            handleTableChage={(e) => this.handleTableChage(e, "systemTerms")}
            sizeRange={
              window.screen.width > 1023
                ? JSON.parse(localStorage.getItem("tableSizeRange"))
                    ?.systemTerms
                : termsData.length
            }
            pagination={window.screen.width > 1023 ? true : false}
          />
        </Card>
      </div>
    );
  };

  onFinishTicket = (values) => {
    const { ticketUpdateId } = this.state;
    if (parseInt(values["usersCount"]) >= 2) {
      let data = {
        entityData: {
          usersCount: parseInt(values["usersCount"]),
        },
      };
      this.setState({
        showTicket: false,
      });
      this.props?.actionupdateDropDownOptions(ticketUpdateId, data);
    } else {
      message.error("The user count must be 2 or more.");
    }
  };

  onFinishTaxRate = (values) => {
    const { salesUpdateId } = this.state;
    let data = {
      entityData: {
        taxRate: parseFloat(values["taxRate"]),
      },
    };
    this.setState({
      showSales: false,
    });
    this.props?.actionupdateDropDownOptions(salesUpdateId, data);
  };

  onFinishDoc = () => {
    const { leadData, quoteAttachment, quoteAttachmentId } = this.state;
    if (
      quoteAttachment !== undefined &&
      quoteAttachment !== "" &&
      quoteAttachmentId !== undefined &&
      quoteAttachmentId !== ""
    ) {
      this.setState({
        showDocModal: false,
      });
      let data = {
        entityData: {
          leadId: leadData?.id,
          date: moment().format("L"),
          document: "Tax Exempt",
          docType: "Tax Exempt",
          description: "",
          docAttachment: quoteAttachment,
          docAttachmentId: quoteAttachmentId,
        },
      };
      // console.log(data);
      this.props.actionAddDoc(data);
    } else {
      message.error("Please attach document");
    }
  };

  onFinishTerms = (values) => {
    const { termsUpdateId, termsData, recordTermsData } = this.state;
    let data = {};
    if (Object.values(recordTermsData)?.length > 0) {
      termsData?.map((e) => {
        if (e?.id === recordTermsData?.id) {
          e["payment_terms"] = values["payment_terms"];
          e["label"] =
            values["payment_terms"] + " - " + values["payment_discount"] + "%";
          e["payment_discount"] = values["payment_discount"] + "%";
        }
        return e;
      });
      data = {
        entityData: {
          termsData,
        },
      };
    } else {
      let termsInfo = termsData ? termsData : [];
      values["id"] = uuidv4();
      values["label"] =
        values["payment_terms"] + " - " + values["payment_discount"] + "%";
      values["payment_discount"] = values["payment_discount"] + "%";
      termsInfo.push(values);
      data = {
        entityData: {
          termsData: termsInfo,
        },
      };
    }
    this.props?.actionupdateDropDownOptions(termsUpdateId, data);
    this.setState({
      termsForm: false,
    });
  };

  onFinishTrucks = (values) => {
    const { trucksUpdateId, trucksData, recordTrucksData } = this.state;
    let data = {};
    if (Object.values(recordTrucksData)?.length > 0) {
      trucksData?.map((e) => {
        if (e?.id === recordTrucksData?.id) {
          e["value"] = values["value"];
          e["truck_code"] = values["truck_code"];
          e["label"] = values["truck_code"] + " - " + values["value"];
        }
        return e;
      });
      data = {
        entityData: {
          trucksData,
        },
      };
    } else {
      let trucksInfo = trucksData ? trucksData : [];
      values["id"] = uuidv4();
      values["label"] = values["truck_code"] + " - " + values["value"];
      trucksInfo.push(values);
      data = {
        entityData: {
          trucksData: trucksInfo,
        },
      };
    }
    // console.log(data, "data");
    this.props?.actionupdateDropDownOptions(trucksUpdateId, data);
    this.setState({
      truckForm: false,
    });
  };

  showLeads = () => {
    const { leadDetails } = this.state;

    const customerColumns = [
      {
        title: "JOB#",
        dataIndex: "jobNumber",
        key: "jobNumber",
        sorter: false,
        className: "col_styling",
        width: "6%",
        onCell: (record) => ({
          onClick: () => {
            this.props.history.push(`/app/jobs/job-profile/${record.id}`);
          },
        }),
        render: (jobNumber, record) => {
          // console.log(record);
          return (
            <>
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="col_styling table-font-mobile"
              >
                {jobNumber}
              </span>
            </>
          );
        },
      },
      {
        title: "JOB NAME",
        dataIndex: "",
        key: "",
        sorter: false,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => {
            this.props.history.push(`/app/jobs/job-profile/${record.id}`);
          },
        }),
        render: (record) => {
          return (
            <>
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="col_styling table-font-mobile"
              >
                {record?.jobName ? record?.jobName : record?.jobNumber}
              </span>
            </>
          );
        },
      },
      {
        title: "CUSTOMER",
        dataIndex: "companyName",
        key: "companyName",
        sorter: false,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(
              `/app/customer-account-profile/${record.companyId}`
            ),
        }),
        render: (companyName, record) => {
          return (
            <>
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="col_styling table-font-mobile"
              >
                {companyName}
              </span>
            </>
          );
        },
      },
      {
        title: "CONTACT",
        dataIndex: "multipleContacts",
        key: "multipleContacts",
        sorter: false,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => {
            this.props.history.push(`/app/jobs/job-profile/${record.id}`);
          },
        }),
        render: (multipleContacts, record) => {
          return (
            <>
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="col_styling table-font-mobile"
              >
                {multipleContacts?.map((m) => (
                  <div>{m?.firstName + " " + m?.lastName} </div>
                ))}
              </span>
            </>
          );
        },
      },
      {
        title: "JOB STATUS",
        dataIndex: "stageId",
        key: "stageId",
        sorter: false,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => {
            this.props.history.push(`/app/jobs/job-profile/${record.id}`);
          },
        }),
        render: (stageId) => {
          return (
            <Button
              className="lead_ButtonStatus"
              style={{
                background: "transparent",
                borderRadius: "6px",
                borderColor: appConfig["STAGE_COLORS"][stageId],
                color: appConfig["STAGE_COLORS"][stageId],
                borderWidth: "1px",
                borderStyle: "solid",
              }}
            >
              <span
                className="col_styling table-font-mobile"
                style={{
                  fontSize: "14px",
                  fontFamily: "sans-serif",
                }}
              >
                {stageId === "Close Job" ? "Closed" : stageId}
              </span>
            </Button>
          );
        },
      },
      {
        title: "TAX EXEMPT",
        dataIndex: "taxExempt",
        key: "taxExempt",
        sorter: false,
        className: "col_styling",
        width: "15%",

        render: (taxExempt, record) => {
          return (
            <>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  flexWrap: "nowrap",
                }}
              >
                <Switch
                  checked={taxExempt}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  // defaultChecked={taxExempt}
                  // checked={taxExemptState}
                  className="switchcolor"
                  onChange={(e) => this.onChangeSwitch(e, record)}
                />
              </Row>
            </>
          );
        },
      },
    ];

    return (
      <div
        style={{
          margin: "0px auto",
          minHeight: "100vh",
          maxWidth: "100vw",
          textAlign: "left",
        }}
      >
        <Row
          onClick={() =>
            this.setState({
              showLeads: false,
            })
          }
          style={{ cursor: "pointer" }}
        >
          <BsArrowLeftShort
            className="back-button-return-icon"
            style={{ color: "#5f6c74", fontSize: "25px" }}
          />
          <p
            style={{ color: "#808080", fontSize: "16px", fontWeight: "600" }}
            className="back-button-return"
          >
            Return
          </p>
        </Row>
        <Row
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "1vw",
          }}
        >
          <Card
            title="Jobs"
            style={{
              minHeight: "10vh",
              width: "100%",
              alignSelf: "center",
              marginBottom: "25px",
            }}
          >
            <BaseTable
              className="customertablegrp table_laptopscreen"
              columnsData={customerColumns}
              source={leadDetails?.sort((a, b) =>
                a.jobNumber < b.jobNumber ? 1 : -1
              )}
              // rowSelection={rowSelection}
              total={leadDetails?.length}
              rowKey={(record) => record.id}
              handleTableChage={(e) =>
                this.handleTableChage(e, "systemTaxExempt")
              }
              sizeRange={
                window.screen.width > 1023
                  ? JSON.parse(localStorage.getItem("tableSizeRange"))
                      ?.systemTaxExempt
                  : leadDetails?.length
              }
              pagination={window.screen.width > 1023 ? true : false}
            />
          </Card>
        </Row>
      </div>
    );
  };

  onChangeSwitch = (e, record) => {
    // console.log(e, record);
    record["taxExempt"] = e;
    if (e === true) {
      this.setState({
        showDocModal: e,
        leadData: record,
        leadId: record?.id,
      });
    } else {
      this.setState(
        {
          leadId: record?.id,
          leadData: record,
        },
        () => this.updateLeadData()
      );
    }
  };

  updateLeadData = () => {
    const { leadData, leadId } = this.state;
    delete leadData?.id;
    delete leadData?.updatedAt;
    leadData["reQuote"] = true;
    // console.log(leadData);
    let data = {
      entityData: {
        ...leadData,
      },
    };
    this.props?.actionUpdateJob(leadId, data);
  };

  onCancelDoc = () => {
    const { leadData, leadDetails } = this.state;
    leadData["taxExempt"] = !leadData["taxExempt"];
    leadDetails?.splice(
      leadDetails?.findIndex((l) => l.id === leadData.id),
      1,
      leadData
    );
    this.setState({
      showDocModal: false,
      leadDetails,
      leadData: {},
      leadId: "",
    });
  };

  mainCategory = () => {
    const { addMainValue, materialList } = this.state;
    if (addMainValue === "") {
      this.setState({
        addMainCategory: false,
        mainClickMode: 0,
        clickMode: 0,
      });
      if (Object.keys(materialList)?.length > 0) {
        this.setState({
          category: Object.keys(materialList)[0],
          categoryKeyValue: Object.values(
            materialList[Object.keys(materialList)[0]]
          )[0].categoryKey,
        });
      }
    }
  };

  addMainCategory = () => {
    const { materialList, addMainValue, categoryKeyValue, items } = this.state;
    if (addMainValue !== "" && categoryKeyValue !== "") {
      let subCatData = {
        cate: addMainValue,
        categoryKey: categoryKeyValue,
        editSubCat: false,
        subTypes: [
          {
            subType: "",
            id: uuidv4(),
            editMode: false,
          },
        ],
        subcat: "",
        subShortName: undefined,
        editShort: true,
        indexVal: items?.length,
      };

      let data = {
        [addMainValue]: [subCatData],
      };
      let newObject = Object.assign(materialList, data);
      items.push(addMainValue);
      this.setState({
        materialList: newObject,
        addMainCategory: false,
        category: addMainValue,
        addMainValue: "",
        mainClickMode: Object.keys(materialList).length - 1,
        clickMode: 0,
        editMode: true,
        items,
      });
      setTimeout(() => {
        document.getElementById("myText").focus();
      }, 1);
    } else {
      if (addMainValue === "") {
        message.error("Please add category!");
        return;
      }
      if (categoryKeyValue === "") {
        message.error("Please add category key!");
        return;
      }
      this.setState({
        addMainCategory: false,
        addMainValue: "",
        mainClickMode: 0,
      });
    }
  };

  render() {
    const {
      loading,
      truckForm,
      commissionForm,
      errorLoading,
      totalData,
      showMaterials,
      // showCommisions,
      recordData,
      showLeads,
      showDocModal,
      quoteAttachmentId,
      imageLoading,
      showTrucks,
      showTerms,
      recordTrucksData,
      termsForm,
      recordTermsData,
      commissionType,
      showTicket,
      usersCount,
      salesTaxRate,
      showSales,
    } = this.state;

    const { resultData, leadsData } = this.props;
    const columns = [
      {
        title: "CATEGORIES",
        dataIndex: "name",
        key: "name",
        sorter: false,
        with: "180px",
        onCell: (record) => ({
          onClick: () => {
            record?.name === "Tax Exempt" && this.props?.actionFetchGetLeads();
            this.setState({
              showMaterials: record.name === "Materials" ? true : false,
              // showCommisions: record.name === "Commissions" ? true : false,
              showTrucks: record.name === "Truck Types" ? true : false,
              showTerms: record.name === "Payment Terms" ? true : false,
              showTicket:
                record.name === "Ticket Verified Users" ? true : false,
              showSales: record.name === "Sales Tax" ? true : false,
            });
            if (Object.keys(this.state.materialList)?.length > 0) {
              this.setState({
                category: Object.keys(this.state.materialList)[0],
                categoryKeyValue: Object.values(
                  this.state.materialList[
                    Object.keys(this.state.materialList)[0]
                  ]
                )[0].categoryKey,
              });
            }
          },
        }),
        render: (name) => {
          return (
            <span
              className="col_styling table-font-mobile"
              style={{
                textTransform: "capitalize",
              }}
            >
              {name === "Ticket Verified Users"
                ? `${name} - ${usersCount}`
                : name === "Sales Tax"
                ? `${name} - ${salesTaxRate}%`
                : name}
            </span>
          );
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        sorter: false,
        render: (action, record) => {
          return (
            <>
              <Col
                style={{
                  width: "10px",
                  float: "right",
                  marginRight: "50px",
                }}
                className="action-ellipsis-button"
              >
                <Popover
                  content={
                    <Row className="popovergrid" style={{ maxWidth: "90px" }}>
                      {" "}
                      <Col
                        onClick={() => {
                          record?.name === "Tax Exempt" &&
                            this.props?.actionFetchGetLeads();
                          this.setState({
                            showMaterials:
                              record.name === "Materials" ? true : false,
                            showCommisions:
                              record.name === "Commissions" ? true : false,
                            showTrucks:
                              record.name === "Truck Types" ? true : false,
                            showTerms:
                              record.name === "Payment Terms" ? true : false,
                            showTicket:
                              record.name === "Ticket Verified Users"
                                ? true
                                : false,
                            showSales:
                              record.name === "Sales Tax" ? true : false,
                          });
                        }}
                        span={24}
                        style={{ cursor: "pointer" }}
                      >
                        <Button
                          className="btn_edit"
                          // onClick={() => this.editModal(record)}
                        >
                          <span className="span_edit">
                            <MdModeEditOutline style={{ marginRight: "5px" }} />{" "}
                            Edit
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  }
                  trigger="hover"
                  placement="left"
                >
                  <Button
                    type="button"
                    style={{ background: "transparent", border: "none" }}
                  >
                    <EllipsisOutlined
                      style={{ fontSize: "35px", color: "grey" }}
                      className="action-ellipsis-button"
                    />
                  </Button>
                </Popover>
              </Col>
            </>
          );
        },
      },
    ];

    return (
      <div>
        <div
          style={{
            background: "#fafafa",
          }}
        >
          <Loading
            enableLoading={resultData?.loading || leadsData?.loading || loading}
          />
          <Row>
            <Col
              xxl={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              xs={{ span: 24, offset: 0 }}
            >
              {errorLoading ? (
                <ErrorView refreshRoles={this.onRefresh} type="ManageRoles" />
              ) : showMaterials ? (
                this.showMaterials()
              ) : //  : showCommisions ? (
              //   this.showCommisions()
              showLeads ? (
                this.showLeads()
              ) : showTrucks ? (
                this.showTrucksData()
              ) : showTerms ? (
                this.showTermsData()
              ) : (
                <>
                  <Row>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 6 }}
                      xl={{ span: 6 }}
                      xxl={{ span: 6 }}
                      className="quarrytoolgrid system-settings-layout"
                    >
                      <SearchFunction
                        data={totalData}
                        getSearchData={this.searchResult}
                        onResetData={this.onResetData}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      span={24}
                      data-aos={window.screen.width > 1023 ? "fade-up" : ""}
                    >
                      <BaseTable
                        className="table_manageroles table_laptopscreen"
                        columnsData={columns}
                        source={totalData}
                        total={totalData?.length}
                        handleTableChage={(e) =>
                          this.handleTableChage(e, "systemSettings")
                        }
                        sizeRange={
                          window.screen.width > 1023
                            ? JSON.parse(localStorage.getItem("tableSizeRange"))
                                ?.systemSettings
                            : totalData?.length
                        }
                        pagination={window.screen.width > 1023 ? true : false}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </div>

        {showTicket && (
          <BaseModal
            title={"Update Ticket Verified"}
            onCancel={() => {
              this.setState({
                showTicket: false,
              });
            }}
            visible={showTicket}
            destroyOnClose={true}
            formId="updateUsersCount"
            loading={this.state.loading}
            className="antmodal_grid headermodal"
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "25%"
                : "500px"
            }
          >
            <Form
              onFinish={this.onFinishTicket}
              initialValues={{ remember: true }}
              id="updateUsersCount"
            >
              <Row gutter={[16, 0]}>
                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Users Count</span>
                  <Form.Item
                    name="usersCount"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your usersCount!",
                      },
                    ]}
                    initialValue={usersCount}
                  >
                    <NumberFormat
                      className="ant-input-number numberictest"
                      style={{ width: "100%" }}
                      placeholder="Please Enter Your usersCount!"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}

        {showSales && (
          <BaseModal
            title={"Update Sales Tax Rate"}
            onCancel={() => {
              this.setState({
                showSales: false,
              });
            }}
            visible={showSales}
            destroyOnClose={true}
            formId="updateTaxRate"
            loading={this.state.loading}
            className="antmodal_grid headermodal"
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "25%"
                : "500px"
            }
          >
            <Form
              onFinish={this.onFinishTaxRate}
              initialValues={{ remember: true }}
              id="updateTaxRate"
            >
              <Row gutter={[16, 0]}>
                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Sales Tax</span>
                  <Form.Item
                    name="taxRate"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Sales Tax!",
                      },
                    ]}
                    initialValue={salesTaxRate}
                  >
                    <NumberFormat
                      className="ant-input-number numberictest"
                      style={{ width: "100%" }}
                      placeholder="Please Enter Your Sales Tax!"
                      suffix={"%"}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}

        {commissionForm && (
          <BaseModal
            title={
              Object.values(recordData)?.length > 0
                ? "Update Commission"
                : "Add Commission"
            }
            onCancel={() => {
              this.setState({
                commissionForm: false,
                recordData: {},
              });
            }}
            visible={commissionForm}
            destroyOnClose={true}
            formId="addCommission"
            loading={this.state.loading}
            className="antmodal_grid headermodal"
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "25%"
                : "500px"
            }
          >
            <Form
              onFinish={
                commissionType === "Trucking"
                  ? this.onFinishCommissionsTrucking
                  : this.onFinishCommissions
              }
              initialValues={{ remember: true }}
              id="addCommission"
            >
              <Row gutter={[16, 0]}>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 12 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Min Revenue Tier</span>
                  <Form.Item
                    name={"minRevenueTier"}
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Min-Revenue Tier!",
                      },
                    ]}
                    initialValue={recordData?.minRevenueTier}
                  >
                    <NumberFormat
                      onChange={(e) => this.setState({ phone: e.target.value })}
                      className="ant-input-number numberictest"
                      prefix="$"
                      style={{ width: "100%" }}
                      placeholder="Please Enter Your Min-Revenue Tier!"
                      thousandSeparator
                    />
                  </Form.Item>
                </Col>

                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 12 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Max Revenue Tier</span>
                  <Form.Item
                    name={"maxRevenueTier"}
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Max-Revenue Tier!",
                      },
                    ]}
                    initialValue={recordData?.maxRevenueTier}
                  >
                    <NumberFormat
                      onChange={(e) => this.setState({ phone: e.target.value })}
                      className="ant-input-number numberictest"
                      prefix="$"
                      style={{ width: "100%" }}
                      placeholder="Please Enter Your Max-Revenue Tier!"
                      thousandSeparator
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <span className="title_changes">Commissions</span>
                  <Form.Item
                    name="rate"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter your Commissions!",
                      },
                    ]}
                    initialValue={recordData?.rate}
                  >
                    <NumberFormat
                      className="ant-input-number numberictest"
                      suffix="%"
                      style={{ width: "100%" }}
                      placeholder="Please Enter Your Min-Revenue Tier!"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}

        {truckForm && (
          <BaseModal
            title={
              Object.values(recordTrucksData)?.length > 0
                ? "Update Truck"
                : "Add Truck"
            }
            onCancel={() => {
              this.setState({
                truckForm: false,
              });
            }}
            visible={truckForm}
            destroyOnClose={true}
            formId="addCommission"
            loading={this.state.loading}
            className="antmodal_grid headermodal"
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "25%"
                : "500px"
            }
          >
            <Form
              onFinish={this.onFinishTrucks}
              initialValues={{ remember: true }}
              id="addCommission"
            >
              <Row gutter={[16, 0]}>
                <Col span={24}>
                  <span className="title_changes">Truck Type</span>
                  <Form.Item
                    name="value"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Truck Type!",
                      },
                    ]}
                    initialValue={recordTrucksData?.value}
                  >
                    <Input
                      autoFocus
                      className="form-input"
                      placeholder="Please add truck type"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <span className="title_changes">Short Name</span>
                  <Form.Item
                    name={"truck_code"}
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Short Name!",
                      },
                    ]}
                    initialValue={recordTrucksData?.truck_code}
                  >
                    <Input
                      className="form-input"
                      placeholder="Please add truck type shortName"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}

        {termsForm && (
          <BaseModal
            title={
              Object.values(recordTermsData)?.length > 0
                ? "Update Carrier Payment Terms"
                : "Add Carrier Payment Terms"
            }
            onCancel={() => {
              this.setState({
                termsForm: false,
              });
            }}
            visible={termsForm}
            destroyOnClose={true}
            formId="addCommission"
            loading={this.state.loading}
            className="antmodal_grid headermodal"
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "25%"
                : "500px"
            }
          >
            <Form
              onFinish={this.onFinishTerms}
              initialValues={{ remember: true }}
              id="addCommission"
            >
              <Row gutter={[16, 0]}>
                <Col span={24}>
                  <span className="title_changes">Payment Terms</span>
                  <Form.Item
                    name="payment_terms"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Payment Terms!",
                      },
                    ]}
                    initialValue={recordTermsData?.payment_terms}
                  >
                    <NumberFormat
                      className="ant-input-number numberictest"
                      style={{ width: "100%" }}
                      prefix={"Net "}
                      placeholder="Please Enter Payment Terms"
                      autoFocus
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <span className="title_changes">Payment Discount</span>
                  <Form.Item
                    name={"payment_discount"}
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Payment Discount!",
                      },
                    ]}
                    initialValue={recordTermsData?.payment_discount}
                  >
                    <InputNumber
                      formatter={(value) => (value !== "" ? `${value}%` : "")}
                      style={{ width: "100%" }}
                      placeholder="Please enter your Discount"
                      className="form-input"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}

        {showDocModal && (
          <BaseModal
            title={"Add Document"}
            onCancel={this.onCancelDoc}
            visible={showDocModal}
            destroyOnClose={true}
            formId="docForm"
            loading={this.state.loading}
            className="antmodal_grid headermodal"
            width={"15%"}
          >
            <Form
              onFinish={this.onFinishDoc}
              initialValues={{ remember: true }}
              id="docForm"
            >
              <Row gutter={[16, 0]}>
                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">
                    Upload Tax Exempt Certificate
                  </span>
                  <Form.Item
                    name="docAttachment"
                    rules={[
                      {
                        required: false,
                        message: "Please Attach Your Document",
                      },
                    ]}
                    className="uploadtaxexempt"
                  >
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      style={{
                        borderRadius: "50%",
                        width: "100%",
                        height: "100%",
                      }}
                      showUploadList={false}
                      customRequest={dummyRequest}
                      onChange={(e) => this.handleUploadPic(e)}
                    >
                      <Spin spinning={imageLoading}>
                        {quoteAttachmentId ? (
                          <SlDoc
                            style={{
                              fontSize: "30px",
                              height: "30px",
                              marginRight: "5px",
                            }}
                          />
                        ) : (
                          <CameraOutlined className="camera-icon" />
                        )}
                      </Spin>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  resultData: state.reducer?.dropDownOptions,
  updateData: state?.reducer?.dropDownOptionsInfo,
  leadsData: state?.reducer?.leadsData,
  leadUpdateInfo: state?.reducer?.leadUpdateInfo,
  docResult: state?.reducer?.docResult,
});

const mapDispatchToProps = (dispatch) => ({
  actionUpdateQuarryStatus: (data) => {
    dispatch(updateQuarryStatus(data));
  },
  actionFetchDropDown: () => {
    dispatch(getDropDownOptions());
  },
  actionupdateDropDownOptions: (id, data) => {
    dispatch(updateDropDownOptions(id, data));
  },
  actionFetchGetLeads: () => {
    dispatch(getLeads());
  },
  actionUpdateJob: (id, data) => {
    dispatch(updateLead(id, data));
  },
  actionAddDoc: (data) => {
    dispatch(addDocument(data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAppContext(withRouter(SystemSettings)));
