import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Popover,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Popconfirm,
} from "antd";
import {
  EllipsisOutlined,
  UnorderedListOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { FaMap } from "react-icons/fa";
import MapComponent from "../Maps/MapComponent";
import { IoFilterSharp } from "react-icons/io5";
import { MdPhone, MdEmail, MdModeEditOutline, MdDelete } from "react-icons/md";
import Filter from "../../Common/Filter";
import FooterData from "../../Common/FooterData";
import appConfig from "../../config/AppConfig";
import { add, deleteRecord, list, update } from "../../api/Apis";
import NumberFormat from "react-number-format";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import Loading from "../../Common/Loading";
import { withAppContext } from "../../Store/AppContext";
import BaseModal from "../../Common/BaseModal";
import BaseTable from "../../Common/BaseTable";
import moment from "moment";
import ErrorView from "../../Common/ErrorView";
const Search = Input.Search;

class OwnerDatabase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: "",
      boardView: false,
      listView: true,
      loading: false,
      selectionType: "",
      selectedRowKeys: [],
      selected: true,
      color: "active",
      visible: false,
      isModalVisible: false,
      leadsData: "",
      Success: "",
      citySearch: [],
      streetNumber: "",
      street: "",
      city: "",
      addressState: "",
      zipCode: "",
      columnsData: [],
      ownerData: [],
      selectedAddress: "",
      latitude: "",
      longitude: "",
      filterData: [],
      errorLoading: false,
      mapData: [],
      lastEvaluatedKey: "",
      editModal: false,
      manualData: [],
      checkData: [],
      importData: [],
      bothData: [],
      searchInput: "",
    };
  }

  componentDidMount() {
    this.getDatabase();
    this.getDatabaseLocations();
    document.title = "Twisted Nail - Owner Database";
  }

  onClearFilter = () => {
    this.getDatabase();
    this.getDatabaseLocations();
    this.closeFilter();
  };

  onRefresh = () => {
    this.getDatabase();
    this.getDatabaseLocations();
  };

  getDatabase = () => {
    this.setState({ loading: true });
    list(appConfig["entityNames"]["ownerDatabase"])
      .then((result) => {
        if (result.success) {
          // if (result.data.data.manuallyEnteredData) {
          //   this.setState({
          //     manualData: result.data.data,
          //   });
          // } else {
          //   this.setState({ importData: result.data.data });
          // }
          //console.log(result.data.data);
          this.setState({
            bothData: result.data.data,
            filterData: result.data.data,
            importData: result.data.data.filter(
              (e) => e.manuallyEnteredData !== "YES"
            ),
            manualData: result.data.data.filter(
              (e) => e.manuallyEnteredData === "YES"
            ),
            //columnsData: result.data.data,
            lastEvaluatedKey: result.data.lastEvaluatedKey,
            loading: false,
            errorLoading: false,
          });
        } else {
          this.setState({ loading: false, errorLoading: true });
        }
      })
      .catch((err) => {
        this.setState({ loading: false, errorLoading: true });
        console.log(err, "error");
      });
  };

  getDatabaseLocations = () => {
    this.setState({ loading: true });
    list(appConfig["entityNames"]["ownerLocations"])
      .then((result) => {
        if (result.success) {
          this.setState({
            mapData: result.data.filter((e) => e.lat && e.lng !== ""),
            loading: false,
            errorLoading: false,
          });
        } else {
          this.setState({ loading: false, errorLoading: true });
        }
      })
      .catch((err) => {
        this.setState({ loading: false, errorLoading: true });
        console.log(err, "error");
      });
  };

  getOwnerDetails = () => {
    //console.log("cvgcf ");
    this.setState({ loading: true });
    list(appConfig["entityNames"]["ownerDatabase"], {
      lastEvaluatedKey: this.state.lastEvaluatedKey,
    })
      .then((result) => {
        if (result.success) {
          this.setState({
            bothData: this.state.bothData.concat(result.data.data),
            importData: this.state.importData
              .concat(result.data.data)
              .filter((e) => e.manuallyEnteredData !== "YES"),
            manualData: this.state.manualData
              .concat(result.data.data)
              .filter((e) => e.manuallyEnteredData === "YES"),
            //columnsData: this.state.columnsData.concat(result.data.data),
            filterData: this.state.filterData.concat(result.data.data),
            lastEvaluatedKey: result.data.lastEvaluatedKey,
            loading: false,
            errorLoading: false,
          });
        } else {
          this.setState({ loading: false, errorLoading: true });
        }
      })
      .catch((err) => {
        this.setState({ loading: false, errorLoading: true });
        console.log(err, "error");
      });
  };

  appApiCall = (data, id) => {
    if (this.state.editModal) {
      update(appConfig["entityNames"]["ownerDatabase"], id, data).then(
        (result) => {
          console.log(result);
          if (result.success) {
            this.setState({ isModalVisible: false, loading: false });
            this.getDatabase();
          } else {
            this.setState({ isModalVisible: false, loading: false });
          }
        }
      );
    } else {
      add(appConfig["entityNames"]["ownerDatabase"], data)
        .then((result) => {
          if (result.success) {
            this.setState({ loading: false, isModalVisible: false });
            this.getDatabase();
          } else {
            this.setState({ loading: false, errorLoading: true });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false, errorLoading: true });
        });
    }
  };

  onFinish = (values) => {
    const { ownerData } = this.state;
    this.setState({ loading: true });
    if (ownerData["manuallyEnteredData"] || ownerData === "") {
      let data = {
        entityData: {
          add_date: moment().format("L"),
          address: this.state.selectedAddress || this.state.ownerData.address,
          lat: this.state.latitude || this.state.ownerData.lat,
          lng: this.state.longitude || this.state.ownerData.lng,
          mailing_city: this.state.city || this.state.ownerData.mailing_city,
          //mailing_country:this.state.country || this.state.ownerData.mailing_country,
          mailing_state:
            this.state.addressState || this.state.ownerData.mailing_state,
          mailing_street:
            this.state.street || this.state.ownerData.mailing_street,
          mailing_zip: this.state.zipCode || this.state.ownerData.mailing_zip,
          telephone: values["telephone"],
          legal_name: values["legal_name"],
          email_address: values["email_address"],
          driver_total: values["driver_total"],
          manuallyEnteredData: "YES",
        },
      };
      console.log(data);
      this.appApiCall(data, ownerData?.id);
    } else {
      let data = {
        entityData: {
          add_date: moment().format("L"),
          address: this.state.selectedAddress || this.state.ownerData.address,
          lat: this.state.latitude || this.state.ownerData.lat,
          lng: this.state.longitude || this.state.ownerData.lng,
          mailing_city: this.state.city || this.state.ownerData.mailing_city,
          //mailing_country:this.state.country || this.state.ownerData.mailing_country,
          mailing_state:
            this.state.addressState || this.state.ownerData.mailing_state,
          mailing_street:
            this.state.street || this.state.ownerData.mailing_street,
          mailing_zip: this.state.zipCode || this.state.ownerData.mailing_zip,
          telephone: values["telephone"],
          legal_name: values["legal_name"],
          email_address: values["email_address"],
          driver_total: values["driver_total"],
        },
      };
      console.log(data);
      this.appApiCall(data, ownerData?.id);
    }
  };

  deleteLeadFromDB = (record) => {
    this.setState({ loading: true });
    deleteRecord(appConfig["entityNames"]["ownerDatabase"], record?.id).then(
      (result) => {
        if (result.success) {
          this.getDatabase();
          this.setState({ loading: false });
        } else {
          this.setState({ loading: false });
        }
      }
    );
  };

  closeFilter = () => {
    this.setState({
      visible: false,
    });
  };

  showModal = () => {
    this.setState({ isModalVisible: true, ownerData: "", editModal: false });
  };

  editModal = (record) => {
    this.setState({ isModalVisible: true, ownerData: record, editModal: true });
  };

  getAddress = (address, latLng) => {
    this.setState({ selectedAddress: address.label });
  };

  handleSelect = (address) => {
    const citySearch = [];
    citySearch.push(address.label);
    this.setState({
      citySearch,
    });
    let streetNumberValue = "";
    let streetValue = "";
    let cityValue = "";
    let stateValue = "";
    let zipCodeValue = "";
    let stateShortValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        getLatLng(results[0]).then(({ lat, lng }) =>
          this.setState({
            latitude: lat,
            longitude: lng,
          })
        );
        const geoIdData = results[0];
        this.setState({
          geoIdData,
        });
        let area = results[0].address_components;
        for (let i = 0; i < area.length; i++) {
          if (area[i].types[0] === "street_number") {
            streetNumberValue = area[i].long_name;
          }
          if (area[i].types[0] === "route") {
            streetValue = area[i].long_name;
          }
          if (area[i].types[0] === "locality") {
            cityValue = area[i].long_name;
          }
          if (area[i].types[0] === "administrative_area_level_1") {
            stateValue = area[i].long_name;
          }
          if (area[i].types[0] === "administrative_area_level_1") {
            stateShortValue = area[i].short_name;
          }
          if (area[i].types[0] === "postal_code") {
            zipCodeValue = area[i].long_name;
          }
        }
        this.setState({
          streetNumber: streetNumberValue,
          street: streetValue,
          city: cityValue,
          addressState: stateValue,
          stateAbbr: stateShortValue,
          zipCode: zipCodeValue,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  searchData = (event) => {
    let search = event;
    this.setState({ loading: true });
    if (search !== "") {
      list(appConfig["entityNames"]["ownerDatabase"], { search }).then(
        (result) => {
          console.log(result);
          if (result.success) {
            this.setState({ filterData: result.data, loading: false });
          } else {
            this.setState({ loading: false });
          }
        }
      );
    } else {
      //this.getDatabase();
      window.location.reload();
    }
  };

  searchResult = (filterData) => {
    this.setState({
      filterData,
    });
  };

  filterResultData = (checkData) => {
    this.setState({ checkData, loading: true });
    if (
      checkData.length === 0 ||
      checkData.length === 2 ||
      checkData.includes("Imported Data")
    ) {
      this.getDatabase();
    } else if (checkData.includes("Manually Entered Data")) {
      list(appConfig["entityNames"]["ownerDatabase"], {
        manuallyEnteredData: "YES",
      })
        .then((result) => {
          if (result.success) {
            this.setState({
              manualData: result.data,
              filterData: result.data,
              lastEvaluatedKey: result.data.lastEvaluatedKey,
              loading: false,
              errorLoading: false,
            });
          } else {
            this.setState({ loading: false, errorLoading: true });
          }
        })
        .catch((err) => {
          this.setState({ loading: false, errorLoading: true });
          console.log(err, "error");
        });
    }
    this.closeFilter();
  };

  onClickMap = () => {
    this.setState({ selected: false });
  };

  onSelectChange = (data) => {
    this.setState({ selectedRowKeys: data });
    // let selectedProducts = this.state.selectedProducts;
    // if (data.length > 1) {
    //   selectedProducts.splice(selectedProducts.indexOf(data), 1);
    //   selectedProducts.push(data);
    // } else {
    //   selectedProducts.push(data);
    // }
    // this.setState({ selectedRowKeys: data });
  };

  render() {
    const {
      loading,
      selectedRowKeys,
      selected,
      citySearch,
      filterData,
      ownerData,
      manualData,
      isModalVisible,
      visible,
      mapData,
      checkData,
      bothData,
      importData,
      errorLoading,
    } = this.state;

    const columns = [
      {
        title: "COMPANY NAME",
        dataIndex: "legal_name",
        key: "legal_name",
        sorter: false,
        className: "col_style_owner",
        // fixed: "left",
        // onCell: (record) => ({
        //   onClick: () =>
        //     (window.location = `/app/leads/leaddetails/${record.id}`),
        // }),
        render: (legal_name) => {
          return (
            <span
              className="col_style_owner"
              style={{
                textTransform: "capitalize",
                width: "10px",
              }}
            >
              <Row>{legal_name}</Row>
            </span>
          );
        },
      },
      {
        title: "ADDRESS",
        dataIndex: "mailing_city",
        key: "mailing_city",
        sorter: false,
        className: "col_style_owner",
        // onCell: (record) => ({
        //   onClick: () =>
        //     (window.location = `/app/leads/leaddetails/${record.id}`),
        // }),
        render: (mailing_city, record) => {
          return (
            <span
              className="col_style_owner"
              style={{
                textTransform: "capitalize",
              }}
            >
              {record.mailing_street +
                ", " +
                mailing_city +
                ", " +
                record.mailing_state +
                ", " +
                // record.mailing_street +
                // ", " +
                record.mailing_zip}
            </span>
          );
        },
      },
      {
        title: "DRIVERS",
        dataIndex: "driver_total",
        key: "driver_total",
        sorter: false,
        className: "col_style_owner",
        width: "100px",
        // onCell: (record) => ({
        //   onClick: () =>
        //     (window.location = `/app/leads/leaddetails/${record.id}`),
        // }),
        render: (driver_total) => {
          return <span className="col_style_owner">{driver_total}</span>;
        },
      },
      {
        title: "EMAIL",
        dataIndex: "email_address",
        key: "email_address",
        sorter: false,
        className: "col_style_quarry",
        // onCell: (record) => ({
        //   onClick: () =>
        //     (window.location = `/app/leads/leaddetails/${record.id}`),
        // }),
        render: (email_address) => {
          return <span className="col_style_quarry">{email_address}</span>;
        },
      },
      {
        title: "PHONE",
        dataIndex: "telephone",
        key: "telephone",
        sorter: false,
        className: "col_style_owner",
        width: "180px",
        // onCell: (record) => ({
        //   onClick: () =>
        //     (window.location = `/app/leads/leaddetails/${record.id}`),
        // }),
        render: (telephone) => {
          return <span className="col_style_owner">{telephone}</span>;
        },
      },

      {
        title: "ACTIONS",
        dataIndex: "id",
        key: "id",
        sorter: false,
        className: "action_tab",
        width: "120px",
        fixed: "right",
        render: (id, record) => {
          return (
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Col span={7}>
                <a href={`tel:${record.telephone}`}>
                  <button
                    type="button"
                    style={{
                      background: "transparent",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <MdPhone
                      style={{ color: "#878787", fontSize: "22px" }}
                      className="icon"
                    />
                  </button>
                </a>
              </Col>
              <Col span={7}>
                <a href={`mailto:${record.email_address}`}>
                  <button
                    type="button"
                    style={{
                      background: "transparent",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <MdEmail
                      style={{ color: "#878787", fontSize: "22px" }}
                      className="icon"
                    />
                  </button>
                </a>
              </Col>
              <Col span={7}>
                <Popover
                  trigger="hover"
                  placement="left"
                  content={
                    <Row className="popovergrid">
                      <Col span={24}>
                        <Button
                          style={{
                            fontSize: "15px",
                            cursor: "pointer",
                            border: "none",
                            padding: "0px",
                            textAlign: "left",
                            width: "100%",
                            height: "auto",
                          }}
                          onClick={() => this.editModal(record)}
                        >
                          <span
                            style={{
                              color: "black",
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                              justifyContent: "left",
                            }}
                          >
                            <MdModeEditOutline style={{ marginRight: "5px" }} />{" "}
                            Edit
                          </span>
                        </Button>
                      </Col>
                      <Col span={24}>
                        <Button
                          style={{
                            fontSize: "15px",
                            cursor: "pointer",
                            border: "none",
                            padding: "0px",
                            textAlign: "left",
                            width: "100%",
                          }}
                        >
                          <Popconfirm
                            title="Are you sure？"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.deleteLeadFromDB(record)}
                          >
                            <span
                              style={{
                                color: "black",
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "left",
                              }}
                            >
                              <MdDelete style={{ marginRight: "5px" }} /> Delete
                            </span>
                          </Popconfirm>
                        </Button>
                      </Col>
                    </Row>
                  }
                >
                  <EllipsisOutlined
                    style={{ fontSize: "30px", color: "grey" }}
                    className="action-ellipsis-button"
                  />
                </Popover>
              </Col>
            </Row>
          );
        },
      },
    ];

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    // console.log(selectedRowKeys);
    return (
      <div
        style={{
          background: "#fafafa",
        }}
      >
        <Loading enableLoading={loading} />
        {errorLoading ? (
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <ErrorView refreshLeadDetails={this.onRefresh} type="LeadDetails" />
          </Col>
        ) : (
          <>
            <Row style={{ margin: "20px 0px" }}>
              <Col
                // span={12}
                xxl={{ span: 12 }}
                xl={{ span: 12 }}
                lg={{ span: 12 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Row>
                  <Col
                    xxl={{ span: 10 }}
                    xl={{ span: 10 }}
                    lg={{ span: 10 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={10}
                    className="quarrytoolgrid"
                  >
                    <Search
                      className="products-search"
                      size="large"
                      placeholder="Search..."
                      onSearch={this.searchData}
                      style={{ borderRadius: "5px" }}
                      enterButton
                      allowClear
                    />
                  </Col>
                </Row>
              </Col>

              <Col
                xxl={{ span: 12 }}
                xl={{ span: 12 }}
                lg={{ span: 12 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                // span={12}
              >
                <Row style={{ justifyContent: "right" }} gutter={[8, 10]}>
                  <Col className="gridbtntool">
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      style={{
                        borderRadius: "5px",
                        height: "40px",
                      }}
                      onClick={this.showModal}
                    >
                      <span style={{ fontWeight: "500", fontSize: "16px" }}>
                        Owner Database
                      </span>
                    </Button>
                  </Col>
                  <Col className="gridbtntool">
                    <Button
                      className="button_Crm"
                      style={{
                        borderRadius: "5px",
                        height: "40px",
                      }}
                      onClick={() => {
                        this.setState({ visible: true });
                      }}
                    >
                      <Row>
                        <Col>
                          <span
                            style={{
                              color: "#5a6067",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Filter
                          </span>
                        </Col>
                        <Col>
                          <IoFilterSharp
                            style={{
                              fontSize: "22px",
                              marginLeft: "8px",
                              marginTop: "2px",
                            }}
                          />
                        </Col>
                      </Row>
                    </Button>
                  </Col>
                  <Col className="gridbtntool">
                    <Row
                      style={{
                        background: "#cecece",
                        height: "40px",
                        borderRadius: "6px",
                        justifyContent: "center",
                      }}
                    >
                      <Col style={{ marginTop: "4px" }}>
                        <Button
                          style={{
                            border: "none",
                            borderRadius: "5px",
                            padding: "5px 8px",
                            margin: "0px 5px",
                            background: this.state.selected
                              ? "#FFF"
                              : "transparent",
                          }}
                          onClick={() => this.setState({ selected: true })}
                        >
                          <UnorderedListOutlined
                            style={{
                              fontSize: "22px",
                              color: this.state.selected
                                ? "#586370"
                                : "#586370",
                            }}
                          />
                        </Button>
                      </Col>
                      <Col style={{ marginTop: "4px" }}>
                        <Button
                          style={{
                            border: "none",
                            borderRadius: "5px",
                            padding: "5px 8px",
                            margin: "0px 5px",
                            background: this.state.selected
                              ? "transparent"
                              : "#FFF",
                          }}
                          onClick={this.onClickMap}
                        >
                          <FaMap
                            style={{
                              fontSize: "22px",
                              color: !this.state.selected
                                ? "#586370"
                                : "#586370",
                            }}
                          />
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col
                xl={{ span: 24 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Card bodyStyle={{ padding: "0px" }}>
                  {selected ? (
                    <BaseTable
                      className="ownerdatatable table_laptopscreen"
                      columnsData={columns}
                      source={
                        filterData
                          ? filterData
                          : checkData.length === 0 || checkData.length === 2
                          ? bothData
                          : checkData.includes("Manually Entered Data")
                          ? manualData
                          : importData
                      }
                      rowSelection={rowSelection}
                      total={
                        filterData
                          ? filterData
                          : checkData.length === 0 || checkData.length === 2
                          ? bothData.length
                          : checkData.includes("Manually Entered Data")
                          ? manualData.length
                          : importData.length
                      }
                      getOwnerDetails={this.getOwnerDetails}
                      rowKey={(record) => record.id}
                    />
                  ) : (
                    <div className="quarryheight_tool">
                      <MapComponent ownerDatabase quarryData={mapData} />
                    </div>
                  )}
                </Card>

                {selectedRowKeys.length > 0 && (
                  <FooterData count={selectedRowKeys.length} print />
                )}
              </Col>
            </Row>
          </>
        )}

        {visible && (
          <Filter
            visible={visible}
            closeFilter={this.closeFilter}
            filterResultData={this.filterResultData}
            checkData={checkData?.length > 0 && checkData}
            onClearFilter={this.onClearFilter}
            ownerDatabase
          />
        )}

        {isModalVisible && (
          <BaseModal
            title={this.state.editModal ? "Update Owner" : "Add Owner"}
            onCancel={() =>
              this.setState({ isModalVisible: false, editModal: false })
            }
            formId="ownerDataform"
            className="antmodal_grid headermodal"
          >
            <Form onFinish={this.onFinish} id="ownerDataform">
              <Row gutter={[32, 0]}>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  // span={11}
                >
                  <span className="title_changes">Date</span>
                  <Form.Item
                    name="date"
                    rules={[
                      {
                        required: true,
                        message: "Please enter date!",
                      },
                    ]}
                    initialValue={moment()}
                  >
                    <DatePicker
                      style={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "5px",
                      }}
                      defaultValue={moment()}
                      format={"MM/DD/YYYY"}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  // span={11}
                  // offset={2}
                >
                  <span className="title_changes">Company Name</span>
                  <Form.Item
                    name="legal_name"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Company Name!",
                      },
                    ]}
                    initialValue={ownerData.legal_name}
                  >
                    <Input
                      className="form-input"
                      placeholder="Please Enter Your Company Name"
                      //readOnly
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  // span={11}
                  // offset={0}
                >
                  <span className="title_changes">Address</span>
                  <Form.Item
                    name="location"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Location!",
                      },
                    ]}
                  >
                    <GooglePlacesAutocomplete
                      className="form-input"
                      autocompletionRequest={{
                        componentRestrictions: {
                          country: ["us"],
                        },
                      }}
                      selectProps={{
                        citySearch,
                        defaultInputValue: `${
                          ownerData &&
                          (ownerData?.mailing_street
                            ? ownerData?.mailing_street + ", "
                            : "") +
                            (ownerData?.mailing_city
                              ? ownerData?.mailing_city + ", "
                              : "") +
                            (ownerData?.mailing_state
                              ? ownerData?.mailing_state + ", "
                              : "") +
                            (ownerData?.mailing_zip
                              ? ownerData?.mailing_zip
                              : "")
                        }`,
                        placeholder: "Search Address...",
                        onChange: (address) => {
                          this.handleSelect(address);
                          this.getAddress(address);
                        },
                      }}
                    />
                  </Form.Item>
                </Col>
                {/* <Col span={11} offset={2}>
                  <span className="title_changes">Address</span>
                  <Form.Item
                    name="address"
                    // onChange={this.getAddress}
                    rules={[
                      {
                        required: false,
                        errorMessage: true,
                        message: "Please enter your address!",
                      },
                    ]}
                  >
                    <GooglePlacesAutocomplete
                      className="form-input"
                      autocompletionRequest={{
                        componentRestrictions: {
                          country: ["us"],
                        },
                      }}
                      selectProps={{
                        citySearch,
                        defaultInputValue: `${ownerData.address}`,
                        placeholder: "Search Address...",
                        onChange: (address) => {
                          this.handleSelect(address);
                          this.getAddress(address);
                        },
                      }}
                    />
                  </Form.Item>
                </Col> */}
                {/* <Col span={12} offset={0}>
                <span className="title_changes">Location</span>
                <Form.Item
                  name="location"
                  rules={[
                    { required: false, message: "Please enter your location!" },
                  ]}
                  initialValue={columnsData.address}
                >
                  <GooglePlacesAutocomplete
                    className="form-input"
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: ["us"],
                      },
                    }}
                    selectProps={{
                      citySearch,
                      // defaultInputValue: `${""}`,
                      placeholder: "Search Address...",
                      onChange: (address) => {
                        this.handleSelect(address);
                      },
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12} offset={0}>
                <span className="title_changes">City</span>
                <Form.Item
                  rules={[
                    { required: false, message: "Please enter your city!" },
                  ]}
                  initialValue={columnsData.city}
                >
                  <Input
                    className="form-input"
                    placeholder="Please enter your  city"
                    readOnly
                    value={city}
                  />
                </Form.Item>
              </Col>
              <Col span={12} offset={0}>
                <span className="title_changes">State</span>
                <Form.Item
                  rules={[
                    { required: false, message: "Please enter your state!" },
                  ]}
                  initialValue={leadsData.addressState}
                >
                  <Input
                    className="form-input"
                    placeholder="Please enter your  state"
                    readOnly
                    value={addressState}
                  />
                </Form.Item>
              </Col>
              <Col span={12} offset={0}>
                <span className="title_changes">Zip Code</span>
                <Form.Item
                  rules={[
                    { required: true, message: "Please enter your zipCode!" },
                  ]}
                  initialValue={leadsData.zipCode}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    className="form-input"
                    placeholder="Please enter your  zip code"
                    readOnly
                    value={zipCode}
                  />
                </Form.Item>
              </Col> */}
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  // span={11}
                  // offset={2}
                >
                  <span className="title_changes">Phone</span>
                  <Form.Item
                    name="telephone"
                    rules={[
                      { required: true, message: "Please Enter Your Phone!" },
                    ]}
                    initialValue={ownerData.telephone}
                  >
                    <NumberFormat
                      className="ant-input-number form-input numberictest"
                      format="(###) ###-####"
                      style={{ width: "100%" }}
                      placeholder="Please Enter Your Phone"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  // span={11}
                  // offset={0}
                >
                  <span className="title_changes">Email</span>
                  <Form.Item
                    name="email_address"
                    rules={[
                      { required: true, message: "Please Enter Your Email!" },
                      {
                        type: "email",
                        message: "The Input Value Is Not Valid Email",
                      },
                    ]}
                    initialValue={ownerData.email_address}
                  >
                    <Input
                      className="form-input"
                      placeholder="Please Enter Your Email"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  // span={11}
                  // offset={2}
                >
                  <span className="title_changes">Drivers</span>
                  <Form.Item
                    name="driver_total"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Total Drivers!",
                      },
                    ]}
                    initialValue={ownerData.driver_total}
                  >
                    <InputNumber
                      min={1}
                      className="form-input"
                      style={{ width: "100%" }}
                      placeholder="Please Enter Your Total Drivers"
                    />
                  </Form.Item>
                </Col>
                {/* <Col span={12} offset={0}>
                <span className="title_changes">TRUCKS</span>
                <Form.Item
                  name="trucks"
                  rules={[
                    { required: true, message: "Please enter your trucks!" },
                  ]}
                  initialValue={leadsData.trucks}
                >
                  <InputNumber
                    className="form-input"
                    style={{ width: "100%" }}
                    placeholder="Please enter your trucks"
                  />
                </Form.Item>
              </Col>
              <Col span={12} offset={0}>
                <span className="title_changes">Drivers</span>
                <Form.Item
                  name="drivers"
                  rules={[
                    { required: true, message: "Please enter your Drivers!" },
                  ]}
                  initialValue={leadsData.drivers}
                >
                  <InputNumber
                    className="form-input"
                    style={{ width: "100%" }}
                    placeholder="Please enter your Drivers"
                  />
                </Form.Item>
              </Col>
              <Col span={12} offset={0}>
                <span className="title_changes">Cargo Carried</span>
                <Form.Item
                  name="cargo"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your cargo carried!",
                    },
                  ]}
                  initialValue={leadsData.cargo}
                >
                  <InputNumber
                    className="form-input"
                    style={{ width: "100%" }}
                    placeholder="Please enter your cargo carried"
                  />
                </Form.Item>
              </Col> */}
              </Row>
            </Form>
          </BaseModal>
        )}
      </div>
    );
  }
}
export default withAppContext(OwnerDatabase);
