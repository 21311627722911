import Lottie from "lottie-react";
import trucksuccess from "../Json/trucksuccess.json";

const Trucksuccess = () => {
  return (
    <div>
      <div
        style={{
          width: "150px",
          height: "150px",
          margin: "0px auto",
        }}
      >
        <Lottie animationData={trucksuccess} loop={true} />
      </div>
    </div>
  );
};
export default Trucksuccess;
