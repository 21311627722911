import {
  FETCH_CONTACTS,
  FETCH_LEADS,
  FETCH_CONTACTS_DELETE,
  FETCH_CONTACTS_UPDATE,
  FETCH_OWNEROPERATORS,
  FETCH_COMPANY_DELETE,
  FETCH_COMPANY_ADD,
  FETCH_COMPANY_UPDATE,
  FETCH_DROPDOWNOPTIONS,
  FETCH_DROPDOWNOPTIONS_UPDATE,
  FETCH_COMPANIES,
  FETCH_CONTACTS_ADD,
  FETCH_COMPANY,
  FETCH_LEADS_ADD,
  FETCH_LEADS_UPDATE,
  FETCH_JOBCONTACT_DELETE,
  FETCH_DOCUMENTS_ADD,
  FETCH_DOCUMENTS_UPDATE,
  FETCH_DOCUMENTS_DELETE,
  FETCH_TRUCKSLIST,
  FETCH_TRUCKSLIST_ADD,
  FETCH_QUARRIESLIST,
  FETCH_QUARRIESLIST_ADD,
  FETCH_QUARRIESLIST_UPDATE,
  FETCH_QUARRIESLIST_DELETE,
  FETCH_CONTACT_ADD,
  FETCH_LEADS_DELETE,
  FETCH_LEAD,
  FETCH_TRUCKSLIST_QUERY,
  FETCH_DOCUMENTS,
  FETCH_NOTES,
  FETCH_NOTES_ADD,
  FETCH_NOTES_UPDATE,
  FETCH_NOTES_DELETE,
  FETCH_QUARRY,
  FETCH_TOPVENDORS,
  FETCH_TRUCKLIST,
  FETCH_TRUCKLIST_UPDATE,
  FETCH_USERS,
  FETCH_USER_ADD,
  FETCH_USER_UPDATE,
  FETCH_ROLES,
  FETCH_ROLE_ADD,
  FETCH_ROLE_UPDATE,
  FETCH_ROLE_DELETE,
  FETCH_DISPATCH,
  FETCH_TICKETPROCESS,
  FETCH_INVOICES,
  FETCH_INVOICE,
  FETCH_INVOICE_GENERATE,
  FETCH_TICKETPROCESS_ADD,
  FETCH_TICKETPROCESS_DELETE,
  FETCH_DASHBOARD,
  FETCH_SALESPERSON,
  FETCH_RECURRING,
  UPDATE_QUARRYSTATUS,
  GET_DISPATCH_SUMMARY,
  DELETE_TRUCKSLIST,
  GET_DELETE_DATA,
  DELETE_RESTORE_DATA,
  FETCH_ORDERS,
  FETCH_ORDERS_UPDATE,
  UPDATE_INVOICES,
  DELETE_ORDERS_DATA,
  UPDATE_ESTIMATE,
  FETCH_SALES_SUMMARY,
  UPDATE_MULTIPLE_INVOICES,
  GET_ACCOUNTS_PAYABLE,
  FETCH_SINGLE_TICKETPROCESS,
  FETCH_DISPATCH_EMAIL
} from "./types";
// import AppConfig from "../../config/AppConfig";
// import { list } from "../../api/Apis";

const BACKEND_API = "BACKEND_API";
// export const fetchList = (query, type) => async (dispatch) => {
//   type: BACKEND_API,
//   payload: {
//     method: 'post',
//     url: '/global_location/',
//     data,
//     ...UPDATE_LOCATIONS
//   }
//   console.log(type);
//   const response = await list(query);
//   dispatch({ type: FETCH_COMPANY, payload: response.data });
// };

export const getDashboard = (data) => ({
  type: BACKEND_API,
  payload: {
    method: "post",
    data: data,
    url: "getDashboard",
    ...FETCH_DASHBOARD,
  },
});

export const generateInvoice = (data) => ({
  type: BACKEND_API,
  payload: {
    method: "post",
    data: data,
    url: `generateInvoice`,
    ...FETCH_INVOICE_GENERATE,
  },
});

export const getSalesSummary = () => ({
  type: BACKEND_API,
  payload: {
    method: "get",
    url: `getSalesSummary`,
    ...FETCH_SALES_SUMMARY,
  },
});

export const getInvoiceDetails = (id) => ({
  type: BACKEND_API,
  payload: {
    method: "get",
    url: `invoices/${id}`,
    ...FETCH_INVOICE,
  },
});

export const getAccountsPayable = () => ({
  type: BACKEND_API,
  payload: {
    method: "get",
    url: "accountsPayable",
    ...GET_ACCOUNTS_PAYABLE,
  },
});

export const getInvoices = () => ({
  type: BACKEND_API,
  payload: {
    method: "get",
    url: "invoices",
    ...FETCH_INVOICES,
  },
});

export const updateInvoices = (id, data) => ({
  type: BACKEND_API,
  payload: {
    method: "put",
    data: data,
    url: `invoices/${id}`,
    ...UPDATE_INVOICES,
  },
});

export const updateMultipleInvoice = (data) => ({
  type: BACKEND_API,
  payload: {
    method: "post",
    data: data,
    url: `updateMultipleInvoices`,
    ...UPDATE_MULTIPLE_INVOICES,
  },
});

export const getTickets = () => ({
  type: BACKEND_API,
  payload: {
    method: "get",
    url: "ticketProcess",
    ...FETCH_TICKETPROCESS,
  },
});

export const getSingleTicketData = (id) => ({
  type: BACKEND_API,
  payload: {
    method: "get",
    url: `ticketProcess?jobId=${id}`,
    ...FETCH_SINGLE_TICKETPROCESS,
  },
});

export const addTicket = (data) => ({
  type: BACKEND_API,
  payload: {
    method: "post",
    data: data,
    url: `addTicketProcess`,
    ...FETCH_TICKETPROCESS_ADD,
  },
});

export const deleteTicket = (id) => ({
  type: BACKEND_API,
  payload: {
    method: "delete",
    url: `ticketProcess/${id}`,
    ...FETCH_TICKETPROCESS_DELETE,
  },
});

export const getDispatch = () => ({
  type: BACKEND_API,
  payload: {
    method: "get",
    url: "getDispatch",
    ...FETCH_DISPATCH,
  },
});

export const getOrders = () => ({
  type: BACKEND_API,
  payload: {
    method: "get",
    url: "orders",
    ...FETCH_ORDERS,
  },
});

export const getRoles = () => ({
  type: BACKEND_API,
  payload: {
    method: "get",
    url: "roles",
    ...FETCH_ROLES,
  },
});

export const addRole = (data) => ({
  type: BACKEND_API,
  payload: {
    method: "post",
    data: data,
    url: "roles",
    ...FETCH_ROLE_ADD,
  },
});

export const updateRole = (id, data) => ({
  type: BACKEND_API,
  payload: {
    method: "put",
    data: data,
    url: `roles/${id}`,
    ...FETCH_ROLE_UPDATE,
  },
});

export const deleteRole = (id) => ({
  type: BACKEND_API,
  payload: {
    method: "delete",
    url: `roles/${id}`,
    ...FETCH_ROLE_DELETE,
  },
});

export const getUsers = () => ({
  type: BACKEND_API,
  payload: {
    method: "get",
    url: "users",
    ...FETCH_USERS,
  },
});

export const addUser = (data) => ({
  type: BACKEND_API,
  payload: {
    method: "post",
    data: data,
    url: "addUser",
    ...FETCH_USER_ADD,
  },
});

export const updateUser = (id, data) => ({
  type: BACKEND_API,
  payload: {
    method: "put",
    data: data,
    url: `users/${id}`,
    ...FETCH_USER_UPDATE,
  },
});

export const deleteUser = (id) => ({
  type: BACKEND_API,
  payload: {
    method: "delete",
    url: `users/${id}`,
    ...FETCH_USER_UPDATE,
  },
});

export const getCompanies = () => ({
  type: BACKEND_API,
  payload: {
    method: "get",
    url: "companies",
    ...FETCH_COMPANIES,
  },
});

export const getCompany = (id) => ({
  type: BACKEND_API,
  payload: {
    method: "get",
    url: `getCompany/${id}`,
    ...FETCH_COMPANY,
  },
});

export const addCompany = (data) => ({
  type: BACKEND_API,
  payload: {
    method: "post",
    data: data,
    url: "addCompany",
    ...FETCH_COMPANY_ADD,
  },
});

export const deleteCompany = (id) => ({
  type: BACKEND_API,
  payload: {
    method: "delete",
    url: `companies/${id}`,
    ...FETCH_COMPANY_DELETE,
  },
});

export const updateCompany = (id, data) => ({
  type: BACKEND_API,
  payload: {
    method: "put",
    data: data,
    url: `companies/${id}`,
    ...FETCH_COMPANY_UPDATE,
  },
});

export const getLeads = () => ({
  type: BACKEND_API,
  payload: {
    method: "get",
    url: "getLeads",
    ...FETCH_LEADS,
  },
});

export const getLead = (id) => ({
  type: BACKEND_API,
  payload: {
    method: "get",
    url: `getLead/${id}`,
    ...FETCH_LEAD,
  },
});

export const addLead = (data) => ({
  type: BACKEND_API,
  payload: {
    method: "post",
    data: data,
    url: "addLead",
    ...FETCH_LEADS_ADD,
  },
});

export const updateLead = (id, data) => ({
  type: BACKEND_API,
  payload: {
    method: "put",
    data: data,
    url: `leads/${id}`,
    ...FETCH_LEADS_UPDATE,
  },
});

export const updateOrder = (id, data) => ({
  type: BACKEND_API,
  payload: {
    method: "put",
    data: data,
    url: `orders/${id}`,
    ...FETCH_ORDERS_UPDATE,
  },
});

export const deleteLead = (id) => ({
  type: BACKEND_API,
  payload: {
    method: "delete",
    url: `leads/${id}`,
    ...FETCH_LEADS_DELETE,
  },
});

export const getContacts = () => ({
  type: BACKEND_API,
  payload: {
    method: "get",
    url: "contacts",
    ...FETCH_CONTACTS,
  },
});

export const deleteContacts = (id) => ({
  type: BACKEND_API,
  payload: {
    method: "delete",
    url: `contacts/${id}`,
    ...FETCH_CONTACTS_DELETE,
  },
});

export const deleteContactandJob = (id) => ({
  type: BACKEND_API,
  payload: {
    method: "delete",
    url: `deleteContact/${id}`,
    ...FETCH_JOBCONTACT_DELETE,
  },
});

export const updateContacts = (id, data) => ({
  type: BACKEND_API,
  payload: {
    method: "put",
    data: data,
    url: `contacts/${id}`,
    ...FETCH_CONTACTS_UPDATE,
  },
});

export const addContacts = (data) => ({
  type: BACKEND_API,
  payload: {
    method: "post",
    data: data,
    url: "addContact",
    ...FETCH_CONTACTS_ADD,
  },
});

export const addContact = (data) => ({
  type: BACKEND_API,
  payload: {
    method: "post",
    data: data,
    url: "contacts",
    ...FETCH_CONTACT_ADD,
  },
});

export const getOwnerOperators = () => ({
  type: BACKEND_API,
  payload: {
    method: "get",
    url: "companies?isOwnerOperator=true",
    ...FETCH_OWNEROPERATORS,
  },
});

export const getDropDownOptions = () => ({
  type: BACKEND_API,
  payload: {
    method: "get",
    url: `dropDownOptions`,
    ...FETCH_DROPDOWNOPTIONS,
  },
});

export const updateDropDownOptions = (id, data) => ({
  type: BACKEND_API,
  payload: {
    method: "put",
    data: data,
    url: `dropDownOptions/${id}`,
    ...FETCH_DROPDOWNOPTIONS_UPDATE,
  },
});

export const getDocuments = (id) => ({
  type: BACKEND_API,
  payload: {
    method: "get",
    url: `documents?leadId=${id}`,
    ...FETCH_DOCUMENTS,
  },
});

export const addDocument = (data, type) => ({
  type: BACKEND_API,
  payload: {
    method: "post",
    data: data,
    url: "documents",
    ...FETCH_DOCUMENTS_ADD,
  },
  updateStatus: type,
});

export const updateDocument = (id, data) => ({
  type: BACKEND_API,
  payload: {
    method: "put",
    data: data,
    url: `documents/${id}`,
    ...FETCH_DOCUMENTS_UPDATE,
  },
});

export const deleteDocument = (id) => ({
  type: BACKEND_API,
  payload: {
    method: "delete",
    url: `documents/${id}`,
    ...FETCH_DOCUMENTS_DELETE,
  },
});

export const getTrucksList = () => ({
  type: BACKEND_API,
  payload: {
    method: "get",
    url: `trucksList`,
    ...FETCH_TRUCKSLIST,
  },
});

export const deleteTrucksList = (id) => ({
  type: BACKEND_API,
  payload: {
    method: "delete",
    url: `trucksList/${id}`,
    ...DELETE_TRUCKSLIST,
  },
});

export const getTruckList = (id) => ({
  type: BACKEND_API,
  payload: {
    method: "get",
    url: `getCarrierDetails/${id}`,
    ...FETCH_TRUCKLIST,
  },
});

export const updateTrucksList = (id, data) => ({
  type: BACKEND_API,
  payload: {
    method: "put",
    data: data,
    url: `trucksList/${id}`,
    ...FETCH_TRUCKLIST_UPDATE,
  },
});

export const getTrucksListQuery = () => ({
  type: BACKEND_API,
  payload: {
    method: "get",
    url: `trucksList?carrier_status=Update Needed`,
    ...FETCH_TRUCKSLIST_QUERY,
  },
});

export const addTrucksList = (data) => ({
  type: BACKEND_API,
  payload: {
    method: "post",
    data: data,
    url: `trucksList`,
    ...FETCH_TRUCKSLIST_ADD,
  },
});

export const getQuarryLocations = () => ({
  type: BACKEND_API,
  payload: {
    method: "get",
    url: `quarryLocations`,
    ...FETCH_QUARRIESLIST,
  },
});

export const getQuarry = (id) => ({
  type: BACKEND_API,
  payload: {
    method: "get",
    url: `getQuarryDetails/${id}`,
    ...FETCH_QUARRY,
  },
});

export const addQuarry = (data) => ({
  type: BACKEND_API,
  payload: {
    method: "post",
    data: data,
    url: `quarryLocations`,
    ...FETCH_QUARRIESLIST_ADD,
  },
});

export const updateQuarry = (id, data) => ({
  type: BACKEND_API,
  payload: {
    method: "put",
    data: data,
    url: `quarryLocations/${id}`,
    ...FETCH_QUARRIESLIST_UPDATE,
  },
});

export const deleteQuarry = (id) => ({
  type: BACKEND_API,
  payload: {
    method: "delete",
    url: `quarryLocations/${id}`,
    ...FETCH_QUARRIESLIST_DELETE,
  },
});

export const getNotes = (id) => ({
  type: BACKEND_API,
  payload: {
    method: "get",
    url: `leadNotes?leadId=${id}`,
    ...FETCH_NOTES,
  },
});

export const addNotes = (data) => ({
  type: BACKEND_API,
  payload: {
    method: "post",
    data: data,
    url: "leadNotes",
    ...FETCH_NOTES_ADD,
  },
});

export const updateNotes = (id, data) => ({
  type: BACKEND_API,
  payload: {
    method: "put",
    data: data,
    url: `leadNotes/${id}`,
    ...FETCH_NOTES_UPDATE,
  },
});

export const deleteNotes = (id) => ({
  type: BACKEND_API,
  payload: {
    method: "delete",
    url: `leadNotes/${id}`,
    ...FETCH_NOTES_DELETE,
  },
});

export const getTopVendors = (data) => ({
  type: BACKEND_API,
  payload: {
    method: "post",
    data: data,
    url: `getTopQuarries`,
    ...FETCH_TOPVENDORS,
  },
});

export const getSalesPerson = (data) => ({
  type: BACKEND_API,
  payload: {
    method: "get",
    url: `salesPerson`,
    ...FETCH_SALESPERSON,
  },
});

export const updateRecurringData = (data) => ({
  type: BACKEND_API,
  payload: {
    method: "post",
    data: data,
    url: `updateRecurring`,
    ...FETCH_RECURRING,
  },
});

export const updateQuarryStatus = (data) => ({
  type: BACKEND_API,
  payload: {
    method: "post",
    data: data,
    url: `updateQuarryStatus`,
    ...UPDATE_QUARRYSTATUS,
  },
});

export const getDispatchSummary = (data) => ({
  type: BACKEND_API,
  payload: {
    method: "post",
    data: data,
    url: `getDispatchSummary`,
    ...GET_DISPATCH_SUMMARY,
  },
});

export const getDeleteData = () => ({
  type: BACKEND_API,
  payload: {
    method: "get",
    url: `getDeletedData`,
    ...GET_DELETE_DATA,
  },
});
export const updateGenerateEstimate = (data) => ({
  type: BACKEND_API,
  payload: {
    method: "post",
    data: data,
    url: `updateEstimate`,
    ...UPDATE_ESTIMATE,
  },
});

export const deleteData = (id) => ({
  type: BACKEND_API,
  payload: {
    method: "delete",
    url: `deletedData/${id}`,
    ...DELETE_RESTORE_DATA,
  },
});

export const deleteOrders = (id) => ({
  type: BACKEND_API,
  payload: {
    method: "delete",
    url: `orders/${id}`,
    ...DELETE_ORDERS_DATA,
  },
});

export const disptachMail = (data) => ({
  type: BACKEND_API,
  payload: {
    method: "POST",
    url: `dispatchMail`,
    data,
    ...FETCH_DISPATCH_EMAIL,
  },
});
