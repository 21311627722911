import React, { Component } from "react";
import JobDataBase from "../Database/JobDataBase";

class RequestDispatch extends Component {
    render() {
        return (
            <div>
                <JobDataBase requestDispatch />
            </div>
        );
    }
}


export default RequestDispatch;
