import { Route } from "react-router-dom";
import Application from "./Components/Application";
import Login from "./Login";
import Register from "./Register";
export const Authenticated = () => {
  // let jwtToken= Cookies.get("jwt_Token")
  // console.log(jwtToken);
  // if(jwtToken === undefined){
  //   return (<Redirect to="/login" />);
  // }
  return (
    <div style={{ background: "#f0f2f5"}}>
      {window.location.pathname !== "/login" &&
        window.location.pathname !== "/" &&
        window.location.pathname !== "/register" && <Application />}
      {(window.location.pathname === "/" ||
        window.location.pathname === "/login") && (
        <Route path="/" component={Login} />
      )}
      {window.location.pathname === "/register" && (
        <Route path="/" component={Register} />
      )}
    </div>
  );
};

export default Authenticated;
