import React, { Component } from "react";
import { connect } from "react-redux";
import { withAppContext } from "../../Store/AppContext";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Input,
  Form,
  Popover,
  Tag,
  DatePicker,
  InputNumber,
  Select,
} from "antd";
import Aos from "aos";
import moment from "moment";
import {
  addTicket,
  deleteTicket,
  generateInvoice,
  getDispatch,
  getDropDownOptions,
  getTickets,
} from "../../Redux/Actions/actions";
import Loading from "../../Common/Loading";
import BaseTable from "../../Common/BaseTable";
import { PDFViewer } from "react-view-pdf";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";
import BaseModal from "../../Common/BaseModal";
import SearchFunction from "../../Common/SearchFunction";

const { TextArea } = Input;
const { Option } = Select;
class ManagerReview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalTicketsData: [],
      filterData: [],
      allTruckNumbers: [],
      allTruckTypes: [],
      trucksTypesData: [],
      leadsData: {},
      singlePdfTicket: "",
      pickUpTime: "",
      ticketUploaded: "",
      isModalVisible: false,
      quantityType: "Tons",
    };
  }

  componentDidMount = () => {
    const { actionFetchGetTickets, actiongetDropDownOptions } = this.props;
    document.title = "Twisted Nail - Ticket Processing";
    actionFetchGetTickets();
    actiongetDropDownOptions();
    Aos.init({
      // initialise with other settings
      duration: 2000,
    });
  };

  componentDidUpdate(prevProps) {
    const { dropDownOptions, totalResult } = this.props;
    if (
      prevProps?.totalResult?.success === false &&
      totalResult?.success === true
    ) {
      const ticketData = this.props?.totalResult?.ticketsData?.filter(
        (e) => e.managerReview
      );
      let data = ticketData?.sort(
        (a, b) =>
          new Date(moment(b?.uploadDate).format("L")) -
          new Date(moment(a?.uploadDate).format("L"))
      );

      if (data?.length > 0) {
        this.setState({
          totalTicketsData: ticketData,
          filterData: ticketData,
        });
      }
    }

    if (
      prevProps?.dropDownOptions?.success === false &&
      dropDownOptions?.success === true
    ) {
      this.setState({
        trucksTypesData: this.props?.trucksTypesData[0]?.trucksData,
      });
    }
  }

  showModal = (record) => {
    const updatedNewTrucks = [];
    record?.material.map((m) =>
      m?.assignTrucksInfo?.forEach((element) => {
        if (element?.secondTruckNo?.length > 0) {
          updatedNewTrucks.push(element?.secondTruckNo);
        }
        updatedNewTrucks.push(element?.truckId);
      })
    );
    this.setState({
      leadsData: record,
      isModalVisible: true,
      ticketUploaded: record.ticketUploadPicture,
      singlePdfTicket: record.singlePdfTicket,
      allTruckNumbers: updatedNewTrucks?.flat(),
      allTruckTypes: record?.material[0]?.truckType,
    });
  };

  searchResult = (searchValue) => {
    const { filterData } = this.state;
    let searchFilter = [];
    if (typeof searchValue === "number") {
      searchFilter = filterData.filter(
        (e) =>
          parseInt(e["jobNumber"]) === searchValue ||
          parseInt(e["ticketNumber"]) === searchValue
      );
    } else {
      searchValue = searchValue?.toLowerCase();
      searchFilter = filterData.filter(
        (e) =>
          e["company"]?.toLowerCase().indexOf(searchValue) > -1 ||
          (e["managerInfo"] &&
            e["managerInfo"]["name"]?.toLowerCase().indexOf(searchValue) >
              -1) ||
          e["companyName"]?.toLowerCase().indexOf(searchValue) > -1
      );
    }
    this.setState({
      totalTicketsData: searchFilter,
    });
  };

  onResetData = () => {
    this?.props?.actionFetchGetTickets();
  };

  render() {
    const { loading } = this.props;
    const {
      singlePdfTicket,
      totalTicketsData,
      isModalVisible,
      leadsData,
      ticketUploaded,
      quantityType,
      pickUpTime,
      allTruckNumbers,
      allTruckTypes,
      trucksTypesData,
    } = this.state;

    const Controls = () => {
      const { resetTransform } = useControls();

      return (
        <div className="tools">
          {/* <span class="custom-button" onClick={() => zoomIn()}>
            Zoom In
          </span>
          <span class="custom-button1" onClick={() => zoomOut()}>
            Zoom Out
          </span> */}
          <span class="custom-button2" onClick={() => resetTransform()}>
            Reset
          </span>
        </div>
      );
    };

    const selectAfter = (
      <Select
        defaultValue={quantityType}
        className="select-after"
        onChange={(e) => {
          this.setState({ quantityType: e });
        }}
      >
        <Option value="Tons">Tons</Option>
        <Option value="Loads">Loads</Option>
        <Option value="Hours">Hours</Option>
      </Select>
    );
    const columns = [
      {
        title: "Date",
        dataIndex: "",
        key: "date",
        sorter: false,
        width: "10%",
        className: "col_styling",
        onCell: (record) => ({
          onDoubleClick: () => {
            this.showModal(record);
          },
        }),
        render: (record) => {
          return (
            <span
              className="col_styling table-font-mobile-accounts"
              style={{
                textTransform: "capitalize",
                width: "10px",
              }}
            >
              {moment(record?.managerInfo?.date).format("l")}
            </span>
          );
        },
      },
      {
        title: "Time",
        dataIndex: "",
        key: "time",
        sorter: false,
        width: "10%",
        className: "col_styling",
        onCell: (record) => ({
          onDoubleClick: () => {
            this.showModal(record);
          },
        }),
        render: (record) => {
          return (
            <span
              className="col_styling table-font-mobile-accounts"
              style={{
                textTransform: "capitalize",
                width: "10px",
              }}
            >
              {record?.managerInfo?.time}
            </span>
          );
        },
      },
      {
        title: "User Name",
        dataIndex: "",
        key: "managerName",
        sorter: false,
        className: "col_styling",
        width: "15%",
        onCell: (record) => ({
          onDoubleClick: () => {
            this.showModal(record);
          },
        }),
        render: (record) => {
          return (
            <span
              className="col_styling table-font-mobile-accounts"
              style={{
                textTransform: "capitalize",
                width: "10px",
              }}
            >
              {record?.managerInfo?.name}
            </span>
          );
        },
      },
      {
        title: "CUSTOMER",
        dataIndex: "companyName",
        key: "companyName",
        sorter: false,
        className: "col_styling",
        width: "15%",
        onCell: (record) => ({
          onDoubleClick: () => {
            this.showModal(record);
          },
        }),
        render: (companyName) => {
          return (
            <span
              className="col_styling table-font-mobile-accounts"
              style={{
                textTransform: "capitalize",
                width: "10px",
              }}
            >
              {companyName}
            </span>
          );
        },
      },
      {
        title: "JOB #",
        dataIndex: "jobNumber",
        key: "jobNumber",
        sorter: false,
        className: "col_styling",
        width: "10%",
        onCell: (record) => ({
          onDoubleClick: () => {
            this.showModal(record);
          },
        }),
        render: (jobNumber) => {
          return (
            <span
              className="col_styling table-font-mobile-accounts"
              style={{
                textTransform: "capitalize",
                width: "10px",
              }}
            >
              {jobNumber}
            </span>
          );
        },
      },
      {
        title: "Ticket #",
        dataIndex: "ticketNumber",
        key: "ticketNumber",
        sorter: false,
        className: "col_styling",
        width: "10%",
        onCell: (record) => ({
          onDoubleClick: () => {
            this.showModal(record);
          },
        }),
        render: (ticketNumber) => {
          return (
            <span
              className="col_styling table-font-mobile-accounts"
              style={{
                textTransform: "capitalize",
                width: "10px",
              }}
            >
              {ticketNumber}
            </span>
          );
        },
      },
      {
        title: "Manager Notes",
        dataIndex: "",
        key: "managerNotes",
        sorter: false,
        className: "col_styling",
        onCell: (record) => ({
          onDoubleClick: () => {
            this.showModal(record);
          },
        }),
        render: (record) => {
          return (
            <span
              className="col_styling table-font-mobile-accounts"
              style={{
                textTransform: "capitalize",
                width: "10px",
              }}
            >
              {record?.managerInfo?.notes}
            </span>
          );
        },
      },
    ];

    return (
      <div
        style={{
          background: "#fafafa",
        }}
      >
        <Loading enableLoading={loading} />
        {/* <Row
          style={{
            marginBottom: "1vw",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Row
            onClick={() => {
              this.props.actionFetchGetTickets();
              this.props.handleCancel();
            }}
            style={{ cursor: "pointer" }}
          >
            <BsArrowLeftShort
              style={{ color: "#5f6c74", fontSize: "25px" }}
              className="back-button-return-icon"
            />
            <p
              style={{ color: "#808080", fontSize: "16px", fontWeight: "600" }}
              className="back-button-return"
            >
              Return
            </p>
          </Row>
        </Row> */}
        <Row style={{ marginBottom: "1vw" }}>
          <Col
            xxl={{ span: 6 }}
            xl={{ span: 6 }}
            lg={{ span: 6 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            // span={12}
          >
            <Row>
              <Col
                xxl={{ span: 24 }}
                xl={{ span: 24 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                className="quarrytoolgrid"
              >
                <SearchFunction
                  searchData={totalTicketsData}
                  tableName={"leads"}
                  getSearchData={this.searchResult}
                  onResetData={this.onResetData}
                  // searchFields={searchFields}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Card bodyStyle={{ padding: "0px" }} data-aos="fade-up">
              {totalTicketsData?.length > 0 ? (
                <BaseTable
                  className="financeticket_table table_laptopscreen"
                  columnsData={columns}
                  source={totalTicketsData}
                  total={totalTicketsData?.length}
                  rowKey={(record) => record.id}
                  handleTableChage={(e) =>
                    this.handleTableChage(e, "ticketUpload")
                  }
                  sizeRange={
                    window.screen.width > 1023
                      ? JSON.parse(localStorage.getItem("tableSizeRange"))
                          ?.ticketUpload
                      : totalTicketsData?.length
                  }
                  pagination={window.screen.width > 1023 ? true : false}
                />
              ) : (
                <Card
                  style={{ padding: "150px", textAlign: "center" }}
                  className="no-items-card"
                >
                  <span style={{ fontSize: "20px" }} className="no-items-card">
                    No Tickets Found!
                  </span>
                </Card>
              )}
            </Card>
          </Col>
        </Row>
        {isModalVisible && (
          <BaseModal
            className="tickectfinan_modal antmodal_grid headermodal"
            title={`View Details`}
            onCancel={() =>
              this.setState({
                isModalVisible: false,
                leadsData: {},
                ticketUploaded: "",
                singlePdfTicket: "",
                allTruckNumbers: [],
                allTruckTypes: [],
              })
            }
            formId="ticketForm"
            loading={loading}
            footer={false}
            submitButton={
              leadsData?.ticketVerification === "Open"
                ? "Submit for review"
                : leadsData?.ticketVerification === "Review"
                ? "Approve"
                : "Next"
            }
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "80%"
                : "850px"
            }
            btnHeight="40px"
          >
            <Form
              id="ticketForm"
              onFinish={(e) => this.onFinish(e, leadsData)}
              ref={this.formRef}
            >
              {singlePdfTicket !== "" && (
                <Col
                  xxl={{ span: 24, offset: 0 }}
                  xl={{ span: 24, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  className="pdfviewcls"
                >
                  <TransformWrapper
                    initialScale={1}
                    initialPositionX={1}
                    initialPositionY={1}
                  >
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                      <React.Fragment>
                        <Controls />
                        <TransformComponent>
                          <PDFViewer
                            url={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/${singlePdfTicket}?#view=FitH`}
                            viewer="url"
                            style={{
                              width: "100%",
                              height: "400px",
                              overflow: "auto",
                            }}
                            onError={(error) => {
                              console.error("Error loading PDF:", error);
                            }}
                          />
                        </TransformComponent>
                      </React.Fragment>
                    )}
                  </TransformWrapper>
                </Col>
              )}
              <div className="bordered-class">
                {ticketUploaded && leadsData?.ticketVerification === "Open" && (
                  <Row gutter={[24, 8]} style={{ marginTop: "1vw" }}>
                    <Col
                      xxl={{ span: 12, offset: 0 }}
                      xl={{ span: 12, offset: 0 }}
                      lg={{ span: 12, offset: 0 }}
                      md={{ span: 12, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      xs={{ span: 24, offset: 0 }}
                    >
                      <b>Ticket Date</b>

                      <Form.Item
                        name="ticketDate"
                        rules={[
                          {
                            required: false,
                            message: "Please input your Date!",
                          },
                        ]}
                        initialValue={
                          leadsData?.ticketDate && moment(leadsData?.ticketDate)
                        }
                        style={{ marginBottom: "0px" }}
                      >
                        <DatePicker
                          className="form-input"
                          style={{ width: "100%" }}
                          placeholder="Select Date"
                          format={"M/D/YYYY"}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xxl={{ span: 12, offset: 0 }}
                      xl={{ span: 12, offset: 0 }}
                      lg={{ span: 12, offset: 0 }}
                      md={{ span: 12, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      xs={{ span: 24, offset: 0 }}
                    >
                      <b style={{ fontSize: "14px" }}>
                        Load Time
                        {pickUpTime !== "Invalid Date" && pickUpTime !== ""
                          ? " (" + pickUpTime + ")"
                          : leadsData?.pickupTime
                          ? " (" + leadsData?.pickupTime + ")"
                          : ""}{" "}
                        <span>(Ex: 1005a for 10:05 AM)</span>
                      </b>
                      <br />
                      <Form.Item
                        name="pickupVal"
                        rules={[
                          {
                            required: false,
                            message: "Please input your Pickup-Time!",
                          },
                        ]}
                        initialValue={
                          leadsData?.pickupVal ? leadsData?.pickupVal : ""
                        }
                        style={{ marginBottom: "0px" }}
                      >
                        <Input
                          className="form-input"
                          placeholder="Please enter Time"
                          onChange={(e) => this.handleTimeChange(e)}
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      xxl={{
                        span: 8,
                      }}
                      xl={{
                        span: 8,
                      }}
                      lg={{
                        span: 8,
                      }}
                      md={{ span: 12 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <b>Ticket Number</b>
                      <Form.Item
                        name="ticketNumber"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Ticket Number!",
                          },
                        ]}
                        initialValue={leadsData?.ticketNumber}
                        style={{ marginBottom: "0px" }}
                      >
                        <Input
                          className="form-input"
                          placeholder="Please enter ticket number"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xxl={{
                        span: 8,
                      }}
                      xl={{
                        span: 8,
                      }}
                      lg={{
                        span: 8,
                      }}
                      md={{ span: 12 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <b>Truck Number</b>
                      <Form.Item
                        name="truckNumber"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Truck Number!",
                          },
                        ]}
                        style={{ marginBottom: "0px" }}
                        initialValue={
                          leadsData?.truckNumber ? leadsData?.truckNumber : ""
                        }
                      >
                        <Input
                          type="number"
                          onChange={(e) => {
                            this.setState({
                              updatedTruckNumber: Number(e.target.value),
                              selectedMaterialId: "",
                              selectedCarrierId: "",
                            });
                          }}
                          placeholder="Please Select Truck number"
                          style={{
                            width: "100%",
                          }}
                          className="form-input"
                          onBlur={this.truckNumberValidation}
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      xxl={{ span: 8, offset: 0 }}
                      xl={{ span: 8, offset: 0 }}
                      lg={{ span: 8, offset: 0 }}
                      md={{ span: 12, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      xs={{ span: 24, offset: 0 }}
                    >
                      <b>Quantity</b>

                      <Form.Item
                        name="quantity"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Quantity",
                          },
                        ]}
                        initialValue={
                          leadsData?.quantity ? leadsData?.quantity : ""
                        }
                        style={{ marginBottom: "0px" }}
                      >
                        <InputNumber
                          placeholder="Please Enter Quantity"
                          addonAfter={selectAfter}
                          style={{ height: "40px", width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                {ticketUploaded && leadsData?.ticketVerification !== "Open" && (
                  <>
                    <Row>
                      <Col
                        xxl={{ span: 24 }}
                        xl={{ span: 24 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                        style={{
                          backgroundColor: "#586370",
                          color: "#fff",
                          fontWeight: "bold",
                          margin: "10px 0px",
                          borderRadius: "3px",
                        }}
                      >
                        <span
                          style={{ padding: "5px 0px", display: "flex" }}
                          className="title_changes_name"
                        >
                          TICKET DETAILS
                        </span>
                      </Col>
                    </Row>
                    <Row
                      gutter={[12, 0]}
                      style={{
                        marginBottom: "10px",
                      }}
                      className="tiprotitle"
                    >
                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 8 }}
                        md={{ span: 12 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                        style={{ marginBottom: "0.5vw" }}
                      >
                        <b style={{ marginBottom: "10px" }}>Date:</b>
                        <Input
                          className="form-input"
                          placeholder="Enter pickup address"
                          value={leadsData?.ticketDate}
                        />
                      </Col>
                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 8 }}
                        md={{ span: 12 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                        style={{ marginBottom: "0.5vw" }}
                      >
                        <b style={{ marginBottom: "10px" }}>Load Time:</b>{" "}
                        <Input
                          className="form-input"
                          placeholder="Enter pickup address"
                          value={
                            leadsData?.pickupTime ? leadsData?.pickupTime : ""
                          }
                        />
                      </Col>
                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 8 }}
                        md={{ span: 12 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                        style={{ marginBottom: "0.5vw" }}
                      >
                        <b style={{ marginBottom: "10px" }}>Truck Number:</b>
                        <Input
                          className="form-input"
                          placeholder="Enter truck rate"
                          value={leadsData?.truckNumber}
                        />
                      </Col>

                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 8 }}
                        md={{ span: 12 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                        style={{ marginBottom: "0.5vw" }}
                      >
                        <b style={{ marginBottom: "10px" }}>Quantity:</b>

                        <Input
                          className="form-input"
                          placeholder="Enter truck rate"
                          value={leadsData?.quantity}
                        />
                      </Col>

                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 8 }}
                        md={{ span: 12 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                        style={{ marginBottom: "0.5vw" }}
                      >
                        <b style={{ marginBottom: "10px" }}>Ticket Number:</b>
                        <Input
                          className="form-input"
                          placeholder="Enter truck rate"
                          value={leadsData?.ticketNumber}
                        />
                      </Col>

                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 8 }}
                        md={{ span: 12 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                        style={{ marginBottom: "0.5vw" }}
                      >
                        <b style={{ marginBottom: "10px" }}>Ticket Status:</b>
                        <Input
                          className="form-input"
                          placeholder="Enter truck rate"
                          value={leadsData?.ticketVerification}
                        />
                      </Col>
                    </Row>
                  </>
                )}

                <Col
                  span={24}
                  style={{ marginBottom: "0.5vw", marginTop: "0.5vw" }}
                >
                  <span className="title_changes">Manager Review Notes:</span>

                  <TextArea
                    value={leadsData?.managerNotes}
                    bordered={false}
                    rows={4}
                    style={{
                      width: "100%",
                      height: "75px",
                      borderColor: "#d6d6d6",
                      fontSize: "18px",
                      fontWeight: "500",
                      background: "#f0f0f0",
                      color: "#000",
                    }}
                    className="form-input"
                    placeholder="Please Enter Review Notes"
                    readOnly
                  />
                </Col>

                {leadsData !== "" && (
                  <>
                    <div style={{ marginTop: "10px" }}>
                      <Row>
                        <Col
                          xxl={{ span: 24 }}
                          xl={{ span: 24 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                          style={{
                            backgroundColor: "#586370",
                            color: "#fff",
                            fontWeight: "bold",
                            margin: "10px 0px",
                            borderRadius: "3px",
                          }}
                        >
                          <span
                            style={{ padding: "5px 0px", display: "flex" }}
                            className="title_changes_name"
                          >
                            TICKET UPLOAD DETAILS
                          </span>
                        </Col>
                      </Row>

                      <Row gutter={[12, 0]} className="tiprotitle">
                        <Col
                          xxl={{ span: 8 }}
                          xl={{ span: 8 }}
                          lg={{ span: 8 }}
                          md={{ span: 8 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                          style={{ marginBottom: "0.5vw" }}
                        >
                          <b style={{ marginBottom: "5px" }}>Customer:</b>
                          <Input
                            className="form-input"
                            placeholder="Enter customer name"
                            value={leadsData?.companyName}
                            readOnly
                            style={{
                              background: "#f0f0f0",
                              color: "#000",
                            }}
                          />
                        </Col>

                        <Col
                          xxl={{ span: 8 }}
                          xl={{ span: 8 }}
                          lg={{ span: 8 }}
                          md={{ span: 8 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                          style={{ marginBottom: "0.5vw" }}
                        >
                          <b style={{ marginBottom: "10px" }}>Job Name:</b>{" "}
                          <Input
                            className="form-input"
                            placeholder="Enter job name"
                            value={leadsData?.jobName}
                            readOnly
                            style={{
                              background: "#f0f0f0",
                              color: "#000",
                            }}
                          />
                        </Col>
                        <Col
                          xxl={{ span: 8 }}
                          xl={{ span: 8 }}
                          lg={{ span: 8 }}
                          md={{ span: 8 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                          style={{ marginBottom: "0.5vw" }}
                        >
                          <b style={{ marginBottom: "10px" }}>Order Name:</b>
                          {leadsData?.jobType === "Trucking & Material Job" ? (
                            <Input
                              className="form-input"
                              placeholder="Please enter ticket number"
                              value={leadsData?.material[0]?.vendor}
                              readOnly
                              style={{
                                background: "#f0f0f0",
                                color: "#000",
                              }}
                            />
                          ) : leadsData?.jobType === "Trucking Job" ? (
                            <Input
                              className="form-input"
                              placeholder="Please enter ticket number"
                              value={leadsData?.material[0]?.pickUpAddress}
                              readOnly
                              style={{
                                background: "#f0f0f0",
                                color: "#000",
                              }}
                            />
                          ) : null}
                        </Col>
                        <Col
                          xxl={{ span: 24 }}
                          xl={{ span: 24 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <b style={{ marginBottom: "10px" }}>
                            Approved Trucks:
                          </b>
                          <Input
                            className="form-input"
                            placeholder="Enter approved trucks"
                            value={allTruckNumbers.join(", ")}
                            readOnly
                            style={{
                              background: "#f0f0f0",
                              color: "#000",
                            }}
                          />
                        </Col>
                      </Row>
                    </div>

                    {leadsData?.jobType === "Trucking & Material Job" && (
                      <div style={{ marginTop: "10px" }}>
                        <Row>
                          <Col
                            xxl={{ span: 24 }}
                            xl={{ span: 24 }}
                            lg={{ span: 24 }}
                            md={{ span: 24 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                            style={{
                              backgroundColor: "#586370",
                              color: "#fff",
                              fontWeight: "bold",
                              margin: "10px 0px",
                              borderRadius: "3px",
                            }}
                          >
                            <span
                              style={{ padding: "5px 0px", display: "flex" }}
                              className="title_changes_name"
                            >
                              TRUCKING & MATERIAL JOB INFORMATION
                            </span>
                          </Col>
                        </Row>
                        <Row gutter={[12, 0]} className="tiprotitle">
                          <Col
                            xxl={{ span: 12 }}
                            xl={{ span: 12 }}
                            lg={{ span: 12 }}
                            md={{ span: 12 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                            style={{ marginBottom: "0.5vw" }}
                          >
                            <b style={{ marginBottom: "10px" }}>Quarry Name:</b>{" "}
                            <Input
                              className="form-input"
                              placeholder="Enter quarry name"
                              value={leadsData?.material[0]?.vendor}
                              readOnly
                              style={{
                                background: "#f0f0f0",
                                color: "#000",
                              }}
                            />
                          </Col>

                          <Col
                            xxl={{ span: 12 }}
                            xl={{ span: 12 }}
                            lg={{ span: 12 }}
                            md={{ span: 12 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <b style={{ marginBottom: "10px" }}>Materials:</b>
                            <div>
                              <Input
                                className="form-input"
                                placeholder="Enter materials"
                                value={leadsData?.material[0].label}
                                readOnly
                                style={{
                                  background: "#f0f0f0",
                                  color: "#000",
                                }}
                              />
                              {leadsData?.material?.length > 1 && (
                                <Popover
                                  content={leadsData?.material?.map(
                                    (e, i) =>
                                      i !== 0 && (
                                        <p style={{ marginBottom: "1px" }}>
                                          <span
                                            className="title_changes"
                                            style={{ fontSize: "14px" }}
                                          >
                                            {e.label}
                                          </span>
                                        </p>
                                      )
                                  )}
                                >
                                  <Tag
                                    style={{
                                      height: "24px",
                                      marginLeft: "10px",
                                      backgroundColor: "#586370",
                                      color: "#FFF",
                                      borderRadius: "50px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    +{leadsData?.material?.length - 1}
                                  </Tag>
                                </Popover>
                              )}
                            </div>
                          </Col>

                          <Col
                            xxl={{ span: 12 }}
                            xl={{ span: 12 }}
                            lg={{ span: 12 }}
                            md={{ span: 12 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                            style={{ marginBottom: "0.5vw" }}
                          >
                            <b style={{ marginBottom: "10px" }}>Pickup:</b>{" "}
                            <Input
                              className="form-input"
                              placeholder="Enter pickup address"
                              value={leadsData?.material[0]?.pickUpAddress}
                              readOnly
                              style={{
                                background: "#f0f0f0",
                                color: "#000",
                              }}
                            />
                          </Col>

                          <Col
                            xxl={{ span: 6 }}
                            xl={{ span: 6 }}
                            lg={{ span: 6 }}
                            md={{ span: 6 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                            style={{ marginBottom: "0.5vw" }}
                          >
                            <b style={{ marginBottom: "10px" }}>Truck Rate:</b>{" "}
                            <Input
                              className="form-input"
                              placeholder="Enter truck rate"
                              value={`$ ${leadsData?.material[0]?.truckingPrice.toFixed(
                                2
                              )}`}
                              readOnly
                              style={{
                                background: "#f0f0f0",
                                color: "#000",
                              }}
                            />
                          </Col>

                          <Col
                            xxl={{ span: 6 }}
                            xl={{ span: 6 }}
                            lg={{ span: 6 }}
                            md={{ span: 6 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <b style={{ marginBottom: "10px" }}>
                              Material Rate:
                            </b>{" "}
                            <Input
                              className="form-input"
                              placeholder="Enter material rate"
                              value={`$ ${leadsData?.material[0]?.totalPrice.toFixed(
                                2
                              )}`}
                              readOnly
                              style={{
                                background: "#f0f0f0",
                                color: "#000",
                              }}
                            />
                          </Col>

                          <Col
                            xxl={{ span: 12 }}
                            xl={{ span: 12 }}
                            lg={{ span: 12 }}
                            md={{ span: 12 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                            style={{ marginBottom: "0.5vw" }}
                          >
                            <b style={{ marginBottom: "10px" }}>Drop-Off:</b>{" "}
                            <Input
                              className="form-input"
                              placeholder="Enter pickup address"
                              value={leadsData?.dropoffAddress}
                              readOnly
                              style={{
                                background: "#f0f0f0",
                                color: "#000",
                              }}
                            />
                          </Col>

                          <Col
                            xxl={{ span: 12 }}
                            xl={{ span: 12 }}
                            lg={{ span: 12 }}
                            md={{ span: 12 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <b style={{ marginBottom: "10px" }}>Truck Types:</b>
                            <Input
                              className="form-input"
                              placeholder="Enter Truck Types"
                              value={trucksTypesData
                                ?.filter((e) =>
                                  allTruckTypes?.includes(e.value)
                                )
                                .map((e) => e.truck_code)
                                ?.join(", ")}
                              readOnly
                              style={{
                                background: "#f0f0f0",
                                color: "#000",
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    )}
                    {leadsData?.jobType === "Trucking Job" && (
                      <div style={{ marginTop: "10px" }}>
                        <Row>
                          <Col
                            xxl={{ span: 24 }}
                            xl={{ span: 24 }}
                            lg={{ span: 24 }}
                            md={{ span: 24 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                            style={{
                              backgroundColor: "#586370",
                              color: "#fff",
                              fontWeight: "bold",
                              margin: "10px 0px",
                              borderRadius: "3px",
                            }}
                          >
                            <span
                              style={{ padding: "5px 0px", display: "flex" }}
                              className="title_changes_name"
                            >
                              TRUCKING JOB INFORMATION
                            </span>
                          </Col>
                        </Row>
                        <Row gutter={[12, 0]} className="tiprotitle">
                          <Col
                            xxl={{ span: 12 }}
                            xl={{ span: 12 }}
                            lg={{ span: 12 }}
                            md={{ span: 12 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                            style={{ marginBottom: "0.5vw" }}
                          >
                            <b style={{ marginBottom: "10px" }}>Pickup:</b>{" "}
                            <Input
                              className="form-input"
                              placeholder="Enter pickup address"
                              value={leadsData?.material[0]?.pickUpAddress}
                              readOnly
                              style={{
                                background: "#f0f0f0",
                                color: "#000",
                              }}
                            />
                          </Col>

                          <Col
                            xxl={{ span: 12 }}
                            xl={{ span: 12 }}
                            lg={{ span: 12 }}
                            md={{ span: 12 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                            style={{ marginBottom: "0.5vw" }}
                          >
                            <b style={{ marginBottom: "10px" }}>Truck Rate:</b>{" "}
                            <Input
                              className="form-input"
                              placeholder="Enter truck rate"
                              value={`$ ${leadsData?.material[0]?.truckingPrice.toFixed(
                                2
                              )}`}
                              readOnly
                              style={{
                                background: "#f0f0f0",
                                color: "#000",
                              }}
                            />
                          </Col>

                          <Col
                            xxl={{ span: 12 }}
                            xl={{ span: 12 }}
                            lg={{ span: 12 }}
                            md={{ span: 12 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                            style={{ marginBottom: "0.5vw" }}
                          >
                            <b style={{ marginBottom: "10px" }}>Drop-Off:</b>{" "}
                            <Input
                              className="form-input"
                              placeholder="Enter pickup address"
                              value={leadsData?.dropoffAddress}
                              readOnly
                              style={{
                                background: "#f0f0f0",
                                color: "#000",
                              }}
                            />
                          </Col>

                          <Col
                            xxl={{ span: 12 }}
                            xl={{ span: 12 }}
                            lg={{ span: 12 }}
                            md={{ span: 12 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <b style={{ marginBottom: "10px" }}>Truck Types:</b>
                            <Input
                              className="form-input"
                              placeholder="Enter approved trucks"
                              value={trucksTypesData
                                ?.filter((e) =>
                                  allTruckTypes?.includes(e.value)
                                )
                                .map((e) => e.truck_code)
                                ?.join(", ")}
                              readOnly
                              style={{
                                background: "#f0f0f0",
                                color: "#000",
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    )}
                  </>
                )}
              </div>
            </Form>
          </BaseModal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) =>
  // console.log(state),
  ({
    totalResult: state?.reducer?.ticketsResult,
    loading: state?.reducer?.ticketsResult?.loading,
    errorLoading: state?.reducer?.ticketsResult?.error,
    dispatchResult: state?.reducer?.dispatchResult,
    generateInvoiceInfo: state?.reducer?.generateInvoiceInfo,
    ticketsInfo: state?.reducer?.ticketsInfo,
    dropDownOptions: state.reducer?.dropDownOptions,
    trucksTypesData: state.reducer?.dropDownOptions?.totalData?.filter(
      (t) => t.typeofDropDown === "trucksType"
    ),
  });

const mapDispatchToProps = (dispatch) => ({
  actionFetchGetTickets: () => {
    dispatch(getTickets());
  },
  actionFetchGetDispatch: () => {
    dispatch(getDispatch());
  },
  actionFetchGenerateInvoice: (data) => {
    dispatch(generateInvoice(data));
  },
  actionFetchAddTickets: (data) => {
    dispatch(addTicket(data));
  },
  actionsFetchDeleteTicket: (id) => {
    dispatch(deleteTicket(id));
  },
  actiongetDropDownOptions: () => {
    dispatch(getDropDownOptions());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAppContext(withRouter(ManagerReview)));
