import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Storage } from "aws-amplify";
import { Card, Row, Col, message, Button, Popconfirm, Checkbox } from "antd";
import { withAppContext } from "../../Store/AppContext";
import Loading from "../../Common/Loading";

import { DownloadOutlined, DeleteFilled } from "@ant-design/icons";
import { IoFilterSharp } from "react-icons/io5";
import { BsArrowLeftShort } from "react-icons/bs";
import Filter from "../../Common/Filter";
import {
  getDropDownOptions,
  getQuarry,
  getQuarryLocations,
  updateQuarry,
} from "../../Redux/Actions/actions";
import { add } from "../../api/Apis";
// import { saveAs } from "file-saver";
// import JSZip from "jszip";

class ImageDatabase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      filterVisible: false,

      imageDatabase: [],
      checkData: [],
      quarryData: [],
      quarryMaterialData: [],
      downloadList: [],

      materialCategory: {},
      singleQuarryData: {},

      quarryId: "",
    };
  }

  componentDidMount() {
    const { actionFetchQuarries, actiongetDropDownOptions } = this.props;
    this.setState({
      loading: true,
    });
    this.getAllImages();
    actiongetDropDownOptions();
    actionFetchQuarries();
  }

  componentDidUpdate = (prevProps) => {
    if (
      prevProps?.dropDownOptions?.success === false &&
      this.props.dropDownOptions?.success === true
    ) {
      let materialData = {};
      let materialsNewList = this.props.materials[0]?.materialsNewList;
      let index = 0;
      let sortedMaterialList = materialsNewList
        ?.filter((v) => v.statusCode)
        .sort((a, b) => a?.indexVal - b?.indexVal);
      sortedMaterialList?.map((eachMat) => {
        let subTypes = [];
        if (eachMat?.statusCode) {
          index += 1;
          materialData[eachMat.category] = !Object.keys(materialData).includes(
            eachMat.category
          )
            ? []
            : materialData[eachMat.category];
          let typeCheck = materialData[eachMat.category].filter(
            (o) => o.subcat === eachMat.materialValue.split(" - ")[1]
          );

          if (typeCheck.length > 0) {
            subTypes = typeCheck[0]["subTypes"];
            subTypes.push({
              subType: eachMat.materialValue.split(" - ")[2],
              id: eachMat.id,
            });
          } else {
            subTypes.push({
              subType: eachMat.materialValue.split(" - ")[2],
              id: eachMat.id,
            });
            materialData[eachMat.category].push({
              subcat: eachMat.materialValue.split(" - ")[1],
              cate: eachMat.materialValue.split(" - ")[0],
              categoryKey: eachMat.categoryKey,
              subShortName: eachMat.subShortName,
              subTypes,
            });
          }
          if (
            sortedMaterialList?.filter((e) => e.statusCode)?.length === index
          ) {
            this.setState({
              materialCategory: materialData,
            });
          }
        }
        return true;
      });
    }

    if (
      prevProps?.totalData?.success === false &&
      this.props?.totalData?.success === true
    ) {
      this.setState({
        quarryData: this.props.totalData?.quarryData,
      });
    }

    if (
      prevProps?.singleQuarryResult?.success === false &&
      this.props?.singleQuarryResult?.success === true
    ) {
      const { quarryId, materialId } = this.state;
      const quarryMaterialData =
        this.props?.singleQuarryResult?.quarry?.material;

      let filterMaterialData = quarryMaterialData.map((material) => {
        material.materialPicture = material.materialPicture.filter(
          (pic) => pic.id !== materialId
        );
        return material;
      });

      let data = {
        entityData: {
          material: filterMaterialData,
        },
      };
      message.success("Material Picture Deleted Successfully!");
      this.props.actionUpdateQuarry(quarryId, data);
    }

    if (
      prevProps?.updateQuarriesInfo?.success === false &&
      this.props?.updateQuarriesInfo?.success === true
    ) {
      this.getAllImages();
    }
  };

  onClearFilter = () => {
    this.setState({
      loading: true,
      filterVisible: false,
      checkData: [],
    });
    this.getAllImages();
  };

  closeFilter = () => {
    this.setState({
      filterVisible: false,
    });
  };

  removePicture = async (record) => {
    this.setState({
      loading: true,
    });
    await Storage.remove(record.key);
    let splitData = record.key.split("/");
    this.setState({
      materialId: splitData[2],
      quarryId: splitData[1],
    });
    this.props.actionGetQuarries(splitData[1]);
  };

  selectDownlaod = (event, record) => {
    const { downloadList } = this.state;
    let data = downloadList?.length > 0 ? downloadList : [];
    if (event.target.checked) {
      data.push(record);
    } else {
      data.splice((e) => data.findIndex(e.key === record.key), 1);
    }
    this.setState({
      downloadList: data,
    });
  };

  fileDownloadQuote = async (fileName, type) => {
    this.setState({
      loading: true,
    });
    Storage.get(fileName, { download: true })
      .then((res) => this.downloadBlobQuote(res.Body, "Material_Picture"))
      .catch((err) => console.log(err));
  };

  downloadImages = async (fileName) => {
    this.setState({
      loading: true,
    });
    fileName?.map((e) =>
      Storage.get(e.key, { download: true })
        .then((res) => this.downloadBlobQuote(res.Body, "Material_Picture"))
        .catch((err) => console.log(err))
    );
  };

  downloadBlobQuote(blob, fileName) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.download = fileName || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    message.success("Downloaded successfully");
    this.setState({
      loading: false,
    });
    return a;
  }

  getAllImages = () => {
    // await Storage.remove('Quarries');
    Storage.list("Quarries/").then((res) => {
      this.setState({
        imageDatabase: res,
        loading: false,
      });
    });
  };

  filterResultData = (data) => {
    console.log(data, "data");
    this.setState({
      loading: true,
    });
    let mergedList = [];
    if (data["materialId"]) {
      mergedList = data["materialId"];
    } else {
      mergedList = data["subCategoryId"]?.flatMap((item) =>
        item.key.split(",")
      );
    }

    let submitData = {
      entityData: {
        diameter: data["diameter"] ? data["diameter"] : false,
        quarryId: data["quarryId"] ? data["quarryId"] : undefined,
        quarryType:
          data["quarryType"]?.length > 0 ? data["quarryType"] : undefined,
        // origin: data["origin"],
        // quarryAddress: data["quarryAddress"] ? data["quarryAddress"] : "",
        // radius: data["radius"] ? data["radius"] : "",
        materialId: mergedList?.length > 0 ? mergedList : undefined,
        materialDiameter: data["materialDiameter"]
          ? data["materialDiameter"]
          : undefined,
        materialMaxDiameter: data["materialMaxDiameter"]
          ? data["materialMaxDiameter"]
          : undefined,
        materialMinDiameter: data["materialMinDiameter"]
          ? data["materialMinDiameter"]
          : undefined,
        materialStatus:
          data["materialStatus"]?.length > 0
            ? data["materialStatus"]
            : undefined,
        materialSupply:
          data["materialSupply"]?.length > 0
            ? data["materialSupply"]
            : undefined,
        materialTest:
          data["materialTest"]?.length > 0 ? data["materialTest"] : undefined,
      },
    };

    Object.keys(submitData["entityData"]).forEach(
      (key) =>
        submitData["entityData"][key] === undefined &&
        delete submitData["entityData"][key]
    );

    add("getQuarryImages", submitData)
      .then((result) => {
        console.log(result, "result");
        this.setState({
          imageDatabase: result.data,
          loading: false,
          checkData: data,
        });
        this.closeFilter();
      })
      .catch((err) => console.log(err));
  };

  onClickReturn = () => {
    this.props.history.goBack();
  };

  // downloadZip = async () => {
  //   const { imageDatabase } = this.state;
  //   const zip = new JSZip();
  //   // Create an array of promises to fetch and add images to the zip file
  //   const promises = imageDatabase.map((image) => {
  //     return Storage.get(image?.key, {
  //       download: true,
  //     })
  //       .then((response) => response.Body)
  //       .then((blob) => {
  //         zip.file(`${image.fileName}`, blob, { binary: true });
  //       })
  //       .catch((error) => console.error("Error downloading image:", error));
  //   });

  //   // Wait for all promises to resolve
  //   await Promise.all(promises);

  //   // Generate the zip file
  //   const content = await zip.generateAsync({ type: "blob" });
  //   // Save the zip file
  //   await saveAs(content, "images.zip");
  // };

  render() {
    const {
      quarryData,
      loading,
      imageDatabase,
      checkData,
      filterVisible,
      materialCategory,
      downloadList,
    } = this.state;

    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    return (
      <div>
        <Loading enableLoading={loading} />
        <Row style={{ marginBottom: "1vw" }}>
          <Col span={12} className="image_dispalyFilter">
            <Row
              style={{
                backgroundColor: "#fafafa",
                width: "100%",
                cursor: "pointer",
              }}
              className="image_dispalyFilter"
              onClick={this.onClickReturn}
            >
              <BsArrowLeftShort
                style={{ color: "#808080", fontSize: "23px" }}
                className="back-button-return-icon"
              />
              <p
                style={{
                  color: "#5f6c74",
                  fontSize: "16px",
                  fontWeight: "600",
                  marginBottom: "0px",
                }}
                className="back-button-return"
              >
                Return
              </p>
            </Row>
          </Col>

          <Col
            span={12}
            className="image_dispalyFilter"
            style={{ justifyContent: "end" }}
          >
            {downloadList?.length > 0 && (
              <Col className="gridbtntool">
                <Button
                  className="button_Crm"
                  style={{
                    borderRadius: "5px",
                    height: "40px",
                  }}
                  onClick={() => {
                    this.downloadImages(downloadList);
                  }}
                  icon={
                    <DownloadOutlined
                      style={{
                        fontSize: "20px",
                        marginLeft: "8px",
                        marginTop: "2px",
                      }}
                      className="trucking-filter-icon"
                    />
                  }
                >
                  <span
                    style={{
                      color: "#5a6067",
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                    className="col_styling table-font-mobile"
                  >
                    Download
                  </span>
                </Button>
              </Col>
            )}

            <Col className="gridbtntool">
              <Button
                className="button_Crm"
                style={{
                  borderRadius: "5px",
                  height: "40px",
                }}
                onClick={() => {
                  this.setState({ filterVisible: true });
                }}
              >
                <Row>
                  <Col>
                    <span
                      style={{
                        color: "#5a6067",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                      className="col_styling table-font-mobile"
                    >
                      Filter
                    </span>
                  </Col>
                  <Col>
                    <IoFilterSharp
                      style={{
                        fontSize: "22px",
                        marginLeft: "8px",
                        marginTop: "2px",
                      }}
                      className="trucking-filter-icon"
                    />
                  </Col>
                </Row>
              </Button>
            </Col>
          </Col>
        </Row>
        <Card style={{ minHeight: "80vh" }}>
          <Row gutter={[24, 0]}>
            {imageDatabase?.map((i) => (
              <Col
                xxl={{ span: 6 }}
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                md={{ span: 12 }}
                sm={{ span: 12 }}
                xs={{ span: 24 }}
                style={{ height: "200px", marginBottom: "1vw", width: "100%" }}
              >
                <div className="deleteImageBtn">
                  <Checkbox
                    style={{
                      background: "#586370",
                      fontSize: "14px",
                      padding: "2px 5px",
                    }}
                    onChange={(e) => this.selectDownlaod(e, i)}
                  />
                  {userDetails?.userProfile === "Admin" && (
                    <Popconfirm
                      title={
                        <span>{`Are you sure you want to remove ？`}</span>
                      }
                      okText={
                        <span style={{ width: "60px", height: "30px" }}>
                          Yes
                        </span>
                      }
                      cancelText={
                        <span style={{ width: "60px", height: "30px" }}>
                          No
                        </span>
                      }
                      onConfirm={() => this.removePicture(i)}
                    >
                      <DeleteFilled
                        style={{
                          color: "#FFF",
                          background: "red",
                          padding: "5px",
                          fontSize: "16px",
                        }}
                      />
                    </Popconfirm>
                  )}
                </div>

                <div className="container">
                  <img
                    className="image"
                    src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.us-east-1.amazonaws.com/public/${i.key}`}
                    alt=""
                    style={{
                      height: "100%",
                      width: "100%",
                      cursor: "pointer",
                    }}
                  />

                  <div
                    className="middle"
                    onClick={() =>
                      this.fileDownloadQuote(
                        i.key,
                        "materialPicture",
                        "docAttachment"
                      )
                    }
                  >
                    <div className="downloadIcon">
                      <DownloadOutlined
                        style={{
                          fontSize: "30px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Card>

        {filterVisible && (
          <Filter
            visible={filterVisible}
            closeFilter={this.closeFilter}
            filterResultData={this.filterResultData}
            filterPropQuarryData={checkData}
            onClearFilter={this.onClearFilter}
            imageDatabase
            materialCategory={materialCategory}
            quarryData={quarryData}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dropDownOptions: state?.reducer?.dropDownOptions,
  materials: state?.reducer?.dropDownOptions?.totalData?.filter(
    (m) => m?.typeofDropDown === "material"
  ),
  totalData: state?.reducer?.quarriesResult,
  singleQuarryResult: state?.reducer?.quarryResult,
  updateQuarriesInfo: state?.reducer?.quarriesInfo,
});

const mapDispatchToProps = (dispatch) => ({
  actiongetDropDownOptions: () => {
    dispatch(getDropDownOptions());
  },
  actionFetchQuarries: () => {
    dispatch(getQuarryLocations());
  },
  actionGetQuarries: (id) => {
    dispatch(getQuarry(id));
  },
  actionUpdateQuarry: (id, data) => {
    dispatch(updateQuarry(id, data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAppContext(withRouter(ImageDatabase)));
