import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Button,
  Tabs,
  Form,
  Input,
  DatePicker,
  Upload,
  Spin,
  Select,
  message,
  Popover,
  Popconfirm,
  InputNumber,
  Checkbox,
  Tooltip,
  Radio,
  Switch,
  Divider,
} from "antd";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import {
  FilePdfOutlined,
  PlusOutlined,
  CameraOutlined,
  EditOutlined,
  EllipsisOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { GrDocumentText } from "react-icons/gr";
import { SiGooglesheets } from "react-icons/si";
import { Storage } from "aws-amplify";
import NumberFormat from "react-number-format";
import { MdModeEditOutline, MdDelete } from "react-icons/md";
import { HiOutlineDocumentArrowDown } from "react-icons/hi2";
import { BsArrowLeftShort } from "react-icons/bs";
import moment from "moment";
import { add, list, update, updateTotal } from "../../../api/Apis";
import appConfig from "../../../config/AppConfig";
import Loading from "../../../Common/Loading";
import ErrorView from "../../../Common/ErrorView";
import { withAppContext } from "../../../Store/AppContext";
import BaseModal from "../../../Common/BaseModal";
import BaseTable from "../../../Common/BaseTable";
import TextArea from "antd/lib/input/TextArea";
import { v4 as uuidv4 } from "uuid";
import AOS from "aos";
import "aos/dist/aos.css";
import { connect } from "react-redux";
import {
  addDocument,
  addLead,
  deleteContactandJob,
  getCompany,
  getDropDownOptions,
  updateCompany,
  updateContacts,
  updateDropDownOptions,
  updateLead,
  getTopVendors,
  addContact,
  deleteDocument,
} from "../../../Redux/Actions/actions";
import QuarryJsonData from "../../../Json/quarryData.json";

const { TabPane } = Tabs;

const { Option } = Select;

const jobType = [
  { label: "Trucking Job", value: "Trucking Job" },
  { label: "Trucking & Material Job", value: "Trucking & Material Job" },
];

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

class CompanyDetails extends Component {
  formRef = React.createRef();
  formContact = React.createRef();
  state = {
    isModalVisible: false,
    datePicker: "",
    totalLeadsData: [],
    companyData: [],
    contactsData: [],
    invoicesDetails: [],
    quotesDetails: [],
    documentsDetails: [],
    latitude: "",
    longitude: "",
    quarryData: [],
    keyValue: "1",
    isDocModalVisible: false,
    imageLoading: false,
    quoteAttachment: "",
    dropAddress: "",
    pickUpAddress: "",
    leadVisible: false,
    quickLeadType: "Material Sales",
    selectedAddress: "",
    contactPerson: "",
    leadQuote: [],
    companyId: "",
    contactId: "",
    jobContactData: "",
    selectedTrucks: [],
    isContactModalVisible: false,
    jobData: "",
    jobInfo: "Trucking Job",
    truckDropInfo: false,
    newRole: "",
    trucksData: [],
    materialList: [],
    isCheckBoxMaterial: false,
    isCheckBox: false,
    roleCheckBox: false,
    rolesData: [],
    quoteAttachmentId: "",
    selectedTruckType: [],
    selectedMaterial: [],
    recordMaterialData: "",
    selectedMaterialType: "",
    finalMaterialData: [],
    quantity: "",
    apContactData: [],
    selectedAssignTo: "",
    activeClasses: false,
    materialPopup: false,
    quantityType: "Ton",
    truckingCostType: "Ton",
    truckingPriceType: "Ton",
    materialCostType: "Ton",
    totalPriceType: "Ton",
    dropoffLatitude: "",
    dropoffLongitude: "",
    pickUpLatitude: "",
    pickupLongitude: "",
    selectedTerm: "",
    pickupDetails: [],
    vendorData: [],
    initialMaterialCost: "",
    materialPriceState: "",
    selectVendor: "",
    materialValue: "",
    categoriesList: [],
    materialCostPrice: "",
    materialTruckPrice: 0,
    milesData: "",
    duration: "",
    vendorName: "",
    masterTrucksId: "",
    masterRoleId: "",
    notesData: [],
    notesPopup: false,
    recordNotesData: "",
    fileType: "",
    filterInvoicesData: [],
    filterLeadsData: [],
    averageDayToPay: "",
    // showSwitchtoggle: false,
    quarryContacts: [],
    materialSelectedData: {},
    topVendorsData: [],
    vendorDetails: "",
    updateJobIdContact: [],
    modallLoading: false,
    newJobContacts: [],
    jobIdState: undefined,
    customRate: false,
    materialCustomRate: false,
    diameter: "",
    materialCategory: {},
    selectedRowKeys: [],
    openDropdown: "",
    salesCommission: "",
  };

  componentDidMount() {
    this.props.actionFetchCompany(this.props.match.params.id);
    this.getQuarryLocations();
    this.props?.context?.updateCompanyId(this.props.match.params.id);
    document.title = "Twisted Nail - Company Details";
    this.props.actiongetDropDownOptions();
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
  }
  // showSwitch = (e) => {
  //   if (e === true) {
  //     this.setState({ showSwitchtoggle: true });
  //   } else {
  //     this.setState({ showSwitchtoggle: false });
  //   }
  // };

  componentDidUpdate = (newProps) => {
    if (
      newProps?.topVendorsData?.success === false &&
      this.props?.topVendorsData?.success === true
    ) {
      if (this.props?.topVendorsData.vendors?.length > 0) {
        this.setState({
          topVendorsData: this.props?.topVendorsData.vendors,
          loading: false,
        });
      } else {
        this.setState({
          topVendorsData: this.props?.topVendorsData.vendors,
          loading: false,
        });
        message.info("No vendors found!");
      }
    }
    if (newProps?.match?.params?.id !== this.props?.match?.params?.id) {
      this.props.actionFetchCompany(this.props?.match?.params?.id);
      this.props?.context?.updateCompanyId(this?.props?.match?.params?.id);
      // this.props.actiongetDropDownOptions();
    }
    if (
      newProps?.totalResult?.success === false &&
      this.props?.totalResult?.success === true
    ) {
      // console.log("company");
      let apContactData = this.props.result?.contactDetails?.filter(
        (e) => e?.contactRole && e?.contactRole === "Accounts Payable"
      );
      let newJobContacts = this.props.result?.contactDetails?.filter(
        (e) =>
          e?.contactRole &&
          (e?.contactRole === "Accounts Payable" ||
            e?.contactRole === "Accounts Receivable" ||
            e?.contactRole === "Accounting")
      );

      this.props?.result?.invoiceDetails?.forEach((element) => {
        if (element.ARinvoiceStatus) {
          let x = new moment(moment(element.ARPaymentDate).format("l"));
          let y = new moment(moment(element.createdAt).format("l"));
          let diff = x - y;
          let duration = Math.ceil(diff / (1000 * 60 * 60 * 24));
          element["paidDays"] = duration;
        }
      });

      // console.log(result.data);
      this.setState({
        quarryContacts: this.props?.result?.contactDetails?.filter(
          (e) => e.materialId
        ),
        quarryData: [this.props?.result],
        apContactData,
        newJobContacts,
        notesData: this.props?.result?.notesDetails,
        averageDayToPay: this.props?.result?.invoiceDetails
          ?.filter((e) => e.paidDays)
          ?.reduce((a, v) => (a = a + v.paidDays), 0),
        companyData: this.props?.result,
        totalLeadsData: this.props?.result?.leadDetails,
        filterLeadsData: this.props?.result?.leadDetails?.filter(
          (e) => e?.stageId !== "Close Job" && e?.stageId !== "Paused"
        ),
        contactsData: this.props?.result?.contactDetails,
        documentsDetails: this.props?.result?.documentsDetails,
        invoicesDetails: this.props?.result?.invoiceDetails,
        filterInvoicesData: this.props?.result?.invoiceDetails?.filter(
          (i) => !i?.ARinvoiceStatus && i?.companyTerms !== "Cash"
          // i?.ARinvoiceStatus !== "Paid" &&
          // i?.ARinvoiceStatus !== undefined
        ),
      });
    }
    if (
      newProps?.companiesInfo?.success === false &&
      this.props?.companiesInfo?.success === true
    ) {
      // console.log("newProps");
      this.setState({
        selectedTerm: "",
      });
      this.props.actionFetchCompany(this.props.match.params.id);
    }
    if (
      newProps?.contactsInfo?.success === false &&
      this.props?.contactsInfo?.success === true
    ) {
      const { contactsData, updateJobIdContact } = this.state;
      let data = updateJobIdContact;
      data.push(this.props.contactsInfo?.contact?.data?.id);
      let contactData = contactsData;
      contactData.push(this.props?.contactsInfo?.contact?.data);
      this.formContact?.current?.setFieldsValue({ contactperson: data });
      this.setState({
        contactsData: contactData,
        modallLoading: false,
        newRole: "",
        roleCheckBox: false,
      });

      this.props.actionFetchCompany(this.props.match.params.id);
    }

    if (
      newProps?.contactsDelete?.success === false &&
      this.props?.contactsDelete?.success === true
    ) {
      this.props.actionFetchCompany(this.props.match.params.id);
    }

    if (
      newProps?.leadsInfo?.success === false &&
      this.props?.leadsInfo?.success === true
    ) {
      this.props.actionFetchCompany(this.props.match.params.id);
    }
    if (
      newProps?.leadUpdateInfo?.success === false &&
      this.props?.leadUpdateInfo?.success === true
    ) {
      // console.log("leadUpdateInfo");
      this.setState({
        jobData: "",
        isCheckBoxMaterial: false,
        isCheckBox: false,
        truckDropInfo: false,
        selectedTruckType: [],
        selectedMaterial: [],
        recordMaterialData: [],
        materialCostType: "Ton",
        truckingCostType: "Ton",
        quantityType: "Ton",
        totalPriceType: "Ton",
        dropoffLatitude: "",
        dropoffLongitude: "",
        quarryContacts: [],
      });
      this.props.actionFetchCompany(this.props.match.params.id);
    }
    if (
      newProps?.docResult?.success === false &&
      this.props?.docResult?.success === true
    ) {
      // console.log("docResult")
      this.setState({
        quoteAttachment: "",
        selectedAssignTo: "",
        jobIdState: undefined,
      });
      this.props.actionFetchCompany(this.props.match.params.id);
    }
    if (
      newProps?.dropDownOptionsInfo?.success === false &&
      this.props?.dropDownOptionsInfo?.success === true
    ) {
      this.props.actiongetDropDownOptions();
    }
    if (
      newProps?.dropDownOptions?.success === false &&
      this.props?.dropDownOptions?.success === true
    ) {
      let materialData = {};
      this.props.materialList[0]?.materialsNewList?.forEach(
        (eachMat, index) => {
          if (eachMat?.statusCode) {
            materialData[eachMat.category] = !Object.keys(
              materialData
            ).includes(eachMat.category)
              ? []
              : materialData[eachMat.category];
          }
        }
      );
      // console.log("drop")
      this.setState({
        materialCategory: materialData,
        rolesData: this.props.rolesData[0]?.rolesData?.sort((a, b) =>
          a?.value > b?.value ? 1 : -1
        ),
        trucksData: this.props?.trucksData[0]?.trucksData,
        materialList: this.props?.materialList[0]?.materialsNewList,
      });
    }
  };

  // static getDerivedStateFromProps(props, state) {
  //   if (JSON.stringify(props?.result) !== JSON.stringify(state?.companyData)) {
  //     console.log("--------getDerivedStateFromProps------------");
  //     return {
  //       companyData: props?.result,
  //       totalLeadsData: props.result.leadDetails,
  //       filterLeadsData: props.result.leadDetails?.filter(
  //         (e) => e?.stageId !== "Close Job" && e?.stageId !== "Paused"
  //       ),
  //       contactsData: props.result.contactDetails,
  //       documentsDetails: props.result.documentsDetails,
  //       invoicesDetails: props.result.invoiceDetails,
  //       filterInvoicesData: props.result?.invoiceDetails?.filter(
  //         (i) => !i?.ARinvoiceStatus && i?.companyTerms !== "Cash"

  //         // i?.ARinvoiceStatus !== "Paid" &&
  //         // i?.ARinvoiceStatus !== undefined
  //       ),
  //     };
  //   }
  // }

  onRefresh = () => {
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    this.props.actionFetchCompany(id);
    this.props?.context?.updateCompanyId(id);
    this.props.actiongetDropDownOptions();
  };

  getAddress = (address, type) => {
    if (type === "pickup") {
      this.setState({
        selectedPickupAddress: address.label,
      });
    } else if (type === "dropOff") {
      this.setState({
        selectedDropOffAddress: address.label,
      });
    } else {
      this.setState({ selectedAddress: address.label });
    }
  };

  handleSelect = (address, type) => {
    const { dropoffLatitude, dropoffLongitude } = this.state;
    const citySearch = [];
    citySearch.push(address.label);
    this.setState({
      citySearch,
    });
    let cityValue = "";
    let stateValue = "";
    let zipCodeValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        getLatLng(results[0]).then(({ lat, lng }) => {
          if (type === "pickup") {
            this.setState({
              loading: true,
            });
            let url =
              "https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&destinations=" +
              [dropoffLatitude, dropoffLongitude] +
              "&origins=" +
              [lat, lng] +
              "&key=AIzaSyB781k9fNf61hnxE3A6cvcascwhAhIVIK4";
            let milesData = {
              entityData: {
                url,
              },
            };
            add("getMiles", milesData)
              .then((res) =>
                this.setState({
                  pickUpLatitude: lat,
                  pickupLongitude: lng,
                  milesData: res.data.distance,
                  duration: res.data.duration,
                  loading: false,
                })
              )
              .catch((err) => {
                this.setState({
                  loading: false,
                });
                console.log(err, "err");
              });
          } else if (type === "dropOff") {
            this.setState({ dropoffLatitude: lat, dropoffLongitude: lng });
          } else {
            this.setState({ latitude: lat, longitude: lng });
          }
        });
        const geoIdData = results[0];
        this.setState({
          geoIdData,
        });
        let address = results[0].address_components;
        for (let i = 0; i < address?.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
          if (address[i].types[0] === "postal_code") {
            zipCodeValue = address[i].long_name;
          }
        }
        this.setState({
          city: cityValue,
          addressState: stateValue,
          zipCode: zipCodeValue,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  handleUploadPic = async (info) => {
    this.setState({
      imageLoading: true,
      loading: true,
    });
    switch (info.file.status) {
      case "done":
        if (info.fileList.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              Storage.put(info.file.uid, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                Storage.get(result.key).then((urlResult) => {
                  this.setState({
                    quoteAttachment: urlResult,
                    imageLoading: false,
                    loading: false,
                    quoteAttachmentId: result.key,
                    fileType: info.file.type,
                  });
                });
              });
            } else {
              this.setState({
                imageLoading: false,
                loading: false,
              });
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;
      default:
    }
  };

  fileDownload = async (fileName, downloadFileName, companyName) => {
    let savedFileName = downloadFileName + "_" + companyName.replace(/ /g, "");
    this.setState({
      loading: true,
    });
    Storage.get(fileName, { download: true }).then((res) =>
      this.downloadBlob(res.Body, savedFileName)
    );
  };

  downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    // a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    this.setState({
      loading: false,
    });
    return a;
  }

  showModal = (key) => {
    if (key === "2") {
      this.setState({ isContactModalVisible: true, jobContactData: "" });
    } else if (key === "4") {
      this.setState({ isDocModalVisible: true });
    } else if (key === "1") {
      this.setState({
        leadVisible: true,
        jobData: "",
        selectedMaterial: [],
        selectedMaterialType: "",
        jobInfo: "Trucking Job",
      });
    } else {
      this.setState({ notesPopup: true, recordNotesData: "" });
    }
  };

  editModal = (record, type) => {
    if (type === "editJob") {
      this.setState(
        {
          leadVisible: true,
          jobData: record,
          jobInfo: record?.jobType,
          selectedMaterial: record?.material ? record?.material : [],
          selectedTruckType: record?.truckType,
          selectedMaterialType: "",
          dropAddress: record?.dropoff,
          selectedDropOffAddress: record?.dropoff,
          leadQuote: record?.multipleContacts,
          updateJobIdContact: record?.multipleContacts,
          dropoffLatitude: record?.dropOffLatLng?.lat,
          dropoffLongitude: record?.dropOffLatLng?.lng,
          truckingCostType: "Hour",
          totalPriceType: "Ton",
          materialCostType: "Ton",
          truckingPriceType: "Hour",
        }
        // () => this.props.context.getSalesPersons()
      );
    } else {
      this.setState({
        isContactModalVisible: true,
        jobContactData: record,
      });
    }
  };

  onFinishCompanyDetails = (values) => {
    const { companyData } = this.state;

    this.setState({
      isModalVisible: false,
    });
    const data = {
      entityData: {
        accountTerms:
          values["accountTerms"] === "Custom Input"
            ? values["accountTermsOthers"]
            : values["accountTerms"] || companyData.accountTerms,
        accountChargeLimit:
          values["accountChargeLimit"] || companyData?.accountChargeLimit,
        email: values["email"] || companyData?.email,
        phone: values["phone"] || companyData?.phone,
        address: this.state.selectedAddress || companyData?.address,
        lat: this.state.latitude || companyData?.["lat"],
        lng: this.state.longitude || companyData?.["lng"],
        notes: values["notes"] || companyData?.notes,
      },
    };
    // console.log(data);
    this.updateCompanyDetails(data);
  };

  updateCompanyDetails = (data) => {
    this.props.actionUpdateCompany(this.props?.match?.params?.id, data);
  };

  deleteContact = (data) => {
    this.props.actionDeleteContact(data?.id);
  };

  onFinishDoc = (values) => {
    const {
      selectedAssignTo,
      fileType,
      quoteAttachment,
      quoteAttachmentId,
      jobIdState,
    } = this.state;

    if (quoteAttachmentId !== "" && quoteAttachmentId !== undefined) {
      this.setState({
        isDocModalVisible: false,
      });
      let commonData = {
        date: moment(values["date"]).format("L"),
        document: values["documentName"],
        docType: values["docType"],
        // description: values["description"],
        docAttachment: quoteAttachment,
        docAttachmentId: quoteAttachmentId,
        fileType: fileType,
        assignedTo:
          jobIdState !== "" && jobIdState !== undefined
            ? jobIdState
            : "Company",
      };

      if (selectedAssignTo === "Job") {
        let lead = {
          entityData: {
            ...commonData,
            leadId: values["leads"],
          },
        };
        // console.log(lead);
        this.props.actionAddDoc(lead);
      } else {
        let company = {
          entityData: {
            ...commonData,
            companyId: selectedAssignTo,
          },
        };
        // console.log(company);
        this.props.actionAddDoc(company);
      }
    } else {
      message.info("Please Add Document To Proceed!");
    }
  };

  handleSelectContact = (e) => {
    const { contactsData, jobData } = this.state;
    if (jobData !== "") {
      contactsData?.map((contact) => {
        if (e?.includes(contact?.id)) {
          if (
            contact["jobId"]?.length > 0 &&
            !contact["jobId"]?.includes(jobData?.id)
          ) {
            contact["jobId"].push(jobData?.id);
          } else {
            contact["jobId"] =
              contact["jobId"]?.length > 0 ? contact["jobId"] : [jobData?.id];
          }
          return contact;
        } else {
          if (contact["jobId"]?.includes(jobData?.id)) {
            contact["jobId"] = contact["jobId"]?.filter(
              (ct) => ct !== jobData?.id
            );
          }
        }
        return contact;
      });
      this.setState({ contactId: e, updateJobIdContact: contactsData });
    } else {
      let data = contactsData?.filter((contact) => e?.includes(contact?.id));
      console.log(data, "contactsData");
      this.setState({ contactId: e, updateJobIdContact: data });
    }
  };

  onFinish = (values) => {
    const {
      updateJobIdContact,
      jobData,
      companyData,
      truckDropInfo,
      selectedTruckType,
      selectedMaterial,
      dropoffLatitude,
      dropoffLongitude,
      selectedDropOffAddress,
      jobInfo,
      quarryContacts,
      contactsData,
      newJobContacts,
      salesCommission,
    } = this.state;
    this.setState({
      leadVisible: false,
    });
    let commonData = {
      companyId: this.props?.match?.params?.id,
      company: companyData?.company,
      companyName: companyData?.companyName,
      contactperson: "",
      contactId: "",
      multipleContacts:
        jobData !== ""
          ? updateJobIdContact?.filter((e) => e?.jobId?.includes(jobData?.id))
          : newJobContacts?.length > 0
          ? newJobContacts
          : updateJobIdContact,
      jobType: values["jobType"],
      jobName: values["jobName"],
      dropoff: selectedDropOffAddress,
      dropOffLatLng: {
        lat: dropoffLatitude,
        lng: dropoffLongitude,
      },
      truckType:
        truckDropInfo === true ? values["truckOther"] : selectedTruckType,
      notes: values["notes"],
      durationOfJob: values["durationOfJob"],
      salesPersonId: values["salesPerson"],
      salesCommission,
    };
    console.log(commonData, "commonData");
    // console.log(commonData, "commonData");
    if (jobInfo === "Trucking Job") {
      let leadData = {
        entityData: {
          ...commonData,
          material: selectedMaterial,
        },
      };
      leadData["entityData"]["leadType"] = [
        { id: "1", value: "Material Sales" },
      ];
      if (jobData !== "") {
        leadData["entityData"]["stageId"] = jobData?.stageId;
        this.updateJob(leadData);
      } else {
        leadData["entityData"]["stageId"] = "Contacted";
        this.addLead(leadData);
      }
    } else {
      let leadData = {
        entityData: {
          ...commonData,
          material: selectedMaterial,
          taxExempt: values.taxExempt,
          taxIncluded: values.taxIncluded,
          taxStatus: values.taxIncluded === "Tax Added" ? true : false,
        },
      };
      leadData["entityData"]["leadType"] = [
        { id: "1", value: "Material Sales" },
      ];
      if (jobData !== "") {
        leadData["entityData"]["stageId"] = jobData?.stageId;

        let quarryContact = {};
        let quarryContactsData = [];

        contactsData?.filter((e) => {
          quarryContactsData = quarryContacts?.filter((q) => q.id !== e.id);
          return e;
        });
        quarryContact["entityData"] = quarryContactsData;
        this.updateMultipleContacts(
          quarryContact,
          leadData,
          quarryContactsData
        );
      } else {
        leadData["entityData"]["stageId"] = "Contacted";
        let quarryContact = {};
        let quarryContactsData = [];

        contactsData?.filter((e) => {
          quarryContactsData = quarryContacts?.filter((q) => q.id !== e.id);
          return e;
        });
        quarryContact["entityData"] = quarryContactsData;
        if (quarryContactsData?.length > 0) {
          leadData["entityData"]["multipleContacts"] =
            quarryContactsData.concat(
              leadData["entityData"]["multipleContacts"]
            );
          this.addLead(leadData);
        } else {
          this.addLead(leadData);
        }
      }
    }

    if (this.state.isCheckBox) {
      this.props.context.updateTrucksType(values["truckOther"], "trucksType");
    }
  };

  updateMultipleContacts = (quarryContact, leadData, quarryContactsData) => {
    const { updateJobIdContact, leadQuote } = this.state;
    if (
      JSON.stringify(updateJobIdContact) !== JSON.stringify(leadQuote) &&
      quarryContactsData?.length > 0
    ) {
      quarryContact["entityData"] =
        quarryContactsData.concat(updateJobIdContact);
      leadData["entityData"]["multipleContacts"] = quarryContact["entityData"];
      this.updateMultipleContact(quarryContact, leadData);
    } else if (
      JSON.stringify(updateJobIdContact) !== JSON.stringify(leadQuote)
    ) {
      let updateData = {
        entityData: updateJobIdContact,
      };
      this.updateMultipleContact(updateData, leadData);
    } else if (quarryContactsData?.length > 0) {
      leadData["entityData"]["multipleContacts"] =
        quarryContactsData.concat(updateJobIdContact);
      this.addMultipleContact(quarryContact, leadData);
    } else {
      this.setState({
        isModalVisible: false,
      });
      this.updateJob(leadData);
    }
  };

  updateMultipleContact = (quarryContact, leadData) => {
    updateTotal("updateMultipleContacts", quarryContact)
      .then((result) => {
        if (result.success) {
          this.setState({
            isModalVisible: false,
          });
          this.updateJob(leadData);
        }
      })
      .catch((err) => console.log(err));
  };

  addMultipleContact = (quarryContact, leadData) => {
    add("addMultipleContacts", quarryContact)
      .then((result) => {
        if (result.success) {
          // console.log(result);
          this.setState({
            isModalVisible: false,
          });
          this.updateJob(leadData);
        }
      })
      .catch((err) => console.log(err));
  };

  updateJobMaterials = (data) => {
    this.setState({ loading: true });
    const { jobData } = this.state;
    let materialsData = jobData?.material;
    // console.log(materialsData, data);
    materialsData?.map((m) => {
      data?.map((d) => {
        if (d?.materialId === m?.id) {
          m["distance"] = d?.distance;
          m["duration"] = d?.duration;
        }
        return true;
      });
      return true;
    });
    // console.log(materialsData);
    let finalData = {
      entityData: {
        material: materialsData,
      },
    };
    this.updateJob(finalData);
  };

  updateJob = (data) => {
    const { jobData } = this.state;
    this.props.actionUpdateJob(jobData?.id, data);
  };

  onChangeTrucksType = (values) => {
    // console.log("checked", values);
    this.setState({ selectedTruckType: values });
  };

  onFinishContact = (values) => {
    const { jobContactData, jobData } = this.state;
    if (this.state.roleCheckBox) {
      this.updateRolesData(values["roleOther"]);
    }
    this.setState({ isContactModalVisible: false });
    let contactsData = {
      entityData: {
        companyId: this.props?.match?.params?.id,
        firstName: values.fName,
        lastName: values.lName,
        phone: values.phone,
        email: values.email,
        jobId: jobData ? [jobData?.id] : [],
        contactRole:
          values["role"] === "Add New Role"
            ? values["roleOther"]
            : values["role"],
        notes: values.notes,
      },
    };

    if (jobContactData !== "") {
      contactsData["entityData"]["jobId"] = jobContactData?.jobId;
      this.updateContact(contactsData);
    } else {
      this.addContact(contactsData);
    }
  };

  addContact = (data) => {
    this.setState({
      newRole: "",
      roleCheckBox: false,
      modallLoading: true,
    });
    this.props.actionAddContact(data);
    // console.log("actionAddContact");
  };

  updateContact = (data) => {
    this.props.actionUpdateContact(this.state.jobContactData?.id, data);
    this.setState({
      newRole: "",
      roleCheckBox: false,
    });
  };

  updateRolesData = (values) => {
    let rolesData = this.state.rolesData;
    rolesData.push({
      label: values,
      value: values.charAt(0).toUpperCase(),
      id: rolesData?.length + 1,
    });
    let data = {
      entityData: {
        rolesData,
        typeofDropDown: "roles",
      },
    };
    this.props.actionupdateDropDownOptions(this.props.rolesData[0]?.id, data);
  };

  addContactToJob = (newContact) => {
    this.setState({ selectedContacts: [] });
    const { jobData } = this.state;
    let existContactData = jobData?.multipleContacts;
    existContactData?.push(newContact);
    let data = {
      entityData: {
        multipleContacts: existContactData,
      },
    };
    // console.log(data, "data");
    update(appConfig["entityNames"]["updateLead"], jobData?.id, data)
      .then((result) => {
        // console.log(result);
        if (result.success) {
          message.info("Contact Added Successfully To Job");
          const { match } = this.props;
          const { params } = match;
          const { id } = params;
          this.getCompanyDetails(id);
          this.setState({
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  };

  addLead = (data) => {
    this.props.actionAddJob(data);
  };

  addActiveClass = (e) => {
    const activeClasses = [
      ...this.state.activeClasses.slice(0, e),
      !this.state.activeClasses[e],
      this.state.activeClasses.slice(e + 1),
    ].flat();
    this.setState({ activeClasses });
  };

  handleTrucksData = (value, option, index) => {
    // console.log(value, option, index);

    let selectedTrucks =
      this.state.selectedTrucks?.length > 0
        ? this.state.selectedTrucks.filter((o) => o.truckType === option.value)
        : [];
    if (selectedTrucks?.length === 0) {
      selectedTrucks = this.state.selectedTrucks.filter(
        (dr) => dr.dropdownKey !== index
      );
      selectedTrucks.push({
        truckType: option.value,
        id: option.key,
        dropdownKey: index,
        trucks: "",
      });
    } else {
      selectedTrucks = this.state.selectedTrucks;
    }
    this.setState({
      selectedTrucks,
    });
  };

  handleNoOfTrucks = (trucks, index) => {
    let selectedTrucks = this.state.selectedTrucks;

    selectedTrucks.map((tr) => {
      if (tr.dropdownKey === index) {
        tr["trucks"] = trucks;
      }
      return tr;
    });
    this.setState({
      selectedTrucks,
    });
  };

  handleToRemoveTruckBase = (index) => {
    // console.log(index);
    let selectedTrucks = this.state.selectedTrucks.filter(
      (removeTr) => removeTr.dropdownKey !== index
    );

    selectedTrucks.map((eachTr) => {
      if (parseInt(eachTr.dropdownKey) > index) {
        eachTr["dropdownKey"] = parseInt(eachTr.dropdownKey) - index;
      }
      if (index === 0 && parseInt(eachTr.dropdownKey) !== index) {
        if (parseInt(eachTr.dropdownKey) === 1) {
          eachTr["dropdownKey"] = index;
        } else {
          eachTr["dropdownKey"] = parseInt(eachTr.dropdownKey) - 1;
        }
      }
      return eachTr;
    });

    this.setState({ selectedTrucks });
  };

  onChangeCheckBox = (e) => {
    this.setState({ isCheckBox: e.target.checked });
  };

  onFinishMaterial = (values) => {
    const {
      recordMaterialData,
      materialSelectedData,
      quantity,
      quantityType,
      jobInfo,
      pickUpLatitude,
      pickupLongitude,
      truckingPriceType,
      selectedPickupAddress,
      truckingCostType,
      materialCostType,
      totalPriceType,
      // pickupDetails,
      initialMaterialCost,
      duration,
      milesData,
      // materialCostPrice,
      // companyData,
      // jobData,
      vendorDetails,
      topVendorsData,
      materialTruckPrice,
      truckingCost,
      materialPriceState,
      diameter,
      selectedMaterial,
    } = this.state;
    // let materialData = this.state?.selectedMaterial?.filter(
    //   (m) => m?.id === materialSelectedData?.id
    // );
    // console.log(materialData, "material");
    if (jobInfo === "Trucking Job") {
      let truckingData = {
        entityData: {
          id:
            materialSelectedData["id"] === undefined
              ? uuidv4()
              : materialSelectedData["id"],
          truckingCost:
            parseFloat(values["truckingCost"]) ||
            recordMaterialData?.truckingCost,
          truckingCostType:
            truckingCostType || recordMaterialData?.truckingCostType,
          truckingPrice:
            parseFloat(values["truckingPrice"]) ||
            recordMaterialData?.truckingPrice,
          truckingPriceType:
            truckingPriceType || recordMaterialData?.truckingPriceType,
          pickUpAddress:
            selectedPickupAddress || recordMaterialData?.pickUpAddress,
          pickUpLatLng: {
            lat: pickUpLatitude || recordMaterialData?.pickUpLatLng?.lat,
            lng: pickupLongitude || recordMaterialData?.pickUpLatLng?.lng,
          },
          duration: duration || recordMaterialData?.duration,
          milesData: milesData || recordMaterialData?.milesData,
        },
      };
      // console.log(truckingData, "truckingData");
      if (
        truckingData.entityData.pickUpAddress !== undefined &&
        truckingData.entityData.pickUpAddress !== ""
      ) {
        this.addMaterialOrTruckInfo(truckingData);
      } else {
        message.error("Please Enter PickUp Location");
      }
    } else {
      if (truckingPriceType === totalPriceType) {
        // if (materialData?.length > 0) {
        //   message.info("Given Material Is Already Selected");
        //   return true;
        // } else {
        if (topVendorsData?.length === 0) {
          message.error("Please Find Nearest Vendors!");
          return true;
        }
        if (topVendorsData?.length !== 0 && vendorDetails === "") {
          message.error("Please select at least one Vendor!");
          return true;
        }

        let minD = values["materialMinDiameter"]?.replace('"', "");
        let maxD = values["materialMaxDiameter"]?.replace('"', "");
        let mD = values["materialDiameter"]?.replace('"', "");

        let materialMinDiameter = 0,
          materialMaxDiameter = 0,
          materialDiameter = 0,
          filteredData = [];

        if (diameter) {
          materialMinDiameter =
            minD?.split(" ")?.length !== 2 && String(parseInt(minD)) !== minD
              ? this.DecimalToMixedFraction(minD)
              : minD;

          materialMaxDiameter =
            maxD?.split(" ")?.length !== 2 && String(parseInt(maxD)) !== maxD
              ? this.DecimalToMixedFraction(maxD)
              : maxD;
        } else {
          materialDiameter =
            mD?.split(" ")?.length !== 2 && String(parseInt(mD)) !== mD
              ? this.DecimalToMixedFraction(mD)
              : mD;
        }

        selectedMaterial?.forEach((c) => {
          if (
            c.vendorId === vendorDetails?.vendorId &&
            c.id === materialSelectedData?.id &&
            (c.diameter
              ? c["materialMinDiameter"] === materialMinDiameter &&
                c["materialMaxDiameter"] === materialMaxDiameter
              : c["materialDiameter"] === materialDiameter)
          ) {
          } else {
            filteredData.push(c);
          }
        });
        if (
          filteredData.filter(
            (e) =>
              e.vendorId === vendorDetails?.id &&
              e.id === materialSelectedData["id"] &&
              (e.diameter
                ? e["materialMinDiameter"] === materialMinDiameter &&
                  e["materialMaxDiameter"] === materialMaxDiameter
                : e["materialDiameter"] === materialDiameter)
          )?.length > 0
        ) {
          message.error("You can't select same material with same vendor!");
          return true;
        }
        let materialData = {
          entityData: {
            id: materialSelectedData["id"],
            label: materialSelectedData["label"],
            value:
              vendorDetails?.material && vendorDetails?.material[0]["value"],
            category: materialSelectedData["category"],
            categoryKey: materialSelectedData["categoryKey"],
            subShortName: materialSelectedData["subShortName"],
            materialShortValue:
              materialSelectedData["categoryKey"] +
              " - " +
              materialSelectedData["subShortName"] +
              " - " +
              materialSelectedData["label"].split(" - ")[1],
            quantity: quantity,
            quantityType: quantityType,
            vendorId: vendorDetails?.id || recordMaterialData?.vendorId,
            vendor: vendorDetails?.quarryName || recordMaterialData?.vendor,
            vendorTerms: vendorDetails?.accountTerms,
            vendorDiscount: vendorDetails?.payDiscount,
            vendorDiscountTerms: vendorDetails?.payDiscountTerms,
            pickUpAddress:
              vendorDetails?.address || recordMaterialData?.pickUpAddress,
            pickUpLatLng: {
              lat: vendorDetails?.lat || recordMaterialData?.pickUpLatLng?.lat,
              lng: vendorDetails?.lng || recordMaterialData?.pickUpLatLng?.lng,
            },
            materialCost: parseFloat(initialMaterialCost),
            materialCostType: materialCostType,
            totalPrice:
              parseFloat(values["totalPrice"]) ||
              recordMaterialData?.totalPrice,
            totalPriceType: totalPriceType,
            truckingPrice: materialTruckPrice,
            truckingPriceType: truckingPriceType,
            truckingCost: truckingCost,
            truckingCostType: truckingCostType,
            duration: vendorDetails?.duration || recordMaterialData?.duration,
            milesData: vendorDetails?.distance || recordMaterialData?.milesData,
            // materialCostAtQuarryPerTon: materialCostPrice,
            materialCostAtQuarryPerTon: parseFloat(initialMaterialCost),
            landedPrice:
              totalPriceType === truckingPriceType
                ? parseFloat(materialPriceState) + materialTruckPrice
                : "N/A",
            landedCost:
              materialCostType === truckingCostType &&
              initialMaterialCost &&
              truckingCost
                ? parseFloat(initialMaterialCost + truckingCost)?.toFixed(2)
                : "N/A",
            totalSale:
              materialPriceState && quantity && materialTruckPrice
                ? parseFloat(
                    (materialPriceState + materialTruckPrice) * quantity
                  )?.toFixed(2)
                : "N/A",
            totalExepense:
              initialMaterialCost && quantity && truckingCost
                ? parseFloat(
                    (initialMaterialCost + truckingCost) * quantity
                  )?.toFixed(2)
                : "",
            totalProfit:
              materialPriceState !== undefined &&
              materialPriceState !== "" &&
              materialTruckPrice !== undefined &&
              materialTruckPrice !== "" &&
              quantity !== undefined &&
              quantity !== "" &&
              initialMaterialCost !== undefined &&
              initialMaterialCost !== "" &&
              materialTruckPrice !== undefined &&
              materialTruckPrice !== "" &&
              truckingCost !== undefined &&
              truckingCost !== "" &&
              parseFloat(
                (materialPriceState + materialTruckPrice) * quantity -
                  (initialMaterialCost + truckingCost) * quantity
              )?.toFixed(2),
            totalProfitPercentage:
              materialPriceState !== undefined &&
              materialPriceState !== "" &&
              materialTruckPrice !== undefined &&
              materialTruckPrice !== "" &&
              quantity !== undefined &&
              quantity !== "" &&
              initialMaterialCost !== undefined &&
              initialMaterialCost !== "" &&
              materialTruckPrice !== undefined &&
              materialTruckPrice !== "" &&
              truckingCost !== undefined &&
              truckingCost !== "" &&
              (
                (((materialPriceState + materialTruckPrice) * quantity -
                  (initialMaterialCost + truckingCost) * quantity) /
                  ((materialPriceState + materialTruckPrice) * quantity)) *
                100
              )?.toFixed(2),
            materialDiameter: materialDiameter,
            materialStatus: values["materialStatus"],
            materialSupply: values["materialSupply"],
            materialTest: values["materialTest"],
            materialMaxDiameter: materialMaxDiameter,
            materialMinDiameter: materialMinDiameter,
            diameter,
          },
        };
        // console.log(materialData, "materialData");
        this.addMaterialOrTruckInfo(materialData);
        this.addVendorContact(vendorDetails);
        // }
      } else {
        message.error(
          "Please Select Same Units For Material Price & Trucking Price"
        );
      }
      // this.formRef.current.setFieldsValue({ material: "", quantity: "" });
      // this.formRef.current.setFieldsValue({ material: "", quantity: "" });
    }
  };

  addVendorContact = (vendorDetails) => {
    const { quarryContacts, jobData } = this.state;
    let contactsData = quarryContacts?.length > 0 ? quarryContacts : [];
    vendorDetails?.contactDetails
      ?.filter((q) => q?.isPrimaryContact)
      ?.map((c) => {
        if (contactsData?.filter((t) => t.id === c.id)?.length > 0) {
          // console.log(contactsData, "contactsData");
        } else {
          contactsData.push({
            id: c["id"],
            materialId: c?.materialId,
            // companyId: companyData?.companyId,
            quarryId: vendorDetails?.id,
            jobId: jobData ? [jobData?.id] : [],
            firstName: c["firstName"],
            lastName: c["lastName"],
            phone: c["phone"],
            email: c["email"],
            notes: c["notes"],
            contactRole: c["contactRole"],
          });
        }
        return true;
      });
    this.setState({
      quarryContacts: contactsData,
    });
  };

  onChangeTerms = (e) => {
    this.setState({ selectedTerm: e });
    this.formRef.current.setFieldsValue({
      accountTermsOthers: "",
    });
  };

  onSelectChange = (data, value) => {
    this.setState({
      selectedRowKeys: data,
      vendorDetails: value[0],
      initialMaterialCost: value[0]?.material[0]?.price,
      materialCostPrice: value[0]?.material[0]?.price,
      materialPriceState: "",
      truckingCost: "",
    });
    this.formRef.current.setFieldsValue({
      quantity: "",
      totalPrice: "",
      markUp: "",
      truckingCost: "",
      truckingPrice: "",
      materialCost: value[0]?.material[0]?.price,
    });
  };

  addMaterialOrTruckInfo = (data) => {
    this.setState({
      loading: false,
    });
    let materialInfo =
      this.state.selectedMaterial?.length > 0
        ? this.state?.selectedMaterial
        : [];
    materialInfo.push(data["entityData"]);
    this.setState({
      pickupDetails: [],
      selectedMaterial: materialInfo,
      loading: false,
      quantityType: "Ton",
      selectedMaterialType: "",
      materialSelectedData: "",
      quantity: "",
      recordMaterialData: "",
      materialPopup: false,
      truckingCostType: "Ton",
      totalPriceType: "Ton",
      materialCostType: "Ton",
      truckingPriceType: "Ton",
      selectedPickupAddress: "",
      pickUpLatitude: "",
      pickupLongitude: "",
      filterResultData: [],
      duration: "",
      milesData: "",
      selectVendor: "",
      initialMaterialCost: "",
      materialPriceState: "",
      materialTruckPrice: "",
      materialCostPrice: "",
    });
  };

  editMaterial = (data) => {
    const { jobInfo } = this.state;
    if (data !== "") {
      let filteredData = [];
      this.state?.selectedMaterial?.forEach((c) => {
        if (c.vendorId === data?.vendorId && c.id === data?.id) {
        } else {
          filteredData.push(c);
        }
      });
      this.setState({ selectedMaterial: filteredData });
    }
    if (jobInfo === "Trucking Job") {
      this.setState({
        customRate: false,
        materialCustomRate: false,
        materialPopup: true,
        recordMaterialData: data,
        selectedMaterialType: data?.category,
        materialSelectedData: data,
        truckingCostType: data?.truckingCostType
          ? data?.truckingCostType
          : "Ton",
        truckingPriceType: data?.truckingPriceType
          ? data?.truckingPriceType
          : "Ton",
      });
    } else {
      this.getVendorDetails(data);
    }
  };

  getVendorDetails = (data) => {
    // console.log(data);
    const { vendorData } = this.state;
    let vendor = vendorData?.filter((v) => v?.id === data?.vendorId);
    // console.log(vendor);
    let categoriesList = vendor[0]?.material?.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.category === value.category)
    );
    // console.log(categoriesList);
    this.setState(
      {
        diameter: data?.diameter,
        customRate: false,
        materialCustomRate: false,
        pickupDetails: vendor,
        vendorDetails: vendor[0],
        selectedRowKeys: [vendor[0]?.id],
        vendorName: vendor[0]?.quarryName,
        selectVendor: vendor,
        categoriesList,
        milesData: data?.milesData,
        duration: data?.duration,
        materialValue: data?.category,
        materialPopup: true,
        recordMaterialData: data,
        useMarkUp: data.quantity,
        selectedMaterialType: data?.category,
        materialSelectedData: data,
        quantityType: data?.quantityType ? data?.quantityType : "Ton",
        quantity: data?.quantity,
        truckingCost: data?.truckingCost,
        truckingCostType: data?.truckingCostType
          ? data?.truckingCostType
          : "Ton",
        truckingPriceType: data?.truckingPriceType
          ? data?.truckingPriceType
          : "Ton",
        materialCostType: data?.materialCostType
          ? data?.materialCostType
          : "Ton",
        totalPriceType: data?.totalPriceType ? data?.totalPriceType : "Ton",
        initialMaterialCost: data?.materialCost,
        materialCostPrice: data?.materialCostAtQuarryPerTon,
        materialPriceState: data?.totalPrice,
        materialTruckPrice: data?.truckingPrice,
      },
      () => {
        this.getTopVendors(data);
        this.onSelectMaterialCat(data?.category, "edit");
      }
    );
  };

  onCancelMaterial = () => {
    const { recordMaterialData } = this.state;
    // console.log(recordMaterialData);
    if (
      recordMaterialData !== "" &&
      recordMaterialData !== undefined &&
      recordMaterialData?.length !== 0
    ) {
      let materialInfo =
        this.state.selectedMaterial?.length > 0
          ? this.state?.selectedMaterial
          : [];
      materialInfo.push(recordMaterialData);
      this.setState({
        selectedMaterial: materialInfo,
        recordMaterialData: "",
        selectedMaterialType: "",
        materialPopup: false,
        filterResultData: [],
        finalMaterialData: [],
        initialMaterialCost: "",
        pickupDetails: [],
        materialPriceState: "",
        duration: "",
        milesData: "",
        selectVendor: "",
        materialCostPrice: "",
        materialSelectedData: "",
        vendorDetails: "",
        quantity: "",
        materialTruckPrice: "",
        truckingCost: "",
        customRate: false,
        materialCustomRate: false,
      });
      // this.formRef.current.setFieldsValue({ material: "", quantity: "" });
    } else {
      this.setState({ selectedMaterialType: "", materialPopup: false });
    }
  };

  deleteMaterial = (record) => {
    if (this.state.jobInfo === "Trucking Job") {
      let materialInfo = this.state.selectedMaterial.filter(
        (c) => c.id !== record.id
      );
      this.setState({
        selectedMaterial: materialInfo,
      });
      message.info("Trucking Details Deleted Successfully");
    } else {
      let filteredData = [];
      this.state?.selectedMaterial?.forEach((c) => {
        if (c.vendorId === record?.vendorId && c.id === record?.id) {
        } else {
          filteredData.push(c);
        }
      });
      this.setState({
        selectedMaterial: filteredData,
      });
      message.info("Material Deleted Successfully!");
    }
  };

  onSelectMaterialCat = (e, type) => {
    const { materialList } = this.state;
    if (type === "edit") {
      let materialsData = [];
      materialList?.map((newMat) => {
        if (e === newMat?.category) {
          materialsData.push(newMat);
        }
        return true;
      });
      this.setState({
        selectedMaterialType: e,
        finalMaterialData: materialsData,
      });
    } else {
      let materialsData = [];
      materialList?.map((newMat) => {
        if (e === newMat?.category) {
          materialsData.push(newMat);
        }
        return true;
      });
      if (e) {
        this.formRef.current.setFieldsValue({ material: undefined, price: "" });
        this.setState({
          materialSelectedData: "",
          topVendorsData: [],
          vendorDetails: "",
          selectedRowKeys: [],
        });
      }
      this.setState({
        selectedMaterialType: e,
        finalMaterialData: materialsData,
      });
    }
  };

  onSelectMaterial = (e) => {
    const { finalMaterialData } = this.state;
    finalMaterialData?.filter(
      (f) => f.id === e && this.setState({ materialSelectedData: f })
    );
    this.setState({
      topVendorsData: [],
      vendorDetails: "",
      selectedRowKeys: [],
    });
  };

  // Switch Component for Invoice
  switchInvoices = (e) => {
    const { invoicesDetails } = this.state;
    if (e === true) {
      this.setState({
        filterInvoicesData: invoicesDetails,
      });
    } else {
      this.setState({
        filterInvoicesData: invoicesDetails?.filter(
          (i) => !i?.ARinvoiceStatus && i?.companyTerms !== "Cash"
        ),
      });
    }
  };

  // Switch Component for Job
  switchJobs = (f) => {
    const { totalLeadsData } = this.state;
    if (f === true) {
      this.setState({
        filterLeadsData: totalLeadsData,
      });
    } else {
      this.setState({
        filterLeadsData: totalLeadsData?.filter(
          (g) => g?.stageId !== "Close Job" && g?.stageId !== "Paused"
        ),
      });
    }
  };

  getQuarryLocations = () => {
    this.setState({ loading: true });
    list(appConfig["entityNames"]["qLocations"])
      .then((result) => {
        if (result.success) {
          this.setState({
            quarryDetails: result.data,
            vendorData: result.data,
            loading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
          errorLoading: true,
        });
      });
  };

  onSelectVendor = (value, option) => {
    this.setState({
      loading: true,
    });
    this.formRef.current.setFieldsValue({
      material: "",
      quantity: "",
      materialCategory: "",
      materialPriceState: "",
      totalPrice: "",
    });
    const { vendorData, dropoffLatitude, dropoffLongitude } = this.state;
    let data = vendorData?.filter((e) => e?.id === option?.key);

    let url =
      "https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&destinations=" +
      [dropoffLatitude, dropoffLongitude] +
      "&origins=" +
      [data[0]?.lat, data[0]?.lng] +
      "&key=AIzaSyB781k9fNf61hnxE3A6cvcascwhAhIVIK4";

    let milesData = {
      entityData: {
        url,
      },
    };

    let categoriesList = data[0]?.material?.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.category === value.category)
    );

    add("getMiles", milesData)
      .then((res) =>
        this.setState({
          newVendor: value,
          selectVendor: data,
          categoriesList,
          selectedMaterialType: "",
          finalMaterialData: [],
          pickupDetails: data,
          milesData: res.data.distance,
          duration: res.data.duration,
          loading: false,
          materialPriceState: "",
        })
      )
      .catch((err) => {
        this.setState({
          loading: false,
        });
        console.log(err, "err");
      });
  };

  onChangeVendor = (e) => {
    // console.log(e);
    if (e !== undefined) {
      this.setState({
        vendorName: e,
        materialTruckPrice: "",
      });
    } else {
      this.setState({
        initialMaterialCost: "",
        materialPriceState: "",
        materialCostState: "",
        materialTruckPrice: "",
        selectVendor: [],
        milesData: "",
        duration: "",
        vendorName: "",
        categoriesList: [],
      });
      this.formRef.current.setFieldsValue({
        material: "",
        quantity: "",
        materialCategory: "",
        totalPrice: "",
        truckingPrice: "",
      });
    }
  };

  // handleChangeVendor = (e) => {
  //   this.setState({
  //     newVendor: e,
  //     selectedMaterialType: "",
  //     finalMaterialData: [],
  //   });
  // };

  onClickReturn = () => {
    this.props.history.goBack();
  };

  addNotes = (values) => {
    const { recordNotesData } = this.state;
    this.setState({ notesPopup: false });
    if (recordNotesData !== "") {
      let filteredData = this.state.notesData.filter(
        (c) => c.id !== recordNotesData?.id
      );
      this.setState({
        notesData: filteredData,
      });
    }

    let data = {
      entityData: {
        id: recordNotesData?.id !== undefined ? recordNotesData?.id : uuidv4(),
        date:
          recordNotesData?.date !== undefined
            ? recordNotesData?.date
            : moment().format("MM-DD-YYYY"),
        time:
          recordNotesData?.time !== undefined
            ? recordNotesData?.time
            : moment().format("LT"),
        typeofNotes: values["type"],
        notes: values["notes"],
      },
    };
    // console.log(data);
    let notesInfo =
      this.state?.notesData?.length > 0 ? this.state?.notesData : [];
    notesInfo.push(data["entityData"]);
    this.setState({ notesData: notesInfo });
    this.updateNotes();
  };

  updateNotes = () => {
    const { notesData } = this.state;
    let data = {
      entityData: {
        notesDetails: notesData,
      },
    };
    this.updateCompanyDetails(data);
  };

  editNotes = (data) => {
    this.setState({
      notesPopup: true,
      recordNotesData: data,
    });
  };

  deleteNotes = (id) => {
    let notesInfo = this.state.notesData.filter((c) => c?.id !== id);
    this.setState(
      {
        notesData: notesInfo,
      },
      () => this.updateNotes()
    );
    message.info("Notes Deleted Successfully!");
  };

  resetvendorValues = () => {
    this.setState({
      topVendorsData: [],
      selectedRowKeys: [],
      // vendorDetails: "",
      // selectedRowKeys: [],
    });
  };

  DecimalToMixedFraction = (val) => {
    // eslint-disable-next-line no-eval
    const decimal = eval(val);
    // Split the decimal into whole and fractional parts
    const wholeNumber = Math.floor(decimal);
    const fractionalPart = decimal - wholeNumber;
    // Convert the fractional part to a fraction
    const fraction = this.decimalToFraction(fractionalPart);

    // Combine the whole number and fraction
    const mixedFraction =
      wholeNumber !== 0 ? `${wholeNumber} ${fraction}` : `${fraction}`;

    return mixedFraction;
  };

  decimalToFraction = (decimal) => {
    const EPSILON = 1.0e-9;
    let numerator = decimal;
    let denominator = 1;

    while (Math.abs(Math.round(numerator) - numerator) > EPSILON) {
      numerator *= 10;
      denominator *= 10;
    }

    const gcd = this.getGreatestCommonDivisor(numerator, denominator);
    numerator /= gcd;
    denominator /= gcd;

    return `${Math.floor(numerator)}/${Math.floor(denominator)}`;
  };

  getGreatestCommonDivisor = (a, b) => {
    if (b === 0) {
      return a;
    }
    return this.getGreatestCommonDivisor(b, a % b);
  };

  getTopVendors = (values) => {
    const {
      materialSelectedData,
      dropoffLatitude,
      diameter,
      dropoffLongitude,
    } = this.state;
    if (materialSelectedData !== "") {
      this.setState({
        loading: true,
        initialMaterialCost: materialSelectedData?.materialCost
          ? materialSelectedData?.materialCost
          : "",
      });

      let minD = values["materialMinDiameter"]?.replace('"', "");
      let maxD = values["materialMaxDiameter"]?.replace('"', "");
      let mD = values["materialDiameter"]?.replace('"', "");

      let materialMinDiameter = 0,
        materialMaxDiameter = 0,
        materialDiameter = 0;

      if (diameter) {
        materialMinDiameter =
          minD?.split(" ")?.length !== 2 && String(parseInt(minD)) !== minD
            ? this.DecimalToMixedFraction(minD)
            : minD;

        materialMaxDiameter =
          maxD?.split(" ")?.length !== 2 && String(parseInt(maxD)) !== maxD
            ? this.DecimalToMixedFraction(maxD)
            : maxD;
      } else {
        materialDiameter =
          mD?.split(" ")?.length !== 2 && String(parseInt(mD)) !== mD
            ? this.DecimalToMixedFraction(mD)
            : mD;
      }

      let data = {
        entityData: {
          material: materialSelectedData,
          dropOffLatLng: { lat: dropoffLatitude, lng: dropoffLongitude },
          materialDiameter: materialDiameter,
          materialStatus: values["materialStatus"],
          materialSupply: values["materialSupply"],
          materialTest: values["materialTest"],
          materialMaxDiameter: materialMaxDiameter,
          materialMinDiameter: materialMinDiameter,
          diameter,
        },
      };
      this.props?.actionFetchTopVendors(data);
    } else {
      message.error("Please Select Material!");
    }
  };

  handleCheckStatus = (e) => {
    this.setState({ diameter: e });
    this.formRef.current.setFieldsValue({
      materialDiameter: "",
      materialMinDiameter: "",
      materialMaxDiameter: "",
    });
  };

  handleTableChage = (props, type) => {
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    localStorage.setItem(
      "tableSizeRange",
      JSON.stringify({ ...tableSizeRange, [type]: props })
    );
  };

  render() {
    const {
      useMarkUp,
      materialCategory,
      customRate,
      materialCustomRate,
      invoicesDetails,
      truckingPriceType,
      totalPriceType,
      // dropAddress,
      companyData,
      totalLeadsData,
      contactsData,
      citySearch,
      documentsDetails,
      imageLoading,
      isDocModalVisible,
      quoteAttachment,
      leadVisible,
      jobContactData,
      jobData,
      isContactModalVisible,
      jobInfo,
      // truckDropInfo,
      newRole,
      // selectedMaterial,
      recordMaterialData,
      newJobContacts,
      selectedAssignTo,
      materialPopup,
      selectedTerm,
      // vendorData,
      initialMaterialCost,
      materialPriceState,
      materialTruckPrice,
      milesData,
      duration,
      notesPopup,
      recordNotesData,
      fileType,
      filterInvoicesData,
      filterLeadsData,
      averageDayToPay,
      // showSwitchtoggle,
      rolesData,
      // trucksData,
      finalMaterialData,
      topVendorsData,
      selectedRowKeys,
      modallLoading,
      quantityType,
      quantity,
      truckingCostType,
      materialCostType,
      truckingCost,
      // updateJobIdContact
      jobIdState,
      diameter,
    } = this.state;

    const { loading, errorLoading } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const selectAfter = (
      <Select
        className="select-after"
        defaultValue={quantityType}
        onChange={(e) => {
          this.setState({ quantityType: e });
        }}
      >
        <Option value="Ton">Ton</Option>
        <Option value="Load">Load</Option>
        <Option value="Yard">Yard</Option>
      </Select>
    );

    const selectAfterTruck = (
      <Select
        defaultValue={truckingCostType}
        className="select-after"
        onChange={(e) => {
          this.setState({ truckingCostType: e });
        }}
      >
        <Option value="Ton">Ton</Option>
        <Option value="Load">Load</Option>
        <Option value="Hour">Hour</Option>
      </Select>
    );

    const selectAfterTruckPrice = (
      <Select
        defaultValue={truckingPriceType}
        className="select-after"
        onChange={(e) => {
          this.setState({ truckingPriceType: e });
        }}
      >
        <Option value="Ton">Ton</Option>
        <Option value="Load">Load</Option>
        <Option value="Hour">Hour</Option>
      </Select>
    );

    const selectAfterMaterial = (
      <Select
        defaultValue={materialCostType}
        className="select-after"
        onChange={(e) => {
          this.setState({ materialCostType: e });
        }}
      >
        <Option value="Ton">Ton</Option>
        <Option value="Load">Load</Option>
        <Option value="Yard">Yard</Option>
      </Select>
    );

    const selectAfterCustomer = (
      <Select
        defaultValue={totalPriceType}
        className="select-after"
        onChange={(e) => {
          this.setState({ totalPriceType: e });
        }}
      >
        <Option value="Ton">Ton</Option>
        <Option value="Load">Load</Option>
        <Option value="Yard">Yard</Option>
      </Select>
    );

    const topVendorsColumns = [
      {
        title: "VENDOR NAME",
        dataIndex: "quarryName",
        key: "quarryName",
        sorter: false,
        width: "40%",
        className: "col_style_quarry",
        render: (quarryName, record) => {
          return (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "fit-content",
              }}
              onClick={() => this.onSelectChange([record.id], [record])}
            >
              {quarryName}
            </Row>
          );
        },
      },
      {
        title: "RUN & RATE INFORMATION",
        dataIndex: "distance",
        key: "distance",
        sorter: false,
        width: "40%",
        className: "col_style_quarry",
        render: (distance, record) => {
          return (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "fit-content",
              }}
              onClick={() => this.onSelectChange([record.id], [record])}
            >
              {distance + "les, " + record?.duration}
            </Row>
          );
        },
      },
      {
        title: "COST / Ton",
        dataIndex: "material",
        key: "material",
        sorter: false,
        width: "20%",
        className: "col_style_quarry",
        render: (material, record) => {
          return (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "fit-content",
              }}
              onClick={() => this.onSelectChange([record.id], [record])}
            >
              {"$" + material[0]?.price?.toFixed(2)}
            </Row>
          );
        },
      },
    ];

    const leadsColumns = [
      {
        title: "Job #",
        dataIndex: "jobNumber",
        key: "jobNumber",
        sorter: false,
        className: "col_style_quarry",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(`/app/jobs/job-profile/${record.id}`),
        }),
        render: (jobNumber, record) => {
          return <span className="col_style_quarry">{jobNumber}</span>;
        },
      },
      {
        title: "Job Name",
        dataIndex: "jobName",
        key: "jobName",
        sorter: false,
        className: "col_style_quarry",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(`/app/jobs/job-profile/${record.id}`),
        }),
        render: (jobName, record) => {
          return (
            <span className="col_style_quarry">
              {jobName !== "" ? jobName : record?.jobNumber}
            </span>
          );
        },
      },
      {
        title: "Contact Name",
        dataIndex: "multipleContacts",
        key: "multipleContacts",
        sorter: false,
        className: "col_style_quarry",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(`/app/jobs/job-profile/${record.id}`),
        }),
        render: (multipleContacts) => {
          return (
            <div>
              {multipleContacts?.map((c) => (
                <Popover
                  content={
                    (c?.phone || c?.email) && (
                      <Row>
                        <Col span={24}>
                          <span>{c.phone}</span>
                        </Col>
                        <Col span={24}>
                          <span
                            style={{
                              color: "black",
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                              justifyContent: "left",
                            }}
                          >
                            {c.email}
                          </span>
                        </Col>
                      </Row>
                    )
                  }
                >
                  <span className="col_style_quarry">
                    <div>{c?.firstName + " " + c?.lastName}</div>
                  </span>
                </Popover>
              ))}
            </div>
          );
        },
      },
      {
        title: "Material",
        dataIndex: "material",
        key: "material",
        sorter: false,
        className: "col_style_quarry",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(`/app/jobs/job-profile/${record.id}`),
        }),
        render: (material) => {
          // console.log(material);
          return (
            <span className="col_style_quarry">
              {Array.isArray(material) &&
                material?.length > 0 &&
                material?.map((e) => (
                  <div>{e?.category ? e?.category :  e?.materialName}</div>
                ))}
            </span>
          );
        },
      },
      {
        title: "Job Status",
        dataIndex: "stageId",
        key: "stageId",
        sorter: false,
        className: "col_style_quarry",
        render: (stageId, record) => {
          return (
            <button
              className="col_style_quarry"
              style={{
                border: "1px solid",
                background: "transparent",
                borderRadius: "6px",
                borderColor: appConfig["STAGE_COLORS"][record.stageId],
                color: appConfig["STAGE_COLORS"][record.stageId],
              }}
            >
              {stageId === "Close Job" ? "Closed" : stageId}
            </button>
          );
        },
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "",
        sorter: false,
        className: "col_styling action_tab",
        width: "4%",
        fixed: "right",
        // onCell: (record) => ({
        //   onClick: () =>
        //     (window.location="/app/jobs/job-profile/"+record.id),
        // }),
        render: (record) => {
          return (
            <>
              {record?.stageId !== "Close Job" && (
                <Col>
                  <Popover
                    placement="bottom"
                    content={
                      <Row className="popovergrid" style={{ maxWidth: "90" }}>
                        <Col span={24}>
                          <Button
                            className="btn_edit"
                            onClick={() => {
                              this.props.history.push(
                                `/app/updateJobDetails/${record.id}`
                              );
                              // this.editModal(record, "editJob");
                            }}
                          >
                            <span className="span_edit">
                              <MdModeEditOutline
                                style={{ marginRight: "5px" }}
                              />
                              Edit
                            </span>
                          </Button>
                        </Col>
                      </Row>
                    }
                  >
                    <EllipsisOutlined
                      style={{ fontSize: "30px", color: "grey" }}
                      className="action-ellipsis-button"
                    />
                  </Popover>
                </Col>
              )}
            </>
          );
        },
      },
    ];

    const contactsColumns = [
      {
        title: "Contact Name",
        dataIndex: "firstName",
        key: "firstName",
        sorter: false,
        width: "20%",
        className: "col_style_quarry",
        render: (firstName, record) => {
          return (
            <span className="col_style_quarry">
              {firstName + " " + record.lastName}
            </span>
          );
        },
      },
      {
        title: "Role",
        dataIndex: "contactRole",
        key: "contactRole",
        sorter: false,
        width: "20%",
        className: "col_style_quarry",
        render: (contactRole) => {
          return <span className="col_style_quarry">{contactRole}</span>;
        },
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        sorter: false,
        className: "col_style_quarry",
        width: "20%",
        render: (phone) => {
          return <span className="col_style_quarry">{phone}</span>;
        },
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        sorter: false,
        className: "col_style_quarry",
        width: "25%",
        render: (email) => {
          return <span className="col_style_quarry">{email}</span>;
        },
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "",
        sorter: false,
        className: "col_style_quarry",
        width: "20%",
        fixed: "right",
        render: (record) => {
          return (
            <Col>
              <Popover
                placement="left"
                content={
                  <Row className="popovergrid" style={{ maxWidth: "90px" }}>
                    <Col span={24}>
                      <Button
                        className="btn_edit"
                        onClick={() => this.editModal(record)}
                      >
                        <span className="span_edit">
                          <MdModeEditOutline style={{ marginRight: "5px" }} />
                          Edit
                        </span>
                      </Button>
                    </Col>
                    <Col span={24}>
                      <Button className="btn_edit">
                        <Popconfirm
                          title="Are you sure？"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => this.deleteContact(record)}
                        >
                          <span className="span_edit">
                            <MdDelete style={{ marginRight: "5px" }} /> Delete
                          </span>
                        </Popconfirm>
                      </Button>
                    </Col>
                  </Row>
                }
              >
                <EllipsisOutlined
                  style={{ fontSize: "30px", color: "grey" }}
                  className="action-ellipsis-button"
                />
              </Popover>
            </Col>
            // <Col>
            //   <Popover
            //     placement="left"
            //     content={
            //       <Col>
            //         <Row className="popovergrid">
            //           <Col
            //             span={24}
            //             onClick={() => this.editModal(record)}
            //             style={{ cursor: "pointer" }}
            //           >
            //             <div className="icon-alignmentit">
            //               <MdModeEditOutline /> Edit
            //             </div>
            //           </Col>
            //           <Col
            //             span={24}
            //             style={{ cursor: "pointer" }}
            //             onClick={() => this.deleteContact(record)}
            //           >
            //             <div className="icon-alignmentit">
            //               <MdDelete /> Delete
            //             </div>
            //           </Col>
            //         </Row>
            //       </Col>
            //     }
            //   >
            //     <EllipsisOutlined style={{ fontSize: "30px", color: "grey" }} />
            //   </Popover>
            // </Col>
          );
        },
      },
    ];

    const invoicesColumns = [
      {
        title: "Date",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: false,
        width: "15%",
        className: "col_style_quarry",
        render: (createdAt) => {
          return (
            <span className="col_style_quarry">
              {moment(createdAt).format("L")}
            </span>
          );
        },
      },
      {
        title: "Invoice Number",
        dataIndex: "invoiceNumber",
        key: "invoiceNumber",
        sorter: false,
        width: "25%",
        className: "col_style_quarry",
        render: (invoiceNumber) => {
          return <span className="col_style_quarry">{invoiceNumber}</span>;
        },
      },
      {
        title: "Job Number",
        dataIndex: "",
        key: "",
        sorter: false,
        width: "20%",
        className: "col_style_quarry",
        render: (record) => {
          return (
            <span className="col_style_quarry">
              {record?.ticketData?.jobNumber}
            </span>
          );
        },
      },
      {
        title: "Invoice Amount",
        dataIndex: "ticketsTotal",
        key: "ticketsTotal",
        sorter: false,
        width: "25%",
        className: "col_style_quarry",
        render: (ticketsTotal) => {
          return (
            <span className="col_style_quarry">
              {`$${parseFloat(ticketsTotal)?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}`}
            </span>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "ARinvoiceStatus",
        key: "ARinvoiceStatus",
        sorter: false,
        width: "25%",
        className: "col_style_quarry",
        render: (ARinvoiceStatus, record) => {
          return (
            <span className="col_style_quarry">
              {record?.companyTerms === "Cash"
                ? "Paid"
                : ARinvoiceStatus
                ? ARinvoiceStatus
                : "Unpaid"}
            </span>
          );
        },
      },
      {
        title: "Attachment",
        dataIndex: "invoicePath",
        key: "invoicePath",
        sorter: false,
        width: "5%",
        className: "col_style_quarry",
        render: (invoicePath, record) => {
          return (
            <span className="col_style_quarry">
              {invoicePath !== undefined &&
                invoicePath !== "" &&
                invoicePath !== null && (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.fileDownload(
                        record.invoicePath,
                        "Attachment",
                        "invoicePath"
                      );
                    }}
                  >
                    <Row
                      style={{
                        color: "#7B8190",
                        fontSize: "13px",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        flexDirection: "coloumn",
                        width: "75px",
                      }}
                    >
                      <HiOutlineDocumentArrowDown
                        style={{
                          fontSize: "30px",
                          height: "30px",
                          marginRight: "5px",
                        }}
                      />
                      {/* <span style={{ marginTop: "6px" }}>Download</span> */}
                    </Row>
                  </span>
                )}
            </span>
          );
        },
      },
      // {
      //   title: "Attachments",
      //   dataIndex: "invoiceAttachment",
      //   key: "attachment",
      //   sorter: false,
      //   className: "col_style_quarry",
      //   render: (attachment) => {
      //     return <span className="col_style_quarry">{attachment}</span>;
      //   },
      // },
    ];

    const docuColumn = [
      {
        title: "Upload Date",
        dataIndex: "date",
        key: "date",
        sorter: false,
        width: "20%",
        className: "col_style_quarry",
        render: (date) => {
          return (
            <span className="col_style_quarry">{moment(date).format("L")}</span>
          );
        },
      },
      {
        title: "Document Name",
        dataIndex: "document",
        key: "document",
        sorter: false,
        width: "30%",
        className: "col_style_quarry",
        render: (document) => {
          return <span className="col_style_quarry">{document}</span>;
        },
      },
      {
        title: "Type",
        dataIndex: "docType",
        key: "docType",
        sorter: false,
        width: "30%",
        className: "col_style_quarry",
        render: (docType) => {
          return <span className="col_style_quarry">{docType}</span>;
        },
      },
      {
        title: "Assigned To",
        dataIndex: "assignedTo",
        key: "assignedTo",
        sorter: false,
        width: "30%",
        className: "col_style_quarry",
        render: (assignedTo) => {
          return (
            <span className="col_style_quarry">
              {assignedTo === "Company" ? assignedTo : `Job#: ${assignedTo}`}
            </span>
          );
        },
      },
      {
        title: "Attachment",
        dataIndex: "docAttachment",
        key: "docAttachment",
        sorter: false,
        width: "4%",
        className: "col_style_quarry",
        render: (docAttachment, record) => {
          return (
            <span className="col_style_quarry">
              {docAttachment !== undefined &&
                docAttachment !== "" &&
                docAttachment !== null && (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.fileDownload(
                        record.docAttachmentId,
                        "Attachment",
                        "docAttachment"
                      );
                    }}
                  >
                    <Row
                      style={{
                        color: "#7B8190",
                        fontSize: "13px",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        flexDirection: "coloumn",
                        width: "75px",
                      }}
                    >
                      <HiOutlineDocumentArrowDown
                        style={{
                          fontSize: "30px",
                          height: "30px",
                          // marginRight: "5px",
                        }}
                      />
                      {/* <span style={{ marginTop: "6px" }}>Download</span> */}
                    </Row>
                  </span>
                )}
            </span>
          );
        },
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "",
        sorter: false,
        className: "col_style_quarry",
        width: "10%",
        fixed: "right",
        render: (record) => {
          return (
            <Col>
              <Popover
                placement="left"
                content={
                  <Row className="popovergrid" style={{ maxWidth: "90px" }}>
                    {/* <Col span={24}>
                      <Button
                        style={{
                          fontSize: "15px",
                          cursor: "pointer",
                          border: "none",
                          padding: "0px",
                          textAlign: "left",
                          width: "100%",
                        }}
                        onClick={() => this.editNotes(record)}
                      >
                        <span
                          style={{
                            color: "black",
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "center",
                            justifyContent: "left",
                            height: "auto",
                          }}
                        >
                          <MdModeEditOutline
                            style={{
                              marginRight: "5px",
                            }}
                          />{" "}
                          Edit
                        </span>
                      </Button>
                    </Col> */}
                    <Col span={24}>
                      <Button className="btn_edit">
                        <Popconfirm
                          title="Are you sure？"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() =>
                            this.props.actionFetchDeleteDoc(record?.id)
                          }
                        >
                          <span className="span_edit">
                            <MdDelete
                              style={{
                                marginRight: "5px",
                              }}
                            />
                            Delete
                          </span>
                        </Popconfirm>
                      </Button>
                    </Col>
                  </Row>
                }
              >
                <EllipsisOutlined
                  style={{
                    fontSize: "30px",
                    color: "grey",
                  }}
                  className="action-ellipsis-button"
                />
              </Popover>
            </Col>
          );
        },
      },
      // {
      //   title: "Document Attachment",
      //   dataIndex: "docAttachment",
      //   key: "docAttachment",
      //   sorter: false,
      //   className: "col_style_quarry",
      //   render: (docAttachment) => {
      //     return <span className="col_style_quarry">{docAttachment}</span>;
      //   },
      // },
    ];

    const notesColumns = [
      {
        title: "Date Created",
        dataIndex: "date",
        key: "date",
        sorter: false,
        width: "15%",
        className: "col_style_quarry",
        render: (date) => {
          return <span className="col_style_quarry">{date}</span>;
        },
      },
      {
        title: "Time",
        dataIndex: "time",
        key: "time",
        sorter: false,
        width: "15%",
        className: "col_style_quarry",
        render: (time) => {
          return <span className="col_style_quarry">{time}</span>;
        },
      },
      {
        title: "Note Type",
        dataIndex: "typeofNotes",
        key: "typeofNotes",
        sorter: false,
        className: "col_style_quarry",
        width: "25%",
        render: (typeofNotes) => {
          return <span className="col_style_quarry">{typeofNotes}</span>;
        },
      },
      {
        title: "Note",
        dataIndex: "notes",
        key: "notes",
        sorter: false,
        className: "col_style_quarry",
        render: (notes) => {
          return <span className="col_style_quarry">{notes}</span>;
        },
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "",
        sorter: false,
        className: "col_style_quarry",
        width: "10%",
        fixed: "right",
        render: (record) => {
          return (
            <Col>
              <Popover
                placement="left"
                content={
                  <Row className="popovergrid" style={{ maxWidth: "90px" }}>
                    <Col span={24}>
                      <Button
                        className="btn_edit"
                        onClick={() => this.editNotes(record)}
                      >
                        <span className="span_edit">
                          <MdModeEditOutline
                            style={{
                              marginRight: "5px",
                            }}
                          />{" "}
                          Edit
                        </span>
                      </Button>
                    </Col>
                    <Col span={24}>
                      <Button className="btn_edit">
                        <Popconfirm
                          title="Are you sure？"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => this.deleteNotes(record?.id)}
                          // onConfirm={() => this.deleteCompany(record)}
                        >
                          <span className="span_edit">
                            <MdDelete
                              style={{
                                marginRight: "5px",
                              }}
                            />
                            Delete
                          </span>
                        </Popconfirm>
                      </Button>
                    </Col>
                  </Row>
                }
              >
                <EllipsisOutlined
                  style={{
                    fontSize: "30px",
                    color: "grey",
                  }}
                  className="action-ellipsis-button"
                />
              </Popover>
            </Col>
          );
        },
      },
    ];

    // const quotesColumn = [
    //   {
    //     title: "Date",
    //     dataIndex: "date",
    //     key: "date",
    //     sorter: false,
    //     width: "25%",
    //     className: "col_style_quarry",
    //     render: (quarrydate) => {
    //       return <span className="col_style_quarry">{quarrydate}</span>;
    //     },
    //   },
    //   {
    //     title: "Quantity",
    //     dataIndex: "quantity",
    //     key: "quantity",
    //     sorter: false,
    //     width: "25%",
    //     className: "col_style_quarry",
    //     render: (quantity) => {
    //       return <span className="col_style_quarry">{quantity}</span>;
    //     },
    //   },
    //   // {
    //   //   title: "Quote Attachment",
    //   //   dataIndex: "quoteAttachment",
    //   //   key: "quoteAttachment",
    //   //   sorter: false,
    //   //   className: "col_style_quarry",
    //   //   render: (quoteAttachment) => {
    //   //     return <span className="col_style_quarry">{quoteAttachment}</span>;
    //   //   },
    //   // },
    //   {
    //     title: "Quote Status",
    //     dataIndex: "quotestatus",
    //     key: "quotestatus",
    //     sorter: false,
    //     width: "25%",
    //     className: "col_style_quarry",
    //     render: (quotestatus) => {
    //       return (
    //         <Button
    //           className="col_style_quarry"
    //           style={{
    //             color: appConfig["quote_status_colors"][quotestatus],
    //             borderColor: appConfig["quote_status_colors"][quotestatus],
    //             borderRadius: "6px",
    //           }}
    //         >
    //           {quotestatus}
    //         </Button>
    //       );
    //     },
    //   },
    //   {
    //     title: "Quote Attachment",
    //     dataIndex: "quoteAttachment",
    //     key: "quoteAttachment",
    //     sorter: false,
    //     width: "25%",
    //     className: "col_style_quarry",
    //     render: (quoteAttachment) => {
    //       return (
    //         <span className="col_style_quarry">
    //           <span
    //             style={{ cursor: "pointer" }}
    //             onClick={() => {
    //               this.fileDownload(
    //                 quoteAttachment,
    //                 "Attachment",
    //                 "quoteAttachment"
    //               );
    //             }}
    //           >
    //             <Row
    //               style={{
    //                 color: "#7B8190",
    //                 fontSize: "13px",
    //               }}
    //             >
    //               <FilePdfOutlined
    //                 style={{
    //                   fontSize: "30px",
    //                   height: "30px",
    //                   marginRight: "5px",
    //                 }}
    //               />
    //               <span style={{ marginTop: "6px" }}>Download</span>
    //             </Row>
    //           </span>
    //         </span>
    //       );
    //     },
    //   },
    // ];
    // console.log(companyData,"companyData");

    return (
      <>
        <Loading enableLoading={loading} />
        <Row
          style={{
            backgroundColor: "#fafafa",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "60px",
            width: "100%",
          }}
        >
          <Row onClick={this.onClickReturn} style={{ cursor: "pointer" }}>
            <BsArrowLeftShort
              style={{ color: "#5f6c74", fontSize: "25px" }}
              className="back-button-return-icon"
            />
            <p
              style={{ color: "#808080", fontSize: "16px", fontWeight: "600" }}
              className="back-button-return"
            >
              Return
            </p>
          </Row>
        </Row>
        <div
          style={{
            background: "#fafafa",
            minHeight: "75vh",
          }}
        >
          {errorLoading ? (
            <Col
              xxl={{ span: 24 }}
              xl={{ span: 24 }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <ErrorView
                refreshLeadDetails={this.onRefresh}
                type="LeadDetails"
              />
            </Col>
          ) : (
            <Row style={{ marginBottom: "1vw" }} gutter={[12, 0]}>
              <Col
                xxl={{ span: 9 }}
                xl={{ span: 9 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                data-aos={window.screen.width > 1023 ? "fade-right" : ""}
              >
                <Card>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "-12px",
                        right: "0px",
                        border: "0.5px solid #aaa8a8",
                        width: "2vw",
                        height: "2vw",
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        alignContent: "center",
                        justifyContent: " center",
                        padding: "8px",
                        borderRadius: "100%",
                      }}
                      className="edit-details-button"
                    >
                      <Button
                        style={{
                          border: "none",
                          cursor: "pointer",
                          // marginTop: "-90px",
                          backgroundColor: "transparent",
                        }}
                        onClick={() => this.setState({ isModalVisible: true })}
                      >
                        <EditOutlined
                          style={{
                            color: "#5f6c74",
                            fontSize: "120%",
                            fontWeight: "600",
                          }}
                        />
                      </Button>
                    </div>
                    <p
                      className="detail-heading"
                      style={{ fontSize: "16px", color: "#5f6c74" }}
                    >
                      Customer Account
                    </p>
                    <div className="linkcompany">
                      <p
                        className="detail-ans"
                        style={{
                          fontSize: "20px",
                          marginTop: "10px",
                          textTransform: "capitalize",
                          marginBottom: "5px",
                          color: "#0d0f10",
                          fontWeight: "800",
                        }}
                      >
                        {companyData?.company}
                      </p>
                    </div>
                  </div>
                </Card>

                <Card style={{ marginTop: "25px" }}>
                  <p
                    className="detail-ans"
                    style={{
                      fontWeight: "600",
                      marginBottom: "8px",
                      borderBottom: "1px solid rgb(175, 175, 175)",
                      width: "fit-content",
                    }}
                  >
                    Customer Details
                  </p>{" "}
                  <Row
                    span={24}
                    style={{
                      marginBottom: "8px",
                      marginTop: "5px",
                      // borderBottom: "2px ridge #e6e6e6",
                    }}
                  >
                    <Col
                      span={9}
                      className="detail-heading table-font-mobile"
                      style={{ fontSize: "16px" }}
                    >
                      Mailing Address
                    </Col>
                    <Col span={15} className="detail-ans">
                      {companyData?.address && companyData?.address}
                    </Col>
                  </Row>
                  <Row
                    span={24}
                    style={{
                      marginBottom: "8px",
                      // borderBottom: "2px ridge #e6e6e6",
                    }}
                  >
                    <Col
                      span={9}
                      className="detail-heading table-font-mobile"
                      style={{ fontSize: "16px" }}
                    >
                      Office Email
                    </Col>
                    <Col span={15} className="detail-ans">
                      {companyData?.email && companyData?.email}
                    </Col>
                  </Row>
                  <Row
                    span={24}
                    style={{
                      marginBottom: "8px",
                      // borderBottom: "2px ridge #e6e6e6",
                    }}
                  >
                    <Col
                      span={9}
                      className="detail-heading table-font-mobile"
                      style={{ fontSize: "16px" }}
                    >
                      Office Phone
                    </Col>
                    <Col span={15} className="detail-ans">
                      {companyData?.phone && companyData?.phone}
                    </Col>
                  </Row>
                  <p
                    className="detail-ans"
                    style={{
                      fontWeight: "600",
                      marginBottom: "8px",
                      borderBottom: "1px solid rgb(175, 175, 175)",
                      width: "fit-content",
                    }}
                  >
                    Account Details
                  </p>{" "}
                  <Row
                    span={24}
                    style={{
                      marginBottom: "8px",
                      marginTop: "5px",
                      // borderBottom: "2px ridge #e6e6e6",
                    }}
                  >
                    <Col
                      span={9}
                      className="detail-heading table-font-mobile"
                      style={{ fontSize: "16px" }}
                    >
                      Terms
                    </Col>
                    <Col span={15} className="detail-ans">
                      {companyData?.accountTerms && companyData?.accountTerms}
                    </Col>
                  </Row>
                  {/* <Row
                    span={24}
                    style={{
                      marginBottom: "8px",
                      // borderBottom: "2px ridge #e6e6e6",
                    }}
                  >
                    <Col
                      span={9}
                      className="detail-heading table-font-mobile"
                      style={{ fontSize: "16px" }}
                    >
                      Charge Limit
                    </Col>
                    <Col span={15} className="detail-ans">
                      {companyData?.accountChargeLimit &&
                        companyData?.accountChargeLimit}
                    </Col>
                  </Row> */}
                  <Row
                    span={24}
                    style={{
                      marginBottom: "8px",
                      // borderBottom: "2px ridge #e6e6e6",
                    }}
                  >
                    <Col
                      span={9}
                      className="detail-heading table-font-mobile"
                      style={{ fontSize: "16px" }}
                    >
                      Current Balance
                    </Col>
                    <Col span={15} className="detail-ans">
                      {companyData?.currentBalance &&
                        `$${parseFloat(companyData?.currentBalance)?.toFixed(
                          2
                        )}`}
                    </Col>
                  </Row>
                  <Row
                    span={24}
                    style={{
                      marginBottom: "8px",
                      // borderBottom: "2px ridge #e6e6e6",
                    }}
                  >
                    <Col
                      span={9}
                      className="detail-heading table-font-mobile"
                      style={{ fontSize: "16px" }}
                    >
                      Overdue Balance
                    </Col>
                    <Col span={15} className="detail-ans">
                      {companyData?.overDueBalance &&
                        `$${parseFloat(companyData?.overDueBalance)?.toFixed(
                          2
                        )}`}
                    </Col>
                  </Row>
                  <Row
                    span={24}
                    style={{
                      marginBottom: "8px",
                      // borderBottom: "2px ridge #e6e6e6",
                    }}
                  >
                    <Col
                      span={9}
                      className="detail-heading table-font-mobile"
                      style={{ fontSize: "16px" }}
                    >
                      Average Days To Pay
                    </Col>
                    <Col span={15} className="detail-ans">
                      <p
                        className="detail-ans"
                        style={{
                          fontSize: "18px",
                          // fontWeight: "bold",
                          marginBottom: "0px",
                        }}
                      >
                        {parseFloat(
                          averageDayToPay /
                            invoicesDetails?.filter((e) => e.paidDays).length
                        ).toFixed(1) === "NaN"
                          ? 0
                          : parseFloat(
                              averageDayToPay /
                                invoicesDetails?.filter((e) => e.paidDays)
                                  ?.length
                            ).toFixed(1)}{" "}
                        <span
                          style={{
                            fontSize: "18px",
                            color: "#b8b8b8",
                          }}
                        >
                          Days
                        </span>
                      </p>
                      {/* {averageDayToPay +" Days"} */}
                    </Col>
                  </Row>{" "}
                  <p
                    className="detail-ans"
                    style={{
                      fontWeight: "600",
                      marginBottom: "8px",
                      borderBottom: "1px solid rgb(175, 175, 175)",
                      width: "fit-content",
                    }}
                  >
                    Notes
                  </p>
                  <div
                    style={{
                      minHeight: "65px",
                      borderRadius: "3px",
                      backgroundColor: "#e3e7ea",
                      padding: "6px",
                      marginTop: "5px",
                    }}
                    className="detail-ans"
                  >
                    {companyData?.notes && companyData?.notes}
                  </div>
                  {/* {apContactData?.map(
                    (
                      apInfo // className="fontreducer"
                    ) => (
                      <>
                        <p
                          className="detail-ans"
                          style={{
                            fontWeight: "600",
                            marginTop: "15px",
                            marginBottom: "-3px",
                          }}
                        >
                          AP Contact Details
                        </p>
                        <Row
                          span={24}
                          style={{
                            marginBottom: "8px",
                            // borderBottom: "2px ridge #e6e6e6",
                            marginTop: "5px",
                          }}
                        >
                          <Col
                            span={12}
                            className="detail-heading"
                            style={{ fontSize: "16px" }}
                          >
                            AP Contact Name
                          </Col>
                          <Col span={12} className="detail-ans">
                            {apInfo?.firstName &&
                              apInfo?.firstName + " " + apInfo?.lastName}
                          </Col>
                        </Row>
                        <Row
                          span={24}
                          style={{
                            marginBottom: "8px",
                            // borderBottom: "2px ridge #e6e6e6",
                          }}
                        >
                          <Col
                            span={12}
                            className="detail-heading"
                            style={{ fontSize: "16px" }}
                          >
                            AP Email
                          </Col>
                          <p className="detail-ans">{apInfo?.email}</p>
                        </Row>
                        <Row
                          span={24}
                          style={{
                            marginBottom: "8px",
                            // borderBottom: "2px ridge #e6e6e6",
                          }}
                        >
                          <Col
                            span={12}
                            className="detail-heading"
                            style={{ fontSize: "16px" }}
                          >
                            AP Phone
                          </Col>
                          <Col span={12} className="detail-ans">
                            {apInfo?.phone}
                          </Col>
                        </Row>
                      </>
                    )
                  )} */}
                </Card>

                {/* <Row>
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    lg={{ span: 24, offset: 0 }}
                    xl={{ span: 24, offset: 0 }}
                    xxl={{ span: 24, offset: 0 }}
                    style={{
                      width: "100%",
                      marginTop: "25px",
                      padding: "0px",
                    }}
                  >
                    {quarryData?.length > 0 && (
                      <div className="companydetail_map">
                        <MapComponent
                          isMarkerShown
                          quarryData={quarryData}
                          singleValues
                        />
                      </div>
                    )}
                  </Col>
                </Row> */}
              </Col>

              <Col
                xxl={{ span: 15, offset: 0 }}
                xl={{ span: 15, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
                // span={15}
                // offset={1}
                data-aos={window.screen.width > 1023 ? "fade-left" : ""}
              >
                <Card
                  className="card_virtual"
                  bodyStyle={{ padding: "0px" }}
                  style={{ background: "#eff1f3" }}
                >
                  <Tabs
                    defaultActiveKey="1"
                    className="ant-card companydetailtab"
                    style={{ borderRadius: "5px" }}
                    onChange={(e) => {
                      this.setState({
                        keyValue: e,
                      });
                    }}
                    tabBarExtraContent={
                      <>
                        {this.state?.keyValue !== "3" && (
                          <Button
                            type="primary"
                            htmlType="submit"
                            icon={<PlusOutlined />}
                            style={{
                              height: "35px",
                              borderRadius: "5px",
                              marginRight: "10px",
                            }}
                            onClick={() => this.showModal(this.state?.keyValue)}
                          >
                            {this.state?.keyValue === "1"
                              ? "Job"
                              : this.state?.keyValue === "2"
                              ? "Contact"
                              : //: this.state?.keyValue === "3"
                              //? "Invoice"
                              this.state?.keyValue === "4"
                              ? "Document"
                              : "Notes"}
                          </Button>
                        )}
                        {this.state?.keyValue === "3" && (
                          <Row
                            style={{
                              marginRight: "10px",
                            }}
                          >
                            <span
                              style={{
                                marginRight: "3px",
                                fontWeight: "600",
                                fontSize: "16px",
                              }}
                              className="detail-heading table-font-mobile"
                            >
                              All Invoices
                            </span>
                            <Switch
                              className="switchcolor"
                              onChange={(e) => this.switchInvoices(e)}
                            />
                          </Row>
                        )}
                        {this.state?.keyValue === "1" && (
                          <Row
                            style={{
                              float: "left",
                              marginTop: "5px",
                              marginRight: "10px",
                            }}
                          >
                            <span
                              style={{
                                marginRight: "6px",
                                fontWeight: "600",
                                fontSize: "16px",
                              }}
                              className="detail-heading table-font-mobile"
                            >
                              All Jobs
                            </span>
                            <Switch
                              className="switchcolor"
                              onChange={(f) => this.switchJobs(f)}
                            />
                          </Row>
                        )}
                      </>
                    }
                  >
                    <TabPane
                      tab={<span className="tab_Text">Jobs</span>}
                      key="1"
                    >
                      <BaseTable
                        className="leads_tableclass table_laptopscreen"
                        columnsData={leadsColumns}
                        source={filterLeadsData?.sort(
                          (a, b) => a.createdAt - b.createdAt
                        )}
                        total={filterLeadsData?.length}
                        handleTableChage={(e) =>
                          this.handleTableChage(e, "companyDetails")
                        }
                        sizeRange={
                          window.screen.width > 1023
                            ? JSON.parse(localStorage.getItem("tableSizeRange"))
                                ?.companyDetails
                            : filterLeadsData?.length
                        }
                        pagination={window.screen.width > 1023 ? true : false}
                      />
                    </TabPane>
                    <TabPane tab={<span>Contacts</span>} key="2">
                      <BaseTable
                        className="contacts_tableclass table_laptopscreen"
                        columnsData={contactsColumns}
                        source={contactsData}
                        total={contactsData?.length}
                        handleTableChage={(e) =>
                          this.handleTableChage(e, "companyContactDetails")
                        }
                        sizeRange={
                          window.screen.width > 1023
                            ? JSON.parse(localStorage.getItem("tableSizeRange"))
                                ?.companyContactDetails
                            : contactsData?.length
                        }
                        pagination={window.screen.width > 1023 ? true : false}
                      />
                    </TabPane>
                    <TabPane tab={<span>Invoices</span>} key="3">
                      <BaseTable
                        className="invoices_tableclass table_laptopscreen"
                        columnsData={invoicesColumns}
                        source={filterInvoicesData?.sort(
                          (a, b) => a.createdAt - b.createdAt
                        )}
                        total={filterInvoicesData?.length}
                        handleTableChage={(e) =>
                          this.handleTableChage(e, "companyInvoiceDetails")
                        }
                        sizeRange={
                          window.screen.width > 1023
                            ? JSON.parse(localStorage.getItem("tableSizeRange"))
                                ?.companyInvoiceDetails
                            : filterInvoicesData?.length
                        }
                        pagination={window.screen.width > 1023 ? true : false}
                      />
                    </TabPane>
                    {/* <TabPane tab={<span>Quotes</span>} key="5">
                      <BaseTable
                        className="quotes_tableclass"
                        columnsData={quotesColumn}
                        source={quotesDetails}
                        total={quotesDetails.length}
                      /> 
                       {totalQuotesData.map((eachItem) => (
                      <div
                        className="invoice-details quote-details"
                        key={eachItem.id}
                        style={{ marginTop: "24px" }}
                      >
                        <p className="item quote">{eachItem.date}</p>
                        <p className="item">{eachItem.invoiceNo}</p>
                        <p className="item">{eachItem.price}</p>
                        <Popover
                          content={
                            <Row>
                              <Col span={24}>
                                <div
                                  style={{
                                    padding: "5px 5px",
                                    fontSize: "15px",
                                  }}
                                >
                                  <MdModeEditOutline /> Edit
                                </div>
                              </Col>
                              <Col span={24}>
                                <div
                                  style={{
                                    padding: "5px 5px",
                                    fontSize: "15px",
                                  }}
                                >
                                  <MdDelete /> Delete
                                </div>
                              </Col>
                            </Row>
                          }
                          trigger="click"
                          placement="bottom"
                        >
                          <button className="three-dots-button-doc">
                            <BsThreeDots className="three-dots-icon" />
                          </button>
                        </Popover>
                      </div>
                    ))} 
                    </TabPane>
                    */}
                    <TabPane tab={<span>Documents</span>} key="4">
                      <BaseTable
                        className="documents_tableclass table_laptopscreen"
                        columnsData={docuColumn}
                        source={documentsDetails?.sort(
                          (a, b) => moment(b.date) - moment(a?.date)
                        )}
                        total={documentsDetails?.length}
                        handleTableChage={(e) =>
                          this.handleTableChage(e, "companyDocDetails")
                        }
                        sizeRange={
                          window.screen.width > 1023
                            ? JSON.parse(localStorage.getItem("tableSizeRange"))
                                ?.companyDocDetails
                            : documentsDetails?.length
                        }
                        pagination={window.screen.width > 1023 ? true : false}
                      />
                    </TabPane>
                    <TabPane tab={<span>Notes</span>} key="5">
                      <BaseTable
                        className="documents_tableclass table_laptopscreen"
                        columnsData={notesColumns}
                        source={companyData?.notesDetails}
                        total={companyData?.notesDetails?.length}
                        handleTableChage={(e) =>
                          this.handleTableChage(e, "companyNotesDetails")
                        }
                        sizeRange={
                          window.screen.width > 1023
                            ? JSON.parse(localStorage.getItem("tableSizeRange"))
                                ?.companyNotesDetails
                            : companyData?.notesDetails?.length
                        }
                        pagination={window.screen.width > 1023 ? true : false}
                      />
                    </TabPane>
                  </Tabs>
                </Card>
              </Col>
            </Row>
          )}
        </div>

        {this.state?.isModalVisible && (
          <BaseModal
            title={"Update Customer Account Profile"}
            onCancel={() => {
              this.setState({
                isModalVisible: false,
                selectedTerm: "",
              });
            }}
            formId="CompanyDetails"
            className="antmodal_grid headermodal"
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "80%"
                : "640px"
            }
            loading={loading}
          >
            <Form
              onFinish={this.onFinishCompanyDetails}
              id="CompanyDetails"
              ref={this.formRef}
            >
              <Row gutter={[32, 0]}>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={11}
                >
                  <span className="title_changes">Customer Account</span>
                  <Form.Item
                    initialValue={companyData?.company}
                    name="company"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Customer Account",
                      },
                    ]}
                  >
                    <Input
                      // style={{ fontSize: "16px" }}
                      // bordered={false}
                      readOnly
                      className="form-input"
                      placeholder="Please enter your Customer Account"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={11}
                >
                  <span className="title_changes">Company Mailing Address</span>
                  <Form.Item
                    name="address"
                    rules={[
                      {
                        required: false,
                        message: "Please enter your address",
                      },
                    ]}
                  >
                    <GooglePlacesAutocomplete
                      className="form-input"
                      autocompletionRequest={{
                        componentRestrictions: {
                          country: ["us"],
                        },
                      }}
                      selectProps={{
                        defaultInputValue: `${
                          companyData?.address ? companyData?.address : ""
                        }`,
                        citySearch,
                        placeholder: "Search Address...",
                        onChange: (address) => {
                          this.handleSelect(address);
                          this.getAddress(address);
                        },
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={11,offset:2}
                >
                  <span className="title_changes">Office Email</span>
                  <Form.Item
                    initialValue={companyData?.email}
                    name="email"
                    rules={[
                      { required: false, message: "Please enter your email!" },
                      {
                        type: "email",
                        message: "The input is not valid Corporate Email",
                      },
                    ]}
                  >
                    <Input
                      className="form-input"
                      placeholder="Please enter your email"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={11}
                >
                  <span className="title_changes">Office Phone</span>
                  <Form.Item
                    initialValue={companyData?.phone}
                    name="phone"
                    rules={[
                      {
                        required: false,
                        message: "Please enter your phone number",
                      },
                    ]}
                  >
                    <NumberFormat
                      className="ant-input-number numberictest"
                      format="(###) ###-####"
                      style={{ width: "100%" }}
                      placeholder="Please enter your phone number"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={11}
                >
                  <span className="title_changes">Account Terms</span>
                  <Form.Item
                    name="accountTerms"
                    rules={[
                      {
                        required: true,
                        message: "type your Account Terms",
                      },
                    ]}
                    initialValue={
                      companyData?.accountTerms
                        ? companyData?.accountTerms
                        : "Net 0"
                    }
                  >
                    <Select
                      size="large"
                      allowClear
                      className="select_multiplequarytol"
                      placeholder="Account Terms"
                      showArrow
                      style={{ width: "100%" }}
                      onChange={(e) => this.onChangeTerms(e)}
                    >
                      <Option value="Net 0">Cash</Option>
                      <Option value="Net 14">Net 14</Option>
                      <Option value="Net 30">Net 30</Option>
                      <Option value="Custom Input">Custom Input</Option>
                    </Select>

                    {/* <NumberFormat
                      className="ant-input-number numberictest"
                      format="#######"
                      style={{ width: "100%" }}
                      placeholder="Please enter your Account Terms"
                    /> */}
                  </Form.Item>
                </Col>

                {/* <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={11}
                >
                  <span className="title_changes">Account Charge Limit</span>
                  <Form.Item
                    initialValue={companyData?.accountChargeLimit}
                    name="accountChargeLimit"
                    rules={[
                      {
                        required: false,
                        message: "type your Account Charge Limit",
                      },
                    ]}
                  >
                    <NumberFormat
                      className="ant-input-number numberictest"
                      style={{ width: "100%" }}
                      prefix={"$ "}
                      suffix={".00"}
                      thousandSeparator
                      placeholder="Please enter your Account Charge Limit"
                    />
                  </Form.Item>
                </Col> */}
                {selectedTerm === "Custom Input" && (
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={12}
                  >
                    <span className="title_changes">
                      Account Terms (Others)
                    </span>
                    <Form.Item
                      name="accountTermsOthers"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter New Account Terms",
                        },
                      ]}
                    >
                      <NumberFormat
                        className="ant-input-number numberictest"
                        style={{ width: "100%" }}
                        prefix={"Net "}
                        placeholder="Please Enter New Account Terms"
                      />
                    </Form.Item>
                  </Col>
                )}
                {/* <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={11}
                >
                  <span className="title_changes">Net</span>
                  <Form.Item
                    name="net"
                    rules={[
                      {
                        required: false,
                        message: "Please type Net Days!",
                      },
                    ]}
                    initialValue={companyData?.net}
                    className="addontext"
                  >
                    <Input
                      className="form-input"
                      placeholder="Please enter your Net Days"
                      addonAfter="Days"
                      type="number"
                    />
                  </Form.Item>
                </Col> */}
                {/* <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={11}
                >
                  <span className="title_changes">Payment Accepted</span>
                  <Form.Item
                    name="paymentMode"
                    rules={[
                      {
                        required: false,
                        message: "Please choice a payment type!",
                      },
                    ]}
                    initialValue={companyData?.paymentMode}
                  >
                    <Select
                      mode="multiple"
                      className="select_multiplequarytol"
                      placeholder="Payment Type"
                      showArrow
                      style={{ width: "100%" }}
                    >
                      <Option value="Check">Check</Option>
                      <Option value="Credit Card">Credit Card</Option>
                      <Option value="ACH">ACH</Option>
                      <Option value="Automated Clearing House">
                        Automated Clearing House
                      </Option>
                    </Select>
                  </Form.Item>
                </Col> */}

                <Col span={24}>
                  <span className="title_changes">Notes</span>
                  <Form.Item
                    name="notes"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Notes!",
                      },
                    ]}
                    initialValue={companyData?.notes}
                  >
                    <TextArea
                      rows={4}
                      style={{
                        width: "100%",
                        height: "75px",
                        borderColor: "#d6d6d6",
                      }}
                      className="form-input"
                      placeholder="Please Enter Your Notes"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}

        {isDocModalVisible && (
          <BaseModal
            title="Add Document"
            visible={this.state.isDocModalVisible}
            onCancel={() => {
              this.setState({
                isDocModalVisible: false,
                quoteAttachment: "",
                selectedAssignTo: "",
              });
            }}
            formId="addDocument"
            className="antmodal_grid headermodal"
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "80%"
                : "520px"
            }
            loading={loading}
            disabled={imageLoading ? true : false}
          >
            <Form
              onFinish={this.onFinishDoc}
              ref={this.formRef}
              id="addDocument"
            >
              <Row gutter={[32, 0]}>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={24}
                >
                  <span className="title_changes">Date</span>
                  <Form.Item
                    name="date"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Date!",
                      },
                    ]}
                    initialValue={moment()}
                  >
                    <DatePicker
                      style={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "5px",
                      }}
                      defaultValue={moment()}
                      format={"MM/DD/YYYY"}
                    />
                  </Form.Item>
                </Col>

                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={12}
                >
                  <span className="title_changes">Document Name</span>
                  <Form.Item
                    name="documentName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Document Name",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      className="form-input"
                      placeholder="Please Enter Document Name"
                    />
                  </Form.Item>
                </Col>

                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={12}
                >
                  <span className="title_changes">Assign To</span>
                  <Form.Item
                    name="assignTo"
                    rules={[
                      {
                        required: true,
                        message: "Please Select One",
                      },
                    ]}
                  >
                    <Radio.Group
                      style={{
                        // marginTop: "5px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-around",
                        // flexWrap: "wrap",
                        // maxWidth:"100%",
                        padding: "8px",
                        border: "1px solid #e5e5e5",
                        height: "40px",
                        borderRadius: "5px",
                      }}
                      onChange={(e) => {
                        this.setState({
                          selectedAssignTo: e?.target?.value,
                          jobIdState: undefined,
                        });
                        this.formRef.current.setFieldsValue({
                          leads: undefined,
                        });
                      }}
                      name="radiogroup"
                      defaultValue={1}
                    >
                      <Radio value={"Job"}>Job</Radio>
                      <Radio value={companyData?.id}>Company</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={12}
                >
                  <span className="title_changes">Type</span>
                  <Form.Item
                    name="docType"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Document  Status",
                      },
                    ]}
                    // initialValue="Contract"
                  >
                    <Select
                      className="select_Height"
                      placeholder="Please Select Doc Type"
                      showArrow
                      style={{ width: "100%" }}
                    >
                      <Option value="Contract">Contract</Option>
                      <Option value="Insurance">Insurance</Option>
                      <Option value="Others">Others</Option>
                    </Select>
                  </Form.Item>
                </Col>

                {selectedAssignTo === "Job" && (
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={12}
                  >
                    <span className="title_changes">
                      Select A Job To Assign
                    </span>
                    <Form.Item name="leads">
                      <Select
                        value={jobIdState}
                        size="large"
                        showSearch
                        allowClear
                        className="select_Height"
                        // style={{ height: "40px" }}
                        placeholder="Please Select A Job Number"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onChange={(e) =>
                          this.setState({
                            jobIdState: totalLeadsData?.filter(
                              (t) => t?.id === e
                            )[0]?.jobNumber,
                          })
                        }
                      >
                        {totalLeadsData?.map((eachItem) => (
                          <Option
                            key={eachItem.id}
                            value={eachItem.id}
                            className="option_style"
                          >
                            {eachItem?.jobNumber}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                {/* <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={24}
                >
                  <span className="title_changes">Description</span>
                  <Form.Item
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Description",
                      },
                    ]}
                  >
                    <TextArea
                      rows={4}
                      style={{
                        width: "100%",
                      }}
                      className="form-input"
                      placeholder="Please Enter Your Description"
                    />
                  </Form.Item>
                </Col> */}
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={24}
                >
                  <span className="title_changes">Attachment</span>
                  <Form.Item
                    name="docAttachment"
                    rules={[
                      {
                        required: false,
                        message: "Please Attach Your Document",
                      },
                    ]}
                  >
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      style={{
                        borderRadius: "50%",
                        width: "100%",
                        height: "100%",
                      }}
                      showUploadList={false}
                      customRequest={dummyRequest}
                      onChange={(e) => this.handleUploadPic(e)}
                    >
                      <Spin spinning={imageLoading}>
                        {quoteAttachment ? (
                          fileType === "application/pdf" ? (
                            <FilePdfOutlined
                              style={{
                                fontSize: "30px",
                                height: "30px",
                                marginRight: "5px",
                              }}
                            />
                          ) : fileType ===
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                            <GrDocumentText
                              style={{
                                fontSize: "30px",
                                height: "30px",
                                marginRight: "5px",
                              }}
                            />
                          ) : fileType ===
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                            <SiGooglesheets
                              style={{
                                fontSize: "30px",
                                height: "30px",
                                marginRight: "5px",
                              }}
                            />
                          ) : (
                            <img
                              src={`${quoteAttachment}`}
                              alt="avatar"
                              style={{ width: "100px", height: "100%" }}
                            />
                          )
                        ) : (
                          <CameraOutlined className="camera-icon" />
                        )}
                      </Spin>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}

        {leadVisible && (
          <BaseModal
            title={
              jobData !== ""
                ? `Update Job #${jobData?.jobNumber}`
                : "Add New Job"
            }
            onCancel={() => {
              this.setState({
                leadVisible: false,
                truckDropInfo: false,
                isCheckBoxMaterial: false,
                isCheckBox: false,
                selectedMaterial: [],
                isCheckBoxTruckType: false,
                jobData: "",
                materialPopup: false,
                salesCommission: "",
              });
            }}
            loading={loading}
            visible={leadVisible}
            destroyOnClose={true}
            formId="modalform"
            className="antmodal_grid headermodal"
            width={"80%"}
          >
            <Form
              onFinish={this.onFinish}
              initialValues={{ remember: true }}
              id="modalform"
              ref={this.formContact}
            >
              <Loading enableLoading={modallLoading} />
              <Row gutter={[24, 0]}>
                {/* <Col span={6}>
                  <span className="title_changes">Company</span>
                  <Form.Item
                    name="company"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Company!",
                      },
                    ]}
                    initialValue={
                      companyData?.company ? companyData?.company : ""
                    }
                  >
                    <Input bordered={false} readOnly className="form-input" />
                  </Form.Item>
                </Col> */}
                <Col
                  xxl={{ span: 6 }}
                  xl={{ span: 6 }}
                  lg={{ span: 12 }}
                  md={{ span: 12 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Job Name</span>
                  <Form.Item
                    name={"jobName"}
                    initialValue={
                      jobData?.jobName ? jobData?.jobName : jobData?.jobNumber
                    }
                  >
                    <Input
                      placeholder="Please Enter Job Name"
                      className="form-input"
                      maxLength={12}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 6 }}
                  xl={{ span: 6 }}
                  lg={{ span: 12 }}
                  md={{ span: 12 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Contact Name</span>
                  <div className="contactname-multiple">
                    <Form.Item
                      name="contactperson"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Your Contact Name!",
                        },
                      ]}
                      initialValue={
                        // jobData?.multipleContacts &&
                        // jobData?.multipleContacts.map((c) => c?.id)
                        jobData?.multipleContacts
                          ? jobData?.multipleContacts.map((c) =>
                              c?.companyId === jobData?.companyId
                                ? c?.id
                                : c?.firstName + " " + c?.lastName
                            )
                          : newJobContacts?.map((e) => e?.id)
                      }
                    >
                      <Select
                        size="large"
                        allowClear
                        mode="multiple"
                        showSearch
                        className="select_multiplequarytol"
                        style={{
                          height: "auto !important",
                          width: "100% !important",
                        }}
                        placeholder="Please Select A Contact Name"
                        // style={{ height: "40px" }}
                        onChange={(e) => this.handleSelectContact(e)}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {contactsData?.map((eachItem) => (
                          <Option
                            key={eachItem.id}
                            value={eachItem.id}
                            className="option_style"
                          >
                            {eachItem.firstName + " " + eachItem.lastName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Tooltip
                      title="Add Contact"
                      overlayStyle={{
                        backGroundColor: "#000",
                        color: "#000",
                      }}
                    >
                      <div
                        style={{
                          // width: "100%",
                          fontWeight: "500",
                          // height: "25px",
                          fontSize: "20px",
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "auto",
                          padding: "8px",
                          position: "absolute",
                          top: "0px",
                          right: "0px",
                          height: "40px",
                          borderLeft: "1px solid #ddd",
                          zIndex: "+9",
                          cursor: "pointer",
                        }}
                        onClick={
                          () =>
                            // this.state.companyCheck !== ""
                            //   ?
                            this.setState({
                              isContactModalVisible: true,
                              jobContactData: "",
                            })
                          // : message.info(
                          //     "Please Enter Your Company Name First!"
                          //   )
                        }
                      >
                        {/* ADD NEW CONTACT */}
                        <UsergroupAddOutlined />
                      </div>
                    </Tooltip>
                  </div>
                </Col>

                <Col
                  xxl={{ span: 6 }}
                  xl={{ span: 6 }}
                  lg={{ span: 12 }}
                  md={{ span: 12 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Job Type</span>
                  <Form.Item
                    name="jobType"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Job Type!",
                      },
                    ]}
                    initialValue={jobInfo || jobData?.jobType}
                  >
                    <Select
                      disabled={
                        jobData?.stageId === "Dispatch Approved"
                          ? true
                          : jobData?.quoteStatus === "Accepted"
                          ? true
                          : false
                      }
                      size="large"
                      defaultValue={jobData?.jobType}
                      showSearch
                      allowClear
                      className="select_Height"
                      // style={{ height: "40px" }}
                      onChange={(value) =>
                        this.setState({
                          jobInfo: value,
                          truckDropInfo: "",
                          selectedMaterial: [],
                        })
                      }
                      placeholder="Please Select A Customer Account"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {jobType.map((eachItem) => (
                        <Option
                          key={eachItem.label}
                          value={eachItem.label}
                          className="option_style"
                        >
                          {eachItem.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col
                  xxl={{ span: 6 }}
                  xl={{ span: 6 }}
                  lg={{ span: 12 }}
                  md={{ span: 12 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={24}
                >
                  <span className="title_changes">Delivery Location</span>
                  <Form.Item
                    name="deliveryAddress"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Delivery Location",
                      },
                    ]}
                    style={{ marginBottom: "10px" }}
                  >
                    <GooglePlacesAutocomplete
                      className="form-input"
                      autocompletionRequest={{
                        componentRestrictions: {
                          country: ["us"],
                        },
                      }}
                      selectProps={{
                        //citySearch,
                        defaultInputValue: `${
                          jobData?.dropoff ? jobData?.dropoff : ""
                        }`,
                        placeholder: "Search Address...",
                        onChange: (address) => {
                          this.handleSelect(address, "dropOff");
                          this.getAddress(address, "dropOff");
                          this.setState({
                            dropAddress: address.label,
                          });
                        },
                      }}
                    />
                  </Form.Item>
                </Col>

                {/* {(jobInfo === "Trucking Job" ||
                  jobInfo === "Trucking & Material Job") && (
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 12 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Requested Trucks</span>
                    <Form.Item
                      name="truckType"
                      rules={[
                        {
                          required: false,
                          message: "Please Select Trucks",
                        },
                      ]}
                      style={{ marginBottom: "10px" }}
                    >
                      <div className="chckbox-truck">
                        <Checkbox.Group
                          style={{
                            width: "100%",
                          }}
                          onChange={this.onChangeTrucksType}
                          defaultValue={jobData?.truckType}
                        >
                          <Row style={{ width: "100%" }}>
                            {!truckDropInfo && (
                              <>
                                {trucksData?.map((t) => (
                                  <Col
                                    xxl={{ span: 8 }}
                                    xl={{ span: 8 }}
                                    lg={{ span: 12 }}
                                    md={{ span: 12 }}
                                    sm={{ span: 24 }}
                                    xs={{ span: 24 }}
                                  >
                                    <Checkbox value={t.value} key={t.value}>
                                      <span
                                        style={{
                                          fontSize: "15px",
                                          fontWeight: "700",
                                          color: "rgb(88,99,112)",
                                        }}
                                      >
                                        {t.value}
                                      </span>
                                    </Checkbox>
                                  </Col>
                                ))}
                              </>
                            )}
                          </Row>
                        </Checkbox.Group>
                      </div>
                    </Form.Item>
                    {truckDropInfo && (
                      <Row>
                        <Col
                          xxl={{ span: 24 }}
                          xl={{ span: 24 }}
                          lg={{ span: 12 }}
                          md={{ span: 12 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <span className="title_changes">Truck Type</span>
                          <Form.Item
                            name="truckOther"
                            rules={[
                              {
                                required: false,
                                message: "Please Input Your Truck Type!",
                              },
                            ]}
                            // initialValue={jobData?.truckType}
                          >
                            <Input
                              className="form-input"
                              placeholder="Please add truck type"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Checkbox
                            checked={this.state.isCheckBox}
                            onChange={this.onChangeCheckBox}
                            style={{ marginBottom: "18px" }}
                          >
                            <span
                              className="checkBox_size"
                              style={{ fontSize: "14px" }}
                            >
                              Add this new truck type to default list
                            </span>
                          </Checkbox>
                        </Col>
                      </Row>
                    )}
                  </Col>
                )} */}

                <Col
                  xxl={{ span: 6 }}
                  xl={{ span: 6 }}
                  lg={{ span: 12 }}
                  md={{ span: 12 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Sales Person</span>
                  <Form.Item
                    name="salesPerson"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Sales Person!",
                      },
                    ]}
                    initialValue={jobData?.salesPersonId}
                  >
                    <Select
                      size="large"
                      defaultValue={jobData?.salesPersonId}
                      showSearch
                      allowClear
                      className="select_Height"
                      onChange={(val, option) =>
                        this.setState({
                          salesCommission: option.salesCommission,
                        })
                      }
                      placeholder="Please Select A Customer Account"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {this.props.context.salesPersonsData?.map((eachItem) => (
                        <Option
                          key={eachItem.id}
                          value={eachItem.id}
                          salesCommission={eachItem.salesCommission}
                          className="option_style"
                        >
                          {eachItem?.firstName + " " + eachItem?.lastName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col
                  xxl={{ span: 6 }}
                  xl={{ span: 6 }}
                  lg={{ span: 12 }}
                  md={{ span: 12 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Notes</span>
                  <Form.Item
                    name="notes"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Notes!",
                      },
                    ]}
                    initialValue={jobData?.notes}
                  >
                    <TextArea
                      rows={4}
                      style={{
                        width: "100%",
                        height: "75px",
                        borderColor: "#d6d6d6",
                      }}
                      className="form-input"
                      placeholder="Please Enter Your Notes"
                    />
                  </Form.Item>
                </Col>
                {/* {jobInfo === "Trucking & Material Job" && (
                  <>
                    <Col
                      xxl={{ span: 6 }}
                      xl={{ span: 6 }}
                      lg={{ span: 12 }}
                      md={{ span: 12 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Tax exempt status</span>
                      <Form.Item
                        name="taxExempt"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Tax exempt!",
                          },
                        ]}
                        initialValue={
                          jobData?.taxExempt
                            ? jobData?.taxExempt
                            : "Material is quoted as"
                        }
                      >
                        {
                          <Select
                            size="large"
                            showSearch
                            allowClear
                            className="select_Height"
                            onChange={(e) => this.setState({ openDropdown: e })}
                            placeholder="Please Select A Tax exempt"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          >
                            <Option value="Tax Exempt">Tax Exempt</Option>
                            <Option value="Material is quoted as">
                              Not Tax Exempt
                            </Option>
                          </Select>
                        }
                      </Form.Item>
                    </Col>
                    {this.state.openDropdown === "Material is quoted as" && (
                      <Col
                        xxl={{ span: 6 }}
                        xl={{ span: 6 }}
                        lg={{ span: 12 }}
                        md={{ span: 12 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <span className="title_changes">
                          Material is quoted as
                        </span>
                        <Form.Item
                          name="taxIncluded"
                          rules={[
                            {
                              required: true,
                              message: "Please Select Tax exempt!",
                            },
                          ]}
                          initialValue={
                            jobData?.taxIncluded
                              ? jobData?.taxIncluded
                              : "Tax Added"
                          }
                        >
                          <Select
                            size="large"
                            showSearch
                            allowClear
                            className="select_Height"
                            onChange={() =>
                              this.setState({
                                openDropdown: "Material is quoted as",
                              })
                            }
                            placeholder=""
                          >
                            <Option value="Tax Included">Tax Included </Option>
                            <Option value="Tax Added">Pre-Tax</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                  </>
                )} */}
              </Row>

              {jobInfo === "Trucking & Material Job" && (
                <>
                  {jobData?.requiredMaterials && (
                    <Row
                      span={24}
                      justify="center"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <Col
                        className="title_changes"
                        style={{
                          fontSize: "17px",
                          fontWeight: "700",
                          textAlign: "center",
                          color: "rgb(88,99,112)",
                          // borderBottom: "1px solid #586370",
                        }}
                      >
                        Material Notes:
                      </Col>
                      <Col
                        className="title_changes"
                        style={{
                          fontSize: "17px",
                          fontWeight: "700",
                          marginLeft: "12px",
                        }}
                      >
                        {` ${jobData?.requiredMaterials}`}
                      </Col>
                    </Row>
                  )}
                </>
              )}

              {/* {jobInfo !== "Trucking Job" && (
                <Row>
                  <Row
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px",
                      marginTop: "15px",
                    }}
                  >
                    <span
                      style={{
                        marginBottom: "10px",
                        display: "flex",
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "rgb(88,99,112)",
                        textTransform: "capitalize",
                      }}
                      className="detail-heading table-font-mobile"
                    >
                      Material Details:
                    </span>
                    <span>
                      <Button
                        icon={<PlusOutlined />}
                        style={{
                          // width: "100%",
                          // height: "40px",
                          backgroundColor: "#586370",
                          color: "#ffffff",
                          borderRadius: "3px",
                        }}
                        onClick={() => {
                          dropAddress !== "" && dropAddress !== undefined
                            ? this.setState({
                                materialPopup: true,
                                materialSelectedData: "",
                                finalMaterialData: [],
                                topVendorsData: [],
                                vendorDetails: "",
                                initialMaterialCost: "",
                                materialPriceState: "",
                                materialTruckPrice: "",
                                duration: "",
                                milesData: "",
                                selectVendor: "",
                                quantityType: "Ton",
                                totalPriceType: "Ton",
                                materialCostType: "Ton",
                                truckingPriceType: "Ton",
                                truckingCostType: "Ton",
                                customRate: false,
                                diameter: false,
                                materialCustomRate: false,
                              })
                            : message.info(
                                "Please Enter Your Delivery Location!"
                              );
                        }}
                      >
                        Add Material
                      </Button>
                    </span>
                  </Row>
                  <Col
                    span={24}
                    style={{ overflowX: "auto" }}
                    className="twistedtable"
                  >
                    <table style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th>Vendor</th>
                          <th>Material</th>
                          <th>Quantity</th>
                          <th>Material Cost</th>
                          <th>Material Price</th>
                          <th>Trucking Cost</th>
                          <th>Trucking Price</th>
                          <th>Landed Price</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedMaterial?.map((data, index) => (
                          <tr
                            onDoubleClick={() => this.editMaterial(data)}
                            style={{ cursor: "pointer" }}
                          >
                            <td>{data?.vendor}</td>
                            <td>{data?.value}</td>
                            <td>
                              {data?.quantity && data?.quantityType
                                ? `${data?.quantity} - ${data?.quantityType}`
                                : ""}
                            </td>
                            <td>
                              {data?.materialCost && data?.materialCostType
                                ? `$ ${parseFloat(
                                    data?.materialCost
                                  )?.toLocaleString("us-EN", {
                                    minimumFractionDigits: 2,
                                  })} / ${data?.materialCostType}`
                                : ""}
                            </td>
                            <td>
                              {data?.totalPrice && data?.totalPriceType
                                ? `$ ${parseFloat(
                                    data?.totalPrice
                                  )?.toLocaleString("us-EN", {
                                    minimumFractionDigits: 2,
                                  })} / ${data?.totalPriceType}`
                                : ""}
                            </td>
                            <td>
                              {data?.truckingCost && data?.truckingCostType
                                ? `$ ${parseFloat(
                                    data?.truckingCost
                                  )?.toLocaleString("us-EN", {
                                    minimumFractionDigits: 2,
                                  })} / ${data?.truckingCostType}`
                                : ""}
                            </td>
                            <td>
                              {data?.truckingPrice && data?.truckingPriceType
                                ? `$ ${parseFloat(
                                    data?.truckingPrice
                                  )?.toLocaleString("us-EN", {
                                    minimumFractionDigits: 2,
                                  })} / ${data?.truckingPriceType}`
                                : ""}
                            </td>
                            <td>
                              {data?.landedPrice === "N/A"
                                ? data?.landedPrice
                                : `$ ${parseFloat(
                                    data?.landedPrice
                                  )?.toLocaleString("us-EN", {
                                    minimumFractionDigits: 2,
                                  })} / ${data?.totalPriceType}`}
                            </td>
                            <td style={{ display: "flex", margin: "6px" }}>
                              <Button
                                style={{ marginLeft: "6px", width: "65px" }}
                                onClick={() => this.editMaterial(data)}
                                type="primary"
                              >
                                Edit
                              </Button>
                              <Button
                                style={{ marginLeft: "6px" }}
                                onClick={() => this.deleteMaterial(data)}
                                type="primary"
                              >
                                Delete
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Col>
                </Row>
              )} */}

              {/* {jobInfo === "Trucking Job" && (
                <Row>
                  <Row
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px",
                      marginTop: "15px",
                    }}
                  >
                    <span
                      style={{
                        marginBottom: "10px",
                        display: "flex",
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "rgb(88,99,112)",
                        textTransform: "capitalize",
                      }}
                    >
                      Trucking Details:
                    </span>
                    <span>
                      <Button
                        icon={<PlusOutlined />}
                        style={{
                          // width: "100%",
                          // height: "40px",
                          backgroundColor: "#586370",
                          color: "#ffffff",
                          borderRadius: "3px",
                        }}
                        onClick={() => {
                          dropAddress !== "" && dropAddress !== undefined
                            ? this.setState({
                                materialPopup: true,
                                materialSelectedData: "",
                                finalMaterialData: [],
                                selectVendor: "",
                                duration: "",
                                milesData: "",
                                truckingCostType: "Ton",
                                truckingPriceType: "Ton",
                                customRate: false,
                                materialCustomRate: false,
                              })
                            : message.info(
                                "Please Enter Your Delivery Location!"
                              );
                        }}
                      >
                        Add Trucking Details
                      </Button>
                    </span>
                  </Row>
                  <Col
                    span={24}
                    style={{ overflowX: "auto" }}
                    className="twistedtable"
                  >
                    <table style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th>Pickup Location</th>
                          <th>Trucking Cost</th>
                          <th>Trucking Price</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedMaterial?.map((data, index) => (
                          <tr
                            onDoubleClick={() => this.editMaterial(data)}
                            style={{ cursor: "pointer" }}
                          >
                            <td>{data?.pickUpAddress}</td>
                            <td>
                              {data?.truckingCost && data?.truckingCostType
                                ? `$ ${parseFloat(
                                    data?.truckingCost
                                  )?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                  })} / ${data?.truckingCostType}`
                                : ""}
                            </td>
                            <td>
                              {data?.truckingPrice && data?.truckingPriceType
                                ? `$ ${parseFloat(
                                    data?.truckingPrice
                                  )?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                  })} / ${data?.truckingPriceType}`
                                : ""}
                            </td>
                            <td>
                              <Button
                                style={{ marginLeft: "6px", width: "80px" }}
                                onClick={() => this.editMaterial(data)}
                                type="primary"
                              >
                                Edit
                              </Button>
                              <Button
                                style={{ marginLeft: "6px" }}
                                onClick={() => this.deleteMaterial(data)}
                                type="primary"
                              >
                                Delete
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Col>
                </Row>
              )} */}
            </Form>
          </BaseModal>
        )}

        {isContactModalVisible && (
          <BaseModal
            title={jobContactData !== "" ? "Update Contact" : "Add Contact"}
            onCancel={() =>
              this.setState({
                isContactModalVisible: false,
                newRole: "",
                roleCheckBox: false,
              })
            }
            loading={loading}
            formId="contact"
            className="antmodal_grid headermodal"
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "80%"
                : "520px"
            }
          >
            <Form onFinish={this.onFinishContact} id="contact">
              <Row gutter={[32, 0]}>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 12 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">First Name</span>
                  <Form.Item
                    name="fName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your First Name!",
                      },
                    ]}
                    initialValue={jobContactData?.firstName}
                  >
                    <Input
                      className="form-input"
                      placeholder="Please Enter Your First Name"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 12 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Last Name</span>
                  <Form.Item
                    name="lName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter your Last Name!",
                      },
                    ]}
                    initialValue={jobContactData?.lastName}
                  >
                    <Input
                      className="form-input"
                      placeholder="Please Enter your Last Name"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <span className="title_changes">Phone</span>
                  <Form.Item
                    name="phone"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Phone!",
                      },
                    ]}
                    initialValue={jobContactData?.phone}
                  >
                    <NumberFormat
                      className="ant-input-number numberictest"
                      format="(###) ###-####"
                      style={{ width: "100%" }}
                      placeholder="Please Enter Your Phone"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <span className="title_changes">Email</span>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Email!",
                      },
                      {
                        type: "email",
                        message: "The Input Is Not Valid Email",
                      },
                    ]}
                    initialValue={jobContactData?.email}
                  >
                    <Input
                      className="form-input"
                      placeholder="Please Enter Your Email"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Role</span>
                  {/* <span
                    style={{
                      color: "#878787",
                      fontSize: "15px",
                      marginLeft: "5px",
                      fontWeight: "400",
                    }}
                  >
                    (If you want add new role, please input)
                  </span> */}
                  <Form.Item
                    name="role"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Role!",
                      },
                    ]}
                    initialValue={jobContactData?.contactRole}
                  >
                    <Select
                      size="large"
                      showSearch
                      allowClear
                      className="select_Height"
                      // style={{ height: "40px" }}
                      placeholder="Please Select A Customer Account"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(value) => {
                        this.setState({ newRole: value });
                        value === "Others" &&
                          message.info("Please Enter New Role");
                      }}
                    >
                      {rolesData?.map((role) => (
                        <Option key={role.id} value={role.label}>
                          {role.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {newRole === "Add New Role" && (
                  <>
                    <Col span={24}>
                      <span className="title_changes">Add New Role</span>
                      <Form.Item
                        name="roleOther"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter New Role!",
                          },
                        ]}
                        //initialValue={jobContactData?.contactRole}
                      >
                        <Input
                          className="form-input"
                          placeholder="Add New Role"
                        />
                      </Form.Item>

                      <Checkbox
                        checked={this.state.roleCheckBox}
                        onChange={(e) =>
                          this.setState({ roleCheckBox: e.target.checked })
                        }
                        style={{ marginBottom: "18px" }}
                      >
                        <span
                          className="checkBox_size"
                          style={{ fontSize: "14px" }}
                        >
                          Add this new role to default list
                        </span>
                      </Checkbox>
                    </Col>
                  </>
                )}

                <Col span={24}>
                  <span className="title_changes">Notes</span>
                  <Form.Item
                    name="notes"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Notes!",
                      },
                    ]}
                    initialValue={
                      jobContactData?.notes ? jobContactData?.notes : ""
                    }
                  >
                    <TextArea
                      rows={4}
                      style={{
                        width: "100%",
                        height: "75px",
                        borderColor: "#d6d6d6",
                      }}
                      className="form-input"
                      placeholder="Please Enter Your Notes"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}

        {materialPopup && (
          <BaseModal
            title={
              recordMaterialData?.length !== 0 && jobInfo === "Trucking Job"
                ? "Update Trucking Details"
                : recordMaterialData?.length !== 0 && jobInfo !== "Trucking Job"
                ? "Update Material Details"
                : jobInfo !== "Trucking Job"
                ? "Add Material Details"
                : "Add Trucking Details"
            }
            onCancel={this.onCancelMaterial}
            loading={loading}
            formId="material"
            // width="40%"
            className="antmodal_grid headermodal"
            width={
              jobInfo === "Trucking & Material Job" ? "55%" : "35%"
              // window.screen.width > "501" && window.screen.width <= "991"
              //   ? "80%"
              //   : "560px"
            }
            submitButton={
              jobInfo === "Trucking & Material Job" &&
              topVendorsData?.length === 0
                ? "Find Nearest Vendors"
                : recordMaterialData?.length !== 0 && jobInfo !== "Trucking Job"
                ? "Update"
                : "Add"
            }
            btnWidth={"fit-content"}
          >
            <Loading enableLoading={loading} />
            <Form
              onFinish={
                jobInfo === "Trucking & Material Job" &&
                topVendorsData?.length === 0
                  ? this.getTopVendors
                  : this.onFinishMaterial
              }
              initialValues={{ remember: true }}
              id="material"
              ref={this.formRef}
              style={{ width: "100%" }}
            >
              <Row style={{ width: "100%" }} gutter={[24, 0]}>
                {jobInfo === "Trucking & Material Job" && (
                  <>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Material Categories</span>
                      <Form.Item
                        name="materialCategory"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Your Material!",
                          },
                        ]}
                        initialValue={recordMaterialData?.category}
                      >
                        <Select
                          size="large"
                          showSearch
                          allowClear
                          className="select_Height"
                          placeholder="Please Select A Material"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onClear={() =>
                            this.setState({
                              materialSelectedData: "",
                            })
                          }
                          onChange={(value) => {
                            this.onSelectMaterialCat(value);
                            this.setState({
                              materialValue: value,
                              initialMaterialCost: "",
                              materialPriceState: "",
                              materialCostState: "",
                              materialTruckPrice: "",
                            });
                            this.formRef.current.setFieldsValue({
                              material: "",
                              quantity: "",
                              totalPrice: "",
                              truckingPrice: "",
                            });
                          }}
                        >
                          {Object.keys(materialCategory).map((c, i) => (
                            <Option key={i} value={c}>
                              {c}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">
                        Material Subcategory
                      </span>
                      <Form.Item
                        name="material"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Your Material!",
                          },
                        ]}
                        initialValue={recordMaterialData?.label}
                      >
                        <Select
                          size="large"
                          showSearch
                          allowClear
                          onClear={() =>
                            this.setState({
                              materialSelectedData: "",
                            })
                          }
                          className="select_Height"
                          placeholder="Please Select A Material"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={this.onSelectMaterial}
                        >
                          {finalMaterialData?.map(
                            (m) =>
                              m.statusCode && (
                                <Option key={m?.id} value={m?.id}>
                                  {m?.label}
                                </Option>
                              )
                          )}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col
                      span={12}
                      style={{ paddingLeft: "0px", paddingRight: "0px" }}
                    >
                      <Col span={24}>
                        <span className="title_changes">Testing Status</span>
                        <Form.Item
                          name="materialTest"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Testing!",
                            },
                          ]}
                          initialValue={recordMaterialData?.materialTest}
                          style={{ marginBottom: "10px" }}
                        >
                          <Select
                            size="large"
                            showSearch
                            allowClear
                            className="select_Height"
                            // style={{ height: "40px" }}
                            placeholder="Please Select A testMaterial"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onChange={this.resetvendorValues}
                          >
                            {QuarryJsonData?.testMaterial?.map((m, i) => (
                              <Option key={i} value={m}>
                                {m}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      {diameter ? (
                        <Col span={24} style={{ marginTop: "20px" }}>
                          <Row gutter={[12, 0]}>
                            <Col span={12}>
                              <span className="title_changes">Min</span>
                              <Form.Item
                                name="materialMinDiameter"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Diameter!",
                                  },
                                ]}
                                initialValue={
                                  recordMaterialData?.materialMinDiameter
                                }
                                style={{ marginBottom: "10px" }}
                              >
                                {/* <InputNumber
                                  formatter={(value) =>
                                    value !== "" ? `${value}"` : ""
                                  }
                                  style={{ width: "100%", height: "40px" }}
                                  placeholder="Please enter your diameter"
                                  className="form-input"
                                  onChange={this.resetvendorValues}
                                /> */}
                                <Input
                                  style={{
                                    height: "40px",
                                    width: "100%",
                                  }}
                                  className="form-input"
                                  placeholder="Please enter diameter"
                                  onChange={this.resetvendorValues}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span className="title_changes">Max</span>
                                <Switch
                                  checkedChildren={"Diameter"}
                                  unCheckedChildren={"Range"}
                                  onChange={(e) =>
                                    this.setState({ diameter: e })
                                  }
                                  checked={diameter}
                                >
                                  Size Range
                                </Switch>
                              </span>
                              <Form.Item
                                name="materialMaxDiameter"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Diameter!",
                                  },
                                ]}
                                initialValue={
                                  recordMaterialData?.materialMaxDiameter
                                }
                                style={{ marginBottom: "10px" }}
                              >
                                {/* <InputNumber
                                  formatter={(value) =>
                                    value !== "" ? `${value}"` : ""
                                  }
                                  style={{ width: "100%", height: "40px" }}
                                  placeholder="Please enter your diameter"
                                  className="form-input"
                                  onChange={this.resetvendorValues}
                                /> */}
                                <Input
                                  style={{
                                    height: "40px",
                                    width: "100%",
                                  }}
                                  className="form-input"
                                  placeholder="Please enter diameter"
                                  onChange={this.resetvendorValues}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      ) : (
                        <Col span={24} style={{ marginTop: "20px" }}>
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span className="title_changes">Diameter</span>
                            <Switch
                              checkedChildren={"Diameter"}
                              unCheckedChildren={"Range"}
                              onChange={(e) => this.handleCheckStatus(e)}
                            >
                              Size Range
                            </Switch>
                          </span>
                          <Form.Item
                            name="materialDiameter"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Diameter!",
                              },
                            ]}
                            initialValue={recordMaterialData?.materialDiameter}
                            style={{ marginBottom: "10px" }}
                          >
                            <Input
                              style={{
                                height: "40px",
                                width: "100%",
                              }}
                              className="form-input"
                              placeholder="Please enter diameter"
                              onChange={this.resetvendorValues}
                            />
                            {/* <InputNumber
                              formatter={(value) =>
                                value !== "" ? `${value}"` : ""
                              }
                              style={{ width: "100%", height: "40px" }}
                              placeholder="Please enter your diameter"
                              className="form-input"
                              onChange={this.resetvendorValues}
                            /> */}
                          </Form.Item>
                        </Col>
                      )}
                    </Col>
                    <Col
                      span={12}
                      style={{ paddingLeft: "0px", paddingRight: "0px" }}
                    >
                      <Col span={24}>
                        <span className="title_changes">Washed Status</span>
                        <Form.Item
                          name="materialStatus"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter washed status!",
                            },
                          ]}
                          initialValue={recordMaterialData?.materialStatus}
                          style={{ marginBottom: "10px" }}
                        >
                          <Select
                            size="large"
                            showSearch
                            allowClear
                            className="select_Height"
                            // style={{ height: "40px" }}
                            placeholder="Please Select A statusMaterial"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onChange={this.resetvendorValues}
                          >
                            {QuarryJsonData?.statusMaterial?.map((m, i) => (
                              <Option key={i} value={m}>
                                {m}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24} style={{ marginTop: "20px" }}>
                        <span className="title_changes">Supply</span>
                        <Form.Item
                          name="materialSupply"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter supply!",
                            },
                          ]}
                          initialValue={recordMaterialData?.materialSupply}
                          style={{ marginBottom: "10px" }}
                        >
                          <Select
                            size="large"
                            showSearch
                            allowClear
                            className="select_Height"
                            // style={{ height: "40px" }}
                            placeholder="Please Select A Supply"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onChange={this.resetvendorValues}
                          >
                            {QuarryJsonData?.supplyMaterial?.map((m, i) => (
                              <Option key={i} value={m}>
                                {m}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Col>

                    <Loading enableLoading={this.state.loading} />
                    {topVendorsData?.length > 0 && (
                      <Col
                        xxl={{ span: 24 }}
                        xl={{ span: 24 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                        className="tiprotitle"
                        style={{ marginBottom: "0.5vw" }}
                      >
                        <BaseTable
                          columnsData={topVendorsColumns}
                          source={topVendorsData?.slice(0, 3)}
                          total={topVendorsData?.length}
                          className="table_laptopscreen"
                          pagination={false}
                          rowSelection={{
                            type: "radio",
                            ...rowSelection,
                          }}
                          rowKey={(record) => record.id}
                        />
                      </Col>
                    )}

                    {topVendorsData?.length > 0 && (
                      <>
                        <Col
                          xxl={{ span: 8 }}
                          xl={{ span: 8 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <span className="title_changes">Use Markup</span>
                          <Form.Item
                            name="markUp"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Use Markup",
                              },
                            ]}
                            initialValue={recordMaterialData?.quantity}
                          >
                            <InputNumber
                              decimalSeparator="."
                              precision={2}
                              placeholder="Quantity"
                              addonAfter={"%"}
                              style={{
                                height: "40px",
                                width: "100%",
                              }}
                              onChange={(e) => {
                                this.setState({
                                  useMarkUp: e,
                                  materialPriceState:
                                    e === initialMaterialCost &&
                                    e < initialMaterialCost
                                      ? 0
                                      : initialMaterialCost +
                                        initialMaterialCost * (e / 100),
                                });
                                this.formRef.current.setFieldsValue({
                                  totalPrice:
                                    e === 0
                                      ? initialMaterialCost
                                      : e
                                      ? initialMaterialCost +
                                        initialMaterialCost * (e / 100)
                                      : "",
                                });
                              }}
                            />
                          </Form.Item>
                        </Col>
                        {/* <Col
                          xxl={{ span: 8 }}
                          xl={{ span: 8 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <span className="title_changes">
                            {" "}
                            Required Quantity
                          </span>
                          <Form.Item
                            name="quantity"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Quantity",
                              },
                            ]}
                            initialValue={recordMaterialData?.quantity}
                          >
                            <InputNumber
                              // precision={2}
                              // decimalSeparator={"."}
                              placeholder="Quantity"
                              addonAfter={selectAfter}
                              style={{
                                height: "40px",
                                width: "100%",
                              }}
                              onChange={(e) =>
                                this.setState({
                                  quantity: e,
                                  // initialMaterialCost: materialCostPrice,
                                })
                              }
                            />
                          </Form.Item>
                        </Col> */}
                        <Col
                          xxl={{ span: 8 }}
                          xl={{ span: 8 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <span className="title_changes">Material Cost</span>
                          <Form.Item
                            name="materialCost"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Material Cost!",
                              },
                            ]}
                            initialValue={initialMaterialCost?.toLocaleString(
                              2
                            )}
                            style={{ marginBottom: "0px" }}
                          >
                            <InputNumber
                              className="material"
                              formatter={(value) =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }
                              parser={(value) =>
                                value.replace(/\$\s?|(,*)/g, "")
                              }
                              // value={initialMaterialCost?.toLocaleString(2)}
                              prefix={"$"}
                              precision={2}
                              decimalSeparator={"."}
                              placeholder="Please Enter Material Cost"
                              addonAfter={selectAfterMaterial}
                              style={{
                                height: "40px",
                                width: "100%",
                              }}
                              readOnly={!materialCustomRate}
                              onChange={(e) => {
                                this.setState({
                                  materialCostPrice: e,
                                  initialMaterialCost: e,
                                  materialPriceState:
                                    useMarkUp === e && useMarkUp < e
                                      ? 0
                                      : e + e * (useMarkUp / 100),
                                });
                                this.formRef.current.setFieldsValue({
                                  totalPrice:
                                    useMarkUp === 0
                                      ? e
                                      : useMarkUp
                                      ? e + e * (useMarkUp / 100)
                                      : "",
                                });
                              }}
                            />
                          </Form.Item>
                          <div
                            style={{ marginTop: "2px", marginBottom: "5px" }}
                          >
                            <Checkbox
                              onChange={(e) =>
                                this.setState({
                                  materialCustomRate: e.target.checked,
                                })
                              }
                            >
                              <span>Custom Rate</span>
                            </Checkbox>
                          </div>
                        </Col>
                        <Col
                          xxl={{ span: 8 }}
                          xl={{ span: 8 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <span className="title_changes">Material Price</span>
                          <Form.Item
                            name="totalPrice"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Material Price!",
                              },
                            ]}
                            initialValue={recordMaterialData?.totalPrice?.toLocaleString(
                              2
                            )}
                          >
                            <InputNumber
                              prefix={"$"}
                              precision={2}
                              formatter={(value) =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }
                              parser={(value) =>
                                value.replace(/\$\s?|(,*)/g, "")
                              }
                              decimalSeparator={"."} // value={quantity}
                              placeholder="Please Enter Material Price"
                              addonAfter={selectAfterCustomer}
                              style={{
                                height: "40px",
                                width: "100%",
                              }}
                              onChange={(e) => {
                                this.formRef.current.setFieldsValue({
                                  markUp:
                                    ((parseFloat(e) - initialMaterialCost) /
                                      initialMaterialCost) *
                                    100,
                                });
                                this.setState({ materialPriceState: e });
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xxl={{ span: 8 }}
                          xl={{ span: 8 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <span className="title_changes">
                            {" "}
                            Required Quantity
                          </span>
                          <Form.Item
                            name="quantity"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Quantity",
                              },
                            ]}
                            initialValue={recordMaterialData?.quantity}
                          >
                            <InputNumber
                              // precision={2}
                              // decimalSeparator={"."}
                              placeholder="Quantity"
                              addonAfter={selectAfter}
                              style={{
                                height: "40px",
                                width: "100%",
                              }}
                              onChange={(e) =>
                                this.setState({
                                  quantity: e,
                                  // initialMaterialCost: materialCostPrice,
                                })
                              }
                            />
                          </Form.Item>
                        </Col>
                        {/* <Col
                          xxl={{ span: 8 }}
                          xl={{ span: 8 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <span className="title_changes">Use Markup</span>
                          <Form.Item
                            name="markUp"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Use Markup",
                              },
                            ]}
                            initialValue={recordMaterialData?.quantity}
                          >
                            <InputNumber
                              decimalSeparator="."
                              precision={2}
                              placeholder="Quantity"
                              addonAfter={"%"}
                              style={{
                                height: "40px",
                                width: "100%",
                              }}
                              onChange={(e) => {
                                this.setState({
                                  useMarkUp: e,
                                  materialPriceState:
                                    e === initialMaterialCost &&
                                    e < initialMaterialCost
                                      ? 0
                                      : initialMaterialCost +
                                        initialMaterialCost * (e / 100),
                                });
                                this.formRef.current.setFieldsValue({
                                  totalPrice:
                                    e === 0
                                      ? initialMaterialCost
                                      : e
                                      ? initialMaterialCost +
                                        initialMaterialCost * (e / 100)
                                      : "",
                                });
                              }}
                            />
                          </Form.Item>
                        </Col> */}
                      </>
                    )}
                  </>
                )}
                {jobInfo === "Trucking Job" && (
                  <>
                    <Col
                      xxl={{ span: 24 }}
                      xl={{ span: 24 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Pickup Location</span>
                      <Form.Item
                        name="pickup"
                        rules={[
                          {
                            required: false,
                            errorMessage: true,
                            message: "Please Enter Your Pickup Location",
                          },
                        ]}
                      >
                        <GooglePlacesAutocomplete
                          className="form-input"
                          autocompletionRequest={{
                            componentRestrictions: {
                              country: ["us"],
                            },
                          }}
                          selectProps={{
                            citySearch,
                            defaultInputValue: `${
                              recordMaterialData?.pickUpAddress
                                ? recordMaterialData?.pickUpAddress
                                : ""
                            }`,
                            placeholder: "Search Address...",
                            onChange: (address) => {
                              this.handleSelect(address, "pickup");
                              this.getAddress(address, "pickup");
                              this.setState({
                                pickUpAddress: address.label,
                              });
                            },
                          }}
                        />
                      </Form.Item>
                    </Col>
                    {((milesData !== "" && milesData !== undefined) ||
                      recordMaterialData?.milesData !== undefined) && (
                      <Col
                        span={24}
                        className="tiprotitle"
                        style={{ marginBottom: "0.5vw" }}
                      >
                        <span className="title_changes">
                          <b
                            style={{
                              marginBottom: "10px",
                              borderBottom: "1px solid gray",
                            }}
                          >
                            Run and Rate information:
                          </b>
                          {milesData?.text !== undefined ? (
                            <div>
                              {milesData?.text + "les, " + duration?.text}
                            </div>
                          ) : (
                            recordMaterialData?.milesData?.text !==
                              undefined && (
                              <div>
                                {recordMaterialData?.milesData?.text +
                                  "les, " +
                                  recordMaterialData?.duration?.text}
                              </div>
                            )
                          )}
                        </span>
                      </Col>
                    )}
                  </>
                )}
                {((jobInfo === "Trucking & Material Job" &&
                  topVendorsData?.length > 0) ||
                  jobInfo !== "Trucking & Material Job") && (
                  <>
                    <Col
                      xxl={{ span: jobInfo === "Trucking Job" ? 12 : 8 }}
                      xl={{ span: jobInfo === "Trucking Job" ? 12 : 8 }}
                      lg={{ span: jobInfo === "Trucking Job" ? 12 : 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Trucking Price</span>
                      <Form.Item
                        name="truckingPrice"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Trucking Price!",
                          },
                        ]}
                        initialValue={recordMaterialData?.truckingPrice?.toLocaleString(
                          2
                        )}
                      >
                        <InputNumber
                          prefix={"$"}
                          precision={2}
                          decimalSeparator={"."} // value={quantity}
                          placeholder="Please Enter Trucking Price"
                          addonAfter={selectAfterTruckPrice}
                          style={{
                            height: "40px",
                            width: "100%",
                          }}
                          // formatter={(value) =>
                          //   `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          // }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                          // onChange={(e) => this.setState({ materialTruckPrice: e,
                          //   truckingCost:
                          //   parseFloat(e) - parseFloat(e) * (12.5 / 100),

                          // })}
                          onChange={(e) => {
                            this.setState({
                              materialTruckPrice: e,
                              truckingCost:
                                parseFloat(e) - parseFloat(e) * (12.5 / 100),
                            });
                            this.formRef.current.setFieldsValue({
                              truckingCost:
                                parseFloat(e) - parseFloat(e) * (12.5 / 100),
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xxl={{ span: jobInfo === "Trucking Job" ? 12 : 8 }}
                      xl={{ span: jobInfo === "Trucking Job" ? 12 : 8 }}
                      lg={{ span: jobInfo === "Trucking Job" ? 12 : 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Trucking Cost</span>
                      <Form.Item
                        name="truckingCost"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Trucking Cost!",
                          },
                        ]}
                        initialValue={recordMaterialData?.truckingCost?.toLocaleString(
                          2
                        )}
                        style={{ marginBottom: "0px" }}
                      >
                        <InputNumber
                          prefix={"$"}
                          precision={2}
                          // formatter={(value) =>
                          //   `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          // }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                          decimalSeparator={"."} // value={quantity}
                          placeholder="Please Enter Trucking Cost"
                          addonAfter={selectAfterTruck}
                          style={{
                            height: "40px",
                            width: "100%",
                          }}
                          readOnly={!customRate}
                          onChange={(e) => {
                            this.setState({
                              truckingCost: e,
                              materialTruckPrice:
                                parseFloat(e) + parseFloat(e) * (12.5 / 100),
                            });
                            this.formRef.current.setFieldsValue({
                              truckingPrice:
                                parseFloat(e) + parseFloat(e) * (12.5 / 100),
                            });
                          }}
                        />
                      </Form.Item>
                      <div style={{ marginTop: "2px", marginBottom: "5px" }}>
                        <Checkbox
                          onChange={(e) =>
                            this.setState({
                              customRate: e.target.checked,
                            })
                          }
                        >
                          <span>Custom Rate</span>
                        </Checkbox>
                      </div>
                    </Col>
                  </>
                )}
                {jobInfo === "Trucking & Material Job" &&
                  topVendorsData?.length > 0 && (
                    <>
                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      ></Col>

                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Row justify="space-between">
                          <Col span={12}>
                            <span className="title_changes">
                              Landed Cost -{" "}
                            </span>
                          </Col>
                          <Col span={12}>
                            {materialCostType === truckingCostType &&
                            initialMaterialCost &&
                            truckingCost
                              ? `$${parseFloat(
                                  initialMaterialCost + truckingCost
                                )?.toLocaleString("us-EN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                                 / ${materialCostType}`
                              : "N/A"}
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Row>
                          <Col span={12}>
                            <span className="title_changes">
                              Landed Price -{" "}
                            </span>
                          </Col>
                          <Col span={12}>
                            {truckingPriceType === totalPriceType &&
                            materialPriceState &&
                            materialTruckPrice
                              ? `$${parseFloat(
                                  materialPriceState + materialTruckPrice
                                )?.toLocaleString("us-EN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })} / ${truckingPriceType}`
                              : "N/A"}
                          </Col>
                        </Row>
                      </Col>
                      <Divider
                        style={{ width: "100%", border: "1px solid #dbdbdb" }}
                      />
                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Row>
                          <Col span={12}>
                            <span className="title_changes">Total Sale - </span>
                          </Col>
                          <Col span={12}>
                            {truckingPriceType === totalPriceType &&
                            materialPriceState &&
                            quantity &&
                            materialTruckPrice
                              ? `$${parseFloat(
                                  (materialPriceState + materialTruckPrice) *
                                    quantity
                                )?.toLocaleString("us-EN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}`
                              : "N/A"}
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Row>
                          <Col span={12}>
                            <span className="title_changes">
                              Total Expense -{" "}
                            </span>
                          </Col>
                          <Col span={12}>
                            {materialCostType === truckingCostType &&
                            initialMaterialCost &&
                            quantity &&
                            truckingCost
                              ? `$${parseFloat(
                                  (initialMaterialCost + truckingCost) *
                                    quantity
                                )?.toLocaleString("us-EN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}`
                              : "N/A"}
                          </Col>
                        </Row>
                      </Col>

                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Row>
                          <Col span={12}>
                            <span className="title_changes">
                              Total Profit (%) -
                            </span>
                          </Col>
                          <Col span={12}>
                            {truckingPriceType === totalPriceType &&
                            materialCostType === truckingCostType &&
                            materialPriceState !== undefined &&
                            materialPriceState !== "" &&
                            materialTruckPrice !== undefined &&
                            materialTruckPrice !== "" &&
                            quantity !== undefined &&
                            quantity !== "" &&
                            initialMaterialCost !== undefined &&
                            initialMaterialCost !== "" &&
                            materialTruckPrice !== undefined &&
                            materialTruckPrice !== "" &&
                            truckingCost !== undefined &&
                            truckingCost !== ""
                              ? `$${parseFloat(
                                  (materialPriceState + materialTruckPrice) *
                                    quantity -
                                    (initialMaterialCost + truckingCost) *
                                      quantity
                                )?.toLocaleString("us-EN", {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                })} (${
                                  !isNaN(
                                    (
                                      (((materialPriceState +
                                        materialTruckPrice) *
                                        quantity -
                                        (initialMaterialCost + truckingCost) *
                                          quantity) /
                                        ((materialPriceState +
                                          materialTruckPrice) *
                                          quantity)) *
                                      100
                                    )?.toFixed(2)
                                  )
                                    ? (
                                        (((materialPriceState +
                                          materialTruckPrice) *
                                          quantity -
                                          (initialMaterialCost + truckingCost) *
                                            quantity) /
                                          ((materialPriceState +
                                            materialTruckPrice) *
                                            quantity)) *
                                        100
                                      )?.toFixed(2)
                                    : "0.00"
                                }%)`
                              : "N/A"}
                          </Col>
                        </Row>
                      </Col>
                    </>
                  )}
              </Row>
            </Form>
          </BaseModal>
        )}

        {notesPopup && (
          <BaseModal
            title={recordNotesData !== "" ? "Update Notes" : "Add New Notes"}
            onCancel={() => {
              this.setState({
                notesPopup: false,
              });
            }}
            visible={notesPopup}
            destroyOnClose={true}
            formId="addNotes"
            loading={loading}
            className="antmodal_grid headermodal"
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "80%"
                : "520px"
            }
          >
            <Form
              onFinish={this.addNotes}
              initialValues={{ remember: true }}
              id="addNotes"
            >
              <Row gutter={[24, 0]}>
                <Col span={24}>
                  <span className="title_changes">Type of Notes</span>
                  <Form.Item
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Notes!",
                      },
                    ]}
                    initialValue={
                      recordNotesData?.typeofNotes
                        ? recordNotesData?.typeofNotes
                        : ""
                    }
                  >
                    <Input
                      style={{
                        width: "100%",
                        borderColor: "#d6d6d6",
                      }}
                      className="form-input"
                      placeholder="Please Enter Your Type Of Notes"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <span className="title_changes">Notes</span>
                  <Form.Item
                    name="notes"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Notes!",
                      },
                    ]}
                    initialValue={
                      recordNotesData?.notes ? recordNotesData?.notes : ""
                    }
                  >
                    <TextArea
                      rows={4}
                      style={{
                        width: "100%",
                        height: "75px",
                        borderColor: "#d6d6d6",
                      }}
                      className="form-input"
                      placeholder="Please Enter Your Notes"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) =>
  // console.log(state),
  ({
    totalResult: state.reducer?.companyResult,
    result: state?.reducer?.companyResult?.companyData,
    loading: state?.reducer?.companyResult?.loading,
    errorLoading: state.reducer?.companyResult?.error,
    companiesInfo: state.reducer?.companiesInfo,
    contactsInfo: state.reducer?.contactData,
    contactsDelete: state.reducer?.contactsInfo,
    leadsInfo: state.reducer?.leadsInfo,
    leadUpdateInfo: state.reducer?.leadUpdateInfo,
    docResult: state.reducer?.docResult,
    dropDownOptions: state.reducer?.dropDownOptions,
    rolesData: state.reducer?.dropDownOptions?.totalData?.filter(
      (t) => t.typeofDropDown === "roles"
    ),
    materialList: state.reducer?.dropDownOptions?.totalData?.filter(
      (q) => q?.typeofDropDown === "material"
    ),
    trucksData: state.reducer?.dropDownOptions?.totalData?.filter(
      (t) => t.typeofDropDown === "trucksType"
    ),
    dropDownOptionsInfo: state.reducer?.dropDownOptionsInfo,
    topVendorsData: state.reducer.vendorsData,
  });

const mapDispatchToProps = (dispatch) => ({
  actionFetchCompany: (id) => {
    dispatch(getCompany(id));
  },
  actionUpdateCompany: (id, data) => {
    dispatch(updateCompany(id, data));
  },
  actionAddContact: (data) => {
    dispatch(addContact(data));
  },
  actionUpdateContact: (id, data) => {
    dispatch(updateContacts(id, data));
  },
  actionDeleteContact: (id) => {
    dispatch(deleteContactandJob(id));
  },
  actionAddJob: (data) => {
    dispatch(addLead(data));
  },
  actionUpdateJob: (id, data) => {
    dispatch(updateLead(id, data));
  },
  actionAddDoc: (data) => {
    dispatch(addDocument(data));
  },
  actiongetDropDownOptions: () => {
    dispatch(getDropDownOptions());
  },
  actionupdateDropDownOptions: (id, data) => {
    dispatch(updateDropDownOptions(id, data));
  },
  actionFetchTopVendors: (data) => {
    dispatch(getTopVendors(data));
  },
  actionFetchDeleteDoc: (id) => {
    dispatch(deleteDocument(id));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAppContext(withRouter(CompanyDetails)));
