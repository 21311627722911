import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Form, Input, Button, Row, Col, message, Select } from "antd";
import { add, getDetails } from "./api/Apis";
import MainLogo from "./Images/MainLogo.png";
import { Auth } from "aws-amplify";
import NumberFormat from "react-number-format";
import LocationAutocomplete from "./Components/AutoComplete";
import BaseModal from "./Common/BaseModal";
import AppConfig from "./config/AppConfig";
import { withAppContext } from "./Store/AppContext";

const { Option } = Select;

const userProfile = [{ label: "Admin", value: "Admin" }];
class Register extends Component {
  state = {
    loading: false,
    selectedAddress: "",
    visible: false,
    email: "",
    password: "",
    signUpResponseData: [],
    registerData: [],
  };

  componentDidMount() {
    document.title = "Twisted Nail - Register";
  }

  handleSubmit = async (values) => {
    values["address"] = this.state.selectedAddress;
    this.setState({
      loading: true,
      registerData: values,
    });
    let lowerEmail = values.email.toLowerCase();
    try {
      await Auth.signUp(lowerEmail, values.password).then((data) => {
        this.setState({
          visible: true,
          signUpResponseData: data,
          email: lowerEmail,
          password: values.password,
          loading: false,
        });
      });
    } catch (error) {
      message.error(error.message);
      this.setState({
        loading: false,
      });
    }
  };

  handleOk = async (e) => {
    try {
      this.setState({
        loading: true,
      });
      await Auth.confirmSignUp(this.state.email, e.code).then(
        async (result) => {
          this.doLogin();
        }
      );
    } catch (error) {
      this.setState({
        loading: false,
      });
      message.error("Invalid Confirm Code");
    }
  };

  doLogin = async () => {
    try {
      const user = await Auth.signIn(this.state.email, this.state.password);
      const userDetails = Object.assign(
        {},
        {
          token: user.signInUserSession.idToken.jwtToken,
          userid: user.username,
        }
      );
      this.setState({
        visible: false,
      });
      this.saveUser(userDetails);
    } catch (err) {
      message.error("Error: unable to login");
      this.setState({
        loading: false,
        errors: err.message,
        visible: false,
      });
    }
  };

  saveUser = async (userDetails) => {
    const { signUpResponseData, registerData } = this.state;
    const data = {
      entityData: {
        id: signUpResponseData.userSub,
        firstName: registerData.firstName,
        lastName: registerData.lastName,
        phone: registerData.phone,
        email: registerData.email,
        userProfile: registerData.userProfile,
        company: registerData.company,
        address: registerData.address,
        recordStatus: true,
      },
    };

    add(AppConfig["entityNames"]["users"], data).then((result) => {
      if (result.success) {
        this.setState({
          loading: false,
        });
        this.getLoginDetails(userDetails);
        message.success("User created successfully.");
      } else {
        this.setState({
          loading: false,
        });
        message.error(result.errors);
        console.log(result.errors);
      }
    });
  };

  getLoginDetails = (userDetails) => {
    this.props.context.setUserLogin(true);
    getDetails(AppConfig["entityNames"]["users"], userDetails.userid)
      .then((result) => {
        if (result.success) {
          localStorage.setItem("userDetails", JSON.stringify(result.data));
          this.props.history.replace("/app/Dashboard");
          this.setState({
            loading: false,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  getAddress = (address, latLng, id) => {
    this.setState({
      selectedAddress: address,
    });
    // this.props.form.setFieldsValue({
    //   [id]: address,
    // });
    // let latLangs = this.state.latLangs;
    // this.setState({
    //   latLangs,
    // });
  };

  content = (
    <div>
      <p>-Minimum password length: 6</p>
      <p>-Required at least one uppercase letter</p>
      <p>-Required at least one lowercase letter</p>
      <p>-Required at least one number</p>
      <p>-Required at least one nonalphanumeric character</p>
    </div>
  );

  render() {
    const { selectedAddress, visible } = this.state;
    return (
      <div
        style={{ background: "#eff1f3 0% 0% no-repeat padding-box" }}
        className="admin-login-form"
      >
        <div className="signUp-fields">
          <Form onFinish={this.handleSubmit}>
            <div style={{ textAlign: "center", marginBottom: "24px" }}>
              <img src={MainLogo} alt="Logo" style={{ width: "100%" }} />
            </div>
            <Row gutter={10} className="margin_bottom">
              <Col span={12}>
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your first name!",
                    },
                  ]}
                >
                  <Input
                    placeholder="First Name"
                    //   value={this.state.userDetails.firstName}
                    //   onChange={(e) =>
                    //     this.handleOnChange("firstName", e.target.value)
                    //   }
                    className="form-input"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your last name!",
                    },
                  ]}
                >
                  <Input placeholder="Last Name" className="form-input" />
                </Form.Item>
              </Col>
            </Row>
            <Row className="margin_bottom">
              <Col span={24}>
                <Form.Item
                  className="Formitem"
                  name="company"
                  rules={[
                    {
                      required: true,
                      message: "Please input your company name!",
                    },
                  ]}
                >
                  <Input placeholder="Company name" className="form-input" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10} className="margin_bottom">
              <Col span={24}>
                <Form.Item
                  className="Formitem"
                  name="address"
                  rules={[
                    {
                      required: selectedAddress.length !== 0 ? false : true,
                      message: "Please input your Address!",
                    },
                  ]}
                  initialValue={this.state.selectedAddress || ""}
                >
                  <LocationAutocomplete
                    getAddress={(address, latLng) =>
                      this.getAddress(address, latLng, "address")
                    }
                    className="form-input"
                    editMode={false}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10} className="margin_bottom">
              <Col span={12}>
                <Form.Item
                  className="Formitem"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your contact phone!",
                    },
                  ]}
                >
                  <NumberFormat
                    className="ant-input-number"
                    placeholder="Phone"
                    //   value={this.state.userDetails.phone}
                    //   onChange={(e) => this.handleOnChange("phone", e.target.value)}
                    format="(###) ###-####"
                    style={{ width: "100%", border: "1pxx solid " }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input email",
                    },
                    {
                      type: "email",
                      message: "The input is not valid Corporate Email",
                    },
                  ]}
                  //   initialValue={proConatct.town ? proConatct.town : ""}
                >
                  <Input placeholder="Email address" className="form-input" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10} className="margin_bottom">
              <Col span={12}>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password
                    placeholder="Password"
                    autoComplete="new-password"
                    className="form-input"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="confirm"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder="Confirm Password"
                    className="form-input"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10} className="margin_bottom">
              <Col span={24}>
                <Form.Item
                  className="Formitem"
                  name="userProfile"
                  rules={[
                    {
                      required: true,
                      message: "Please input your userProfile!",
                    },
                  ]}
                >
                  <Select
                    className="select_Height"
                    // defaultValue="Adminstrator"
                    placeholder="I am"
                    // onChange={(e) => {
                    //   this.setState({ userprofile: e });
                    // }}
                    style={{
                      border: "#949caa",
                    }}
                  >
                    {userProfile.map((eachItem) => (
                      <Option key={eachItem.value} className="option_style">
                        {eachItem.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row style={{ marginTop: "15px" }} className="margin_bottom">
              <Form.Item style={{ width: "100%", marginBottom: "15px" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.loading}
                  className="login-form-button"
                  style={{
                    backgroundColor: "#949caa",
                    border: "none",
                    height: "40px",
                  }}
                >
                  <span style={{ fontWeight: "500", color: "#FFF" }}>
                    Create an account
                  </span>
                </Button>
              </Form.Item>
            </Row>
            <Form.Item style={{ width: "100%", textAlign: "center" }}>
              <Link to="/login">
                <span style={{ color: "#868b99", fontSize: "16px" }}>
                  Already have an account? Login here
                </span>
              </Link>
            </Form.Item>
          </Form>
        </div>

        {visible && (
          <BaseModal
            title={
              <p
                className="font-family-style font-medium-size"
                style={{ color: "blue", margin: "0px" }}
              >
                Confirm SignUp
              </p>
            }
            onCancel={() => this.setState({ visible: false })}
            formId="registerForm"
            width={"30%"}
            submitButton="Confirm"
          >
            <div style={{ padding: "5px 10px" }}>
              <span className="font-family-style text-size padding register-confirm">
                We have sent you a confirmation code to your email. Please enter
                the code below to complete your signup. Thank you.
              </span>
            </div>
            <Form
              className="padding font-family-style text-size"
              onFinish={this.handleOk}
              style={{ padding: "5px 10px" }}
              id="registerForm"
            >
              <Form.Item
                name="code"
                rules={[
                  {
                    required: true,
                    message: "Please input the code.",
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder="Please input the code."
                  className="form-input"
                  maxLength="6"
                />
              </Form.Item>
            </Form>
          </BaseModal>
        )}
      </div>
    );
  }
}
export default withAppContext(withRouter(Register));
