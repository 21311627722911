import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withAppContext } from "../../../Store/AppContext";
// import { Link } from "react-router-dom";
import { Row, Col, Card, Button, Image } from "antd";
import { BsArrowLeftShort } from "react-icons/bs";
// import BaseTable from "../Common/BaseTable";
import Title from "antd/lib/typography/Title";
import "../../../Stylesheet/invoice.scss";
import { MdDocumentScanner } from "react-icons/md";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import moment from "moment";
import Twisted from "../../../Images/Twisted.png";
class Invoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: "",
      boardView: false,
      listView: true,
      loading: false,
      selectionType: "",
      selectedRowKeys: [],
      record: "",
    };
  }
  componentDidMount() {
    document.title = "Twisted Nail - Invoice";
  }

  onSelectChange = (data) => {
    this.setState({ selectedRowKeys: data });
  };

  render() {
    // const columns = [
    //   {
    //     title: "TICKET#",
    //     dataIndex: "ticket",
    //     key: "ticket",
    //     sorter: false,

    //     render: () => {
    //       return (
    //         <>
    //           <span
    //             className="tic-styl"
    //             style={{
    //               textTransform: "capitalize",
    //               color: "#474747",
    //               fontSize: "16px",
    //               fontFamily: "sans-serif",
    //             }}
    //           >
    //             {paymentData.ticketData.ticketNumber}
    //           </span>
    //         </>
    //       );
    //     },
    //   },
    //   {
    //     title: "Material",
    //     dataIndex: "value",
    //     key: "value",
    //     sorter: false,

    //     render: () => {
    //       return (
    //         <>
    //           <span
    //             className="tic-styl"
    //             style={{
    //               textTransform: "capitalize",
    //               color: "#474747",
    //               fontSize: "16px",
    //               fontFamily: "sans-serif",
    //             }}
    //           >
    //            {paymentData.ticketData.material.map((e)=>e.value +",")}
    //           </span>
    //         </>
    //       );
    //     },
    //   },
    //   {
    //     title: "Quantity",
    //     dataIndex: "quantity",
    //     key: "quantity",
    //     sorter: false,

    //     render: () => {
    //       return (
    //         <>
    //           <span
    //             className="tic-styl"
    //             style={{
    //               textTransform: "capitalize",
    //               color: "#474747",
    //               fontSize: "16px",
    //               fontFamily: "sans-serif",
    //             }}
    //           >
    //             {paymentData.ticketData.quantity}
    //           </span>
    //         </>
    //       );
    //     },
    //   },
    //   {
    //     title: "PRICE ",
    //     dataIndex: "price",
    //     key: "price",
    //     sorter: false,
    //     render: () => {
    //       return (
    //         <>
    //           <span
    //             // className="col_styling"
    //             style={{
    //               textTransform: "capitalize",
    //               color: "#474747",
    //               fontSize: "16px",
    //               fontFamily: "sans-serif",
    //             }}
    //           >
    //             {"$ " + paymentData.ticketData.ticketsTotal}
    //           </span>
    //         </>
    //       );
    //     },
    //   },
    //   // {
    //   //   title: "TAX ",
    //   //   dataIndex: "tax",
    //   //   key: "tax",
    //   //   sorter: false,

    //   //   render: (tax) => {
    //   //     return (
    //   //       <>
    //   //         <span
    //   //           // className="col_styling"
    //   //           style={{
    //   //             textTransform: "capitalize",
    //   //             color: "#474747",
    //   //             fontSize: "16px",
    //   //             fontFamily: "sans-serif",
    //   //           }}
    //   //         >
    //   //           {tax}
    //   //         </span>
    //   //       </>
    //   //     );
    //   //   },
    //   // },
    //   {
    //     title: "AMOUNT ",
    //     dataIndex: "amount",
    //     key: "amount",
    //     sorter: false,
    //     render: () => {
    //       return (
    //         <>
    //           <span
    //             className="tic-styl"
    //             style={{
    //               textTransform: "capitalize",
    //               color: "#474747",
    //               fontSize: "16px",
    //               fontFamily: "sans-serif",
    //             }}
    //           >
    //             {"$ " + paymentData.ticketData.totalPrice}
    //           </span>
    //         </>
    //       );
    //     },
    //   },
    // ];
    // const data = [
    //   {
    //     key: "1",
    //     ticket: "123566",
    //     price: "$1,000.00",
    //     tax: "$10.00",
    //     amount: "$1,000.00",
    //   },
    // ];
    const { paymentData } = this.props;

    return (
      <>
        <div className="linkreturn">
          <Row
            onClick={() => this.props.onCancel()}
            style={{ cursor: "pointer" }}
          >
            <BsArrowLeftShort
              style={{ color: "#5f6c74", fontSize: "25px" }}
              className="back-button-return-icon"
            />
            <p
              style={{ color: "#808080", fontSize: "16px", fontWeight: "600" }}
              className="back-button-return"
            >
              Return
            </p>
          </Row>
        </div>
        <div
          className="noteinvoice-box"
          style={{ minHeight: "100vh", backgroundColor: "#fafafa" }}
        >
          <Row>
            <Col
              xxl={{ span: 18, offset: 3 }}
              xl={{ span: 18, offset: 3 }}
              lg={{ span: 18, offset: 3 }}
              md={{ span: 18, offset: 3 }}
              sm={{ span: 24, offset: 0 }}
              xs={{ span: 24, offset: 0 }}
              // span={18}
              // offset={3}
              // style={{
              //   backgroundColor: "#ffffff",
              //   border: "1px solid #ededed",
              //   minHeight: "70vh",
              // }}
            >
              <ReactToPrint content={() => this.componentRef}>
                <div className="printbtn">
                  <PrintContextConsumer>
                    {({ handlePrint }) => (
                      <Button type="primary" onClick={handlePrint}>
                        <MdDocumentScanner />
                        Export to PDF
                      </Button>
                    )}
                  </PrintContextConsumer>
                </div>
                {this.props.accountReceivable ? (
                  <>
                    <Card ref={(el) => (this.componentRef = el)}>
                      <div className="printviewgrp">
                        <div className="printviewscroll">
                          <div className="printview">
                            <div className="logoview">
                              <img
                                src={Twisted}
                                alt="logo"
                                className="logo-header"
                              />
                            </div>
                            <div className="headingprint">
                              {/* <Title level={3} className="headinginvoice">
                                Invoice
                              </Title> */}
                              {/* <div className="box_printpara">
                                <span className="parainvoicetxt">
                                  Lorem Ipsum has been the industry's standard
                                  dummy unknown printer took a galley of type
                                  and scrambled it to make a type specimen book.
                                  It has survived not only five centuries, but
                                  also the leap into electronic typesetting,
                                  remaining essentially unchanged. It was
                                  popularised in the 1960s with the release of
                                  Letraset sheets containing Lorem Ipsum
                                  passages, and more recently with desktop
                                  publishing software like Aldus PageMaker
                                  including versions of Lorem Ipsum.
                                </span>
                              </div> */}
                            </div>
                            <div className="box_printgrp">
                              <div className="box_print">
                                <div className="invoiceamount">
                                  <div className="flex_invoice">
                                    <b>Invoice Number</b>
                                    <span>{paymentData.invoiceNumber}</span>
                                  </div>
                                  <div className="flex_invoice">
                                    <b>Invoice Date</b>
                                    <span>
                                      {moment(paymentData.createdAt).format(
                                        "ll"
                                      )}
                                      {/* {paymentData.ARPaymentDate} */}
                                    </span>
                                  </div>
                                  <div className="flex_invoice">
                                    <b>Invoice Status</b>
                                    <span>{paymentData?.ARinvoiceStatus}</span>
                                  </div>
                                  <div className="flex_invoice">
                                    <b>Total Amount</b>
                                    <span>
                                      {`$ ${parseFloat(
                                        paymentData?.ticketsTotal
                                      )?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                      })}`}
                                    </span>
                                  </div>
                                  <div className="flex_invoice">
                                    <b>Pending Balance</b>
                                    <span>
                                      {`$ ${
                                        parseFloat(
                                          paymentData?.ARpartialAmount
                                        ).toFixed(1) === "NaN"
                                          ? 0
                                          : parseFloat(
                                              paymentData?.ARpartialAmount
                                            ).toLocaleString(undefined, {
                                              minimumFractionDigits: 2,
                                            })
                                      }`}
                                    </span>
                                  </div>
                                  {/* <div className="flex_invoice">
                                    <b>Payment Due :</b>
                                    <span>
                                      {paymentData?.ARdueDays + " days"}
                                    </span>
                                  </div> */}
                                </div>
                              </div>
                              <div className="box_printr">
                                <div className="invoiceamount2">
                                  <Title
                                    level={5}
                                    className="headinginvoice table_invoice"
                                    style={{ fontSize: "16px" }}
                                  >
                                    Twisted Nail,LLC
                                  </Title>
                                  <span style={{ fontSize: "16px" }}>
                                    1 World Way,Los Angeles,
                                  </span>
                                  <span style={{ fontSize: "16px" }}>
                                    CA 90045,United States,
                                  </span>
                                  <span style={{ fontSize: "16px" }}>
                                    +1 855-463-5252,
                                  </span>
                                  <span style={{ fontSize: "16px" }}>
                                    https://www.flylax.com
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="printview">
                            <div className="headingprint">
                              <Title level={4} className="headinginvoice">
                                From:
                              </Title>
                            </div>
                            <div className="box_printgrp">
                              <div className="box_print">
                                <div className="invoiceamount1">
                                  <b style={{ fontSize: "16px" }}>
                                    Job# {paymentData?.ticketData?.jobName}
                                  </b>
                                  <Title
                                    style={{ fontSize: "16px" }}
                                    level={5}
                                    className="headinginvoice"
                                  >
                                    {paymentData?.ticketData?.companyName}
                                  </Title>
                                  <span style={{ fontSize: "16px" }}>
                                    {paymentData?.ticketData?.dropoffAddress}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="printview">
                            <div className="box_printgrp">
                              <div className="box_printtable ant-table-content">
                                <div className="ant-table">
                                  <table>
                                    <thead className="ant-table-thead">
                                      <tr>
                                        <th className="table_invoice">Date</th>
                                        <th className="table_invoice">
                                          Amount Paid
                                        </th>
                                        <th className="table_invoice">
                                          Payment Method
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="ant-table-tbody">
                                      {paymentData?.ARpartialHistory?.length >
                                        0 &&
                                        paymentData?.ARpartialHistory?.map(
                                          (d) => (
                                            <tr>
                                              <td className="table_invoice">
                                                {d?.ARPaymentDate}
                                              </td>
                                              <td className="table_invoice">
                                                {`$ ${parseFloat(
                                                  d?.ARAmount
                                                )?.toLocaleString(undefined, {
                                                  minimumFractionDigits: 2,
                                                })}`}
                                              </td>
                                              <td className="table_invoice">
                                                {d?.ARdueDays}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </>
                ) : (
                  <>
                    <Card ref={(el) => (this.componentRef = el)}>
                      <div className="printviewgrp">
                        <div className="printviewscroll">
                          <div className="printview">
                            <div className="logoview">
                              <img
                                src={Twisted}
                                alt="logo"
                                className="logo-header"
                              />
                            </div>
                            <div className="headingprint"></div>
                            <div className="box_printgrp">
                              <div className="box_print">
                                <div className="invoiceamount">
                                  <div className="flex_invoice">
                                    <b>Invoice Number</b>
                                    <span>{paymentData.invoiceNumber}</span>
                                  </div>
                                  <div className="flex_invoice">
                                    <b>Invoice Date</b>
                                    <span>
                                      {moment(paymentData.createdAt).format(
                                        "ll"
                                      )}
                                    </span>
                                  </div>
                                  <div className="flex_invoice">
                                    <b>Invoice Status</b>
                                    <span>
                                      {paymentData?.APTinvoiceStatus ||
                                        paymentData.APMinvoiceStatus}
                                    </span>
                                  </div>

                                  <div className="flex_invoice">
                                    <b>Total Amount</b>
                                    <span>
                                      {paymentData?.isTruckingJob === true
                                        ? paymentData?.vendor[0]
                                            ?.truckingCost !== undefined &&
                                          `$ ${parseFloat(
                                            paymentData?.vendor[0]?.truckingCost
                                          )?.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                          })}`
                                        : paymentData?.vendor[0]
                                            ?.materialCost !== undefined &&
                                          `$ ${parseFloat(
                                            paymentData?.vendor[0]?.materialCost
                                          )?.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                          })}`}
                                    </span>
                                  </div>

                                  {paymentData?.APMpartialHistory &&
                                    paymentData?.APMpartialHistory[0]
                                      ?.discountApplied > 0 && (
                                      <div className="flex_invoice">
                                        <b>Discount Amount</b>
                                        <span>
                                          {paymentData?.isTruckingJob ? (
                                            ""
                                          ) : (
                                            <span>
                                              <span>
                                                {parseFloat(
                                                  paymentData?.vendor[0]
                                                    ?.materialCost -
                                                    paymentData
                                                      ?.APMpartialHistory[0]
                                                      ?.APAmount
                                                ).toFixed(2)}
                                              </span>
                                              <span
                                                style={{
                                                  fontWeight: "bold",
                                                  paddingLeft: "5px",
                                                }}
                                              >
                                                {"($" +
                                                  paymentData
                                                    ?.APMpartialHistory[0]
                                                    ?.discountApplied +
                                                  "%)"}
                                              </span>
                                            </span>
                                          )}
                                        </span>
                                      </div>
                                    )}

                                  <div className="flex_invoice">
                                    <b>Pending Balance</b>
                                    <span>
                                      <span>
                                        {`$ ${
                                          parseFloat(
                                            paymentData?.isTruckingJob
                                              ? paymentData?.APTpartialAmount
                                              : paymentData?.APMpartialAmount
                                          ).toFixed(1) === "NaN"
                                            ? 0
                                            : parseFloat(
                                                paymentData?.isTruckingJob
                                                  ? paymentData?.APTpartialAmount
                                                  : paymentData?.APMpartialAmount
                                              ).toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                              })
                                        }`}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="box_printr">
                                <div className="invoiceamount2">
                                  <Title
                                    level={5}
                                    className="headinginvoice table_invoice"
                                    style={{ fontSize: "16px" }}
                                  >
                                    Twisted Nail,LLC
                                  </Title>
                                  <span style={{ fontSize: "16px" }}>
                                    1 World Way,Los Angeles,
                                  </span>
                                  <span style={{ fontSize: "16px" }}>
                                    CA 90045,United States,
                                  </span>
                                  <span style={{ fontSize: "16px" }}>
                                    +1 855-463-5252,
                                  </span>
                                  <span style={{ fontSize: "16px" }}>
                                    https://www.flylax.com
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="printview">
                            <div className="headingprint">
                              <Title level={4} className="headinginvoice">
                                To:
                              </Title>
                            </div>
                            <div className="box_printgrp">
                              <div className="box_print">
                                <div className="invoiceamount1">
                                  <b>
                                    {paymentData.vendor.map(
                                      (ven) => ven.vendor
                                    )}
                                  </b>
                                  <Title level={5} className="headinginvoice">
                                    {
                                      paymentData?.vendor?.assignTrucksInfo
                                        ?.carrier_name
                                    }
                                  </Title>
                                  <span style={{ fontSize: "14px" }}>
                                    {paymentData.vendor.map(
                                      (ef) => ef.pickUpAddress
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="printview">
                            <div className="box_printgrp">
                              <div className="box_printtable ant-table-content">
                                <div className="ant-table">
                                  <table>
                                    <thead className="ant-table-thead">
                                      <tr>
                                        <th className="table_invoice">Date</th>
                                        <th className="table_invoice">
                                          Amount Paid
                                        </th>
                                        <th className="table_invoice">
                                          Payment Method
                                        </th>
                                        <th className="table_invoice">
                                          Payment Picture
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="ant-table-tbody">
                                      {paymentData.isTruckingJob
                                        ? paymentData?.APTpartialHistory?.map(
                                            (ap) => (
                                              <tr>
                                                <td className="table_invoice">
                                                  {ap?.APPaymentDate}
                                                </td>
                                                <td className="table_invoice">
                                                  {`$ ${parseFloat(
                                                    ap?.APAmount
                                                  )?.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                  })}`}
                                                </td>
                                                <td className="table_invoice">
                                                  {ap?.APdueDays}
                                                </td>
                                                <td className="table_invoice">
                                                  {ap?.paymentPicture?.map(
                                                    (e) => (
                                                      <Image
                                                        src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.us-east-1.amazonaws.com/public/${e.paymentAttachmentId}`}
                                                        alt="Payment"
                                                        style={{
                                                          width: "150px",
                                                          height: "50px",
                                                        }}
                                                      />
                                                    )
                                                  )}
                                                </td>
                                              </tr>
                                            )
                                          )
                                        : paymentData?.APMpartialHistory?.map(
                                            (ap) => (
                                              <tr>
                                                <td className="table_invoice">
                                                  {ap?.APPaymentDate}
                                                </td>
                                                <td className="table_invoice">
                                                  {`$ ${parseFloat(
                                                    ap?.APAmount
                                                  )?.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                  })}`}
                                                </td>
                                                <td className="table_invoice">
                                                  {ap?.APdueDays}
                                                </td>
                                                <td className="table_invoice">
                                                  {ap?.paymentPicture?.map(
                                                    (e) => (
                                                      <Image
                                                        src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.us-east-1.amazonaws.com/public/${e.paymentAttachmentId}`}
                                                        alt="Payment"
                                                        style={{
                                                          width: "150px",
                                                          height: "50px",
                                                        }}
                                                      />
                                                    )
                                                  )}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="invoiceamount3">
                        <div className="flex_invoice">
                          <b>Subtotal :</b>
                          <span>
                            <b>$1,000.00</b>
                          </span>
                        </div>
                        <div className="flex_invoice">
                          <b>Tax :</b>
                          <span>
                            <b>{"$ " + paymentData.vendor[0].afterTax}</b>
                          </span>
                        </div>
                        <div className="flex_invoice">
                          <b>Amount Due(USD) :</b>
                          <span>
                            <b>$1,000.00</b>
                          </span>
                        </div>
                      </div> */}
                    </Card>
                  </>
                )}
              </ReactToPrint>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
export default withAppContext(withRouter(Invoice));
