import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  Form,
  Input,
  Popconfirm,
  Popover,
  InputNumber,
  Checkbox,
} from "antd";
import {
  PlusOutlined,
  EllipsisOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";
import AOS from "aos";
import "aos/dist/aos.css";
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import BaseTable from "../../Common/BaseTable";
import SearchFunction from "../../Common/SearchFunction";
import "react-phone-input-2/lib/style.css";
import TextArea from "antd/lib/input/TextArea";
import { add, deleteRecord, list, update } from "../../api/Apis";
import BaseModal from "../../Common/BaseModal";
import appConfig from "../../config/AppConfig";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import NumberFormat from "react-number-format";
import Loading from "../../Common/Loading";
import { withAppContext } from "../../Store/AppContext";
import { withRouter } from "react-router-dom";
import { getSalesSummary } from "../../Redux/Actions/actions";
import { useSelector, useDispatch } from "react-redux";
import { Storage } from "aws-amplify";
// import { connect } from "react-redux";
// import { getSalesPerson } from "../Redux/Actions/actions";
function SalesPerson(props) {
  const [show, setShow] = useState(false);
  const [details, setDetails] = useState([]);
  const [recordValue, setRecordValue] = useState("");
  const [selectedAddress, setSelectedAddress] = useState(false);
  const [latitude, setLatitude] = useState(false);
  const [longitude, setLongitude] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [reportData, setReportData] = useState({
    jobCount: 0,
    totalRevenue: 0,
    totalCost: 0,
    totalProfit: 0,
    profitPercentage: 0,
  });
  const [salesSummary, setSalesSummary] = useState(false);
  const [contractMinimum, setContractMinimum] = useState(false);

  const dispatch = useDispatch();
  const salesResult = useSelector((state) => state?.reducer?.salesResult);

  useEffect(() => {
    setLoading(true);
    getsalePerson();
    // props?.actionFetchGetDispatch();
    document.title = "Twisted Nail - SalesPeople";
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
  }, []);

  useEffect(() => {
    if (
      salesSummary &&
      salesResult?.loading === false &&
      salesResult?.success === true
    ) {
      Storage.get("salesSummary.pdf", { download: true }).then((res) =>
        downloadBlob(res.Body, "Sales Summary")
      );
      setSalesSummary(false);
    }
  }, [salesResult, salesSummary]);

  const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    // a.target = "_blank";
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    setLoading(false);
    return a;
  };

  const getsalePerson = () => {
    list("salesPerson")
      .then((result) => {
        if (result.success) {
          let totalRevenue = 0,
            jobCount = 0,
            totalCost = 0,
            totalProfit = 0,
            profitPercentage = 0;
          result?.data?.forEach((element) => {
            if (element?.reports?.length > 0) {
              if (element?.reports[0]?.invoicedJobs !== null) {
                jobCount += parseInt(element?.reports[0]?.invoicedJobs);
              }
              if (element?.reports[0]?.mLifetimeRevenue !== null) {
                totalRevenue += parseFloat(
                  element?.reports[0]?.mLifetimeRevenue +
                    element?.reports[0]?.tLifetimeRevenue
                );
              }
              if (element?.reports[0]?.materialtotalCost !== null) {
                totalCost += parseFloat(
                  element?.reports[0]?.materialtotalCost +
                    element?.reports[0]?.trucktotalCost
                );
              }
              if (element?.reports[0]?.totalProfit !== null) {
                totalProfit += parseFloat(
                  element?.reports[0]?.materialTotalProfit +
                    element?.reports[0]?.truckTotalProfit
                );
              }
            }
          });
          profitPercentage =
            totalProfit !== 0 && totalRevenue !== 0
              ? parseFloat((totalProfit / totalRevenue) * 100)
              : 0;

          setReportData({
            jobCount,
            totalRevenue,
            totalCost,
            totalProfit,
            profitPercentage,
          });
          // console.log(totalRevenue, totalCost, totalProfit, profitPercentage);
          setDetails(result.data);
          setFilterData(result.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err, "error");
      });
  };

  const onFinish = async (values) => {
    setLoading(true);
    setShow(false);

    let data = {
      entityData: {
        firstName: values["firstName"],
        lastName: values["lastName"],
        email: values["email"],
        phone: values["phone"],
        // commission: values["commission"],
        remarks: values["remarks"],
        address: selectedAddress,
        lat: latitude,
        lng: longitude,
        salesCommission: values["salesCommission"],
        contractMinimum
      },
    };
    if (recordValue !== "") {
      update(appConfig["entityNames"]["salesPerson"], recordValue?.id, data)
        .then((res) => {
          if (res.success) {
            setRecordValue("");
            props.context.getSalesPersons();
            getsalePerson();
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch((err) => console.log(err));
    } else {
      add(appConfig["entityNames"]["salesPerson"], data)
        .then((result) => {
          if (result.success) {
            setShow(false);
            props?.context?.getSalesPersons();
            getsalePerson();
            setLoading(false);
          } else {
            setShow(false);
          }
        })
        .catch((err) => console.log(err, "error"));
    }
  };

  const getAddress = (address, latLng, id) => {
    setSelectedAddress(address.label);
    // console.log(this.state.selectedAddress);
  };

  const handleSelect = (address) => {
    // console.log(address);
    // let cityValue = "";
    // let stateValue = "";
    // let zipCodeValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        // console.log(results);
        getLatLng(results[0]).then(({ lat, lng }) => {
          setLatitude(lat);
          setLongitude(lng);
        });
        // const geoIdData = results[0];
        // this.setState({
        //   geoIdData,
        // });
        // let address = results[0].address_components;
        // for (let i = 0; i < address.length; i++) {
        //   if (address[i].types[0] === "locality") {
        //     cityValue = address[i].long_name;
        //   } else if (address[i].types[0] === "route") {
        //     cityValue = address[i].long_name;
        //   } else if (address[i].types[0] === "political") {
        //     cityValue = address[i].long_name;
        //   }
        //   if (address[i].types[0] === "administrative_area_level_1") {
        //     stateValue = address[i].short_name;
        //   }
        //   if (address[i].types[0] === "postal_code") {
        //     zipCodeValue = address[i].long_name;
        //   }
        // }
        //  console.log(cityValue, stateValue, zipCodeValue);
        // this.setState({
        //   city: cityValue,
        //   addressState: stateValue,
        //   zipCode: zipCodeValue,
        // });
      })
      .catch((error) => console.error("Error", error));
  };

  const searchResult = (searchValue) => {
    const filterData = details;
    let searchData = [];
    if (typeof searchValue === "number") {
      searchData = filterData.filter(
        (e) => parseInt(e["phone"]?.replace(/[^+0-9]/g, "")) === searchValue
      );
    } else {
      searchData = filterData.filter(
        (e) =>
          e["email"]?.toLowerCase().indexOf(searchValue) > -1 ||
          e["firstName"]?.toLowerCase().indexOf(searchValue) > -1 ||
          e["lastName"]?.toLowerCase().indexOf(searchValue) > -1
      );
    }
    setDetails(searchData);
  };

  const onResetData = () => {
    setDetails(filterData);
    props.context.getSalesPersons();
  };

  const searchFields = ["firstName", "lastName", "email", "phone"];

  const deleteData = (data) => {
    setLoading(true);
    deleteRecord(appConfig["entityNames"]["salesPerson"], data.id)
      .then((res) => {
        if (res.success) {
          props.context.getSalesPersons();
          getsalePerson();
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const editModal = (record) => {
    setShow(true);
    setRecordValue(record);
    setContractMinimum(record?.contractMinimum);
    setLatitude(record?.lat);
    setLongitude(record?.lng);
    setSelectedAddress(record?.address);
  };

  const handleTableChage = (data) => {
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    localStorage.setItem(
      "tableSizeRange",
      JSON.stringify({ ...tableSizeRange, salesPerson: data })
    );
    props.context.updateUserSettings({
      ...tableSizeRange,
      salesPerson: data,
    });
  };

  const getSummary = () => {
    setLoading(true);
    setSalesSummary(true);
    dispatch(getSalesSummary());
  };

  const columns = [
    {
      title: "FIRST NAME",
      dataIndex: "firstName",
      key: "firstName",
      className: "col_styling",
      width: "10%",
      onCell: (record) => ({
        onClick: () => props.history.push(`/app/salesPerson/${record.id}`),
      }),
      render: (firstName) => {
        return (
          <span
            className="col_styling table-font-mobile"
            style={{
              textTransform: "capitalize",
              width: "10px",
            }}
          >
            {firstName}
          </span>
        );
      },
    },
    {
      title: "LAST NAME",
      dataIndex: "lastName",
      key: "lastName",
      sorter: false,
      className: "col_styling",
      width: "10%",
      onCell: (record) => ({
        onClick: () => props.history.push(`/app/salesPerson/${record.id}`),
      }),
      render: (lastName) => {
        return (
          <span
            className="col_styling table-font-mobile"
            style={{
              textTransform: "capitalize",
              width: "10px",
            }}
          >
            {lastName}
          </span>
        );
      },
    },
    // {
    //   title: "JOB TYPE",
    //   dataIndex: "",
    //   key: "",
    //   sorter: false,
    //   className: "col_styling",
    //   onCell: (record) => ({
    //     onClick: () => props.history.push(`/app/salesPerson/${record.id}`),
    //   }),
    //   render: () => {
    //     return (
    //       <>
    //         <span
    //           style={{
    //             textTransform: "capitalize",
    //             color: "#474747",
    //             fontSize: "16px",
    //             fontFamily: "sans-serif",
    //           }}
    //         >
    //           <div style={{ marginBottom: "3px" }}>Trucking & Material</div>
    //           <div>Trucking</div>
    //         </span>
    //       </>
    //     );
    //   },
    // },
    {
      title: "PHONE",
      dataIndex: "phone",
      key: "phone",
      sorter: false,
      className: "col_styling",
      width: "10%",
      onCell: (record) => ({
        onClick: () => props.history.push(`/app/salesPerson/${record.id}`),
      }),
      render: (phone) => {
        return (
          <span
            className="col_styling table-font-mobile"
            style={{
              textTransform: "capitalize",
              width: "10px",
            }}
          >
            {phone}
          </span>
        );
      },
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
      sorter: false,
      className: "col_styling",
      width: "10%",
      onCell: (record) => ({
        onClick: () => props.history.push(`/app/salesPerson/${record.id}`),
      }),
      render: (email) => {
        return (
          <span
            className="col_styling table-font-mobile"
            style={{
              textTransform: "capitalize",
              width: "10px",
            }}
          >
            {email}
          </span>
        );
      },
    },
    {
      title: "LIFETIME GROSS PROFIT",
      dataIndex: "address",
      key: "address",
      sorter: false,
      className: "col_styling",
      width: "13%",
      onCell: (record) => ({
        onClick: () => props.history.push(`/app/salesPerson/${record.id}`),
      }),
      render: (address, record) => {
        return (
          <span
            className="col_styling table-font-mobile"
            style={{
              // textTransform: "capitalize",
              width: "10px",
            }}
          >
            <div>
              {record?.reports !== undefined &&
                `$ ` +
                  record?.reports[0]?.truckGross?.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) +
                  " (T)"}
            </div>

            <div style={{ marginBottom: "3px" }}>
              {record?.reports !== undefined &&
                `$ ` +
                  record?.reports[0]?.materialGross?.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) +
                  " (M)"}
            </div>
          </span>
        );
      },
    },
    {
      title: "Commissions Earned",
      dataIndex: "address",
      key: "address",
      sorter: false,
      className: "col_styling",
      width: "13%",
      onCell: (record) => ({
        onClick: () => props.history.push(`/app/salesPerson/${record.id}`),
      }),
      render: (address, record) => {
        return (
          <span
            className="col_styling table-font-mobile"
            style={{
              // textTransform: "capitalize",
              width: "10px",
            }}
          >
            <div>
              {record?.reports !== undefined &&
                `$ ` +
                  record?.reports[0]?.truckCommisionsEarned?.toLocaleString(
                    "en-US",
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
            </div>

            <div style={{ marginBottom: "3px" }}>
              {record?.reports !== undefined &&
                `$ ` +
                  record?.reports[0]?.materialCommisionsEarned?.toLocaleString(
                    "en-US",
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
            </div>
          </span>
        );
      },
    },
    {
      title: "Avg Gross Profit Per Month",
      dataIndex: "address",
      key: "address",
      sorter: false,
      className: "col_styling",
      width: "15%",
      onCell: (record) => ({
        onClick: () => props.history.push(`/app/salesPerson/${record.id}`),
      }),
      render: (address, record) => {
        return (
          <span
            className="col_styling table-font-mobile"
            style={{
              // textTransform: "capitalize",
              width: "10px",
            }}
          >
            <div>
              {record?.reports !== undefined &&
                `$ ` +
                  record?.reports[0]?.tAvgRevenue?.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
            </div>
            <div style={{ marginBottom: "3px" }}>
              {record?.reports !== undefined &&
                `$ ` +
                  record?.reports[0]?.mAvgRevenue?.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
            </div>
          </span>
        );
      },
    },
    // {
    //   title: "COMMISSION %",
    //   dataIndex: "commission",
    //   key: "commission",
    //   sorter: false,
    //   className: "col_styling",
    //   width: "10%",
    //   onCell: (record) => ({
    //     onClick: () => props.history.push(`/app/salesPerson/${record.id}`),
    //   }),
    //   render: (commission) => {
    //     return (
    //       <span
    //         className="col_styling"
    //         style={{
    //           textTransform: "capitalize",
    //           width: "10px",
    //         }}
    //       >
    //         {commission}
    //       </span>
    //     );
    //   },
    // },
    // {
    //   title: "REMARKS",
    //   dataIndex: "remarks",
    //   key: "remarks",
    //   sorter: false,
    //   className: "col_styling",
    //   width: "10%",
    //   onCell: (record) => ({
    //     onClick: () => props.history.push(`/app/salesPerson/${record.id}`),
    //   }),
    //   render: (remarks) => {
    //     return (
    //       <span
    //         className="col_styling"
    //         style={{
    //           textTransform: "capitalize",
    //           width: "10px",
    //         }}
    //       >
    //         {remarks}
    //       </span>
    //     );
    //   },
    // },
    {
      title: "ACTIONS",
      dataIndex: "tag",
      key: "tag",
      sorter: false,
      width: "5%",
      className: "col_style_quarry",
      fixed: "right",
      render: (tag, record) => {
        return (
          <>
            <Row>
              <Col>
                <Popover
                  content={
                    <Row className="popovergrid" style={{ maxWidth: "90px" }}>
                      <Col span={24}>
                        <Button
                          className="btn_edit"
                          onClick={() => editModal(record)}
                        >
                          <span className="span_edit">
                            <MdModeEditOutline style={{ marginRight: "5px" }} />
                            Edit
                          </span>
                        </Button>
                      </Col>
                      <Col span={24}>
                        <Button className="btn_edit">
                          <Popconfirm
                            title="Are you sure？"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => deleteData(record)}
                          >
                            <span className="span_edit">
                              <MdDelete style={{ marginRight: "5px" }} /> Delete
                            </span>
                          </Popconfirm>
                        </Button>
                      </Col>
                    </Row>
                  }
                  trigger="hover"
                  placement="left"
                >
                  <EllipsisOutlined
                    style={{
                      fontSize: "35px",
                      color: "grey",
                      marginLeft: "16px",
                    }}
                    className="action-ellipsis-button"
                  />
                </Popover>
              </Col>
            </Row>
          </>
        );
      },
    },
  ];

  return (
    <div>
      <Loading enableLoading={loading} />
      <>
        <Row
          style={{
            borderRadius: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "20px 0px",
            padding: "0px 20px",
            backgroundColor: "#ffffff",
            border: "1px solid gray",
            // textAlign: "left",
          }}
          className="project-analysis-layout"
          gutter={[12, 0]}
        >
          <Col style={{ margin: "12px 0px" }}>
            <p
              style={{
                fontSize: "18px",
                color: "#747474",
                marginBottom: "-3px",
                fontWeight: "600",
              }}
              className="owner-operator-card-head"
            >
              Job Count
            </p>
            <p
              style={{
                fontSize: "26px",
                fontWeight: "bold",
                marginBottom: "0px",
              }}
              className="owner-operator-card-body"
            >
              {reportData?.jobCount}
            </p>
          </Col>
          <Col style={{ margin: "12px 0px" }}>
            <p
              style={{
                fontSize: "18px",
                color: "#747474",
                marginBottom: "-3px",
                fontWeight: "600",
              }}
              className="owner-operator-card-head"
            >
              Revenue
            </p>
            <p
              style={{
                fontSize: "26px",
                fontWeight: "bold",
                marginBottom: "0px",
              }}
              className="owner-operator-card-body"
            >
              {`$` +
                reportData?.totalRevenue?.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
            </p>
          </Col>
          <Col style={{ margin: "12px 0px" }}>
            <p
              style={{
                fontSize: "18px",
                color: "#747474",
                marginBottom: "-3px",
                fontWeight: "600",
              }}
              className="owner-operator-card-head"
            >
              Cost of Goods Sold
            </p>
            <p
              style={{
                fontSize: "26px",
                fontWeight: "bold",
                marginBottom: "0px",
              }}
              className="owner-operator-card-body"
            >
              {`$` +
                reportData?.totalCost?.toLocaleString("us-EN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
            </p>
          </Col>
          <Col style={{ margin: "12px 0px" }}>
            <p
              style={{
                fontSize: "18px",
                color: "#747474",
                marginBottom: "-3px",
                fontWeight: "600",
              }}
              className="owner-operator-card-head"
            >
              Gross Profit
            </p>
            <p
              style={{
                fontSize: "26px",
                fontWeight: "bold",
                marginBottom: "0px",
              }}
              className="owner-operator-card-body"
            >
              {/* {reportData?.totalProfit?.toString().includes("-")
                ? `-$${parseFloat(
                    reportData?.totalProfit?.toString()?.split("-")[1]
                  )?.toLocaleString("en-us", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`
                : `$${parseFloat(reportData?.totalProfit)?.toLocaleString(
                    "en-us",
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}`} */}
              {`$` + reportData?.totalProfit?.toLocaleString(2)}
            </p>
          </Col>
          <Col style={{ margin: "12px 0px" }}>
            <p
              style={{
                fontSize: "18px",
                color: "#747474",
                marginBottom: "-3px",
                fontWeight: "600",
              }}
              className="owner-operator-card-head"
            >
              Gross Profit %{" "}
            </p>
            <p
              style={{
                fontSize: "26px",
                fontWeight: "bold",
                marginBottom: "0px",
              }}
              className="owner-operator-card-body"
            >
              {reportData?.profitPercentage?.toFixed(2) + " %"}
            </p>
          </Col>
        </Row>

        <Row style={{ marginBottom: "1vw" }}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 24 }}
            xxl={{ span: 24 }}
          >
            <Row>
              <Col
                xs={{ span: 14 }}
                sm={{ span: 14 }}
                md={{ span: 14 }}
                lg={{ span: 14 }}
                xl={{ span: 6 }}
                xxl={{ span: 6 }}
                className="quarrytoolgrid search-button-project"
              >
                <SearchFunction
                  tableName={"salesPerson"}
                  getSearchData={searchResult}
                  onResetData={onResetData}
                  searchFields={searchFields}
                />
              </Col>
              <Col
                xs={{ span: 10 }}
                sm={{ span: 10 }}
                md={{ span: 10 }}
                lg={{ span: 10 }}
                xl={{ span: 18 }}
                xxl={{ span: 18 }}
              >
                <Row style={{ justifyContent: "right" }}>
                  <Col className="gridbtntool">
                    <Button
                      type="primary"
                      icon={<SnippetsOutlined />}
                      style={{
                        borderRadius: "5px",
                        height: "40px",
                      }}
                      onClick={() => {
                        getSummary();
                      }}
                    >
                      <span
                        className="salesperson-button"
                        style={{ fontWeight: "500", fontSize: "16px" }}
                      >
                        Sales Summary
                      </span>
                    </Button>
                  </Col>

                  <Col className="gridbtntool">
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      style={{
                        borderRadius: "5px",
                        height: "40px",
                      }}
                      onClick={() => {
                        setShow(true);
                        setRecordValue("");
                      }}
                    >
                      <span
                        className="salesperson-button"
                        style={{ fontWeight: "500", fontSize: "16px" }}
                      >
                        SalesPerson
                      </span>
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Card
              bodyStyle={{ padding: "0px" }}
              data-aos="fade-up"
              // style={{ background: "#eff1f3", minHeight: "80vh" }}
            >
              <BaseTable
                className="table_system_users table_crm table_laptopscreen"
                columnsData={columns}
                source={details}
                total={details.length}
                handleTableChage={handleTableChage}
                sizeRange={
                  window.screen.width > 1023
                    ? JSON.parse(localStorage.getItem("tableSizeRange"))
                        ?.salesPerson
                    : details.length
                }
                pagination={window.screen.width > 1023 ? true : false}
              />
              {/* <div
                className="ant-table-content ant-table invoicetablennd"
                style={{ margin: "5px 0px" }}
              >
                <table style={{ tableLayout: "auto" }}>
                  <thead className="ant-table-thead salepersonhead">
                    <tr>
                      <th style={{ width: "18%" }}>FIRST NAME</th>
                      <th style={{ width: "18%" }}>LAST NAME</th>
                      <th style={{ width: "18%" }}>LIFETIME REVENUE</th>
                      <th style={{ width: "18%" }}>COMMISSIONS EARNED</th>
                      <th style={{ width: "18%" }}>AVG REVENUE PER MONTH</th>
                      {/* <th style={{ width: "15%" }}>MONTHLY GROWTH (%)</th> 
                      <th style={{ width: "10%" }}>ACTIONS</th>
                    </tr>
                  </thead>
                  <tbody className="ant-table-tbody">
                    {details?.map((e) => (
                      <tr>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            props.history.push(`/app/salesPerson/${e.id}`)
                          }
                        >
                          <div className="txtinvoiceprt">{e.firstName}</div>
                        </td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            props.history.push(`/app/salesPerson/${e.id}`)
                          }
                        >
                          <div className="txtinvoiceprt">{e.lastName}</div>
                        </td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            props.history.push(`/app/salesPerson/${e.id}`)
                          }
                        >
                          <div className="txtinvoiceprt">
                            {e?.reports !== undefined &&
                              `$ ` +
                                e?.reports[0]?.lifetimeRevenue?.toLocaleString(
                                  2
                                )}
                          </div>
                        </td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            props.history.push(`/app/salesPerson/${e.id}`)
                          }
                        >
                          <div className="txtinvoiceprt">
                            {e?.reports !== undefined &&
                              `$ ` +
                                e?.reports[0]?.commisionsEarned?.toLocaleString(
                                  2
                                )}
                          </div>
                        </td>

                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            props.history.push(`/app/salesPerson/${e.id}`)
                          }
                        >
                          <div className="txtinvoiceprt">
                            {e?.reports !== undefined &&
                              `$ ` +
                                e?.reports[0]?.avgRevenue?.toLocaleString(2)}
                          </div>
                        </td>
                        {/* <td
                         style={{cursor:"pointer"}}
                          onClick={() =>
                            props.history.push(`/app/salesPerson/${e.id}`)
                          }
                        >
                          <div className="txtinvoiceprt">
                            {e?.reports !== undefined &&
                              e?.reports[0]?.monthlyGrowth !== undefined &&
                              e?.reports[0]?.monthlyGrowth?.toLocaleString(
                                "en-US"
                              ) + " %"}
                          </div>
                        </td> 
                        <td style={{ cursor: "pointer" }}>
                          <Row>
                            <Col>
                              <Popover
                                content={
                                  <Row className="popovergrid">
                                    <Col span={24}>
                                      <Button
                                        style={{
                                          fontSize: "15px",
                                          cursor: "pointer",
                                          border: "none",
                                          padding: "0px",
                                          textAlign: "left",
                                          width: "100%",
                                          height: "auto",
                                        }}
                                        onClick={() => editModal(e)}
                                      >
                                        <span
                                          style={{
                                            color: "black",
                                            display: "flex",
                                            flexWrap: "wrap",
                                            alignItems: "center",
                                            justifyContent: "left",
                                          }}
                                        >
                                          <MdModeEditOutline
                                            style={{ marginRight: "5px" }}
                                          />
                                          Edit
                                        </span>
                                      </Button>
                                    </Col>
                                    <Col span={24}>
                                      <Button
                                        style={{
                                          fontSize: "15px",
                                          cursor: "pointer",
                                          border: "none",
                                          padding: "0px",
                                          textAlign: "left",
                                          width: "100%",
                                        }}
                                      >
                                        <Popconfirm
                                          title="Are you sure？"
                                          okText="Yes"
                                          cancelText="No"
                                          onConfirm={() => deleteData(e)}
                                        >
                                          <span
                                            style={{
                                              color: "black",
                                              display: "flex",
                                              flexWrap: "wrap",
                                              alignItems: "center",
                                              justifyContent: "left",
                                            }}
                                          >
                                            <MdDelete
                                              style={{ marginRight: "5px" }}
                                            />{" "}
                                            Delete
                                          </span>
                                        </Popconfirm>
                                      </Button>
                                    </Col>
                                  </Row>
                                }
                                trigger="hover"
                                placement="left"
                              >
                                <EllipsisOutlined
                                  style={{
                                    fontSize: "35px",
                                    color: "grey",
                                    marginLeft: "16px",
                                  }}
                                />
                              </Popover>
                            </Col>
                          </Row>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot className="ant-table-tfoot">
                    <tr>
                      <td align="right" colSpan={2}>
                      </td>
                      <td>
                        <div className="txtinvoiceprt">
                          {`$ ` +
                            parseFloat(
                              details
                                .filter(
                                  (e) =>
                                    e.reports !== undefined &&
                                    e?.reports[0]?.lifetimeRevenue
                                )
                                .reduce(
                                  (a, v) =>
                                    (a = a + v?.reports[0]?.lifetimeRevenue),
                                  0
                                )
                            )?.toLocaleString(2)}
                        </div>
                      </td>
                      <td>
                        <div className="txtinvoiceprt">
                          {`$ ` +
                            parseFloat(
                              details
                                .filter(
                                  (e) =>
                                    e.reports !== undefined &&
                                    e?.reports[0]?.commisionsEarned
                                )
                                .reduce(
                                  (a, v) =>
                                    (a = a + v?.reports[0]?.commisionsEarned),
                                  0
                                )
                            )?.toLocaleString(2)}
                        </div>
                      </td>
                      <td>
                        <div className="txtinvoiceprt">
                          {`$ ` +
                            parseFloat(
                              details
                                .filter(
                                  (e) =>
                                    e.reports !== undefined &&
                                    e?.reports[0]?.avgRevenue
                                )
                                .reduce(
                                  (a, v) => (a = a + v?.reports[0]?.avgRevenue),
                                  0
                                )
                            )?.toLocaleString(2)}
                        </div>
                      </td>
                      {/* <td>
                        <div className="txtinvoiceprt">
                          {parseFloat(
                            details
                              .filter(
                                (e) =>
                                  e.reports !== undefined &&
                                  e?.reports[0]?.monthlyGrowth
                              )
                              .reduce(
                                (a, v) =>
                                  (a = a + v?.reports[0]?.monthlyGrowth),
                                0
                              )
                          )?.toFixed(2) + " %"}
                        </div>
                      </td> 
                      <td colSpan={1} />
                    </tr>
                  </tfoot>
                </table>
              </div> */}
            </Card>
          </Col>
        </Row>
      </>

      {show && (
        <BaseModal
          title="Add Sales Person"
          onCancel={() => setShow(false)}
          formId="sales"
          className="antmodal_grid headermodal"
          width={
            window.screen.width > "501" && window.screen.width <= "991"
              ? "80%"
              : "35%"
          }
        >
          <Form onFinish={onFinish} id="sales">
            <Row gutter={[32, 0]}>
              <Col
                xxl={{ span: 12, offset: 0 }}
                xl={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
              >
                <span className="title_changes">First Name</span>
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Your First Name!",
                    },
                  ]}
                  initialValue={recordValue?.firstName}
                >
                  <Input
                    className="form-input"
                    style={{ width: "100%" }}
                    placeholder="Please Enter Your First Name"
                  />
                </Form.Item>
              </Col>
              <Col
                xxl={{ span: 12, offset: 0 }}
                xl={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
              >
                <span className="title_changes">Last Name</span>
                <Form.Item
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Your Last Name!",
                    },
                  ]}
                  initialValue={recordValue?.lastName}
                >
                  <Input
                    className="form-input"
                    style={{ width: "100%" }}
                    placeholder="Please Enter Your Last Name"
                  />
                </Form.Item>
              </Col>
              <Col
                // span={12}
                xxl={{ span: 12, offset: 0 }}
                xl={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
              >
                <span className="title_changes">Email</span>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input email",
                    },
                    {
                      type: "email",
                      message: "The Input Is Not Valid Email",
                    },
                  ]}
                  initialValue={recordValue?.email}
                >
                  <Input
                    placeholder="Email address"
                    className="form-input"
                    // readOnly={editData ? true : ""}
                  />
                </Form.Item>
              </Col>
              <Col
                // span={12}
                xxl={{ span: 12, offset: 0 }}
                xl={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
              >
                <span className="title_changes">Phone</span>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your contact phone!",
                    },
                  ]}
                  initialValue={recordValue?.phone}
                >
                  <NumberFormat
                    className="ant-input-number numberictest"
                    format="(###) ###-####"
                    style={{ width: "100%" }}
                    placeholder="Please Enter Your Phone"
                  />
                </Form.Item>
              </Col>

              <Col
                xxl={{ span: 12, offset: 0 }}
                xl={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
              >
                <span className="title_changes">Sales Commission</span>
                <Form.Item
                  name="salesCommission"
                  rules={[
                    {
                      required: true,
                      message: "Please input sales Commission!",
                    },
                  ]}
                  initialValue={recordValue?.salesCommission}
                >
                  <InputNumber
                    formatter={(value) => (value !== "" ? `${value}%` : "")}
                    style={{ width: "100%", height: "40px" }}
                    placeholder="Please enter Trucking Commission"
                    className="form-input"
                  />
                </Form.Item>
              </Col>

              <Col
                xxl={{ span: 12, offset: 0 }}
                xl={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
              >
                <span className="title_changes">Address</span>
                <Form.Item
                  name="address"
                  rules={[
                    {
                      required: false,
                      message: "Please Enter Address",
                    },
                  ]}
                >
                  <GooglePlacesAutocomplete
                    className="form-input"
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: ["us"],
                      },
                    }}
                    selectProps={{
                      defaultInputValue: `${
                        recordValue?.address ? recordValue?.address : ""
                      }`,
                      placeholder: "Search Address...",
                      onChange: (address) => {
                        handleSelect(address);
                        getAddress(address);
                      },
                    }}
                  />
                </Form.Item>
              </Col>

              <Col
                xxl={{ span: 24, offset: 0 }}
                xl={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
              >
                <Checkbox
                  checked={contractMinimum}
                  onChange={(e) => setContractMinimum(e.target.checked)}
                  style={{ marginBottom: "18px" }}
                >
                  <span className="checkBox_size" style={{ fontSize: "14px" }}>
                    Override Minimum Hours
                  </span>
                </Checkbox>
              </Col>

              <Col
                xxl={{ span: 24, offset: 0 }}
                xl={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
              >
                <span className="title_changes">Remarks</span>
                <Form.Item
                  className="Formitem"
                  name="remarks"
                  rules={[
                    {
                      required: false,
                      message: "Please Enter Remarks!",
                    },
                  ]}
                  initialValue={recordValue?.remarks}
                >
                  <TextArea
                    rows={4}
                    placeholder="Remark"
                    className="form-input"
                  />
                </Form.Item>
              </Col>

              <Col
                // span={24} offset={0}
                xxl={{ span: 24, offset: 0 }}
                xl={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
              >
                <div
                  style={{
                    // marginBottom: "20px",
                    marginLeft: "auto",
                    maxWidth: "180px",
                  }}
                ></div>
              </Col>
            </Row>
          </Form>
        </BaseModal>
      )}
    </div>
  );
}

export default withAppContext(withRouter(SalesPerson));

// const mapStateToProps = (state) => ({
//   totalResult: state?.reducer?.salesPersonResult,
// });

// const mapDispatchToProps = (dispatch) => ({
//   actionFetchGetDispatch: () => {
//     dispatch(getSalesPerson());
//   },
// });

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withAppContext(withRouter(SalesPerson)));
