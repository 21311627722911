import React, { Component } from "react";
import {
  Col,
  Row,
  Card,
  Select,
  Button,
  Form,
  DatePicker,
  Popover,
  Tabs,
  Checkbox,
} from "antd";
import { LinkOutlined } from "@ant-design/icons";
import BaseTable from "../Common/BaseTable";
import { Column } from "@ant-design/plots";
import Loading from "../Common/Loading";
import moment from "moment";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, withRouter } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";
import BaseDrawer from "../Common/BaseDrawer";
import { IoFilterSharp } from "react-icons/io5";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { withAppContext } from "../Store/AppContext";
import { connect } from "react-redux";
import {
  getDashboard,
  getInvoices,
  getDropDownOptions,
} from "../Redux/Actions/actions";

const { Option } = Select;

const months = [
  {
    month: "January",
  },
  {
    month: "February",
  },
  {
    month: "March",
  },
  {
    month: "April",
  },
  {
    month: "May",
  },
  {
    month: "June",
  },
  {
    month: "July",
  },
  {
    month: "August",
  },
  {
    month: "September",
  },
  {
    month: "October",
  },
  {
    month: "November",
  },
  {
    month: "December",
  },
];

const Q1 = [
  {
    month: "January",
  },
  {
    month: "February",
  },
  {
    month: "March",
  },
];

const Q2 = [
  {
    month: "April",
  },
  {
    month: "May",
  },
  {
    month: "June",
  },
];

const Q3 = [
  {
    month: "July",
  },
  {
    month: "August",
  },
  {
    month: "September",
  },
];

const Q4 = [
  {
    month: "October",
  },
  {
    month: "November",
  },
  {
    month: "December",
  },
];

const trucktypeOptions = [
  "Round Bottom",
  "End Dump",
  "Belly Dump",
  "16+ Ton Dump Truck",
  "10+ Ton Dump Truck",
];
const truckCheckedList = [];

class Dashboard extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: "",
      boardView: true,
      listView: false,
      selectionType: "",
      selectedRowKeys: [],
      result: [],
      customerDropDown: "allTime",
      topTrucksDropDown: "allTime",
      truckscodeDropDown: "truckCode",
      topCompaniesAllTime: [],
      topTrucks: [],
      totalSummaryDropDown: moment().format("YYYY"),
      salesCustomQuarter: "",
      materialSalesCustomQuarter: "",
      truckingSalesYear: "",
      truckingSalesQuarterly: "",
      truckingSalesMonthly: "",
      materialSalesYear: "",
      materialSalesQuarterly: "",
      materialSalesMonthly: "",
      truckingSalesCustom: 0,
      materialSalesCustom: 0,
      customerfilterData: [],
      isDrawerVisible: false,
      topMaterialsTypes: "materialName",
      truckcodeCheckall: false,
      truckcheckedlist: truckCheckedList,
      filteredCustomers: "",
      dashboardAddress: "",
      latitude: "",
      longitude: "",
      topSalesPerson: [],
      salesPersonDropDown: "allTime",
      truckReport: [],
      truckGraph: false,
      filterSearchData: "",
      filterCarriersData: [],
      truckTypes: [],
      topMaterialsDropDown: "allTime",
      topQuarriesDropDown: "allTime",
      largestSingleRevenueDropDown: "allTime",
      grossProfit: true,
      sortedData: [],
      checkedVal: true,
    };
  }

  componentDidMount() {
    document.title = "Twisted Nail - Dashboard";
    this.getDashBoard();
    this.updateSortedData();
    this.props?.actionFetchGetInvoices();
    this.props?.actiongetDropDownOptions();
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { checkedVal, result } = this.state;
    if (
      prevState.checkedVal !== checkedVal ||
      prevState.result.largestSingleRevenue !== result.largestSingleRevenue
    ) {
      this.updateSortedData();
    }
    if (
      prevProps?.dropDownOptions?.success === false &&
      this.props?.dropDownOptions?.success === true
    ) {
      let data = {
        label: "All Truck Type - All Truck Type",
        id: 0,
        value: "All Truck Type",
        truck_code: "truckType",
      };
      const updatedList = [...this.props?.trucksTypes[0]?.trucksData];
      updatedList.unshift(data);
      this.setState({
        trucksTypes: updatedList,
      });
    }

    if (
      prevProps?.dashboardResult?.success === false &&
      this.props?.dashboardResult?.success === true
    ) {
      const { truckGraphData } = this.state;

      let data = [];
      this.props?.dashboardResult?.dashboardData?.truckReport?.filter((d) => {
        truckGraphData?.map((t) => {
          if (t === d?.type) {
            data?.push(d);
          }
          return true;
        });
        return true;
      });

      this.setState({
        result: this.props?.dashboardResult?.dashboardData,
        truckingSalesCustom:
          this.props?.dashboardResult?.dashboardData?.truckingSalesCustom,
        materialSalesCustom:
          this.props?.dashboardResult?.dashboardData?.materialSalesCustom,
        topSalesPerson:
          this.props?.dashboardResult?.dashboardData?.topSalesPerson,
        loading: false,
        topCompaniesAllTime:
          this.props?.dashboardResult?.dashboardData?.topCompanies.filter(
            (c) => c !== null
          ),
        topTrucks: this.props?.dashboardResult?.dashboardData?.topTrucks.filter(
          (t) => t !== null
        ),
        filterTruckcode:
          this.props?.dashboardResult?.dashboardData?.topTrucks.filter(
            (t) => t !== null
          ),
        materialSalesYear: "",
        materialSalesQuarterly: "",
        materialSalesMonthly: "",
        truckingSalesYear: "",
        truckingSalesQuarterly: "",
        truckingSalesMonthly: "",
        truckReport: data,
      });
    }
    if (
      prevProps?.invoicesResult?.success === false &&
      this?.props?.invoicesResult?.success === true
    ) {
      const { totalSummaryDropDown } = this.state;
      let filterYear = this?.props?.invoicesResult?.invoicesData?.filter(
        (e) => moment(e.createdAt).format("YYYY") === totalSummaryDropDown
      );
      let filteredCustomers = filterYear?.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.companyId === value.companyId)
      );

      let filterCarriersData = filterYear?.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.ticketData.selectedCarrierId ===
              value.ticketData.selectedCarrierId
          )
      );

      this.setState({
        filteredCustomers,
        filterCarriersData,
      });
    }
  }

  searchFilter = (values) => {
    // const { latitude, longitude } = this.state;
    let data = {
      companyId: values["customerName"] || "",
      truckType: values["truckTypesData"] || [],
      // lat: latitude || "",
      // lang: longitude || "",
      // radius: values["radius"] || "",
      salesPerson: values["salesPerson"] || "",
      truckNumber: values["truckNumber"] || "",
      carrierName: values["carrierName"] || "",
    };

    this.setState({
      isModalVisible: false,
    });
    if (data.truckType?.length > 0) {
      this.setState({
        truckGraph: true,
        truckGraphData: data?.truckType,
      });
    }
    this.setState({
      filterSearchData: data,
    });
    this.getDashBoard(data);
  };

  getDashBoard = (filterData) => {
    // console.log(filterData);
    const {
      totalSummaryDropDown,
      customerDropDown,
      truckingSalesYear,
      truckingSalesQuarterly,
      truckingSalesMonthly,
      materialSalesYear,
      materialSalesQuarterly,
      materialSalesMonthly,
      topTrucksDropDown,
      salesPersonDropDown,
      topMaterialsDropDown,
      topQuarriesDropDown,
      largestSingleRevenueDropDown,
      grossProfit,
    } = this.state;
    this.setState({ loading: true });
    let data = {
      entityData: {
        topSalesPerson: salesPersonDropDown,
        year: totalSummaryDropDown,
        truckingCustomSales: {
          year: truckingSalesYear,
          quarter: truckingSalesQuarterly,
          month: truckingSalesMonthly,
        },
        materialCustomSales: {
          year: materialSalesYear,
          quarter: materialSalesQuarterly,
          month: materialSalesMonthly,
        },
        truckingSales: {
          year: moment().format("YYYY"),
          quarter: moment().quarter(),
          month: moment().format("MMMM"),
        },
        materialSales: {
          year: moment().format("YYYY"),
          quarter: moment().quarter(),
          month: moment().format("MMMM"),
        },
        topCompanies: customerDropDown,
        topTrucks: topTrucksDropDown,
        companyId: filterData?.companyId || "",
        truckType: filterData?.truckType || [],
        salesPersonId: filterData?.salesPerson || "",
        carrierName: filterData?.carrierName || "",
        truckNumber: filterData?.truckNumber || "",
        topMaterials: topMaterialsDropDown,
        topQuarries: topQuarriesDropDown,
        largestSingleRevenue: largestSingleRevenueDropDown,
        grossProfit,
      },
    };
    // console.log(data, "data");
    this.props?.actionFetchGetDashboard(data);
  };

  handleSelect = (address) => {
    this.setState({
      dashboardAddress: address.label,
    });

    geocodeByAddress(address.label)
      .then((results) => {
        getLatLng(results[0]).then(({ lat, lng }) =>
          this.setState({
            latitude: lat,
            longitude: lng,
          })
        );
      })
      .catch((error) => console.error("Error", error));
  };

  onChangetruckcode = (truckcheckedlist) => {
    this.setState({
      truckcheckedlist,
      // truckcodeintermediate:
      //   !!truckcheckedlist.length &&
      //   truckcheckedlist.length < trucktypeOptions.length,
      checkAll: truckcheckedlist.length === trucktypeOptions.length,
    });
  };

  onCheckAlltruckcode = (e) => {
    this.formRef.current.setFieldsValue({
      truckTypesData: e.target.checked ? trucktypeOptions : [],
    });
    this.setState({
      truckcheckedlist: e.target.checked ? trucktypeOptions : [],
      // truckcodeintermediate: false,
      checkAll: e.target.checked,
    });
  };

  filterClearAll = () => {
    this.setState(
      {
        filterSearchData: "",
        truckcheckedlist: null,
        checkAll: null,
        isModalVisible: false,
        truckGraph: false,
      },
      () => this.getDashBoard()
    );
  };

  onChangeYear = (e) => {
    this.setState(
      {
        totalSummaryDropDown: moment(e).format("YYYY"),
      },
      () => {
        this.props?.actionFetchGetInvoices();
        this.getDashBoard();
      }
    );
  };

  onFinish = (values) => {
    const data = {
      entityData: {
        year: moment(values?.year).format("YYYY"),
        quarter: values["quarter"],
        month: values["month"],
      },
    };
    // console.log(data, "data");
    if (
      data?.entityData?.year !== "Invalid date" &&
      data?.entityData?.year !== undefined
    ) {
      this.setState({ truckingSalesYear: data?.entityData?.year });
    } else {
      this.setState({ truckingSalesYear: "" });
    }
    if (data?.entityData?.quarter !== undefined) {
      this.setState({ truckingSalesQuarterly: data?.entityData?.quarter });
    } else {
      this.setState({ truckingSalesQuarterly: "" });
    }
    if (data?.entityData?.month !== undefined) {
      this.setState({ truckingSalesMonthly: data?.entityData?.month });
    } else {
      this.setState({ truckingSalesMonthly: "" });
    }
    this.getDashBoard();
  };

  onFinishMaterialSales = (values) => {
    const data = {
      entityData: {
        year: moment(values?.year).format("YYYY"),
        quarter: values["quarter"],
        month: values["month"],
      },
    };
    // console.log(data, "data");
    if (
      data?.entityData?.year !== "Invalid date" &&
      data?.entityData?.year !== undefined
    ) {
      this.setState({ materialSalesYear: data?.entityData?.year });
    } else {
      this.setState({ materialSalesYear: "" });
    }
    if (data?.entityData?.quarter !== undefined) {
      this.setState({ materialSalesQuarterly: data?.entityData?.quarter });
    } else {
      this.setState({ materialSalesQuarterly: "" });
    }
    if (data?.entityData?.month !== undefined) {
      this.setState({ materialSalesMonthly: data?.entityData?.month });
    } else {
      this.setState({ materialSalesMonthly: "" });
    }
    this.getDashBoard();
  };

  onReset = () => {
    this.formRef.current.setFieldsValue({
      year: "",
      quarter: "",
      month: "",
    });
    this.setState({ truckingSalesCustom: 0, materialSalesCustom: 0 });
  };

  filterTrucks = (e) => {
    let filterCode = [];
    const { filterTruckcode } = this.state;
    console.log("value:", e, filterTruckcode);
    if (e !== "truckType") {
      filterCode = filterTruckcode.filter((t) => t.truckCode === e);
    } else {
      filterCode = filterTruckcode;
    }
    this.setState({
      topTrucks: filterCode,
    });
  };

  handleTableChage = (props, type) => {
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    localStorage.setItem(
      "tableSizeRange",
      JSON.stringify({ ...tableSizeRange, [type]: props })
    );
  };

  updateSortedData() {
    const { result, checkedVal } = this.state;
    const sorted = checkedVal
      ? result.largestSingleRevenue
          ?.slice()
          ?.sort((a, b) => (a.totalRevenue < b.totalRevenue ? 1 : -1))
      : result.largestSingleRevenue
          ?.slice()
          ?.sort((a, b) => (a.grossProfit < b.grossProfit ? 1 : -1));

    this.setState({ sortedData: sorted });
  }

  render() {
    const {
      sortedData,
      filterCarriersData,
      topSalesPerson,
      // dashboardAddress,
      trucksTypes,
      filteredCustomers,
      checkedVal,
      topCompaniesAllTime,
      topTrucks,
      result,
      salesCustomQuarter,
      materialSalesCustomQuarter,
      totalSummaryDropDown,
      materialSalesCustom,
      truckingSalesCustom,
      // customerfilterData,
      // truckscodeDropDown,
      isModalVisible,
      // truckcheckedlist,
      // truckGraphData,
      truckReport,
      truckGraph,
      topMaterialsTypes,
      filterSearchData,
    } = this.state;

    const { loading } = this.props;
    const revenueColumns = [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        align: "center",

        sorter: false,
        // width: 20,
        onCell: (record) => ({
          onClick: () =>
            (window.location =
              "/app/customer-account-profile/" + record.companyId),
        }),
        render: (truckId) => {
          return (
            <span
              // className="col_styling"
              style={{
                textTransform: "capitalize",
              }}
            >
              {truckId}
            </span>
          );
        },
      },
      {
        title: "Revenue",
        dataIndex: "totalRevenue",
        key: "totalRevenue",
        align: "center",

        // className: "col_styling",
        // width: 20,
        onCell: (record) => ({
          onClick: () =>
            (window.location =
              "/app/customer-account-profile/" + record.companyId),
        }),
        render: (carrier_name) => {
          return (
            <span
              className="amount_type revenue"
              style={{
                textTransform: "capitalize",
              }}
            >
              <span className="dollar_class">$</span>
              {` ${parseFloat(carrier_name)?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`}
            </span>
          );
        },
      },
      {
        title: "Gross Profit",
        dataIndex: "grossProfit",
        key: "grossProfit",
        align: "center",
        // width: 20,
        // className: "col_styling",
        onCell: (record) => ({
          onClick: () =>
            (window.location =
              "/app/customer-account-profile/" + record.companyId),
        }),
        render: (grossProfit, record) => {
          return (
            <span className="amount_type">
              <span className="dollar_class">$</span>
              {` ${parseFloat(grossProfit)?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`}

              {/* {` ${parseFloat(record?.grossProfitPercentage)?.toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )}%`} */}
            </span>
          );
        },
      },
      {
        title: "%",
        dataIndex: "grossProfit",
        key: "grossProfit",
        align: "center",

        // width: 10,
        // className: "col_styling",
        onCell: (record) => ({
          onClick: () =>
            (window.location =
              "/app/customer-account-profile/" + record.companyId),
        }),
        render: (grossProfit, record) => {
          const grossProfitPercentage = parseFloat(
            record?.grossProfitPercentage
          );

          // Check if grossProfitPercentage is a valid number
          if (!isNaN(grossProfitPercentage)) {
            // If valid, format and display the percentage
            return (
              <span className="amount_type1">
                {`${grossProfitPercentage.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}%`}
              </span>
            );
          } else {
            // If NaN, display a dash "-"
            return <span className="amount_type1">-</span>;
          }
        },
      },
    ];

    const allTime = [
      {
        label: "All Time",
        value: "allTime",
      },
      {
        label: "This Year",
        value: "year",
      },
      {
        label: "This Quarter",
        value: "quarter",
      },
      {
        label: "This Month",
        value: "month",
      },
      {
        label: "This Week",
        value: "week",
      },
    ];
    const revData = [...allTime, { label: "Daily", value: "day" }];
    const allTypes = [
      {
        label: "All Types",
        value: "materialName",
      },
      {
        label: "Material Category ",
        value: "category",
      },
      {
        label: "Material Type ",
        value: "subCategory",
      },
      {
        label: "Specific Material ",
        value: "value",
      },
    ];

    let newData = [
      {
        month: "M",
        type: "Trucking Profit",
        sales: 0,
        group: "T",
        percentage: 0,
      },
    ];
    let updatedData = newData?.concat(result?.chart);
    const config = {
      data: truckGraph ? truckReport : updatedData,
      xField: "month",
      yField: "sales",
      isGroup: true,
      isStack: true,
      yAxis: {
        min: 0,
        label: {
          style: {
            fontWeight: "bold",
            fontSize: 15,
          },
        },
      },
      xAxis: {
        label: {
          formatter: (month) => {
            if (month === "M") {
              return "";
            }
            return month;
          },
          style: {
            fontWeight: "bold",
            fontSize: 15,
          },
        },
      },
      seriesField: "type",
      groupField: "group",
      dodgePadding: 2,
      meta: {
        sales: {
          formatter: (sales) => `$ ${parseInt(sales).toLocaleString()}`,
        },
      },

      tooltip: {
        customContent: (title, items) => {
          return (
            items[0]?.data?.month !== "M" && (
              <div
                style={{
                  padding: "5px",
                  background: "#fff",
                }}
              >
                <p style={{ margin: 0, fontWeight: "bold" }}>{title}</p>
                {items.map((item, index) => (
                  <p
                    key={index}
                    style={{
                      margin: "10px",
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        // fontSize: "14px",
                        marginRight: "10px",
                      }}
                    >
                      <span
                        className="dotDashBoard"
                        style={{
                          backgroundColor: item.color,
                          marginRight: "10px",
                        }}
                      />
                      {item.data.type} ({item.data.percentage}%):
                    </span>
                    <span>${item.data.sales}</span>
                  </p>
                ))}
              </div>
            )
          );
        },
      },
      barWidthRatio: 1, // Adjust the bar width
      legend: {
        position: "top",
      },
      color: ["#1a1ad9", " #ccccff", "#ff6961", "#ff716978"],
    };

    const trucksColumns = (type) => [
      {
        title: type === "date" ? "Date" : "Truck #",
        dataIndex: type,
        key: type,
        sorter: false,
        width: 20,
        // className: "col_styling",
        onCell: (record) => ({
          onClick: () =>
            type === "date"
              ? (window.location =
                  "/app/customer-account-profile/" + record.companyId)
              : (window.location =
                  "/app/twisted-nail/carrier-profile/" + record.id),
        }),
        render: (truckId) => {
          return (
            <span
              // className="col_styling"
              style={{
                textTransform: "capitalize",
              }}
            >
              {truckId}
            </span>
          );
        },
      },
      {
        title: type === "date" ? "Revenue" : "Carrier",
        dataIndex: type === "date" ? "totalRevenue" : "carrier_name",
        key: type === "date" ? "totalRevenue" : "carrier_name",
        sorter: false,
        // className: "col_styling",
        width: type === "date" ? 20 : 70,
        onCell: (record) => ({
          onClick: () =>
            type === "date"
              ? (window.location =
                  "/app/customer-account-profile/" + record.companyId)
              : (window.location =
                  "/app/twisted-nail/carrier-profile/" + record.id),
        }),
        render: (carrier_name) => {
          return (
            <span
              // className="col_styling"
              style={{
                textTransform: "capitalize",
              }}
            >
              {type === "date"
                ? `$ ${parseFloat(carrier_name)?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`
                : carrier_name}
            </span>
          );
        },
      },
      {
        title: type === "date" ? "Gross Profit" : "Freight Revenue",
        dataIndex: type === "date" ? "grossProfit" : "truckRevenue",
        key: type === "date" ? "grossProfit" : "truckRevenue",
        sorter: false,
        width: type === "date" ? 20 : 40,
        align: "right",
        // className: "col_styling",
        onCell: (record) => ({
          onClick: () =>
            type === "date"
              ? (window.location =
                  "/app/customer-account-profile/" + record.companyId)
              : (window.location =
                  "/app/twisted-nail/carrier-profile/" + record.id),
        }),
        render: (truckRevenue, record) => {
          return (
            <span className="amount_type">
              <span className="dollar_class">$</span>
              {` ${parseFloat(truckRevenue)?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`}

              {type === "date" &&
                ` (${parseFloat(record?.grossProfitPercentage)?.toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}%)`}
            </span>
          );
        },
      },
    ];

    const columnsData = (type) => [
      {
        title:
          type === "Top Quarries"
            ? "Quarry"
            : type === "Top Materials"
            ? "Material"
            : type,
        dataIndex:
          type === "Top Quarries"
            ? "quarryName"
            : type === "Sales Person"
            ? "salesPerson"
            : type === "Cutomers"
            ? "company"
            : topMaterialsTypes,
        key:
          type === "Top Quarries"
            ? "quarryName"
            : type === "Sales Person"
            ? "salesPerson"
            : type === "Cutomers"
            ? "company"
            : "materialName",
        sorter: false,
        onCell: (record) => ({
          onClick: () =>
            type === "Sales Person"
              ? (window.location = "/app/salesPerson/" + record.id)
              : type === "Cutomers"
              ? (window.location = "/app/customer-account-profile/" + record.id)
              : type === "Company Name"
              ? (window.location =
                  "/app/customer-account-profile/" + record.companyId)
              : type === "Top Materials"
              ? (window.location = "/app/quarry-profile/" + record.quarryId)
              : (window.location = "/app/quarry-profile/" + record.quarryId),
        }),
        render: (result) => {
          const content = <div style={{ maxWidth: "300px" }}>{result}</div>;
          return (
            <Popover content={content} trigger="hover">
              <span
                style={{
                  textTransform: "capitalize",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "170px",
                  display: "inline-block",
                }}
              >
                {result}
              </span>
            </Popover>
          );
        },
      },
      {
        title:
          type === "Top Quarries"
            ? "Purchases"
            : type === "Top Materials"
            ? "Revenue"
            : type === "Cutomers"
            ? "Revenue"
            : type === "Sales Person"
            ? "Revenue"
            : type,
        dataIndex:
          type === "Top Quarries"
            ? "qRevenue"
            : type === "Sales Person"
            ? "salesRevenue"
            : type === "Cutomers"
            ? "ticketsTotal"
            : "mRevenue",
        key:
          type === "Top Quarries"
            ? "qRevenue"
            : type === "Sales Person"
            ? "salesRevenue"
            : type === "Cutomers"
            ? "ticketsTotal"
            : "mRevenue",
        sorter: false,
        width:
          type === "Top Quarries"
            ? 150
            : type === "Top Materials"
            ? 150
            : type === "Customers"
            ? 150
            : type === "Sales Person"
            ? 150
            : 150,
        onCell: (record) => ({
          onClick: () =>
            type === "Sales Person"
              ? (window.location = "/app/salesPerson/" + record.id)
              : type === "Cutomers"
              ? (window.location = "/app/customer-account-profile/" + record.id)
              : type === "Top Materials"
              ? (window.location = "/app/quarry-profile/" + record.quarryId)
              : (window.location = "/app/quarry-profile/" + record.quarryId),
        }),
        render: (revenue) => {
          return (
            <span style={{ marginRight: "0px" }} className="amount_type">
              <span className="dollar_class">$</span>
              {` ${parseFloat(revenue)?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`}
            </span>
          );
        },
      },
    ];

    const content = (
      <div>
        <Form
          layout="vertical"
          onFinish={this.onFinish}
          initialValues={{ remember: true }}
          id="dash"
          ref={this.formRef}
          style={{ width: "160px" }}
        >
          <Col>
            <span className="title_changes">Year</span>
            <Form.Item
              name="year"
              rules={[
                {
                  required: true,
                  message: "Please Select Year!",
                },
              ]}
            >
              <DatePicker
                // defaultValue={moment()}
                allowClear
                picker="year"
                bordered={true}
                disabledDate={(current) => {
                  let customDate = moment().format("YYYY-MM-DD");
                  return current && current > moment(customDate, "YYYY-MM-DD");
                }}
                // onChange={this.onChangeYear}
              />
              {/* <Select
                style={{ width: "100%" }}
                allowClear={true}
                onChange={(e) => this.setState({ salesCustomYear: e })}
              >
                <Option value="2021">2021</Option>
                <Option value="2020">2020</Option>
                <Option value="2019">2019</Option>
                <Option value="2018">2018</Option>
                <Option value="2017">2017</Option>
              </Select> */}
            </Form.Item>
          </Col>
          <Col>
            <span className="title_changes">Quarter</span>
            <Form.Item
              name="quarter"
              rules={[
                {
                  required: false,
                  message: "Please Select Quarter!",
                },
              ]}
            >
              {/* <DatePicker
                // defaultValue={moment()}
                allowClear
                picker="quarter"
                bordered={true}
                // onChange={this.onChangeYear}
              /> */}
              <Select
                style={{ width: "100%" }}
                placeholder={"Quarter"}
                allowClear={true}
                onChange={(e) => this.setState({ salesCustomQuarter: e })}
              >
                <Option value={1}>Q1</Option>
                <Option value={2}>Q2</Option>
                <Option value={3}>Q3</Option>
                <Option value={4}>Q4</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <span className="title_changes">Month</span>
            <Form.Item
              name="month"
              rules={[
                {
                  required: false,
                  message: "Please Select Month!",
                },
              ]}
            >
              {/* <DatePicker
                // defaultValue={moment()}
                allowClear
                picker="month"
                bordered={true}
                onChange={this.onChangeYear}
              /> */}
              <Select
                placeholder={"Month"}
                style={{ width: "100%" }}
                allowClear={true}
                onChange={(e) => this.setState({ salesCustomMonth: e })}
              >
                {salesCustomQuarter === 1
                  ? Q1?.map((m) => <Option value={m?.month}>{m?.month}</Option>)
                  : salesCustomQuarter === 2
                  ? Q2?.map((m) => <Option value={m?.month}>{m?.month}</Option>)
                  : salesCustomQuarter === 3
                  ? Q3?.map((m) => <Option value={m?.month}>{m?.month}</Option>)
                  : salesCustomQuarter === 4
                  ? Q4?.map((m) => <Option value={m?.month}>{m?.month}</Option>)
                  : months?.map((m) => (
                      <Option value={m?.month}>{m?.month}</Option>
                    ))}
              </Select>
            </Form.Item>
          </Col>
        </Form>
        <Row justify="space-between">
          <Button
            type="primary"
            // htmlType="submit"
            onClick={this.onReset}
            // loading={this.state.loading}
            style={{
              height: "32px",
              width: "fit-content",
              borderRadius: "5px",
            }}
            // form="dash"
          >
            Reset
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            // loading={this.state.loading}
            style={{
              height: "32px",
              width: "75px",
              borderRadius: "5px",
            }}
            form="dash"
          >
            Search
          </Button>
        </Row>
      </div>
    );

    const contentMaterial = (
      <div>
        <Form
          layout="vertical"
          onFinish={this.onFinishMaterialSales}
          initialValues={{ remember: true }}
          id="dashboard"
          ref={this.formRef}
          style={{ width: "160px" }}
        >
          <Col>
            <span className="title_changes">Year</span>
            <Form.Item
              name="year"
              rules={[
                {
                  required: true,
                  message: "Please Select Year!",
                },
              ]}
            >
              <DatePicker
                // defaultValue={moment()}
                allowClear
                picker="year"
                bordered={true}
                disabledDate={(current) => {
                  let customDate = moment().format("YYYY-MM-DD");
                  return current && current > moment(customDate, "YYYY-MM-DD");
                }}
                // onChange={this.onChangeYear}
              />
              {/* <Select
                style={{ width: "100%" }}
                allowClear={true}
                onChange={(e) => this.setState({ salesCustomYear: e })}
              >
                <Option value="2021">2021</Option>
                <Option value="2020">2020</Option>
                <Option value="2019">2019</Option>
                <Option value="2018">2018</Option>
                <Option value="2017">2017</Option>
              </Select> */}
            </Form.Item>
          </Col>
          <Col>
            <span className="title_changes">Quarter</span>
            <Form.Item
              name="quarter"
              rules={[
                {
                  required: false,
                  message: "Please Select Quarter!",
                },
              ]}
            >
              {/* <DatePicker
                // defaultValue={moment()}
                allowClear
                picker="quarter"
                bordered={true}
                // onChange={this.onChangeYear}
              /> */}
              <Select
                style={{ width: "100%" }}
                placeholder={"Quarter"}
                allowClear={true}
                onChange={(e) =>
                  this.setState({ materialSalesCustomQuarter: e })
                }
              >
                <Option value={1}>Q1</Option>
                <Option value={2}>Q2</Option>
                <Option value={3}>Q3</Option>
                <Option value={4}>Q4</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <span className="title_changes">Month</span>
            <Form.Item
              name="month"
              rules={[
                {
                  required: false,
                  message: "Please Select Month!",
                },
              ]}
            >
              {/* <DatePicker
                // defaultValue={moment()}
                allowClear
                picker="month"
                bordered={true}
                onChange={this.onChangeYear}
              /> */}
              <Select
                placeholder={"Month"}
                style={{ width: "100%" }}
                allowClear={true}
                onChange={(e) => this.setState({ salesCustomMonth: e })}
              >
                {materialSalesCustomQuarter === 1
                  ? Q1?.map((m) => <Option value={m?.month}>{m?.month}</Option>)
                  : materialSalesCustomQuarter === 2
                  ? Q2?.map((m) => <Option value={m?.month}>{m?.month}</Option>)
                  : materialSalesCustomQuarter === 3
                  ? Q3?.map((m) => <Option value={m?.month}>{m?.month}</Option>)
                  : materialSalesCustomQuarter === 4
                  ? Q4?.map((m) => <Option value={m?.month}>{m?.month}</Option>)
                  : months?.map((m) => (
                      <Option value={m?.month}>{m?.month}</Option>
                    ))}
              </Select>
            </Form.Item>
          </Col>
        </Form>
        <Row justify="space-between">
          <Button
            type="primary"
            // htmlType="submit"
            onClick={this.onReset}
            // loading={this.state.loading}
            style={{
              height: "32px",
              width: "fit-content",
              borderRadius: "5px",
            }}
            // form="dash"
          >
            Reset
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            // loading={this.state.loading}
            style={{
              height: "32px",
              width: "fit-content",
              borderRadius: "5px",
            }}
            form="dashboard"
          >
            Search
          </Button>
        </Row>
      </div>
    );

    return (
      <div style={{ backgroundColor: "#fafafa" }}>
        <Loading enableLoading={loading} />
        <Row
          gutter={[24, 10]}
          data-aos={window.screen.width > 1023 ? "fade-down" : ""}
        >
          <Col
            xxl={{ span: 6 }}
            xl={{ span: 6 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            // span={6}
          >
            <Card bordered={true} className="card-dash">
              <Row
                // justify="center"
                style={{
                  width: "100%",
                  padding: "1px",
                }}
              >
                <Tabs
                  defaultActiveKey={1}
                  className="tabversi tabversi-dashboard"
                  onChange={""}
                >
                  <Tabs.TabPane
                    tab={
                      <Row
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Col>Total</Col>
                      </Row>
                    }
                    key={1}
                  >
                    <Row style={{ marginTop: "6px" }}>
                      <Col
                        style={{
                          color: "grey",
                          fontSize: "16px",
                          textAlign: "left",
                        }}
                      >
                        <div className="headingcard">
                          <Link to="/app/accountsReceivable">
                            Accounts Receivable
                            <LinkOutlined style={{ margin: "0px 2px" }} />
                          </Link>
                        </div>
                        <span
                          style={{
                            fontSize: "25px",
                            color: "rgb(73 80 87)",
                            width: "100%",
                            display: "flex",
                          }}
                          className="owner-operator-card-body"
                        >
                          {`$ ${
                            result?.arCurrent !== undefined
                              ? parseFloat(result?.arCurrent)?.toLocaleString(
                                  "us-En",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : "0.00"
                          }`}
                        </span>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={
                      <Row
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Col>Current</Col>
                      </Row>
                    }
                    key={2}
                  >
                    <Row style={{ marginTop: "6px" }}>
                      <Col
                        style={{
                          color: "grey",
                          fontSize: "16px",
                          textAlign: "left",
                        }}
                      >
                        <div className="headingcard">
                          <Link to="/app/accountsReceivable">
                            Accounts Receivable
                            <LinkOutlined style={{ margin: "0px 2px" }} />
                          </Link>
                        </div>
                        <span
                          style={{
                            fontSize: "25px",
                            color: "rgb(73 80 87)",
                            width: "100%",
                            display: "flex",
                          }}
                          className="owner-operator-card-body"
                        >
                          {`$ ${
                            result?.arOverdue !== undefined
                              ? parseFloat(result?.arOverdue)?.toLocaleString(
                                  "us-EN",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : "0.00"
                          }`}
                        </span>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={
                      <Row
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Col>Overdue</Col>
                      </Row>
                    }
                    key={3}
                  >
                    <Row style={{ marginTop: "6px" }}>
                      <Col
                        style={{
                          color: "grey",
                          fontSize: "16px",
                          textAlign: "left",
                        }}
                      >
                        <div className="headingcard">
                          <Link to="/app/accountsReceivable">
                            Accounts Receivable
                            <LinkOutlined style={{ margin: "0px 2px" }} />
                          </Link>
                        </div>
                        <span
                          style={{
                            fontSize: "25px",
                            color: "rgb(73 80 87)",
                            width: "100%",
                            display: "flex",
                          }}
                          className="owner-operator-card-body"
                        >
                          {`$ ${
                            result?.arTotal !== undefined
                              ? parseFloat(result?.arTotal)?.toLocaleString(
                                  "us-EN",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : "0.00"
                          }`}
                        </span>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                </Tabs>
              </Row>
            </Card>
          </Col>
          <Col
            xxl={{ span: 6 }}
            xl={{ span: 6 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            // span={6}
          >
            <Card bordered={true} className="card-dash">
              <Row
                // justify="center"
                style={{
                  width: "100%",
                  padding: "1px",
                }}
              >
                <Tabs
                  defaultActiveKey={1}
                  className="tabversi tabversi-dashboard"
                  onChange={""}
                >
                  <Tabs.TabPane
                    tab={
                      <Row
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Col>Total</Col>
                      </Row>
                    }
                    key={1}
                  >
                    <Row style={{ marginTop: "6px" }}>
                      <Col
                        style={{
                          color: "grey",
                          fontSize: "16px",
                          textAlign: "left",
                        }}
                      >
                        <div className="headingcard">
                          <Link to="/app/accountsPayable">
                            Accounts Payable
                            <LinkOutlined style={{ margin: "0px 2px" }} />
                          </Link>
                        </div>
                        <span
                          style={{
                            fontSize: "25px",
                            color: "rgb(73 80 87)",
                            width: "100%",
                            display: "flex",
                          }}
                          className="owner-operator-card-body"
                        >
                          {`$ ${
                            result?.apTotal !== undefined
                              ? parseFloat(result?.apTotal)?.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                  }
                                )
                              : "0.00"
                          }`}
                        </span>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={
                      <Row
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Col>Trucking</Col>
                      </Row>
                    }
                    key={2}
                  >
                    <Row style={{ marginTop: "6px" }}>
                      <Col
                        style={{
                          color: "grey",
                          fontSize: "16px",
                          textAlign: "left",
                        }}
                      >
                        <div className="headingcard">
                          <Link to="/app/accountsPayable">
                            Accounts Payable
                            <LinkOutlined style={{ margin: "0px 2px" }} />
                          </Link>
                        </div>
                        <span
                          style={{
                            fontSize: "25px",
                            color: "rgb(73 80 87)",
                            width: "100%",
                            display: "flex",
                          }}
                          className="owner-operator-card-body"
                        >
                          {`$ ${
                            result?.apTrucking !== undefined
                              ? parseFloat(result?.apTrucking)?.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                  }
                                )
                              : "0.00"
                          }`}
                        </span>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={
                      <Row
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Col>Material</Col>
                      </Row>
                    }
                    key={3}
                  >
                    <Row style={{ marginTop: "6px" }}>
                      <Col
                        style={{
                          color: "grey",
                          fontSize: "16px",
                          textAlign: "left",
                        }}
                      >
                        <div className="headingcard">
                          <Link to="/app/accountsPayable">
                            Accounts Payable
                            <LinkOutlined style={{ margin: "0px 2px" }} />
                          </Link>
                        </div>
                        <span
                          style={{
                            fontSize: "25px",
                            color: "rgb(73 80 87)",
                            width: "100%",
                            display: "flex",
                          }}
                          className="owner-operator-card-body"
                        >
                          {`$ ${
                            result?.apMaterial !== undefined
                              ? parseFloat(result?.apMaterial)?.toLocaleString(
                                  "us-EN",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : "0.00"
                          }`}
                        </span>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                </Tabs>
              </Row>
            </Card>
          </Col>
          <Col
            xxl={{ span: 6 }}
            xl={{ span: 6 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            // span={6}
          >
            <Card bordered={true} className="card-dash">
              <Row
                // justify="center"
                style={{
                  width: "100%",
                }}
                gutter={[8, 0]}
              >
                <Col span={24}>
                  <Tabs
                    defaultActiveKey={1}
                    className="tabversi tabversi-dashboard"
                    onChange={""}
                  >
                    <Tabs.TabPane
                      tab={
                        <Row
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Col>Annual</Col>
                        </Row>
                      }
                      key={1}
                    >
                      <Row style={{ marginTop: "6px" }}>
                        <Col style={{ color: "red", fontSize: "16px" }}>
                          Trucking Sales
                          <span
                            style={{
                              fontSize: "25px",
                              color: "rgb(73 80 87)",
                              width: "100%",
                              display: "flex",
                            }}
                            className="owner-operator-card-body"
                          >
                            {`$ ${
                              result?.truckingSalesAnually !== undefined
                                ? result?.truckingSalesAnually?.toLocaleString(
                                    undefined,
                                    { minimumFractionDigits: 2 }
                                  )
                                : "0.00"
                            }`}
                          </span>
                        </Col>
                      </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab={
                        <Row
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Col>Quarterly</Col>
                        </Row>
                      }
                      key={2}
                    >
                      <Row style={{ marginTop: "6px" }}>
                        <Col style={{ color: "red", fontSize: "16px" }}>
                          Trucking Sales
                          <span
                            style={{
                              fontSize: "25px",
                              color: "rgb(73 80 87)",
                              width: "100%",
                              display: "flex",
                            }}
                            className="owner-operator-card-body"
                          >
                            {`$ ${result?.truckingSalesQuarterly?.toLocaleString(
                              undefined,
                              { minimumFractionDigits: 2 }
                            )}`}
                          </span>
                        </Col>
                      </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab={
                        <Row
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Col>Monthly</Col>
                        </Row>
                      }
                      key={3}
                    >
                      <Row style={{ marginTop: "6px" }}>
                        <Col style={{ color: "red", fontSize: "16px" }}>
                          Trucking Sales
                          <span
                            style={{
                              fontSize: "25px",
                              color: "rgb(73 80 87)",
                              width: "100%",
                              display: "flex",
                            }}
                            className="owner-operator-card-body"
                          >
                            {`$ ${result?.truckingSalesMonthly?.toLocaleString(
                              undefined,
                              { minimumFractionDigits: 2 }
                            )}`}
                          </span>
                        </Col>
                      </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab={
                        <Row
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          className="toggletabdd"
                        >
                          <Col style={{ textAlign: "center" }}>
                            <Popover
                              placement="bottomLeft"
                              content={content}
                              title="FILTER"
                              trigger="click"
                            >
                              <span
                                style={{
                                  margin: "2px",
                                  width: "auto",
                                  textAlign: "center",
                                  height: "auto",
                                  display: "flex",
                                  alignContent: "baseline",
                                  alignItems: "baseline",
                                  // paddingTop: "10px",
                                  color: "grey",
                                }}
                                onClick={() =>
                                  this.setState(
                                    {
                                      truckingSalesYear: "2022",
                                    },
                                    () => this.getDashBoard()
                                  )
                                }
                              >
                                <BsThreeDots />
                              </span>
                            </Popover>
                          </Col>
                        </Row>
                      }
                      key={4}
                    >
                      <Row style={{ marginTop: "6px" }}>
                        <Col
                          style={{
                            color: "red",
                            fontSize: "16px",
                            textAlign: "left",
                          }}
                        >
                          Trucking Sales
                          <span
                            style={{
                              fontSize: "25px",
                              color: "rgb(73 80 87)",
                              width: "100%",
                              display: "flex",
                            }}
                            className="owner-operator-card-body"
                          >
                            {truckingSalesCustom !== undefined
                              ? `$ ${truckingSalesCustom?.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 2 }
                                )}`
                              : "$ 0"}
                          </span>
                        </Col>
                      </Row>
                    </Tabs.TabPane>
                  </Tabs>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col
            xxl={{ span: 6 }}
            xl={{ span: 6 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            // span={6}
          >
            <Card bordered={true} className="card-dash">
              <Row
                // justify="center"
                style={{
                  width: "100%",
                }}
                gutter={[8, 0]}
              >
                <Col span={24}>
                  <Tabs
                    defaultActiveKey={1}
                    className="tabversi tabversi-dashboard"
                    onChange={""}
                  >
                    <Tabs.TabPane
                      tab={
                        <Row
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Col>Annual</Col>
                        </Row>
                      }
                      key={1}
                    >
                      <Row style={{ marginTop: "6px" }}>
                        <Col
                          style={{
                            color: "red",
                            fontSize: "16px",
                            textAlign: "left",
                          }}
                        >
                          Material Sales
                          <span
                            style={{
                              fontSize: "25px",
                              color: "rgb(73 80 87)",
                              width: "100%",
                              display: "flex",
                            }}
                            className="owner-operator-card-body"
                          >
                            {`$ ${
                              result?.materialSalesAnually !== undefined
                                ? result?.materialSalesAnually?.toLocaleString(
                                    undefined,
                                    { minimumFractionDigits: 2 }
                                  )
                                : "0.00"
                            }`}
                          </span>
                        </Col>
                      </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab={
                        <Row
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Col>Quarterly</Col>
                        </Row>
                      }
                      key={2}
                    >
                      <Row style={{ marginTop: "6px" }}>
                        <Col style={{ color: "red", fontSize: "16px" }}>
                          Material Sales
                          <span
                            style={{
                              fontSize: "25px",
                              color: "rgb(73 80 87)",
                              width: "100%",
                              display: "flex",
                            }}
                            className="owner-operator-card-body"
                          >
                            {`$ ${result?.materialSalesQuarterly?.toLocaleString(
                              undefined,
                              { minimumFractionDigits: 2 }
                            )}`}
                          </span>
                        </Col>
                      </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab={
                        <Row
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Col>Monthly</Col>
                        </Row>
                      }
                      key={3}
                    >
                      <Row style={{ marginTop: "6px" }}>
                        <Col style={{ color: "red", fontSize: "16px" }}>
                          Material Sales
                          <span
                            style={{
                              fontSize: "25px",
                              color: "rgb(73 80 87)",
                              width: "100%",
                              display: "flex",
                            }}
                            className="owner-operator-card-body"
                          >
                            {`$ ${result?.materialSalesMonthly?.toLocaleString(
                              undefined,
                              { minimumFractionDigits: 2 }
                            )}`}
                          </span>
                        </Col>
                      </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab={
                        <Row
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          className="toggletabdd"
                        >
                          <Col style={{ textAlign: "center" }}>
                            <Popover
                              placement="bottomLeft"
                              content={contentMaterial}
                              title="FILTER"
                              trigger="click"
                            >
                              <span
                                style={{
                                  margin: "2px",
                                  width: "auto",
                                  textAlign: "center",
                                  height: "auto",
                                  display: "flex",
                                  alignContent: "baseline",
                                  alignItems: "baseline",
                                  // paddingTop: "10px",
                                  color: "grey",
                                }}
                                onClick={() =>
                                  this.setState(
                                    {
                                      materialSalesYear: "2022",
                                    },
                                    () => this.getDashBoard()
                                  )
                                }
                              >
                                <BsThreeDots />
                              </span>
                            </Popover>
                          </Col>
                        </Row>
                      }
                      key={4}
                    >
                      <Row style={{ marginTop: "6px" }}>
                        <Col style={{ color: "red", fontSize: "16px" }}>
                          Material Sales
                          <span
                            style={{
                              fontSize: "25px",
                              color: "rgb(73 80 87)",
                              width: "100%",
                              display: "flex",
                              maxWidth: "max-content",
                            }}
                            className="owner-operator-card-body"
                          >
                            {materialSalesCustom !== undefined
                              ? `$ ${materialSalesCustom?.toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 2 }
                                )}`
                              : "$ 0"}
                          </span>
                        </Col>
                      </Row>
                    </Tabs.TabPane>
                  </Tabs>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        {result?.chart?.length > 0 && (
          <Row>
            <Col
              xxl={{ span: 24 }}
              xl={{ span: 24 }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              style={{ margin: "10px 0px" }}
              data-aos={window.screen.width > 1023 ? "zoom-in" : ""}
            >
              <Card
                className="cardtitlemob dashboard"
                bordered={false}
                style={{ height: "auto", borderRadius: "5px" }}
                title={<h2 className="heading-big">Total Summary</h2>}
                extra={
                  <div className="filterbox">
                    <Button
                      style={{
                        backgroundColor: "transparent",
                      }}
                      onClick={() =>
                        this.setState({
                          isModalVisible: true,
                        })
                      }
                    >
                      Filter
                      <IoFilterSharp
                        style={{
                          fontSize: "100%",
                          marginLeft: "8px",
                        }}
                      />
                    </Button>
                    <DatePicker
                      defaultValue={moment()}
                      placement={"bottomRight"}
                      allowClear
                      picker="year"
                      bordered={true}
                      onChange={this.onChangeYear}
                      disabledDate={(current) => {
                        let customDate = moment().format("YYYY-MM-DD");
                        return (
                          current && current > moment(customDate, "YYYY-MM-DD")
                        );
                      }}
                    />
                  </div>
                }
              >
                {<Column {...config} />}
              </Card>
            </Col>
          </Row>
        )}

        <Row gutter={[8, 0]}>
          <Col
            xxl={{ span: 10 }}
            xl={{ span: 10 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            // style={{ margin: "10px 0px" }}
            data-aos={window.screen.width > 1023 ? "fade-up" : ""}
          >
            <Card
              className="cardtitlemob dashboard"
              title={
                <h2 className="heading-big">
                  {" "}
                  Largest {checkedVal ? "Revenue" : "GrossProfit"}
                </h2>
              }
              extra={
                <>
                  <Select
                    value={checkedVal ? "Revenue" : "GrossProfit"}
                    style={{ width: 120, marginRight: "20px" }}
                    className="dashBoard_dropdown"
                    onChange={(e) => {
                      this.setState({
                        checkedVal: e === "Revenue" ? true : false,
                      });
                    }}
                  >
                    <Option value="Revenue">Revenue</Option>
                    <Option value="GrossProfit">Gross Profit</Option>
                  </Select>
                  <Select
                    defaultValue="All Time"
                    className="cardtitlemob_topTrucks"
                    style={{ width: "120px" }}
                    allowClear
                    onChange={(e) =>
                      this.setState({ largestSingleRevenueDropDown: e }, () =>
                        this.getDashBoard()
                      )
                    }
                  >
                    {revData?.map((e) => (
                      <Select.Option value={e.value}>{e.label}</Select.Option>
                    ))}
                  </Select>
                </>
              }
              style={{
                height: "auto",
                borderRadius: "10px",
              }}
              bodyStyle={{
                padding: "12px",
              }}
            >
              <BaseTable
                className="table-dash table_laptopscreen"
                columnsData={revenueColumns}
                source={sortedData}
                loading={loading}
                total={sortedData?.length}
                pageSizePagination={true}
                // scroll={{ y: "280px" }}
                sticky={true}
                scrollTop={false}
                handleTableChage={(e) =>
                  this.handleTableChage(e, "dashBoardlargestSingleRevenue")
                }
                sizeRange={
                  window.screen.width > 1023
                    ? JSON.parse(localStorage.getItem("tableSizeRange"))
                        ?.dashBoardlargestSingleRevenue
                    : sortedData?.length
                }
                pagination={window.screen.width > 1023 ? true : false}
              />
            </Card>
          </Col>
          <Col
            xxl={{ span: 7 }}
            xl={{ span: 7 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            data-aos={window.screen.width > 1023 ? "fade-right" : ""}
          >
            <Card
              className="dashboard"
              title={<h2 className="heading-big">Top Customers</h2>}
              extra={
                <Select
                  defaultValue="All Time"
                  style={{ width: "120px" }}
                  onChange={(e) =>
                    this.setState({ customerDropDown: e }, () =>
                      this.getDashBoard()
                    )
                  }
                >
                  {allTime?.map((e) => (
                    <Select.Option value={e.value}>{e.label}</Select.Option>
                  ))}
                </Select>
              }
              style={{
                height: "auto",
                borderRadius: "10px",
              }}
              bodyStyle={{
                padding: "12px",
              }}
            >
              <BaseTable
                className="table-dash table_laptopscreen"
                columnsData={columnsData("Cutomers")}
                source={topCompaniesAllTime}
                total={topCompaniesAllTime?.length}
                loading={loading}
                scrollTop={false}
                pageSizePagination={true}
                // scroll={{ y: "280px" }}
                sticky={true}
                handleTableChage={(e) =>
                  this.handleTableChage(e, "dashBoardCustomers")
                }
                sizeRange={
                  window.screen.width > 1023
                    ? JSON.parse(localStorage.getItem("tableSizeRange"))
                        ?.dashBoardCustomers
                    : topCompaniesAllTime?.length
                }
                pagination={window.screen.width > 1023 ? true : false}
              />
            </Card>
          </Col>
          <Col
            xxl={{ span: 7 }}
            xl={{ span: 7 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            // style={{ margin: "10px 0px" }}
            data-aos={window.screen.width > 1023 ? "fade-left" : ""}
          >
            <Card
              className="dashboard"
              title={<h2 className="heading-big">Top Salesperson</h2>}
              extra={
                <Select
                  defaultValue="All Time"
                  style={{ width: "120px" }}
                  onChange={(e) =>
                    this.setState({ salesPersonDropDown: e }, () =>
                      this.getDashBoard()
                    )
                  }
                >
                  {allTime?.map((e) => (
                    <Select.Option value={e.value}>{e.label}</Select.Option>
                  ))}
                </Select>
              }
              style={{
                height: "auto",
                borderRadius: "10px",
              }}
              bodyStyle={{
                padding: "12px",
              }}
            >
              <BaseTable
                className="table-dash table_laptopscreen"
                columnsData={columnsData("Sales Person")}
                source={topSalesPerson}
                total={topSalesPerson?.length}
                loading={loading}
                scrollTop={false}
                pageSizePagination={true}
                // scroll={{ y: "280px" }}
                sticky={true}
                handleTableChage={(e) =>
                  this.handleTableChage(e, "dashBoardSales")
                }
                sizeRange={
                  window.screen.width > 1023
                    ? JSON.parse(localStorage.getItem("tableSizeRange"))
                        ?.dashBoardSales
                    : topSalesPerson?.length
                }
                pagination={window.screen.width > 1023 ? true : false}
              />
            </Card>
          </Col>
        </Row>

        <Row gutter={[8, 0]} style={{ marginTop: "1vw" }}>
          <Col
            xxl={{ span: 10 }}
            xl={{ span: 10 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            // style={{ margin: "10px 0px" }}
            data-aos={window.screen.width > 1023 ? "fade-up" : ""}
          >
            <Card
              className="cardtitlemob dashboard"
              title={<h2 className="heading-big">Top Trucks</h2>}
              extra={
                <>
                  <Select
                    defaultValue="All Truck Types"
                    className="cardtitlemob_topTrucks"
                    // style={{ marginRight: "5px", width: "180px" }}
                    onChange={(e) => this.filterTrucks(e)}
                    allowClear
                  >
                    {trucksTypes?.map((t) => (
                      <Select.Option value={t.truck_code}>
                        {t.value}
                      </Select.Option>
                    ))}
                  </Select>

                  <Select
                    defaultValue="All Time"
                    className="cardtitlemob_topTrucks"
                    // style={{ width: "120px" }}
                    allowClear
                    onChange={(e) =>
                      this.setState({ topTrucksDropDown: e }, () =>
                        this.getDashBoard()
                      )
                    }
                  >
                    {allTime?.map((e) => (
                      <Select.Option value={e.value}>{e.label}</Select.Option>
                    ))}
                  </Select>
                </>
              }
              style={{
                height: "auto",
                borderRadius: "10px",
              }}
              bodyStyle={{
                padding: "12px",
              }}
            >
              <BaseTable
                className="table-dash table_laptopscreen"
                columnsData={trucksColumns("truckId")}
                source={topTrucks}
                loading={loading}
                total={topTrucks?.length}
                pageSizePagination={true}
                // scroll={{ y: "280px" }}
                sticky={true}
                scrollTop={false}
                handleTableChage={(e) =>
                  this.handleTableChage(e, "dashBoardTrucks")
                }
                sizeRange={
                  window.screen.width > 1023
                    ? JSON.parse(localStorage.getItem("tableSizeRange"))
                        ?.dashBoardTrucks
                    : topTrucks?.length
                }
                pagination={window.screen.width > 1023 ? true : false}
              />
            </Card>
          </Col>
          <Col
            xxl={{ span: 7 }}
            xl={{ span: 7 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            data-aos={window.screen.width > 1023 ? "fade-right" : ""}
          >
            <Card
              className="dashboard"
              title={<h2 className="heading-big">Top Quarry</h2>}
              extra={
                <Select
                  defaultValue="All Time"
                  style={{ width: "120px" }}
                  onChange={(e) =>
                    this.setState({ topQuarriesDropDown: e }, () =>
                      this.getDashBoard()
                    )
                  }
                >
                  {allTime?.map((e) => (
                    <Select.Option value={e.value}>{e.label}</Select.Option>
                  ))}
                </Select>
              }
              style={{
                height: "auto",
                borderRadius: "10px",
              }}
              bodyStyle={{
                padding: "12px",
              }}
            >
              <BaseTable
                className="table-dash table_laptopscreen"
                columnsData={columnsData("Top Quarries")}
                source={result?.topQuarries}
                total={result?.topQuarries?.length}
                loading={loading}
                scrollTop={false}
                pageSizePagination={true}
                // scroll={{ y: "280px" }}
                sticky={true}
                handleTableChage={(e) =>
                  this.handleTableChage(e, "dashBoardQuarries")
                }
                sizeRange={
                  window.screen.width > 1023
                    ? JSON.parse(localStorage.getItem("tableSizeRange"))
                        ?.dashBoardQuarries
                    : result?.topMaterials?.length
                }
                pagination={window.screen.width > 1023 ? true : false}
              />
            </Card>
          </Col>
          <Col
            xxl={{ span: 7 }}
            xl={{ span: 7 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            // style={{ margin: "10px 0px" }}
            data-aos={window.screen.width > 1023 ? "fade-left" : ""}
          >
            <Card
              className="dashboard"
              title={<h2 className="heading-big">Top Materials</h2>}
              extra={
                <>
                  <Select
                    className="res-stage-times"
                    defaultValue="All Time"
                    style={{ width: "100px", marginRight: "20px" }}
                    onChange={(e) =>
                      this.setState({ topMaterialsDropDown: e }, () =>
                        this.getDashBoard()
                      )
                    }
                  >
                    {allTime?.map((e) => (
                      <Select.Option value={e.value}>{e.label}</Select.Option>
                    ))}
                  </Select>
                  <Select
                    className="res-stage-types"
                    defaultValue="All Types"
                    style={{ width: "110px" }}
                    onChange={(e) => this.setState({ topMaterialsTypes: e })}
                  >
                    {allTypes?.map((e) => (
                      <Select.Option value={e.value}>{e.label}</Select.Option>
                    ))}
                  </Select>
                </>
              }
              style={{
                height: "auto",
                borderRadius: "10px",
                marginLeft: "10px",
              }}
              bodyStyle={{
                padding: "12px",
              }}
            >
              <BaseTable
                className="table-dash table_laptopscreen"
                columnsData={columnsData("Top Materials")}
                source={result?.topMaterials}
                total={result?.topMaterials?.length}
                loading={loading}
                scrollTop={false}
                pageSizePagination={true}
                // scroll={{ y: "280px" }}
                sticky={true}
                handleTableChage={(e) =>
                  this.handleTableChage(e, "dashBoardMaterials")
                }
                sizeRange={
                  window.screen.width > 1023
                    ? JSON.parse(localStorage.getItem("tableSizeRange"))
                        ?.dashBoardMaterials
                    : result?.topMaterials?.length
                }
                pagination={window.screen.width > 1023 ? true : false}
              />
            </Card>
          </Col>
        </Row>

        {isModalVisible && (
          <>
            <BaseDrawer
              formId="dashboardfilter"
              visible={this.state?.isModalVisible}
              className="quarrytool_modal mapquarry"
              title="Filter"
              onClose={() => {
                this.setState({
                  isModalVisible: false,
                });
              }}
              destroyOnClose={true}
              footer={[
                <Button
                  htmlType="submit"
                  style={{
                    height: "45px",
                    width: "100%",
                    backgroundColor: "#1e90ff40",
                    border: "none",
                    color: "#000",
                  }}
                  form="dashboardfilter"
                >
                  <span style={{ fontSize: "16px", fontWeight: "500" }}>
                    Show Result
                  </span>
                </Button>,
              ]}
            >
              <Form
                layout="vertical"
                onFinish={this.searchFilter}
                id="dashboardfilter"
                ref={this.formRef}
              >
                <Row>
                  <Col span={24} className="righttextfilter">
                    <span>
                      <Link
                        onClick={(e) => {
                          this.filterClearAll(e);
                        }}
                      >
                        Clear Filter
                      </Link>
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <span className="title_changes">Customer</span>
                    <Form.Item
                      name="customerName"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Customer!",
                        },
                      ]}
                      initialValue={filterSearchData?.companyId}
                    >
                      <Select
                        showSearch
                        allowClear
                        className="select_Height"
                        style={{ height: "40px" }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        // onChange={this.onSelectCompany}
                        placeholder="Please Select A Customer"
                      >
                        {filteredCustomers?.map((e) => (
                          <Select.Option key={e.companyId} value={e.companyId}>
                            {e.companyName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <span
                    className="title_changes"
                    style={{ fontWeight: "bold" }}
                  >
                    Select Truck Type
                  </span>
                  <Col span={24}>
                    <Checkbox
                      // indeterminate={this.state.truckcodeintermediate}
                      onChange={this.onCheckAlltruckcode}
                      checked={this.state.checkAll}
                      style={{
                        marginTop: "10px",
                        borderBottom: "2px solid #9a9a9a",
                        paddingBottom: "5px",
                      }}
                    >
                      <span className="checkBox_size">Select All</span>
                    </Checkbox>
                  </Col>
                  <Form.Item
                    name="truckTypesData"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your checkData!",
                      },
                    ]}
                    initialValue={filterSearchData?.truckType}
                  >
                    <Checkbox.Group
                      onChange={this.onChangetruckcode}
                      style={{ width: "100%" }}
                    >
                      {trucktypeOptions?.map((d) => (
                        <Col
                          span={24}
                          style={{
                            marginBottom: "10px",
                            marginTop: "10px",
                          }}
                        >
                          <Checkbox value={d} key={d}>
                            <span className="checkBox_size">{d}</span>
                          </Checkbox>
                        </Col>
                      ))}
                      {/* </Row> */}
                    </Checkbox.Group>
                  </Form.Item>

                  {/* <Col span={24}>
                    <span className="title_changes">Address</span>
                    <Form.Item
                      name="dashboardAddress"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Location!",
                        },
                      ]}
                    >
                      <GooglePlacesAutocomplete
                        className="form-input"
                        autocompletionRequest={{
                          componentRestrictions: {
                            country: ["us"],
                          },
                        }}
                        selectProps={{
                          dashboardAddress,
                          // defaultInputValue: `${
                          //   this.props?.checkData?.ownerOperatorLocation !==
                          //     "" &&
                          //   this.props?.checkData?.ownerOperatorLocation !==
                          //     undefined
                          //     ? this.props?.checkData?.ownerOperatorLocation
                          //     : ownerOperatorAddress
                          // }`,

                          placeholder: "Search Address...",
                          onChange: (address) => {
                            this.setState({
                              dashboardAddress: address.label,
                            });
                            this.handleSelect(address);
                            // this.getAddress(address);
                          },
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <span className="title_changes">Radius</span>
                    <Form.Item
                      name="radius"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Radius!",
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="Please Enter Your Radius"
                        addonAfter={"Miles"}
                        style={{
                          height: "40px",
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col> */}

                  <Col span={24}>
                    <span className="title_changes">Sales Person</span>
                    <Form.Item
                      name="salesPerson"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Salesperson!",
                        },
                      ]}
                      initialValue={filterSearchData?.salesPerson}
                    >
                      <Select
                        showSearch
                        allowClear
                        className="select_Height"
                        style={{ height: "40px" }}
                        // onChange={this.onSelectCompany}
                        placeholder="Please Select A Salesperson"
                      >
                        {this?.props?.context?.salesPersonsData?.map(
                          (eachItem) =>
                            moment(eachItem.createdAt).format("YYYY") ===
                              totalSummaryDropDown && (
                              <Option
                                key={eachItem.id}
                                value={eachItem.id}
                                className="option_style"
                              >
                                {eachItem?.firstName + " " + eachItem?.lastName}
                              </Option>
                            )
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <span className="title_changes">Carrier Name</span>
                    <Form.Item
                      name="carrierName"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Carrier Name!",
                        },
                      ]}
                      initialValue={filterSearchData?.carrierName}
                    >
                      <Select
                        showSearch
                        allowClear
                        className="select_Height"
                        style={{ height: "40px" }}
                        // onChange={this.onSelectCompany}
                        placeholder="Please Select carrierName"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {filterCarriersData?.map((e) =>
                          e.ticketData.selectedMaterial[0].assignTrucksInfo?.map(
                            (t, i) =>
                              t?.carrierId ===
                                e.ticketData.selectedCarrierId && (
                                <Select.Option key={i} value={t.carrierId}>
                                  {t.carrier_name}
                                </Select.Option>
                              )
                          )
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <span className="title_changes">Truck Number</span>
                    <Form.Item
                      name="truckNumber"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Trucks Number!",
                        },
                      ]}
                      initialValue={filterSearchData?.truckNumber}
                    >
                      <Select
                        showSearch
                        allowClear
                        className="select_Height"
                        style={{ height: "40px", marginBottom: "5px" }}
                        // onChange={this.onSelectCompany}
                        placeholder="Please Select A truckNumber"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {filterCarriersData?.map((e) =>
                          e.ticketData.selectedMaterial[0].assignTrucksInfo?.map(
                            (t, i) =>
                              t?.carrierId ===
                                e.ticketData.selectedCarrierId && (
                                <Select.Option key={i} value={t.truckId}>
                                  {t.truckId}
                                </Select.Option>
                              )
                          )
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </BaseDrawer>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) =>
  // console.log(state),
  ({
    dashboardResult: state?.reducer?.dashboardResult,
    loading: state?.reducer?.dashboardResult?.loading,
    errorLoading: state?.reducer?.dashboardResult?.error,
    invoicesResult: state?.reducer?.invoicesResult,
    trucksTypes: state.reducer?.dropDownOptions?.totalData?.filter(
      (t) => t.typeofDropDown === "trucksType"
    ),
    dropDownOptions: state.reducer?.dropDownOptions,
  });

const mapDispatchToProps = (dispatch) => ({
  actionFetchGetDashboard: (data) => {
    dispatch(getDashboard(data));
  },
  actionFetchGetInvoices: () => {
    dispatch(getInvoices());
  },
  actiongetDropDownOptions: () => {
    dispatch(getDropDownOptions());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAppContext(withRouter(Dashboard)));
