import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  DatePicker,
  Select,
  Divider,
  Tabs,
  Badge,
  Button,
  InputNumber,
  Menu,
  Dropdown,
  message,
  Switch,
  Spin,
} from "antd";
import { IoCaretDownCircleOutline } from "react-icons/io5";
import {
  DeleteOutlined,
  InboxOutlined,
  DollarOutlined,
  DownloadOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import FooterData from "../../Common/FooterData";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { Storage } from "aws-amplify";
import { list, update } from "../../api/Apis";
import BaseModal from "../../Common/BaseModal";
import BaseTable from "../../Common/BaseTable";
import moment from "moment";
import Loading from "../../Common/Loading";
import DownloadLoading from "../../Common/DownloadLoading";

import appConfig from "../../config/AppConfig";
import Dragger from "antd/lib/upload/Dragger";
import AOS from "aos";
import { v4 as uuidv4 } from "uuid";

import "aos/dist/aos.css";
import TextArea from "antd/lib/input/TextArea";
import Invoice from "../Finance/Invoices/Invoice";
import { downloadExcel } from "react-export-table-to-excel";
import excelIcon from "../../Images/excel.png";
import excelIcon1 from "../../Images/excel1.png";
import { withAppContext } from "../../Store/AppContext";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getInvoiceDetails, updateInvoices } from "../../Redux/Actions/actions";

const paymentCategories = [
  { label: "Bank Payment", value: "Bank Payment" },
  { label: "Cash", value: "Cash" },
  { label: "Check", value: "Check" },
];
const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

class AccountsPayable extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: "",
      boardView: true,
      listView: false,
      loading: false,
      selectionType: "",
      selectedRowKeys: [],
      selected: true,
      color: "active",
      visible: false,
      checkData: ["Quarry Bill", "Trucking Bill"],
      imageLoading: false,
      unPaidData: [],
      overDueData: [],
      filterData: [],
      totalData: [],
      filteredCustomers: [],
      filteredCustomersData: [],
      fromDate: "",
      toDate: "",
      companyName: undefined,
      invoiceNumber: "",
      tabKey: "1",
      dueNext30Days: 0,
      overDueAmount: 0,
      payOut: "",
      materialType: "",
      showPaymentModal: false,
      paymentData: "",
      showPDFview: false,
      paymentAttachment: [],
      paidDaysData: [],
      selectedData: [],
      filterUnpaidData: [],
      materialDiscount: "",
      paymentDate: "",
      toggle: false,
      showMessage: false,
      APAmount: 0,
      paymentIndex: 0,
      activeTab: "All",
      updateCount: 0,
      loadingDownload: false,
      downloadProgress: 0,
      modalVisible: false,
      enableRowSelection: false,
    };
  }

  componentDidMount() {
    this.getInvoicesPayable();
    document.title = "Twisted Nail - Accounts Payable";
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
  }

  getInvoicesPayable = () => {
    this.setState({
      loading: true,
    });
    list("accountsPayable")
      .then((result) => {
        let unPaidData = [],
          overDueData = [],
          PaidData = [],
          dueNext30Days = [],
          filteredCustomers = [],
          sum = 0,
          overDueAmount = 0;

        result.data?.map((i) => {
          if (
            i.APTinvoiceStatus === "Unpaid" ||
            i.APMinvoiceStatus === "Unpaid"
          ) {
            unPaidData.push(i);
            if (
              (i.APMdueDays >= 0 && i.APMdueDays <= 29) ||
              (i.APTdueDays >= 0 && i.APTdueDays <= 29)
            ) {
              dueNext30Days.push(i);
            }
          } else if (
            i.APMinvoiceStatus === "Overdue" ||
            i.APTinvoiceStatus === "Overdue"
          ) {
            overDueData.push(i);
          } else if (
            i.APMinvoiceStatus === "Paid" ||
            i.APTinvoiceStatus === "Paid"
          ) {
            PaidData.push(i);
          }
          if (i.isTruckingAndMaterialJob) {
            filteredCustomers.push({
              customer: i?.vendor[0]?.vendor,
              customerId: i?.vendor[0]?.vendorId,
              materialType: "isTruckingAndMaterialJob",
              APMinvoiceStatus: i.APMinvoiceStatus,
            });
          } else {
            i.vendor[0].truckingCost = (
              (i?.vendor[0]?.["truckingCost"] * 87.5) /
              100
            ).toFixed(2);
            filteredCustomers.push({
              customer: i.vendor[0]?.assignTrucksInfo[0]?.carrier_name,
              customerId: i.vendor[0]?.assignTrucksInfo[0]?.carrierId,
              materialType: "isTruckingJob",
              APTinvoiceStatus: i.APTinvoiceStatus,
            });
          }
          if (i.APTinvoiceStatus === "Paid" || i.APMinvoiceStatus === "Paid") {
            let x = new moment(moment(i.APPaymentDate).format("l"));
            let y = new moment(moment(i.createdAt).format("l"));
            let diff = x - y;
            let duration = Math.ceil(diff / (1000 * 60 * 60 * 24));
            i["paidDays"] = duration;
          }
          return i;
        });

        let filteredData = filteredCustomers.filter(
          (e) =>
            e.APMinvoiceStatus === "Unpaid" || e.APTinvoiceStatus === "Unpaid"
        );

        let filteredCustomersData = filteredData?.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t?.customer === value?.customer)
        );

        dueNext30Days.map((e) => {
          if (e.isTruckingJob) {
            sum += parseFloat(e.vendor[0]?.truckingCost);
          } else {
            sum += parseFloat(e.vendor[0]?.materialCost);
          }
          return e;
        });

        overDueData.map((e) => {
          if (e.isTruckingJob) {
            overDueAmount += parseFloat(e.vendor[0]?.truckingCost);
          } else {
            overDueAmount +=
              parseFloat(e.vendor[0]?.truckingCost) +
              parseFloat(e.vendor[0]?.materialCost);
          }
          return e;
        });

        // data.reduce((a, v) => (a = a + v.prix), 0);
        if (result.success) {
          this.setState({
            totalData: result.data,
            filterData: result.data,
            PaidData,
            unPaidData,
            filterUnpaidData: unPaidData,
            overDueData,
            overDueAmount,
            filteredCustomers,
            filteredCustomersData,
            dueNext30Days: sum,
            loading: false,
            paidDaysData: result.data
              .filter((e) => e.paidDays)
              .reduce((a, v) => (a = a + v.paidDays), 0),
            billRecord: "",
            billPayment: "",
            billEdit: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  };

  handleFileDelete = (info, type) => {
    const { recordMaterialData, paymentAttachment } = this.state;
    if (type === "paymentPicture") {
      if (recordMaterialData) {
        let docData = paymentAttachment?.filter((e) => e.id !== info.id);
        this.setState({
          paymentAttachment: docData,
        });
      } else {
        let docData = paymentAttachment?.filter((e) => e.id !== info.id);
        this.setState({
          paymentAttachment: docData,
        });
      }
      this.setState({
        imageLoading: false,
        loading: false,
        percentage: 0,
      });
    }
  };

  handleTabFilters = (key) => {
    this.setState({ activeTab: key });
    this.filterResultData([key]);
  };

  uploadTicketPdf = async (info, type) => {
    const { recordDocData, quoteAttachment, paymentAttachment } = this.state;
    this.setState({
      imageLoading: true,
      loading: true,
    });
    switch (info.file.status) {
      case "done":
        this.setState({
          fileList: info.fileList,
        });
        if (info.fileList.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              Storage.put(info.file.uid, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                Storage.get(result.key).then((urlResult) => {
                  if (type === "paymentPicture") {
                    let paymentAttachmentInfo = paymentAttachment
                      ? paymentAttachment
                      : [];
                    paymentAttachmentInfo.push({
                      id: uuidv4(),
                      paymentAttachmentId: result?.key,
                      fileType: info.file.type,
                      fileName: info.file.name,
                    });
                    this.setState({
                      paymentAttachment: paymentAttachmentInfo,
                      imageLoading: false,
                      loading: false,
                    });
                  }
                });
              });
            } else {
              this.setState({
                imageLoading: false,
                percentage: 0,
                loading: false,
              });
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;

      case "removed":
        if (recordDocData) {
          let docData = recordDocData.docAttachment.filter(
            (e) => e.id !== info.file.id
          );
          recordDocData["docAttachment"] = docData;
          this.setState({
            quoteAttachment: docData,
            recordDocData,
          });
        } else {
          let docData = quoteAttachment.filter(
            (e) => e.paymentAttachmentId !== info.file.uid
          );
          this.setState({
            quoteAttachment: docData,
          });
        }
        this.setState({
          imageLoading: false,
          loading: false,
          percentage: 0,
        });
        break;
      default:
        this.setState({
          imageLoading: false,
          loading: false,
          percentage: 0,
        });
    }
  };

  switchInvoices = (e) => {
    this.setState({
      toggle: e,
    });
    const { unPaidData } = this.state;
    let data = [];
    if (e === true) {
      unPaidData?.forEach((record) => {
        let current = new Date(record.createdAt);
        current.setDate(
          current.getDate() +
            parseInt(record?.vendor[0]?.vendorDiscountTerms?.split("Net")[1])
        );
        let x = new moment(moment(current).format("l"));
        let y = new moment(moment().format("l"));
        let diff = x - y;
        let duration = Math.ceil(diff / (1000 * 60 * 60 * 24));
        if (
          duration >= 0 &&
          record?.isTruckingAndMaterialJob &&
          !record?.APMpartialHistory?.length > 0 &&
          record?.vendor[0]?.vendorDiscount
        ) {
          data.push(record);
        }
      });

      // let data = unPaidData?.filter(
      //   (i) =>
      //     i?.vendor[0]?.vendorDiscount &&
      //     i?.isTruckingAndMaterialJob &&
      // );
      this.setState({
        filterUnpaidData: data,
      });
    } else {
      this.setState({
        filterUnpaidData: unPaidData,
      });
    }
  };

  onSelectChange = (data, selectedRows) => {
    this.setState({ selectedRowKeys: data, selectedData: selectedRows });

    if (data?.length > 0) {
      this.setState({
        enableRowSelection: true,
      });
    } else {
      this.setState({
        enableRowSelection: false,
      });
    }
  };

  handleTabChange = (e) => {
    const { filteredCustomers } = this.state;
    let filteredData = [];
    if (Number(e) === 1) {
      filteredData = filteredCustomers.filter(
        (e) =>
          e.APMinvoiceStatus === "Unpaid" || e.APTinvoiceStatus === "Unpaid"
      );
    } else if (Number(e) === 2) {
      filteredData = filteredCustomers.filter(
        (e) =>
          e.APMinvoiceStatus === "Overdue" || e.APTinvoiceStatus === "Overdue"
      );
    } else if (Number(e) === 3) {
      filteredData = filteredCustomers.filter(
        (e) => e.APMinvoiceStatus === "Paid" || e.APTinvoiceStatus === "Paid"
      );
    } else {
      filteredData = filteredCustomers?.filter((value) => value.customer);
    }
    let filteredCustomersData = filteredData?.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.customer === value.customer)
    );
    this.setState({
      tabKey: e,
      filteredCustomersData,
      toggle: false,
      checkData: ["Quarry Bill", "Trucking Bill"],
      selectedData: [],
      selectedRowKeys: [],
    });
    this.handleReset();
  };

  onChangeDate = (e) => {
    const { paymentData, materialDiscount } = this.state;
    let vendorDiscountTerms =
      paymentData?.vendor[0]?.vendorDiscountTerms?.split("Net")[1];
    let current = new Date(paymentData.createdAt);
    current.setDate(current.getDate() + parseInt(vendorDiscountTerms));
    let x = new moment(moment(current).format("l"));
    let y = new moment(moment(e).format("l"));
    let diff = x - y;
    let duration = Math.ceil(diff / (1000 * 60 * 60 * 24));

    if (
      paymentData?.isTruckingAndMaterialJob &&
      !paymentData?.APMpartialHistory?.length > 0
    ) {
      if (
        paymentData?.vendor[0]?.vendorDiscount &&
        paymentData?.vendor[0]?.vendorDiscount !== "" &&
        paymentData?.vendor[0]?.vendorDiscount !== undefined &&
        paymentData?.vendor[0]?.vendorDiscount !== 0 &&
        duration >= 0
      ) {
        this.formRef.current.setFieldsValue({
          paymentAmount: materialDiscount,
        });
        this.setState({
          showMessage: true,
          paymentDate: e,
        });
      } else {
        this.formRef.current.setFieldsValue({
          paymentAmount: paymentData?.vendor[0]?.materialCost,
        });
        this.setState({
          showMessage: false,
          paymentDate: e,
        });
      }
    } else {
      this.setState({
        showMessage: false,
        paymentDate: e,
      });
    }
  };

  onChangeAmount = (e) => {
    const { paymentData, materialDiscount, paymentDate } = this.state;
    let vendorDiscountTerms =
      paymentData?.vendor[0]?.vendorDiscountTerms?.split("Net")[1];
    let current = new Date(paymentData.createdAt);
    current.setDate(current.getDate() + parseInt(vendorDiscountTerms));
    let x = new moment(moment(current).format("l"));
    let y = new moment(moment(paymentDate).format("l"));
    let diff = x - y;
    let duration = Math.ceil(diff / (1000 * 60 * 60 * 24));
    // console.log(e);
    if (paymentData?.isTruckingAndMaterialJob) {
      if (parseInt(e) === parseInt(materialDiscount) && duration >= 0) {
        this.setState({
          showMessage: true,
        });
      } else {
        this.setState({
          showMessage: false,
        });
      }
    }
  };

  onFinish = (values) => {
    const {
      paymentData,
      paymentAttachment,
      selectedData,
      selectedRowKeys,
      paymentIndex,
      materialDiscount,
    } = this.state;
    if (values["paymentAmount"] === 0) {
      alert("Transaction amount can't be zero.");
      return;
    }
    let balanceAmount = "",
      data = {},
      discountAmount = 0,
      APMpartialHistory =
        paymentData?.APMpartialHistory?.length > 0
          ? paymentData?.APMpartialHistory
          : [],
      APTpartialHistory =
        paymentData?.APTpartialHistory?.length > 0
          ? paymentData?.APTpartialHistory
          : [];

    if (paymentData.isTruckingAndMaterialJob) {
      if (paymentData.APMpartialPaid) {
        balanceAmount =
          parseFloat(paymentData.APMpartialAmount) -
          parseFloat(values["paymentAmount"]);
      } else {
        if (!this.state.showMessage) {
          balanceAmount =
            parseFloat(paymentData.vendor[0]?.materialCost) -
            parseFloat(values["paymentAmount"]);
        } else {
          balanceAmount = 0;
          discountAmount = paymentData?.vendor[0]?.vendorDiscount;
        }
      }
      APMpartialHistory?.push({
        APPaymentDate: moment().format("l"),
        APpartialAmount: balanceAmount,
        APAmount: values["paymentAmount"],
        APdueDays: values["paymentMethod"],
        paymentPicture: paymentAttachment,
        discountApplied: discountAmount,
      });
    } else {
      if (paymentData.APTpartialPaid) {
        balanceAmount =
          parseFloat(paymentData.APTpartialAmount) -
          parseFloat(values["paymentAmount"]);
      } else {
        balanceAmount =
          paymentData.vendor[0]?.truckingCost -
          parseFloat(values["paymentAmount"]);
      }
      APTpartialHistory?.push({
        APPaymentDate: moment().format("l"),
        APpartialAmount: balanceAmount,
        APAmount: values["paymentAmount"],
        APdueDays: values["paymentMethod"],
        paymentPicture: paymentAttachment,
      });
    }
    if (balanceAmount === 0) {
      if (paymentData.isTruckingAndMaterialJob) {
        data["entityData"] = {
          APMpartialAmount: balanceAmount,
          APMdueDays: values["paymentMethod"],
          APMinvoiceStatus: "Paid",
          APMPaymentDate: moment(values["paymentDate"]).format("l"),
          APMpartialPaid: false,
          APMpartialHistory,
        };
      } else {
        data["entityData"] = {
          APpartialAmount: balanceAmount,
          APTdueDays: values["paymentMethod"],
          APTinvoiceStatus: "Paid",
          APTPaymentDate: moment(values["paymentDate"]).format("l"),
          APTpartialPaid: false,
          APTpartialHistory,
        };
      }
    } else if (balanceAmount < 0) {
      alert("Transaction amount is more than the maximum limit amount.");
      return;
    } else {
      if (paymentData.isTruckingAndMaterialJob) {
        data["entityData"] = {
          APMpartialPaid: true,
          APMpartialAmount: balanceAmount,
          APMpartialHistory,
        };
      } else {
        data["entityData"] = {
          APTpartialPaid: true,
          APTpartialAmount: balanceAmount,
          APTpartialHistory,
        };
      }
    }

    this.setState({
      // loading: true,
      // showPaymentModal: false,
      paymentAttachment: [],
    });
    update("invoices", this.state.paymentData.invoiceId, data)
      .then((res) => {
        try {
          if (res.success) {
            message.success("Payment recorded successfully");
            this.setState({
              toggle: false,
              showPaymentModal: false,
              paymentData: "",
            });

            if (
              selectedRowKeys?.length > 0 &&
              selectedRowKeys.length !== paymentIndex + 1
            ) {
              let AmountData = selectedData[paymentIndex + 1];

              this.showModalPayment(selectedData, paymentIndex + 1);
              this.setState({
                paymentIndex: paymentIndex + 1,
              });

              this.formRef.current.setFieldsValue({
                paymentAmount: AmountData.isTruckingJob
                  ? AmountData?.APTpartialPaid
                    ? AmountData?.APTpartialAmount
                    : AmountData?.vendor[0]?.truckingCost
                  : AmountData?.APMpartialPaid
                  ? AmountData?.APMpartialAmount
                  : materialDiscount !== ""
                  ? materialDiscount
                  : AmountData?.vendor[0]?.materialCost,
                paymentMethod: selectedData.paymentMethod,
              });
            } else {
              this.getInvoicesPayable();
              this.setState({
                showPaymentModal: false,
              });
              if (selectedRowKeys?.length > 0) {
                message.info("All payments recorded successfully!");
              }
            }
            this.setState({
              loading: false,
              selectedRowKeys: [],
            });
          }
        } catch (error) {
          console.log("Error:", error);
        }
      })
      .catch((err) => console.log(err));
  };

  getAddress = (address, latLng, id) => {
    this.setState({
      selectedAddress: address,
    });
  };

  showModalPayment = (data, paymentIndex) => {
    let record = data[paymentIndex];
    let vendorDiscountTerms =
      record?.vendor[0]?.vendorDiscountTerms?.split("Net")[1];

    let current = new Date(record.createdAt);
    current.setDate(current.getDate() + parseInt(vendorDiscountTerms));
    let x = new moment(moment(current).format("l"));
    let y = new moment(moment().format("l"));
    let diff = x - y;
    let duration = Math.ceil(diff / (1000 * 60 * 60 * 24));
    if (
      record?.vendor[0]?.vendorDiscount &&
      record?.vendor[0]?.vendorDiscount !== "" &&
      record?.vendor[0]?.vendorDiscount !== undefined &&
      record?.vendor[0]?.vendorDiscount !== 0 &&
      record?.APMinvoiceStatus === "Unpaid" &&
      duration >= 0 &&
      !record?.APMpartialHistory?.length > 0
    ) {
      let materialDiscountAmount =
        record?.isTruckingAndMaterialJob &&
        parseFloat(record?.vendor[0]?.materialCost) -
          parseFloat(
            (parseFloat(record?.vendor[0]?.materialCost) *
              parseFloat(parseInt(record?.vendor[0]?.vendorDiscount))) /
              100
          );
      this.setState({
        materialDiscount: materialDiscountAmount,
        filterMaterialDiscount: materialDiscountAmount,
        showPaymentModal: true,
        paymentData: record,
        showMessage: true,
        paymentAttachment: [],
      });
    }
    this.setState({
      showPaymentModal: true,
      paymentData: record,
    });
  };

  filterResultData = (selectedData) => {
    const { totalData, tabKey } = this.state;
    this.setState({
      checkData: selectedData,
      visible: false,
    });
    const isTruckingAndMaterialJob = totalData?.filter(
      (e) => e.isTruckingAndMaterialJob
    );
    const isTruckingJob = totalData?.filter((e) => e.isTruckingJob);
    const quarryBill = selectedData.includes("Quarry Bill");

    const truckingBill = selectedData.includes("Trucking Bill");

    let filterInfo = null;

    filterInfo =
      quarryBill && truckingBill
        ? totalData
        : quarryBill
        ? isTruckingAndMaterialJob
        : truckingBill
        ? isTruckingJob
        : totalData;

    if (tabKey === "1") {
      this.setState({
        unPaidData: filterInfo?.filter(
          (i) =>
            i.APTinvoiceStatus === "Unpaid" || i.APMinvoiceStatus === "Unpaid"
        ),
        filterUnpaidData: filterInfo?.filter(
          (i) =>
            i.APTinvoiceStatus === "Unpaid" || i.APMinvoiceStatus === "Unpaid"
        ),
        selectedData: filterInfo?.filter(
          (i) =>
            i.APTinvoiceStatus === "Unpaid" || i.APMinvoiceStatus === "Unpaid"
        ),
      });
    } else if (tabKey === "2") {
      this.setState({
        overDueData: filterInfo?.filter(
          (i) =>
            i.APTinvoiceStatus === "Overdue" || i.APMinvoiceStatus === "Overdue"
        ),
        selectedData: filterInfo?.filter(
          (i) =>
            i.APTinvoiceStatus === "Overdue" || i.APMinvoiceStatus === "Overdue"
        ),
      });
    } else if (tabKey === "3") {
      this.setState({
        PaidData: filterInfo?.filter(
          (i) => i.APTinvoiceStatus === "Paid" || i.APMinvoiceStatus === "Paid"
        ),
        selectedData: filterInfo?.filter(
          (i) => i.APTinvoiceStatus === "Paid" || i.APMinvoiceStatus === "Paid"
        ),
      });
    } else {
      this.setState({
        filterData: filterInfo,
        selectedData: filterInfo,
      });
    }
  };

  fileDownloadQuote = async (fileName) => {
    this.setState({
      loading: true,
    });
    Storage.get(fileName, { download: true }).then((res) =>
      this.downloadBlobQuote(res.Body, "fileName")
    );
  };

  downloadBlobQuote(blob, fileName) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.download = fileName || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    message.success("Downloaded successfully");
    this.setState({
      loading: false,
    });
    return a;
  }

  updateProgress = (percentage) => {
    this.setState({ downloadProgress: percentage });
  };

  downloadZip = async (images, accountsPayable) => {
    this.setState({
      loadingDownload: true,
      modalVisible: true,
    });

    const zip = new JSZip();
    const folders = {};

    // Fetch and organize images into folders based on companyId
    const promises = images.map(async (image) => {
      const companyId = image.companyName;
      if (!folders[companyId]) {
        folders[companyId] = zip.folder(companyId);
      }
      const folder = folders[companyId];
      const invoicePath = image.invoicePath;

      // Fetch the image URL from Storage
      try {
        const imageUrl = await Storage.get(invoicePath);

        // Add the image to the folder
        folder.file(
          invoicePath,
          await fetch(imageUrl).then((response) => response.blob()),
          { binary: true }
        );

        // Calculate and update the progress
        const completedCount = Object.keys(folders).length;
        const totalCount = images.length;
        const percentage = (completedCount / totalCount) * 100;
        this.updateProgress(percentage);
      } catch (error) {
        console.error(`Error fetching ${invoicePath} from Storage: ${error}`);
      }
    });

    // Wait for all promises to resolve
    await Promise.all(promises);

    // Generate the zip file
    const content = await zip.generateAsync({ type: "blob" });

    // Save the zip file
    saveAs(content, `accountsPayable`);
    window.location.href = "/app/accountsPayable";

    this.setState({
      loadingDownload: false,
      downloadProgress: 0,
      selectedData: [],
    });
  };

  handleFilter = () => {
    const {
      customer,
      totalData,
      invoiceNumber,
      fromDate,
      toDate,
      tabKey,
      materialType,
    } = this.state;
    let toDateBefore = new Date(toDate);
    toDateBefore.setDate(toDateBefore.getDate() + 1);
    let fromDateBefore = new Date(fromDate);
    fromDateBefore.setDate(fromDateBefore.getDate() - 1);
    let filterData = [];
    if (
      customer !== "" &&
      customer !== undefined &&
      invoiceNumber !== "" &&
      toDate !== "" &&
      fromDate !== ""
    ) {
      filterData = totalData.filter((e) =>
        materialType === "isTruckingAndMaterialJob" &&
        e.isTruckingAndMaterialJob
          ? e["vendor"][0]["vendor"]?.indexOf(customer) > -1 &&
            e["invoiceNumber"]?.indexOf(invoiceNumber) > -1 &&
            moment(moment(e.createdAt).format("l")).isBetween(
              moment(fromDateBefore).format("l"),
              moment(toDateBefore).format("l")
            )
          : e.isTruckingJob &&
            e["vendor"][0]["assignTrucksInfo"][0]["carrier_name"]?.indexOf(
              customer
            ) > -1 &&
            e["invoiceNumber"]?.indexOf(invoiceNumber) > -1 &&
            moment(moment(e.createdAt).format("l")).isBetween(
              moment(fromDateBefore).format("l"),
              moment(toDateBefore).format("l")
            )
      );
    } else if (
      customer !== "" &&
      invoiceNumber !== "" &&
      customer !== undefined
    ) {
      filterData = totalData.filter((e) =>
        materialType === "isTruckingAndMaterialJob" &&
        e.isTruckingAndMaterialJob
          ? e["vendor"][0]["vendor"]?.indexOf(customer) > -1 &&
            e["invoiceNumber"]?.indexOf(invoiceNumber) > -1
          : e.isTruckingJob &&
            e["vendor"][0]["assignTrucksInfo"] &&
            e["vendor"][0]["assignTrucksInfo"][0] &&
            e["vendor"][0]["assignTrucksInfo"][0]["carrier_name"]?.indexOf(
              customer
            ) > -1 &&
            e["invoiceNumber"]?.indexOf(invoiceNumber) > -1
      );
    } else if (
      customer !== undefined &&
      customer !== "" &&
      toDate !== "" &&
      fromDate !== ""
    ) {
      filterData = totalData.filter((e) =>
        materialType === "isTruckingAndMaterialJob" &&
        e.isTruckingAndMaterialJob
          ? e["vendor"][0]["vendor"]?.indexOf(customer) > -1 &&
            moment(moment(e.createdAt).format("l")).isBetween(
              moment(fromDateBefore).format("l"),
              moment(toDateBefore).format("l")
            )
          : e.isTruckingJob &&
            e["vendor"][0]["assignTrucksInfo"] &&
            e["vendor"][0]["assignTrucksInfo"][0] &&
            e["vendor"][0]["assignTrucksInfo"][0]["carrier_name"]?.indexOf(
              customer
            ) > -1 &&
            moment(moment(e.createdAt).format("l")).isBetween(
              moment(fromDateBefore).format("l"),
              moment(toDateBefore).format("l")
            )
      );
    } else if (invoiceNumber !== "" && toDate !== "" && fromDate !== "") {
      filterData = totalData.filter(
        (e) =>
          e["invoiceNumber"].indexOf(invoiceNumber) > -1 &&
          moment(moment(e.createdAt).format("l")).isBetween(
            moment(fromDateBefore).format("l"),
            moment(toDateBefore).format("l")
          )
      );
    } else if (customer !== undefined && customer !== "") {
      filterData = totalData.filter((e) =>
        materialType === "isTruckingAndMaterialJob" &&
        e.isTruckingAndMaterialJob
          ? e["vendor"][0]["vendor"]?.indexOf(customer) > -1
          : e.isTruckingJob &&
            e["vendor"][0]["assignTrucksInfo"] &&
            e["vendor"][0]["assignTrucksInfo"][0] &&
            e["vendor"][0]["assignTrucksInfo"][0]["carrier_name"]?.indexOf(
              customer
            ) > -1
      );
    } else if (invoiceNumber !== "") {
      filterData = totalData.filter(
        (e) => e["invoiceNumber"]?.indexOf(invoiceNumber) > -1
      );
    } else if (toDate !== "" && fromDate !== "") {
      filterData = totalData.filter((e) =>
        moment(moment(e.createdAt).format("l")).isBetween(
          moment(fromDateBefore).format("l"),
          moment(toDateBefore).format("l")
        )
      );
    }
    // console.log(filterData, "filterData");

    if (tabKey === "1") {
      this.setState({
        unPaidData: filterData?.filter(
          (i) =>
            i.APTinvoiceStatus === "Unpaid" || i.APMinvoiceStatus === "Unpaid"
        ),
        filterUnpaidData: filterData?.filter(
          (i) =>
            i.APTinvoiceStatus === "Unpaid" || i.APMinvoiceStatus === "Unpaid"
        ),
        selectedData: filterData?.filter(
          (i) =>
            i.APTinvoiceStatus === "Unpaid" || i.APMinvoiceStatus === "Unpaid"
        ),
      });
    } else if (tabKey === "2") {
      this.setState({
        overDueData: filterData?.filter(
          (i) =>
            i.APTinvoiceStatus === "Overdue" || i.APMinvoiceStatus === "Overdue"
        ),
        selectedData: filterData?.filter(
          (i) =>
            i.APTinvoiceStatus === "Overdue" || i.APMinvoiceStatus === "Overdue"
        ),
      });
    } else if (tabKey === "3") {
      this.setState({
        PaidData: filterData?.filter(
          (i) => i.APTinvoiceStatus === "Paid" || i.APMinvoiceStatus === "Paid"
        ),
        selectedData: filterData?.filter(
          (i) => i.APTinvoiceStatus === "Paid" || i.APMinvoiceStatus === "Paid"
        ),
      });
    } else {
      this.setState({
        filterData,
        selectedData: filterData,
      });
    }
  };

  handleReset = () => {
    const { totalData } = this.state;
    this.setState({
      invoiceNumber: "",
      toDate: "",
      fromDate: "",
      customer: undefined,
      filterData: totalData,
      selectedData: [],
      unPaidData: totalData?.filter(
        (i) =>
          i.APMinvoiceStatus === "Unpaid" || i.APTinvoiceStatus === "Unpaid"
      ),
      filterUnpaidData: totalData?.filter(
        (i) =>
          i.APMinvoiceStatus === "Unpaid" || i.APTinvoiceStatus === "Unpaid"
      ),
      overDueData: totalData?.filter(
        (i) =>
          i.APMinvoiceStatus === "Overdue" || i.APTinvoiceStatus === "Overdue"
      ),
      PaidData: totalData?.filter(
        (i) => i.APMinvoiceStatus === "Paid" || i.APTinvoiceStatus === "Paid"
      ),
    });
  };

  accountpayitem = (record) => {
    // console.log(record);
    return (
      <Menu>
        {((record.APMinvoiceStatus && record.APMinvoiceStatus !== "Paid") ||
          (record?.APTinvoiceStatus &&
            record?.APTinvoiceStatus !== "Paid")) && (
          <Menu.Item onClick={() => this.showModalPayment([record], 0)}>
            Record payment
          </Menu.Item>
        )}
        {/* {(record?.APMpartialHistory?.length > 0 ||
          record?.APTpartialHistory?.length > 0 ||
          record.APMinvoiceStatus === "Paid" ||
          record?.APTinvoiceStatus === "Paid") && (
          <Menu.Item
            onClick={() =>
              this.setState({
                showPDFview: true,
                paymentData: record,
              })
            }
          >
            View
          </Menu.Item> */}
        {/* )} */}
        {/* {(record?.APMpartialHistory?.length > 0 ||
          record?.APTpartialHistory?.length > 0 ||
          record.APMinvoiceStatus === "Paid" ||
          record?.APTinvoiceStatus === "Paid") && (
          <Menu.Item>Export as PDF</Menu.Item>
        )} */}
      </Menu>
    );
  };

  exportData = (selectedData) => {
    this.setState({
      loading: true,
    });
    if (selectedData?.length > 0) {
      let data = [],
        header = [];
      header = [
        "INVOICE #",
        "INVOICE DATE",
        "VENDOR",
        "DUE IN",
        "STATUS",
        "AMOUNT DUE",
      ];
      selectedData?.map((e) =>
        data.push({
          invoiceNumber: e?.invoiceNumber,
          createdAt: moment(e?.createdAt).format("L"),
          vendor: e?.isTruckingJob
            ? e?.vendor[0]?.assignTrucksInfo[0]?.carrier_name
            : e?.vendor[0]?.vendor,
          paymentMethod: e?.isTruckingAndMaterialJob
            ? e?.APMdueDays !== "Cash" &&
              e?.APMdueDays !== "Bank Payment" &&
              e?.APMdueDays !== "Check"
              ? e?.APMdueDays >= 0
                ? "In " + e.APMdueDays + " days"
                : Math.abs(e.APMdueDays) + " days ago"
              : e?.APMdueDays
            : e?.APTdueDays !== "Cash" &&
              e?.APTdueDays !== "Bank Payment" &&
              e?.APTdueDays !== "Check"
            ? e?.APTdueDays >= 0
              ? "In " + e?.APTdueDays + " days"
              : Math.abs(e?.APTdueDays) + " days ago"
            : e?.APTdueDays,
          status: e?.isTruckingAndMaterialJob
            ? e?.APMinvoiceStatus
            : e?.APTinvoiceStatus,
          amountDue: e?.isTruckingJob
            ? e?.APTpartialPaid
              ? `$ ` + parseFloat(e?.APTpartialAmount)?.toLocaleString(2)
              : `$ ` + parseFloat(e.vendor[0]?.truckingCost)?.toLocaleString(2)
            : e?.APMpartialHistory &&
              e?.APMpartialHistory[0]?.discountApplied !== 0
            ? `$ ` +
              parseFloat(e?.APMpartialHistory[0]?.APAmount)?.toLocaleString(2)
            : `$ ` + parseFloat(e.vendor[0]?.materialCost)?.toLocaleString(2),
        })
      );
      downloadExcel({
        fileName: "Accounts Payable Details",
        sheet: "Accounts Payable Invoices",
        tablePayload: {
          header,
          body: data,
        },
      });
      this.setState({
        loading: false,
      });

      message.success("Record downloaded Successfully!");
    } else {
      message.error("Please filter atleast one customer!");
      this.setState({
        loading: false,
      });
    }
  };

  handleTableChage = (props, type) => {
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    localStorage.setItem(
      "tableSizeRange",
      JSON.stringify({ ...tableSizeRange, [type]: props })
    );
  };

  render() {
    const {
      materialDiscount,
      paymentData,
      totalData,
      loading,
      customer,
      paymentAttachment,
      selectedRowKeys,
      filterData,
      imageLoading,
      unPaidData,
      overDueData,
      filteredCustomersData,
      fromDate,
      toDate,
      invoiceNumber,
      dueNext30Days,
      overDueAmount,
      showPaymentModal,
      activeTab,
      showPDFview,
      paidDaysData,
      PaidData,
      tabKey,
      filterUnpaidData,
      showMessage,
      toggle,
      selectedData,
      billEdit,
      billRecord,
      billPayment,
      loadingDownload,
    } = this.state;
    const { billManagement } = this.props;

    const { Option } = Select;

    let truckingData = PaidData?.filter((p) => p?.isTruckingJob);

    let materialData = PaidData?.filter(
      (p) => p?.isTruckingAndMaterialJob === true
    );

    let data = 0;

    truckingData?.map(
      (p) =>
        (data += p?.APTpartialPaid
          ? parseFloat(p?.APTpartialAmount)
          : parseFloat(p.vendor[0]?.truckingCost))
    );

    let material = materialData?.map((p) =>
      p?.APMpartialHistory && p?.APMpartialHistory[0]?.discountApplied !== 0
        ? parseFloat(p?.APMpartialHistory[0]?.APAmount)
        : parseFloat(p.vendor[0]?.materialCost)
    );

    let materials = material?.filter((f) => !isNaN(f));

    materials?.map((a) => (data += a));

    const columns = [
      {
        title: "INVOICE #",
        dataIndex: "invoiceNumber",
        key: "invoiceNumber",
        sorter: (a, b) => (a?.invoiceNumber < b?.invoiceNumber ? -1 : 1),
        className: "col_styling",
        width: tabKey !== "1" ? "10%" : "8%",
        show: false,
        onCell: (record) => ({
          onClick: () => {
            this.setState({
              showPDFview: !billManagement,
              paymentData: record,
            });
          },
        }),
        render: (invoiceNumber) => {
          return (
            <span
              className="col_styling table-font-mobile-accounts"
              style={{
                textTransform: "capitalize",
              }}
            >
              {invoiceNumber}
            </span>
          );
        },
      },
      {
        title: "INVOICE DATE",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: (a, b) => {
          const createdAtA = moment(a.createdAt);
          const createdAtB = moment(b.createdAt);
          return createdAtA - createdAtB;
        },
        className: "col_styling",
        width: billManagement ? "15%" : "10%",
        show: false,
        onCell: (record) => ({
          onClick: () => {
            this.setState({
              showPDFview: !billManagement,
              paymentData: record,
            });
          },
        }),
        render: (createdAt) => {
          return (
            <span
              className="col_styling table-font-mobile-accounts"
              style={{
                textTransform: "capitalize",
              }}
            >
              {moment(createdAt).format("l")}
            </span>
          );
        },
      },
      {
        title: "VENDOR",
        dataIndex: "invoiceId",
        key: "invoiceId",
        sorter: (a, b) => {
          const aValue = a?.isTruckingJob
            ? a?.vendor[0]?.assignTrucksInfo[0]?.carrier_name
            : a?.vendor[0]?.vendor;

          const bValue = b?.isTruckingJob
            ? b?.vendor[0]?.assignTrucksInfo[0]?.carrier_name
            : b?.vendor[0]?.vendor;

          return aValue?.toLowerCase() > bValue?.toLowerCase() ? 1 : -1;
        },
        className: "col_styling",
        width: billManagement ? "20%" : "25%",
        show: false,
        onCell: (record) => ({
          onClick: () => {
            this.setState({
              showPDFview: !billManagement,
              paymentData: record,
            });
          },
        }),
        render: (invoiceId, record) => {
          return (
            <span
              className="col_styling table-font-mobile-accounts"
              style={{
                textTransform: "capitalize",
                color: "#212121",
              }}
            >
              {record?.isTruckingJob
                ? record?.vendor[0]?.assignTrucksInfo[0]?.carrier_name
                : record?.vendor[0]?.vendor}
            </span>
          );
        },
      },
      {
        title: tabKey !== "3" ? "DUE IN" : "PAYMENT METHOD",
        dataIndex: "APMdueDays",
        key: "APMdueDays",
        className: "col_styling",
        width: "10%",
        sorter:
          tabKey !== "3"
            ? (a, b) => {
                const aValue = a?.isTruckingJob ? a?.APTdueDays : a.APMdueDays;
                const bValue = b?.isTruckingJob ? b?.APTdueDays : a.APMdueDays;
                return aValue > bValue ? 1 : -1;
              }
            : false,
        show: billManagement ? true : false,
        onCell: (record) => ({
          onClick: () => {
            this.setState({
              showPDFview: !billManagement,
              paymentData: record,
            });
          },
        }),
        render: (APMdueDays, record) => {
          return record?.isTruckingAndMaterialJob ? (
            <span className="col_styling table-font-mobile-accounts">
              {APMdueDays !== "Cash" &&
              APMdueDays !== "Bank Payment" &&
              APMdueDays !== "Check" ? (
                APMdueDays >= 0 ? (
                  "In " + APMdueDays + " days"
                ) : (
                  <span style={{ color: "#d4380d" }}>
                    {Math.abs(APMdueDays) + " days ago"}
                  </span>
                )
              ) : (
                <span>{APMdueDays}</span>
              )}
            </span>
          ) : (
            <span className="col_styling table-font-mobile-accounts">
              {record?.APTdueDays !== "Cash" &&
              record?.APTdueDays !== "Bank Payment" &&
              record?.APTdueDays !== "Check" ? (
                record?.APTdueDays >= 0 ? (
                  "In " + record?.APTdueDays + " days"
                ) : (
                  <span style={{ color: "#d4380d" }}>
                    {Math.abs(record?.APTdueDays) + " days ago"}
                  </span>
                )
              ) : (
                record?.APTdueDays
              )}
            </span>
          );
        },
      },
      {
        title: "STATUS",
        dataIndex: "APMinvoiceStatus",
        key: "APMinvoiceStatus",
        sorter: false,
        className: "col_styling",
        width: "10%",
        show: billManagement,
        onCell: (record) => ({
          onClick: () => {
            this.setState({
              showPDFview: !billManagement,
              paymentData: record,
            });
          },
        }),
        render: (APMinvoiceStatus, record) => {
          return (
            <Button
              className="lead_ButtonStatus"
              style={{
                background: "transparent",
                borderRadius: "6px",
                borderColor: record.isTruckingAndMaterialJob
                  ? appConfig["STAGE_COLORS"][APMinvoiceStatus]
                  : appConfig["STAGE_COLORS"][record.APTinvoiceStatus],
                color: record.isTruckingAndMaterialJob
                  ? appConfig["STAGE_COLORS"][APMinvoiceStatus]
                  : appConfig["STAGE_COLORS"][record.APTinvoiceStatus],
                borderWidth: "1px",
                borderStyle: "solid",
              }}
            >
              <span
                className="col_styling table-font-mobile-accounts"
                style={{
                  fontSize: "14px",
                  fontFamily: "sans-serif",
                }}
              >
                {record.isTruckingAndMaterialJob
                  ? APMinvoiceStatus
                  : record.APTinvoiceStatus}
              </span>
            </Button>
          );
        },
      },
      {
        title: "Rate",
        dataIndex: "rate",
        key: "rate",
        sorter: true,
        className: "col_styling",
        width: "20%",
        show: !billManagement,
        onCell: (record) => ({
          onClick: () => {
            this.setState({
              showPDFview: !billManagement,

              paymentData: record,
            });
          },
        }),
        render: (rate, record) => {
          return (
            <span
              className="col_styling table-font-mobile-accounts"
              style={{
                textTransform: "capitalize",
                color: "#212121",
              }}
            >
              {record.isTruckingJob ? (
                <InputNumber
                  id={record.id}
                  className="accounts_payable"
                  value={parseFloat(
                    billPayment !== "" &&
                      billEdit &&
                      billRecord.id === record.id
                      ? billPayment
                      : record.vendor[0]?.truckingRate
                  )?.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  prefix={"$"}
                  precision={2}
                  decimalSeparator={"."}
                  style={{
                    height: "40px",
                    width: "100%",
                    backgroundColor: "transparent",
                  }}
                  bordered={
                    billEdit && billRecord.id === record.id ? true : false
                  }
                  readOnly={
                    billEdit && billRecord.id === record.id ? false : true
                  }
                  autoFocus={
                    billEdit && billRecord.id === record.id ? true : false
                  }
                  onChange={(value) =>
                    this.setState({
                      billPayment: value,
                    })
                  }
                />
              ) : (
                <InputNumber
                  id={record.id}
                  className="accounts_payable"
                  value={parseFloat(
                    billPayment !== "" &&
                      billEdit &&
                      billRecord.id === record.id
                      ? billPayment
                      : record.vendor[0]?.materialRate
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  onChange={(value) =>
                    this.setState({
                      billPayment: value,
                    })
                  }
                  prefix={"$"}
                  precision={2}
                  decimalSeparator={"."}
                  style={{
                    height: "40px",
                    width: "100%",
                    backgroundColor: "transparent",
                  }}
                  bordered={
                    billEdit && billRecord.id === record.id ? true : false
                  }
                  readOnly={
                    billEdit && billRecord.id === record.id ? false : true
                  }
                  autoFocus={
                    billEdit && billRecord.id === record.id ? true : false
                  }
                />
              )}
            </span>
          );
        },
      },

      {
        title: "Units",
        dataIndex: "",
        key: "",
        sorter: true,
        className: "col_styling",
        width: "15%",
        show: !billManagement,
        onCell: (record) => ({
          onClick: () => {
            this.setState({
              showPDFview: !billManagement,
              paymentData: record,
            });
          },
        }),
        render: (record) => {
          return (
            <span
              className="col_styling table-font-mobile-accounts"
              style={{
                textTransform: "capitalize",
                color: "#212121",
              }}
            >
              {parseInt(
                record?.ticketDetails?.reduce(
                  (a, v) => (a = a + parseInt(v?.quantity)),
                  0
                )
              )}
            </span>
          );
        },
      },
      {
        title: billManagement
          ? "AMOUNT"
          : tabKey !== "3"
          ? "AMOUNT DUE"
          : "AMOUNT PAID",
        dataIndex: "",
        key: "",
        sorter: billManagement ? true : false,
        className: "col_styling",
        // sorter:
        show: false,
        onCell: (record) => ({
          onClick: () => {
            this.setState({
              showPDFview: !billManagement,
              paymentData: record,
            });
          },
        }),
        width: billManagement ? "20%" : tabKey !== "1" ? "15%" : "10%",
        render: (record) => {
          return (
            <span
              className="col_styling table-font-mobile-accounts"
              style={{
                textTransform: "capitalize",
                color: "#212121",
              }}
            >
              {record.isTruckingJob ? (
                <InputNumber
                  className="accounts_payable"
                  value={
                    record?.APTpartialPaid
                      ? parseFloat(record?.APTpartialAmount).toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )
                      : parseFloat(
                          record.vendor[0]?.truckingCost
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                  }
                  prefix={"$"}
                  precision={2}
                  decimalSeparator={"."}
                  style={{
                    height: "40px",
                    width: "100%",
                  }}
                  bordered={false}
                  readOnly={true}
                />
              ) : (
                <InputNumber
                  className="accounts_payable"
                  value={
                    record?.APMpartialAmount
                      ? parseFloat(
                          record?.APMpartialHistory[0]?.discountApplied > 0
                            ? record?.APMpartialHistory[0]?.APpartialAmount
                            : record?.APMpartialAmount
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : parseFloat(
                          record?.vendor[0]?.materialCost
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                  }
                  prefix={"$"}
                  precision={2}
                  decimalSeparator={"."}
                  style={{
                    height: "40px",
                    width: "100%",
                  }}
                  bordered={false}
                  readOnly={true}
                />
              )}
            </span>
          );
        },
      },
      {
        title: "Early Pay Discount",
        dataIndex: "",
        key: "",
        sorter: false,
        onCell: (record) => ({
          onClick: () => {
            this.setState({
              showPDFview: !billManagement,
              paymentData: record,
            });
          },
        }),
        className: "col_styling",
        // sorter:
        show: billManagement ? true : tabKey === "1" ? false : true,
        width: "15%",
        render: (record) => {
          return (
            <span
              className="col_styling table-font-mobile-accounts"
              style={{
                textTransform: "capitalize",
                color: "#212121",
              }}
            >
              {record.vendor[0]?.vendorDiscount !== undefined &&
                record.vendor[0]?.vendorDiscount !== "" &&
                record.vendor[0]?.vendorDiscount !== null &&
                record.vendor[0]?.vendorDiscountTerms &&
                record.vendor[0]?.vendorDiscountTerms !== "" &&
                record.vendor[0]?.vendorDiscountTerms !== null &&
                record?.isTruckingAndMaterialJob &&
                record.vendor[0]?.vendorDiscount +
                  "% " +
                  record.vendor[0]?.vendorDiscountTerms}
              {/* : "N/A"} */}
            </span>
          );
        },
      },
      {
        title: "ACTIONS",
        dataIndex: "invoiceNumber",
        key: "invoiceNumber",
        sorter: false,
        show: false,
        className: "col_styling",
        width: billManagement ? "10%" : "15%",
        fixed: "right",
        render: (invoiceNumber, record) => {
          return (
            <div>
              <div className="actionpayable">
                <span
                  className="col_styling table-font-mobile-accounts"
                  style={{
                    textTransform: "capitalize",
                    display: "flex",
                    alignItems: "center",
                  }}
                  // onClick={() => this.showModalPayment(record)}
                >
                  Payment Actions
                </span>
                <span
                  style={{
                    marginLeft: "10px",
                    fontSize: "24px",
                    display: "flex",
                  }}
                >
                  <Dropdown
                    overlay={() => this.accountpayitem(record)}
                    trigger={["click"]}
                  >
                    <IoCaretDownCircleOutline className="col_styling table-font-mobile" />
                  </Dropdown>
                </span>
              </div>
            </div>
          );
        },
      },
    ].filter((e) => !e.show);

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    return (
      <div
        style={{
          background: "#fafafa",
        }}
      >
        <DownloadLoading enableDownload={loadingDownload} />
        <Loading enableLoading={loading} />
        {showPDFview ? (
          <Invoice
            paymentData={paymentData}
            onCancel={() => this.setState({ showPDFview: false })}
          />
        ) : (
          <>
            {/* {selectedRowKeys?.length > 0 && (
              <Col className="gridbtntool">
                <Button
                  type="primary"
                  icon={
                    <FilePdfOutlined
                      style={{ fontSize: "22px", color: "#ff0000c2" }}
                    />
                  }
                  onClick={this.exportData}
                  style={{
                    borderRadius: "5px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    background: "#FFF",
                    borderColor: "#ff000063",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                      marginLeft: "5px",
                      color: "#ff0000c2",
                    }}
                  >
                    Export PDF
                  </span>
                </Button>
              </Col>
            )} */}

            <Row
              style={{
                borderRadius: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "20px 0px",
                padding: "0px 20px",
                backgroundColor: "#ffffff",
                border: "1px solid gray",
                textAlign: "left",
              }}
              gutter={[12, 0]}
            >
              <Col
                xxl={{ span: 6 }}
                xl={{ span: 6 }}
                lg={{ span: 6 }}
                md={{ span: 12 }}
                sm={{ span: 12 }}
                xs={{ span: 24 }}
                style={{ margin: "12px 0px" }}
              >
                <p
                  style={{
                    fontSize: "18px",
                    color: "#747474",
                    marginBottom: "-3px",
                    fontWeight: "600",
                  }}
                  className="owner-operator-card-head"
                >
                  Overdue
                </p>
                <p
                  style={{
                    fontSize: "26px",
                    fontWeight: "bold",
                    marginBottom: "0px",
                  }}
                  className="owner-operator-card-body"
                >
                  $
                  {overDueAmount?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}{" "}
                  <span
                    style={{
                      fontSize: "18px",
                      color: "#747474",
                      fontWeight: "500",
                    }}
                    className="owner-operator-card-body"
                  >
                    USD
                  </span>
                </p>
              </Col>
              <Col
                xxl={{ span: 6 }}
                xl={{ span: 6 }}
                lg={{ span: 6 }}
                md={{ span: 12 }}
                sm={{ span: 12 }}
                xs={{ span: 24 }}
                style={{ margin: "12px 0px" }}
              >
                <p
                  style={{
                    fontSize: "18px",
                    color: "#747474",
                    marginBottom: "-3px",
                    fontWeight: "600",
                  }}
                  className="owner-operator-card-head"
                >
                  Due within next 30 days
                </p>
                <p
                  style={{
                    fontSize: "26px",
                    fontWeight: "bold",
                    marginBottom: "0px",
                  }}
                  className="owner-operator-card-body"
                >
                  $
                  {dueNext30Days?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}{" "}
                  <span
                    style={{
                      fontSize: "18px",
                      color: "#747474",
                      fontWeight: "500",
                    }}
                    className="owner-operator-card-body"
                  >
                    USD
                  </span>
                </p>
              </Col>
              <Col
                xxl={{ span: 6 }}
                xl={{ span: 6 }}
                lg={{ span: 6 }}
                md={{ span: 12 }}
                sm={{ span: 12 }}
                xs={{ span: 24 }}
                style={{ margin: "12px 0px" }}
              >
                <p
                  style={{
                    fontSize: "18px",
                    color: "#747474",
                    marginBottom: "-3px",
                    fontWeight: "600",
                  }}
                  className="owner-operator-card-head"
                >
                  Average time to Pay
                </p>
                <p
                  style={{
                    fontSize: "26px",
                    fontWeight: "bold",
                    marginBottom: "0px",
                  }}
                  className="owner-operator-card-body"
                >
                  {parseFloat(
                    paidDaysData / totalData?.filter((e) => e.paidDays)?.length
                  ).toFixed(1) === "NaN"
                    ? 0
                    : parseFloat(
                        paidDaysData /
                          totalData?.filter((e) => e.paidDays)?.length
                      ).toFixed(1)}{" "}
                  <span
                    style={{
                      fontSize: "18px",
                      color: "#747474",
                      fontWeight: "500",
                    }}
                    className="owner-operator-card-body"
                  >
                    Days
                  </span>
                </p>
              </Col>
            </Row>
            <div
              style={{
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                margin: "20px 0px",
                justifyContent: "center",
              }}
            >
              <Row
                style={{
                  width: "100%",
                  textAlign: "left",
                }}
              >
                <Col
                  xxl={{ span: 4 }}
                  xl={{ span: 4 }}
                  lg={{ span: 12 }}
                  md={{ span: 12 }}
                  sm={{ span: 8 }}
                  xs={{ span: 12 }}
                >
                  <div
                    style={{
                      margin: "12px 0px",
                      width: "100%",
                      padding: "0px 10px",
                    }}
                  >
                    <Select
                      id="reset"
                      size="large"
                      showSearch
                      className="select_Height"
                      placeholder="All Customers"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      style={{
                        width: "100%",
                      }}
                      onChange={(option, value) => {
                        this.setState({
                          customer: option,
                          materialType: value.label,
                        });
                      }}
                      value={customer}
                    >
                      {filteredCustomersData?.map((d, index) => (
                        <Option
                          key={index}
                          value={d?.customer}
                          label={d?.materialType}
                        >
                          {d?.customer}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col>

                <Col
                  xxl={{ span: 4 }}
                  xl={{ span: 4 }}
                  lg={{ span: 6 }}
                  md={{ span: 6 }}
                  sm={{ span: 8 }}
                  xs={{ span: 12 }}
                >
                  <div
                    style={{
                      margin: "12px 0px",
                      width: "100%",
                      padding: "0px 10px",
                    }}
                  >
                    <DatePicker
                      value={fromDate}
                      className="form-input"
                      style={{ width: "100%" }}
                      placeholder="From"
                      format={"MM/DD/YYYY"}
                      onChange={(e) =>
                        this.setState({
                          fromDate: e,
                          toDate: "",
                        })
                      }
                    />
                  </div>
                </Col>
                <Col
                  xxl={{ span: 4 }}
                  xl={{ span: 4 }}
                  lg={{ span: 6 }}
                  md={{ span: 6 }}
                  sm={{ span: 8 }}
                  xs={{ span: 12 }}
                >
                  <div
                    style={{
                      margin: "12px 0px",
                      width: "100%",
                      padding: "0px 10px",
                    }}
                  >
                    <DatePicker
                      value={toDate}
                      className="form-input"
                      style={{ width: "100%" }}
                      placeholder="To"
                      format={"MM/DD/YYYY"}
                      onChange={(e) =>
                        this.setState({
                          toDate: e,
                        })
                      }
                      disabledDate={(current) => {
                        return (
                          current && current.valueOf() < new Date(fromDate)
                        );
                      }}
                    />
                  </div>
                </Col>
                <Col
                  xxl={{ span: 4 }}
                  xl={{ span: 4 }}
                  lg={{ span: 6 }}
                  md={{ span: 6 }}
                  sm={{ span: 8 }}
                  xs={{ span: 12 }}
                >
                  <div
                    style={{
                      margin: "12px 0px",
                      width: "100%",
                      padding: "0px 10px",
                    }}
                  >
                    <Input
                      value={invoiceNumber}
                      className="form-input"
                      placeholder="Enter Invoice #"
                      onChange={(e) =>
                        this.setState({
                          invoiceNumber: e.target.value,
                        })
                      }
                    />
                  </div>
                </Col>
                <Col
                  xxl={{ span: 5 }}
                  xl={{ span: 5 }}
                  lg={{ span: 6 }}
                  md={{ span: 6 }}
                  sm={{ span: 8 }}
                  xs={{ span: 12 }}
                >
                  <div className="tabstatus filter">
                    <Tabs
                      activeKey={activeTab}
                      className="tabversi tabversi-dashboard"
                      onChange={this.handleTabFilters}
                    >
                      <Tabs.TabPane
                        tab={
                          <Row
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Col>All</Col>
                            <Badge
                              dot={false}
                              style={{
                                backgroundColor: "#586370",
                                margin: "1px 5px",
                                display: "flex",
                                alignContent: "center",
                                justifyContent: "center",
                              }}
                              overflowCount={1000}
                            />
                          </Row>
                        }
                        key="All"
                      ></Tabs.TabPane>
                      <Tabs.TabPane
                        tab={
                          <Row
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Col>Quarry Bill</Col>
                            <Badge
                              dot={false}
                              style={{
                                backgroundColor: "#586370",
                                margin: "1px 5px",
                                display: "flex",
                                alignContent: "center",
                                justifyContent: "center",
                              }}
                              overflowCount={1000}
                            />
                          </Row>
                        }
                        key="Quarry Bill"
                      ></Tabs.TabPane>
                      <Tabs.TabPane
                        tab={
                          <Row
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Col>Trucking Bill</Col>
                            <Badge
                              dot={false}
                              style={{
                                backgroundColor: "#586370",
                                margin: "1px 5px",
                                display: "flex",
                                alignContent: "center",
                                justifyContent: "center",
                              }}
                              overflowCount={1000}
                            />
                          </Row>
                        }
                        key="Trucking Bill"
                      ></Tabs.TabPane>
                    </Tabs>
                  </div>
                </Col>

                <Col
                  xxl={{ span: 3 }}
                  xl={{ span: 3 }}
                  lg={{ span: 14 }}
                  md={{ span: 12 }}
                  sm={{ span: 8 }}
                  xs={{ span: 12 }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                      height: "100%",
                      alignItems: "center",
                      padding: "0px 10px",
                    }}
                  >
                    <Button
                      type="primary"
                      style={{
                        height: "35px",
                        width: "100px",
                        borderRadius: "5px",
                        marginRight: "12px",
                      }}
                      onClick={this.handleFilter}
                      disabled={
                        customer || invoiceNumber || (fromDate && toDate)
                          ? false
                          : true
                      }
                    >
                      Apply
                    </Button>
                    <Button
                      style={{
                        height: "35px",
                        width: "100px",
                        borderRadius: "5px",
                      }}
                      onClick={this.handleReset}
                    >
                      Reset
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>

            <Row>
              <Col
                xxl={{ span: 24 }}
                xl={{ span: 24 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <>
                  <Divider className="linedivider show-on-desktop" />
                  <>
                    <div className="tabstatus">
                      <Tabs
                        defaultActiveKey={1}
                        className="tabversi"
                        onChange={this.handleTabChange}
                      >
                        <Tabs.TabPane
                          tab={
                            <Row
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Col>Unpaid</Col>
                              <Badge
                                count={unPaidData?.length}
                                dot={false}
                                style={{
                                  backgroundColor: "#586370",
                                  margin: "1px 5px",
                                  display: "flex",
                                  alignContent: "center",
                                  justifyContent: "center",
                                }}
                                overflowCount={1000}
                              />
                            </Row>
                          }
                          key={1}
                        >
                          <Row>
                            <Col
                              className="show-early-pay-discount"
                              span={24}
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                marginBottom: "6px",
                              }}
                            >
                              {selectedData.length > 0 &&
                                this.state.enableRowSelection && (
                                  <Col
                                    xxl={{ span: 3 }}
                                    xl={{ span: 3 }}
                                    lg={{ span: 3 }}
                                    md={{ span: 6 }}
                                    sm={{ span: 8 }}
                                    xs={{ span: 12 }}
                                  >
                                    <div
                                      style={{
                                        margin: "12px 0px",
                                        width: "max-content",
                                        // padding: "0px 10px",
                                      }}
                                    >
                                      <Col
                                        className="gridbtntool"
                                        style={{
                                          borderRadius: "5px",
                                          height: "40px",
                                          display: "flex",
                                          alignItems: "center",
                                          fontColor: "white",
                                          border:
                                            selectedData?.length > 0
                                              ? "1px solid #008000"
                                              : "1px solid gray",
                                          padding: "5px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          this.exportData(
                                            this.state.selectedData
                                          )
                                        }
                                      >
                                        <span
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                            color: "grey",
                                            display: "flex",
                                            flexWrap: "",
                                            alignItems: "center",
                                          }}
                                        >
                                          <img
                                            src={
                                              selectedData?.length > 0
                                                ? excelIcon
                                                : excelIcon1
                                            }
                                            alt="excelIcon"
                                            style={{
                                              width: "24px",
                                              height: "auto",
                                              // fontSize: "18px",
                                              marginRight: "8px",
                                            }}
                                          />
                                          <div
                                            className="fontsettingg"
                                            style={{
                                              color:
                                                selectedData?.length > 0
                                                  ? "green"
                                                  : "gray",
                                              fontSize: "14px",
                                            }}
                                          >
                                            Export Excel
                                          </div>
                                        </span>
                                      </Col>
                                    </div>
                                  </Col>
                                )}
                              {selectedData.length > 0 &&
                                this.state.enableRowSelection && (
                                  <>
                                    <Col className="gridbtntool">
                                      <Button
                                        style={{
                                          borderRadius: "5px",
                                          height: "40px",
                                        }}
                                        onClick={() => {
                                          this.downloadZip(selectedData);
                                        }}
                                        icon={
                                          <DownloadOutlined
                                            style={{
                                              fontSize: "20px",
                                              marginLeft: "8px",
                                              marginTop: "2px",
                                            }}
                                            className="trucking-filter-icon"
                                          />
                                        }
                                      >
                                        <span
                                          style={{
                                            color: "#5a6067",
                                            fontWeight: "500",
                                            fontSize: "16px",
                                          }}
                                          className="col_styling table-font-mobile"
                                        >
                                          Download
                                        </span>
                                      </Button>
                                    </Col>
                                    <Button
                                      type="primary"
                                      icon={<DollarOutlined />}
                                      onClick={() => {
                                        this.showModalPayment(
                                          this.state.selectedData,
                                          0
                                        );
                                      }}
                                      style={{
                                        borderRadius: "5px",
                                        height: "35px",
                                        margin: "auto 10px",
                                      }}
                                    >
                                      <span>Record Payment</span>
                                    </Button>
                                  </>
                                )}

                              <span
                                className="title_changes"
                                style={{ marginRight: "10px" }}
                              >
                                Show Early Pay Discount
                              </span>
                              <Switch
                                checked={toggle}
                                className="switchcolor"
                                onChange={(e) => this.switchInvoices(e)}
                              />
                            </Col>
                            <Col
                              xxl={{ span: 24 }}
                              xl={{ span: 24 }}
                              lg={{ span: 24 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <Card
                                bodyStyle={{ padding: "0px" }}
                                // style={{ background: "#eff1f3",
                                // minHeight: "80vh" }}
                                data-aos="fade-up"
                              >
                                <BaseTable
                                  className="financeinvoice_table table_laptopscreen"
                                  columnsData={columns}
                                  sortDirections={["ascend", "descend"]}
                                  source={filterUnpaidData.sort((a, b) =>
                                    a.invoiceNumber > b.invoiceNumber ? -1 : 1
                                  )}
                                  handleTableChage={(e) =>
                                    this.handleTableChage(e, "APUnpaid")
                                  }
                                  sizeRange={
                                    window.screen.width > 1023
                                      ? JSON.parse(
                                          localStorage.getItem("tableSizeRange")
                                        )?.APUnpaid
                                      : filterUnpaidData?.length
                                  }
                                  pagination={
                                    window.screen.width > 1023 ? true : false
                                  }
                                  rowSelection={rowSelection}
                                  total={filterUnpaidData?.length}
                                  rowKey={(record) => record.id}
                                />
                              </Card>
                            </Col>
                          </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                          tab={
                            <Row
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Col>Overdue</Col>
                              <Badge
                                count={overDueData?.length}
                                dot={false}
                                style={{
                                  backgroundColor: "#586370",
                                  margin: "1px 5px",
                                  display: "flex",
                                  alignContent: "center",
                                  justifyContent: "center",
                                }}
                                overflowCount={1000}
                              />
                            </Row>
                          }
                          key={2}
                        >
                          <Row>
                            <Col
                              className="show-early-pay-discount"
                              span={24}
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                marginBottom: "6px",
                              }}
                            >
                              {selectedData.length > 0 &&
                                this.state.enableRowSelection && (
                                  <>
                                    <Col className="gridbtntool">
                                      <Button
                                        style={{
                                          borderRadius: "5px",
                                          height: "40px",
                                        }}
                                        onClick={() => {
                                          this.downloadZip(selectedData);
                                        }}
                                        icon={
                                          <DownloadOutlined
                                            style={{
                                              fontSize: "20px",
                                              marginLeft: "8px",
                                              marginTop: "2px",
                                            }}
                                            className="trucking-filter-icon"
                                          />
                                        }
                                      >
                                        <span
                                          style={{
                                            color: "#5a6067",
                                            fontWeight: "500",
                                            fontSize: "16px",
                                          }}
                                          className="col_styling table-font-mobile"
                                        >
                                          Download
                                        </span>
                                      </Button>
                                    </Col>
                                    <Button
                                      type="primary"
                                      icon={<DollarOutlined />}
                                      onClick={() => {
                                        this.showModalPayment(
                                          this.state.selectedData,
                                          0
                                        );
                                      }}
                                      style={{
                                        borderRadius: "5px",
                                        height: "35px",
                                        margin: "auto 10px",
                                      }}
                                    >
                                      <span>Record Payment</span>
                                    </Button>
                                  </>
                                )}
                            </Col>
                            <Col
                              xxl={{ span: 24 }}
                              xl={{ span: 24 }}
                              lg={{ span: 24 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <Card bodyStyle={{ padding: "0px" }}>
                                <BaseTable
                                  className="financeinvoice_table table_laptopscreen"
                                  columnsData={columns}
                                  sortDirections={["ascend", "descend"]}
                                  source={overDueData.sort((a, b) =>
                                    a.invoiceNumber > b.invoiceNumber ? -1 : 1
                                  )}
                                  handleTableChage={(e) =>
                                    this.handleTableChage(e, "APOverdue")
                                  }
                                  sizeRange={
                                    window.screen.width > 1023
                                      ? JSON.parse(
                                          localStorage.getItem("tableSizeRange")
                                        )?.APOverdue
                                      : overDueData?.length
                                  }
                                  pagination={
                                    window.screen.width > 1023 ? true : false
                                  }
                                  rowSelection={rowSelection}
                                  total={overDueData?.length}
                                  rowKey={(record) => record.id}
                                />
                              </Card>
                              {selectedRowKeys.length > 0 && (
                                <FooterData
                                  count={selectedRowKeys.length}
                                  invoice
                                />
                              )}
                            </Col>
                          </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                          tab={
                            <Row
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Col>Paid</Col>

                              <Badge
                                count={PaidData?.length}
                                dot={false}
                                style={{
                                  backgroundColor: "#586370",
                                  margin: "1px 5px",
                                  display: "flex",
                                  alignContent: "center",
                                  justifyContent: "center",
                                }}
                                overflowCount={1000}
                              />
                            </Row>
                          }
                          key={3}
                        >
                          <Col
                            className="show-early-pay-discount"
                            span={24}
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              marginBottom: "6px",
                            }}
                          >
                            {selectedData.length > 0 &&
                              this.state.enableRowSelection && (
                                <>
                                  <Col className="gridbtntool">
                                    <Button
                                      style={{
                                        borderRadius: "5px",
                                        height: "40px",
                                      }}
                                      onClick={() => {
                                        this.downloadZip(selectedData);
                                      }}
                                      icon={
                                        <DownloadOutlined
                                          style={{
                                            fontSize: "20px",
                                            marginLeft: "8px",
                                            marginTop: "2px",
                                          }}
                                          className="trucking-filter-icon"
                                        />
                                      }
                                    >
                                      <span
                                        style={{
                                          color: "#5a6067",
                                          fontWeight: "500",
                                          fontSize: "16px",
                                        }}
                                        className="col_styling table-font-mobile"
                                      >
                                        Download
                                      </span>
                                    </Button>
                                  </Col>
                                </>
                              )}
                          </Col>
                          <Col
                            xxl={{ span: 24 }}
                            xl={{ span: 24 }}
                            lg={{ span: 24 }}
                            md={{ span: 24 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <Card bodyStyle={{ padding: "0px" }}>
                              <BaseTable
                                className="financeinvoice_table table_laptopscreen"
                                columnsData={columns}
                                sortDirections={["ascend", "descend"]}
                                source={PaidData?.sort((a, b) =>
                                  a.invoiceNumber > b.invoiceNumber ? -1 : 1
                                )}
                                handleTableChage={(e) =>
                                  this.handleTableChage(e, "APPaid")
                                }
                                sizeRange={
                                  window.screen.width > 1023
                                    ? JSON.parse(
                                        localStorage.getItem("tableSizeRange")
                                      )?.APPaid
                                    : PaidData?.length
                                }
                                pagination={
                                  window.screen.width > 1023 ? true : false
                                }
                                rowSelection={rowSelection}
                                total={PaidData?.length}
                                rowKey={(record) => record.id}
                              />
                            </Card>
                          </Col>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                          tab={
                            <Row
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Col>All Invoices</Col>

                              <Badge
                                count={filterData?.length}
                                dot={false}
                                style={{
                                  backgroundColor: "#586370",
                                  margin: "1px 5px",
                                  display: "flex",
                                  alignContent: "center",
                                  justifyContent: "center",
                                }}
                                overflowCount={1000}
                              />
                            </Row>
                          }
                          key={4}
                        >
                          <Col
                            className="show-early-pay-discount"
                            span={24}
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              marginBottom: "6px",
                            }}
                          >
                            {selectedData.length > 0 &&
                              this.state.enableRowSelection && (
                                <>
                                  <Col className="gridbtntool">
                                    <Button
                                      style={{
                                        borderRadius: "5px",
                                        height: "40px",
                                      }}
                                      onClick={() => {
                                        this.downloadZip(selectedData);
                                      }}
                                      icon={
                                        <DownloadOutlined
                                          style={{
                                            fontSize: "20px",
                                            marginLeft: "8px",
                                            marginTop: "2px",
                                          }}
                                          className="trucking-filter-icon"
                                        />
                                      }
                                    >
                                      <span
                                        style={{
                                          color: "#5a6067",
                                          fontWeight: "500",
                                          fontSize: "16px",
                                        }}
                                        className="col_styling table-font-mobile"
                                      >
                                        Download
                                      </span>
                                    </Button>
                                  </Col>
                                </>
                              )}
                          </Col>
                          <Col
                            xxl={{ span: 24 }}
                            xl={{ span: 24 }}
                            lg={{ span: 24 }}
                            md={{ span: 24 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <Card bodyStyle={{ padding: "0px" }}>
                              <BaseTable
                                className="financeinvoice_tabletable_laptopscreen"
                                columnsData={columns}
                                sortDirections={["ascend", "descend"]}
                                source={filterData.sort((a, b) =>
                                  a.invoiceNumber > b.invoiceNumber ? -1 : 1
                                )}
                                handleTableChage={(e) =>
                                  this.handleTableChage(e, "APAllInvoices")
                                }
                                sizeRange={
                                  window.screen.width > 1023
                                    ? JSON.parse(
                                        localStorage.getItem("tableSizeRange")
                                      )?.APAllInvoices
                                    : filterData?.length
                                }
                                pagination={
                                  window.screen.width > 1023 ? true : false
                                }
                                rowSelection={rowSelection}
                                total={filterData?.length}
                                rowKey={(record) => record.id}
                              />
                            </Card>
                          </Col>
                        </Tabs.TabPane>
                      </Tabs>
                    </div>
                  </>
                </>
              </Col>
            </Row>
          </>
        )}

        {showPaymentModal && paymentData && (
          <BaseModal
            className="tickectfinan_modal antmodal_grid headermodal"
            title={`Record a payment for Invoice #${paymentData.invoiceNumber}`}
            visible={showPaymentModal}
            onCancel={() => {
              this.setState({
                showPaymentModal: false,
                materialDiscount: "",
                showMessage: false,
                paymentAttachment: [],
              });
            }}
            formId="paymentForm"
          >
            {/* <span className="payable-title">Record a Payment
you've already received,Suchas checque,cash or Bank payment</span> */}
            <Form
              id="paymentForm"
              onFinish={this.onFinish}
              ref={this.formRef}
              className="formpayablecontent"
            >
              <Row gutter={[8, 0]}>
                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <div className="payflex">
                    <span className="title_changes">Payment Date</span>
                    <Form.Item
                      name="paymentDate"
                      rules={[
                        {
                          required: true,
                          message: "Please input your paymentDate!",
                        },
                      ]}
                      initialValue={moment()}
                    >
                      <DatePicker
                        className="form-input payabledate"
                        style={{ width: "100%" }}
                        format={"MM/DD/YYYY"}
                        placeholder="Payment Date"
                        onChange={
                          this.onChangeDate
                          // this.setState({
                          //   fromDate: e,
                          //   toDate: "",
                          // })
                        }
                      />
                    </Form.Item>
                  </div>
                </Col>

                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <div className="payflex">
                    <span className="title_changes">Amount</span>
                    <Form.Item
                      name="paymentAmount"
                      rules={[
                        {
                          required: true,
                          message: "Please input your paymentAmount!",
                        },
                      ]}
                      initialValue={
                        paymentData.isTruckingJob
                          ? paymentData?.APTpartialPaid
                            ? paymentData?.APTpartialAmount?.toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : paymentData?.vendor[0]?.truckingCost?.toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                          : paymentData?.APMpartialPaid.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )
                          ? paymentData?.APMpartialAmount
                          : materialDiscount !== ""
                          ? materialDiscount.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : paymentData?.vendor[0]?.materialCost.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )
                      }
                    >
                      <InputNumber
                        formatter={(value) => {
                          const formattedValue = `${parseFloat(value || 0)}`;
                          return formattedValue.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          );
                        }}
                        parser={(value) => {
                          return parseFloat(value.replace(/\$\s?|(,*)/g, ""));
                        }}
                        prefix={"$"}
                        precision={2}
                        decimalSeparator={"."}
                        placeholder="Please Enter Payment Amount"
                        style={{
                          width: "100%",
                        }}
                        className="numberbox"
                        onChange={this.onChangeAmount}
                      />
                    </Form.Item>
                    {showMessage && (
                      <Col
                        xxl={{ span: 12, offset: 7 }}
                        xl={{ span: 12, offset: 7 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <span
                          style={{
                            float: "center",
                            fontSize: "15px",
                            color: "#16993f",
                          }}
                        >
                          {`Early Pay Discount Of
                            ${
                              paymentData?.vendor[0]?.vendorDiscount !==
                                undefined &&
                              paymentData?.vendor[0]?.vendorDiscount
                            }% Applied`}
                        </span>
                      </Col>
                    )}
                  </div>
                </Col>

                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <div className="payflex">
                    <span className="title_changes">Payment Method</span>
                    <Form.Item
                      name="paymentMethod"
                      rules={[
                        {
                          required: true,
                          message: "Please input your paymentMethod!",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        showSearch
                        allowClear
                        className="select_Height"
                        placeholder="Please Select A Payment Method"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        style={{ width: "100%" }}
                      >
                        {paymentCategories?.map((d, index) => (
                          <Option key={index} value={d.value}>
                            {d.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <div className="payflex">
                    <span className="title_changes">Notes</span>
                    <Form.Item
                      name="paymentNotes"
                      rules={[
                        {
                          required: false,
                          message: "Please input your paymentNotes!",
                        },
                      ]}
                    >
                      <TextArea
                        rows={4}
                        style={{
                          width: "100%",
                          height: "75px",
                          borderColor: "#d6d6d6",
                        }}
                        className="form-input"
                        placeholder="Please Enter Notes"
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={24}
                >
                  <span className="title_changes">Payment Picture</span>
                  <Form.Item
                    name="paymentPicture"
                    rules={[
                      {
                        required: false,
                        message: "Please Attach paymentPicture",
                      },
                    ]}
                  >
                    <Spin spinning={imageLoading}>
                      <Dragger
                        onChange={(e) =>
                          this.uploadTicketPdf(e, "paymentPicture")
                        }
                        customRequest={dummyRequest}
                        listType="picture"
                        multiple={true}
                        className="drag-ticket"
                        showUploadList={false}
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                          Click or drag file to this area to upload
                        </p>
                      </Dragger>

                      <div style={{ marginTop: "10px" }}>
                        {paymentAttachment?.length > 0 &&
                          paymentAttachment?.map((file) => (
                            <div style={{ marginBottom: "5px" }}>
                              <Card
                                key={file.uid}
                                bodyStyle={{ padding: "4px 10px 4px 4px" }}
                                width="100%"
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  {file.fileType === "image/jpeg" ||
                                  file.fileType === "image/png" ? (
                                    <img
                                      src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.us-east-1.amazonaws.com/public/${file?.paymentAttachmentId}`}
                                      alt="Avatar"
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        objectFit: "fill",
                                        marginRight: "10px",
                                      }}
                                    />
                                  ) : (
                                    <FilePdfOutlined
                                      style={{
                                        marginRight: "10px",
                                        fontSize: "35px",
                                      }}
                                    />
                                  )}
                                  <span>{file?.fileName}</span>
                                  <Button
                                    icon={
                                      <DeleteOutlined
                                        style={{
                                          fontSize: "18px",
                                        }}
                                      />
                                    }
                                    onClick={() =>
                                      this.handleFileDelete(
                                        file,
                                        "paymentPicture"
                                      )
                                    }
                                    className="upload_Delete_Icon"
                                  />
                                </div>
                              </Card>
                            </div>
                          ))}
                      </div>
                    </Spin>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  totalResult: state?.reducer?.invoiceResult,
});

const mapDispatchToProps = (dispatch) => ({
  actionUpdateInvoice: (id, data) => {
    dispatch(updateInvoices(id, data));
  },
  actionFetchGetInvoiceDetails: (id) => {
    dispatch(getInvoiceDetails(id));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAppContext(withRouter(AccountsPayable)));
