import React from "react";
import { DragDropContext, DropTarget, DragSource } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import updateData from "immutability-helper";
import {
  Row,
  Col,
  message,
  Form,
  Button,
  Switch,
  Checkbox,
  Input,
  Select,
  Tooltip,
  InputNumber,
  Divider,
  Spin,
  DatePicker,
  Radio,
} from "antd";
import { add, deleteRecord, getDetails, list, update } from "../api/Apis";
import appConfig from "../config/AppConfig";
import { Storage } from "aws-amplify";
import { withAppContext } from "../Store/AppContext";
import { withRouter } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import BaseTable from "./BaseTable";
import BaseModal from "./BaseModal";
import TextArea from "antd/lib/input/TextArea";
import {
  PlusOutlined,
  UsergroupAddOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { v4 as uuidv4 } from "uuid";
import Loading from "./Loading";
import NumberFormat from "react-number-format";
import moment from "moment";
// import { BsFillCircleFill } from "react-icons/bs";
import QuarryJsonData from "../Json/quarryData.json";
import Dragger from "antd/lib/upload/Dragger";

const { Option } = Select;

const jobType = [
  { label: "Trucking Job", value: "Trucking Job" },
  { label: "Trucking & Material Job", value: "Trucking & Material Job" },
];

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

const stageConditions = {
  "Uncontacted Lead": [
    "Pending Carrier Packet + Insurance",
    "Archived Carrier",
    "Stalled Application",
  ],
  "Pending Carrier Packet + Insurance": [
    "Uncontacted Lead",
    "Pending Insurance",
    "Pending Carrier Packet",
    "Archived Carrier",
    "Carrier Data Input",
    "Stalled Application",
  ],
  "Pending Insurance": [
    "Uncontacted Lead",
    "Pending Carrier Packet + Insurance",
    "Carrier Data Input",
    "Archived Carrier",
    "Stalled Application",
  ],
  "Pending Carrier Packet": [
    "Uncontacted Lead",
    "Pending Carrier Packet + Insurance",
    "Pending Insurance",
    "Carrier Data Input",
    "Archived Carrier",
    "Stalled Application",
  ],
  "Carrier Data Input": [
    "Uncontacted Lead",
    "Pending Carrier Packet + Insurance",
    "Pending Insurance",
    "Pending Carrier Packet",
    "Welcome Call",
    "Archived Carrier",
    "Stalled Application",
  ],
  "Welcome Call": [
    "Uncontacted Lead",
    "Pending Carrier Packet + Insurance",
    "Pending Insurance",
    "Pending Carrier Packet",
    "Carrier Data Input",
    "Approved Carrier",
    "Archived Carrier",
    "Stalled Application",
  ],
  "Approved Carrier": [],
  "Archived Carrier": [
    "Pending Carrier Packet + Insurance",
    "Stalled Application",
  ],
  "Stalled Application": ["Pending Carrier Packet + Insurance"],
};
class DraggableCard extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      funnelData: [],
      leadsData: [],
      funnelId: "",
      isHovering: false,
      isModalVisible: false,
      isContactVisible: false,
      leadDetails: "",
      contactsData: [],
      trucksData: [],
      dropAddress: "",
      jobInfo: "",
      dropoffLatitude: "",
      dropoffLongitude: "",
      seperate: false,
      initialMaterialCost: "",
      materialPriceState: "",
      selectVendor: "",
      materialValue: "",
      categoriesList: [],
      materialCostPrice: "",
      materialTruckPrice: 0,
      milesData: "",
      duration: "",
      vendorName: "",
      quantity: 1,
      masterRoleId: "",
      masterTruckId: "",
      truckingPriceType: "Ton",
      quantityType: "Ton",
      totalPriceType: "Ton",
      materialCostType: "Ton",
      truckingCostType: "Ton",
      leadQuote: [],
      selectedMaterial: [],
      selectedMaterialType: "",
      truckId: "",
      localTruckId: "",
      openDropdown: "",
      topVendorsData: [],
      vendorData: [],
      customRate: false,
      materialCustomRate: false,
      vendorDetails: "",
      salesCommission: "",
      addTruck: false,
      imageLoading: false,
      overWeightPermitState: "",
      companyData: {},
      selectedTruckCode: "",
      fileType: "",
    };
  }

  componentDidMount() {
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
    this.getDropDownOptions();
  }

  static getDerivedStateFromProps(props) {
    return {
      funnelData: props.headingData,
      leadsData: props.contentData.sort((a, b) =>
        a.createdAt < b.createdAt ? 1 : -1
      ),
    };
  }

  getLeadDetails = (id) => {
    getDetails(appConfig["entityNames"]["singleLead"], id).then((result) => {
      if (result.success) {
        this.setState({
          isModalVisible: true,
          jobInfo: result.data?.jobType,
          jobEditModal: true,
          selectedMaterial: result.data?.material ? result.data?.material : [],
          selectedTruckType: result.data?.truckType,
          selectedMaterialType: "",
          selectedDropOffAddress: result.data?.dropoff,
          dropAddress: result.data?.dropoff,
          leadQuote: result.data?.multipleContacts,
          dropoffLatitude: result.data?.dropOffLatLng?.lat,
          dropoffLongitude: result.data?.dropOffLatLng?.lng,
          taxExempt: result.data?.taxExempt,
          leadDetails: result.data,
          openDropdown: result.data?.taxExempt
            ? result.data?.taxExempt
            : "Material is quoted as",
        });
        this.getCompanyContacts(
          result?.data?.companyId,
          result.data?.multipleContacts
        );
      } else {
        this.setState({
          loading: false,
          errorLoading: true,
        });
      }
    });
  };

  getCompanyContacts = (id, jobContacts) => {
    getDetails(appConfig["entityNames"]["companyDetails"], id).then(
      (result) => {
        let quarryContacts = [];
        if (result.success) {
          quarryContacts = jobContacts.concat(result.data.contactDetails);
          this.setState({
            contactsData: quarryContacts?.filter(
              (value, index, self) =>
                index === self.findIndex((t) => t?.id === value?.id)
            ),
            companyId: id,
          });
        }
      }
    );
  };

  getDropDownOptions = () => {
    list("dropDownOptions", { typeofDropDown: "material" }).then((result) => {
      let materialData = {};
      result.data[0]?.materialsNewList?.forEach((eachMat, index) => {
        if (eachMat?.statusCode) {
          materialData[eachMat.category] = !Object.keys(materialData).includes(
            eachMat.category
          )
            ? []
            : materialData[eachMat.category];
        }
      });
      this.setState({
        materialCategory: materialData,
        // rolesData: this.props.rolesData[0]?.rolesData?.sort((a, b) =>
        //   a?.value > b?.value ? 1 : -1
        // ),
        // trucksData: this.props.trucksData[0]?.trucksData,
        materialList: result.data[0]?.materialsNewList,
      });
    });
  };

  onSelectLead = (e) => {
    // console.log(e);
    const { contactsData } = this.state;
    // console.log(contactsData);
    let data = contactsData?.filter((contact) => e?.includes(contact?.id));
    // console.log(data);
    this.setState({ leadQuote: data, contactId: e });
  };

  update = (id, status) => {
    const { leadsData } = this.state;
    const taskData = leadsData.find((task) => task.id === id);
    console.log(taskData, id, status);
    if (taskData?.stageId === "Job Lead" && status === "Contacted") {
      // console.log("Job Lead");
      this.updateStage(id, status, taskData);
    } else if (
      (taskData?.stageId === "Contacted" || taskData?.stageId === "Paused") &&
      status === "Build Quote"
    ) {
      // console.log("Contacted");
      if (taskData?.fileName) {
        message.info("Quote is already generated for this job");
      } else {
        this.getLeadDetails(id);
        this.setState({
          selectedStatus: status,
        });
      }
    } else if (
      taskData?.stageId === "Build Quote" &&
      (status === "Quote Delivered" ||
        status === "Quote Accepted" ||
        status === "Dispatch Approved" ||
        status === "Paused" ||
        status === "Close Job")
    ) {
      // console.log("Generate");
      this.updateStage(id, status, taskData);
    } else if (
      taskData?.stageId === "Quote Delivered" &&
      (status === "Quote Accepted" ||
        status === "Dispatch Approved" ||
        status === "Paused" ||
        status === "Close Job")
    ) {
      // console.log("Delivered");
      this.updateStage(id, status, taskData);
    } else if (
      taskData?.stageId === "Quote Accepted" &&
      (status === "Dispatch Approved" ||
        status === "Paused" ||
        status === "Close Job")
    ) {
      // console.log("Accepted");
      this.updateStage(id, status, taskData);
    } else if (
      taskData?.stageId === "Dispatch Approved" &&
      (status === "Paused" || status === "Close Job")
    ) {
      // console.log("Dispatch");
      this.updateStage(id, status, taskData);
    } else if (
      taskData?.stageId === "Paused" &&
      (status === "Quote Delivered" ||
        status === "Quote Accepted" ||
        status === "Contacted" ||
        status === "Close Job")
    ) {
      // console.log("Paused");
      this.updateStage(id, status, taskData);
    } else if (
      taskData?.stageId === "Close Job" &&
      (status === "Quote Delivered" ||
        status === "Quote Accepted" ||
        status === "Contacted" ||
        status === "Dispatch Approved" ||
        status === "Paused")
    ) {
      message.info("This Job is closed");
    }
  };

  updateStage = (id, status, taskData) => {
    // console.log("object");
    const { leadsData } = this.state;
    taskData.stageId = status;
    const taskIndex = leadsData.indexOf(taskData);
    let data = {
      entityData: {
        stageId: taskData["stageId"],
      },
    };
    const newTasks = updateData(taskData, {
      [taskIndex]: { $set: taskData },
    });
    this.setState({
      taskData: newTasks,
    });

    update(appConfig["entityNames"]["updateLead"], id, data)
      .then((result) => {
        message.success(`Status updated to ${status}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateCompany = (id, status) => {
    const { leadsData } = this.state;
    const taskData = leadsData.find((task) => task.id === id);
    if (
      taskData?.stageId === "Carrier Data Input" &&
      status === "Welcome Call"
    ) {
      this.welcomeCallCondition(id, status, taskData);
      this.setState({
        companyData: taskData,
        selectedAddress: taskData?.address,
        latitude: taskData?.lat,
        longitude: taskData?.lng,
      });
    } else if (
      taskData?.stageId === "Welcome Call" &&
      status === "Approved Carrier"
    ) {
      taskData.stageId = status;
      const taskIndex = leadsData.indexOf(taskData);
      let data = {
        entityData: {
          stageId: taskData["stageId"],
        },
      };
      const newTasks = updateData(leadsData, {
        [taskIndex]: { $set: taskData },
      });
      this.setState({
        leadsData: newTasks,
        loading: true,
      });
      update(appConfig["entityNames"]["updateCompany"], id, data)
        .then((result) => {
          if (result.success) {
            this.addToMasterData(taskData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (
      taskData?.stageId === "Stalled Application" &&
      status === "Pending Carrier Packet + Insurance"
    ) {
      const { leadsData } = this.state;
      taskData.stageId = status;
      const taskIndex = leadsData.indexOf(taskData);
      let data = {
        entityData: {
          stageId: taskData["stageId"],
          stageIdUpdatedDate: moment().format("L"),
          registeredSince: moment().format("L"),
          createdAt: Date.now(),
        },
      };
      const newTasks = updateData(leadsData, {
        [taskIndex]: { $set: taskData },
      });
      this.setState({
        leadsData: newTasks,
      });
      update(appConfig["entityNames"]["updateCompany"], id, data)
        .then((result) => {})
        .catch((err) => {
          console.log(err);
        });
    } else if (stageConditions[taskData?.stageId].includes(status)) {
      this.updateOwnerStage(id, status, taskData);
    }
  };

  addToMasterData = (taskData) => {
    let finalData = {
      entityData: {
        ...taskData,
      },
    };
    // console.log(finalData);
    add("trucksList", finalData).then((res) => {
      if (res.success) {
        message.info(
          `${res.data.data.carrier_name} Added To TNBS Owner Operator Successfully`
        );
        this.setState({ loading: false });
      }
    });
  };

  updateOwnerStage = (id, status, taskData) => {
    const { leadsData } = this.state;
    taskData.stageId = status;
    const taskIndex = leadsData.indexOf(taskData);
    let data = {
      entityData: {
        stageId: taskData["stageId"],
        stageIdUpdatedDate: moment().format("L"),
      },
    };
    // console.log(data);
    const newTasks = updateData(leadsData, {
      [taskIndex]: { $set: taskData },
    });
    this.setState({
      leadsData: newTasks,
    });
    update(appConfig["entityNames"]["updateCompany"], id, data)
      .then((result) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  getCompanies = (id, status, taskData) => {
    this.setState({ loading: true });
    list("companies").then((result) => {
      if (result.success) {
        let data = [];
        let finalRes = result.data.filter((c) => c.isOwnerOperator);
        finalRes.forEach((e) => {
          if (e?.isOwnerOperator && e?.carrierDetails?.length > 0) {
            e?.carrierDetails?.forEach((c) => {
              if (c?.truckId !== null && c?.truckId !== undefined) {
                data.push(parseInt(c?.truckId));
              }
            });
          }
        });
        let res = data?.length > 0 ? data.flat() : [0];
        let truckIdLocal = Math.max(...res);
        // console.log(truckIdLocal);
        this.setState(
          {
            localTruckId: truckIdLocal,
          },
          () => this.getTwistedNailData(id, status, taskData)
        );
      } else {
        this.setState({ loading: false, errorLoading: true });
      }
    });
  };

  getTwistedNailData = (id, status, taskData) => {
    list("trucksList")
      .then((result) => {
        if (result.success) {
          let data = [];
          result.data.forEach((e) => {
            if (e?.carrierDetails?.length > 0) {
              e?.carrierDetails?.forEach((c) => {
                if (c?.truckId !== null && c?.truckId !== undefined) {
                  data.push(parseInt(c?.truckId));
                }
              });
            }
          });
          let res = data?.length > 0 ? data.flat() : [100];
          let truckIdLocal = Math.max(...res);
          this.setState(
            {
              truckId: truckIdLocal,
            },
            () => this.addTruckId(id, status, taskData?.carrierDetails)
          );
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  };

  addTruckId = (id, status, data) => {
    const { truckId, localTruckId } = this.state;

    let count = 0;
    if (Array.isArray(data) && data?.length > 0) {
      for (let i of data) {
        count += 1;
        if (localTruckId > truckId) {
          i["truckId"] = localTruckId + count;
        } else {
          i["truckId"] = truckId + count;
        }
      }
      let finalData = {
        entityData: {
          carrierDetails: data,
          stageId: status,
        },
      };
      // console.log(finalData);
      this.updateCompanyDetails(id, finalData);
    }
    // else {
    //   message.error("Please Enter Atleast One Equipment");
    // }
  };

  updateCompanyDetails = (id, data) => {
    update(appConfig["entityNames"]["updateCompany"], id, data)
      .then((res) => {
        if (res.success) {
          this.getCompanies();
          this.props.onResetData();
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onDoubleClick = (id) => {
    this.props.history.push(`/app/jobs/job-profile/${id}`);
  };

  handleMouseEnter = (id) => {
    this.setState({
      isHovering: id,
    });
  };

  handleMouseLeave = (id) => {
    this.setState({
      isHovering: false,
    });
  };

  editModal = (record) => {
    this.setState({ isContactVisible: true, jobContactData: record });
  };

  getAddress = (address, type) => {
    if (type === "pickup") {
      this.setState({
        selectedPickupAddress: address.label,
      });
    } else if (type === "dropOff") {
      this.setState({
        selectedDropOffAddress: address.label,
      });
    } else {
      this.setState({ selectedAddress: address.label });
    }
  };

  handleSelect = (address, type) => {
    const { dropoffLatitude, dropoffLongitude } = this.state;
    const citySearch = [];
    citySearch.push(address.label);
    this.setState({
      citySearch,
    });
    let cityValue = "";
    let stateValue = "";
    let zipCodeValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        getLatLng(results[0]).then(({ lat, lng }) => {
          if (type === "pickup") {
            this.setState({
              loading: true,
            });
            let url =
              "https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&destinations=" +
              [dropoffLatitude, dropoffLongitude] +
              "&origins=" +
              [lat, lng] +
              "&key=AIzaSyB781k9fNf61hnxE3A6cvcascwhAhIVIK4";
            let milesData = {
              entityData: {
                url,
              },
            };
            add("getMiles", milesData)
              .then((res) =>
                this.setState({
                  pickUpLatitude: lat,
                  pickupLongitude: lng,
                  milesData: res.data.distance,
                  duration: res.data.duration,
                  loading: false,
                })
              )
              .catch((err) => {
                this.setState({
                  loading: false,
                });
                console.log(err, "err");
              });
          } else if (type === "dropOff") {
            this.setState({ dropoffLatitude: lat, dropoffLongitude: lng });
          } else {
            this.setState({ latitude: lat, longitude: lng });
          }
        });
        const geoIdData = results[0];
        this.setState({
          geoIdData,
        });
        let address = results[0].address_components;
        for (let i = 0; i < address?.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
          if (address[i].types[0] === "postal_code") {
            zipCodeValue = address[i].long_name;
          }
        }
        this.setState({
          city: cityValue,
          addressState: stateValue,
          zipCode: zipCodeValue,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  onChangeTrucksType = (values) => {
    // console.log("checked", values);
    this.setState({ selectedTruckType: values });
  };

  onFinish = (values) => {
    const {
      leadDetails,
      selectedStatus,
      jobInfo,
      // truckDropInfo,
      selectedTruckType,
      selectedMaterial,
      leadQuote,
      dropoffLatitude,
      dropoffLongitude,
      selectedDropOffAddress,
      salesCommission,
    } = this.state;
    const { match } = this.props;
    const { params } = match;
    const { id } = params;

    let accountPayableContact = leadQuote?.filter(
      (e) => e?.contactRole === "Accounts Payable"
    );
    // console.log(accountPayableContact, "accountPayableContact");

    let commonData = {
      contactperson: "",
      contactId: "",
      jobName: values["jobName"],
      jobType: values["jobType"],
      dropoff: selectedDropOffAddress,
      dropOffLatLng: {
        lat: dropoffLatitude,
        lng: dropoffLongitude,
      },
      multipleContacts: leadQuote,
      truckType: selectedTruckType,
      notes:
        values["notes"] === ""
          ? ""
          : values["notes"] !== ""
          ? values["notes"]
          : leadDetails?.notes,
      salesPersonId: values["salesPerson"],
      salesCommission,
      // dateOfHaul:
      //   moment(values["dateOfHaul"]).format("L") || leadDetails?.dateOfHaul,
      durationOfJob: values["durationOfJob"],
    };
    if (selectedStatus === "Build Quote") {
      let statusCommonData = {
        ...commonData,
        company: leadDetails?.companyDetails?.company,
        companyName: leadDetails?.companyDetails?.company,
        companyAddress: leadDetails?.companyDetails?.address,
        stageId: selectedStatus,
        accountsPayableContact: accountPayableContact,
        accountTerms: leadDetails?.companyDetails?.accountTerms,
      };
      if (jobInfo === "Trucking Job") {
        let leadData = {
          entityData: {
            ...statusCommonData,
            material: selectedMaterial,
          },
        };
        leadData.entityData.material.map((e) => (e["assigned"] = false));
        // console.log(leadData, "leadData");
        this.verificationTruckingJob(id, leadData);
      } else {
        let leadData = {
          entityData: {
            ...statusCommonData,
            taxExempt: values.taxExempt,
            taxIncluded: values.taxIncluded,
            taxStatus: values.taxIncluded === "Tax Added" ? true : false,
            seperate:
              this.state.seperate === true ? this.state.seperate : false,
            material: selectedMaterial,
          },
        };
        leadData.entityData.material.map((e) => (e["assigned"] = false));
        // console.log(leadData, "leadData");
        this.verificationTuckingAndMaterial(id, leadData);
      }
    }
    // if (this.state.isCheckBox) {
    //   // console.log("checked contact");
    //   this.updateRolesData(values["roleOther"]);
    // }
    // else if (this.state.isCheckBoxTruckType) {
    //   this.updateTrucksType(values["truckOther"], "trucksType");
    // }
  };

  updateJobMaterials = (data) => {
    this.setState({ loading: true });
    const { leadDetails } = this.state;
    let materialsData = leadDetails?.material;
    // console.log(materialsData, data);
    materialsData?.map((m) => {
      data?.map((d) => {
        if (d?.materialId === m?.id) {
          m["distance"] = d?.distance;
          m["duration"] = d?.duration;
        }
        return true;
      });
      return true;
    });
    // console.log(materialsData);
    let finalData = {
      entityData: {
        material: materialsData,
      },
    };
    // console.log(finalData);
    this.updateLead(leadDetails?.id, finalData);
  };

  verificationTruckingJob = (id, data) => {
    const { selectedMaterial, selectedTruckType, leadDetails } = this.state;
    if (
      selectedMaterial[0]?.pickUpAddress !== "" &&
      selectedMaterial[0]?.pickUpAddress !== undefined &&
      selectedMaterial?.length !== 0
    ) {
      if (selectedTruckType !== undefined && selectedTruckType?.length !== 0) {
        if (
          this.state?.dropAddress !== "" &&
          this.state?.dropAddress !== undefined
        ) {
          if (
            selectedMaterial[0]?.truckingCost !== "" &&
            selectedMaterial[0]?.truckingCost !== undefined &&
            selectedMaterial[0]?.truckingPrice !== "" &&
            selectedMaterial[0]?.truckingPrice !== undefined
          ) {
            if (
              leadDetails?.companyDetails?.accountTerms !== "" &&
              leadDetails?.companyDetails?.accountTerms !== undefined
            ) {
              this.setState({
                isModalVisible: false,
              });
              this.updateLead(leadDetails?.id, data);
              // this.getDistanceAndTimeMethod(data);
              // console.log(data, "Trucking Job");
            } else {
              message.error(
                "Please Enter Account Terms In Customer Account Profile To Proceed Further"
              );
            }
          } else {
            message.error("Please Enter Trucking Cost And Trucking Price");
          }
        } else {
          message.info("Please Select Dropoff Location");
        }
      } else {
        message.info("Please Select Truck Type");
      }
    } else {
      message.info("Please Select Pickup Address");
    }
  };

  verificationTuckingAndMaterial = (id, data) => {
    const { selectedMaterial, selectedTruckType, leadDetails } = this.state;
    let vendor = data.entityData.material?.filter((e) => e?.vendor);
    if (vendor?.length === data?.entityData?.material?.length) {
      if (selectedTruckType !== undefined && selectedTruckType?.length !== 0) {
        if (
          this.state?.dropAddress !== "" &&
          this.state?.dropAddress !== undefined
        ) {
          if (selectedMaterial?.length > 0) {
            if (
              leadDetails?.companyDetails?.accountTerms !== "" &&
              leadDetails?.companyDetails?.accountTerms !== undefined
            ) {
              this.setState({
                isModalVisible: false,
              });
              this.updateLead(leadDetails?.id, data);
              // this.getDistanceAndTimeMethod(data);
            } else {
              message.error(
                "Please Enter Account Terms In Customer Account Profile To Proceed Further"
              );
            }
            // console.log(data, "Trucking Job + Material");
          } else {
            message.error("Please Select Required Materials");
          }
        } else {
          message.error("Please Select Dropoff Location");
        }
      } else {
        message.error("Please Select Truck Type");
      }
    } else {
      message.error("Please Add Vendor Details To Materials");
    }
  };

  updateLead = (id, data) => {
    this.setState({
      loading: true,
      isModalVisible: false,
    });
    if (data["entityData"]["stageId"] === "Dispatch Approved") {
      data["entityData"]["recurring"] = this.state.recurringData;
    }
    update(appConfig["entityNames"]["updateLead"], id, data)
      .then((result) => {
        // console.log(result);
        if (result.success) {
          this.props.requestMethod();
          this.setState({
            loading: false,
            selectedMaterial: [],
            recordMaterialData: [],
            truckDropInfo: false,
            materialCostType: "Ton",
            truckingCostType: "Ton",
            quantityType: "Ton",
            totalPriceType: "Ton",
            showSpecificDate: false,
            iscalenderVisible: false,
            taxExempt: "",
            dropoffLatitude: "",
            dropoffLongitude: "",
          });
        } else {
          this.setState({
            loading: false,
            selectedMaterial: [],
            recordMaterialData: [],
            truckDropInfo: false,
            materialCostType: "Ton",
            truckingCostType: "Ton",
            quantityType: "Ton",
            totalPriceType: "Ton",
            showSpecificDate: false,
            iscalenderVisible: false,
            taxExempt: "",
            dropoffLatitude: "",
            dropoffLongitude: "",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
          selectedMaterial: [],
          recordMaterialData: [],
          truckDropInfo: false,
          materialCostType: "Ton",
          truckingCostType: "Ton",
          quantityType: "Ton",
          totalPriceType: "Ton",
          showSpecificDate: false,
          iscalenderVisible: false,
          taxExempt: "",
          dropoffLatitude: "",
          dropoffLongitude: "",
        });
      });
  };

  onFinishMaterial = (values) => {
    const {
      recordMaterialData,
      materialSelectedData,
      quantityType,
      jobInfo,
      pickUpLatitude,
      pickupLongitude,
      truckingPriceType,
      selectedPickupAddress,
      initialMaterialCost,
      duration,
      milesData,
      totalPriceType,
      materialCostType,
      truckingCostType,
      vendorDetails,
      topVendorsData,
      materialPriceState,
      quantity,
      materialTruckPrice,
      truckingCost,
      diameter,
      selectedMaterial,
    } = this.state;

    if (jobInfo === "Trucking Job") {
      let truckingData = {
        entityData: {
          id:
            materialSelectedData["id"] === undefined
              ? uuidv4()
              : materialSelectedData["id"],
          truckingPrice:
            parseFloat(values["truckingPrice"]) ||
            recordMaterialData?.truckingPrice,
          truckingPriceType: truckingPriceType,
          pickUpAddress: selectedPickupAddress,
          pickUpLatLng: {
            lat: pickUpLatitude,
            lng: pickupLongitude,
          },
          truckingCost:
            parseFloat(values["truckingCost"]) ||
            recordMaterialData?.truckingCost,
          truckingCostType: truckingCostType,
          duration: duration,
          milesData: milesData,
        },
      };
      if (
        truckingData.entityData.pickUpAddress !== undefined &&
        truckingData.entityData.pickUpAddress !== ""
      ) {
        this.addMaterialOrTruckInfo(truckingData);
      } else {
        message.error("Please Enter PickUp Location");
      }
    } else {
      if (truckingPriceType === totalPriceType) {
        if (topVendorsData?.length === 0) {
          message.error("Please Find Nearest Vendors!");
          return true;
        }
        if (topVendorsData?.length !== 0 && vendorDetails === "") {
          message.error("Please select at least one Vendor!");
          return true;
        }
        let materialMinDiameter = 0,
          materialMaxDiameter = 0,
          materialDiameter = 0,
          filteredData = [];

        if (diameter) {
          materialMinDiameter =
            values["materialMinDiameter"].split(" ")?.length !== 2 &&
            String(parseInt(values["materialMinDiameter"])) !==
              values["materialMinDiameter"]
              ? this.DecimalToMixedFraction(values["materialMinDiameter"])
              : values["materialMinDiameter"];

          materialMaxDiameter =
            values["materialMaxDiameter"].split(" ")?.length !== 2 &&
            String(parseInt(values["materialMaxDiameter"])) !==
              values["materialMaxDiameter"]
              ? this.DecimalToMixedFraction(values["materialMaxDiameter"])
              : values["materialMaxDiameter"];
        } else {
          materialDiameter =
            values["materialDiameter"].split(" ")?.length !== 2 &&
            String(parseInt(values["materialDiameter"])) !==
              values["materialDiameter"]
              ? this.DecimalToMixedFraction(values["materialDiameter"])
              : values["materialDiameter"];
        }

        selectedMaterial?.forEach((c) => {
          if (
            c.vendorId === vendorDetails?.vendorId &&
            c.id === materialSelectedData?.id &&
            (c.diameter
              ? c["materialMinDiameter"] === materialMinDiameter &&
                c["materialMaxDiameter"] === materialMaxDiameter
              : c["materialDiameter"] === materialDiameter)
          ) {
          } else {
            filteredData.push(c);
          }
        });
        if (
          filteredData.filter(
            (e) =>
              e.vendorId === vendorDetails?.id &&
              e.id === materialSelectedData["id"] &&
              (e.diameter
                ? e["materialMinDiameter"] === materialMinDiameter &&
                  e["materialMaxDiameter"] === materialMaxDiameter
                : e["materialDiameter"] === materialDiameter)
          )?.length > 0
        ) {
          message.error("You can't select same material with same vendor!");
          return true;
        }
        let materialData = {
          entityData: {
            id: materialSelectedData["id"],
            label: materialSelectedData["label"],
            value:
              vendorDetails?.material && vendorDetails?.material[0]["value"],
            category: materialSelectedData["category"],
            categoryKey: materialSelectedData["categoryKey"],
            subShortName: materialSelectedData["subShortName"],
            materialShortValue:
              materialSelectedData["categoryKey"] +
              " - " +
              materialSelectedData["subShortName"] +
              " - " +
              materialSelectedData["label"].split(" - ")[1],
            truckingPrice: materialTruckPrice,
            truckingPriceType: truckingPriceType,
            truckingCost: truckingCost,
            truckingCostType: truckingCostType,
            quantity: quantity,
            quantityType: quantityType,
            vendorId: vendorDetails?.id,
            vendor: vendorDetails?.quarryName,
            vendorTerms:
              vendorDetails?.accountTerms || materialSelectedData?.vendorTerms,
            vendorDiscountTerms:
              vendorDetails?.payDiscountTerms ||
              materialSelectedData?.vendorDiscountTerms,
            vendorDiscount:
              vendorDetails?.payDiscount ||
              materialSelectedData?.vendorDiscount,
            pickUpAddress: vendorDetails?.address,
            pickUpLatLng: {
              lat: vendorDetails?.lat,
              lng: vendorDetails?.lng,
            },
            materialCost: parseFloat(initialMaterialCost),
            materialCostType: materialCostType,
            totalPrice: materialPriceState,
            totalPriceType: totalPriceType,
            duration: vendorDetails?.duration || materialSelectedData?.duration,
            milesData:
              vendorDetails?.distance || materialSelectedData?.milesData,
            materialCostAtQuarryPerTon: parseFloat(initialMaterialCost),
            landedPrice:
              totalPriceType === truckingPriceType
                ? parseFloat(materialPriceState) + materialTruckPrice
                : "N/A",
            landedCost:
              materialCostType === truckingCostType &&
              initialMaterialCost &&
              truckingCost
                ? parseFloat(initialMaterialCost + truckingCost)?.toFixed(2)
                : "N/A",
            totalSale:
              materialPriceState && quantity && materialTruckPrice
                ? parseFloat(
                    (materialPriceState + materialTruckPrice) * quantity
                  )?.toFixed(2)
                : "N/A",
            totalExepense:
              initialMaterialCost && quantity && truckingCost
                ? parseFloat(
                    (initialMaterialCost + truckingCost) * quantity
                  )?.toFixed(2)
                : "",
            totalProfit:
              materialPriceState !== undefined &&
              materialPriceState !== "" &&
              materialTruckPrice !== undefined &&
              materialTruckPrice !== "" &&
              quantity !== undefined &&
              quantity !== "" &&
              initialMaterialCost !== undefined &&
              initialMaterialCost !== "" &&
              materialTruckPrice !== undefined &&
              materialTruckPrice !== "" &&
              truckingCost !== undefined &&
              truckingCost !== "" &&
              parseFloat(
                (materialPriceState + materialTruckPrice) * quantity -
                  (initialMaterialCost + truckingCost) * quantity
              )?.toFixed(2),
            totalProfitPercentage:
              materialPriceState !== undefined &&
              materialPriceState !== "" &&
              materialTruckPrice !== undefined &&
              materialTruckPrice !== "" &&
              quantity !== undefined &&
              quantity !== "" &&
              initialMaterialCost !== undefined &&
              initialMaterialCost !== "" &&
              materialTruckPrice !== undefined &&
              materialTruckPrice !== "" &&
              truckingCost !== undefined &&
              truckingCost !== "" &&
              (
                (((materialPriceState + materialTruckPrice) * quantity -
                  (initialMaterialCost + truckingCost) * quantity) /
                  ((materialPriceState + materialTruckPrice) * quantity)) *
                100
              )?.toFixed(2),
            materialDiameter: materialDiameter,
            materialStatus: values["materialStatus"],
            materialSupply: values["materialSupply"],
            materialTest: values["materialTest"],
            materialMaxDiameter: materialMaxDiameter,
            materialMinDiameter: materialMinDiameter,
            diameter,
          },
        };
        // console.log(materialData);

        this.addMaterialOrTruckInfo(materialData);
        this.addVendorContact(vendorDetails);
        // }
      } else {
        message.error(
          "Please Select Same Units For Material Price & Trucking Price"
        );
      }
    }
  };

  addVendorContact = (vendorDetails) => {
    const { leadDetails, quarryContacts } = this.state;
    let contactsData = quarryContacts?.length > 0 ? quarryContacts : [];
    vendorDetails?.contactDetails
      ?.filter((q) => q?.isPrimaryContact)
      ?.map((c) => {
        if (contactsData?.filter((t) => t.id === c.id)?.length > 0) {
          // console.log(contactsData, "contactsData");
        } else {
          contactsData.push({
            id: c["id"],
            materialId: c?.materialId,
            // companyId: leadDetails?.companyId,
            quarryId: vendorDetails?.id,
            jobId: [leadDetails?.id],
            firstName: c["firstName"],
            lastName: c["lastName"],
            phone: c["phone"],
            email: c["email"],
            notes: c["notes"],
            contactRole: c["contactRole"],
          });
        }
        return true;
      });
    this.setState({
      quarryContacts: contactsData,
    });
  };

  addMaterialOrTruckInfo = (data) => {
    this.setState({
      loading: false,
    });
    let materialInfo =
      this.state.selectedMaterial?.length > 0
        ? this.state?.selectedMaterial
        : [];
    materialInfo.push(data["entityData"]);
    this.setState({
      pickupDetails: [],
      selectedMaterial: materialInfo,
      loading: false,
      quantityType: "Ton",
      selectedMaterialType: "",
      materialSelectedData: "",
      quantity: "",
      recordMaterialData: "",
      materialPopup: false,
      truckingCostType: "Ton",
      totalPriceType: "Ton",
      materialCostType: "Ton",
      truckingPriceType: "Ton",
      selectedPickupAddress: "",
      pickUpLatitude: "",
      pickupLongitude: "",
      finalMaterialData: [],
      filterResultData: [],
      duration: "",
      milesData: "",
      selectVendor: "",
      initialMaterialCost: "",
      materialPriceState: "",
      materialTruckPrice: "",
      materialCostPrice: "",
    });
    if (this.state.recordUpdate === true) {
      let leadData = {
        entityData: {
          material: materialInfo,
        },
      };
      this.updateLead(this.props.match.params.id, leadData);
    }
  };

  handleCheckStatus = (e) => {
    this.setState({ diameter: e });
    this.formRef.current.setFieldsValue({
      materialDiameter: "",
      materialMinDiameter: "",
      materialMaxDiameter: "",
    });
  };

  editMaterial = (data, type) => {
    // const { contactDetails } = this.state;
    if (this.state.leadDetails?.stageId !== "Dispatch Approved") {
      if (type === "recordUpdate") {
        this.setState({ recordUpdate: true });
      }
      const { jobInfo } = this.state;
      if (data !== "") {
        let filteredData = [];
        this.state?.selectedMaterial?.forEach((c) => {
          if (c.vendorId === data?.vendorId && c.id === data?.id) {
          } else {
            filteredData.push(c);
          }
        });
        this.setState({ selectedMaterial: filteredData });
      }
      if (jobInfo === "Trucking Job") {
        this.setState({
          materialPopup: true,
          recordMaterialData: data,
          selectedMaterialType: data?.category,
          materialSelectedData: data,
          truckingCostType: data?.truckingCostType
            ? data?.truckingCostType
            : "Ton",
          truckingPriceType: data?.truckingPriceType
            ? data?.truckingPriceType
            : "Ton",
          duration: data?.duration,
          milesData: data?.milesData,
          selectedPickupAddress: data?.pickUpAddress,
          pickUpLatitude: data?.pickUpLatLng?.lat,
          pickupLongitude: data?.pickUpLatLng?.lng,
          customRate: false,
          materialCustomRate: false,
        });
      } else {
        this.getVendorDetails(data);
      }
    }
  };

  getVendorDetails = (data) => {
    console.log(data);
    const { vendorData } = this.props;
    console.log(vendorData, "vendorDAta");
    let vendor = vendorData?.filter((v) => v?.id === data?.vendorId);
    // console.log(vendor);
    let categoriesList = vendor[0]?.material?.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.category === value.category)
    );
    // console.log(categoriesList);

    this.setState(
      {
        diameter: data?.diameter,
        customRate: false,
        materialCustomRate: false,
        pickupDetails: vendor,
        vendorDetails: vendor[0],
        vendorName: vendor[0]?.quarryName,
        selectedRowKeys: [vendor[0]?.id],
        selectVendor: vendor,
        categoriesList,
        milesData: data?.milesData,
        duration: data?.duration,
        materialValue: data?.category,
        materialPopup: true,
        recordMaterialData: data,
        useMarkUp: data.quantity,
        selectedMaterialType: data?.category,
        materialSelectedData: data,
        quantityType: data?.quantityType ? data?.quantityType : "Ton",
        quantity: data?.quantity,
        truckingCost: data?.truckingCost,
        truckingCostType: data?.truckingCostType
          ? data?.truckingCostType
          : "Ton",
        truckingPriceType: data?.truckingPriceType
          ? data?.truckingPriceType
          : "Ton",
        materialCostType: data?.materialCostType
          ? data?.materialCostType
          : "Ton",
        totalPriceType: data?.totalPriceType ? data?.totalPriceType : "Ton",
        initialMaterialCost: data?.materialCost,
        materialPriceState: data?.totalPrice,
        materialTruckPrice: data?.truckingPrice,
        materialCostPrice: data?.materialCostAtQuarryPerTon,
      },
      () => {
        this.getTopVendors(data);
        this.onSelectMaterialCat(data?.category, "edit");
      }
    );
  };

  onCancelMaterial = () => {
    const { recordMaterialData } = this.state;
    // console.log(recordMaterialData);
    if (
      recordMaterialData !== "" &&
      recordMaterialData !== undefined &&
      recordMaterialData?.length !== 0
    ) {
      let materialInfo =
        this.state.selectedMaterial?.length > 0
          ? this.state?.selectedMaterial
          : [];
      materialInfo.push(recordMaterialData);
      this.setState({
        selectedMaterial: materialInfo,
        recordMaterialData: "",
        selectedMaterialType: "",
        materialPopup: false,
        filterResultData: [],
        finalMaterialData: [],
        initialMaterialCost: "",
        pickupDetails: [],
        materialPriceState: "",
        duration: "",
        milesData: "",
        selectVendor: "",
        materialCostPrice: "",
        materialSelectedData: "",
        vendorDetails: "",
        quantity: "",
        materialTruckPrice: "",
        truckingCost: "",
        customRate: false,
        materialCustomRate: false,
      });
      // this.formRef.current.setFieldsValue({ material: "", quantity: "" });
    } else {
      this.setState({ selectedMaterialType: "", materialPopup: false });
    }
  };

  deleteMaterial = (record) => {
    if (this.state.jobInfo === "Trucking Job") {
      let materialInfo = this.state.selectedMaterial.filter(
        (c) => c.id !== record.id
      );
      this.setState({
        selectedMaterial: materialInfo,
      });
      message.info("Trucking Details Deleted Successfully");
    } else {
      let filteredData = [];
      this.state?.selectedMaterial?.forEach((c) => {
        if (c.vendorId === record?.vendorId && c.id === record?.id) {
        } else {
          filteredData.push(c);
        }
      });
      this.setState({
        selectedMaterial: filteredData,
      });
      message.info("Material Deleted Successfully!");
    }
  };

  onSelectMaterialCat = (e, type) => {
    const { materialList } = this.state;
    if (type === "edit") {
      let materialsData = [];
      materialList?.map((newMat) => {
        if (e === newMat?.category) {
          materialsData.push(newMat);
        }
        return true;
      });
      this.setState({
        selectedMaterialType: e,
        finalMaterialData: materialsData,
      });
    } else {
      let materialsData = [];
      materialList?.map((newMat) => {
        if (e === newMat?.category) {
          materialsData.push(newMat);
        }
        return true;
      });
      if (e) {
        this.formRef.current.setFieldsValue({ material: undefined, price: "" });
        this.setState({
          materialSelectedData: "",
          topVendorsData: [],
          vendorDetails: "",
          selectedRowKeys: [],
        });
      }
      this.setState({
        selectedMaterialType: e,
        finalMaterialData: materialsData,
      });
    }
  };

  onSelectMaterial = (e) => {
    const { finalMaterialData } = this.state;
    finalMaterialData?.filter(
      (f) => f.id === e && this.setState({ materialSelectedData: f })
    );
    this.setState({
      topVendorsData: [],
      vendorDetails: "",
      selectedRowKeys: [],
    });
  };

  onFinishContact = (values) => {
    const { leadDetails, jobContactData } = this.state;
    this.setState({ loading: true, isContactVisible: false });
    let contactsData = {
      entityData: {
        companyId: leadDetails?.companyId,
        firstName: values["fName"],
        lastName: values["lName"],
        phone: values["phone"],
        email: values["email"],
        notes: values["notes"],
        contactRole:
          values["role"] === "Add New Role"
            ? values["roleOther"]
            : values["role"],
      },
    };
    if (jobContactData !== "") {
      this.updateContact(contactsData);
    } else {
      this.addContact(contactsData);
    }
    // console.log(contactsData, "contactsData");
    if (this.state.roleCheckBox) {
      this.props.context.updateRolesData(values["roleOther"]);
    }
  };

  addContact = (data) => {
    add("contacts", data)
      .then((result) => {
        if (result.success) {
          this.addContactToJob(result?.data?.data);
          this.getCompanyContacts(this.state.companyId);
          this.setState({
            loading: false,
            newRole: "",
            roleOther: "",
            roleCheckBox: false,
          });
        } else {
          this.setState({
            loading: false,
            roleOther: "",
            newRole: "",
            roleCheckBox: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
          newRole: "",
          roleOther: "",
        });
        console.log(err);
      });
  };

  updateContact = (data) => {
    // console.log(data);
    update(
      appConfig["entityNames"]["updateContact"],
      this.state?.jobContactData?.id,
      data
    )
      .then((res) => {
        if (res.success) {
          // console.log(res);
          this.setState({
            loading: false,
            isCheckBox: false,
            newRole: "",
            roleOther: "",
            roleCheckBox: false,
            jobContactData: "",
            isContactVisible: false,
          });
          this.getLeadDetails(this.props.match.params.id);
        } else {
          this.setState({
            loading: false,
            isCheckBox: false,
            newRole: "",
            roleOther: "",
            roleCheckBox: false,
            jobContactData: "",
            isContactVisible: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
          isCheckBox: false,
          newRole: "",
          roleCheckBox: false,
          roleOther: "",
          jobContactData: "",
          isContactVisible: false,
        });
      });
  };

  addContactToJob = (newContact) => {
    this.setState({ selectedContacts: [] });
    const { leadDetails } = this.state;
    let existContactData = leadDetails?.multipleContacts;
    existContactData.push(newContact);
    let data = {
      entityData: {
        multipleContacts: existContactData,
      },
    };
    // console.log(data, "data");
    update(appConfig["entityNames"]["updateLead"], leadDetails?.id, data)
      .then((result) => {
        // console.log(result);
        if (result.success) {
          message.info("Contact Added Successfully");
          this.getLeadDetails(this.props?.match?.params?.id);
          this.setState({
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  };

  onSelectVendor = (value, option) => {
    this.formRef.current.setFieldsValue({
      material: "",
      quantity: "",
      materialCategory: "",
      materialPriceState: "",
      totalPrice: "",
    });

    this.setState({
      loading: true,
    });
    const { dropoffLatitude, dropoffLongitude } = this.state;
    let data = this.props.vendorData?.filter((e) => e?.id === option?.key);
    let url =
      "https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&destinations=" +
      [dropoffLatitude, dropoffLongitude] +
      "&origins=" +
      [data[0]?.lat, data[0]?.lng] +
      "&key=AIzaSyB781k9fNf61hnxE3A6cvcascwhAhIVIK4";

    let milesData = {
      entityData: {
        url,
      },
    };

    let categoriesList = data[0]?.material?.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.category === value.category)
    );

    add("getMiles", milesData)
      .then((res) =>
        this.setState({
          newVendor: value,
          selectVendor: data,
          categoriesList,
          selectedMaterialType: "",
          finalMaterialData: [],
          pickupDetails: data,
          milesData: res.data.distance,
          duration: res.data.duration,
          loading: false,
          materialPriceState: "",
        })
      )
      .catch((err) => {
        this.setState({
          loading: false,
        });
        console.log(err, "err");
      });
  };

  onChangeVendor = (e) => {
    // console.log(e);
    if (e !== undefined) {
      this.setState({
        vendorName: e,
        materialTruckPrice: "",
      });
    } else {
      this.setState({
        initialMaterialCost: "",
        materialPriceState: "",
        materialCostState: "",
        materialTruckPrice: "",
        selectVendor: [],
        milesData: "",
        duration: "",
        vendorName: "",
        categoriesList: [],
      });
      this.formRef.current.setFieldsValue({
        material: "",
        quantity: "",
        materialCategory: "",
        totalPrice: "",
        truckingPrice: "",
      });
    }
  };

  deleteContact = (data) => {
    this.setState({ loading: true });
    deleteRecord(appConfig["entityNames"]["deleteContactandJob"], data.id)
      .then((res) => {
        if (res.success) {
          this.setState({
            loading: false,
          });
          this.getLeadDetails(this.props?.match?.params?.id);
        } else {
          this.setState({
            loading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  };

  onSelectChange = (data, value) => {
    this.setState({
      selectedRowKeys: data,
      vendorDetails: value[0],
      initialMaterialCost: value[0]?.material[0]?.price,
      materialCostPrice: value[0]?.material[0]?.price,
      materialPriceState: "",
      truckingCost: "",
    });
    this.formRef.current.setFieldsValue({
      quantity: "",
      totalPrice: "",
      markUp: "",
      truckingCost: "",
      truckingPrice: "",
      materialCost: value[0]?.material[0]?.price,
    });
  };

  DecimalToMixedFraction = (val) => {
    // eslint-disable-next-line no-eval
    const decimal = eval(val);
    // Split the decimal into whole and fractional parts
    const wholeNumber = Math.floor(decimal);
    const fractionalPart = decimal - wholeNumber;
    // Convert the fractional part to a fraction
    const fraction = this.decimalToFraction(fractionalPart);

    // Combine the whole number and fraction
    const mixedFraction =
      wholeNumber !== 0 ? `${wholeNumber} ${fraction}` : `${fraction}`;

    return mixedFraction;
  };

  decimalToFraction = (decimal) => {
    const EPSILON = 1.0e-9;
    let numerator = decimal;
    let denominator = 1;

    while (Math.abs(Math.round(numerator) - numerator) > EPSILON) {
      numerator *= 10;
      denominator *= 10;
    }

    const gcd = this.getGreatestCommonDivisor(numerator, denominator);
    numerator /= gcd;
    denominator /= gcd;

    return `${Math.floor(numerator)}/${Math.floor(denominator)}`;
  };

  getGreatestCommonDivisor = (a, b) => {
    if (b === 0) {
      return a;
    }
    return this.getGreatestCommonDivisor(b, a % b);
  };

  getTopVendors = (values) => {
    const {
      materialSelectedData,
      dropoffLatitude,
      dropoffLongitude,
      diameter,
    } = this.state;

    let materialMinDiameter = 0,
      materialMaxDiameter = 0,
      materialDiameter = 0;

    if (diameter) {
      materialMinDiameter =
        values["materialMinDiameter"].split(" ")?.length !== 2 &&
        String(parseInt(values["materialMinDiameter"])) !==
          values["materialMinDiameter"]
          ? this.DecimalToMixedFraction(values["materialMinDiameter"])
          : values["materialMinDiameter"];

      materialMaxDiameter =
        values["materialMaxDiameter"].split(" ")?.length !== 2 &&
        String(parseInt(values["materialMaxDiameter"])) !==
          values["materialMaxDiameter"]
          ? this.DecimalToMixedFraction(values["materialMaxDiameter"])
          : values["materialMaxDiameter"];
    } else {
      materialDiameter =
        values["materialDiameter"].split(" ")?.length !== 2 &&
        String(parseInt(values["materialDiameter"])) !==
          values["materialDiameter"]
          ? this.DecimalToMixedFraction(values["materialDiameter"])
          : values["materialDiameter"];
    }

    if (materialSelectedData !== "") {
      this.setState({
        loading: true,
        initialMaterialCost: materialSelectedData?.materialCost
          ? materialSelectedData?.materialCost
          : "",
      });

      let data = {
        entityData: {
          material: materialSelectedData,
          dropOffLatLng: { lat: dropoffLatitude, lng: dropoffLongitude },
          materialDiameter: materialDiameter,
          materialStatus: values["materialStatus"],
          materialSupply: values["materialSupply"],
          materialTest: values["materialTest"],
          materialMaxDiameter: materialMaxDiameter,
          materialMinDiameter: materialMinDiameter,
          diameter,
        },
      };
      console.log(data, "data");
      this.FindTopVendors(data);
    } else {
      message.error("Please Select Material!");
    }
  };

  FindTopVendors = (data) => {
    add("getTopQuarries", data).then((res) => {
      console.log(res, "res");
      this.setState({
        topVendorsData: res.data,
        loading: false,
      });
    });
  };

  resetvendorValues = () => {
    this.setState({
      topVendorsData: [],
      selectedRowKeys: [],
    });
  };

  onChangeOverWeight = (e) => {
    this.setState({
      overWeightPermitState: e?.target?.value,
    });
    if (e?.target?.value === "No") {
      this.formRef.current.setFieldsValue({
        overWeightExpiryDate: "",
      });
      this.setState({
        quoteAttachment: "",
        quoteAttachmentId: "",
      });
    }
  };

  onFinishTruckDetails = (values) => {
    const {
      selectedAddress,
      latitude,
      longitude,
      selectedTruckCode,
      companyData,
      citySearch,
      quoteAttachmentId,
      equipmentAttachment,
      fileType,
    } = this.state;
    // console.log(citySearch);

    if (values["overWeightPermit"] === "Yes" && quoteAttachmentId === "") {
      message.info("Please Attach Your Document!");
      return;
    }
    if (
      (citySearch !== "" && citySearch !== undefined) ||
      (selectedAddress !== "" && selectedAddress !== undefined)
    ) {
      let equipmentTypeDetails = {
        carrier_name: companyData?.carrier_name,
        id: uuidv4(),
        parking_location: selectedAddress,
        lat: latitude,
        lng: longitude,
        truck_type: values["truck_type"],
        overWeightPermit: values["overWeightPermit"],
        overWeightExpiryDate:
          values["overWeightPermit"] === "Yes"
            ? moment(values["overWeightExpiryDate"]).format("L")
            : "",
        empty_weight: values["empty_weight"],
        truck_code: selectedTruckCode,
        carrierId: companyData?.id,
        equipmentAttachment:
          values["overWeightPermit"] === "Yes" ? equipmentAttachment : "",
        docAttachmentId:
          values["overWeightPermit"] === "Yes" ? quoteAttachmentId : "",
        fileType: fileType,
      };

      let finalData = {
        entityData: {
          carrierDetails: [equipmentTypeDetails],
        },
      };
      this.setState({
        addTruck: false,
      });
      this.updateCompanyDetails(companyData.id, finalData);
      message.success("Truck Details Added Successfully!");
    } else {
      message.info("Please Enter Parking Location !");
    }
  };

  handleUploadPic = async (info) => {
    this.setState({
      imageLoading: true,
      loading: true,
    });
    switch (info.file.status) {
      case "done":
        if (info.fileList.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              Storage.put(info.file.uid, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                Storage.get(result.key).then(() => {
                  this.setState({
                    quoteAttachmentId: result.key,
                    equipmentAttachment: result,
                    fileType: info.file.type,
                    imageLoading: false,
                    loading: false,
                  });
                });
              });
            } else {
              this.setState({
                imageLoading: false,
                loading: false,
              });
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;
      case "removed":
        this.setState({
          equipmentAttachment: "",
          quoteAttachmentId: "",
          imageLoading: false,
          loading: false,
          percentage: 0,
        });
        break;
      default:
    }
  };

  welcomeCallCondition = (id, status, companyData) => {
    if (companyData?.carrierDetails?.length > 0) {
      if (companyData?.payment_terms && companyData?.payment_terms !== "") {
        if (
          companyData?.insurance_expiry &&
          companyData?.insurance_expiry !== ""
        ) {
          if (
            companyData?.direct_deposit &&
            companyData?.direct_deposit !== ""
          ) {
            if (companyData?.usdot && companyData?.usdot !== "") {
              this.getCompanies(id, status, companyData);
            } else {
              message.error("Please Enter USDOT To Proceed");
            }
          } else {
            message.error("Please Enter Deposit Method To Proceed");
          }
        } else {
          message.error("Please Enter Insurance Expiration Date To Proceed");
        }
      } else {
        message.error("Please Enter Payment Terms To Proceed");
      }
    } else {
      message.error("Please Enter Equipment Details To Proceed");
      this.setState({ addTruck: true });
    }
  };

  render() {
    const {
      companyData,
      overWeightPermitState,
      imageLoading,
      addTruck,
      customRate,
      selectedRowKeys,
      diameter,
      materialCategory,
      finalMaterialData,
      quantity,
      truckingCostType,
      useMarkUp,
      truckingCost,
      topVendorsData,
      materialCostType,
      materialCustomRate,
      citySearch,
      isModalVisible,
      selectedStatus,
      jobInfo,
      leadDetails,
      dropAddress,
      // seperate,
      isContactVisible,
      contactsData,
      selectedMaterial,
      truckDropInfo,
      jobContactData,
      newRole,
      materialPopup,
      recordMaterialData,
      loading,
      milesData,
      initialMaterialCost,
      materialPriceState,
      materialTruckPrice,
      duration,
      truckingPriceType,
      totalPriceType,
      openDropdown,
      funnelData,
      leadsData,
      isHovering,
    } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const topVendorsColumns = [
      {
        title: "VENDOR NAME",
        dataIndex: "quarryName",
        key: "quarryName",
        sorter: false,
        width: "40%",
        className: "col_style_quarry",
        render: (quarryName, record) => {
          return (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "fit-content",
              }}
              onClick={() => this.onSelectChange([record.id], [record])}
            >
              {quarryName}
            </Row>
          );
        },
      },
      {
        title: "RUN & RATE INFORMATION",
        dataIndex: "distance",
        key: "distance",
        sorter: false,
        width: "40%",
        className: "col_style_quarry",
        render: (distance, record) => {
          return (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "fit-content",
              }}
              onClick={() => this.onSelectChange([record.id], [record])}
            >
              {distance + "les, " + record?.duration}
            </Row>
          );
        },
      },
      {
        title: "COST / Ton",
        dataIndex: "material",
        key: "material",
        sorter: false,
        width: "20%",
        className: "col_style_quarry",
        render: (material, record) => {
          return (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "fit-content",
              }}
              onClick={() => this.onSelectChange([record.id], [record])}
            >
              {"$" + material[0]?.price?.toFixed(2)}
            </Row>
          );
        },
      },
    ];

    const selectAfter = (
      <Select
        defaultValue={this.state?.quantityType}
        className="select-after"
        onChange={(e) => {
          this.setState({ quantityType: e });
        }}
      >
        <Option value="Ton">Ton</Option>
        <Option value="Load">Load</Option>
        <Option value="Yard">Yard</Option>
      </Select>
    );

    const selectAfterMaterial = (
      <Select
        defaultValue={this.state?.materialCostType}
        className="select-after"
        onChange={(e) => {
          this.setState({ materialCostType: e });
        }}
      >
        <Option value="Ton">Ton</Option>
        <Option value="Load">Load</Option>
        <Option value="Yard">Yard</Option>
      </Select>
    );

    const selectAfterTruck = (
      <Select
        defaultValue={this.state?.truckingCostType}
        className="select-after"
        onChange={(e) => {
          this.setState({ truckingCostType: e });
        }}
      >
        <Option value="Ton">Ton</Option>
        <Option value="Load">Load</Option>
        <Option value="Hour">Hour</Option>
      </Select>
    );

    const selectAfterTruckPrice = (
      <Select
        defaultValue={this.state?.truckingPriceType}
        className="select-after"
        onChange={(e) => {
          this.setState({ truckingPriceType: e });
        }}
      >
        <Option value="Ton">Ton</Option>
        <Option value="Load">Load</Option>
        <Option value="Hour">Hour</Option>
      </Select>
    );

    const selectAfterCustomer = (
      <Select
        defaultValue={this.state?.totalPriceType}
        className="select-after"
        onChange={(e) => {
          this.setState({ totalPriceType: e });
        }}
      >
        <Option value="Ton">Ton</Option>
        <Option value="Load">Load</Option>
      </Select>
    );

    const classes = {
      board: {
        display: "flex",
        margin: "0 auto",
        height: window.width <= 377 && window.width <= 799 ? "75vh" : "60vh",
        // width: "90vw",
        // fontFamily: 'Arial, "Helvetica Neue", sans-serif',
      },
      column: {
        // minWidth:"100px",
        width:
          window.location.pathname === "/app/trucking-material"
            ? "240px"
            : "191px",
        height: "100%",
        margin: "0 auto",
        // backgroundColor: "#FCC8B2",
      },
      columnHead: {
        textAlign: "center",
        padding: 10,
        borderLeft: "none",
        height: "40px",
        // backgroundColor: "#C6D8AF",
      },
      columnHeads: {
        textAlign: "center",
        padding: 8,
        borderLeft: "none",
        height: "60px",
        // backgroundColor: "#C6D8AF",
      },
      item: {
        padding: 10,
        margin: 5,
        fontSize: "0.8em",
        cursor: "pointer",
        backgroundColor: "white",
        borderRadius: "5px",
      },
    };

    const channels = funnelData;

    return (
      <>
        <Loading enableLoading={this.state.loading} />

        <div data-aos="flip-left">
          <section style={classes.board}>
            {channels.map(
              (channel) =>
                channel !== "Job Lead" && (
                  <KanbanColumn status={channel}>
                    <div style={classes.column}>
                      <div
                        style={
                          leadsData[0]?.isOwnerOperator === true
                            ? classes.columnHeads
                            : classes.columnHead
                        }
                        className="customer_leadHeading customer_Lead"
                      >
                        {`${channel} (${
                          leadsData?.filter((l) => l?.stageId === channel)
                            ?.length
                        })`}
                      </div>
                      <div
                        className="kanbanscroll"
                        style={{ height: "92%", overflowY: "auto" }}
                      >
                        {leadsData
                          ?.filter((item) => item.stageId === channel)
                          .map((item) =>
                            item?.leadType &&
                            item?.leadType[0]?.value === "Material Sales" ? (
                              <KanbanItem id={item.id} onDrop={this.update}>
                                <div
                                  onDoubleClick={() => {
                                    this.onDoubleClick(item.id);
                                    this.props.context.updateSideronCollapse(
                                      false
                                    );
                                  }}
                                  style={{
                                    padding: 10,
                                    margin: 5,
                                    fontSize: "0.8em",
                                    cursor: "pointer",
                                    backgroundColor: "white",
                                    borderRadius: "5px",
                                    boxShadow:
                                      isHovering === item.id
                                        ? "0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%)"
                                        : "none",
                                  }}
                                  onMouseEnter={() =>
                                    this.handleMouseEnter(item.id)
                                  }
                                  onMouseLeave={() =>
                                    this.handleMouseLeave(item.id)
                                  }
                                >
                                  <Row>
                                    <Col span={24}>
                                      <div className="grid_Name">
                                        <b>
                                          {item?.jobNumber === undefined
                                            ? ""
                                            : "Job #" + item?.jobNumber}
                                          , {item?.company}
                                        </b>
                                      </div>
                                    </Col>

                                    <Col
                                      span={24}
                                      className="grid_Company companynamed"
                                    >
                                      {item?.jobType}
                                    </Col>

                                    <Col
                                      span={24}
                                      className="grid_Name namegridss"
                                    >
                                      {item?.firstName ? (
                                        <>
                                          {item?.firstName
                                            ? item?.firstName +
                                              " " +
                                              item?.lastName
                                            : ""}
                                        </>
                                      ) : (
                                        <>
                                          {item?.contactperson ===
                                          "undefined undefined"
                                            ? ""
                                            : item?.contactperson
                                            ? item?.contactperson
                                            : item?.multipleContacts
                                            ? item?.multipleContacts?.map(
                                                (e) => (
                                                  <div>
                                                    {e?.firstName +
                                                      " " +
                                                      e?.lastName}
                                                  </div>
                                                )
                                              )
                                            : ""}
                                        </>
                                      )}
                                    </Col>
                                    {/* <Col span={24} className="grid_Status">
                                    <span
                                      style={{
                                        padding: "3px",
                                        display: "flex",
                                        flexWrap: "wrap",
                                        marginLeft: "auto",
                                        maxWidth: "max-content",
                                        alignContent: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <BsFillCircleFill
                                        style={{
                                          color:
                                            appConfig["STAGE_COLORS"][
                                              item.stageId
                                            ],
                                          fontSize: "8px",
                                          marginRight: "5px",
                                          height: "auto",
                                        }}
                                      />
                                      {item.stageId}
                                    </span>
                                  </Col> */}
                                  </Row>
                                </div>
                              </KanbanItem>
                            ) : (
                              <KanbanItem
                                id={item.id}
                                onDrop={this.updateCompany}
                              >
                                <div
                                  style={classes.item}
                                  onDoubleClick={() => {
                                    window.location = `/app/owner-operator-profile/${item.id}`;
                                    this.props.context.updateSideronCollapse(
                                      false
                                    );
                                  }}
                                >
                                  <Row>
                                    <Col span={24}>
                                      <div className="grid_Name">
                                        <b>{item?.company}</b>
                                      </div>
                                    </Col>
                                    <Row>
                                      {item?.contactDetails
                                        ?.filter(
                                          (e) => e?.isPrimaryContact === true
                                        )
                                        .map((f) => (
                                          <>
                                            <Col
                                              span={24}
                                              className="grid_Name"
                                            >
                                              {f?.firstName !== undefined
                                                ? f?.firstName
                                                : " " + f?.lastName !==
                                                  undefined
                                                ? f?.lastName
                                                : ""}
                                            </Col>
                                            <Col
                                              span={24}
                                              className="grid_Company"
                                            >
                                              {f?.phone}
                                            </Col>
                                            <Col
                                              span={24}
                                              className="grid_Company companynamed"
                                              // style={{ fontSize: "14px" }}
                                            >
                                              {f?.email}
                                            </Col>
                                          </>
                                        ))}
                                    </Row>
                                    <Col
                                      span={24}
                                      className="grid_Name"
                                      // style={{ fontSize: "14px" }}
                                    >
                                      Truck Count:{" "}
                                      {`${item?.carrierDetails?.length}`}
                                    </Col>
                                    {/* <Col
                                      span={24}
                                      style={{
                                        float: "right",
                                        fontSize: "12px",
                                      }}
                                      className="grid_Status"
                                    >
                                      <span
                                        style={{
                                          padding: "3px",
                                          // display: "flex",
                                          // flexWrap: "wrap",
                                          // // marginLeft: "auto",
                                          // maxWidth: "100%",
                                          // alignContent: "center",
                                          // justifyContent: "center",
                                        }}
                                      >
                                        <BsFillCircleFill
                                          style={{
                                            color:
                                              appConfig["STAGE_COLORS"][
                                                item.stageId
                                              ],
                                            fontSize: "8px",
                                            marginRight: "5px",
                                            height: "auto",
                                          }}
                                        />
                                        <span
                                          style={{
                                            color: "#848484",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {`${item.stageId}
                                           (${Math.ceil(
                                            (new moment(moment().format("l")) -
                                              new moment(
                                                moment(
                                                  item?.stageIdUpdatedDate
                                                ).format("l")
                                              )) /
                                              (1000 * 60 * 60 * 24)
                                          )})`} 
                                        </span>
                                      </span>
                                    </Col> */}
                                  </Row>
                                </div>
                              </KanbanItem>
                            )
                          )}
                      </div>
                    </div>
                  </KanbanColumn>
                )
            )}
          </section>
        </div>

        {isModalVisible && (
          <BaseModal
            title={
              selectedStatus === "Build Quote"
                ? `Build Quote Form #${leadDetails?.jobNumber}`
                : `Update Job Profile #${leadDetails?.jobNumber}`
            }
            onCancel={() =>
              this.setState({
                isModalVisible: false,
                selectedStatus: "",
                quoteAttachment: "",
                isCheckBox: false,
                isCheckBoxMaterial: false,
                isCheckBoxTruckType: false,
                jobInfo: "",
                truckDropInfo: false,
                selectedMaterial: [],
                showSpecificDate: false,
                iscalenderVisible: false,
                openDropdown: "",
                salesCommission: "",
              })
            }
            formId="LeadForm"
            loading={this.state.loading}
            className="antmodal_grid headermodal"
            width={"80%"}
          >
            <Form onFinish={this.onFinish} id="LeadForm">
              <Row gutter={[24, 0]}>
                <Col span={6}>
                  <span className="title_changes">Job Name</span>
                  <Form.Item
                    name={"jobName"}
                    initialValue={
                      leadDetails?.jobName
                        ? leadDetails?.jobName
                        : leadDetails?.jobNumber
                    }
                  >
                    <Input
                      placeholder="Please Enter Job Name"
                      className="form-input"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <span className="title_changes">Contact Name</span>
                  <div className="contactname-multiple">
                    <Form.Item
                      name="contactperson"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Your Contact Name!",
                        },
                      ]}
                      initialValue={
                        leadDetails?.multipleContacts
                          ? leadDetails?.multipleContacts.map((c) => c?.id)
                          : leadDetails?.contactperson
                      }
                    >
                      <Select
                        size="large"
                        allowClear
                        mode="multiple"
                        showSearch
                        className="select_multiplequarytol"
                        style={{
                          height: "auto !important",
                          width: "100% !important",
                        }}
                        placeholder="Please Select A Contact Name"
                        // style={{ height: "40px" }}
                        onChange={(e) => this.onSelectLead(e)}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {contactsData?.map((eachItem) => (
                          <Option
                            key={eachItem.id}
                            value={eachItem.id}
                            className="option_style"
                          >
                            {eachItem.firstName + " " + eachItem.lastName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {!isContactVisible && (
                      <Tooltip
                        title="Add Contact"
                        overlayStyle={{
                          backGroundColor: "#000",
                          color: "#000",
                        }}
                      >
                        <div
                          style={{
                            // width: "100%",
                            fontWeight: "500",
                            // height: "25px",
                            fontSize: "20px",
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "auto",
                            padding: "8px",
                            position: "absolute",
                            top: "0px",
                            right: "0px",
                            height: "100%",
                            borderLeft: "1px solid #ddd",
                            zIndex: "+9",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            this.setState({
                              isContactVisible: true,
                              jobContactData: "",
                            })
                          }
                        >
                          <UsergroupAddOutlined />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </Col>
                <Col span={6}>
                  <span className="title_changes">Job Type</span>
                  <Form.Item
                    name="jobType"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Job Type!",
                      },
                    ]}
                    initialValue={leadDetails?.jobType}
                  >
                    <Select
                      disabled={
                        selectedStatus === "Dispatch Approved"
                          ? true
                          : leadDetails?.quoteStatus === "Accepted"
                          ? true
                          : false
                      }
                      size="large"
                      defaultValue={leadDetails?.jobType}
                      showSearch
                      allowClear
                      className="select_Height"
                      // style={{ height: "40px" }}
                      onChange={(value) =>
                        this.setState({
                          jobInfo: value,
                          selectedMaterial: [],
                        })
                      }
                      placeholder="Please Select A Customer Account"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {jobType?.map((eachItem) => (
                        <Option
                          key={eachItem.label}
                          value={eachItem.label}
                          className="option_style"
                        >
                          {eachItem.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col
                  xxl={{ span: 6 }}
                  xl={{ span: 6 }}
                  lg={{ span: 6 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Delivery Location</span>
                  <Form.Item
                    name="dropoff"
                    rules={[
                      {
                        required: false,
                        errorMessage: true,
                        message: "Please Enter Your DropOff Location",
                      },
                    ]}
                  >
                    {/* <LocationSearchInput
                    getAddress={(addresss, latLng) =>
                      this.getAddresss(addresss, latLng, "addresss")
                    }
                    editMode={false}
                    className="form-input locatsearchbox"
                    style={{ width: "100%" }}
                    placeholder="Please enter your pick up"
                  /> */}
                    <GooglePlacesAutocomplete
                      className="form-input"
                      autocompletionRequest={{
                        componentRestrictions: {
                          country: ["us"],
                        },
                      }}
                      selectProps={{
                        citySearch,
                        defaultInputValue: `${
                          leadDetails?.dropoff ? leadDetails?.dropoff : ""
                        }`,
                        placeholder: "Search Address...",
                        onChange: (address) => {
                          this.handleSelect(address, "dropOff");
                          this.getAddress(address, "dropOff");
                          this.setState({
                            dropAddress: address.label,
                          });
                        },
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col
                  xxl={{
                    span: 12,
                  }}
                  xl={{
                    span: 12,
                  }}
                  lg={{
                    span: 12,
                  }}
                  md={{
                    span: 12,
                  }}
                  sm={{
                    span: 12,
                  }}
                  xs={{
                    span: 12,
                  }}
                >
                  <span className="title_changes">Truck Type</span>
                  <Form.Item
                    name="truckType"
                    rules={[
                      {
                        required: false,
                        message: "Please Select truck type",
                      },
                    ]}
                    style={{ marginBottom: "10px" }}
                  >
                    <div className="chckbox-truck">
                      <Checkbox.Group
                        style={{
                          width: "100%",
                        }}
                        onChange={this.onChangeTrucksType}
                        defaultValue={leadDetails?.truckType}
                      >
                        <Row style={{ width: "100%" }}>
                          {!truckDropInfo && (
                            <>
                              {this.props.truckTypeData?.map((t) => (
                                <Col span={8}>
                                  <Checkbox value={t.value} key={t.value}>
                                    <span
                                      style={{
                                        fontSize: "15px",
                                        fontWeight: "700",
                                        color: "rgb(88,99,112)",
                                      }}
                                    >
                                      {t.label}
                                    </span>
                                  </Checkbox>
                                </Col>
                              ))}
                            </>
                          )}
                        </Row>
                      </Checkbox.Group>
                    </div>
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <span className="title_changes">Sales Person</span>
                  <Form.Item
                    name="salesPerson"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Sales Person!",
                      },
                    ]}
                    initialValue={leadDetails?.salesPersonId}
                  >
                    <Select
                      size="large"
                      defaultValue={leadDetails?.salesPersonId}
                      showSearch
                      allowClear
                      className="select_Height"
                      onChange={(val, option) =>
                        this.setState({
                          salesCommission: option.salesCommission,
                        })
                      }
                      placeholder="Please Select A Customer Account"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {this.props.context.salesPersonsData?.map((eachItem) => (
                        <Option
                          key={eachItem.id}
                          value={eachItem.id}
                          salesCommission={eachItem.salesCommission}
                          className="option_style"
                        >
                          {eachItem?.firstName + " " + eachItem?.lastName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={jobInfo === "Trucking Job" ? 24 : 6}>
                  <span className="title_changes">Notes</span>
                  <Form.Item
                    name="notes"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Notes!",
                      },
                    ]}
                    initialValue={leadDetails?.notes}
                  >
                    <TextArea
                      rows={4}
                      style={{
                        width: "100%",
                        height: "75px",
                        borderColor: "#d6d6d6",
                      }}
                      className="form-input"
                      placeholder="Please Enter Notes"
                    />
                  </Form.Item>
                </Col>

                {jobInfo === "Trucking & Material Job" && (
                  <>
                    <Col span={6}>
                      <span className="title_changes">Tax exempt status</span>
                      <Form.Item
                        name="taxExempt"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Tax exempt!",
                          },
                        ]}
                        initialValue={
                          leadDetails?.taxExempt
                            ? leadDetails?.taxExempt
                            : "Material is quoted as"
                        }
                      >
                        {
                          <Select
                            size="large"
                            showSearch
                            allowClear
                            className="select_Height"
                            onChange={(e) => this.setState({ openDropdown: e })}
                            placeholder="Please Select A Tax exempt"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          >
                            <Option value="Tax Exempt">Tax Exempt</Option>
                            <Option value="Material is quoted as">
                              Not Tax Exempt
                            </Option>
                          </Select>
                        }
                      </Form.Item>
                    </Col>
                    {openDropdown === "Material is quoted as" && (
                      <Col span={6}>
                        <span className="title_changes">
                          Material is quoted as
                        </span>
                        <Form.Item
                          name="taxIncluded"
                          rules={[
                            {
                              required: true,
                              message: "Please Select Tax exempt!",
                            },
                          ]}
                          initialValue={
                            leadDetails?.taxIncluded
                              ? leadDetails?.taxIncluded
                              : "Tax Added"
                          }
                        >
                          <Select
                            size="large"
                            showSearch
                            allowClear
                            className="select_Height"
                            onChange={() =>
                              this.setState({
                                openDropdown: "Material is quoted as",
                              })
                            }
                            placeholder=""
                          >
                            <Option value="Tax Included">Tax Included</Option>
                            <Option value="Tax Added">Pre-Tax</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    )}

                    {/* <Col span={9}>
                      <span
                        className="title_changes"
                        style={{
                          fontSize: "16px",
                          borderBottom: "1px solid #e5e5e5",
                          width: "fit-content",
                        }}
                      >
                        Quote
                      </span>
                      <Form.Item
                        name="seperate"
                        initialValue={leadDetails?.seperate}
                      >
                        <Checkbox
                          checked={seperate}
                          onChange={(e) =>
                            this.setState({ seperate: e.target.checked })
                          }
                          className="title_changes"
                          style={{
                            marginTop: "5px",
                            width: "100%",
                            // display: "flex",
                            // flexWrap: "wrap",
                            // maxWidth:"100%",
                            fontSize: "16px",
                            fontWeight: "400",
                            // color: "rgb(88,99,112)",
                          }}
                        >
                          Generate Estimate For Material And Trucking
                          Individually
                        </Checkbox>
                      </Form.Item>
                    </Col> */}

                    <Col span={3}>
                      <p
                        className="title_changes"
                        style={{
                          fontSize: "16px",
                          borderBottom: "1px solid #e5e5e5",
                          width: "fit-content",
                        }}
                      >
                        Account Terms
                      </p>
                      <p
                        className="detail-ans"
                        style={{
                          marginTop: "-10px",
                          // width: "60%",
                          marginLeft: "5px",
                          // padding: "8px",
                          // border: "1px solid #e5e5e5",
                          height: "40px",
                        }}
                      >
                        {leadDetails?.companyDetails?.accountTerms &&
                          leadDetails?.companyDetails?.accountTerms}
                      </p>
                    </Col>
                  </>
                )}
              </Row>

              {jobInfo === "Trucking & Material Job" && (
                <>
                  {leadDetails?.requiredMaterials && (
                    <Row
                      span={24}
                      justify="center"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <Col
                        className="title_changes"
                        style={{
                          fontSize: "17px",
                          fontWeight: "700",
                          textAlign: "center",
                          color: "rgb(88,99,112)",
                          // borderBottom: "1px solid #586370",
                        }}
                      >
                        Required Materials:
                      </Col>
                      <Col
                        className="title_changes"
                        style={{
                          fontSize: "17px",
                          fontWeight: "700",
                          marginLeft: "12px",
                        }}
                      >
                        {` ${leadDetails?.requiredMaterials}`}
                      </Col>
                    </Row>
                  )}
                </>
              )}

              {jobInfo !== "Trucking Job" && (
                <Row>
                  <Row
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px",
                      marginTop: "15px",
                    }}
                  >
                    <span
                      style={{
                        marginBottom: "10px",
                        display: "flex",
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "rgb(88,99,112)",
                        textTransform: "capitalize",
                      }}
                    >
                      Material Details:
                    </span>
                    <span>
                      <Button
                        icon={<PlusOutlined />}
                        style={{
                          // width: "100%",
                          // height: "40px",
                          backgroundColor: "#586370",
                          color: "#ffffff",
                          borderRadius: "3px",
                        }}
                        onClick={() => {
                          dropAddress !== "" && dropAddress !== undefined
                            ? this.setState({
                                materialPopup: true,
                                materialSelectedData: "",
                                finalMaterialData: [],
                                topVendorsData: [],
                                vendorDetails: "",
                                initialMaterialCost: "",
                                materialPriceState: "",
                                materialTruckPrice: "",
                                duration: "",
                                milesData: "",
                                selectVendor: "",
                                quantityType: "Ton",
                                totalPriceType: "Ton",
                                materialCostType: "Ton",
                                truckingPriceType: "Ton",
                                truckingCostType: "Ton",
                                customRate: false,
                                materialCustomRate: false,
                                diameter: false,
                              })
                            : message.info(
                                "Please Enter Your Delivery Location!"
                              );
                        }}
                      >
                        Add Material
                      </Button>
                    </span>
                  </Row>
                  <Col
                    span={24}
                    style={{ overflowX: "auto" }}
                    className="twistedtable"
                  >
                    <table style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th>Vendor</th>
                          <th>Material</th>
                          <th>Quantity</th>
                          <th>Material Cost</th>
                          <th>Material Price</th>
                          <th>Trucking Cost</th>
                          <th>Trucking Price</th>
                          <th>Landed Price</th>
                          <th>Actions</th>
                          {/* <th>Pickup Location</th>
                            <th>DropOff Location</th> */}
                          {/* <th></th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {selectedMaterial?.map((data, index) => (
                          <tr
                            onDoubleClick={() => this.editMaterial(data)}
                            style={{ cursor: "pointer" }}
                          >
                            <td>{data?.vendor}</td>
                            <td>{data?.value}</td>
                            <td>
                              {data?.quantity && data?.quantityType
                                ? `${data?.quantity} - ${data?.quantityType}`
                                : ""}
                            </td>
                            <td>
                              {data?.materialCost && data?.materialCostType
                                ? `$ ${parseFloat(
                                    data?.materialCost
                                  )?.toLocaleString("us-En", {
                                    minimumFractionDigits: 2,
                                  })} / ${data?.materialCostType}`
                                : ""}
                            </td>
                            <td>
                              {data?.totalPrice && data?.totalPriceType
                                ? `$ ${parseFloat(
                                    data?.totalPrice
                                  )?.toLocaleString("us-En", {
                                    minimumFractionDigits: 2,
                                  })} / ${data?.totalPriceType}`
                                : ""}
                            </td>
                            <td>
                              {data?.truckingCost && data?.truckingCostType
                                ? `$ ${parseFloat(
                                    data?.truckingCost
                                  )?.toLocaleString("us-En", {
                                    minimumFractionDigits: 2,
                                  })} / ${data?.truckingCostType}`
                                : ""}
                            </td>
                            <td>
                              {data?.truckingPrice && data?.truckingPriceType
                                ? `$ ${parseFloat(
                                    data?.truckingPrice
                                  )?.toLocaleString("us-En", {
                                    minimumFractionDigits: 2,
                                  })} / ${data?.truckingPriceType}`
                                : ""}
                            </td>
                            <td>
                              {data?.landedPrice === "N/A"
                                ? data?.landedPrice
                                : `$ ${parseFloat(
                                    data?.landedPrice
                                  )?.toLocaleString("us-En", {
                                    minimumFractionDigits: 2,
                                  })} / ${data?.totalPriceType}`}
                            </td>
                            {/* <td>{data?.pickUpAddress}</td>
                              <td> {data?.dropoff ? data?.dropoff : ""}</td> */}
                            <td style={{ display: "flex", margin: "6px" }}>
                              <Button
                                style={{ marginLeft: "6px", width: "70px" }}
                                onClick={() => this.editMaterial(data)}
                                type="primary"
                              >
                                Edit
                              </Button>
                              <Button
                                style={{ marginLeft: "6px" }}
                                onClick={() => this.deleteMaterial(data)}
                                type="primary"
                              >
                                Delete
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Col>
                </Row>
              )}

              {jobInfo === "Trucking Job" && (
                <Row>
                  <Row
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px",
                      marginTop: "15px",
                    }}
                  >
                    <span
                      style={{
                        marginBottom: "10px",
                        display: "flex",
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "rgb(88,99,112)",
                        textTransform: "capitalize",
                      }}
                    >
                      Trucking Details:
                    </span>
                    <span>
                      <Button
                        icon={<PlusOutlined />}
                        style={{
                          // width: "100%",
                          // height: "40px",
                          backgroundColor: "#586370",
                          color: "#ffffff",
                          borderRadius: "3px",
                        }}
                        onClick={() => {
                          dropAddress !== "" && dropAddress !== undefined
                            ? this.setState({
                                materialPopup: true,
                                materialSelectedData: "",
                                finalMaterialData: [],
                                selectVendor: "",
                                duration: "",
                                milesData: "",
                                truckingCostType: "Ton",
                                truckingPriceType: "Ton",
                              })
                            : message.info(
                                "Please Enter Your Delivery Location!"
                              );
                        }}
                      >
                        Add Trucking Details
                      </Button>
                    </span>
                  </Row>
                  <Col
                    span={24}
                    style={{ overflowX: "auto" }}
                    className="twistedtable"
                  >
                    <table style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          {/* <th>Vendor</th> */}
                          <th>Pickup Location</th>
                          {/* <th>Material</th> */}
                          <th>Trucking Cost</th>
                          <th>Trucking Price To Customer</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedMaterial?.map((data, index) => (
                          <tr
                            onDoubleClick={() => this.editMaterial(data)}
                            style={{ cursor: "pointer" }}
                          >
                            <td>{data?.pickUpAddress}</td>
                            {/* <td>{data?.vendor}</td> */}
                            {/* <td>{data?.value}</td> */}
                            <td>
                              {data?.truckingCost && data?.truckingCostType
                                ? `$ ${parseFloat(
                                    data?.truckingCost
                                  )?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                  })} / ${data?.truckingCostType}`
                                : ""}
                            </td>
                            <td>
                              {data?.truckingPrice && data?.truckingPriceType
                                ? `$ ${parseFloat(
                                    data?.truckingPrice
                                  )?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                  })} / ${data?.truckingPriceType}`
                                : ""}
                            </td>
                            <td>
                              {/* <Button
                                onClick={() => this.editMaterial(data)}
                                type="primary"
                              >
                                Edit
                              </Button> */}
                              <Button
                                style={{ marginLeft: "6px" }}
                                onClick={() => this.deleteMaterial(data.id)}
                                type="primary"
                              >
                                Delete
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Col>
                </Row>
              )}
            </Form>
          </BaseModal>
        )}

        {isContactVisible && (
          <BaseModal
            title={jobContactData !== "" ? "Update Contact" : " Add Contact"}
            onCancel={() => {
              this.setState({
                isContactVisible: false,
                isCheckBox: false,
                newRole: "",
              });
            }}
            loading={this.state.loading}
            formId="contact"
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "80%"
                : "520px"
            }
            className="antmodal_grid headermodal"
          >
            <Form
              style={{ width: "100%" }}
              onFinish={this.onFinishContact}
              id="contact"
            >
              <Row gutter={[12, 0]}>
                <Col span={12}>
                  <span className="title_changes">First Name</span>
                  <Form.Item
                    name="fName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your First Name!",
                      },
                    ]}
                    initialValue={jobContactData?.firstName}
                  >
                    <Input
                      onChange={(e) =>
                        this.setState({ firstName: e.target.value })
                      }
                      className="form-input"
                      placeholder="Please Enter Your First Name"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <span className="title_changes">Last Name</span>
                  <Form.Item
                    name="lName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter your Last Name!",
                      },
                    ]}
                    initialValue={jobContactData?.lastName}
                  >
                    <Input
                      onChange={(e) =>
                        this.setState({ lastName: e.target.value })
                      }
                      className="form-input"
                      placeholder="Please Enter your Last Name"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <span className="title_changes">Phone</span>
                  <Form.Item
                    name="phone"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Phone!",
                      },
                    ]}
                    initialValue={jobContactData?.phone}
                  >
                    <NumberFormat
                      onChange={(e) => this.setState({ phone: e.target.value })}
                      className="ant-input-number numberictest"
                      format="(###) ###-####"
                      style={{ width: "100%" }}
                      placeholder="Please Enter Your Phone"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <span className="title_changes">Email</span>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Email!",
                      },
                      {
                        type: "email",
                        message: "The Input Is Not Valid Email",
                      },
                    ]}
                    initialValue={jobContactData?.email}
                  >
                    <Input
                      onChange={(e) => this.setState({ email: e.target.value })}
                      className="form-input"
                      placeholder="Please Enter Your Email"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{
                    span: 24,
                  }}
                  xl={{
                    span: 24,
                  }}
                  lg={{
                    span: 24,
                  }}
                  md={{
                    span: 24,
                  }}
                  sm={{
                    span: 24,
                  }}
                  xs={{
                    span: 24,
                  }}
                >
                  <span className="title_changes">Role</span>
                  <Form.Item
                    name="role"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Role!",
                      },
                    ]}
                  >
                    <Select
                      defaultValue={jobContactData?.contactRole}
                      size="large"
                      showSearch
                      allowClear
                      className="select_Height"
                      // style={{ height: "40px" }}
                      placeholder="Please Select A Role"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(value) => {
                        this.setState({ newRole: value });
                        value === "Others" && message.info("Please Enter Role");
                      }}
                    >
                      {this.props.context.rolesData?.map((role) => (
                        <Option key={role.id} value={role.label}>
                          {role.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {newRole === "Add New Role" && (
                  <>
                    <Col span={24}>
                      <span className="title_changes">Add New Role</span>
                      <Form.Item
                        name="roleOther"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter New Role!",
                          },
                        ]}
                      >
                        <Input
                          onChange={(e) =>
                            this.setState({ roleOther: e?.target?.value })
                          }
                          className="form-input"
                          placeholder="Add New Role"
                        />
                      </Form.Item>

                      <Checkbox
                        checked={this.state.roleCheckBox}
                        onChange={(e) =>
                          this.setState({
                            roleCheckBox: e.target.checked,
                          })
                        }
                        style={{ marginBottom: "18px" }}
                      >
                        <span
                          className="checkBox_size"
                          style={{ fontSize: "14px" }}
                        >
                          Add this new role to default list
                        </span>
                      </Checkbox>
                    </Col>
                  </>
                )}
              </Row>
            </Form>
          </BaseModal>
        )}

        {materialPopup && (
          <BaseModal
            title={
              recordMaterialData?.length !== 0 && jobInfo === "Trucking Job"
                ? "Update Trucking Details"
                : recordMaterialData?.length !== 0 && jobInfo !== "Trucking Job"
                ? "Update Material Details"
                : jobInfo !== "Trucking Job"
                ? "Add Material Details"
                : "Add Trucking Details"
            }
            onCancel={this.onCancelMaterial}
            loading={this.state.loading}
            formId="material"
            // width="40%"
            className="antmodal_grid headermodal"
            submitButton={
              jobInfo === "Trucking & Material Job" &&
              topVendorsData?.length === 0
                ? "Find Nearest Vendors"
                : recordMaterialData?.length !== 0 && jobInfo !== "Trucking Job"
                ? "Update"
                : "Add"
            }
            btnWidth={"fit-content"}
            width={
              jobInfo === "Trucking & Material Job" ? "55%" : "35%"
              // window.screen.width > "501" && window.screen.width <= "991"
              //   ? "80%"
              //   : "560px"
            }
          >
            <Loading enableLoading={loading} />
            <Form
              onFinish={
                jobInfo === "Trucking & Material Job" &&
                topVendorsData?.length === 0
                  ? this.getTopVendors
                  : this.onFinishMaterial
              }
              initialValues={{ remember: true }}
              id="material"
              ref={this.formRef}
              style={{ width: "100%" }}
            >
              <Row style={{ width: "100%" }} gutter={[24, 0]}>
                {jobInfo !== "Trucking Job" && (
                  <>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Material Categories</span>
                      <Form.Item
                        name="materialCategory"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Your Material!",
                          },
                        ]}
                        initialValue={recordMaterialData?.category}
                      >
                        <Select
                          size="large"
                          showSearch
                          allowClear
                          className="select_Height"
                          placeholder="Please Select A Material"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onClear={() =>
                            this.setState({
                              materialSelectedData: "",
                            })
                          }
                          onChange={(value) => {
                            this.onSelectMaterialCat(value);
                            this.setState({
                              materialValue: value,
                              initialMaterialCost: "",
                              materialPriceState: "",
                              materialCostState: "",
                              materialTruckPrice: "",
                            });
                            this.formRef.current.setFieldsValue({
                              material: "",
                              quantity: "",
                              totalPrice: "",
                              truckingPrice: "",
                            });
                          }}
                        >
                          {Object?.keys(materialCategory).map((c, i) => (
                            <Option key={i} value={c}>
                              {c}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">
                        Material Subcategory
                      </span>
                      <Form.Item
                        name="material"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Your Material!",
                          },
                        ]}
                        initialValue={recordMaterialData?.label}
                      >
                        <Select
                          size="large"
                          onClear={() =>
                            this.setState({
                              materialSelectedData: "",
                            })
                          }
                          showSearch
                          allowClear
                          className="select_Height"
                          placeholder="Please Select A Material"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={this.onSelectMaterial}
                        >
                          {finalMaterialData?.map(
                            (m) =>
                              m.statusCode && (
                                <Option key={m?.id} value={m?.id}>
                                  {m?.label}
                                </Option>
                              )
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col
                      span={12}
                      style={{ paddingLeft: "0px", paddingRight: "0px" }}
                    >
                      <Col span={24}>
                        <span className="title_changes">Testing</span>
                        <Form.Item
                          name="materialTest"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Testing!",
                            },
                          ]}
                          initialValue={recordMaterialData?.materialTest}
                          style={{ marginBottom: "10px" }}
                        >
                          <Select
                            size="large"
                            showSearch
                            allowClear
                            className="select_Height"
                            // style={{ height: "40px" }}
                            placeholder="Please Select A testMaterial"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onChange={this.resetvendorValues}
                          >
                            {QuarryJsonData?.testMaterial?.map((m, i) => (
                              <Option key={i} value={m}>
                                {m}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      {diameter ? (
                        <Col span={24} style={{ marginTop: "20px" }}>
                          <Row gutter={[12, 0]}>
                            <Col span={12}>
                              <span className="title_changes">Min</span>
                              <Form.Item
                                name="materialMinDiameter"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Diameter!",
                                  },
                                ]}
                                initialValue={
                                  recordMaterialData?.materialMinDiameter
                                }
                                style={{ marginBottom: "10px" }}
                              >
                                {/* <InputNumber
                                  formatter={(value) =>
                                    value !== "" ? `${value}"` : ""
                                  }
                                  style={{ width: "100%", height: "40px" }}
                                  placeholder="Please enter your diameter"
                                  className="form-input"
                                  onChange={this.resetvendorValues}
                                /> */}
                                <Input
                                  style={{
                                    height: "40px",
                                    width: "100%",
                                  }}
                                  className="form-input"
                                  placeholder="Please enter diameter"
                                  onChange={this.resetvendorValues}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span className="title_changes">Max</span>
                                <Switch
                                  checkedChildren={"Diameter"}
                                  unCheckedChildren={"Range"}
                                  onChange={(e) =>
                                    this.setState({ diameter: e })
                                  }
                                  checked={diameter}
                                >
                                  Size Range
                                </Switch>
                              </span>
                              <Form.Item
                                name="materialMaxDiameter"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Diameter!",
                                  },
                                ]}
                                initialValue={
                                  recordMaterialData?.materialMaxDiameter
                                }
                                style={{ marginBottom: "10px" }}
                              >
                                <Input
                                  style={{
                                    height: "40px",
                                    width: "100%",
                                  }}
                                  className="form-input"
                                  placeholder="Please enter diameter"
                                  onChange={this.resetvendorValues}
                                />
                                {/* <InputNumber
                                  formatter={(value) =>
                                    value !== "" ? `${value}"` : ""
                                  }
                                  style={{ width: "100%", height: "40px" }}
                                  placeholder="Please enter your diameter"
                                  className="form-input"
                                  onChange={this.resetvendorValues}
                                /> */}
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      ) : (
                        <Col span={24} style={{ marginTop: "20px" }}>
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span className="title_changes">Diameter</span>
                            <Switch
                              checkedChildren={"Diameter"}
                              unCheckedChildren={"Range"}
                              onChange={(e) => this.handleCheckStatus(e)}
                            >
                              Size Range
                            </Switch>
                          </span>
                          <Form.Item
                            name="materialDiameter"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Diameter!",
                              },
                            ]}
                            initialValue={recordMaterialData?.materialDiameter}
                            style={{ marginBottom: "10px" }}
                          >
                            {/* <InputNumber
                              formatter={(value) =>
                                value !== "" ? `${value}"` : ""
                              }
                              style={{ width: "100%", height: "40px" }}
                              placeholder="Please enter your diameter"
                              className="form-input"
                              onChange={this.resetvendorValues}
                            /> */}
                            <Input
                              style={{
                                height: "40px",
                                width: "100%",
                              }}
                              className="form-input"
                              placeholder="Please enter diameter"
                              onChange={this.resetvendorValues}
                            />
                          </Form.Item>
                        </Col>
                      )}
                    </Col>
                    <Col
                      span={12}
                      style={{ paddingLeft: "0px", paddingRight: "0px" }}
                    >
                      <Col span={24}>
                        <span className="title_changes">Washed Status</span>
                        <Form.Item
                          name="materialStatus"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter washed status!",
                            },
                          ]}
                          initialValue={recordMaterialData?.materialStatus}
                          style={{ marginBottom: "10px" }}
                        >
                          <Select
                            size="large"
                            showSearch
                            allowClear
                            className="select_Height"
                            // style={{ height: "40px" }}
                            placeholder="Please Select A statusMaterial"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onChange={this.resetvendorValues}
                          >
                            {QuarryJsonData?.statusMaterial?.map((m, i) => (
                              <Option key={i} value={m}>
                                {m}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24} style={{ marginTop: "20px" }}>
                        <span className="title_changes">Supply</span>
                        <Form.Item
                          name="materialSupply"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter supply!",
                            },
                          ]}
                          initialValue={recordMaterialData?.materialSupply}
                          style={{ marginBottom: "10px" }}
                        >
                          <Select
                            size="large"
                            showSearch
                            allowClear
                            className="select_Height"
                            // style={{ height: "40px" }}
                            placeholder="Please Select A Supply"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onChange={this.resetvendorValues}
                          >
                            {QuarryJsonData?.supplyMaterial?.map((m, i) => (
                              <Option key={i} value={m}>
                                {m}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Col>

                    <Loading enableLoading={this.state.loading} />
                    {topVendorsData?.length > 0 && (
                      <Col
                        xxl={{ span: 24 }}
                        xl={{ span: 24 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                        className="tiprotitle"
                        style={{ marginBottom: "0.5vw" }}
                      >
                        <BaseTable
                          columnsData={topVendorsColumns}
                          source={topVendorsData?.slice(0, 3)}
                          total={topVendorsData?.length}
                          className="table_laptopscreen"
                          pagination={false}
                          rowSelection={{
                            type: "radio",
                            ...rowSelection,
                          }}
                          rowKey={(record) => record.id}
                        />
                      </Col>
                    )}
                    {topVendorsData?.length > 0 && (
                      <>
                        {/* <Col
                          xxl={{ span: 8 }}
                          xl={{ span: 8 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <span className="title_changes">
                            Required Quantity
                          </span>
                          <Form.Item
                            name="quantity"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Quantity",
                              },
                            ]}
                            initialValue={recordMaterialData?.quantity}
                          >
                            <InputNumber
                              decimalSeparator="."
                              precision={2}
                              placeholder="Quantity"
                              addonAfter={selectAfter}
                              style={{
                                height: "40px",
                                width: "100%",
                              }}
                              onChange={(e) =>
                                this.setState({
                                  quantity: e,
                                  // initialMaterialCost: materialCostPrice,
                                })
                              }
                            />
                          </Form.Item>
                        </Col> */}
                        <Col
                          xxl={{ span: 8 }}
                          xl={{ span: 8 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <span className="title_changes">Use Markup</span>
                          <Form.Item
                            name="markUp"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Use Markup",
                              },
                            ]}
                            initialValue={recordMaterialData?.quantity}
                          >
                            <InputNumber
                              decimalSeparator="."
                              precision={2}
                              placeholder="Use Markup"
                              addonAfter={"%"}
                              style={{
                                height: "40px",
                                width: "100%",
                              }}
                              onChange={(e) => {
                                this.setState({
                                  useMarkUp: e,
                                  materialPriceState:
                                    e === initialMaterialCost &&
                                    e < initialMaterialCost
                                      ? 0
                                      : initialMaterialCost +
                                        initialMaterialCost * (e / 100),
                                });
                                this.formRef.current.setFieldsValue({
                                  totalPrice:
                                    e === 0
                                      ? initialMaterialCost
                                      : e
                                      ? initialMaterialCost +
                                        initialMaterialCost * (e / 100)
                                      : "",
                                });
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xxl={{ span: 8 }}
                          xl={{ span: 8 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <span className="title_changes">Material Cost</span>
                          <Form.Item
                            name="materialCost"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Material Cost!",
                              },
                            ]}
                            initialValue={initialMaterialCost?.toLocaleString(
                              2
                            )}
                            style={{ marginBottom: "0px" }}
                          >
                            <InputNumber
                              parser={(value) =>
                                value.replace(/\$\s?|(,*)/g, "")
                              }
                              className="material"
                              prefix={"$"}
                              precision={2}
                              decimalSeparator={"."}
                              placeholder="Please Enter Material Cost"
                              addonAfter={selectAfterMaterial}
                              style={{
                                height: "40px",
                                width: "100%",
                              }}
                              readOnly={!materialCustomRate}
                              onChange={(e) => {
                                this.setState({
                                  materialCostPrice: e,
                                  initialMaterialCost: e,
                                  materialPriceState:
                                    useMarkUp === e && useMarkUp < e
                                      ? 0
                                      : e + e * (useMarkUp / 100),
                                });
                                this.formRef.current.setFieldsValue({
                                  totalPrice:
                                    useMarkUp === 0
                                      ? e
                                      : useMarkUp
                                      ? e + e * (useMarkUp / 100)
                                      : "",
                                });
                              }}
                            />
                          </Form.Item>
                          <div
                            style={{ marginTop: "2px", marginBottom: "5px" }}
                          >
                            <Checkbox
                              onChange={(e) =>
                                this.setState({
                                  materialCustomRate: e.target.checked,
                                })
                              }
                            >
                              <span>Custom Rate</span>
                            </Checkbox>
                          </div>
                        </Col>
                        <Col
                          xxl={{ span: 8 }}
                          xl={{ span: 8 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <span className="title_changes">Material Price</span>
                          <Form.Item
                            name="totalPrice"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Material Price!",
                              },
                            ]}
                            initialValue={recordMaterialData?.totalPrice?.toLocaleString(
                              2
                            )}
                          >
                            <InputNumber
                              prefix={"$"}
                              precision={2}
                              // formatter={(value) =>
                              //   `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              // }
                              parser={(value) =>
                                value.replace(/\$\s?|(,*)/g, "")
                              }
                              decimalSeparator={"."} // value={quantity}
                              placeholder="Please Enter Material Price"
                              addonAfter={selectAfterCustomer}
                              style={{
                                height: "40px",
                                width: "100%",
                              }}
                              onChange={(e) => {
                                this.formRef.current.setFieldsValue({
                                  markUp:
                                    ((parseFloat(e) - initialMaterialCost) /
                                      initialMaterialCost) *
                                    100,
                                });
                                this.setState({ materialPriceState: e });
                              }}
                            />
                          </Form.Item>
                        </Col>
                        {/* <Col
                          xxl={{ span: 8 }}
                          xl={{ span: 8 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <span className="title_changes">Use Markup</span>
                          <Form.Item
                            name="markUp"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Use Markup",
                              },
                            ]}
                            initialValue={recordMaterialData?.quantity}
                          >
                            <InputNumber
                              decimalSeparator="."
                              precision={2}
                              placeholder="Use Markup"
                              addonAfter={"%"}
                              style={{
                                height: "40px",
                                width: "100%",
                              }}
                              onChange={(e) => {
                                this.setState({
                                  useMarkUp: e,
                                  materialPriceState:
                                    e === initialMaterialCost &&
                                    e < initialMaterialCost
                                      ? 0
                                      : initialMaterialCost +
                                        initialMaterialCost * (e / 100),
                                });
                                this.formRef.current.setFieldsValue({
                                  totalPrice:
                                    e === 0
                                      ? initialMaterialCost
                                      : e
                                      ? initialMaterialCost +
                                        initialMaterialCost * (e / 100)
                                      : "",
                                });
                              }}
                            />
                          </Form.Item>
                        </Col> */}
                        <Col
                          xxl={{ span: 8 }}
                          xl={{ span: 8 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <span className="title_changes">
                            Required Quantity
                          </span>
                          <Form.Item
                            name="quantity"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Quantity",
                              },
                            ]}
                            initialValue={recordMaterialData?.quantity}
                          >
                            <InputNumber
                              decimalSeparator="."
                              precision={2}
                              placeholder="Quantity"
                              addonAfter={selectAfter}
                              style={{
                                height: "40px",
                                width: "100%",
                              }}
                              onChange={(e) =>
                                this.setState({
                                  quantity: e,
                                  // initialMaterialCost: materialCostPrice,
                                })
                              }
                            />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </>
                )}

                {jobInfo === "Trucking Job" && (
                  <>
                    <Col
                      xxl={{ span: 24 }}
                      xl={{ span: 24 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Pickup Location</span>
                      <Form.Item
                        name="pickup"
                        rules={[
                          {
                            required: false,
                            errorMessage: true,
                            message: "Please Enter Your Pickup Location",
                          },
                        ]}
                      >
                        <GooglePlacesAutocomplete
                          className="form-input"
                          autocompletionRequest={{
                            componentRestrictions: {
                              country: ["us"],
                            },
                          }}
                          selectProps={{
                            citySearch,
                            defaultInputValue: `${
                              recordMaterialData?.pickUpAddress
                                ? recordMaterialData?.pickUpAddress
                                : ""
                            }`,
                            placeholder: "Search Address...",
                            onChange: (address) => {
                              this.handleSelect(address, "pickup");
                              this.getAddress(address, "pickup");
                              this.setState({
                                pickUpAddress: address.label,
                              });
                            },
                          }}
                        />
                      </Form.Item>
                    </Col>
                    {((milesData !== "" && milesData !== undefined) ||
                      recordMaterialData?.milesData !== undefined) && (
                      <Col
                        span={24}
                        className="tiprotitle"
                        style={{ marginBottom: "0.5vw" }}
                      >
                        <span className="title_changes">
                          <b
                            style={{
                              marginBottom: "10px",
                              borderBottom: "1px solid gray",
                            }}
                          >
                            Run and Rate information:
                          </b>
                          {milesData?.text !== undefined ? (
                            <div>
                              {milesData?.text + "les, " + duration?.text}
                            </div>
                          ) : (
                            recordMaterialData?.milesData?.text !==
                              undefined && (
                              <div>
                                {recordMaterialData?.milesData?.text +
                                  "les, " +
                                  recordMaterialData?.duration?.text}
                              </div>
                            )
                          )}
                        </span>
                      </Col>
                    )}
                  </>
                )}
                {((jobInfo === "Trucking & Material Job" &&
                  topVendorsData?.length > 0) ||
                  jobInfo !== "Trucking & Material Job") && (
                  <>
                    <Col
                      xxl={{ span: jobInfo === "Trucking Job" ? 12 : 8 }}
                      xl={{ span: jobInfo === "Trucking Job" ? 12 : 8 }}
                      lg={{ span: jobInfo === "Trucking Job" ? 12 : 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Trucking Price</span>
                      <Form.Item
                        name="truckingPrice"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Trucking Price!",
                          },
                        ]}
                        initialValue={recordMaterialData?.truckingPrice?.toLocaleString(
                          2
                        )}
                      >
                        <InputNumber
                          prefix={"$"}
                          precision={2}
                          decimalSeparator={"."} // value={quantity}
                          placeholder="Please Enter Trucking Price"
                          addonAfter={selectAfterTruckPrice}
                          style={{
                            height: "40px",
                            width: "100%",
                          }}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                          onChange={(e) => {
                            this.setState({
                              materialTruckPrice: e,
                              truckingCost:
                                parseFloat(e) - parseFloat(e) * (12.5 / 100),
                            });
                            this.formRef.current.setFieldsValue({
                              truckingCost:
                                parseFloat(e) - parseFloat(e) * (12.5 / 100),
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      xxl={{ span: jobInfo === "Trucking Job" ? 12 : 8 }}
                      xl={{ span: jobInfo === "Trucking Job" ? 12 : 8 }}
                      lg={{ span: jobInfo === "Trucking Job" ? 12 : 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Trucking Cost</span>
                      <Form.Item
                        name="truckingCost"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Trucking Cost!",
                          },
                        ]}
                        initialValue={recordMaterialData?.truckingCost?.toLocaleString(
                          2
                        )}
                        style={{ marginBottom: "0px" }}
                      >
                        <InputNumber
                          prefix={"$"}
                          precision={2}
                          // formatter={(value) =>
                          //   `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          // }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                          decimalSeparator={"."} // value={quantity}
                          placeholder="Please Enter Trucking Cost"
                          addonAfter={selectAfterTruck}
                          style={{
                            height: "40px",
                            width: "100%",
                          }}
                          readOnly={!customRate}
                          onChange={(e) => {
                            this.setState({
                              truckingCost: e,
                              materialTruckPrice:
                                parseFloat(e) + parseFloat(e) * (12.5 / 100),
                            });
                            this.formRef.current.setFieldsValue({
                              truckingPrice:
                                parseFloat(e) + parseFloat(e) * (12.5 / 100),
                            });
                          }}
                        />
                      </Form.Item>

                      <div style={{ marginTop: "2px", marginBottom: "5px" }}>
                        <Checkbox
                          onChange={(e) =>
                            this.setState({
                              customRate: e.target.checked,
                            })
                          }
                        >
                          <span>Custom Rate</span>
                        </Checkbox>
                      </div>
                    </Col>
                  </>
                )}

                {jobInfo === "Trucking & Material Job" &&
                  topVendorsData?.length > 0 && (
                    <>
                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      ></Col>

                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Row justify="space-between">
                          <Col span={12}>
                            <span className="title_changes">
                              Landed Cost -{" "}
                            </span>
                          </Col>
                          <Col span={12}>
                            {materialCostType === truckingCostType &&
                            initialMaterialCost &&
                            truckingCost
                              ? `$${parseFloat(
                                  initialMaterialCost + truckingCost
                                )?.toLocaleString("us-EN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                                 / ${materialCostType}`
                              : "N/A"}
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Row>
                          <Col span={12}>
                            <span className="title_changes">
                              Landed Price -{" "}
                            </span>
                          </Col>
                          <Col span={12}>
                            {truckingPriceType === totalPriceType &&
                            materialPriceState &&
                            materialTruckPrice
                              ? `$${parseFloat(
                                  materialPriceState + materialTruckPrice
                                )?.toLocaleString("us-EN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })} / ${truckingPriceType}`
                              : "N/A"}
                          </Col>
                        </Row>
                      </Col>
                      <Divider
                        style={{ width: "100%", border: "1px solid #dbdbdb" }}
                      />
                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Row>
                          <Col span={12}>
                            <span className="title_changes">Total Sale - </span>
                          </Col>
                          <Col span={12}>
                            {truckingPriceType === totalPriceType &&
                            materialPriceState &&
                            quantity &&
                            materialTruckPrice
                              ? `$${parseFloat(
                                  (materialPriceState + materialTruckPrice) *
                                    quantity
                                )?.toLocaleString("us-EN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}`
                              : "N/A"}
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Row>
                          <Col span={12}>
                            <span className="title_changes">
                              Total Expense -{" "}
                            </span>
                          </Col>
                          <Col span={12}>
                            {materialCostType === truckingCostType &&
                            initialMaterialCost &&
                            quantity &&
                            truckingCost
                              ? `$${parseFloat(
                                  (initialMaterialCost + truckingCost) *
                                    quantity
                                )?.toLocaleString("us-EN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}`
                              : "N/A"}
                          </Col>
                        </Row>
                      </Col>

                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Row>
                          <Col span={12}>
                            <span className="title_changes">
                              Total Profit (%) -
                            </span>
                          </Col>
                          <Col span={12}>
                            {truckingPriceType === totalPriceType &&
                            materialCostType === truckingCostType &&
                            materialPriceState !== undefined &&
                            materialPriceState !== "" &&
                            materialTruckPrice !== undefined &&
                            materialTruckPrice !== "" &&
                            quantity !== undefined &&
                            quantity !== "" &&
                            initialMaterialCost !== undefined &&
                            initialMaterialCost !== "" &&
                            materialTruckPrice !== undefined &&
                            materialTruckPrice !== "" &&
                            truckingCost !== undefined &&
                            truckingCost !== ""
                              ? `$${parseFloat(
                                  (materialPriceState + materialTruckPrice) *
                                    quantity -
                                    (initialMaterialCost + truckingCost) *
                                      quantity
                                )?.toLocaleString("us-EN", {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                })} (${
                                  !isNaN(
                                    (
                                      (((materialPriceState +
                                        materialTruckPrice) *
                                        quantity -
                                        (initialMaterialCost + truckingCost) *
                                          quantity) /
                                        ((materialPriceState +
                                          materialTruckPrice) *
                                          quantity)) *
                                      100
                                    )?.toFixed(2)
                                  )
                                    ? (
                                        (((materialPriceState +
                                          materialTruckPrice) *
                                          quantity -
                                          (initialMaterialCost + truckingCost) *
                                            quantity) /
                                          ((materialPriceState +
                                            materialTruckPrice) *
                                            quantity)) *
                                        100
                                      )?.toFixed(2)
                                    : "0.00"
                                }%)`
                              : "N/A"}
                          </Col>
                        </Row>
                      </Col>
                    </>
                  )}
              </Row>
            </Form>
          </BaseModal>
        )}

        {addTruck && (
          <BaseModal
            className="crmform_modal antmodal_grid headermodal"
            title={"Add Equipment"}
            onCancel={() =>
              this.setState({
                addTruck: false,
                companyData: {},
              })
            }
            loading={imageLoading}
            visible={addTruck}
            destroyOnClose={true}
            formId="truck"
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "80%"
                : "720px"
            }
          >
            <Form
              style={{ width: "100%" }}
              onFinish={this.onFinishTruckDetails}
              id="truck"
              ref={this.formRef}
            >
              <Row gutter={[24, 8]}>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Truck Type</span>
                  <Form.Item
                    name={"truck_type"}
                    rules={[
                      {
                        required: true,
                        message: "Please Select Truck Type",
                      },
                    ]}
                    style={{
                      marginBottom: "5px",
                    }}
                  >
                    <Select
                      allowClear
                      className="select_Height"
                      style={{
                        height: "40px",
                        fontWeight: "300",
                      }}
                      placeholder="Select Truck Type"
                      onChange={(e, option) => {
                        this.setState({
                          selectedTruckCode: option?.key,
                        });
                      }}
                    >
                      {this.props.trucksData?.map((t) => (
                        <Option value={t.value} key={t.truck_code}>
                          {t.value}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Empty Weight</span>
                  <Form.Item
                    name={"empty_weight"}
                    rules={[
                      {
                        required: false,
                        message: "Enter Empty Weight",
                      },
                    ]}
                  >
                    <InputNumber
                      min={1}
                      addonAfter={"Ton"}
                      style={{
                        width: "100%",
                      }}
                      placeholder="Enter Empty Weight"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={11}
                >
                  <span className="title_changes">Parking Location</span>
                  <Form.Item
                    name="address"
                    rules={[
                      {
                        required: false,
                        message: "Please enter your address",
                      },
                    ]}
                  >
                    <GooglePlacesAutocomplete
                      className="form-input"
                      autocompletionRequest={{
                        componentRestrictions: {
                          country: ["us"],
                        },
                      }}
                      selectProps={{
                        defaultInputValue: `${
                          companyData?.address ? companyData?.address : ""
                        }`,
                        citySearch,
                        placeholder: "Search Address...",
                        onChange: (address) => {
                          this.handleSelect(address);
                          this.getAddress(address);
                        },
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 8 }}
                  xl={{ span: 8 }}
                  lg={{ span: 8 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Overweight Permit</span>
                  <Form.Item
                    name="overWeightPermit"
                    rules={[
                      {
                        required: false,
                        message: "Please Select Required!",
                      },
                    ]}
                  >
                    <Radio.Group
                      onChange={this.onChangeOverWeight}
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-around",
                        padding: "8px",
                        border: "1px solid #e5e5e5",
                        height: "40px",
                        borderRadius: "5px",
                      }}
                    >
                      <Radio style={{ fontSize: "16px" }} value={"Yes"}>
                        Yes
                      </Radio>
                      <Radio style={{ fontSize: "16px" }} value={"No"}>
                        No
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                {overWeightPermitState === "Yes" && (
                  <>
                    <Col span={12} offset={4}>
                      <span className="title_changes">Date Of Expiry</span>
                      <Form.Item
                        name={"overWeightExpiryDate"}
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Date Of Expiry",
                          },
                        ]}
                      >
                        <DatePicker
                          placeholder="Please Select A Date"
                          style={{
                            width: "100%",
                            height: "40px",
                            borderRadius: "5px",
                          }}
                          format={"MM/DD/YYYY"}
                          // defaultValue={moment()}
                          placement={"bottomRight"}
                          allowClear
                          bordered={true}
                          // onChange={this.onChangeYear}
                          disabledDate={(current) => {
                            let customDate = moment().format("MM-DD-YYYY");
                            return (
                              current &&
                              current < moment(customDate, "MM-DD-YYYY")
                            );
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xxl={{ span: 24 }}
                      xl={{ span: 24 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      // span={24}
                    >
                      <span className="title_changes">Attachment</span>
                      <Form.Item
                        name="docAttachment"
                        rules={[
                          {
                            required: true,
                            message: "Please Attach Your Document",
                          },
                        ]}
                      >
                        <Spin spinning={imageLoading}>
                          <Dragger
                            onChange={this.handleUploadPic}
                            customRequest={dummyRequest}
                            listType="picture"
                            // fileList={recordDocData?.docAttachment}
                            multiple={true}
                            className="drag-ticket"
                            // beforeUpload={this.handleUploadType}
                            maxCount={1}
                          >
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                              Click or drag file to this area to upload
                            </p>
                          </Dragger>
                        </Spin>
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>
            </Form>
          </BaseModal>
        )}
      </>
    );
  }
}

export default withAppContext(
  withRouter(DragDropContext(HTML5Backend)(DraggableCard))
);

const boxTarget = {
  drop(props) {
    return { name: props.status };
  },
};

class KanbanColumn extends React.Component {
  render() {
    return this.props.connectDropTarget(<div>{this.props.children}</div>);
  }
}

KanbanColumn = DropTarget("kanbanItem", boxTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
}))(KanbanColumn);

// Item

const boxSource = {
  beginDrag(props) {
    return {
      name: props.id,
    };
  },

  endDrag(props, monitor) {
    // const item = monitor.getItem();
    const dropResult = monitor.getDropResult();
    if (dropResult) {
      props.onDrop(monitor.getItem().name, dropResult.name);
    }
  },
};

class KanbanItem extends React.Component {
  render() {
    return this.props.connectDragSource(<div>{this.props.children}</div>);
  }
}

KanbanItem = DragSource("kanbanItem", boxSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
}))(KanbanItem);
