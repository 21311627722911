import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Popover,
  Input,
  Form,
  Popconfirm,
  Select,
  message,
  Checkbox,
  InputNumber,
  Tooltip,
  Divider,
  Switch,
  // DatePicker,
  AutoComplete,
} from "antd";
import {
  UnorderedListOutlined,
  EllipsisOutlined,
  UsergroupAddOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import { RiDashboardLine } from "react-icons/ri";
import { MdModeEditOutline, MdDelete } from "react-icons/md";
// import FooterData from "../../Common/FooterData";
import SearchFunction from "../../../Common/SearchFunction";
import { withAppContext } from "../../../Store/AppContext";
import { add, list, updateTotal } from "../../../api/Apis";
import appConfig from "../../../config/AppConfig";
import DraggableCard from "../../../Common/DraggableCard";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import NumberFormat from "react-number-format";
import funnelStages from "../../../Json/funnel.json";
import Loading from "../../../Common/Loading";
import ErrorView from "../../../Common/ErrorView";
import BaseModal from "../../../Common/BaseModal";
import BaseTable from "../../../Common/BaseTable";
import TextArea from "antd/lib/input/TextArea";
import { IoFilterSharp } from "react-icons/io5";
import Filter from "../../../Common/Filter";
import { v4 as uuidv4 } from "uuid";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaMap } from "react-icons/fa";
import { connect } from "react-redux";
import {
  addContact,
  addContacts,
  addLead,
  deleteLead,
  getCompanies,
  getCompany,
  getDropDownOptions,
  getLeads,
  getQuarryLocations,
  getTopVendors,
  updateDropDownOptions,
  updateLead,
} from "../../../Redux/Actions/actions";
import { withRouter } from "react-router-dom";
import QuarryJsonData from "../../../Json/quarryData.json";
import moment from "moment";
import MapComponent from "../../Maps/MapComponent";

const jobType = [
  { label: "Trucking Job", value: "Trucking Job" },
  { label: "Trucking & Material Job", value: "Trucking & Material Job" },
];

const { Option } = Select;

class TruckingMaterial extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      boardView: true,
      selectedRowKeys: [],
      visible: false,
      isModalVisible: false,
      addressState: "",
      zipCode: "",
      leadsData: "",
      funnelData: [],
      citySearch: "",
      totalLeadsData: [],
      searchingData: [],
      filterData: [],
      latitude: "",
      longitude: "",
      filterVisible: false,
      companyData: [],
      filterCompanyData: [],
      filterDriverData: [],
      jobInfo: "",
      truckDropInfo: "",
      selectedMaterial: [],
      recordMaterailData: "",
      materialCheck: false,
      selectedMaterialType: "",
      pickUpAddress: "",
      dropAddress: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      roleOther: "",
      isContactVisible: false,
      contactsData: [],
      leadQuote: [],
      recordCompanyId: "",
      equipmentTypeDetails: [],
      getCompanyData: [],
      quantityType: "Ton",
      truckingCostType: "Ton",
      truckingPriceType: "Ton",
      totalPriceType: "Ton",
      materialCostType: "Ton",
      dropoffLatitude: "",
      dropoffLongitude: "",
      pickUpLatitude: "",
      pickupLongitude: "",
      filterPropTruckingData: {
        company: undefined,
        jobType: undefined,
        jobStatus: [
          "Job Lead",
          "Contacted",
          "Build Quote",
          "Quote Delivered",
          "Quote Accepted",
          "Dispatch Approved",
        ],
      },
      pickupDetails: [],
      loading: false,
      vendorData: [],
      initialMaterialCost: "",
      materialCostState: "",
      materialPriceState: "",
      lead: false,
      companyCheck: "",
      recordContactData: "",
      selectVendor: "",
      materialValue: "",
      categoriesList: [],
      materialCostPrice: "",
      materialTruckPrice: 0,
      milesData: "",
      duration: "",
      vendorName: "",
      quantity: 1,
      contactInfo: [],
      masterRoleId: "",
      masterTruckId: "",
      quarryContacts: [],
      rolesData: [],
      materialList: [],
      materialSelectedData: {},
      topVendorsData: [],
      vendorDetails: "",
      updateJobIdContact: [],
      modallLoading: false,
      customRate: false,
      materialCustomRate: false,
      materialCategory: {},
      openDropdown: "",
      salesCommission: "",
      selectedCompanyId: "",
      companyContacts: [],
      isNewContact: true,
      showMapComponent: false,
      showTab: "listView",
      showSearchResult: false,
    };
  }

  componentDidMount = () => {
    let filterStorageData = JSON.parse(
      localStorage.getItem("filterStorageData")
    );
    document.title = "Twisted Nail - Trucking & Material";
    if (filterStorageData) {
      this.setState({
        totalLeadsData: filterStorageData.result,
        filterData: filterStorageData.result,
        searchingData: filterStorageData.result,
        filterPropTruckingData: filterStorageData.filter,
      });
    } else {
      this?.props?.actionFetchLeads();
    }
    this?.props?.actionFetchCompanies();
    this.funnelInfo();
    this.props.actiongetDropDownOptions();
    this.props.actionFetchQuarries();
    // this.updateRoles(); this method is used to create a new drop down list
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps?.topVendorsData?.success === false &&
      this.props?.topVendorsData?.success === true
    ) {
      if (this.props?.topVendorsData.vendors?.length > 0) {
        this.setState({
          topVendorsData: this.props?.topVendorsData.vendors,
          loading: false,
        });
      } else {
        this.setState({
          topVendorsData: this.props?.topVendorsData.vendors,
          loading: false,
        });
        message.info("No vendors found!");
      }
    }
    if (prevProps.context.dropDownId !== this.props.context.dropDownId) {
      this.funnelInfo();
      this?.props?.actionFetchLeads();
      this?.props?.actionFetchCompanies();
      this.props.actiongetDropDownOptions();
    }
    if (
      JSON.stringify(prevState?.totalLeadsData) ===
      JSON.stringify(this.state?.totalLeadsData)
    ) {
      let hashKeyValue = window.location.hash;
      if (hashKeyValue === "#quickJob") {
        hashKeyValue = "";
        this.props.history.push(`${window.location.pathname}`);
        this?.props?.actionFetchLeads();
      }
    }
    if (
      prevProps?.result?.success === false &&
      this.props?.result?.success === true
    ) {
      let getLeadsData = this.props?.result?.leads?.filter(
        (f) => !Array.isArray(f)
      );
      this.setState(
        {
          totalLeadsData: getLeadsData?.filter(
            (e) => e?.leadType && e?.leadType[0]?.id === "1"
          ),
          filterData: getLeadsData?.filter(
            (e) => e?.leadType && e?.leadType[0]?.id === "1"
          ),
          searchingData: getLeadsData?.filter(
            (e) => e?.leadType && e?.leadType[0]?.id === "1"
          ),
        },
        () => this.filterResultData(this.state?.filterPropTruckingData)
      );
    }
    if (
      prevProps?.companiesResult?.success === false &&
      this.props?.companiesResult?.success === true
    ) {
      this.setState({
        companyData: this.props?.companiesResult?.companies?.filter(
          (c) => !c.isOwnerOperator && c.company
        ),
      });
    }
    if (
      prevProps?.leadInfo?.success === false &&
      this.props?.leadInfo?.success === true
    ) {
      this.props.history?.push(`${window.location.pathname}#quickJob`);
      this?.props?.actionFetchLeads();
      this?.props?.actionFetchCompanies();
      this.setState({
        isCheckBox: false,
        isCheckBoxcontact: false,
        deliverySelectedAddress: "",
        pickupSelectedAddress: "",
        dropoffLatitude: "",
        dropoffLongitude: "",
        pickUpLatitude: "",
        pickupLongitude: "",
        contactInfo: [],
      });
    }

    if (
      prevProps?.leadUpdateInfo?.success === false &&
      this.props?.leadUpdateInfo?.success === true
    ) {
      this?.props?.actionFetchLeads();
      this?.props?.actionFetchCompanies();
      this.props.context.handleFilterInfo();
      this.setState({
        selectedMaterial: [],
        recordMaterialData: [],
        truckDropInfo: false,
        materialCostType: "Ton",
        truckingCostType: "Ton",
        quantityType: "Ton",
        totalPriceType: "Ton",
        showSpecificDate: false,
        iscalenderVisible: false,
        dropoffLatitude: "",
        dropoffLongitude: "",
        quarryContacts: this.state?.leadsData?.multipleContacts?.filter(
          (e) => e.materialId
        ),
        selectedContacts: this.state?.leadsData?.multipleContacts?.map(
          (o) => o?.id
        ),
        checkData: "",
      });
    }
    if (
      prevProps?.companyResult?.success === false &&
      this.props?.companyResult?.success === true
    ) {
      let quarryContacts = [];
      if (this.state?.leadsData?.multipleContacts?.length > 0) {
        quarryContacts =
          this.props.companyResult?.companyData?.contactDetails.concat(
            this.state?.leadsData?.multipleContacts
          );
      } else {
        quarryContacts = this.props.companyResult?.companyData?.contactDetails;
      }

      quarryContacts = quarryContacts?.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t?.id === value?.id)
      );

      this.setState({
        contactsData: quarryContacts,
        modallLoading: false,
      });
    }
    if (
      prevProps?.contactData?.success === false &&
      this.props?.contactData?.success === true
    ) {
      let data = this.state.selectedContacts;
      data.push(this.props.contactData.contact.data.id);
      let contactData = this.state.contactsData;
      contactData.push(this.props.contactData.contact.data);
      this.setState({
        selectedContacts: data,
        contactsData: contactData,
        modallLoading: false,
      });
      this?.props?.actionFetchLeads();
      // this.addContactToJob(this.props.contactData.contact.data);
      // this?.props?.actionFetchLeads();
      // this.props.actionFetchCompany(this.state?.recordCompanyId);
      // this.setState({
      //   loading: false,
      //   isCheckcontact: false,
      //   isContactVisible: false,
      //   roleOther: "",
      //   newRole: "",
      // });
    }
    if (
      prevProps?.dropDownOptions?.success === false &&
      this.props?.dropDownOptions?.success === true
    ) {
      let materialData = {};
      this.props.materialList[0]?.materialsNewList?.forEach(
        (eachMat, index) => {
          if (eachMat?.statusCode) {
            materialData[eachMat.category] = !Object.keys(
              materialData
            ).includes(eachMat.category)
              ? []
              : materialData[eachMat.category];
          }
        }
      );
      this.setState({
        materialCategory: materialData,
        rolesData: this.props.rolesData[0]?.rolesData?.sort((a, b) =>
          a?.value > b?.value ? 1 : -1
        ),
        materialList: this.props.materialList[0]?.materialsNewList,
      });
    }
    if (
      prevProps?.dropDownOptionsInfo?.success === false &&
      this.props?.dropDownOptionsInfo?.success === true
    ) {
      this.props.actiongetDropDownOptions();
    }
    if (
      prevProps?.quarriesData?.success === false &&
      this.props?.quarriesData?.success === true
    ) {
      this.setState({
        quarryDetails: this.props.quarriesData.quarryData,
        vendorData: this.props.quarriesData.quarryData,
      });
    }
  }

  onClickRefresh = () => {
    this?.props?.actionFetchLeads();
    this.funnelInfo();
  };

  onResetData = () => {
    this.setState({
      showSearchResult: false,
    });
    this?.props?.actionFetchLeads();
  };

  funnelInfo = () => {
    let funnel = [];
    const funnelId = "1";
    funnelStages.stages.map(
      (eachItem) =>
        parseInt(funnelId) === parseInt(eachItem.funnelId) &&
        eachItem.stage.map((stage) => {
          funnel.push(stage.content);
          this.setState({
            funnelData: funnel,
          });
          return true;
        })
    );
  };
  showMap = (filterData) => {
    const { showSearchResult } = this.state;
    return (
      <MapComponent
        filteredMapData={filterData}
        stageId
        truckingSearch={showSearchResult}
      />
    );
  };

  editCustomerModal = (record) => {
    this.setState({
      modallLoading: true,
      isModalVisible: true,
      leadsData: record,
      quarryContacts: record?.multipleContacts.filter((e) => e.materialId),
      selectedContacts: record?.multipleContacts?.map((o) => o?.id),
      jobInfo: record?.jobType,
      selectedTruckType: record?.truckType,
      selectedMaterial: record?.material ? record?.material : [],
      selectedMaterialType: "",
      pickUpAddress: record?.pickup,
      dropAddress: record?.dropoff,
      selectedDropOffAddress: record?.dropoff,
      recordCompanyId: record?.companyId,
      leadQuote: record?.multipleContacts,
      updateJobIdContact: record?.multipleContacts,
      dropoffLatitude: record?.dropOffLatLng?.lat,
      dropoffLongitude: record?.dropOffLatLng?.lng,
      openDropdown: record?.taxExempt
        ? record?.taxExempt
        : "Material is quoted as",
    });
    this.props.actionFetchCompany(record?.companyId);
  };

  deleteRecordData = (record) => {
    this.props.actionDeleteLead(record?.id);
  };

  filterResultData = (data) => {
    const { filterData } = this.state;
    if (data["jobType"]?.length === 0) {
      delete data["jobType"];
    }
    let resultData = this.findAllFilterData(filterData, data);
    this.setState({
      totalLeadsData: resultData,
      filterPropTruckingData: data,
      searchingData: resultData,
    });

    let filterInfo = {};
    const { jobStatus, ...employeeRest } = data;
    filterInfo = employeeRest;
    Object.keys(filterInfo)?.forEach((key) => {
      if (filterInfo[key] === undefined) {
        delete filterInfo[key];
      }
    });
    localStorage.setItem(
      "filterStorageData",
      JSON.stringify({
        result: resultData,
        filter: data,
        filterShowData: filterInfo,
      })
    );
    this.closeFilter();
  };

  findAllFilterData = (data, filterCriteria) => {
    return data.filter((item) => {
      if (
        (!filterCriteria.company ||
          filterCriteria.company === item.companyId) &&
        (!filterCriteria.jobType ||
          filterCriteria.jobType.includes(item.jobType)) &&
        (!filterCriteria.salesPersonName ||
          filterCriteria.salesPersonName === item.salesPersonId) &&
        (!filterCriteria.jobStatus ||
          filterCriteria.jobStatus.includes(item.stageId))
      ) {
        return true;
      }
      return false;
    });
  };

  closeFilter = () => {
    this.setState({
      visible: false,
      filterVisible: false,
    });
  };

  onClearFilter = () => {
    localStorage.removeItem("filterStorageData", JSON.stringify({}));
    this.setState(
      {
        filterVisible: false,
        visible: false,
        filterPropTruckingData: {
          company: undefined,
          jobType: undefined,
          jobStatus: [
            "Job Lead",
            "Contacted",
            "Build Quote",
            "Quote Delivered",
            "Quote Accepted",
            "Dispatch Approved",
          ],
        },
      },
      () => this?.props?.actionFetchLeads()
    );
  };

  onSelectChange = (data, value) => {
    this.setState({
      selectedRowKeys: data,
      vendorDetails: value[0],
      initialMaterialCost: value[0]?.material[0]?.price,
      materialCostPrice: value[0]?.material[0]?.price,
      materialPriceState: "",
      truckingCost: "",
    });
    this.formRef.current.setFieldsValue({
      quantity: "",
      totalPrice: "",
      markUp: "",
      truckingCost: "",
      truckingPrice: "",
      materialCost: value[0]?.material[0]?.price,
    });
  };

  onFinish = (values) => {
    const {
      leadsData,
      truckDropInfo,
      selectedTruckType,
      selectedMaterial,
      updateJobIdContact,
      dropoffLatitude,
      dropoffLongitude,
      selectedDropOffAddress,
      contactsData,
      quarryContacts,
      salesCommission,
    } = this.state;

    this.setState({
      isModalVisible: false,
    });

    let commonData = {
      company: leadsData?.company,
      companyName: leadsData?.companyName,
      contactperson: "",
      contactId: "",
      jobName: values["jobName"],
      jobType: values["jobType"],
      dropoff: selectedDropOffAddress,
      dropOffLatLng: {
        lat: dropoffLatitude,
        lng: dropoffLongitude,
      },
      multipleContacts: updateJobIdContact?.filter((e) =>
        e?.jobId?.includes(leadsData?.id)
      ),
      truckType:
        truckDropInfo === true ? values["truckOther"] : selectedTruckType,
      notes: values["notes"],
      durationOfJob: values["durationOfJob"],
      salesPersonId: values["salesPerson"],
      salesCommission,
    };
    let leadData = {
      entityData: {
        ...commonData,
        material: selectedMaterial,
        taxExempt: values.taxExempt,
        taxIncluded: values.taxIncluded,
        taxStatus: values.taxIncluded === "Tax Added" ? true : false,
      },
    };

    let quarryContactsData = [];
    let quarryContact = {};

    contactsData?.filter((e) => {
      quarryContactsData = quarryContacts?.filter((q) => q.id !== e.id);
      return e;
    });
    quarryContact["entityData"] = quarryContactsData;

    this.updateMultipleContacts(
      quarryContact,
      leadsData?.id,
      leadData,
      quarryContactsData
    );
  };

  updateMultipleContacts = (
    quarryContact,
    id,
    leadData,
    quarryContactsData
  ) => {
    const { updateJobIdContact, leadQuote } = this.state;
    if (
      JSON.stringify(updateJobIdContact) !== JSON.stringify(leadQuote) &&
      quarryContactsData?.length > 0
    ) {
      quarryContact["entityData"] =
        quarryContactsData.concat(updateJobIdContact);
      leadData["entityData"]["multipleContacts"] = quarryContact["entityData"];
      this.updateMultipleContact(quarryContact, id, leadData);
    } else if (
      JSON.stringify(updateJobIdContact) !== JSON.stringify(leadQuote)
    ) {
      let updateData = {
        entityData: updateJobIdContact,
      };
      this.updateMultipleContact(updateData, id, leadData);
    } else if (quarryContactsData?.length > 0) {
      leadData["entityData"]["multipleContacts"] =
        quarryContactsData.concat(updateJobIdContact);
      this.addMultipleContact(quarryContact, id, leadData);
    } else {
      this.props.actionUpdateJob(id, leadData);
    }
  };

  updateMultipleContact = (quarryContact, id, leadData) => {
    updateTotal("updateMultipleContacts", quarryContact)
      .then((result) => {
        if (result.success) {
          this.props.actionUpdateJob(id, leadData);
        }
      })
      .catch((err) => console.log(err));
  };

  addMultipleContact = (quarryContact, id, leadData) => {
    add("addMultipleContacts", quarryContact)
      .then((result) => {
        if (result.success) {
          this.props.actionUpdateJob(id, leadData);
        }
      })
      .catch((err) => console.log(err));
  };

  updateJobMaterials = (data) => {
    this.setState({ loading: true });
    const { leadsData } = this.state;
    let materialsData = leadsData?.material;
    // console.log(materialsData, data);
    materialsData?.map((m) => {
      data?.map((d) => {
        if (d?.materialId === m?.id) {
          m["distance"] = d?.distance;
          m["duration"] = d?.duration;
        }
        return true;
      });
      return true;
    });
    // console.log(materialsData);
    let finalData = {
      entityData: {
        material: materialsData,
      },
    };
    this.props.actionUpdateJob(leadsData?.id, finalData);
  };

  getAddress = (address, type) => {
    if (type === "pickup") {
      this.setState({
        selectedPickupAddress: address.label,
      });
    } else if (type === "dropOff") {
      this.setState({
        selectedDropOffAddress: address.label,
      });
    } else {
      this.setState({ selectedAddress: address.label });
    }
  };

  handleSelect = (address, type) => {
    const { dropoffLatitude, dropoffLongitude, lead } = this.state;
    this.setState({
      citySearch: address.label,
    });
    let cityValue = "";
    let stateValue = "";
    let zipCodeValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        getLatLng(results[0]).then(({ lat, lng }) => {
          if (type === "pickup") {
            if (!lead) {
              this.setState({
                loading: true,
              });
              let url =
                "https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&destinations=" +
                [dropoffLatitude, dropoffLongitude] +
                "&origins=" +
                [lat, lng] +
                "&key=AIzaSyB781k9fNf61hnxE3A6cvcascwhAhIVIK4";
              let milesData = {
                entityData: {
                  url,
                },
              };
              add("getMiles", milesData)
                .then((res) =>
                  this.setState({
                    pickUpLatitude: lat,
                    pickupLongitude: lng,
                    milesData: res.data.distance,
                    duration: res.data.duration,
                    loading: false,
                  })
                )
                .catch((err) => {
                  this.setState({
                    loading: false,
                  });
                  console.log(err, "err");
                });
            } else {
              this.setState({
                pickUpLatitude: lat,
                pickupLongitude: lng,
              });
            }
          } else if (type === "dropOff") {
            this.setState({ dropoffLatitude: lat, dropoffLongitude: lng });
          } else {
            this.setState({ latitude: lat, longitude: lng });
          }
        });
        const geoIdData = results[0];
        this.setState({
          geoIdData,
        });
        let address = results[0].address_components;
        for (let i = 0; i < address?.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
          if (address[i].types[0] === "postal_code") {
            zipCodeValue = address[i].long_name;
          }
        }
        this.setState({
          city: cityValue,
          addressState: stateValue,
          zipCode: zipCodeValue,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  handleOk = () => {
    this.setState({ isModalVisible: false });
  };

  searchResult = (searchValue) => {
    const { filterData } = this.state;
    let searchFilter = [];
    if (typeof searchValue === "number") {
      searchFilter = filterData.filter(
        (e) =>
          e?.jobNumber.toString().indexOf(searchValue.toString()) > -1 ||
          parseInt(e["phone"]?.replace(/[^+0-9]/g, ""), 10) === searchValue
      );
    } else if (typeof searchValue === "string") {
      searchValue = searchValue.toLowerCase();
      searchFilter = filterData.filter(
        (e) =>
          e["company"]?.toLowerCase().indexOf(searchValue) > -1 ||
          e["companyName"]?.toLowerCase().indexOf(searchValue) > -1
      );
    }
    this.setState({
      totalLeadsData: searchFilter,
      showSearchResult: true,
    });
    this.showMap(searchFilter);
  };

  onFinishMaterial = (values) => {
    const {
      recordMaterialData,
      materialSelectedData,
      quantityType,
      jobInfo,
      pickUpLatitude,
      pickupLongitude,
      truckingPriceType,
      selectedPickupAddress,
      truckingCostType,
      materialCostType,
      totalPriceType,
      // pickupDetails,
      initialMaterialCost,
      duration,
      milesData,
      vendorDetails,
      topVendorsData,
      materialTruckPrice,
      truckingCost,
      materialPriceState,
      quantity,
      diameter,
      selectedMaterial,
    } = this.state;

    // let materialData = this.state?.selectedMaterial?.filter(
    //   (m) => m?.id === materialSelectedData?.id
    // );

    if (jobInfo === "Trucking Job") {
      let truckingData = {
        entityData: {
          id:
            materialSelectedData["id"] === undefined
              ? uuidv4()
              : materialSelectedData["id"],
          truckingPrice:
            parseFloat(values["truckingPrice"]) ||
            recordMaterialData?.truckingPrice,
          truckingPriceType:
            truckingPriceType || recordMaterialData?.truckingPriceType,
          truckingCost:
            parseFloat(values["truckingCost"]) ||
            recordMaterialData?.truckingCost,
          truckingCostType:
            truckingCostType || recordMaterialData?.truckingCostType,
          pickUpAddress:
            selectedPickupAddress || recordMaterialData?.pickUpAddress,
          pickUpLatLng: {
            lat: pickUpLatitude || recordMaterialData?.pickUpLatLng?.lat,
            lng: pickupLongitude || recordMaterialData?.pickUpLatLng?.lng,
          },
          duration: duration || recordMaterialData?.duration,
          milesData: milesData || recordMaterialData?.milesData,
        },
      };
      // console.log(truckingData, "truckingData");
      if (
        truckingData.entityData.pickUpAddress !== undefined &&
        truckingData.entityData.pickUpAddress !== ""
      ) {
        this.addMaterialOrTruckInfo(truckingData);
        // let submitData = {
        //   entityData: {
        //     origin: {
        //       lat: pickUpLatitude,
        //       long: pickupLongitude,
        //     },
        //     filterData: [],
        //     radius: 1,
        //   },
        // };
        // add("getDist/quarryLocations", submitData).then((result) => {
        //   const { leadsData } = this.state;
        //   if (result.success) {
        //     let contactsData = [];
        //     if (result?.data?.length > 0) {
        //       result.data[0]?.contactDetails?.map((c) => {
        //         contactsData.push({
        //           companyId: leadsData?.companyId,
        //           jobId: leadsData?.id,
        //           firstName: c["firstName"],
        //           lastName: c["lastName"],
        //           phone: c["phone"],
        //           email: c["email"],
        //           notes: c["notes"],
        //           contactRole: c["contactRole"],
        //           isPrimaryContact: false,
        //         });
        //         return true;
        //       });
        //       let quarryContact = {};
        //       quarryContact["entityData"] = contactsData;
        //       console.log(quarryContact);
        //       add("addMultipleContacts", quarryContact)
        //         .then((result) => {
        //           if (result.success) {
        //             console.log(result);
        //           }
        //         })
        //         .catch((err) => console.log(err));
        //     }
        //   }
        // });
      } else {
        message.error("Please Enter PickUp Location");
      }
    } else {
      if (truckingPriceType === totalPriceType) {
        // if (materialData?.length > 0) {
        //   message.info("Given Material Is Already Selected");
        //   return true;
        // } else {
        if (topVendorsData?.length === 0) {
          message.error("Please Find Nearest Vendors!");
          return true;
        }
        if (topVendorsData?.length !== 0 && vendorDetails === "") {
          message.error("Please select at least one Vendor!");
          return true;
        }
        let materialMinDiameter = 0,
          materialMaxDiameter = 0,
          materialDiameter = 0,
          filteredData = [];

        let minD = values["materialMinDiameter"]?.replace('"', "");
        let maxD = values["materialMaxDiameter"]?.replace('"', "");
        let mD = values["materialDiameter"]?.replace('"', "");

        if (diameter) {
          materialMinDiameter =
            minD?.split(" ")?.length !== 2 && String(parseInt(minD)) !== minD
              ? this.DecimalToMixedFraction(minD)
              : minD;

          materialMaxDiameter =
            maxD?.split(" ")?.length !== 2 && String(parseInt(maxD)) !== maxD
              ? this.DecimalToMixedFraction(maxD)
              : maxD;
        } else {
          materialDiameter =
            mD?.split(" ")?.length !== 2 && String(parseInt(mD)) !== mD
              ? this.DecimalToMixedFraction(mD)
              : mD;
        }

        selectedMaterial?.forEach((c) => {
          if (
            c.vendorId === vendorDetails?.vendorId &&
            c.id === materialSelectedData?.id &&
            (c.diameter
              ? c["materialMinDiameter"] === materialMinDiameter &&
                c["materialMaxDiameter"] === materialMaxDiameter
              : c["materialDiameter"] === materialDiameter)
          ) {
          } else {
            filteredData.push(c);
          }
        });
        if (
          filteredData.filter(
            (e) =>
              e.vendorId === vendorDetails?.id &&
              e.id === materialSelectedData["id"] &&
              (e.diameter
                ? e["materialMinDiameter"] === materialMinDiameter &&
                  e["materialMaxDiameter"] === materialMaxDiameter
                : e["materialDiameter"] === materialDiameter)
          )?.length > 0
        ) {
          message.error("You can't select same material with same vendor!");
          return true;
        }
        let materialData = {
          entityData: {
            id: materialSelectedData["id"],
            label: materialSelectedData["label"],
            value:
              vendorDetails?.material && vendorDetails?.material[0]["value"],
            category: materialSelectedData["category"],
            categoryKey: materialSelectedData["categoryKey"],
            subShortName: materialSelectedData["subShortName"],
            materialShortValue:
              materialSelectedData["categoryKey"] +
              " - " +
              materialSelectedData["subShortName"] +
              " - " +
              materialSelectedData["label"].split(" - ")[1],
            quantity: quantity,
            quantityType: quantityType,
            vendorId: vendorDetails?.id || recordMaterialData?.vendorId,
            vendor: vendorDetails?.quarryName || recordMaterialData?.vendor,
            vendorTerms: vendorDetails?.accountTerms,
            vendorDiscount: vendorDetails?.payDiscount,
            vendorDiscountTerms: vendorDetails?.payDiscountTerms,
            pickUpAddress:
              vendorDetails?.address || recordMaterialData?.pickUpAddress,
            pickUpLatLng: {
              lat: vendorDetails?.lat || recordMaterialData?.pickUpLatLng?.lat,
              lng: vendorDetails?.lng || recordMaterialData?.pickUpLatLng?.lng,
            },
            materialCost: parseFloat(initialMaterialCost),
            materialCostType: materialCostType,
            totalPrice:
              parseFloat(values["totalPrice"]) ||
              recordMaterialData?.totalPrice,
            totalPriceType: totalPriceType,
            truckingPrice: materialTruckPrice,
            truckingPriceType: truckingPriceType,
            truckingCost: truckingCost,
            truckingCostType: truckingCostType,
            duration: vendorDetails?.duration || recordMaterialData?.duration,
            milesData: vendorDetails?.distance || recordMaterialData?.milesData,
            materialCostAtQuarryPerTon: parseFloat(initialMaterialCost),
            landedPrice:
              totalPriceType === truckingPriceType
                ? parseFloat(materialPriceState) + materialTruckPrice
                : "N/A",
            landedCost:
              materialCostType === truckingCostType &&
              initialMaterialCost &&
              truckingCost
                ? parseFloat(initialMaterialCost + truckingCost)?.toFixed(2)
                : "N/A",
            totalSale:
              materialPriceState && quantity && materialTruckPrice
                ? parseFloat(
                    (materialPriceState + materialTruckPrice) * quantity
                  )?.toFixed(2)
                : "N/A",
            totalExepense:
              initialMaterialCost && quantity && truckingCost
                ? parseFloat(
                    (initialMaterialCost + truckingCost) * quantity
                  )?.toFixed(2)
                : "",
            totalProfit:
              materialPriceState !== undefined &&
              materialPriceState !== "" &&
              materialTruckPrice !== undefined &&
              materialTruckPrice !== "" &&
              quantity !== undefined &&
              quantity !== "" &&
              initialMaterialCost !== undefined &&
              initialMaterialCost !== "" &&
              materialTruckPrice !== undefined &&
              materialTruckPrice !== "" &&
              truckingCost !== undefined &&
              truckingCost !== "" &&
              parseFloat(
                (materialPriceState + materialTruckPrice) * quantity -
                  (initialMaterialCost + truckingCost) * quantity
              )?.toFixed(2),
            totalProfitPercentage:
              materialPriceState !== undefined &&
              materialPriceState !== "" &&
              materialTruckPrice !== undefined &&
              materialTruckPrice !== "" &&
              quantity !== undefined &&
              quantity !== "" &&
              initialMaterialCost !== undefined &&
              initialMaterialCost !== "" &&
              materialTruckPrice !== undefined &&
              materialTruckPrice !== "" &&
              truckingCost !== undefined &&
              truckingCost !== "" &&
              (
                (((materialPriceState + materialTruckPrice) * quantity -
                  (initialMaterialCost + truckingCost) * quantity) /
                  ((materialPriceState + materialTruckPrice) * quantity)) *
                100
              )?.toFixed(2),
            materialDiameter: materialDiameter,
            materialStatus: values["materialStatus"],
            materialSupply: values["materialSupply"],
            materialTest: values["materialTest"],
            materialMaxDiameter: materialMaxDiameter,
            materialMinDiameter: materialMinDiameter,
            diameter,
          },
        };
        // console.log(materialData, "materialData");
        this.addMaterialOrTruckInfo(materialData);
        this.addVendorContact(vendorDetails);
        // }
      } else {
        message.error(
          "Please Select Same Units For Material Price & Trucking Price"
        );
      }
    }
  };

  addVendorContact = (vendorDetails) => {
    const { leadsData, quarryContacts } = this.state;
    let contactsData = quarryContacts?.length > 0 ? quarryContacts : [];
    vendorDetails?.contactDetails
      ?.filter((q) => q?.isPrimaryContact)
      ?.map((c) => {
        if (contactsData?.filter((t) => t.id === c.id)?.length > 0) {
          // console.log(contactsData, "contactsData");
        } else {
          contactsData.push({
            id: c["id"],
            materialId: c?.materialId,
            // companyId: leadsData?.companyId,
            quarryId: vendorDetails?.id,
            jobId: [leadsData?.id],
            firstName: c["firstName"],
            lastName: c["lastName"],
            phone: c["phone"],
            email: c["email"],
            notes: c["notes"],
            contactRole: c["contactRole"],
          });
        }
        return true;
      });
    this.setState({
      quarryContacts: contactsData,
    });
  };

  addMaterialOrTruckInfo = (data) => {
    this.setState({
      loading: false,
    });
    let materialInfo =
      this.state.selectedMaterial?.length > 0
        ? this.state?.selectedMaterial
        : [];
    materialInfo.push(data["entityData"]);
    this.setState({
      pickupDetails: [],
      selectedMaterial: materialInfo,
      loading: false,
      quantityType: "Ton",
      selectedMaterialType: "",
      materialSelectedData: "",
      quantity: "",
      recordMaterialData: "",
      materialPopup: false,
      truckingCostType: "Ton",
      totalPriceType: "Ton",
      materialCostType: "Ton",
      truckingPriceType: "Ton",
      selectedPickupAddress: "",
      pickUpLatitude: "",
      pickupLongitude: "",
      materialCostState: "",
      filterResultData: [],
      duration: "",
      milesData: "",
      selectVendor: "",
      initialMaterialCost: "",
      materialPriceState: "",
      materialTruckPrice: "",
      materialCostPrice: "",
    });
  };

  editMaterial = (data) => {
    // console.log(data, "data");
    const { jobInfo } = this.state;
    if (data !== "") {
      let filteredData = [];
      this.state?.selectedMaterial?.forEach((c) => {
        if (c.vendorId === data?.vendorId && c.id === data?.id) {
        } else {
          filteredData.push(c);
        }
      });
      this.setState({ selectedMaterial: filteredData });
    }
    if (jobInfo === "Trucking Job") {
      this.setState({
        materialPopup: true,
        recordMaterialData: data,
        selectedMaterialType: data?.category,
        materialSelectedData: data,
        truckingCostType: data?.truckingCostType
          ? data?.truckingCostType
          : "Ton",
        truckingPriceType: data?.truckingPriceType
          ? data?.truckingPriceType
          : "Ton",
        customRate: false,
        materialCustomRate: false,
      });
    } else {
      this.getVendorDetails(data);
    }
  };

  getVendorDetails = (data) => {
    // console.log(data);
    const { vendorData } = this.state;
    let vendor = vendorData?.filter((v) => v?.id === data?.vendorId);
    // console.log(vendor);
    let categoriesList = vendor[0]?.material?.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.category === value.category)
    );
    // console.log(categoriesList);
    this.setState(
      {
        diameter: data?.diameter,
        customRate: false,
        materialCustomRate: false,
        pickupDetails: vendor,
        vendorDetails: vendor[0],
        vendorName: vendor[0]?.quarryName,
        selectedRowKeys: [vendor[0]?.id],
        selectVendor: vendor,
        categoriesList,
        milesData: data?.milesData,
        duration: data?.duration,
        materialValue: data?.category,
        materialPopup: true,
        recordMaterialData: data,
        useMarkUp: data.quantity,
        selectedMaterialType: data?.category,
        materialSelectedData: data,
        quantityType: data?.quantityType ? data?.quantityType : "Ton",
        quantity: data?.quantity,
        truckingCost: data?.truckingCost,
        truckingCostType: data?.truckingCostType
          ? data?.truckingCostType
          : "Ton",
        truckingPriceType: data?.truckingPriceType
          ? data?.truckingPriceType
          : "Ton",
        materialCostType: data?.materialCostType
          ? data?.materialCostType
          : "Ton",
        totalPriceType: data?.totalPriceType ? data?.totalPriceType : "Ton",
        initialMaterialCost: data?.materialCost,
        materialPriceState: data?.totalPrice,
        materialTruckPrice: data?.truckingPrice,
        materialCostPrice: data?.materialCostAtQuarryPerTon,
      },
      () => {
        this.getTopVendors(data);
        this.onSelectMaterialCat(data?.category, "edit");
      }
    );
  };

  onCancelMaterial = () => {
    const { recordMaterialData } = this.state;
    // console.log(recordMaterialData);
    if (
      recordMaterialData !== "" &&
      recordMaterialData !== undefined &&
      recordMaterialData?.length !== 0
    ) {
      let materialInfo =
        this.state.selectedMaterial?.length > 0
          ? this.state?.selectedMaterial
          : [];
      materialInfo.push(recordMaterialData);
      this.setState({
        selectedMaterial: materialInfo,
        recordMaterialData: "",
        selectedMaterialType: "",
        materialPopup: false,
        filterResultData: [],
        finalMaterialData: [],
        materialCostState: "",
        initialMaterialCost: "",
        pickupDetails: [],
        materialPriceState: "",
        duration: "",
        milesData: "",
        selectVendor: "",
        materialCostPrice: "",
        materialSelectedData: "",
        vendorDetails: "",
        quantity: "",
        materialTruckPrice: "",
        truckingCost: "",
        customRate: false,
        materialCustomRate: false,
      });
      // this.formRef.current.setFieldsValue({ material: "", quantity: "" });
    } else {
      this.setState({ selectedMaterialType: "", materialPopup: false });
    }
  };

  deleteMaterial = (record) => {
    if (this.state.jobInfo === "Trucking Job") {
      let materialInfo = this.state.selectedMaterial.filter(
        (c) => c.id !== record.id
      );
      this.setState({
        selectedMaterial: materialInfo,
      });
      message.info("Trucking Details Deleted Successfully");
    } else {
      let filteredData = [];
      this.state?.selectedMaterial?.forEach((c) => {
        if (c.vendorId === record?.vendorId && c.id === record?.id) {
        } else {
          filteredData.push(c);
        }
      });
      this.setState({
        selectedMaterial: filteredData,
      });
      message.info("Material Deleted Successfully!");
    }
  };

  onSelectMaterialCat = (e, type) => {
    if (type === "edit") {
      const { materialList } = this.state;
      let materialsData = [];
      materialList?.map((newMat) => {
        if (e === newMat?.category) {
          materialsData.push(newMat);
        }
        return true;
      });
      this.setState({
        selectedMaterialType: e,
        finalMaterialData: materialsData,
      });
    } else {
      const { materialList } = this.state;
      let materialsData = [];
      materialList?.map((newMat) => {
        if (e === newMat?.category) {
          materialsData.push(newMat);
        }
        return true;
      });
      if (e) {
        this.formRef.current.setFieldsValue({ material: undefined, price: "" });
        this.setState({
          materialSelectedData: "",
          topVendorsData: [],
          vendorDetails: "",
          selectedRowKeys: [],
        });
      }
      this.setState({
        selectedMaterialType: e,
        finalMaterialData: materialsData,
      });
    }
  };

  onSelectMaterial = (e) => {
    // console.log("check");
    const { finalMaterialData } = this.state;
    finalMaterialData?.filter(
      (f) => f.id === e && this.setState({ materialSelectedData: f })
    );
    this.setState({
      topVendorsData: [],
      vendorDetails: "",
      selectedRowKeys: [],
    });
  };

  onChangeVendor = (e) => {
    // console.log(e);
    if (e !== undefined) {
      this.setState({
        vendorName: e,
        materialTruckPrice: "",
      });
    } else {
      this.setState({
        initialMaterialCost: "",
        materialPriceState: "",
        materialCostState: "",
        materialTruckPrice: "",
        selectVendor: [],
        milesData: "",
        duration: "",
        vendorName: "",
        categoriesList: [],
      });
      this.formRef.current.setFieldsValue({
        material: "",
        quantity: "",
        materialCategory: "",
        totalPrice: "",
        truckingPrice: "",
      });
    }
  };

  onChangeTrucksType = (values) => {
    // console.log("checked", values);
    this.setState({ selectedTruckType: values });
  };

  // updateRoles = () => {
  //   console.log("object");          this method is used to create a new drop down list
  //   let data = {
  //     entityData: {
  //       typeofDropDown: "ownerOperatorRoles",
  //       ownerRolesData: [],
  //       id: uuidv4(),
  //     },
  //   };

  //   add("dropDownOptions", data).then((result) => {
  //     if (result.success) {
  //       this.getTrucksType();
  //     }
  //   });
  // };

  onFinishContact = (values) => {
    const { leadsData, lead, selectedCompanyId, companyContacts } = this.state;
    this.setState({
      loading: true,
    });
    if (this.state.isCheckcontact) {
      this.updateRolesData(values["roleOther"], "roles");
    }
    if (lead) {
      const { recordContactData } = this.state;

      if (recordContactData !== "") {
        let filteredData = this.state.contactInfo.filter(
          (c) => c.id !== recordContactData?.id
        );
        this.setState({
          contactInfo: filteredData,
        });
      }

      let data = {
        entityData: {
          id: uuidv4(),
          contact_name:
            values["fName"] + " " + values["lName"] ||
            recordContactData?.contact_name,
          contact_number: values.phone,
          jobId: leadsData ? [leadsData?.id] : [],
          contact_email: values.email,
          firstName: values.fName,
          lastName: values.lName,
          phone: values.phone,
          email: values.email,
          contactRole:
            values["role"] === "Add New Role"
              ? values["roleOther"]
              : values["role"],
          company:
            this.state.companyCheck === ""
              ? ""
              : this.state.companyCheck || recordContactData?.company,
          companyId:
            selectedCompanyId !== "" && selectedCompanyId !== undefined
              ? selectedCompanyId
              : "",
          notes: values["notes"],
        },
      };
      let contactData = this.state.contactInfo?.filter(
        (c) => c?.email === values?.email && c?.phone === values?.phone
      );
      // console.log(values?.email, "values?.email", contactData);
      if (contactData?.length > 0) {
        message.info("Given Email and Phone Number already exists");
        this.setState({
          loading: false,
        });
        return true;
      } else {
        this.setState({
          isContactVisible: false,
        });
        add(appConfig["entityNames"]["checkContact"], data)
          .then((result) => {
            if (result.data.message === "success") {
              add(appConfig["entityNames"]["updateContact"], data);
              message.success("Contact Added Successfully!");
              let contactInfo =
                this.state.contactInfo?.length > 0
                  ? this.state.contactInfo
                  : [];
              if (recordContactData?.length > 0) {
                let filteredData = this.state.contactInfo?.filter(
                  (c) => c.id !== recordContactData?.id
                );
                filteredData.push(data["entityData"]);
                this.formRef.current.setFieldsValue({
                  contactperson: filteredData?.map((e) => e.id),
                });
                this.setState({
                  contactInfo: filteredData,
                  companyContacts: companyContacts.concat(filteredData),
                  loading: false,
                  isCheckcontact: false,
                  newRole: "",
                });
              }
              contactInfo.push(data["entityData"]);
              this.formRef.current.setFieldsValue({
                contactperson: contactInfo?.map((e) => e.id),
              });
              this.setState({
                companyContacts: companyContacts.concat(contactInfo),
                contactInfo,
                loading: false,

                isCheckcontact: false,
                newRole: "",
              });
            } else {
              message.info(result.data.message);
              this.setState({
                loading: false,
                isCheckcontact: false,
                newRole: "",
              });
              return true;
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
            });
            console.log(err);
          });
      }
    } else {
      this.setState({ isContactVisible: false });
      let commonData = {
        companyId: leadsData?.companyId,
        firstName: values["fName"],
        lastName: values["lName"],
        phone: values["phone"],
        email: values["email"],
        jobId: [leadsData?.id],
        contactRole:
          values["role"] === "Others" ? values["roleOther"] : values["role"],
      };
      let contactsData = {
        entityData: {
          ...commonData,
        },
      };
      // console.log(contactsData, "contactsData");
      this.addContact(contactsData);
    }
  };

  updateRolesData = (values) => {
    let rolesData = this.state.rolesData;
    rolesData.push({
      label: values,
      value: values.charAt(0).toUpperCase(),
      id: rolesData?.length + 1,
    });
    let data = {
      entityData: {
        rolesData,
        typeofDropDown: "roles",
      },
    };
    this.props.actionupdateDropDownOptions(this.props.rolesData[0]?.id, data);
  };

  addContact = (data) => {
    // console.log("AddContact Api");
    this.setState({
      modallLoading: true,
    });
    this.props.actionAddGenericContact(data);
  };

  // addContactToJob = (newContact) => {
  //   this.setState({ selectedContacts: [] });
  //   const { leadsData } = this.state;
  //   let existContactData = leadsData?.multipleContacts;
  //   existContactData.push(newContact);
  //   let data = {
  //     entityData: {
  //       multipleContacts: existContactData,
  //     },
  //   };
  //   this.props.actionUpdateJob(leadsData?.id, data);
  //   // console.log(data, "data");
  // };

  handleSelectContact = (e) => {
    const { contactsData, leadsData } = this.state;

    contactsData?.map((contact) => {
      if (e?.includes(contact?.id)) {
        if (
          contact["jobId"]?.length > 0 &&
          !contact["jobId"]?.includes(leadsData?.id)
        ) {
          contact["jobId"].push(leadsData?.id);
        } else {
          contact["jobId"] =
            contact["jobId"]?.length > 0 ? contact["jobId"] : [leadsData?.id];
        }
        return contact;
      } else {
        if (contact["jobId"]?.includes(leadsData?.id)) {
          contact["jobId"] = contact["jobId"]?.filter(
            (ct) => ct !== leadsData?.id
          );
        }
      }
      return contact;
    });

    this.setState({ contactId: e, updateJobIdContact: contactsData });
  };

  onSelectVendor = (value, option) => {
    const { vendorData, dropoffLatitude, dropoffLongitude } = this.state;
    this.setState({
      loading: true,
    });
    this.formRef.current.setFieldsValue({
      material: "",
      quantity: "",
      materialCategory: "",
      materialPriceState: "",
      totalPrice: "",
    });

    let data = vendorData?.filter((e) => e?.id === option?.key);
    let url =
      "https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&destinations=" +
      [dropoffLatitude, dropoffLongitude] +
      "&origins=" +
      [data[0]?.lat, data[0]?.lng] +
      "&key=AIzaSyB781k9fNf61hnxE3A6cvcascwhAhIVIK4";

    let milesData = {
      entityData: {
        url,
      },
    };
    let categoriesList = data[0]?.material?.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.category === value.category)
    );
    add("/getMiles", milesData)
      .then((res) =>
        this.setState({
          newVendor: value,
          selectVendor: data,
          categoriesList,
          selectedMaterialType: "",
          finalMaterialData: [],
          pickupDetails: data,
          milesData: res.data.distance,
          duration: res.data.duration,
          loading: false,
          materialPriceState: "",
        })
      )
      .catch((err) => {
        this.setState({
          loading: false,
        });
        console.log(err, "err");
      });
  };

  handleChangeVendor = (e) => {
    this.setState({
      newVendor: e,
      selectedMaterialType: "",
      finalMaterialData: [],
    });
  };

  onFinishLead = (values) => {
    const {
      contactInfo,
      dropoffLatitude,
      dropoffLongitude,
      selectedDropOffAddress,
      selectedPickupAddress,
      pickUpLatitude,
      pickupLongitude,
      isNewContact,
      // companyData,
      selectedCompanyId,
    } = this.state;

    // let existingCompanyName = companyData?.filter(
    //   (e) => e.company === values["customerAccount"]
    // );

    let commonData = {
      companyId:
        selectedCompanyId !== undefined && selectedCompanyId !== ""
          ? selectedCompanyId
          : "",
      company: values["customerAccount"],
      companyName: values["customerAccount"],
      multipleContacts:
        contactInfo?.length === 0
          ? [
              {
                firstName: values["customerAccount"],
                lastName: "",
                contactRole: "",
                phone: "",
                email: "",
                notes: "",
              },
            ]
          : contactInfo,
      leadType: [
        {
          id: "1",
          value: "Material Sales",
        },
      ],
      jobType: values?.jobType,
      jobName: "",
      dropoff: selectedDropOffAddress,
      dropOffLatLng: {
        lat: dropoffLatitude,
        lng: dropoffLongitude,
      },
      stageId: "Contacted",
      notes: values["notes"],
      isNewCompany:
        selectedCompanyId !== undefined && selectedCompanyId !== ""
          ? false
          : true,
      isNewContact,
    };
    let truckingData = {
      entityData: {
        ...commonData,
      },
    };
    let truckingWithPickUp = {
      entityData: {
        ...commonData,
        material: [
          {
            pickUpAddress: selectedPickupAddress,
            pickUpLatLng: {
              lat: pickUpLatitude,
              lng: pickupLongitude,
            },
          },
        ],
      },
    };
    let materialData = {
      entityData: {
        ...commonData,
        requiredMaterials: values["requiredMaterials"],
      },
    };

    // if (existingCompanyName?.length > 0) {
    //   message.error("This Company already exists!");
    //   this?.props?.actionFetchCompanies();
    //   return;
    // } else {
    if (values["jobType"] === "Trucking Job") {
      this.setState({ lead: false });
      // console.log(truckingData, "truckingData");
      if (selectedPickupAddress !== undefined && selectedPickupAddress !== "") {
        let url =
          "https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&destinations=" +
          [dropoffLatitude, dropoffLongitude] +
          "&origins=" +
          [pickUpLatitude, pickupLongitude] +
          "&key=AIzaSyB781k9fNf61hnxE3A6cvcascwhAhIVIK4";
        let milesData = {
          entityData: {
            url,
          },
        };
        add("getMiles", milesData)
          .then((res) => {
            if (res.success) {
              truckingWithPickUp["entityData"]["material"][0]["duration"] =
                res.data.duration;
              truckingWithPickUp["entityData"]["material"][0]["milesData"] =
                res.data.distance;
              // console.log(truckingWithPickUp);
              this.props.actionAddJob(truckingWithPickUp);
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
            });
            console.log(err, "err");
          });
      } else {
        this.setState({ lead: false });
        // console.log(truckingData);
        this.props.actionAddJob(truckingData);
      }
    } else {
      this.setState({ lead: false });
      // console.log(materialData, "materialData");
      this.props.actionAddJob(materialData);
    }
    // }
  };

  deleteContactInfo = (id) => {
    let contactInfo = this.state.contactInfo.filter((c) => c.id !== id);
    this.setState({
      contactInfo,
    });
    message.info("Contact Removed Successfully!");
  };

  editContactInfo = (data) => {
    this.setState({
      isContactVisible: true,
      recordContactData: data,
    });
  };

  resetvendorValues = () => {
    this.setState({
      topVendorsData: [],
      // vendorDetails: "",
      selectedRowKeys: [],
    });
  };

  DecimalToMixedFraction = (val) => {
    // eslint-disable-next-line no-eval
    const decimal = eval(val);
    // Split the decimal into whole and fractional parts
    const wholeNumber = Math.floor(decimal);
    const fractionalPart = decimal - wholeNumber;
    // Convert the fractional part to a fraction
    const fraction = this.decimalToFraction(fractionalPart);

    // Combine the whole number and fraction
    const mixedFraction =
      wholeNumber !== 0 ? `${wholeNumber} ${fraction}` : `${fraction}`;

    return mixedFraction;
  };

  decimalToFraction = (decimal) => {
    const EPSILON = 1.0e-9;
    let numerator = decimal;
    let denominator = 1;

    while (Math.abs(Math.round(numerator) - numerator) > EPSILON) {
      numerator *= 10;
      denominator *= 10;
    }

    const gcd = this.getGreatestCommonDivisor(numerator, denominator);
    numerator /= gcd;
    denominator /= gcd;

    return `${Math.floor(numerator)}/${Math.floor(denominator)}`;
  };

  getGreatestCommonDivisor = (a, b) => {
    if (b === 0) {
      return a;
    }
    return this.getGreatestCommonDivisor(b, a % b);
  };

  getTopVendors = (values) => {
    const {
      materialSelectedData,
      dropoffLatitude,
      dropoffLongitude,
      diameter,
    } = this.state;
    // console.log(materialSelectedData, "materialSelectedData");
    if (materialSelectedData !== "") {
      this.setState({
        loading: true,
        initialMaterialCost: materialSelectedData?.materialCost
          ? materialSelectedData?.materialCost
          : "",
      });

      let minD = values["materialMinDiameter"]?.replace('"', "");
      let maxD = values["materialMaxDiameter"]?.replace('"', "");
      let mD = values["materialDiameter"]?.replace('"', "");

      let materialMinDiameter = 0,
        materialMaxDiameter = 0,
        materialDiameter = 0;

      if (diameter) {
        materialMinDiameter =
          minD?.split(" ")?.length !== 2 && String(parseInt(minD)) !== minD
            ? this.DecimalToMixedFraction(minD)
            : minD;

        materialMaxDiameter =
          maxD?.split(" ")?.length !== 2 && String(parseInt(maxD)) !== maxD
            ? this.DecimalToMixedFraction(maxD)
            : maxD;
      } else {
        materialDiameter =
          mD?.split(" ")?.length !== 2 && String(parseInt(mD)) !== mD
            ? this.DecimalToMixedFraction(mD)
            : mD;
      }

      let data = {
        entityData: {
          material: materialSelectedData,
          dropOffLatLng: { lat: dropoffLatitude, lng: dropoffLongitude },
          materialDiameter: materialDiameter,
          materialStatus: values["materialStatus"],
          materialSupply: values["materialSupply"],
          materialTest: values["materialTest"],
          materialMaxDiameter: materialMaxDiameter,
          materialMinDiameter: materialMinDiameter,
          diameter,
        },
      };
      this.props?.actionFetchTopVendors(data);
    } else {
      message.error("Please Select Material!");
    }
  };

  handleCheckStatus = (e) => {
    this.setState({ diameter: e });
    this.formRef.current.setFieldsValue({
      materialDiameter: "",
      materialMinDiameter: "",
      materialMaxDiameter: "",
    });
  };

  handleTableChage = (props) => {
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    localStorage.setItem(
      "tableSizeRange",
      JSON.stringify({ ...tableSizeRange, truckingMaterial: props })
    );
  };

  onSelectCompanyName = (value, option) => {
    this.setState({
      companyCheck: value,
      selectedCompanyId: option.key,
    });
    this.getContactDetails(option.key);
  };

  handleChangeCompanyName = (value, option) => {
    this.setState({
      companyContacts: [],
    });
    if (option) {
      this.setState({
        selectedCompanyId: option.key,
      });
      this.getContactDetails(option.key);
    }
    this.setState({
      companyCheck: value,
    });
  };

  getContactDetails = (id) => {
    if (id) {
      list(appConfig["entityNames"]["updateContact"], { companyId: id }).then(
        (result) => {
          if (result.success) {
            this.setState({
              loading: false,
              companyContacts: result.data.filter((d) => d.isDriver !== true),
            });
          }
        }
      );
    }
  };

  render() {
    const filterInfo =
      JSON.parse(localStorage.getItem("filterStorageData"))?.filterShowData ||
      {};
    const {
      companyContacts,
      useMarkUp,
      materialCustomRate,
      materialCategory,
      customRate,
      modallLoading,
      truckingPriceType,
      totalPriceType,
      dropAddress,
      selectedRowKeys,
      citySearch,
      leadsData,
      isModalVisible,
      companyData,
      truckDropInfo,
      jobInfo,
      selectedMaterial,
      recordMaterialData,
      materialTruckPrice,
      totalLeadsData,
      isContactVisible,
      contactsData,
      newRole,
      selectedContacts,
      materialPopup,
      vendorData,
      initialMaterialCost,
      materialPriceState,
      lead,
      contactInfo,
      recordContactData,
      // vendorName,
      // categoriesList,
      // selectVendor,
      // materialValue,
      milesData,
      duration,
      showTab,
      rolesData,
      finalMaterialData,
      topVendorsData,
      quantityType,
      quantity,
      truckingCostType,
      materialCostType,
      truckingCost,
      diameter,
    } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const { loading, errorLoading } = this.props;

    const customerColumns = [
      {
        title: "JOB#",
        dataIndex: "jobNumber",
        key: "jobNumber",
        sorter: false,
        className: "col_styling",
        width: "6%",
        onCell: (record) => ({
          onClick: () => {
            this.props.history.push(`/app/jobs/job-profile/${record.id}`);
          },
        }),
        render: (jobNumber, record) => {
          return (
            <>
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="table-font-mobile"
              >
                {jobNumber}
              </span>
            </>
          );
        },
      },
      {
        title: "JOB NAME",
        dataIndex: "",
        key: "",
        sorter: false,
        className: "col_styling",
        width: "200px",
        onCell: (record) => ({
          onClick: () => {
            this.props.history.push(`/app/jobs/job-profile/${record.id}`);
          },
        }),
        render: (record) => {
          return (
            <>
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="table-font-mobile"
              >
                {record?.jobName ? record?.jobName : record?.jobNumber}
              </span>
            </>
          );
        },
      },
      {
        title: "JOB TYPE",
        dataIndex: "jobType",
        key: "jobType",
        sorter: false,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => {
            this.props.history.push(`/app/jobs/job-profile/${record.id}`);
          },
        }),
        render: (jobType, record) => {
          return (
            <>
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="table-font-mobile"
              >
                {jobType?.replace("Job", "")}
              </span>
            </>
          );
        },
      },
      {
        title: "CUSTOMER",
        dataIndex: "companyName",
        key: "companyName",
        sorter: false,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(
              `/app/customer-account-profile/${record.companyId}`
            ),
        }),
        render: (companyName, record) => {
          return (
            <>
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="table-font-mobile"
              >
                {companyName}
              </span>
            </>
          );
        },
      },
      {
        title: "CONTACT",
        dataIndex: "multipleContacts",
        key: "multipleContacts",
        sorter: false,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => {
            this.props.history.push(`/app/jobs/job-profile/${record.id}`);
          },
        }),
        render: (multipleContacts, record) => {
          return (
            <>
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="table-font-mobile"
              >
                {multipleContacts?.length > 0 &&
                  multipleContacts[0]?.firstName +
                    " " +
                    multipleContacts[0]?.lastName}
              </span>
            </>
          );
        },
      },
      {
        title: "CONTACT PHONE",
        dataIndex: "phone",
        key: "phone",
        sorter: false,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => {
            this.props.history.push(`/app/jobs/job-profile/${record.id}`);
          },
        }),
        render: (phone, record) => {
          return (
            <>
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="table-font-mobile"
              >
                {record?.multipleContacts?.length > 0 &&
                  record?.multipleContacts[0]?.phone}
              </span>
            </>
          );
        },
      },
      {
        title: "DATE ADDED",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: false,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => {
            this.props.history.push(`/app/jobs/job-profile/${record.id}`);
          },
        }),
        render: (createdAt, record) => {
          return (
            <>
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="table-font-mobile"
              >
                {moment(createdAt).format("MM/DD/YYYY")}
              </span>
            </>
          );
        },
      },
      {
        title: "LAST ACTIVITY",
        dataIndex: "updatedAt",
        key: "updatedAt",
        sorter: false,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => {
            this.props.history.push(`/app/jobs/job-profile/${record.id}`);
          },
        }),
        render: (updatedAt, record) => {
          return (
            <>
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="table-font-mobile"
              >
                {moment(updatedAt).format("MM/DD/YYYY")}
              </span>
            </>
          );
        },
      },
      {
        title: "STAGE",
        dataIndex: "stageId",
        key: "stageId",
        sorter: false,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => {
            this.props.history.push(`/app/jobs/job-profile/${record.id}`);
          },
        }),
        render: (stageId) => {
          return (
            <Button
              className="lead_ButtonStatus"
              style={{
                background: "transparent",
                borderRadius: "6px",
                borderColor: appConfig["STAGE_COLORS"][stageId],
                color: appConfig["STAGE_COLORS"][stageId],
                borderWidth: "1px",
                borderStyle: "solid",
                width: "153px",
              }}
            >
              <span
                className="col_styling table-font-mobile"
                style={{
                  fontSize: "14px",
                  fontFamily: "sans-serif",
                  margin: "0px auto",
                }}
              >
                {stageId === "Close Job" ? "Closed" : stageId}
              </span>
            </Button>
          );
        },
      },
      {
        title: "ACTIONS",
        dataIndex: "actions",
        key: "actions",
        sorter: false,
        className: "col_styling",
        width: "5%",
        fixed: "right",

        render: (actions, record) => {
          return (
            <>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  flexWrap: "nowrap",
                }}
              >
                <Col>
                  <Popover
                    placement="left"
                    content={
                      <Row className="popovergrid" style={{ maxWidth: "90px" }}>
                        {record?.stageId !== "Close Job" && (
                          <Col span={24}>
                            <Button
                              className="btn_edit"
                              onClick={() => {
                                this.props.history.push(
                                  `/app/updateJobDetails/${record.id}`
                                );
                                // this.editCustomerModal(record);
                              }}
                            >
                              <span className="span_edit">
                                <MdModeEditOutline
                                  className="table-font-mobile"
                                  style={{ marginRight: "5px" }}
                                />{" "}
                                Edit
                              </span>
                            </Button>
                          </Col>
                        )}
                        <Col span={24}>
                          <Button className="btn_edit">
                            <Popconfirm
                              title="Are you sure？"
                              okText="Yes"
                              cancelText="No"
                              onConfirm={() => this.deleteRecordData(record)}
                            >
                              <span className="span_edit">
                                <MdDelete style={{ marginRight: "5px" }} />{" "}
                                Delete
                              </span>
                            </Popconfirm>
                          </Button>
                        </Col>
                      </Row>
                    }
                  >
                    <EllipsisOutlined
                      style={{ fontSize: "30px", color: "grey" }}
                      className="action-ellipsis-button"
                    />
                  </Popover>
                </Col>
              </Row>
            </>
          );
        },
      },
    ];

    const topVendorsColumns = [
      {
        title: "VENDOR NAME",
        dataIndex: "quarryName",
        key: "quarryName",
        sorter: false,
        width: "40%",
        className: "col_style_quarry",
        render: (quarryName, record) => {
          return (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "fit-content",
              }}
              onClick={() => this.onSelectChange([record.id], [record])}
            >
              {quarryName}
            </Row>
          );
        },
      },
      {
        title: "RUN & RATE INFORMATION",
        dataIndex: "distance",
        key: "distance",
        sorter: false,
        width: "40%",
        className: "col_style_quarry",
        render: (distance, record) => {
          return (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "fit-content",
              }}
              onClick={() => this.onSelectChange([record.id], [record])}
            >
              {distance + "les, " + record?.duration}
            </Row>
          );
        },
      },
      {
        title: "COST / Ton",
        dataIndex: "material",
        key: "material",
        sorter: false,
        width: "20%",
        className: "col_style_quarry",
        render: (material, record) => {
          return (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "fit-content",
              }}
              onClick={() => this.onSelectChange([record.id], [record])}
            >
              {"$" + material[0]?.price?.toFixed(2)}
            </Row>
          );
        },
      },
    ];

    const selectAfter = (
      <Select
        className="select-after"
        defaultValue={quantityType}
        onChange={(e) => {
          this.setState({ quantityType: e });
        }}
      >
        <Option value="Ton">Ton</Option>
        <Option value="Load">Load</Option>
        <Option value="Yard">Yard</Option>
      </Select>
    );

    const selectAfterTruck = (
      <Select
        defaultValue={truckingCostType}
        className="select-after"
        onChange={(e) => {
          this.setState({ truckingCostType: e });
        }}
      >
        <Option value="Ton">Ton</Option>
        <Option value="Load">Load</Option>
        <Option value="Hour">Hour</Option>
      </Select>
    );

    const selectAfterTruckPrice = (
      <Select
        defaultValue={truckingPriceType}
        className="select-after"
        onChange={(e) => {
          this.setState({ truckingPriceType: e });
        }}
      >
        <Option value="Ton">Ton</Option>
        <Option value="Load">Load</Option>
        <Option value="Hour">Hour</Option>
      </Select>
    );

    const selectAfterMaterial = (
      <Select
        defaultValue={materialCostType}
        className="select-after"
        onChange={(e) => {
          this.setState({ materialCostType: e });
        }}
      >
        <Option value="Ton">Ton</Option>
        <Option value="Load">Load</Option>
        <Option value="Yard">Yard</Option>
      </Select>
    );

    const selectAfterCustomer = (
      <Select
        defaultValue={totalPriceType}
        className="select-after"
        onChange={(e) => {
          this.setState({ totalPriceType: e });
        }}
      >
        <Option value="Ton">Ton</Option>
        <Option value="Load">Load</Option>
        <Option value="Yard">Yard</Option>
      </Select>
    );

    const searchFields = [
      "jobNumber",
      "companyName",
      "jobName",
      // "multipleContacts",
    ];

    return (
      <>
        <Loading enableLoading={loading} />
        {totalLeadsData && (
          <div
            style={{
              background: "#fafafa",
            }}
          >
            <Row style={{ marginBottom: "1vw" }}>
              <Col
                xxl={{ span: 6 }}
                xl={{ span: 6 }}
                lg={{ span: 6 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                // span={12}
              >
                <Row>
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    className="quarrytoolgrid"
                  >
                    <SearchFunction
                      searchData={totalLeadsData}
                      tableName={"leads"}
                      getSearchData={this.searchResult}
                      onResetData={this.onResetData}
                      searchFields={searchFields}
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                xxl={{ span: 18 }}
                xl={{ span: 18 }}
                lg={{ span: 18 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                // span={12}
                style={{ marginLeft: "auto" }}
              >
                <Row className="trucking-material-layout" gutter={[8, 0]}>
                  <Col className="gridbtntool">
                    <Button
                      // className="truckingMaterial_buttons"
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() =>
                        this.setState({
                          lead: true,
                          jobInfo: "",
                          contactInfo: [],
                        })
                      }
                      style={{
                        borderRadius: "5px",
                        height: "40px",
                        margin: "auto 0px",
                      }}
                    >
                      <span>Job</span>
                    </Button>
                  </Col>

                  <Col className="gridbtntool">
                    <Button
                      // className="button_Crm"
                      style={{
                        borderRadius: "5px",
                        height: "40px",
                      }}
                      onClick={() => {
                        this.setState({ visible: true });
                      }}
                    >
                      <Row>
                        <Col>
                          <span
                            style={{
                              color:
                                Object.keys(filterInfo)?.length > 0
                                  ? "red"
                                  : "#5a6067",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                            className="table-font-mobile"
                          >
                            {Object.keys(filterInfo)?.length > 0
                              ? `${
                                  "Filter (" + Object.keys(filterInfo)?.length
                                })`
                              : "Filter"}
                          </span>
                        </Col>
                        <Col>
                          <IoFilterSharp
                            style={{
                              fontSize: "22px",
                              marginLeft: "8px",
                              marginTop: "2px",
                              color:
                                Object.keys(filterInfo)?.length > 0
                                  ? "red"
                                  : "#5a6067",
                            }}
                            className="trucking-filter-icon"
                          />
                        </Col>
                      </Row>
                    </Button>
                  </Col>
                  <Col className="gridbtntool">
                    <Row
                      style={{
                        background: "#cecece",
                        height: "40px",
                        borderRadius: "6px",
                      }}
                    >
                      <Col style={{ marginTop: "4px" }}>
                        <Button
                          style={{
                            border: "none",
                            borderRadius: "5px",
                            padding: "5px 8px",
                            margin: "0px 5px",
                            background:
                              this.state.showTab === "listView"
                                ? "#fff"
                                : "transparent",
                            boxShadow: "none",
                            outline: "none",
                          }}
                          onClick={() => {
                            this.setState({ showTab: "listView" });
                            window.screen.width >= 1200 &&
                              this.props.context.updateSideronCollapse(false);
                          }}
                        >
                          <UnorderedListOutlined
                            style={{
                              fontSize: "22px",
                              color:
                                this.state.showTab === "listView"
                                  ? "#586370"
                                  : "#586370",
                            }}
                          />
                        </Button>
                      </Col>
                      <Col style={{ marginTop: "4px" }}>
                        <Button
                          style={{
                            border: "none",
                            borderRadius: "5px",
                            padding: "5px 8px",
                            margin: "0px 5px",
                            background:
                              this.state.showTab === "boardView"
                                ? "#fff"
                                : "transparent",
                            boxShadow: "none",
                            outline: "none",
                          }}
                          onClick={() => {
                            this.setState({ showTab: "boardView" });
                            window.screen.width >= 1200 &&
                              this.props.context.updateSideronCollapse(false);
                          }}
                        >
                          <RiDashboardLine
                            style={{
                              fontSize: "22px",
                              background:
                                this.state.showTab === "boardView"
                                  ? "#fff"
                                  : "transparent",
                            }}
                          />
                        </Button>
                      </Col>
                      <Col style={{ marginTop: "4px" }}>
                        <Button
                          style={{
                            border: "none",
                            borderRadius: "5px",
                            padding: "5px 8px",
                            margin: "0px 5px",
                            background:
                              this.state.showTab === "showMapComponent"
                                ? "#fff"
                                : "transparent",
                            boxShadow: "none",
                            outline: "none",
                          }}
                          onClick={() =>
                            this.setState({ showTab: "showMapComponent" })
                          }
                        >
                          <FaMap
                            style={{
                              fontSize: "22px",
                              color:
                                this.state.showTab === "showMapComponent"
                                  ? "#586370"
                                  : "#586370",
                            }}
                          />
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            {showTab === "boardView" ? (
              <Row style={{ overflow: "auto" }}>
                <Col
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  style={{ overflow: "auto" }}
                >
                  {errorLoading ? (
                    <ErrorView refreshCrm={this.onClickRefresh} type="CRM" />
                  ) : (
                    <Card
                      bodyStyle={{
                        padding: "0px",
                        overflowX: "auto",
                        height: "100%",
                      }}
                      style={{
                        background: "#eff1f3",
                        minHeight: "70vh",
                        // height: "100%",
                      }}
                      className="kanbanscrollCard"
                    >
                      <DraggableCard
                        headingData={this.state.funnelData}
                        contentData={totalLeadsData}
                        requestMethod={this.onResetData}
                        truckTypeData={this.props.context.trucksData}
                        vendorData={vendorData}
                        leads
                      />
                    </Card>
                  )}
                </Col>
              </Row>
            ) : showTab === "showMapComponent" ? (
              <Row>
                <Col
                  xxl={{ span: 24, offset: 0 }}
                  xl={{ span: 24, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  // style={{ height: "75vh" }}
                  className="twistednailmap"
                >
                  {this.showMap(totalLeadsData)}
                  {/* <MapComponent truckData={filterData} truck /> */}
                </Col>
              </Row>
            ) : (
              <Row data-aos="fade-up">
                <Col
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  {errorLoading ? (
                    <ErrorView refreshCrm={this.onClickRefresh} type="CRM" />
                  ) : (
                    <Card bodyStyle={{ padding: "0px", overflowX: "auto" }}>
                      <BaseTable
                        className="customertablegrp table_laptopscreen table-font-mobile"
                        columnsData={customerColumns}
                        source={totalLeadsData.sort((a, b) =>
                          parseInt(a.jobNumber).valueOf() <
                          parseInt(b.jobNumber).valueOf()
                            ? 1
                            : -1
                        )}
                        // rowSelection={rowSelection}
                        total={totalLeadsData?.length}
                        rowKey={(record) => record.id}
                        handleTableChage={this.handleTableChage}
                        sizeRange={
                          window.screen.width > 1023
                            ? JSON.parse(localStorage.getItem("tableSizeRange"))
                                ?.truckingMaterial
                            : totalLeadsData?.length
                        }
                        pagination={window.screen.width > 1023 ? true : false}
                      />
                      {/* {selectedRowKeys?.length > 0 && (
                        <FooterData count={selectedRowKeys?.length} deactive />
                      )} */}
                    </Card>
                  )}
                </Col>
              </Row>
            )}

            {isModalVisible && (
              <BaseModal
                className="crmform_modal antmodal_grid headermodal"
                title={`Update Job Details #${leadsData?.jobNumber}`}
                onCancel={() => {
                  this.setState({
                    isModalVisible: false,
                    truckDropInfo: false,
                    jobEditModal: false,
                    isCheckBoxMaterial: false,
                    isCheckBox: false,
                    selectedMaterial: [],
                    isCheckBoxTruckType: false,
                    isContactVisible: false,
                    equipmentTypeDetails: [],
                    companyCheck: "",
                    materialPopup: false,
                    salesCommission: "",
                  });
                }}
                loading={loading}
                visible={isModalVisible}
                destroyOnClose={true}
                formId="customerReport"
                width={"85%"}
              >
                <Form
                  onFinish={this.onFinish}
                  initialValues={{ remember: true }}
                  id="customerReport"
                  // ref={this.formRef}
                >
                  <Loading enableLoading={modallLoading} />
                  <Row gutter={[24, 0]}>
                    {/* <Col
                      xxl={{
                        span: 6,
                      }}
                      xl={{
                        span: 6,
                      }}
                      lg={{
                        span: 6,
                      }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      // span= 12}
                      // offset={0}
                    >
                      <span className="title_changes">Customer Name</span>
                      <Form.Item
                        name="company"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Your Company Name!",
                          },
                        ]}
                        initialValue={leadsData?.company}
                      >
                        <Input
                          className="form-input"
                          placeholder="Please Enter Your Company Name"
                        />
                      </Form.Item>
                    </Col> */}
                    <>
                      <Col
                        xxl={{ span: 6 }}
                        xl={{ span: 6 }}
                        lg={{ span: 6 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <span className="title_changes">Job Name</span>
                        <Form.Item
                          name={"jobName"}
                          initialValue={
                            leadsData?.jobName
                              ? leadsData?.jobName
                              : leadsData?.jobNumber
                          }
                        >
                          <Input
                            placeholder="Please Enter Job Name"
                            className="form-input"
                          />
                        </Form.Item>
                      </Col>

                      {/* {selectedContacts?.length > 0 && ( */}
                      <Col
                        xxl={{ span: 6 }}
                        xl={{ span: 6 }}
                        lg={{ span: 6 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <span className="title_changes">Contact Name</span>
                        <div className="contactname-multiple">
                          <Form.Item
                            name="contactperson"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Your Contact Name!",
                              },
                            ]}
                            initialValue={selectedContacts}
                          >
                            <Select
                              size="large"
                              allowClear
                              mode="multiple"
                              showSearch
                              className="select_multiplequarytol"
                              style={{
                                height: "auto !important",
                                width: "100% !important",
                              }}
                              placeholder="Please Select A Contact Name"
                              // style={{ height: "40px" }}
                              onChange={(e) => this.handleSelectContact(e)}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                            >
                              {contactsData?.map((eachItem) => (
                                <Option
                                  key={eachItem?.id}
                                  value={eachItem?.id}
                                  className="option_style"
                                >
                                  {eachItem?.firstName +
                                    " " +
                                    eachItem?.lastName}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                          {!isContactVisible && (
                            <Tooltip
                              title="Add Contact"
                              overlayStyle={{
                                backGroundColor: "#000",
                                color: "#000",
                              }}
                            >
                              <div
                                style={{
                                  // width: "100%",
                                  fontWeight: "500",
                                  // height: "25px",
                                  fontSize: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: "auto",
                                  padding: "8px",
                                  position: "absolute",
                                  top: "0px",
                                  right: "0px",
                                  height: "100%",
                                  borderLeft: "1px solid #ddd",
                                  zIndex: "+9",
                                  cursor: "pointer",
                                }}
                                onClick={
                                  () =>
                                    // this.state.companyCheck !== ""
                                    //   ?
                                    this.setState({
                                      isContactVisible: true,
                                      jobContactData: "",
                                    })
                                  // : message.info(
                                  //     "Please Enter Your Company Name First!"
                                  //   )
                                }
                              >
                                {/* ADD NEW CONTACT */}
                                <UsergroupAddOutlined />
                              </div>
                            </Tooltip>
                          )}
                        </div>
                      </Col>
                      {/* )} */}

                      <Col
                        xxl={{ span: 6 }}
                        xl={{ span: 6 }}
                        lg={{ span: 6 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <span className="title_changes">Job Type</span>
                        <Form.Item
                          name="jobType"
                          rules={[
                            {
                              required: true,
                              message: "Please Select Job Type!",
                            },
                          ]}
                          initialValue={leadsData?.jobType}
                        >
                          <Select
                            disabled={
                              leadsData?.stageId === "Dispatch Approved"
                                ? true
                                : leadsData?.quoteStatus === "Accepted"
                                ? true
                                : false
                            }
                            size="large"
                            defaultValue={leadsData?.jobType}
                            showSearch
                            allowClear
                            className="select_Height"
                            // style={{ height: "40px" }}
                            onChange={(value) =>
                              this.setState({
                                jobInfo: value,
                                truckDropInfo: "",
                                isContactVisible: false,
                                selectedMaterial: [],
                              })
                            }
                            placeholder="Please Select A Job Type"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          >
                            {jobType.map((eachItem) => (
                              <Option
                                key={eachItem.label}
                                value={eachItem.label}
                                className="option_style"
                              >
                                {eachItem.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col
                        xxl={{ span: 6 }}
                        xl={{ span: 6 }}
                        lg={{ span: 6 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <span className="title_changes">Delivery Location</span>
                        <Form.Item
                          name="deliveryAddress"
                          rules={[
                            {
                              required: false,
                              message: "Please Enter Your Delivery Location",
                            },
                          ]}
                          style={{ marginBottom: "10px" }}
                        >
                          <GooglePlacesAutocomplete
                            className="form-input"
                            autocompletionRequest={{
                              componentRestrictions: {
                                country: ["us"],
                              },
                            }}
                            selectProps={{
                              //citySearch,
                              defaultInputValue: `${
                                leadsData?.dropoff ? leadsData?.dropoff : ""
                              }`,
                              placeholder: "Search Address...",
                              onChange: (address) => {
                                this.handleSelect(address, "dropOff");
                                this.getAddress(address, "dropOff");
                                this.setState({
                                  dropAddress: address.label,
                                });
                              },
                            }}
                          />
                        </Form.Item>
                      </Col>

                      {(jobInfo === "Trucking Job" ||
                        jobInfo === "Trucking & Material Job") && (
                        <Col
                          xxl={{ span: 12 }}
                          xl={{ span: 12 }}
                          lg={{ span: 12 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <span className="title_changes">
                            Requested Trucks
                          </span>
                          <Form.Item
                            name="truckType"
                            rules={[
                              {
                                required: false,
                                message: "Please Select Trucks",
                              },
                            ]}
                            style={{ marginBottom: "10px" }}
                          >
                            <div className="chckbox-truck">
                              <Checkbox.Group
                                style={{
                                  width: "100%",
                                }}
                                onChange={this.onChangeTrucksType}
                                defaultValue={leadsData?.truckType}
                              >
                                <Row style={{ width: "100%" }}>
                                  {!truckDropInfo && (
                                    <>
                                      {this.props.context.trucksData?.map(
                                        (t) => (
                                          <Col
                                            xxl={{ span: 8 }}
                                            xl={{ span: 8 }}
                                            lg={{ span: 8 }}
                                            md={{ span: 18 }}
                                            sm={{ span: 24 }}
                                            xs={{ span: 24 }}
                                          >
                                            <Checkbox
                                              value={t.value}
                                              key={t.value}
                                            >
                                              <span
                                                style={{
                                                  fontSize: "15px",
                                                  fontWeight: "700",
                                                  color: "rgb(88,99,112)",
                                                }}
                                              >
                                                {t.value}
                                              </span>
                                            </Checkbox>
                                          </Col>
                                        )
                                      )}
                                    </>
                                  )}
                                  {/* <Col span={8}>
                              <Checkbox
                                onChange={(e) =>
                                  this.setState({
                                    truckDropInfo: e.target.checked,
                                  })
                                }
                                value="Others"
                              >
                                Others
                              </Checkbox>
                            </Col> */}
                                </Row>
                              </Checkbox.Group>
                            </div>
                          </Form.Item>
                          {truckDropInfo && (
                            <Row>
                              <Col
                                xxl={{ span: 24, offset: 0 }}
                                xl={{ span: 24, offset: 0 }}
                                lg={{ span: 24, offset: 0 }}
                                md={{ span: 24, offset: 0 }}
                                sm={{ span: 24, offset: 0 }}
                                xs={{ span: 24, offset: 0 }}
                              >
                                <span className="title_changes">
                                  Truck Type
                                </span>
                                <Form.Item
                                  name="truckOther"
                                  rules={[
                                    {
                                      required: false,
                                      message: "Please Input Your Truck Type!",
                                    },
                                  ]}
                                  // initialValue={jobData?.truckType}
                                >
                                  <Input
                                    className="form-input"
                                    placeholder="Please add truck type"
                                  />
                                </Form.Item>
                              </Col>
                              <Col
                                xxl={{ span: 24 }}
                                xl={{ span: 24 }}
                                lg={{ span: 24 }}
                                md={{ span: 24 }}
                                sm={{ span: 24 }}
                                xs={{ span: 24 }}
                              >
                                <Checkbox
                                  checked={this.state.isCheckBox}
                                  onChange={this.onChangeCheckBox}
                                  style={{ marginBottom: "18px" }}
                                >
                                  <span
                                    className="checkBox_size"
                                    style={{ fontSize: "14px" }}
                                  >
                                    Add this new truck type to default list
                                  </span>
                                </Checkbox>
                              </Col>
                            </Row>
                          )}
                        </Col>
                      )}

                      <Col
                        xxl={{ span: 6 }}
                        xl={{ span: 6 }}
                        lg={{ span: 6 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <span className="title_changes">Sales Person</span>
                        <Form.Item
                          name="salesPerson"
                          rules={[
                            {
                              required: true,
                              message: "Please Select Sales Person!",
                            },
                          ]}
                          initialValue={leadsData?.salesPersonId}
                        >
                          <Select
                            size="large"
                            defaultValue={leadsData?.salesPersonId}
                            showSearch
                            allowClear
                            className="select_Height"
                            onChange={(val, option) =>
                              this.setState({
                                salesCommission: option.salesCommission,
                              })
                            }
                            placeholder="Please Select A Sales Person"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          >
                            {this.props.context.salesPersonsData?.map(
                              (eachItem) => (
                                <Option
                                  key={eachItem.id}
                                  value={eachItem.id}
                                  salesCommission={eachItem.salesCommission}
                                  className="option_style"
                                >
                                  {eachItem?.firstName +
                                    " " +
                                    eachItem?.lastName}
                                </Option>
                              )
                            )}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col
                        xxl={{ span: 6 }}
                        xl={{ span: 6 }}
                        lg={{ span: 6 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <span className="title_changes">Notes</span>
                        <Form.Item
                          name="notes"
                          rules={[
                            {
                              required: false,
                              message: "Please Enter Your Notes!",
                            },
                          ]}
                          initialValue={leadsData?.notes}
                        >
                          <TextArea
                            rows={4}
                            style={{
                              width: "100%",
                              height: "76px",
                              borderColor: "#d6d6d6",
                            }}
                            className="form-input"
                            placeholder="Please Enter Your Notes"
                          />
                        </Form.Item>
                      </Col>
                      {jobInfo === "Trucking & Material Job" && (
                        <>
                          <Col
                            xxl={{ span: 6 }}
                            xl={{ span: 6 }}
                            lg={{ span: 6 }}
                            md={{ span: 24 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <span className="title_changes">
                              Tax Exempt Status
                            </span>
                            <Form.Item
                              name="taxExempt"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Select Tax exempt!",
                                },
                              ]}
                              initialValue={
                                leadsData?.taxExempt
                                  ? leadsData?.taxExempt
                                  : "Material is quoted as"
                              }
                            >
                              {
                                <Select
                                  size="large"
                                  showSearch
                                  allowClear
                                  className="select_Height"
                                  onChange={(e) =>
                                    this.setState({ openDropdown: e })
                                  }
                                  placeholder="Please Select A Tax exempt"
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                >
                                  <Option value="Tax Exempt">Tax Exempt</Option>
                                  <Option value="Material is quoted as">
                                    Not Tax Exempt
                                  </Option>
                                </Select>
                              }
                            </Form.Item>
                          </Col>
                          {this.state.openDropdown ===
                            "Material is quoted as" && (
                            <Col
                              xxl={{ span: 6 }}
                              xl={{ span: 6 }}
                              lg={{ span: 6 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <span className="title_changes">
                                Material is quoted as
                              </span>
                              <Form.Item
                                name="taxIncluded"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Select Tax exempt!",
                                  },
                                ]}
                                initialValue={
                                  leadsData?.taxIncluded
                                    ? leadsData?.taxIncluded
                                    : "Tax Added"
                                }
                              >
                                <Select
                                  size="large"
                                  showSearch
                                  allowClear
                                  className="select_Height"
                                  onChange={() =>
                                    this.setState({
                                      openDropdown: "Material is quoted as",
                                    })
                                  }
                                  placeholder=""
                                >
                                  <Option value="Tax Included">
                                    Tax Included{" "}
                                  </Option>
                                  <Option value="Tax Added">Pre-Tax</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                          )}
                        </>
                      )}
                    </>
                  </Row>

                  {jobInfo === "Trucking & Material Job" && (
                    <>
                      {leadsData?.requiredMaterials && (
                        <Row
                          xxl={{ span: 24 }}
                          xl={{ span: 24 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                          justify="center"
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <Col
                            className="title_changes"
                            style={{
                              fontSize: "17px",
                              fontWeight: "700",
                              textAlign: "center",
                              color: "rgb(88,99,112)",
                              // borderBottom: "1px solid #586370",
                            }}
                          >
                            Material Notes:
                          </Col>
                          <Col
                            className="title_changes"
                            style={{
                              fontSize: "17px",
                              fontWeight: "700",
                              marginLeft: "12px",
                            }}
                          >
                            {` ${leadsData?.requiredMaterials}`}
                          </Col>
                        </Row>
                      )}
                    </>
                  )}

                  {jobInfo !== "Trucking Job" && (
                    <Row>
                      <Row
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "10px",
                          marginTop: "15px",
                        }}
                      >
                        <span
                          style={{
                            marginBottom: "10px",
                            display: "flex",
                            fontSize: "17px",
                            fontWeight: "700",
                            color: "rgb(88,99,112)",
                            textTransform: "capitalize",
                          }}
                        >
                          Material Details:
                        </span>
                        <span>
                          <Button
                            icon={<PlusOutlined />}
                            style={{
                              // width: "100%",
                              // height: "40px",
                              backgroundColor: "#586370",
                              color: "#ffffff",
                              borderRadius: "3px",
                            }}
                            onClick={() => {
                              dropAddress !== "" && dropAddress !== undefined
                                ? this.setState({
                                    materialPopup: true,
                                    materialSelectedData: "",
                                    finalMaterialData: [],
                                    topVendorsData: [],
                                    vendorDetails: "",
                                    selectVendor: "",
                                    duration: "",
                                    milesData: "",
                                    quantityType: "Ton",
                                    totalPriceType: "Ton",
                                    materialCostType: "Ton",
                                    truckingPriceType: "Ton",
                                    truckingCostType: "Ton",
                                    customRate: false,
                                    diameter: false,
                                    materialCustomRate: false,
                                  })
                                : message.info(
                                    "Please Enter Your Delivery Location!"
                                  );
                            }}
                          >
                            Add Material
                          </Button>
                        </span>
                      </Row>
                      <Col
                        xxl={{ span: 24 }}
                        xl={{ span: 24 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                        style={{ overflowX: "auto" }}
                        className="twistedtable"
                      >
                        <table style={{ width: "100%" }}>
                          <thead>
                            <tr>
                              <th>Vendor</th>
                              <th>Material</th>
                              <th>Quantity</th>
                              <th>Material Cost</th>
                              <th>Material Price</th>
                              <th>Trucking Cost</th>
                              <th>Trucking Price</th>
                              <th>Landed Price</th>
                              <th>Actions</th>
                              {/* <th>Pickup Location</th>
                            <th>DropOff Location</th> */}
                              {/* <th></th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {selectedMaterial?.map((data, index) => (
                              <tr
                                onDoubleClick={() => this.editMaterial(data)}
                                style={{ cursor: "pointer" }}
                              >
                                <td>{data?.vendor}</td>
                                <td>{data?.value}</td>
                                <td>
                                  {data?.quantity && data?.quantityType
                                    ? `${data?.quantity} - ${data?.quantityType}`
                                    : ""}
                                </td>
                                <td>
                                  {data?.materialCost && data?.materialCostType
                                    ? `$ ${parseFloat(
                                        data?.materialCost
                                      )?.toLocaleString("us-EN", {
                                        minimumFractionDigits: 2,
                                      })} / ${data?.materialCostType}`
                                    : ""}
                                </td>
                                <td>
                                  {data?.totalPrice && data?.totalPriceType
                                    ? `$ ${parseFloat(
                                        data?.totalPrice
                                      )?.toLocaleString("us-EN", {
                                        minimumFractionDigits: 2,
                                      })} / ${data?.totalPriceType}`
                                    : ""}
                                </td>
                                <td>
                                  {data?.truckingCost && data?.truckingCostType
                                    ? `$ ${parseFloat(
                                        data?.truckingCost
                                      )?.toLocaleString("us-EN", {
                                        minimumFractionDigits: 2,
                                      })} / ${data?.truckingCostType}`
                                    : ""}
                                </td>
                                <td>
                                  {data?.truckingPrice &&
                                  data?.truckingPriceType
                                    ? `$ ${parseFloat(
                                        data?.truckingPrice
                                      )?.toLocaleString("us-EN", {
                                        minimumFractionDigits: 2,
                                      })} / ${data?.truckingPriceType}`
                                    : ""}
                                </td>
                                <td>
                                  {data?.landedPrice === "N/A"
                                    ? data?.landedPrice
                                    : `$ ${parseFloat(
                                        data?.landedPrice
                                      )?.toLocaleString("us-EN", {
                                        minimumFractionDigits: 2,
                                      })} / ${data?.totalPriceType}`}
                                </td>
                                {/* <td>{data?.pickUpAddress}</td>
                              <td> {data?.dropoff ? data?.dropoff : ""}</td> */}
                                <td style={{ display: "flex", margin: "6px" }}>
                                  <Button
                                    style={{ marginLeft: "6px", width: "70px" }}
                                    onClick={() => this.editMaterial(data)}
                                    type="primary"
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    style={{ marginLeft: "6px" }}
                                    onClick={() => this.deleteMaterial(data)}
                                    type="primary"
                                  >
                                    Delete
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  )}

                  {jobInfo === "Trucking Job" && (
                    <Row>
                      <Row
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "10px",
                          marginTop: "15px",
                        }}
                      >
                        <span
                          style={{
                            marginBottom: "10px",
                            display: "flex",
                            fontSize: "16px",
                            fontWeight: "500",
                            color: "rgb(88,99,112)",
                            textTransform: "capitalize",
                          }}
                        >
                          Trucking Details:
                        </span>
                        <span>
                          <Button
                            icon={<PlusOutlined />}
                            style={{
                              // width: "100%",
                              // height: "40px",
                              backgroundColor: "#586370",
                              color: "#ffffff",
                              borderRadius: "3px",
                            }}
                            onClick={() => {
                              dropAddress !== "" && dropAddress !== undefined
                                ? this.setState({
                                    materialPopup: true,
                                    materialSelectedData: "",
                                    finalMaterialData: [],
                                    selectVendor: "",
                                    duration: "",
                                    milesData: "",
                                    truckingCostType: "Ton",
                                    truckingPriceType: "Ton",
                                    recordMaterialData: "",
                                    customRate: false,
                                    materialCustomRate: false,
                                  })
                                : message.info(
                                    "Please Enter Your Delivery Location!"
                                  );
                            }}
                          >
                            Add Trucking Details
                          </Button>
                        </span>
                      </Row>
                      <Col
                        xxl={{ span: 24 }}
                        xl={{ span: 24 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                        style={{ overflowX: "auto" }}
                        className="twistedtable"
                      >
                        <table
                          className="lead-details-trucking-details"
                          style={{ width: "100%" }}
                        >
                          <thead>
                            <tr>
                              {/* <th>Vendor</th> */}
                              <th>Pickup Location</th>
                              {/* <th>Material</th> */}
                              <th>Trucking Cost</th>
                              <th>Trucking Price</th>
                              <th>Actions</th>
                              {/* <th></th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {selectedMaterial?.map((data, index) => (
                              <tr
                                onDoubleClick={() => this.editMaterial(data)}
                                style={{ cursor: "pointer" }}
                              >
                                <td>{data?.pickUpAddress}</td>
                                {/* <td>{data?.vendor}</td> */}
                                {/* <td>{data?.value}</td> */}
                                <td>
                                  {data?.truckingCost && data?.truckingCostType
                                    ? `$ ${parseFloat(
                                        data?.truckingCost
                                      )?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                      })} / ${data?.truckingCostType}`
                                    : ""}
                                </td>
                                <td>
                                  {data?.truckingPrice &&
                                  data?.truckingPriceType
                                    ? `$ ${parseFloat(
                                        data?.truckingPrice
                                      )?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                      })} / ${data?.truckingPriceType}`
                                    : ""}
                                </td>
                                <td>
                                  <Button
                                    style={{ marginLeft: "6px", width: "70px" }}
                                    onClick={() => this.editMaterial(data)}
                                    type="primary"
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    style={{ marginLeft: "6px" }}
                                    onClick={() => this.deleteMaterial(data)}
                                    type="primary"
                                  >
                                    Delete
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  )}
                </Form>
              </BaseModal>
            )}

            {isContactVisible && (
              <BaseModal
                className="crmform_modal antmodal_grid headermodal"
                title="Add New Contact"
                onCancel={() => {
                  this.setState({
                    isCheckBox: false,
                    isCheckBoxTruckType: false,
                    isContactVisible: false,
                    newRole: "",
                    isCheckcontact: false,
                  });
                }}
                loading={loading}
                visible={isContactVisible}
                destroyOnClose={true}
                formId="contact"
                width={
                  window.screen.width > "501" && window.screen.width <= "991"
                    ? "80%"
                    : "500px"
                }
              >
                <Form
                  style={{ width: "100%" }}
                  onFinish={this.onFinishContact}
                  id="contact"
                >
                  <Row gutter={[24, 0]}>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">First Name</span>
                      <Form.Item
                        name="fName"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Your First Name!",
                          },
                        ]}
                        initialValue={recordContactData?.firstName}
                      >
                        <Input
                          onChange={(e) =>
                            this.setState({
                              firstName: e.target.value,
                            })
                          }
                          autoFocus
                          className="form-input"
                          placeholder="Please Enter Your First Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Last Name</span>
                      <Form.Item
                        name="lName"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter your Last Name!",
                          },
                        ]}
                        initialValue={recordContactData?.lastName}
                      >
                        <Input
                          onChange={(e) =>
                            this.setState({
                              lastName: e.target.value,
                            })
                          }
                          className="form-input"
                          placeholder="Please Enter your Last Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xxl={{ span: 24 }}
                      xl={{ span: 24 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Phone</span>
                      <Form.Item
                        name="phone"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Your Phone!",
                          },
                        ]}
                        initialValue={recordContactData?.phone}
                      >
                        <NumberFormat
                          onChange={(e) =>
                            this.setState({ phone: e.target.value })
                          }
                          className="ant-input-number numberictest"
                          format="(###) ###-####"
                          style={{ width: "100%" }}
                          placeholder="Please Enter Your Phone"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xxl={{ span: 24 }}
                      xl={{ span: 24 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Email</span>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Your Email!",
                          },
                          {
                            type: "email",
                            message: "The Input Is Not Valid Email",
                          },
                        ]}
                        initialValue={recordContactData?.email}
                      >
                        <Input
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                          className="form-input"
                          placeholder="Please Enter Your Email"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xxl={{ span: 24 }}
                      xl={{ span: 24 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Role</span>
                      <Form.Item
                        name="role"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Your Role!",
                          },
                        ]}
                        initialValue={recordContactData?.contactRole}
                      >
                        <Select
                          size="large"
                          showSearch
                          allowClear
                          className="select_Height"
                          // style={{ height: "40px" }}
                          placeholder="Please Select A Role"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={(value) => {
                            this.setState({ newRole: value });
                            value === "Others" &&
                              message.info("Please Enter Role");
                          }}
                        >
                          {rolesData?.map((role) => (
                            <Option key={role.id} value={role.label}>
                              {role.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    {newRole === "Add New Role" && (
                      <Col
                        xxl={{ span: 24 }}
                        xl={{ span: 24 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <span className="title_changes">Add New Role</span>
                        <Form.Item
                          name="roleOther"
                          rules={[
                            {
                              required: false,
                              message: "Please Enter New Role!",
                            },
                          ]}
                        >
                          <Input
                            onChange={(e) =>
                              this.setState({
                                roleOther: e?.target?.value,
                              })
                            }
                            className="form-input"
                            placeholder="Add New Role"
                          />
                        </Form.Item>

                        <Checkbox
                          checked={this.state.isCheckcontact}
                          onChange={(e) =>
                            this.setState({
                              isCheckcontact: e.target.checked,
                            })
                          }
                          style={{ marginBottom: "18px" }}
                        >
                          <span
                            className="checkBox_size"
                            style={{ fontSize: "14px" }}
                          >
                            Add this new role to default list
                          </span>
                        </Checkbox>
                      </Col>
                    )}
                    <Col
                      xxl={{ span: 24 }}
                      xl={{ span: 24 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Notes</span>
                      <Form.Item
                        name="notes"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Your Notes!",
                          },
                        ]}
                        initialValue={
                          recordContactData?.notes
                            ? recordContactData?.notes
                            : ""
                        }
                      >
                        <TextArea
                          rows={4}
                          style={{
                            width: "100%",
                            height: "65px",
                            borderColor: "#d6d6d6",
                          }}
                          className="form-input"
                          placeholder="Please Enter Your Notes"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </BaseModal>
            )}

            {this.state.visible && (
              <Filter
                visible={this.state.visible}
                closeFilter={this.closeFilter}
                filterResultData={this.filterResultData}
                onClearFilter={this.onClearFilter}
                truckingMaterial
                filterPropTruckingData={this.state.filterPropTruckingData}
                JobData={this.state.filterData}
                company={companyData}
                salesPersonName={this.props.context.salesPersonsData}
              />
            )}

            {materialPopup && (
              <BaseModal
                title={
                  recordMaterialData?.length !== 0 && jobInfo === "Trucking Job"
                    ? "Update Trucking Details"
                    : recordMaterialData?.length !== 0 &&
                      jobInfo !== "Trucking Job"
                    ? "Update Material Details"
                    : jobInfo !== "Trucking Job"
                    ? "Add Material Details"
                    : "Add Trucking Details"
                }
                onCancel={this.onCancelMaterial}
                loading={loading}
                formId="material"
                width={
                  jobInfo === "Trucking & Material Job" ? "55%" : "35%"
                  // window.screen.width > "501" && window.screen.width <= "991"
                  //   ? "80%"
                  //   : "560px"
                }
                className="antmodal_grid headermodal"
                submitButton={
                  jobInfo === "Trucking & Material Job" &&
                  topVendorsData?.length === 0
                    ? "Find Nearest Vendors"
                    : recordMaterialData?.length !== 0 &&
                      jobInfo !== "Trucking Job"
                    ? "Update"
                    : "Add"
                }
                btnWidth={"fit-content"}
              >
                <Loading enableLoading={loading} />

                <Form
                  onFinish={
                    jobInfo === "Trucking & Material Job" &&
                    topVendorsData?.length === 0
                      ? this.getTopVendors
                      : this.onFinishMaterial
                  }
                  initialValues={{ remember: true }}
                  id="material"
                  ref={this.formRef}
                  style={{ width: "100%" }}
                >
                  <Row style={{ width: "100%" }} gutter={[24, 0]}>
                    {jobInfo === "Trucking & Material Job" && (
                      <>
                        <Col
                          xxl={{ span: 12 }}
                          xl={{ span: 12 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <span className="title_changes">
                            Material Categories
                          </span>
                          <Form.Item
                            name="materialCategory"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Your Material!",
                              },
                            ]}
                            initialValue={recordMaterialData?.category}
                          >
                            <Select
                              size="large"
                              showSearch
                              onClear={() =>
                                this.setState({
                                  materialSelectedData: "",
                                })
                              }
                              allowClear
                              className="select_Height"
                              placeholder="Please Select A Material"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              onChange={(value) => {
                                this.onSelectMaterialCat(value);
                                this.setState({
                                  materialValue: value,
                                  initialMaterialCost: "",
                                  materialPriceState: "",
                                  materialCostState: "",
                                  materialTruckPrice: "",
                                });
                                this.formRef.current.setFieldsValue({
                                  material: "",
                                  quantity: "",
                                  totalPrice: "",
                                  truckingPrice: "",
                                });
                              }}
                            >
                              {Object.keys(materialCategory).map((c, i) => (
                                <Option key={i} value={c}>
                                  {c}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col
                          xxl={{ span: 12 }}
                          xl={{ span: 12 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <span className="title_changes">
                            Material Subcategory
                          </span>
                          <Form.Item
                            name="material"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Your Material!",
                              },
                            ]}
                            initialValue={recordMaterialData?.label}
                          >
                            <Select
                              size="large"
                              showSearch
                              onClear={() =>
                                this.setState({
                                  materialSelectedData: "",
                                })
                              }
                              allowClear
                              className="select_Height"
                              placeholder="Please Select A Material"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              onChange={this.onSelectMaterial}
                            >
                              {finalMaterialData?.map(
                                (m) =>
                                  m.statusCode && (
                                    <Option key={m?.id} value={m?.id}>
                                      {m?.label}
                                    </Option>
                                  )
                              )}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col
                          span={12}
                          style={{ paddingLeft: "0px", paddingRight: "0px" }}
                        >
                          <Col span={24}>
                            <span className="title_changes">
                              Testing Status
                            </span>
                            <Form.Item
                              name="materialTest"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter Testing!",
                                },
                              ]}
                              initialValue={recordMaterialData?.materialTest}
                              style={{ marginBottom: "10px" }}
                            >
                              <Select
                                size="large"
                                showSearch
                                allowClear
                                className="select_Height"
                                // style={{ height: "40px" }}
                                placeholder="Please Select A testMaterial"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                onChange={this.resetvendorValues}
                              >
                                {QuarryJsonData?.testMaterial?.map((m, i) => (
                                  <Option key={i} value={m}>
                                    {m}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          {diameter ? (
                            <Col span={24} style={{ marginTop: "20px" }}>
                              <Row gutter={[12, 0]}>
                                <Col span={12}>
                                  <span className="title_changes">Min</span>
                                  <Form.Item
                                    name="materialMinDiameter"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please Enter Diameter!",
                                      },
                                    ]}
                                    initialValue={
                                      recordMaterialData?.materialMinDiameter
                                    }
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <Input
                                      style={{
                                        height: "40px",
                                        width: "100%",
                                      }}
                                      className="form-input"
                                      placeholder="Please enter diameter"
                                      onChange={this.resetvendorValues}
                                    />
                                    {/* <InputNumber
                                      formatter={(value) =>
                                        value !== "" ? `${value}"` : ""
                                      }
                                      style={{ width: "100%", height: "40px" }}
                                      placeholder="Please enter your diameter"
                                      className="form-input"
                                      onChange={this.resetvendorValues}
                                    /> */}
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <span
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <span className="title_changes">Max</span>
                                    <Switch
                                      checkedChildren={"Diameter"}
                                      unCheckedChildren={"Range"}
                                      onChange={(e) =>
                                        this.setState({ diameter: e })
                                      }
                                      checked={diameter}
                                    >
                                      Size Range
                                    </Switch>
                                  </span>
                                  <Form.Item
                                    name="materialMaxDiameter"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please Enter Diameter!",
                                      },
                                    ]}
                                    initialValue={
                                      recordMaterialData?.materialMaxDiameter
                                    }
                                    style={{ marginBottom: "10px" }}
                                  >
                                    {/* <InputNumber
                                      formatter={(value) =>
                                        value !== "" ? `${value}"` : ""
                                      }
                                      style={{ width: "100%", height: "40px" }}
                                      placeholder="Please enter your diameter"
                                      className="form-input"
                                      onChange={this.resetvendorValues}
                                    /> */}
                                    <Input
                                      style={{
                                        height: "40px",
                                        width: "100%",
                                      }}
                                      className="form-input"
                                      placeholder="Please enter diameter"
                                      onChange={this.resetvendorValues}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Col>
                          ) : (
                            <Col span={24} style={{ marginTop: "20px" }}>
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span className="title_changes">
                                  {" "}
                                  Material Diameter
                                </span>
                                <Switch
                                  checkedChildren={"Diameter"}
                                  unCheckedChildren={"Range"}
                                  onChange={(e) => this.handleCheckStatus(e)}
                                >
                                  Size Range
                                </Switch>
                              </span>
                              <Form.Item
                                name="materialDiameter"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Diameter!",
                                  },
                                ]}
                                initialValue={
                                  recordMaterialData?.materialDiameter
                                }
                                style={{ marginBottom: "10px" }}
                              >
                                {/* <InputNumber
                                  formatter={(value) =>
                                    value !== "" ? `${value}"` : ""
                                  }
                                  style={{ width: "100%", height: "40px" }}
                                  placeholder="Please enter your diameter"
                                  className="form-input"
                                  onChange={this.resetvendorValues}
                                /> */}
                                <Input
                                  style={{
                                    height: "40px",
                                    width: "100%",
                                  }}
                                  className="form-input"
                                  placeholder="Please enter diameter"
                                  onChange={this.resetvendorValues}
                                />
                              </Form.Item>
                            </Col>
                          )}
                        </Col>
                        <Col
                          span={12}
                          style={{ paddingLeft: "0px", paddingRight: "0px" }}
                        >
                          <Col span={24}>
                            <span className="title_changes">Washed Status</span>
                            <Form.Item
                              name="materialStatus"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter washed status!",
                                },
                              ]}
                              initialValue={recordMaterialData?.materialStatus}
                              style={{ marginBottom: "10px" }}
                            >
                              <Select
                                size="large"
                                showSearch
                                allowClear
                                className="select_Height"
                                // style={{ height: "40px" }}
                                placeholder="Please Select A statusMaterial"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                onChange={this.resetvendorValues}
                              >
                                {QuarryJsonData?.statusMaterial?.map((m, i) => (
                                  <Option key={i} value={m}>
                                    {m}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={24} style={{ marginTop: "20px" }}>
                            <span className="title_changes">Supply</span>
                            <Form.Item
                              name="materialSupply"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter supply!",
                                },
                              ]}
                              initialValue={recordMaterialData?.materialSupply}
                              style={{ marginBottom: "10px" }}
                            >
                              <Select
                                size="large"
                                showSearch
                                allowClear
                                className="select_Height"
                                // style={{ height: "40px" }}
                                placeholder="Please Select A Supply"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                onChange={this.resetvendorValues}
                              >
                                {QuarryJsonData?.supplyMaterial?.map((m, i) => (
                                  <Option key={i} value={m}>
                                    {m}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Col>
                        <Loading enableLoading={this.state.loading} />
                        {topVendorsData?.length > 0 && (
                          <Col
                            xxl={{ span: 24 }}
                            xl={{ span: 24 }}
                            lg={{ span: 24 }}
                            md={{ span: 24 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                            className="tiprotitle"
                            style={{ marginBottom: "0.5vw" }}
                          >
                            <BaseTable
                              columnsData={topVendorsColumns}
                              source={topVendorsData?.slice(0, 3)}
                              total={topVendorsData?.length}
                              className="table_laptopscreen"
                              pagination={false}
                              rowSelection={{
                                type: "radio",
                                ...rowSelection,
                              }}
                              rowKey={(record) => record.id}
                            />
                          </Col>
                        )}
                        {topVendorsData?.length > 0 && (
                          <>
                            <Col
                              xxl={{ span: 8 }}
                              xl={{ span: 8 }}
                              lg={{ span: 24 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <span className="title_changes">Use Markup</span>
                              <Form.Item
                                name="markUp"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Use Markup",
                                  },
                                ]}
                                initialValue={recordMaterialData?.quantity}
                              >
                                <InputNumber
                                  decimalSeparator="."
                                  precision={2}
                                  placeholder="Quantity"
                                  addonAfter={"%"}
                                  style={{
                                    height: "40px",
                                    width: "100%",
                                  }}
                                  onChange={(e) => {
                                    this.setState({
                                      useMarkUp: e,
                                      materialPriceState:
                                        e === initialMaterialCost &&
                                        e < initialMaterialCost
                                          ? 0
                                          : initialMaterialCost +
                                            initialMaterialCost * (e / 100),
                                    });
                                    this.formRef.current.setFieldsValue({
                                      totalPrice:
                                        e === 0
                                          ? initialMaterialCost
                                          : e
                                          ? initialMaterialCost +
                                            initialMaterialCost * (e / 100)
                                          : "",
                                    });
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            {/* <Col
                              xxl={{ span: 8 }}
                              xl={{ span: 8 }}
                              lg={{ span: 24 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <span className="title_changes">
                                Required QuantityFG
                              </span>
                              <Form.Item
                                name="quantity"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Quantity",
                                  },
                                ]}
                                initialValue={recordMaterialData?.quantity}
                              >
                                <InputNumber
                                  placeholder="Quantity"
                                  addonAfter={selectAfter}
                                  style={{
                                    height: "40px",
                                    width: "100%",
                                  }}
                                  onChange={(e) =>
                                    this.setState({
                                      quantity: e,
                                      // initialMaterialCost: materialCostPrice,
                                    })
                                  }
                                />
                              </Form.Item>
                            </Col> */}

                            <Col
                              xxl={{ span: 8 }}
                              xl={{ span: 8 }}
                              lg={{ span: 24 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <span className="title_changes">
                                Material Cost
                              </span>
                              <Form.Item
                                name="materialCost"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Material Cost!",
                                  },
                                ]}
                                initialValue={initialMaterialCost?.toLocaleString(
                                  2
                                )}
                                style={{ marginBottom: "0px" }}
                              >
                                <InputNumber
                                  className="material"
                                  formatter={(value) =>
                                    `${value}`.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )
                                  }
                                  parser={(value) =>
                                    value.replace(/\$\s?|(,*)/g, "")
                                  }
                                  // value={initialMaterialCost?.toLocaleString(2)}
                                  prefix={"$"}
                                  precision={2}
                                  decimalSeparator={"."}
                                  placeholder="Please Enter Material Cost"
                                  addonAfter={selectAfterMaterial}
                                  style={{
                                    height: "40px",
                                    width: "100%",
                                  }}
                                  readOnly={!materialCustomRate}
                                  onChange={(e) => {
                                    this.setState({
                                      materialCostPrice: e,
                                      initialMaterialCost: e,
                                      materialPriceState:
                                        useMarkUp === e && useMarkUp < e
                                          ? 0
                                          : e + e * (useMarkUp / 100),
                                    });
                                    this.formRef.current.setFieldsValue({
                                      totalPrice:
                                        useMarkUp === 0
                                          ? e
                                          : useMarkUp
                                          ? e + e * (useMarkUp / 100)
                                          : "",
                                    });
                                  }}
                                />
                              </Form.Item>
                              <div
                                style={{
                                  marginTop: "2px",
                                  marginBottom: "5px",
                                }}
                              >
                                <Checkbox
                                  onChange={(e) =>
                                    this.setState({
                                      materialCustomRate: e.target.checked,
                                    })
                                  }
                                >
                                  <span>Custom Rate</span>
                                </Checkbox>
                              </div>
                            </Col>
                            <Col
                              xxl={{ span: 8 }}
                              xl={{ span: 8 }}
                              lg={{ span: 24 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <span className="title_changes">
                                Material Price
                              </span>
                              <Form.Item
                                name="totalPrice"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Material Price!",
                                  },
                                ]}
                                initialValue={recordMaterialData?.totalPrice?.toLocaleString(
                                  2
                                )}
                              >
                                <InputNumber
                                  prefix={"$"}
                                  precision={2}
                                  formatter={(value) =>
                                    `${value}`.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )
                                  }
                                  parser={(value) =>
                                    value.replace(/\$\s?|(,*)/g, "")
                                  }
                                  decimalSeparator={"."} // value={quantity}
                                  placeholder="Please Enter Material Price"
                                  addonAfter={selectAfterCustomer}
                                  style={{
                                    height: "40px",
                                    width: "100%",
                                  }}
                                  onChange={(e) => {
                                    this.formRef.current.setFieldsValue({
                                      markUp:
                                        ((parseFloat(e) - initialMaterialCost) /
                                          initialMaterialCost) *
                                        100,
                                    });
                                    this.setState({ materialPriceState: e });
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              xxl={{ span: 8 }}
                              xl={{ span: 8 }}
                              lg={{ span: 24 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <span className="title_changes">
                                Required Quantity
                              </span>
                              <Form.Item
                                name="quantity"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Quantity",
                                  },
                                ]}
                                initialValue={recordMaterialData?.quantity}
                              >
                                <InputNumber
                                  placeholder="Quantity"
                                  addonAfter={selectAfter}
                                  style={{
                                    height: "40px",
                                    width: "100%",
                                  }}
                                  onChange={(e) =>
                                    this.setState({
                                      quantity: e,
                                      // initialMaterialCost: materialCostPrice,
                                    })
                                  }
                                />
                              </Form.Item>
                            </Col>
                            {/* <Col
                              xxl={{ span: 8 }}
                              xl={{ span: 8 }}
                              lg={{ span: 24 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <span className="title_changes">Use Markup</span>
                              <Form.Item
                                name="markUp"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Use Markup",
                                  },
                                ]}
                                initialValue={recordMaterialData?.quantity}
                              >
                                <InputNumber
                                  decimalSeparator="."
                                  precision={2}
                                  placeholder="Quantity"
                                  addonAfter={"%"}
                                  style={{
                                    height: "40px",
                                    width: "100%",
                                  }}
                                  onChange={(e) => {
                                    this.setState({
                                      useMarkUp: e,
                                      materialPriceState:
                                        e === initialMaterialCost &&
                                        e < initialMaterialCost
                                          ? 0
                                          : initialMaterialCost +
                                            initialMaterialCost * (e / 100),
                                    });
                                    this.formRef.current.setFieldsValue({
                                      totalPrice:
                                        e === 0
                                          ? initialMaterialCost
                                          : e
                                          ? initialMaterialCost +
                                            initialMaterialCost * (e / 100)
                                          : "",
                                    });
                                  }}
                                />
                              </Form.Item>
                            </Col> */}
                          </>
                        )}
                      </>
                    )}

                    {jobInfo === "Trucking Job" && (
                      <>
                        <Col
                          xxl={{ span: 24 }}
                          xl={{ span: 24 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <span className="title_changes">Pickup Location</span>
                          <Form.Item
                            name="pickup"
                            rules={[
                              {
                                required: false,
                                errorMessage: true,
                                message: "Please Enter Your Pickup Location",
                              },
                            ]}
                          >
                            <GooglePlacesAutocomplete
                              className="form-input"
                              autocompletionRequest={{
                                componentRestrictions: {
                                  country: ["us"],
                                },
                              }}
                              selectProps={{
                                citySearch,
                                defaultInputValue: `${
                                  recordMaterialData?.pickUpAddress
                                    ? recordMaterialData?.pickUpAddress
                                    : ""
                                }`,
                                placeholder: "Search Address...",
                                onChange: (address) => {
                                  this.handleSelect(address, "pickup");
                                  this.getAddress(address, "pickup");
                                  this.setState({
                                    pickUpAddress: address.label,
                                  });
                                },
                              }}
                            />
                          </Form.Item>
                        </Col>
                        {((milesData !== "" && milesData !== undefined) ||
                          recordMaterialData?.milesData !== undefined) && (
                          <Col
                            xxl={{ span: 24 }}
                            xl={{ span: 24 }}
                            lg={{ span: 24 }}
                            md={{ span: 24 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                            className="tiprotitle"
                            style={{ marginBottom: "0.5vw" }}
                          >
                            <span className="title_changes">
                              <b
                                style={{
                                  marginBottom: "10px",
                                  borderBottom: "1px solid gray",
                                }}
                              >
                                Run and Rate information:
                              </b>
                              {milesData?.text !== undefined ? (
                                <div>
                                  {milesData?.text + "les, " + duration?.text}
                                </div>
                              ) : (
                                recordMaterialData?.milesData?.text !==
                                  undefined && (
                                  <div>
                                    {recordMaterialData?.milesData?.text +
                                      "les, " +
                                      recordMaterialData?.duration?.text}
                                  </div>
                                )
                              )}
                            </span>
                          </Col>
                        )}
                      </>
                    )}

                    {((jobInfo === "Trucking & Material Job" &&
                      topVendorsData?.length > 0) ||
                      jobInfo !== "Trucking & Material Job") && (
                      <>
                        <Col
                          xxl={{ span: jobInfo === "Trucking Job" ? 12 : 8 }}
                          xl={{ span: jobInfo === "Trucking Job" ? 12 : 8 }}
                          lg={{ span: jobInfo === "Trucking Job" ? 12 : 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <span className="title_changes">Trucking Price</span>
                          <Form.Item
                            name="truckingPrice"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Trucking Price!",
                              },
                            ]}
                            initialValue={recordMaterialData?.truckingPrice?.toLocaleString(
                              2
                            )}
                          >
                            <InputNumber
                              prefix={"$"}
                              precision={2}
                              decimalSeparator={"."} // value={quantity}
                              placeholder="Please Enter Trucking Price"
                              addonAfter={selectAfterTruckPrice}
                              style={{
                                height: "40px",
                                width: "100%",
                              }}
                              // formatter={(value) =>
                              //   `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              // }
                              parser={(value) =>
                                value.replace(/\$\s?|(,*)/g, "")
                              }
                              // onChange={(e) =>
                              //   this.setState({ materialTruckPrice: e })
                              // }
                              onChange={(e) => {
                                this.setState({
                                  materialTruckPrice: e,
                                  truckingCost:
                                    parseFloat(e) -
                                    parseFloat(e) * (12.5 / 100),
                                });
                                this.formRef.current.setFieldsValue({
                                  truckingCost:
                                    parseFloat(e) -
                                    parseFloat(e) * (12.5 / 100),
                                });
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xxl={{ span: jobInfo === "Trucking Job" ? 12 : 8 }}
                          xl={{ span: jobInfo === "Trucking Job" ? 12 : 8 }}
                          lg={{ span: jobInfo === "Trucking Job" ? 12 : 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <span className="title_changes">Trucking Cost</span>
                          <Form.Item
                            name="truckingCost"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Trucking Cost!",
                              },
                            ]}
                            initialValue={recordMaterialData?.truckingCost?.toLocaleString(
                              2
                            )}
                            style={{ marginBottom: "0px" }}
                          >
                            <InputNumber
                              prefix={"$"}
                              precision={2}
                              // formatter={(value) =>
                              //   `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              // }
                              parser={(value) =>
                                value.replace(/\$\s?|(,*)/g, "")
                              }
                              decimalSeparator={"."} // value={quantity}
                              placeholder="Please Enter Trucking Cost"
                              addonAfter={selectAfterTruck}
                              style={{
                                height: "40px",
                                width: "100%",
                              }}
                              readOnly={!customRate}
                              onChange={(e) => {
                                this.setState({
                                  truckingCost: e,
                                  materialTruckPrice:
                                    parseFloat(e) +
                                    parseFloat(e) * (12.5 / 100),
                                });
                                this.formRef.current.setFieldsValue({
                                  truckingPrice:
                                    parseFloat(e) +
                                    parseFloat(e) * (12.5 / 100),
                                });
                              }}
                            />
                          </Form.Item>
                          <div
                            style={{ marginTop: "2px", marginBottom: "5px" }}
                          >
                            <Checkbox
                              onChange={(e) =>
                                this.setState({
                                  customRate: e.target.checked,
                                })
                              }
                            >
                              <span>Custom Rate</span>
                            </Checkbox>
                          </div>
                        </Col>
                      </>
                    )}
                    {jobInfo === "Trucking & Material Job" &&
                      topVendorsData?.length > 0 && (
                        <>
                          <Col
                            xxl={{ span: 8 }}
                            xl={{ span: 8 }}
                            lg={{ span: 24 }}
                            md={{ span: 24 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          ></Col>

                          <Col
                            xxl={{ span: 8 }}
                            xl={{ span: 8 }}
                            lg={{ span: 24 }}
                            md={{ span: 24 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <Row justify="space-between">
                              <Col span={12}>
                                <span className="title_changes">
                                  Landed Cost -{" "}
                                </span>
                              </Col>
                              <Col span={12}>
                                {materialCostType === truckingCostType &&
                                initialMaterialCost &&
                                truckingCost
                                  ? `$${parseFloat(
                                      initialMaterialCost + truckingCost
                                    )?.toLocaleString("us-EN", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                 / ${materialCostType}`
                                  : "N/A"}
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            xxl={{ span: 8 }}
                            xl={{ span: 8 }}
                            lg={{ span: 24 }}
                            md={{ span: 24 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <Row>
                              <Col span={12}>
                                <span className="title_changes">
                                  Landed Price -{" "}
                                </span>
                              </Col>
                              <Col span={12}>
                                {truckingPriceType === totalPriceType &&
                                materialPriceState &&
                                materialTruckPrice
                                  ? `$${parseFloat(
                                      materialPriceState + materialTruckPrice
                                    )?.toLocaleString("us-EN", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })} / ${truckingPriceType}`
                                  : "N/A"}
                              </Col>
                            </Row>
                          </Col>
                          <Divider
                            style={{
                              width: "100%",
                              border: "1px solid #dbdbdb",
                            }}
                          />
                          <Col
                            xxl={{ span: 8 }}
                            xl={{ span: 8 }}
                            lg={{ span: 24 }}
                            md={{ span: 24 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <Row>
                              <Col span={12}>
                                <span className="title_changes">
                                  Total Sale -{" "}
                                </span>
                              </Col>
                              <Col span={12}>
                                {truckingPriceType === totalPriceType &&
                                materialPriceState &&
                                quantity &&
                                materialTruckPrice
                                  ? `$${parseFloat(
                                      (materialPriceState +
                                        materialTruckPrice) *
                                        quantity
                                    )?.toLocaleString("us-EN", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}`
                                  : "N/A"}
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            xxl={{ span: 8 }}
                            xl={{ span: 8 }}
                            lg={{ span: 24 }}
                            md={{ span: 24 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <Row>
                              <Col span={12}>
                                <span className="title_changes">
                                  Total Expense -{" "}
                                </span>
                              </Col>
                              <Col span={12}>
                                {materialCostType === truckingCostType &&
                                initialMaterialCost &&
                                quantity &&
                                truckingCost
                                  ? `$${parseFloat(
                                      (initialMaterialCost + truckingCost) *
                                        quantity
                                    )?.toLocaleString("us-EN", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}`
                                  : "N/A"}
                              </Col>
                            </Row>
                          </Col>

                          <Col
                            xxl={{ span: 8 }}
                            xl={{ span: 8 }}
                            lg={{ span: 24 }}
                            md={{ span: 24 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <Row>
                              <Col span={12}>
                                <span className="title_changes">
                                  Total Profit (%) -
                                </span>
                              </Col>
                              <Col span={12}>
                                {truckingPriceType === totalPriceType &&
                                materialCostType === truckingCostType &&
                                materialPriceState !== undefined &&
                                materialPriceState !== "" &&
                                materialTruckPrice !== undefined &&
                                materialTruckPrice !== "" &&
                                quantity !== undefined &&
                                quantity !== "" &&
                                initialMaterialCost !== undefined &&
                                initialMaterialCost !== "" &&
                                materialTruckPrice !== undefined &&
                                materialTruckPrice !== "" &&
                                truckingCost !== undefined &&
                                truckingCost !== ""
                                  ? `$${parseFloat(
                                      (materialPriceState +
                                        materialTruckPrice) *
                                        quantity -
                                        (initialMaterialCost + truckingCost) *
                                          quantity
                                    )?.toLocaleString("us-EN", {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2,
                                    })} (${
                                      !isNaN(
                                        (
                                          (((materialPriceState +
                                            materialTruckPrice) *
                                            quantity -
                                            (initialMaterialCost +
                                              truckingCost) *
                                              quantity) /
                                            ((materialPriceState +
                                              materialTruckPrice) *
                                              quantity)) *
                                          100
                                        )?.toFixed(2)
                                      )
                                        ? (
                                            (((materialPriceState +
                                              materialTruckPrice) *
                                              quantity -
                                              (initialMaterialCost +
                                                truckingCost) *
                                                quantity) /
                                              ((materialPriceState +
                                                materialTruckPrice) *
                                                quantity)) *
                                            100
                                          )?.toFixed(2)
                                        : "0.00"
                                    }%)`
                                  : "N/A"}
                              </Col>
                            </Row>
                          </Col>
                        </>
                      )}
                  </Row>
                </Form>
              </BaseModal>
            )}

            {lead && (
              <BaseModal
                className="crmform_modal antmodal_grid headermodal"
                title="Add New Job"
                onCancel={() =>
                  this.setState({ lead: false, isNewContact: true })
                }
                loading={loading}
                visible={lead}
                destroyOnClose={true}
                formId="lead"
                width={
                  window.screen.width > "501" && window.screen.width <= "991"
                    ? "80%"
                    : "560px"
                }
              >
                <Form
                  style={{ width: "100%" }}
                  onFinish={this.onFinishLead}
                  id="lead"
                  ref={this.formRef}
                >
                  <Row gutter={[24, 0]}>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Job Type</span>
                      <Form.Item
                        name="jobType"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Job Type!",
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          allowClear
                          className="select_Height"
                          onChange={(value) =>
                            this.setState({
                              jobInfo: value,
                              pickUpAddress: "",
                              pickupLatitude: "",
                              pickupLongitude: "",
                              truckDropInfo: "",
                              isContactVisible: false,
                              selectedMaterial: [],
                            })
                          }
                          placeholder="Please Select A Job Type"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          {jobType.map((eachItem) => (
                            <Option
                              key={eachItem.label}
                              value={eachItem.label}
                              className="option_style"
                            >
                              {eachItem.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Customer Account</span>
                      <Form.Item
                        name="customerAccount"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Customer Account!",
                          },
                        ]}
                      >
                        <AutoComplete
                          allowClear
                          size="large"
                          showSearch
                          className="select_Height"
                          style={{
                            borderColor: "#d6d6d6",
                          }}
                          onSelect={this.onSelectCompanyName}
                          onChange={this.handleChangeCompanyName}
                          placeholder="Please Select A Customer Account"
                          filterOption={(inputValue, option) =>
                            option.value
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                        >
                          {companyData.map(
                            (eachItem) =>
                              eachItem.companyName && (
                                <Option
                                  key={eachItem.id}
                                  value={eachItem.companyName}
                                  className="option_style"
                                >
                                  {eachItem.companyName}
                                </Option>
                              )
                          )}
                        </AutoComplete>
                        {/* <Input
                          className="form-input"
                          placeholder={"Please Enter Customer Account"}
                          onChange={(e) =>
                            this.setState({
                              companyCheck: e.target.value,
                            })
                          }
                        /> */}
                      </Form.Item>
                    </Col>

                    <Col
                      xxl={{ span: 24, offset: 0 }}
                      xl={{ span: 24, offset: 0 }}
                      lg={{ span: 24, offset: 0 }}
                      md={{ span: 24, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      xs={{ span: 24, offset: 0 }}
                    >
                      <span className="title_changes">Contact Name</span>
                      <div className="contactname-multiple">
                        <Form.Item
                          name="contactperson"
                          rules={[
                            {
                              required: false,
                              message: "Please Enter Your Contact Name!",
                            },
                          ]}
                        >
                          <Select
                            size="large"
                            allowClear
                            mode="multiple"
                            showSearch
                            className="select_multiplequarytol"
                            style={{
                              height: "auto !important",
                              width: "100% !important",
                            }}
                            placeholder="Please Select A Contact Name"
                            onChange={(e, option) => {
                              this.setState({
                                contactInfo: option.map((e) => e.option),
                                isNewContact: false,
                              });
                            }}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          >
                            {companyContacts?.map((eachItem, i) => (
                              <Option
                                key={i}
                                value={eachItem?.id}
                                option={eachItem}
                                className="option_style"
                              >
                                {eachItem.firstName + " " + eachItem.lastName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>

                        {!isContactVisible && (
                          <Tooltip
                            title="Add Contact"
                            overlayStyle={{
                              backGroundColor: "#000",
                              color: "#000",
                            }}
                          >
                            <div
                              style={{
                                fontWeight: "500",
                                fontSize: "20px",
                                display: "flex",
                                alignItems: "center",
                                marginLeft: "auto",
                                padding: "8px",
                                position: "absolute",
                                top: "0px",
                                right: "0px",
                                height: "100%",
                                borderLeft: "1px solid #ddd",
                                zIndex: "+9",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                this.state.companyCheck !== ""
                                  ? this.setState({
                                      isContactVisible: true,
                                      recordContactData: "",
                                    })
                                  : message.info(
                                      "Please Enter Your Company Name First!"
                                    )
                              }
                            >
                              <UsergroupAddOutlined />
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    </Col>

                    <>
                      {contactInfo?.map((c) => (
                        <Col
                          xxl={{ span: 24 }}
                          xl={{ span: 24 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                          style={{ marginBottom: "1vw" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "nowrap",
                              alignItems: "center",
                            }}
                          >
                            {/* <span className="title_changes">
                                Contact Name:
                              </span> */}
                            <Popover
                              content={
                                (c?.phone || c?.email) && (
                                  <Row>
                                    <Col
                                      xxl={{ span: 24 }}
                                      xl={{ span: 24 }}
                                      lg={{ span: 24 }}
                                      md={{ span: 24 }}
                                      sm={{ span: 24 }}
                                      xs={{ span: 24 }}
                                    >
                                      <span>{c.phone}</span>
                                    </Col>
                                    <Col
                                      xxl={{ span: 24 }}
                                      xl={{ span: 24 }}
                                      lg={{ span: 24 }}
                                      md={{ span: 24 }}
                                      sm={{ span: 24 }}
                                      xs={{ span: 24 }}
                                    >
                                      <span
                                        style={{
                                          color: "black",
                                          display: "flex",
                                          flexWrap: "wrap",
                                          alignItems: "center",
                                          justifyContent: "left",
                                        }}
                                      >
                                        {c.email}
                                      </span>
                                    </Col>
                                  </Row>
                                )
                              }
                            >
                              <span
                                className="title_changes"
                                style={{
                                  margin: "0px 5px",
                                  wordBreak: "break-word",
                                  maxWidth: "270px",
                                  width: "100%",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textTransform: "capitalize",
                                  cursor: "pointer",
                                }}
                              >
                                {c.contactRole
                                  ? c.firstName +
                                    " " +
                                    c.lastName +
                                    ", " +
                                    c.contactRole
                                  : c.firstName + " " + c.lastName}
                              </span>
                            </Popover>
                            <Col>
                              <Popover
                                placement="left"
                                content={
                                  <Row
                                    className="popovergrid"
                                    style={{ maxWidth: "80px" }}
                                  >
                                    <Col
                                      xxl={{ span: 24 }}
                                      xl={{ span: 24 }}
                                      lg={{ span: 24 }}
                                      md={{ span: 24 }}
                                      sm={{ span: 24 }}
                                      xs={{ span: 24 }}
                                    >
                                      <Button
                                        className="btn_edit"
                                        onClick={() => this.editContactInfo(c)}
                                      >
                                        <span className="span_edit">
                                          <MdModeEditOutline
                                            style={{ marginRight: "5px" }}
                                          />
                                          Edit
                                        </span>
                                      </Button>
                                    </Col>
                                    <Col
                                      xxl={{ span: 24 }}
                                      xl={{ span: 24 }}
                                      lg={{ span: 24 }}
                                      md={{ span: 24 }}
                                      sm={{ span: 24 }}
                                      xs={{ span: 24 }}
                                    >
                                      <Button className="btn_edit">
                                        <Popconfirm
                                          title="Are you sure？"
                                          okText="Yes"
                                          cancelText="No"
                                          onConfirm={() =>
                                            this.deleteContactInfo(c.id)
                                          }
                                        >
                                          <span className="span_edit">
                                            <MdDelete
                                              style={{ marginRight: "5px" }}
                                            />{" "}
                                            Delete
                                          </span>
                                        </Popconfirm>
                                      </Button>
                                    </Col>
                                  </Row>
                                }
                              >
                                <EllipsisOutlined
                                  style={{ fontSize: "30px", color: "grey" }}
                                  className="action-ellipsis-button"
                                />
                              </Popover>
                            </Col>
                          </div>
                        </Col>
                      ))}

                      {/* <Col
                        xxl={{ span: 24 }}
                        xl={{ span: 24 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Form.Item name="button">
                          <Button
                            type="primary"
                            style={{
                              width: "100%",
                              fontWeight: "500",
                              height: "40px",
                            }}
                            onClick={() =>
                              this.state.companyCheck !== ""
                                ? this.setState({
                                    isContactVisible: true,
                                    recordContactData: "",
                                  })
                                : message.info(
                                    "Please Enter Your Company Name First!"
                                  )
                            }
                          >
                            ADD CONTACT
                          </Button>
                        </Form.Item>
                      </Col> */}
                    </>

                    {jobInfo === "Trucking Job" && (
                      <Col
                        xxl={{ span: 24, offset: 0 }}
                        xl={{ span: 24, offset: 0 }}
                        lg={{ span: 24, offset: 0 }}
                        md={{ span: 24, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        xs={{ span: 24, offset: 0 }}
                      >
                        <span className="title_changes">Pickup Address</span>
                        <Form.Item
                          name="pickupAddress"
                          rules={[
                            {
                              required: false,
                              message: "Please Enter Your Address",
                            },
                          ]}
                        >
                          <GooglePlacesAutocomplete
                            className="form-input"
                            autocompletionRequest={{
                              componentRestrictions: {
                                country: ["us"],
                              },
                            }}
                            selectProps={{
                              placeholder: "Search Address...",
                              onChange: (address) => {
                                this.handleSelect(address, "pickup");
                                this.getAddress(address, "pickup");
                              },
                            }}
                          />
                        </Form.Item>
                      </Col>
                    )}

                    <Col
                      xxl={{ span: 24, offset: 0 }}
                      xl={{ span: 24, offset: 0 }}
                      lg={{ span: 24, offset: 0 }}
                      md={{ span: 24, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      xs={{ span: 24, offset: 0 }}
                    >
                      <span className="title_changes">Delivery Address</span>
                      <Form.Item
                        name="deliveryAddress"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Your Address",
                          },
                        ]}
                      >
                        <GooglePlacesAutocomplete
                          className="form-input"
                          autocompletionRequest={{
                            componentRestrictions: {
                              country: ["us"],
                            },
                          }}
                          selectProps={{
                            placeholder: "Search Address...",
                            onChange: (address) => {
                              this.handleSelect(address, "dropOff");
                              this.getAddress(address, "dropOff");
                              this.setState({
                                dropAddress: address.label,
                              });
                            },
                          }}
                        />
                      </Form.Item>
                    </Col>

                    {jobInfo === "Trucking & Material Job" && (
                      <Col
                        xxl={{ span: 12 }}
                        xl={{ span: 12 }}
                        lg={{ span: 12 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <span className="title_changes">Materials</span>
                        <Form.Item
                          name="requiredMaterials"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Required Materials!",
                            },
                          ]}
                        >
                          <TextArea
                            rows={4}
                            style={{
                              width: "100%",
                              height: "65px",
                              borderColor: "#d6d6d6",
                            }}
                            className="form-input"
                            placeholder="Please Enter Required Materials"
                          />
                        </Form.Item>
                      </Col>
                    )}
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      span={jobInfo === "Trucking & Material Job" ? 12 : 24}
                    >
                      <span className="title_changes">Notes</span>
                      <Form.Item
                        name="notes"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Your Notes!",
                          },
                        ]}
                        // initialValue={
                        //   recordContactData?.notes
                        //     ? recordContactData?.notes
                        //     : ""
                        // }
                      >
                        <TextArea
                          rows={4}
                          style={{
                            width: "100%",
                            height: "75px",
                            borderColor: "#d6d6d6",
                          }}
                          className="form-input"
                          placeholder="Please Enter Your Notes"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </BaseModal>
            )}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) =>
  // console.log(state),
  ({
    result: state.reducer?.leadsData,
    loading: state.reducer?.leadsData.loading,
    errorLoading: state.reducer?.leadsData.error,
    companiesResult: state?.reducer?.companiesData,
    leadInfo: state?.reducer?.leadsInfo,
    leadUpdateInfo: state?.reducer?.leadUpdateInfo,
    companyResult: state?.reducer?.companyResult,
    contactsInfo: state.reducer?.contactsInfo,
    contactData: state.reducer?.contactData,
    rolesData: state.reducer?.dropDownOptions?.totalData?.filter(
      (q) => q?.typeofDropDown === "roles"
    ),
    materialList: state.reducer?.dropDownOptions?.totalData?.filter(
      (q) => q?.typeofDropDown === "material"
    ),
    dropDownOptions: state.reducer.dropDownOptions,
    dropDownOptionsInfo: state.reducer?.dropDownOptionsInfo,
    quarriesData: state.reducer.quarriesResult,
    topVendorsData: state.reducer.vendorsData,
  });

const mapDispatchToProps = (dispatch) => ({
  actionFetchLeads: (id) => {
    dispatch(getLeads(id));
  },
  actionFetchCompanies: () => {
    dispatch(getCompanies());
  },
  actionAddJob: (data) => {
    dispatch(addLead(data));
  },
  actionUpdateJob: (id, data) => {
    dispatch(updateLead(id, data));
  },
  actionDeleteLead: (id) => {
    dispatch(deleteLead(id));
  },
  actionFetchCompany: (id) => {
    dispatch(getCompany(id));
  },
  actionAddContact: (data) => {
    dispatch(addContacts(data));
  },
  actionAddGenericContact: (data) => {
    dispatch(addContact(data));
  },
  actiongetDropDownOptions: () => {
    dispatch(getDropDownOptions());
  },
  actionupdateDropDownOptions: (id, data) => {
    dispatch(updateDropDownOptions(id, data));
  },
  actionFetchQuarries: () => {
    dispatch(getQuarryLocations());
  },
  actionFetchTopVendors: (data) => {
    dispatch(getTopVendors(data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAppContext(withRouter(TruckingMaterial)));
