import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  InputNumber,
  Button,
  Card,
  Popover,
  Popconfirm,
  Checkbox,
  message,
  Tabs,
  Spin,
  Table,
  Switch,
  Collapse,
  // Radio,
} from "antd";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
// import { geocodeByLatLng } from "react-google-places-autocomplete";
import MapComponent from "../../Components/Maps/MapComponent";
import {
  EditOutlined,
  EllipsisOutlined,
  PlusOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  // FileOutlined,
  DownloadOutlined,
  ContactsOutlined,
  InboxOutlined,
  PaperClipOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import { HiOutlineDocumentArrowDown } from "react-icons/hi2";
import {
  BsArrowLeftShort,
  BsFillCircleFill,
  BsInfoCircle,
} from "react-icons/bs";
import { add, deleteRecord, update } from "../../api/Apis";
import appConfig from "../../config/AppConfig";
import Loading from "../../Common/Loading";
import BaseModal from "../../Common/BaseModal";
import { withAppContext } from "../../Store/AppContext";
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import BaseDrawer from "../../Common/BaseDrawer";
import NumberFormat from "react-number-format";
import TextArea from "antd/lib/input/TextArea";
import TimePicker from "rc-time-picker";
import { v4 as uuidv4 } from "uuid";
import BaseTable from "../../Common/BaseTable";
import moment from "moment";
import { Storage } from "aws-amplify";
import Dragger from "antd/lib/upload/Dragger";
import Filter from "../../Common/Filter";
import { IoFilterSharp } from "react-icons/io5";
import { BsCardImage } from "react-icons/bs";
import ErrorView from "../../Common/ErrorView";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getDropDownOptions,
  getQuarry,
  updateDropDownOptions,
  updateQuarry,
} from "../../Redux/Actions/actions";
import Nodata from "../nodata";
import QuarryJsonData from "../../Json/quarryData.json";
import { saveAs } from "file-saver";
import JSZip from "jszip";
// const { Panel } = Collapse;

const { Option } = Select;

const { TabPane } = Tabs;

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
class QuarryDetails extends Component {
  formRef = React.createRef();
  state = {
    isModalVisible: false,
    //selectedStatus: "",
    loading: false,
    quarryData: [],
    resultData: [],
    citySearch: [],
    city: "",
    addressState: "",
    zipCode: "",
    selectedAddress: "",
    materialList: [],
    latitude: "",
    longitude: "",
    mineStatus: "",
    selectedMaterialType: "",
    keyValue: "1",
    selectedTerm: "",
    notesData: [],
    isDocModalVisible: false,
    imageLoading: false,
    quoteAttachment: [],
    ticketUploaded: [],
    isDetailModal: false,
    recordDocData: "",
    docsData: [],
    visible: false,
    filteredInvoicesData: [],
    invoicesData: [],
    checkData: [],
    materialKey: "",
    errorLoading: false,
    quarryRoles: [],
    contactInfo: [],
    quantityType: "Ton",
    materialAttachment: [],
    diameter: "",
    showLocationInput: true,
    materialCategory: {},
    subCategory1: "",
    subCategory2: [],
    categoryKey: "",
    subShortName: "",
    addCategory: false,
    materialDocInfo: [],
    showImages: false,
    imageData: [],
    imgLoading: false,
    materialProfilePic: "",
    recordData: {},
    tabKey: "",
    salesTaxRate: 0,
  };

  componentDidMount() {
    this.setState({
      loading: true,
    });
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    this.props.actionFetchQuarries(id);
    // this.getQuarryDetails(id);
    this.props?.context?.updateQuarryId(id);
    this.props.actiongetDropDownOptions();
    document.title = "Twisted Nail - Quarry Details";
  }

  componentDidUpdate(newProps) {
    if (
      newProps?.totalResult?.success === false &&
      this.props?.totalResult?.success === true
    ) {
      let materialDocInfo = this.props?.totalResult?.quarry?.material?.filter(
        (value, index, self) =>
          self.findIndex((e) => e.id === value.id) === index
      );
      this.setState({
        quarryData: [this.props?.totalResult?.quarry],
        resultData: this.props?.totalResult?.quarry,
        city: this.props?.totalResult?.quarrycity,
        addressState: this.props?.totalResult?.quarrystate,
        zipCode: this.props?.totalResult?.quarryzip_cd,
        selectedMaterial: this.props?.totalResult?.quarry?.material,
        filteredMaterials: this.props?.totalResult?.quarry?.material,
        contactInfo:
          this.props?.totalResult?.quarry?.contactDetails !== undefined
            ? this.props?.totalResult?.quarry?.contactDetails
            : [],
        notesData: this.props?.totalResult?.quarry?.notesDetails,
        docsData: this.props?.totalResult?.quarry?.documentsDetails,
        invoicesData: this.props?.totalResult?.quarry?.invoiceDetails,
        filteredInvoicesData: this.props?.totalResult?.quarry?.invoiceDetails,
        materialDocInfo,
      });
    }
    if (
      newProps?.quarriesInfo?.success === false &&
      this.props?.quarriesInfo?.success === true
    ) {
      // console.log("quarryInfo");
      this.setState({
        selectedTerm: "",
      });
      this.props.actionFetchQuarries(this.props.match.params.id);
      // this.props.actiongetDropDownOptions();
    }
    if (
      newProps?.dropDownOptionsInfo?.success === false &&
      this.props?.dropDownOptionsInfo?.success === true
    ) {
      this.props.actiongetDropDownOptions();
    }
    if (
      newProps?.dropDownOptions?.success === false &&
      this.props?.dropDownOptions?.success === true
    ) {
      this.setState({
        salesTaxRate: this.props.dropDownOptions?.totalData?.find(
          (e) => e?.typeofDropDown === "salesTaxRate"
        )?.taxRate,
      });
      let materialData = {};
      let materialsNewList = this.props.materials[0]?.materialsNewList;
      let index = 0;
      let sortedMaterialList = materialsNewList
        ?.filter((v) => v.statusCode)
        .sort((a, b) => a?.indexVal - b?.indexVal);
      sortedMaterialList?.map((eachMat) => {
        let subTypes = [];
        if (eachMat?.statusCode) {
          index += 1;
          materialData[eachMat.category] = !Object.keys(materialData).includes(
            eachMat.category
          )
            ? []
            : materialData[eachMat.category];
          let typeCheck = materialData[eachMat.category].filter(
            (o) => o.subcat === eachMat.materialValue.split(" - ")[1]
          );

          if (typeCheck.length > 0) {
            subTypes = typeCheck[0]["subTypes"];
            subTypes.push({
              subType: eachMat.materialValue.split(" - ")[2],
              id: eachMat.id,
            });
          } else {
            subTypes.push({
              subType: eachMat.materialValue.split(" - ")[2],
              id: eachMat.id,
            });
            materialData[eachMat.category].push({
              subcat: eachMat.materialValue.split(" - ")[1],
              cate: eachMat.materialValue.split(" - ")[0],
              categoryKey: eachMat.categoryKey,
              subShortName: eachMat.subShortName,
              subTypes,
            });
          }
          if (
            sortedMaterialList?.filter((e) => e.statusCode)?.length === index
          ) {
            this.setState({
              materialCategory: materialData,
            });
          }
        }
        return true;
      });
      // console.log("drop")
      this.setState({
        quarryRoles: this.props.quarryRoles[0]?.quarryRolesData?.sort((a, b) =>
          a?.value > b?.value ? 1 : -1
        ),
        materialList: this.props.materials[0]?.materialsNewList,
      });
    }
  }

  // static getDerivedStateFromProps(props, state) {
  //   // console.log("--------getDerivedStateFromProps------------");
  //   if (
  //     JSON.stringify(props?.materials) !== JSON.stringify(state?.materialList)
  //   ) {
  //     return {
  //       materialList: props.materials[0]?.materialsNewList,
  //     };
  //   }
  //   if (
  //     JSON.stringify(props?.quarryRoles) !== JSON.stringify(state?.quarryRoles)
  //   ) {
  //     console.log("quarryRoles");
  //     return {
  //       quarryRoles: props.quarryRoles[0]?.quarryRolesData?.sort((a, b) =>
  //         a?.value > b?.value ? 1 : -1
  //       ),
  //     };
  //   }
  // }

  onRefresh = () => {
    this.setState({
      loading: true,
    });
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    this.props.actionFetchQuarries(id);
    this.props?.context?.updateQuarryId(id);
    this.props.actiongetDropDownOptions();
  };

  onClickClearFilter = () => {
    this.setState({
      loading: true,
    });
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    this.props.actionFetchQuarries(id);
    this.props?.context?.updateQuarryId(id);
    this.props.actiongetDropDownOptions();
    this.closeFilter();
    this.setState({ checkData: "" });
  };

  getAddress = (address, latLng, id) => {
    this.setState({
      selectedAddress: address.label,
    });
  };

  showModal = (key) => {
    const { materialCategory, materialList } = this.state;

    if (key === "2") {
      this.setState({
        contactPopup: true,
        selectedMaterialType: "",
        recordContactData: "",
      });
    } else if (key === "1") {
      this.setState({
        materialPopup: true,
        diameter: "",
        recordMaterialData: "",
        quantityType: "Ton",
        selectedMaterial: this.state.resultData?.material,
        materialAttachment: [],
        subCategory2: [],
        subCategory1: "",
        selectedMaterialType: "Unknown",
      });
      Object.values(materialCategory["Unknown"])?.map((c, i) => {
        this.setState({
          subCategory1: c.subcat,
          subCategory2: c.subTypes,
          categoryKey: c.categoryKey,
          subShortName: c.subShortName,
        });
        materialList?.forEach((f) => {
          if (f.id === c.subTypes?.find((e) => e.id)?.id) {
            this.setState({ materialSelectedData: f });
          }
        });
        return c;
      });
    } else if (key === "4") {
      this.setState({
        isDocModalVisible: true,
        recordDocData: "",
        quoteAttachment: [],
      });
    } else if (key === "3") {
      this.setState({ visible: true });
    } else {
      this.setState({
        notesPopup: true,
        recordNotesData: "",
      });
    }
  };

  handleSelect = (address) => {
    const citySearch = [];
    citySearch.push(address.label);
    this.setState({
      citySearch,
    });
    let cityValue = "";
    let stateValue = "";
    let zipCodeValue = "";
    let stateShortValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        getLatLng(results[0]).then(({ lat, lng }) =>
          this.setState({
            latitude: lat,
            longitude: lng,
          })
        );
        console.log("results", this.state.latitude, this.state.longitude);
        const geoIdData = results[0];
        this.setState({
          geoIdData,
        });
        let area = results[0].address_components;
        //console.log(results[0]);
        for (let i = 0; i < area.length; i++) {
          if (area[i].types[0] === "locality") {
            cityValue = area[i].long_name;
          }
          if (area[i].types[0] === "administrative_area_level_1") {
            stateValue = area[i].long_name;
          }
          if (area[i].types[0] === "administrative_area_level_1") {
            stateShortValue = area[i].short_name;
          }
          if (area[i].types[0] === "postal_code") {
            zipCodeValue = area[i].long_name;
          }
        }
        this.setState({
          city: cityValue,
          addressState: stateValue,
          stateAbbr: stateShortValue,
          zipCode: zipCodeValue,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  onFinish = (values) => {
    console.log("values", values);
    this.setState({ loading: true, isModalVisible: false });

    const { latitude, longitude, selectedAddress, showLocationInput } =
      this.state;

    let type = [];
    this.state?.selectedMaterial?.map(
      (e) => e?.categoryKey !== undefined && type.push({ key: e?.categoryKey })
    );

    let uniqueObjArray = [
      ...new Map(type?.map((item) => [item["key"], item])).values(),
    ];
    // console.log(uniqueObjArray);

    // geocodeByLatLng({ lat: parseFloat(latitude), lng: parseFloat(longitude) })
    //   .then((results) => console.log(results))
    //   .catch((error) => console.error(error));

    // return;

    let data = {
      entityData: {
        quarryType: uniqueObjArray,
        notes: values["notes"],
        // quarryType: this.state.quarryType || resultData?.quarryType,
        quarryName: values["quarryName"],
        quarryNickName: values["quarryNickName"],
        // material: this.state?.selectedMaterial,
        phone: values["phone"],
        // contactDetails: this.state?.contactInfo,
        accountTerms:
          values["accountTerms"] === "Custom Input"
            ? values["accountTermsOthers"]
            : values["accountTerms"],
        accountLimit: values["accountLimit"],
        payDiscountTerms: values["payDiscountTerms"] || "",
        // chargeAccount: values["chargeAccount"],
        payDiscount: values["payDiscount"] || "",
        startTime: moment(values["startTime"]),
        endTime: moment(values["endTime"]),
      },
    };
    if (showLocationInput === false) {
      data["entityData"]["lat"] = Number(values["lat"]);
      data["entityData"]["lng"] = Number(values["lng"]);
      data["entityData"]["address"] = "";
    } else {
      data["entityData"]["address"] = selectedAddress;
      data["entityData"]["lat"] = Number(latitude);
      data["entityData"]["lng"] = Number(longitude);
    }
    if (
      data["entityData"]["payDiscountTerms"]?.split("Net")[1] === undefined ||
      (data["entityData"]["accountTerms"] !== "Cash" &&
        parseInt(data["entityData"]["accountTerms"]?.split("Net")[1]) >=
          parseInt(data["entityData"]["payDiscountTerms"]?.split("Net")[1]))
    ) {
      this.props.actionFetchUpdateQuarry(this.props.match.params.id, data);
    } else {
      message.error("Discount Terms can't exceed Account Terms!");
    }
  };

  onClickReturn = () => {
    this.props.history.goBack();
  };
  onChange = (time, timeString) => {
    console.log(time, timeString);
  };
  onSelectMaterialCat = (e, type) => {
    // console.log(e);
    if (type === "edit") {
      const { materialList } = this.state;
      let materialsData = [];
      materialList?.map((newMat) => {
        if (e === newMat?.category) {
          materialsData.push(newMat);
        }
        return true;
      });
      this.setState({
        selectedMaterialType: e,
        finalMaterialData: materialsData,
      });
    } else {
      const { materialList } = this.state;
      let materialsData = [];
      materialList?.map((newMat) => {
        if (e === newMat?.category) {
          materialsData.push(newMat);
        }
        return true;
      });
      if (e) {
        this.formRef.current.setFieldsValue({ material: undefined, price: "" });
        this.setState({
          materialSelectedData: "",
        });
      }
      this.setState({
        selectedMaterialType: e,
        finalMaterialData: materialsData,
      });
    }
  };

  onSelectMaterial = (e) => {
    const { materialList } = this.state;
    materialList?.filter(
      (f) => f.id === e && this.setState({ materialSelectedData: f })
    );
  };

  selectQuarryType = (value, option) => {
    // console.log(option)
    this.setState({
      quarryType: option,
    });
  };

  DecimalToMixedFraction = (val) => {
    // eslint-disable-next-line no-eval
    const decimal = eval(val);
    // Split the decimal into whole and fractional parts
    const wholeNumber = Math.floor(decimal);
    const fractionalPart = decimal - wholeNumber;
    // Convert the fractional part to a fraction
    const fraction = this.decimalToFraction(fractionalPart);

    // Combine the whole number and fraction
    const mixedFraction =
      wholeNumber !== 0 ? `${wholeNumber} ${fraction}` : `${fraction}`;

    return mixedFraction;
  };

  decimalToFraction = (decimal) => {
    const EPSILON = 1.0e-9;
    let numerator = decimal;
    let denominator = 1;

    while (Math.abs(Math.round(numerator) - numerator) > EPSILON) {
      numerator *= 10;
      denominator *= 10;
    }

    const gcd = this.getGreatestCommonDivisor(numerator, denominator);
    numerator /= gcd;
    denominator /= gcd;

    return `${Math.floor(numerator)}/${Math.floor(denominator)}`;
  };

  getGreatestCommonDivisor = (a, b) => {
    if (b === 0) {
      return a;
    }
    return this.getGreatestCommonDivisor(b, a % b);
  };

  onFinishMaterial = (values) => {
    const {
      recordMaterialData,
      materialSelectedData,
      quantityType,
      selectedMaterial,
      materialAttachment,
      diameter,
      salesTaxRate,
    } = this.state;

    let minD = String(values["materialMinDiameter"])?.replace('"', "");
    let maxD = String(values["materialMaxDiameter"])?.replace('"', "");
    let mD = String(values["materialDiameter"])?.replace('"', "");
    let materialMinDiameter = "",
      materialMaxDiameter = "",
      materialDiameter = "",
      materialData = [];

    if (diameter) {
      materialMinDiameter =
        minD !== "Unknown"
          ? minD?.split(" ")?.length !== 2 && String(parseInt(minD)) !== minD
            ? this.DecimalToMixedFraction(minD)
            : minD
          : minD;

      materialMaxDiameter =
        maxD !== "Unknown"
          ? maxD?.split(" ")?.length !== 2 && String(parseInt(maxD)) !== maxD
            ? this.DecimalToMixedFraction(maxD)
            : maxD
          : maxD;
    } else {
      materialDiameter =
        mD !== "Unknown"
          ? mD?.split(" ")?.length !== 2 && String(parseInt(mD)) !== mD
            ? this.DecimalToMixedFraction(mD)
            : mD
          : mD;
    }

    if (
      recordMaterialData !== "" &&
      Object.keys(recordMaterialData)?.length > 0
    ) {
      let filteredData = selectedMaterial.filter(
        (c) => c.materialId !== recordMaterialData?.materialId
      );
      materialData = filteredData?.filter(
        (m) =>
          m?.id === materialSelectedData?.id &&
          (m.diameter
            ? m["materialMinDiameter"] === materialMinDiameter &&
              m["materialMaxDiameter"] === materialMaxDiameter
            : m["materialDiameter"] === materialDiameter)
      );
    } else {
      materialData = selectedMaterial?.filter(
        (m) =>
          m?.id === materialSelectedData?.id &&
          (m.diameter
            ? m["materialMinDiameter"] === materialMinDiameter &&
              m["materialMaxDiameter"] === materialMaxDiameter
            : m["materialDiameter"] === materialDiameter)
      );
    }
    if (materialData?.length > 0) {
      message.info("Given Material Is Already Selected");
      return true;
    } else {
      this.setState({
        loading: true,
        materialPopup: false,
      });
      let data = {
        entityData: {
          id: materialSelectedData["id"],
          materialId:
            recordMaterialData === ""
              ? uuidv4()
              : recordMaterialData?.materialId
              ? recordMaterialData?.materialId
              : uuidv4(),
          label: materialSelectedData["label"],
          value:
            materialSelectedData["materialValue"] || recordMaterialData?.value,
          category: materialSelectedData["category"],
          subCategory: materialSelectedData["subCategory"],
          price: values["price"] || recordMaterialData["price"],
          quantity: "",
          quantityType: quantityType || recordMaterialData?.quantityType,
          categoryKey: materialSelectedData["categoryKey"],
          docDetails: materialSelectedData["docDetails"],
          materialPicture: materialAttachment,
          materialNotes: values["materialNotes"],
          materialStatus: values["materialStatus"],
          materialSupply: values["materialSupply"],
          materialTest: values["materialTest"],
          materialTax: values["materialTax"],
          additionalTaxRate: salesTaxRate,
          materialMaxDiameter: materialMaxDiameter,
          materialMinDiameter: materialMinDiameter,
          materialDiameter: materialDiameter,
          materialCheckDiameter: diameter,
          statusCode: materialSelectedData["statusCode"],
          subShortName: materialSelectedData["subShortName"],
          confirm: materialSelectedData["confirm"]
            ? materialSelectedData["confirm"]
            : false,
        },
      };
      if (recordMaterialData === "") {
        selectedMaterial.splice(
          selectedMaterial.findIndex(
            (f) => f?.categoryKey === data["entityData"]["categoryKey"]
          ) + 1,
          0,
          data["entityData"]
        );
      } else {
        selectedMaterial.splice(
          selectedMaterial.findIndex(
            (f) => f?.materialId === data["entityData"]["materialId"]
          ),
          1,
          data["entityData"]
        );
      }
      this.setState(
        {
          selectedMaterial,
          materialCheck: false,
          quantityType: "Ton",
          selectedMaterialType: "",
          recordMaterialData: "",
          materialSelectedData: "",
          diameter: "",
        },
        () => this.updateMaterial()
      );
    }
  };

  updateMaterial = () => {
    const { selectedMaterial } = this.state;
    if (selectedMaterial?.length > 0) {
      let type = [];
      this.state?.selectedMaterial?.map(
        (e) =>
          e?.categoryKey !== undefined && type.push({ key: e?.categoryKey })
      );
      let uniqueObjArray = [
        ...new Map(type?.map((item) => [item["key"], item])).values(),
      ];
      if (uniqueObjArray?.length > 0) {
        let data = {
          entityData: {
            quarryType: uniqueObjArray,
            material: selectedMaterial,
          },
        };
        this.props.actionFetchUpdateQuarry(this.props.match.params.id, data);
      }
    } else {
      let data = {
        entityData: {
          quarryType: [],
          material: [],
        },
      };
      // console.log(data);
      this.props.actionFetchUpdateQuarry(this.props.match.params.id, data);
    }
  };

  editMaterial = (data) => {
    this.setState(
      {
        materialPopup: true,
        recordMaterialData: data,
        materialSelectedData: data,
        recordContactData: "",
        selectedMaterialType: data?.category,
        quantityType: data?.quantityType ? data?.quantityType : "Ton",
        diameter: data?.materialCheckDiameter,
        materialAttachment: data?.materialPicture,
      },
      () => this.subCategoryInfo(data)
      // () => this.onSelectMaterialCat(data?.category, "edit")
    );
  };

  updateRecord = (record) => {
    const { selectedMaterial } = this.state;
    record["confirm"] = true;
    record["updatedAt"] = moment().format("l");
    this.setState({ loading: true });
    selectedMaterial?.splice(
      selectedMaterial?.findIndex((e) => e?.id === record.id),
      1,
      record
    );
    this.setState(
      {
        selectedMaterial,
      },
      () => this.updateMaterial()
    );
    message.success("Material Updated Successfully!");
  };

  subCategoryInfo = (data) => {
    const { materialCategory } = this.state;
    if (materialCategory[data.category]) {
      Object.values(materialCategory[data.category])?.forEach((c, i) => {
        if (c.subcat === data?.label?.split(" - ")[0]) {
          this.setState({
            subCategory1: data.category,
            subCategory2: c.subTypes,
            categoryKey: c.categoryKey,
            subShortName: c.subShortName,
          });
        }
      });
    }
  };

  deleteMaterial = (record) => {
    // this.deltePictureOfMaterial(record);
    this.deleteDocOfMaterial(record.id);
    const { selectedMaterial } = this.state;
    this.setState({ loading: true });
    selectedMaterial?.splice(
      selectedMaterial?.findIndex((e) => e?.materialId === record?.materialId),
      1
    );
    this.setState(
      {
        selectedMaterial,
      },
      () => this.updateMaterial()
    );
    message.info("Material Deleted Successfully!");
  };

  deltePictureOfMaterial = async (data) => {
    if (data.materialPicture?.length > 0) {
      data.materialPicture?.map((e) => Storage.remove(e.quoteAttachmentId));
    }
  };

  addContact = (values) => {
    const { recordContactData, resultData, quarryName, contactInfo } =
      this.state;

    if (recordContactData !== "") {
      let filteredData = this.state.contactInfo.filter(
        (c) => c.id !== recordContactData?.id
      );
      this.setState({
        contactInfo: filteredData,
      });
    }

    let data = {
      entityData: {
        id: uuidv4(),
        firstName: values.fName,
        lastName: values.lName,
        phone: values.phone,
        email: values.email,
        contactRole:
          values["role"] === "Add New Role"
            ? values["roleOther"]
            : values["role"],
        notes: values.notes,
        company: quarryName || resultData?.quarryName,
        isPrimaryContact: recordContactData?.isPrimaryContact,
      },
    };
    if (contactInfo?.length === 0) {
      data["entityData"]["isPrimaryContact"] = true;
    }
    // console.log(data, "data");
    let contactData = this.state?.contactInfo?.filter(
      (c) => c?.email === values?.email && c?.phone === values?.phone
    );

    if (contactData?.length > 0) {
      message.info("Given Email and Phone Number already exists");
      this.setState({
        loading: false,
      });
      return true;
    } else {
      this.setState({
        contactPopup: false,
        loading: true,
      });
      if (this.state.isCheckcontact) {
        this.updateQuarryRolesData(values["roleOther"]);
      }
      add(appConfig["entityNames"]["checkContact"], data)
        .then((result) => {
          if (result.data.message === "success") {
            // message.success("Contact Added Successfully!");
            let contactInfo =
              this.state?.contactInfo?.length > 0
                ? this.state?.contactInfo
                : [];
            if (recordContactData?.length > 0) {
              let filteredData = this.state.contactInfo.filter(
                (c) => c.id !== recordContactData?.id
              );
              filteredData.push(data["entityData"]);
              this.setState({
                contactInfo: filteredData,
                loading: false,
                isCheckcontact: false,
                newRole: "",
              });
            }
            contactInfo.push(data["entityData"]);
            this.setState({
              contactInfo,
              isCheckcontact: false,
              newRole: "",
            });
            this.updateContact();
          } else {
            message.info(result.data.message);
            this.setState({
              isCheckcontact: false,
              newRole: "",
            });
            return true;
          }
        })
        .catch((err) => {
          this.setState({});
          console.log(err);
        });
    }
  };

  updateQuarryRolesData = (values) => {
    let quarryRolesData = this.state.quarryRoles;
    quarryRolesData.push({
      label: values,
      value: values.charAt(0).toUpperCase(),
      id: quarryRolesData?.length + 1,
    });
    let data = {
      entityData: {
        quarryRolesData,
        typeofDropDown: "quarryRoles",
      },
    };
    this.props.actionupdateDropDownOptions(this.props.quarryRoles[0]?.id, data);
  };

  updateContact = () => {
    const { contactInfo } = this.state;
    let data = {
      entityData: {
        contactDetails: contactInfo,
      },
    };
    this.props.actionFetchUpdateQuarry(this.props.match.params.id, data);
  };

  deleteContactInfo = (id) => {
    this.setState({ loading: true });
    let contactInfo = this.state.contactInfo.filter((c) => c.id !== id);
    this.setState(
      {
        contactInfo,
      },
      () => this.updateContact()
    );
    message.info("Contact Removed Successfully!");
  };

  editContactInfo = (data) => {
    // console.log(data);
    this.setState({
      contactPopup: true,
      recordContactData: data,
      recordMaterialData: "",
      quarryName: data?.company,
    });
  };

  onSelectAccountTerms = (e) => {
    this.setState({ selectedTerm: e });
    this.formRef.current.setFieldsValue({
      accountTermsOthers: "",
    });
  };

  addNotes = (values) => {
    const { recordNotesData } = this.state;
    this.setState({ loading: true, notesPopup: false });
    if (recordNotesData !== "") {
      let filteredData = this.state.notesData.filter(
        (c) => c.id !== recordNotesData?.id
      );
      this.setState({
        notesData: filteredData,
      });
    }

    let data = {
      entityData: {
        id: recordNotesData?.id !== undefined ? recordNotesData?.id : uuidv4(),
        date:
          recordNotesData?.date !== undefined
            ? recordNotesData?.date
            : moment().format("MM-DD-YYYY LT"),
        typeofNotes: values["type"],
        notes: values["notes"],
      },
    };
    // console.log(data);
    let notesInfo =
      this.state?.notesData?.length > 0 ? this.state?.notesData : [];
    notesInfo.push(data["entityData"]);
    this.setState({ notesData: notesInfo });
    this.updateNotes();
  };

  updateNotes = () => {
    const { notesData } = this.state;
    let data = {
      entityData: {
        notesDetails: notesData,
      },
    };
    this.props.actionFetchUpdateQuarry(this.props.match.params.id, data);
  };

  editNotes = (data) => {
    this.setState({
      notesPopup: true,
      recordNotesData: data,
    });
  };

  deleteNotes = (id) => {
    this.setState({ loading: true });
    let notesInfo = this.state.notesData.filter((c) => c?.id !== id);
    this.setState(
      {
        notesData: notesInfo,
      },
      () => this.updateNotes()
    );
    message.info("Notes Deleted Successfully!");
  };

  fileDownload = async (fileName, downloadFileName, companyName) => {
    let savedFileName = downloadFileName + "_" + companyName.replace(/ /g, "");
    this.setState({
      loadingDownload: true,
    });
    Storage.get(fileName, { download: true }).then((res) =>
      this.downloadBlob(res.Body, savedFileName)
    );
  };

  downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    this.setState({
      loadingDownload: false,
    });
    return a;
  }

  fileDownloadQuote = async (fileName, type) => {
    if (type === "materialPicture") {
      this.setState({
        imgLoading: fileName,
      });
    }
    Storage.get(fileName, { download: true })
      .then((res) => this.downloadBlobQuote(res.Body, fileName, type))
      .catch((err) => console.log(err));
  };

  downloadBlobQuote(blob, fileName, type) {
    const url = URL.createObjectURL(blob);
    // console.log(fileName, "fileName");
    //https://twisted-nail-dev-attachments.s3.us-east-1.amazonaws.com/quotes/7d0e0df0-1fb8-11ed-b551-fd2cfd43234b_quote.pdf?x-id=GetObject
    // const url = `https://${process.env.REACT_APP_S3_BUCKET}.s3.us-east-1.amazonaws.com/${fileName}`;
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.download = fileName || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    if (type === "materialPicture") {
      message.success("Downloaded successfully");
    }
    this.setState({
      imgLoading: false,
    });
    return a;
  }

  onFinishDoc = (values) => {
    const { quoteAttachment, recordDocData, selectedMaterial } = this.state;
    this.setState({
      loading: true,
      isDocModalVisible: false,
    });
    let data = {
      entityData: {
        quarryId: this.props?.match?.params?.id,
        documentName: values["documentName"],
        description: values["description"],
        docAttachment: quoteAttachment,
        materialId: values["materialId"],
      },
    };

    if (recordDocData !== "") {
      if (values["materialId"] !== recordDocData.materialId) {
        selectedMaterial.forEach((element) => {
          if (element.id === recordDocData.materialId) {
            element.docDetails = element.docDetails.filter(
              (d) => d.id !== recordDocData.id
            );
          }
        });
        this.setState({
          selectedMaterial,
        });
      }
      this.updateDocument(data);
    } else {
      this.addDocument(data);
    }
  };

  addDocument = (data) => {
    const { selectedMaterial } = this.state;
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    add(appConfig["entityNames"]["addDocuments"], data)
      .then((res) => {
        if (res.success) {
          let materialIdData = selectedMaterial?.filter(
            (s) => s?.id === res.data.data.materialId
          );
          if (materialIdData?.length > 0) {
            materialIdData[0]["docDetails"] =
              materialIdData[0]["docDetails"] || [];
            materialIdData[0]["docDetails"].push(res.data.data);

            selectedMaterial?.splice(
              selectedMaterial?.findIndex(
                (e) => e.id === data["entityData"]["materialId"]
              ),
              1,
              materialIdData[0]
            );
            let finalData = {
              entityData: {
                material: selectedMaterial,
              },
            };
            this.props.actionFetchUpdateQuarry(id, finalData);
            this.setState({
              quoteAttachment: [],
            });
          } else {
            this.props.actionFetchQuarries(id);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          quoteAttachment: [],
        });
      });
  };

  updateDocument = (data) => {
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    const { selectedMaterial, recordDocData } = this.state;
    update(appConfig["entityNames"]["addDocuments"], recordDocData?.id, data)
      .then((res) => {
        if (res.success) {
          let materialIdData = selectedMaterial?.filter(
            (s) => s?.id === res.data.data.materialId
          );
          if (materialIdData?.length > 0) {
            materialIdData[0]["docDetails"] =
              materialIdData[0]["docDetails"] || [];
            materialIdData[0]["docDetails"].push(res.data.data);
            // console.log(materialIdData, "materialIdData", res.data.data);
            selectedMaterial?.splice(
              selectedMaterial?.findIndex(
                (e) => e.id === data["entityData"]["materialId"]
              ),
              1,
              materialIdData[0]
            );
            let finalData = {
              entityData: {
                material: selectedMaterial,
              },
            };
            this.props.actionFetchUpdateQuarry(id, finalData);
            this.setState({
              quoteAttachment: [],
            });
          } else {
            this.props.actionFetchQuarries(id);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          // loading: false,
          quoteAttachment: [],
        });
      });
  };

  settime = () => {
    setTimeout(() => {
      this.setState({ percentage: this.state.percentage + 40 });
    }, 1000);
  };

  handleFileDelete = (info, type) => {
    const { recordMaterialData, materialAttachment } = this.state;
    if (type === "materialPicture") {
      if (recordMaterialData) {
        let docData = materialAttachment?.filter((e) => e.id !== info.id);
        this.setState({
          materialAttachment: docData,
        });
      } else {
        let docData = materialAttachment?.filter((e) => e.id !== info.id);
        this.setState({
          materialAttachment: docData,
        });
      }
      this.setState({
        imageLoading: false,
        loading: false,
        percentage: 0,
      });
    }
  };

  uploadTicketPdf = async (info, type) => {
    const { recordDocData, quoteAttachment, materialAttachment } = this.state;
    this.setState({
      imageLoading: true,
      loading: true,
    });
    switch (info.file.status) {
      case "done":
        this.setState({
          fileList: info.fileList,
        });
        if (info.fileList.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              let uniqueId = uuidv4();
              Storage.put(
                type === "materialPicture"
                  ? `Quarries/${this.props.match.params.id}/${uniqueId}`
                  : info.file.uid,
                info.file.originFileObj,
                {
                  contentType: info.file.type,
                }
              ).then((result) => {
                Storage.get(result.key).then((urlResult) => {
                  if (type === "materialPicture") {
                    let materialAttachmentInfo = materialAttachment
                      ? materialAttachment
                      : [];
                    materialAttachmentInfo.push({
                      id: uniqueId,
                      materialAttachment: urlResult,
                      quoteAttachmentId: result?.key,
                      fileType: info.file.type,
                      fileName: info.file.name,
                    });
                    this.setState({
                      materialAttachment: materialAttachmentInfo,
                      imageLoading: false,
                      loading: false,
                    });
                  } else {
                    let quoteAttachmentInfo = quoteAttachment
                      ? quoteAttachment
                      : [];
                    quoteAttachmentInfo.push({
                      id: uniqueId,
                      quoteAttachment: urlResult,
                      quoteAttachmentId: result?.key,
                      fileType: info.file.type,
                      fileName: info.file.name,
                    });
                    this.setState({
                      quoteAttachment: quoteAttachmentInfo,
                      imageLoading: false,
                      loading: false,
                    });
                  }
                });
              });
            } else {
              this.setState({
                imageLoading: false,
                percentage: 0,
                loading: false,
              });
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;

      case "removed":
        // if (type === "materialPicture") {
        //   if (recordMaterialData) {
        //     let docData = recordMaterialData.materialPicture.filter(
        //       (e) => e.id !== info.file.id
        //     );
        //     recordMaterialData["materialPicture"] = docData;
        //     console.log(docData, "docData");
        //     this.setState({
        //       materialAttachment: docData,
        //       recordMaterialData,
        //     });
        //   } else {
        //     let docData = materialAttachment.filter(
        //       (e) => e.quoteAttachmentId !== info.file.uid
        //     );
        //     materialAttachment["materialPicture"] = docData;
        //     console.log(docData, "docData");
        //     this.setState({
        //       materialAttachment: docData,
        //     });
        //   }
        //   this.setState({
        //     imageLoading: false,
        //     loading: false,
        //     percentage: 0,
        //   });
        // } else {
        if (recordDocData) {
          let docData = recordDocData.docAttachment.filter(
            (e) => e.id !== info.file.id
          );
          recordDocData["docAttachment"] = docData;
          this.setState({
            quoteAttachment: docData,
            recordDocData,
          });
        } else {
          let docData = quoteAttachment.filter(
            (e) => e.quoteAttachmentId !== info.file.uid
          );
          this.setState({
            quoteAttachment: docData,
          });
        }
        this.setState({
          imageLoading: false,
          loading: false,
          percentage: 0,
        });
        break;
      default:
        this.setState(
          {
            imageLoading: false,
            loading: false,
            percentage: 0,
          },
          this.settime()
        );
    }
  };

  showDetailModal = (data) => {
    this.setState({ isDetailModal: true, docData: data?.docDetails });
  };

  deleteDoc = (record, type) => {
    this.setState({ loading: true });
    if (type === "deleteDocOnly") {
      this.deleteDocRecord(record?.id);
    } else {
      const { selectedMaterial } = this.state;
      selectedMaterial.forEach((element) => {
        if (element.id === record.materialId) {
          element.docDetails = element.docDetails.filter(
            (d) => d.id !== record.id
          );
        }
      });

      let finalData = {
        entityData: {
          material: selectedMaterial,
        },
      };
      this.props.actionFetchUpdateQuarry(this.props.match.params.id, finalData);
      this.deleteDocRecord(record?.id);
    }
  };

  deleteDocOfMaterial = (id) => {
    // console.log(id);
    const { docsData } = this.state;

    let docInfo = docsData?.filter((d) => d?.materialId === id);
    // console.log(docInfo);
    if (docInfo?.length === 1) {
      this.deleteDoc(docInfo[0], "deleteDocOnly");
    }
  };

  deleteDocRecord = (id) => {
    deleteRecord(appConfig["entityNames"]["addDocuments"], id)
      .then((res) => {
        if (res.success) {
          this.setState({ loading: false });
          this.getQuarryDetails(this.props.match.params.id);
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  };

  editDoc = (data) => {
    this.setState({
      isDocModalVisible: true,
      recordDocData: data,
      quoteAttachment: data?.docAttachment,
    });
  };

  filterMaterials = (e) => {
    // console.log(e);
    if (e === "All") {
      this.setState({ filteredMaterials: this.state.selectedMaterial });
    } else {
      let data = this.state.selectedMaterial?.filter((f) => f?.category === e);
      // console.log(data);
      this.setState({ filteredMaterials: data });
    }
  };

  closeFilter = () => {
    this.setState({
      visible: false,
    });
  };

  filterResultData = (data) => {
    const { invoicesData } = this.state;

    if (
      data?.paymentStatus !== undefined &&
      data?.materialType === undefined &&
      data?.startAndEnddates === undefined
    ) {
      const filteredData = invoicesData?.filter(
        (i) => i?.APMinvoiceStatus === data?.paymentStatus
      );
      // console.log(filteredData);
      this.setState({ filteredInvoicesData: filteredData });
    } else if (
      data?.startAndEnddates !== undefined &&
      data?.paymentStatus === undefined &&
      data?.materialType === undefined
    ) {
      const filteredData = invoicesData?.filter((i) =>
        moment(moment(i?.createdAt).format("MM/DD/YYYY")).isBetween(
          data?.startAndEnddates?.startDate,
          data?.startAndEnddates?.endDate,
          undefined,
          "[]"
        )
      );
      this.setState({ filteredInvoicesData: filteredData });
    } else if (
      data?.materialType !== undefined &&
      data?.startAndEnddates === undefined &&
      data?.paymentStatus === undefined
    ) {
      const filteredData = invoicesData?.filter(
        (i) => i?.vendor[0]?.category === data?.materialType
      );
      this.setState({ filteredInvoicesData: filteredData });
    } else if (
      data?.startAndEnddates !== undefined &&
      data?.materialType !== undefined &&
      data?.paymentStatus === undefined
    ) {
      const filteredData = invoicesData?.filter(
        (i) =>
          moment(moment(i?.createdAt).format("MM/DD/YYYY")).isBetween(
            data?.startAndEnddates?.startDate,
            data?.startAndEnddates?.endDate,
            undefined,
            "[]"
          ) && i?.vendor[0]?.category === data?.materialType
      );
      this.setState({ filteredInvoicesData: filteredData });
    } else if (
      data?.materialType !== undefined &&
      data?.paymentStatus !== undefined &&
      data?.startAndEnddates === undefined
    ) {
      const filteredData = invoicesData?.filter(
        (i) =>
          i?.APMinvoiceStatus === data?.paymentStatus &&
          i?.vendor[0]?.category === data?.materialType
      );
      this.setState({ filteredInvoicesData: filteredData });
    } else if (
      data?.paymentStatus !== undefined &&
      data?.startAndEnddates !== undefined &&
      data?.materialType === undefined
    ) {
      const filteredData = invoicesData?.filter(
        (i) =>
          i?.APMinvoiceStatus === data?.paymentStatus &&
          moment(moment(i?.createdAt).format("MM/DD/YYYY")).isBetween(
            data?.startAndEnddates?.startDate,
            data?.startAndEnddates?.endDate,
            undefined,
            "[]"
          )
      );
      this.setState({ filteredInvoicesData: filteredData });
    } else if (
      data?.paymentStatus !== undefined &&
      data?.startAndEnddates !== undefined &&
      data?.materialType !== undefined
    ) {
      const filteredData = invoicesData?.filter(
        (i) =>
          i?.APMinvoiceStatus === data?.paymentStatus &&
          moment(moment(i?.createdAt).format("MM/DD/YYYY")).isBetween(
            data?.startAndEnddates?.startDate,
            data?.startAndEnddates?.endDate,
            undefined,
            "[]"
          ) &&
          i?.vendor[0]?.category === data?.materialType
      );
      this.setState({ filteredInvoicesData: filteredData });
    }
    this.closeFilter();
    this.setState({ checkData: data });
  };

  onSelectChange = (data) => {
    const { contactInfo } = this.state;
    contactInfo?.map((c) => {
      if (c?.id === data) {
        c["isPrimaryContact"] = true;
      } else {
        c["isPrimaryContact"] = false;
      }
      return true;
    });

    let finalData = {
      entityData: {
        contactDetails: contactInfo.sort((a, b) =>
          a.isPrimaryContact < b.isPrimaryContact ? 1 : -1
        ),
      },
    };
    this.props.actionFetchUpdateQuarry(this.props.match.params.id, finalData);
  };

  handleCheckStatus = (e) => {
    this.setState({ diameter: e });
    this.formRef.current.setFieldsValue({
      materialDiameter: "",
      materialMinDiameter: "",
      materialMaxDiameter: "",
    });
  };

  downloadZip = async (images) => {
    const zip = new JSZip();
    // Create an array of promises to fetch and add images to the zip file
    const promises = images.map((image) => {
      return Storage.get(image?.quoteAttachmentId, { download: true })
        .then((response) => response.Body)
        .then((blob) => {
          zip.file(`${image.fileName}`, blob, { binary: true });
        })
        .catch((error) => console.error("Error downloading image:", error));
    });

    // Wait for all promises to resolve
    await Promise.all(promises);

    // Generate the zip file
    const content = await zip.generateAsync({ type: "blob" });

    // Save the zip file
    saveAs(content, "images.zip");
  };

  addCategory = (values) => {
    const {
      selectedMaterialType,
      subCategory1,
      materialList,
      categoryKey,
      subShortName,
      subCategory2,
    } = this.state;
    let duplicateMaterialValue = subCategory2?.filter(
      (e) => e.subType === values["materialsub2"]
    );
    let updateSub2 = subCategory2?.filter((e) => e.subType === "");
    let data = {};
    if (duplicateMaterialValue?.length === 0) {
      if (updateSub2?.length > 0) {
        materialList?.forEach((e) => {
          if (e.id === updateSub2[0].id) {
            e[
              "materialValue"
            ] = `${selectedMaterialType} - ${subCategory1} - ${values["materialsub2"]}`;
            e["subCategory"] = `${selectedMaterialType} - ${subCategory1}`;
            e["label"] = `${subCategory1} - ${values["materialsub2"]}`;
          }
        });
      } else {
        data = {
          materialValue: `${selectedMaterialType} - ${subCategory1} - ${values["materialsub2"]}`,
          subCategory: `${selectedMaterialType} - ${subCategory1}`,
          categoryKey: categoryKey,
          id: uuidv4(),
          label: `${subCategory1} - ${values["materialsub2"]}`,
          category: selectedMaterialType,
          statusCode: true,
          subShortName: subShortName,
        };
        materialList?.push(data);
      }
      let submitData = {
        entityData: {
          materialsNewList: materialList,
          typeofDropDown: "material",
        },
      };

      // Adding SubCategory2 in Materials
      let sub2 = {
        subType: values["materialsub2"],
        id: updateSub2?.length > 0 ? updateSub2[0].id : data["id"],
      };
      if (updateSub2?.length > 0) {
        subCategory2[0]["subType"] = values["materialsub2"];
      } else {
        subCategory2.push(sub2);
      }
      this.setState({
        addCategory: false,
        subCategory2,
      });
      // Updating New Material values
      this.props.actionupdateDropDownOptions(
        this.props.materials[0]?.id,
        submitData
      );
      message.success("Material Added successfully!");
      this.onSelectMaterial(sub2["id"]);
      this.formRef.current.setFieldsValue({
        subCategory2: sub2["id"],
      });
    } else {
      message.error("Material name already exists!");
    }
  };

  handleTableChage = (props, type) => {
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    localStorage.setItem(
      "tableSizeRange",
      JSON.stringify({ ...tableSizeRange, [type]: props })
    );
  };

  handleRadio = (id) => {
    const { selectedMaterial, imageData, recordData } = this.state;
    imageData?.forEach((i) => {
      if (i.id === id) {
        i["profilePic"] = true;
      } else {
        i["profilePic"] = false;
      }
    });
    recordData["materialPicture"] = imageData;
    selectedMaterial.splice(
      selectedMaterial.findIndex((f) => f?.id === recordData.id),
      1,
      recordData
    );
    this.setState({
      materialProfilePic: id,
      selectedMaterial,
    });
    this.updateMaterial();
    message.success("Material profile picture changed successfully");
  };

  uploadMaterialPictures = () => {
    const { recordData, selectedMaterial, materialAttachment } = this.state;
    let pictures =
      recordData.materialPicture?.length > 0 ? recordData.materialPicture : [];
    const imagesData = pictures.concat(materialAttachment);
    recordData["materialPicture"] = imagesData;
    selectedMaterial.splice(
      selectedMaterial.findIndex((f) => f?.id === recordData.id),
      1,
      recordData
    );
    this.setState(
      {
        selectedMaterial,
        showImages: false,
      },
      () => this.updateMaterial()
    );
  };

  findMaterialTaxCount = (subCategories) => {
    let result = subCategories?.flat();
    let taxData = result.filter(
      (element) =>
        element?.materialTax === "Natural" ||
        element?.materialTax === "Manufactured"
    )?.length;
    return result?.length - taxData;
  };

  render() {
    const {
      tabKey,
      materialProfilePic,
      materialAttachment,
      imageData,
      showImages,
      addCategory,
      subCategory2,
      subCategory1,
      materialCategory,
      materialKey,
      isModalVisible,
      quarryData,
      citySearch,
      materialPopup,
      recordContactData,
      recordMaterialData,
      contactPopup,
      resultData,
      // selectedMaterial,
      selectedMaterialType,
      // finalMaterialData,
      // contactInfo,
      quantityType,
      newRole,
      selectedTerm,
      notesPopup,
      recordNotesData,
      // materialList,
      isDocModalVisible,
      imageLoading,
      // quoteAttachment,
      // materialSelectedData,
      isDetailModal,
      docData,
      recordDocData,
      filteredMaterials,
      visible,
      invoicesData,
      filteredInvoicesData,
      checkData,
      quarryRoles,
      loadingDownload,
      diameter,
      materialDocInfo,
      imgLoading,
    } = this.state;

    const { loading, errorLoading } = this.props;
    const uniqueNames = [...new Set(filteredMaterials?.map((e) => e.id))];
    const sortedData = uniqueNames.flatMap((name) => {
      const items = filteredMaterials?.filter((item) => item.id === name);
      return items;
    });
    const groupedData = sortedData.reduce((groups, item) => {
      const { category, subCategory } = item;
      if (!groups[category]) {
        groups[category] = {};
      }

      if (!groups[category][subCategory]) {
        groups[category][subCategory] = [];
      }

      groups[category][subCategory].push(item);

      return groups;
    }, {});

    const selectAfter = (
      <Select
        defaultValue={quantityType}
        className="select-after"
        onChange={(e) => {
          this.setState({ quantityType: e });
        }}
      >
        <Option value="Ton">Ton</Option>
        <Option value="Load">Load</Option>
        <Option value="Yard">Yard</Option>
      </Select>
    );

    const materialDoc = [
      {
        title: "Document Name",
        dataIndex: "documentName",
        key: "documentName",
        sorter: false,
        className: "col_style_quarry",
        render: (documentName) => {
          return (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "fit-content",
              }}
            >
              {documentName}
            </Row>
          );
        },
      },
      {
        title: "Attachment",
        dataIndex: "docAttachment",
        key: "docAttachment",
        sorter: false,
        className: "col_style_quarry",
        render: (docAttachment, record) => {
          return (
            <span className="col_style_quarry">
              {docAttachment !== undefined &&
                docAttachment !== "" &&
                docAttachment !== null &&
                docAttachment.map((e) => (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.fileDownloadQuote(
                        e.quoteAttachmentId,
                        "Attachment",
                        "docAttachment"
                      );
                    }}
                  >
                    <Row
                      style={{
                        color: "#7B8190",
                        fontSize: "13px",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        flexDirection: "coloumn",
                        width: "75px",
                      }}
                    >
                      <HiOutlineDocumentArrowDown
                        style={{
                          fontSize: "30px",
                          height: "30px",
                          marginRight: "5px",
                        }}
                      />
                    </Row>
                  </span>
                ))}
            </span>
          );
        },
      },
    ];

    const materialColumns = (eachItem) => [
      // {
      //   title: "",
      //   dataIndex: "materialPicture",
      //   key: "materialPicture",
      //   sorter: false,
      //   width: "10%",
      //   className: "col_style_quarry",
      //   render: (materialPicture, record) => {
      //     console.log(materialPicture, "materialPicture");
      //     return (
      //       <Row
      //         style={{
      //           display: "flex",
      //           alignItems: "center",
      //           minWidth: "fit-content",
      //           color: !record?.statusCode && "red",
      //         }}
      //       >

      //         {/* {record?.docDetails?.length > 0 && (
      //           <Col>
      //             <Button
      //               icon={<PaperClipOutlined />}
      //               style={{
      //                 fontSize: "15px",
      //                 cursor: "pointer",
      //                 border: "none",
      //                 backgroundColor: "transparent",
      //               }}
      //               onClick={() => this.showDetailModal(record)}
      //             ></Button>
      //           </Col>
      //         )} */}
      //       </Row>
      //     );
      //   },
      // },

      {
        title: <span style={{ fontWeight: "500" }}> {eachItem}</span>,
        dataIndex: "value",
        key: "value",
        sorter: false,
        width: "30%",
        className: "col_style_quarry",
        render: (value, record) => {
          let imageData = record.materialPicture?.filter(
            (e) => e.fileType === "image/png" || e.fileType === "image/jpeg"
          );
          const profilePic = imageData?.filter((e) => e.profilePic);
          let data = value?.split(" - ");
          var x = new moment(moment().format("l"));
          var y = new moment(moment(record.updatedAt).format("l"));
          var diff = x - y;
          let duration = Math.ceil(diff / (1000 * 60 * 60 * 24));
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              {imageData?.length > 0 && (
                <img
                  src={
                    profilePic?.length > 0
                      ? `https://${process.env.REACT_APP_S3_BUCKET}.s3.us-east-1.amazonaws.com/public/${profilePic[0]?.quoteAttachmentId}`
                      : `https://${process.env.REACT_APP_S3_BUCKET}.s3.us-east-1.amazonaws.com/public/${imageData[0]?.quoteAttachmentId}`
                  }
                  alt=""
                  style={{
                    height: "50px",
                    width: "50px",
                    marginRight: "10px",
                  }}
                  // onClick={() =>
                  //   this.setState({
                  //     showImages: true,
                  //     imageData: record.materialPicture,
                  //     materialProfilePic:
                  //       record?.materialPicture?.filter((e) => e.profilePic)
                  //         ?.length > 0
                  //         ? record?.materialPicture?.filter(
                  //             (e) => e.profilePic
                  //           )[0]?.id
                  //         : record?.materialPicture[0].id,
                  //     recordData: record,
                  //   })
                  // }
                />
              )}
              <Popover
                content={
                  <Row>
                    <Col span={24}>
                      <span className="col_style_quarry">{value}</span>
                    </Col>
                  </Row>
                }
              >
                <span
                  style={{
                    padding: "3px",
                    display: "flex",
                    flexWrap: "wrap",
                    // marginLeft: "auto",
                    maxWidth: "max-content",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  <BsFillCircleFill
                    style={{
                      color: record.confirm
                        ? duration <= 30
                          ? "green"
                          : duration >= 31 && duration <= 120
                          ? "#ffc902"
                          : "red"
                        : "transparent",
                      fontSize: "8px",
                      marginRight: "5px",
                      height: "auto",
                    }}
                  />
                  <span
                    style={{ color: !record?.statusCode && "red" }}
                  >{`${data[2]}`}</span>
                </span>
              </Popover>
              {record.materialNotes !== "" && (
                <Popover
                  content={
                    <Row>
                      <Col span={24}>
                        <span className="col_style_quarry">
                          {record.materialNotes}
                        </span>
                      </Col>
                    </Row>
                  }
                >
                  <span
                    style={{
                      color: !record?.statusCode && "red",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <BsInfoCircle
                      style={{ fontSize: "17px", marginLeft: "5px" }}
                    />
                  </span>
                </Popover>
              )}
            </div>
          );
          // return <span className="col_style_quarry">{value}</span>;
        },
      },
      {
        title: "Diameter",
        dataIndex: "materialDiameter",
        key: "materialDiameter",
        sorter: false,
        width: "13%",
        className: "col_style_quarry",
        render: (materialDiameter, record) => {
          return (
            <span
              className="col_styling"
              style={{
                color:
                  (!record?.statusCode ||
                    (!record.materialCheckDiameter &&
                      materialDiameter === "Unknown")) &&
                  "red",
                textTransform: "capitalize",
              }}
            >
              {record.materialCheckDiameter
                ? `${record.materialMinDiameter}"` +
                  " - " +
                  `${record?.materialMaxDiameter}"`
                : materialDiameter === "Unknown"
                ? materialDiameter
                : `${materialDiameter}"`}
            </span>
          );
        },
      },
      {
        title: "Price",
        dataIndex: "price",
        key: "price",
        sorter: false,
        width: "10%",
        className: "col_style_quarry",
        render: (price, record) => {
          return (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "fit-content",
                color: !record?.statusCode && "red",
              }}
            >
              <Col
                className="col_style_quarry"
                style={{ color: !record?.statusCode && "red" }}
              >{`$ ${parseFloat(price)?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })} Per ${record?.quantityType}`}</Col>
              {record?.docDetails?.length > 0 && (
                <Col>
                  <Button
                    icon={<PaperClipOutlined />}
                    style={{
                      fontSize: "15px",
                      cursor: "pointer",
                      border: "none",
                      backgroundColor: "transparent",
                    }}
                    onClick={() => this.showDetailModal(record)}
                  ></Button>
                </Col>
              )}
            </Row>
          );
        },
      },

      {
        title: "Washed",
        dataIndex: "materialStatus",
        key: "materialStatus",
        sorter: false,
        width: "10%",
        className: "col_style_quarry",
        render: (materialStatus, record) => {
          return (
            <span
              className="col_styling"
              style={{
                color: !record?.statusCode && "red",
                textTransform: "capitalize",
              }}
            >
              {materialStatus}
            </span>
          );
        },
      },
      {
        title: "Testing",
        dataIndex: "materialTest",
        key: "materialTest",
        sorter: false,
        width: "10%",
        className: "col_style_quarry",
        render: (materialTest, record) => {
          return (
            <span
              className="col_styling"
              style={{
                color: !record?.statusCode && "red",
                textTransform: "capitalize",
              }}
            >
              {materialTest}
            </span>
          );
        },
      },
      {
        title: "Material Tax",
        dataIndex: "materialTax",
        key: "materialTax",
        sorter: false,
        width: "15%",
        className: "col_style_quarry",
        render: (materialTax, record) => {
          return materialTax ? (
            <span
              className="col_styling"
              style={{
                color: materialTax === "Unknown" ? "red" : "",
              }}
            >
              {materialTax}
            </span>
          ) : (
            <span
              style={{
                color: "red",
                cursor: "text",
              }}
            >
              --EMPTY--
            </span>
          );
        },
      },
      {
        title: "Supply",
        dataIndex: "materialSupply",
        key: "materialSupply",
        sorter: false,
        width: "10%",
        className: "col_style_quarry",
        render: (materialSupply, record) => {
          return (
            <span
              className="col_styling"
              style={{
                color: !record?.statusCode && "red",

                textTransform: "capitalize",
              }}
            >
              {materialSupply}
            </span>
          );
        },
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "",
        sorter: false,
        className: "col_style_quarry",
        width: "10%",
        render: (record) => {
          return (
            <Col>
              <Popover
                placement="left"
                content={
                  <Row className="popovergrid" style={{ maxWidth: "100px" }}>
                    <Col span={24}>
                      <Button
                        className="btn_edit"
                        onClick={() =>
                          this.setState({
                            showImages: true,
                            imageData: record.materialPicture,
                            materialProfilePic:
                              record?.materialPicture?.filter(
                                (e) => e.profilePic
                              )?.length > 0
                                ? record?.materialPicture?.filter(
                                    (e) => e.profilePic
                                  )[0]?.id
                                : record?.materialPicture[0]?.id,
                            recordData: record,
                            materialAttachment: [],
                          })
                        }
                        // onClick={() => this.updateRecord(record)}
                      >
                        <span
                          className="span_edit"
                          style={{
                            color: "black",
                            height: "auto",
                          }}
                        >
                          <BsCardImage
                            style={{
                              marginRight: "5px",
                            }}
                          />{" "}
                          Pictures
                        </span>
                      </Button>
                    </Col>
                    <Col span={24}>
                      <Button
                        className="btn_edit"
                        onClick={() => this.updateRecord(record)}
                      >
                        <span
                          className="span_edit"
                          style={{
                            color: "black",
                            height: "auto",
                          }}
                        >
                          <CheckCircleOutlined
                            style={{
                              marginRight: "5px",
                            }}
                          />{" "}
                          Confirm
                        </span>
                      </Button>
                    </Col>
                    <Col span={24}>
                      <Button
                        className="btn_edit"
                        onClick={() => this.editMaterial(record)}
                      >
                        <span className="span_edit">
                          <MdModeEditOutline
                            style={{
                              marginRight: "5px",
                            }}
                          />{" "}
                          Edit
                        </span>
                      </Button>
                    </Col>
                    <Col span={24}>
                      <Button className="btn_edit">
                        <Popconfirm
                          title="Are you sure？"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => this.deleteMaterial(record)}
                          // onConfirm={() => this.deleteCompany(record)}
                        >
                          <span className="span_edit">
                            <MdDelete
                              style={{
                                marginRight: "5px",
                              }}
                            />
                            Delete
                          </span>
                        </Popconfirm>
                      </Button>
                    </Col>
                  </Row>
                }
              >
                <EllipsisOutlined
                  style={{
                    fontSize: "30px",
                    color: "grey",
                  }}
                  className="action-ellipsis-button"
                />
              </Popover>
            </Col>
          );
        },
      },
    ];

    const contactsColumns = [
      {
        title: "Contact Name",
        dataIndex: "firstName",
        key: "firstName",
        sorter: false,
        width: "20%",
        className: "col_style_quarry",
        render: (firstName, record) => {
          return {
            props: {
              style: {
                background: record.isPrimaryContact ? "#dcf4ff" : "white",
              },
            },
            children: (
              <span className={"col_style_quarry"}>
                {record.isPrimaryContact
                  ? firstName + " " + record.lastName + " (Primary)"
                  : firstName + " " + record.lastName}
              </span>
            ),
          };
        },
      },
      {
        title: "Role",
        dataIndex: "contactRole",
        key: "contactRole",
        sorter: false,
        width: "20%",
        className: "col_style_quarry",
        render: (contactRole, record) => {
          return {
            props: {
              style: {
                background: record.isPrimaryContact ? "#dcf4ff" : "white",
              },
            },
            children: <span className={"col_style_quarry"}>{contactRole}</span>,
          };
        },
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        sorter: false,
        className: "col_style_quarry",
        width: "15%",
        render: (phone, record) => {
          return {
            props: {
              style: {
                background: record.isPrimaryContact ? "#dcf4ff" : "white",
              },
            },
            children: <span className={"col_style_quarry"}>{phone}</span>,
          };
        },
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        sorter: false,
        className: "col_style_quarry",
        width: "35%",
        render: (email, record) => {
          return {
            props: {
              style: {
                background: record.isPrimaryContact ? "#dcf4ff" : "white",
              },
            },
            children: <span className={"col_style_quarry"}>{email}</span>,
          };
        },
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "",
        sorter: false,
        className: "col_style_quarry",
        width: "15%",
        render: (record) => {
          return {
            props: {
              style: {
                background: record.isPrimaryContact ? "#dcf4ff" : "white",
              },
            },
            children: (
              <Col>
                <Popover
                  placement="left"
                  content={
                    <Row className="popovergrid" style={{ maxWidth: "124px" }}>
                      <Col span={24}>
                        <Button
                          className="btn_edit"
                          onClick={() => this.editContactInfo(record)}
                        >
                          <span className="span_edit">
                            <MdModeEditOutline style={{ marginRight: "5px" }} />
                            Edit
                          </span>
                        </Button>
                      </Col>
                      <Col span={24}>
                        <Button className="btn_edit">
                          <Popconfirm
                            title="Are you sure？"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.deleteContactInfo(record?.id)}
                          >
                            <span className="span_edit">
                              <MdDelete style={{ marginRight: "5px" }} /> Delete
                            </span>
                          </Popconfirm>
                        </Button>
                      </Col>
                      {!record?.isPrimaryContact && (
                        <Col span={24}>
                          <Button
                            className="btn_edit"
                            onClick={() => this.onSelectChange(record.id)}
                          >
                            <span
                              className="span_edit"
                              style={{
                                fontSize: "13px",
                              }}
                            >
                              <ContactsOutlined
                                style={{ marginRight: "5px" }}
                              />{" "}
                              Set as Primary
                            </span>
                          </Button>
                        </Col>
                      )}
                    </Row>
                  }
                >
                  <EllipsisOutlined
                    style={{ fontSize: "30px", color: "grey" }}
                    className="action-ellipsis-button"
                  />
                </Popover>
              </Col>
              // <Col>
              //   <Popover
              //     placement="left"
              //     content={
              //       <Col>
              //         <Row className="popovergrid">
              //           <Col
              //             span={24}
              //             onClick={() => this.editModal(record)}
              //             style={{ cursor: "pointer" }}
              //           >
              //             <div className="icon-alignmentit">
              //               <MdModeEditOutline /> Edit
              //             </div>
              //           </Col>
              //           <Col
              //             span={24}
              //             style={{ cursor: "pointer" }}
              //             onClick={() => this.deleteContact(record)}
              //           >
              //             <div className="icon-alignmentit">
              //               <MdDelete /> Delete
              //             </div>
              //           </Col>
              //         </Row>
              //       </Col>
              //     }
              //   >
              //     <EllipsisOutlined style={{ fontSize: "30px", color: "grey" }} />
              //   </Popover>
              // </Col>
            ),
          };
        },
      },
    ];

    const invoicesColumns = [
      {
        title: "Ticket Date",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: false,
        width: "10%",
        className: "col_style_quarry",
        onCell: (record) => ({
          onDoubleClick: () =>
            (window.location = `/app/Invoice-Management/${record.invoiceId}`),
        }),
        render: (createdAt) => {
          return (
            <span className="col_style_quarry">
              {moment(createdAt).format("L")}
            </span>
          );
        },
      },
      {
        title: "Ticket Number",
        dataIndex: "truckNumber",
        key: "truckNumber",
        sorter: false,
        width: "10%",
        className: "col_style_quarry",
        onCell: (record) => ({
          onDoubleClick: () =>
            (window.location = `/app/Invoice-Management/${record.invoiceId}`),
        }),
        render: (truckNumber) => {
          return <span className="col_style_quarry">{truckNumber}</span>;
        },
      },
      {
        title: "Material Type",
        dataIndex: "",
        key: "",
        sorter: false,
        width: "15%",
        className: "col_style_quarry",
        onCell: (record) => ({
          onDoubleClick: () =>
            (window.location = `/app/Invoice-Management/${record.invoiceId}`),
        }),
        render: (record) => {
          let data = record?.vendor?.map((v) => v?.value)[0]?.split(" - ");
          return (
            <span className="col_style_quarry">{`${data[0]} - ${data[2]}`}</span>
          );
        },
      },
      {
        title: "Material Cost",
        dataIndex: "ticketsTotal",
        key: "ticketsTotal",
        sorter: false,
        width: "10%",
        className: "col_style_quarry",
        onCell: (record) => ({
          onDoubleClick: () =>
            (window.location = `/app/Invoice-Management/${record.invoiceId}`),
        }),
        render: (ticketsTotal) => {
          return (
            <span className="col_style_quarry">
              {`$${parseInt(ticketsTotal)?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}`}
            </span>
          );
        },
      },
      // {
      //   title: "Due to Vendor",
      //   dataIndex: "",
      //   key: "",
      //   sorter: false,
      //   width: "10%",
      //   className: "col_style_quarry",
      //   render: (record) => {
      //     return (
      //       <span className="col_style_quarry">
      //         {record?.vendor &&
      //           "$ " + parseInt(record?.vendor[0]?.materialCost) + ".00"}
      //       </span>
      //     );
      //   },
      // },
      {
        title: "Due Date",
        dataIndex: "APMinvoiceStatus",
        key: "APMinvoiceStatus",
        sorter: false,
        width: "10%",
        className: "col_style_quarry",
        onCell: (record) => ({
          onDoubleClick: () =>
            (window.location = `/app/Invoice-Management/${record.invoiceId}`),
        }),
        render: (APMinvoiceStatus, record) => {
          return (
            <span className="col_style_quarry">
              {APMinvoiceStatus === "Unpaid"
                ? `${moment(
                    moment().add(parseInt(record?.APMdueDays), "days")
                  ).format("L")}`
                : "EMPTY"}
            </span>
          );
        },
      },
      {
        title: "Payment Status",
        dataIndex: "APMinvoiceStatus",
        key: "APMinvoiceStatus",
        sorter: false,
        width: "10%",
        className: "col_style_quarry",
        onCell: (record) => ({
          onDoubleClick: () =>
            (window.location = `/app/Invoice-Management/${record.invoiceId}`),
        }),
        render: (APMinvoiceStatus, record) => {
          // APMinvoiceStatus === "Unpaid" ? `Due on: ${moment().add(record?.APdueDays, "days")` : APMinvoiceStatus
          return (
            <span className="col_style_quarry">
              {APMinvoiceStatus}
              {/* {APMinvoiceStatus === "Unpaid"
                ? `Due on: ${moment(
                    moment().add(parseInt(record?.APdueDays), "days")
                  ).format("L")}`
                : APMinvoiceStatus} */}
            </span>
          );
        },
      },
      {
        title: "Attachment",
        dataIndex: "invoicePath",
        key: "invoicePath",
        sorter: false,
        width: "4%",
        className: "col_style_quarry",
        render: (invoicePath, record) => {
          return (
            <span className="col_style_quarry">
              {invoicePath !== undefined &&
                invoicePath !== "" &&
                invoicePath !== null && (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.fileDownloadQuote(
                        invoicePath,
                        "Attachment",
                        "invoicePath"
                      );
                    }}
                  >
                    <Row
                      style={{
                        color: "#7B8190",
                        fontSize: "13px",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        flexDirection: "coloumn",
                        width: "75px",
                      }}
                    >
                      <HiOutlineDocumentArrowDown
                        style={{
                          fontSize: "30px",
                          height: "30px",
                          marginRight: "5px",
                        }}
                      />
                      {/* <span style={{ marginTop: "6px" }}>Download</span> */}
                    </Row>
                  </span>
                )}
            </span>
          );
        },
      },
      // {
      //   title: "Attachments",
      //   dataIndex: "invoiceAttachment",
      //   key: "attachment",
      //   sorter: false,
      //   className: "col_style_quarry",
      //   render: (attachment) => {
      //     return <span className="col_style_quarry">{attachment}</span>;
      //   },
      // },
    ];

    const docuColumn = [
      {
        title: "Upload Date",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: false,
        width: "10%",
        className: "col_style_quarry",
        render: (createdAt) => {
          return (
            <span className="col_style_quarry">
              {moment(createdAt).format("L")}
            </span>
          );
        },
      },
      {
        title: "Document Name",
        dataIndex: "documentName",
        key: "documentName",
        sorter: false,
        width: "20%",
        className: "col_style_quarry",
        render: (documentName) => {
          return <span className="col_style_quarry">{documentName}</span>;
        },
      },
      {
        title: "Document Description",
        dataIndex: "description",
        key: "description",
        sorter: false,
        width: "40%",
        className: "col_style_quarry",
        render: (description) => {
          return <span className="col_style_quarry">{description}</span>;
        },
      },
      {
        title: "Attachment",
        dataIndex: "docAttachment",
        key: "docAttachment",
        sorter: false,
        width: "10%",
        className: "col_style_quarry",
        render: (docAttachment, record) => {
          // console.log(docAttachment, "docAttachment");
          return (
            <span
              className="col_style_quarry"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              {docAttachment?.map((d) => (
                <Popover placement="left" content={d.fileName}>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.fileDownload(
                        d?.quoteAttachmentId,
                        "Attachment",
                        "docAttachment"
                      );
                    }}
                  >
                    <Row
                      style={{
                        color: "#7B8190",
                        fontSize: "13px",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        flexDirection: "coloumn",
                        width: "75px",
                      }}
                    >
                      {/* {record?.fileType === "image/jpeg" ||
                    record?.fileType === "image/png" ? (
                      <img
                        src={`${d?.quoteAttachment}`}
                        alt="avatar"
                        style={{ width: "75%", height: "100%" }}
                      />
                    ) : (
                      <> */}
                      <HiOutlineDocumentArrowDown
                        style={{
                          fontSize: "30px",
                          height: "30px",
                          // marginRight: "5px",
                        }}
                      />
                      {/* <span style={{ marginTop: "6px" }}>Download</span> */}
                      {/* </>
                    )} */}
                    </Row>
                  </span>
                </Popover>
              ))}
            </span>
          );
        },
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "",
        sorter: false,
        className: "col_style_quarry",
        width: "5%",
        render: (record) => {
          return (
            <Col>
              <Popover
                placement="left"
                content={
                  <Row className="popovergrid" style={{ maxWidth: "90px" }}>
                    <Col span={24}>
                      <Button
                        className="btn_edit"
                        onClick={() => this.editDoc(record)}
                      >
                        <span className="span_edit">
                          <MdModeEditOutline
                            style={{
                              marginRight: "5px",
                            }}
                          />{" "}
                          Edit
                        </span>
                      </Button>
                    </Col>
                    <Col span={24}>
                      <Button className="btn_edit">
                        <Popconfirm
                          title="Are you sure？"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => this.deleteDoc(record)}
                          // onConfirm={() => this.deleteCompany(record)}
                        >
                          <span className="span_edit">
                            <MdDelete
                              style={{
                                marginRight: "5px",
                              }}
                            />
                            Delete
                          </span>
                        </Popconfirm>
                      </Button>
                    </Col>
                  </Row>
                }
              >
                <EllipsisOutlined
                  style={{
                    fontSize: "30px",
                    color: "grey",
                  }}
                  className="action-ellipsis-button"
                />
              </Popover>
            </Col>
          );
        },
      },
    ];

    const notesColumns = [
      {
        title: "Date Created",
        dataIndex: "date",
        key: "date",
        sorter: false,
        width: "18%",
        className: "col_style_quarry",
        render: (date) => {
          return <span className="col_style_quarry">{date}</span>;
        },
      },
      {
        title: "Category",
        dataIndex: "typeofNotes",
        key: "typeofNotes",
        sorter: false,
        className: "col_style_quarry",
        width: "25%",
        render: (typeofNotes) => {
          return <span className="col_style_quarry">{typeofNotes}</span>;
        },
      },
      {
        title: "Note",
        dataIndex: "notes",
        key: "notes",
        sorter: false,
        className: "col_style_quarry",
        render: (notes) => {
          return <span className="col_style_quarry">{notes}</span>;
        },
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "",
        sorter: false,
        className: "col_style_quarry",
        width: "10%",
        render: (record) => {
          return (
            <Col>
              <Popover
                placement="left"
                content={
                  <Row className="popovergrid" style={{ maxWidth: "90px" }}>
                    <Col span={24}>
                      <Button
                        className="btn_edit"
                        onClick={() => this.editNotes(record)}
                      >
                        <span className="span_edit">
                          <MdModeEditOutline
                            style={{
                              marginRight: "5px",
                            }}
                          />{" "}
                          Edit
                        </span>
                      </Button>
                    </Col>
                    <Col span={24}>
                      <Button className="btn_edit">
                        <Popconfirm
                          title="Are you sure？"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => this.deleteNotes(record?.id)}
                          // onConfirm={() => this.deleteCompany(record)}
                        >
                          <span className="span_edit">
                            <MdDelete
                              style={{
                                marginRight: "5px",
                              }}
                            />
                            Delete
                          </span>
                        </Popconfirm>
                      </Button>
                    </Col>
                  </Row>
                }
              >
                <EllipsisOutlined
                  style={{
                    fontSize: "30px",
                    color: "grey",
                  }}
                  className="action-ellipsis-button"
                />
              </Popover>
            </Col>
          );
        },
      },
    ];

    // const content = (
    //   <div>
    //     {statusData.map((e) => (
    //       <Row
    //         onClick={() => this.onClickStageId(e)}
    //         style={{
    //           cursor: "pointer",
    //         }}
    //       >
    //         <Col
    //           style={{
    //             fontSize: "13px",
    //             fontFamily: "sans-serif",
    //           }}
    //         >
    //           <Button
    //             style={{
    //               width: "180px",
    //               marginBottom: "5px",
    //               background: e === selectedStatus ? "#1890ff" : "#FFF",
    //               color: e === selectedStatus ? "#FFF" : "#1890ff",
    //             }}
    //           >
    //             {e}
    //           </Button>
    //         </Col>
    //       </Row>
    //     ))}
    //   </div>
    // );

    return (
      <>
        <Loading enableLoading={loading || loadingDownload} />

        <Row
          style={{
            backgroundColor: "#fafafa",
            height: "60px",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={this.onClickReturn}
        >
          <BsArrowLeftShort
            style={{ color: "#808080", fontSize: "23px" }}
            className="back-button-return-icon"
          />
          <p
            style={{
              color: "#5f6c74",
              fontSize: "16px",
              fontWeight: "600",
            }}
            className="back-button-return"
          >
            Return
          </p>
        </Row>

        <div
          style={{
            background: "#fafafa",
          }}
        >
          {errorLoading ? (
            <Col
              xxl={{ span: 24 }}
              xl={{ span: 24 }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <ErrorView
                refreshLeadDetails={this.onRefresh}
                type="LeadDetails"
              />
            </Col>
          ) : (
            <Row style={{ marginBottom: "1vw" }} gutter={[12, 0]}>
              <Col
                // span={8}
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 8 }}
                xxl={{ span: 8 }}
                data-aos={window.screen.width > 1023 ? "fade-right" : ""}
              >
                <Card>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "-12px",
                        right: "0px",
                        border: "0.5px solid #aaa8a8",
                        width: "2vw",
                        height: "2vw",
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        alignContent: "center",
                        justifyContent: " center",
                        padding: "8px",
                        borderRadius: "100%",
                      }}
                      className="edit-details-button"
                    >
                      <Button
                        style={{
                          border: "none",
                          cursor: "pointer",
                          // marginTop: "-90px",
                          backgroundColor: "transparent",
                        }}
                        onClick={() =>
                          this.setState({
                            isModalVisible: true,
                            // selectedTerm: resultData?.selectedTerm,
                            editModal: true,
                            // selectedMaterial: resultData?.material,
                            // contactInfo: resultData?.contactDetails,
                            // quantityType: resultData?.quantityType,
                            selectedAddress: resultData?.address,
                            latitude: resultData?.lat,
                            longitude: resultData?.lng,
                            showLocationInput:
                              resultData?.address !== "" ? true : false,
                          })
                        }
                      >
                        <EditOutlined
                          style={{
                            color: "#586370",
                            fontSize: "125%",
                            fontWeight: "600",
                          }}
                        />
                      </Button>
                    </div>
                    <div className="linkcompany">
                      <p
                        className="detail-ans"
                        style={{
                          fontWeight: "800",
                          fontSize: "20px",
                          color: "#0d0f10",
                          textTransform: "capitalize",
                          marginBottom: "5px",
                        }}
                      >
                        {resultData?.quarryName}
                      </p>
                    </div>
                  </div>
                  {/* <p
                    span={24}
                    className="detail-ans"
                    style={{
                      fontWeight: "600",
                      fontSize: "18px",
                      borderBottom: "1px solid #afafaf",
                      width: "fit-content",
                    }}
                  >
                    Quarry Details
                  </p> */}
                  <Row
                    span={24}
                    style={{
                      marginBottom: "8px",
                    }}
                  >
                    <Col
                      span={8}
                      className="detail-heading table-font-mobile"
                      style={{ fontSize: "16px" }}
                    >
                      Nick Name
                    </Col>
                    <Col span={16} className="detail-ans">
                      {resultData?.quarryNickName}
                    </Col>
                  </Row>
                  <Row
                    span={24}
                    style={{
                      marginBottom: "8px",
                      // borderBottom: "2px ridge #e6e6e6",
                    }}
                  >
                    <Col
                      span={8}
                      className="detail-heading table-font-mobile"
                      style={{ fontSize: "16px" }}
                    >
                      Location
                    </Col>
                    <Col span={16} className="detail-ans">
                      {/* {resultData.lat + " " + resultData.lng} */}
                      {resultData?.address !== "" &&
                      resultData?.address !== undefined
                        ? resultData.address
                        : `${resultData.lat}, ${resultData?.lng}`}
                      {/* {resultData?.lat && resultData?.lat},
                      {resultData?.lng && resultData?.lng} */}
                    </Col>
                  </Row>
                  {/* <Row
                    span={24}
                    style={{
                      marginBottom: "8px",
                      // borderBottom: "2px ridge #e6e6e6",
                    }}
                  >
                    <Col
                      span={8}
                      className="detail-heading"
                      style={{ fontSize: "16px" }}
                    >
                      Co-ordinates
                    </Col>
                    <Col span={16} className="detail-ans">
                      {resultData?.lat &&
                        resultData?.lat + ", " + resultData?.lng}
                    </Col>
                  </Row> */}
                  <Row
                    span={24}
                    style={{
                      marginBottom: "8px",
                      // borderBottom: "2px ridge #e6e6e6",
                    }}
                  >
                    <Col
                      span={8}
                      className="detail-heading table-font-mobile"
                      style={{ fontSize: "16px" }}
                    >
                      Phone Number
                    </Col>
                    <Col span={16} className="detail-ans">
                      {resultData?.phone && resultData?.phone}
                    </Col>
                  </Row>
                  <Row
                    span={24}
                    style={{
                      marginBottom: "8px",
                      // borderBottom: "2px ridge #e6e6e6",
                    }}
                  >
                    <Col
                      span={8}
                      className="detail-heading table-font-mobile"
                      style={{ fontSize: "16px" }}
                    >
                      Type
                    </Col>
                    <Col span={16} className="detail-ans">
                      {resultData?.quarryType &&
                        resultData?.quarryType?.map((e) => e?.key)?.join(", ")}
                    </Col>
                  </Row>
                  {(resultData?.startTime || resultData?.endTime) && (
                    <Row
                      span={24}
                      style={{
                        marginBottom: "8px",
                        // borderBottom: "2px ridge #e6e6e6",
                      }}
                    >
                      <Col
                        span={8}
                        className="detail-heading table-font-mobile"
                        style={{ fontSize: "16px" }}
                      >
                        Quarry Hours
                      </Col>
                      <Col span={16} className="detail-ans">
                        {resultData?.startTime && resultData?.endTime
                          ? moment(resultData?.startTime).format("hh:mm A") +
                            " - " +
                            moment(resultData?.endTime).format("hh:mm A")
                          : resultData?.startTime
                          ? moment(resultData?.startTime).format("hh:mm A") +
                            " (Pit Open)"
                          : resultData?.endTime &&
                            moment(resultData?.endTime).format("hh:mm A") +
                              " (Pit Close)"}
                      </Col>
                    </Row>
                  )}
                </Card>
                <div className="lead_detailmap" style={{ marginTop: "25px" }}>
                  {quarryData?.length > 0 && (
                    <MapComponent
                      isMarkerShown
                      quarryData={quarryData}
                      // jobProfile
                      singleValues
                      disableScroll
                    />
                  )}
                </div>

                <Card style={{ marginTop: "25px" }}>
                  {/* <p
                    span={24}
                    className="detail-ans"
                    style={{
                      fontWeight: "600",
                      fontSize: "18px",
                      borderBottom: "1px solid #afafaf",
                      width: "fit-content",
                    }}
                  >
                    Quarry Details
                  </p> */}
                  {/* <Row
                    span={24}
                    style={{
                      marginBottom: "8px",
                      // borderBottom: "2px ridge #e6e6e6",
                    }}
                  >
                    <Col
                      span={8}
                      className="detail-heading"
                      style={{ fontSize: "16px" }}
                    >
                      Type
                    </Col>
                    <Col span={16} className="detail-ans">
                      {resultData?.quarryType &&
                        resultData?.quarryType?.map((e) => e?.key)?.join(", ")}
                    </Col>
                  </Row> */}
                  {/* <Row
                    span={24}
                    style={{
                      marginBottom: "8px",
                      // borderBottom: "2px ridge #e6e6e6",
                    }}
                  >
                    <Col
                      span={8}
                      className="detail-heading"
                      style={{ fontSize: "16px" }}
                    >
                      Location
                    </Col>
                    <Col span={16} className="detail-ans">
                      {resultData?.address && resultData?.address}
                    </Col>
                  </Row> */}
                  {/* <Row
                    span={24}
                    style={{
                      marginBottom: "8px",
                      // borderBottom: "2px ridge #e6e6e6",
                    }}
                  >
                    <Col
                      span={8}
                      className="detail-heading"
                      style={{ fontSize: "16px" }}
                    >
                      Phone Number
                    </Col>
                    <Col span={16} className="detail-ans">
                      {resultData?.phone && resultData?.phone}
                    </Col>
                  </Row> */}
                  <p
                    span={24}
                    className="detail-ans"
                    style={{
                      fontWeight: "600",
                      fontSize: "18px",
                      borderBottom: "1px solid #afafaf",
                      width: "fit-content",
                    }}
                  >
                    Account Settings
                  </p>
                  {/* <Row
                    style={{
                      marginBottom: "8px",
                      // borderBottom: "2px ridge #e6e6e6",
                    }}
                  >
                    <Col
                      span={8}
                      className="detail-heading"
                      style={{ fontSize: "16px" }}
                    >
                      Charge Account
                    </Col>
                    <Col span={16} className="detail-ans">
                      {resultData?.chargeAccount === true ? "Yes" : "No"}
                    </Col>
                  </Row> */}
                  <Row
                    style={{
                      marginBottom: "8px",
                      // borderBottom: "2px ridge #e6e6e6",
                    }}
                  >
                    <Col
                      span={8}
                      className="detail-heading table-font-mobile"
                      style={{ fontSize: "16px" }}
                    >
                      Terms
                    </Col>
                    <Col span={16} className="detail-ans">
                      {resultData?.accountTerms && resultData?.accountTerms}
                    </Col>
                  </Row>
                  <Row
                    style={{
                      marginBottom: "8px",
                      // borderBottom: "2px ridge #e6e6e6",
                    }}
                  >
                    <Col
                      span={8}
                      className="detail-heading table-font-mobile"
                      style={{ fontSize: "16px" }}
                    >
                      Early Pay
                    </Col>
                    <Col span={16} className="detail-ans">
                      {resultData?.payDiscount &&
                        resultData?.payDiscountTerms &&
                        resultData?.payDiscount +
                          "% " +
                          resultData?.payDiscountTerms}
                    </Col>
                  </Row>
                  <Row
                    style={{
                      marginBottom: "8px",
                      // borderBottom: "2px ridge #e6e6e6",
                    }}
                  >
                    <Col
                      span={8}
                      className="detail-heading table-font-mobile"
                      style={{ fontSize: "16px" }}
                    >
                      Charge Limit
                    </Col>
                    <Col span={16} className="detail-ans">
                      {resultData?.accountLimit && resultData?.accountLimit}
                    </Col>
                  </Row>
                  <p
                    span={24}
                    className="detail-ans"
                    style={{
                      fontWeight: "600",
                      fontSize: "18px",
                      borderBottom: "1px solid #afafaf",
                      width: "fit-content",
                    }}
                  >
                    Account Status
                  </p>
                  <Row
                    span={24}
                    style={{
                      marginBottom: "8px",
                      // borderBottom: "2px ridge #e6e6e6",
                    }}
                  >
                    <Col
                      span={8}
                      className="detail-heading table-font-mobile"
                      style={{ fontSize: "16px" }}
                    >
                      Balance
                    </Col>
                    <Col span={16} className="detail-ans">
                      {resultData?.currentBalance &&
                        `$${parseFloat(resultData?.currentBalance)?.toFixed(
                          2
                        )}`}
                    </Col>
                  </Row>
                  <Row
                    span={24}
                    style={{
                      marginBottom: "8px",
                      // borderBottom: "2px ridge #e6e6e6",
                    }}
                  >
                    <Col
                      span={8}
                      className="detail-heading table-font-mobile"
                      style={{ fontSize: "16px" }}
                    >
                      Overdue
                    </Col>
                    <Col span={16} className="detail-ans">
                      {resultData?.overDueBalance &&
                        `$${parseFloat(resultData?.overDueBalance)?.toFixed(
                          2
                        )}`}
                    </Col>
                  </Row>
                  <Row
                    span={24}
                    style={{
                      marginBottom: "8px",
                      // borderBottom: "2px ridge #e6e6e6",
                    }}
                  >
                    <Col
                      span={8}
                      className="detail-heading table-font-mobile"
                      style={{ fontSize: "16px" }}
                    >
                      Avg Days to Pay
                    </Col>
                    <Col span={16} className="detail-ans">
                      {resultData?.averageDayToPay &&
                        resultData?.averageDayToPay}
                    </Col>
                  </Row>
                  <p
                    className="detail-ans"
                    style={{ fontWeight: "600", marginBottom: "-3px" }}
                  >
                    Notes
                  </p>
                  <div
                    style={{
                      minHeight: "65px",
                      borderRadius: "3px",
                      backgroundColor: "#e3e7ea",
                      padding: "6px",
                    }}
                    className="detail-ans"
                  >
                    {resultData?.notes && resultData?.notes}
                  </div>
                </Card>
              </Col>

              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 16, offset: 0 }}
                xl={{ span: 16, offset: 0 }}
                xxl={{ span: 16, offset: 0 }}
                data-aos={window.screen.width > 1023 ? "fade-left" : ""}
                // span={15} offset={1}
              >
                <Card
                  className="card_virtual"
                  bodyStyle={{ padding: "0px" }}
                  style={{ background: "#eff1f3", marginBottom: "10px" }}
                >
                  <Tabs
                    defaultActiveKey="1"
                    className="ant-card"
                    style={{ borderRadius: "5px" }}
                    onChange={(e) => {
                      this.setState({
                        keyValue: e,
                        materialKey: "",
                        tabKey: "",
                      });
                    }}
                    tabBarExtraContent={
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        {/* {this.state?.keyValue === "1" && (
                        <Select
                          size="medium"
                          style={{
                            width: "160px",
                            borderRadius: "6px",
                            // height: "35px",
                            marginRight: "10px",
                          }}
                          onChange={this.filterMaterials}
                          placeholder="Filter Materials"
                          defaultValue="All"
                          // className="select_Height"
                        >
                          {materialsCategoryData?.map((eachItem) => (
                            <Option
                              key={eachItem?.id}
                              value={eachItem?.label}
                              className="option_style"
                            >
                              {eachItem?.label}
                            </Option>
                          ))}
                        </Select>
                      )} */}
                        <Button
                          type="primary"
                          htmlType="submit"
                          icon={
                            this.state?.keyValue === "3" ? (
                              <IoFilterSharp
                                style={{
                                  fontSize: "15px",
                                  marginRight: "12px",
                                  marginBottom: "-2px",
                                }}
                              />
                            ) : (
                              <PlusOutlined />
                            )
                          }
                          style={{
                            height: "35px",
                            borderRadius: "5px",
                            marginRight: "10px",
                          }}
                          onClick={() => {
                            this.showModal(this.state.keyValue);
                          }}
                        >
                          {this.state?.keyValue === "1"
                            ? "Material"
                            : this.state?.keyValue === "2"
                            ? "Contact"
                            : this.state?.keyValue === "4"
                            ? "Document"
                            : this.state.keyValue === "3"
                            ? "Filter"
                            : "Note"}
                        </Button>
                      </Row>
                    }
                  >
                    <TabPane tab={<span>Material Details</span>} key="1">
                      {Object.keys(groupedData)?.length > 0 ? (
                        <Tabs
                          activeKey={
                            tabKey !== "" ? tabKey : Object.keys(groupedData)[0]
                          }
                          className="ant-card"
                          style={{ borderRadius: "5px" }}
                          onChange={(e) =>
                            this.setState({
                              materialKey: "",
                              tabKey: e,
                            })
                          }
                        >
                          {Object.entries(groupedData).map(
                            ([category, subCategories]) => (
                              <TabPane
                                tab={
                                  <span style={{ fontWeight: "400" }}>
                                    {this.findMaterialTaxCount(
                                      Object.values(subCategories)
                                    ) !== 0 ? (
                                      <span>
                                        {category}
                                        <span style={{ color: "red" }}>
                                          {" ("}
                                          {this.findMaterialTaxCount(
                                            Object.values(subCategories)
                                          )}
                                          {")"}
                                        </span>
                                      </span>
                                    ) : (
                                      category
                                    )}
                                  </span>
                                }
                                key={category}
                              >
                                <Collapse
                                  activeKey={
                                    materialKey !== ""
                                      ? materialKey
                                      : Object.keys(groupedData[category]) !==
                                          undefined &&
                                        Object.keys(groupedData[category])
                                  }
                                  // accordion
                                  onChange={(e) =>
                                    this.setState({
                                      materialKey: e,
                                    })
                                  }
                                  className="quarryProfile_Collapse"
                                >
                                  {Object.entries(subCategories).map(
                                    ([subCategory, items]) => (
                                      // <Panel
                                      //   // header={subCategory.split("-")[1]}
                                      //   key={subCategory}
                                      // >
                                      <BaseTable
                                        columnsData={materialColumns(
                                          subCategory.split("-")[1]
                                        )}
                                        source={items}
                                        total={items?.length}
                                        className="table_laptopscreen table_bottom_style"
                                        // pageSize={["5", "10", "25", "50", "100"]}
                                        // defaultPageSize={5}
                                        handleTableChage={(e) =>
                                          this.handleTableChage(
                                            e,
                                            "quarryDetails"
                                          )
                                        }
                                        sizeRange={
                                          window.screen.width > 1023
                                            ? JSON.parse(
                                                localStorage.getItem(
                                                  "tableSizeRange"
                                                )
                                              )?.quarryDetails
                                            : items?.length
                                        }
                                        pagination={
                                          window.screen.width > 1023
                                            ? true
                                            : false
                                        }
                                      />
                                      // </Panel>
                                    )
                                  )}
                                </Collapse>
                              </TabPane>
                            )
                          )}
                        </Tabs>
                      ) : (
                        <Nodata />
                      )}
                    </TabPane>

                    <TabPane tab={<span>Contacts</span>} key="2">
                      <BaseTable
                        columnsData={contactsColumns}
                        source={resultData?.contactDetails}
                        total={resultData?.contactDetails?.length}
                        className="table_laptopscreen"
                        handleTableChage={(e) =>
                          this.handleTableChage(e, "quarryContactDetails")
                        }
                        sizeRange={
                          window.screen.width > 1023
                            ? JSON.parse(localStorage.getItem("tableSizeRange"))
                                ?.quarryContactDetails
                            : resultData?.contactDetails?.length
                        }
                        pagination={window.screen.width > 1023 ? true : false}
                      />
                    </TabPane>
                    <TabPane tab={<span>Invoices</span>} key="3">
                      <BaseTable
                        className="invoices_tableclass table_laptopscreen"
                        columnsData={invoicesColumns}
                        source={filteredInvoicesData?.filter(
                          (e) => e?.isTruckingAndMaterialJob
                        )}
                        total={
                          filteredInvoicesData?.filter(
                            (e) => e?.isTruckingAndMaterialJob
                          )?.length
                        }
                        handleTableChage={(e) =>
                          this.handleTableChage(e, "quarryInvoiceDetails")
                        }
                        sizeRange={
                          window.screen.width > 1023
                            ? JSON.parse(localStorage.getItem("tableSizeRange"))
                                ?.quarryInvoiceDetails
                            : filteredInvoicesData?.filter(
                                (e) => e?.isTruckingAndMaterialJob
                              )?.length
                        }
                        pagination={window.screen.width > 1023 ? true : false}
                      />
                    </TabPane>
                    <TabPane tab={<span>Documents</span>} key="4">
                      <BaseTable
                        className="invoices_tableclass table_laptopscreen"
                        columnsData={docuColumn}
                        source={resultData?.documentsDetails?.sort(
                          (a, b) => b.createdAt - a.createdAt
                        )}
                        total={resultData?.documentsDetails?.length}
                        handleTableChage={(e) =>
                          this.handleTableChage(e, "quarryDocDetails")
                        }
                        sizeRange={
                          window.screen.width > 1023
                            ? JSON.parse(localStorage.getItem("tableSizeRange"))
                                ?.quarryDocDetails
                            : resultData?.documentsDetails?.length
                        }
                        pagination={window.screen.width > 1023 ? true : false}
                      />
                    </TabPane>
                    <TabPane tab={<span>Notes</span>} key="5">
                      <BaseTable
                        columnsData={notesColumns}
                        source={resultData?.notesDetails}
                        total={resultData?.notesDetails?.length}
                        className="table_laptopscreen"
                        handleTableChage={(e) =>
                          this.handleTableChage(e, "quarryNotesDetails")
                        }
                        sizeRange={
                          window.screen.width > 1023
                            ? JSON.parse(localStorage.getItem("tableSizeRange"))
                                ?.quarryNotesDetails
                            : resultData?.notesDetails?.length
                        }
                        pagination={window.screen.width > 1023 ? true : false}
                      />
                    </TabPane>
                  </Tabs>
                </Card>
              </Col>
            </Row>
          )}
        </div>

        {isModalVisible && (
          <>
            <BaseDrawer
              visible={this.state?.isModalVisible}
              className="quarrytool_modal mapquarry"
              title={this.state?.editModal ? "Update Quarry" : "Add Quarry"}
              onClose={() => {
                this.setState({
                  isModalVisible: false,
                  selectedMaterial: [],
                  selectedTerm: "",
                });
              }}
              formId="Quarry"
              destroyOnClose={true}
              footer={[
                <div style={{ float: "right" }}>
                  <Button
                    onClick={() => {
                      this.setState({
                        isModalVisible: false,
                        selectedMaterial: [],
                      });
                    }}
                    className="cancelbtnd"
                    style={{
                      height: "35px",
                      width: "100px",
                      borderRadius: "5px",
                      marginRight: "25px",
                    }}
                    type="ghost"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    style={{
                      height: "35px",
                      width: "100px",
                      borderRadius: "5px",
                    }}
                    form="Quarry"
                  >
                    Save
                  </Button>
                </div>,
              ]}
            >
              <Form
                layout="vertical"
                onFinish={this.onFinish}
                id="Quarry"
                ref={this.formRef}
              >
                <Row gutter={[24, 0]}>
                  {/* <Col
                    // xxl={{ span: 11, offset: 0 }}
                    // xl={{ span: 11, offset: 0 }}
                    // lg={{ span: 11, offset: 0 }}
                    // md={{ span: 24, offset: 0 }}
                    // sm={{ span: 24, offset: 0 }}
                    // xs={{ span: 24, offset: 0 }}
                    span={24}
                    // offset={0}
                  >
                    <span className="title_changes">Quarry Type</span>
                    <Form.Item
                      name="quarryType"
                      rules={[
                        {
                          required: false,
                          message: "Please Select your Quarry Type!",
                        },
                      ]}
                      initialValue={resultData?.quarryType}
                    >
                      <Select
                        showSearch
                        mode="multiple"
                        allowClear
                        // className="select_Height"
                        style={{
                          height: "auto !important",
                          width: "100% !important",
                        }}
                        className="select_multiplequarytol"
                        onChange={this.selectQuarryType}
                        placeholder="Please Select A Customer Account"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {materialsNewList?.category?.map((eachItem) => (
                          <Option
                            key={eachItem?.code}
                            value={eachItem?.id}
                            className="option_style"
                          >
                            {eachItem?.value}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col> */}
                  <Col span={24}>
                    <span className="title_changes">Quarry Name</span>
                    <Form.Item
                      name="quarryName"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter your Quarry Name!",
                        },
                      ]}
                      initialValue={resultData?.quarryName}
                    >
                      <Input
                        className="form-input"
                        placeholder="Please Enter your Quarry Name"
                        onChange={(e) =>
                          this.setState({ quarryName: e.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <span className="title_changes">Quarry Nick Name</span>
                    <Form.Item
                      name="quarryNickName"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter your Nick Name!",
                        },
                      ]}
                      initialValue={resultData?.quarryNickName}
                    >
                      <Input
                        className="form-input"
                        placeholder="Please Enter your Nick Name"
                        maxLength={12}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <span className="title_changes">Quarry Phone Number</span>
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Phone",
                        },
                      ]}
                      initialValue={resultData?.phone}
                    >
                      <NumberFormat
                        className="ant-input-number numberictest"
                        format="### ###-####"
                        style={{ width: "100%" }}
                        placeholder="Please Enter Your Phone"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Row justify={"space-between"}>
                      <span className="title_changes">
                        {this.state.showLocationInput
                          ? "Location"
                          : "Coordinates"}{" "}
                      </span>

                      <span
                        className="title_changes"
                        style={{ fontSize: "12px" }}
                      >
                        {this.state.showLocationInput ? "Enable" : "Disable"}{" "}
                        Coordinates{" "}
                        <Switch
                          checked={!this.state.showLocationInput}
                          onChange={() => {
                            this.setState({
                              showLocationInput: !this.state.showLocationInput,
                            });
                            this.formRef.current.setFieldsValue({
                              lat: "",
                              lng: "",
                            });
                          }}
                        />
                      </span>
                    </Row>
                  </Col>

                  {this.state.showLocationInput ? (
                    <Col span={24}>
                      <Form.Item
                        name="location"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Your Location!",
                          },
                        ]}
                      >
                        <GooglePlacesAutocomplete
                          className="form-input"
                          autocompletionRequest={{
                            componentRestrictions: {
                              country: ["us"],
                            },
                          }}
                          selectProps={{
                            citySearch,
                            defaultInputValue: `${
                              resultData?.address ? resultData?.address : ""
                            }`,
                            placeholder: "Search Address...",
                            onChange: (address) => {
                              this.handleSelect(address);
                              this.getAddress(address);
                            },
                          }}
                        />
                      </Form.Item>
                    </Col>
                  ) : (
                    <Col span={24}>
                      <Row gutter={[16, 16]}>
                        <Col
                          xxl={{ span: 12 }}
                          xl={{ span: 12 }}
                          lg={{ span: 12 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <span className="title_changes">Latitude</span>
                          <Form.Item
                            name="lat"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Cordinates!",
                              },
                            ]}
                            initialValue={resultData?.lat && resultData?.lat}
                          >
                            <Input
                              type="text"
                              style={{ width: "100%" }}
                              placeholder="Please Paste Cordinates Here"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xxl={{ span: 12 }}
                          xl={{ span: 12 }}
                          lg={{ span: 12 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <span className="title_changes">Longitude</span>
                          <Form.Item
                            name="lng"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Cordinates!",
                              },
                            ]}
                            initialValue={resultData?.lng && resultData?.lng}
                          >
                            <Input
                              type="text"
                              placeholder="Please Paste Cordinates Here"
                            />
                          </Form.Item>
                        </Col>
                        <Col></Col>
                      </Row>
                    </Col>
                  )}

                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={11}
                  >
                    <span className="title_changes">Account Terms</span>
                    <Form.Item
                      name="accountTerms"
                      rules={[
                        {
                          required: true,
                          message: "Select Your Account Terms",
                        },
                      ]}
                      initialValue={resultData?.accountTerms}
                    >
                      <Select
                        size="large"
                        allowClear
                        className="select_multiplequarytol"
                        placeholder="Account Terms"
                        showArrow
                        style={{ width: "100%" }}
                        onChange={this.onSelectAccountTerms}
                      >
                        <Option value="Net 30">Net 30</Option>
                        <Option value="Cash">Cash/Prepaid</Option>
                        <Option value="Custom Input">Custom Input</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  {selectedTerm === "Custom Input" && (
                    <Col
                      xxl={{ span: 24 }}
                      xl={{ span: 24 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      // span={12}
                    >
                      <span className="title_changes">
                        Account Terms (Others)
                      </span>
                      <Form.Item
                        name="accountTermsOthers"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter New Account Terms",
                          },
                        ]}
                      >
                        <NumberFormat
                          className="ant-input-number numberictest"
                          style={{ width: "100%" }}
                          prefix={"Net "}
                          placeholder="Please Enter New Account Terms"
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {/* <>
                    {selectedMaterial?.length > 0 && (
                      <span
                        className="title_changes"
                        style={{
                          marginLeft: "14px",
                          fontSize: "17px",
                          fontWeight: "500",
                          borderBottom: "1px solid #586370",
                        }}
                      >
                        Materials & Quantity:
                      </span>
                    )}

                    <Col span={24}>
                      {selectedMaterial?.map((m) => (
                        <Popover
                          title={null}
                          trigger="hover"
                          placement="bottom"
                          content={
                            <Row>
                              <Col span={24}>
                                <span>{m.label}</span>
                              </Col>
                            </Row>
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "nowrap",
                              alignItems: "center",
                              width: "100%",
                            }}
                            className="loop-hrline"
                          >
                            <span
                              style={{
                                margin: "0px 3px",
                                wordBreak: "break-word",
                                width: "100%",
                                // maxWidth: "625px",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                fontSize: "16px",
                              }}
                            >
                              {m?.label}
                            </span>
                            <span
                              className="title_changes"
                              style={{
                                margin: "0px 3px",
                                wordBreak: "break-word",
                                width: "100%",
                                // maxWidth: "625px",
                                textOverflow: "ellipsis",
                                // whiteSpace: "nowrap",
                                // overflow: "hidden",
                                fontSize: "16px",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "100%",
                                  fontWeight: "400",
                                }}
                              >
                                {` - ($${parseFloat(m?.price)?.toFixed(
                                  2
                                )} per ${m?.quantityType})`}
                              </span>
                            </span>
                            <Col>
                              <Popover
                                placement="left"
                                content={
                                  <Row className="popovergrid">
                                    <Col span={24}>
                                      <Button
                                        style={{
                                          fontSize: "15px",
                                          cursor: "pointer",
                                          border: "none",
                                          padding: "0px",
                                          textAlign: "left",
                                          width: "100%",
                                        }}
                                        onClick={() => this.editMaterial(m)}
                                      >
                                        <span
                                          style={{
                                            color: "black",
                                            display: "flex",
                                            flexWrap: "wrap",
                                            alignItems: "center",
                                            justifyContent: "left",
                                            height: "auto",
                                          }}
                                        >
                                          <MdModeEditOutline
                                            style={{
                                              marginRight: "5px",
                                            }}
                                          />{" "}
                                          Edit
                                        </span>
                                      </Button>
                                    </Col>
                                    <Col span={24}>
                                      <Button
                                        style={{
                                          fontSize: "15px",
                                          cursor: "pointer",
                                          border: "none",
                                          padding: "0px",
                                          textAlign: "left",
                                          width: "100%",
                                        }}
                                      >
                                        <Popconfirm
                                          title="Are you sure？"
                                          okText="Yes"
                                          cancelText="No"
                                          onConfirm={() =>
                                            this.deleteMaterial(m?.id)
                                          }
                                          // onConfirm={() => this.deleteCompany(record)}
                                        >
                                          <span
                                            style={{
                                              color: "black",
                                              display: "flex",
                                              flexWrap: "wrap",
                                              alignItems: "center",
                                              justifyContent: "left",
                                            }}
                                          >
                                            <MdDelete
                                              style={{
                                                marginRight: "5px",
                                              }}
                                            />
                                            Delete
                                          </span>
                                        </Popconfirm>
                                      </Button>
                                    </Col>
                                  </Row>
                                }
                              >
                                <EllipsisOutlined
                                  style={{
                                    fontSize: "30px",
                                    color: "grey",
                                  }}
                                />
                              </Popover>
                            </Col>
                          </div>
                        </Popover>
                      ))}
                    </Col>
                  </> */}
                  {/* <Col span={24}>
                    <Form.Item name="button">
                      <Button
                        icon={<PlusOutlined />}
                        type="primary"
                        style={{
                          width: "100%",
                          fontWeight: "500",
                          height: "40px",
                          marginTop: "25px",
                        }}
                        onClick={
                          () =>
                            // selectedMaterialType !== ""
                            this.setState({
                              materialPopup: true,
                              recordMaterialData: "",
                              quantityType: "Ton",
                            })
                          // : message.info(
                          //     "Please Select Any Category Of Material First!"
                          //   )
                        }
                      >
                        ADD MATERIAL
                      </Button>
                    </Form.Item>
                  </Col> */}

                  {/* {resultData?.contactDetails?.length > 0 && (
                    <Col span={24}>
                      <span
                        className="title_changes"
                        style={{
                          // marginLeft: "14px",
                          fontSize: "17px",
                          fontWeight: "500",
                          borderBottom: "1px solid #586370",
                        }}
                      >
                        Contact Details:
                      </span>
                    </Col>
                  )} */}

                  {/* {contactInfo?.map((c) => (
                    <Col span={24}>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          alignItems: "center",
                        }}
                        className="loop-hrline"
                      >
                        <Popover
                          content={
                            (c?.phone || c?.email) && (
                              <Row>
                                <Col span={24}>
                                  <span>{c.phone}</span>
                                </Col>
                                <Col span={24}>
                                  <span
                                    style={{
                                      color: "black",
                                      display: "flex",
                                      flexWrap: "wrap",
                                      alignItems: "center",
                                      justifyContent: "left",
                                    }}
                                  >
                                    {c.email}
                                  </span>
                                </Col>
                              </Row>
                            )
                          }
                        >
                          <span
                            className="title_changes"
                            style={{
                              margin: "0px 5px",
                              wordBreak: "break-word",
                              width: "100%",
                              maxWidth: "270px",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textTransform: "capitalize",
                              cursor: "pointer",
                            }}
                          >
                            {c.contactRole
                              ? c.firstName +
                                " " +
                                c.lastName +
                                ", " +
                                c.contactRole
                              : c.firstName + " " + c.lastName}
                          </span>
                        </Popover>
                        <Col>
                          <Popover
                            placement="left"
                            content={
                              <Row className="popovergrid">
                                <Col span={24}>
                                  <Button
                                    style={{
                                      fontSize: "15px",
                                      cursor: "pointer",
                                      border: "none",
                                      padding: "0px",
                                      textAlign: "left",
                                      width: "100%",
                                    }}
                                    onClick={() => this.editContactInfo(c)}
                                  >
                                    <span
                                      style={{
                                        color: "black",
                                        display: "flex",
                                        flexWrap: "wrap",
                                        alignItems: "center",
                                        justifyContent: "left",
                                        height: "auto",
                                      }}
                                    >
                                      <MdModeEditOutline
                                        style={{
                                          marginRight: "5px",
                                        }}
                                      />{" "}
                                      Edit
                                    </span>
                                  </Button>
                                </Col>
                                <Col span={24}>
                                  <Button
                                    style={{
                                      fontSize: "15px",
                                      cursor: "pointer",
                                      border: "none",
                                      padding: "0px",
                                      textAlign: "left",
                                      width: "100%",
                                    }}
                                  >
                                    <Popconfirm
                                      title="Are you sure？"
                                      okText="Yes"
                                      cancelText="No"
                                      onConfirm={() =>
                                        this.deleteContactInfo(c.id)
                                      }
                                      // onConfirm={() => this.deleteCompany(record)}
                                    >
                                      <span
                                        style={{
                                          color: "black",
                                          display: "flex",
                                          flexWrap: "wrap",
                                          alignItems: "center",
                                          justifyContent: "left",
                                        }}
                                      >
                                        <MdDelete
                                          style={{
                                            marginRight: "5px",
                                          }}
                                        />{" "}
                                        Delete
                                      </span>
                                    </Popconfirm>
                                  </Button>
                                </Col>
                              </Row>
                            }
                          >
                            <EllipsisOutlined
                              style={{
                                fontSize: "30px",
                                color: "grey",
                              }}
                            />
                          </Popover>
                        </Col>
                      </div>
                    </Col>
                  ))}
                  <Col span={24}>
                    <Form.Item name="button">
                      <Button
                        icon={<PlusOutlined />}
                        type="primary"
                        style={{
                          width: "100%",
                          fontWeight: "500",
                          height: "40px",
                        }}
                        onClick={
                          () =>
                            // this.state.companyCheck !== ""
                            //   ?
                            this.setState({
                              contactPopup: true,
                              recordContactData: "",
                            })
                          // : message.info(
                          //     "Please Enter Your Company Name First!"
                          //   )
                        }
                      >
                        ADD CONTACT
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <span className="title_changes">Notes</span>
                    <Form.Item
                      name="notes"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Notes!",
                        },
                      ]}
                      initialValue={resultData?.notes && resultData?.notes}
                    >
                      <TextArea
                        rows={4}
                        style={{
                          width: "100%",
                          height: "75px",
                          borderColor: "#d6d6d6",
                        }}
                        className="form-input"
                        placeholder="Please Enter Your Notes"
                      />
                    </Form.Item>
                  </Col> */}

                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 12 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={11}
                  >
                    <span className="title_changes">Early Pay Discount</span>
                    <Form.Item
                      initialValue={resultData?.payDiscount}
                      name="payDiscount"
                      rules={[
                        {
                          required: false,
                          message: "Please enter your Discount",
                        },
                      ]}
                    >
                      <InputNumber
                        // prefix=""
                        // suffix={
                        //   <Tooltip title="Ex. 2% Net 7 implies a 2% discount if paid prior to Net 7">
                        //     <HiInformationCircle
                        //       style={{ color: "rgba(0,0,0,.45)" }}
                        //     />
                        //   </Tooltip>
                        // }
                        formatter={(value) => `${value}%`}
                        style={{ width: "100%" }}
                        placeholder="Please enter your Discount"
                        className="form-input"
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 12 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={11}
                  >
                    <span className="title_changes">Discount Terms</span>
                    <Form.Item
                      initialValue={resultData?.payDiscountTerms}
                      name="payDiscountTerms"
                      rules={[
                        {
                          required: false,
                          message: "Please enter your Discount Terms",
                        },
                      ]}
                    >
                      <NumberFormat
                        className="ant-input-number numberictest"
                        style={{ width: "100%" }}
                        prefix={"Net "}
                        placeholder="Please Enter New Account Terms"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 12 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={11}
                  >
                    <span className="title_changes"> Pit Open</span>
                    <Form.Item
                      name="startTime"
                      rules={[
                        {
                          required: false,
                          message: "Please enter your Pit Open",
                        },
                      ]}
                      initialValue={
                        resultData?.startTime && moment(resultData?.startTime)
                      }
                    >
                      <TimePicker
                        placeholder="Select Time"
                        use12Hours
                        minuteStep={15}
                        showSecond={false}
                        focusOnOpen={true}
                        format="hh:mm A"
                        className="time-picker "
                        style={{
                          width: "100%",
                          height: "40px",
                          borderRadius: "5px",
                          fontSize: "500",
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 12 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={11}
                  >
                    <span className="title_changes"> Pit Close</span>
                    <Form.Item
                      name="endTime"
                      rules={[
                        {
                          required: false,
                          message: "Please enter Pit Close",
                        },
                      ]}
                      initialValue={
                        resultData?.endTime && moment(resultData?.endTime)
                      }
                    >
                      <TimePicker
                        placeholder="Select Time"
                        use12Hours
                        minuteStep={15}
                        showSecond={false}
                        focusOnOpen={true}
                        format="hh:mm A"
                        className="time-picker "
                        style={{
                          width: "100%",
                          height: "40px",
                          borderRadius: "5px",
                          fontSize: "500",
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={11}
                  >
                    <span className="title_changes">Account Charge Limit</span>
                    <Form.Item
                      initialValue={resultData?.accountLimit}
                      name="accountLimit"
                      rules={[
                        {
                          required: false,
                          message: "Please enter your Account Limit",
                        },
                      ]}
                    >
                      <NumberFormat
                        className="ant-input-number numberictest"
                        // format="#######"
                        style={{ width: "100%" }}
                        prefix={"$ "}
                        suffix={".00"}
                        thousandSeparator
                        placeholder="Please enter your Account Limit"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <span className="title_changes">Notes</span>
                    <Form.Item
                      name="notes"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Notes!",
                        },
                      ]}
                      initialValue={resultData?.notes && resultData?.notes}
                    >
                      <TextArea
                        rows={4}
                        style={{
                          width: "100%",
                          height: "75px",
                          borderColor: "#d6d6d6",
                        }}
                        className="form-input"
                        placeholder="Please Enter Your Notes"
                      />
                    </Form.Item>
                  </Col>
                  {/* <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={11}
                  >
                    <span className="title_changes">Current Balance</span>
                    <Form.Item
                      initialValue={""}
                      name="currentbalance"
                      rules={[
                        {
                          required: true,
                          message: "Current Balance require",
                        },
                      ]}
                    >
                      <Input
                        // prefix=""
                        // suffix={
                        //   <Tooltip title="Ex. 2% Net 7 implies a 2% discount if paid prior to Net 7">
                        //     <HiInformationCircle
                        //       style={{ color: "rgba(0,0,0,.45)" }}
                        //     />
                        //   </Tooltip>
                        // }
                        style={{ width: "100%" }}
                        placeholder="Please enter your current balance"
                        className="form-input"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={11}
                  >
                    <span className="title_changes">Overdue Balance</span>
                    <Form.Item
                      initialValue={""}
                      name="overduebalance"
                      rules={[
                        {
                          required: true,
                          message: "Overdue Balance require",
                        },
                      ]}
                    >
                      <Input
                        // prefix=""
                        // suffix={
                        //   <Tooltip title="Ex. 2% Net 7 implies a 2% discount if paid prior to Net 7">
                        //     <HiInformationCircle
                        //       style={{ color: "rgba(0,0,0,.45)" }}
                        //     />
                        //   </Tooltip>
                        // }
                        style={{ width: "100%" }}
                        placeholder="Please enter your Overdue Balance "
                        className="form-input"
                      />
                    </Form.Item>
                  </Col> */}
                </Row>
              </Form>
            </BaseDrawer>
          </>
        )}

        {materialPopup && (
          <BaseModal
            title={
              recordMaterialData !== ""
                ? "Update Material Details"
                : "Add New Material"
            }
            onCancel={() => {
              this.setState({
                materialPopup: false,
                materialCheck: false,
                selectedMaterialType: "",
                materialAttachment: [],
                subCategory2: [],
                subCategory1: "",
              });
            }}
            visible={materialPopup}
            destroyOnClose={true}
            formId="addMaterial"
            loading={loading}
            className="antmodal_grid headermodal"
            width={"40%"}
          >
            <Form
              onFinish={this.onFinishMaterial}
              initialValues={{ remember: true }}
              id="addMaterial"
              ref={this.formRef}
            >
              <Row gutter={[24, 0]}>
                <Col span={12}>
                  <span className="title_changes">Material Categories</span>
                  <Form.Item
                    name="cat"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Your Material Category!",
                      },
                    ]}
                    initialValue={selectedMaterialType}
                    style={{ marginBottom: "10px" }}
                  >
                    <Select
                      size="large"
                      showSearch
                      allowClear
                      className="select_Height"
                      // style={{ height: "40px" }}
                      placeholder="Please Select A Material"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(e) => {
                        this.setState({
                          selectedMaterialType: e,
                        });
                        if (e) {
                          this.formRef.current.setFieldsValue({
                            subCategory1: undefined,
                            subCategory2: undefined,
                            price: "",
                          });
                          this.setState({
                            subCategory1: "",
                            subCategory2: [],
                          });
                        }
                      }}
                      // onChange={this.onSelectMaterialCat}
                    >
                      {Object.keys(materialCategory)?.map((c, i) => (
                        <Option key={i} value={c}>
                          {c}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <span className="title_changes">Material Type</span>
                  <Form.Item
                    name="subCategory1"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Material Type!",
                      },
                    ]}
                    initialValue={
                      recordMaterialData?.label?.split(" - ")[0] || "Unknown"
                    }
                    style={{ marginBottom: "10px" }}
                  >
                    <Select
                      size="large"
                      showSearch
                      allowClear
                      className="select_Height"
                      // style={{ height: "40px" }}
                      placeholder="Please Select A Material"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(e, option) => {
                        this.setState({
                          subCategory1: e,
                          subCategory2: option.label,
                          categoryKey: option.labelprop,
                          subShortName: option.labelprop2,
                        });
                        if (e) {
                          this.formRef.current.setFieldsValue({
                            subCategory2: undefined,
                            price: "",
                          });
                        }
                      }}
                      // onChange={this.onSelectMaterial}
                    >
                      {selectedMaterialType &&
                        materialCategory[selectedMaterialType] !== undefined &&
                        Object.values(
                          materialCategory[selectedMaterialType]
                        )?.map((c, i) => (
                          <Option
                            key={i}
                            value={c?.subcat}
                            label={c?.subTypes}
                            labelprop={c?.categoryKey}
                            labelprop2={c?.subShortName}
                          >
                            {c?.subcat}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <span
                    className="title_changes"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Material Name
                  </span>
                  <div className="contactname-multiple">
                    <Form.Item
                      name="subCategory2"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Your Material Name!",
                        },
                      ]}
                      initialValue={
                        subCategory1
                          ? recordMaterialData?.id || "Unknown"
                          : recordMaterialData?.value?.split(" - ")[2] ||
                            "Unknown"
                      }
                      style={{ marginBottom: "10px" }}
                    >
                      <Select
                        size="large"
                        showSearch
                        allowClear
                        className="select_Height select_arrow_none"
                        // style={{ height: "40px" }}
                        placeholder="Please Select A Category2"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onChange={this.onSelectMaterial}
                      >
                        {selectedMaterialType &&
                          subCategory1 &&
                          subCategory2?.map(
                            (c, i) =>
                              c.subType !== "" && (
                                <Option key={i} value={c.id}>
                                  {c.subType}
                                </Option>
                              )
                          )}
                      </Select>
                    </Form.Item>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "auto",
                        padding: "8px",
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                        height: "40px",
                        borderLeft: "1px solid #ddd",
                        zIndex: "+9",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (selectedMaterialType && subCategory1) {
                          this.setState({
                            addCategory: true,
                          });
                        } else {
                          message.info(
                            "Please add Material Category & Material Type"
                          );
                        }
                      }}
                    >
                      <span
                        className="title_changes"
                        style={{ fontSize: "13px", fontWeight: "500" }}
                      >
                        <PlusOutlined
                          style={{ color: "#000", marginRight: "5px" }}
                        />
                        Material
                      </span>
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <span className="title_changes">Price</span>
                  <Form.Item
                    name="price"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Price",
                      },
                    ]}
                    style={{ marginBottom: "10px" }}
                    initialValue={recordMaterialData?.price}
                  >
                    <InputNumber
                      // prefix={"$"}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      precision={2}
                      decimalSeparator={"."} // value={quantity}
                      addonBefore={"$"}
                      addonAfter={selectAfter}
                      placeholder="Please Enter Price"
                      // addonAfter={selectAfter}
                      style={{ height: "40px", width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <span className="title_changes">Testing Status</span>
                  <Form.Item
                    name="materialTest"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Testing!",
                      },
                    ]}
                    initialValue={recordMaterialData?.materialTest || "Unknown"}
                    style={{ marginBottom: "10px" }}
                  >
                    <Select
                      size="large"
                      showSearch
                      allowClear
                      className="select_Height"
                      // style={{ height: "40px" }}
                      placeholder="Please Select A testMaterial"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {QuarryJsonData?.testMaterial?.map((m, i) => (
                        <Option key={i} value={m}>
                          {m}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <span className="title_changes">Washed Status</span>
                  <Form.Item
                    name="materialStatus"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter washed status!",
                      },
                    ]}
                    initialValue={
                      recordMaterialData?.materialStatus || "Unknown"
                    }
                    style={{ marginBottom: "10px" }}
                  >
                    <Select
                      size="large"
                      showSearch
                      allowClear
                      className="select_Height"
                      // style={{ height: "40px" }}
                      placeholder="Please Select A statusMaterial"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {QuarryJsonData?.statusMaterial?.map((m, i) => (
                        <Option key={i} value={m}>
                          {m}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {diameter ? (
                  <Col span={12} style={{ marginTop: "20px" }}>
                    <Row gutter={[12, 0]}>
                      <Col span={12}>
                        <span className="title_changes">Min</span>
                        <Form.Item
                          name="materialMinDiameter"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Diameter!",
                            },
                          ]}
                          initialValue={recordMaterialData?.materialMinDiameter}
                          style={{ marginBottom: "10px" }}
                        >
                          {/* <InputNumber
                              formatter={(value) =>
                                value !== "" ? `${value}"` : ""
                              }
                              style={{ width: "100%", height: "40px" }}
                              placeholder="Please enter your diameter"
                              className="form-input"
                            /> */}
                          <Input
                            style={{
                              height: "40px",
                              width: "100%",
                            }}
                            className="form-input"
                            // formatter={(value) => `${value}"`}
                            placeholder="Please enter diameter"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span className="title_changes">Max</span>
                          <Switch
                            checkedChildren={"Diameter"}
                            unCheckedChildren={"Range"}
                            onChange={(e) => this.handleCheckStatus(e)}
                            checked={diameter}
                          >
                            Size Range
                          </Switch>
                        </span>
                        <Form.Item
                          name="materialMaxDiameter"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Diameter!",
                            },
                          ]}
                          initialValue={recordMaterialData?.materialMaxDiameter}
                          style={{ marginBottom: "10px" }}
                        >
                          {/* <InputNumber
                              formatter={(value) =>
                                value !== "" ? `${value}"` : ""
                              }
                              style={{ width: "100%", height: "40px" }}
                              placeholder="Please enter your diameter"
                              className="form-input"
                            /> */}
                          <Input
                            style={{
                              height: "40px",
                              width: "100%",
                            }}
                            className="form-input"
                            // formatter={(value) => `${value}"`}
                            placeholder="Please enter diameter"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <Col span={12} style={{ marginTop: "20px" }}>
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span className="title_changes">Diameter</span>
                      <Switch
                        checkedChildren={"Diameter"}
                        unCheckedChildren={"Range"}
                        onChange={(e) => this.setState({ diameter: e })}
                      >
                        Size Range
                      </Switch>
                    </span>
                    <Form.Item
                      name="materialDiameter"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Diameter!",
                        },
                      ]}
                      initialValue={
                        recordMaterialData?.materialDiameter || "Unknown"
                      }
                      style={{ marginBottom: "10px" }}
                    >
                      <Input
                        style={{
                          height: "40px",
                          width: "100%",
                        }}
                        className="form-input"
                        // formatter={(value) => `${value}"`}
                        placeholder="Please enter diameter"
                      />
                      {/* <InputNumber
                          formatter={(value) =>
                            value !== "" ? `${value}"` : ""
                          }
                          style={{ width: "100%", height: "40px" }}
                          placeholder="Please enter your diameter"
                          className="form-input"
                        /> */}
                    </Form.Item>
                  </Col>
                )}

                <Col span={12} style={{ marginTop: "20px" }}>
                  <span className="title_changes">Supply</span>
                  <Form.Item
                    name="materialSupply"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter supply!",
                      },
                    ]}
                    initialValue={
                      recordMaterialData?.materialSupply || "Unknown Supply"
                    }
                    style={{ marginBottom: "10px" }}
                  >
                    <Select
                      size="large"
                      showSearch
                      allowClear
                      className="select_Height"
                      // style={{ height: "40px" }}
                      placeholder="Please Select A Supply"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {QuarryJsonData?.supplyMaterial?.map((m, i) => (
                        <Option key={i} value={m}>
                          {m}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <span className="title_changes">Material Tax</span>
                  <Form.Item
                    name="materialTax"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Material Tax!",
                      },
                    ]}
                    initialValue={recordMaterialData?.materialTax || "Unknown"}
                    style={{ marginBottom: "10px" }}
                  >
                    <Select
                      size="large"
                      showSearch
                      allowClear
                      className="select_Height"
                      // style={{ height: "40px" }}
                      placeholder="Please Select A Material"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      <Option value="Unknown">Unknown</Option>
                      <Option value="Natural"> Natural</Option>
                      <Option value="Manufactured">Manufactured</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <span className="title_changes">Material Description</span>
                  <Form.Item
                    name="materialNotes"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter materialNotes!",
                      },
                    ]}
                    initialValue={
                      recordMaterialData?.materialNotes
                        ? recordMaterialData?.materialNotes
                        : ""
                    }
                  >
                    <TextArea
                      rows={4}
                      style={{
                        width: "100%",
                        height: "75px",
                        borderColor: "#d6d6d6",
                      }}
                      className="form-input"
                      placeholder="Please Enter materialNotes"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={24}
                >
                  <span className="title_changes">Material Picture</span>
                  <Form.Item
                    name="materialPicture1"
                    rules={[
                      {
                        required: false,
                        message: "Please Attach materialPicture",
                      },
                    ]}
                  >
                    <Spin spinning={imageLoading}>
                      <Dragger
                        onChange={(e) =>
                          this.uploadTicketPdf(e, "materialPicture")
                        }
                        customRequest={dummyRequest}
                        listType="picture"
                        multiple={true}
                        className="drag-ticket"
                        showUploadList={false}
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                          Click or drag file to this area to upload
                        </p>
                      </Dragger>

                      <div style={{ marginTop: "10px" }}>
                        {materialAttachment?.length > 0 &&
                          materialAttachment?.map((file) => (
                            <div style={{ marginBottom: "5px" }}>
                              <Card
                                key={file.uid}
                                bodyStyle={{ padding: "4px 10px 4px 4px" }}
                                width="100%"
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  {file.fileType === "image/jpeg" ||
                                  file.fileType === "image/png" ? (
                                    <img
                                      src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.us-east-1.amazonaws.com/public/${file?.quoteAttachmentId}`}
                                      alt="Avatar"
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        objectFit: "fill",
                                        marginRight: "10px",
                                      }}
                                    />
                                  ) : (
                                    <FilePdfOutlined
                                      style={{
                                        marginRight: "10px",
                                        fontSize: "35px",
                                      }}
                                    />
                                  )}
                                  <span>{file?.fileName}</span>
                                  <Button
                                    icon={
                                      <DeleteOutlined
                                        style={{
                                          fontSize: "18px",
                                        }}
                                      />
                                    }
                                    onClick={() =>
                                      this.handleFileDelete(
                                        file,
                                        "materialPicture"
                                      )
                                    }
                                    className="upload_Delete_Icon"
                                  />
                                </div>
                              </Card>
                            </div>
                          ))}
                      </div>
                    </Spin>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}

        {contactPopup && (
          <BaseModal
            title={
              recordContactData !== "" ? "Update Contact" : "Add New Contact"
            }
            onCancel={() => {
              this.setState({
                contactPopup: false,
                isCheckcontact: false,
                newRole: "",
              });
            }}
            visible={contactPopup}
            destroyOnClose={true}
            formId="addContact"
            loading={loading}
            className="antmodal_grid headermodal"
            width="500px"
          >
            <Form
              onFinish={this.addContact}
              initialValues={{ remember: true }}
              id="addContact"
            >
              <Row gutter={[24, 0]}>
                <>
                  <Col span={12}>
                    <span className="title_changes">First Name</span>
                    <Form.Item
                      name="fName"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Your First Name!",
                        },
                      ]}
                      initialValue={
                        recordContactData?.firstName
                          ? recordContactData?.firstName
                          : ""
                      }
                    >
                      <Input
                        className="form-input"
                        placeholder="Please Enter Your First Name"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <span className="title_changes">Last Name</span>
                    <Form.Item
                      name="lName"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter your Last Name!",
                        },
                      ]}
                      initialValue={
                        recordContactData?.lastName
                          ? recordContactData?.lastName
                          : ""
                      }
                    >
                      <Input
                        className="form-input"
                        placeholder="Please Enter your Last Name"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <span className="title_changes">Phone</span>
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Phone!",
                        },
                      ]}
                      initialValue={
                        recordContactData?.phone ? recordContactData?.phone : ""
                      }
                    >
                      <NumberFormat
                        className="ant-input-number numberictest"
                        format="(###) ###-####"
                        style={{ width: "100%" }}
                        placeholder="Please Enter Your Phone"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <span className="title_changes">Email</span>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Email!",
                        },
                        {
                          type: "email",
                          message: "The Input Is Not Valid Email",
                        },
                      ]}
                      initialValue={
                        recordContactData?.email ? recordContactData?.email : ""
                      }
                    >
                      <Input
                        className="form-input"
                        placeholder="Please Enter Your Email"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <span className="title_changes">Role</span>
                    {/* <span
                      style={{
                        color: "#878787",
                        fontSize: "15px",
                        marginLeft: "5px",
                        fontWeight: "400",
                      }}
                    >
                      (If you want add new role, please input)
                    </span>  */}
                    <Form.Item
                      name="role"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Role!",
                        },
                      ]}
                      initialValue={
                        recordContactData?.contactRole &&
                        recordContactData?.contactRole
                      }
                    >
                      <Select
                        size="large"
                        showSearch
                        allowClear
                        className="select_Height"
                        // style={{ height: "40px" }}
                        placeholder="Please Select A Role"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onChange={(value) => {
                          this.setState({ newRole: value });
                          value === "Others" &&
                            message.info("Please Input Your Role");
                        }}
                      >
                        {quarryRoles?.map((role) => (
                          <Option value={role?.label}>{role?.label}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  {/* <Col span={12}>
                        <span className="title_changes">Area</span>
                        <Form.Item
                          name="address"
                          rules={[
                            {
                              required: false,
                              message: "Please enter your address",
                            },
                          ]}
                        >
                          <GooglePlacesAutocomplete
                            className="form-input"
                            // apiKey={process.env.REACT_APP_MAPS_KEY}
                            autocompletionRequest={{
                              componentRestrictions: {
                                country: ["us"],
                              },
                            }}
                            selectProps={{
                              defaultInputValue: `${
                                recordContactData?.address
                                  ? recordContactData?.address
                                  : ""
                              }`,
                              citySearch,
                              placeholder: "Search Address...",
                              onChange: (address) => {
                                this.handleSelect(address);
                                this.getAddress(address);
                              },
                            }}
                          />
                        </Form.Item>
                      </Col> */}

                  {newRole === "Add New Role" && (
                    <Col span={24}>
                      <Form.Item name="roleOther">
                        <Input
                          className="form-input"
                          placeholder="Please Enter Role"
                        />
                      </Form.Item>
                      <Checkbox
                        checked={this.state.isCheckcontact}
                        onChange={(e) =>
                          this.setState({
                            isCheckcontact: e.target.checked,
                          })
                        }
                        style={{ marginBottom: "18px" }}
                      >
                        <span
                          className="checkBox_size"
                          style={{ fontSize: "14px" }}
                        >
                          Add this new role to default list
                        </span>
                      </Checkbox>
                    </Col>
                  )}
                  <Col span={24}>
                    <span className="title_changes">Notes</span>
                    <Form.Item
                      name="notes"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Notes!",
                        },
                      ]}
                      initialValue={
                        recordContactData?.notes ? recordContactData?.notes : ""
                      }
                    >
                      <TextArea
                        rows={4}
                        style={{
                          width: "100%",
                          height: "75px",
                          borderColor: "#d6d6d6",
                        }}
                        className="form-input"
                        placeholder="Please Enter Your Notes"
                      />
                    </Form.Item>
                  </Col>
                </>
              </Row>
            </Form>
          </BaseModal>
        )}

        {isDocModalVisible && (
          <BaseModal
            title="Add Document"
            visible={this.state.isDocModalVisible}
            onCancel={() => {
              this.setState({
                isDocModalVisible: false,
                quoteAttachment: [],
                selectedAssignTo: "",
              });
            }}
            formId="addDocument"
            className="antmodal_grid headermodal"
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "80%"
                : "520px"
            }
            loading={loading}
            disabled={imageLoading ? true : false}
          >
            <Form onFinish={this.onFinishDoc} id="addDocument">
              <Row gutter={[32, 0]}>
                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Material</span>
                  <Form.Item
                    name="materialId"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Material!",
                      },
                    ]}
                    initialValue={recordDocData?.materialId}
                  >
                    <Select
                      className="select_Height"
                      size="large"
                      allowClear
                      style={{ borderRadius: "6px", border: "#949caa" }}
                    >
                      {materialDocInfo?.map((m) => (
                        <Option key={m?.id} value={m?.id}>
                          {m?.value}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {/* <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={24}
                >
                  <span className="title_changes">Date</span>
                  <Form.Item
                    name="date"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Date!",
                      },
                    ]}
                    initialValue={
                      recordDocData?.date && moment(recordDocData?.date)
                    }
                  >
                    <DatePicker
                      style={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "5px",
                      }}
                      defaultValue={moment()}
                      format={"MM/DD/YYYY"}
                    />
                  </Form.Item>
                </Col> */}

                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={12}
                >
                  <span className="title_changes">Document Name</span>
                  <Form.Item
                    name="documentName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Document Name",
                      },
                    ]}
                    initialValue={recordDocData?.documentName}
                  >
                    <Input
                      type="text"
                      className="form-input"
                      placeholder="Please Enter Document Name"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={24}
                >
                  <span className="title_changes">Description</span>
                  <Form.Item
                    name="description"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Description",
                      },
                    ]}
                    initialValue={recordDocData?.description}
                  >
                    <TextArea
                      rows={4}
                      style={{
                        width: "100%",
                      }}
                      className="form-input"
                      placeholder="Please Enter Your Description"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={24}
                >
                  <span className="title_changes">Attachment</span>
                  <Form.Item
                    name="docAttachment"
                    rules={[
                      {
                        required: false,
                        message: "Please Attach Your Document",
                      },
                    ]}
                  >
                    <Spin spinning={imageLoading}>
                      <Dragger
                        onChange={this.uploadTicketPdf}
                        customRequest={dummyRequest}
                        listType="picture"
                        defaultFileList={recordDocData?.docAttachment}
                        multiple={true}
                        className="drag-ticket"
                        // beforeUpload={this.handleUploadType}
                        // maxCount={1}
                        // defaultFileList={recordDocData?.docAttachment}
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                          Click or drag file to this area to upload
                        </p>
                      </Dragger>
                    </Spin>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}

        {notesPopup && (
          <BaseModal
            title={recordNotesData !== "" ? "Update Note" : "Add New Note"}
            onCancel={() => {
              this.setState({
                notesPopup: false,
              });
            }}
            visible={notesPopup}
            destroyOnClose={true}
            formId="addNotes"
            loading={loading}
            className="antmodal_grid headermodal"
            width={"25%"}
          >
            <Form
              onFinish={this.addNotes}
              initialValues={{ remember: true }}
              id="addNotes"
            >
              <Row gutter={[24, 0]}>
                <Col span={24}>
                  <span className="title_changes">Category</span>
                  <Form.Item
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Notes!",
                      },
                    ]}
                    initialValue={
                      recordNotesData?.typeofNotes
                        ? recordNotesData?.typeofNotes
                        : ""
                    }
                  >
                    <Input
                      style={{
                        width: "100%",
                        borderColor: "#d6d6d6",
                      }}
                      className="form-input"
                      placeholder="Please Enter Your Category"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <span className="title_changes">Note</span>
                  <Form.Item
                    name="notes"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Note!",
                      },
                    ]}
                    initialValue={
                      recordNotesData?.notes ? recordNotesData?.notes : ""
                    }
                  >
                    <TextArea
                      rows={4}
                      style={{
                        width: "100%",
                        height: "75px",
                        borderColor: "#d6d6d6",
                      }}
                      className="form-input"
                      placeholder="Please Enter Your Notes"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}

        {isDetailModal && (
          <BaseModal
            title={"Material Attachments"}
            onCancel={() => {
              this.setState({
                isDetailModal: false,
              });
            }}
            visible={isDetailModal}
            destroyOnClose={true}
            formId="addNotes"
            loading={loading}
            className="antmodal_grid headermodal"
            width={"25%"}
            footer={null}
          >
            <Table dataSource={docData} columns={materialDoc} />
            {/* <Row gutter={[24, 0]}>
              {docData.map((e) =>
                e.docAttachment?.map((d) => (
                  <>
                    {(d?.fileType === "image/jpeg" ||
                      d?.fileType === "image/png") && (
                      <Row style={{ flexWrap: "wrap", margin: "5px" }}>
                        <Col>
                          <Image width={225} src={`${d?.quoteAttachment}`} />
                        </Col>
                      </Row>
                    )}
                  </>
                ))
              )} */}
            {/* <Col
                span={24}
                className="detail-ans"
                style={{
                  fontWeight: "600",
                  marginBottom: "15px",
                }}
              >
                Uplaod Attachments
              </Col> */}

            {/* {docData.map((e) =>
                e?.docAttachment?.map((f) => (
                  <>
                    <Col
                      span={8}
                      onClick={() => {
                        this.fileDownload(
                          f?.quoteAttachmentId,
                          "Attachment",
                          "quoteAttachment"
                        );
                      }}
                      style={{
                        marginBottom: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <Row
                        style={{
                          color: "#7B8190",
                          fontSize: "13px",
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <FileOutlined
                          style={{
                            fontSize: "30px",
                            height: "30px",
                            // marginRight: "5px",
                          }}
                        />
                        <span style={{ marginTop: "6px" }}>Download</span>
                      </Row>
                    </Col>
                  </>
                ))
              )} 
            </Row>*/}
          </BaseModal>
        )}

        {addCategory && (
          <BaseModal
            title={"Add Material"}
            onCancel={() => {
              this.setState({
                addCategory: false,
              });
            }}
            visible={addCategory}
            destroyOnClose={true}
            formId="addCategory"
            loading={loading}
            className="antmodal_grid headermodal"
            width={"25%"}
          >
            <Form
              onFinish={this.addCategory}
              initialValues={{ remember: true }}
              id="addCategory"
            >
              <Row gutter={[24, 0]}>
                <Col span={24}>
                  <span className="title_changes">Material Name</span>
                  <Form.Item
                    name="materialsub2"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Material Name!",
                      },
                    ]}
                  >
                    <Input
                      style={{
                        width: "100%",
                        borderColor: "#d6d6d6",
                      }}
                      className="form-input"
                      placeholder="Please Enter Material"
                      autoFocus
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}

        {showImages && (
          <BaseModal
            title={"Material Pictures"}
            onCancel={() => {
              this.setState({
                showImages: false,
                imgLoading: false,
              });
            }}
            visible={showImages}
            destroyOnClose={true}
            formId="showImages"
            // loading={loading}
            className="antmodal_grid headermodal"
            rejectBtn={materialAttachment?.length > 0 && "Download All"}
            rejectButton={true}
            submitButton={materialAttachment?.length > 0 && "Submit"}
            cancelButton={materialAttachment?.length > 0 && "Cancel"}
            btnWidth={"180px"}
            onReject={() => {
              this.downloadZip(imageData);
            }}
          >
            <Form onFinish={this.uploadMaterialPictures} id="showImages">
              <Row gutter={[12, 12]}>
                {imageData?.map(
                  (e) =>
                    (e.fileType === "image/png" ||
                      e.fileType === "image/jpeg") && (
                      <Col span={6} style={{ height: "100%" }}>
                        {imgLoading === e.quoteAttachmentId && (
                          <Spin className="spin_quarry" />
                        )}
                        <div style={{ height: "100%" }}>
                          {/* <Radio.Group
                            value={materialProfilePic}
                            onChange={() => this.handleRadio(e.id)}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Radio value={e.id} className="Radio_btn" />
                          </Radio.Group> */}
                          <div className="container">
                            <img
                              src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.us-east-1.amazonaws.com/public/${e?.quoteAttachmentId}`}
                              alt="Avatar"
                              className="image"
                              style={{
                                width: "100%",
                                height: "180px",
                                objectFit: "fill",
                                border:
                                  materialProfilePic === e.id &&
                                  "3px solid red",
                              }}
                              onClick={() => this.handleRadio(e.id)}
                            />
                            <div
                              className="middle"
                              onClick={() =>
                                this.fileDownloadQuote(
                                  e.quoteAttachmentId,
                                  "materialPicture",
                                  "docAttachment"
                                )
                              }
                            >
                              <div className="downloadIcon">
                                <DownloadOutlined
                                  style={{
                                    fontSize: "30px",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    )
                )}
              </Row>
              <Row gutter={[12, 0]} style={{ marginTop: "1vw" }}>
                {imageData?.map(
                  (e) =>
                    e.fileType !== "image/png" &&
                    e.fileType !== "image/jpeg" && (
                      <Col>
                        <Popover
                          content={
                            <Row>
                              <Col span={24}>
                                <span className="col_style_quarry">
                                  {e.fileName}
                                </span>
                              </Col>
                            </Row>
                          }
                        >
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.fileDownloadQuote(
                                e.quoteAttachmentId,
                                "Attachment",
                                "docAttachment"
                              );
                            }}
                          >
                            <Row
                              style={{
                                color: "#7B8190",
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "center",
                                flexDirection: "coloumn",
                              }}
                            >
                              {/* <FilePdfOutlined */}
                              <HiOutlineDocumentArrowDown
                                style={{
                                  fontSize: "50px",
                                }}
                              />
                            </Row>
                          </span>
                        </Popover>
                      </Col>
                    )
                )}
              </Row>

              <Col
                xxl={{ span: 24 }}
                xl={{ span: 24 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                {/* <span className="title_changes">Material Picture</span> */}
                <Spin spinning={imageLoading}>
                  <Dragger
                    onChange={(e) => this.uploadTicketPdf(e, "materialPicture")}
                    customRequest={dummyRequest}
                    listType="picture"
                    multiple={true}
                    className="drag-ticket"
                    showUploadList={false}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                  </Dragger>
                  <div style={{ marginTop: "10px" }}>
                    {materialAttachment?.length > 0 &&
                      materialAttachment?.map((file) => (
                        <div style={{ marginBottom: "5px" }}>
                          <Card
                            key={file.uid}
                            bodyStyle={{ padding: "4px 10px 4px 4px" }}
                            width="100%"
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              {file.fileType === "image/jpeg" ||
                              file.fileType === "image/png" ? (
                                <img
                                  src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.us-east-1.amazonaws.com/public/${file?.quoteAttachmentId}`}
                                  alt="Avatar"
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    objectFit: "fill",
                                    marginRight: "10px",
                                  }}
                                />
                              ) : (
                                <FilePdfOutlined
                                  style={{
                                    marginRight: "10px",
                                    fontSize: "35px",
                                  }}
                                />
                              )}
                              <span>{file?.fileName}</span>
                              <Button
                                icon={
                                  <DeleteOutlined
                                    style={{
                                      fontSize: "18px",
                                    }}
                                  />
                                }
                                onClick={() =>
                                  this.handleFileDelete(file, "materialPicture")
                                }
                                className="upload_Delete_Icon"
                              />
                            </div>
                          </Card>
                        </div>
                      ))}
                  </div>
                </Spin>
              </Col>
            </Form>
          </BaseModal>
        )}

        {visible && (
          <Filter
            visible={visible}
            closeFilter={this.closeFilter}
            filterResultData={this.filterResultData}
            quarryProfile
            onClickClearFilter={this.onClickClearFilter}
            invoicesData={invoicesData}
            checkData={checkData}
            materialCategory={materialCategory}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) =>
  // console.log(state),
  ({
    totalResult: state?.reducer?.quarryResult,
    loading: state?.reducer?.quarryResult.loading,
    quarriesInfo: state?.reducer?.quarriesInfo,
    dropDownOptions: state.reducer.dropDownOptions,
    dropDownOptionsInfo: state.reducer?.dropDownOptionsInfo,
    materials: state.reducer?.dropDownOptions?.totalData?.filter(
      (m) => m?.typeofDropDown === "material"
    ),
    quarryRoles: state.reducer?.dropDownOptions?.totalData?.filter(
      (q) => q?.typeofDropDown === "quarryRoles"
    ),
  });

const mapDispatchToProps = (dispatch) => ({
  actionFetchQuarries: (id) => {
    dispatch(getQuarry(id));
  },
  actionFetchUpdateQuarry: (id, data) => {
    dispatch(updateQuarry(id, data));
  },
  actiongetDropDownOptions: () => {
    dispatch(getDropDownOptions());
  },
  actionupdateDropDownOptions: (id, data) => {
    dispatch(updateDropDownOptions(id, data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAppContext(withRouter(QuarryDetails)));
