import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Popover,
  Row,
  Select,
  Popconfirm,
  message,
  Checkbox,
  DatePicker,
  InputNumber,
  Radio,
  Spin,
  Upload,
  notification,
} from "antd";
import React from "react";
import { FaMap } from "react-icons/fa";
import { IoFilterSharp } from "react-icons/io5";
import { add, deleteRecord, list, deleteAll } from "../../api/Apis";
// import FooterData from "../Common/FooterData";
import Loading from "../../Common/Loading";
// import SearchFunction from "../Common/SearchFunction";
import MapComponent from "../Maps/MapComponent";
import {
  ArrowDownOutlined,
  EllipsisOutlined,
  PlusOutlined,
  UnorderedListOutlined,
  CameraOutlined,
  DeleteOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { downloadExcel } from "react-export-table-to-excel";
// import { MdDelete, MdModeEditOutline } from "react-icons/md";
import BaseModal from "../../Common/BaseModal";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import NumberFormat from "react-number-format";
import Filter from "../../Common/Filter";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import { v4 as uuidv4 } from "uuid";
import AOS from "aos";
import "aos/dist/aos.css";
import appConfig from "../../config/AppConfig";
import BaseTable from "../../Common/BaseTable";
import ErrorView from "../../Common/ErrorView";
import { Storage } from "aws-amplify";
import { withAppContext } from "../../Store/AppContext";
import { connect } from "react-redux";
import {
  addTrucksList,
  getDropDownOptions,
  getOwnerOperators,
  getTrucksList,
  updateTrucksList,
} from "../../Redux/Actions/actions";
import { MdArchive, MdUnarchive } from "react-icons/md";
import { withRouter } from "react-router-dom";

const { Option } = Select;
const Search = Input.Search;

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
class TwistedNailOwnerOperator extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],
      totalTrucksData: [],
      trucksData: [],
      filterData: [],
      selected: true,
      citySearch: "",
      isModalVisible: false,
      city: "",
      addressState: "",
      zipCode: "",
      latitude: "",
      longitude: "",
      selectedAddress: "",
      minValue: 0,
      maxValue: 10,
      currentPage: 1,
      perpageSize: 10,
      contactName: "",
      contactNumer: "",
      truckCount: "",
      usdot: "",
      carrierName: "",
      checkData: [],
      visible: false,
      trucksFilterData: [],
      addContactVisible: false,
      addTrucksLocations: false,
      jobContactData: "",
      selectedTruckCode: "NA",
      selectedType: "",
      emptyWeight: "",
      directDeposit: "",
      newContactData: [],
      hoverData: "",
      isCompanyModalVisible: false,
      companyData: "",
      contactInfo: [],
      recordContactData: "",
      isContactVisible: false,
      newRole: "",
      trucksList: [],
      equipmentTypeDetails: [],
      selectedPaymentType: "",
      primaryContactCheck: false,
      sortBy: "",
      overWeightPermitState: "",
      quoteAttachment: "",
      quoteAttachmentId: "",
      imageLoading: false,
      errorLoading: false,
      bulkDelete: [],
      carrierCount: [],
      paymentTerms: [],
      payment_discount: "12.5%",
      trucksTypes: [],
      filterCarrierData: [],
      carrierAddress: "",
      paymentTermsVal: "Net 7",
      truckLat: "",
      truckLng: "",
      archiveData: true,
      banData: true,
      bannedData: "",
      showbanModel: false,
    };
  }

  componentDidMount() {
    let filterStorageData = JSON.parse(
      localStorage.getItem("filterStorageData")
    );
    document.title = "Twisted Nail - Trucking & Material";
    if (filterStorageData) {
      this.setState({
        trucksData: filterStorageData.result,
        filterData: filterStorageData.result,
        checkData: filterStorageData.filter,
        filterCarrierData: filterStorageData.filterCarrierData,
      });
    } else {
      this.props.actionFetchTrucksList();
    }
    // this.getTwistedNailData();
    // this.getCompanies();
    this.props.actionFetchOwnerOperators();
    this.props?.actiongetDropDownOptions();
    document.title = "Twisted Nail - Owner Operator";
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.totalOwnerOperatorsData?.success === false &&
      this.props?.totalOwnerOperatorsData?.success === true
    ) {
      let data = [];
      this.props?.totalOwnerOperatorsData?.ownersData?.forEach((e) => {
        if (e?.isOwnerOperator && e?.carrierDetails?.length > 0) {
          e?.carrierDetails?.forEach((c) => {
            if (c?.truckId !== null && c?.truckId !== undefined) {
              data.push(parseInt(c?.truckId));
            }
          });
        }
      });
      let res = data?.length > 0 ? data.flat() : [0];
      let truckIdLocal = Math.max(...res);
      // console.log(truckIdLocal);
      this.setState({
        // ownerCompanyData: result.data,
        loading: false,
        localTruckId: truckIdLocal,
      });
    }
    if (
      prevProps?.totalTrucksListResult?.success === false &&
      this.props?.totalTrucksListResult?.success === true
    ) {
      let data = [];
      this.props?.totalTrucksListResult?.trucksListData?.forEach((e) => {
        if (e?.carrierDetails?.length > 0) {
          e?.carrierDetails?.forEach((c) => {
            if (c?.truckId !== null && c?.truckId !== undefined) {
              data.push(parseInt(c?.truckId));
            }
          });
        }
      });
      let res = data?.length > 0 ? data.flat() : [100];
      let truckIdLocal = Math.max(...res);
      let carrierData = [];
      let newData = this.props?.totalTrucksListResult?.trucksListData;
      // ?.filter(
      //   (t) => t?.carrier_status === "Active Carrier"
      // );
      let carrierCount = [];
      if (newData?.length > 0) {
        newData?.forEach((c) => {
          if (c?.carrierDetails?.length > 0) {
            c?.carrierDetails?.forEach((t) =>
              carrierData?.push({
                id: c?.id,
                truckingId: t?.id,
                truckId: t?.truckId,
                carrier_name: t?.carrier_name,
                usdot: c?.usdot,
                contact_name: c?.contactDetails
                  ?.filter((con) => con?.isPrimaryContact === true)
                  ?.map((p) =>
                    p?.contact_name
                      ? p?.contact_name
                      : p?.firstName + " " + p?.lastName
                  )[0],
                contact_number: c?.contactDetails
                  ?.filter((con) => con?.isPrimaryContact === true)
                  ?.map((p) => (p?.phone ? p?.phone : p?.contact_number))[0],
                truck_code: t?.truck_code,
                truck_type: t?.truck_type,
                parking_location: t?.parking_location,
                insurance_expiry: c?.insurance_expiry,
                payment_terms: c?.payment_terms,
                payment_discount: c?.payment_discount,
                direct_deposit: c?.direct_deposit,
                lat: t?.lat,
                lng: t?.lng,
                showCarrier: c?.showCarrier,
                banCarrier: c?.banCarrier,
                // carrier_status: c?.carrier_status,
              })
            );
            if (c.showCarrier !== false && c.banCarrier !== false) {
              carrierCount?.push(c);
            }
          }
        });
        let filterCarrierData = carrierData?.filter(
          (value, index, self) =>
            self.findIndex((e) => e.id === value.id) === index
        );
        let removeDuplicatetrucks = carrierData?.filter(
          (record, index, self) =>
            self.findIndex((e) => e.truckId === record.truckId) === index
        );
        this.setState({
          totalTrucksData: removeDuplicatetrucks,
          trucksData: removeDuplicatetrucks,
          filterData: removeDuplicatetrucks,
          carrierCount: carrierCount,
          filterCarrierData,
        });
      }
      this.setState({
        assignTruckId: truckIdLocal,
      });
    }

    if (
      prevProps?.dropDownOptions?.success === false &&
      this.props?.dropDownOptions?.success === true
    ) {
      this.setState({
        paymentTerms: this.props?.termsData[0]?.termsData,
        trucksTypes: this.props?.trucksTypes[0]?.trucksData,
      });
    }
    if (
      prevProps?.trucksListInfo?.success === false &&
      this.props?.trucksListInfo?.success === true
    ) {
      this.props?.actionFetchTrucksList();
    }
  }

  onRefresh = () => {
    this.props.actionFetchTrucksList();
    this.props.actionFetchOwnerOperators();
  };

  // onFinishCompanyDetails = (values) => {
  //   const {
  //     companyData,
  //     selectedAddress,
  //     latitude,
  //     longitude,
  //     equipmentTypeDetails,
  //     carrierName,
  //     payment_discount,
  //   } = this.state;
  //   this.setState({
  //     loading: true,
  //     isCompanyModalVisible: false,
  //   });
  //   const data = {
  //     entityData: {
  //       carrierId:
  //         companyData?.carrierId !== undefined
  //           ? companyData?.carrierId
  //           : uuidv4(),
  //       company:
  //         values["carrier_name"] !== undefined ? values["carrier_name"] : "",
  //       carrier_name:
  //         values["carrier_name"] !== undefined ? values["carrier_name"] : "",
  //       // contactInfo: contactInfo,
  //       address: selectedAddress,
  //       usdot: values["usdot"],
  //       phone: values["phone"],
  //       email: values["email"],
  //       payment_terms:
  //         // values["payment_terms"] === "Custom Input"
  //         //   ? values["paymentTermsOthers"]
  //         //   :
  //         values["payment_terms"],
  //       payment_discount: payment_discount,
  //       insurance_expiry:
  //         values["insurance_expiry"] === null
  //           ? ""
  //           : values["insurance_expiry"] === ""
  //           ? ""
  //           : moment(values["insurance_expiry"]).format("L"),
  //       registered_since:
  //         values["registeredSince"] === null
  //           ? ""
  //           : moment(values["registeredSince"]).format("L"),
  //       direct_deposit: values["direct_deposit"],
  //       lat: latitude,
  //       lng: longitude,
  //       notes: values["notes"],
  //     },
  //   };
  //   if (carrierName !== "" && carrierName !== undefined) {
  //     equipmentTypeDetails?.map((t) => (t["carrier_name"] = carrierName));
  //     data["entityData"]["carrierDetails"] = equipmentTypeDetails;
  //   }
  //   this.onFinish(data);
  // };

  onChangeTerms = (e, option) => {
    console.log(e, "e");
    this.setState({ payment_discount: option.key });
    // this.setState({ selectedPaymentType: e });
    // this.formRef.current.setFieldsValue({
    //   paymentTermsOthers: "",
    // });
  };

  addNewCarrier = (data) => {
    this.props.actionAddTrucksList(data);
  };

  // editModal = (data) => {
  //   // console.log(data);
  //   this.setState({
  //     isCompanyModalVisible: true,
  //     companyData: data,
  //     // contactInfo: data?.contactInfo,
  //     latitude: data?.lat,
  //     longitude: data?.lng,
  //     selectedAddress: data?.address,
  //     // equipmentTypeDetails: data?.carrierDetails,
  //   });
  // };

  hoverModal = (data) => {
    this.setState({
      hoverData: data,
    });
  };

  getCompanies = () => {
    this.setState({ loading: true });
    list("companies", { isOwnerOperator: true }).then((result) => {
      if (result.success) {
        let finalRes = result.data;
        let data = finalRes?.map((e) =>
          e?.carrierDetails.map((c) =>
            c?.truckId !== undefined ? c?.truckId : 0
          )
        );
        let res = data.flat();
        let truckIdLocal = Math.max(...res);
        // console.log(truckIdLocal);
        this.setState({
          ownerCompanyData: result.data,
          loading: false,
          localTruckId: truckIdLocal,
        });
      } else {
        this.setState({ loading: false, errorLoading: true });
      }
    });
  };

  onFinish = (values) => {
    const { companyData } = this.state;
    this.props.actionUpdateTrucksList(companyData?.id, values);
    this.setState({
      loading: false,
      contactName: "",
      contactNumer: "",
      selectedType: "",
      directDeposit: "",
      citySearch: "",
      truckCount: "",
      latitude: "",
      longitude: "",
      emptyWeight: "",
      isCompanyModalVisible: false,
      selectedPaymentType: "",
      carrierName: "",
    });
    this.handleCancel();
  };

  getAddress = (address) => {
    this.setState({
      selectedAddress: address.label,
    });
  };

  handleSelect = (address, type) => {
    this.setState({
      citySearch: address.label,
    });
    let cityValue = "";
    let stateValue = "";
    let zipCodeValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        getLatLng(results[0]).then(({ lat, lng }) => {
          if (type === "carrier") {
            this.setState({ latitude: lat, longitude: lng });
          } else {
            this.setState({ truckLat: lat, truckLng: lng });
          }
        });
        const geoIdData = results[0];
        this.setState({
          geoIdData,
        });
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
          if (address[i].types[0] === "postal_code") {
            zipCodeValue = address[i].long_name;
          }
        }
        this.setState({
          city: cityValue,
          addressState: stateValue,
          zipCode: zipCodeValue,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  onSelectChange = (data, record) => {
    this.setState({ selectedRowKeys: data, bulkDelete: record });
  };

  deleteTruck = (data) => {
    this.setState({ loading: true });
    deleteRecord("trucksList", data.id)
      .then((result) => {
        if (result.success) {
          this.setState({ loading: false });
          this.getTwistedNailData();
          this.props.context.getTrucksType();
          this.getCompanies();
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  };

  deleteBulkTrucks = () => {
    const { bulkDelete } = this.state;
    this.setState({ loading: true });
    let data = {
      entityData: bulkDelete,
    };
    deleteAll("bulkDelete/trucksList", data)
      .then((result) => {
        if (result.success) {
          message.success("Trucks deleted successfully!");
          this.setState({ selectedRowKeys: [] });
          this.props.actionFetchTrucksList();
          this.props.actionFetchOwnerOperators();
          this.props?.actiongetDropDownOptions();
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  };

  handlePagination = async (page, pageSize, load) => {
    if (page <= 1) {
      this.setState({
        minValue: 0,
        maxValue: pageSize,
      });
    } else {
      this.setState({
        minValue: (page - 1) * pageSize,
        maxValue: page * pageSize,
      });
    }
    this.setState({
      currentPage: page,
      perpageSize: pageSize,
    });
  };

  closeFilter = () => {
    this.setState({
      visible: false,
    });
  };

  filterResultData = async (data) => {
    const { trucksData, filterCarrierData } = this.state;
    let resultData = [];
    resultData = this.findAllFilterData(trucksData, data);
    if (
      data.ownerOperatorLocation !== "" &&
      data.ownerOperatorLocation !== undefined
    ) {
      let submitData = {
        entityData: {
          origin: data.origin,
          filterData: [],
          radius: data.ownerOperatorRadius,
        },
      };
      let filterAddress = (await this.getFilterAddress(submitData)) || [];
      resultData = this.findAllFilterData(filterAddress, data);
    }
    const activeTrucks = resultData?.filter(
      (e) => e.showCarrier !== false && e.banCarrier !== false
    );

    const filterCarrierCount = activeTrucks?.filter(
      (value, index, self) => self.findIndex((e) => e.id === value.id) === index
    );

    let filterInfo = {};
    const { dispatchCheckData, origin, ...employeeRest } = data;
    filterInfo = employeeRest;
    if (data.ownerOperatorLocation === "") {
      const { ownerOperatorLocation, ownerOperatorRadius, ...employeeRest } =
        filterInfo;
      filterInfo = employeeRest;
    }
    if (data.ownerOperatorRadius === 0 || data.ownerOperatorRadius === null) {
      const { ownerOperatorRadius, ...employeeRest } = filterInfo;
      filterInfo = employeeRest;
    }
    Object.keys(filterInfo)?.forEach((key) => {
      if (filterInfo[key] === undefined) {
        delete filterInfo[key];
      }
    });
    localStorage.setItem(
      "filterStorageData",
      JSON.stringify({
        result: activeTrucks,
        filter: data,
        filterShowData: filterInfo,
        filterCarrierData,
      })
    );

    this.setState({
      checkData: data,
      filterData: activeTrucks,
      carrierCount: filterCarrierCount,
      // filterCarrierData,
    });
    this.closeFilter();
    this.showMap(activeTrucks);
  };

  findAllFilterData = (data, filterCriteria) => {
    return data.filter((item) => {
      if (
        (!filterCriteria.ownerOperatorCarrier_name ||
          filterCriteria.ownerOperatorCarrier_name === item.id) &&
        (!filterCriteria.dispatchCheckData ||
          filterCriteria.dispatchCheckData.includes(item.truck_type))
      ) {
        return true;
      }
      return false;
    });
  };

  getFilterAddress = async (submitData) => {
    const result = await add("getDist/trucksList", submitData);
    let carrierData = [];
    result.data?.forEach((c) => {
      if (
        c?.carrierDetails?.length > 0 &&
        c.showCarrier !== false &&
        c.banCarrier !== false
      ) {
        c?.carrierDetails?.forEach((t) =>
          carrierData?.push({
            id: c?.id,
            truckingId: t?.id,
            truckId: t?.truckId,
            carrier_name: t?.carrier_name,
            usdot: c?.usdot,
            contact_name: c?.contactDetails
              ?.filter((con) => con?.isPrimaryContact === true)
              ?.map((p) =>
                p?.contact_name
                  ? p?.contact_name
                  : p?.firstName + " " + p?.lastName
              )[0],
            contact_number: c?.contactDetails
              ?.filter((con) => con?.isPrimaryContact === true)
              ?.map((p) => (p?.phone ? p?.phone : p?.contact_number))[0],
            truck_code: t?.truck_code,
            truck_type: t?.truck_type,
            parking_location: t?.parking_location,
            insurance_expiry: c?.insurance_expiry,
            payment_terms: c?.payment_terms,
            payment_discount: c?.payment_discount,
            direct_deposit: c?.direct_deposit,
            lat: t?.lat,
            lng: t?.lng,
            showCarrier: c?.showCarrier,
            banCarrier: c?.banCarrier,
          })
        );
      }
    });
    return carrierData;
  };

  showMap = (filterData) => {
    const { totalTrucksData } = this.state;
    const data = filterData?.filter(
      (e) => e?.showCarrier !== false && e?.banCarrier !== false
    );
    return (
      <MapComponent
        filteredMapData={data}
        truck
        propsData={this.props}
        totalTrucksData={totalTrucksData}
      />
    );
  };

  addContact = (values) => {
    const { recordContactData, companyData, primaryContactCheck } = this.state;

    if (recordContactData !== "") {
      let filteredData = this.state.contactInfo.filter(
        (c) => c.id !== recordContactData?.id
      );
      this.setState({
        contactInfo: filteredData,
      });
    }

    let data = {
      entityData: {
        id:
          recordContactData?.id !== undefined
            ? recordContactData?.id
            : uuidv4(),
        contact_name: values["fName"] + " " + values["lName"],
        firstName: values.fName,
        lastName: values.lName,
        contact_number: values.phone,
        email: values.email,
        contactRole:
          values["role"] === "Add New Role"
            ? values["roleOther"]
            : recordContactData?.role
            ? recordContactData?.role
            : values["role"],
        notes: values.notes,
        company: "",
        companyId: companyData?.id,
      },
    };

    if (primaryContactCheck) {
      data["entityData"]["isPrimaryContact"] = true;
    } else {
      data["entityData"]["isPrimaryContact"] = false;
    }

    let contactData = this.state.contactInfo?.filter(
      (c) =>
        c?.email !== undefined &&
        c?.email === values?.email &&
        c?.contact_number !== undefined &&
        c?.contact_number === values?.phone
    );

    if (contactData?.length > 0) {
      message.info("Given Email and Phone Number already exists");
      return true;
    } else {
      this.setState({
        loading: true,
        isContactVisible: false,
      });
      if (this.state.isCheckcontact) {
        this.props.context.updateOwnerRolesData(values["roleOther"]);
      }
      add(appConfig["entityNames"]["checkContact"], data)
        .then((result) => {
          if (result.data.message === "success") {
            message.success("Contact Added Successfully!");
            let contactInfo =
              this.state.contactInfo?.length > 0 ? this.state.contactInfo : [];
            if (recordContactData?.length > 0) {
              let filteredData = this.state.contactInfo.filter(
                (c) => c.id !== recordContactData?.id
              );
              filteredData.push(data["entityData"]);
              this.setState({
                contactInfo: filteredData,
                loading: false,
                isCheckcontact: false,
                newRole: "",
                primaryContactCheck: false,
              });
            }
            contactInfo.push(data["entityData"]);
            this.setState({
              contactInfo,
              loading: false,
              isCheckcontact: false,
              newRole: "",
              primaryContactCheck: false,
            });
          } else {
            message.info(result.data.message);
            this.setState({
              loading: false,
              isCheckcontact: false,
              newRole: "",
              primaryContactCheck: false,
            });
            return true;
          }
        })
        .catch((err) => {
          this.setState({
            loading: false,
            isContactVisible: false,
          });
          console.log(err);
        });
    }
  };

  deleteContactInfo = (id) => {
    let contactInfo = this.state.contactInfo.filter((c) => c.id !== id);
    this.setState({
      contactInfo,
    });
    message.info("Contact Removed Successfully!");
  };

  editContactInfo = (data) => {
    this.setState({
      isContactVisible: true,
      recordContactData: data,
      primaryContactCheck: data?.isPrimaryContact,
    });
  };

  handleCancel = () => {
    this.setState({
      contactName: "",
      contactNumer: "",
      selectedType: "",
      selectedTruckCode: "",
      truckCount: "",
      citySearch: "",
      latitude: "",
      longitude: "",
      usdot: "",
      carrierName: "",
    });
  };

  onClickClearFilter = () => {
    localStorage.removeItem("filterStorageData", JSON.stringify({}));
    this.props.actionFetchTrucksList();
    this.props.context.getTrucksType();
    this.setState({ visible: false, checkData: [] });
  };

  onResetData = () => {
    this.props.actionFetchTrucksList();
    this.props.context.getTrucksType();
  };

  searchResult = (e) => {
    let searchValue = e.target.value;
    const { trucksData } = this.state;
    let searchFilter = [];
    if (searchValue === undefined || searchValue === "") {
      this.setState({
        filterData: trucksData,
      });
      return;
    }
    if (typeof searchValue === "number" || !isNaN(searchValue)) {
      searchValue = parseFloat(searchValue);
      searchFilter = trucksData?.filter(
        (e) => parseInt(e?.truckId) === searchValue
      );
    } else {
      searchValue = searchValue.trim().toLowerCase();
      searchFilter = trucksData?.filter(
        (e) =>
          e["carrier_name"]?.toLowerCase().indexOf(searchValue) > -1 ||
          e["contact_name"]?.toLowerCase().indexOf(searchValue) > -1
      );
    }
    this.setState({
      filterData: searchFilter,
    });
  };

  onFinishTruckDetails = (values) => {
    const {
      selectedAddress,
      equipmentTypeDetails,
      recordTruckData,
      truckLat,
      truckLng,
      selectedTruckCode,
      // localTruckId,
      // truckId,
      quoteAttachment,
      quoteAttachmentId,
      carrierName,
    } = this.state;
    if (
      (selectedAddress !== "" && selectedAddress !== undefined) ||
      (recordTruckData?.parking_location !== "" &&
        recordTruckData?.parking_location !== undefined)
    ) {
      this.setState({ loading: true, addTruck: false });
      let data = {
        entityData: {
          carrier_name: carrierName,
          id: recordTruckData?.id ? recordTruckData?.id : uuidv4(),
          parking_location: selectedAddress,
          lat: truckLat,
          lng: truckLng,
          truck_type: values["truck_type"],
          overWeightPermit: values["overWeightPermit"],
          overWeightExpiryDate:
            values["overWeightPermit"] === "Yes"
              ? moment(values["overWeightExpiryDate"]).format("L")
              : "",
          empty_weight: values["emptyWeight"],
          truck_code: selectedTruckCode,
          // truckId: recordTruckData?.truckId,
          // carrierId: this.props?.match?.params?.id,
          docAttachment:
            values["overWeightPermit"] === "Yes" ? quoteAttachment : "",
          docAttachmentId:
            values["overWeightPermit"] === "Yes" ? quoteAttachmentId : "",
          secondTruckNo: values["secondTruckNo"],
        },
      };

      let truckInfo =
        equipmentTypeDetails?.length > 0 ? equipmentTypeDetails : [];
      truckInfo.push(data["entityData"]);
      this.setState({
        equipmentTypeDetails: truckInfo,
        loading: false,
        selectedAddress: "",
        truckLat: "",
        truckLng: "",
        selectedTruckCode: "",
      });
      // let finalData = {
      //   entityData: {
      //     carrierDetails: truckInfo,
      //   },
      // };
      // console.log(finalData, "finalData");
      // this.onFinish(finalData);
    } else {
      message.info("Please Enter Parking Location");
    }
  };

  onCancelTruck = () => {
    const { recordTruckData } = this.state;
    // console.log(recordTruckData)
    if (
      recordTruckData !== "" &&
      recordTruckData !== undefined &&
      recordTruckData?.length !== 0
    ) {
      let truckInfo =
        this.state.equipmentTypeDetails?.length > 0
          ? this.state?.equipmentTypeDetails
          : [];
      truckInfo.push(recordTruckData);
      this.setState({
        equipmentTypeDetails: truckInfo,
        recordTruckData: "",
        addTruck: false,
        selectedTruckCode: "",
      });
      // this.formRef.current.setFieldsValue({ material: "", quantity: "" });
    } else {
      this.setState({
        addTruck: false,
        selectedTruckCode: "",
        overWeightPermitState: "",
      });
    }
  };

  onClickEdit = (data) => {
    // console.log(data);
    let filteredData = this.state.equipmentTypeDetails.filter(
      (c) => c.id !== data?.id
    );
    this.setState({
      equipmentTypeDetails: filteredData,
      addTruck: true,
      recordTruckData: data,
      selectedAddress: data?.parking_location,
      latitude: data?.lat,
      longitude: data?.lng,
      selectedTruckCode: data?.truck_code,
      quoteAttachment: data?.docAttachment,
      quoteAttachmentId: data?.docAttachmentId,
      overWeightPermitState: data?.overWeightPermit,
    });
  };

  deleteTruckDetails = (id) => {
    let truckInfo = this.state.equipmentTypeDetails?.filter((c) => c.id !== id);
    // console.log(truckInfo);
    message.info("Truck Details Deleted Successfully!");
    let finalData = {
      entityData: {
        carrierDetails: truckInfo,
      },
    };
    this.updateCompanyDetails(finalData);
    this.setState({
      equipmentTypeDetails: truckInfo,
    });
  };

  onClickDelete = (id) => {
    const { newContactData } = this.state;
    const filterData = newContactData?.filter((e) => e.truckId !== id);
    // console.log(filterData);
    this.setState({ newContactData: filterData });
  };

  onFinishCarrier = (values) => {
    const {
      // equipmentTypeDetails,
      localTruckId,
      assignTruckId,
      longitude,
      latitude,
      contactInfo,
      carrierAddress,
      selectedTruckCode,
    } = this.state;
    if (latitude !== "" && longitude !== "" && carrierAddress !== "") {
      if (contactInfo?.length > 0) {
        this.setState({
          addCarrier: false,
        });

        let data = {
          carrier_name: values["carrier_name"],
          id: uuidv4(),
          parking_location: carrierAddress,
          lat: latitude,
          lng: longitude,
          truck_type: values["truck_type"],
          overWeightPermit: "",
          overWeightExpiryDate: "",
          empty_weight: "",
          truck_code: selectedTruckCode,
          truckId:
            localTruckId > assignTruckId ? localTruckId + 1 : assignTruckId + 1,
          docAttachment: "",
          docAttachmentId: "",
          secondTruckNo: "",
        };
        values["equipmentTypeDetails"] = [data];
        // console.log(equipmentTypeDetails) Equipment &
        this.onFinishTotalData(values);
      } else {
        message.info("Please Add Contact Information");
      }
    } else {
      message.info("Please Enter Office Address");
    }
  };

  onFinishTotalData = (values) => {
    const {
      // equipmentTypeDetails,
      contactInfo,
      payment_discount,
      latitude,
      longitude,
      carrierAddress,
    } = this.state;
    let commonId = uuidv4();
    values["equipmentTypeDetails"]?.map((e) => (e["carrierId"] = commonId));
    let data = {
      entityData: {
        id: commonId,
        carrier_name: values["carrier_name"],
        carrierDetails: values["equipmentTypeDetails"], //equipmentTypeDetails
        contactDetails: contactInfo,
        usdot: values["usdot"],
        email: values["carrier_email"],
        payment_terms: values["payment_terms"],
        payment_discount: payment_discount,
        insurance_expiry:
          values["insurance_expiry"] === null
            ? ""
            : values["insurance_expiry"] === ""
            ? ""
            : moment(values["insurance_expiry"]).format("L"),
        registered_since:
          values["registeredSince"] === null
            ? ""
            : moment(values["registeredSince"]).format("L"),
        direct_deposit: "Direct Deposit", //values["direct_deposit"]
        lat: latitude,
        lng: longitude,
        address: carrierAddress,
      },
    };
    this.addNewCarrier(data);
  };

  handleUploadPic = async (info) => {
    this.setState({
      imageLoading: true,
      loading: true,
    });
    switch (info.file.status) {
      case "done":
        if (info.fileList.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              Storage.put(info.file.uid, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                Storage.get(result.key).then((urlResult) => {
                  this.setState({
                    quoteAttachment: urlResult,
                    imageLoading: false,
                    loading: false,
                    quoteAttachmentId: result.key,
                  });
                });
              });
            } else {
              this.setState({
                imageLoading: false,
                loading: false,
              });
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;
      default:
    }
  };

  fileDownload = async (fileName, downloadFileName, companyName) => {
    let savedFileName = downloadFileName + "_" + companyName.replace(/ /g, "");
    this.setState({
      loading: true,
    });
    Storage.get(fileName, { download: true }).then((res) =>
      this.downloadBlob(res.Body, savedFileName)
    );
  };

  downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    // a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    this.setState({
      loading: false,
    });
    return a;
  }

  onChangeOverWeight = (e) => {
    this.setState({
      overWeightPermitState: e?.target?.value,
    });
    if (e?.target?.value === "No") {
      this.formRef.current.setFieldsValue({
        overWeightExpiryDate: "",
      });
      this.setState({
        quoteAttachment: "",
        quoteAttachmentId: "",
      });
    }
  };

  insuranceExpired = (data, type) => {
    let today = moment().add(30, "days").calendar();
    let expired = [];
    let expiredSoon = [];
    data?.forEach((element) => {
      if (
        new Date().getTime() >= new Date(element?.insurance_expiry).getTime()
      ) {
        expired.push(element);
      } else if (
        new Date(today).getTime() >
          new Date(element?.insurance_expiry).getTime() &&
        new Date().getTime() < new Date(element?.insurance_expiry).getTime()
      ) {
        expiredSoon.push(element);
      }
    });
    if (type === "Soon") {
      return expiredSoon;
    } else {
      return expired;
    }
  };

  trucksTypeCount = (data, truckType) => {
    return data?.filter((e) => e?.truck_code === truckType);
  };

  downloadTrucks = (selectedData) => {
    this.setState({
      loading: true,
    });
    if (selectedData?.length > 0) {
      let data = [];
      const header = [
        "TRUCK#",
        "CARRIER NAME",
        "USDOT",
        "CONTACT",
        "PHONE",
        "TRUCK",
        "INSURANCE EXPIRY",
      ];
      selectedData?.map((e) =>
        data.push({
          truckId: e.truckId,
          carrier_name: e.carrier_name,
          usdot: e.usdot,
          contact_name: e.contact_name,
          contact_number: e.contact_number,
          truck_code: e.truck_code,
          insurance_expiry: e.insurance_expiry,
        })
      );

      downloadExcel({
        fileName: "Owner Operator Database Details",
        sheet: "Owner Operator Database",
        tablePayload: {
          header,
          body: data,
        },
      });
      this.setState({
        loading: false,
      });

      message.success("Record downloaded Successfully!");
    }
  };

  handleTableChage = (props) => {
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    localStorage.setItem(
      "tableSizeRange",
      JSON.stringify({ ...tableSizeRange, TNBSOperator: props })
    );
  };

  archiveEdit = (record) => {
    let data = {
      entityData: {
        showCarrier:
          record.showCarrier !== undefined ? !record.showCarrier : false,
      },
    };
    this.props.actionUpdateTrucksList(record?.id, data);
    message.success(
      `${record.carrier_name} ${
        data["entityData"]["showCarrier"] ? "retrived" : "archived"
      } successfully`
    );
  };

  bannedData = (values, record) => {
    let data = {
      entityData: {
        banCarrier:
          record.banCarrier !== undefined ? !record.banCarrier : false,
        banReason: values["banReason"],
      },
    };
    this.props.actionUpdateTrucksList(record?.id, data);
    message.success(
      `${record.carrier_name} ${
        data["entityData"]["banCarrier"] ? "unban" : "ban"
      } successfully`
    );
    this.setState({
      showbanModel: false,
    });
  };

  compareFilters = (a, b) => {
    const today = moment().add(30, "days").calendar();
    const aExpired = new Date(a.insurance_expiry) < new Date(today);
    const bExpired = new Date(b.insurance_expiry) < new Date(today);

    if (aExpired && !bExpired) {
      return -1;
    }
    if (!aExpired && bExpired) {
      return 1;
    }

    return parseInt(a.truckId) < parseInt(b?.truckId) ? 1 : -1;
  };

  render() {
    const filterInfo =
      JSON.parse(localStorage.getItem("filterStorageData"))?.filterShowData ||
      {};
    const {
      bannedData,
      banData,
      showbanModel,
      archiveData,
      selectedAddress,
      paymentTermsVal,
      carrierAddress,
      filterCarrierData,
      trucksTypes,
      paymentTerms,
      carrierCount,
      selectedRowKeys,
      filterData,
      selected,
      visible,
      checkData,
      citySearch,
      contactInfo,
      recordContactData,
      isContactVisible,
      newRole,
      addTruck,
      recordTruckData,
      addCarrier,
      overWeightPermitState,
      quoteAttachment,
      imageLoading,
    } = this.state;
    const { userRoleAccess } = this.props.context;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const { loading, errorLoading } = this.props;
    const today = moment().add(30, "days").calendar();
    const openNotification = () => {
      const btn = (
        <div>
          <Button
            style={{
              borderRadius: "5px",
              height: "30px",
              marginRight: "10px",
            }}
            size="small"
            onClick={() => {
              notification.destroy();
              this.deleteBulkTrucks();
            }}
          >
            Confirm
          </Button>
          <Button
            style={{
              borderRadius: "5px",
              height: "30px",
            }}
            size="small"
            onClick={() => notification.destroy()}
          >
            Cancel
          </Button>
        </div>
      );
      notification.warning({
        message:
          "You have selected multiple trucks to be permanently deleted from the roster. This action cannot be undone. Do you wish to proceed?",
        btn,
        placement: "topRight",
      });
    };
    const trucksColumns = [
      {
        title: "TRUCK#",
        dataIndex: "truckId",
        key: "truckId",
        className: "col_styling",
        width: "5%",
        onCell: (record) => ({
          onClick: () => {
            this.props.history.push(
              `/app/twisted-nail/carrier-profile/${record?.id}`
            );
          },
        }),
        sorter: (a, b) => parseInt(a?.truckId) - parseInt(b?.truckId),

        render: (truckId, record) => {
          return {
            props: {
              style: {
                background:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fcfacc"
                    : "white",
                borderColor:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fadb14"
                    : "#d9d9d9",
              },
            },
            children: (
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="col_styling table-font-mobile"
              >
                {truckId}
              </span>
            ),
          };
        },
      },
      {
        title: "CARRIER NAME",
        dataIndex: "carrier_name",
        key: "carrier_name",
        className: "col_styling",
        width: "17%",
        onCell: (record) => ({
          onClick: () => {
            this.props.history.push(
              `/app/twisted-nail/carrier-profile/${record?.id}`
            );
          },
        }),
        sorter: (a, b) => a?.carrier_name?.localeCompare(b?.carrier_name),
        render: (carrier_name, record) => {
          return {
            props: {
              style: {
                background:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fcfacc"
                    : "white",
                borderColor:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fadb14"
                    : "#d9d9d9",
              },
            },
            children: (
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="col_styling table-font-mobile"
              >
                {carrier_name}
              </span>
            ),
          };
        },
      },
      {
        title: "USDOT",
        dataIndex: "usdot",
        key: "usdot",
        className: "col_styling",
        width: "8%",
        // onCell: (record) => ({
        //   onClick: () =>
        //     this.props.history.push(
        //       `/app/twisted-nail/carrier-profile/${record?.id}`
        //     ),
        // }),
        // sorter: (a, b) =>
        //   a.contactDetails
        //     ?.filter((e) => e.isPrimaryContact === true)[0]
        //     ?.contact_name.localeCompare(
        //       b.contactDetails?.filter((e) => e.isPrimaryContact === true)[0]
        //         ?.contact_name
        //     ),
        // sorter: (a, b) => a?.usdot - b?.usdot,
        render: (usdot, record) => {
          return {
            props: {
              style: {
                background:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fcfacc"
                    : "white",
                borderColor:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fadb14"
                    : "#d9d9d9",
              },
            },
            children: (
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="col_styling table-font-mobile"
              >
                {
                  <a
                    href={"https://safer.fmcsa.dot.gov/CompanySnapshot.aspx"}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {usdot}
                  </a>
                }
              </span>
            ),
          };
        },
      },
      {
        title: "CONTACT",
        dataIndex: "contact_name",
        key: "contact_name",
        className: "col_styling",
        width: "15%",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(
              `/app/twisted-nail/carrier-profile/${record?.id}`
            ),
        }),
        // sorter: (a, b) =>
        //   a.contactDetails
        //     ?.filter((e) => e.isPrimaryContact === true)[0]
        //     ?.contact_name.localeCompare(
        //       b.contactDetails?.filter((e) => e.isPrimaryContact === true)[0]
        //         ?.contact_name
        //     ),
        sorter: (a, b) => a?.contact_name?.localeCompare(b?.contact_name),
        render: (contact_name, record) => {
          return {
            props: {
              style: {
                background:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fcfacc"
                    : "white",
                borderColor:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fadb14"
                    : "#d9d9d9",
              },
            },
            children: (
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="col_styling table-font-mobile"
              >
                {contact_name}
              </span>
            ),
          };
        },
      },
      {
        title: "PHONE",
        dataIndex: "contact_number",
        key: "contact_number",
        className: "col_styling",
        width: "10%",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(
              `/app/twisted-nail/carrier-profile/${record?.id}`
            ),
        }),
        render: (contact_number, record) => {
          return {
            props: {
              style: {
                background:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fcfacc"
                    : "white",
                borderColor:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fadb14"
                    : "#d9d9d9",
              },
            },
            children: (
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="col_styling table-font-mobile"
              >
                {contact_number}
              </span>
            ),
          };
        },
      },
      // {
      //   title: "PAYLOAD",
      //   dataIndex: "truck_code",
      //   key: "truck_code",
      //   className: "col_styling",
      //   width: "10%",
      //   onCell: (record) => ({
      //     onClick: () =>
      //       this.props.history.push(
      //         `/app/twisted-nail/carrier-profile/${record?.id}`
      //       ),
      //   }),
      //   render: (truck_code) => {
      //     return (
      //       <>
      //         <span
      //           style={{
      //             textTransform: "capitalize",
      //             color: "#474747",
      //             fontSize: "16px",
      //             fontFamily: "sans-serif",
      //           }}
      //         >
      //           {truck_code}
      //         </span>
      //       </>
      //     );
      //   },
      // },
      {
        title: "TRUCK",
        dataIndex: "truck_code",
        key: "truck_code",
        className: "col_styling",
        width: "10%",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(
              `/app/twisted-nail/carrier-profile/${record?.id}`
            ),
        }),
        render: (truck_code, record) => {
          return {
            props: {
              style: {
                background:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fcfacc"
                    : "white",
                borderColor:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fadb14"
                    : "#d9d9d9",
              },
            },
            children: (
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="col_styling table-font-mobile"
              >
                {truck_code}
              </span>
            ),
          };
        },
      },
      {
        title: "Ins.Expiry",
        dataIndex: "insurance_expiry",
        key: "insurance_expiry",
        className: "col_styling",
        width: "10%",
        onCell: (record) => ({
          onClick: () => {
            this.props.history.push(
              `/app/twisted-nail/carrier-profile/${record?.id}`
            );
          },
        }),
        sorter: (a, b) =>
          moment(a.insurance_expiry).unix() - moment(b.insurance_expiry).unix(),
        render: (insurance_expiry, record) => {
          return {
            props: {
              style: {
                background:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fcfacc"
                    : "white",
                borderColor:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fadb14"
                    : "#d9d9d9",
              },
            },
            children: (
              <span
                style={{
                  textTransform: "capitalize",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                  color: "#474747",
                }}
                className="col_styling table-font-mobile"
              >
                {moment(insurance_expiry).format("l")}
              </span>
            ),
          };
        },
      },
      // {
      //   title: "PAYMENT TERMS",
      //   dataIndex: "payment_terms",
      //   key: "payment_terms",
      //   className: "col_styling",
      //   width: "9%",
      //   onCell: (record) => ({
      //     onClick: () => {
      //       this.props.history.push(
      //         `/app/twisted-nail/carrier-profile/${record?.id}`
      //       );
      //     },
      //   }),
      //   // sorter: (a, b) =>
      //   //   moment(a.insurance_expiry).unix() - moment(b.insurance_expiry).unix(),
      //   render: (payment_terms) => {
      //     return (
      //       <>
      //         <span
      //           style={{
      //             textTransform: "capitalize",
      //             fontSize: "16px",
      //             fontFamily: "sans-serif",
      //             // color:
      //             //   new Date(today).getTime() <=
      //             //   new Date(insurance_expiry).getTime()
      //             //     ? "black"
      //             //     : "#ff6961",
      //           }}
      //         >
      //           {payment_terms}
      //         </span>
      //       </>
      //     );
      //   },
      // },
      // {
      //   title: "DEPOSIT METHOD",
      //   dataIndex: "direct_deposit",
      //   key: "direct_deposit",
      //   className: "col_styling",
      //   width: "9%",
      //   onCell: (record) => ({
      //     onClick: () => {
      //       this.props.history.push(
      //         `/app/twisted-nail/carrier-profile/${record?.id}`
      //       );
      //     },
      //   }),
      //   // sorter: (a, b) =>
      //   //   moment(a.insurance_expiry).unix() - moment(b.insurance_expiry).unix(),
      //   render: (direct_deposit) => {
      //     return (
      //       <>
      //         <span
      //           style={{
      //             textTransform: "capitalize",
      //             fontSize: "16px",
      //             fontFamily: "sans-serif",
      //             // color:
      //             //   new Date(today).getTime() <=
      //             //   new Date(insurance_expiry).getTime()
      //             //     ? "black"
      //             //     : "red",
      //           }}
      //         >
      //           {direct_deposit === "Direct Deposit" ? "DD" : direct_deposit}
      //         </span>
      //       </>
      //     );
      //   },
      // },
      // {
      //   title: "CARRIER STATUS",
      //   dataIndex: "carrier_status",
      //   key: "carrier_status",
      //   sorter: false,
      //   className: "col_styling",
      //   width: "180px",
      //   onCell: (record) => ({
      //     onClick: () =>
      //       this.props.history.push(
      //         `/app/twisted-nail/carrier-profile/${record?.id}`
      //       ),
      //   }),
      //   render: (carrier_status) => {
      //     // console.log(record);
      //     return (
      //       <Button
      //         className="col_styling"
      //         style={{
      //           color:
      //             carrier_status === "Active Carrier"
      //               ? "#4C9150"
      //               : carrier_status === "Updated Needed"
      //               ? "#434F61"
      //               : "#D15300",
      //           fontSize: "16px",
      //           fontFamily: "sans-serif",
      //           fontWeight: "600",
      //           borderColor:
      //             carrier_status === "Active Carrier"
      //               ? "#4C9150"
      //               : carrier_status === "Updated Needed"
      //               ? "#434F61"
      //               : "#D15300",
      //           borderRadius: "5px",
      //         }}
      //       >
      //         {carrier_status}
      //       </Button>
      //     );
      //   },
      // },
      {
        title: "ACTIONS",
        dataIndex: "",
        key: "",
        sorter: false,
        className: "col_styling",
        width: "5%",
        fixed: "right",
        render: (record) => {
          return {
            props: {
              style: {
                background:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fcfacc"
                    : "white",
                borderColor:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fadb14"
                    : "#d9d9d9",
              },
            },
            children: (
              <Row
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  flexWrap: "nowrap",
                }}
                className="col_styling table-font-mobile"
              >
                <Col>
                  <Popover
                    placement="left"
                    content={
                      <Row
                        className="popovergrid"
                        style={{ maxWidth: "100px" }}
                      >
                        {banData && (
                          <Col span={24}>
                            <Button className="btn_edit">
                              <Popconfirm
                                title={
                                  <span>
                                    {`Are you sure you want to ${
                                      record?.showCarrier === false
                                        ? "retrive"
                                        : "archive"
                                    } `}
                                    <b>{record.carrier_name}？</b>
                                  </span>
                                }
                                okText={
                                  <span
                                    style={{ width: "60px", height: "30px" }}
                                  >
                                    Yes
                                  </span>
                                }
                                cancelText={
                                  <span
                                    style={{ width: "60px", height: "30px" }}
                                  >
                                    No
                                  </span>
                                }
                                onConfirm={() => this.archiveEdit(record)}
                              >
                                <span className="span_edit">
                                  {record?.showCarrier === false ? (
                                    <>
                                      <MdUnarchive
                                        style={{ marginRight: "5px" }}
                                      />{" "}
                                      Retrive
                                    </>
                                  ) : (
                                    <>
                                      <MdArchive
                                        style={{ marginRight: "5px" }}
                                      />{" "}
                                      Archive
                                    </>
                                  )}
                                </span>
                              </Popconfirm>
                            </Button>
                          </Col>
                        )}
                        {archiveData && (
                          <Col span={24}>
                            <Button className="btn_edit">
                              <Popconfirm
                                title={
                                  <span>
                                    {`Are you sure you want to ${
                                      record?.banCarrier === false
                                        ? "unban"
                                        : "ban"
                                    } `}
                                    <b>{record.carrier_name}？</b>
                                  </span>
                                }
                                okText={
                                  <span
                                    style={{ width: "60px", height: "30px" }}
                                  >
                                    Yes
                                  </span>
                                }
                                cancelText={
                                  <span
                                    style={{ width: "60px", height: "30px" }}
                                  >
                                    No
                                  </span>
                                }
                                onConfirm={() => {
                                  this.setState({
                                    showbanModel: banData,
                                    bannedData: record,
                                  });
                                  if (!banData) {
                                    this.bannedData(record, record);
                                  }
                                }}
                              >
                                <span className="span_edit">
                                  {record?.banCarrier === false ? (
                                    <>
                                      <StopOutlined
                                        style={{ marginRight: "5px" }}
                                      />{" "}
                                      Unban
                                    </>
                                  ) : (
                                    <>
                                      <StopOutlined
                                        style={{ marginRight: "5px" }}
                                      />{" "}
                                      Ban
                                    </>
                                  )}
                                </span>
                              </Popconfirm>
                            </Button>
                          </Col>
                        )}
                        {/*
                          <Col span={24}>
                          <Button
                            style={{
                              fontSize: "15px",
                              cursor: "pointer",
                              border: "none",
                              padding: "0px",
                              textAlign: "left",
                              width: "100%",
                            }}
                            onClick={() => this.editModal(record)}
                          >
                            <span
                              style={{
                                color: "black",
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "left",
                                height: "auto",
                              }}
                            >
                              <MdModeEditOutline
                                style={{ marginRight: "5px" }}
                              />{" "}
                              Edit
                            </span>
                          </Button>
                        </Col>
                         <Col span={24}>
                          <Button
                            style={{
                              fontSize: "15px",
                              cursor: "pointer",
                              border: "none",
                              padding: "0px",
                              textAlign: "left",
                              width: "100%",
                            }}
                          >
                            <Popconfirm
                              title="Are you sure？"
                              okText="Yes"
                              cancelText="No"
                              onConfirm={() => this.deleteTruck(record)}
                            >
                              <span
                                style={{
                                  color: "black",
                                  display: "flex",
                                  flexWrap: "wrap",
                                  alignItems: "center",
                                  justifyContent: "left",
                                }}
                              >
                                <MdDelete style={{ marginRight: "5px" }} />{" "}
                                Delete
                              </span>
                            </Popconfirm>
                          </Button>
                        </Col> */}
                      </Row>
                    }
                  >
                    <EllipsisOutlined
                      style={{ fontSize: "30px", color: "grey" }}
                      className="action-ellipsis-button"
                    />
                  </Popover>
                </Col>
              </Row>
            ),
          };
        },
      },
    ];

    const searchFields = ["carrierDetails", "carrier_name"];

    return (
      <>
        <Loading enableLoading={loading} />
        <div
          style={{
            background: "#fafafa",
          }}
        >
          {errorLoading ? (
            <Col
              xxl={{ span: 24 }}
              xl={{ span: 24 }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <ErrorView
                refreshLeadDetails={this.onRefresh}
                type="LeadDetails"
              />
            </Col>
          ) : (
            <>
              <Row style={{ marginBottom: "1vw" }}>
                <Col span={24}>
                  <Row
                    style={{
                      borderRadius: "10px",
                      marginBottom: "20px",
                      padding: "0px 20px",
                      backgroundColor: "#ffffff",
                      border: "1px solid gray",
                      textAlign: "left",
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    <Col
                      xxl={{ span: 6 }}
                      xl={{ span: 6 }}
                      lg={{ span: 6 }}
                      md={{ span: 12 }}
                      sm={{ span: 12 }}
                      xs={{ span: 24 }}
                      style={{ margin: "12px 0px" }}
                    >
                      <p
                        style={{
                          fontSize: "26px",
                          fontWeight: "bold",
                          marginBottom: "0px",
                        }}
                        className="owner-operator-card-body"
                      >
                        <span
                          style={{
                            fontSize: "18px",
                            color: "#747474",
                            fontWeight: "600",
                            width: "100%",
                            display: "flex",
                            flexWrap: "nowrap",
                          }}
                          className="owner-operator-card-head"
                        >
                          Carriers #
                        </span>
                        {carrierCount?.length}
                      </p>
                    </Col>
                    <Col
                      xxl={{ span: 6 }}
                      xl={{ span: 6 }}
                      lg={{ span: 6 }}
                      md={{ span: 12 }}
                      sm={{ span: 12 }}
                      xs={{ span: 24 }}
                      style={{ margin: "12px 0px" }}
                    >
                      <p
                        style={{
                          fontSize: "26px",
                          fontWeight: "bold",
                          marginBottom: "0px",
                        }}
                        className="owner-operator-card-body"
                      >
                        <span
                          style={{
                            fontSize: "18px",
                            color: "#747474",
                            fontWeight: "600",
                            width: "100%",
                            display: "flex",
                            flexWrap: "nowrap",
                          }}
                          className="owner-operator-card-head"
                        >
                          Trucks #
                        </span>
                        {
                          filterData.filter(
                            (e) =>
                              e.showCarrier !== false && e.banCarrier !== false
                          )?.length
                        }
                      </p>
                    </Col>
                    <Col
                      xxl={{ span: 6 }}
                      xl={{ span: 6 }}
                      lg={{ span: 6 }}
                      md={{ span: 12 }}
                      sm={{ span: 12 }}
                      xs={{ span: 24 }}
                      style={{ margin: "12px 0px" }}
                    >
                      <p
                        style={{
                          fontSize: "26px",
                          fontWeight: "bold",
                          marginBottom: "0px",
                        }}
                        className="owner-operator-card-body"
                      >
                        <span
                          style={{
                            fontSize: "18px",
                            color: "#747474",
                            fontWeight: "600",
                            width: "100%",
                            display: "flex",
                            flexWrap: "nowrap",
                          }}
                          className="owner-operator-card-head"
                        >
                          Insurance Expired #
                        </span>
                        {this.insuranceExpired(carrierCount, "Expired")?.length}
                      </p>
                    </Col>
                    <Col
                      xxl={{ span: 6 }}
                      xl={{ span: 6 }}
                      lg={{ span: 6 }}
                      md={{ span: 12 }}
                      sm={{ span: 12 }}
                      xs={{ span: 24 }}
                      style={{ margin: "12px 0px" }}
                    >
                      <p
                        style={{
                          fontSize: "26px",
                          fontWeight: "bold",
                          marginBottom: "0px",
                        }}
                        className="owner-operator-card-body"
                      >
                        <span
                          style={{
                            fontSize: "18px",
                            color: "#747474",
                            fontWeight: "600",
                            width: "100%",
                            display: "flex",
                            flexWrap: "nowrap",
                          }}
                          className="owner-operator-card-head"
                        >
                          Insurance Expiring Soon #
                        </span>
                        {this.insuranceExpired(carrierCount, "Soon")?.length}
                      </p>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      borderRadius: "10px",
                      marginBottom: "20px",
                      padding: "0px 20px",
                      backgroundColor: "#ffffff",
                      border: "1px solid gray",
                      textAlign: "left",
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      overflowX: "auto",
                    }}
                  >
                    <div
                      className="typematerials"
                      style={{ width: "100%", margin: "8px 0px" }}
                    >
                      {trucksTypes?.map((element) => (
                        <div
                          className="itemtruckType"
                          style={{ maxWidth: `${300 / trucksTypes?.length}%` }}
                        >
                          <p
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                              marginBottom: "0px",
                            }}
                            className="owner-operator-card-body"
                          >
                            <span
                              style={{
                                fontSize: "18px",
                                color: "#747474",
                                fontWeight: "600",
                                width: "100%",
                                display: "flex",
                                flexWrap: "nowrap",
                              }}
                              className="owner-operator-card-head"
                            >
                              {element.truck_code}
                            </span>
                            {
                              this.trucksTypeCount(
                                filterData.filter(
                                  (e) =>
                                    e.showCarrier !== false &&
                                    e.banCarrier !== false
                                ),
                                element.truck_code
                              )?.length
                            }
                          </p>
                        </div>
                      ))}
                    </div>
                  </Row>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                  xxl={{ span: 6 }}
                >
                  <Row>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}
                      xl={{ span: 24 }}
                      xxl={{ span: 24 }}
                      className="quarrytoolgrid"
                    >
                      {/* <SearchFunction
                        searchData={filterData}
                        tableName={"trucksList"}
                        getSearchData={this.searchResult}
                        onResetData={this.onResetData}
                        searchFields={searchFields}
                      /> */}
                      <Search
                        className="products-search"
                        size="large"
                        searchData={filterData}
                        tableName={"trucksList"}
                        // getSearchData={this.searchResult}
                        // onResetData={this.onResetData}
                        style={{ borderRadius: "5px" }}
                        enterButton
                        onChange={this.searchResult}
                        allowClear
                        searchFields={searchFields}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 18 }}
                  xl={{ span: 18 }}
                  xxl={{ span: 18 }}
                  // span={12}
                >
                  <Row style={{ justifyContent: "right" }}>
                    {selectedRowKeys?.length > 0 &&
                      userRoleAccess?.roleName === "Admin" && (
                        <Col className="gridbtntool">
                          {/* <Popconfirm
                          title="
                          You have selected multiple trucks to be permanently deleted from the roster. This action cannot be undone. Do you wish to proceed?"
                          okText="Confirm"
                          cancelText="Cancel"
                          onConfirm={() => this.deleteBulkTrucks}
                        > */}
                          <Button
                            icon={<DeleteOutlined style={{ color: "red" }} />}
                            onClick={openNotification}
                            style={{
                              borderRadius: "5px",
                              height: "35px",
                              margin: "auto 0px",
                              border: "1px solid red",
                            }}
                          >
                            <span style={{ color: "red" }}>Bulk Delete</span>
                          </Button>
                          {/* </Popconfirm> */}
                        </Col>
                      )}
                    <Col className="gridbtntool">
                      <Button
                        type="primary"
                        icon={
                          archiveData ? (
                            <MdArchive style={{ fontSize: "18px" }} />
                          ) : (
                            <MdUnarchive style={{ fontSize: "18px" }} />
                          )
                        }
                        onClick={() =>
                          this.setState({
                            archiveData: !archiveData,
                            banData: true,
                          })
                        }
                        style={{
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "5px",
                          height: "40px",
                        }}
                      >
                        <span
                          className="archive-button-ownerOperator"
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            marginLeft: "5px",
                          }}
                        >
                          {archiveData ? "Archive" : "Retrive"}
                        </span>
                      </Button>
                    </Col>

                    <Col className="gridbtntool">
                      <Button
                        type="primary"
                        icon={
                          banData ? (
                            <MdArchive style={{ fontSize: "18px" }} />
                          ) : (
                            <MdUnarchive style={{ fontSize: "18px" }} />
                          )
                        }
                        onClick={() =>
                          this.setState({
                            banData: !banData,
                            archiveData: true,
                          })
                        }
                        style={{
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "5px",
                          height: "40px",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            marginLeft: "5px",
                          }}
                          className="archive-button-ownerOperator"
                        >
                          {banData ? "Banned" : "Unbanned"}
                        </span>
                      </Button>
                    </Col>
                    {/* {/* {Object.keys(checkData)?.length > 0 && ( */}
                    <Col className="gridbtntool">
                      <Button
                        className="show-on-desktop"
                        icon={<ArrowDownOutlined style={{ color: "green" }} />}
                        onClick={() =>
                          this.downloadTrucks(
                            filterData.filter(
                              (e) =>
                                e.showCarrier !== false &&
                                e.banCarrier !== false
                            )
                          )
                        }
                        style={{
                          borderRadius: "5px",
                          height: "35px",
                          margin: "auto 0px",
                          border: "1px solid green",
                        }}
                      >
                        <span style={{ color: "green" }}>Download Report</span>
                      </Button>
                      <Button
                        className="show-on-mobile"
                        icon={<ArrowDownOutlined style={{ color: "green" }} />}
                        onClick={() =>
                          this.downloadTrucks(
                            filterData.filter(
                              (e) =>
                                e.showCarrier !== false &&
                                e.banCarrier !== false
                            )
                          )
                        }
                        style={{
                          borderRadius: "5px",
                          height: "35px",
                          margin: "auto 0px",
                          border: "1px solid green",
                        }}
                      >
                        <span style={{ color: "green" }}> Report</span>
                      </Button>
                    </Col>
                    {/* )} */}
                    <Col className="gridbtntool">
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => {
                          this.setState({
                            addCarrier: true,
                            selectedTruckDetails: [],
                            equipmentTypeDetails: [],
                            contactInfo: [],
                            carrierAddress: "",
                            carrierName: "",
                          });
                        }}
                        style={{
                          borderRadius: "5px",
                          height: "35px",
                          margin: "auto 0px",
                        }}
                      >
                        <span className="archive-button-ownerOperator">
                          Carrier
                        </span>
                      </Button>
                    </Col>
                    {/* {!selected && ( */}
                    <Col className="gridbtntool">
                      <Button
                        className="filterButton"
                        style={{
                          borderRadius: "5px",
                          height: "40px",
                        }}
                        onClick={() => {
                          this.setState({ visible: true });
                        }}
                      >
                        <Row>
                          <Col>
                            <span
                              className="show-on-desktop"
                              style={{
                                color:
                                  Object.keys(filterInfo)?.length > 0
                                    ? "red"
                                    : "#5a6067",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              {Object.keys(filterInfo)?.length > 0
                                ? `${
                                    "Filter (" + Object.keys(filterInfo)?.length
                                  })`
                                : "Filter"}
                            </span>
                          </Col>
                          <Col>
                            <IoFilterSharp
                              className="filterIcon"
                              style={{
                                fontSize: "22px",
                                marginLeft: "8px",
                                color:
                                  Object.keys(filterInfo)?.length > 0
                                    ? "red"
                                    : "#5a6067",
                              }}
                            />
                          </Col>
                        </Row>
                      </Button>
                    </Col>

                    <Col className="gridbtntool">
                      <Row
                        style={{
                          background: "#cecece",
                          height: "40px",
                          borderRadius: "6px",
                        }}
                      >
                        <Col style={{ marginTop: "4px" }}>
                          <Button
                            style={{
                              border: "none",
                              borderRadius: "5px",
                              padding: "5px 8px",
                              margin: "0px 5px",
                              background: this.state.selected
                                ? "#FFF"
                                : "transparent",
                              boxShadow: "none",
                              outline: "none",
                            }}
                            onClick={() => this.setState({ selected: true })}
                          >
                            <UnorderedListOutlined
                              style={{
                                fontSize: "22px",
                                color: this.state.selected
                                  ? "#586370"
                                  : "#586370",
                              }}
                            />
                          </Button>
                        </Col>
                        <Col style={{ marginTop: "4px" }}>
                          <Button
                            style={{
                              border: "none",
                              borderRadius: "5px",
                              padding: "5px 8px",
                              margin: "0px 5px",
                              background: this.state.selected
                                ? "transparent"
                                : "#FFF",
                              boxShadow: "none",
                              outline: "none",
                            }}
                            onClick={() => this.setState({ selected: false })}
                          >
                            <FaMap
                              style={{
                                fontSize: "22px",
                                color: !this.state.selected
                                  ? "#586370"
                                  : "#586370",
                              }}
                            />
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <Card bodyStyle={{ padding: "0px" }} data-aos="fade-up">
                    {selected ? (
                      <BaseTable
                        // className="customertablegrp table_laptopscreen"
                        columnsData={trucksColumns}
                        source={
                          !archiveData
                            ? filterData
                                .filter((e) => e.showCarrier === false)
                                ?.sort(
                                  (a, b) =>
                                    new Date(a.insurance_expiry) -
                                    new Date(b.insurance_expiry)
                                )
                            : // .sort((a, b) =>
                            //   parseInt(a.truckId) < parseInt(b?.truckId)
                            //     ? 1
                            //     : -1
                            // )
                            !banData
                            ? filterData
                                .filter((e) => e.banCarrier === false)
                                ?.sort(this.compareFilters)
                            : // .sort((a, b) =>
                              //   parseInt(a.truckId) < parseInt(b?.truckId)
                              //     ? 1
                              //     : -1
                              // )
                              filterData
                                .filter(
                                  (e) =>
                                    e.showCarrier !== false &&
                                    e.banCarrier !== false
                                )
                                ?.sort(this.compareFilters)
                          // .sort((a, b) =>
                          //   parseInt(a.truckId) < parseInt(b?.truckId)
                          //     ? 1
                          //     : -1
                          // )
                        }
                        rowSelection={rowSelection}
                        total={
                          !archiveData
                            ? filterData.filter((e) => e.showCarrier === false)
                                .length
                            : !banData
                            ? filterData.filter((e) => e.banCarrier === false)
                                .length
                            : filterData.filter(
                                (e) =>
                                  e.showCarrier !== false &&
                                  e.banCarrier !== false
                              ).length
                        }
                        rowKey={(record) => record.truckingId}
                        handleTableChage={this.handleTableChage}
                        sizeRange={
                          window.screen.width > 1023
                            ? JSON.parse(localStorage.getItem("tableSizeRange"))
                                ?.TNBSOperator
                            : !archiveData
                            ? filterData.filter((e) => e.showCarrier === false)
                                .length
                            : !banData
                            ? filterData.filter((e) => e.banCarrier === false)
                                .length
                            : filterData.filter(
                                (e) =>
                                  e.showCarrier !== false &&
                                  e.banCarrier !== false
                              ).length
                        }
                        pagination={window.screen.width > 1023 ? true : false}
                      />
                    ) : (
                      <Row>
                        <Col
                          xxl={{ span: 24, offset: 0 }}
                          xl={{ span: 24, offset: 0 }}
                          lg={{ span: 24, offset: 0 }}
                          md={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          xs={{ span: 24, offset: 0 }}
                          // style={{ height: "75vh" }}
                          className="twistednailmap"
                        >
                          {this.showMap(filterData)}
                          {/* <MapComponent truckData={filterData} truck /> */}
                        </Col>
                      </Row>
                    )}
                  </Card>
                  {/* {selectedRowKeys?.length > 0 && (
                    <FooterData count={selectedRowKeys?.length} />
                  )} */}
                </Col>
              </Row>
            </>
          )}

          {showbanModel && (
            <BaseModal
              title={"Reason for ban"}
              onCancel={() => {
                this.setState({
                  showbanModel: false,
                  selectedPaymentType: "",
                });
              }}
              formId="bannedReason"
              className="antmodal_grid headermodal"
              width={
                window.screen.width > "501" && window.screen.width <= "991"
                  ? "80%"
                  : "640px"
              }
              loading={this.state.loading}
            >
              <Form
                onFinish={(values) => this.bannedData(values, bannedData)}
                id="bannedReason"
                ref={this.formRef}
              >
                <Col span={24}>
                  <Form.Item
                    name="banReason"
                    rules={[
                      {
                        required: true,
                        message: "Please enter reason",
                      },
                    ]}
                  >
                    <TextArea
                      rows={4}
                      className="form-input"
                      placeholder="Please enter reason"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Form>
            </BaseModal>
          )}
          {visible && (
            <Filter
              visible={visible}
              closeFilter={this.closeFilter}
              filterResultData={this.filterResultData}
              checkData={checkData}
              twistedOperator
              trucksData={this.props?.context?.trucksData}
              onClickClearFilter={this.onClickClearFilter}
              carrierData={filterCarrierData}
            />
          )}

          {/* {isCompanyModalVisible && (
            <BaseModal
              title={
                companyData !== ""
                  ? "Update Carrier Details"
                  : "Add New Carrier"
              }
              onCancel={() => {
                this.setState({
                  isCompanyModalVisible: false,
                  selectedPaymentType: "",
                });
              }}
              formId="CompanyDetails"
              className="antmodal_grid headermodal"
              width={
                window.screen.width > "501" && window.screen.width <= "991"
                  ? "80%"
                  : "640px"
              }
              loading={this.state.loading}
            >
              <Form
                onFinish={this.onFinishCompanyDetails}
                id="CompanyDetails"
                ref={this.formRef}
              >
                <Row gutter={[32, 0]}>
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={11}
                  >
                    <span className="title_changes">Carrier Name</span>
                    <Form.Item
                      initialValue={companyData?.carrier_name}
                      name="carrier_name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Carrier Name",
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) =>
                          this.setState({ carrierName: e.target.value })
                        }
                        className="form-input"
                        placeholder="Please enter Carrier Name"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">USDOT</span>
                    <Form.Item
                      name="usdot"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter USDOT!",
                        },
                      ]}
                      initialValue={companyData?.usdot}
                    >
                      <NumberFormat
                        className="ant-input-number numberictest"
                        format="##########"
                        style={{ width: "100%" }}
                        placeholder="Please Enter USDOT"
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={11}
                  >
                    <span className="title_changes">
                      Insurance Expiration Date
                    </span>
                    <Form.Item
                      name="insurance_expiry"
                      rules={[
                        {
                          required: false,
                          message: "Select Insurance Exipiry Date",
                        },
                      ]}
                      initialValue={
                        companyData?.insurance_expiry !== "" &&
                        moment(companyData?.insurance_expiry)
                      }
                    >
                      <DatePicker
                        size="large"
                        format={"MM/DD/YYYY"}
                        // className="ant-input-number numberictest"
                        style={{ width: "100%", borderRadius: "5px" }}
                        placeholder="Please Select A Date"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={11}
                  >
                    <span className="title_changes">Registered Since</span>
                    <Form.Item
                      name="registeredSince"
                      rules={[
                        {
                          required: true,
                          message: "Select Registered Since",
                        },
                      ]}
                      initialValue={
                        companyData?.registered_since &&
                        moment(companyData?.registered_since)
                      }
                    >
                      <DatePicker
                        size="large"
                        format={"MM/DD/YYYY"}
                        // className="ant-input-number numberictest"
                        style={{ width: "100%", borderRadius: "5px" }}
                        placeholder="Please Select A Date"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Payment Terms</span>
                    <Form.Item
                      name="payment_terms"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Required!",
                        },
                      ]}
                      initialValue={companyData?.payment_terms}
                    >
                      <Select
                        allowClear
                        className="select_Height"
                        style={{ height: "40px", fontWeight: "300" }}
                        placeholder="Please Select Payment Type"
                        onChange={this.onChangeTerms}
                      >
                        {paymentTerms?.map((e) => (
                          <Option
                            key={e.payment_discount}
                            value={e.payment_terms}
                          >
                            {e.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  {selectedPaymentType === "Custom Input" && (
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      // span={12}
                    >
                      <span className="title_changes">
                        Payment Terms (Others)
                      </span>
                      <Form.Item
                        name="paymentTermsOthers"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter New Payment Terms",
                          },
                        ]}
                      >
                        <NumberFormat
                          className="ant-input-number numberictest"
                          style={{ width: "100%" }}
                          prefix={"Net "}
                          placeholder="Please Enter New Payment Terms"
                        />
                      </Form.Item>
                    </Col>
                  )}
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Deposit Method</span>
                    <Form.Item
                      name="direct_deposit"
                      // onChange={this.getparking_location}
                      rules={[
                        {
                          required: false,
                          errorMessage: true,
                          message: "Please Enter Your Direct Deposit!",
                        },
                      ]}
                      initialValue={companyData?.direct_deposit}
                    >
                      <Radio.Group
                        defaultValue={companyData?.direct_deposit}
                        // onChange={(e) =>
                        //   this.setState({ supplier: e?.target?.value })
                        // }
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "left",
                          // flexWrap: "wrap",
                          // maxWidth:"100%",
                          padding: "8px",
                          border: "1px solid #e5e5e5",
                          height: "40px",
                          borderRadius: "5px",
                        }}
                      >
                        <Radio
                          style={{ fontSize: "16px", whiteSpace: "nowrap" }}
                          value={"Direct Deposit"}
                        >
                          Direct Deposit
                        </Radio>
                        <Radio
                          style={{ fontSize: "16px", whiteSpace: "nowrap" }}
                          value={"Check"}
                        >
                          Check
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Office Phone</span>
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Office Phone!",
                        },
                      ]}
                      initialValue={companyData?.phone}
                    >
                      <NumberFormat
                        className="ant-input-number numberictest"
                        format="(###) ###-####"
                        style={{ width: "100%" }}
                        placeholder="Please Enter Office Phone"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={selectedPaymentType === "Custom Input" ? 24 : 12}>
                    <span className="title_changes">Office Email</span>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Office Email!",
                        },
                        {
                          type: "email",
                          message: "The Input Is Not Valid Email",
                        },
                      ]}
                      initialValue={companyData?.email}
                    >
                      <Input
                        className="form-input"
                        placeholder="Please Enter Office Email"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={11}
                  >
                    <span className="title_changes">Office Address</span>
                    <Form.Item
                      name="address"
                      rules={[
                        {
                          required: false,
                          message: "Please enter your address",
                        },
                      ]}
                    >
                      <GooglePlacesAutocomplete
                        className="form-input"
                        // apiKey={process.env.REACT_APP_MAPS_KEY}
                        autocompletionRequest={{
                          componentRestrictions: {
                            country: ["us"],
                          },
                        }}
                        selectProps={{
                          defaultInputValue: `${
                            companyData?.address ? companyData?.address : ""
                          }`,
                          citySearch,
                          placeholder: "Search Address...",
                          onChange: (address) => {
                            this.handleSelect(address);
                            this.getAddress(address);
                          },
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {/* <>
                    {contactInfo?.map((c) => (
                      <Col span={24} style={{ marginBottom: "1vw" }}>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            alignItems: "center",
                          }}
                        >
                          <Popover
                            content={
                              (c?.phone || c?.email) && (
                                <Row>
                                  <Col span={24}>
                                    <span>{c.phone}</span>
                                  </Col>
                                  <Col span={24}>
                                    <span
                                      style={{
                                        color: "black",
                                        display: "flex",
                                        flexWrap: "wrap",
                                        alignItems: "center",
                                        justifyContent: "left",
                                      }}
                                    >
                                      {c.email}
                                    </span>
                                  </Col>
                                </Row>
                              )
                            }
                          >
                            <span
                              className="title_changes"
                              style={{
                                margin: "0px 5px",
                                wordBreak: "break-word",
                                width: "100%",
                                maxWidth: "270px",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textTransform: "capitalize",
                                cursor: "pointer",
                              }}
                            >
                              {c.contactRole
                                ? c.firstName +
                                  " " +
                                  c.lastName +
                                  ", " +
                                  c.contactRole
                                : c.firstName + " " + c.lastName}
                            </span>
                          </Popover>
                          <Col>
                            <Popover
                              placement="left"
                              content={
                                <Row className="popovergrid">
                                  <Col span={24}>
                                    <Button
                                      style={{
                                        fontSize: "15px",
                                        cursor: "pointer",
                                        border: "none",
                                        padding: "0px",
                                        textAlign: "left",
                                        width: "100%",
                                        height: "auto",
                                      }}
                                      onClick={() => this.editContactInfo(c)}
                                    >
                                      <span
                                        style={{
                                          color: "black",
                                          display: "flex",
                                          flexWrap: "wrap",
                                          alignItems: "center",
                                          justifyContent: "left",
                                        }}
                                      >
                                        <MdModeEditOutline
                                          style={{ marginRight: "5px" }}
                                        />
                                        Edit
                                      </span>
                                    </Button>
                                  </Col>
                                  <Col span={24}>
                                    <Button
                                      style={{
                                        fontSize: "15px",
                                        cursor: "pointer",
                                        border: "none",
                                        padding: "0px",
                                        textAlign: "left",
                                        width: "100%",
                                        height: "auto",
                                      }}
                                    >
                                      <Popconfirm
                                        title="Are you sure？"
                                        okText="Yes"
                                        cancelText="No"
                                        onConfirm={() =>
                                          this.deleteContactInfo(c.id)
                                        }
                                      >
                                        <span
                                          style={{
                                            color: "black",
                                            display: "flex",
                                            flexWrap: "wrap",
                                            alignItems: "center",
                                            justifyContent: "left",
                                          }}
                                        >
                                          <MdDelete
                                            style={{ marginRight: "5px" }}
                                          />{" "}
                                          Delete
                                        </span>
                                      </Popconfirm>
                                    </Button>
                                  </Col>
                                </Row>
                              }
                            >
                              <EllipsisOutlined
                                style={{
                                  fontSize: "30px",
                                  color: "grey",
                                  marginTop: "3px",
                                }}
                              />
                            </Popover>
                          </Col>
                        </div>
                      </Col>
                    ))}
                    <Col span={24}>
                      <Form.Item name="button">
                        <Button
                          type="primary"
                          style={{
                            width: "100%",
                            fontWeight: "500",
                            height: "40px",
                          }}
                          onClick={() =>
                            this.setState({
                              isContactVisible: true,
                              recordContactData: "",
                            })
                          }
                        >
                          ADD CONTACT
                        </Button>
                      </Form.Item>
                    </Col>

                    {equipmentTypeDetails?.map((c) => (
                      <Col span={24} style={{ marginBottom: "1vw" }}>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            alignItems: "center",
                          }}
                        >
                          <Popover
                            content={
                              (c?.phone || c?.email) && (
                                <Row>
                                  <Col span={24}>
                                    <span>{c.phone}</span>
                                  </Col>
                                  <Col span={24}>
                                    <span
                                      style={{
                                        color: "black",
                                        display: "flex",
                                        flexWrap: "wrap",
                                        alignItems: "center",
                                        justifyContent: "left",
                                      }}
                                    >
                                      {c.email}
                                    </span>
                                  </Col>
                                </Row>
                              )
                            }
                          >
                            <span
                              className="title_changes"
                              style={{
                                margin: "0px 5px",
                                wordBreak: "break-word",
                                width: "100%",
                                maxWidth: "270px",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textTransform: "capitalize",
                                cursor: "pointer",
                              }}
                            >
                              {c.contactRole
                                ? c.firstName +
                                  " " +
                                  c.lastName +
                                  ", " +
                                  c.contactRole
                                : c.firstName + " " + c.lastName}
                            </span>
                          </Popover>
                          <Col>
                            <Popover
                              placement="left"
                              content={
                                <Row className="popovergrid">
                                  <Col span={24}>
                                    <Button
                                      style={{
                                        fontSize: "15px",
                                        cursor: "pointer",
                                        border: "none",
                                        padding: "0px",
                                        textAlign: "left",
                                        width: "100%",
                                        height: "auto",
                                      }}
                                      onClick={() => this.onClickEdit(c)}
                                    >
                                      <span
                                        style={{
                                          color: "black",
                                          display: "flex",
                                          flexWrap: "wrap",
                                          alignItems: "center",
                                          justifyContent: "left",
                                        }}
                                      >
                                        <MdModeEditOutline
                                          style={{ marginRight: "5px" }}
                                        />
                                        Edit
                                      </span>
                                    </Button>
                                  </Col>
                                  <Col span={24}>
                                    <Button
                                      style={{
                                        fontSize: "15px",
                                        cursor: "pointer",
                                        border: "none",
                                        padding: "0px",
                                        textAlign: "left",
                                        width: "100%",
                                        height: "auto",
                                      }}
                                    >
                                      <Popconfirm
                                        title="Are you sure？"
                                        okText="Yes"
                                        cancelText="No"
                                        onConfirm={() =>
                                          this.deleteTruckDetails(c.id)
                                        }
                                      >
                                        <span
                                          style={{
                                            color: "black",
                                            display: "flex",
                                            flexWrap: "wrap",
                                            alignItems: "center",
                                            justifyContent: "left",
                                          }}
                                        >
                                          <MdDelete
                                            style={{ marginRight: "5px" }}
                                          />{" "}
                                          Delete
                                        </span>
                                      </Popconfirm>
                                    </Button>
                                  </Col>
                                </Row>
                              }
                            >
                              <EllipsisOutlined
                                style={{
                                  fontSize: "30px",
                                  color: "grey",
                                  marginTop: "3px",
                                }}
                              />
                            </Popover>
                          </Col>
                        </div>
                      </Col>
                    ))}

                    <Col span={24}>
                      <Form.Item name="button">
                        <Button
                          type="primary"
                          style={{
                            width: "100%",
                            fontWeight: "500",
                            height: "40px",
                          }}
                          onClick={() =>
                            this.setState({
                              addTruck: true,
                              recordTruckData: "",
                              equipmentTypeDetails: [],
                            })
                          }
                        >
                          ADD EQUIPMENT
                        </Button>
                      </Form.Item>
                    </Col>
                  </> 

                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Notes</span>
                    <Form.Item
                      name="notes"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Notes!",
                        },
                      ]}
                      initialValue={companyData?.notes}
                    >
                      <TextArea
                        rows={4}
                        style={{
                          width: "100%",
                          height: "75px",
                          borderColor: "#d6d6d6",
                        }}
                        className="form-input"
                        placeholder="Please Enter Your Notes"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </BaseModal>
          )} */}

          {isContactVisible && (
            <BaseModal
              className="crmform_modal antmodal_grid headermodal"
              title={
                recordContactData !== "" ? "Update Contact" : "Add New Contact"
              }
              onCancel={() => {
                this.setState({
                  isCheckBox: false,
                  isCheckBoxTruckType: false,
                  isContactVisible: false,
                  newRole: "",
                });
              }}
              loading={this.state.loading}
              visible={isContactVisible}
              destroyOnClose={true}
              formId="contact"
              // width={"25%"}
              width={
                window.screen.width > "501" && window.screen.width <= "991"
                  ? "25%"
                  : "500px"
              }
            >
              <Form
                style={{ width: "100%" }}
                onFinish={this.addContact}
                id="contact"
              >
                <Row gutter={[12, 0]}>
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">First Name</span>
                    <Form.Item
                      name="fName"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Your First Name!",
                        },
                      ]}
                      initialValue={recordContactData?.firstName}
                    >
                      <Input
                        onChange={(e) =>
                          this.setState({
                            firstName: e.target.value,
                          })
                        }
                        className="form-input"
                        placeholder="Please Enter Your First Name"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Last Name</span>
                    <Form.Item
                      name="lName"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter your Last Name!",
                        },
                      ]}
                      initialValue={recordContactData?.lastName}
                    >
                      <Input
                        onChange={(e) =>
                          this.setState({
                            lastName: e.target.value,
                          })
                        }
                        className="form-input"
                        placeholder="Please Enter your Last Name"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Phone</span>
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Phone!",
                        },
                      ]}
                      initialValue={recordContactData?.contact_number}
                    >
                      <NumberFormat
                        onChange={(e) =>
                          this.setState({ phone: e.target.value })
                        }
                        className="ant-input-number numberictest"
                        format="(###) ###-####"
                        style={{ width: "100%" }}
                        placeholder="Please Enter Your Phone"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Email</span>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Email!",
                        },
                        {
                          type: "email",
                          message: "The Input Is Not Valid Email",
                        },
                      ]}
                      initialValue={recordContactData?.email}
                    >
                      <Input
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                        className="form-input"
                        placeholder="Please Enter Your Email"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Role</span>
                    <Form.Item
                      name="role"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Role!",
                        },
                      ]}
                      initialValue={
                        recordContactData?.contactRole &&
                        recordContactData?.contactRole
                      }
                    >
                      <Select
                        size="large"
                        showSearch
                        allowClear
                        className="select_Height"
                        // style={{ height: "40px" }}
                        placeholder="Please Select A Role"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onChange={(value) => {
                          this.setState({ newRole: value });
                          value === "Others" &&
                            message.info("Please Input Your Role");
                        }}
                      >
                        {this.props.context.ownerRoles?.map((role, i) => (
                          <Option key={i} value={role.label}>
                            {role.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  {newRole === "Add New Role" && (
                    <Col
                      xxl={{ span: 24 }}
                      xl={{ span: 24 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Add New Role</span>
                      <Form.Item name="roleOther">
                        <Input
                          className="form-input"
                          placeholder="Please Enter Role"
                        />
                      </Form.Item>
                      <Checkbox
                        checked={this.state.isCheckcontact}
                        onChange={(e) =>
                          this.setState({
                            isCheckcontact: e.target.checked,
                          })
                        }
                        style={{ marginBottom: "18px" }}
                      >
                        <span
                          className="checkBox_size"
                          style={{ fontSize: "14px" }}
                        >
                          Add this new role to default list
                        </span>
                      </Checkbox>
                    </Col>
                  )}
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Notes</span>
                    <Form.Item
                      name="notes"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Notes!",
                        },
                      ]}
                      initialValue={recordContactData?.notes}
                    >
                      <TextArea
                        rows={4}
                        style={{
                          width: "100%",
                          height: "65px",
                          borderColor: "#d6d6d6",
                        }}
                        className="form-input"
                        placeholder="Please Enter Your Notes"
                      />
                    </Form.Item>
                  </Col>

                  {(contactInfo?.filter((e) => e?.isPrimaryContact === true)
                    ?.length === 0 ||
                    contactInfo?.length === 0 ||
                    recordContactData?.isPrimaryContact === true) && (
                    <Col
                      xxl={{ span: 24 }}
                      xl={{ span: 24 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      {/* <span className="title_changes">Add this contact as primary</span> */}
                      <Checkbox
                        checked={this.state.primaryContactCheck}
                        onChange={(e) =>
                          this.setState({
                            primaryContactCheck: e.target.checked,
                          })
                        }
                        style={{ marginBottom: "18px" }}
                      >
                        <span
                          className="checkBox_size"
                          style={{ fontSize: "14px" }}
                        >
                          Add This Contact As Primary
                        </span>
                      </Checkbox>
                    </Col>
                  )}
                </Row>
              </Form>
            </BaseModal>
          )}

          {addTruck && (
            <BaseModal
              className="crmform_modal antmodal_grid headermodal"
              title={
                recordTruckData !== "" ? "Update Equipment" : "Add Equipment"
              }
              onCancel={this.onCancelTruck}
              loading={this.state.loading}
              visible={addTruck}
              destroyOnClose={true}
              formId="truck"
              width={"40%"}
            >
              <Form
                style={{ width: "100%" }}
                onFinish={this.onFinishTruckDetails}
                id="truck"
                ref={this.formRef}
              >
                <Row gutter={[16, 8]}>
                  <Col
                    xxl={{ span: 8 }}
                    xl={{ span: 8 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Secondary Truck#</span>
                    <Form.Item
                      name={"secondTruckNo"}
                      rules={[
                        {
                          required: false,
                          message: "Enter Secondary Truck#",
                        },
                      ]}
                      initialValue={recordTruckData?.secondTruckNo}
                    >
                      <InputNumber
                        min={1}
                        style={{
                          width: "100%",
                        }}
                        placeholder="Enter Secondary Truck#"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 8 }}
                    xl={{ span: 8 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Truck Type</span>
                    <Form.Item
                      name={"truck_type"}
                      rules={[
                        {
                          required: true,
                          message: "Please Select Truck Type",
                        },
                      ]}
                      style={{
                        marginBottom: "5px",
                      }}
                      initialValue={recordTruckData?.truck_type}
                    >
                      <Select
                        showSearch
                        allowClear
                        className="select_Height"
                        style={{
                          height: "40px",
                          fontWeight: "300",
                        }}
                        placeholder="Select Truck Type"
                        onChange={(e) => {
                          this.props.context.trucksData?.filter(
                            (t) =>
                              e === t.value &&
                              this.setState({
                                selectedTruckCode: t.truck_code,
                              })
                          );
                        }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        defaultValue={recordTruckData?.truck_type}
                      >
                        {this.props.context.trucksData?.map((t) => (
                          <Option value={t.value} key={t.label}>
                            {t.value}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col
                    xxl={{ span: 8 }}
                    xl={{ span: 8 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Empty Weight</span>
                    <Form.Item
                      name={"emptyWeight"}
                      rules={[
                        {
                          required: false,
                          message: "Enter Empty Weight",
                        },
                      ]}
                      initialValue={recordTruckData?.empty_weight}
                    >
                      <InputNumber
                        min={1}
                        addonAfter={"Ton"}
                        style={{
                          width: "100%",
                        }}
                        placeholder="Enter Empty Weight"
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={11}
                  >
                    <span className="title_changes">Parking Location</span>
                    <Form.Item
                      name="address"
                      rules={[
                        {
                          required: false,
                          message: "Please enter your address",
                        },
                      ]}
                    >
                      <GooglePlacesAutocomplete
                        className="form-input"
                        // apiKey={process.env.REACT_APP_MAPS_KEY}
                        autocompletionRequest={{
                          componentRestrictions: {
                            country: ["us"],
                          },
                        }}
                        selectProps={{
                          defaultInputValue: `${
                            selectedAddress
                              ? selectedAddress
                              : recordTruckData?.parking_location
                              ? recordTruckData?.parking_location
                              : ""
                          }`,
                          citySearch,
                          placeholder: "Search Address...",
                          onChange: (address) => {
                            this.handleSelect(address);
                            this.getAddress(address);
                          },
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    xxl={{ span: 8 }}
                    xl={{ span: 8 }}
                    lg={{ span: 6 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Overweight Permit</span>
                    <Form.Item
                      name="overWeightPermit"
                      rules={[
                        {
                          required: false,
                          message: "Please Select Required!",
                        },
                      ]}
                      initialValue={recordTruckData?.overWeightPermit}
                    >
                      <Radio.Group
                        defaultValue={recordTruckData?.overWeightPermit}
                        onChange={this.onChangeOverWeight}
                        style={{
                          // marginTop: "5px",
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-around",
                          // flexWrap: "wrap",
                          // maxWidth:"100%",
                          padding: "8px",
                          border: "1px solid #e5e5e5",
                          height: "40px",
                          borderRadius: "5px",
                        }}
                      >
                        <Radio style={{ fontSize: "16px" }} value={"Yes"}>
                          Yes
                        </Radio>
                        <Radio style={{ fontSize: "16px" }} value={"No"}>
                          No
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  {overWeightPermitState === "Yes" && (
                    <>
                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 6 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <span className="title_changes">Date Of Expiry</span>
                        <Form.Item
                          name={"overWeightExpiryDate"}
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Average Load Size",
                            },
                          ]}
                          initialValue={
                            recordTruckData?.overWeightExpiryDate
                              ? moment(recordTruckData?.overWeightExpiryDate)
                              : ""
                          }
                        >
                          <DatePicker
                            placeholder="Please Select A Date"
                            style={{
                              width: "100%",
                              height: "40px",
                              borderRadius: "5px",
                            }}
                            format={"MM/DD/YYYY"}
                            // defaultValue={moment()}
                            placement={"bottomRight"}
                            allowClear
                            bordered={true}
                            // onChange={this.onChangeYear}
                            disabledDate={(current) => {
                              let customDate = moment().format("MM-DD-YYYY");
                              return (
                                current &&
                                current < moment(customDate, "MM-DD-YYYY")
                              );
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 6 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                        // span={24}
                      >
                        <span className="title_changes">Attachment</span>
                        <Form.Item
                          name="docAttachment"
                          rules={[
                            {
                              required: true,
                              message: "Please Attach Your Document",
                            },
                          ]}
                          initialValue={recordTruckData?.docAttachment}
                        >
                          <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            style={{
                              borderRadius: "50%",
                              width: "100%",
                              height: "100%",
                            }}
                            showUploadList={false}
                            customRequest={dummyRequest}
                            onChange={(e) => this.handleUploadPic(e)}
                          >
                            <Spin spinning={imageLoading}>
                              {quoteAttachment ? (
                                <img
                                  src={`${quoteAttachment}`}
                                  alt="avatar"
                                  style={{ width: "100px", height: "100%" }}
                                />
                              ) : (
                                <CameraOutlined className="camera-icon" />
                              )}
                            </Spin>
                          </Upload>
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Row>
              </Form>
            </BaseModal>
          )}

          {addCarrier && (
            <BaseModal
              title={"Add New Carrier"}
              onCancel={() =>
                this.setState({
                  addCarrier: false,
                  selectedPaymentType: "",
                  overWeightPermitState: "",
                  carrierName: "",
                  contactInfo: [],
                })
              }
              loading={this.state.loading}
              formId="addCarrier"
              className="antmodal_grid headermodal"
              width={"65%"}
            >
              <Form
                initialValues={{ remember: true }}
                onFinish={this.onFinishCarrier}
                id="addCarrier"
                style={{ marginBottom: "25px" }}
              >
                <Row gutter={[24, 0]}>
                  <Col
                    xxl={{ span: 6 }}
                    xl={{ span: 8 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Carrier Name</span>
                    <Form.Item
                      name="carrier_name"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Your carrier_name!",
                        },
                      ]}
                    >
                      <Input
                        className="form-input"
                        placeholder="Please Enter Your carrier_name"
                        onChange={(e) =>
                          this.setState({
                            carrierName: e.target.value,
                          })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 6 }}
                    xl={{ span: 8 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Carrier Email</span>
                    <Form.Item
                      name="carrier_email"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Email!",
                        },
                        {
                          type: "email",
                          message: "The Input Is Not Valid Email",
                        },
                      ]}
                    >
                      <Input
                        className="form-input"
                        placeholder="Please Enter Your Email"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 6 }}
                    xl={{ span: 8 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">USDOT</span>
                    <Form.Item
                      name="usdot"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Your usdot!",
                        },
                      ]}
                    >
                      <NumberFormat
                        className="ant-input-number form-input numberictest"
                        format="##########"
                        style={{ width: "100%" }}
                        placeholder="Please Enter Your usdot"
                        onChange={(e) =>
                          this.setState({
                            usdot: e.target.value,
                          })
                        }
                        //defaultValue={leadsData.phone}
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    xxl={{ span: 6 }}
                    xl={{ span: 8 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Truck Type</span>
                    <Form.Item
                      name="truck_type"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Truck Type",
                        },
                      ]}
                      style={{
                        marginBottom: "5px",
                      }}
                      initialValue={
                        recordTruckData?.truck_type
                          ? recordTruckData?.truck_type
                          : "NA"
                      }
                    >
                      <Select
                        allowClear
                        className="select_Height"
                        style={{
                          height: "40px",
                          fontWeight: "300",
                        }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        placeholder="Select Truck Type"
                        onChange={(e) => {
                          this.props.context.trucksData?.filter(
                            (t) =>
                              e === t.value &&
                              this.setState({
                                selectedTruckCode: t.truck_code,
                              })
                          );
                        }}
                        defaultValue={recordTruckData?.truck_type}
                      >
                        {this.props.context.trucksData?.map((t) => (
                          <Option value={t.value} key={t.label}>
                            {t.value}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  {/* <Col
                    xxl={{ span: 6 }}
                    xl={{ span: 8 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Deposit Method</span>
                    <Form.Item
                      name="direct_deposit"
                      // onChange={this.getparking_location}
                      rules={[
                        {
                          required: true,
                          errorMessage: true,
                          message: "Please Enter Your Direct Deposit!",
                        },
                      ]}
                      initialValue={"Direct Deposit"}
                    >
                      <Radio.Group
                        defaultValue={"Direct Deposit"}
                        // onChange={(e) =>
                        //   this.setState({ supplier: e?.target?.value })
                        // }
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "left",
                          // flexWrap: "wrap",
                          // maxWidth:"100%",
                          padding: "8px",
                          border: "1px solid #e5e5e5",
                          height: "40px",
                          borderRadius: "5px",
                        }}
                      >
                        <Radio
                          style={{ fontSize: "16px", whiteSpace: "nowrap" }}
                          value={"Direct Deposit"}
                        >
                          Direct Deposit
                        </Radio>
                        <Radio
                          style={{ fontSize: "16px", whiteSpace: "nowrap" }}
                          value={"Check"}
                        >
                          Check
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col> */}
                  <Col
                    xxl={{ span: 6 }}
                    xl={{ span: 8 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={11}
                  >
                    <span className="title_changes">Office Address</span>
                    <Form.Item
                      name="address"
                      rules={[
                        {
                          required: false,
                          message: "Please enter your address",
                        },
                      ]}
                      initialValue={carrierAddress}
                    >
                      <GooglePlacesAutocomplete
                        className="form-input"
                        // apiKey={process.env.REACT_APP_MAPS_KEY}
                        autocompletionRequest={{
                          componentRestrictions: {
                            country: ["us"],
                          },
                        }}
                        selectProps={{
                          // defaultInputValue: `${
                          //   carrierAddress ? carrierAddress : ""
                          // }`,
                          citySearch,
                          placeholder: "Search Address...",
                          onChange: (address) => {
                            this.handleSelect(address, "carrier");
                            this.setState({
                              carrierAddress: address.label,
                            });
                          },
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 6 }}
                    xl={{ span: 8 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={11}
                  >
                    <span className="title_changes">
                      Insurance Expiration Date
                    </span>
                    <Form.Item
                      name="insurance_expiry"
                      rules={[
                        {
                          required: true,
                          message: "Select Insurance Exipiry Date",
                        },
                      ]}
                    >
                      <DatePicker
                        size="large"
                        format={"MM/DD/YYYY"}
                        // className="ant-input-number numberictest"
                        style={{ width: "100%", borderRadius: "5px" }}
                        placeholder="Please Select A Date"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 6 }}
                    xl={{ span: 8 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={11}
                  >
                    <span className="title_changes">Registered Since</span>
                    <Form.Item
                      name="registeredSince"
                      rules={[
                        {
                          required: true,
                          message: "Select Registered Since",
                        },
                      ]}
                      initialValue={
                        filterData?.registeredSince
                          ? moment(filterData?.registeredSince)
                          : moment()
                      }
                    >
                      <DatePicker
                        size="large"
                        format={"MM/DD/YYYY"}
                        // className="ant-input-number numberictest"
                        style={{ width: "100%", borderRadius: "5px" }}
                        placeholder="Please Select A Date"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 6 }}
                    xl={{ span: 8 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Payment Terms</span>
                    <Form.Item
                      name="payment_terms"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Require!",
                        },
                      ]}
                      initialValue={paymentTermsVal}
                    >
                      <Select
                        allowClear
                        className="select_Height"
                        style={{ height: "40px", fontWeight: "300" }}
                        placeholder="Please Select Payment Type"
                        onChange={(e, option) => {
                          this.setState({
                            paymentTermsVal: e,
                            payment_discount: option.key,
                          });
                        }}
                      >
                        {paymentTerms?.map((e) => (
                          <Option
                            key={e.payment_discount}
                            value={e.payment_terms}
                          >
                            {e.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Row
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px",
                      marginTop: "15px",
                    }}
                  >
                    <span
                      style={{
                        marginBottom: "10px",
                        display: "flex",
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "rgb(88,99,112)",
                        textTransform: "capitalize",
                      }}
                    >
                      CONTACT DETAILS:
                    </span>
                    <span>
                      <Button
                        icon={<PlusOutlined />}
                        style={{
                          width: "150px",
                          fontSize: "14px",
                        }}
                        onClick={() =>
                          this.setState({
                            isContactVisible: true,
                            recordContactData: "",
                          })
                        }
                        type="primary"
                      >
                        Add Contact
                      </Button>
                    </span>
                  </Row>
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    style={{ overflowX: "auto" }}
                    className="twistedtable"
                  >
                    <table style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th>CONTACT NAME</th>
                          <th>CONTACT ROLE</th>
                          <th>ACTIONS</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {contactInfo?.map((data, index) => (
                          <tr
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => this.editContactInfo(data)}
                          >
                            <td>{data?.firstName + " " + data?.lastName}</td>
                            <td>{data?.contactRole}</td>
                            <td>
                              <Button
                                style={{ marginLeft: "10px", width: "80px" }}
                                onClick={() => this.deleteContactInfo(data?.id)}
                                type="primary"
                              >
                                Delete
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </Form>
            </BaseModal>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) =>
  // console.log(state)
  ({
    totalOwnerOperatorsData: state.reducer?.ownerOperatorsData,
    loading: state?.reducer?.trucksListResult?.loading,
    totalTrucksListResult: state.reducer?.trucksListResult,
    trucksListInfo: state.reducer?.trucksListInfo,
    termsData: state.reducer?.dropDownOptions?.totalData?.filter(
      (t) => t.typeofDropDown === "carrierTerms"
    ),
    trucksTypes: state.reducer?.dropDownOptions?.totalData?.filter(
      (t) => t.typeofDropDown === "trucksType"
    ),
    dropDownOptions: state.reducer?.dropDownOptions,
  });

const mapDispatchToProps = (dispatch) => ({
  actionFetchOwnerOperators: () => {
    dispatch(getOwnerOperators());
  },
  actionFetchTrucksList: () => {
    dispatch(getTrucksList());
  },
  actionUpdateTrucksList: (id, data) => {
    dispatch(updateTrucksList(id, data));
  },
  actionAddTrucksList: (data) => {
    dispatch(addTrucksList(data));
  },
  actiongetDropDownOptions: () => {
    dispatch(getDropDownOptions());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAppContext(withRouter(TwistedNailOwnerOperator)));
