import React, { Component } from "react";
import { FaMap } from "react-icons/fa";
import { IoFilterSharp } from "react-icons/io5";
import { MdArchive, MdUnarchive } from "react-icons/md";
import {
  Button,
  Col,
  Row,
  Popover,
  notification,
  Popconfirm,
  message,
  Form,
  InputNumber,
  Select,
  DatePicker,
  Input,
} from "antd";
import moment from "moment";
import { connect } from "react-redux";
import { list } from "../../api/Apis";
import BaseTable from "../../Common/BaseTable";
import BaseModal from "../../Common/BaseModal";
import ErrorView from "../../Common/ErrorView";
import Loading from "../../Common/Loading";
import {
  getDropDownOptions,
  getLeads,
  updateLead,
  updateInvoices,
  updateRecurringData,
} from "../../Redux/Actions/actions";
import { EllipsisOutlined, UnorderedListOutlined } from "@ant-design/icons";
import MapComponent from "../Maps/MapComponent";
import SearchFunction from "../../Common/SearchFunction";
import Filter from "../../Common/Filter";
import MapDierection from "../Maps/MapDierection";

const { Option } = Select;
const { TextArea } = Input;
class JobDatabase extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      filterData: [],
      jobDatabaseData: [],
      trucksTypesData: [],
      totalMaterials: [],
      companyData: [],

      dispatchTypeSelect: "",
      dispatchDate: "",

      truckTypeCounts: {},
      leadDetails: {},

      selected: true,
      dispatchModal: false,
      materialLoading: false,
      dispatchTypeModal: false,
      visible: false,
      truckingPriceType: "Tons",
      filterPropTruckingData: {
        company: undefined,
        jobType: undefined,
        dispatchCheckData: undefined,
      },
      archiveData: true,
      hourlyJob: false,
      waiveContract: "",
    };
  }

  componentDidMount() {
    const { requestDispatch, actiongetDropDownOptions, actionFetchLeads } =
      this.props;
    let filterStorageData = JSON.parse(
      localStorage.getItem("filterStorageData")
    );
    if (filterStorageData) {
      this.setState({
        jobDatabaseData: filterStorageData.result,
        filterData: filterStorageData.result,
        filterPropTruckingData: filterStorageData.filter,
      });
    } else {
      actionFetchLeads();
    }
    actiongetDropDownOptions();
    document.title = `Twisted Nail - ${
      requestDispatch ? "Request Dispatch" : "Job Database"
    }`;
  }

  componentDidUpdate(prevProps) {
    const {
      dropDownOptions,
      leadUpdateInfo,
      actionFetchLeads,
      result,

      materialResult,
      requestDispatch,
    } = this.props;

    if (
      prevProps?.dropDownOptions?.success === false &&
      dropDownOptions?.success === true
    ) {
      const { filterData } = this.state;
      this.setState({
        trucksTypesData: this.props?.trucksTypesData[0]?.trucksData,
      });
      this.trucksFindCount(
        this.props?.trucksTypesData[0]?.trucksData,
        filterData
      );
    }

    if (
      prevProps?.leadUpdateInfo?.success === false &&
      leadUpdateInfo?.success === true
    ) {
      actionFetchLeads();
    }

    if (prevProps?.result?.success === false && result?.success === true) {
      let getLeadsData = result?.leads?.filter((f) => !Array.isArray(f));
      let type = [];
      getLeadsData?.map(
        (e) =>
          e?.company !== undefined &&
          e?.leadType &&
          e?.leadType[0]?.id === "1" &&
          e?.stageId === "Dispatch Approved" &&
          type.push({
            company: e?.company,
            companyId: e?.companyId,
            jobNumberId: e?.jobNumberId,
          })
      );

      let leadsInfo = getLeadsData?.filter(
        (e) =>
          e?.leadType &&
          e?.leadType[0]?.id === "1" &&
          e?.stageId === "Dispatch Approved"
      );

      let filterInfo = requestDispatch
        ? leadsInfo?.filter((e) => e?.showArchive !== false)
        : leadsInfo;

      filterInfo?.forEach((element) => {
        element?.material?.forEach((mt) => {
          if (element.jobType !== "Trucking Job") {
            mt["projectRevenue"] =
              mt?.quantity *
              (parseFloat(mt.truckingPrice) + parseFloat(mt.totalPrice));
            mt["COGS"] =
              mt?.quantity *
              (parseFloat(mt.truckingCost) + parseFloat(mt.materialCost));
            mt["grossProfit"] = mt["projectRevenue"] - mt["COGS"];
            mt["grossProfitPercentage"] =
              (mt["grossProfit"] / (mt["projectRevenue"] + mt["COGS"])) * 100;
          } else {
            mt["projectRevenue"] = parseFloat(mt.truckingPrice);
            mt["COGS"] = parseFloat(mt.truckingCost);
            mt["grossProfit"] = mt["projectRevenue"] - mt["COGS"];
            mt["grossProfitPercentage"] =
              (mt["grossProfit"] / (mt["projectRevenue"] + mt["COGS"])) * 100;
          }
        });
      });

      this.trucksFindCount(
        this.props?.trucksTypesData[0]?.trucksData,
        filterInfo
      );
      let companyData = filterInfo.filter(
        (val, index, self) =>
          self.findIndex((e) => e.companyId === val.companyId) === index
      );

      this.setState({
        jobDatabaseData: filterInfo,
        filterData: filterInfo,
        companyData,
      });
    }

    if (
      prevProps?.materialResult?.success === false &&
      materialResult?.success === true
    ) {
      this.setState(
        {
          totalMaterials: materialResult.updatedData?.materialData,
          dispatchTypeModal: false,
          materialLoading: false,
          dispatchTypeSelect: "",
          previousDispatchData: materialResult.updatedData.previousDispatchData,
        },
        () => actionFetchLeads()
      );
      this.formRef.current.setFieldsValue({
        dispatchTypeSelect: "",
        truckType: [],
        trucksRequested: "",
        dispatchNotes: "",
      });
      message.success("Material Dispatched Successfully!");
    }
  }

  trucksFindCount = (trucksTypes, filterInfo, filterTrucks) => {
    let truckTypeCounts = {};

    trucksTypes?.forEach((truckType) => {
      truckTypeCounts[truckType.value] = 0;
    });

    filterInfo.forEach((dataItem) => {
      dataItem.material?.forEach((material) => {
        const truckTypes = material.truckType;

        if (truckTypes) {
          truckTypes?.forEach((truckType) => {
            const matchingType = trucksTypes?.find(
              (type) => type.value === truckType
            );

            if (matchingType) {
              truckTypeCounts[matchingType.value]++;
            }
          });
        }
      });
    });

    if (filterTrucks?.length > 0) {
      let data = trucksTypes?.filter((e) => filterTrucks.includes(e.value));
      let newTruckTypeCounts = {};
      Object.keys(truckTypeCounts)?.forEach((e) => {
        data?.forEach((d) => {
          newTruckTypeCounts[e] = d.value === e ? truckTypeCounts[e] : 0;
        });
      });
      truckTypeCounts = newTruckTypeCounts;
    }

    this.setState({
      truckTypeCounts,
    });
  };

  updateLead = (id, data) => {
    this.props?.actionUpdateJob(id, data);
  };

  onCloseJob = (data) => {
    let submitData = {
      entityData: {
        stageId: "Close Job",
      },
    };
    list("invoices", {
      jobId: data.id,
    }).then((res) => {
      if (res.success) {
        if (res.data?.length > 0) {
          res.data?.forEach((e) => {
            let invoiceData = {
              entityData: {
                stageId: "Close Job",
                closedDate: moment().format("l"),
              },
            };
            this.props.actionUpdateInvoice(e.id, invoiceData);
          });
        }
      }
    });
    this.updateLead(data.id, submitData);
  };

  archiveEdit = (record) => {
    let data = {
      entityData: {
        showArchive:
          record.showArchive !== undefined ? !record.showArchive : false,
      },
    };
    this.updateLead(record?.id, data);
    message.success(
      `#${record.jobNumber} ${
        data["entityData"]["showArchive"] ? "retrived" : "archived"
      } successfully`
    );
  };

  countCustomers = (data) => {
    return data.filter(
      (value, index, self) =>
        self.findIndex((e) => e.companyId === value.companyId) === index
    );
  };

  findJobType = (type, data) => {
    return data.filter((value) => value.jobType === type);
  };

  onClickRequestDispatch = (data) => {
    const { trucksTypesData } = this.state;
    let trucksInfo = [];
    trucksTypesData?.map((t) => {
      data?.truckType?.map((d) => {
        if (d === t?.truck_type) {
          trucksInfo?.push(t);
        }
        return true;
      });
      return true;
    });
    this.setState({
      dispatchModal: true,
      leadDetails: data,
      previousDispatchData: data?.dispatchData,
      totalMaterials: data?.material,
      trucksInfo,
    });
  };

  onCancelDispatchModal = () => {
    const { leadDetails } = this.state;
    leadDetails?.material?.map((t) => delete t?.dispatchData);
    leadDetails?.material?.map((t) => delete t?.dispatchType);
    leadDetails?.material?.map((t) => delete t?.assignTrucks);
    leadDetails?.material?.map((t) => delete t?.assignTrucksInfo);

    this.setState({
      dispatchModal: false,
      showSpecificDate: false,
      iscalenderVisible: false,
      selectedMaterial: "",
      totalMaterials: [],
    });
  };

  onClickSelectDispatch = (data) => {
    let hourlyJob =
      data?.hourlyCharge &&
      (data.truckingCostType === "Hour" || data.truckingPriceType === "Hour");
    this.setState({
      dispatchTypeModal: true,
      selectedMaterial: data,
      dispatch: "",
      dispatchTypeSelect: "",
      dispatchDate: "",
      hourlyJob,
      waiveContract:''
    });
    this.formRef.current.setFieldsValue({
      dispatchTypeSelect: "",
      truckType: undefined,
      trucksRequested: "",
      dispatchNotes: "",
      dispatch: "",
    });
  };

  onFinishRecurring = (values) => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const {
      selectedMaterial,
      leadDetails,
      truckingPriceType,
      previousDispatchData,
    } = this.state;
    if (
      values["dispatchTypeSelect"] === "Copy Previous Dispatch Request" &&
      previousDispatchData === undefined
    ) {
      message.error("No Previous Dispatch Data!");
      return true;
    } else {
      this.setState({
        materialLoading: true,
      });
      values["dispatchDate"] = moment(values["specificDate"]).format("l");
      values["trucksRequestedType"] = truckingPriceType;
      values["jobId"] = leadDetails?.id;
      values["requestDispatch"] = true;
      values["waiveContract"] =
        values["waiveContract"] === "Yes" ? true : false;
      let data = {
        entityData: values,
      };

      data["entityData"]["material"] = [selectedMaterial];
      data["entityData"]["dispatcherDetails"] = userDetails;
      this.props?.actionFetchMaterialData(data);
      this.setState({
        dispatchModal: false,
      });
    }
  };

  onFinishDispatch = (values) => {
    const { leadDetails, totalMaterials } = this.state;

    let dispatchData = leadDetails?.material?.filter((m) => m?.dispatchData);

    let simpleDispatchData = leadDetails?.material?.filter(
      (f) => f?.dispatchType === "Simple Dispatch"
    );
    if (
      dispatchData?.length === leadDetails?.material?.length ||
      simpleDispatchData?.length === leadDetails?.material?.length
    ) {
      this.setState({ dispatchModal: false });
      let data = {
        entityData: {
          salesPersonId: leadDetails?.salesPersonId,
          contactperson: "",
          contactId: "",
          jobName: leadDetails?.jobName,
          jobType: leadDetails?.jobType,
          dropoff: leadDetails?.dropoff,
          dropOffLatLng: {
            lat: leadDetails?.dropOffLatLng?.lat,
            lng: leadDetails?.dropOffLatLng?.lng,
          },
          multipleContacts: leadDetails?.multipleContacts,
          truckType: leadDetails?.truckType,
          notes: leadDetails?.notes,
          dateOfHaul: leadDetails?.dateOfHaul,
          durationOfJob: leadDetails?.durationOfJob,
          companyName: leadDetails?.company,
          companyAddress: leadDetails?.companyAddress,
          stageId: leadDetails?.stageId,
          terms: leadDetails?.accountTerms,
          accountsPayableContact: leadDetails?.accountsPayableContact,
          taxExempt: leadDetails?.taxExempt,
          requestDispatch: true,
          dispatchNotes: values["dispatchNotes"],
          jobNumber: leadDetails?.jobNumber,
          material: totalMaterials,
        },
      };
      this.updateLead(leadDetails?.id, data);
    } else {
      if (
        dispatchData?.length !== leadDetails?.material?.length &&
        simpleDispatchData?.length === 0
      ) {
        message.error("Please Select Dispatch Type For Materials");
      } else if (
        dispatchData?.length !== leadDetails?.material?.length &&
        simpleDispatchData?.length > 0
      ) {
        message.error("Please Select Same Dispatch Type For All Materials");
      }
    }
  };

  searchResult = (searchValue) => {
    const { jobDatabaseData } = this.state;
    let searchFilter = [];
    if (typeof searchValue === "number") {
      searchFilter = jobDatabaseData.filter(
        (e) =>
          parseInt(e["phone"]?.replace(/[^+0-9]/g, "")) === searchValue ||
          parseInt(e["jobNumber"]) === searchValue
      );
    } else {
      searchValue = searchValue?.toLowerCase();
      searchFilter = jobDatabaseData.filter(
        (e) =>
          e["company"]?.toLowerCase().indexOf(searchValue) > -1 ||
          e["companyName"]?.toLowerCase().indexOf(searchValue) > -1
      );
    }
    this.setState({
      filterData: searchFilter,
    });
    this.showMap(searchFilter);
  };

  onResetData = () => {
    this?.props?.actionFetchLeads();
  };

  closeFilter = () => {
    this.setState({
      visible: false,
    });
  };

  filterResultData = (data) => {
    const { jobDatabaseData } = this.state;
    if (data["jobType"]?.length === 0) {
      delete data["jobType"];
    }
    // filterTrucksInfo = jobDatabaseData?.filter((job) => {
    //   const trucksData = job?.material?.flatMap((e) => e.truckType);
    //   return trucksData.some((t) => data.dispatchCheckData.includes(t));
    // });
    let resultData = this.findAllFilterData(jobDatabaseData, data);
    this.setState({
      filterData: resultData,
      filterPropTruckingData: data,
    });

    // this.trucksFindCount(trucksTypesData, resultData, data.dispatchCheckData);

    let filterInfo = {};
    const { dispatchCheckData, ...employeeRest } = data;
    filterInfo = employeeRest;
    Object.keys(filterInfo)?.forEach((key) => {
      if (filterInfo[key] === undefined) {
        delete filterInfo[key];
      }
    });
    localStorage.setItem(
      "filterStorageData",
      JSON.stringify({
        result: resultData,
        filter: data,
        filterShowData: filterInfo,
      })
    );
    this.closeFilter();
    this.showMap(resultData);
  };

  findAllFilterData = (data, filterCriteria) => {
    return data.filter((item) => {
      if (
        (!filterCriteria.company ||
          filterCriteria.company === item.companyId) &&
        (!filterCriteria.jobType ||
          filterCriteria.jobType.includes(item.jobType))
      ) {
        return true;
      }
      return false;
    });
  };

  onClearFilter = () => {
    localStorage.removeItem("filterStorageData", JSON.stringify({}));
    this.setState(
      {
        filterVisible: false,
        visible: false,
        filterPropTruckingData: {
          company: undefined,
          jobType: undefined,
        },
      },
      () => this?.props?.actionFetchLeads()
    );
  };

  showMap = (filterData) => {
    const { trucksTypesData } = this.state;
    return (
      <MapComponent
        filteredMapData={filterData}
        requestDispatch
        trucksTypesData={trucksTypesData}
      />
    );
  };

  showSingleMap = (modalData) => {
    return (
      <MapDierection
        quarryData={modalData}
        mapsData={modalData}
        singleValues
        handleCancel={this.submitMap}
        onCancel={this.onCancel}
        mapStatus={this.state.mapStatus}
        updateRecord={this.updateRecord}
        requestDispatch={this.state.dispatchModal}
      />
    );
  };

  handleTableChage = (props, dispatchReq) => {
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    localStorage.setItem(
      "tableSizeRange",
      JSON.stringify({
        ...tableSizeRange,
        [dispatchReq ? "dispatchTool" : "jobDatabase"]: props,
      })
    );
  };

  truckCodes = (trucksData) => {
    const { trucksTypesData } = this.state;
    let truckCodes = trucksData.map((t) => {
      return trucksTypesData
        ?.filter((e) => t?.includes(e.value))
        .map((e) => e.truck_code);
    });
    return truckCodes;
  };

  render() {
    const filterInfo =
      JSON.parse(localStorage.getItem("filterStorageData"))?.filterShowData ||
      {};
    const {
      archiveData,
      companyData,
      visible,
      selected,
      materialLoading,
      selectedMaterial,
      dispatchTypeModal,
      filterData,
      trucksTypesData,
      // truckTypeCounts,
      leadDetails,
      dispatchModal,
      totalMaterials,
      filterPropTruckingData,
      hourlyJob,
      waiveContract,
    } = this.state;
    const { errorLoading, loading, requestDispatch } = this.props;

    const selectAfterTruckPrice = (
      <Select
        defaultValue={this.state?.truckingPriceType}
        className="select-after"
        onChange={(e) => {
          this.setState({ truckingPriceType: e });
        }}
      >
        <Option value="Tons">Tons</Option>
        <Option value="Loads">Loads</Option>
        <Option value="Trucks">Trucks</Option>
      </Select>
    );

    const openNotification = (record) => {
      const btn = (
        <div>
          <Button
            style={{
              borderRadius: "5px",
              height: "30px",
              marginRight: "10px",
            }}
            size="small"
            onClick={() => {
              notification.destroy();
              this.onCloseJob(record);
            }}
          >
            Confirm
          </Button>
          <Button
            style={{
              borderRadius: "5px",
              height: "30px",
            }}
            size="small"
            onClick={() => notification.destroy()}
          >
            Cancel
          </Button>
        </div>
      );
      notification.warning({
        message: "Are you sure you want to close the job?",
        btn,
        placement: "topRight",
      });
    };

    const dispatchColumns = [
      {
        title: (
          <span style={{ color: "#586370", fontWeight: "600" }}>Vendor</span>
        ),
        dataIndex: "vendor",
        key: "vendor",
        sorter: false,
        show: true,
        width: "15%",
        className: "col_style_quarry",
        onCell: (record) => ({
          onClick: () => this.onClickSelectDispatch(record),
        }),
        render: (vendor) => {
          return <span className="col_style_quarry">{vendor}</span>;
        },
      },
      {
        title: (
          <span style={{ color: "#586370", fontWeight: "600" }}>
            Material To Haul
          </span>
        ),
        dataIndex: "categoryKey",
        key: "categoryKey",
        show: true,
        sorter: false,
        onCell: (record) => ({
          onClick: () => this.onClickSelectDispatch(record),
        }),
        // width: "120px",
        className: "col_style_quarry",
        render: (categoryKey, record) => {
          return (
            <span className="col_style_quarry">{`${categoryKey} - ${
              record?.label?.split(" - ")[1]
            } - ${record?.quantity} ${record?.quantityType}`}</span>
          );
        },
      },
      {
        title: (
          <span style={{ color: "#586370", fontWeight: "600" }}>
            Material Cost
          </span>
        ),
        dataIndex: "materialCost",
        key: "materialCost",
        sorter: false,
        className: "col_style_quarry",
        show: true,
        // width: "75px",
        onCell: (record) => ({
          onClick: () => this.onClickSelectDispatch(record),
        }),
        render: (materialCost, record) => {
          return (
            <span className="col_style_quarry">
              {materialCost &&
                `$${parseFloat(materialCost)?.toLocaleString("us-EN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })} / ${record?.materialCostType}`}
            </span>
          );
        },
      },
      {
        title: (
          <span style={{ color: "#586370", fontWeight: "600" }}>
            Material Price
          </span>
        ),
        dataIndex: "totalPrice",
        key: "totalPrice",
        show: true,
        sorter: false,
        className: "col_style_quarry",
        // width: "75px",
        onCell: (record) => ({
          onClick: () => this.onClickSelectDispatch(record),
        }),
        render: (totalPrice, record) => {
          return (
            <span className="col_style_quarry">
              {totalPrice &&
                `$${parseFloat(totalPrice)?.toLocaleString("us-EN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })} / ${record?.totalPriceType}`}
            </span>
          );
        },
      },
      {
        title: (
          <span style={{ color: "#586370", fontWeight: "600" }}>
            Trucking Cost
          </span>
        ),
        dataIndex: "truckingCost",
        key: "truckingCost",
        show: true,
        sorter: false,
        className: "col_style_quarry",
        // width: "75px",
        onCell: (record) => ({
          onClick: () => this.onClickSelectDispatch(record),
        }),
        render: (truckingCost, record) => {
          return (
            <span className="col_style_quarry">
              {truckingCost &&
                `$${parseFloat(truckingCost)?.toLocaleString("us-EN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })} / ${record?.truckingCostType}`}
            </span>
          );
        },
      },
      {
        title: (
          <span style={{ color: "#586370", fontWeight: "600" }}>
            Trucking Price
          </span>
        ),
        dataIndex: "truckingPrice",
        key: "truckingPrice",
        show: true,
        sorter: false,
        className: "col_style_quarry",
        // width: "75px",
        onCell: (record) => ({
          onClick: () => this.onClickSelectDispatch(record),
        }),
        render: (truckingPrice, record) => {
          return (
            <span className="col_style_quarry">
              {truckingPrice &&
                `$${parseFloat(truckingPrice)?.toLocaleString("us-EN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })} / ${record?.truckingPriceType}`}
            </span>
          );
        },
      },
      {
        title: (
          <span style={{ color: "#586370", fontWeight: "600" }}>
            Landed Price
          </span>
        ),
        dataIndex: "landedPrice",
        key: "landedPrice",
        show: true,
        sorter: false,
        className: "col_style_quarry",
        // width: "75px",
        onCell: (record) => ({
          onClick: () => this.onClickSelectDispatch(record),
        }),
        render: (landedPrice, record) => {
          return (
            <span className="col_style_quarry">
              {landedPrice && landedPrice === "N/A"
                ? "N/A"
                : `$${parseFloat(landedPrice)?.toLocaleString("us-EN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`}
            </span>
          );
        },
      },
      {
        title: (
          <span style={{ color: "#586370", fontWeight: "600" }}>
            Minimum Hours
          </span>
        ),
        dataIndex: "hourlyCharge",
        key: "hourlyCharge",
        sorter: false,
        className: "col_style_quarry",
        width: "75px",
        show:
          totalMaterials?.filter(
            (e) =>
              e.truckingCostType === "Hour" || e.truckingPriceType === "Hour"
          )?.length > 0,
        onCell: (record) => ({
          onClick: () => this.onClickSelectDispatch(record),
        }),
        // fixed: "right",
        render: (hourlyCharge) => {
          return (
            <span className="col_style_quarry">
              {hourlyCharge && `${hourlyCharge} Hours`}
            </span>
          );
        },
      },
      {
        title: (
          <span style={{ color: "#586370", fontWeight: "600" }}>
            Dispatch Type
          </span>
        ),
        dataIndex: "",
        show: true,
        key: "",
        sorter: false,
        className: "col_style_quarry",
        // width: "10%",
        render: (record) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                // disabled={record?.dispatchData}
                onClick={() => this.onClickSelectDispatch(record)}
                className="lead_ButtonStatus"
                style={{
                  color:
                    record?.dispatchData ||
                    record?.dispatchType === "Simple Dispatch"
                      ? "green"
                      : "#586370",
                  border:
                    record?.dispatchData ||
                    record?.dispatchType === "Simple Dispatch"
                      ? "1px solid green"
                      : "1px solid #586370",
                }}
              >
                <span
                  className="col_style_quarry"
                  style={{ width: "fit-content" }}
                >
                  Select Dispatch Type
                </span>
              </Button>
            </div>
          );
        },
      },
    ]?.filter((e) => e.show);

    const dispatchColumnsTrucking = [
      {
        title: (
          <span style={{ color: "#586370", fontWeight: "600" }}>
            PickUp Location
          </span>
        ),
        dataIndex: "pickUpAddress",
        key: "pickUpAddress",
        sorter: false,
        className: "col_style_quarry",
        show: true,
        width: "35%",
        onCell: (record) => ({
          onClick: () => this.onClickSelectDispatch(record),
        }),
        render: (pickUpAddress) => {
          return <span className="col_style_quarry">{pickUpAddress}</span>;
        },
      },
      {
        title: (
          <span style={{ color: "#586370", fontWeight: "600" }}>
            Material Name
          </span>
        ),
        dataIndex: "materialName",
        key: "materialName",
        sorter: false,
        className: "col_style_quarry",
        show: true,
        width: "75px",
        onCell: (record) => ({
          onClick: () => this.onClickSelectDispatch(record),
        }),
        render: (materialName) => {
          return <span className="col_style_quarry">{materialName}</span>;
        },
      },
      {
        title: (
          <span style={{ color: "#586370", fontWeight: "600" }}>
            Trucking Cost
          </span>
        ),
        dataIndex: "truckingCost",
        key: "truckingCost",
        sorter: false,
        className: "col_style_quarry",
        show: true,
        width: "75px",
        onCell: (record) => ({
          onClick: () => this.onClickSelectDispatch(record),
        }),
        render: (truckingCost, record) => {
          return (
            <span className="col_style_quarry">
              {truckingCost &&
                `$${parseFloat(truckingCost)?.toFixed(2)} / ${
                  record?.truckingCostType
                }`}
            </span>
          );
        },
      },
      {
        title: (
          <span style={{ color: "#586370", fontWeight: "600" }}>
            Trucking Price
          </span>
        ),
        dataIndex: "truckingPrice",
        key: "truckingPrice",
        sorter: false,
        className: "col_style_quarry",
        show: true,
        width: "75px",
        onCell: (record) => ({
          onClick: () => this.onClickSelectDispatch(record),
        }),
        render: (truckingPrice, record) => {
          return (
            <span className="col_style_quarry">
              {truckingPrice &&
                `$${parseFloat(truckingPrice)?.toFixed(2)} / ${
                  record?.truckingPriceType
                }`}
            </span>
          );
        },
      },
      {
        title: (
          <span style={{ color: "#586370", fontWeight: "600" }}>
            Minimum Hours
          </span>
        ),
        dataIndex: "hourlyCharge",
        key: "hourlyCharge",
        sorter: false,
        className: "col_style_quarry",
        show:
          totalMaterials?.filter(
            (e) =>
              e.truckingCostType === "Hour" || e.truckingPriceType === "Hour"
          )?.length > 0,
        onCell: (record) => ({
          onClick: () => this.onClickSelectDispatch(record),
        }),
        // fixed: "right",.
        width: "75px",
        render: (hourlyCharge) => {
          return (
            <span className="col_style_quarry">
              {hourlyCharge && `${hourlyCharge} Hours`}
            </span>
          );
        },
      },
      {
        title: (
          <span style={{ color: "#586370", fontWeight: "600" }}>
            Dispatch Type
          </span>
        ),
        dataIndex: "",
        key: "",
        sorter: false,
        className: "col_style_quarry",
        width: "10%",
        show: true,
        render: (record) => {
          return (
            <Button
              // disabled={record?.dispatchData}
              onClick={() => this.onClickSelectDispatch(record)}
              className="lead_ButtonStatus"
              style={{
                color:
                  record?.dispatchData ||
                  record?.dispatchType === "Simple Dispatch"
                    ? "green"
                    : "#586370",
                border:
                  record?.dispatchData ||
                  record?.dispatchType === "Simple Dispatch"
                    ? "1px solid green"
                    : "1px solid #586370",
              }}
            >
              <span
                className="col_style_quarry"
                style={{ width: "fit-content" }}
              >
                Select Dispatch Type
              </span>
            </Button>
          );
        },
      },
    ]?.filter((e) => e?.show);

    const customerColumns = [
      {
        title: "JOB#",
        dataIndex: "jobNumber",
        key: "jobNumber",
        sorter: (a, b) => (a.jobNumber > b.jobNumber ? 1 : -1),
        className: "col_styling",
        show: true,
        width: 70,
        fixed: "left",
        onCell: (record) => ({
          onClick: () => {
            requestDispatch
              ? this.onClickRequestDispatch(record)
              : this.props.history.push(`/app/jobs/job-profile/${record.id}`);
          },
        }),
        render: (jobNumber) => {
          return (
            <>
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="table-font-mobile"
              >
                {jobNumber}
              </span>
            </>
          );
        },
      },
      {
        title: "JOB NAME",
        dataIndex: "",
        key: "",
        sorter: (a, b) => (a.jobNumber > b.jobNumber ? 1 : -1),
        show: true,
        className: "col_styling",
        width: 120,
        fixed: "left",
        onCell: (record) => ({
          onClick: () => {
            requestDispatch
              ? this.onClickRequestDispatch(record)
              : this.props.history.push(`/app/jobs/job-profile/${record.id}`);
          },
        }),
        render: (record) => {
          return (
            <>
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="table-font-mobile"
              >
                {record?.jobName ? (
                  record?.jobName?.length > 10 ? (
                    <Popover
                      placement="left"
                      content={<Row>{record?.jobName}</Row>}
                    >
                      {`${record?.jobName.slice(0, 10)}...`}
                    </Popover>
                  ) : (
                    record?.jobName
                  )
                ) : (
                  record?.jobNumber
                )}
              </span>
            </>
          );
        },
      },
      {
        title: "CUSTOMER",
        dataIndex: "companyName",
        key: "companyName",
        sorter: (a, b) => (a.companyName > b.companyName ? 1 : -1),
        show: true,
        width: 150,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => {
            requestDispatch
              ? this.onClickRequestDispatch(record)
              : this.props.history.push(
                  `/app/customer-account-profile/${record.companyId}`
                );
          },
        }),
        render: (companyName) => {
          return (
            <>
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="table-font-mobile"
              >
                {companyName?.length > 17 ? (
                  <Popover placement="left" content={<Row>{companyName}</Row>}>
                    {`${companyName.slice(0, 17)}...`}
                  </Popover>
                ) : (
                  companyName
                )}
              </span>
            </>
          );
        },
      },
      {
        title: "JOB TYPE",
        dataIndex: "jobType",
        key: "jobType",
        sorter: (a, b) => (a.jobType > b.jobType ? 1 : -1),
        className: "col_styling",
        show: true,
        width: 150,
        onCell: (record) => ({
          onClick: () => {
            requestDispatch
              ? this.onClickRequestDispatch(record)
              : this.props.history.push(`/app/jobs/job-profile/${record.id}`);
          },
        }),
        render: (jobType, record) => {
          return (
            <>
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="table-font-mobile"
              >
                {jobType?.replace("Job", "")}
              </span>
            </>
          );
        },
      },
      {
        title: "Equipment",
        dataIndex: "material",
        key: "material",
        sorter: false,
        className: "col_styling",
        width: 250,
        show: true,
        onCell: (record) => ({
          onClick: () => {
            requestDispatch
              ? this.onClickRequestDispatch(record)
              : this.props.history.push(`/app/jobs/job-profile/${record.id}`);
          },
        }),
        render: (material) => {
          const trucksData = material?.map((e) => e.truckType);
          let truckCodes = trucksData.map((t) => {
            return trucksTypesData
              ?.filter((e) => t?.includes(e.value))
              .map((e) => e.truck_code);
          });
          let mergedArray = [].concat(...truckCodes);
          let uniqueArray = [...new Set(mergedArray)];
          return (
            <>
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="table-font-mobile"
              >
                {uniqueArray?.length > 6 ? (
                  <Popover
                    placement="left"
                    content={<Row>{uniqueArray.slice(6)?.join(", ")}</Row>}
                  >
                    {`${uniqueArray.slice(0, 6)?.join(", ")}...`}
                  </Popover>
                ) : (
                  uniqueArray?.join(", ")
                )}
              </span>
            </>
          );
        },
      },
      {
        title: "Material",
        dataIndex: "material",
        key: "Material",
        sorter: (a, b) => (a.jobType > b.jobType ? 1 : -1),
        className: "col_styling",
        show: true,
        width: 150,
        onCell: (record) => ({
          onClick: () => {
            requestDispatch
              ? this.onClickRequestDispatch(record)
              : this.props.history.push(`/app/jobs/job-profile/${record.id}`);
          },
        }),
        render: (material, record) => {
          return (
            <>
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="table-font-mobile"
              >
                {record.jobType === "Trucking Job" ? (
                  <div>{record?.material[0]?.materialName}</div>
                ) : (
                  <>
                    {material
                      ?.map((e) => e?.category + " - " + e?.categoryKey)
                      ?.join(", ")}
                  </>
                )}
              </span>
            </>
          );
        },
      },
      {
        title: "CONTACT",
        dataIndex: "multipleContacts",
        key: "multipleContacts",
        sorter: false,
        className: "col_styling",
        width: 140,
        show: requestDispatch,
        onCell: (record) => ({
          onClick: () => {
            this.onClickRequestDispatch(record);
          },
        }),
        render: (multipleContacts, record) => {
          return (
            <>
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="table-font-mobile"
              >
                {multipleContacts?.length > 0 &&
                  multipleContacts[0]?.firstName +
                    " " +
                    multipleContacts[0]?.lastName}
              </span>
            </>
          );
        },
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        sorter: false,
        show: requestDispatch,
        width: 120,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => {
            this.onClickRequestDispatch(record);
          },
        }),
        render: (phone, record) => {
          return (
            <>
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="table-font-mobile"
              >
                {record?.multipleContacts?.length > 0 &&
                  record?.multipleContacts[0]?.phone}
              </span>
            </>
          );
        },
      },
      {
        title: "Job Start",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: (a, b) => (a.createdAt > b.createdAt ? 1 : -1),
        show: requestDispatch,
        className: "col_styling",
        width: 100,
        onCell: (record) => ({
          onClick: () => {
            requestDispatch
              ? this.onClickRequestDispatch(record)
              : this.props.history.push(`/app/jobs/job-profile/${record.id}`);
          },
        }),
        render: (createdAt, record) => {
          return (
            <>
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="table-font-mobile"
              >
                {moment(createdAt).format("MM/DD/YYYY")}
              </span>
            </>
          );
        },
      },
      {
        title: "Material",
        dataIndex: "material",
        key: "material",
        sorter: false,
        show: !requestDispatch,
        className: "col_styling",
        width: 200,
        onCell: (record) => ({
          onClick: () => {
            this.props.history.push(`/app/jobs/job-profile/${record.id}`);
          },
        }),
        render: (material, record) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                color: "#474747",
                fontSize: "16px",
                fontFamily: "sans-serif",
              }}
              className="table-font-mobile"
            >
              {record?.jobType === "Trucking Job" ? (
                <span
                  style={{
                    color: "#8d9ca6",
                    fontSize: "15px",
                  }}
                >
                  N/A
                </span>
              ) : material?.length > 1 ? (
                <Popover
                  placement="left"
                  content={
                    <Row className="popovergrid" style={{ maxWidth: "100%" }}>
                      <Col span={24}>
                        <div
                          style={{ overflowX: "auto", width: "100%" }}
                          className="twistedtable"
                        >
                          <table style={{ width: "100%" }}>
                            <thead>
                              <tr>
                                {record?.jobType !== "Trucking Job" && (
                                  <>
                                    <th>MATERIAL CATEGORY</th>
                                    <th>MATERIAL TYPE</th>
                                  </>
                                )}
                                <th>SPECIFIC MATERIAL </th>
                              </tr>
                            </thead>
                            <tbody>
                              {material.map((data) => (
                                <tr style={{ cursor: "pointer" }}>
                                  <td>{data?.category}</td>
                                  <td>{data.label.split(" - ")[0]}</td>
                                  <td>{data.label.split(" - ")[1]}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </Row>
                  }
                >
                  Multiple
                </Popover>
              ) : (
                material[0]?.label
              )}
            </span>
          );
        },
      },
      {
        title: "Quantity",
        dataIndex: "material",
        key: "quantity",
        sorter: false,
        width: 100,
        show: !requestDispatch,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(`/app/jobs/job-profile/${record.id}`),
        }),
        render: (material, record) => {
          return (
            <>
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="table-font-mobile"
              >
                {record?.jobType !== "Trucking Job" ? (
                  material[0]?.quantity + " " + material[0]?.quantityType
                ) : (
                  <span
                    style={{
                      color: "#8d9ca6",
                      fontSize: "15px",
                    }}
                  >
                    N/A
                  </span>
                )}
              </span>
            </>
          );
        },
      },
      {
        title: "Project Revenue",
        dataIndex: "material",
        key: "projectRevenue",
        sorter: false,
        show: !requestDispatch,
        className: "col_styling",
        width: 150,
        // onCell: (record) => ({
        //   onClick: () =>
        //     this.props.history.push(`/app/jobs/job-profile/${record.id}`),
        // }),
        render: (material) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                color: "#474747",
                fontSize: "16px",
                fontFamily: "sans-serif",
              }}
              className="table-font-mobile"
            >
              {`$ ${parseFloat(material[0]?.projectRevenue).toLocaleString(
                "us-EN",
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )}`}
            </span>
          );
        },
      },
      {
        title: "COGS",
        dataIndex: "material",
        key: "COGS",
        sorter: false,
        show: !requestDispatch,
        className: "col_styling",
        width: 150,
        // onCell: (record) => ({
        //   onClick: () =>
        //     this.props.history.push(`/app/jobs/job-profile/${record.id}`),
        // }),
        render: (material) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                color: "#474747",
                fontSize: "16px",
                fontFamily: "sans-serif",
              }}
              className="table-font-mobile"
            >
              {`$ ${parseFloat(material[0]?.COGS).toLocaleString("us-EN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`}
            </span>
          );
        },
      },
      {
        title: "Gross Profit",
        dataIndex: "material",
        key: "Gross Profit",
        sorter: false,
        show: !requestDispatch,
        className: "col_styling",
        width: 150,
        // onCell: (record) => ({
        //   onClick: () =>
        //     this.props.history.push(`/app/jobs/job-profile/${record.id}`),
        // }),
        render: (material) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                color: "#474747",
                fontSize: "16px",
                fontFamily: "sans-serif",
              }}
              className="table-font-mobile"
            >
              {`$ ${parseFloat(material[0]?.grossProfit).toLocaleString(
                "us-EN",
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )}`}
              {` (${parseFloat(
                material[0]?.grossProfitPercentage
              ).toLocaleString("us-EN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}%)`}
            </span>
          );
        },
      },
      {
        title: "ACTIONS",
        dataIndex: "actions",
        key: "actions",
        sorter: false,
        className: "col_styling",
        width: 80,
        fixed: "right",
        show: true,
        render: (actions, record) => {
          return (
            <>
              <Row
                style={{
                  width: "5%",
                }}
              >
                <Col>
                  <Popover
                    placement="left"
                    content={
                      <Row className="popovergrid" style={{ maxWidth: "90px" }}>
                        {!requestDispatch &&
                          record?.stageId !== "Close Job" && (
                            <Col span={24}>
                              <Button
                                className="btn_edit"
                                onClick={() => openNotification(record)}
                              >
                                <span
                                  className="span_edit"
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Close Job
                                </span>
                              </Button>
                            </Col>
                          )}
                        <Col span={24}>
                          <Button className="btn_edit">
                            <Popconfirm
                              title={
                                <span>
                                  {`Are you sure you want to ${
                                    record?.showArchive === false
                                      ? "retrive"
                                      : "archive"
                                  } #`}
                                  <b>{record.jobNumber}？</b>
                                </span>
                              }
                              okText={
                                <span style={{ width: "60px", height: "30px" }}>
                                  Yes
                                </span>
                              }
                              cancelText={
                                <span style={{ width: "60px", height: "30px" }}>
                                  No
                                </span>
                              }
                              onConfirm={() => this.archiveEdit(record)}
                            >
                              <span className="span_edit">
                                {record?.showArchive === false ? (
                                  <span
                                    className="span_edit"
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Retrive
                                  </span>
                                ) : (
                                  <span
                                    className="span_edit"
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Archive
                                  </span>
                                )}
                              </span>
                            </Popconfirm>
                          </Button>
                        </Col>
                      </Row>
                    }
                  >
                    <EllipsisOutlined
                      style={{ fontSize: "30px", color: "grey" }}
                      className="action-ellipsis-button"
                    />
                  </Popover>
                </Col>
              </Row>
            </>
          );
        },
      },
    ].filter((e) => e.show);

    return (
      <div
        style={{
          background: "#fafafa",
        }}
      >
        <Loading enableLoading={loading} />

        {errorLoading ? (
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <ErrorView refreshLeadDetails={this.onRefresh} type="LeadDetails" />
          </Col>
        ) : (
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            gutter={[12, 0]}
          >
            <div
              style={{
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                margin: "20px 0px",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Row style={{ marginBottom: "1vw", width: "100%" }}>
                <Col span={24}>
                  <Row
                    style={{
                      borderRadius: "10px",
                      marginBottom: "20px",
                      padding: "0px 20px",
                      backgroundColor: "#ffffff",
                      border: "1px solid gray",
                      textAlign: "left",
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    <Col
                      xxl={{ span: 6 }}
                      xl={{ span: 6 }}
                      lg={{ span: 6 }}
                      md={{ span: 12 }}
                      sm={{ span: 12 }}
                      xs={{ span: 24 }}
                      style={{ margin: "12px 0px" }}
                    >
                      <p
                        style={{
                          fontSize: "26px",
                          fontWeight: "bold",
                          marginBottom: "0px",
                        }}
                        className="owner-operator-card-body"
                      >
                        <span
                          style={{
                            fontSize: "18px",
                            color: "#747474",
                            fontWeight: "600",
                            width: "100%",
                            display: "flex",
                            flexWrap: "nowrap",
                          }}
                          className="owner-operator-card-head"
                        >
                          Customers #
                        </span>
                        {this.countCustomers(filterData)?.length}
                      </p>
                    </Col>
                    <Col
                      xxl={{ span: 6 }}
                      xl={{ span: 6 }}
                      lg={{ span: 6 }}
                      md={{ span: 12 }}
                      sm={{ span: 12 }}
                      xs={{ span: 24 }}
                      style={{ margin: "12px 0px" }}
                    >
                      <p
                        style={{
                          fontSize: "26px",
                          fontWeight: "bold",
                          marginBottom: "0px",
                        }}
                        className="owner-operator-card-body"
                      >
                        <span
                          style={{
                            fontSize: "18px",
                            color: "#747474",
                            fontWeight: "600",
                            width: "100%",
                            display: "flex",
                            flexWrap: "nowrap",
                          }}
                          className="owner-operator-card-head"
                        >
                          Jobs #
                        </span>
                        {filterData?.length}
                      </p>
                    </Col>
                    <Col
                      xxl={{ span: 6 }}
                      xl={{ span: 6 }}
                      lg={{ span: 6 }}
                      md={{ span: 12 }}
                      sm={{ span: 12 }}
                      xs={{ span: 24 }}
                      style={{ margin: "12px 0px" }}
                    >
                      <p
                        style={{
                          fontSize: "26px",
                          fontWeight: "bold",
                          marginBottom: "0px",
                        }}
                        className="owner-operator-card-body"
                      >
                        <span
                          style={{
                            fontSize: "18px",
                            color: "#747474",
                            fontWeight: "600",
                            width: "100%",
                            display: "flex",
                            flexWrap: "nowrap",
                          }}
                          className="owner-operator-card-head"
                        >
                          Trucking Jobs #
                        </span>
                        {this.findJobType("Trucking Job", filterData)?.length}
                      </p>
                    </Col>
                    <Col
                      xxl={{ span: 6 }}
                      xl={{ span: 6 }}
                      lg={{ span: 6 }}
                      md={{ span: 12 }}
                      sm={{ span: 12 }}
                      xs={{ span: 24 }}
                      style={{ margin: "12px 0px" }}
                    >
                      <p
                        style={{
                          fontSize: "26px",
                          fontWeight: "bold",
                          marginBottom: "0px",
                        }}
                        className="owner-operator-card-body"
                      >
                        <span
                          style={{
                            fontSize: "18px",
                            color: "#747474",
                            fontWeight: "600",
                            width: "100%",
                            display: "flex",
                            flexWrap: "nowrap",
                          }}
                          className="owner-operator-card-head"
                        >
                          Trucking & Material Jobs #
                        </span>
                        {
                          this.findJobType(
                            "Trucking & Material Job",
                            filterData
                          )?.length
                        }
                      </p>
                    </Col>
                  </Row>
                  {/* <Row
                    style={{
                      borderRadius: "10px",
                      marginBottom: "20px",
                      padding: "0px 20px",
                      backgroundColor: "#ffffff",
                      border: "1px solid gray",
                      textAlign: "left",
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      overflowX: "auto",
                    }}
                  >
                    <div
                      className="typematerials"
                      style={{ width: "100%", margin: "8px 0px" }}
                    >
                      {trucksTypesData?.map((element) => (
                        <div
                          className="itemtruckType"
                          style={{
                            maxWidth: `${300 / trucksTypesData?.length}%`,
                          }}
                        >
                          <p
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                              marginBottom: "0px",
                            }}
                            className="owner-operator-card-body"
                          >
                            <span
                              style={{
                                fontSize: "18px",
                                color: "#747474",
                                fontWeight: "600",
                                width: "100%",
                                display: "flex",
                                flexWrap: "nowrap",
                              }}
                              className="owner-operator-card-head"
                            >
                              {element.truck_code}
                            </span>

                            {Object.keys(truckTypeCounts).map(
                              (e) => e === element.value && truckTypeCounts[e]
                            )}
                          </p>
                        </div>
                      ))}
                    </div>
                  </Row> */}
                </Col>

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                  xxl={{ span: 6 }}
                >
                  <Row>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}
                      xl={{ span: 24 }}
                      xxl={{ span: 24 }}
                      className="quarrytoolgrid"
                    >
                      <SearchFunction
                        searchData={filterData}
                        tableName={"jobDatabase"}
                        getSearchData={this.searchResult}
                        onResetData={this.onResetData}
                        // searchFields={searchFields}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 18 }}
                  xl={{ span: 18 }}
                  xxl={{ span: 18 }}
                >
                  <Row style={{ justifyContent: "right" }}>
                    {!requestDispatch && (
                      <Col className="gridbtntool">
                        <Button
                          type="primary"
                          icon={
                            archiveData ? (
                              <MdArchive style={{ fontSize: "18px" }} />
                            ) : (
                              <MdUnarchive style={{ fontSize: "18px" }} />
                            )
                          }
                          onClick={() =>
                            this.setState({
                              archiveData: !archiveData,
                            })
                          }
                          style={{
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "5px",
                            height: "40px",
                          }}
                        >
                          <span
                            className="archive-button-ownerOperator"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                              marginLeft: "5px",
                            }}
                          >
                            {archiveData ? "Archive" : "Retrive"}
                          </span>
                        </Button>
                      </Col>
                    )}

                    <Col className="gridbtntool">
                      <Button
                        className="filterButton"
                        style={{
                          borderRadius: "5px",
                          height: "40px",
                        }}
                        onClick={() => {
                          this.setState({ visible: true });
                        }}
                      >
                        <Row>
                          <Col>
                            <span
                              className="show-on-desktop"
                              style={{
                                color:
                                  Object.keys(filterInfo)?.length > 0
                                    ? "red"
                                    : "#5a6067",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              {Object.keys(filterInfo)?.length > 0
                                ? `${
                                    "Filter (" + Object.keys(filterInfo)?.length
                                  })`
                                : "Filter"}
                            </span>
                          </Col>
                          <Col>
                            <IoFilterSharp
                              className="filterIcon"
                              style={{
                                fontSize: "22px",
                                marginLeft: "8px",
                                color:
                                  Object.keys(filterInfo)?.length > 0
                                    ? "red"
                                    : "#5a6067",
                              }}
                            />
                          </Col>
                        </Row>
                      </Button>
                    </Col>

                    {requestDispatch && (
                      <Col className="gridbtntool">
                        <Row
                          style={{
                            background: "#cecece",
                            height: "40px",
                            borderRadius: "6px",
                          }}
                        >
                          <Col style={{ marginTop: "4px" }}>
                            <Button
                              style={{
                                border: "none",
                                borderRadius: "5px",
                                padding: "5px 8px",
                                margin: "0px 5px",
                                background: selected ? "#FFF" : "transparent",
                                boxShadow: "none",
                                outline: "none",
                              }}
                              onClick={() => this.setState({ selected: true })}
                            >
                              <UnorderedListOutlined
                                style={{
                                  fontSize: "22px",
                                  color: "#586370",
                                }}
                              />
                            </Button>
                          </Col>
                          <Col style={{ marginTop: "4px" }}>
                            <Button
                              style={{
                                border: "none",
                                borderRadius: "5px",
                                padding: "5px 8px",
                                margin: "0px 5px",
                                background: selected ? "transparent" : "#FFF",
                                boxShadow: "none",
                                outline: "none",
                              }}
                              onClick={() => this.setState({ selected: false })}
                            >
                              <FaMap
                                style={{
                                  fontSize: "22px",
                                  color: "#586370",
                                }}
                              />
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </div>
            {selected ? (
              <BaseTable
                className="customertablegrp table_laptopscreen table-font-mobile"
                columnsData={customerColumns}
                source={
                  archiveData
                    ? filterData
                        ?.filter((e) => e?.showArchive !== false)
                        ?.sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1))
                    : filterData
                        ?.filter((e) => e?.showArchive === false)
                        ?.sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1))
                }
                total={
                  archiveData
                    ? filterData?.filter((e) => e?.showArchive !== false)
                        ?.length
                    : filterData?.filter((e) => e?.showArchive === false)
                        ?.length
                }
                rowKey={(record) => record.id}
                handleTableChage={(e) =>
                  this.handleTableChage(e, requestDispatch)
                }
                sizeRange={
                  window.screen.width > 1023
                    ? requestDispatch
                      ? JSON.parse(localStorage.getItem("tableSizeRange"))
                          ?.dispatchTool
                      : JSON.parse(localStorage.getItem("tableSizeRange"))
                          ?.jobDatabase
                    : archiveData
                    ? filterData?.filter((e) => e?.showArchive !== false)
                        ?.length
                    : filterData?.filter((e) => e?.showArchive === false)
                        ?.length
                }
                pagination={window.screen.width > 1023 ? true : false}
                scroll={
                  requestDispatch
                    ? ""
                    : {
                        x: 1980,
                        y: 100000,
                      }
                }
              />
            ) : (
              <Row>
                <Col
                  xxl={{ span: 24, offset: 0 }}
                  xl={{ span: 24, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  style={{ height: "75vh" }}
                  className="twistednailmap"
                >
                  {this.showMap(filterData)}
                </Col>
              </Row>
            )}
          </Col>
        )}

        {dispatchModal && (
          <BaseModal
            title={`Dispatch Request for Job #${leadDetails?.jobNumber}`}
            onCancel={this.onCancelDispatchModal}
            formId="dispatchForm"
            loading={loading}
            className="antmodal_grid headermodal"
            width={"80%"}
            footer={false}
          >
            <Form
              onFinish={this.onFinishDispatch}
              id="dispatchForm"
              ref={this.formRef}
            >
              <Row gutter={[24, 0]}>
                <Col
                  xxl={{ span: 24, offset: 0 }}
                  xl={{ span: 24, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                >
                  {this.showSingleMap(leadDetails)}
                </Col>

                <Col
                  xxl={{ span: 24, offset: 0 }}
                  xl={{ span: 24, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                >
                  {leadDetails?.jobType !== "Trucking Job" && (
                    <Row style={{ width: "100%" }}>
                      <Row
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "10px",
                          marginTop: "15px",
                        }}
                      >
                        <span
                          style={{
                            marginBottom: "10px",
                            display: "flex",
                            fontSize: "15px",
                            fontWeight: "500",
                            color: "rgb(88,99,112)",
                            textTransform: "capitalize",
                          }}
                        >
                          Material Details:
                        </span>
                      </Row>
                      <Col
                        span={24}
                        style={{ overflowX: "auto" }}
                        className="twistedtable"
                      >
                        <BaseTable
                          columnsData={dispatchColumns}
                          source={totalMaterials}
                          pagination={false}
                        />
                      </Col>
                    </Row>
                  )}
                  {leadDetails?.jobType === "Trucking Job" && (
                    <Row style={{ width: "100%" }}>
                      <Row
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "10px",
                          marginTop: "15px",
                        }}
                      >
                        <span
                          style={{
                            marginBottom: "10px",
                            display: "flex",
                            fontSize: "15px",
                            fontWeight: "500",
                            color: "rgb(88,99,112)",
                            textTransform: "capitalize",
                          }}
                        >
                          Trucking Details:
                        </span>
                      </Row>
                      <Col
                        span={24}
                        style={{ overflowX: "auto" }}
                        className="twistedtable"
                      >
                        <BaseTable
                          columnsData={dispatchColumnsTrucking}
                          source={totalMaterials}
                          pagination={false}
                        />
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}

        {dispatchTypeModal && (
          <BaseModal
            title={"Dispatch Request"}
            onCancel={() => {
              this.setState({
                dispatchTypeModal: false,
                dispatchTypeSelect: "",
                dispatchDate: "",
              });
              this.formRef.current.setFieldsValue({
                dispatchTypeSelect: "",
                truckType: undefined,
                trucksRequested: "",
                dispatchNotes: "",
                dispatch: "",
              });
            }}
            formId="calendermodal"
            className="antmodal_grid headermodal"
            width="40%"
            loading={materialLoading}
            destroyOnClose={true}
            pagination={window.screen.width > 1023 ? true : false}
          >
            <Form
              id="calendermodal"
              onFinish={this.onFinishRecurring}
              ref={this.formRef}
            >
              <Row gutter={[8, 0]}>
                <Col
                  xxl={{ span: hourlyJob ? 12 : 8 }}
                  xl={{ span: hourlyJob ? 12 : 8 }}
                  lg={{ span: hourlyJob ? 12 : 8 }}
                  md={{ span: hourlyJob ? 12 : 8 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span
                    className="title_changes"
                    style={{ marginBottom: "10px" }}
                  >
                    Select Date
                  </span>
                  <Form.Item
                    name="specificDate"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Date!",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "5px",
                      }}
                      format={"MM/DD/YYYY"}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: hourlyJob ? 12 : 8 }}
                  xl={{ span: hourlyJob ? 12 : 8 }}
                  lg={{ span: hourlyJob ? 12 : 8 }}
                  md={{ span: hourlyJob ? 12 : 8 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span
                    className="title_changes"
                    style={{ marginBottom: "10px" }}
                  >
                    Trucks Requested
                  </span>
                  <Form.Item
                    name="trucksRequested"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your TrucksRequested!",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="Please Enter Trucking Price To Customer"
                      addonAfter={selectAfterTruckPrice}
                      style={{
                        height: "40px",
                        width: "100%",
                      }}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: hourlyJob ? 12 : 8 }}
                  xl={{ span: hourlyJob ? 12 : 8 }}
                  lg={{ span: hourlyJob ? 12 : 8 }}
                  md={{ span: hourlyJob ? 12 : 8 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span
                    className="title_changes"
                    style={{ marginBottom: "10px" }}
                  >
                    Dispatch Priority
                  </span>
                  <Form.Item
                    name="dispatchPriority"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Dispatch Priority !",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      className="select_Height"
                      style={{
                        height: "40px",
                        fontWeight: "300",
                      }}
                      placeholder="Please Select Dispatch Priority"
                    >
                      <Option value="1">1 (High Priority)</Option>
                      <Option value="2">2</Option>
                      <Option value="3">3</Option>
                      <Option value="4">4</Option>
                      <Option value="5">5 (Low Priority)</Option>
                    </Select>
                  </Form.Item>
                </Col>
                {hourlyJob && (
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 12 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">
                      Waive Contract Hourly Minimum{" "}
                    </span>
                    <Form.Item
                      name="waiveContract"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please Select Waive Contract Hourly Minimum!",
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        className="select_Height"
                        style={{
                          height: "40px",
                          fontWeight: "300",
                        }}
                        placeholder="Please Select Waive Contract Hourly Minimum"
                        onChange={(e) =>
                          this.setState({
                            waiveContract: e,
                          })
                        }
                      >
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                {waiveContract !== "" && (
                  <span
                    className="title_changes"
                    style={{
                      marginBottom: "10px",
                      marginTop: "-10px",
                      fontWeight: "600",
                      color:'red'
                    }}
                  >
                    {waiveContract === "Yes"
                      ? `You have removed the ${selectedMaterial?.hourlyCharge} hour minimum for this dispatch request`
                      : waiveContract === "No" &&
                        `Hourly Minimum of ${selectedMaterial?.hourlyCharge} hours will be applied to this dispatch`}
                  </span>
                )}
                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span
                    className="title_changes"
                    style={{ marginBottom: "10px" }}
                  >
                    Select Trucks Type
                  </span>
                  <Form.Item
                    name="truckType"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Your Truck Type!",
                      },
                    ]}
                    initialValue={selectedMaterial?.truckType}
                  >
                    <Select
                      allowClear
                      className="select_multiplequarytol"
                      listHeight={200}
                      listItemHeight={4}
                      style={{
                        height: "auto",
                        fontWeight: "300",
                      }}
                      mode="multiple"
                      placeholder="Please Select Your Type"
                    >
                      {trucksTypesData?.map((e, i) => (
                        <Option value={e?.value} key={i}>
                          {e?.value}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <span className="title_changes">Notes for Dispatcher: </span>
                  <Form.Item
                    name="dispatchNotes"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Dispatch Notes!",
                      },
                    ]}
                  >
                    <TextArea
                      rows={4}
                      style={{
                        width: "100%",
                        height: "75px",
                        borderColor: "#d6d6d6",
                      }}
                      className="form-input"
                      placeholder="Please Enter Your Dispatch Notes"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}

        {visible && (
          <Filter
            visible={visible}
            closeFilter={this.closeFilter}
            filterResultData={this.filterResultData}
            onClearFilter={this.onClearFilter}
            truckingMaterial
            jobDatabase
            filterPropTruckingData={filterPropTruckingData}
            JobData={filterData}
            company={companyData}
            trucksData={trucksTypesData}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  result: state.reducer?.leadsData,
  loading: state?.reducer?.leadsData?.loading,
  errorLoading: state?.reducer?.leadsData?.error,
  leadUpdateInfo: state?.reducer?.leadUpdateInfo,
  trucksTypesData: state.reducer?.dropDownOptions?.totalData?.filter(
    (t) => t.typeofDropDown === "trucksType"
  ),
  dropDownOptions: state.reducer?.dropDownOptions,
  materialResult: state?.reducer?.materialResult,
});

const mapDispatchToProps = (dispatch) => ({
  actionFetchLeads: () => {
    dispatch(getLeads());
  },
  actionUpdateJob: (id, data) => {
    dispatch(updateLead(id, data));
  },
  actiongetDropDownOptions: () => {
    dispatch(getDropDownOptions());
  },
  actionUpdateInvoice: (id, data) => {
    dispatch(updateInvoices(id, data));
  },
  actionFetchMaterialData: (data) => {
    dispatch(updateRecurringData(data));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(JobDatabase);
