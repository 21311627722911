import {
  FETCH_LEADS,
  FETCH_CONTACTS,
  FETCH_CONTACTS_DELETE,
  FETCH_CONTACTS_UPDATE,
  FETCH_CONTACTS_ADD,
  FETCH_OWNEROPERATORS,
  FETCH_COMPANY_DELETE,
  FETCH_COMPANY_UPDATE,
  FETCH_COMPANY_ADD,
  FETCH_DROPDOWNOPTIONS,
  FETCH_DROPDOWNOPTIONS_UPDATE,
  FETCH_COMPANIES,
  FETCH_COMPANY,
  FETCH_LEADS_ADD,
  FETCH_LEADS_UPDATE,
  FETCH_JOBCONTACT_DELETE,
  FETCH_DOCUMENTS_ADD,
  FETCH_DOCUMENTS_UPDATE,
  FETCH_DOCUMENTS_DELETE,
  FETCH_TRUCKSLIST,
  FETCH_TRUCKSLIST_ADD,
  FETCH_QUARRIESLIST,
  FETCH_QUARRIESLIST_UPDATE,
  FETCH_QUARRIESLIST_ADD,
  FETCH_QUARRIESLIST_DELETE,
  FETCH_CONTACT_ADD,
  FETCH_LEADS_DELETE,
  FETCH_LEAD,
  FETCH_TRUCKSLIST_QUERY,
  FETCH_DOCUMENTS,
  FETCH_NOTES,
  FETCH_NOTES_ADD,
  FETCH_NOTES_UPDATE,
  FETCH_NOTES_DELETE,
  FETCH_QUARRY,
  FETCH_TOPVENDORS,
  FETCH_TRUCKLIST,
  FETCH_TRUCKLIST_UPDATE,
  FETCH_USERS,
  FETCH_USER_ADD,
  FETCH_USER_UPDATE,
  FETCH_USER_DELETE,
  FETCH_ROLES,
  FETCH_ROLE_ADD,
  FETCH_ROLE_UPDATE,
  FETCH_ROLE_DELETE,
  FETCH_DISPATCH,
  FETCH_TICKETPROCESS,
  FETCH_INVOICES,
  FETCH_INVOICE,
  FETCH_INVOICE_GENERATE,
  FETCH_TICKETPROCESS_ADD,
  FETCH_TICKETPROCESS_DELETE,
  FETCH_DASHBOARD,
  FETCH_SALESPERSON,
  FETCH_RECURRING,
  UPDATE_QUARRYSTATUS,
  GET_DISPATCH_SUMMARY,
  DELETE_TRUCKSLIST,
  GET_DELETE_DATA,
  DELETE_RESTORE_DATA,
  FETCH_ORDERS,
  FETCH_ORDERS_UPDATE,
  UPDATE_INVOICES,
  DELETE_ORDERS_DATA,
  UPDATE_ESTIMATE,
  FETCH_SALES_SUMMARY,
  UPDATE_MULTIPLE_INVOICES,
  GET_ACCOUNTS_PAYABLE,
  FETCH_SINGLE_TICKETPROCESS,
  FETCH_DISPATCH_EMAIL,
} from "../Actions/types";

const actionStatus = {
  loading: false,
  success: false,
  error: false,
};

const INITIAL_STATE = {
  accountsResult: {
    accountsResult: [],
    ...actionStatus,
  },
  companiesData: {
    companies: [],
    loading: false,
    success: false,
    error: false,
  },
  leadsData: {
    leads: [],
    loading: false,
    success: false,
    error: false,
  },
  contactsData: {
    contacts: [],
    loading: false,
    success: false,
    error: false,
  },
  contactsInfo: {
    contactsData: [],
    loading: false,
    success: false,
    error: false,
  },
  companiesInfo: {
    loading: false,
    success: false,
    error: false,
  },
  ownerOperatorsData: {
    ownersData: [],
    loading: false,
    success: false,
    error: false,
  },
  dropDownOptions: {
    totalData: [],
    loading: false,
    success: false,
    error: false,
  },
  dropDownOptionsInfo: {
    loading: false,
    success: false,
    error: false,
  },
  companyResult: {
    companyData: [],
    loading: false,
    success: false,
    error: false,
  },
  leadsInfo: {
    leads: [],
    loading: false,
    success: false,
    error: false,
  },
  leadUpdateInfo: {
    loading: false,
    success: false,
    error: false,
  },
  docResult: {
    loading: false,
    success: false,
    error: false,
  },
  trucksListResult: {
    trucksListData: [],
    loading: false,
    success: false,
    error: false,
  },
  trucksListInfo: {
    loading: false,
    success: false,
    error: false,
  },
  quarriesResult: {
    quarryData: [],
    ...actionStatus,
  },
  quarriesInfo: actionStatus,
  contactData: {
    contact: [],
    ...actionStatus,
  },
  leadData: {
    lead: [],
    ...actionStatus,
  },
  notesResult: {
    notesData: [],
    ...actionStatus,
  },
  notesInfo: actionStatus,

  quarryResult: {
    quarry: [],
    ...actionStatus,
  },
  vendorsData: {
    vendors: [],
    ...actionStatus,
  },
  truckListResult: {
    truckListData: [],
    ...actionStatus,
  },
  usersResult: {
    usersData: [],
    ...actionStatus,
  },
  usersInfo: actionStatus,
  rolesResult: {
    rolesData: [],
    ...actionStatus,
  },
  rolesInfo: actionStatus,
  rolesUpdateInfo: actionStatus,
  dispatchResult: {
    dispatchData: [],
    ...actionStatus,
  },
  ticketsResult: {
    ticketsData: [],
    ...actionStatus,
  },
  invoicesResult: {
    invoicesData: [],
    ...actionStatus,
  },
  invoiceResult: {
    invoiceData: [],
    ...actionStatus,
  },
  generateInvoiceInfo: actionStatus,
  ticketsInfo: actionStatus,
  dashboardResult: {
    dashboardData: [],
    ...actionStatus,
  },
  salesPersonResult: {
    salesData: [],
    ...actionStatus,
  },
  materialResult: {
    updatedData: [],
    ...actionStatus,
  },
  quarryStatusResult: actionStatus,

  dispatchStatusResult: actionStatus,

  trucksInfo: actionStatus,

  deletedResult: {
    deletedData: [],
    ...actionStatus,
  },

  deletedResultInfo: actionStatus,
  ordersResult: {
    ordersData: [],
    ...actionStatus,
  },
  ordersResultInfo: actionStatus,
  invoiceInfo: actionStatus,
  estimateResult: {
    ...actionStatus,
    data: [],
  },
  mailResult: actionStatus,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_DISPATCH_EMAIL.pending:
      return {
        ...state,
        mailResult: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case FETCH_DISPATCH_EMAIL.success:
      return {
        ...state,
        mailResult: {
          loading: false,
          success: true,
          error: false,
        },
      };
    case FETCH_DISPATCH_EMAIL.error:
      return {
        ...state,
        mailResult: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case GET_ACCOUNTS_PAYABLE.pending:
      return {
        ...state,
        accountsResult: {
          accountsResult: [],
          loading: true,
          success: false,
          error: false,
        },
      };
    case GET_ACCOUNTS_PAYABLE.success:
      return {
        ...state,
        accountsResult: {
          data: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };
    case GET_ACCOUNTS_PAYABLE.error:
      return {
        ...state,
        accountsResult: {
          accountsResult: [],
          loading: false,
          success: false,
          error: true,
        },
      };
    case UPDATE_MULTIPLE_INVOICES.pending:
      return {
        ...state,
        invoiceInfo: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case UPDATE_MULTIPLE_INVOICES.success:
      return {
        ...state,
        invoiceInfo: {
          loading: false,
          success: true,
          error: false,
        },
      };
    case UPDATE_MULTIPLE_INVOICES.error:
      return {
        ...state,
        invoiceInfo: {
          loading: false,
          success: false,
          error: true,
        },
      };
    case FETCH_SALES_SUMMARY.pending:
      return {
        ...state,
        salesResult: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case FETCH_SALES_SUMMARY.success:
      return {
        ...state,
        salesResult: {
          loading: false,
          success: true,
          error: false,
        },
      };
    case FETCH_SALES_SUMMARY.error:
      return {
        ...state,
        salesResult: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case UPDATE_ESTIMATE.pending:
      return {
        ...state,
        estimateResult: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case UPDATE_ESTIMATE.success:
      return {
        ...state,
        estimateResult: {
          loading: false,
          success: true,
          data: action.payload,
          error: false,
        },
      };
    case UPDATE_ESTIMATE.error:
      return {
        ...state,
        estimateResult: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case DELETE_ORDERS_DATA.pending:
      return {
        ...state,
        ordersResultInfo: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case DELETE_ORDERS_DATA.success:
      return {
        ...state,
        ordersResultInfo: {
          loading: false,
          success: true,
          error: false,
        },
      };
    case DELETE_ORDERS_DATA.error:
      return {
        ...state,
        ordersResultInfo: {
          loading: false,
          success: false,
          error: true,
        },
      };
    case UPDATE_INVOICES.pending:
      return {
        ...state,
        invoiceInfo: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case UPDATE_INVOICES.success:
      return {
        ...state,
        invoiceInfo: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case UPDATE_INVOICES.error:
      return {
        ...state,
        invoiceInfo: {
          loading: false,
          success: false,
          error: true,
        },
      };
    case FETCH_ORDERS_UPDATE.pending:
      return {
        ...state,
        ordersResultInfo: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_ORDERS_UPDATE.success:
      return {
        ...state,
        ordersResultInfo: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_ORDERS_UPDATE.error:
      return {
        ...state,
        ordersResultInfo: {
          loading: false,
          success: false,
          error: true,
        },
      };
    case FETCH_ORDERS.pending:
      return {
        ...state,
        ordersResult: {
          ordersData: [],
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_ORDERS.success:
      return {
        ...state,
        ordersResult: {
          ordersData: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_ORDERS.error:
      return {
        ...state,
        ordersResult: {
          ordersData: [],
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_DASHBOARD.pending:
      return {
        ...state,
        dashboardResult: {
          dashboardData: [],
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_DASHBOARD.success:
      return {
        ...state,
        dashboardResult: {
          dashboardData: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_DASHBOARD.error:
      return {
        ...state,
        dashboardResult: {
          dashboardData: [],
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_INVOICE_GENERATE.pending:
      return {
        ...state,
        generateInvoiceInfo: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_INVOICE_GENERATE.success:
      return {
        ...state,
        generateInvoiceInfo: {
          loading: false,
          success: true,
          error: false,
          data: action.payload,
        },
      };

    case FETCH_INVOICE_GENERATE.error:
      return {
        ...state,
        generateInvoiceInfo: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_INVOICE.pending:
      return {
        ...state,
        invoiceResult: {
          invoiceData: [],
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_INVOICE.success:
      return {
        ...state,
        invoiceResult: {
          invoiceData: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_INVOICE.error:
      return {
        ...state,
        invoiceResult: {
          invoiceData: [],
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_INVOICES.pending:
      return {
        ...state,
        invoicesResult: {
          invoicesData: [],
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_INVOICES.success:
      return {
        ...state,
        invoicesResult: {
          invoicesData: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_INVOICES.error:
      return {
        ...state,
        invoicesResult: {
          invoicesData: [],
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_SINGLE_TICKETPROCESS.pending:
      return {
        ...state,
        ticketsResult: {
          ticketsData: [],
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_SINGLE_TICKETPROCESS.success:
      return {
        ...state,
        ticketsResult: {
          ticketsData: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_SINGLE_TICKETPROCESS.error:
      return {
        ...state,
        ticketsResult: {
          ticketsData: [],
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_TICKETPROCESS.pending:
      return {
        ...state,
        ticketsResult: {
          ticketsData: [],
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_TICKETPROCESS.success:
      return {
        ...state,
        ticketsResult: {
          ticketsData: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_TICKETPROCESS.error:
      return {
        ...state,
        ticketsResult: {
          ticketsData: [],
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_TICKETPROCESS_ADD.pending:
      return {
        ...state,
        ticketsInfo: {
          loading: true,
          success: false,
          error: false,
          result: [],
        },
      };

    case FETCH_TICKETPROCESS_ADD.success:
      return {
        ...state,
        ticketsInfo: {
          loading: false,
          success: true,
          error: false,
          result: action.payload.data,
        },
      };

    case FETCH_TICKETPROCESS_ADD.error:
      return {
        ...state,
        ticketsInfo: {
          loading: false,
          success: false,
          error: true,
          result: [],
        },
      };

    case FETCH_TICKETPROCESS_DELETE.pending:
      return {
        ...state,
        ticketsInfo: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_TICKETPROCESS_DELETE.success:
      return {
        ...state,
        ticketsInfo: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_TICKETPROCESS_DELETE.error:
      return {
        ...state,
        ticketsInfo: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_DISPATCH.pending:
      return {
        ...state,
        dispatchResult: {
          dispatchData: [],
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_DISPATCH.success:
      return {
        ...state,
        dispatchResult: {
          dispatchData: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_DISPATCH.error:
      return {
        ...state,
        dispatchResult: {
          dispatchData: [],
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_ROLES.pending:
      return {
        ...state,
        rolesResult: {
          rolesData: [],
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_ROLES.success:
      return {
        ...state,
        rolesResult: {
          rolesData: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_ROLES.error:
      return {
        ...state,
        rolesResult: {
          rolesData: [],
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_ROLE_ADD.pending:
      return {
        ...state,
        rolesInfo: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_ROLE_ADD.success:
      return {
        ...state,
        rolesInfo: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_ROLE_ADD.error:
      return {
        ...state,
        rolesInfo: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_ROLE_UPDATE.pending:
      return {
        ...state,
        rolesUpdateInfo: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_ROLE_UPDATE.success:
      return {
        ...state,
        rolesUpdateInfo: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_ROLE_UPDATE.error:
      return {
        ...state,
        rolesUpdateInfo: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_ROLE_DELETE.pending:
      return {
        ...state,
        rolesInfo: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_ROLE_DELETE.success:
      return {
        ...state,
        rolesInfo: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_ROLE_DELETE.error:
      return {
        ...state,
        rolesInfo: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_USERS.pending:
      return {
        ...state,
        usersResult: {
          usersData: [],
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_USERS.success:
      return {
        ...state,
        usersResult: {
          usersData: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_USERS.error:
      return {
        ...state,
        usersResult: {
          usersData: [],
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_USER_ADD.pending:
      return {
        ...state,
        usersInfo: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_USER_ADD.success:
      return {
        ...state,
        usersInfo: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_USER_ADD.error:
      return {
        ...state,
        usersInfo: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_USER_UPDATE.pending:
      return {
        ...state,
        usersInfo: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_USER_UPDATE.success:
      return {
        ...state,
        usersInfo: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_USER_UPDATE.error:
      return {
        ...state,
        usersInfo: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_USER_DELETE.pending:
      return {
        ...state,
        usersInfo: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_USER_DELETE.success:
      return {
        ...state,
        usersInfo: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_USER_DELETE.error:
      return {
        ...state,
        usersInfo: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_COMPANIES.pending:
      return {
        ...state,
        companiesData: {
          companies: [],
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_COMPANIES.success:
      return {
        ...state,
        companiesData: {
          companies: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_COMPANIES.error:
      return {
        ...state,
        companiesData: {
          companies: [],
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_COMPANY.pending:
      return {
        ...state,
        companyResult: {
          companyData: [],
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_COMPANY.success:
      return {
        ...state,
        companyResult: {
          companyData: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_COMPANY.error:
      return {
        ...state,
        companyResult: {
          companyData: [],
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_COMPANY_DELETE.pending:
      return {
        ...state,
        companiesInfo: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_COMPANY_DELETE.success:
      return {
        ...state,
        companiesInfo: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_COMPANY_DELETE.error:
      return {
        ...state,
        companiesInfo: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_COMPANY_UPDATE.pending:
      return {
        ...state,
        companiesInfo: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_COMPANY_UPDATE.success:
      return {
        ...state,
        companiesInfo: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_COMPANY_UPDATE.error:
      return {
        ...state,
        companiesInfo: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_COMPANY_ADD.pending:
      return {
        ...state,
        companiesInfo: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_COMPANY_ADD.success:
      return {
        ...state,
        companiesInfo: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_COMPANY_ADD.error:
      return {
        ...state,
        companiesInfo: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_OWNEROPERATORS.pending:
      return {
        ...state,
        ownerOperatorsData: {
          ownersData: [],
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_OWNEROPERATORS.success:
      return {
        ...state,
        ownerOperatorsData: {
          ownersData: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_OWNEROPERATORS.error:
      return {
        ...state,
        ownerOperatorsData: {
          ownersData: [],
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_LEADS.pending:
      return {
        ...state,
        leadsData: {
          leads: [],
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_LEADS.success:
      return {
        ...state,
        leadsData: {
          leads: action.payload.data,
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_LEADS.error:
      return {
        ...state,
        leadsData: {
          leads: [],
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_LEAD.pending:
      return {
        ...state,
        leadData: {
          lead: [],
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_LEAD.success:
      return {
        ...state,
        leadData: {
          lead: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_LEAD.error:
      return {
        ...state,
        leadData: {
          lead: [],
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_LEADS_ADD.pending:
      return {
        ...state,
        leadsInfo: {
          leads: [],
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_LEADS_ADD.success:
      return {
        ...state,
        leadsInfo: {
          leads: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_LEADS_ADD.error:
      return {
        ...state,
        leadsInfo: {
          leads: [],
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_LEADS_UPDATE.pending:
      return {
        ...state,
        leadUpdateInfo: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_LEADS_UPDATE.success:
      return {
        ...state,
        leadUpdateInfo: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_LEADS_UPDATE.error:
      return {
        ...state,
        leadUpdateInfo: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_LEADS_DELETE.pending:
      return {
        ...state,
        leadUpdateInfo: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_LEADS_DELETE.success:
      return {
        ...state,
        leadUpdateInfo: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_LEADS_DELETE.error:
      return {
        ...state,
        leadUpdateInfo: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_CONTACTS.pending:
      return {
        ...state,
        contactsData: {
          contacts: [],
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_CONTACTS.success:
      return {
        ...state,
        contactsData: {
          contacts: action.payload,
          loading: false,
          success: true,
          error: false,
        },
        contactsInfo: {
          loading: false,
          success: false,
          error: false,
        },
      };

    case FETCH_CONTACTS.error:
      return {
        ...state,
        contactsData: {
          contacts: [],
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_CONTACTS_DELETE.pending:
      return {
        ...state,
        contactsInfo: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_CONTACTS_DELETE.success:
      return {
        ...state,
        contactsInfo: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_CONTACTS_DELETE.error:
      return {
        ...state,
        contactsInfo: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_JOBCONTACT_DELETE.pending:
      return {
        ...state,
        contactsInfo: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_JOBCONTACT_DELETE.success:
      return {
        ...state,
        contactsInfo: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_JOBCONTACT_DELETE.error:
      return {
        ...state,
        contactsInfo: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_CONTACTS_UPDATE.pending:
      return {
        ...state,
        contactsInfo: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_CONTACTS_UPDATE.success:
      return {
        ...state,
        contactsInfo: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_CONTACTS_UPDATE.error:
      return {
        ...state,
        contactsInfo: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_CONTACTS_ADD.pending:
      return {
        ...state,
        contactsInfo: {
          contactsData: [],
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_CONTACTS_ADD.success:
      return {
        ...state,
        contactsInfo: {
          contactsData: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_CONTACTS_ADD.error:
      return {
        ...state,
        contactsInfo: {
          contactsData: [],
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_DROPDOWNOPTIONS.pending:
      return {
        ...state,
        dropDownOptions: {
          totalData: [],
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_DROPDOWNOPTIONS.success:
      return {
        ...state,
        dropDownOptions: {
          totalData: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_DROPDOWNOPTIONS.error:
      return {
        ...state,
        dropDownOptions: {
          totalData: [],
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_DROPDOWNOPTIONS_UPDATE.pending:
      return {
        ...state,
        dropDownOptionsInfo: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_DROPDOWNOPTIONS_UPDATE.success:
      return {
        ...state,
        dropDownOptionsInfo: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_DROPDOWNOPTIONS_UPDATE.error:
      return {
        ...state,
        dropDownOptionsInfo: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_DOCUMENTS.pending:
      return {
        ...state,
        docsData: {
          docData: [],
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_DOCUMENTS.success:
      return {
        ...state,
        docsData: {
          docData: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_DOCUMENTS.error:
      return {
        ...state,
        docsData: {
          docData: [],
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_DOCUMENTS_ADD.pending:
      return {
        ...state,
        docResult: {
          loading: true,
          success: false,
          error: false,
          noUpdate: action.updateStatus,
        },
      };

    case FETCH_DOCUMENTS_ADD.success:
      return {
        ...state,
        docResult: {
          loading: false,
          success: true,
          error: false,
          noUpdate: action.updateStatus,
        },
      };

    case FETCH_DOCUMENTS_ADD.error:
      return {
        ...state,
        docResult: {
          loading: false,
          success: false,
          error: true,
          noUpdate: action.updateStatus,
        },
      };

    case FETCH_DOCUMENTS_UPDATE.pending:
      return {
        ...state,
        docResult: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_DOCUMENTS_UPDATE.success:
      return {
        ...state,
        docResult: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_DOCUMENTS_UPDATE.error:
      return {
        ...state,
        docResult: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_DOCUMENTS_DELETE.pending:
      return {
        ...state,
        docResult: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_DOCUMENTS_DELETE.success:
      return {
        ...state,
        docResult: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_DOCUMENTS_DELETE.error:
      return {
        ...state,
        docResult: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_TRUCKSLIST.pending:
      return {
        ...state,
        trucksListResult: {
          trucksListData: [],
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_TRUCKSLIST.success:
      return {
        ...state,
        trucksListResult: {
          trucksListData: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_TRUCKSLIST.error:
      return {
        ...state,
        trucksListResult: {
          trucksListData: [],
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_TRUCKLIST.pending:
      return {
        ...state,
        truckListResult: {
          truckListData: [],
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_TRUCKLIST.success:
      return {
        ...state,
        truckListResult: {
          truckListData: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_TRUCKLIST.error:
      return {
        ...state,
        truckListResult: {
          truckListData: [],
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_TRUCKSLIST_ADD.pending:
      return {
        ...state,
        trucksListInfo: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_TRUCKSLIST_ADD.success:
      return {
        ...state,
        trucksListInfo: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_TRUCKSLIST_ADD.error:
      return {
        ...state,
        trucksListInfo: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_TRUCKLIST_UPDATE.pending:
      return {
        ...state,
        trucksListInfo: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_TRUCKLIST_UPDATE.success:
      return {
        ...state,
        trucksListInfo: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_TRUCKLIST_UPDATE.error:
      return {
        ...state,
        trucksListInfo: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_QUARRIESLIST.pending:
      return {
        ...state,
        quarriesResult: {
          quarryData: [],
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_QUARRIESLIST.success:
      return {
        ...state,
        quarriesResult: {
          quarryData: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_QUARRIESLIST.error:
      return {
        ...state,
        quarriesResult: {
          quarryData: [],
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_QUARRIESLIST_UPDATE.pending:
      return {
        ...state,
        quarriesInfo: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_QUARRIESLIST_UPDATE.success:
      return {
        ...state,
        quarriesInfo: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_QUARRIESLIST_UPDATE.error:
      return {
        ...state,
        quarriesInfo: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_QUARRIESLIST_ADD.pending:
      return {
        ...state,
        quarriesInfo: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_QUARRIESLIST_ADD.success:
      return {
        ...state,
        quarriesInfo: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_QUARRIESLIST_ADD.error:
      return {
        ...state,
        quarriesInfo: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_QUARRIESLIST_DELETE.pending:
      return {
        ...state,
        quarriesInfo: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_QUARRIESLIST_DELETE.success:
      return {
        ...state,
        quarriesInfo: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_QUARRIESLIST_DELETE.error:
      return {
        ...state,
        quarriesInfo: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_CONTACT_ADD.pending:
      return {
        ...state,
        contactData: {
          contact: [],
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_CONTACT_ADD.success:
      return {
        ...state,
        contactData: {
          contact: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_CONTACT_ADD.error:
      return {
        ...state,
        contactData: {
          contact: [],
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_TRUCKSLIST_QUERY.pending:
      return {
        ...state,
        trucksResult: {
          trucksData: [],
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_TRUCKSLIST_QUERY.success:
      return {
        ...state,
        trucksResult: {
          trucksData: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_TRUCKSLIST_QUERY.error:
      return {
        ...state,
        trucksResult: {
          trucksData: [],
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_NOTES.pending:
      return {
        ...state,
        notesResult: {
          notesData: [],
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_NOTES.success:
      return {
        ...state,
        notesResult: {
          notesData: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_NOTES.error:
      return {
        ...state,
        notesResult: {
          notesData: [],
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_NOTES_ADD.pending:
      return {
        ...state,
        notesInfo: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_NOTES_ADD.success:
      return {
        ...state,
        notesInfo: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_NOTES_ADD.error:
      return {
        ...state,
        notesInfo: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_NOTES_UPDATE.pending:
      return {
        ...state,
        notesInfo: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_NOTES_UPDATE.success:
      return {
        ...state,
        notesInfo: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_NOTES_UPDATE.error:
      return {
        ...state,
        notesInfo: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_NOTES_DELETE.pending:
      return {
        ...state,
        notesInfo: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_NOTES_DELETE.success:
      return {
        ...state,
        notesInfo: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_NOTES_DELETE.error:
      return {
        ...state,
        notesInfo: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_QUARRY.pending:
      return {
        ...state,
        quarryResult: {
          quarry: [],
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_QUARRY.success:
      return {
        ...state,
        quarryResult: {
          quarry: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_QUARRY.error:
      return {
        ...state,
        quarryResult: {
          quarry: [],
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_TOPVENDORS.pending:
      return {
        ...state,
        vendorsData: {
          vendors: [],
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_TOPVENDORS.success:
      return {
        ...state,
        vendorsData: {
          vendors: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_TOPVENDORS.error:
      return {
        ...state,
        vendorsData: {
          vendors: [],
          loading: false,
          success: false,
          error: true,
        },
      };
    case FETCH_SALESPERSON.pending:
      return {
        ...state,
        salesPersonResult: {
          salesData: [],
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_SALESPERSON.success:
      return {
        ...state,
        salesPersonResult: {
          salesData: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_SALESPERSON.error:
      return {
        ...state,
        salesPersonResult: {
          salesData: [],
          loading: false,
          success: false,
          error: true,
        },
      };

    case FETCH_RECURRING.pending:
      return {
        ...state,
        materialResult: {
          updatedData: [],
          loading: true,
          success: false,
          error: false,
        },
      };

    case FETCH_RECURRING.success:
      return {
        ...state,
        materialResult: {
          updatedData: action.payload.data,
          loading: false,
          success: true,
          error: false,
        },
      };

    case FETCH_RECURRING.error:
      return {
        ...state,
        materialResult: {
          updatedData: [],
          loading: false,
          success: false,
          error: true,
        },
      };

    case UPDATE_QUARRYSTATUS.pending:
      return {
        ...state,
        quarryStatusResult: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case UPDATE_QUARRYSTATUS.success:
      return {
        ...state,
        quarryStatusResult: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case UPDATE_QUARRYSTATUS.error:
      return {
        ...state,
        quarryStatusResult: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case GET_DISPATCH_SUMMARY.pending:
      return {
        ...state,
        dispatchStatusResult: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case GET_DISPATCH_SUMMARY.success:
      return {
        ...state,
        dispatchStatusResult: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case GET_DISPATCH_SUMMARY.error:
      return {
        ...state,
        dispatchStatusResult: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case DELETE_TRUCKSLIST.pending:
      return {
        ...state,
        trucksInfo: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case DELETE_TRUCKSLIST.success:
      return {
        ...state,
        trucksInfo: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case DELETE_TRUCKSLIST.error:
      return {
        ...state,
        trucksInfo: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case GET_DELETE_DATA.pending:
      return {
        ...state,
        deletedResult: {
          deletedData: [],
          loading: true,
          success: false,
          error: true,
        },
      };

    case GET_DELETE_DATA.success:
      return {
        ...state,
        deletedResult: {
          deletedData: action.payload,
          loading: false,
          success: true,
          error: true,
        },
      };

    case GET_DELETE_DATA.error:
      return {
        ...state,
        deletedResult: {
          deletedData: [],
          loading: false,
          success: false,
          error: true,
        },
      };

    case DELETE_RESTORE_DATA.pending:
      return {
        ...state,
        deletedResultInfo: {
          loading: true,
          success: false,
          error: true,
        },
      };

    case DELETE_RESTORE_DATA.success:
      return {
        ...state,
        deletedResultInfo: {
          loading: false,
          success: true,
          error: true,
        },
      };

    case DELETE_RESTORE_DATA.error:
      return {
        ...state,
        deletedResultInfo: {
          loading: false,
          success: false,
          error: true,
        },
      };

    default:
      return state;
  }
}
