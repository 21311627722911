// /* global navigator */
// import axios from "axios";
// import appConfig from "./AppConfig";
// // import { Auth } from "aws-amplify";

// const axiosInstance = axios.create({
//   headers: {
//     "Content-Type": "application/json",
//     Authorization: `Bearer eyJraWQiOiIxdGh6Y1wvdEliN3hiaHYwRVpaV3lwamtYTkIzQmZTcVNjSWM2WFloakMxST0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI4NTE0YjE1MC1kYWQ4LTRlMGMtOTkyMy03OWM4NmU4NjVlODMiLCJhdWQiOiIya2RzNDRqcnYxbzhnZGdvZmdtZGUxdmg5ciIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZXZlbnRfaWQiOiJlMTg3ZjAzYy02N2Q1LTRiM2QtYTYyNS1iYjNkZWFlNmM1NmQiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY3MzM1MDE2MiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfT3VyWUxEbk00IiwiY29nbml0bzp1c2VybmFtZSI6Ijg1MTRiMTUwLWRhZDgtNGUwYy05OTIzLTc5Yzg2ZTg2NWU4MyIsImV4cCI6MTY3MzQzNDM1OCwiaWF0IjoxNjczNDMwNzU4LCJlbWFpbCI6Imxva2VzaEB1bm9zaW1wbGUuY29tIn0.aiFBJJOk7-Y48ergspf860egbysfiKv9HEcmrhuBksL35ShhvQ6OD4XLgHLPjhQ4PMUMWnaFQ-WEKkTfCQnrCzyolVhGH9I4bMplE4EDvS-CMVES-Y5TQqdTjVVA_6_IJ2azAYuGVTbiN-towwsdHw7bmlXJHU1iGlN7ixUWMo8HHyMnyllPwLmJxELSHL1xvOwA2PhL92TJpCwRcAo-p6i2zQJ4AKQygWoDc1orpLoHna_arB-WEH1h2Ltmwaj1pQwPvdB5_9vrCS0kCmH4e7KBjaiscY8kuohF3P9Q4RYZpgnZz69rWXy5HO5JHX64lEsM_5tSCZ4oc068Kl9pSA`,
//     "Accept-Language": navigator.language,
//     // .split(/[-_]/)[0]
//   },
//   baseURL: `${appConfig.API_GATEWAY}/api/`,
//   withCredentials: true,
// });

// export const setHeaders = (token) => {
//   Object.assign(axiosInstance.defaults, { headers: { authorization: `Bearer ${token}` } });
// };

// export default axiosInstance;

import axios from "axios";
import appConfig from "./AppConfig";
import { Auth } from "aws-amplify";

// const { method, url, params, data, ...rest } = action.payload;

const headersData = async (m) => {
  let token;
  await Auth.currentSession()
    .then((response) => {
      token = response.idToken.jwtToken;
    })
    .catch((error) => {
      console.log(error);
      window.location.assign("/login");
    });

  let obj = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: m.data,
    method: m.method,
    url: `${appConfig.API_GATEWAY}/api/${m.url}`,
  };
  return obj;
};

// let dataObj = {
//   method: "get",
//   url: "https://api.dev.twistednail.app/api/companies",
//   data: {},
//   headers: {
//     Authorization:
//       "Bearer eyJraWQiOiIxdGh6Y1wvdEliN3hiaHYwRVpaV3lwamtYTkIzQmZTcVNjSWM2WFloakMxST0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI4NTE0YjE1MC1kYWQ4LTRlMGMtOTkyMy03OWM4NmU4NjVlODMiLCJhdWQiOiIya2RzNDRqcnYxbzhnZGdvZmdtZGUxdmg5ciIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZXZlbnRfaWQiOiJlMTg3ZjAzYy02N2Q1LTRiM2QtYTYyNS1iYjNkZWFlNmM1NmQiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY3MzM1MDE2MiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfT3VyWUxEbk00IiwiY29nbml0bzp1c2VybmFtZSI6Ijg1MTRiMTUwLWRhZDgtNGUwYy05OTIzLTc5Yzg2ZTg2NWU4MyIsImV4cCI6MTY3MzQzOTM2MSwiaWF0IjoxNjczNDM1NzYxLCJlbWFpbCI6Imxva2VzaEB1bm9zaW1wbGUuY29tIn0.OKCZGjIfesvRlJK51AZGRaNNQQ09DAB4GV9qGp2VqOL1dlyoFUUWdGEPDLyRSv0kJUc2FLDiHKAu1w061gexrZ4JEOz_Ig3B0UbReE8ZAYev9qUorp7hVMK8bDYUPVL5jvIgELWSPNN7uTcGyOZl9uGfKHIrqUmNeibZUZEA6TUaPow46Da_KqpofxofAqa2iDSANpbT_XXcL85CEErTu1pInIT5Oyosi71LvTIxRAOgCC9CfmI4F1PbNemYg89H4wIBFI1t3dxA3ejUTB370XlBeibnSkkigko1mATcvj6MNFd9LEorULlWT_3GP6f-ftFrOouC8fw2OMHDSuO7hw",
//   },
// };
const axiosInstance = async (method) => {
  return await new Promise((resolve) => {
    axios.create(
      headersData(method).then((response) => {
        resolve(response);
      })
    );
  });
};

// const axiosInstance = () => {
//   return new Promise((resolve) => {
//     axios(
//       headersData().then((response) => {
//         resolve(response);
//       })
//     )
//       .then((response) => {
//         console.log(response, "responseData");
//         resolve(response);
//       })
//       .catch(async (error) => {
//         // addError(error);
//         console.log(error, "console error", error.response);
//         if (error.response && error.response.status === 401) {
//           // window.location.assign("/login");
//           // alert("Your session has expired.Please login!");
//         } else if (error.response && error.response.status === 400) {
//           // window.location.assign("/pageNotFound/newtWork");
//           // alert("Something went wrong... Please try again...");
//         } else if (error.response && error.response.status === 502) {
//           // window.location.assign("/pageNotFound/newtWork");
//           // alert("Something went wrong... Please try again...");
//         }

//         if (error && !error.response) {
//           // window.location.assign("/pageNotFound/newtWork");
//           // alert("Please check your network and try again");
//           console.log(error);
//         }
//       });
//   });
// };

// export const setHeaders = (token) => {
//   Object.assign(axiosInstance.defaults, {
//     headers: { authorization: `Bearer ${token}` },
//   });
// };

export default axiosInstance;
