import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Spin,
  Upload,
  message,
  Popover,
} from "antd";
import React, { Component } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
// import NumberFormat from "react-number-format";
import { withRouter } from "react-router-dom";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import TextArea from "antd/lib/input/TextArea";
import Title from "antd/lib/typography/Title";
import ImageCrop from "antd-img-crop";
import profileAvatar from "../../Images/profileAvatar.png";
import { Auth, Storage } from "aws-amplify";
import { update } from "../../api/Apis";
import AppConfig from "../../config/AppConfig";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Loading from "../../Common/Loading";
export class Profilesetting extends Component {
  formRef = React.createRef();
  state = {
    image2Loading: false,
    profileImage2: "",
    companyAddress: "",
    loading: false,
  };
  componentDidMount() {
    // document.title = document.URL;
    document.title = "Twisted Nail - Profile Setting";
  }

  getAddress = (address, latLng) => {
    this.setState({ selectedAddress: address.label });
  };

  content = () => {
    return (
      <div>
        <p>-Minimum password length: 8</p>{" "}
        <p>-Required at least one uppercase letter</p>{" "}
        <p>-Required at least one lowercase letter</p>{" "}
        <p>-Required at least one number</p>{" "}
        <p>-Required at least one nonalphanumeric character</p>{" "}
      </div>
    );
  };

  handleSelect = (address) => {
    const companyAddress = [];
    companyAddress.push(address.label);
    this.setState({
      companyAddress,
    });
    let cityValue = "";
    let stateValue = "";
    let zipCodeValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        const geoIdData = results[0];
        this.setState({
          geoIdData,
        });
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
          if (address[i].types[0] === "postal_code") {
            zipCodeValue = address[i].long_name;
          }
        }
        this.setState({
          city: cityValue,
          addressState: stateValue,
          zipCode: zipCodeValue,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  handleCroppedImage2 = async (crop) => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.setState({
      image2Loading: true,
    });
    const Images = [];
    let image = "";
    Storage.put(crop.uid, crop, {
      contentType: crop.type,
    }).then(async (result) => {
      Images.push(result.key);
      image =
        `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
        result.key;

      if (image && Images) {
        this.setState({
          profileImage2: image,
        });
        this.savePic(userDetails, image);
      }
    });
  };

  savePic = (userDetails, image) => {
    let data = {
      entityData: {
        profileImage: image,
      },
    };
    update(AppConfig["entityNames"]["users"], userDetails.id, data).then(
      (result) => {
        if (result.success) {
          this.setState({
            image2Loading: false,
          });
          localStorage.setItem("userDetails", JSON.stringify(result.data.data));
          message.success("Your profile picture updated successfully");
          window.location.reload();
        } else {
          this.setState({
            image2Loading: false,
          });
          message.error(result.errors);
          console.log(result.errors);
        }
      }
    );
  };

  onFinish = (values) => {
    this.setState({
      loading: true,
    });
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const { companyAddress } = this.state;
    values["address"] =
      companyAddress !== "" ? companyAddress : userDetails.address;
    let data = {
      entityData: values,
    };

    update(AppConfig["entityNames"]["users"], userDetails.id, data).then(
      (result) => {
        if (result.success) {
          this.setState({
            loading: false,
          });
          localStorage.setItem("userDetails", JSON.stringify(result.data.data));
        } else {
          this.setState({
            loading: false,
          });
          console.log(result.errors);
        }
      }
    );
  };
  onClickReturn = () => {
    this.props.history.goBack();
  };

  updatePassword = (e) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, e.oldPassword, e.newPassword);
      })
      .then((data) => {
        if (data === "SUCCESS") {
          // console.log(data);
          message.success("Your password updated successfully");
          this.formRef.current.setFieldsValue({
            oldPassword: "",
            newPassword: "",
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        //  if (err.includes("LimitExceededException: Attempt limit exceeded, please try after some time")) {
        //   message.info(
        //     "Attempt limit exceeded, please try after some time"
        //   );
        // }else {
        message.error("Your Old Password Is Incorrect");
        // }
      });
  };

  render() {
    const {
      // profileImage2,
      image2Loading,
      loading,
    } = this.state;
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    // console.log(profileImage2, "profileImage2");
    // console.log(userDetails, "userDetails");
    return (
      <>
        {userDetails ? (
          <div
            style={{ backgroundColor: "rgba(250,250,250,1)" }}
            className="profilesetting"
          >
            <Loading enableLoading={loading} />
            <Row
              style={{
                display: "flex",
                maxWidth: "max-content",
                cursor: "pointer",
              }}
              onClick={this.onClickReturn}
            >
              <Col
                style={{
                  color: "#808080",
                  fontSize: "22px",
                  lineHeight: "0px",
                }}
              >
                <BsArrowLeftShort className="back-button-return-icon" />
              </Col>
              <Col>
                <p
                  style={{
                    color: "#808080",
                    fontSize: "14px",
                    margin: "0px",
                  }}
                  className="back-button-return"
                >
                  Return
                </p>
              </Col>
            </Row>

            <div
              style={{
                padding: "10px",
                margin: "0px auto",
                maxWidth: "1400px",
              }}
            >
              <Card>
                <Row gutter={[20, 0]}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 5 }}
                    xl={{ span: 5 }}
                    xxl={{ span: 5 }}
                  >
                    <Title
                      level={4}
                      style={{
                        marginBottom: "20px",
                        marginTop: "0px",
                        float: "left",
                      }}
                    >
                      Profile Image
                    </Title>
                    <Spin
                      spinning={image2Loading}
                      size="large"
                      className="center-item"
                      style={{ marginTop: "10%" }}
                    >
                      <div className="uploadimg_grid">
                        <img
                          src={
                            userDetails?.profileImage
                              ? userDetails?.profileImage
                              : profileAvatar
                          }
                          alt="profile"
                          className="img-profile"
                          style={{ width: "100%", height: "100%" }}
                        />
                        <Button
                          htmlType="submit"
                          type="primary"
                          className="update-profile-btn"
                        >
                          <ImageCrop
                            rotate={true}
                            onModalOk={this.handleCroppedImage2}
                            modalOk="Save"
                          >
                            <Upload showUploadList={false}>
                              <span className="Btn-name">Upload Image</span>
                            </Upload>
                          </ImageCrop>
                        </Button>
                      </div>
                    </Spin>
                  </Col>
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    lg={{ span: 18, offset: 1 }}
                    xl={{ span: 18, offset: 1 }}
                    xxl={{ span: 18, offset: 1 }}
                  >
                    <Form
                      layout="vertical"
                      id="profilesett"
                      onFinish={this.onFinish}
                    >
                      <Row gutter={[40, 0]}>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}
                          xl={{ span: 24 }}
                          xxl={{ span: 24 }}
                        >
                          <Title
                            level={4}
                            style={{
                              marginBottom: "20px",
                              marginTop: "0px",
                              float: "left",
                            }}
                          >
                            Profile Details
                          </Title>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                          xl={{ span: 12 }}
                          xxl={{ span: 12 }}
                        >
                          <Form.Item
                            label="First Name"
                            name="firstName"
                            rules={[
                              {
                                required: true,
                                message: "Please input your firstname!",
                              },
                            ]}
                            initialValue={userDetails?.firstName}
                          >
                            <Input
                              className="form-input"
                              placeholder="Please input your firstname"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                          xl={{ span: 12 }}
                          xxl={{ span: 12 }}
                        >
                          <Form.Item
                            label="Last Name"
                            name="lastName"
                            rules={[
                              {
                                required: true,
                                message: "Please input your lastname!",
                              },
                            ]}
                            initialValue={userDetails?.lastName}
                          >
                            <Input
                              className="form-input"
                              placeholder="Please input your lastname"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          // span={12}
                          xxl={{ span: 12, offset: 0 }}
                          xl={{ span: 12, offset: 0 }}
                          lg={{ span: 12, offset: 0 }}
                          md={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          xs={{ span: 24, offset: 0 }}
                        >
                          <span
                            className="title_changes"
                            style={{ display: "flex" }}
                          >
                            Phone
                          </span>
                          <Form.Item
                            name="phone"
                            rules={[
                              {
                                required: true,
                                message: "Please input your Phone Number!",
                              },
                            ]}
                            initialValue={userDetails?.phone}
                          >
                            <ReactPhoneInput
                              inputExtraProps={{
                                name: "contactMainPhone",
                                required: true,
                                // autoFocus: true,
                              }}
                              style={{ marginTop: "9px" }}
                              placeholder=""
                              country="us"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                          xl={{ span: 12 }}
                          xxl={{ span: 12 }}
                        >
                          <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: "Please input your Email Address!",
                              },
                            ]}
                            initialValue={userDetails?.email}
                          >
                            <Input
                              className="form-input"
                              placeholder="Please input your Email"
                              readOnly="true"
                            />
                          </Form.Item>
                        </Col>

                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                          xl={{ span: 12 }}
                          xxl={{ span: 12 }}
                        >
                          <Form.Item
                            label="Company Name"
                            name="company"
                            rules={[
                              {
                                required: true,
                                message: "Please input your Company Name!",
                              },
                            ]}
                            initialValue={userDetails?.company}
                          >
                            <Input
                              className="form-input"
                              placeholder="Please input your company"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                          xl={{ span: 12 }}
                          xxl={{ span: 12 }}
                        >
                          <Form.Item
                            label="Company Address"
                            name="address"
                            rules={[
                              {
                                required: false,
                                message: "Please input your Company Address!",
                              },
                            ]}
                          >
                            <GooglePlacesAutocomplete
                              className="form-input"
                              autocompletionRequest={{
                                componentRestrictions: {
                                  country: ["us"],
                                },
                              }}
                              selectProps={{
                                defaultInputValue: `${
                                  userDetails?.address
                                    ? userDetails?.address
                                    : ""
                                }`,
                                placeholder: "Search Address...",
                                onChange: (address) => {
                                  this.handleSelect(address);
                                  this.getAddress(address);
                                },
                              }}
                            />
                          </Form.Item>
                        </Col>
                        {/* <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                          xl={{ span: 12 }}
                          xxl={{ span: 12 }}
                        >
                          <Form.Item
                            label="Mobile Number"
                            name="mobile"
                            rules={[
                              {
                                required: true,
                                message: "Please input your Mobile Number!",
                              },
                            ]}
                            initialValue={userDetails?.mobile}
                          >
                            <NumberFormat
                              className="ant-input-number form-input numberictest"
                              format="(###) ###-####"
                              style={{ width: "100%" }}
                              placeholder="Please Enter Your Mobile"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                          xl={{ span: 12 }}
                          xxl={{ span: 12 }}
                        >
                          <Form.Item
                            label="Website"
                            name="website"
                            rules={[
                              {
                                required: false,
                                message: "Please input your Website!",
                              },
                            ]}
                            initialValue={userDetails?.website}
                          >
                            <Input
                              className="form-input"
                              placeholder="Please input your website"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                          xl={{ span: 12 }}
                          xxl={{ span: 12 }}
                        >
                          <Form.Item
                            label="Title or Position"
                            name="titleposition"
                            rules={[
                              {
                                required: false,
                                message: "Please input your Title or Position!",
                              },
                            ]}
                            initialValue={userDetails?.titleposition}
                          >
                            <Input
                              className="form-input"
                              placeholder="Please input your Title or Position"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                          xl={{ span: 12 }}
                          xxl={{ span: 12 }}
                        >
                          <Form.Item
                            label="WhatsApp"
                            name="whatsapp"
                            rules={[
                              {
                                required: true,
                                message: "Please input your WhatApps!",
                              },
                            ]}
                            initialValue={userDetails?.whatsapp}
                          >
                            <NumberFormat
                              className="ant-input-number form-input numberictest"
                              format="(###) ###-####"
                              style={{ width: "100%" }}
                              placeholder="Please input your WhatApps"
                            />
                          </Form.Item>
                        </Col> */}

                        <Col span={24}>
                          <Form.Item
                            label="About Me"
                            name="aboutme"
                            rules={[
                              {
                                required: false,
                                message: "what your About !",
                              },
                            ]}
                            initialValue={userDetails?.aboutme}
                          >
                            <TextArea
                              placeholder="what your About"
                              className="form-input"
                              style={{ minHeight: "100px", resize: "none" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Button
                            htmlType="submit"
                            type="primary"
                            style={{
                              widtd: "100%%",
                              marginRight: "auto",
                              display: "flex",
                              justifyContent: "center",
                            }}
                            form="profilesett"
                          >
                            Update Profile
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </Card>

              {/* <Card style={{ margin: "20px 0px" }}>
                <Row gutter={[20, 0]}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                    xl={{ span: 5 }}
                    xxl={{ span: 5 }}
                  >
                    <h1
                      style={{
                        margin: "20px 0px",
                        fontSize: "20px",
                        fontWeight: "600",
                        textTransform: "capitalize",
                        color: "#000",
                      }}
                    >
                      Social Media
                    </h1>
                  </Col>
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    lg={{ span: 24, offset: 0 }}
                    xl={{ span: 18, offset: 1 }}
                    xxl={{ span: 18, offset: 1 }}
                  >
                    <Form id="socialmedia" layout="vertical">
                      <Row gutter={[40, 0]}>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                          xl={{ span: 12 }}
                          xxl={{ span: 12 }}
                        >
                          <Form.Item
                            label="Facebook"
                            name="facebook"
                            rules={[
                              {
                                required: true,
                                message: "Please input your Facebook!",
                              },
                            ]}
                            initialValue="https://www.facebook.com/public/Anthony-Castellanos"
                          >
                            <Input
                              className="form-input"
                              placeholder="Please input your Facebook"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                          xl={{ span: 12 }}
                          xxl={{ span: 12 }}
                        >
                          <Form.Item
                            label="Twitter"
                            name="twitter"
                            rules={[
                              {
                                required: true,
                                message: "Please input your Twitter!",
                              },
                            ]}
                          >
                            <Input
                              className="form-input"
                              placeholder="Please input your Twitter"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                          xl={{ span: 12 }}
                          xxl={{ span: 12 }}
                        >
                          <Form.Item
                            label="LinkedIn"
                            name="linkedIn"
                            rules={[
                              {
                                required: true,
                                message: "Please input your LinkedIn!",
                              },
                            ]}
                          >
                            <Input
                              className="form-input"
                              placeholder="Please input your LinkedIn"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                          xl={{ span: 12 }}
                          xxl={{ span: 12 }}
                        >
                          <Form.Item
                            label="Instagram"
                            name="instagram"
                            rules={[
                              {
                                required: true,
                                message: "Please input your Instagram!",
                              },
                            ]}
                          >
                            <Input
                              className="form-input"
                              placeholder="Please input your Instagram"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          // span={24}
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}
                          xl={{ span: 24 }}
                          xxl={{ span: 24 }}
                        >
                          <Button
                            htmlType="submit"
                            type="primary"
                            style={{
                              widtd: "100%%",
                              marginLeft: "auto",
                              display: "flex",
                              justifyContent: "center",
                            }}
                            form="socialmedia"
                          >
                            Update Link
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </Card> */}
              <Card style={{ margin: "20px 0px" }}>
                <Row gutter={[20, 0]}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                    xl={{ span: 5 }}
                    xxl={{ span: 5 }}
                  >
                    <h1
                      style={{
                        margin: "20px 0px",
                        fontSize: "20px",
                        fontWeight: "600",
                        textTransform: "capitalize",
                        color: "#000",
                      }}
                    >
                      Change Password
                    </h1>
                  </Col>
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    lg={{ span: 24, offset: 0 }}
                    xl={{ span: 18, offset: 1 }}
                    xxl={{ span: 18, offset: 1 }}
                  >
                    <Form
                      id="changepassowrd"
                      layout="vertical"
                      ref={this.formRef}
                      onFinish={this.updatePassword}
                      style={{ margin: "20px 0" }}
                    >
                      <Row gutter={[40, 0]}>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                          xl={{ span: 12 }}
                          xxl={{ span: 12 }}
                        >
                          <Form.Item
                            name="oldPassword"
                            rules={[
                              {
                                required: true,
                                // pattern:
                                //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])(?=.*[A-Z])(?=.*[a-z])[A-Za-z\d@$!%*#?&]{8,}$/,
                                // message: "Password Doesn't Match",
                              },
                            ]}
                            hasFeedback
                            style={{ marginBottom: "0px" }}
                          >
                            <Input.Password
                              placeholder="Old Password"
                              autoComplete="new-password"
                              className="form-input"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                          xl={{ span: 12 }}
                          xxl={{ span: 12 }}
                        >
                          <Form.Item
                            name="newPassword"
                            rules={[
                              {
                                required: true,
                                pattern:
                                  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])(?=.*[A-Z])(?=.*[a-z])[A-Za-z\d@$!%*#?&]{8,}$/,
                                message: "Password Critieria Doesn't Match",
                              },
                            ]}
                            hasFeedback
                            style={{ marginBottom: "0px" }}
                          >
                            <Input.Password
                              placeholder="New Password"
                              autoComplete="new-password"
                              className="form-input"
                            />
                          </Form.Item>
                          <Popover
                            content={this.content}
                            title="Password Criteria"
                          >
                            <Button
                              className="criteria"
                              type="text"
                              style={{
                                color: "#39acda",
                                marginLeft: "-15px",
                                background: "transparent",
                                border: "none",
                                float: "left",
                              }}
                            >
                              Password Criteria{" "}
                            </Button>
                          </Popover>
                        </Col>

                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}
                          xl={{ span: 24 }}
                          xxl={{ span: 24 }}
                          style={{ marginTop: "1vw" }}
                        >
                          <Button
                            htmlType="submit"
                            type="primary"
                            style={{
                              // width: "100%",
                              marginLeft: "auto",
                              display: "flex",
                              justifyContent: "center",
                              float: "left",
                            }}
                            form="changepassowrd"
                          >
                            Update Password
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </Card>
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default withRouter(Profilesetting);
