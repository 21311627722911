import PropTypes from "prop-types";
import { Drawer } from "antd";

const BaseDrawer = (props) => {
  return (
    <div>
      <Drawer
        title={props.title}
        onClose={props.onClose}
        visible={props.visible}
        footer={props.footer}
        maskClosable={false}
        // className="drawerbox"
        className={props.className}
      >
        {props.children}
      </Drawer>
    </div>
  );
};

BaseDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  destroyOnClose: PropTypes.bool,
  width: PropTypes.string,
};
BaseDrawer.defaultProps = {
  placement: "right",
  // className: "drawerbox",
  destroyOnClose: true,
};
// #endregion

export default BaseDrawer;
