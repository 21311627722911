import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Button,
  Tabs,
  Form,
  Input,
  DatePicker,
  Select,
  message,
  Popover,
  InputNumber,
  Radio,
  Popconfirm,
  Checkbox,
  Spin,
} from "antd";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import {
  PlusOutlined,
  EditOutlined,
  EllipsisOutlined,
  ContactsOutlined,
  InboxOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
import funnelStages from "../../../Json/funnel.json";
import NumberFormat from "react-number-format";
import { MdModeEditOutline, MdDelete } from "react-icons/md";
import { BsArrowLeftShort } from "react-icons/bs";
import { HiOutlineDocumentArrowDown } from "react-icons/hi2";
import moment from "moment";
import { add } from "../../../api/Apis";
import appConfig from "../../../config/AppConfig";
import Loading from "../../../Common/Loading";
import ErrorView from "../../../Common/ErrorView";
import { withAppContext } from "../../../Store/AppContext";
import BaseModal from "../../../Common/BaseModal";
import BaseTable from "../../../Common/BaseTable";
import TextArea from "antd/lib/input/TextArea";
import MapComponent from "../../Maps/MapComponent";
import { v4 as uuidv4 } from "uuid";
import AOS from "aos";
import "aos/dist/aos.css";
import { Storage } from "aws-amplify";
import Dragger from "antd/lib/upload/Dragger";
import { connect } from "react-redux";
import {
  addDocument,
  addTrucksList,
  deleteDocument,
  getCompany,
  getDropDownOptions,
  getOwnerOperators,
  getTrucksList,
  updateCompany,
  updateDocument,
  updateDropDownOptions,
} from "../../../Redux/Actions/actions";

const { TabPane } = Tabs;

const { Option } = Select;

const stageConditions = {
  "Uncontacted Lead": [
    "Pending Carrier Packet + Insurance",
    "Archived Carrier",
    "Stalled Application",
  ],
  "Pending Carrier Packet + Insurance": [
    "Uncontacted Lead",
    "Pending Insurance",
    "Pending Carrier Packet",
    "Archived Carrier",
    "Carrier Data Input",
    "Stalled Application",
  ],
  "Pending Insurance": [
    "Uncontacted Lead",
    "Pending Carrier Packet + Insurance",
    "Carrier Data Input",
    "Archived Carrier",
    "Stalled Application",
  ],
  "Pending Carrier Packet": [
    "Uncontacted Lead",
    "Pending Carrier Packet + Insurance",
    "Pending Insurance",
    "Carrier Data Input",
    "Archived Carrier",
    "Stalled Application",
  ],
  "Carrier Data Input": [
    "Uncontacted Lead",
    "Pending Carrier Packet + Insurance",
    "Pending Insurance",
    "Pending Carrier Packet",
    "Welcome Call",
    "Archived Carrier",
    "Stalled Application",
  ],
  "Welcome Call": [
    "Uncontacted Lead",
    "Pending Carrier Packet + Insurance",
    "Pending Insurance",
    "Pending Carrier Packet",
    "Carrier Data Input",
    "Approved Carrier",
    "Archived Carrier",
    "Stalled Application",
  ],
  "Approved Carrier": [],
  "Archived Carrier": [
    "Pending Carrier Packet + Insurance",
    "Stalled Application",
  ],
  "Stalled Application": ["Pending Carrier Packet + Insurance"],
};

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

const optionsInfo = [
  { label: "Insurance", value: "Insurance" },
  { label: "Carrier Packet", value: "Carrier Packet" },
];
class OwnerOperatorProfile extends Component {
  formRef = React.createRef();
  state = {
    datePicker: "",
    totalLeadsData: [],
    companyData: [],
    contactsData: [],
    invoicesDetails: [],
    quotesDetails: [],
    documentsDetails: [],
    latitude: "",
    longitude: "",
    quarryData: [],
    funnelData: [],
    keyValue: "1",
    dropAddress: "",
    pickUpAddress: "",
    leadVisible: false,
    selectedAddress: "",
    contactPerson: "",
    companyId: "",
    contactId: "",
    jobContactData: "",
    isContactModalVisible: false,
    jobData: "",
    newRole: "",
    trucksData: [],
    jobEditModal: false,
    isCheckBox: false,
    roleCheckBox: false,
    selectedTruckType: [],
    recordMaterialData: "",
    isContactVisible: false,
    activeClasses: false,
    materialPopup: false,
    dropoffLatitude: "",
    dropoffLongitude: "",
    pickUpLatitude: "",
    pickupLongitude: "",
    selectedTerm: "",
    addTruck: false,
    truckId: "",
    localTruckId: "",
    selectedTruckCode: "",
    isCheckcontact: false,
    masterRoleId: "",
    selectedPaymentType: "",
    // selectedRowKeys: "",
    notesData: [],
    notesPopup: false,
    recordNotesData: "",
    overWeightPermitState: "",
    imageLoading: false,
    quoteAttachment: "",
    quoteAttachmentId: "",
    fileType: "",
    carrierName: "",
    equipmentTypeDetails: [],
    recordTruckData: "",
    isDocModalVisible: false,
    ownerRoles: [],
    equipmentAttachment: "",
    paymentTerms: [],
    payment_discount: "",
    documentTypeVal: "",
    docInsuranceInfo: [],
    updateStageData: false,
    insuranceDate: "",
    removeInsurance: false,
  };

  componentDidMount() {
    this.props.actionFetchOwnerOperators();
    this.props.actionFetchCompany(this.props.match.params.id);
    this.props.actionFetchTrucksList();
    this.props?.context?.updateCompanyId(this.props.match.params.id);
    document.title = "Twisted Nail - Company Details";
    this.props.actiongetDropDownOptions();
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
  }

  componentDidUpdate(newProps) {
    if (newProps?.match.params.id !== this.props?.match?.params?.id) {
      this.props?.actionFetchCompany(this.props?.match?.params?.id);
      this.props?.context?.updateCompanyId(this.props?.match?.params?.id);
    }
    if (
      newProps?.totalResult?.success === false &&
      this.props?.totalResult?.success === true
    ) {
      this.props.actionFetchTrucksList();
      this.props.actionFetchOwnerOperators();
      this.funnelInfo(this.props?.result);
      let apContactData = this.props?.result?.contactDetails?.filter(
        (e) => e?.contactRole && e?.contactRole === "Accounts Payable"
      );
      const docInsuranceInfo = this.props?.result?.documentsDetails?.filter(
        (e) =>
          e.documentTypeVal === "Insurance" ||
          e.documentTypeVal === "Carrier Packet"
      );
      const { updateStageData, insuranceDate, removeInsurance } = this.state;
      if (updateStageData) {
        if (docInsuranceInfo?.length === 2) {
          this.onClickStageId(
            "Carrier Data Input",
            insuranceDate !== "" ? moment(insuranceDate).format("L") : ""
          );
        } else if (docInsuranceInfo?.length === 1) {
          if (docInsuranceInfo[0]?.documentTypeVal === "Insurance") {
            this.onClickStageId(
              "Pending Carrier Packet",
              insuranceDate !== "" ? moment(insuranceDate).format("L") : ""
            );
          } else if (
            docInsuranceInfo[0]?.documentTypeVal === "Carrier Packet"
          ) {
            this.onClickStageId("Pending Insurance", removeInsurance && "");
          }
        } else if (docInsuranceInfo?.length === 0) {
          this.onClickStageId("Uncontacted Lead", removeInsurance && "");
        }
        this.setState({
          removeInsurance: false,
          updateStageData: false,
        });
      }

      this.setState({
        companyData: this.props?.result,
        selectedAddress: this.props?.result.address,
        documentsDetails: this.props?.result.documentsDetails,
        docInsuranceInfo,
        invoicesDetails: this.props?.result.invoiceDetails,
        apContactData,
        // quotesDetails: result.quotesDetails,
        quarryData: [this.props?.result],
        equipmentTypeDetails: this.props?.result.carrierDetails,
        selectedStatus: this.props?.result.stageId,
        contactsData: this.props?.result.contactDetails,
        notesData: this.props?.result.notesDetails,
      });
    }
    if (
      newProps?.companiesResult?.success === false &&
      this.props?.companiesResult?.success === true
    ) {
      let data = [];
      this.props?.companiesResult?.ownersData?.forEach((e) => {
        if (e?.isOwnerOperator && e?.carrierDetails?.length > 0) {
          e?.carrierDetails?.forEach((c) => {
            if (c?.truckId !== null && c?.truckId !== undefined) {
              data.push(parseInt(c?.truckId));
            }
          });
        }
      });
      let res = data?.length > 0 ? data.flat() : [0];
      let truckIdLocal = Math.max(...res);
      this.setState({
        localTruckId: truckIdLocal,
      });
    }
    if (
      newProps?.companiesInfo?.success === false &&
      this.props?.companiesInfo?.success === true
    ) {
      this.setState({
        addTruck: false,
        selectedTerm: "",
        selectedTruckCode: "",
        selectedAddress: "",
        latitude: "",
        longitude: "",
        selectedPaymentType: "",
        quoteAttachmentId: "",
        quoteAttachment: "",
        overWeightPermitState: "",
        newRole: "",
        isCheckcontact: false,
        carrierName: "",
        recordTruckData: "",
      });
      this.props?.actionFetchCompany(this.props?.match.params.id);
    }

    if (
      newProps?.docResult?.success === false &&
      this.props?.docResult?.success === true
    ) {
      this.setState({
        quoteAttachment: "",
        quoteAttachmentId: "",
        updateStageData: true,
      });
      this.props?.actionFetchCompany(this.props?.match.params.id);
    }
    if (
      newProps?.totalTrucksListResult?.success === false &&
      this.props?.totalTrucksListResult?.success === true
    ) {
      let data = [];
      this.props?.totalTrucksListResult?.trucksListData?.forEach((e) => {
        if (e?.carrierDetails?.length > 0) {
          e?.carrierDetails?.forEach((c) => {
            if (c?.truckId !== null && c?.truckId !== undefined) {
              data.push(parseInt(c?.truckId));
            }
          });
        }
      });
      let res = data?.length > 0 ? data.flat() : [100];
      let truckIdLocal = Math.max(...res);
      this.setState({
        loading: false,
        truckId: truckIdLocal,
      });
    }
    if (
      newProps?.trucksListInfo?.success === false &&
      this.props?.trucksListInfo?.success === true
    ) {
      this.props?.actionFetchTrucksList();
    }
    if (
      newProps?.dropDownOptions?.success === false &&
      this.props?.dropDownOptions?.success === true
    ) {
      this.setState({
        ownerRoles: this.props?.ownerRoles[0]?.ownerRolesData?.sort((a, b) =>
          a?.value > b?.value ? 1 : -1
        ),
        trucksData: this.props?.trucksData[0]?.trucksData,
        paymentTerms: this.props?.termsData[0]?.termsData,
      });
    }
    if (
      newProps?.dropDownOptionsInfo?.success === false &&
      this.props?.dropDownOptionsInfo?.success === true
    ) {
      this.props?.actiongetDropDownOptions();
    }
  }

  static getDerivedStateFromProps(props, state) {
    // console.log("--------getDerivedStateFromProps------------");
    if (
      JSON.stringify(props?.ownerRoles[0]?.ownerRolesData) !==
      JSON.stringify(state?.ownerRoles)
    ) {
      return {
        ownerRoles: props.ownerRoles[0]?.ownerRolesData?.sort((a, b) =>
          a?.value > b?.value ? 1 : -1
        ),
        trucksData: props.trucksData[0]?.trucksData,
      };
    }
  }

  onRefresh = () => {
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    this.props.actionFetchCompany(id);
    this.props.actionFetchOwnerOperators();
    this.props?.context?.updateCompanyId(id);
    this.props.actionFetchTrucksList();
    this.props.actiongetDropDownOptions();
  };

  funnelInfo = (data) => {
    let funnel = [];
    const funnelId = "2";
    funnelStages.stages.map(
      (eachItem) =>
        parseInt(funnelId) === parseInt(eachItem.funnelId) &&
        eachItem.stage.map((stage) => {
          funnel.push(stage.content);
          this.setState({
            funnelData: funnel,
          });
          return true;
        })
    );
  };

  getAddress = (address) => {
    // console.log(address);
    this.setState({ selectedAddress: address.label });
  };

  handleSelect = (address) => {
    const citySearch = [];
    citySearch.push(address.label);
    this.setState({
      citySearch,
    });
    let cityValue = "";
    let stateValue = "";
    let zipCodeValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        getLatLng(results[0]).then(({ lat, lng }) => {
          this.setState({ latitude: lat, longitude: lng });
        });
        const geoIdData = results[0];
        this.setState({
          geoIdData,
        });
        let address = results[0].address_components;
        for (let i = 0; i < address?.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
          if (address[i].types[0] === "postal_code") {
            zipCodeValue = address[i].long_name;
          }
        }
        this.setState({
          city: cityValue,
          addressState: stateValue,
          zipCode: zipCodeValue,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  showModal = (key) => {
    const { companyData } = this.state;
    if (key === "1") {
      this.setState({
        addTruck: true,
        recordTruckData: "",
        equipmentTypeDetails: companyData?.carrierDetails,
        quoteAttachmentId: "",
        equipmentAttachment: "",
        overWeightPermitState: "No",
        latitude: companyData?.lat,
        longitude: companyData?.lng,
      });
    } else if (key === "2") {
      this.setState({ isContactModalVisible: true, jobContactData: "" });
    } else if (key === "3") {
      this.setState({
        isDocModalVisible: true,
        recordDocData: "",
        documentTypeVal: "",
      });
    } else if (key === "4") {
      this.setState({ notesPopup: true, recordNotesData: "" });
    }
  };

  onFinishCompanyDetails = (values) => {
    const {
      selectedAddress,
      latitude,
      longitude,
      carrierName,
      equipmentTypeDetails,
      payment_discount,
      city,
      addressState,
      selectedStatus,
    } = this.state;
    const data = {
      entityData: {
        carrier_name: values["carrier_name"],
        carrier_status: values["carrier_status"],
        company: values["carrier_name"],
        address: selectedAddress,
        cityName: city,
        stateName: addressState,
        usdot: values["usdot"],
        payment_terms:
          // values["payment_terms"] === "Custom Input"
          //   ? values["paymentTermsOthers"]
          //   :
          values["payment_terms"],
        payment_discount: payment_discount,

        // insurance_expiry:
        //   values["insuranceDate"] === null
        //     ? ""
        //     : values["insuranceDate"] === ""
        //     ? ""
        //     : moment(values["insuranceDate"]).format("L"),
        direct_deposit: values["direct_deposit"],
        phone: values["phone"],
        email: values["email"],
        lat: latitude,
        lng: longitude,
        notes: values["notes"],
        registered_since: moment(values["registered_since"]).format("l"),
      },
    };
    // console.log(data);
    if (carrierName !== "" && carrierName !== undefined) {
      equipmentTypeDetails?.map((t) => (t["carrier_name"] = carrierName));
      data["entityData"]["carrierDetails"] = equipmentTypeDetails;
    }
    this.setState({
      isCompanyModalVisible: false,
    });
    if (
      selectedStatus === "Update Needed" &&
      moment(data["entityData"]["insurance_expiry"]).isAfter(
        moment().add(29, "days")
      )
    ) {
      this.addToMasterData(data, data["entityData"]["insurance_expiry"]);
      data["entityData"]["stageId"] = "Approved Carrier";
    }
    this.updateCompanyDetails(data);
  };

  updateCompanyDetails = (data) => {
    this.props.actionUpdateCompany(this.props?.match?.params?.id, data);
    if (data["entityData"]["stageId"] === "Approved Carrier") {
      message.success("Carrier Moved To Owner Operator Database");
      this.props.history.push(
        `/app/twisted-nail/carrier-profile/${this.props?.match?.params?.id}`
      );
    }
  };

  onClickReturn = () => {
    this.props.history.goBack();
  };

  deleteTruckDetails = (id) => {
    let truckInfo = this.state.equipmentTypeDetails?.filter((c) => c.id !== id);
    // console.log(truckInfo);
    message.info("Truck Details Deleted Successfully!");
    let finalData = {
      entityData: {
        carrierDetails: truckInfo,
      },
    };
    this.updateCompanyDetails(finalData);
    this.setState({
      equipmentTypeDetails: truckInfo,
    });
  };

  onFinishContact = (values) => {
    const { jobContactData, contactInfo } = this.state;

    if (jobContactData !== "") {
      let filteredData = this.state.contactsData.filter(
        (c) => c.id !== jobContactData?.id
      );
      this.setState({
        contactsData: filteredData,
      });
    }

    let data = {
      entityData: {
        id: jobContactData?.id !== undefined ? jobContactData?.id : uuidv4(),
        carrierId: this.props?.match?.params?.id,
        companyId: this.props?.match?.params?.id,
        contact_name: values?.fName + " " + values?.lName,
        firstName: values.fName,
        lastName: values.lName,
        contact_number: values.phone,
        email: values.email,
        contactRole:
          values["role"] === "Add New Role"
            ? values["roleOther"]
            : values["role"],
        notes: values.notes,
        company: "",
        isPrimaryContact: jobContactData?.isPrimaryContact,
      },
    };

    if (contactInfo?.length === 0) {
      data["entityData"]["isPrimaryContact"] = true;
    }

    let contactData = this.state.contactsData?.filter(
      (c) => c?.email === values?.email && c?.contact_number === values?.phone
    );

    if (contactData?.length > 0) {
      message.info("Given Email and Phone Number already exists");
      this.setState({
        loading: false,
      });
      return true;
    } else {
      this.setState({
        isContactModalVisible: false,
      });
      if (this.state.isCheckcontact) {
        this.updateOwnerRolesData(values["roleOther"]);
      }
      add(appConfig["entityNames"]["checkContact"], data)
        .then((result) => {
          if (result.data.message === "success") {
            message.success("Contact Added Successfully!");
            this.addContact(data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // console.log(data, "data");
  };

  updateOwnerRolesData = (values) => {
    let ownerRolesData = this.state.ownerRoles;
    ownerRolesData.push({
      label: values,
      value: values.charAt(0).toUpperCase(),
      id: ownerRolesData?.length + 1,
    });
    let data = {
      entityData: {
        ownerRolesData,
        typeofDropDown: "ownerOperatorRoles",
      },
    };
    this.props.actionupdateDropDownOptions(this.props.ownerRoles[0]?.id, data);
  };

  addContact = (data) => {
    const { contactsData } = this.state;
    const contactInfo = contactsData?.length > 0 ? contactsData : [];
    contactInfo.unshift(data["entityData"]);
    let finalData = {
      entityData: {
        contactDetails: contactInfo,
      },
    };
    // console.log(finalData);
    this.updateCompanyDetails(finalData);
  };

  deleteContactInfo = (id) => {
    const { contactsData } = this.state;
    let deleteData = contactsData.filter((c) => c.id !== id);
    let finalData = {
      entityData: {
        contactDetails: deleteData,
      },
    };
    this.updateCompanyDetails(finalData);
    this.setState({
      contactsData: deleteData,
    });
    message.info("Contact Removed Successfully!");
  };

  editContactInfo = (data) => {
    this.setState({
      isContactModalVisible: true,
      jobContactData: data,
      primaryContactCheck: data?.isPrimaryContact,
    });
  };

  addActiveClass = (e) => {
    const activeClasses = [
      ...this.state.activeClasses.slice(0, e),
      !this.state.activeClasses[e],
      this.state.activeClasses.slice(e + 1),
    ].flat();
    this.setState({ activeClasses });
  };

  handleTrucksData = (value, option, index) => {
    // console.log(value, option, index);

    let selectedTrucks =
      this.state.selectedTrucks?.length > 0
        ? this.state.selectedTrucks.filter((o) => o.truckType === option.value)
        : [];
    if (selectedTrucks?.length === 0) {
      selectedTrucks = this.state.selectedTrucks.filter(
        (dr) => dr.dropdownKey !== index
      );
      selectedTrucks.push({
        truckType: option.value,
        id: option.key,
        dropdownKey: index,
        trucks: "",
      });
    } else {
      selectedTrucks = this.state.selectedTrucks;
    }
    this.setState({
      selectedTrucks,
    });
  };

  handleNoOfTrucks = (trucks, index) => {
    let selectedTrucks = this.state.selectedTrucks;

    selectedTrucks.map((tr) => {
      if (tr.dropdownKey === index) {
        tr["trucks"] = trucks;
      }
      return tr;
    });
    this.setState({
      selectedTrucks,
    });
  };

  handleToRemoveTruckBase = (index) => {
    // console.log(index);
    let selectedTrucks = this.state.selectedTrucks.filter(
      (removeTr) => removeTr.dropdownKey !== index
    );

    selectedTrucks.map((eachTr) => {
      if (parseInt(eachTr.dropdownKey) > index) {
        eachTr["dropdownKey"] = parseInt(eachTr.dropdownKey) - index;
      }
      if (index === 0 && parseInt(eachTr.dropdownKey) !== index) {
        if (parseInt(eachTr.dropdownKey) === 1) {
          eachTr["dropdownKey"] = index;
        } else {
          eachTr["dropdownKey"] = parseInt(eachTr.dropdownKey) - 1;
        }
      }
      return eachTr;
    });

    this.setState({ selectedTrucks });
  };

  onChangeCheckBox = (e) => {
    this.setState({ isCheckBox: e.target.checked });
  };

  onCancelTruck = () => {
    const { recordTruckData } = this.state;
    // console.log(recordTruckData)
    if (
      recordTruckData !== "" &&
      recordTruckData !== undefined &&
      recordTruckData?.length !== 0
    ) {
      let truckInfo =
        this.state.equipmentTypeDetails?.length > 0
          ? this.state?.equipmentTypeDetails
          : [];
      truckInfo.push(recordTruckData);
      this.setState({
        equipmentTypeDetails: truckInfo,
        recordTruckData: "",
        addTruck: false,
        selectedTruckCode: "",
      });
      // this.formRef.current.setFieldsValue({ material: "", quantity: "" });
    } else {
      this.setState({
        addTruck: false,
        selectedTruckCode: "",
        overWeightPermitState: "",
        quoteAttachment: "",
        quoteAttachmentId: "",
      });
    }
  };

  onFinishTruckDetails = (values) => {
    const {
      selectedAddress,
      equipmentTypeDetails,
      recordTruckData,
      latitude,
      longitude,
      selectedTruckCode,
      companyData,
      localTruckId,
      truckId,
      citySearch,
      quoteAttachmentId,
      equipmentAttachment,
      fileType,
    } = this.state;
    // console.log(citySearch);

    if (values["overWeightPermit"] === "Yes" && quoteAttachmentId === "") {
      message.info("Please Attach Your Document!");
      return;
    }
    if (
      (citySearch !== "" && citySearch !== undefined) ||
      (selectedAddress !== "" && selectedAddress !== undefined) ||
      (recordTruckData?.parking_location !== "" &&
        recordTruckData?.parking_location !== undefined)
    ) {
      let data = {
        entityData: {
          carrier_name: companyData?.carrier_name,
          id: recordTruckData?.id ? recordTruckData?.id : uuidv4(),
          parking_location: selectedAddress,
          lat: latitude,
          lng: longitude,
          truck_type: values["truck_type"],
          overWeightPermit: values["overWeightPermit"],
          overWeightExpiryDate:
            values["overWeightPermit"] === "Yes"
              ? moment(values["overWeightExpiryDate"]).format("L")
              : "",
          empty_weight: values["empty_weight"],
          truck_code: selectedTruckCode,
          truckId: recordTruckData?.truckId,
          carrierId: this.props?.match?.params?.id,
          equipmentAttachment:
            values["overWeightPermit"] === "Yes" ? equipmentAttachment : "",
          docAttachmentId:
            values["overWeightPermit"] === "Yes" ? quoteAttachmentId : "",
          fileType: fileType,
        },
      };
      if (recordTruckData === "") {
        equipmentTypeDetails?.push(data["entityData"]);
        if (
          companyData?.stageId === "Welcome Call" ||
          companyData?.stageId === "Approved Carrier"
        ) {
          if (localTruckId > truckId) {
            data["entityData"]["truckId"] = localTruckId + 1;
          } else {
            data["entityData"]["truckId"] = truckId + 1;
          }
        }
      } else {
        equipmentTypeDetails?.splice(
          equipmentTypeDetails?.findIndex(
            (e) => e.id === data["entityData"]["id"]
          ),
          1,
          data["entityData"]
        );
      }
      // let truckInfo =
      //   equipmentTypeDetails?.length > 0 ? equipmentTypeDetails : [];
      // truckInfo.push(data["entityData"]);
      let finalData = {
        entityData: {
          carrierDetails: equipmentTypeDetails,
        },
      };
      this.setState({
        addTruck: false,
      });
      // console.log(finalData, "finalData");
      this.updateCompanyDetails(finalData);
    } else {
      message.info("Please Enter Parking Location !");
    }
  };

  onClickStageId = (e, expiryDate) => {
    this.setState({
      selectedStatus: e,
      isStatusChanged: true,
    });
    this.updateStageId(e, expiryDate);
  };

  updateStageId = (e, expiryDate) => {
    const { companyData } = this.state;
    const today = moment().add(30, "days").calendar();
    let data = {
      entityData: {
        stageId: e,
        stageIdUpdatedDate: moment().format("L"),
      },
    };
    data["entityData"]["insurance_expiry"] = expiryDate;
    // console.log(data);
    if (e === "Pending Insurance") {
      this.updateCompanyDetails(data);
    } else if (e === "Pending Carrier Packet") {
      this.updateCompanyDetails(data);
    } else if (
      e === "Carrier Data Input" &&
      companyData?.carrierDetails?.length > 0
    ) {
      this.updateCompanyDetails(data);
    } else if (e === "Welcome Call") {
      this.addTruckId(e);
    } else if (e === "Approved Carrier") {
      if (
        new Date().getTime() >=
          new Date(companyData["insurance_expiry"]).getTime() ||
        (new Date(today).getTime() >
          new Date(companyData["insurance_expiry"]).getTime() &&
          new Date().getTime() <
            new Date(companyData["insurance_expiry"]).getTime())
      ) {
        this.setState({
          selectedStatus: "Welcome Call",
        });
        message.error("Please Update Insurance Expiry");
        return true;
      } else {
        data["entityData"]["carrier_status"] = "Active Carrier";
        this.addToMasterData(data);
        this.updateCompanyDetails(data);
      }
    } else if (
      e === "Pending Carrier Packet + Insurance" &&
      companyData?.stageId === "Stalled Application"
    ) {
      data["entityData"]["registered_since"] = moment().format("L");
      data["entityData"]["createdAt"] = Date.now();
      this.updateCompanyDetails(data);
    } else {
      this.updateCompanyDetails(data);
    }
  };

  addToMasterData = (data, expiry) => {
    const { companyData } = this.state;
    if (companyData?.carrierDetails?.length > 0) {
      if (expiry) {
        companyData["insurance_expiry"] = expiry;
      }
      let finalData = {
        entityData: {
          ...companyData,
          carrier_status: "Active Carrier",
        },
      };
      this.props.actionAddTrucksList(finalData);
      if (!expiry) {
      }
    } else {
      message.info("Please add atleast one equipment");
    }
  };

  addTruckId = (e) => {
    const { equipmentTypeDetails, truckId, localTruckId, companyData } =
      this.state;
    // let parkingLocation = equipmentTypeDetails?.filter(
    //   (e) => e?.parking_location
    // );
    // if (parkingLocation?.length === equipmentTypeDetails?.length) {
    if (companyData?.carrierDetails?.length > 0) {
      if (companyData?.payment_terms && companyData?.payment_terms !== "") {
        if (
          companyData?.insurance_expiry &&
          companyData?.insurance_expiry !== ""
        ) {
          if (
            companyData?.direct_deposit &&
            companyData?.direct_deposit !== ""
          ) {
            if (companyData?.usdot && companyData?.usdot !== "") {
              let count = 0;
              for (let i of equipmentTypeDetails) {
                count += 1;
                if (localTruckId > truckId) {
                  i["truckId"] =
                    i["truckId"] !== undefined
                      ? i["truckId"]
                      : localTruckId + count;
                } else {
                  i["truckId"] =
                    i["truckId"] !== undefined ? i["truckId"] : truckId + count;
                }
              }
              let finalData = {
                entityData: {
                  carrierDetails: equipmentTypeDetails,
                  stageId: e,
                },
              };

              // console.log(finalData);
              this.updateCompanyDetails(finalData);
            } else {
              message.error("Please Enter USDOT To Proceed");
              this.setState({ selectedStatus: companyData?.stageId });
            }
          } else {
            message.error("Please Enter Deposit Method To Proceed");
            this.setState({ selectedStatus: companyData?.stageId });
          }
        } else {
          message.error("Please Enter Insurance Expiration Date To Proceed");
          this.setState({ selectedStatus: companyData?.stageId });
        }
      } else {
        message.error("Please Enter Payment Terms To Proceed");
        this.setState({ selectedStatus: companyData?.stageId });
      }
    } else {
      message.error("Please Enter Equipment Details To Proceed");
      this.setState({ selectedStatus: companyData?.stageId });
    }
  };

  editModalTruck = (record) => {
    this.setState({ recordTruckData: record, addTruck: true });
  };

  onClickEdit = (data) => {
    // console.log(data);
    this.setState({
      addTruck: true,
      recordTruckData: data,
      latitude: data?.lat,
      longitude: data?.lng,
      selectedAddress: data?.parking_location,
      selectedTruckCode: data?.truck_code,
      overWeightPermitState: data?.overWeightPermit,
      equipmentAttachment: data?.equipmentAttachment,
      quoteAttachmentId: data?.docAttachmentId,
    });
  };

  onChangeTerms = (e, option) => {
    this.setState({ payment_discount: option.key });
    // this.setState({ selectedPaymentType: e });
    // this.formRef.current.setFieldsValue({
    //   paymentTermsOthers: "",
    // });
  };

  onSelectChange = (data) => {
    const { contactsData } = this.state;
    contactsData?.map((c) => {
      if (c?.id === data) {
        c["isPrimaryContact"] = true;
      } else {
        c["isPrimaryContact"] = false;
      }
      return true;
    });
    let finalData = {
      entityData: {
        contactDetails: contactsData.sort((a, b) =>
          a.isPrimaryContact < b.isPrimaryContact ? 1 : -1
        ),
      },
    };
    // console.log(finalData, "FD");
    this.updateCompanyDetails(finalData);
  };

  addNotes = (values) => {
    const { recordNotesData } = this.state;
    this.setState({ loading: true, notesPopup: false });
    if (recordNotesData !== "") {
      let filteredData = this.state.notesData.filter(
        (c) => c.id !== recordNotesData?.id
      );
      this.setState({
        notesData: filteredData,
      });
    }

    let data = {
      entityData: {
        id: recordNotesData?.id !== undefined ? recordNotesData?.id : uuidv4(),
        date:
          recordNotesData?.date !== undefined
            ? recordNotesData?.date
            : moment().format("MM-DD-YYYY LT"),
        typeofNotes: values["type"],
        notes: values["notes"],
      },
    };
    // console.log(data);
    let notesInfo =
      this.state?.notesData?.length > 0 ? this.state?.notesData : [];
    notesInfo.push(data["entityData"]);
    this.setState({ notesData: notesInfo });
    this.updateNotes();
  };

  updateNotes = () => {
    const { notesData } = this.state;
    let data = {
      entityData: {
        notesDetails: notesData,
      },
    };
    this.updateCompanyDetails(data);
  };

  editNotes = (data) => {
    this.setState({
      notesPopup: true,
      recordNotesData: data,
    });
  };

  deleteNotes = (id) => {
    this.setState({ loading: true });
    let notesInfo = this.state.notesData.filter((c) => c?.id !== id);
    this.setState(
      {
        notesData: notesInfo,
      },
      () => this.updateNotes()
    );
    message.info("Notes Deleted Successfully!");
  };

  handleUploadPic = async (info) => {
    this.setState({
      imageLoading: true,
      loading: true,
    });
    switch (info.file.status) {
      case "done":
        if (info.fileList.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              Storage.put(info.file.uid, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                Storage.get(result.key).then(() => {
                  this.setState({
                    quoteAttachmentId: result.key,
                    equipmentAttachment: result,
                    fileType: info.file.type,
                    imageLoading: false,
                    loading: false,
                  });
                });
              });
            } else {
              this.setState({
                imageLoading: false,
                loading: false,
              });
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;
      case "removed":
        this.setState({
          equipmentAttachment: "",
          quoteAttachmentId: "",
          imageLoading: false,
          loading: false,
          percentage: 0,
        });
        break;
      default:
    }
  };

  fileDownload = async (fileName, downloadFileName, companyName) => {
    let savedFileName = downloadFileName + "_" + companyName.replace(/ /g, "");
    this.setState({
      loadingDownload: true,
    });
    Storage.get(fileName, { download: true }).then((res) =>
      this.downloadBlob(res.Body, savedFileName)
    );
  };

  downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    // a.target = "_blank";
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    this.setState({
      loadingDownload: false,
    });
    return a;
  }

  onChangeOverWeight = (e) => {
    this.setState({
      overWeightPermitState: e?.target?.value,
    });
    if (e?.target?.value === "No") {
      this.formRef.current.setFieldsValue({
        overWeightExpiryDate: "",
      });
      this.setState({
        quoteAttachment: "",
        quoteAttachmentId: "",
      });
    }
  };

  onFinishDoc = (values) => {
    const {
      quoteAttachment,
      recordDocData,
      documentTypeVal,
      companyData,
      //  equipmentTypeDetails
    } = this.state;
    if (quoteAttachment !== undefined && quoteAttachment !== "") {
      this.setState({
        loading: true,
        isDocModalVisible: false,
        insuranceDate:
          values["insuranceDate"] !== undefined
            ? values["insuranceDate"]
            : companyData?.insurance_expiry,
      });
      let data = {
        entityData: {
          companyId: this.props?.match?.params?.id,
          carrierId: this.props?.match?.params?.id,
          documentType: values["documentType"],
          documentTypeVal: documentTypeVal,
          description: values["description"],
          docAttachment: quoteAttachment,
        },
      };

      if (recordDocData !== "") {
        this.updateDocument(data);
      } else {
        this.addDocument(data);
      }
    } else {
      message.error("Please upload a document!");
    }
  };

  addDocument = (data) => {
    this.props.actionAddDoc(data);
  };

  updateDocument = (data) => {
    const { recordDocData } = this.state;
    this.props.actionUpdateDoc(recordDocData?.id, data);
  };

  DownloadDoc = (data) => {
    data?.docAttachment?.map((e) =>
      this.fileDownload(e.quoteAttachmentId, "Attachment", "docAttachment")
    );
  };

  deleteDoc = (record, type) => {
    if (record?.documentTypeVal === "Insurance") {
      this.setState({
        removeInsurance: true,
      });
    }
    this.props.actionDeleteDoc(record?.id);
    // this.setState({ loading: true });
    // if (type === "deleteDocOnly") {
    //   this.deleteDocRecord(record?.id);
    // } else {
    //   const { equipmentTypeDetails } = this.state;
    //   equipmentTypeDetails.forEach((element) => {
    //     if (element.id === record.truckId) {
    //       element.docDetails = element.docDetails.filter(
    //         (d) => d.id !== record.id
    //       );
    //     }
    //   });

    //   let finalData = {
    //     entityData: {
    //       carrierDetails: equipmentTypeDetails,
    //     },
    //   };
    //   this.updateCompanyDetails(finalData);
    //   this.deleteDocRecord(record?.id);
    // }
  };

  // deleteDocRecord = (id) => {
  //   deleteRecord(appConfig["entityNames"]["addDocuments"], id)
  //     .then((res) => {
  //       if (res.success) {
  //         this.setState({ loading: false });
  //         this.getCarrierProfile(this.props.match.params.id);
  //       }
  //     })
  //     .catch((err) => {
  //       this.setState({ loading: false });
  //       console.log(err);
  //     });
  // };

  editDoc = (data) => {
    this.setState({
      isDocModalVisible: true,
      recordDocData: data,
      quoteAttachment: data?.docAttachment,
      documentTypeVal: data?.documentTypeVal,
    });
  };

  uploadTicketPdf = async (info) => {
    const { recordDocData } = this.state;
    this.setState({
      imageLoading: true,
      loading: true,
    });

    switch (info.file.status) {
      case "done":
        this.setState({
          fileList: info.fileList,
        });
        if (info.fileList.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              Storage.put(info.file.uid, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                Storage.get(result.key).then(() => {
                  let quoteAttachmentInfo = this.state.quoteAttachment
                    ? this.state.quoteAttachment
                    : [];
                  quoteAttachmentInfo.push({
                    id: uuidv4(),
                    quoteAttachmentId: result?.key,
                    fileType: info.file.type,
                    fileName: info.file.name,
                  });
                  this.setState({
                    quoteAttachment: quoteAttachmentInfo,
                    imageLoading: false,
                    loading: false,
                  });
                });
              });
            } else {
              this.setState({
                imageLoading: false,
                percentage: 0,
                loading: false,
              });
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;

      case "removed":
        if (recordDocData) {
          let docData = recordDocData.docAttachment.filter(
            (e) => e.id !== info.file.id
          );
          recordDocData["docAttachment"] = docData;
          this.setState({
            quoteAttachment: docData,
            recordDocData,
          });
        }
        this.setState({
          ticketUploaded: "",
          imageLoading: false,
          loading: false,
          percentage: 0,
        });
        break;
      default:
        this.setState(
          {
            imageLoading: false,
            loading: false,

            percentage: 0,
          },
          this.settime()
        );
    }
  };

  settime = () => {
    setTimeout(() => {
      this.setState({ percentage: this.state.percentage + 40 });
    }, 1000);
  };

  handleTableChage = (props, type) => {
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    localStorage.setItem(
      "tableSizeRange",
      JSON.stringify({ ...tableSizeRange, [type]: props })
    );
  };

  disabledButton = (e) => {
    return stageConditions[this.state.companyData.stageId].includes(e)
      ? false
      : true;
  };

  cursorButton = (e) => {
    return stageConditions[this.state.companyData.stageId].includes(e)
      ? "pointer"
      : "not-allowed";
  };

  colorButton = (e) => {
    return stageConditions[this.state.companyData.stageId].includes(e)
      ? "#1890ff"
      : "#8d9ca6";
  };

  render() {
    const {
      paymentTerms,
      quoteAttachmentId,
      equipmentAttachment,
      companyData,
      // totalLeadsData,
      citySearch,
      // invoicesDetails,
      documentsDetails,
      jobContactData,
      // selectedTrucks,
      isContactModalVisible,
      isCompanyModalVisible,
      // keyValue,
      // materialList,
      // materialSelectedData,
      // materialList,
      // selectedTerm,
      addTruck,
      recordTruckData,
      selectedStatus,
      // equipmentTypeDetails,
      trucksData,
      // equipmentTypeDetails,
      // truckId,
      // selectedTruckCode,
      newRole,
      selectedPaymentType,
      // selectedRowKeys,
      notesPopup,
      recordNotesData,
      overWeightPermitState,
      imageLoading,
      isDocModalVisible,
      recordDocData,
      ownerRoles,
      loadingDownload,
      documentTypeVal,
      docInsuranceInfo,
    } = this.state;
    const { errorLoading, loading } = this.props;
    let today = moment().add(30, "days").calendar();

    // const rowSelection = {
    //   selectedRowKeys,
    //   onChange: this.onSelectChange,
    // };
    // console.log(selectedTruckCode, "selectedTruckCode");
    // console.log(trucksData, "trucksData");

    // console.log(equipmentTypeDetails, "equipmentTypeDetails");

    const content = (
      <div>
        {this.state.funnelData?.map((e) => (
          <Row
            style={
              {
                // pointerEvents: `${
                //   this.state.companyData.stageId === "Dispatch Approved" &&
                //   e !== "Payment Processed" &&
                //   e !== "Order Delivered" &&
                //   "none"
                // }`,
                // cursor: `${
                //   this.state.companyData.stageId === "Dispatch Approved" &&
                //   e !== "Payment Processed" &&
                //   e !== "Order Delivered"
                //     ? "not-allowed"
                //     : "pointer"
                // }`,
              }
            }
          >
            <Col
              style={{
                fontSize: "13px",
                fontFamily: "sans-serif",
              }}
            >
              <Button
                onClick={() => this.onClickStageId(e)}
                style={{
                  width: "255px",
                  marginBottom: "5px",
                  // background:
                  //   this.state.companyData?.stageId === "Uncontacted Lead" &&
                  //   e !== "Pending Carrier Packet + Insurance"
                  //     ? "#1890ff"
                  //     : "",
                  // background:
                  //   e === this.state.selectedStatus ? "#1890ff" : "#FFF",
                  color: this.colorButton(e),
                  cursor: this.cursorButton(e),
                }}
                disabled={this.disabledButton(e)}
              >
                {e}
              </Button>
            </Col>
          </Row>
        ))}
      </div>
    );

    const equipmentColumns = [
      {
        title: "Truck Id",
        dataIndex: "truckId",
        key: "truckId",
        sorter: false,
        width: "5%",
        className: "col_style_quarry",
        render: (truckId, record) => {
          return <span className="col_style_quarry">{truckId && truckId}</span>;
        },
      },
      {
        title: "Truck Type",
        dataIndex: "truck_type",
        key: "truck_type",
        sorter: false,
        width: "10%",
        className: "col_style_quarry",
        render: (truck_type) => {
          return (
            <span className="col_style_quarry">
              {trucksData?.map((t) => t.value === truck_type && t?.label)}
            </span>
          );
        },
      },
      {
        title: "Parking Location",
        dataIndex: "parking_location",
        key: "parking_location",
        sorter: false,
        className: "col_style_quarry",
        width: "15%",
        render: (parking_location) => {
          return (
            <span className="col_style_quarry">
              {parking_location && parking_location}
            </span>
          );
        },
      },
      {
        title: "Empty Weight",
        dataIndex: "empty_weight",
        key: "empty_weight",
        sorter: false,
        width: "5%",
        className: "col_style_quarry",
        render: (empty_weight) => {
          return (
            <span className="col_style_quarry">
              {empty_weight && `${empty_weight} Ton`}
            </span>
          );
        },
      },
      {
        title: "Overweight Permit",
        dataIndex: "overWeightPermit",
        key: "overWeightPermit",
        sorter: false,
        width: "5%",
        className: "col_style_quarry",
        render: (overWeightPermit) => {
          return <span className="col_style_quarry">{overWeightPermit}</span>;
        },
      },
      {
        title: " OW Permit Expiry",
        dataIndex: "overWeightExpiryDate",
        key: "overWeightExpiryDate",
        sorter: false,
        width: "5%",
        className: "col_style_quarry",
        render: (overWeightExpiryDate, record) => {
          return (
            <span
              className="col_style_quarry"
              style={{
                color:
                  new Date(today).getTime() <=
                  new Date(overWeightExpiryDate).getTime()
                    ? "black"
                    : new Date(today).getTime() >=
                      new Date(overWeightExpiryDate).getTime()
                    ? "red"
                    : "black",
              }}
            >
              {record?.overWeightPermit === "Yes"
                ? moment(overWeightExpiryDate).format("L")
                : record?.overWeightPermit === "No"
                ? "N/A"
                : ""}
            </span>
          );
        },
      },
      {
        title: "Document Attachment",
        dataIndex: "docAttachmentId",
        key: "docAttachmentId",
        sorter: false,
        width: "5%",
        className: "col_style_quarry",
        render: (docAttachmentId, record) => {
          // console.log(docAttachmentId, "docAttachmentId");
          return (
            <span className="col_style_quarry">
              {docAttachmentId !== undefined &&
              record?.overWeightPermit === "Yes" &&
              docAttachmentId !== "" &&
              docAttachmentId !== null ? (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.fileDownload(
                      docAttachmentId,
                      "Attachment",
                      "docAttachment"
                    );
                  }}
                >
                  <Row
                    style={{
                      color: "#7B8190",
                      fontSize: "13px",
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      flexDirection: "coloumn",
                    }}
                  >
                    <HiOutlineDocumentArrowDown
                      style={{
                        fontSize: "30px",
                        height: "30px",
                        // marginRight: "5px",
                      }}
                    />
                    {/* <span style={{ marginTop: "6px" }}>Download</span> */}
                  </Row>
                </span>
              ) : record?.overWeightPermit === "No" ? (
                "N/A"
              ) : (
                ""
              )}
            </span>
          );
        },
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "",
        sorter: false,
        className: "col_style_quarry",
        width: "2%",
        render: (record) => {
          return (
            <Col>
              <Popover
                placement="left"
                content={
                  <Row className="popovergrid" style={{ maxWidth: "90px" }}>
                    <Col span={24}>
                      <Button
                        className="btn_edit"
                        onClick={() => this.onClickEdit(record)}
                      >
                        <span className="span_edit">
                          <MdModeEditOutline style={{ marginRight: "5px" }} />
                          Edit
                        </span>
                      </Button>
                    </Col>
                    <Col span={24}>
                      <Button className="btn_edit">
                        <Popconfirm
                          title="Are you sure？"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => this.deleteTruckDetails(record?.id)}
                        >
                          <span className="span_edit">
                            <MdDelete style={{ marginRight: "5px" }} /> Delete
                          </span>
                        </Popconfirm>
                      </Button>
                    </Col>
                  </Row>
                }
              >
                <EllipsisOutlined
                  style={{ fontSize: "30px", color: "grey" }}
                  className="action-ellipsis-button"
                />
              </Popover>
            </Col>
          );
        },
      },
    ];

    const contactsColumns = [
      {
        title: "Contact Name",
        dataIndex: "firstName",
        key: "firstName",
        sorter: false,
        width: "20%",
        className: "col_style_quarry",
        render: (firstName, record) => {
          return {
            props: {
              style: {
                background: record.isPrimaryContact ? "#dcf4ff" : "white",
              },
            },
            children: (
              <span className={"col_style_quarry"}>
                {record.isPrimaryContact
                  ? firstName + " " + record.lastName + " (Primary)"
                  : firstName + " " + record.lastName}
              </span>
            ),
          };
        },
      },
      {
        title: "Role",
        dataIndex: "contactRole",
        key: "contactRole",
        sorter: false,
        width: "20%",
        className: "col_style_quarry",
        render: (contactRole, record) => {
          return {
            props: {
              style: {
                background: record.isPrimaryContact ? "#dcf4ff" : "white",
              },
            },
            children: (
              <span className="col_style_quarry">
                {contactRole && contactRole}
              </span>
            ),
          };
        },
      },
      {
        title: "Phone",
        dataIndex: "contact_number",
        key: "contact_number",
        sorter: false,
        className: "col_style_quarry",
        width: "15%",
        render: (contact_number, record) => {
          return {
            props: {
              style: {
                background: record.isPrimaryContact ? "#dcf4ff" : "white",
              },
            },
            children: (
              <span className="col_style_quarry">{contact_number}</span>
            ),
          };
        },
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        sorter: false,
        className: "col_style_quarry",
        width: "40%",
        render: (email, record) => {
          return {
            props: {
              style: {
                background: record.isPrimaryContact ? "#dcf4ff" : "white",
              },
            },
            children: <span className="col_style_quarry">{email}</span>,
          };
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        sorter: false,
        className: "col_style_quarry",
        width: "15%",
        render: (record) => {
          return {
            props: {
              style: {
                background: record.isPrimaryContact ? "#dcf4ff" : "white",
              },
            },
            children: (
              <Col>
                <Popover
                  placement="left"
                  content={
                    <Row className="popovergrid" style={{ maxWidth: "135px" }}>
                      <Col span={24}>
                        <Button
                          className="btn_edit"
                          // style={{
                          //   fontSize: "15px",
                          //   cursor: "pointer",
                          //   border: "none",
                          //   padding: "0px",
                          //   textAlign: "left",
                          //   width: "100%",
                          //   height: "auto",
                          // }}
                          onClick={() => this.editContactInfo(record)}
                        >
                          <span className="span_edit">
                            <MdModeEditOutline style={{ marginRight: "5px" }} />
                            Edit
                          </span>
                        </Button>
                      </Col>
                      <Col span={24}>
                        <Button
                          className="btn_edit"
                          // style={{
                          //   fontSize: "15px",
                          //   cursor: "pointer",
                          //   border: "none",
                          //   padding: "0px",
                          //   textAlign: "left",
                          //   width: "100%",
                          //   height: "auto",
                          // }}
                        >
                          <Popconfirm
                            title="Are you sure？"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.deleteContactInfo(record?.id)}
                          >
                            <span className="span_edit">
                              <MdDelete style={{ marginRight: "5px" }} /> Delete
                            </span>
                          </Popconfirm>
                        </Button>
                      </Col>
                      {!record?.isPrimaryContact && (
                        <Col span={24}>
                          <Button
                            className="btn_edit"
                            onClick={() => this.onSelectChange(record.id)}
                          >
                            <span
                              className="span_edit"
                              style={{
                                color: "black",
                                display: "flex",
                                // flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "left",
                              }}
                            >
                              <ContactsOutlined
                                style={{
                                  marginRight: "5px",
                                }}
                              />
                              Set as Primary
                            </span>
                          </Button>
                        </Col>
                      )}
                    </Row>
                  }
                >
                  <EllipsisOutlined
                    style={{ fontSize: "30px", color: "grey" }}
                    className="action-ellipsis-button"
                  />
                </Popover>
              </Col>
            ),
          };
        },
      },
    ];

    const docColumns = [
      {
        title: "Upload Date",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: false,
        width: "10%",
        className: "col_style_quarry",
        render: (createdAt) => {
          return (
            <span className="col_style_quarry">
              {moment(createdAt).format("L")}
            </span>
          );
        },
      },
      {
        title: "Document Type",
        dataIndex: "documentType",
        key: "documentType",
        sorter: false,
        width: "20%",
        className: "col_style_quarry",
        render: (documentType) => {
          return <span className="col_style_quarry">{documentType}</span>;
        },
      },
      {
        title: "Document Description",
        dataIndex: "description",
        key: "description",
        sorter: false,
        width: "40%",
        className: "col_style_quarry",
        render: (description) => {
          return <span className="col_style_quarry">{description}</span>;
        },
      },
      {
        title: "Attachment",
        dataIndex: "docAttachment",
        key: "docAttachment",
        sorter: false,
        width: "8%",
        className: "col_style_quarry",
        render: (docAttachment) => {
          return (
            <span
              className="col_style_quarry"
              // style={{
              //   display: "flex",
              //   justifyContent: "center",
              //   alignContent: "center",
              //   flexWrap: "wrap",
              // }}
            >
              {docAttachment !== undefined &&
                docAttachment !== "" &&
                docAttachment !== null &&
                docAttachment?.map((d) => (
                  <Popover placement="left" content={d.fileName}>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.fileDownload(
                          d.quoteAttachmentId,
                          "Attachment",
                          "docAttachment"
                        );
                      }}
                    >
                      <Row
                        style={{
                          color: "#7B8190",
                          fontSize: "13px",
                          marginBottom: "3px",
                          // flexDirection: "column",
                        }}
                      >
                        <HiOutlineDocumentArrowDown
                          style={{
                            fontSize: "30px",
                            height: "30px",
                            // marginRight: "5px",
                          }}
                        />
                        {/* <span style={{ marginTop: "6px" }}>Download</span> */}
                      </Row>
                    </div>
                  </Popover>
                ))}
            </span>
          );
        },
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "",
        sorter: false,
        className: "col_style_quarry",
        width: "2%",
        render: (record) => {
          return (
            <Col>
              <Popover
                placement="left"
                content={
                  <Row className="popovergrid" style={{ maxWidth: "110px" }}>
                    <Col span={24}>
                      <Button
                        className="btn_edit"
                        onClick={() => this.DownloadDoc(record)}
                      >
                        <span className="span_edit">
                          <ArrowDownOutlined
                            style={{
                              marginRight: "5px",
                            }}
                          />{" "}
                          Download
                        </span>
                      </Button>
                    </Col>
                    {record.documentTypeVal !== "Insurance" &&
                      record.documentTypeVal !== "Carrier Packet" && (
                        <Col span={24}>
                          <Button
                            className="btn_edit"
                            onClick={() => this.editDoc(record)}
                          >
                            <span className="span_edit">
                              <MdModeEditOutline
                                style={{
                                  marginRight: "5px",
                                }}
                              />{" "}
                              Edit
                            </span>
                          </Button>
                        </Col>
                      )}
                    <Col span={24}>
                      <Button className="btn_edit">
                        <Popconfirm
                          title="Are you sure？"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => this.deleteDoc(record)}
                        >
                          <span className="span_edit">
                            <MdDelete style={{ marginRight: "5px" }} /> Delete
                          </span>
                        </Popconfirm>
                      </Button>
                    </Col>
                  </Row>
                }
              >
                <EllipsisOutlined
                  style={{ fontSize: "30px", color: "grey" }}
                  className="action-ellipsis-button"
                />
              </Popover>
            </Col>
          );
        },
      },
      // {
      //   title: "Document Attachment",
      //   dataIndex: "docAttachment",
      //   key: "docAttachment",
      //   sorter: false,
      //   className: "col_style_quarry",
      //   render: (docAttachment) => {
      //     return <span className="col_style_quarry">{docAttachment}</span>;
      //   },
      // },
    ];

    const notesColumns = [
      {
        title: "Date Created",
        dataIndex: "date",
        key: "date",
        sorter: false,
        width: "18%",
        className: "col_style_quarry",
        render: (date) => {
          return <span className="col_style_quarry">{date}</span>;
        },
      },
      {
        title: "Type Of Notes",
        dataIndex: "typeofNotes",
        key: "typeofNotes",
        sorter: false,
        className: "col_style_quarry",
        width: "25%",
        render: (typeofNotes) => {
          return <span className="col_style_quarry">{typeofNotes}</span>;
        },
      },
      {
        title: "Notes",
        dataIndex: "notes",
        key: "notes",
        sorter: false,
        className: "col_style_quarry",
        render: (notes) => {
          return <span className="col_style_quarry">{notes}</span>;
        },
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "",
        sorter: false,
        className: "col_style_quarry",
        width: "10%",
        render: (record) => {
          return (
            <Col>
              <Popover
                placement="left"
                content={
                  <Row className="popovergrid" style={{ maxWidth: "90px" }}>
                    <Col span={24}>
                      <Button
                        className="btn_edit"
                        onClick={() => this.editNotes(record)}
                      >
                        <span className="span_edit">
                          <MdModeEditOutline
                            style={{
                              marginRight: "5px",
                            }}
                          />{" "}
                          Edit
                        </span>
                      </Button>
                    </Col>
                    <Col span={24}>
                      <Button className="btn_edit">
                        <Popconfirm
                          title="Are you sure？"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => this.deleteNotes(record?.id)}
                          // onConfirm={() => this.deleteCompany(record)}
                        >
                          <span className="span_edit">
                            <MdDelete
                              style={{
                                marginRight: "5px",
                              }}
                            />
                            Delete
                          </span>
                        </Popconfirm>
                      </Button>
                    </Col>
                  </Row>
                }
              >
                <EllipsisOutlined
                  style={{
                    fontSize: "30px",
                    color: "grey",
                  }}
                  className="action-ellipsis-button"
                />
              </Popover>
            </Col>
          );
        },
      },
    ];
    // var x = new moment(moment().format("l"));

    // var y = new moment(moment(companyData?.stageIdUpdatedDate).format("l"));
    // var diff = x - y;
    // let duration = Math.ceil(diff / (1000 * 60 * 60 * 24));
    // console.log(duration);

    return (
      <>
        <Loading enableLoading={loading || loadingDownload} />
        <Row
          style={{
            backgroundColor: "#fafafa",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "60px",
            width: "100%",
          }}
        >
          <Row onClick={this.onClickReturn} style={{ cursor: "pointer" }}>
            <BsArrowLeftShort
              style={{ color: "#808080", fontSize: "23px" }}
              className="back-button-return-icon"
            />
            <p
              style={{ color: "#5f6c74", fontSize: "16px", fontWeight: "600" }}
              className="back-button-return"
            >
              Return
            </p>
          </Row>
        </Row>
        <div
          style={{
            background: "#fafafa",
            minHeight: "75vh",
          }}
        >
          {errorLoading ? (
            <Col
              xxl={{ span: 24 }}
              xl={{ span: 24 }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <ErrorView
                refreshLeadDetails={this.onRefresh}
                type="LeadDetails"
              />
            </Col>
          ) : (
            <Row style={{ marginBottom: "1vw" }} gutter={[12, 0]}>
              <Col
                xxl={{ span: 8 }}
                xl={{ span: 8 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                data-aos={window.screen.width > 1023 ? "fade-right" : ""}
              >
                <Card>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "-12px",
                        right: "0px",
                        border: "0.5px solid #aaa8a8",
                        width: "2vw",
                        height: "2vw",
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        alignContent: "center",
                        justifyContent: " center",
                        padding: "8px",
                        borderRadius: "100%",
                      }}
                      className="edit-details-button"
                    >
                      <Button
                        style={{
                          border: "none",
                          cursor: "pointer",
                          // marginTop: "-90px",
                          backgroundColor: "transparent",
                        }}
                        className="table-font-mobile"
                        onClick={() =>
                          this.setState({
                            isCompanyModalVisible: true,
                            selectedAddress: companyData?.address,
                            latitude: companyData?.lat,
                            longitude: companyData?.lng,
                            payment_discount: companyData?.payment_discount,
                          })
                        }
                        type="ghost"
                      >
                        <EditOutlined
                          style={{
                            color: "#5f6c74",
                            fontSize: "100%",
                            fontWeight: "600",
                          }}
                          className="table-font-mobile"
                        />
                      </Button>
                    </div>
                    {/* <p
                      className="detail-heading"
                      style={{
                        fontSize: "15px",
                        color: "#5f6c74",
                        textAlign: "center",
                      }}
                    >
                      OWNER OPERATOR
                    </p> */}
                    <div className="linkcompany">
                      <p
                        className="detail-ans"
                        style={{
                          fontSize: "20px",
                          fontWeight: "800",
                          marginTop: "10px",
                          textAlign: "center",
                        }}
                      >
                        {companyData?.company}
                      </p>
                    </div>
                    <div className="linkcompany">
                      <p
                        className="detail-ans"
                        style={{
                          fontSize: "20px",
                          fontWeight: "800",
                          marginTop: "10px",
                          textAlign: "center",
                        }}
                      >
                        {companyData?.usdot && companyData?.usdot}
                      </p>
                    </div>

                    {/* <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      className="detail-heading"
                      style={{ fontSize: "16px" }}
                    >
                      USDOT
                    </Col> */}
                    {/* <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      className="detail-ans"
                    >
                      {companyData?.usdot && companyData?.usdot}
                    </Col> */}
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      {/* <Button
                        className="detail-ans"
                        style={{
                          color:
                            companyData?.carrier_status === "Active Carrier"
                              ? "#4C9150"
                              : companyData?.carrier_status ===
                                "Inactive Carrier"
                              ? "#434F61"
                              : companyData?.carrier_status === "Banned"
                              ? "#AB0000"
                              : "#D15300",
                          fontSize: "16px",
                          fontFamily: "sans-serif",
                          fontWeight: "600",
                          borderColor:
                            companyData?.carrier_status === "Active Carrier"
                              ? "#4C9150"
                              : companyData?.carrier_status ===
                                "Inactive Carrier"
                              ? "#434F61"
                              : companyData?.carrier_status === "Banned"
                              ? "#AB0000"
                              : "#D15300",
                          borderRadius: "5px",
                        }}
                      >
                        {companyData?.carrier_status &&
                          companyData?.carrier_status}
                      </Button> */}
                    </Col>
                    <Popover
                      placement="right"
                      content={selectedStatus !== "Update Needed" && content}
                      trigger="hover"
                    >
                      <Button
                        className="lead_ButtonStatus"
                        style={{
                          // color: "#3f27c3",
                          // border: "1px solid #3f27c3",
                          background: "transparent",
                          borderRadius: "6px",
                          borderColor:
                            appConfig["STAGE_COLORS"][companyData.stageId],
                          color: appConfig["STAGE_COLORS"][companyData.stageId],
                          borderWidth: "1px",
                          borderStyle: "solid",
                          marginTop: "10px",
                        }}
                      >
                        {
                          selectedStatus && selectedStatus
                          // )
                          // : (
                          //   <span>{companyData.stageId }</span>
                        }
                      </Button>
                    </Popover>
                  </div>
                </Card>

                <Card style={{ marginTop: "25px" }}>
                  <p
                    className="detail-ans"
                    style={{ fontWeight: "600", marginTop: "15px" }}
                  >
                    Company Details
                  </p>
                  {/* <Row
                    style={{
                      marginBottom: "8px",
                      // borderBottom: "2px ridge #e6e6e6",
                    }}
                  >
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      className="detail-heading"
                      style={{ fontSize: "16px" }}
                    >
                      USDOT
                    </Col>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      className="detail-ans"
                    >
                      {companyData?.usdot && companyData?.usdot}
                    </Col>
                  </Row> */}
                  <Row
                    style={{
                      marginBottom: "8px",
                      marginTop: "5px",
                      // borderBottom: "2px ridge #e6e6e6",
                    }}
                  >
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      className="detail-heading table-font-mobile"
                      style={{ fontSize: "16px" }}
                    >
                      Mailing Address
                    </Col>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      className="detail-ans"
                    >
                      {companyData?.address && companyData?.address}
                    </Col>
                  </Row>
                  <Row
                    style={{
                      marginBottom: "8px",
                      // borderBottom: "2px ridge #e6e6e6",
                    }}
                  >
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      className="detail-heading table-font-mobile"
                      style={{ fontSize: "16px" }}
                    >
                      Office Email
                    </Col>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      className="detail-ans"
                    >
                      {companyData?.email
                        ? companyData?.email
                        : companyData?.contactDetails?.filter(
                            (e) => e?.isPrimaryContact
                          )[0]?.email}
                    </Col>
                  </Row>
                  <Row
                    style={{
                      marginBottom: "8px",
                      // borderBottom: "2px ridge #e6e6e6",
                    }}
                  >
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      className="detail-heading table-font-mobile"
                      style={{ fontSize: "16px" }}
                    >
                      Office Phone
                    </Col>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      className="detail-ans"
                    >
                      {companyData?.phone
                        ? companyData?.phone
                        : companyData?.contactDetails?.filter(
                            (e) => e?.isPrimaryContact
                          )[0]?.contact_number}
                    </Col>
                  </Row>

                  <Row
                    style={{
                      marginBottom: "8px",
                      // borderBottom: "2px ridge #e6e6e6",
                    }}
                  >
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      className="detail-heading table-font-mobile"
                      style={{ fontSize: "16px" }}
                    >
                      Payment Terms
                    </Col>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      className="detail-ans"
                    >
                      {companyData?.payment_terms +
                        " - " +
                        companyData?.payment_discount}
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "5px" }}>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      className="detail-heading table-font-mobile"
                      style={{ fontSize: "16px" }}
                    >
                      Insurance Expiry Date
                    </Col>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      className="detail-ans"
                    >
                      {companyData?.insurance_expiry &&
                        companyData?.insurance_expiry}
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "5px" }}>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      className="detail-heading table-font-mobile"
                      style={{ fontSize: "16px" }}
                    >
                      Deposit Method
                    </Col>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      className="detail-ans"
                    >
                      {companyData?.direct_deposit &&
                        companyData?.direct_deposit}
                    </Col>
                  </Row>

                  <Row
                    style={{
                      marginBottom: "8px",
                      // borderBottom: "2px ridge #e6e6e6",
                    }}
                  >
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      className="detail-heading table-font-mobile"
                      style={{ fontSize: "16px" }}
                    >
                      Registered Since
                    </Col>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      className="detail-ans"
                    >
                      {companyData?.createdAt &&
                        moment(companyData?.createdAt).format("L")}
                    </Col>
                  </Row>
                  <p
                    className="detail-ans"
                    style={{ fontWeight: "600", marginBottom: "-3px" }}
                  >
                    Notes
                  </p>
                  <div
                    style={{
                      minHeight: "65px",
                      borderRadius: "3px",
                      backgroundColor: "#e3e7ea",
                      padding: "6px",
                    }}
                    className="detail-ans"
                  >
                    {companyData?.notes && companyData?.notes}
                  </div>
                </Card>
              </Col>
              <Col
                xxl={{ span: 16, offset: 0 }}
                xl={{ span: 16, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
                // span={15}
                // offset={1}
                data-aos={window.screen.width > 1023 ? "fade-left" : ""}
              >
                <Card
                  className="card_virtual"
                  bodyStyle={{ padding: "0px" }}
                  style={{ background: "#eff1f3" }}
                >
                  <Tabs
                    defaultActiveKey="1"
                    className="ant-card companydetailtab"
                    style={{ borderRadius: "5px" }}
                    onChange={(e) => {
                      this.setState({
                        keyValue: e,
                      });
                    }}
                    tabBarExtraContent={
                      <Button
                        type="primary"
                        htmlType="submit"
                        icon={<PlusOutlined />}
                        style={{
                          height: "35px",
                          borderRadius: "5px",
                          marginRight: "10px",
                        }}
                        onClick={() => this.showModal(this.state?.keyValue)}
                      >
                        {this.state?.keyValue === "1"
                          ? "Equipment"
                          : this.state?.keyValue === "2"
                          ? "Contact"
                          : this.state?.keyValue === "3"
                          ? "Document"
                          : this.state?.keyValue === "4" && "Notes"}
                      </Button>
                    }
                  >
                    <TabPane tab={<span>Equipment</span>} key="1">
                      <BaseTable
                        className="invoices_tableclass table_laptopscreen"
                        columnsData={equipmentColumns}
                        source={companyData.carrierDetails}
                        total={companyData?.carrierDetails?.length}
                        handleTableChage={(e) =>
                          this.handleTableChage(e, "ownerOperatorDetails")
                        }
                        sizeRange={
                          window.screen.width > 1023
                            ? JSON.parse(localStorage.getItem("tableSizeRange"))
                                .ownerOperatorDetails
                            : companyData?.carrierDetails?.length
                        }
                        pagination={window.screen.width > 1023 ? true : false}
                      />
                    </TabPane>
                    <TabPane tab={<span>Contacts</span>} key="2">
                      <BaseTable
                        // rowSelection={{
                        //   type: "radio",
                        //   ...rowSelection,
                        // }}
                        rowKey={(record) => record?.id}
                        className="contacts_tableclass table_laptopscreen"
                        columnsData={contactsColumns}
                        source={companyData?.contactDetails}
                        total={companyData?.contactDetails?.length}
                        handleTableChage={(e) =>
                          this.handleTableChage(
                            e,
                            "ownerOperatorContactDetails"
                          )
                        }
                        sizeRange={
                          window.screen.width > 1023
                            ? JSON.parse(localStorage.getItem("tableSizeRange"))
                                .ownerOperatorContactDetails
                            : companyData?.contactDetails?.length
                        }
                        pagination={window.screen.width > 1023 ? true : false}
                      />
                    </TabPane>
                    <TabPane tab={<span>Documents</span>} key="3">
                      <BaseTable
                        className="documents_tableclass table_laptopscreen"
                        columnsData={docColumns}
                        source={documentsDetails?.sort(
                          (a, b) =>
                            new Date(b.createdAt) - new Date(a?.createdAt)
                        )}
                        total={documentsDetails?.length}
                        handleTableChage={(e) =>
                          this.handleTableChage(e, "ownerOperatorDocDetails")
                        }
                        sizeRange={
                          window.screen.width > 1023
                            ? JSON.parse(localStorage.getItem("tableSizeRange"))
                                .ownerOperatorDocDetails
                            : documentsDetails?.length
                        }
                        pagination={window.screen.width > 1023 ? true : false}
                      />
                    </TabPane>
                    <TabPane tab={<span>Notes</span>} key="4">
                      <BaseTable
                        className="documents_tableclass table_laptopscreen"
                        columnsData={notesColumns}
                        source={companyData?.notesDetails}
                        total={companyData?.notesDetails?.length}
                        handleTableChage={(e) =>
                          this.handleTableChage(e, "ownerOperatorNotesDetails")
                        }
                        sizeRange={
                          window.screen.width > 1023
                            ? JSON.parse(localStorage.getItem("tableSizeRange"))
                                .ownerOperatorNotesDetails
                            : companyData?.notesDetails?.length
                        }
                        pagination={window.screen.width > 1023 ? true : false}
                      />
                    </TabPane>
                  </Tabs>
                </Card>
                {companyData?.address && (
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    lg={{ span: 24, offset: 0 }}
                    xl={{ span: 24, offset: 0 }}
                    xxl={{ span: 24, offset: 0 }}
                    style={{
                      width: "100%",
                      marginTop: "25px",
                    }}
                  >
                    <div className="lead_detailmap">
                      <MapComponent
                        filteredMapData={[companyData]}
                        ownerProfile
                        disableScroll
                        // OwnerOperatorProfileWelcomeCall
                      />
                    </div>
                  </Col>
                )}
              </Col>
            </Row>
          )}
        </div>

        {isCompanyModalVisible && (
          <BaseModal
            title={"Update Carrier Details"}
            onCancel={() => {
              this.setState({
                isCompanyModalVisible: false,
                selectedPaymentType: "",
              });
            }}
            formId="CompanyDetails"
            className="antmodal_grid headermodal"
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "80%"
                : "600px"
            }
            loading={loading}
          >
            <Form
              onFinish={this.onFinishCompanyDetails}
              id="CompanyDetails"
              ref={this.formRef}
            >
              <Row gutter={[32, 0]}>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={11}
                >
                  <span className="title_changes">Carrier Name</span>
                  <Form.Item
                    initialValue={companyData?.carrier_name}
                    name="carrier_name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Carrier Name",
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) =>
                        this.setState({ carrierName: e.target.value })
                      }
                      className="form-input"
                      placeholder="Please enter Carrier Name"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Registered Since</span>
                  <Form.Item
                    name="registered_since"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your date!",
                      },
                    ]}
                    initialValue={
                      companyData?.registered_since
                        ? moment(companyData?.registered_since)
                        : moment()
                    }
                  >
                    <DatePicker
                      style={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "5px",
                      }}
                      format={"MM/DD/YYYY"}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">USDOT</span>
                  <Form.Item
                    name="usdot"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter USDOT!",
                      },
                    ]}
                    initialValue={companyData?.usdot}
                  >
                    <NumberFormat
                      className="ant-input-number numberictest"
                      format="##########"
                      style={{ width: "100%" }}
                      placeholder="Please Enter USDOT"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Deposit Method</span>
                  <Form.Item
                    name="direct_deposit"
                    // onChange={this.getparking_location}
                    rules={[
                      {
                        required: false,
                        errorMessage: true,
                        message: "Please Enter Your Direct Deposit!",
                      },
                    ]}
                    initialValue={companyData?.direct_deposit}
                  >
                    <Radio.Group
                      defaultValue={companyData?.direct_deposit}
                      // onChange={(e) =>
                      //   this.setState({ supplier: e?.target?.value })
                      // }
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        // flexWrap: "wrap",
                        // maxWidth:"100%",
                        padding: "4px",
                        border: "1px solid #e5e5e5",
                        height: "40px",
                        borderRadius: "5px",
                      }}
                    >
                      <Radio
                        style={{ fontSize: "16px" }}
                        value={"Direct Deposit"}
                      >
                        Direct Deposit
                      </Radio>
                      <Radio style={{ fontSize: "16px" }} value={"Check"}>
                        Check
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 12 }}
                  sm={{ span: 12 }}
                  xs={{ span: 12 }}
                  // span={11}
                >
                  <span className="title_changes">Company Mailing Address</span>
                  <Form.Item
                    name="address"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Company Mailing Address",
                      },
                    ]}
                  >
                    <GooglePlacesAutocomplete
                      className="form-input"
                      // apiKey={process.env.REACT_APP_MAPS_KEY}
                      autocompletionRequest={{
                        componentRestrictions: {
                          country: ["us"],
                        },
                      }}
                      selectProps={{
                        defaultInputValue: `${
                          companyData?.address ? companyData?.address : ""
                        }`,
                        citySearch,
                        placeholder: "Search Address...",
                        onChange: (address) => {
                          this.handleSelect(address);
                          this.getAddress(address);
                        },
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Payment Terms</span>
                  <Form.Item
                    name="payment_terms"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Required!",
                      },
                    ]}
                    initialValue={companyData?.payment_terms}
                  >
                    <Select
                      allowClear
                      className="select_Height"
                      style={{ height: "40px", fontWeight: "300" }}
                      placeholder="Please Select Payment Type"
                      onChange={this.onChangeTerms}
                    >
                      {paymentTerms?.map((e) => (
                        <Option
                          key={e.payment_discount}
                          value={e.payment_terms}
                        >
                          {e.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {selectedPaymentType === "Custom Input" && (
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={12}
                  >
                    <span className="title_changes">
                      Payment Terms (Others)
                    </span>
                    <Form.Item
                      name="paymentTermsOthers"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter New Payment Terms",
                        },
                      ]}
                    >
                      <NumberFormat
                        className="ant-input-number numberictest"
                        style={{ width: "100%" }}
                        prefix={"Net "}
                        placeholder="Please Enter New Payment Terms"
                      />
                    </Form.Item>
                  </Col>
                )}
                {/* <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Carrier Status</span>
                  <Form.Item
                    name="carrier_status"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Require!",
                      },
                    ]}
                    initialValue={
                      companyData?.carrier_status
                        ? companyData?.carrier_status
                        : "Applicant"
                    }
                  >
                    <Select
                      defaultValue={"Applicant"}
                      allowClear
                      className="select_Height"
                      style={{ height: "40px", fontWeight: "300" }}
                      placeholder="Please Select Payment Type"
                    >
                      {statusData?.map((e) => (
                        <Option key={e.label} value={e.label}>
                          {e.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col> */}
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Office Phone</span>
                  <Form.Item
                    name="phone"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Office Phone!",
                      },
                    ]}
                    initialValue={companyData?.phone}
                  >
                    <NumberFormat
                      className="ant-input-number numberictest"
                      format="(###) ###-####"
                      style={{ width: "100%" }}
                      placeholder="Please Enter Office Phone"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  span={selectedPaymentType === "Custom Input" ? 24 : 12}
                >
                  <span className="title_changes">Office Email</span>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Office Email!",
                      },
                      {
                        type: "email",
                        message: "The Input Is Not Valid Email",
                      },
                    ]}
                    initialValue={companyData?.email}
                  >
                    <Input
                      className="form-input"
                      placeholder="Please Enter Office Email"
                    />
                  </Form.Item>
                </Col>

                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Notes</span>
                  <Form.Item
                    name="notes"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Notes!",
                      },
                    ]}
                    initialValue={companyData?.notes}
                  >
                    <TextArea
                      rows={4}
                      style={{
                        width: "100%",
                        height: "75px",
                        borderColor: "#d6d6d6",
                      }}
                      className="form-input"
                      placeholder="Please Enter Your Notes"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}

        {isContactModalVisible && (
          <BaseModal
            title={jobContactData !== "" ? "Update Contact" : "Add New Contact"}
            onCancel={() =>
              this.setState({
                isContactModalVisible: false,
                newRole: "",
                roleCheckBox: false,
                isCheckcontact: false,
                primaryContactCheck: false,
              })
            }
            loading={loading}
            formId="contact"
            className="antmodal_grid headermodal"
            width={"25%"}
          >
            <Form onFinish={this.onFinishContact} id="contact">
              <Row gutter={[16, 0]}>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">First Name</span>
                  <Form.Item
                    name="fName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your First Name!",
                      },
                    ]}
                    initialValue={jobContactData?.firstName}
                  >
                    <Input
                      className="form-input"
                      placeholder="Please Enter Your First Name"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Last Name</span>
                  <Form.Item
                    name="lName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter your Last Name!",
                      },
                    ]}
                    initialValue={jobContactData?.lastName}
                  >
                    <Input
                      className="form-input"
                      placeholder="Please Enter your Last Name"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Phone</span>
                  <Form.Item
                    name="phone"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Phone!",
                      },
                    ]}
                    initialValue={jobContactData?.contact_number}
                  >
                    <NumberFormat
                      className="ant-input-number numberictest"
                      format="(###) ###-####"
                      style={{ width: "100%" }}
                      placeholder="Please Enter Your Phone"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Email</span>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Email!",
                      },
                      {
                        type: "email",
                        message: "The Input Is Not Valid Email",
                      },
                    ]}
                    initialValue={jobContactData?.email}
                  >
                    <Input
                      className="form-input"
                      placeholder="Please Enter Your Email"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Role</span>
                  <Form.Item
                    name="role"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Role!",
                      },
                    ]}
                    initialValue={
                      jobContactData?.contactRole && jobContactData?.contactRole
                    }
                  >
                    <Select
                      size="large"
                      showSearch
                      allowClear
                      className="select_Height"
                      // style={{ height: "40px" }}
                      placeholder="Please Select A Role"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(value) => {
                        this.setState({ newRole: value });
                        value === "Others" &&
                          message.info("Please Input Your Role");
                      }}
                    >
                      {ownerRoles?.map((role) => (
                        <Option key={role.id} value={role.label}>
                          {role.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {newRole === "Add New Role" && (
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Add New Role</span>
                    <Form.Item name="roleOther">
                      <Input
                        className="form-input"
                        placeholder="Please Enter Role"
                      />
                    </Form.Item>
                    <Checkbox
                      checked={this.state.isCheckcontact}
                      onChange={(e) =>
                        this.setState({
                          isCheckcontact: e.target.checked,
                        })
                      }
                      style={{ marginBottom: "18px" }}
                    >
                      <span
                        className="checkBox_size"
                        style={{ fontSize: "14px" }}
                      >
                        Add this new role to default list
                      </span>
                    </Checkbox>
                  </Col>
                )}
                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Notes</span>
                  <Form.Item
                    name="notes"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Notes!",
                      },
                    ]}
                    initialValue={
                      jobContactData?.notes ? jobContactData?.notes : ""
                    }
                  >
                    <TextArea
                      rows={4}
                      style={{
                        width: "100%",
                        height: "75px",
                        borderColor: "#d6d6d6",
                      }}
                      className="form-input"
                      placeholder="Please Enter Your Notes"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}

        {addTruck && (
          <BaseModal
            className="crmform_modal antmodal_grid headermodal"
            title={
              recordTruckData !== "" ? "Update Equipment" : "Add Equipment"
            }
            onCancel={this.onCancelTruck}
            loading={imageLoading}
            visible={addTruck}
            destroyOnClose={true}
            formId="truck"
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "80%"
                : "720px"
            }
          >
            <Form
              style={{ width: "100%" }}
              onFinish={this.onFinishTruckDetails}
              id="truck"
              ref={this.formRef}
            >
              <Row gutter={[24, 8]}>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Truck Type</span>
                  <Form.Item
                    name={"truck_type"}
                    rules={[
                      {
                        required: true,
                        message: "Please Select Truck Type",
                      },
                    ]}
                    initialValue={recordTruckData?.truck_type}
                    style={{
                      marginBottom: "5px",
                    }}
                  >
                    <Select
                      allowClear
                      className="select_Height"
                      style={{
                        height: "40px",
                        fontWeight: "300",
                      }}
                      placeholder="Select Truck Type"
                      onChange={(e, option) => {
                        this.setState({
                          selectedTruckCode: option?.key,
                        });
                      }}
                      defaultValue={recordTruckData?.truck_type}
                    >
                      {trucksData?.map((t) => (
                        <Option value={t.value} key={t.truck_code}>
                          {t.value}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Empty Weight</span>
                  <Form.Item
                    name={"empty_weight"}
                    rules={[
                      {
                        required: false,
                        message: "Enter Empty Weight",
                      },
                    ]}
                    initialValue={recordTruckData?.empty_weight}
                  >
                    <InputNumber
                      min={1}
                      addonAfter={"Ton"}
                      style={{
                        width: "100%",
                      }}
                      placeholder="Enter Empty Weight"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={11}
                >
                  <span className="title_changes">Parking Location</span>
                  <Form.Item
                    name="address"
                    rules={[
                      {
                        required: false,
                        message: "Please enter your address",
                      },
                    ]}
                  >
                    <GooglePlacesAutocomplete
                      className="form-input"
                      // apiKey={process.env.REACT_APP_MAPS_KEY}
                      autocompletionRequest={{
                        componentRestrictions: {
                          country: ["us"],
                        },
                      }}
                      selectProps={{
                        defaultInputValue: `${
                          companyData?.address
                            ? companyData?.address
                            : recordTruckData?.parking_location
                            ? recordTruckData?.parking_location
                            : ""
                        }`,
                        citySearch,
                        placeholder: "Search Address...",
                        onChange: (address) => {
                          this.handleSelect(address);
                          this.getAddress(address);
                        },
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 8 }}
                  xl={{ span: 8 }}
                  lg={{ span: 8 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Overweight Permit</span>
                  <Form.Item
                    name="overWeightPermit"
                    rules={[
                      {
                        required: false,
                        message: "Please Select Required!",
                      },
                    ]}
                    initialValue={recordTruckData?.overWeightPermit}
                  >
                    <Radio.Group
                      // defaultValue={recordTruckData?.overWeightPermit}
                      onChange={this.onChangeOverWeight}
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-around",
                        padding: "8px",
                        border: "1px solid #e5e5e5",
                        height: "40px",
                        borderRadius: "5px",
                      }}
                    >
                      <Radio style={{ fontSize: "16px" }} value={"Yes"}>
                        Yes
                      </Radio>
                      <Radio style={{ fontSize: "16px" }} value={"No"}>
                        No
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                {overWeightPermitState === "Yes" && (
                  <>
                    <Col span={12} offset={4}>
                      <span className="title_changes">Date Of Expiry</span>
                      <Form.Item
                        name={"overWeightExpiryDate"}
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Date Of Expiry",
                          },
                        ]}
                        initialValue={
                          recordTruckData?.overWeightExpiryDate
                            ? moment(recordTruckData?.overWeightExpiryDate)
                            : ""
                        }
                      >
                        <DatePicker
                          placeholder="Please Select A Date"
                          style={{
                            width: "100%",
                            height: "40px",
                            borderRadius: "5px",
                          }}
                          format={"MM/DD/YYYY"}
                          // defaultValue={moment()}
                          placement={"bottomRight"}
                          allowClear
                          bordered={true}
                          // onChange={this.onChangeYear}
                          disabledDate={(current) => {
                            let customDate = moment().format("MM-DD-YYYY");
                            return (
                              current &&
                              current < moment(customDate, "MM-DD-YYYY")
                            );
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xxl={{ span: 24 }}
                      xl={{ span: 24 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      // span={24}
                    >
                      <span className="title_changes">Attachment</span>
                      <Form.Item
                        name="docAttachment"
                        rules={[
                          {
                            required: true,
                            message: "Please Attach Your Document",
                          },
                        ]}
                        initialValue={
                          quoteAttachmentId !== "" && quoteAttachmentId
                        }
                      >
                        <Spin spinning={imageLoading}>
                          <Dragger
                            onChange={this.handleUploadPic}
                            customRequest={dummyRequest}
                            listType="picture"
                            // fileList={recordDocData?.docAttachment}
                            multiple={true}
                            className="drag-ticket"
                            // beforeUpload={this.handleUploadType}
                            maxCount={1}
                            defaultFileList={
                              equipmentAttachment && [equipmentAttachment]
                            }
                          >
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                              Click or drag file to this area to upload
                            </p>
                          </Dragger>
                        </Spin>
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>
            </Form>
          </BaseModal>
        )}

        {notesPopup && (
          <BaseModal
            title={recordNotesData !== "" ? "Update Notes" : "Add New Notes"}
            onCancel={() => {
              this.setState({
                notesPopup: false,
              });
            }}
            visible={notesPopup}
            destroyOnClose={true}
            formId="addNotes"
            loading={loading}
            className="antmodal_grid headermodal"
            width={"25%"}
          >
            <Form
              onFinish={this.addNotes}
              initialValues={{ remember: true }}
              id="addNotes"
            >
              <Row gutter={[24, 0]}>
                <Col span={24}>
                  <span className="title_changes">Type of Notes</span>
                  <Form.Item
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Notes!",
                      },
                    ]}
                    initialValue={
                      recordNotesData?.typeofNotes
                        ? recordNotesData?.typeofNotes
                        : ""
                    }
                  >
                    <Input
                      style={{
                        width: "100%",
                        borderColor: "#d6d6d6",
                      }}
                      className="form-input"
                      placeholder="Please Enter Your Type Of Notes"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <span className="title_changes">Notes</span>
                  <Form.Item
                    name="notes"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Notes!",
                      },
                    ]}
                    initialValue={
                      recordNotesData?.notes ? recordNotesData?.notes : ""
                    }
                  >
                    <TextArea
                      rows={4}
                      style={{
                        width: "100%",
                        height: "75px",
                        borderColor: "#d6d6d6",
                      }}
                      className="form-input"
                      placeholder="Please Enter Your Notes"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}

        {isDocModalVisible && (
          <BaseModal
            title="Add Document"
            visible={isDocModalVisible}
            onCancel={() => {
              this.setState({
                isDocModalVisible: false,
                quoteAttachment: "",
                selectedAssignTo: "",
                documentTypeVal: "",
              });
            }}
            formId="addDocument"
            className="antmodal_grid headermodal"
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "80%"
                : "520px"
            }
            loading={loading}
            disabled={imageLoading ? true : false}
          >
            <Form onFinish={this.onFinishDoc} id="addDocument">
              <Row gutter={[32, 0]}>
                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Document Type</span>
                  <Form.Item
                    name="documentType"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Document Type",
                      },
                    ]}
                    initialValue={recordDocData?.documentType}
                  >
                    <Input
                      type="text"
                      className="form-input"
                      placeholder="Please Enter Document Type"
                    />
                  </Form.Item>
                </Col>

                {(Object.keys(recordDocData)?.length > 0 ||
                  docInsuranceInfo?.length !== 2) && (
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <Radio.Group
                      onChange={(e) =>
                        this.setState({ documentTypeVal: e?.target?.value })
                      }
                      style={{
                        width: "100%",
                        display: "flex",
                        // justifyContent: "space-between",
                        padding: "4px",
                        height: "40px",
                        borderRadius: "5px",
                      }}
                    >
                      {optionsInfo?.map(
                        (r) =>
                          docInsuranceInfo[0]?.documentTypeVal !== r.value && (
                            <Radio
                              style={{ fontSize: "16px" }}
                              value={r.value}
                              key={r.label}
                            >
                              {r.value}
                            </Radio>
                          )
                      )}
                    </Radio.Group>
                  </Col>
                )}

                {documentTypeVal === "Insurance" && (
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">
                      Insurance Expiration Date
                    </span>
                    <Form.Item
                      name="insuranceDate"
                      rules={[
                        {
                          required: true,
                          message: "Select Insurance Exipiry Date",
                        },
                      ]}
                      initialValue={
                        companyData?.insurance_expiry
                          ? moment(companyData?.insurance_expiry)
                          : ""
                      }
                    >
                      <DatePicker
                        size="large"
                        format={"MM/DD/YYYY"}
                        style={{ width: "100%", borderRadius: "5px" }}
                        placeholder="Please Select A Date"
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={24}
                >
                  <span className="title_changes">Description</span>
                  <Form.Item
                    name="description"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Description",
                      },
                    ]}
                    initialValue={recordDocData?.description}
                  >
                    <TextArea
                      rows={4}
                      style={{
                        width: "100%",
                      }}
                      className="form-input"
                      placeholder="Please Enter Your Description"
                    />
                  </Form.Item>
                </Col>

                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={24}
                >
                  <span className="title_changes">Attachment</span>
                  <Form.Item
                    name="docAttachment"
                    rules={[
                      {
                        required: false,
                        message: "Please Attach Your Document",
                      },
                    ]}
                  >
                    <Spin spinning={imageLoading}>
                      <Dragger
                        onChange={this.uploadTicketPdf}
                        customRequest={dummyRequest}
                        listType="picture"
                        multiple={true}
                        className="drag-ticket"
                        defaultFileList={recordDocData?.docAttachment}
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                          Click or drag file to this area to upload
                        </p>
                      </Dragger>
                    </Spin>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) =>
  // console.log(state),
  ({
    totalResult: state.reducer?.companyResult,
    result: state?.reducer?.companyResult?.companyData,
    companiesResult: state?.reducer?.ownerOperatorsData,
    loading: state?.reducer?.companyResult?.loading,
    errorLoading: state.reducer?.companyResult?.error,
    companiesInfo: state.reducer?.companiesInfo,
    contactsInfo: state.reducer?.contactsInfo,
    leadsInfo: state.reducer?.leadsInfo,
    leadUpdateInfo: state.reducer?.leadUpdateInfo,
    docResult: state.reducer?.docResult,
    dropDownOptions: state.reducer?.dropDownOptions,
    ownerRoles: state.reducer?.dropDownOptions?.totalData?.filter(
      (t) => t.typeofDropDown === "ownerOperatorRoles"
    ),
    trucksData: state.reducer?.dropDownOptions?.totalData?.filter(
      (t) => t.typeofDropDown === "trucksType"
    ),
    termsData: state.reducer?.dropDownOptions?.totalData?.filter(
      (t) => t.typeofDropDown === "carrierTerms"
    ),
    dropDownOptionsInfo: state.reducer?.dropDownOptionsInfo,
    totalTrucksListResult: state.reducer?.trucksListResult,
    trucksListInfo: state.reducer?.trucksListInfo,
  });

const mapDispatchToProps = (dispatch) => ({
  actionFetchOwnerOperators: () => {
    dispatch(getOwnerOperators());
  },
  actionFetchCompany: (id) => {
    dispatch(getCompany(id));
  },
  actionUpdateCompany: (id, data) => {
    dispatch(updateCompany(id, data));
  },
  actionAddDoc: (data) => {
    dispatch(addDocument(data));
  },
  actionUpdateDoc: (id, data) => {
    dispatch(updateDocument(id, data));
  },
  actionDeleteDoc: (id) => {
    dispatch(deleteDocument(id));
  },
  actiongetDropDownOptions: () => {
    dispatch(getDropDownOptions());
  },
  actionupdateDropDownOptions: (id, data) => {
    dispatch(updateDropDownOptions(id, data));
  },
  actionFetchTrucksList: () => {
    dispatch(getTrucksList());
  },
  actionAddTrucksList: (data) => {
    dispatch(addTrucksList(data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAppContext(withRouter(OwnerOperatorProfile)));
