import React, { Component } from "react";
import {
  Input,
  Row,
  Col,
  Card,
  Button,
  Form,
  Upload,
  notification,
  message,
  // Collapse,
  Progress,
  Select,
  // Tag,
  // Popover,
  // Popconfirm,
  // Table,
} from "antd";
import {
  // EllipsisOutlined,
  // CheckCircleFilled,
  PlusOutlined,
  CheckOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import Loading from "../../../Common/Loading";
import { Storage } from "aws-amplify";
import moment from "moment";
import SearchFunction from "../../../Common/SearchFunction";
import BaseModal from "../../../Common/BaseModal";
// import BaseTable from "../../../Common/BaseTable";
import { add, deleteRecord, getDetails, update } from "../../../api/Apis";
import { withAppContext } from "../../../Store/AppContext";
import { withRouter } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { BsArrowLeftShort } from "react-icons/bs";
import { PDFViewer } from "react-view-pdf";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";
// import { MdOutlineFullscreen } from "react-icons/md";
import ScrollComponent from "../../../Common/ScrollComponent";

const { Dragger } = Upload;
// const { Panel } = Collapse;
const { TextArea } = Input;
const { Option } = Select;
const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

class TicketUploadProcessing extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: "",
      boardView: true,
      listView: false,
      loading: false,
      selectionType: "",
      selectedRowKeys: [],
      selected: true,
      color: "active",
      isModalVisible: false,
      isModalVisible1: false,
      isReviewModalVisible: false,
      ticketDate: "",
      totalTicketsData: [],
      startTime: "",
      dropoffAddress: "",
      pickupAddress: "",
      reviewData: "",
      leadsData: "",
      ticketUploaded: "",
      fileList: [],
      ticketLoading: false,
      newTicket: true,
      assignedJobs: [],
      setCounter: [],
      singlePdfTicket: "",
      filterData: [],
      quantityType: "",
      isEdit: false,
      showInvoiceModal: false,
      invoiceDate: "",
      selectedTickets: [],
      ticketStatus: "",
      minValue: 0,
      maxValue: 5,
      currentPage: 1,
      perpageSize: 5,
      showApproved: true,
      showProcesssed: true,
      reviewNotes: "",
      selectedTicketsInfo: [],
      percentage: 0,
      pickUpTime: "",
      showRejectModel: false,
      ticketIndex: 0,
      showRejectData: false,
      assignTrucksInfo: [],
      assignTrucks: [],
      trucksResult: [],
    };
  }

  componentDidMount = () => {
    document.title = "Twisted Nail - Ticket Processing";
    this.getTicketInfo();
    AOS.init({
      duration: 2000,
    });
  };

  static getDerivedStateFromProps = (props, state) => {
    if (props.trucksResult?.length > 0 && state.trucksResult?.length === 0) {
      return {
        trucksResult: props?.trucksResult?.sort((a, b) =>
          a.truck_type > b.truck_type ? 1 : -1
        ),
      };
    }
    return null;
  };

  onSelectJob = () => {
    let leadsData = {};
    let jobDetails = Object.values(this.props.jobInfo)[0][0];
    // console.log(jobDetails, "jobDetails");
    leadsData["salesPersonId"] = jobDetails["salesPersonId"];
    leadsData["salesCommission"] = jobDetails["salesCommission"];
    leadsData["jobId"] = jobDetails["jobId"];
    leadsData["jobNumber"] = jobDetails["jobNumber"];
    leadsData["jobName"] = jobDetails["jobName"];
    leadsData["dropoffAddress"] = jobDetails["dropoffAddress"];
    leadsData["material"] = jobDetails["material"];
    leadsData["pickupAddress"] = jobDetails["pickup"];
    leadsData["jobType"] = jobDetails["jobType"];
    leadsData["companyId"] = jobDetails["companyId"];
    leadsData["companyName"] = jobDetails["companyName"];
    leadsData["companyAddress"] = jobDetails["companyAddress"];
    leadsData["companyPhone"] = jobDetails["companyPhone"];
    leadsData["taxExempt"] = jobDetails["taxExempt"];
    leadsData["ticketNumber"] = "";
    leadsData["ticketDate"] = "";
    leadsData["assignTrucks"] = jobDetails["material"]["assignTrucks"];
    leadsData["dispatchNotes"] = jobDetails["dispatchNotes"];
    leadsData["jobNotes"] = jobDetails["notes"];
    leadsData["quantityType"] = jobDetails["trucksRequestedType"];
    // leadsData["orderNumber"] = jobDetails["orderNumber"];
    // leadsData["orderId"] = jobDetails["id"];
    leadsData["approvedDate"] = jobDetails["approvedDate"];
    leadsData["invoiceNotes"] = jobDetails["invoiceNotes"];
    leadsData["waiveContract"] = jobDetails["othersData"]["waiveContract"] || jobDetails["waiveContract"];

    this.setState({
      leadsData,
    });
  };

  handleSucess = () => {
    message.success("Ticket uploaded sucessfully");
    this.getTicketInfo();
    this.setState({
      selectedRowKeys: [],
      showRejectData: false,
      isModalVisible: false,
      newTicket: false,
      ticketUploaded: "",
      loading: false,
      leadsData: "",
      ticketStatus: "",
      percentage: 0,
    });
  };

  getTicketInfo = () => {
    this.setState({ loading: true });
    getDetails("getMaterials", Object.keys(this.props.jobInfo)[0]).then(
      (res) => {
        if (res.success) {
          this.onSelectJob();
          let selectedTicketsInfo = res.data?.uploads?.filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                (t) =>
                  t.companyId === value.companyId &&
                  t?.ticketVerification === "Approved"
              )
          );
          const filterInfo = res.data?.uploads.filter(
            (item) => !("ticketNotes" in item)
          );
          this.setState({
            totalTicketsData: filterInfo,
            filterData: filterInfo,
            selectedTicketsInfo,
            selectedTickets: [],
            selectedRowKeys: [],
          });
          setTimeout(() => {
            this.setState({
              loading: false,
            });
          }, 1500);
        }
      }
    );
  };

  nextReview = (record, id, incrementIndex) => {
    Object.keys(record).map((e) => {
      Object.values(record[e]).map((item, index) => {
        if (item.id === id) {
          // console.log("test");
          this.nextData(record, id, incrementIndex ? index : index + 1, e);
        }
        return true;
      });
      return true;
    });
  };
  // message.error("There are no more tickets to review!");

  nextData = (record, id, currentIndexValue, keyValue) => {
    Object.keys(record).map((key, mainIndex) => {
      if (key === keyValue) {
        Object.values(record[keyValue]).map((item, index) => {
          if (index === currentIndexValue) {
            this.setState({
              leadsData: item,
              ticketUploaded: item.ticketUploadPicture,
              singlePdfTicket: item.singlePdfTicket,
              ticketIndex: index + 1,
            });
            this.formRef.current.setFieldsValue({
              uploadStatus: item?.uploadStatus ? item?.uploadStatus : "",
              // reviewNotes: item.reviewNotes ? item.reviewNotes : "",
            });
          }
          return true;
        });
        // console.log(record[keyValue].length, currentIndexValue);
        if (record[keyValue].length === currentIndexValue) {
          if (mainIndex + 1 === Object.keys(record).length) {
            this.setState({
              isModalVisible: false,
              loading: false,
            });
            message.info("There are no more tickets to review!");
            return true;
          }
          Object.keys(record).map((val, ind) => {
            if (ind === mainIndex + 1) {
              // console.log(ind, mainIndex + 1, "chek");
              Object.values(record[val]).map((item, index) => {
                if (index === 0) {
                  message.info(
                    "Reviewing tickets in this job has completed and moved to next job !"
                  );
                  this.nextReview(record, item.id, true);
                }
                return true;
              });
              return true;
            }
            return true;
          });
        }
      }
      return true;
    });
  };

  next = (data) => {
    setTimeout(() => {
      let indexValue = this.state.totalTicketsData.findIndex(
        (item) => String(item.id) === String(this.state.leadsData.id)
      );
      // console.log(indexValue, this.state.totalTicketsData.length - 1);
      if (indexValue === this.state.totalTicketsData.length - 1) {
        message.error("You have reached to the end of tickets list!");
        return;
      } else if (indexValue !== this.state.totalTicketsData.length - 1) {
        this.setState({
          leadsData: "",
        });
        let currentIndexValue = indexValue + 1;
        this.state.totalTicketsData.map((t, index) => {
          if (currentIndexValue === index) {
            this.setState({
              leadsData: t,
              ticketUploaded: t.ticketUploadPicture,
              singlePdfTicket: t.singlePdfTicket,
            });
          }
          return true;
        });
      }
    }, 500);
    this.getTicketInfo();
  };

  uploadModal = () => {
    const { leadsData } = this.state;
    leadsData["pickupVal"] = "";
    leadsData["uploadStatus"] = "";
    this.setState({
      isModalVisible: true,
      leadsData,
      isEdit: false,
      percentage: 0,
      ticketUploaded: "",
      singlePdfTicket: "",
      newTicket: true,
      reviewNotes: "",
    });
  };

  showModal = (record) => {
    const { showApproved } = this.state;
    getDetails("getMaterials", Object.keys(this.props.jobInfo)[0]).then(
      (res) => {
        let filterData = [];
        const filterInfo = res.data?.uploads.filter(
          (item) => !("ticketNotes" in item)
        );
        if (res.success) {
          if (showApproved) {
            filterData = filterInfo?.filter((e) => e.uploadStatus !== "Closed");
          } else {
            filterData = filterInfo;
          }
          filterData?.forEach((element, index) => {
            if (element.id === record.id) {
              this.setState({
                loading: false,
                isModalVisible: true,
                newTicket: false,
                showRejectData: false,
                ticketUploaded: element.ticketUploadPicture,
                singlePdfTicket: element.singlePdfTicket,
                quantityType: element.quantityType,
                leadsData: element,
                isEdit: true,
                ticketIndex: index + 1,
              });
            }
          });
        }
      }
    );
  };

  handleUploadType = (file) => {
    let isPDF = file.type === "application/pdf";
    if (!isPDF) {
      notification["error"]({
        message: `${file.name} is not a PDf file`,
      });
      this.setState({
        ticketUploaded: "",
        singlePdfTicket: "",
        ticketLoading: false,
      });
      return Upload.LIST_IGNORE;
    }
  };

  uploadTicketPdf = async (info) => {
    const { leadsData, showRejectData } = this.state;
    this.setState({
      ticketLoading: true,
    });
    switch (info.file.status) {
      case "done":
        this.setState({
          fileList: info.fileList,
        });
        if (info.fileList.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              let ticketUploadPicture = info["file"]["uid"] + "_TNBS_ticket";
              Storage.put(ticketUploadPicture, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                if (leadsData?.uploadStatus === "Open" || showRejectData) {
                  let singlePdfTicket = leadsData.singlePdfTicket.replace(
                    leadsData.ticketUploadPicture,
                    result.key
                  );
                  Storage.put(
                    singlePdfTicket.replace("public/", ""),
                    info.file.originFileObj,
                    {
                      contentType: info.file.type,
                    }
                  ).then((result) => {
                    this.setState(
                      {
                        singlePdfTicket: singlePdfTicket,
                      },
                      this.settime()
                    );
                  });
                }
                this.setState(
                  {
                    ticketUploaded: result.key,
                    ticketLoading: false,
                    percentage: info?.file?.percent,
                    showRejectData: false,
                  },
                  this.settime()
                );
              });
            } else {
              this.setState({
                ticketLoading: false,
                percentage: 0,
              });
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;

      case "removed":
        this.setState({
          ticketUploaded: "",
          ticketLoading: false,
          percentage: 0,
        });
        break;
      default:
        this.setState(
          {
            ticketLoading: false,
            percentage: 0,
          },
          this.settime()
        );
    }
  };

  fileDownloadQuote = async (fileName) => {
    Storage.get(fileName, { download: true })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    this.downloadBlobQuote(fileName);
  };

  downloadBlobQuote(fileName) {
    const url = `https://${process.env.REACT_APP_S3_BUCKET}.s3.us-east-1.amazonaws.com/${fileName}`;
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.download = fileName || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    this.setState({
      loading: false,
    });
    return a;
  }

  handleToDeleteTicket = (record) => {
    this.setState({ loading: true });
    deleteRecord("ticketProcess", record.id)
      .then((deleteRes) => {
        if (deleteRes.success) {
          this.getTicketInfo();
        }
      })
      .catch((error) => {
        console.log("catchEror", error);
        this.setState({ loading: false });
      });
  };

  settime = () => {
    setTimeout(() => {
      this.setState({ percentage: this.state.percentage + 40 });
    }, 1000);
  };

  searchResult = (searchValue) => {
    const { totalTicketsData } = this.state;
    let searchFilter = [];
    if (typeof searchValue === "number") {
      searchFilter = totalTicketsData.filter(
        (e) => parseInt(e["jobNumber"]) === searchValue
      );
    } else {
      searchFilter = totalTicketsData.filter(
        (e) => e["companyName"]?.toLowerCase().indexOf(searchValue) > -1
      );
    }
    this.setState({
      filterData: searchFilter,
    });
  };

  handlePagination = async (page, pageSize, load) => {
    if (page <= 1) {
      this.setState({
        minValue: 0,
        maxValue: pageSize,
      });
    } else {
      this.setState({
        minValue: (page - 1) * pageSize,
        maxValue: page * pageSize,
      });
    }
    this.setState({
      currentPage: page,
      perpageSize: pageSize,
    });
  };

  onResetData = () => {
    this.getTicketInfo();
  };

  handleTableChage = (props, type) => {
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    localStorage.setItem(
      "tableSizeRange",
      JSON.stringify({ ...tableSizeRange, [type]: props })
    );
  };

  onFinish = (values, jobData, loginType) => {
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const {
      newTicket,
      ticketUploaded,
      leadsData,
      singlePdfTicket,
      assignTrucks,
      assignTrucksInfo,
      selectedRowKeys,
    } = this.state;
    let data = {
      entityData: {},
    };
    values["othersData"] = {
      waiveContract: leadsData?.waiveContract,
    };
    if (
      // loginType === "open" &&
      leadsData?.uploadStatus === "Closed" &&
      Object.keys(jobData)?.length > 0
    ) {
      this.nextReview(jobData, leadsData.id, false);
    } else {
      this.setState({
        loading: true,
      });
      try {
        if (!newTicket && ticketUploaded !== "" && leadsData.id !== "") {
          if (
            leadsData["uploadStatus"] === "Open" &&
            leadsData.ticketUploadPicture === ticketUploaded
          ) {
            notification["error"]({
              message: "Please upload new ticket, Before submit",
            });
            this.setState({
              loading: false,
            });
            return true;
          } else {
            values["uploadStatus"] =
              leadsData?.uploadStatus === "Review"
                ? "Closed"
                : leadsData?.uploadStatus === "Open"
                ? "Review"
                : "Open";
            values["updatedBy"] = userDetails?.id;
            values["singlePdfTicket"] = singlePdfTicket;
            values["ticketUploadPicture"] = ticketUploaded;
            data["entityData"] = values;
            update("ticketProcess", leadsData.id, data).then((updateRes) => {
              let result = updateRes.data.data;
              Object.values(jobData)[0]?.map((item, index) => {
                if (item.id === result.id) {
                  item["uploadStatus"] = result["uploadStatus"];
                }
                return true;
              });

              if (updateRes.success) {
                message.success("Ticket status updated sucessfully");
                this.setState({
                  showRejectData: false,
                  loading: false,
                  reviewNotes: "",
                  ticketStatus: "",
                  pickUpTime: "",
                  percentage: 0,
                });
                if (Object.keys(jobData).length > 0) {
                  this.nextReview(jobData, leadsData.id, false);
                } else {
                  this.next(updateRes.data);
                }
              }
            });
          }
        } else {
          let newMaterial = leadsData?.material[0];
          if (assignTrucks?.length && assignTrucksInfo?.length) {
            let newMaterial = leadsData?.material[0];
            // const combineTruck = newMaterial?.assignTrucks.concat(assignTrucks);
            // const combineTruckInfo =
            //   newMaterial?.assignTrucksInfo.concat(assignTrucksInfo);
            newMaterial["assignTrucks"] = assignTrucks;
            newMaterial["assignTrucksInfo"] = assignTrucksInfo;
          }
          values["invoiceNotes"] = leadsData?.invoiceNotes;
          values["approvedDate"] = leadsData?.approvedDate;
          // values["orderId"] = leadsData?.orderId;
          // values["orderNumber"] = leadsData?.orderNumber;
          values["quantityType"] = leadsData.quantityType;
          values["taxExempt"] = leadsData?.taxExempt;
          values["jobType"] = leadsData?.jobType;
          values["jobNumber"] = leadsData?.jobNumber;
          values["salesPersonId"] = leadsData?.salesPersonId;
          values["salesCommission"] = leadsData?.salesCommission;
          values["jobName"] = leadsData?.jobName;
          values["companyName"] = leadsData?.companyName;
          values["companyId"] = leadsData?.companyId;
          values["companyAddress"] = leadsData?.companyAddress;
          values["companyPhone"] = leadsData?.companyPhone;
          values["material"] = [newMaterial];
          values["dropoffAddress"] = leadsData?.dropoffAddress;
          values["selectedMaterial"] = [];
          values["totalPrice"] = 0;
          values["pickupAddress"] = "";
          values["ticketVerification"] = "Open";
          values["ticketUploadPicture"] = ticketUploaded;
          values["jobId"] = leadsData.jobId;
          values["materialId"] = leadsData?.material[0]?.id;
          values["createdBy"] = userDetails?.id;
          values["uploadDate"] = moment().format("L");
          values["uploadStatus"] = "Review";
          data["entityData"] = values;
          // console.log(data, "data");
          if (newTicket && ticketUploaded !== "" && leadsData !== "") {
            add("addTicketProcess", data).then((addRes) => {
              if (addRes.success) {
                if (selectedRowKeys?.length > 0) {
                  let newData = {
                    entityData: {
                      ticketIds: selectedRowKeys,
                    },
                  };
                  add("deleteMultipleTickets", newData).then((addRes) => {
                    if (addRes.success) {
                      this.handleSucess();
                    }
                  });
                } else {
                  this.handleSucess();
                }
              }
            });
          } else {
            notification["error"]({
              message: "Please upload ticket & Select Job, Before submit",
            });
            this.setState({
              loading: false,
            });
          }
        }
      } catch (error) {
        console.log("catchError", error);
      }
    }
  };

  onReject = (jobData) => {
    this.setState({
      showRejectData: true,
      percentage: 0,
    });
    // const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    // const { leadsData } = this.state;
    // let data = {
    //   entityData: {
    //     uploadStatus: "Open",
    //     updatedBy: userDetails?.id,
    //   },
    // };

    // update("ticketProcess", leadsData.id, data).then((updateRes) => {
    //   message.success("Ticket rejected sucessfully");
    //   let result = updateRes.data.data;
    //   Object.keys(jobData).map((e) => {
    //     if (e === result.orderId) {
    //       Object.values(jobData[result.orderId]).map((item, index) => {
    //         if (item.id === result.id) {
    //           item["uploadStatus"] = result["uploadStatus"];
    //           // item["reviewNotes"] = result["reviewNotes"];
    //         }
    //         return true;
    //       });
    //     }
    //     return true;
    //   });

    //   if (updateRes.success) {
    //     this.setState({
    //       loading: false,
    //       reviewNotes: "",
    //       ticketStatus: "",
    //       pickUpTime: "",
    //     });
    //     if (Object.keys(jobData).length > 0) {
    //       this.nextReview(jobData, leadsData.id, false);
    //     } else {
    //       this.next(updateRes.data);
    //     }
    //   }
    // });
  };

  onSelectChange = (data) => {
    this.setState({ selectedRowKeys: data });
  };

  handleAssignChange = (trucks, trucksInfo) => {
    this.setState({
      assignTrucks: [trucks],
      assignTrucksInfo: [trucksInfo?.assignTrucksInfo],
    });
  };

  handleReuploadAll = () => {
    const { filterData } = this.state;
    const selectedRowKeys = filterData?.map((e) => e.id);
    this.setState({
      selectedRowKeys,
    });
    if (selectedRowKeys?.length > 0) {
      this.uploadModal();
    } else {
      message.error("Please select atleast one ticket!");
    }
  };

  handleSubmitAll = () => {
    this.setState({
      loading: true,
    });
    const { filterData } = this.state;
    const selectedRowKeys = filterData?.map((e) => e.id);
    let newData = {
      entityData: {
        ticketIds: selectedRowKeys,
      },
    };
    try {
      add("updateMultipleTickets", newData).then((addRes) => {
        if (addRes.success) {
          this.handleSucess();
        }
      });
    } catch {
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    const {
      trucksResult,
      showRejectData,
      ticketIndex,
      totalTicketsData,
      loading,
      leadsData,
      isEdit,
      isModalVisible,
      ticketUploaded,
      newTicket,
      singlePdfTicket,
      ticketLoading,
      filterData,
      // minValue,
      // maxValue,
      percentage,
      //   ticketStatus,
      // showProcesssed,
      showApproved,
      // selectedRowKeys,
    } = this.state;

    const Controls = () => {
      const { resetTransform } = useControls();

      return (
        <div className="tools">
          {/* <span class="custom-button" onClick={() => zoomIn()}>
            Zoom In
          </span>
          <span class="custom-button1" onClick={() => zoomOut()}>
            Zoom Out
          </span> */}
          <span class="custom-button2" onClick={() => resetTransform()}>
            Reset
          </span>
        </div>
      );
    };

    const { finaceInfo } = this.props;
    let tableData = filterData;
    let jobInfo = {},
      loginType = "";
    if (finaceInfo && finaceInfo[0]?.selectedOptions?.includes("Edit")) {
      tableData = filterData.filter((t) => t?.uploadStatus !== "Closed");
    } else if (
      finaceInfo &&
      finaceInfo[0]?.selectedOptions?.includes("Review")
    ) {
      tableData = filterData.filter((t) => t?.uploadStatus === "Review");
      loginType = "review";
    } else if (
      finaceInfo &&
      finaceInfo[0]?.selectedOptions?.includes("Data Input")
    ) {
      tableData = filterData.filter(
        (t) => t?.uploadStatus !== "Review" && t?.uploadStatus !== "Closed"
      );
      loginType = "open";
    }
    tableData.map((a) => {
      if (showApproved) {
        if (a.mid === Object.keys(this.props.jobInfo)[0]) {
          jobInfo[Object.keys(this.props.jobInfo)[0]] =
            Object.keys(this.props.jobInfo)[0] in jobInfo === true
              ? jobInfo[Object.keys(this.props.jobInfo)[0]]
              : [];
          jobInfo[Object.keys(this.props.jobInfo)[0]].push(a);
          return true;
        }
      } else {
        if (a.mid === Object.keys(this.props.jobInfo)[0]) {
          jobInfo[Object.keys(this.props.jobInfo)[0]] =
            Object.keys(this.props.jobInfo)[0] in jobInfo === true
              ? jobInfo[Object.keys(this.props.jobInfo)[0]]
              : [];
          jobInfo[Object.keys(this.props.jobInfo)[0]].push(a);
          return true;
        }
      }
      return true;
    });
    // const rowSelection = {
    //   selectedRowKeys,
    //   selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
    //   onChange: this.onSelectChange,
    // };

    //  const columns = [
    //   {
    //     title: "UPLOAD DATE",
    //     dataIndex: "uploadDate",
    //     key: "uploadDate",
    //     sorter: false,
    //     showTable: true,
    //     className: "col_styling",
    //     render: (uploadDate) => {
    //       return (
    //         <span
    //           className="col_styling table-font-mobile-accounts"
    //           style={{
    //             textTransform: "capitalize",
    //             width: "10px",
    //           }}
    //         >
    //           <Row style={{ color: "#383838" }}>
    //             {moment(uploadDate).format("l")}
    //           </Row>
    //         </span>
    //       );
    //     },
    //   },
    //   {
    //     title: "JOB #",
    //     dataIndex: "jobNumber",
    //     key: "jobNumber",
    //     sorter: false,
    //     showTable: true,
    //     className: "col_styling",
    //     // width: "8%",
    //     render: (jobNumber) => {
    //       return (
    //         <span
    //           className="col_styling table-font-mobile-accounts"
    //           style={{
    //             textTransform: "capitalize",
    //             width: "10px",
    //           }}
    //         >
    //           <Row style={{ color: "#383838" }}>{jobNumber}</Row>
    //         </span>
    //       );
    //     },
    //   },
    //   {
    //     title: "JOB NAME",
    //     dataIndex: "jobName",
    //     key: "jobName",
    //     sorter: false,
    //     showTable: true,
    //     className: "col_styling",
    //     // width: "8%",
    //     render: (jobName) => {
    //       return (
    //         <span
    //           className="col_styling table-font-mobile-accounts"
    //           style={{
    //             textTransform: "capitalize",
    //             width: "10px",
    //           }}
    //         >
    //           <Row style={{ color: "#383838" }}>{jobName}</Row>
    //         </span>
    //       );
    //     },
    //   },
    //   {
    //     title: "VERIFICATION",
    //     dataIndex: "uploadStatus",
    //     key: "uploadStatus",
    //     sorter: false,
    //     className: "col_styling",
    //     showTable: true,
    //     render: (tag2, record) => {
    //       return (
    //         <Tag
    //           style={{
    //             background: "transparent",
    //             border: "none",
    //             height: "40px",
    //             fontSize: "16px",
    //             borderRadius: "5px",
    //             padding: "0px",
    //             color:
    //               tag2 === "Open"
    //                 ? "#0058e0"
    //                 : tag2 === "Pending"
    //                 ? "#d16a00"
    //                 : tag2 === "Approved"
    //                 ? "#00b02e"
    //                 : tag2 === "Invoiced" && "#de0000",
    //           }}
    //           className="col_styling table-font-mobile-accounts"
    //           key={tag2}
    //         >
    //           {tag2 === "Review" ? (
    //             <Button
    //               type="button"
    //               onClick={() => this.showModal(record)}
    //               style={{
    //                 height: "40px",
    //                 backgroundColor: "#586370",
    //                 borderRadius: "5px",
    //                 border: "none",
    //                 cursor: "pointer",
    //                 width: "fit-content",
    //                 color: "#FFF",
    //                 fontWeight: "600",
    //               }}
    //               className="ticket-uplaod-tags-button"
    //             >
    //               <span
    //                 style={{
    //                   fontWeight: "600",
    //                   fontFamily: "Poppins, sans-serif",
    //                   fontSize: "15px",
    //                 }}
    //               >
    //                 {"Quailty Check"}
    //               </span>
    //             </Button>
    //           ) : tag2 === "Closed" ? (
    //             <div
    //               style={{
    //                 display: "flex",
    //                 justifyContent: "space-around",
    //                 alignItems: "center",
    //                 height: "40px",
    //                 fontWeight: "600",
    //               }}
    //               onClick={() => this.showModal(record)}
    //             >
    //               <CheckCircleFilled style={{ marginRight: "5px" }} />
    //               <span>{tag2}</span>
    //             </div>
    //           ) : (
    //             tag2 === "Open" && (
    //               <div
    //                 style={{
    //                   display: "flex",
    //                   justifyContent: "space-around",
    //                   alignItems: "center",
    //                   height: "40px",
    //                 }}
    //               >
    //                 <Button
    //                   type="button"
    //                   onClick={() => this.showModal(record)}
    //                   style={{
    //                     height: "40px",
    //                     backgroundColor: "#f78d8d",
    //                     borderRadius: "5px",
    //                     border: "none",
    //                     cursor: "pointer",
    //                     width: "140px",
    //                     color: "#ffffff",
    //                   }}
    //                 >
    //                   <span
    //                     style={{
    //                       fontWeight: "600",
    //                       fontFamily: "Poppins, sans-serif",
    //                       fontSize: "16px",
    //                     }}
    //                   >
    //                     {"Reupload"}
    //                   </span>
    //                 </Button>
    //               </div>
    //             )
    //           )}
    //         </Tag>
    //       );
    //     },
    //   },
    //   {
    //     title: "ACTION",
    //     dataIndex: "id",
    //     key: "",
    //     sorter: false,
    //     // width: "75px",
    //     fixed: "right",
    //     showTable:
    //       finaceInfo &&
    //       finaceInfo[0]?.selectedOptions.includes("Data Input") &&
    //       finaceInfo[0]?.selectedOptions.includes("Edit")
    //         ? true
    //         : false,
    //     render: (action, record) => {
    //       return (
    //         <>
    //           <Col>
    //             <Popover
    //               placement="bottom"
    //               content={
    //                 <>
    //                   <Row className="popovergrid">
    //                     <Col span={24}>
    //                       <Button
    //                         style={{
    //                           fontSize: "15px",
    //                           cursor: "pointer",
    //                           border: "none",
    //                           padding: "0px",
    //                           textAlign: "left",
    //                           width: "100%",
    //                           height: "auto",
    //                         }}
    //                         onClick={() => this.showModal(record)}
    //                       >
    //                         <span
    //                           style={{
    //                             color: "black",
    //                             display: "flex",
    //                             flexWrap: "wrap",
    //                             alignItems: "center",
    //                             justifyContent: "left",
    //                           }}
    //                         >
    //                           <MdModeEditOutline
    //                             style={{ marginRight: "5px" }}
    //                           />
    //                           Edit
    //                         </span>
    //                       </Button>
    //                     </Col>
    //                     <Col span={24}>
    //                       <Button
    //                         style={{
    //                           fontSize: "15px",
    //                           cursor: "pointer",
    //                           border: "none",
    //                           padding: "0px",
    //                           textAlign: "left",
    //                           width: "100%",
    //                           height: "auto",
    //                         }}
    //                       >
    //                         <Popconfirm
    //                           title="Are you sure？"
    //                           okText="Yes"
    //                           cancelText="No"
    //                           onConfirm={() =>
    //                             this.handleToDeleteTicket(record)
    //                           }
    //                         >
    //                           <span
    //                             style={{
    //                               color: "black",
    //                               display: "flex",
    //                               flexWrap: "wrap",
    //                               alignItems: "center",
    //                               justifyContent: "left",
    //                             }}
    //                           >
    //                             <MdDelete style={{ marginRight: "5px" }} />{" "}
    //                             Delete
    //                           </span>
    //                         </Popconfirm>
    //                       </Button>
    //                     </Col>
    //                   </Row>
    //                 </>
    //               }
    //             >
    //               <EllipsisOutlined
    //                 style={{ fontSize: "35px", color: "grey" }}
    //                 className="action-ellipsis-button"
    //               />
    //             </Popover>
    //           </Col>
    //         </>
    //       );
    //     },
    //   },
    // ].filter((e) => e.showTable === true);

    const searchFields = ["ticketNumber", "jobNumber", "companyName"];
    return (
      <div
        style={{
          background: "#fafafa",
        }}
      >
        <Loading enableLoading={loading} />

        {finaceInfo && finaceInfo.length > 0 && (
          <Row style={{ marginBottom: "1vw" }}>
            <Col
              xxl={{ span: 24 }}
              xl={{ span: 24 }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              {finaceInfo &&
              finaceInfo[0]?.selectedOptions?.includes("Edit") ? (
                <Row
                  style={{
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    margin: "20px 0px",
                    padding: "0px 20px",
                    backgroundColor: "#ffffff",
                    border: "1px solid gray",
                    textAlign: "left",
                  }}
                  gutter={[12, 0]}
                >
                  <Col
                    xxl={{ span: 8 }}
                    xl={{ span: 8 }}
                    lg={{ span: 8 }}
                    md={{ span: 12 }}
                    sm={{ span: 12 }}
                    xs={{ span: 12 }}
                    style={{ margin: "12px 0px" }}
                  >
                    <p
                      style={{
                        fontSize: "26px",
                        fontWeight: "bold",
                        marginBottom: "0px",
                      }}
                      className="owner-operator-card-body"
                    >
                      <span
                        style={{
                          fontSize: "18px",
                          color: "#747474",
                          fontWeight: "600",
                          width: "100%",
                          display: "flex",
                          flexWrap: "nowrap",
                        }}
                        className="owner-operator-card-head"
                      >
                        Quailty Check
                      </span>
                      {parseInt(
                        this.state.totalTicketsData.filter(
                          (e) => e.uploadStatus === "Review"
                        ).length
                      )}
                    </p>
                  </Col>
                  <Col
                    xxl={{ span: 8 }}
                    xl={{ span: 8 }}
                    lg={{ span: 8 }}
                    md={{ span: 12 }}
                    sm={{ span: 12 }}
                    xs={{ span: 12 }}
                    style={{ margin: "12px 0px" }}
                  >
                    <p
                      style={{
                        fontSize: "26px",
                        fontWeight: "bold",
                        marginBottom: "0px",
                      }}
                      className="owner-operator-card-body"
                    >
                      <span
                        style={{
                          fontSize: "18px",
                          color: "#747474",
                          fontWeight: "600",
                          width: "100%",
                          display: "flex",
                          flexWrap: "nowrap",
                        }}
                        className="owner-operator-card-head"
                      >
                        Reupload
                      </span>
                      {parseInt(
                        this.state.totalTicketsData.filter(
                          (e) => e.uploadStatus === "Open"
                        ).length
                      )}
                    </p>
                  </Col>
                  <Col
                    xxl={{ span: 8 }}
                    xl={{ span: 8 }}
                    lg={{ span: 8 }}
                    md={{ span: 12 }}
                    sm={{ span: 12 }}
                    xs={{ span: 12 }}
                    style={{ margin: "12px 0px" }}
                  >
                    <p
                      style={{
                        fontSize: "26px",
                        fontWeight: "bold",
                        marginBottom: "0px",
                      }}
                      className="owner-operator-card-body"
                    >
                      <span
                        style={{
                          fontSize: "18px",
                          color: "#747474",
                          fontWeight: "600",
                          width: "100%",
                          display: "flex",
                          flexWrap: "nowrap",
                        }}
                        className="owner-operator-card-head"
                      >
                        Closed
                      </span>
                      {parseInt(
                        this.state.totalTicketsData.filter(
                          (e) => e.uploadStatus === "Closed"
                        ).length
                      )}
                    </p>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 14 }}
              xl={{ span: 14 }}
              xxl={{ span: 14 }}
            >
              <Row>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 10 }}
                  xl={{ span: 10 }}
                  xxl={{ span: 10 }}
                  className="quarrytoolgrid"
                >
                  <SearchFunction
                    tableName={"ticketProcess"}
                    getSearchData={this.searchResult}
                    onResetData={this.onResetData}
                    searchFields={searchFields}
                  />
                </Col>
              </Row>
            </Col>

            <Col
              s={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 10 }}
              xl={{ span: 10 }}
              xxl={{ span: 10 }}
              // span={12}
            >
              <Row style={{ justifyContent: "right" }}>
                {finaceInfo &&
                finaceInfo[0]?.selectedOptions?.includes("Edit") ? (
                  <>
                    <Col className="gridbtntool">
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        style={{
                          borderRadius: "5px",
                          height: "40px",
                        }}
                        onClick={this.uploadModal}
                      >
                        <span style={{ fontWeight: "500", fontSize: "16px" }}>
                          Upload Ticket
                        </span>
                      </Button>
                    </Col>
                    <Col className="gridbtntool">
                      <Button
                        className="hover_style"
                        style={{
                          borderRadius: "5px",
                          height: "40px",
                          border: "none",
                        }}
                        onClick={() =>
                          this.setState({
                            showApproved: !showApproved,
                          })
                        }
                      >
                        <CheckOutlined
                          style={{
                            color: "#586370",
                            fontSize: "17px",
                            fontWeight: "500",
                          }}
                        />
                        <span
                          style={{
                            color: "#586370",
                            fontSize: "17px",
                            fontWeight: "500",
                          }}
                        >
                          {showApproved ? "Show Closed" : "Hide Closed"}
                        </span>
                      </Button>
                    </Col>
                  </>
                ) : finaceInfo[0]?.selectedOptions?.includes("Review") ? (
                  <Col className="gridbtntool">
                    <Button
                      className="hover_style"
                      style={{
                        borderRadius: "5px",
                        height: "40px",
                        border: "none",
                      }}
                      onClick={() =>
                        this.setState({
                          showApproved: !showApproved,
                        })
                      }
                    >
                      <CheckOutlined
                        style={{
                          color: "#586370",
                          fontSize: "17px",
                          fontWeight: "500",
                        }}
                      />
                      <span
                        style={{
                          color: "#586370",
                          fontSize: "17px",
                          fontWeight: "500",
                        }}
                      >
                        {showApproved ? "Show Closed" : "Hide Closed"}
                      </span>
                    </Button>
                  </Col>
                ) : finaceInfo[0]?.selectedOptions?.includes("Data Input") ? (
                  <Col className="gridbtntool">
                    <Button
                      className="hover_style"
                      style={{
                        borderRadius: "5px",
                        height: "40px",
                        border: "none",
                      }}
                      onClick={() =>
                        this.setState({
                          showApproved: !showApproved,
                        })
                      }
                    >
                      <CheckOutlined
                        style={{
                          color: "#586370",
                          fontSize: "17px",
                          fontWeight: "500",
                        }}
                      />
                      <span
                        style={{
                          color: "#586370",
                          fontSize: "17px",
                          fontWeight: "500",
                        }}
                      >
                        {this.state.showProcesssed
                          ? "Show Processsed"
                          : "Hide Processsed"}
                      </span>
                    </Button>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </Col>
          </Row>
        )}

        <Row
          style={{
            marginBottom: "1vw",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Row
            onClick={() => this.props.handleCancel()}
            style={{ cursor: "pointer" }}
          >
            <BsArrowLeftShort
              style={{ color: "#5f6c74", fontSize: "25px" }}
              className="back-button-return-icon"
            />
            <p
              style={{ color: "#808080", fontSize: "16px", fontWeight: "600" }}
              className="back-button-return"
            >
              Return
            </p>
          </Row>
        </Row>
        <Row>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Card bodyStyle={{ padding: "0px" }} data-aos="fade-up">
              {Object.keys(jobInfo).length > 0 ? (
                <Card
                  title={
                    <span>
                      <span className="detail-heading table-font-mobile">
                        Job# {Object.values(jobInfo)[0][0]?.jobNumber}
                      </span>
                      <span
                        style={{
                          marginLeft: "20px",
                          marginRight: "10px",
                          color: "#666666",
                          fontSize: "18px",
                        }}
                        className="detail-heading table-font-mobile"
                      >
                        Customer:
                      </span>
                      <span
                        style={{ fontWeight: 500 }}
                        className="detail-heading table-font-mobile"
                      >
                        {Object.values(jobInfo)[0][0]?.companyName}
                      </span>

                      <span
                        style={{
                          marginLeft: "20px",
                          marginRight: "10px",
                          color: "#666666",
                          fontSize: "18px",
                        }}
                        className="detail-heading table-font-mobile"
                      >
                        Material:
                      </span>
                      <span
                        style={{ fontWeight: 500 }}
                        className="detail-heading table-font-mobile"
                      >
                        {Object.values(jobInfo)[0][0]?.jobType ===
                        "Trucking Job"
                          ? Object.values(jobInfo)[0][0]?.material[0]
                              ?.pickUpAddress
                          : Object.values(jobInfo)[0][0]?.material[0]?.vendor +
                            " - " +
                            Object.values(
                              jobInfo
                            )[0][0]?.material[0]?.label?.split(" - ")[1]}
                      </span>
                    </span>
                  }
                  extra={
                    <div style={{ display: "flex", width: "100%" }}>
                      <Col
                        className="gridbtntool"
                        style={{ marginLeft: "auto" }}
                      >
                        <Button
                          style={{
                            height: "40px",
                            borderRadius: "5px",
                            marginRight: "25px",
                            cursor: "pointer",
                            color: "#000",
                            fontSize: "30px",
                            alignItems: "center",
                            background: "#00800417",
                            justifyContent: "center",
                            display: "flex",
                            border: " 1px solid #03c503",
                            float: "left",
                            fontWeight: "bold",
                          }}
                          onClick={this.handleSubmitAll}
                        >
                          <span
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Submit for data input
                          </span>
                        </Button>
                      </Col>
                      <Col className="gridbtntool">
                        <Button
                          style={{
                            height: "40px",
                            borderRadius: "5px",
                            cursor: "pointer",
                            color: "red",
                            fontSize: "30px",
                            alignItems: "center",
                            background: "#ff00000d",
                            justifyContent: "center",
                            display: "flex",
                            border: " 1px solid red",
                            float: "left",
                            fontWeight: "bold",
                          }}
                          onClick={this.handleReuploadAll}
                        >
                          <span
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Reject & Reupload All
                          </span>
                        </Button>
                      </Col>
                    </div>
                  }
                >
                  <ScrollComponent
                    images={Object.values(jobInfo)[0]}
                    showModal={(img) => this.showModal(img)}
                  />{" "}
                  {/* <BaseTable
                    className="financeticket_table table_laptopscreen"
                    columnsData={columns}
                    source={
                      dateRestriction
                        ? Object.values(jobInfo[e])?.filter((t) =>
                            moment(t?.uploadDate).isBetween(
                              moment(dateRestriction?.split("-")[0])
                                .subtract(1, "day")
                                ?.format("l"),
                              moment(dateRestriction?.split("-")[1])
                                .add(1, "day")
                                ?.format("l")
                            )
                          )
                        : Object.values(jobInfo[e])
                    }
                    rowSelection={rowSelection}
                    total={Object.values(jobInfo[e]).length}
                    rowKey={(record) => record.id}
                    handleTableChage={(e) =>
                      this.handleTableChage(e, "ticketUpload")
                    }
                    sizeRange={
                      window.screen.width > 1023
                        ? JSON.parse(
                            localStorage.getItem("tableSizeRange")
                          )?.ticketUpload
                        : Object.values(jobInfo[e]).length
                    }
                    pagination={window.screen.width > 1023 ? true : false}
                  /> */}
                </Card>
              ) : (
                <Card
                  style={{ padding: "150px", textAlign: "center" }}
                  className="no-items-card"
                >
                  <span style={{ fontSize: "20px" }} className="no-items-card">
                    No Tickets Found!
                  </span>
                </Card>
              )}
            </Card>
          </Col>

          {isModalVisible && (
            <BaseModal
              className="tickectfinan_modal antmodal_grid headermodal"
              title={
                !newTicket && ticketUploaded
                  ? leadsData?.uploadStatus === "Closed"
                    ? `View Details ${ticketIndex} / ${totalTicketsData?.length}`
                    : `Edit Details ${ticketIndex} / ${
                        totalTicketsData?.filter(
                          (e) => e.uploadStatus !== "Closed"
                        )?.length
                      }`
                  : "Upload Ticket"
              }
              onCancel={() =>
                this.setState({
                  isModalVisible: false,
                  newTicket: true,
                  ticketUploaded: "",
                  percentage: 0,
                  ticketStatus: "",
                  showRejectData: false,
                })
              }
              onReject={() => {
                this.onReject(jobInfo);
              }}
              formId="ticketForm"
              loading={loading}
              rejectButton={
                leadsData["uploadStatus"] === "Review" ? true : false
              }
              submitButton={
                isEdit
                  ? leadsData?.uploadStatus === "Review"
                    ? "Submit for data input"
                    : leadsData?.uploadStatus === "Open"
                    ? "Submit for review"
                    : "Next"
                  : "Quailty Check"
              }
              width={
                window.screen.width > "501" && window.screen.width <= "991"
                  ? "80%"
                  : "900px"
              }
              // btnWidth="200px"
              btnHeight="40px"
              disabled={ticketLoading ? true : false}
            >
              <Form
                id="ticketForm"
                onFinish={(e) => this.onFinish(e, jobInfo, loginType)}
                ref={this.formRef}
              >
                {!newTicket && ticketUploaded !== "" && (
                  // leadsData["uploadStatus"] !== "Open" && (
                  <Col
                    xxl={{ span: 24, offset: 0 }}
                    xl={{ span: 24, offset: 0 }}
                    lg={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    xs={{ span: 24, offset: 0 }}
                    className="pdfviewcls"
                  >
                    <TransformWrapper
                      initialScale={1}
                      initialPositionX={1}
                      initialPositionY={1}
                    >
                      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                        <React.Fragment>
                          <Controls />
                          <TransformComponent>
                            <PDFViewer
                              url={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/${singlePdfTicket}`}
                              viewer="url"
                              showToolbar={false}
                              hideZoom={true}
                              style={{
                                width: "100%",
                                height: "400px",
                                overflow: "auto",
                              }}
                              onError={(error) => {
                                console.error("Error loading PDF:", error);
                              }}
                            />
                          </TransformComponent>
                        </React.Fragment>
                      )}
                    </TransformWrapper>
                  </Col>
                )}
                {newTicket && (
                  <>
                    <Row gutter={[24, 0]}>
                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 8 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                        style={{ marginTop: "1vw" }}
                      >
                        <span className="title_changes">Customer</span>
                        <Form.Item
                          name="selectCustomer"
                          initialValue={leadsData.companyName}
                        >
                          <Input
                            className="select_Height"
                            style={{
                              fontWeight: "600",
                              height: "40px",
                              width: "100%",
                            }}
                            placeholder="Search to Select Customer"
                            readOnly
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 8 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                        style={{ marginTop: "1vw" }}
                      >
                        <span className="title_changes">Select Job #</span>
                        <Form.Item
                          name="selectJob"
                          rules={[
                            {
                              required: true,
                              message: "Please Select Job!",
                            },
                          ]}
                          initialValue={"#" + leadsData?.jobNumber}
                        >
                          <Input
                            className="select_Height"
                            style={{
                              fontWeight: "600",
                              height: "40px",
                              width: "100%",
                            }}
                            placeholder="Search to Select Job"
                            readOnly
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 8 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                        style={{ marginTop: "1vw" }}
                      >
                        <span className="title_changes">Order Name</span>
                        <Form.Item
                          name="selectOrder"
                          rules={[
                            {
                              required: true,
                              message: "Please Select Order!",
                            },
                          ]}
                          initialValue={
                            leadsData.jobType === "Trucking Job"
                              ? leadsData?.material[0]?.pickUpAddress
                              : leadsData?.material[0]?.vendor +
                                " - " +
                                leadsData?.material[0]?.label?.split(" - ")[1]
                          }
                        >
                          <Input
                            className="select_Height"
                            style={{
                              fontWeight: "600",
                              height: "40px",
                              width: "100%",
                            }}
                            placeholder="Search to Select Order"
                            readOnly
                          />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <span className="title_changes">Assign Truck#</span>
                        <Form.Item
                          name="selectMaterial"
                          rules={[
                            {
                              required: true,
                              message: "Please Select Assign Truck!",
                            },
                          ]}
                        >
                          <Select
                            className="select_multiplequarytol"
                            style={{
                              fontWeight: "600",
                              width: "100%",
                            }}
                            placeholder="Search to Select Trucks"
                            allowClear
                            onChange={(value, option) => {
                              this.handleAssignChange(value, option);
                            }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                              const inputValue = input.toLowerCase();
                              const truckLabel =
                                option.label?.toLowerCase() || "";
                              const truckLabel2 =
                                option.label2?.toLowerCase() || "";
                              const truckValue = String(option.value);

                              return (
                                truckLabel.startsWith(inputValue) ||
                                truckLabel2.includes(inputValue) ||
                                truckValue.includes(inputValue)
                              );
                            }}
                          >
                            {Object.keys(leadsData)?.length > 0 &&
                              trucksResult?.map((job) => (
                                <Option
                                  key={Number(job?.truckId)}
                                  value={job?.truckId}
                                  className="option_style"
                                  label={job?.carrier_name}
                                  label2={job?.truck_type}
                                  assignTrucksInfo={job?.assignTrucksInfo}
                                >
                                  <span>
                                    {"TNB " +
                                      job?.truckId +
                                      " - " +
                                      job?.carrier_name +
                                      " - " +
                                      job?.truck_type}
                                  </span>
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    {leadsData !== "" && (
                      <div style={{ marginTop: "10px" }}>
                        <span className="title_changes">Job Details</span>

                        <ul>
                          <li>
                            <Row>
                              <Col
                                xxl={{ span: 5 }}
                                xl={{ span: 5 }}
                                lg={{ span: 5 }}
                                md={{ span: 24 }}
                                sm={{ span: 24 }}
                                xs={{ span: 24 }}
                                style={{ marginBottom: "0.5vw" }}
                              >
                                <b style={{ marginBottom: "10px" }}>Job#:</b>
                              </Col>
                              <Col span={12}>
                                {loginType !== "open" &&
                                loginType !== "review" ? (
                                  <a
                                    href={`/app/jobs/job-profile/${leadsData?.jobId}`}
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    <div
                                      style={{ textDecoration: "underLine" }}
                                    >
                                      {leadsData?.jobNumber}
                                    </div>
                                  </a>
                                ) : (
                                  <div>{leadsData?.jobNumber}</div>
                                )}
                              </Col>
                            </Row>
                          </li>

                          <li>
                            <Row>
                              <Col
                                xxl={{ span: 5 }}
                                xl={{ span: 5 }}
                                lg={{ span: 5 }}
                                md={{ span: 24 }}
                                sm={{ span: 24 }}
                                xs={{ span: 24 }}
                                style={{ marginBottom: "0.5vw" }}
                              >
                                <b style={{ marginBottom: "10px" }}>
                                  Job Name:
                                </b>{" "}
                              </Col>

                              <Col span={12}>
                                <div>{leadsData?.jobName}</div>
                              </Col>
                            </Row>
                          </li>
                          <li>
                            <Row>
                              <Col
                                xxl={{ span: 5 }}
                                xl={{ span: 5 }}
                                lg={{ span: 5 }}
                                md={{ span: 24 }}
                                sm={{ span: 24 }}
                                xs={{ span: 24 }}
                                style={{ marginBottom: "0.5vw" }}
                              >
                                <b style={{ marginBottom: "10px" }}>
                                  Customer:
                                </b>
                              </Col>
                              <Col span={12}>
                                <div>{leadsData?.companyName}</div>
                              </Col>
                            </Row>
                          </li>
                          <li>
                            <Row>
                              <Col
                                xxl={{ span: 5 }}
                                xl={{ span: 5 }}
                                lg={{ span: 5 }}
                                md={{ span: 24 }}
                                sm={{ span: 24 }}
                                xs={{ span: 24 }}
                                style={{ marginBottom: "0.5vw" }}
                              >
                                <b style={{ marginBottom: "10px" }}>Pickup:</b>
                              </Col>
                              <Col span={12}>
                                <div>
                                  {leadsData.material?.map(
                                    (e) => e.pickUpAddress
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </li>
                          <li>
                            <Row>
                              <Col
                                xxl={{ span: 5 }}
                                xl={{ span: 5 }}
                                lg={{ span: 5 }}
                                md={{ span: 24 }}
                                sm={{ span: 24 }}
                                xs={{ span: 24 }}
                                style={{ marginBottom: "0.5vw" }}
                              >
                                <b style={{ marginBottom: "10px" }}>
                                  Drop Off:
                                </b>
                              </Col>
                              <Col span={14}>
                                <div>{leadsData?.dropoffAddress}</div>
                              </Col>
                            </Row>
                          </li>
                          <li>
                            <Row>
                              <Col
                                xxl={{ span: 5 }}
                                xl={{ span: 5 }}
                                lg={{ span: 5 }}
                                md={{ span: 24 }}
                                sm={{ span: 24 }}
                                xs={{ span: 24 }}
                                style={{ marginBottom: "0.5vw" }}
                              >
                                <b style={{ marginBottom: "10px" }}>
                                  Trucking Rate:
                                </b>
                              </Col>
                              <Col span={12}>
                                <div>
                                  $
                                  {leadsData.material?.map(
                                    (e) => e.truckingPrice
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </li>
                          {leadsData?.jobType === "Trucking & Material Job" ? (
                            <>
                              <li>
                                <Row>
                                  <Col
                                    xxl={{ span: 5 }}
                                    xl={{ span: 5 }}
                                    lg={{ span: 5 }}
                                    md={{ span: 24 }}
                                    sm={{ span: 24 }}
                                    xs={{ span: 24 }}
                                    style={{ marginBottom: "0.5vw" }}
                                  >
                                    <b style={{ marginBottom: "10px" }}>
                                      {" "}
                                      Material Rate+Tax:
                                    </b>
                                  </Col>
                                  <Col span={12}>
                                    <div>
                                      $
                                      {leadsData.material?.map(
                                        (e) => e.totalPrice
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              </li>
                              <li>
                                <Row>
                                  <Col
                                    xxl={{ span: 5 }}
                                    xl={{ span: 5 }}
                                    lg={{ span: 5 }}
                                    md={{ span: 24 }}
                                    sm={{ span: 24 }}
                                    xs={{ span: 24 }}
                                    style={{ marginBottom: "0.5vw" }}
                                  >
                                    <b style={{ marginBottom: "10px" }}>
                                      {" "}
                                      Landed Rate:
                                    </b>
                                  </Col>
                                  <Col span={12}>
                                    <div>
                                      $
                                      {leadsData?.material?.map(
                                        (e) => e.landedPrice
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              </li>

                              <li>
                                <Row>
                                  <Col
                                    span={5}
                                    style={{ marginBottom: "0.5vw" }}
                                  >
                                    <b style={{ marginBottom: "10px" }}>
                                      {" "}
                                      Material:
                                    </b>
                                  </Col>
                                  <Col span={19}>
                                    <div>
                                      {leadsData?.material?.map(
                                        (e) => e.category + " - " + e.label
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              </li>

                              <li>
                                <Row>
                                  <Col
                                    span={5}
                                    style={{ marginBottom: "0.5vw" }}
                                  >
                                    <b style={{ marginBottom: "10px" }}>
                                      {" "}
                                      Vendor Name:
                                    </b>
                                  </Col>
                                  <Col span={12}>
                                    <div>
                                      {leadsData?.material?.map(
                                        (e) => e.vendor
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              </li>
                            </>
                          ) : (
                            ""
                          )}
                          <li>
                            <Row>
                              <Col span={5} style={{ marginBottom: "0.5vw" }}>
                                <b style={{ marginBottom: "10px" }}>
                                  {" "}
                                  Assigned Trucks#:
                                </b>
                              </Col>
                              <Col span={12}>
                                <div>
                                  {leadsData?.material?.[0]?.assignTrucks?.join(
                                    ", "
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </li>
                        </ul>
                        {leadsData?.assignTrucks?.length === 1 && (
                          <Col span={12}>
                            <span className="title_changes">
                              <b style={{ marginBottom: "10px" }}>
                                Truck Number
                              </b>
                              <div>
                                {Array.isArray(leadsData?.assignTrucks) &&
                                  leadsData?.assignTrucks[0]}
                              </div>
                            </span>
                          </Col>
                        )}
                      </div>
                    )}
                  </>
                )}
                {leadsData["uploadStatus"] === "Open" &&
                  leadsData["ticketNotes"] && (
                    <>
                      <span className="title_changes">Review Notes:</span>
                      <TextArea
                        bordered={false}
                        rows={4}
                        style={{
                          width: "100%",
                          height: "75px",
                          borderColor: "#d6d6d6",
                          fontSize: "18px",
                          border: "1px solid gray",
                          fontWeight: "500",
                        }}
                        value={leadsData.ticketNotes}
                        className="form-input"
                        placeholder="Please Enter Review Notes"
                        readOnly
                      />
                    </>
                  )}
                {(showRejectData ||
                  newTicket ||
                  leadsData["uploadStatus"] === "Open") && (
                  <Row>
                    <Col
                      xxl={{ span: 24, offset: 0 }}
                      xl={{ span: 24, offset: 0 }}
                      lg={{ span: 24, offset: 0 }}
                      md={{ span: 24, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      xs={{ span: 24, offset: 0 }}
                      style={{
                        marginTop:
                          leadsData["uploadStatus"] === "Open" && "1vw",
                      }}
                    >
                      {leadsData["uploadStatus"] === "Open" && (
                        <span className="title_changes">Reupload Ticket</span>
                      )}
                      <Dragger
                        onChange={this.uploadTicketPdf}
                        customRequest={dummyRequest}
                        listType="picture"
                        multiple={false}
                        className="drag-ticket"
                        beforeUpload={this.handleUploadType}
                        maxCount={1}
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-hint">
                          Click or drag file to this area to upload
                        </p>
                      </Dragger>
                      {percentage > 1 && (
                        <Progress
                          percent={percentage}
                          status={"active"}
                          strokeColor={percentage === 100 && "green"}
                        />
                      )}
                    </Col>
                  </Row>
                )}
              </Form>
            </BaseModal>
          )}
        </Row>
      </div>
    );
  }
}
export default withAppContext(withRouter(TicketUploadProcessing));
